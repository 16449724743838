import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NEVER, Observable } from 'rxjs';

import { IMatSelectOption } from '../../../../../../shared/interfaces/mat-select-option';
import { LookupAbstractService } from '../../../../../../shared/lookup-service/lookup-abstract.service';
import { AppState } from '../../../../../../store/app.reducers';
import { RoutingNumberValidationStatusConfig } from '../../../../../model/routing-number-validation-status.config.model';
import { SupplierService } from '../../../../../services/supplier.service';
import { OutgoingPaymentFormComponentBase } from '../outgoing-payment-form-component.base';
import { postalCodeUpdateValidators } from '../payment-type-util';

@Component({
  selector: 'app-payment-type-wire',
  templateUrl: './payment-type-wire.component.html',
  styleUrls: ['./payment-type-wire.component.scss'],
})
export class PaymentTypeWireComponent extends OutgoingPaymentFormComponentBase implements OnInit {
  @Input() paymentConfig?: any;
  @Input() selectedCountry = '';
  @Output() configForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  states$: Observable<IMatSelectOption[] | null> = NEVER;
  bankStates$: Observable<IMatSelectOption[] | null> = NEVER;
  intBankStates$: Observable<IMatSelectOption[] | null> = NEVER;
  bankIdNumCtrlValidationStatus$: Observable<RoutingNumberValidationStatusConfig> = NEVER;
  intBankIdNumCtrlValidationStatus$: Observable<RoutingNumberValidationStatusConfig> = NEVER;

  constructor(
    private readonly fb: FormBuilder,
    supplierService: SupplierService,
    store: Store<AppState>,
    lookupRepo: LookupAbstractService,
  ) {
    super(supplierService, store, lookupRepo);
  }

  ngOnInit() {
    this.createForm();

    const beneficiaryAddressCountryControl = this.form.get(['beneficiary', 'address', 'country']);
    const bankAddressCountryControl = this.form.get(['bank', 'address', 'country']);
    const intermediaryAddressCountryControl = this.form.get(['intermediary', 'address', 'country']);

    this.subscriptions.push(
      beneficiaryAddressCountryControl.valueChanges.subscribe(
        postalCodeUpdateValidators(this.form.get(['beneficiary', 'address', 'postalCode'])),
      ),
    );
    this.subscriptions.push(
      bankAddressCountryControl.valueChanges.subscribe(postalCodeUpdateValidators(this.form.get(['bank', 'address', 'postalCode']))),
    );
    this.subscriptions.push(
      intermediaryAddressCountryControl.valueChanges.subscribe(
        postalCodeUpdateValidators(this.form.get(['intermediary', 'address', 'postalCode'])),
      ),
    );

    this.setFormDataBasedOnInputs();
    this.setValidationForBankIdNumCtrl();
    this.setValidationForIntBankIdNumCtrl();
    this.setIsInternationalPayment(this.form.get(['bank', 'address', 'country']));

    this.states$ = this.setAvailableStatesOnCountryChange(beneficiaryAddressCountryControl);
    this.bankStates$ = this.setAvailableStatesOnCountryChange(bankAddressCountryControl);
    this.intBankStates$ = this.setAvailableStatesOnCountryChange(intermediaryAddressCountryControl);
  }

  private createForm() {
    this.form = this.fb.group({
      beneficiary: this.fb.group({
        address: this.fb.group({
          addr1: '',
          city: '',
          addr2: '',
          country: '',
          postalCode: '',
          state: '',
          addr3: '',
        }),
        beneficiaryName: '',
        accountNumber: ['', Validators.pattern(/^[0-9]{1,}$/)],
        phoneNumber: '',
      }),
      bank: this.fb.group({
        name: '',
        address: this.fb.group({
          addr1: '',
          city: '',
          addr2: '',
          country: '',
          postalCode: '',
          state: '',
          addr3: '',
        }),
        Id: '',
        branchId: '',
      }),
      intermediary: this.fb.group({
        name: '',
        address: this.fb.group({
          addr1: '',
          city: '',
          addr2: '',
          country: '',
          postalCode: '',
          state: '',
          addr3: '',
        }),
        Id: '',
        branchId: '',
      }),
    });
  }

  private setFormDataBasedOnInputs() {
    if (this.selectedCountry) {
      this.form.get(['bank', 'address', 'country']).setValue(this.selectedCountry);
    }

    if (this.paymentConfig && this.paymentConfig.hasOwnProperty('paymentConfig')) {
      if (this.paymentConfig.paymentConfig.intermediary == null) {
        this.paymentConfig.paymentConfig.intermediary = {};
      }
      this.form.patchValue(this.paymentConfig.paymentConfig);
    }
  }

  private setValidationForBankIdNumCtrl() {
    const bankIdNumCtrl = this.form.get(['bank', 'Id']);
    const countryCtrl = this.form.get(['bank', 'address', 'country']);

    this.bankIdNumCtrlValidationStatus$ = this.setBankIdNumValidation(bankIdNumCtrl, () =>
      this.checkBankIdNumControl(bankIdNumCtrl, countryCtrl, { isRequiredForInternationalPayments: true }),
    );
  }

  private setValidationForIntBankIdNumCtrl() {
    const intBankIdNumCtrl = this.form.get(['intermediary', 'Id']);
    const countryCtrl = this.form.get(['intermediary', 'address', 'country']);

    this.intBankIdNumCtrlValidationStatus$ = this.setBankIdNumValidation(intBankIdNumCtrl, () =>
      this.checkBankIdNumControl(intBankIdNumCtrl, countryCtrl),
    );
  }
}
