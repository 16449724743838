<mat-sidenav-container class="task-sidenav-fab-container">
  <mat-sidenav #sidenav mode="side" opened="true" data-test="tasksSidenav">
    <button (click)="openAddTaskDialog()" class="mat-fab btn-tasks btn-tasks-add" data-test="tasksAddTask">
      <i class="material-icons">add</i>
    </button>

    <div class="example-scrolling-content" *ngIf="assigneeOptions && assigneeOptions.length">
      <app-task-grid-filters-sets
        #filterSet
        (onFilterPicked)="updateFilterSet($event)"
        [assigneeOptions]="assigneeOptions"
        [routingFilterNr]="routingFilterNr"
      ></app-task-grid-filters-sets>
    </div>
  </mat-sidenav>

  <button class="toggle-filters-fab" mat-mini-fab color="primary" (click)="sidenav.toggle()" data-test="tasksToggleSidenav">
    <mat-icon>more_horiz</mat-icon>
  </button>

  <section class="container-fluid flex-container">
    <div class="box box-default table-box table-striped table-responsive mdl-shadow--2dp">
      <div class="card flex-container">
        <div class="card-content flex-container">
          <div class="card-header">
            <div class="title" data-test="tasksCardHeader">Tasks</div>
            <app-task-list-actions *ngIf="selection.selected.length > 0" [selection]="selection" (onAction)="resetDatagridState()">
            </app-task-list-actions>
          </div>

          <div class="tasks-table" data-test="tasksTable">
            <div class="grid-filters-wrapper" *ngIf="assigneeOptions && assigneeOptions.length">
              <app-task-grid-filters
                [hidden]="!isAllFetched"
                [filterSetData]="baseFiltersSet"
                [assigneeOptions]="assigneeOptions"
                (filtersChange)="filtersChange($event)"
              >
              </app-task-grid-filters>
            </div>

            <div class="tasks-table-wrapper">
              <app-spinner [hidden]="!isLoadingResults" [forceShow]="true"></app-spinner>
              <mat-table
                #table
                matSort
                [matSortActive]="defaultSorting.field"
                [matSortDirection]="defaultSorting.direction"
                matSortDisableClear
                [matSortDisabled]="!isAllFetched"
                [dataSource]="dataSource"
              >
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button [matMenuTriggerFor]="menu" data-test="tasksTableSelectionMenu">
                      <span>{{ selection.selected.length }}</span>
                      <mat-icon>arrow_drop_down</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="selectAll($event)" data-test="tasksTableSelectAll">
                        <span>Select All</span>
                      </button>
                      <button mat-menu-item (click)="selectNone($event)" data-test="tasksTableSelectNone">
                        <span>Select None</span>
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element; let row">
                    <div class="checkbox-wrapper">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        data-test="tasksTableRowCheckbox"
                      >
                      </mat-checkbox>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="item_number">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTableItemNoHeader">#</mat-header-cell>
                  <mat-cell *matCellDef="let element"> #{{ element.itemNumber }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTableTitleHeader"> Title </mat-header-cell>
                  <mat-cell *matCellDef="let element"
                    ><a href="#" (click)="gotoEditUrl($event, element.uuid)"> {{ element.title }} </a></mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="author">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTableAuthorHeader"> Author </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ getAuthorName(element.ownerUuid) }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="created_at">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTableCreatedAtHeader"> Created at </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.createdAt | fromUnix | date: 'M/d/y h:mm a' }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTableStatusHeader"> Status </mat-header-cell>
                  <mat-cell *matCellDef="let element"
                    ><app-task-status-badge [status]="getStatusName(element.status)"></app-task-status-badge
                  ></mat-cell>
                </ng-container>

                <ng-container matColumnDef="priority">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTablePriorityHeader"> Priority </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.priority | capitalize }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="due_date">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTableDueDateHeader"> Due date </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.dueDate | fromUnix | date: 'M/d/y' }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="assignee">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTableAssigneeHeader"> Assignee </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="d-flex align-items-center">
                      <i class="material-icons option-icon">{{ element.type === 'group' ? 'people' : 'person' }}</i>
                      <span>{{ getAssigneeName(element.assigneeName, element.assigneeType) }}</span>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="supplier">
                  <mat-header-cell *matHeaderCellDef mat-sort-header data-test="tasksTableSupplierHeader"> Supplier </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.targetType === 'supplier'">
                      <a *ngIf="element.targetUuid; else supplierNoTargetUuid" [routerLink]="['/suppliers', element.targetUuid, 'general']">
                        {{ element.supplierName }}
                      </a>
                      <ng-template #supplierNoTargetUuid>
                        <span
                          matTooltip="We cannot redirect you at the moment. Please use the search bar to navigate to the supplier page."
                        >
                          {{ element.supplierName }}
                        </span>
                      </ng-template>
                    </ng-container>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef data-test="tasksTableActionsHeader"> Actions </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <button mat-icon-button color="primary" (click)="gotoEditUrl($event, element.uuid)" data-test="tasksTableEditTaskBtn">
                      <mat-icon class="mat-24" aria-label="Edit">edit</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      ngxClipboard
                      [cbContent]="getFullEditUrl(element.uuid)"
                      (click)="handleTaskUrlCopy($event, element.uuid)"
                      matTooltip="Copy task link"
                      data-test="tasksTableCopyTaskLinkBtn"
                    >
                      <mat-icon>link</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      color="accent"
                      appTaskNextStatus
                      (taskUpdated)="updateTaskRow($event)"
                      [task]="element"
                      data-test="tasksTableFastForwardBtn"
                    >
                      <mat-icon>fast_forward</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: columns"
                  (click)="selection.toggle(row)"
                  [ngClass]="{ overdue: row.isOverdue && row.status !== 'done' && row.status !== 'closed' }"
                ></mat-row>
              </mat-table>

              <mat-paginator [length]="resultsLength" [pageSize]="ITEMS_PER_PAGE"></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-sidenav-container>
