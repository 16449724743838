<mat-card class="productInstantAccess__matCard">
  <div class="productInstantAccess__matCard__title">
    <h5>Instant Access</h5>
    <div>
      <p [ngStyle]="{ color: productStatusColor }">{{ productStatus | uppercase }}</p>
    </div>
  </div>
  <mat-card-content>
    <mat-table *ngIf="dataSource.data.length" class="productInstantAccess__matCard__table" [dataSource]="dataSource">
      <ng-container matColumnDef="marketplace">
        <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAccess__matCard__table__cell">
          {{ element.marketplace }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dueFromMp">
        <mat-header-cell *matHeaderCellDef>Due From MP</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAccess__matCard__table__cell">
          {{ element.dueFromMp }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mpStatement">
        <mat-header-cell *matHeaderCellDef>MP Statement</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAccess__matCard__table__cell">
          {{ element.mpStatement }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="optInDate">
        <mat-header-cell *matHeaderCellDef>Opt In Date</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productInstantAccess__matCard__table__cell-date">
          {{ element.optInDate }}
          <button
            mat-icon-button
            (click)="openReasonDialog(element.optInDate, element.optOutDate, i)"
            class="productInstantAccess__matCard__table__cell__btn"
          >
            <mat-icon class="productInstantAccess__matCard__table__cell__icon">date_range</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="optOutDate">
        <mat-header-cell *matHeaderCellDef>Opt Out Date</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productInstantAccess__matCard__table__cell-date">
          {{ element.optOutDate }}
          <button
            mat-icon-button
            (click)="openReasonDialog(element.optInDate, element.optOutDate, i)"
            class="productInstantAccess__matCard__table__cell__btn"
          >
            <mat-icon class="productInstantAccess__matCard__table__cell__icon">date_range</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reserveRate">
        <mat-header-cell *matHeaderCellDef>Reserve Rate</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAccess__matCard__table__cell">
          {{ element.reserveRate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fee">
        <mat-header-cell *matHeaderCellDef>Fee</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAccess__matCard__table__cell"> {{ element.fee }}% </mat-cell>
      </ng-container>

      <ng-container matColumnDef="uwStatus">
        <mat-header-cell *matHeaderCellDef>UW Status</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productInstantAccess__matCard__table__cell">
          <mat-select
            class="productInstantAccess__matCard__table__cell__status-{{ getUnderwritingStatusColor(element.uwStatus) }}"
            [(ngModel)]="element.uwStatus"
            (selectionChange)="uwStatusUpdateRequest($event, i)"
          >
            <mat-option *ngFor="let status of uwStatuses" [value]="status">{{ status | capitalize }}</mat-option>
          </mat-select>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="customerSelection">
        <mat-header-cell *matHeaderCellDef>Customer Selection</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAccess__matCard__table__cell">
          {{ element.customerSelection | capitalize }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="serviceColumns" class="productInstantAccess__matCard__table__headerRow"></mat-header-row>
      <mat-row *matRowDef="let row; columns: serviceColumns" class="productInstantAccess__matCard__table__row"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
