import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-receiveable',
  templateUrl: './add-receiveable.component.html',
  styleUrls: ['./add-receiveable.component.less'],
})
export class AddReceiveableComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
