<div class="field-wrapper" [class]="fieldClass" [ngClass]="{ 'has-icon': materialIcon, 'in-group-edit': inGroupEdit }">
  <!-- EDIT MODE -->
  <div *ngIf="(inEditMode() || isInlineEditOnly()) && !isReadOnly; else presentationMode" class="edit-mode">
    <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>

    <mat-form-field>
      <input
        #inlineEditControl
        matInput
        placeholder="{{ label }}"
        [formControl]="formGroup.get(formControlNamee)"
        [matDatepicker]="picker"
        [name]="value"
        [(ngModel)]="value"
        [type]="type"
        [placeholder]="label"
        (blur)="markAsTouched()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div *ngIf="timeForm" class="form" [formGroup]="timeForm">
      <mat-form-field class="form-control-size">
        <input
          #inlineEditControl
          matInput
          [formControlName]="'hours'"
          [placeholder]="'HH'"
          (blur)="markAsTouched()"
          type="number"
          min="0"
          max="12"
        />
      </mat-form-field>

      <mat-form-field class="form-control-size">
        <input
          #inlineEditControl
          matInput
          [formControlName]="'minutes'"
          type="number"
          [placeholder]="'MM'"
          (blur)="markAsTouched()"
          min="0"
          max="60"
        />
      </mat-form-field>

      <mat-form-field class="form-control-size">
        <input
          #inlineEditControl
          matInput
          [formControlName]="'seconds'"
          [placeholder]="'SS'"
          (blur)="markAsTouched()"
          min="0"
          max="60"
          type="number"
        />
      </mat-form-field>

      <mat-form-field class="time-form">
        <mat-select [placeholder]="'time'" [formControlName]="'time'">
          <mat-option *ngFor="let time of times" [value]="time.value">
            {{ time?.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <small>(EST)</small>
    </div>

    <mat-error *ngIf="hasRequiredError()"> This field is <strong>required</strong> </mat-error>

    <div class="help-text-wrapper warning" *ngIf="helpText && !hasRequiredError()">
      {{ helpText }}
    </div>

    <div class="inline-buttons" *ngIf="isInlineEditOnly()">
      <button class="btn btn-xs btn-cancel" (click)="close($event)">Cancel <i class="material-icons">close</i></button>
      <button class="btn btn-xs btn-success btn-save" (click)="callSave(value)">Save <i class="material-icons">done</i></button>
    </div>
  </div>

  <ng-template #presentationMode>
    <div class="presentation-mode" [ngClass]="{ 'disable-inline': disableInline }">
      <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>
      <div class="fake-field">
        <div class="label">
          {{ label }}
          <mat-icon *ngIf="showCopy" ngxClipboard [cbContent]="value" class="btn-copy" matTooltip="Click to copy {{ label }}"
            >content_copy</mat-icon
          >
        </div>
        <div class="value" (click)="edit(value)" (focus)="edit(value)">{{ showDateString || defaultEmptyValue }}</div>
      </div>
    </div>
  </ng-template>
</div>
