export class FeeScheduleListModel {
  feeScheduleCategory: string;
  feeScheduleKey: string;
  feeScheduleName: string;
  feeScheduleType: string;
  feeScheduleTypeCode: string;
  id: string;
  isEditable: false;

  feeFixed: string;
  feePercent: string;
  minPmtFee: string;
  feeScheduleTierKey: string;
  paymentTypeCode: string;
  pmtBatchFeeCode: string;
  pmtBatchFeeName: string;
  tierEnd: string;
  tierStart: string;
  feeScheduleTiers?: Array<any>;
  tiers: number;
  freeAccess: boolean;

  isCollapsed = true;
  isTier = false;
}
