import { Action, createSelector } from '@ngrx/store';
import { isUndefined } from 'util';

import { AppState } from '../../../store/app.reducers';
import { UtilsState } from './utils.reducers';

export const SET_UTIL_ATTR = 'SET_UTIL_ATTR';
export const FETCH_UTIL_ATTR = 'FETCH_UTIL_ATTR';

export class SetUtilAttribute implements Action {
  readonly type = SET_UTIL_ATTR;
  constructor(public payload: { attr: string; data: any }) {}
}

export class FetchUtilAttribute implements Action {
  readonly type = FETCH_UTIL_ATTR;

  constructor(public payload: { attr: string; refresh?: string }) {}
}

const selectUtilsState = (state: AppState) => state.utils;

function selectUtilsAttribute(attr: string) {
  return createSelector(selectUtilsState, (state: UtilsState) => {
    if (!isUndefined(state)) {
      return state.hasOwnProperty(attr) ? state[attr] : undefined;
    }
  });
}

export const selectTimezones = selectUtilsAttribute('timezones');
export const selectFundingPeriods = selectUtilsAttribute('fundingPeriods');
export const selectUsStates = selectUtilsAttribute('usStates');
export const selectCountries = selectUtilsAttribute('countries');

export type UtilsActions = SetUtilAttribute | FetchUtilAttribute;
