import { MARKETPLACES } from './marketplaces';
import {
  AMAZON_SELLER_CENTRAL,
  AUTHORIZE_MARKETPLACE,
  EBAY_MARKETPLACE,
  MORE_COMMERCE_MARKETPLACE,
  NEWEGG_MARKETPLACE,
  PAYONEER_MARKETPLACE,
  PAYPAL_MARKETPLACE,
  PINGPONG_MARKETPLACE,
  SHOPIFY_MARKETPLACE,
  STRIPE_MARKETPLACE,
  TOP_HATTER_MARKETPLACE,
  VOLUSION_MARKETPLACE,
  WALMART_MARKETPLACE,
} from './marketplaces-settings';

export const MARKETPLACES_FOR_EXTENTION_MAP = {
  [MARKETPLACES.AMAZON_MARKETPLACE]: AMAZON_SELLER_CENTRAL,
  [MARKETPLACES.TOPHATTER_MARKETPLACE]: TOP_HATTER_MARKETPLACE,
  [MARKETPLACES.WALLMART_MARKETPLACE]: WALMART_MARKETPLACE,
  [MARKETPLACES.SHOPIFY_MARKETPLACE]: SHOPIFY_MARKETPLACE,
  [MARKETPLACES.PAYONEER_MARKETPLACE]: PAYONEER_MARKETPLACE,
  [MARKETPLACES.MORECOMMERCE_MARKETPLACE]: MORE_COMMERCE_MARKETPLACE,
  [MARKETPLACES.EBAY_MARKETPLACE]: EBAY_MARKETPLACE,
  [MARKETPLACES.VOLUSION_MARKETPLACE]: VOLUSION_MARKETPLACE,
  [MARKETPLACES.PAYPAL_MARKETPLACE]: PAYPAL_MARKETPLACE,
  [MARKETPLACES.STRIPE_MARKETPLACE]: STRIPE_MARKETPLACE,
  [MARKETPLACES.AUTHORIZE_MARKETPLACE]: AUTHORIZE_MARKETPLACE,
  [MARKETPLACES.NEWEGG_MARKETPLACE]: NEWEGG_MARKETPLACE,
  [MARKETPLACES.PINGPONG_MARKETPLACE]: PINGPONG_MARKETPLACE,
};
