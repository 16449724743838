import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { TOOLS_THEME } from '../../../../shared/editor/editor.enum';

@Component({
  selector: 'app-email-base-template',
  templateUrl: './email-base-template.component.html',
})
export class EmailBaseTemplateComponent implements OnInit {
  readonly TOOLS_THEME = TOOLS_THEME;
  public emailBaseTemplateForm: FormGroup;

  @Input() configValue: any;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.configValue ? this.createEditForm() : this.createNewForm();
  }

  createNewForm() {
    this.emailBaseTemplateForm = this.formBuilder.group({
      html: [''],
      text: [''],
    });
  }

  createEditForm() {
    this.emailBaseTemplateForm = this.formBuilder.group({
      html: this.configValue.html,
      text: this.configValue.text,
    });
  }
}
