import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { EVA_IFRAME_SELECTOR } from '../../../shared/eva/const/eva-iframe-selector.enum';
import { EvaService } from '../../../shared/eva/eva.service';
import { CommunicationService } from '../../../shared/iframe/communication/communication.service';
import { MESSAGE_TYPE } from '../../../shared/iframe/communication/message-type.enum';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { TodaysDateCellRenderer } from './../../../shared/functions/date-cell-renderer';
import { PercentCellRenderer } from './../../../shared/functions/percent-cell-renderer';
import { LongTermContractsService } from './../../../shared/services/long-term-contracts/long-term-contracts.service';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from './../supplier-onboarding/supplier-onboarding.service';

@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss'],
})
export class AddContractComponent extends SubscriberComponent implements OnInit {
  actionTitle = 'Add default contract';
  rows: any;
  groupKey: string;
  mpSupKey: string;

  readonly columns = [
    {
      field: 'marketplaceName',
      headerName: 'Marketplace',
    },
    {
      field: 'discountPercent',
      headerName: 'Discount %',
      cellRenderer: PercentCellRenderer,
    },
    {
      field: 'duration',
      headerName: 'Duration (months)',
    },
    {
      field: 'offerDate',
      headerName: 'Offer Date',
      cellRenderer: TodaysDateCellRenderer,
    },
  ];

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<BaseSupplierAction>,
    private readonly ltcService: LongTermContractsService,
    private readonly toastrService: ToastrService,
    private readonly communicationService: CommunicationService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.ltcService.getExistingContractForAddingDefaultContract(AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY).subscribe((data) => {
        this.groupKey = data[0].groupKey;
        this.rows = data;
      }),
      this.store.select(selectSupplierDetails).subscribe((supplierDetails: SupplierDetailsModel) => {
        if (!supplierDetails) {
          return;
        }
        this.mpSupKey = supplierDetails.marketplaces.find((marketplace) => {
          return marketplace.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY;
        }).mpSupKey;
      }),
    );
  }

  pushToDashboard() {
    this.ltcService.pushContractToDashboard(this.mpSupKey, this.groupKey).subscribe(
      () => {
        this.dialogRef.close();
        this.toastrService.success('Contract has been successfully pushed to the dashboard !');
        this.refreshContracts();
      },
      (err) => {
        this.toastrService.error(err.error.message);
      },
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private refreshContracts(): void {
    const message = {
      type: MESSAGE_TYPE.CONTRACTS__REFRESH,
    };
    this.communicationService.sendMessageToChild(EvaService.getIframeIdBySelector(EVA_IFRAME_SELECTOR.TOP_BAR), message);
  }
}
