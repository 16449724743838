<div [formGroup]="batchSupplierEmailForm">
  <mat-form-field>
    <input matInput placeholder="Template name" formControlName="template_name" />
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Description" formControlName="description" />
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Subject" formControlName="subject" />
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="From address" formControlName="source" />
  </mat-form-field>
  <app-editor formControlName="html" [toolsTheme]="TOOLS_THEME.EMAIL"> </app-editor>

  <textarea placeholder="Text template" formControlName="text" class="full-width"></textarea>
</div>
