import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-summary',
  templateUrl: './supplier-summary.component.html',
})
export class SupplierSummaryComponent implements OnInit {
  public readonly NAVLINKS = [{ route: 'categorization', label: 'Supplier Categorization' }];

  public activeIndex: string;

  constructor() {}

  ngOnInit() {}
}
