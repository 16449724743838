export enum DOCUMENT_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
}

export enum DOCUMENT_TYPE {
  BANK_STATEMENT = 'BANK_STATEMENT',
  CONTRACT_ADDENDUM = 'CONTRACT_ADDENDUM',
  IDENTIFICATION_DOCUMENT = 'IDENTIFICATION_DOCUMENT',
  NAME_ADDENDUM = 'NAME_ADDENDUM',
  OTHER = 'OTHER',
  PAYMENT_AUTHORIZATION_FORMS = 'PAYMENT_AUTHORIZATION_FORMS',
  REGISTERED_BUSINESS_DOCUMENT = 'REGISTERED_BUSINESS_DOCUMENT',
  SUPPLIER_AGREEMENT = 'SUPPLIER_AGREEMENT',
  TAX_RETURN_AND_FINANCIAL_STATEMENT = 'TAX_RETURN_AND_FINANCIAL_STATEMENT',
  UCC = 'UCC',
}
