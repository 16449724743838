export const __REAL_TIME_VALIDATOR_DATA__ = {
  result: {
    'ns2:EIDVPersonSearchResponse': {
      '@xmlns:ns2': 'com/thomsonreuters/schemas/eidvsearch',
      EIDVPersonSearchResults: {
        EIDVPersonSearchResult: {
          EIDVPersonSearchRequest: {
            Criteria: {
              BirthDate: {
                Day: '12',
                Month: '12',
                Year: '2012',
              },
              City: 'Zhejiang',
              FirstName: 'Janek',
              LastName: 'Kowalski',
              SSN: '123-45-6789',
              State: 'TX',
              Street: 'street 1',
              ZipCode: '55555',
            },
            EIDVName: 'EIDVPersonUS',
            EIDVVersion: '1',
            InternalID: 'InternalID',
            UserReference: [
              'CLEAR ID Confirm Person Search 1',
              'CLEAR ID Confirm Person Search 2',
              'CLEAR ID Confirm Person Search 3',
              'CLEAR ID Confirm Person Search 4',
              'CLEAR ID Confirm Person Search 5',
              'CLEAR ID Confirm Person Search 6',
              'CLEAR ID Confirm Person Search 7',
              'CLEAR ID Confirm Person Search 8',
              'CLEAR ID Confirm Person Search 9',
            ],
          },
          NormalizedCriteria: {
            BirthDate: '12/12/2012',
            City: 'ZHEJIANG',
            FirstName: 'Janek',
            LastName: 'Kowalski',
            SSN: '123-45-6789',
            State: 'TX',
            StreetName: 'ST',
            StreetNumber: '1',
            ZipCode: '55555',
          },
          PersonEntities: {
            PersonEntity: {
              AdditionalPhoneNumbers: {
                AdditionalPhoneNumber: [
                  {
                    DocumentIdentifierInfo: [
                      {
                        DocumentIdentifier: 'I065271a2ce8711e398db8b09b4f043e0',
                        Source: 'Phone Record',
                      },
                      {
                        DocumentIdentifier: 'IA5A65BDEDF7211D5B69D0E00E20D7175',
                        Source: 'TransUnion',
                      },
                      {
                        DocumentIdentifier: 'Ia11fbaa0a0d911eabea3f0dc9fb69570',
                        Source: 'Utility Listing',
                      },
                      {
                        DocumentIdentifier: 'I2ebd2ea0235711dd86d5f687b7443f19',
                        Source: 'Experian Credit Header',
                      },
                    ],
                    PhoneNumber: '(651) 767-0671',
                    Source: ['Phone Record', 'TransUnion', 'Utility Listing', 'Experian Credit Header'],
                  },
                  {
                    DocumentIdentifierInfo: {
                      DocumentIdentifier: 'I97495601a66d11e598dc8b09b4f043e0',
                      Source: 'Utility Listing',
                    },
                    PhoneNumber: '(651) 968-6802',
                    Source: 'Utility Listing',
                  },
                  {
                    DocumentIdentifierInfo: {
                      DocumentIdentifier: 'I2ebd2ea0235711dd86d5f687b7443f19',
                      Source: 'Experian Credit Header',
                    },
                    PhoneNumber: '(630) 289-7694',
                    Source: 'Experian Credit Header',
                  },
                  {
                    DocumentIdentifierInfo: {
                      DocumentIdentifier: 'I6e5d2da3cd6811e398db8b09b4f043e0',
                      Source: 'Phone Record',
                    },
                    PhoneNumber: '(612) 968-7544',
                    Source: 'Phone Record',
                  },
                  {
                    DocumentIdentifierInfo: {
                      DocumentIdentifier: 'I751d3ef1ce8511e398db8b09b4f043e0',
                      Source: 'Phone Record',
                    },
                    PhoneNumber: '(651) 353-0235',
                    Source: 'Phone Record',
                  },
                ],
              },
              Death: {
                DeathIndicator: 'NO',
              },
              EntityIdentifier: 'P1__MzM4NTU0ODI5',
              GroupId: '8a57c8927471056e01747dcdd03f2234',
              HistoricalAddress: {
                HistoricalAddressIndicator: 'NO',
              },
              KnownAddresses: {
                KnownAddress: [
                  {
                    City: 'SAINT PAUL',
                    County: 'RAMSEY',
                    DocumentIdentifierInfo: [
                      {
                        DocumentIdentifier: 'Ia11fbaa0a0d911eabea3f0dc9fb69570',
                        Source: 'Utility Listing',
                      },
                      {
                        DocumentIdentifier: 'I065271a2ce8711e398db8b09b4f043e0',
                        Source: 'Phone Record',
                      },
                      {
                        DocumentIdentifier: 'I6e5d2da3cd6811e398db8b09b4f043e0',
                        Source: 'Phone Record',
                      },
                      {
                        DocumentIdentifier: 'IA5A65BDEDF7211D5B69D0E00E20D7175',
                        Source: 'TransUnion',
                      },
                      {
                        DocumentIdentifier: 'Id9127bf045c411e28578f7ccc38dcbee',
                        Source: 'Driver\u0027s Licenses',
                      },
                      {
                        DocumentIdentifier: 'I2ebd2ea0235711dd86d5f687b7443f19',
                        Source: 'Experian Credit Header',
                      },
                      {
                        DocumentIdentifier: 'I7b6f39208de811df9b8c850332338889',
                        Source: 'Driver\u0027s Licenses',
                      },
                      {
                        DocumentIdentifier: 'I751d3ef1ce8511e398db8b09b4f043e0',
                        Source: 'Phone Record',
                      },
                      {
                        DocumentIdentifier: 'I7b4dd64000dc11e28b05fdf15589d8e8',
                        Source: 'E-mail Records',
                      },
                    ],
                    LastConfirmed: '20200525',
                    Source: [
                      'Utility Listing',
                      'Phone Record',
                      'TransUnion',
                      'Driver\u0027s Licenses',
                      'Experian Credit Header',
                      'E-mail Records',
                    ],
                    State: 'MN',
                    Street: '9 7TH PL W APT 407',
                    ZipCode: '55102',
                  },
                  {
                    City: 'SAINT PAUL',
                    County: 'DAKOTA',
                    DocumentIdentifierInfo: [
                      {
                        DocumentIdentifier: 'I2ebd2ea0235711dd86d5f687b7443f19',
                        Source: 'Experian Credit Header',
                      },
                      {
                        DocumentIdentifier: 'I6e5d2da3cd6811e398db8b09b4f043e0',
                        Source: 'Phone Record',
                      },
                      {
                        DocumentIdentifier: 'I751d3ef1ce8511e398db8b09b4f043e0',
                        Source: 'Phone Record',
                      },
                      {
                        DocumentIdentifier: 'I97495601a66d11e598dc8b09b4f043e0',
                        Source: 'Utility Listing',
                      },
                      {
                        DocumentIdentifier: 'I7bc37d13235c11e498db8b09b4f043e0',
                        Source: 'TransUnion',
                      },
                      {
                        DocumentIdentifier: 'IA5A65BDEDF7211D5B69D0E00E20D7175',
                        Source: 'TransUnion',
                      },
                      {
                        DocumentIdentifier: 'I065271a2ce8711e398db8b09b4f043e0',
                        Source: 'Phone Record',
                      },
                      {
                        DocumentIdentifier: 'Id454487010e511eaadfea82903531a62',
                        Source: 'Phone Record',
                      },
                    ],
                    LastConfirmed: '20170810',
                    Source: ['Experian Credit Header', 'Phone Record', 'Utility Listing', 'TransUnion'],
                    State: 'MN',
                    Street: '1762 OAKDALE AV 311',
                    ZipCode: '55118',
                  },
                  {
                    City: 'SAINT PAUL',
                    County: 'RAMSEY',
                    DocumentIdentifierInfo: {
                      DocumentIdentifier: 'Ib0b773e01fa011e698dc8b09b4f043e0',
                      Source: 'Household Listing',
                    },
                    LastConfirmed: '20151231',
                    Source: 'Household Listing',
                    State: 'MN',
                    Street: '9 7TH PL W APT 400',
                    ZipCode: '55102',
                  },
                  {
                    City: 'YOUNG AMERICA',
                    County: 'CARVER',
                    DocumentIdentifierInfo: {
                      DocumentIdentifier: 'I2ebd2ea0235711dd86d5f687b7443f19',
                      Source: 'Experian Credit Header',
                    },
                    LastConfirmed: '20100617',
                    Source: 'Experian Credit Header',
                    State: 'MN',
                    Street: '9 7TH PL W # 407',
                    ZipCode: '55555',
                  },
                  {
                    City: 'SAINT PAUL',
                    County: 'RAMSEY',
                    DocumentIdentifierInfo: {
                      DocumentIdentifier: 'I2ebd2ea0235711dd86d5f687b7443f19',
                      Source: 'Experian Credit Header',
                    },
                    LastConfirmed: '20030404',
                    Source: 'Experian Credit Header',
                    State: 'MN',
                    Street: '904 RAYMOND AVE',
                    ZipCode: '55114',
                  },
                ],
              },
              KnownEmailAddresses: {
                KnownEmailAddress: {
                  DocumentIdentifierInfo: {
                    DocumentIdentifier: 'I7b4dd64000dc11e28b05fdf15589d8e8',
                    Source: 'E-mail Records',
                  },
                  EmailAdress: 'JON.KOWALSKI@GMAIL.COM',
                  Source: 'E-mail Records',
                },
              },
              MultipleSSNs: {
                MultipleSSNsIndicator: 'NO',
              },
              OfacListing: {
                OfacListingIndicator: 'NO',
              },
              RecordNumber: '1',
              SSNMatchesMultipleIndividuals: {
                SSNMatchesMultipleIndividualsIndicator: 'NO',
              },
              SearchRecords: {
                SearchRecord: [
                  {
                    BirthDate: {
                      '#text': '1972',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    City: {
                      '#text': 'YOUNG AMERICA',
                      '@matchscore': '42.41',
                      '@matchtype': 'NO MATCH',
                    },
                    ContentScore: '33.12',
                    ContentSource: 'Experian Credit Header',
                    DocumentIdentifier: 'I2ebd2ea0235711dd86d5f687b7443f19',
                    FirstName: {
                      '#text': 'JON',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    LastName: {
                      '#text': 'KOWALSKI',
                      '@matchscore': '100.00',
                      '@matchtype': 'MATCH',
                    },
                    MiddleName: 'C',
                    PhoneNumber: '(651) 767-0671',
                    SSN: {
                      '#text': '473-86-XXXX',
                      '@matchscore': '36.36',
                      '@matchtype': 'NO MATCH',
                    },
                    State: {
                      '#text': 'MN',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    StreetName: {
                      '#text': '7TH PL W',
                      '@matchscore': '54.17',
                      '@matchtype': 'NO MATCH',
                    },
                    StreetNumber: {
                      '#text': '9',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    ZipCode: {
                      '#text': '55555',
                      '@matchscore': '100.00',
                      '@matchtype': 'MATCH',
                    },
                  },
                  {
                    City: {
                      '#text': 'SAINT PAUL',
                      '@matchscore': '44.72',
                      '@matchtype': 'NO MATCH',
                    },
                    ContentScore: '31.05',
                    ContentSource: 'TransUnion',
                    DocumentIdentifier: 'I7bc37d13235c11e498db8b09b4f043e0',
                    FirstName: {
                      '#text': 'JON',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    LastName: {
                      '#text': 'KOWALSKI',
                      '@matchscore': '100.00',
                      '@matchtype': 'MATCH',
                    },
                    MiddleName: 'C',
                    SSN: {
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    State: {
                      '#text': 'MN',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    StreetName: {
                      '#text': 'OAKDALE AVE',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    StreetNumber: {
                      '#text': '1762',
                      '@matchscore': '75.00',
                      '@matchtype': 'PARTIAL MATCH',
                    },
                    ZipCode: {
                      '#text': '55118',
                      '@matchscore': '40.00',
                      '@matchtype': 'NO MATCH',
                    },
                  },
                  {
                    City: {
                      '#text': 'WEST SAINT PAUL',
                      '@matchscore': '50.56',
                      '@matchtype': 'NO MATCH',
                    },
                    ContentScore: '41.86',
                    ContentSource: 'Utility Listing',
                    DocumentIdentifier: 'I97495601a66d11e598dc8b09b4f043e0',
                    FirstName: {
                      '#text': 'JON',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    LastName: {
                      '#text': 'KOWALSKI',
                      '@matchscore': '100.00',
                      '@matchtype': 'MATCH',
                    },
                    PhoneNumber: '(651) 968-6802',
                    SSN: {
                      '#text': '473-86-XXXX',
                      '@matchscore': '36.36',
                      '@matchtype': 'NO MATCH',
                    },
                    State: {
                      '#text': 'MN',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    StreetName: {
                      '#text': 'OAKDALE AVE',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    StreetNumber: {
                      '#text': '1762',
                      '@matchscore': '75.00',
                      '@matchtype': 'PARTIAL MATCH',
                    },
                    ZipCode: {
                      '#text': '55118',
                      '@matchscore': '40.00',
                      '@matchtype': 'NO MATCH',
                    },
                  },
                  {
                    City: {
                      '#text': 'SAINT PAUL',
                      '@matchscore': '44.72',
                      '@matchtype': 'NO MATCH',
                    },
                    ContentScore: '33.46',
                    ContentSource: 'E-mail Records',
                    DocumentIdentifier: 'I7b4dd64000dc11e28b05fdf15589d8e8',
                    FirstName: {
                      '#text': 'JON',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    LastName: {
                      '#text': 'KOWALSKI',
                      '@matchscore': '100.00',
                      '@matchtype': 'MATCH',
                    },
                    State: {
                      '#text': 'MN',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    StreetName: {
                      '#text': '7TH PL W 407',
                      '@matchscore': '52.78',
                      '@matchtype': 'NO MATCH',
                    },
                    StreetNumber: {
                      '#text': '9',
                      '@matchscore': '0.00',
                      '@matchtype': 'NO MATCH',
                    },
                    ZipCode: {
                      '#text': '55102',
                      '@matchscore': '40.00',
                      '@matchtype': 'NO MATCH',
                    },
                  },
                ],
              },
              Summary: {
                BirthDate: {
                  '@matchscore': '0.00',
                  '@matchtype': 'NO MATCH',
                },
                City: {
                  '@matchscore': '50.56',
                  '@matchtype': 'NO MATCH',
                },
                FirstName: {
                  '@matchscore': '0.00',
                  '@matchtype': 'NO MATCH',
                },
                LastName: {
                  '@matchscore': '100.00',
                  '@matchtype': 'MATCH',
                },
                SSN: {
                  '@matchscore': '36.36',
                  '@matchtype': 'NO MATCH',
                },
                State: {
                  '@matchscore': '0.00',
                  '@matchtype': 'NO MATCH',
                },
                StreetName: {
                  '@matchscore': '54.17',
                  '@matchtype': 'NO MATCH',
                },
                StreetNumber: {
                  '@matchscore': '75.00',
                  '@matchtype': 'PARTIAL MATCH',
                },
                ZipCode: {
                  '@matchscore': '100.00',
                  '@matchtype': 'NO MATCH',
                },
              },
              TotalScore: '35.29',
            },
          },
        },
      },
      Status: {
        StatusCode: '200',
        SubStatusCode: '200',
      },
    },
  },
  timestamp: '----',
};

export const __CORELATION_DATA__ = {
  correlation_id: '65a2d84c-34c0-4af2-b8ee-f9cc5e1ac113',
  // correlation_id: '3f5e0238-a334-452a-9df7-bdf263f9bb02',
};

const __SOME_SUPPLIER_KEY__ = 'ac6a722f-6a18-450f-9961-0a1e6845d3f1';
const __SOME_LOGIN_KEY__ = 'e6ae8c95-fcfb-4637-9d4e-a0ca59e5fed3';

export const __TASK_DTO__ = {
  task_name: 'thomson_reuters/request_personal_information_validation',
  task_input: {
    supplier_key: __SOME_SUPPLIER_KEY__,
    person_id: __SOME_LOGIN_KEY__,
    first_name: 'Janek',
    last_name: 'Wiśniewski',
    birth_day: '12',
    birth_month: '12',
    birth_year: '2012',
    city: 'Xian',
    ssn: '987-45-6789',
    state: 'TX',
    street: 'street 1',
    zip_code: '55555',
  },
};

/*
export const __ANOTHER_REAL_TIME_DATA__ = {
  "ns2:EIDVPersonSearchResponse": {
    "@xmlns:ns2": "com/thomsonreuters/schemas/eidvsearch",
    "EIDVPersonSearchResults": {
      "EIDVPersonSearchResult": {
        "EIDVPersonSearchRequest": {
          "Criteria": {
            "BirthDate": {
              "Day": "12",
              "Month": "12",
              "Year": "2012"
            },
            "City": "Zhejiang",
            "FirstName": "Janek",
            "LastName": "Kowalski",
            "SSN": "123-45-6789",
            "State": "TX",
            "Street": "street 1",
            "ZipCode": "55555"
          },
          "EIDVName": "EIDVPersonUS",
          "EIDVVersion": "1",
          "InternalID": "InternalID",
          "UserReference": [
            "CLEAR ID Confirm Person Search 1",
            "CLEAR ID Confirm Person Search 2",
            "CLEAR ID Confirm Person Search 3",
            "CLEAR ID Confirm Person Search 4",
            "CLEAR ID Confirm Person Search 5",
            "CLEAR ID Confirm Person Search 6",
            "CLEAR ID Confirm Person Search 7",
            "CLEAR ID Confirm Person Search 8",
            "CLEAR ID Confirm Person Search 9"
          ]
        },
        "NormalizedCriteria": {
          "BirthDate": "12/12/2012",
          "City": "ZHEJIANG",
          "FirstName": "Janek",
          "LastName": "Kowalski",
          "SSN": "123-45-6789",
          "State": "TX",
          "StreetName": "ST",
          "StreetNumber": "1",
          "ZipCode": "55555"
        },
        "PersonEntities": {
          "PersonEntity": {
            "AdditionalPhoneNumbers": {
              "AdditionalPhoneNumber": [
                {
                  "DocumentIdentifierInfo": [
                    {
                      "DocumentIdentifier": "I065271a2ce8711e398db8b09b4f043e0",
                      "Source": "Phone Record"
                    },
                    {
                      "DocumentIdentifier": "IA5A65BDEDF7211D5B69D0E00E20D7175",
                      "Source": "TransUnion"
                    },
                    {
                      "DocumentIdentifier": "Ia11fbaa0a0d911eabea3f0dc9fb69570",
                      "Source": "Utility Listing"
                    },
                    {
                      "DocumentIdentifier": "I2ebd2ea0235711dd86d5f687b7443f19",
                      "Source": "Experian Credit Header"
                    }
                  ],
                  "PhoneNumber": "(651) 767-0671",
                  "Source": [
                    "Phone Record",
                    "TransUnion",
                    "Utility Listing",
                    "Experian Credit Header"
                  ]
                },
                {
                  "DocumentIdentifierInfo": {
                    "DocumentIdentifier": "I97495601a66d11e598dc8b09b4f043e0",
                    "Source": "Utility Listing"
                  },
                  "PhoneNumber": "(651) 968-6802",
                  "Source": "Utility Listing"
                },
                {
                  "DocumentIdentifierInfo": [
                    {
                      "DocumentIdentifier": "IA5A65BDEDF7211D5B69D0E00E20D7175",
                      "Source": "TransUnion"
                    },
                    {
                      "DocumentIdentifier": "I2ebd2ea0235711dd86d5f687b7443f19",
                      "Source": "Experian Credit Header"
                    }
                  ],
                  "PhoneNumber": "(630) 289-7694",
                  "Source": [
                    "TransUnion",
                    "Experian Credit Header"
                  ]
                },
                {
                  "DocumentIdentifierInfo": {
                    "DocumentIdentifier": "I6e5d2da3cd6811e398db8b09b4f043e0",
                    "Source": "Phone Record"
                  },
                  "PhoneNumber": "(612) 968-7544",
                  "Source": "Phone Record"
                },
                {
                  "DocumentIdentifierInfo": {
                    "DocumentIdentifier": "I751d3ef1ce8511e398db8b09b4f043e0",
                    "Source": "Phone Record"
                  },
                  "PhoneNumber": "(651) 353-0235",
                  "Source": "Phone Record"
                }
              ]
            },
            "Death": {
              "DeathIndicator": "NO"
            },
            "EntityIdentifier": "P1__MzM4NTU0ODI5",
            "GroupId": "8a56c8a5749a0b0b0174a1023e067085",
            "HistoricalAddress": {
              "HistoricalAddressIndicator": "NO"
            },
            "KnownAddresses": {
              "KnownAddress": [
                {
                  "City": "SAINT PAUL",
                  "County": "RAMSEY",
                  "DocumentIdentifierInfo": [
                    {
                      "DocumentIdentifier": "Ia11fbaa0a0d911eabea3f0dc9fb69570",
                      "Source": "Utility Listing"
                    },
                    {
                      "DocumentIdentifier": "I065271a2ce8711e398db8b09b4f043e0",
                      "Source": "Phone Record"
                    },
                    {
                      "DocumentIdentifier": "I6e5d2da3cd6811e398db8b09b4f043e0",
                      "Source": "Phone Record"
                    },
                    {
                      "DocumentIdentifier": "IA5A65BDEDF7211D5B69D0E00E20D7175",
                      "Source": "TransUnion"
                    },
                    {
                      "DocumentIdentifier": "Id9127bf045c411e28578f7ccc38dcbee",
                      "Source": "Driver\u0027s Licenses"
                    },
                    {
                      "DocumentIdentifier": "I2ebd2ea0235711dd86d5f687b7443f19",
                      "Source": "Experian Credit Header"
                    },
                    {
                      "DocumentIdentifier": "I7b6f39208de811df9b8c850332338889",
                      "Source": "Driver\u0027s Licenses"
                    },
                    {
                      "DocumentIdentifier": "I751d3ef1ce8511e398db8b09b4f043e0",
                      "Source": "Phone Record"
                    },
                    {
                      "DocumentIdentifier": "I7b4dd64000dc11e28b05fdf15589d8e8",
                      "Source": "E-mail Records"
                    }
                  ],
                  "LastConfirmed": "20200525",
                  "Source": [
                    "Utility Listing",
                    "Phone Record",
                    "TransUnion",
                    "Driver\u0027s Licenses",
                    "Experian Credit Header",
                    "E-mail Records"
                  ],
                  "State": "MN",
                  "Street": "9 7TH PL W APT 407",
                  "ZipCode": "55102"
                },
                {
                  "City": "SAINT PAUL",
                  "County": "DAKOTA",
                  "DocumentIdentifierInfo": [
                    {
                      "DocumentIdentifier": "I2ebd2ea0235711dd86d5f687b7443f19",
                      "Source": "Experian Credit Header"
                    },
                    {
                      "DocumentIdentifier": "I6e5d2da3cd6811e398db8b09b4f043e0",
                      "Source": "Phone Record"
                    },
                    {
                      "DocumentIdentifier": "I751d3ef1ce8511e398db8b09b4f043e0",
                      "Source": "Phone Record"
                    },
                    {
                      "DocumentIdentifier": "I97495601a66d11e598dc8b09b4f043e0",
                      "Source": "Utility Listing"
                    },
                    {
                      "DocumentIdentifier": "I7bc37d13235c11e498db8b09b4f043e0",
                      "Source": "TransUnion"
                    },
                    {
                      "DocumentIdentifier": "IA5A65BDEDF7211D5B69D0E00E20D7175",
                      "Source": "TransUnion"
                    },
                    {
                      "DocumentIdentifier": "I065271a2ce8711e398db8b09b4f043e0",
                      "Source": "Phone Record"
                    },
                    {
                      "DocumentIdentifier": "Id454487010e511eaadfea82903531a62",
                      "Source": "Phone Record"
                    }
                  ],
                  "LastConfirmed": "20170810",
                  "Source": [
                    "Experian Credit Header",
                    "Phone Record",
                    "Utility Listing",
                    "TransUnion"
                  ],
                  "State": "MN",
                  "Street": "1762 OAKDALE AV 311",
                  "ZipCode": "55118"
                },
                {
                  "City": "SAINT PAUL",
                  "County": "RAMSEY",
                  "DocumentIdentifierInfo": {
                    "DocumentIdentifier": "Ib0b773e01fa011e698dc8b09b4f043e0",
                    "Source": "Household Listing"
                  },
                  "LastConfirmed": "20151231",
                  "Source": "Household Listing",
                  "State": "MN",
                  "Street": "9 7TH PL W APT 400",
                  "ZipCode": "55102"
                },
                {
                  "City": "YOUNG AMERICA",
                  "County": "CARVER",
                  "DocumentIdentifierInfo": {
                    "DocumentIdentifier": "I2ebd2ea0235711dd86d5f687b7443f19",
                    "Source": "Experian Credit Header"
                  },
                  "LastConfirmed": "20100617",
                  "Source": "Experian Credit Header",
                  "State": "MN",
                  "Street": "9 7TH PL W # 407",
                  "ZipCode": "55555"
                },
                {
                  "City": "SAINT PAUL",
                  "County": "RAMSEY",
                  "DocumentIdentifierInfo": {
                    "DocumentIdentifier": "I2ebd2ea0235711dd86d5f687b7443f19",
                    "Source": "Experian Credit Header"
                  },
                  "LastConfirmed": "20030404",
                  "Source": "Experian Credit Header",
                  "State": "MN",
                  "Street": "904 RAYMOND AVE",
                  "ZipCode": "55114"
                }
              ]
            },
            "KnownEmailAddresses": {
              "KnownEmailAddress": {
                "DocumentIdentifierInfo": {
                  "DocumentIdentifier": "I7b4dd64000dc11e28b05fdf15589d8e8",
                  "Source": "E-mail Records"
                },
                "EmailAdress": "JON.KOWALSKI@GMAIL.COM",
                "Source": "E-mail Records"
              }
            },
            "MultipleSSNs": {
              "MultipleSSNsIndicator": "NO"
            },
            "OfacListing": {
              "OfacListingIndicator": "NO"
            },
            "RecordNumber": "1",
            "SSNMatchesMultipleIndividuals": {
              "SSNMatchesMultipleIndividualsIndicator": "NO"
            },
            "SearchRecords": {
              "SearchRecord": [
                {
                  "BirthDate": {
                    "#text": "1972",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "City": {
                    "#text": "YOUNG AMERICA",
                    "@matchscore": "42.41",
                    "@matchtype": "NO MATCH"
                  },
                  "ContentScore": "33.12",
                  "ContentSource": "Experian Credit Header",
                  "DocumentIdentifier": "I2ebd2ea0235711dd86d5f687b7443f19",
                  "FirstName": {
                    "#text": "JON",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "LastName": {
                    "#text": "KOWALSKI",
                    "@matchscore": "100.00",
                    "@matchtype": "MATCH"
                  },
                  "MiddleName": "C",
                  "PhoneNumber": "(651) 767-0671",
                  "SSN": {
                    "#text": "473-86-XXXX",
                    "@matchscore": "36.36",
                    "@matchtype": "NO MATCH"
                  },
                  "State": {
                    "#text": "MN",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "StreetName": {
                    "#text": "7TH PL W",
                    "@matchscore": "54.17",
                    "@matchtype": "NO MATCH"
                  },
                  "StreetNumber": {
                    "#text": "9",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "ZipCode": {
                    "#text": "55555",
                    "@matchscore": "100.00",
                    "@matchtype": "MATCH"
                  }
                },
                {
                  "City": {
                    "#text": "SAINT PAUL",
                    "@matchscore": "44.72",
                    "@matchtype": "NO MATCH"
                  },
                  "ContentScore": "31.05",
                  "ContentSource": "TransUnion",
                  "DocumentIdentifier": "I7bc37d13235c11e498db8b09b4f043e0",
                  "FirstName": {
                    "#text": "JON",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "LastName": {
                    "#text": "KOWALSKI",
                    "@matchscore": "100.00",
                    "@matchtype": "MATCH"
                  },
                  "MiddleName": "C",
                  "SSN": {
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "State": {
                    "#text": "MN",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "StreetName": {
                    "#text": "OAKDALE AVE",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "StreetNumber": {
                    "#text": "1762",
                    "@matchscore": "75.00",
                    "@matchtype": "PARTIAL MATCH"
                  },
                  "ZipCode": {
                    "#text": "55118",
                    "@matchscore": "40.00",
                    "@matchtype": "NO MATCH"
                  }
                },
                {
                  "City": {
                    "#text": "WEST SAINT PAUL",
                    "@matchscore": "50.56",
                    "@matchtype": "NO MATCH"
                  },
                  "ContentScore": "41.86",
                  "ContentSource": "Utility Listing",
                  "DocumentIdentifier": "I97495601a66d11e598dc8b09b4f043e0",
                  "FirstName": {
                    "#text": "JON",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "LastName": {
                    "#text": "KOWALSKI",
                    "@matchscore": "100.00",
                    "@matchtype": "MATCH"
                  },
                  "PhoneNumber": "(651) 968-6802",
                  "SSN": {
                    "#text": "473-86-XXXX",
                    "@matchscore": "36.36",
                    "@matchtype": "NO MATCH"
                  },
                  "State": {
                    "#text": "MN",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "StreetName": {
                    "#text": "OAKDALE AVE",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "StreetNumber": {
                    "#text": "1762",
                    "@matchscore": "75.00",
                    "@matchtype": "PARTIAL MATCH"
                  },
                  "ZipCode": {
                    "#text": "55118",
                    "@matchscore": "40.00",
                    "@matchtype": "NO MATCH"
                  }
                },
                {
                  "City": {
                    "#text": "SAINT PAUL",
                    "@matchscore": "44.72",
                    "@matchtype": "NO MATCH"
                  },
                  "ContentScore": "33.46",
                  "ContentSource": "E-mail Records",
                  "DocumentIdentifier": "I7b4dd64000dc11e28b05fdf15589d8e8",
                  "FirstName": {
                    "#text": "JON",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "LastName": {
                    "#text": "KOWALSKI",
                    "@matchscore": "100.00",
                    "@matchtype": "MATCH"
                  },
                  "State": {
                    "#text": "MN",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "StreetName": {
                    "#text": "7TH PL W 407",
                    "@matchscore": "52.78",
                    "@matchtype": "NO MATCH"
                  },
                  "StreetNumber": {
                    "#text": "9",
                    "@matchscore": "0.00",
                    "@matchtype": "NO MATCH"
                  },
                  "ZipCode": {
                    "#text": "55102",
                    "@matchscore": "40.00",
                    "@matchtype": "NO MATCH"
                  }
                }
              ]
            },
            "Summary": {
              "BirthDate": {
                "@matchscore": "0.00",
                "@matchtype": "NO MATCH"
              },
              "City": {
                "@matchscore": "50.56",
                "@matchtype": "NO MATCH"
              },
              "FirstName": {
                "@matchscore": "0.00",
                "@matchtype": "NO MATCH"
              },
              "LastName": {
                "@matchscore": "100.00",
                "@matchtype": "MATCH"
              },
              "SSN": {
                "@matchscore": "36.36",
                "@matchtype": "NO MATCH"
              },
              "State": {
                "@matchscore": "0.00",
                "@matchtype": "NO MATCH"
              },
              "StreetName": {
                "@matchscore": "54.17",
                "@matchtype": "NO MATCH"
              },
              "StreetNumber": {
                "@matchscore": "75.00",
                "@matchtype": "PARTIAL MATCH"
              },
              "ZipCode": {
                "@matchscore": "100.00",
                "@matchtype": "NO MATCH"
              }
            },
            "TotalScore": "35.29"
          }
        }
      }
    },
    "Status": {
      "StatusCode": "200",
      "SubStatusCode": "200"
    }
  }
}
 */
