import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { isUndefined } from 'util';

import { CardFormgroupMessageService } from '../shared/card-formgroup/card-formgroup-message.service';
import { AppState } from '../store/app.reducers';
import { ErrorLogger } from './../shared/services/error-logger/error-logger.service';
import {
  selectModelPartErrors,
  selectModelPartSaved,
  UpdateModelPart,
  UpdateModelPartError,
  UpdateModelPartMaps,
} from './store/supplier/supplier.actions';

@Injectable()
export abstract class RxEditBaseComponent implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  public formGroups: { [key: string]: FormGroup } = {};

  constructor(protected store: Store<AppState>, protected messageService: CardFormgroupMessageService, protected errorLogger: ErrorLogger) {
    this.initRxStoreSelects();
  }

  initRxStoreSelects() {
    this.store.select(selectModelPartSaved).subscribe((results) => {
      for (const propertyName in results) {
        if (results.hasOwnProperty(propertyName) && !isUndefined(this.formGroups[propertyName])) {
          this.messageService.broadcast(this.formGroups[propertyName], 'saveSuccess');
          this.store.dispatch(new UpdateModelPartMaps({ formGroupKey: propertyName, mapType: 'saved' }));
        }
      }
    });

    // TODO nkler: should not be a part of card-formgroup ?
    this.store.select(selectModelPartErrors).subscribe((results) => {
      for (const propertyName in results) {
        if (results.hasOwnProperty(propertyName) && !isUndefined(this.formGroups[propertyName])) {
          const errorResponse = results[propertyName];
          let responseErrors;

          if (errorResponse instanceof HttpErrorResponse && !isUndefined(errorResponse.error)) {
            responseErrors = errorResponse.error;
          }

          this.messageService.broadcast(this.formGroups[propertyName], 'saveError', responseErrors);
          this.store.dispatch(new UpdateModelPartMaps({ formGroupKey: propertyName, mapType: 'errors' }));
        }
      }
    });
  }

  protected abstract resolveServiceClassBy(formGroupKey: string): string;
  protected abstract resolveParamsBy(formGroupKey: string): any[];
  protected abstract getSupplierKey(): string;

  saveSingleField(name, value, formGroupKey, customMapper = null) {
    const itemPart = {};
    itemPart[name] = value;
    this.storeData(
      this.getSupplierKey(),
      this.resolveParamsBy(formGroupKey),
      itemPart,
      true,
      this.resolveServiceClassBy(formGroupKey),
      formGroupKey,
      null,
      customMapper,
    );
  }

  saveSingleFieldPercentage(name, value, formGroupKey, customMapper = null) {
    const itemPart = {};
    const newValue = +value / 100;
    itemPart[name] = newValue;
    this.storeData(
      this.getSupplierKey(),
      this.resolveParamsBy(formGroupKey),
      itemPart,
      true,
      this.resolveServiceClassBy(formGroupKey),
      formGroupKey,
      null,
      customMapper,
    );
  }

  saveExpectedMPPayDelay(name, value, formGroupKey, customMapper = null) {
    const itemPart = {};
    itemPart[name] = +value;

    this.storeData(
      this.getSupplierKey(),
      this.resolveParamsBy(formGroupKey),
      itemPart,
      true,
      this.resolveServiceClassBy(formGroupKey),
      formGroupKey,
      null,
      customMapper,
    );
  }

  saveFormGroup({ value, valid }: { value: any; valid: boolean }, formGroupKey, customMapper = null) {
    console.log('-----------saveFormGroup--------')
    this.storeData(
      this.getSupplierKey(),
      this.resolveParamsBy(formGroupKey),
      value,
      valid,
      this.resolveServiceClassBy(formGroupKey),
      formGroupKey,
      null,
      customMapper,
    );
  }

  saveFundingFormGroup({ value, valid }: { value: any; valid: boolean }, formGroupKey, customMapper = null) {
    value.advanceRate = value.advanceRate / 100;
    value.minimumReservePct = value.minimumReservePct / 100;

    this.storeData(
      this.getSupplierKey(),
      this.resolveParamsBy(formGroupKey),
      value,
      valid,
      this.resolveServiceClassBy(formGroupKey),
      formGroupKey,
      null,
      customMapper,
    );
  }

  protected prepareRequestPartData(formGroupKey, partData) {
    return partData;
  }

  protected storeData(
    supplierKey,
    modelParams,
    partData,
    isValid,
    serviceClassName,
    formGroupKey,
    propertyName = null,
    customMapper = null,
  ) {
    if (isValid) {
      partData = this.prepareRequestPartData(formGroupKey, partData);

      if (customMapper) {
        partData = { ...customMapper(partData) };
      }

      if (!partData || (partData && Object.keys(partData).length === 0)) {
        const errorTitle = 'EMPTY UPDATE REQUEST PAYLOAD';

        this.errorLogger.log(errorTitle, {
          supplierKey,
          serviceClassName,
          newValues: partData,
          formGroupKey,
          propertyName,
          modelParams: [...modelParams],
        });

        this.store.dispatch(new UpdateModelPartError({ supplierKey, newValues: partData, formGroupKey, error: errorTitle }));
        return;
      }

      this.store.dispatch(
        new UpdateModelPart({
          supplierKey,
          serviceClassName,
          newValues: partData,
          formGroupKey,
          propertyName,
          modelParams: [...modelParams],
        }),
      );
    } else {
      throw new Error('INVALID FORM GROUP');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => sub.unsubscribe());
  }
}
