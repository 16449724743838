<div
  class="card"
  [ngClass]="{
    'ui-ribbon-container hidden-supplier ui-ribbon-danger': supplierDetailsModel && supplierDetailsModel.hide
  }"
>
  <div *ngIf="supplierDetailsModel && supplierDetailsModel.hide" class="ui-ribbon-wrapper">
    <div class="ui-ribbon">HIDDEN</div>
  </div>

  <div class="card-content" *ngIf="loaded; let supplierInfo">
    <div class="top-box">
      <form
        *ngIf="formGroups[CONTACT_NAME_FORM]; else loadingFrom; let contactNameForm"
        app-card-formgroup
        novalidate
        [isGroupEditable]="false"
        #formGroupContactName
        class="supplier-contact-name"
        [formGroup]="contactNameForm"
      >
        <div class="icon-box">
          <div class="ibox-icon">
            <a><i class="fa fa-user" aria-hidden="true"></i></a>
          </div>
          <div class="title">{{ contactNameForm.value['contactFirstName'] }} {{ contactNameForm.value['contactLastName'] }}</div>
          <div class="subtitle">
            {{ contactNameForm.get('contactRole').value }}
          </div>
          <div class="d-flex">
            <div class="fake-field w-50">
              <div class="label">&nbsp;</div>
              <div class="value">
                <span *ngIf="supplierSite; else noSupplierSite">
                  {{ supplierSite }}
                </span>
                <ng-template #noSupplierSite> &nbsp; </ng-template>
              </div>
            </div>

            <div class="w-50 fake-field" *ngIf="supplierInfo.onboardingTemplateCode">
              <div class="label">Onboarding Template</div>
              <span class="value value_simple">
                <span class="badge badge-light" [ngClass]="supplierStatusClass">
                  {{ supplierInfo.onboardingTemplateCode }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
