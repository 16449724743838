<h1 mat-dialog-title class="text-center">UCC filings founds {{ uccData?.UCCFilings?.TLOUCCFiling?.length || '-' }}</h1>
<div mat-dialog-content>
  <div *ngFor="let ucc_filing of uccData?.UCCFilings?.TLOUCCFiling; let $index = index">
    <h4>{{ $index + 1 }}?. UCC Report summary</h4>

    <div class="row">
      <div class="col-xs-6">
        <strong>Filing number:</strong>
      </div>
      <div class="col-xs-6">
        {{ ucc_filing?.FilingNumber || '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <strong>Filing type:</strong>
      </div>
      <div class="col-xs-6">
        {{ ucc_filing?.FilingType || '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <strong>Filing Office name:</strong>
      </div>
      <div class="col-xs-6">
        {{ ucc_filing?.FilingOfficeName || '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <strong>Filing Office City:</strong>
      </div>
      <div class="col-xs-6">
        {{ ucc_filing?.FilingOfficeAddress?.City || '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <strong>Filing Office County:</strong>
      </div>
      <div class="col-xs-6">
        {{ ucc_filing?.FilingOfficeAddress?.County || '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <strong>Filing Office Line:</strong>
      </div>
      <div class="col-xs-6">
        {{ ucc_filing?.FilingOfficeAddress?.Line1 || '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <strong>Filing Office State:</strong>
      </div>
      <div class="col-xs-6">
        {{ ucc_filing?.FilingOfficeAddress?.State || '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <strong>Filing Office Zip:</strong>
      </div>
      <div class="col-xs-6">
        {{ ucc_filing?.FilingOfficeAddress?.Zip || '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <strong>Filing date:</strong>
      </div>
      <div class="col-xs-6" *ngIf="ucc_filing?.FilingDate?.Day">
        {{ ucc_filing?.FilingDate?.Day + '/' + ucc_filing?.FilingDate?.Month + '/' + ucc_filing?.FilingDate?.Year }}
      </div>
      <div class="col-xs-6" *ngIf="!ucc_filing?.FilingDate?.Day">-</div>
    </div>

    <hr />

    <h4 *ngIf="ucc_filing?.CollateralItems?.UCCCollateralItem?.length">
      Collateral Items ({{ ucc_filing?.CollateralItems?.UCCCollateralItem?.length || '-' }})
    </h4>
    <div *ngFor="let collateral of ucc_filing?.CollateralItems?.UCCCollateralItem">
      <div class="row">
        <div class="col-xs-6">
          <strong> Type: </strong>
        </div>
        <div class="col-xs-6">
          {{ collateral['Type'] || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong> Quantity: </strong>
        </div>
        <div class="col-xs-6">
          {{ collateral['Quantity'] || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong> New or used: </strong>
        </div>
        <div class="col-xs-6">
          {{ collateral['NewOrUsed'] || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong> Model: </strong>
        </div>
        <div class="col-xs-6">
          {{ collateral['Model'] || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong> Model year: </strong>
        </div>
        <div class="col-xs-6">
          {{ collateral['ModelYear'] || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong> Manufacturer: </strong>
        </div>
        <div class="col-xs-6">
          {{ collateral['Manufacturer'] || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong> MachineryClassification: </strong>
        </div>
        <div class="col-xs-6">
          {{ collateral['MachineryClassification'] || '-' }}
        </div>
      </div>
      <hr />
    </div>

    <h4 *ngIf="ucc_filing?.Debtors?.TLOUCCDebtor?.length">UCC Debtors summary ({{ ucc_filing?.Debtors?.TLOUCCDebtor?.length || '-' }})</h4>
    <div *ngFor="let debtor of ucc_filing?.Debtors?.TLOUCCDebtor">
      <div class="row">
        <div class="col-xs-6">
          <strong> Name: </strong>
        </div>
        <div class="col-xs-6">
          {{ debtor['Name'] || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong> Business name: </strong>
        </div>
        <div class="col-xs-6">
          {{ debtor['BusinessName'] || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>City:</strong>
        </div>
        <div class="col-xs-6">
          {{ debtor?.Address?.City || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>County:</strong>
        </div>
        <div class="col-xs-6">
          {{ debtor?.Address?.County || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Line:</strong>
        </div>
        <div class="col-xs-6">
          {{ debtor?.Address?.Line1 || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>State:</strong>
        </div>
        <div class="col-xs-6">
          {{ debtor?.Address?.State || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Zip:</strong>
        </div>
        <div class="col-xs-6">
          {{ debtor?.Address?.Zip || '-' }}
        </div>
      </div>
      <hr />

      <h4 *ngIf="ucc_filing?.SecuredParties?.TLOUCCSecuredParty?.length">
        Secured Parties summary ({{ ucc_filing?.SecuredParties?.TLOUCCSecuredParty?.length || '-' }})
      </h4>

      <div *ngFor="let secure of ucc_filing?.SecuredParties?.TLOUCCSecuredParty">
        <div class="row">
          <div class="col-xs-6">
            <strong> Name: </strong>
          </div>
          <div class="col-xs-6">
            {{ secure['Name'] || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <strong> Business name: </strong>
          </div>
          <div class="col-xs-6">
            {{ secure['BusinessName'] || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <strong> FEI number: </strong>
          </div>
          <div class="col-xs-6">
            {{ secure['FEINumber'] || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <strong>City:</strong>
          </div>
          <div class="col-xs-6">
            {{ secure?.Address?.City || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <strong>County:</strong>
          </div>
          <div class="col-xs-6">
            {{ secure?.Address?.County || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <strong>Line:</strong>
          </div>
          <div class="col-xs-6">
            {{ secure?.Address?.Line1 || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <strong>State:</strong>
          </div>
          <div class="col-xs-6">
            {{ secure?.Address?.State || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <strong>Zip:</strong>
          </div>
          <div class="col-xs-6">
            {{ secure?.Address?.Zip || '-' }}
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</div>
