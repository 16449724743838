import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { CRM_OPEN_ALL_TAB, TableEventsService } from '../../shared/table-events.service';
import { AppState } from '../../store/app.reducers';
import { selectCrmItems } from '../store/crm.actions';
import { CrmItemCollection } from '../store/crm.reducers';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { selectSupplierDetails } from './../../suppliers/store/supplier/supplier.actions';
import { ActionTabAllComponent } from './action-tabs/action-tab-all/action-tab-all.component';
import { ActionTabBatchEmailsComponent } from './action-tabs/action-tab-emails/action-tab-batch-emails.component';
import { ActionTabEmailsComponent } from './action-tabs/action-tab-emails/action-tab-emails.component';
import { ActionTabEventsComponent } from './action-tabs/action-tab-events/action-tab-events.component';
import { ActionTabNotesComponent } from './action-tabs/action-tab-notes/action-tab-notes.component';
import { ActionTabTasksComponent } from './action-tabs/action-tab-tasks/action-tab-tasks.component';
import { ActionTabCalculatorComponent } from './action-tabs/action-tab-calculator/action-tab-calculator.component';

@Component({
  selector: 'app-actions-list',
  templateUrl: './actions-list.component.html',
  styleUrls: ['./actions-list.component.scss'],
})
export class ActionsListComponent extends SubscriberComponent implements OnInit {
  @ViewChild(ActionTabNotesComponent, { static: true }) notesTab: ActionTabNotesComponent;
  @ViewChild(ActionTabAllComponent, { static: true }) allTab: ActionTabAllComponent;
  @ViewChild(ActionTabTasksComponent, { static: true }) tasksTab: ActionTabTasksComponent;
  @ViewChild(ActionTabEventsComponent, { static: true }) eventsTab: ActionTabEventsComponent;
  @ViewChild(ActionTabEmailsComponent, { static: true }) emailsTab: ActionTabEmailsComponent;
  @ViewChild(ActionTabBatchEmailsComponent, { static: true }) batchEmailsTab: ActionTabBatchEmailsComponent;
  @ViewChild(ActionTabCalculatorComponent, { static: true }) calculatorTab: ActionTabCalculatorComponent;

  tabs = [];
  crmItems: CrmItemCollection;
  tabIndex = 0;

  constructor(private store: Store<AppState>, private tableEventService: TableEventsService, private cd: ChangeDetectorRef) {
    super();
    this.tabs = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectCrmItems).subscribe((data) => {
        this.crmItems = data;
        this.tableEventService.on(CRM_OPEN_ALL_TAB, () => {
          this.loadTab({ index: 0 });
        });
      }),
    );
    this.tabs = [
      { component: this.allTab },
      { component: this.notesTab },
      { component: this.tasksTab },
      { component: this.eventsTab },
      { component: this.emailsTab },
      { component: this.batchEmailsTab },
      { component: this.calculatorTab },
    ];
    this.loadTab({ index: 0 });

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((supplier) => !supplier))
        .subscribe(() => {
          this.tabIndex = 0;
          this.tabs[this.tabIndex].component.itemsLoaded = -1;
          this.tabs[this.tabIndex].component.uuids = [];
          this.tabs[this.tabIndex].component.loaded = false;
          this.tabs[this.tabIndex].component.pending = false;
          this.tabs[this.tabIndex].component.uuids = [];
          this.cd.detectChanges();
        }),
    );
  }

  loadTab($event) {
    this.cd.detectChanges();
    this.tabs[$event.index].component.fetchItems();
  }
}
