import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ApprovalNotesModalComponent } from './balance-approval/approval-notes-modal/approval-notes-modal.component';
import { BalanceApprovalComponent } from './balance-approval/balance-approval.component';
import { BalanceApprovalService } from './balance-approval/balance-approval.service';
import { DescriptionModalComponent } from './balance-approval/description-modal/description-modal.component';
import { PendingEditComponent } from './pending-edit/pending-edit.component';
import { ProcessAddComponent } from './process-add/process-add.component';
import { ProcessBatchesComponent } from './process-batches/process-batches.component';
import { ProcessEditComponent } from './process-edit/process-edit.component';
import { ProcessListComponent } from './process-list/process-list.component';
import { PaymentBatchDownloaderService } from './service/payment-batch-downloader.service';
import { PaymentBatchDownloaderAbstractService } from './service/payment-batch-downloader-abstract.service';
import { PaymentBatchRepositoryService } from './service/payment-batch-repository-service';
import { PaymentBatchesAbstractService } from './service/payment-batches-abstract-service';
import { PendingReviewService } from './service/pending-review.service';

@NgModule({
  imports: [SharedModule, HttpClientModule],
  declarations: [
    ProcessListComponent,
    ProcessBatchesComponent,
    ProcessEditComponent,
    ProcessAddComponent,
    PendingEditComponent,
    BalanceApprovalComponent,
    DescriptionModalComponent,
    ApprovalNotesModalComponent,
  ],
  providers: [
    { provide: PaymentBatchesAbstractService, useClass: PaymentBatchRepositoryService },
    { provide: PendingReviewService, useClass: PendingReviewService },
    { provide: PaymentBatchDownloaderAbstractService, useClass: PaymentBatchDownloaderService },
    BalanceApprovalService,
  ],
  entryComponents: [DescriptionModalComponent, ApprovalNotesModalComponent],
})
export class ProcessesModule {}
