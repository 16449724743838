<div [formGroup]="emailFrontendTemplateForm">
  <mat-form-field class="full-width">
    <input matInput placeholder="Template name" formControlName="template_name" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Description" formControlName="description" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Subject" formControlName="subject" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <app-editor formControlName="body"> </app-editor>
</div>
