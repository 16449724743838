<ng-template #loadingFilters>
  <div class="form-content">
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </div>
</ng-template>

<div class="filters-containter" *ngIf="filtersOptions; else loadingFilters">
  <h5 data-test="tasksFiltersHeader">Filters</h5>

  <button
    [disabled]="countersLoadingInProgress"
    (click)="refreshFiltersCounts()"
    mat-icon-button
    class="btn-refresh-counters"
    data-test="tasksFiltersRefresh"
  >
    <mat-icon [ngClass]="{ 'fa-spin': countersLoadingInProgress }" class="material-icons">autorenew</mat-icon>
  </button>

  <ul class="grid-filters">
    <li
      *ngFor="let filterItem of filtersOptions.predefined; let filterId = index"
      [ngClass]="{ active: equals(filterUiModel, filterItem.filters) }"
    >
      <a
        [routerLink]="['/tasks/list', filterId, 'filter']"
        [ngClass]="{ group: filterItem.isGroup }"
        (click)="triggerSubfilters(filterItem)"
      >
        <i class="material-icons" *ngIf="filterItem.subFilters">
          {{ filterItem.subFiltersVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
        </i>
        <span class="filter-name" title="{{ filterItem.title }}">{{ filterItem.title }}</span>
        <span class="filter-items-amount" *ngIf="filterItem.count !== undefined"> ({{ filterItem.count }}) </span>
        <span class="filter-member-count" *ngIf="filterItem.subFilters && filterItem.subFilters.length && filterItem.subFiltersVisible">
          (Members: {{ filterItem.membersCount }})
        </span>
      </a>

      <ul class="grid-filters grid-filters-member" *ngIf="filterItem.subFilters" [hidden]="!filterItem.subFiltersVisible">
        <li *ngFor="let filterSubItem of filterItem.subFilters" [ngClass]="{ active: equals(filterUiModel, filterSubItem.filters) }">
          <a (click)="pickFilter(filterSubItem)">
            <span class="filter-name" title="{{ filterSubItem.title }}">{{ filterSubItem.title }}</span>
            <span class="filter-items-amount" *ngIf="filterSubItem.count !== undefined"> ({{ filterSubItem.count }}) </span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
