<div class="inlineNumber">
  <span *ngIf="inPresentationMode(); else inputEditMode">
    {{ _value }}
  </span>

  <ng-template #inputEditMode>
    <mat-form-field class="inlineNumber__input">
      <input
        matInput
        type="Number"
        min="{{ min }}"
        max="{{ max }}"
        placeholder="{{ label }}"
        [(ngModel)]="_value"
        (blur)="onBlur()"
        (keydown)="onKeydown($event)"
      />
    </mat-form-field>
  </ng-template>
  <ng-content></ng-content>

  <mat-icon class="mat-24" aria-label="Edit" class="inlineNumber__editIcon" (click)="toggleEditMode()">edit</mat-icon>
</div>
