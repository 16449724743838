/* eslint-disable */
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManualAdvanceOfferInputsComponent } from '../pending-offer/pending-offer-calculator/pending-offer-progressive-details/instant-advance-underwriting/manual-advance-offer-inputs/manual-advance-offer-inputs.component';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { SupplierAdvancesService } from '../service/supplier-advances.service';
import { ToastrService } from 'ngx-toastr';
import { CalculatorState } from '../pending-offer/pending-offer-calculator/store/calculator-interfaces';
import { isExpectedMonthlyEarningsValid } from '../../../utils/is-expected-montly-earnings-valid';
import { isExpectedDurationValid } from '../../../utils/is-expected-duration-valid';
/* eslint-enable */

@Component({
  selector: 'app-advance-offer-inputs-modal',
  templateUrl: './advance-offer-inputs-modal.component.html',
  styleUrls: ['./advance-offer-inputs-modal.component.scss'],
})
export class AdvanceOfferInputsModalComponent extends SubscriberComponent {
  @ViewChild(ManualAdvanceOfferInputsComponent, { static: true })
  manualAdvanceOfferInputsComponent: ManualAdvanceOfferInputsComponent;

  supplierKey = '';
  advanceLineKey = '';
  calcValues: CalculatorState;
  isSaveEnabled: boolean;

  constructor(
    public dialogRef: MatDialogRef<AdvanceOfferInputsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store<AppState>,
    private supplierAdvancesService: SupplierAdvancesService,
    private toastr: ToastrService,
  ) {
    super();
    this.setData(data);
    this.subscribeOnCalcState();
  }

  setData(data: any) {
    this.supplierKey = data.supplierKey;
    this.advanceLineKey = data && data.setting && data.setting.advance_line_key ? data.setting.advance_line_key : '';
  }

  subscribeOnCalcState() {
    this.subscriptions.push(
      this.store.select('calc').subscribe((calcValues) => {
        this.calcValues = calcValues;
        this.checkSaveButtonStatus();
      }),
    );
  }

  checkSaveButtonStatus() {
    this.isSaveEnabled = this.getSaveButtonEnabledStatus();
  }

  getSaveButtonEnabledStatus() {
    const state = this.calcValues;
    if (state.isTotalPeriodToLong) {
      return false;
    }
    if (state.isTotalFeeIncorrect) {
      return false;
    }
    if (!state.approvedAmount) {
      return false;
    }
    if (state.isEmptyFee) {
      return false;
    }
    if (state.isEmptyPeriodLength) {
      return false;
    }
    if (!state.uwStatus) {
      return false;
    }
    if (!isExpectedMonthlyEarningsValid(state.expectedMonthlyEarnings)) {
      return false;
    }
    if (!isExpectedDurationValid(state.expectedDuration, state.totalPeriodLength)) {
      return false;
    }
    return true;
  }

  close(configs?: any): void {
    this.dialogRef.close(configs);
  }

  onSaveClick() {
    this.updateAdvanceLine();
  }

  updateAdvanceLine() {
    if (!this.supplierKey || !this.advanceLineKey || !this.calcValues) {
      return;
    }
    this.subscriptions.push(
      this.supplierAdvancesService.updateAdvanceLine(this.supplierKey, this.advanceLineKey, this.calcValues).subscribe(
        (data) => {
          this.toastr.success('Advance line was updated successfully');
          this.close({ needTableReloading: true });
          return data;
        },
        (err: any) => {
          this.close();
        },
      ),
    );
  }
}
