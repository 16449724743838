<div class="data-table-ag-grid" *ngIf="reportName">
  <div class="upper-container">
    <div class="data-table-ag-grid__header">
      {{ reportTitle }}
    </div>
    <div class="topContainer d-flex mb-5">
      <ng-content select="[top-container-before]"></ng-content>
      <button mat-raised-button style="margin-right: 5px" color="primary" (click)="onBtnExportCurrent()">Export Current Data</button>
      <ng-content select="[top-container-after]"></ng-content>
    </div>
  </div>
  <app-filtering-panel-component (searchValueEmitter)="loadData($event)" [configuration]="filterConfig"></app-filtering-panel-component>
  <ag-grid-angular
    style="width: 100%; height: 90%"
    #agGrid
    id="myGrid"
    class="ag-theme-balham"
    [gridOptions]="gridOptions"
  ></ag-grid-angular>
</div>

<app-spinner *ngIf="loading" [forceShow]="true"></app-spinner>
