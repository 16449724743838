import { Injectable } from '@angular/core';
import { ErrorHandler, isDevMode } from '@angular/core';
import { isUndefined } from 'util';

import { AppConfigService } from '../config';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private appConfig: AppConfigService) {}

  public handleError(error: any) {
    if (isDevMode()) {
      this.handleErrorDev(error);
    } else {
      let displayErrors = false;
      if (!isUndefined(this.appConfig.env) && !isUndefined(this.appConfig.env.displayErrorsInConsole)) {
        displayErrors = <boolean>this.appConfig.env.displayErrorsInConsole;
      }

      let showErrorPopup = false;
      if (!isUndefined(this.appConfig.env) && !isUndefined(this.appConfig.env.displayErrorsPopup)) {
        showErrorPopup = <boolean>this.appConfig.env.displayErrorsPopup;
      }

      this.handleErrorProd(error, displayErrors, showErrorPopup);
    }

    return;
  }

  protected displayConsoleMessage(error, displayErr: boolean, useGroups: boolean = true) {
    if (displayErr) {
      if (useGroups) {
        console.groupCollapsed('Whoops, something went wrong.');
      }
      console.error('Error', error);
      if (useGroups) {
        console.groupEnd();
      }
    } else {
      console.warn('Check sentry logs or run in dev mode.');
    }
  }

  protected handleErrorDev(error: any) {
    this.displayConsoleMessage(error, true);
    throw error;
  }

  protected handleErrorProd(error: any, displayConsoleErrors = false, displayErrorPopup = false) {
    this.displayConsoleMessage(error, displayConsoleErrors, false);
  }
}

export default AppErrorHandler;
