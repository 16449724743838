import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

import {
  BLOCK_SIZE,
  COLORS,
  COLORSDARKER,
  COLORSLIGHTER,
  COLS,
  IChiaroscuro,
  IEdge,
  IRect,
  IShading,
  KEY,
  LEVEL,
  LINES_PER_LEVEL,
  MESSAGE_X,
  MESSAGE_Y,
  POINTS,
  PSEUDO_3D,
  ROWS,
  SHADOW_SIZE,
  TETRIMINOS_SIZE,
} from '../constants';
import { GameService } from '../game.service';
import { IPiece, Piece } from '../piece/piece.component';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
})
export class BoardComponent implements OnInit {
  @ViewChild('board', { static: true }) canvas: ElementRef;
  @ViewChild('next', { static: true }) canvasNext: ElementRef;
  ctx: CanvasRenderingContext2D;
  ctxNext: CanvasRenderingContext2D;
  board: number[][];
  piece: Piece;
  next: Piece;
  requestId: number;
  paused: boolean;
  gameStarted: boolean;
  time: { start: number; elapsed: number; level: number };
  points: number;
  pointsMultiplier = 1;
  highScore: number;
  lines: number;
  level: number;
  moves = {
    [KEY.LEFT]: (p: IPiece): IPiece => ({ ...p, x: p.x - 1 }),
    [KEY.RIGHT]: (p: IPiece): IPiece => ({ ...p, x: p.x + 1 }),
    [KEY.DOWN]: (p: IPiece): IPiece => ({ ...p, y: p.y + 1 }),
    [KEY.SPACE]: (p: IPiece): IPiece => ({ ...p, y: p.y + 1 }),
    [KEY.A]: (p: IPiece): IPiece => ({ ...p, x: p.x - 1 }),
    [KEY.D]: (p: IPiece): IPiece => ({ ...p, x: p.x + 1 }),
    [KEY.S]: (p: IPiece): IPiece => ({ ...p, y: p.y + 1 }),
    [KEY.SPACE]: (p: IPiece): IPiece => ({ ...p, y: p.y + 1 }),
    [KEY.UP]: (p: IPiece): IPiece => this.service.rotate(p),
    [KEY.W]: (p: IPiece): IPiece => this.service.rotate(p),
  };

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY.ESC) {
      return this.gameOver();
    }

    if (this.moves[event.keyCode]) {
      event.preventDefault();
      let p = this.moves[event.keyCode](this.piece);
      if (event.keyCode === KEY.SPACE) {
        while (this.service.valid(p, this.board)) {
          this.addPoints(POINTS.HARD_DROP);
          this.piece.move(p);
          p = this.moves[KEY.DOWN](this.piece);
        }
        return;
      }

      if (this.service.valid(p, this.board)) {
        this.piece.move(p);
        if (event.keyCode === KEY.DOWN) {
          this.points += POINTS.SOFT_DROP;
        }
      }
    }
  }

  constructor(private service: GameService) {}

  ngOnInit() {
    this.initBoard();
    this.initNext();
    this.resetGame();
    this.resetHighscore();
  }

  addPoints(count: number) {
    this.points += count * this.pointsMultiplier;
  }

  initBoard() {
    this.createMainGameCanvas();
    this.setMainGameCanvasSize();
    this.scaleMainCanvas();
  }

  initNext() {
    this.createNextTetriminosCanvas();
    this.setNextTetriminosCanvasSize();
    this.scaleNextTetriminosCanvas();
  }

  resetHighscore() {
    this.highScore = 0;
  }

  createMainGameCanvas() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
  }

  createNextTetriminosCanvas() {
    this.ctxNext = this.canvasNext.nativeElement.getContext('2d');
  }

  setMainGameCanvasSize() {
    this.ctx.canvas.width = COLS * BLOCK_SIZE;
    this.ctx.canvas.height = ROWS * BLOCK_SIZE;
  }

  setNextTetriminosCanvasSize() {
    this.ctxNext.canvas.width = TETRIMINOS_SIZE * BLOCK_SIZE + SHADOW_SIZE;
    this.ctxNext.canvas.height = TETRIMINOS_SIZE * BLOCK_SIZE;
  }

  scaleMainCanvas() {
    this.ctx.scale(BLOCK_SIZE, BLOCK_SIZE);
  }

  scaleNextTetriminosCanvas() {
    this.ctxNext.scale(BLOCK_SIZE, BLOCK_SIZE);
  }

  play() {
    this.gameStarted = true;
    this.resetGame();
    this.next = new Piece(this.ctx, this.add3D.bind(this));
    this.piece = new Piece(this.ctx, this.add3D.bind(this));
    this.next.drawNext(this.ctxNext);
    this.time.start = performance.now();

    this.cancelOldGameIfNeeded();

    this.animate();
  }

  cancelOldGameIfNeeded() {
    if (this.requestId) {
      cancelAnimationFrame(this.requestId);
    }
  }

  resetGame() {
    this.pointsMultiplier = 1;
    this.points = 0;
    this.lines = 0;
    this.level = 0;
    this.board = this.getEmptyBoard();
    this.time = { start: 0, elapsed: 0, level: LEVEL[this.level] };
    this.paused = false;
    this.addOutlines();
  }

  animate(now = 0) {
    this.time.elapsed = now - this.time.start;
    if (this.time.elapsed > this.time.level) {
      this.time.start = now;
      if (!this.drop()) {
        this.gameOver();
        return;
      }
    }
    this.draw();
    this.requestId = requestAnimationFrame(this.animate.bind(this));
  }

  draw() {
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    this.piece.draw();
    this.drawBoard();
  }

  drop(): boolean {
    const p = this.moves[KEY.DOWN](this.piece);
    if (this.service.valid(p, this.board)) {
      this.piece.move(p);
    } else {
      this.freeze();
      this.clearLines();
      if (this.piece.y === 0) {
        return false;
      }
      this.piece = this.next;
      this.next = new Piece(this.ctx, this.add3D.bind(this));
      this.next.drawNext(this.ctxNext);
    }
    return true;
  }

  clearLines() {
    let lines = 0;
    this.board.forEach((row, y) => {
      if (row.every((value) => value !== 0)) {
        lines++;
        this.board.splice(y, 1);
        this.board.unshift(Array(COLS).fill(0));
      }
    });
    if (lines <= 0) {
      this.pointsMultiplier = this.pointsMultiplier <= 2 ? 1 : this.pointsMultiplier - 1;
      return;
    }
    this.addPoints(this.service.getLinesClearedPoints(lines, this.level));
    this.lines += lines;
    this.pointsMultiplier += lines * lines;
    if (this.lines < LINES_PER_LEVEL) {
      return;
    }
    this.levelUp();
  }

  levelUp() {
    this.level++;
    this.lines -= LINES_PER_LEVEL;
    this.time.level = LEVEL[this.level];
  }

  freeze() {
    this.piece.shape.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value > 0) {
          this.board[y + this.piece.y][x + this.piece.x] = value;
        }
      });
    });
  }

  private add3D(ctx: CanvasRenderingContext2D, x: number, y: number, color: number): void {
    this.addLights(ctx, PSEUDO_3D, x, y, color);
    this.addShadows(ctx, PSEUDO_3D, x, y, color);
  }

  private addLights(ctx: CanvasRenderingContext2D, shading: IShading, x: number, y: number, color: number): void {
    ctx.fillStyle = COLORSLIGHTER[color];
    this.drawChiaroscuro(ctx, PSEUDO_3D.light, x, y);
  }

  private addShadows(ctx: CanvasRenderingContext2D, shading: IShading, x: number, y: number, color: number): void {
    ctx.fillStyle = COLORSDARKER[color];
    this.drawChiaroscuro(ctx, PSEUDO_3D.shadow, x, y);
  }

  private drawChiaroscuro(ctx: CanvasRenderingContext2D, chiaroscuro: IChiaroscuro, x: number, y: number): void {
    this.drawEdge(ctx, chiaroscuro.inside, x, y);
    this.drawEdge(ctx, chiaroscuro.outside, x, y);
  }

  private drawEdge(ctx: CanvasRenderingContext2D, edge: IEdge, x: number, y: number): void {
    this.drawRect(ctx, edge.horizontal, x, y);
    this.drawRect(ctx, edge.vertical, x, y);
  }

  private drawRect(ctx: CanvasRenderingContext2D, rectParams: IRect, x: number, y: number): void {
    ctx.fillRect(x + rectParams.x, y + rectParams.y, rectParams.width, rectParams.height);
  }

  private addOutlines() {
    for (let index = 1; index < COLS; index++) {
      this.ctx.fillStyle = 'black';
      this.ctx.fillRect(index, 0, 0.025, this.ctx.canvas.height);
    }

    for (let index = 1; index < ROWS; index++) {
      this.ctx.fillStyle = 'black';
      this.ctx.fillRect(0, index, this.ctx.canvas.width, 0.025);
    }
  }

  drawBoard() {
    this.board.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value > 0) {
          this.ctx.fillStyle = COLORS[value];
          this.ctx.fillRect(x, y, 1, 1);
          this.add3D(this.ctx, x, y, value);
        }
      });
    });
    this.addOutlines();
  }

  pause() {
    if (this.gameStarted) {
      if (this.paused) {
        this.animate();
      } else {
        this.ctx.font = '1px Arial';
        this.ctx.fillStyle = 'black';
        this.ctx.fillText('GAME PAUSED', MESSAGE_X, MESSAGE_Y);
        cancelAnimationFrame(this.requestId);
      }

      this.paused = !this.paused;
    }
  }

  reset() {
    this.gameOver();
  }

  gameOver() {
    this.gameStarted = false;
    cancelAnimationFrame(this.requestId);
    this.highScoreCalculate();
    this.gameOverMessage();
  }

  highScoreCalculate() {
    this.highScore = this.points > this.highScore ? this.points : this.highScore;
  }

  gameOverMessage() {
    this.ctx.fillStyle = 'black';
    this.ctx.fillRect(1, MESSAGE_Y - 1, 8, 1.2);
    this.ctx.font = '1px Arial';
    this.ctx.fillStyle = 'red';
    this.ctx.fillText('GAME OVER', MESSAGE_X + 0.4, MESSAGE_Y);
  }

  getEmptyBoard(): number[][] {
    return Array.from({ length: ROWS }, () => Array(COLS).fill(0));
  }
}
