<mat-card class="onboardingProducts__card">
  <mat-card-content>
    <h5>Products</h5>

    <form class="onboardingProducts__card__form" [formGroup]="productsForm">
      <mat-checkbox formControlName="iAccess">Instant Access</mat-checkbox>

      <mat-checkbox formControlName="iAdvance">Instant Advance</mat-checkbox>

      <mat-checkbox formControlName="iTransfer">Instant Transfer</mat-checkbox>

      <mat-checkbox formControlName="weeklyPayments">Weekly Payments</mat-checkbox>

      <mat-checkbox formControlName="pybSellerCard">Payability Seller Card</mat-checkbox>

      <mat-checkbox formControlName="iPayout">Instant Payout</mat-checkbox>

      <mat-checkbox formControlName="openInvoices">Open Invoices</mat-checkbox>
    </form>
  </mat-card-content>
</mat-card>
