export enum UW_TOOL_STATUS {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  VERIFIED_ACCESS = 'VERIFIED_ACCESS',
  VERIFIED_ADVANCE = 'VERIFIED_ADVANCE',
  VERIFIED_ACCESS_AND_ADVANCE = 'VERIFIED_ACCESS_AND_ADVANCE',
  NOT_VERIFIED = 'NOT_VERIFIED',
  NOT_RUN = 'NOT_RUN',
  ERROR = 'ERROR',
  NONE = 'NONE',
}

export enum UW_TOOL_TYPE {
  CLEAR_ID = 'THOMSON_REUTERS_CLEAR_ID',
  AU10TIX = 'AU10TIX',
  PERSONAL_BG_CHECK = 'THOMSON_REUTERS_PERSONAL_BACKGROUND_REPORT',
}
