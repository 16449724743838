import { ConfigEmailTypes } from '../../shared/configs/service/configs.service';

export const EMAIL_TYPE_FORM_FIELDS: any[] = [
  {
    type: ConfigEmailTypes.EMAIL_BASE_TEMPL,
    fields: ['key', 'config_type', 'html_template', 'text_template'],
  },
  {
    type: ConfigEmailTypes.EMAIL_TEMPLATE,
    fields: ['key', 'config_type', 'template_name', 'description', 'subject', 'html_template', 'template_variables'],
  },
  {
    type: ConfigEmailTypes.EMAIL_FRONT_CONFIG,
    fields: [
      'key',
      'config_type',
      'template_name',
      'description',
      'subject',
      'html_template',
      'text_template',
      'from_address',
      'to_address',
      'template_buttons',
    ],
  },
  {
    type: ConfigEmailTypes.EMAIL_FRONTEND_AUTOMATIC_CONFIG,
    fields: [
      'key',
      'config_type',
      'template_name',
      'description',
      'subject',
      'html_template',
      'text_template',
      'from_address',
      'to_address',
      'template_buttons',
    ],
  },
  {
    type: ConfigEmailTypes.EMAIL_BACK_CONFIG,
    fields: [
      'key',
      'config_type',
      'template_name',
      'description',
      'subject',
      'from_address',
      'to_address',
      'html_template',
      'text_template',
      'template_variables',
      'template_buttons',
    ],
  },
  {
    type: ConfigEmailTypes.EMAIL_FRONT_BATCH_CONFIG,
    fields: [
      'key',
      'config_type',
      'template_name',
      'description',
      'subject',
      'from_address',
      'html_template',
      'text_template',
      'template_variables',
      'template_buttons',
    ],
  },
];

export const ALLOW_TEMPLATES_VARIABLES_SUPPLIER: any[] = [
  { label: 'First name', value: '{{ supplier.contactFirstName }}' },
  { label: 'Last name', value: '{{ supplier.contactLastName }}' },
  { label: 'Legal name', value: '{{ supplier.legalName }}' },
  { label: 'Contact email', value: '{{ supplier.contactEmail }}' },
  { label: 'Phone number', value: '{{ supplier.phoneNumber }}' },
  { label: 'Tax ID', value: '{{ supplier.taxId }}' },
  { label: 'State code', value: '{{ supplier.address.state }}' },
  { label: 'Postal code', value: '{{ supplier.address.postalCode }}' },
  { label: 'City', value: '{{ supplier.address.city }}' },
  { label: 'Address', value: '{{ supplier.address.addr1 }} {{ supplier.address.addr2 }}' },
  { label: 'Country', value: '{{ supplier.address.country }}' },
  { label: 'Available balance', value: '{{ supplier.balance.availableBalance }}' },
  { label: 'Reserve balance', value: '{{ supplier.balance.reserveBalance }}' },
  { label: 'Potential liability', value: '{{ supplier.balance.potentialLiability }}' },
  { label: 'Advance liability', value: '{{ supplier.balance.advanceLiability }}' },
  { label: 'Pending card txn', value: '{{ supplier.balance.availableCardHold }}' },
];
export const ALLOW_TEMPLATES_VARIABLES_USER: any[] = [
  { label: 'First name', value: '{{ user.firstname }}' },
  { label: 'Last name', value: '{{ user.lastname }}' },
  { label: 'Full name', value: '{{ user.name }}' },
];
export const ALLOW_TEMPLATES_VARIABLES_MISCALENOUS: any[] = [{ label: 'Current date', value: '{{ currentDate | fmtDateOnly }}' }];
