<div class="well">
  <dl class="row">
    <dt class="col-sm-3">Key</dt>
    <dd class="col-sm-9">{{ feeSchedule.feeScheduleKey }}</dd>

    <dt class="col-sm-3">Name</dt>
    <dd class="col-sm-9">{{ feeSchedule.feeScheduleName }}</dd>

    <dt class="col-sm-3">Type</dt>
    <dd class="col-sm-9">{{ feeSchedule.feeScheduleType }}</dd>
  </dl>
</div>

<table class="mdl-data-table">
  <thead>
    <tr>
      <th>Start</th>
      <th>End</th>
      <th>Percentage Fee</th>
      <th>Fixed Fee</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tier of feeSchedule.feeScheduleTiers">
      <td>{{ tier.tierStart | currency: 'USD':true }}</td>
      <td>{{ tier.tierEnd | currency: 'USD':true }}</td>
      <td>{{ tier.feePercent }}%</td>
      <td>{{ tier.feeFixed | currency: 'USD':true }}</td>
    </tr>
  </tbody>
</table>
