<h1 mat-dialog-title>{{ actionName }}</h1>
<div mat-dialog-content class="text-center">
  <ng-container *ngIf="isPdf; else showFullResImage">
    <ng-container *ngIf="pdfLoaded; else loading">
      <iframe [src]="downloadFullUrl" class="verifyPdf"></iframe>
    </ng-container>
  </ng-container>

  <ng-template #showFullResImage>
    <span>
      <img *ngIf="downloadFullUrl" [src]="downloadFullUrl" class="verifyImages" />
    </span>
  </ng-template>
</div>
<div class="approve-navbar" mat-dialog-actions>
  <div class="row">
    <a
      mat-raised-button
      color="primary"
      class="download-link pull-right"
      appAppsDocumentDownload
      [fileUuid]="actionData.satisfied_by.file.file_id"
      [fileName]="actionData.satisfied_by.file.file_name"
      href="#"
    >
      <i class="material-icons">file_download</i>Download image</a
    >
  </div>
  <div class="row">
    <button class="pull-left" mat-raised-button [disabled]="documentRejected" (click)="rejectDocument()" [color]="'warn'" tabindex="-1">
      Reject
    </button>
    <button
      class="pull-right"
      mat-raised-button
      [disabled]="documentApproved"
      (click)="approveDocument()"
      [mat-dialog-close]="actionData"
      tabindex="2"
      [color]="'accent'"
    >
      Approve
    </button>
  </div>
</div>

<ng-template #loading>
  <app-loading-spinner [size]="2"></app-loading-spinner>
</ng-template>
