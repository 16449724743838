import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-list-template',
  templateUrl: './list-template.component.html',
})
export class ListTemplateComponent implements OnInit {
  listTemplateForm: FormGroup;
  listElements: FormArray = new FormArray([]);

  @Input() configValue: any;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.configValue) {
      this.createEditForm();
    } else {
      this.createNewForm();
    }

    this.listElements = this.listTemplateForm.get('listElements') as FormArray;
  }

  addListElement() {
    this.listElements.push(this.createNewListElement());
  }

  removeListElement(i: number) {
    if (this.listElements.length > 1) {
      this.listElements.removeAt(i);
    }
  }

  private createEditForm() {
    this.configValue.forEach((singleValue) => {
      this.listElements.push(this.createEditListElement(singleValue));
    });

    this.listTemplateForm = this.formBuilder.group({
      listElements: this.listElements,
    });
  }

  private createEditListElement(element: any): FormGroup {
    return this.formBuilder.group({
      name: element.name,
      uuid: element.uuid,
    });
  }

  private createNewForm() {
    this.listTemplateForm = this.formBuilder.group({
      listElements: this.formBuilder.array([this.createNewListElement()]),
    });
  }

  private createNewListElement(): FormGroup {
    return this.formBuilder.group({
      name: '',
      uuid: '',
    });
  }
}
