import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isNull, isUndefined } from 'util';

import { IEnvironment } from '../../../../environments/environment.interface';
import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { AppConfigService } from '../../../config';
import { AppState } from '../../../store/app.reducers';
import { CrmUserModel } from '../../models/crm-user.model';
import { NoteDataModel } from '../../models/note-data.model';
import { CrmItemStatus } from '../../shared/crm.enums';
import { AddCrmNote, EditCrmNote, selectPendingCrmStates } from '../../store/crm.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-note-edit',
  templateUrl: './note-add-edit.component.html',
})
export class NoteAddEditComponent extends SubscriberComponent implements OnInit {
  noteAddEditForm: FormGroup;
  noteData: any;
  loggedUser: string;
  editMode = false;
  pending = false;
  actionId = new Date().toString();
  readonly gciId: IEnvironment['workflowGciId'];

  constructor(
    public dialogRef: MatDialogRef<NoteAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private appConfig: AppConfigService,
  ) {
    super();

    if (!isUndefined(data) && !isNull(data)) {
      this.noteData = data.note;
      this.editMode = true;
    }
    this.gciId = appConfig ? appConfig.env.workflowGciId : null;
  }

  ngOnInit() {
    this.createForm();
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        if (!isUndefined(user)) {
          this.loggedUser = user.email;
        }
      }),
    );

    if (this.noteData) {
      this.noteAddEditForm.patchValue(this.noteData.data);
    }

    this.subscriptions.push(
      this.store.select(selectPendingCrmStates).subscribe((items) => {
        if (!isUndefined(items[this.actionId])) {
          if (items[this.actionId] === CrmItemStatus.SUCCESS) {
            this.closeDialog();
          }
        }
      }),
    );
  }

  createForm() {
    this.noteAddEditForm = this.formBuilder.group({
      body: ['', Validators.required],
    });
  }

  async saveNote() {
    const valid = this.noteAddEditForm.valid;
    const value = this.noteAddEditForm.getRawValue();

    if (valid && this.loggedUser) {
      const user = new CrmUserModel(this.loggedUser);
      const note = new NoteDataModel(value.body, user);

      if (this.editMode) {
        this.actionId = this.noteData.uuid;
        this.store.dispatch(new EditCrmNote({ uuid: this.noteData.uuid, data: note }));
      } else {
        this.store.dispatch(new AddCrmNote({ data: note, actionId: this.actionId }));
      }
    } else {
      throw new Error('INVALID FORM GROUP');
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
