import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Deserialize } from 'cerialize';
import { ToastrService } from 'ngx-toastr';

import { DIALOG_SIZE } from '../../../../shared/dialog.config';
import { BatchModel } from '../../../models/batch.model';
import { EmailsService } from '../../../services/emails.service';
import { EmailsStatusService } from '../../../services/emails-status.service';
import { EmailConfirmDialogComponent } from '../../email-confirm-dialog/email-confirm-dialog.component';
import { EmailPreviewComponent } from '../../email-preview/email-preview.component';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-email-expand-row-message',
  templateUrl: 'email-expand-row.component.html',
  styleUrls: ['../email-list.component.scss', 'email-expand-row.component.scss'],
})
export class EmailExpandRowComponent extends SubscriberComponent implements OnInit {
  @Input()
  batchGroupUuid: string;

  dataSource: MatTableDataSource<BatchModel>;
  columns: string[] = ['type', 'created_at', 'subject', 'from', 'status', 'receiver', 'author', 'supplier', 'actions'];

  isLoadingResult = true;
  itemsPerPage = 20;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private emailsService: EmailsService,
    private toastrService: ToastrService,
    private emailsStatusService: EmailsStatusService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.emailsService.getBatchEmails(this.batchGroupUuid).subscribe((response) => {
        this.dataSource = new MatTableDataSource<BatchModel>(response);
        this.dataSource.paginator = this.paginator;
        this.isLoadingResult = false;
      }),
    );
  }

  private acceptEmail(elementUuid: string) {
    this.subscriptions.push(
      this.emailsService.approveEmail(elementUuid).subscribe((response) => {
        this.toastrService.success('Email sent');
        this.refreshTaskRow(Deserialize(response['data'].email_object, BatchModel));
      }),
    );
  }

  private cancelEmail(elementUuid: string) {
    this.subscriptions.push(
      this.emailsService.cancelEmail(elementUuid).subscribe((response) => {
        this.toastrService.success(response['meta'].message);
        this.refreshTaskRow(Deserialize(response['data'].email_object, BatchModel));
      }),
    );
  }

  private refreshTaskRow(updatedEmail: BatchModel) {
    this.dataSource.data = this.dataSource.data.map((emailItem: BatchModel) => {
      if (emailItem.uuid === updatedEmail.uuid) {
        return updatedEmail;
      } else {
        return emailItem;
      }
    });
  }

  /* DIALOGS */
  previewDialog(element: BatchModel) {
    const previewDialog = this.dialog.open(EmailPreviewComponent, {
      ...DIALOG_SIZE.LARGE,
      data: element,
    });

    this.subscriptions.push(previewDialog.afterClosed().subscribe(() => {}));
  }

  acceptDialog(element: BatchModel) {
    const previewDialog = this.dialog.open(EmailConfirmDialogComponent, {
      ...DIALOG_SIZE.MEDIUM_AUTO_HEIGHT,
      data: { msg: 'Are you sure you want to approve this email? This will send only this mail' },
    });

    this.subscriptions.push(
      previewDialog.afterClosed().subscribe((response) => {
        if (response) {
          this.acceptEmail(element.uuid);
        }
      }),
    );
  }

  cancelDialog(element: BatchModel) {
    const previewDialog = this.dialog.open(EmailConfirmDialogComponent, {
      ...DIALOG_SIZE.MEDIUM_AUTO_HEIGHT,
      data: { msg: 'Are you sure want to cancel this email? Canceled email cannot be approve or send' },
    });

    this.subscriptions.push(
      previewDialog.afterClosed().subscribe((response) => {
        if (response) {
          this.cancelEmail(element.uuid);
        }
      }),
    );
  }

  /* RULES FOR BUTTONS */
  showPreviewButton(element: BatchModel): boolean {
    return !this.emailsStatusService.isBatchGroup(element.type);
  }

  showSendAndCancelSingleButton(element: BatchModel): boolean {
    return this.emailsStatusService.isPending(element.status) && !this.emailsStatusService.isBatchGroup(element.type);
  }

  showPreviewEmailLink(element: BatchModel): boolean {
    return !this.emailsStatusService.isBatchGroup(element.type);
  }

  /* LABELS TO GRID */
  getEmailTypeName(typeKey: string): string {
    return this.emailsStatusService.getTypeName(typeKey);
  }
}
