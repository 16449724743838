/* eslint-disable */
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { EmailSendComponent } from '../../crm-tools/emails/email-send/email-send.component';
import { FileUploadComponent } from '../../crm-tools/files/file-upload/file-upload.component';
import { NoteAddEditComponent } from '../../crm-tools/notes/note-add-edit/note-add-edit.component';
import { TaskAddEditComponent } from '../../crm-tools/tasks/task-add-edit/task-add-edit.component';
import { AddChargebackComponent } from '../../suppliers/supplier/add-chargeback/add-chargeback.component';
import { AddMarketplacePaymentComponent } from '../../suppliers/supplier/add-marketplace-payment/add-marketplace-payment.component';
import { AddReceivableComponent } from '../../suppliers/supplier/add-receivable/add-receivable.component';
import { AddZeroMarketplacePaymentComponent } from '../../suppliers/supplier/add-zero-marketplace-payment/add-zero-marketplace-payment.component';
import { DIALOG_SIZE } from '../dialog.config';
import { SubscriberComponent } from './../component-subscriber/subscriber.component';

/* eslint-enable */

@Component({
  selector: 'app-actions-add-fabs',
  styleUrls: ['../../crm-tools/shared/crm-tools.scss', './actions-add-fabs.component.scss'],
  templateUrl: './actions-add-fabs.component.html',
})
export class ActionsAddFabsComponent extends SubscriberComponent {
  open = false;

  constructor(public dialog: MatDialog, private toastrService: ToastrService) {
    super();
  }

  openAddNoteDialog() {
    const dialogRef = this.dialog.open(NoteAddEditComponent);
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('Note added successfully');
        }
      }),
    );
  }

  openAddTaskDialog() {
    const dialogRef = this.dialog.open(TaskAddEditComponent, {
      ...DIALOG_SIZE.DEFAULT,
      disableClose: true,
      data: {
        currentSupplier: true,
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('Task added successfully');
        }
      }),
    );
  }

  openSendEmailDialog() {
    const dialogRef = this.dialog.open(EmailSendComponent, {
      ...DIALOG_SIZE.DEFAULT,
      data: {
        currentSupplier: true,
      },
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('Task added successfully');
        }
      }),
    );
  }

  openUploadFileDialog() {
    const dialogRef = this.dialog.open(FileUploadComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
      data: {
        currentSupplier: true,
      },
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('File uploaded added successfully');
        }
      }),
    );
  }

  openAddChargebackDialog() {
    const dialogRef = this.dialog.open(AddChargebackComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {}));
  }

  openAddReceivableDialog() {
    const dialogRef = this.dialog.open(AddReceivableComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {}));
  }

  openAddMpPaymentDialog() {
    const dialogRef = this.dialog.open(AddMarketplacePaymentComponent, {
      width: DIALOG_SIZE.RWD_40.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {}));
  }

  openAddZeroPaymentDialog() {
    const dialogRef = this.dialog.open(AddZeroMarketplacePaymentComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {}));
  }
}
