<div [formGroup]="keyValueDictionaryForm">
  <mat-list formArrayName="keyValues" *ngFor="let keyValue of keyValues['controls']; let i = index">
    <mat-list-item [formGroupName]="i">
      <mat-form-field class="full-width">
        <input matInput placeholder="Key" formControlName="key" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <input matInput placeholder="Value" formControlName="value" />
      </mat-form-field>
      <button mat-mini-fab (click)="removeKeyValue(i)" color="warn" class="btn btn-sm"><mat-icon>clear</mat-icon></button>
    </mat-list-item>
  </mat-list>
  <button mat-raised-button (click)="addKeyValue()" color="primary">Add item</button>
</div>
