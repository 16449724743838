import { autoserialize, autoserializeIndexable, inheritSerialization } from 'cerialize';

import { BaseCrmModel } from './base-crm.model';

@inheritSerialization(BaseCrmModel)
export class EmailModel extends BaseCrmModel {
  @autoserialize public batchId?: string;
  @autoserialize public batchUuid?: string;
  @autoserialize public configName?: string;
  @autoserialize public config?: string;
  @autoserialize public count: number;
  @autoserialize public createdByName: string;
  @autoserialize public message: string;
  @autoserialize public messageHtml: string;
  @autoserialize public messageSes: any;
  @autoserialize public source: string;
  @autoserialize public status: string;
  @autoserialize public subject: string;
  @autoserialize public supplierName: string;
  @autoserializeIndexable(String) public to: string[];

  constructor(
    createdAt: string,
    ownerUuid: string,
    targetType: string,
    targetUuid: string,
    type: string,
    uuid: string,
    updatedAt: string,
    instanceId: number,
    batchId: string,
    batchUuid: string,
    configName: string,
    config: string,
    count: number,
    createdByName: string,
    message: string,
    messageHtml: string,
    messageSes: any,
    source: string,
    status: string,
    subject: string,
    supplierName: string,
    to: string[],
  ) {
    super(createdAt, ownerUuid, targetType, targetUuid, type, uuid, updatedAt, instanceId);
    this.batchId = batchId;
    this.batchUuid = batchUuid;
    this.config = config;
    this.configName = configName;
    this.count = count;
    this.createdByName = createdByName;
    this.message = message;
    this.messageHtml = messageHtml;
    this.messageSes = messageSes;
    this.source = source;
    this.status = status;
    this.subject = subject;
    this.supplierName = supplierName;
    this.to = to;
  }
}
