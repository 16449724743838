<div class="backgroundReportsContainer">
  <app-background-report-card
    [availableSections]="availableSections$ | async"
    [allReportsMap]="reportData$ | async"
    [reportTitle]="ownerName$ | async"
    [supplierKey]="supplierKey$ | async"
    [owner]="owner$ | async"
    (filterOptionsChanged)="onFilterOptionsChanged($event)"
  >
  </app-background-report-card>
</div>
