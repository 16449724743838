import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { EmailSendComponent } from '../emails/email-send/email-send.component';
import { FileUploadComponent } from '../files/file-upload/file-upload.component';
import { NoteAddEditComponent } from '../notes/note-add-edit/note-add-edit.component';
import { TaskAddEditComponent } from '../tasks/task-add-edit/task-add-edit.component';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from './../../shared/dialog.config';

@Component({
  selector: 'app-actions-add',
  templateUrl: './actions-add.component.html',
  styleUrls: ['../shared/crm-tools.scss', './actions-add.component.scss'],
})
export class ActionsAddComponent extends SubscriberComponent implements OnInit {
  constructor(public dialog: MatDialog, private toastrService: ToastrService) {
    super();
  }

  ngOnInit() {}

  openAddNoteDialog() {
    const dialogRef = this.dialog.open(NoteAddEditComponent);

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('Note added successfully');
        }
      }),
    );
  }

  openAddTaskDialog() {
    const dialogRef = this.dialog.open(TaskAddEditComponent, {
      ...DIALOG_SIZE.DEFAULT,
      disableClose: true,
      data: {
        currentSupplier: true,
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('Task added successfully');
        }
      }),
    );
  }

  openSendEmailDialog() {
    const dialogRef = this.dialog.open(EmailSendComponent, {
      ...DIALOG_SIZE.DEFAULT,
      data: {
        currentSupplier: true,
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('Task added successfully');
        }
      }),
    );
  }

  openUploadFileDialog() {
    const dialogRef = this.dialog.open(FileUploadComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
      data: {
        currentSupplier: true,
      },
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('File uploaded added successfully');
        }
      }),
    );
  }
}
