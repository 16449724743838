import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { trackByProp } from '../../../../../shared/utils/track-by-prop.util';
import { AppState } from '../../../../../store/app.reducers';
import { SupplierManualBankAccountModel } from '../../../../model/supplier-manual-bank-account.model';
import { SupplierService } from '../../../../services/supplier.service';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-payment-manual-link',
  templateUrl: './supplier-payment-manual-link.component.html',
  styleUrls: ['./supplier-payment-manual-link.component.scss'],
})
export class SupplierPaymentManualLinkComponent extends SubscriberComponent {
  formVisible = false;
  bankStatements$: Observable<SupplierManualBankAccountModel[]>;
  bankStatementsLoading$ = new BehaviorSubject<boolean>(true);
  readonly trackBankStatements = trackByProp<SupplierManualBankAccountModel>('manualBankDataKey');

  private supplierKey$: Observable<string>;
  private readonly fetchingErrorMsg = 'Error while fetching manually linked bank statements';
  private readonly updatingErrorMsg = 'Error while updating bank account';
  private readonly updatingSuccessMsg = 'Bank account updated successfully';

  constructor(private store: Store<AppState>, private supplierService: SupplierService, private toastr: ToastrService) {
    super();
    this.supplierKey$ = this.store.select(selectSupplierDetails).pipe(
      filter((s) => !!s),
      map((s) => s.supplierKey),
      distinctUntilChanged(),
      shareReplay(1),
    );
    this.bankStatements$ = this.fetchBankStatements();
  }

  setReceivingPayments(bankAccountKey: string, receivingPayments: boolean): void {
    this.configureBankAccount(bankAccountKey, { receivingPayments });
  }

  setAchContract(bankAccountKey: string, achContract: boolean): void {
    this.configureBankAccount(bankAccountKey, { achContract });
  }

  onSubmitSuccess(): void {
    this.bankStatements$ = this.fetchBankStatements();
    this.toggleForm();
  }

  toggleForm(): void {
    this.formVisible = !this.formVisible;
  }

  private configureBankAccount(bankAccountKey: string, flags: { achContract?: boolean; receivingPayments?: boolean }): void {
    this.subscriptions.push(
      this.supplierKey$
        .pipe(
          take(1),
          switchMap((supKey) => this.supplierService.configureManualBankStatement(supKey, bankAccountKey, flags)),
          finalize(() => {
            if (flags.receivingPayments !== undefined) {
              this.bankStatements$ = this.fetchBankStatements();
            }
          }),
        )
        .subscribe({
          next: () => this.toastr.success(this.updatingSuccessMsg),
          error: () => this.toastr.error(this.updatingErrorMsg),
        }),
    );
  }

  private fetchBankStatements(): Observable<SupplierManualBankAccountModel[]> {
    return this.supplierKey$.pipe(
      tap(() => this.bankStatementsLoading$.next(true)),
      switchMap((supplierKey) => this.supplierService.getManualBankStatements(supplierKey)),
      catchError(() => {
        this.toastr.error(this.fetchingErrorMsg);
        return [];
      }),
      tap(() => this.bankStatementsLoading$.next(false)),
    );
  }
}
