import { Component, EventEmitter, ExistingProvider, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { CancelEmitService } from '../../../suppliers/services/cancel-emit.service';
import { CardFormgroupMessageService } from '../../card-formgroup/card-formgroup-message.service';
import { InlineEditBaseComponent } from '../base/inline-edit-base.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineNumberComponent),
  multi: true,
};

export interface InlineNumberErrorMessages {
  toBig?: string;
  toSmall?: string;
  notInteger?: string;
}

enum ValidationError {
  ERROR_VALUE_TO_BIG = 'toBig',
  ERROR_VALUE_TO_SMALL = 'toSmall',
  ERROR_VALUE_NOT_INTIGER = 'notInteger',
}

@Component({
  selector: 'app-inline-number',
  templateUrl: './inline-number.component.html',
  styleUrls: ['./inline-number.component.scss'],
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
})
export class InlineNumberComponent extends InlineEditBaseComponent implements OnInit {
  @Input() label = '';
  @Input() min = 0;
  @Input() max = 100;
  @Input() allowNotInteger = false;

  @Input() errorMessages: InlineNumberErrorMessages = {
    toBig: 'The value entered is too large!',
    toSmall: 'The value entered is too small!',
    notInteger: 'Value have to be integer!',
  };

  @Output() onSave = new EventEmitter<number>();

  _value: number;
  _previousValue: number;

  constructor(
    protected messageService: CardFormgroupMessageService,
    protected cancelEmitService: CancelEmitService,
    private readonly toastrService: ToastrService,
  ) {
    super(messageService, cancelEmitService);
  }

  get value(): number {
    return this._value;
  }

  set value(v: number) {
    this._previousValue = v;
    this._value = v;
  }

  writeValue(value: any) {
    this._value = value;
  }

  onBlur() {
    this.updateValueIfNeeded();
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.updateValueIfNeeded();
    }
  }

  updateValueIfNeeded() {
    if (!this.isValueChanged()) {
      this.turnOffEditMode();
      return;
    }

    if (!this.isValueValid()) {
      this.backToOldValue();
      return;
    }

    this.updateValue();
    this.saveValue();
    this.turnOffEditMode();
  }

  isValueValid(): boolean {
    if (this.isValueBiggerThanMax()) {
      this.showError(ValidationError.ERROR_VALUE_TO_BIG);
      return false;
    }

    if (this.isValueSmallerThanMin()) {
      this.showError(ValidationError.ERROR_VALUE_TO_SMALL);
      return false;
    }

    if (this.allowNotInteger) {
      return true;
    }

    if (this.isValueNotInteger()) {
      this.showError(ValidationError.ERROR_VALUE_NOT_INTIGER);
      return false;
    }

    return true;
  }

  isValueBiggerThanMax(): boolean {
    return this._value > this.max;
  }

  isValueSmallerThanMin(): boolean {
    return this._value < this.min;
  }

  isValueNotInteger(): boolean {
    return this._value !== Math.floor(this._value);
  }

  showError(errorType: ValidationError) {
    this.toastrService.error(this.errorMessages[errorType]);
  }

  backToOldValue() {
    this._value = this._previousValue;
    this.updateFormGroup();
  }

  updateValue() {
    this._previousValue = this._value;
    this.updateFormGroup();
  }

  updateFormGroup() {
    this.formGroup.get(this.formControlName).setValue(this._value);
  }

  saveValue() {
    this.onSave.emit(this.value);
  }

  isValueChanged(): boolean {
    return this._previousValue !== this._value;
  }

  toggleEditMode() {
    if (this.inPresentationMode()) {
      this.turnOnEditMode();
      return;
    }
    this.updateValueIfNeeded();
  }

  inPresentationMode(): boolean {
    return !this.editing;
  }

  turnOnEditMode() {
    this.editing = true;
  }

  turnOffEditMode() {
    this.editing = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
