export class MarqetaTransaction {
  transaction_date;
  process_date;
  transaction_key;
  xact_type;
  merchant_name;
  merchant_city;
  amount;
  amount_currency;
  pyb_fee;
  pyb_fee_cur;
  rebate_percent;
  status;
  card_type;
  xact_key;
  pyb_memo;
  country;
  fees;
  posted_amount;
  rejection_reason;

  constructor(
    transaction_date: string = null,
    process_date: string = null,
    transaction_key: string = null,
    xact_type: string = null,
    merchant_name: string = null,
    merchant_city: string = null,
    amount: string = null,
    amount_currency: string = null,
    pyb_fee: string = null,
    pyb_fee_cur: string = null,
    rebate_percent: number = null,
    status: string = null,
    card_type: string = null,
    xact_key: string = null,
    pyb_memo: string = null,
    country: string = null,
    fees: string = null,
    posted_amount: string = null,
    rejection_reason: string = null,
  ) {
    this.transaction_date = transaction_date;
    this.process_date = process_date;
    this.transaction_key = transaction_key;
    this.xact_type = xact_type;
    this.merchant_name = merchant_name;
    this.merchant_city = merchant_city;
    this.amount = amount;
    this.amount_currency = amount_currency;
    this.pyb_fee = pyb_fee;
    this.pyb_fee_cur = pyb_fee_cur;
    this.rebate_percent = rebate_percent ? parseFloat((rebate_percent * 100).toString()).toFixed(2) + '%' : null;
    this.status = status;
    this.card_type = card_type;
    this.xact_key = xact_key;
    this.pyb_memo = pyb_memo;
    this.country = country;
    this.fees = fees;
    this.posted_amount = posted_amount;
    this.rejection_reason = rejection_reason;
  }
}
export class MarqetaTransactionMapper {
  public static map(data): MarqetaTransaction {
    if (!data) {
      return;
    }

    return new MarqetaTransaction(
      data.transaction_date,
      data.process_date,
      data.transaction_key,
      data.xact_type,
      data.merchant_name,
      data.merchant_city,
      data.amount,
      data.amount_currency,
      data.pyb_fee,
      data.pyb_fee_cur,
      data.rebate_percent,
      data.status,
      data.card_type,
      data.xact_key,
      data.pyb_memo,
      data.country,
      data.fees,
      data.posted_amount,
      data.rejection_reason,
    );
  }
}
