import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CancelEmitService {
  public onCancellationChange: Subject<boolean> = new Subject();
  public onWinbackChange: Subject<void> = new Subject();

  public editOnCancellationChange: Subject<string> = new Subject();
  private isCancelled = false;
  private isEdit = false;

  constructor() {}

  public setCancellation(): void {
    this.isCancelled = !this.isCancelled;

    this.onCancellationChange.next(this.isCancelled);
  }

  public setWinback(): void {
    this.editOnCancellationChange.next(null);
    this.onWinbackChange.next();
  }

  public setEditCancellation(value: string): void {
    this.editOnCancellationChange.next(value);
  }
}
