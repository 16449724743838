import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-badge',
  templateUrl: './event-badge.component.html',
  styleUrls: ['./event-badge.component.scss'],
})
export class EventBadgeComponent implements OnInit {
  @Input()
  type: string;

  constructor() {}

  ngOnInit() {}
}
