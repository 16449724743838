import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-nested-list-template',
  templateUrl: './nested-list-template.component.html',
  styleUrls: ['./nested-list-template.component.scss'],
})
export class NestedListTemplateComponent implements OnInit {
  nestedListTemplateForm: FormGroup;
  listElements: FormArray = new FormArray([]);
  listElementChildren: FormArray = new FormArray([]);

  @Input() configValue: any;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.configValue) {
      this.createEditForm();
    } else {
      this.createNewForm();
    }
  }

  addNestedListElement(rootElementIndex: number) {
    this.listElementChildren = this.listElements.controls[rootElementIndex].get('children') as FormArray;
    this.listElementChildren.push(this.createNestedListElement());

    if (this.isCollapsed(rootElementIndex)) {
      this.toggleCollapse(rootElementIndex);
    }
  }

  addRootListElement() {
    this.listElements.push(this.createNewListElement());
  }

  isCollapsed(rootElementIndex): boolean {
    const isCollapsed = this.listElements.controls[rootElementIndex].get('isCollapsed').value;
    return isCollapsed;
  }

  hasChildren(rootElementIndex): boolean {
    const children = this.listElements.controls[rootElementIndex].get('children') as FormArray;

    return children.length > 0;
  }

  toggleCollapse(rootElementIndex: number) {
    const isCollapsed = this.listElements.controls[rootElementIndex].get('isCollapsed').value;
    this.listElements.controls[rootElementIndex].patchValue({
      isCollapsed: !isCollapsed,
    });
  }

  removeNestedListElement(rootElementIndex: number, nestedElementIndex: number) {
    this.listElementChildren = this.listElements.controls[rootElementIndex].get('children') as FormArray;
    this.listElementChildren.removeAt(nestedElementIndex);
  }

  removeRootListElement(rootElementIndex: number) {
    if (this.listElements.length > 1) {
      this.listElements.removeAt(rootElementIndex);
    }
  }

  private createEditForm() {
    this.configValue.forEach((singleValue) => {
      this.listElements.push(this.createRootEditListElement(singleValue));
    });

    this.nestedListTemplateForm = this.formBuilder.group({
      listElements: this.listElements,
    });

    this.listElements = this.nestedListTemplateForm.get('listElements') as FormArray;
  }

  private createNestedEditListElement(element: any): FormGroup {
    return this.formBuilder.group({
      name: element.name,
    });
  }

  private createNestedListElement(): FormGroup {
    return this.formBuilder.group({
      name: '',
      children: this.formBuilder.array([]),
    });
  }

  private createNewForm() {
    this.nestedListTemplateForm = this.formBuilder.group({
      listElements: this.formBuilder.array([this.createNewListElement()]),
    });

    this.listElements = this.nestedListTemplateForm.get('listElements') as FormArray;
  }

  private createNewListElement(): FormGroup {
    return this.formBuilder.group({
      name: '',
      isCollapsed: true,
      children: this.formBuilder.array([]),
    });
  }

  private createRootEditListElement(element: any): FormGroup {
    const children: FormArray = new FormArray([]);

    element.children.forEach((child) => {
      children.push(this.createNestedEditListElement(child));
    });

    return this.formBuilder.group({
      name: element.name,
      children,
      isCollapsed: true,
    });
  }
}
