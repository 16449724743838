import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { isString } from 'util';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import Logger from '../../../shared/logger';
import { ErrorVisualizationService } from '../../../shared/services/error-visualization/error-visualization.service';
import { TableEventsService } from '../../../shared/table-events.service';
import { UsersService } from '../../../shared/users/service/users.service';
import { USER_PERMISSION } from '../../../shared/users/user-permissions.enum';
import { AppState } from '../../../store/app.reducers';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';

@Component({
  selector: 'app-edit-marketplace-statement',
  templateUrl: './edit-marketplace-statement.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
})
export class EditMarketplaceStatementComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  readonly actionTitle = 'Edit MP Statement';
  marketplaceStatements = [];

  constructor(
    readonly formBuilder: FormBuilder,
    readonly store: Store<AppState>,
    readonly dialogRef: MatDialogRef<BaseSupplierAction>,
    readonly cliService: CliService,
    readonly supplierService: SupplierService,
    readonly eventsService: TableEventsService,
    private readonly toastrService: ToastrService,
    private readonly errorVisualizationService: ErrorVisualizationService,
    private readonly usersService: UsersService,
    @Optional() @Inject(MAT_DIALOG_DATA) public readonly data: any,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
    if (this.data) {
      this.data = { ...this.data };
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(this.initComponent());
    super.ngOnInit();
    this.onChanges();
  }

  createForm(): void {
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        this.subscriptions.push(
          combineLatest([
            this.usersService.checkGeneralPermission(USER_PERMISSION.FINANCE, user),
            this.usersService.checkGeneralPermission(USER_PERMISSION.dev_support, user),
          ]).subscribe(([finance, devSupport]) => {
            const hasPermToEditDates = finance || devSupport;
            this.supplierActionForm = this.formBuilder.group({
              mp_sup_key: [{ value: '', disabled: true }, Validators.required],
              mp_statement: [{ value: '', disabled: true }, Validators.required],
              start_date: [{ value: null, disabled: !hasPermToEditDates }, Validators.required],
              end_date: [{ value: null, disabled: !hasPermToEditDates }],
              end_bal: [{ value: '', disabled: true }, Validators.required],
            });
          }),
        );
      }),
    );
  }

  onChanges(): void {
    this.subscriptions.push(
      this.supplierActionForm.controls['mp_sup_key'].valueChanges.pipe(filter((value) => value !== '')).subscribe(() => {
        if (!this.data) {
          this.supplierActionForm.get('mp_statement').enable();
        }
      }),
    );
    this.subscriptions.push(
      this.supplierActionForm.controls['mp_statement'].valueChanges.pipe(filter((value) => value !== '')).subscribe(() => {
        this.supplierActionForm.get('end_bal').enable();

        this.supplierActionForm.get('start_date').setValue(this.supplierActionForm.get('mp_statement').value.start_date);
        this.supplierActionForm.get('end_date').setValue(this.supplierActionForm.get('mp_statement').value.end_date);
        this.supplierActionForm.get('end_bal').setValue(this.supplierActionForm.get('mp_statement').value.end_bal);
      }),
    );
  }

  submit(): void {
    const dateFormat = 'YYYY-MM-DD';
    const endDate: string = this.supplierActionForm.get('end_date').value;
    const startDate: string = this.supplierActionForm.get('start_date').value;
    const dataToSave = {
      mp_key: this.supplierActionForm.get('mp_sup_key').value,
      end_bal: this.supplierActionForm.get('end_bal').value,
      start_date: startDate && moment(startDate).isValid() ? moment(startDate).format(dateFormat) : startDate,
      end_date: endDate && moment(endDate).isValid() ? moment(endDate).format(dateFormat) : endDate,
    };

    this.subscriptions.push(
      this.supplierService
        .updateMarketplaceStatement(this.supplierKey, this.supplierActionForm.get('mp_statement').value.mp_statement_key, dataToSave)
        .subscribe(
          () => {
            this.closeDialog(true);
            this.toastrService.success('Marketplace statement edit successfully!');
          },
          (err) => {
            this.errorVisualizationService.showError({
              name: 'Marketplace statement could not been edited',
              errorObject: this.prepareErrorMessage(err.error.message),
            });
          },
        ),
    );
  }

  private prepareErrorMessage(error: any): string {
    if (isString(error)) {
      return error;
    }
    return Object.keys(error).reduce((key) => `${key}: ${error[key][0]}`, '');
  }

  private initComponent(): Subscription {
    return this.store
      .select(selectSupplierDetails)
      .pipe(
        tap((data) => this.assignMarketplaces(data)),
        mergeMap(() => this.getMarketplaceStatements()),
      )
      .subscribe(
        (response) => {
          if (!response) {
            return;
          }

          this.assignStatements(response);
          this.updateForm();
        },
        (error) => Logger.error(error),
      );
  }

  private getMarketplaceStatements(): Observable<any> {
    return this.supplierService.getMarketplaceStatements(this.supplierKey, this.data ? '' : '&has_mp_payment=false');
  }

  private assignStatements(response): void {
    if (!response.result && response.results.length === 0) {
      this.closeDialog();
      this.toastrService.error('There are no marketplace statements to edit');
    }
    this.marketplaceStatements = response.results;
    this.supplierActionForm.controls.mp_sup_key.enable();
  }

  private assignMarketplaces(data): void {
    if (!data) {
      return;
    }

    this.supplierKey = data.supplierKey;

    if (this.data) {
      this.data.currentMp = data.marketplaces.find((mp) => mp.mpSupKey === this.data.mp_sup_key);
    }

    this.marketplaces = data.marketplaces.map((mp) => {
      return {
        label: mp.externalSupplierId ? `${mp.marketplaceName} (${mp.externalSupplierId})` : mp.marketplaceName,
        mp_key: mp.marketplaceKey,
      };
    });
  }

  private updateForm(): void {
    if (!this.data) {
      return;
    }

    const currentStatement = this.marketplaceStatements.find((mp) => mp.mp_statement_key === this.data.mp_statement_key);

    if (!this.data.currentMp || !currentStatement) {
      return;
    }

    const { controls } = this.supplierActionForm;
    controls.mp_sup_key.setValue(this.data.currentMp.marketplaceKey);
    controls.mp_statement.setValue(currentStatement);

    controls.mp_sup_key.disable();
    controls.mp_statement.disable();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
