import { autoserialize } from 'cerialize';

export class BatchModel {
  @autoserialize batch_uuid: string;
  @autoserialize batch_id?: string;
  @autoserialize config_name: string;
  @autoserialize count: number;
  @autoserialize created_at: number;
  @autoserialize created_by_name: string;
  @autoserialize instance_id: number;
  @autoserialize message: string;
  @autoserialize message_html: string;
  @autoserialize source: string;
  @autoserialize status: string;
  @autoserialize subject: string;
  @autoserialize supplier_name: string;
  @autoserialize to: string[];
  @autoserialize type: string;
  @autoserialize target_uuid: string;
  @autoserialize update_at: number;
  @autoserialize uuid: string;

  constructor(
    batch_uuid: string,
    batch_id: string,
    config_name: string,
    count: number,
    created_at: number,
    created_by_name: string,
    instance_id: number,
    message: string,
    message_html: string,
    source: string,
    supplier_name: string,
    status: string,
    subject: string,
    target_uuid: string,
    to: string[],
    type: string,
    update_at: number,
    uuid: string,
  ) {
    this.batch_uuid = batch_uuid;
    this.batch_id = batch_id;
    this.config_name = config_name;
    this.count = count;
    this.created_at = created_at;
    this.created_by_name = created_by_name;
    this.instance_id = instance_id;
    this.message = message;
    this.message_html = message_html;
    this.source = source;
    this.status = status;
    this.subject = subject;
    this.supplier_name = supplier_name;
    this.target_uuid = target_uuid;
    this.to = to;
    this.type = type;
    this.update_at = update_at;
    this.uuid = uuid;
  }
}
