import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { of, timer } from 'rxjs';
import { catchError, mapTo, mergeMap } from 'rxjs/operators';

import { environment } from './../../environments/environment';

@Injectable()
export class VpnTesterService {
  private readonly DELAY = 3000;
  private readonly INTERVAL = 30000;

  constructor(private readonly http: HttpClient, private readonly toastrService: ToastrService) {}

  init(): void {
    timer(this.DELAY, this.INTERVAL)
      .pipe(
        mergeMap(() =>
          this.http.options(`${environment.pybDocumentsApiUrl}health-check`).pipe(
            mapTo(true),
            catchError(() => of(false)),
          ),
        ),
      )
      .subscribe((hasVpn) => {
        if (hasVpn) {
          return;
        }
        this.toastrService.error('Please turn on the Payability VPN. Without it you may experience functionality issues');
      });
  }
}
