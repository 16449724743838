import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { trackByProp } from '../../../shared/utils/track-by-prop.util';
import { CONTRACT_STATUS } from '../../enums/contract-status.enum';
import { CancelEmitService } from '../../services/cancel-emit.service';
import { CancelSupplierService } from '../../services/cancel-supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { PYB_PRODUCTS } from './../../../shared/consts/pyb-products.const';
import { LongTermContractsService } from './../../../shared/services/long-term-contracts/long-term-contracts.service';
import { UsersService } from './../../../shared/users/service/users.service';
import { UserModel } from './../../../shared/users/user.model';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';
import { AppState } from './../../../store/app.reducers';
import { SupplierDetailsModel } from './../../model/supplier.model';
import { WinbackService } from './../../services/winback.service';
import { SupplierBoxHeaderService } from './../supplier-box-header/supplier-box-header.service';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from './../supplier-onboarding/supplier-onboarding.service';
import { WinbackConfirmationModalComponent } from './../winback-confirmation-modal/winback-confirmation-modal.component';

@Component({
  selector: 'app-cancel-supplier',
  templateUrl: './cancel-supplier.component.html',
  styleUrls: ['./cancel-supplier.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelSupplierComponent extends SubscriberComponent implements OnInit {
  public dueDate = new Date();
  public reasons: any;
  public reason: any = [];
  public warning: string;
  public cancelForm: FormGroup;
  public marketPlaces: FormArray = new FormArray([]);
  public isLoading = true;
  public isUpdating: boolean;
  public predictedFinishedDate: string;
  public salesRep: UserModel[] = [];
  public winbackReasons = [];
  public winbackCustomReason = false;

  private pickedMarketplaces = [];
  private supplierKey: string;
  private pendingCancellation: any;
  private amazonMpSupKey: string;
  private winbackMarketplaces = [];

  readonly pybProducts = PYB_PRODUCTS;
  readonly trackByMp = trackByProp('mp.value.data.marketplace_key');
  readonly trackByKey = trackByProp('key');
  readonly ERROR_TEXT = 'Something went wrong';
  readonly NOT_UPDATED_TEXT = 'Something went wrong, data is not updated';
  readonly CANCELLATION_UPDATED_TEXT = 'Cancellation updated successfully';
  readonly CANCELLATION_REQUEST_TEXT = 'Cancellation request successful';
  readonly CUSTOM_WINBACK_DOMAIN = 'winback.other';

  constructor(
    public dialogRef: MatDialogRef<CancelSupplierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private cancelSupplierService: CancelSupplierService,
    private toastrService: ToastrService,
    private cancelEmitService: CancelEmitService,
    private longTermContractsService: LongTermContractsService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private winbackService: WinbackService,
    private usersSerice: UsersService,
    private supplierBoxHeaderService: SupplierBoxHeaderService,
  ) {
    super();
    this.dueDate.setDate(this.dueDate.getDate() + 30);
  }

  ngOnInit() {
    this.selectSupplierDetails();
    this.setData();
  }

  get winbackForm(): FormGroup {
    return this.cancelForm.get('winback') as FormGroup;
  }

  get winbackFormControls() {
    if (!this.winbackForm) {
      return null;
    }
    return this.winbackForm.controls;
  }

  get winbackSalesRep() {
    return this.salesRep.map((u) => ({
      key: u.loginKey,
      name: u.name,
    }));
  }

  get disableSubmitButton(): boolean {
    if (this.isUpdating) {
      return this.winbackFormControls && this.winbackFormControls.enabled.value && !this.winbackForm.valid;
    }
    return !this.cancelForm.valid || !this.isArrayEmpty();
  }

  getActiveContract(): void {
    // eslint-disable-next-line max-len
    this.subscriptions.push(
      this.longTermContractsService.getActiveContract(this.amazonMpSupKey).subscribe((activeContractData) => {
        if (activeContractData.length > 0) {
          this.predictedFinishedDate = activeContractData.find((contract) => {
            return contract.status === CONTRACT_STATUS.ACTIVE;
          }).predictedFinishedDate;
        }
      }),
    );
  }

  setData(): void {
    if (this.data.supplierKey) {
      return this.createNewCancellation(this.data.supplierKey);
    }
    if (this.data.supplierUUID) {
      return this.updateCancellation(this.data);
    }
    throw new Error('Missing required supplierKey');
  }

  selectSupplierDetails(): void {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetails: SupplierDetailsModel) => {
        if (!supplierDetails || !supplierDetails.marketplaces) {
          return;
        }
        const amazon = supplierDetails.marketplaces.find(
          (marketplace) => marketplace.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY,
        );
        if (amazon) {
          this.amazonMpSupKey = amazon.mpSupKey;
          this.getActiveContract();
        }
      }),
    );
  }

  onSubmit(): Subscription {
    const winbackControls = this.winbackFormControls;

    if (winbackControls && winbackControls.enabled.value) {
      this.submitWhenWinbackEnabled();
      return;
    }

    this.isLoading = true;

    if (this.isUpdating && this.pendingCancellation) {
      return this.submitWhenUpdatingAndPending();
    }

    this.submitPutCancellation();
    return;
  }

  isArrayEmpty(): boolean {
    return this.pickedMarketplaces && this.pickedMarketplaces.length > 0;
  }

  changeDate(date) {
    this.dueDate = date;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private prepareWinbackPayload() {
    const values = this.winbackForm.value;
    const reason = this.winbackReasons.find((r) => r.key === values.reason);

    return {
      marketplaces: values.marketplaces.filter((m) => !!m.isChecked).map((m) => m.data.key),
      products: Object.entries(values.products)
        .filter(([_, val]) => !!val)
        .map(([key, _]) => key),
      sales_user: values.sales_rep,
      reason: {
        type: values.reason,
        description: reason && reason.custom ? values.reason_description : reason ? reason.name : '',
      },
    };
  }

  private submitWhenUpdatingAndPending(): Subscription {
    let winback = null;
    if (this.winbackFormControls.enabled.value) {
      winback = this.prepareWinbackPayload();
    }

    const dataToSave = {
      cancellation_reason: this.cancelForm.get('initReason').value,
      notes: this.cancelForm.get('text').value,
      supplier_feedback: this.pendingCancellation.changedData.supplier_feedback,
      winback,
    };

    return this.cancelSupplierService.updatePendingCancellation(this.supplierKey, dataToSave).subscribe(
      () => {
        this.toastrService.success(this.CANCELLATION_UPDATED_TEXT);
        if (!winback) {
          this.cancelEmitService.setCancellation();
        } else {
          this.cancelEmitService.setWinback();
          this.supplierBoxHeaderService.refetchData();
        }
        this.onClose();
      },
      () => {
        this.toastrService.error(this.NOT_UPDATED_TEXT);
        this.onClose();
      },
    );
  }

  private submitPutCancellation(): void {
    this.subscriptions.push(
      this.cancelSupplierService.putCancellation(this.supplierKey, this.prepareData()).subscribe(
        () => {
          this.toastrService.success(this.CANCELLATION_REQUEST_TEXT);
          this.cancelEmitService.setCancellation();
          this.isLoading = false;
          this.onClose();
        },
        () => {
          this.toastrService.error(this.NOT_UPDATED_TEXT);
          this.isLoading = false;
          this.onClose();
        },
      ),
    );
  }

  private initWinback(): void {
    this.subscriptions.push(
      this.winbackService.fetchReasons().subscribe((reasons) => {
        this.winbackReasons = reasons.map((r) => ({
          key: r.code_value,
          name: r.internal_display_name,
          custom: r.domain === this.CUSTOM_WINBACK_DOMAIN,
        }));
      }),
    );
    this.subscriptions.push(
      this.usersSerice.getLogins(null, USER_PERMISSION.US, 'team').subscribe((data) => {
        this.salesRep = data;
      }),
    );
  }

  private submitWhenWinbackEnabled(): void {
    this.openConfirmationModal();
  }

  private createNewCancellation(supplierKey: string) {
    this.supplierKey = supplierKey;
    this.getData();
    this.createForm();
  }

  private updateCancellation(pendingCancellation: any) {
    this.isUpdating = true;
    this.pendingCancellation = pendingCancellation;
    this.supplierKey = pendingCancellation.supplierUUID;
    this.getData();
    this.createForm(this.getWindbackBuilder());
    this.windbackListeners();
    this.cancelForm.get('initReason').setValue(pendingCancellation.reasonCode);
    this.cancelForm.get('text').setValue(pendingCancellation.reason);

    this.initWinback();
  }

  private prepareData(): Object {
    return {
      opt_out_date: this.dueDate,
      notes: this.cancelForm.get('text').value,
      cancellation_reason: this.cancelForm.get('initReason').value,
      marketplaces: this.pickedMarketplaces,
      supplier_feedback: this.cancelForm.get('initReason').value,
    };
  }

  private createForm(winback = null): void {
    const core = {
      initReason: [null, Validators.required],
      text: [''],
      marketPlaces: this.marketPlaces,
    };

    if (winback) {
      core['winback'] = winback;
    }

    this.cancelForm = this.formBuilder.group(core);
  }

  private getWindbackBuilder(): FormGroup {
    const products = this.formBuilder.group(
      {
        instant_advance: [false],
        instant_payouts: [false],
        instant_access: [false],
      },
      { validators: [this.atLeastOneCheckboxCheckedValidator()] },
    );

    return this.formBuilder.group({
      enabled: [false, Validators.required],
      products,
      sales_rep: [null, Validators.required],
      reason: [null, Validators.required],
      reason_description: [null],
      marketplaces: this.formBuilder.array([], [this.atLeastOneCheckboxCheckedValidator()]),
    });
  }

  private atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (
          (control.value.isChecked !== undefined && control.value.isChecked) ||
          (control.value.isChecked === undefined && control.value)
        ) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxToBeChecked: true,
        };
      }

      return null;
    };
  }

  private setWinbackMarketplaces(): void {
    this.winbackMarketplaces.forEach((m) => {
      (this.winbackFormControls.marketplaces as FormArray).push(
        this.formBuilder.group({
          isChecked: true,
          data: {
            key: m.marketplace_key,
            name: m.marketplace_name,
          },
        }),
      );
    });
  }

  private windbackListeners(): void {
    const winback = this.winbackForm;

    this.subscriptions.push(
      winback
        .get('reason')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe((key) => {
          const reason = this.winbackReasons.find((r) => r.key === key);
          this.winbackCustomReason = reason.custom;

          const reasonDescription = winback.get('reason_description');

          if (reason.custom) {
            reasonDescription.setValidators([Validators.required, Validators.minLength(1)]);
            reasonDescription.updateValueAndValidity();

            return;
          }

          reasonDescription.setValidators([]);
          reasonDescription.patchValue(null);
        }),
    );
  }

  private getData(): void {
    this.subscriptions.push(
      forkJoin([this.getActiveMarketPlaces(), this.getCancellationReasons()]).subscribe(
        ([maretkPlaces, reasons]) => {
          this.prepareFormArrayData(maretkPlaces);
          this.reasons = reasons;
          if (reasons && reasons.length > 0) {
            if (this.isUpdating) {
              this.setInitSecondReason(this.pendingCancellation.reasonCode);
            }
            for (const reason of reasons) {
              if (reason && reason.values && reason.values.length > 0) {
                this.reason.push(...reason.values);
              }
            }
          }

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
          this.toastrService.error(this.ERROR_TEXT);
          this.onClose();
        },
      ),
    );
  }

  private prepareFormArrayData(marketplaces: any[]): void {
    this.winbackMarketplaces = [];
    if (marketplaces && Array.isArray(marketplaces) && marketplaces.length > 0) {
      for (const marketPlace of marketplaces) {
        const mpForm = this.formBuilder.group({
          isChecked: [null],
          data: [marketPlace],
        });

        this.setMarketplaceToChecked(mpForm);

        this.subscriptions.push(
          mpForm.valueChanges.subscribe((value) => {
            value.isChecked ? this.addMarketPlace(value.data.marketplace_key) : this.removeMarketPlace(value.data.marketplace_key);
          }),
        );
        this.marketPlaces.push(mpForm);
        if (mpForm.value.isChecked) {
          this.winbackMarketplaces.push(marketPlace);
        }
      }
      this.disableMarketplaceFormIfUpdating();
    }
    this.setWinbackMarketplaces();
  }

  private disableMarketplaceFormIfUpdating(): void {
    if (this.isUpdating) {
      this.marketPlaces.controls.forEach((control) => control.disable());
    }
  }

  private setMarketplaceToChecked(mpForm: FormGroup): void {
    let optedOutMarketplaces = [];
    if (this.pendingCancellation && Array.isArray(this.pendingCancellation.changedData.opted_out_marketplaces)) {
      optedOutMarketplaces = this.pendingCancellation.changedData.opted_out_marketplaces;
    }
    const marketplaceFormKey = mpForm.controls['data'].value.marketplace_key;
    if (optedOutMarketplaces.includes(marketplaceFormKey)) {
      mpForm.patchValue({ isChecked: true });
    }
  }

  private addMarketPlace(mpKey: string): void {
    this.pickedMarketplaces.push(mpKey);
  }

  private removeMarketPlace(mpKey: string): void {
    this.pickedMarketplaces = this.pickedMarketplaces.filter((key) => key !== mpKey);
  }

  private getActiveMarketPlaces(): Observable<any> {
    return this.cancelSupplierService.getActiveMarketPlaces(this.supplierKey);
  }

  private getCancellationReasons(): Observable<any> {
    return this.cancelSupplierService.getCancellationReasons();
  }

  private setInitSecondReason(value: string): void {
    for (const reason of this.reasons) {
      if (reason && reason.values && reason.values.length > 0) {
        for (const secondReason of reason.values) {
          if (secondReason.key === value) {
            this.cancelForm.get('initReason').setValue(reason.key);
          }
        }
      }
    }
  }

  private openConfirmationModal() {
    const marketplaces = this.winbackFormControls.marketplaces.value.filter((m) => !!m.isChecked);
    const reason = this.winbackReasons.find((r) => r.key === this.winbackFormControls.reason.value);
    const salesRep = this.salesRep.find((s) => s.loginKey === this.winbackFormControls.sales_rep.value);

    const dialogRef = this.dialog.open(WinbackConfirmationModalComponent, {
      data: {
        summary: {
          marketplaces: marketplaces.map((m) => m.data.name),
          products: Object.entries(this.winbackFormControls.products.value)
            .filter(([_, val]) => !!val)
            .map(([key, _]) => this.pybProducts[key]),
          sales_rep: salesRep ? salesRep.name : '',
          reason: reason ? reason.name : '',
          reason_description: this.winbackFormControls.reason_description.value,
        },
      },
      width: DIALOG_SIZE.MEDIUM2.width,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoading = true;
        this.submitWhenUpdatingAndPending();
      }
    });
  }
}
