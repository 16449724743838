import { PlatformLocation } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { AppState } from './../../../store/app.reducers';
import { SendSingleEmail } from './../../store/crm.actions';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss'],
})
export class EmailPreviewComponent implements OnInit {
  showTextVersion = false;
  messageHtml: string;
  actionId = new Date().toString();

  constructor(
    public dialogRef: MatDialogRef<EmailPreviewComponent>,
    private platformLocation: PlatformLocation,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    if (this.data.message_html) {
      this.messageHtml = this.data.message_html;
    }
    if (this.data.messageHtml) {
      this.messageHtml = this.data.messageHtml;
    }
  }

  sendEmail() {
    this.store.dispatch(new SendSingleEmail({ data: this.data, actionId: this.actionId }));
    this.closeDialog(true);
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  toggleTextVersion(): void {
    this.showTextVersion = !this.showTextVersion;
  }

  supplierPageUrl(): string {
    const baseUrl: string = (<any>this.platformLocation).location.origin;
    return `${baseUrl}/#/suppliers/${this.data.target_uuid}/general`;
  }
}
