import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UW_TOOL_STATUS } from '../../../../shared/consts/uw-tool.enum';
import { UwToolEntity } from './../../../../shared/models/uw-tool-entity.model';
import { observableRepeater } from './../../../../shared/utils/observable-repeater.util';
import { SupplierUnderwritingService } from './../supplier-underwriting.service';
import { getMainOwner } from './../utils/get-main-owner';
import {
  UNDERWRITING_ACTION,
  UnderwritingFetchTools,
  UnderwritingFetchToolsFailed,
  UnderwritingFetchToolsSuccess,
} from './underwriting.actions';

@Injectable()
export class UnderwritingEffects {
  @Effect()
  fetchToolsList$ = this.actions$.ofType<UnderwritingFetchTools>(UNDERWRITING_ACTION.FETCH_TOOLS).pipe(
    switchMap(({ payload }) => {
      const mainOwner = getMainOwner(payload.supplier);
      const mainOwnerPersonId = mainOwner ? mainOwner.loginKey : null;

      return observableRepeater(
        this.supplierUnderwritingService.fetchAvailableTools(payload.supplier.supplierKey, mainOwnerPersonId),
        this.areToolsReady.bind(this),
        false,
        false,
      ).pipe(
        map((tools: UwToolEntity[]) => new UnderwritingFetchToolsSuccess({ tools })),
        catchError(() => {
          return of(new UnderwritingFetchToolsFailed());
        }),
      );
    }),
  );

  constructor(private actions$: Actions, private supplierUnderwritingService: SupplierUnderwritingService) {}

  private areToolsReady(data: UwToolEntity[]): boolean {
    return data.every((tool) => tool.status !== UW_TOOL_STATUS.PENDING);
  }
}
