<mat-card class="card">
  <h2 class="card-title">Manually Link Bank Statements</h2>
  <ng-container *ngIf="bankStatements$ | async as bankStatements">
    <table *ngIf="bankStatements.length" class="manual-bank-statements">
      <thead>
        <tr>
          <th>Account Number</th>
          <th>Routing Number</th>
          <th>Country</th>
          <th>Created by</th>
          <th>Receiving Gateway / Platform Payments</th>
          <th>Include in ACH Debit Contract?</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bankAccount of bankStatements; trackBy: trackBankStatements">
          <td>{{ bankAccount.accountNumber }}</td>
          <td>{{ bankAccount.routingNumber }}</td>
          <td>{{ bankAccount.bankCountry }}</td>
          <td>{{ bankAccount.addedBy }}</td>
          <td>
            <mat-checkbox
              [checked]="bankAccount.receivingPayments"
              [disabled]="false"
              (change)="setReceivingPayments(bankAccount.manualBankDataKey, $event.checked)"
            ></mat-checkbox>
          </td>
          <td>
            <mat-checkbox
              [checked]="bankAccount.achContract"
              [disabled]="false"
              (change)="setAchContract(bankAccount.manualBankDataKey, $event.checked)"
            ></mat-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
    <app-supplier-payment-manual-link-form
      *ngIf="formVisible"
      (submitSuccess)="onSubmitSuccess()"
      (cancel)="toggleForm()"
    ></app-supplier-payment-manual-link-form>
    <div *ngIf="!formVisible" class="d-flex justify-content-around">
      <button mat-raised-button (click)="toggleForm()" [color]="'accent'"><mat-icon>add</mat-icon> Add new account</button>
    </div>
  </ng-container>
  <app-loading-spinner *ngIf="bankStatementsLoading$ | async" [size]="4"></app-loading-spinner>
</mat-card>
