<div class="calculator-main-content" >
  <section class="" >

    <div class="calculator-with-result">

      <form [formGroup]="calculatorForm" class="calculator-form-content">

        <!-- UW size -->
        <mat-form-field class="calculator-fields" appearance="outline">
          <mat-label>UW Size</mat-label>
          <mat-select placeholder="UW Size" formControlName="seller_size">
            <mat-option *ngFor="let uw of uwSizeOptions" [value]="uw.value">
              {{ uw.label }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <!-- Seller Category -->
        <mat-form-field class="calculator-fields" appearance="outline">
          <mat-label>Seller Category</mat-label>
          <mat-select placeholder="Seller Category" formControlName="supplier_category">
            <mat-option *ngFor="let cate of sellerCategoryOptions" [value]="cate.value">
              {{ cate.label }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <!-- FBA -->
        <mat-form-field class="calculator-fields" appearance="outline">
          <mat-label>FBA %</mat-label>
          <mat-select placeholder="FBA %" formControlName="fba">
            <mat-option *ngFor="let fba of fbaOptions" [value]="fba.value">
              {{ fba.label }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <!-- UCC Senior -->
        <mat-form-field class="calculator-fields" appearance="outline">
          <mat-label>UCC Senior</mat-label>
          <mat-select placeholder="PYB UCC Senior" formControlName="senior_lien">
            <mat-option *ngFor="let ucs of uccSeniorOptions" [value]="ucs.value">
              {{ ucs.label }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <!-- Time Selling -->
        <mat-form-field class="calculator-fields" appearance="outline">
          <mat-label>Time Selling</mat-label>
          <mat-select placeholder="Time Selling" formControlName="time_selling">
            <mat-option *ngFor="let timeS of timeSellingOptions" [value]="timeS.value">
              {{ timeS.label }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <!-- Repayment Method -->
        <mat-form-field class="calculator-fields" appearance="outline">
          <mat-label>Repayment Method</mat-label>
          <mat-select placeholder="Repayment Method" formControlName="repayment_method">
            <mat-option *ngFor="let repayment of repaymentMethodOptions" [value]="repayment.value">
              {{ repayment.label }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

      </form>

      <div class="result-content">
        <mat-card class="calculator-results">

          <mat-card-content>
            <!-- Free Access Risks -->
            <div class="d-flex justify-content-between">
              <div class="card-results-title">Free Access Risks:</div>
              <div class="mt-2">Last saved</div>
            </div>

            <div class="col-md-12 result-name">
              <div class="result-name-title">Risk Score:</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? calculatorResults.free_access_risk.risk_score : '...' }}</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? oldCalculatorResults.free_access_risk.risk_score : '...' }}</div>
            </div>

            <div class="col-md-12 result-name">
              <div class="result-name-title">Target Free Access Price:</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? calculatorResults.free_access_risk.target_price : '...' }}%</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? oldCalculatorResults.free_access_risk.target_price : '...' }}%</div>
            </div>

            <div class="col-md-12 result-name">
              <div class="result-name-title">FA Lower Price Limit:</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? calculatorResults.free_access_risk.lower_limit_price : '...' }}%</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? oldCalculatorResults.free_access_risk.lower_limit_price : '...' }}%</div>
            </div>

            <!-- Instant Access Risks -->
            <div class="card-results-title">Instant Access Risks:</div>

            <div class="col-md-12 result-name">
              <div class="result-name-title">Risk Score:</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? calculatorResults.instant_access_risk.risk_score : '...' }}</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? oldCalculatorResults.instant_access_risk.risk_score : '...' }}</div>
            </div>

            <div class="col-md-12 result-name">
              <div class="result-name-title">Target Instant Access Price:</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? calculatorResults.instant_access_risk.target_price : '...' }}%</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? oldCalculatorResults.instant_access_risk.target_price : '...' }}%</div>
            </div>

            <div class="col-md-12 result-name">
              <div class="result-name-title">IA Lower Price Limit:</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? calculatorResults.instant_access_risk.lower_limit_price : '...' }}%</div>
              <div class="ml-3 col-md-2 text-left">{{ !loading$.getValue() ? oldCalculatorResults.instant_access_risk.lower_limit_price : '...' }}%</div>
            </div>

          </mat-card-content>
        </mat-card>
        <button (click)="saveResults()" mat-raised-button [color]="'primary'" class="mat-raised submit-button col-sm-12 mt-3">
          Save Results
        </button>


        <div *ngIf="oldUserInputs" class="mt-4 ">
          <mat-card>
              <mat-card-content>
                <div class="px-3">
                  <div class="card-results-title">Last saved: Risk Pricing Inputs</div>
        
                  <div class="col-md-12 result-name">
                    <div class="result-name-title">UW Size:</div>
                    <div class="ml-3 col-md-6 text-left">{{ !loading$.getValue() ? oldUserInputs?.seller_size : '...' }}</div>
                  </div>
                  <div class="col-md-12 result-name">
                    <div class="result-name-title">Seller Category:</div>
                    <div class="ml-3 col-md-6 text-left">{{ !loading$.getValue() ? oldUserInputs?.supplier_category : '...' }}</div>
                  </div>
                  <div class="col-md-12 result-name">
                    <div class="result-name-title">FBA %:</div>
                    <div class="ml-3 col-md-6 text-left">{{ !loading$.getValue() ? oldUserInputs?.fba : '...' }}</div>
                  </div>
                  <div class="col-md-12 result-name">
                    <div class="result-name-title">PYB UCC Senior:</div>
                    <div class="ml-3 col-md-6 text-left">{{ !loading$.getValue() ? oldUserInputs?.senior_lien : '...' }}</div>
                  </div>
                  <div class="col-md-12 result-name">
                    <div class="result-name-title">Time Selling:</div>
                    <div class="ml-3 col-md-6 text-left">{{ !loading$.getValue() ? oldUserInputs?.time_selling : '...' }}</div>
                  </div>
                  <div class="col-md-12 result-name">
                    <div class="result-name-title">Repayment Method:</div>
                    <div class="ml-3 col-md-6 text-left">{{ !loading$.getValue() ? oldUserInputs?.repayment_method : '...' }}</div>
                  </div>
                </div>

                <div class="col-md-12 result-name mt-2">
                  <div class="result-name-title text-muted">Last saved:</div>
                  <div class="ml-3 col-md-7 text-left">{{ !loading$.getValue() ? lastTimeStamp : '...' }}</div>
                </div>
                <div class="col-md-12 result-name">
                  <div class="result-name-title text-muted text-right">Last saved by:</div>
                  <div class="ml-3 col-md-7 text-left">{{ !loading$.getValue() ? lastHistoryUser : '...' }}</div>
                </div>
            </mat-card-content>
          </mat-card> 
        </div>

      </div>
    </div>

  </section>
</div>
