import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../../../shared/component-subscriber/subscriber.component';
import { FormValidationStatus } from '../../../../../../shared/enums/form-validation-status.enum';
import { LookupAbstractService } from '../../../../../../shared/lookup-service/lookup-abstract.service';
import { FetchUtilAttribute, selectCountries } from '../../../../../../shared/utils/store/utils.actions';
import { trackByProp } from '../../../../../../shared/utils/track-by-prop.util';
import { AppState } from '../../../../../../store/app.reducers';
import { SupplierManualBankAccountModel } from '../../../../../model/supplier-manual-bank-account.model';
import { SupplierService } from '../../../../../services/supplier.service';
import { selectSupplierDetails } from '../../../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-payment-manual-link-form',
  templateUrl: './supplier-payment-manual-link-form.component.html',
  styleUrls: ['./supplier-payment-manual-link-form.component.scss'],
})
export class SupplierPaymentManualLinkFormComponent extends SubscriberComponent {
  @Output() submitSuccess = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  bankAccountForm: FormGroup;
  availableCountries$: Observable<{ countryCode: string; label: string }[]>;
  availableStates$: Observable<{ id: string; label: string }[]>;
  routingNumberErrorMsg$: Observable<string | null>;
  readonly trackCountries = trackByProp<{ countryCode: string; label: string }>('countryCode');
  readonly trackStates = trackByProp<{ id: string; label: string }>('id');

  private supplierKey$: Observable<string>;
  private memoizedRoutingNumberCheck: {
    routingNumber?: string;
    countryCode?: string;
    validationResult?: Record<string, boolean> | null;
  } = {};
  private routingNumberValidationDebounceTime = 300;
  private readonly formControlsConfig: { [key in keyof SupplierManualBankAccountModel]: any[] } = {
    beneficiaryName: ['', Validators.required],
    bankName: ['', Validators.required],
    bankCountry: ['', Validators.required],
    accountNumber: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
    routingNumber: ['', Validators.required],
    addr1: ['', Validators.required],
    addr2: [''],
    city: ['', Validators.required],
    postalCode: ['', [Validators.required, Validators.pattern(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/)]],
    state: ['', Validators.required],
    country: ['', Validators.required],
  };
  private readonly routingNumberErrorMessages = {
    routingNumberEmpty: 'Routing number required',
    bankCountryEmpty: 'Select a country first',
    required: 'Fill Routing Number & Country',
    routingNumberInvalid: 'Invalid Routing Number',
  };
  private readonly bankAccountAddedMsg = 'Bank account added';
  private readonly addBankAccountErrorMsg = 'Error while adding the bank account';
  private readonly validatingRoutingNumErrorMsg = 'Error while validating the routing number';

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private supplierService: SupplierService,
    private toastr: ToastrService,
    private lookupRepo: LookupAbstractService,
  ) {
    super();
    this.availableCountries$ = this.fetchAvailableCountries();
    this.supplierKey$ = this.store.select(selectSupplierDetails).pipe(
      filter((s) => !!s),
      map((s) => s.supplierKey),
      distinctUntilChanged(),
    );
    this.bankAccountForm = this.formBuilder.group(this.formControlsConfig, { asyncValidators: this.validateRoutingNumber.bind(this) });
    this.routingNumberErrorMsg$ = this.routingNumberValidationMessageChanges();
    this.availableStates$ = this.fetchAvailableStatesForTheSelectedCountry();
  }

  onSubmit(): void {
    this.subscriptions.push(
      this.supplierKey$
        .pipe(
          take(1),
          switchMap((supplierKey) => this.supplierService.addManualBankStatement(supplierKey, this.bankAccountForm.value)),
        )
        .subscribe({
          next: () => {
            this.toastr.success(this.bankAccountAddedMsg);
            this.submitSuccess.emit();
          },
          error: () => {
            this.toastr.error(this.addBankAccountErrorMsg);
          },
        }),
    );
  }

  onCancel(): void {
    this.cancel.emit();
  }

  private routingNumberValidationMessageChanges(): Observable<string | null> {
    return this.bankAccountForm.statusChanges.pipe(map(this.buildRoutingNumberValidationMessage.bind(this)));
  }

  private buildRoutingNumberValidationMessage(status: FormValidationStatus): string | null {
    if (this.bankAccountForm.get('routingNumber').pristine) {
      return null;
    }

    if (status === FormValidationStatus.PENDING) {
      return 'Checking...';
    }

    if (status === FormValidationStatus.INVALID) {
      const formErrors = {
        ...this.bankAccountForm.errors,
        ...this.bankAccountForm.get('routingNumber').errors,
        ...this.bankAccountForm.get('bankCountry').errors,
      };

      for (const errorKey in this.routingNumberErrorMessages) {
        if (formErrors[errorKey]) {
          return this.routingNumberErrorMessages[errorKey];
        }
      }
    }

    return null;
  }

  private fetchAvailableCountries(): Observable<{ countryCode: string; label: string }[]> {
    this.store.dispatch(new FetchUtilAttribute({ attr: 'countries' }));

    return this.store.select(selectCountries).pipe(
      filter((c) => !!c),
      map((arr) => arr.map((c) => ({ countryCode: c.countryCode, label: c.label }))),
    );
  }

  private fetchAvailableStatesForTheSelectedCountry(): Observable<{ id: string; label: string }[]> {
    return this.bankAccountForm.get('country').valueChanges.pipe(
      switchMap((countryCode) => this.lookupRepo.getStates(countryCode)),
      startWith([]),
      distinctUntilChanged(),
      tap(() => this.bankAccountForm.get('state').setValue('')),
    );
  }

  private validateRoutingNumber(formControl: AbstractControl): Observable<ValidationErrors | null> {
    if (!formControl) {
      return of(null);
    }

    const routingNumber = formControl.get('routingNumber').value;
    const countryCode = formControl.get('bankCountry').value;

    if (!routingNumber) {
      return of({ routingNumberEmpty: true });
    }

    if (!countryCode) {
      return of({ bankCountryEmpty: true });
    }

    if (
      routingNumber === this.memoizedRoutingNumberCheck.routingNumber &&
      countryCode === this.memoizedRoutingNumberCheck.countryCode &&
      this.memoizedRoutingNumberCheck.validationResult !== undefined
    ) {
      return of(this.memoizedRoutingNumberCheck.validationResult);
    }

    return timer(this.routingNumberValidationDebounceTime).pipe(
      switchMap(() => this.supplierService.checkRoutingNum(countryCode, routingNumber)),
      map((res) => ((<any>res).error ? { routingNumberInvalid: true } : null)),
      catchError(() => {
        this.toastr.warning(this.validatingRoutingNumErrorMsg);
        return of(null);
      }),
      tap((validationResult) => (this.memoizedRoutingNumberCheck = { routingNumber, countryCode, validationResult })),
    );
  }
}
