export const UNDERWRITING_DAILY_COLUMNS = [
  'Date',
  'Daily Health',
  'Order defect rate',
  'Late shipment',
  'Late responses',
  'Cancellation rate',
  'Delivered on time',
  'Valid tracking rate',
  'Return dissatisfaction rate',
  'Account Status',
  'Account Performance Status',
  'Warning',
];
