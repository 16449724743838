import { AfterContentInit, Component, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

import { EventModel } from '../../models/event.model';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['../../shared/crm-tools.scss', '../../shared/timeline-item.scss', './event-item.component.scss'],
})
export class EventItemComponent implements AfterContentInit {
  @Input() item: EventModel;
  eventExpanded = false;
  message: string;

  correctTime(oldTime) {
    const newTime = moment(oldTime).format();
    const offSetTime = moment.parseZone(newTime, 'YYYY-MM-DD HH:mm').utcOffset();
    return offSetTime;
  }

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngAfterContentInit() {
    this.message = this.sanitizer.sanitize(SecurityContext.HTML, this.item.data.message);
  }
}
