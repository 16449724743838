<mat-card>
  <mat-card-subtitle> Request Advance Line Inputs </mat-card-subtitle>
  <mat-card-content>
    <div>
      <p>Source of Advance: Onboarding</p>
      <p>Machine Learning Ranking: {{ riskRating }}</p>
      <p>Recommended Limit: {{ recommended.descriptionGrossAmount }}</p>

      <p>
        Requested Limit:
        <mat-form-field class="requested-amount">
          <input
            matInput
            (keyup)="changeRequestedAmount($event)"
            (mouseup)="changeRequestedAmount($event)"
            [(ngModel)]="requestedAmount"
            [disabled]="isReadonly"
            type="number"
          />
        </mat-form-field>
      </p>

      <p *ngIf="forceFullSaved">Gross Advance Amount (Requested): {{ requested.grossAmount | currency: 'USD' }}</p>

      <p>Maximum Repayment Reserve %: {{ reserveForRepayments }}</p>
    </div>
    <div>
      <app-fee-summary *ngIf="elementData" [summaryData]="elementData"></app-fee-summary>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-form-field>
      <textarea matInput placeholder="Request Notes" [disabled]="true" [(ngModel)]="requestNotes"></textarea>
    </mat-form-field>
  </mat-card-footer>
</mat-card>
