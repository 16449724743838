import { formatCurrency } from '@angular/common';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { FetchCrmEvents } from '../../../crm-tools/store/crm.actions';
import { ADD_TRANSACTION_EVENT, TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { FetchSupplierDetails } from '../../store/supplier/supplier.actions';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseSupplierAction implements OnInit, OnDestroy {
  supplierActionForm: FormGroup;
  subscriptions: Subscription[] = [];
  marketplaces: any[];
  statements: any[];
  maxDate = moment().toDate();
  supplierKey: string;
  amount: number;

  actionTitle: string;
  confirmationMessage = 'Are you sure you want to proceed?';
  successMessage: string;
  errorMessage: string;

  showForm = true;
  isSaved = false;
  isConfirmed = false;
  isSuccess = false;
  isError = false;
  isLoadingResult = false;

  constructor(
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
    public dialogRef: MatDialogRef<BaseSupplierAction>,
    protected cliService: CliService,
    protected supplierService: SupplierService,
    protected eventsService: TableEventsService,
  ) {}

  abstract createForm();
  abstract submit();

  ngOnInit() {
    this.createForm();
  }

  loadStatements(supplierKey, marketplaceSupplierKey) {
    this.subscriptions.push(
      this.supplierService
        .getReport('v_mp_statements', {
          supplier_key: supplierKey,
          mp_sup_key: marketplaceSupplierKey,
        })
        .subscribe((response) => {
          if (response.results !== null && response.results !== undefined) {
            this.statements = response.results.map((element) => {
              return {
                label: `${element.start_date} – ${element.end_date || 'now'} (${formatCurrency(element.end_bal, 'en-US', '$')})`,
                id: element.statement_id,
              };
            });
          }
        }),
    );
  }

  loadStatementsForAddSupplierFundRecovery(supplierKey, marketplaceSupplierKey) {
    this.subscriptions.push(
      this.supplierService.getStatementsForAddSupplierFundRecovery(supplierKey, marketplaceSupplierKey).subscribe((response) => {
        if (response.results !== null && response.results !== undefined) {
          this.statements = response.results.map((element) => {
            return {
              label: `${element.start_date} – ${element.end_date || 'now'} (${formatCurrency(element.end_bal, 'en-US', '$')})`,
              id: element.statement_id,
              debitAmount: element.debit_amount,
            };
          });
        }
      }),
    );
  }

  save() {
    this.isSaved = true;
    this.supplierActionForm.disable({ onlySelf: true, emitEvent: false });
  }

  editForm() {
    this.isSaved = false;
    this.supplierActionForm.enable({ onlySelf: true, emitEvent: false });
  }

  refreshSupplierDetails(): void {
    this.store.dispatch(
      new FetchSupplierDetails({
        supplierKey: this.supplierKey,
        refresh: true,
      }),
    );
  }

  refreshEvents(): void {
    this.store.dispatch(
      new FetchCrmEvents({
        limit: 20,
      }),
    );
  }

  hideForm() {
    this.isLoadingResult = false;
    this.showForm = false;
    this.isSaved = false;
  }

  resetForm() {
    this.createForm();
    this.showForm = true;
    this.isSaved = false;
    this.isConfirmed = false;
    this.isSuccess = false;
    this.isError = false;
  }

  onSuccess(message: string) {
    this.hideForm();
    this.isSuccess = true;
    this.eventsService.broadcast(ADD_TRANSACTION_EVENT);
    this.successMessage = message;
    this.refreshSupplierDetails();
  }

  onError(message: string) {
    this.hideForm();
    this.isError = true;
    this.errorMessage = message;
    this.refreshEvents();
  }

  back() {
    this.showForm = true;
    this.isError = false;
    this.supplierActionForm.enable();
    this.supplierActionForm.updateValueAndValidity();
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
