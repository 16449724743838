import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { KycFileUpload, KycFileUploadResponse } from './../../suppliers/model/compliance.model';

export interface B2bDocumentTokenModel {
  expiryDate: number;
  gciId: string;
  token: string;
}

@Injectable()
export class B2bDocumentService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.b2bDocumentsApiUrl;
  }

  getToken() {
    const endpointUrl = this.apiUrl + 'getToken';
    return this.httpClient.post<B2bDocumentTokenModel>(endpointUrl, {});
  }

  getFileDownloadUrlWithToken(gci, uuid, token) {
    return of(`${this.apiUrl}api/download/${gci}/${uuid}?b2bdocument_token=${token}`);
  }

  getFileDownloadUrl(gci, uuid) {
    const endpointUrl = this.apiUrl + 'getToken';
    return this.httpClient.post<B2bDocumentTokenModel>(endpointUrl, {}).pipe(
      map((tokenModel) => {
        const downloadUrl = `${this.apiUrl}api/download/${gci}/${uuid}?b2bdocument_token=${tokenModel.token}`;
        return downloadUrl;
      }),
    );
  }

  uploadFile(data: KycFileUpload): Observable<KycFileUploadResponse> {
    const endpointUrl = this.apiUrl + 'api/upload';
    const formData = new FormData();

    Object.keys(data).map((key) => {
      if (key === 'file') {
        formData.append(key, data[key].files[0], data[key].files[0].name);
      } else {
        formData.append(key, data[key]);
      }
    });

    return this.httpClient.post(endpointUrl, formData) as Observable<KycFileUploadResponse>;
  }
}
