import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { AppConfigService } from '../../../config';
import { SupplierCompilanceStatus } from '../../model/supplier-compilance-status';
import { SupplierComplianceActionService } from '../../services/supplier-compliance-action.service';
import { OnboardingDetails, OnboardingDetailsMapper } from './onboarding-details.model';
import { SupplierOnboardingInterface } from './supplier-onboarding-interface';

export const KYC_IDENTITY_KEY = '869f7aaf-e9bd-4f85-bfa6-6a0b0d5616a2';
export const KYC_ADDRESS_KEY = 'f9be5d82-bdbc-4502-8214-4a8c0f095e85';
export const KYC_DOCUSIGN_KEY = '9a3f6e03-4a70-4e7f-861c-86c267789600';
export const ACCOUNT_ACCESS_INVITATION_KEY = 'c5f8e904-75f3-4090-9783-fadafe2162fb';
export const AMAZON_SELLER_CENTRAM_PERM_KEY = '331d90cc-cf7a-4d9f-92bd-8855c1af08c5';

export const ONBOARDING_PROGRESS_KEY = '87b86b36-f7e5-4456-ae35-2611fef77bb8';

export const ONBOARDING_ACTION_KEY = 'd24c3716-3623-4c16-898a-9ec13ee849cc';
export const ONBOARDING_ACTION_TYPE = 'onboarding_check';

export const AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY = '37036286-6ab8-427e-8a5f-ce53cac22f3e';
export const TOPHATTER_MARKETPLACE_KEY = 'cae8ea7b-971b-4595-b920-50d8975f849e';
export const WALLMART_MARKETPLACE_KEY = 'c7d2ca4e-d44b-4713-b804-abc58d38f186';
export const SHOPIFY_MARKETPLACE_KEY = '60e9092e-66d5-4e53-b784-df0a4d552582';
export const PAYONEER_MARKETPLACE_KEY = '3fd6bd61-6d83-4103-84e8-deb3c02b51cb';
export const MORECOMMERCE_MARKETPLACE_KEY = '6ededac2-d90f-43d3-af34-ee34e97e07a1';
export const EBAY_MARKETPLACE_KEY = '44ce7193-05ed-4ff4-b806-1b4315dbe944';
export const VOLUSION_MARKETPLACE_KEY = '5609080f-f80c-421a-ab60-e1cc32e32a20';
export const PAYPAL_MARKETPLACE_KEY = 'f5cd98e5-ee28-456e-97b9-c32b9f01d509';
export const STRIPE_MARKETPLACE_KEY = '57387ee6-087c-4d04-b8bc-247d58355ca2';
export const AUTHORIZE_MARKETPLACE_KEY = 'cc5da097-0366-483d-96f5-f58764c3f89d';
export const TARGET_MARKETPLACE_KEY = '0dcb4780-6760-46b6-acdb-0cc09e701980';
export const NEWEGG_MARKETPLACE_KEY = '39ec5fa0-e6a4-473a-b185-fbfb83c29901';
export const PINGPONG_MARKETPLACE_KEY = '6d13492b-36a4-4c11-8966-abadcd037680';

export const COMPLIANCE_ACTION_KEYS = [
  KYC_IDENTITY_KEY,
  KYC_ADDRESS_KEY,
  KYC_DOCUSIGN_KEY,
  ACCOUNT_ACCESS_INVITATION_KEY,
  AMAZON_SELLER_CENTRAM_PERM_KEY,
];

@Injectable()
export class SupplierOnboardingService implements SupplierOnboardingInterface {
  static readonly COMPLIANCE_STATUS_KEY = 'compliance_status_key';
  static readonly COMPLIANCE_ACITON_KEY = 'compliance_action_key';

  private apiUrl: string;
  private supWidgetsUrl: string;
  private apiUrlMain: string;

  constructor(
    private appConfig: AppConfigService,
    private complianceActionService: SupplierComplianceActionService,
    private httpClient: HttpClient,
  ) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers/';
    this.apiUrlMain = appConfig.env.internalApiUrl;
    this.supWidgetsUrl = appConfig.env.supWidgetsApiUrl;
  }

  getSubmittedFileActions(
    supplierKey: string,
    complianceKey: string,
    action: string,
    refresh = false,
  ): Observable<SupplierCompilanceStatus[]> {
    const endpointUrl = this.apiUrl + `${supplierKey}/compliance_status/${complianceKey}/${action}`;
    let queryParams = new HttpParams();
    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient.get<SupplierCompilanceStatus[]>(endpointUrl, {
      params: queryParams,
    });
  }

  getAmazonMarketplaceAccess(supplierKey: string, refresh = false): Observable<SupplierCompilanceStatus> {
    return this.getSubmittedFileActions(supplierKey, ACCOUNT_ACCESS_INVITATION_KEY, 'actions', refresh).pipe(
      map((result) => {
        if (!isUndefined(result) && !isUndefined(result.length) && result.length) {
          return result[0];
        } else {
          return result as unknown as SupplierCompilanceStatus;
        }
      }),
    );
  }

  getAmazonMarketplacePermissions(supplierKey: string, refresh = false) {
    return this.getSubmittedFileActions(supplierKey, AMAZON_SELLER_CENTRAM_PERM_KEY, 'actions', refresh);
  }

  getComplianceStatuses(supplierKey: string, refresh = false) {
    let queryParams = new HttpParams();
    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    const endpointUrl = this.apiUrl + `${supplierKey}/compliance_status`;
    return this.httpClient.get<SupplierCompilanceStatus[]>(endpointUrl, {
      params: queryParams,
    });
  }

  prepareKycDocumentsObservable(supplierKey, refresh) {
    const complianceActionKeys = [KYC_IDENTITY_KEY, KYC_ADDRESS_KEY, KYC_DOCUSIGN_KEY];
    const observableBatch = complianceActionKeys.map((complianceKey) => {
      return this.getSubmittedFileActions(supplierKey, complianceKey, 'actions', refresh);
    });

    return forkJoin(observableBatch);
  }

  /**
   * @deprecated Old versions from AIv1
   *
   * @param complianceActionKeys
   * @param supplierKey
   * @returns
   */
  prepareComplianceObservable(complianceActionKeys, supplierKey) {
    // prepare request for every static complianceActionKeys value
    const observableBatch = complianceActionKeys.map((complianceKey) => {
      return this.getSubmittedFileActions(supplierKey, complianceKey, 'actions');
    });

    // we also need general info
    observableBatch.push(this.getComplianceStatuses(supplierKey));

    // put them as one observable join
    return forkJoin(observableBatch);
  }

  deleteMarketplaceAccess(amazonAccessStatus, amazonPermissionsStatus) {
    const complianceStatusKeys = [
      amazonAccessStatus[SupplierOnboardingService.COMPLIANCE_STATUS_KEY],
      amazonPermissionsStatus[SupplierOnboardingService.COMPLIANCE_STATUS_KEY],
    ].filter((complianceKey) => complianceKey);

    const preparedActions = complianceStatusKeys.map((complianceKey) => this.complianceActionService.deleteAction(complianceKey));

    preparedActions.push(of(true));

    return forkJoin(preparedActions);
  }

  startOnboardingWorkflow(supplierKey: string, skipped: boolean, mpSupKeys: string[], sendEmail: boolean): Observable<any> {
    const endpointUrl = this.apiUrlMain + `onboarding/suppliers/${supplierKey}/new-process`;
    return this.httpClient.post(endpointUrl, { send_email: sendEmail, skipped, mpSupKeys });
  }

  setUserOnboardingStatus(supplierKey: string, onboardingStatus: boolean): Observable<any> {
    const endpointUrl = this.apiUrlMain + `suppliers/${supplierKey}`;
    return this.httpClient.put(endpointUrl, { onboardingStatus });
  }

  setOnboardingTemplate(supplierKey: string, template: string): Observable<any> {
    const endpointUrl = this.apiUrlMain + `${supplierKey}/onboarding_template`;
    return this.httpClient.post(endpointUrl, { code: template });
  }

  setOnboardingSchema(supplierKey: string, currentSchema: string): Observable<any> {
    const endpointUrl = `${this.apiUrlMain}supplier/${supplierKey}/schema`;
    return this.httpClient.post(endpointUrl, { currentSchema });
  }

  getOnboardingWorkflowDetail(supplierKey: string): Observable<Array<OnboardingDetails>> {
    const endpointUrl = this.apiUrlMain + `compliance/details/${supplierKey}`;
    return this.httpClient.get(endpointUrl).pipe(
      map((data) => {
        if (Array.isArray(this.prepareDataForOnboardingMapper(data))) {
          return this.prepareDataForOnboardingMapper(data).map(OnboardingDetailsMapper.map);
        }

        return null;
      }),
    );
  }

  getSupplierOnboardingProducts(supplierKey: string) {
    const endpointUrl = `${this.apiUrlMain}supplier/${supplierKey}/onboarding_products`;

    return this.httpClient.get(endpointUrl).pipe(
      map((response: any) => {
        return {
          services:
            !isNullOrUndefined(response.services) && response.services.length > 0
              ? response.services.sort((firstElement, secondElement) => this.sortTables(firstElement, secondElement, 'product'))
              : [],
          payment_configuration:
            !isNullOrUndefined(response.payment_configuration) && response.payment_configuration.length > 0
              ? response.payment_configuration.sort((firstElement, secondElement) =>
                  this.sortTables(firstElement, secondElement, 'payment_option'),
                )
              : [],
          marketplaces:
            !isNullOrUndefined(response.marketplaces) && response.marketplaces.length > 0
              ? response.marketplaces.sort((firstElement, secondElement) =>
                  this.sortTables(firstElement, secondElement, 'marketplace_name'),
                )
              : [],
        };
      }),
    );
  }

  getOnboardingDetails(supplierKey: string) {
    const endpointUrl = `${this.apiUrlMain}supplier/${supplierKey}/onboarding_details`;

    return this.httpClient.get(endpointUrl);
  }

  public updateOnboardingStatuses(supplierKey: string, onboardingService: any) {
    const endpointUrl = `${this.apiUrlMain}supplier/${supplierKey}/onboarding_products/${onboardingService.product_code}`;

    return this.httpClient.post(endpointUrl, {
      uw_status: onboardingService.uw_status,
      status: onboardingService.status,
    });
  }

  public updateComplianceWithMpKey(marketplaceSupplierKey: string, data: any) {
    const endpoint = `${this.supWidgetsUrl}suppliers/${marketplaceSupplierKey}/set_bank_account_status`;
    return this.httpClient.post(endpoint, data);
  }

  public updateComplianceWithoutMpKey(supplierKey: string, complianceKey: string, data: any) {
    const endpoint = `${this.apiUrlMain}suppliers/${supplierKey}/compliance_status/${complianceKey}`;
    return this.httpClient.put(endpoint, data);
  }

  private sortTables(firstElement, secondElement, propertyName) {
    if (firstElement[propertyName].toLowerCase() < secondElement[propertyName].toLowerCase()) {
      return -1;
    } else if (firstElement[propertyName].toLowerCase() > secondElement[propertyName].toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  private prepareDataForOnboardingMapper(data: any): any[] {
    const dataList = [];
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const newMarketplaceData = {};
          newMarketplaceData['marketplaceKey'] = key;
          newMarketplaceData['marketplaceName'] = data[key]['name'] ? data[key]['name'] : 'Payability';
          newMarketplaceData['compliances'] = data[key]['compliance'] ? data[key]['compliance'] : data[key];
          newMarketplaceData['isIntegrated'] = data[key]['isIntegrated'];

          if (newMarketplaceData['marketplaceName'] !== 'Payability') {
            dataList.push(newMarketplaceData);

            continue;
          }
          dataList.unshift(newMarketplaceData);
        }
      }
    }
    return dataList;
  }

  backSupplierToOnboarding(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrlMain}onboarding/${supplierKey}/back_to_onboarding`;
    return this.httpClient.post(endpointUrl, {});
  }
}
