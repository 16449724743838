export enum CrmStates {
  HIDDEN,
  OPEN,
  CLOSED,
  FIXED,
}

export enum SupplierInfoStates {
  HIDDEN,
  OPEN,
  CLOSED,
}

export enum ChoosenSearchStates {
  NEW_SEARCH,
  OLD_SEARCH,
}
