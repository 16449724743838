import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { FeatureFlagsGeneralGuard } from '../guards/feature-flags-general.guard';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';
import { RouteAccessResolver } from '../shared/utils/route-access-resolver';
import { TrackerComponent } from './tracker/tracker.component';

export const routes: Routes = [
  {
    path: 'underwriting',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
      },
    },
    children: [
      { path: '', redirectTo: 'tracker', pathMatch: 'full' },
      { path: 'tracker', component: TrackerComponent, pathMatch: 'full' },
    ],
  },
];
