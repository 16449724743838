import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { Supplier2FaComponent } from './supplier-2fa.component';
import { Supplier2FaService } from './supplier-2fa.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [Supplier2FaComponent],
  providers: [Supplier2FaService],
  exports: [Supplier2FaComponent],
})
export class Supplier2FaModule {}
