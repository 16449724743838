import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { SubscriberComponent } from '../../shared/component-subscriber/subscriber.component';
import { AppConfigService } from './../../config';

@Component({
  selector: 'app-waiting-suppliers-report',
  templateUrl: './waiting-suppliers.component.html',
  styleUrls: ['./waiting-suppliers.component.scss'],
})
export class WaitingSuppliersReportComponent extends SubscriberComponent implements OnInit {
  public batches: Observable<any[]>;

  public tableSettings: any;
  public rows: any;
  public columns = [
    {
      field: 'supplier_key',
      headerName: 'Supplier Key',
      width: 300,
      cellRenderer: (cellData: any) => {
        return `<a href="#/suppliers/${cellData.value}/">${cellData.value}</a>`;
      },
    },
    {
      field: 'cache_status',
      headerName: 'Cache Status',
    },
    {
      field: 'cache_started',
      headerName: 'Cache Started',
      width: 200,
      cellRenderer: this.dateFormatter,
    },
    {
      field: 'cache_finished',
      headerName: 'Cache Finished',
      width: 200,
      cellRenderer: this.dateFormatter,
    },
    {
      field: 'number_of_cache_events',
      headerName: 'Number Of Cache Events',
    },
    {
      field: 'event_timestamp',
      headerName: 'Event Timestamp',
      width: 200,
      cellRenderer: this.dateFormatter,
    },
  ];
  public isReady: boolean;
  private apiUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigService,
    private readonly toastrService: ToastrService,
  ) {
    super();
    this.apiUrl = appConfig.env.actionsToolsUrl + 'reports/es_cache';
  }

  ngOnInit(): void {
    this.getBatches();
  }

  getBatches(): void {
    this.isReady = false;

    this.subscriptions.push(
      this.http.get(this.apiUrl).subscribe(
        (rows) => {
          this.rows = rows['data'];
          this.isReady = true;
        },
        () => {
          this.isReady = true;
          this.toastrService.error('Cannot fetch report');
        },
      ),
    );
  }

  private dateFormatter(cellData: { [key: string]: any }): string {
    if (cellData?.value == null) {
      return '';
    }
    return moment(cellData.value).format('MM/DD/YYYY HH:mm');
  }
}
