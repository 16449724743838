import { PlatformLocation } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { DialogService } from '../../shared/dialog/dialog.service';
import { FeatureFlagsEvaService } from '../../shared/feature-flags/feature-flags-eva.service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { EvaComponent } from './../../shared/eva/eva.component';
import { FEATURE_FLAGS_ENV } from './../../shared/feature-flags/feature-flags.enum';
import { FeatureFlagEntity } from './../../shared/feature-flags/feature-flags.model';
import { FeatureFlagsAdminService } from './../../shared/feature-flags/feature-flags-admin.service';
import { CommunicationService } from './../../shared/iframe/communication/communication.service';
import { KeyValue } from './../../shared/models/key-value';
import { trackByIndex } from './../../shared/utils/track-by-index.util';

@Component({
  selector: 'app-admin-feature-flags',
  templateUrl: './feature-flags.component.html',
  styleUrls: ['./feature-flags.component.scss'],
})
export class AdminFeatureFlagsComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  @ViewChild('appEva') appEva: EvaComponent;

  readonly trackBy = trackByIndex;
  readonly SETTINGS_KEY = 'config';

  readonly ENV_CONFIG = [
    { env: FEATURE_FLAGS_ENV.IA, title: 'Internal Admin' },
    { env: FEATURE_FLAGS_ENV.EVA, title: 'Eva' },
  ];

  public environments$: Observable<any>;
  public tableDisabled = false;
  private evaFeatureFlags$ = new BehaviorSubject(null);
  private readonly updateEnvTrigger$ = new Subject();
  private flagsFromEva = null;
  private recalculatedEvaFlags = [];

  constructor(
    private featureFlagsAdminService: FeatureFlagsAdminService,
    private clipboardService: ClipboardService,
    private toastrService: ToastrService,
    private platformLocation: PlatformLocation,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: DialogService,
    private featureFlagsEvaService: FeatureFlagsEvaService,
    private communicationService: CommunicationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenSettingsFromUrl();
    this.initEnvObservable();
  }

  ngAfterViewInit(): void {
    this.listenIframeMessages();
  }

  onReset(): void {
    this.dialog.openConfirm().then((value) => {
      if (!value) {
        return;
      }

      this.resetFlags();
    });
  }

  onChange(env: FEATURE_FLAGS_ENV, flags: any): void {
    if (env === FEATURE_FLAGS_ENV.EVA) {
      this.evaFeatureFlags$.next(null);
      this.featureFlagsAdminService.setAppEva(this.appEva);
      this.appEva.reload();
    }
    this.featureFlagsAdminService.storeAvailableFlagsExecStatus(env, flags);
  }

  async onShare(): Promise<void> {
    const data = await this.featureFlagsAdminService.getRecalculatedFlagsStatus();
    const params = this.featureFlagsAdminService.getEnabledFlags(data).join(',');

    const baseUrl = (<any>this.platformLocation).location.origin;
    const url = `${baseUrl}/#/admin/feature-flags?${this.SETTINGS_KEY}=${params}`;

    this.clipboardService.copy(url);
    this.toastrService.success(`Settings URL: ${url} copied to clipboard.`);
  }

  onReload(): void {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} }).then(() => {
      location.reload();
    });
  }

  async onSaveState(): Promise<void> {
    let flags = this.featureFlagsAdminService.getEnabledFlags(this.recalculatedEvaFlags);

    if (flags.length) {
      this.evaFeatureFlags$.next(null);

      await this.featureFlagsEvaService.storeState(flags).toPromise();
    }

    const data = await this.featureFlagsAdminService.getRecalculatedFlagsStatus();
    flags = this.featureFlagsAdminService.getEnabledFlags(data);

    await this.featureFlagsAdminService.storeState(flags).toPromise();

    this.featureFlagsAdminService.clearGlobalSettingsFetchedFlags();

    this.updateEnvTrigger$.next();
    this.appEva.reload();
  }

  private async resetFlags(): Promise<void> {
    this.featureFlagsAdminService.clearAvailableFlagsExecStatus();
    this.featureFlagsEvaService.clearAvailableFlagsExecStatus();
    this.featureFlagsAdminService.clearGlobalSettingsFetchedFlags();
    const execFlagsEva = await this.updateEvaData();
    this.featureFlagsEvaService.storeAvailableFlagsExecStatus(execFlagsEva.map(({ key, exec }) => ({ [key]: exec })));
    const execFlagsIA = await this.featureFlagsAdminService.getRecalculatedFlagsStatus();
    // eslint-disable-next-line max-len
    this.featureFlagsAdminService.storeAvailableFlagsExecStatus(
      FEATURE_FLAGS_ENV.IA,
      execFlagsIA.map(({ key, exec }) => ({ [key]: exec })),
    );
    this.updateEnvTrigger$.next();
    this.appEva.reload();
  }

  private listenIframeMessages(): void {
    this.subscriptions.push(
      this.communicationService.featureFlagsFromEva$.subscribe((data) => {
        this.provideFeatureFlagsForEva(data);
      }),
    );
  }

  private listenSettingsFromUrl(): void {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe((params) => {
        if (!params) {
          return;
        }
        this.fetchSettingsFromUrl(params);
      }),
    );
  }

  private fetchSettingsFromUrl(params: ParamMap): void {
    const flags = params.get(this.SETTINGS_KEY);
    if (flags === null) {
      return;
    }
    this.applySettings(flags.split(','));
  }

  private applySettings(flags: string[]): void {
    const availableFlags = this.featureFlagsAdminService.getAvailableFlags();
    const execFlags = availableFlags.map((flag) => {
      const index = flags.findIndex((f) => f === flag);

      return { [flag]: index >= 0 };
    });

    this.featureFlagsAdminService.storeAvailableFlagsExecStatus(FEATURE_FLAGS_ENV.IA, execFlags);
  }

  private initEnvObservable(): void {
    // First should be IA and second Eva
    this.environments$ = combineLatest([
      this.updateEnvTrigger$.pipe(
        startWith([null]),
        switchMap(() => this.featureFlagsAdminService.getRecalculatedFlagsStatus()),
      ),
      this.evaFeatureFlags$,
    ]).pipe(
      map((data) => {
        if (!data) {
          return null;
        }
        return data.map((v, k) => {
          return { ...this.ENV_CONFIG[k], data: v };
        });
      }),
    );
  }

  private async updateEvaData(execFlags: KeyValue<boolean>[] = []): Promise<FeatureFlagEntity[]> {
    if (!this.flagsFromEva) {
      return;
    }

    const globalSettingsFlagFromEva = await this.featureFlagsEvaService.fetchAvailableFlagsGlobalSettingsStatus().toPromise();
    const recalculatedFlags = await this.featureFlagsAdminService.getRecalculatedFlagsStatus(
      FEATURE_FLAGS_ENV.EVA,
      this.flagsFromEva.available,
      this.flagsFromEva.build,
      globalSettingsFlagFromEva,
      execFlags,
    );

    this.evaFeatureFlags$.next(recalculatedFlags);

    return recalculatedFlags;
  }

  private async provideFeatureFlagsForEva(flagsFromEva: any): Promise<void> {
    this.flagsFromEva = flagsFromEva;
    this.featureFlagsEvaService.setAppEva(this.appEva);

    this.recalculatedEvaFlags = await this.updateEvaData(this.featureFlagsEvaService.fetchAvailableFlagsExecStatus());
  }
}
