import { autoserialize } from 'cerialize';

export class BaseCrmModel {
  @autoserialize public createdAt: string;
  @autoserialize public ownerUuid: string;
  @autoserialize public targetType: string;
  @autoserialize public targetUuid: string;
  @autoserialize public type: string;
  @autoserialize public uuid: string;
  @autoserialize public updatedAt?: string;
  @autoserialize public instanceId?: number;

  constructor(
    createdAt: string,
    ownerUuid: string,
    targetType: string,
    targetUuid: string,
    type: string,
    uuid: string,
    updatedAt?: string,
    instanceId?: number,
  ) {
    this.createdAt = createdAt;
    this.ownerUuid = ownerUuid;
    this.targetType = targetType;
    this.targetUuid = targetUuid;
    this.type = type;
    this.uuid = uuid;
    this.updatedAt = updatedAt;
    this.instanceId = instanceId;
  }
}
