import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';

import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { UsersService } from './../../../shared/users/service/users.service';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-perm-groups-add',
  templateUrl: './perm-groups-add.component.html',
  styleUrls: ['./perm-groups-add.component.scss'],
})
export class PermGroupsAddComponent extends SubscriberComponent implements OnInit {
  // Fields
  public addPermissionGroupForm: FormGroup;

  public isLocked = false;

  // Accessors
  public get permissions(): FormArray {
    return this.addPermissionGroupForm ? (this.addPermissionGroupForm.get('permissions') as FormArray) : this.formBuilder.array([]);
  }

  public get rootPermissions(): FormArray {
    return this.addPermissionGroupForm ? (this.addPermissionGroupForm.get('rootPermissions') as FormArray) : this.formBuilder.array([]);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: UsersService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  // Methods
  public ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.getData();
      }),
    );

    this.setupComponent();
  }

  public toggleLock(): void {
    this.isLocked = !this.isLocked;
    this.disableForm();
  }

  public getPlaceholder(group: FormGroup): string {
    let result = '';

    if (group) {
      const control = group.get('permDesc');
      if (control) {
        result = control.value;
      }
    }

    return result;
  }

  public onSubmit(): void {
    if (this.addPermissionGroupForm.valid) {
      this.subscriptions.push(
        this.addPermission(this.addPermissionGroupForm.value).subscribe(
          () => {
            const permCode = this.addPermissionGroupForm.value.permCode;
            const permissions = this.addPermissionGroupForm.value.permissions;
            const rootPermissions = this.addPermissionGroupForm.value.rootPermissions;

            const allPermissions = [...permissions, ...rootPermissions];

            this.subscriptions.push(
              this.addSubpermissions(permCode, allPermissions).subscribe(
                () => {
                  this.toastrService.success('New permission group has been successfully added.');
                  this.router.navigate(['../../perm-groups/list'], { relativeTo: this.route });
                },
                (secondError) => {
                  const detailedMessage =
                    secondError && secondError.error && secondError.error.message ? ' ' + secondError.error.message : '';
                  this.toastrService.error('Failed to add new permission group. ' + detailedMessage);
                },
              ),
            );
          },
          (firstError) => {
            const detailedMessage = firstError && firstError.error && firstError.error.message ? ' ' + firstError.error.message : '';
            this.toastrService.error('Failed to add new permission group. ' + detailedMessage);
          },
        ),
      );
    }
  }

  public onCancel(): void {
    this.router.navigate(['../../perm-groups/list'], { relativeTo: this.route });
  }

  private setupComponent(): void {
    this.addPermissionGroupForm = this.formBuilder.group({
      permCode: [{ value: '', disabled: false }, Validators.required],
      permDesc: [{ value: '', disabled: false }, Validators.required],
      generalPerm: [false],
      specSupPerm: [false],
      permissions: this.formBuilder.array([]),
      rootPermissions: this.formBuilder.array([]),
    });
  }

  private getData(): void {
    this.subscriptions.push(
      this.service.getPermissions().subscribe(
        (data) => {
          if (data) {
            const permissions = data.filter((item) => item.assignable === true);
            const rootPermissions = data.filter((item) => item.assignable !== true);

            if (this.addPermissionGroupForm) {
              // get assignable permission groups data
              const groups = permissions.map((permission) =>
                this.formBuilder.group({
                  selected: [false],
                  permCode: [permission.permCode],
                  permDesc: [permission.permDesc],
                }),
              );

              const permissionsArray = this.formBuilder.array(groups);
              this.addPermissionGroupForm.setControl('permissions', permissionsArray);

              // get root permission groups data
              const rootGroups = rootPermissions.map((permission) =>
                this.formBuilder.group({
                  selected: [false],
                  permCode: [permission.permCode],
                  permDesc: [permission.permDesc],
                }),
              );

              const rootPermissionsArray = this.formBuilder.array(rootGroups);
              this.addPermissionGroupForm.setControl('rootPermissions', rootPermissionsArray);
              this.disableForm();
            }
          }
        },
        () => {
          this.toastrService.error('Getting permissions list failed.');
        },
      ),
    );
  }

  private disableForm() {
    if (this.addPermissionGroupForm) {
      this.isLocked ? this.addPermissionGroupForm.disable() : this.addPermissionGroupForm.enable();
    }
  }

  private addPermission(data: any): Observable<any> {
    const init = {
      generalPerm: data.generalPerm,
      specSupPerm: data.specSupPerm,
      permCode: data.permCode,
      permDesc: data.permDesc,
    };

    return this.service.addPermission(init);
  }

  private addSubpermissions(
    permCode: USER_PERMISSION | string,
    permissions: { selected: boolean; permCode: USER_PERMISSION | string; permDesc: string }[],
  ): Observable<any> {
    const permissionsToAdd = permissions.filter((permission) => permission.selected === true);
    if (permissionsToAdd && permissionsToAdd.length > 0) {
      return forkJoin(
        permissionsToAdd.map((permission) => {
          const data = {
            permCode: permission.permCode,
          };
          return this.service.addSubpermission(permCode, data);
        }),
      );
    } else {
      return of({});
    }
  }
}
