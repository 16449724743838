import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { selectLoggedUserModel } from '../../auth/store/auth.actions';
import { DIALOG_SIZE } from '../../shared/dialog.config';
import { UsersService } from '../../shared/users/service/users.service';
import { AppState } from '../../store/app.reducers';
import { ReportsVisibility } from '../model/report-common';
import { ReportsRepositoryService } from '../service/reports-repository.service';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { USER_PERMISSION } from './../../shared/users/user-permissions.enum';
import { B2bWorkflowService } from './../../suppliers/services/workflow/b2b-workflow.service';
import { ReportListModel } from './../model/report-list-model';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit, OnDestroy {
  // Fields
  public reportList: Observable<any[]>;
  public reports: ReportListModel[];
  public selectedVisibilityFilter: ReportsVisibility;
  public visibilityFilterDictionary: { label: string; id: number }[];

  private subscriptions: Subscription[];
  private hasAccessToTableActions = false;

  // Accessors
  public get showTableActions(): boolean {
    return this.hasAccessToTableActions;
  }

  constructor(
    private itemsRepo: ReportsRepositoryService,
    private userSerice: UsersService,
    private store: Store<AppState>,
    private toastrService: ToastrService,
    private b2bWorkflowService: B2bWorkflowService,
    private dialog: MatDialog,
  ) {
    this.subscriptions = [];
  }

  // Methods
  public ngOnInit(): void {
    this.getData();
    this.getPermissions();

    this.setupComponent();
  }

  public ngOnDestroy(): void {
    this.stopMonitoring();
  }

  public showUpdate(report: ReportListModel): boolean {
    return report.googleSheet && report.task && report.task.status !== 2 ? true : false;
  }

  public onSheetUpdate(report: ReportListModel): void {
    const msg = 'Are you sure you want to update? It may take some time and others may be using the file.';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL.width,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.updateSheet(report);
      }
    });
  }

  private getData(): void {
    this.reportList = this.itemsRepo.getItems().pipe(
      flatMap((value) => {
        const reports = value
          .map((report) =>
            Object.assign(report, {
              displayName: report.label || report.name,
            }),
          )
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
        return of(reports);
      }),
    );

    this.reportList.subscribe((data) => {
      this.reports = data;

      this.getSheetsStatus();

      this.stopMonitoring();
      this.startMonitoring();
    });
  }

  private getPermissions(): void {
    this.store.select(selectLoggedUserModel).subscribe((userModel) => {
      if (userModel) {
        this.userSerice.getLogins(userModel.email, USER_PERMISSION.INT_REP_ADMIN).subscribe((data) => {
          if (data && data.length > 0) {
            this.hasAccessToTableActions = true;
          }
        });
      }
    });
  }

  private startMonitoring(): void {
    const subscriptions: Subscription[] = [];

    if (this.reports) {
      this.reports.forEach((report) => {
        if (report.workflowId) {
          const subscription = this.b2bWorkflowService.subscribeTask(report.workflowId, (event) => {
            const data = event;
            report.task = { ...report.task, ...data };
            report.lastUpdated = data.finishedAt ? new Date(data.finishedAt) : undefined;
            report.taskStatus = this.getTaskStatus(data);
          });

          if (subscription) {
            this.subscriptions.push(subscription);
          }
        }
      });
    }
  }

  private stopMonitoring(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => subscription.unsubscribe());
    }
  }

  private getSheetsStatus(): void {
    const observables$: Observable<any>[] = [];

    if (this.reports) {
      this.reports.forEach((report) => {
        if (report.workflowId) {
          const lastExecution$ = this.b2bWorkflowService.getLastExecution(report.workflowId);
          if (lastExecution$) {
            observables$.push(lastExecution$);
          }
        }
      });

      if (observables$ && observables$.length > 0) {
        forkJoin(observables$).subscribe((data) => {
          if (data) {
            data.forEach((singleRecord) => {
              if (singleRecord.task) {
                const report = this.reports.find((singleReport) => singleReport.workflowId === singleRecord.task.name);
                if (report) {
                  report.task = singleRecord.task;
                  report.lastUpdated = singleRecord.task.finishedAt ? new Date(singleRecord.task.finishedAt) : null;
                  report.taskStatus = this.getTaskStatus(singleRecord.task);
                }
              }
            });
          }
        });
      }
    }
  }

  private updateSheet(report: ReportListModel): void {
    const wrokflowData = {
      domain: 'B2B',
      workflowTypeName: 'CLI Command Runner',
      workflowTypeVersion: '1.0',
      workflowId: report.workflowId,
      taskList: 'cli',
      childPolicy: 'TERMINATE',
      executionStartToCloseTimeout: 7200,
      executionStartToCloseTimeoutPeriod: 'SECONDS',
      input: report.task.input,
      tagList: '',
      taskPriority: '',
      taskStartToCloseTimeout: 7200,
      taskStartToCloseTimeoutPeriod: 'SECONDS',
    };
    this.b2bWorkflowService.executeWorkflow(wrokflowData).subscribe(
      (success) => {
        // ...nothing to do here right now
      },
      (error) => {
        this.toastrService.error('Update sheet action failed.');
      },
    );
  }

  private setupComponent(): void {
    this.visibilityFilterDictionary = [
      { label: 'Show Visible Reports', id: ReportsVisibility.showVisible },
      { label: 'Show Hidden Reports', id: ReportsVisibility.showHidden },
      { label: 'Show All Reports', id: ReportsVisibility.showAll },
    ];

    this.selectedVisibilityFilter = ReportsVisibility.showVisible;
  }

  private getTaskStatus(task: any): string {
    let taskStatus = '';

    switch (task.status) {
      case 1:
        taskStatus = 'OK';
        break;
      case 2:
        taskStatus = 'Updating';
        taskStatus += ' (' + task.progressStep + '/' + task.progressCount + ')';
        break;
      case 4:
        taskStatus = 'Timeout';
        break;
      case 6:
        taskStatus = 'Terminated';
        break;
    }

    return taskStatus;
  }
}
