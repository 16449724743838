<div class="row">
  <div class="col-12 col-sm-8">
    <h4>
      Underwriting:
      <span *ngIf="UWDecision; else noUwDecision"> {{ UWDecision }} </span>
      <ng-template #noUwDecision>
        <span>Pending</span>
      </ng-template>
      <small *ngIf="actualHistoryUsed">
        <br />
        Completed by: {{ actualHistoryUsed.completed_by }} on the {{ actualHistoryUsed.updated | date: 'yyyy-MM-dd HH:mm' }}
      </small>
    </h4>
  </div>
  <div class="col-12 col-sm-4 align-self-center">
    <div class="pull-right">
      <app-spinner-button
        *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]"
        (onClick)="runUnderwriting()"
        [disabled]="!underwritingActionStatusKey"
        [loading]="underwritingScriptLoading"
        [customWidth]="'11rem'"
        [text]="'Underwriting Script'"
      >
      </app-spinner-button>
    </div>
  </div>
</div>
