import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { SharedModule } from '../shared.module';
import { DialogService } from './dialog.service';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule, SharedModule],
  providers: [DialogService],
})
export class DialogModule {}
