<h3 mat-dialog-title>Review Final Offer</h3>

<div mat-dialog-content *ngIf="isOffer; else noOffer">
  <app-instant-access *ngIf="isIAccessEnabled" [dataProducts]="dataProducts"></app-instant-access>

  <app-instant-advance *ngIf="isIAdvanceEnabled" [dataProducts]="dataProducts"></app-instant-advance>

  <app-instant-payouts *ngIf="isIPayoutsEnabled" [dataProducts]="dataProducts"></app-instant-payouts>
</div>

<ng-template #noOffer>
  <div mat-dialog-content>
    <p>No products in final offer</p>
  </div>
</ng-template>

<div mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="onSave()">Save</button>
  <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
</div>
