<article class="tl-item">
  <div class="tl-body">
    <div class="tl-entry">
      <div class="tl-icon tl-small-icon btn-events"></div>
      <div class="tl-content">
        <!-- EVENT BODY -->
        <div class="tl-content-body">
          {{ item.createdAt | amAdd: correctTime(item.createdAt):'minutes' | date: 'short' }} -
          <strong *ngIf="item.data.user && item.data.user.email"> by {{ item.data.user.email }}</strong>
          <div [innerHTML]="message"></div>
          <a
            class="btn btn-xs btn-link"
            *ngIf="item.data.extra.inputDict || item.data.extra.kwargs"
            (click)="eventExpanded = !eventExpanded"
            >{{ eventExpanded ? 'Hide params' : 'Show params' }}</a
          >
          <pre *ngIf="eventExpanded">{{ item.data.extra | json }}</pre>
        </div>
      </div>
    </div>
  </div>
</article>
