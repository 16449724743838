import * as moment from 'moment';

export class SummaryOfferModel {
  constructor(
    public underwritingStatus: string,
    public underwritingDate: string,
    public offerStatus: string,
    public createTimestamp: string,
    public updateTimestamp: string,
    public lastUserLogin: string,
  ) {}

  get createDate() {
    return this.parseTimestamp(this.createTimestamp);
  }

  get updateDate() {
    return this.parseTimestamp(this.updateTimestamp);
  }

  parseTimestamp(timestamp: string): string {
    return moment(timestamp).format('YYYY-MM-DD');
  }
}
