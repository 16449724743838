import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isObject } from 'util';

import { ConfigModel } from '../../../shared/configs/config.model';
import { ConfigsService, ConfigTypes } from '../../../shared/configs/service/configs.service';
import { EmailsService } from '../../services/emails.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-email-new-batch',
  templateUrl: './email-new-batch.component.html',
  styleUrls: ['./email-new-batch.component.scss'],
})
export class EmailNewBatchComponent extends SubscriberComponent implements OnInit {
  availableTemplates: ConfigModel[] = [];
  filteredTemplates: Observable<ConfigModel[]>;
  selectedTemplate: ConfigModel;
  form: FormGroup;
  template = new FormControl();
  content = new FormControl();
  suppliersToSend: string[] = [];
  isEditing: boolean;
  errors: string[];
  isLoadingResults: boolean;
  @ViewChildren('inputTextarea') inputTextarea;

  constructor(
    public dialogRef: MatDialogRef<EmailNewBatchComponent>,
    private formBuilder: FormBuilder,
    private configsService: ConfigsService,
    private emailsService: EmailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  ngOnInit() {
    this.isEditing = true;
    this.selectedTemplate = null;
    this.errors = [];
    this.isLoadingResults = false;

    this.form = this.formBuilder.group({
      template: [''],
      content: [''],
    });

    this.subscriptions.push(
      this.form.get('template').valueChanges.subscribe((value) => {
        this.template.reset();
        this.template.setValue(value);
      }),
    );

    this.subscriptions.push(
      this.content.valueChanges.subscribe((value) => {
        this.suppliersToSend = value
          .trim()
          .split(/[\s,]/)
          .map((key) => key.trim())
          .filter((key) => !!key);
        this.validateSuppliers();

        this.form.patchValue({
          content: value,
        });
      }),
    );

    this.subscriptions.push(
      this.configsService.list().subscribe((response) => {
        this.availableTemplates = response.filter((element) => element.configType === ConfigTypes.EMAIL_FRONT_BATCH_CONFIG);
        this.filteredTemplates = this.template.valueChanges.pipe(
          startWith(''),
          map((template) => (template ? this.filterTemplates(template, this.availableTemplates) : this.availableTemplates.slice())),
        );
      }),
    );
  }

  filterTemplates(name: any, availableTemplates) {
    const templateName: string = isObject(name) ? name.configKey : name;
    return availableTemplates.filter((templateModel) => {
      return templateModel.configKey.toLowerCase().indexOf(templateName.toLowerCase()) === 0;
    });
  }

  setTemplate($event) {
    const eventTemplate = $event.option.value;
    if (eventTemplate) {
      this.selectedTemplate = eventTemplate;
      this.form.patchValue({
        template: eventTemplate.configKey,
      });
    }
  }

  saveBatch(): void {
    this.subscriptions.push(
      this.emailsService.sendBatch(this.suppliersToSend, this.selectedTemplate.configKey).subscribe((response) => {
        this.closeDialog(response);
      }),
    );
  }

  toggleEditingTextarea() {
    this.isEditing = !this.isEditing;
    this.focusOnTextarea();
  }

  focusOnTextarea() {
    setTimeout(() => {
      this.inputTextarea.first.nativeElement.focus();
    }, 500);
  }

  focusoutTextarea($event) {
    const valueTextarea: string = $event.target.value;
    if (valueTextarea) {
      this.isEditing = false;
    }
  }

  showCreateButton(): boolean {
    return this.errors.length === 0 && this.suppliersToSend.length !== 0 && this.selectedTemplate !== null;
  }

  private validateSuppliers() {
    this.errors = [];
    this.suppliersToSend.forEach((supplierKey) => {
      if (supplierKey.length !== 36) {
        this.errors.push(`Key ${supplierKey} is not correct UUID`);
      }
    });
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }
}
