<ng-template #badgeLoading>
  <app-loading-spinner [size]="1"></app-loading-spinner>
</ng-template>

<ng-template #badgeError>
  <a class="badge-reload" (click)="reloadCount($event)">
    <mat-icon class="material-icons">autorenew</mat-icon>
  </a>
</ng-template>

<span *ngIf="tasksCountItems !== null; else badgeLoading">
  <a
    mat-button
    class="bagde-notification"
    [routerLink]="['/tasks/list', badgeSettings.filterId, 'filter']"
    [ngClass]="[badgeSettings.customClass ? badgeSettings.customClass : '', !tasksCountItems.count ? 'badge-empty' : '']"
  >
    <span class="nav-icon">{{ tasksCountItems.count }}</span>
    <span class="nav-text">{{ badgeSettings.label }}</span>
  </a>
</span>
