import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';

import { CurrencyCellRenderer } from '../../../../shared/functions/currency-cell-renderer';
import { DateCellRenderer, DateCellWithoutLocalDateRenderer } from '../../../../shared/functions/date-cell-renderer';
import { NumberCellRenderer } from '../../../../shared/functions/number-cell-renderer';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-approved-advances',
  templateUrl: './approved-advances.component.html',
})
export class ApprovedAdvancesComponent extends SubscriberComponent implements OnInit {
  readonly reportName: string = 'supplier_advances';
  @Input() approvedAdvances: Observable<any>;
  tableSettings: any;

  private baseHttpParams: any;
  public columns: ColDef[];
  public rows: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.baseHttpParams = {
      limit: 20,
      offset: 0,
    };

    this.columns = [
      {
        field: 'advance_total_amount',
        headerName: 'Approved Amount',
        width: 120,
        cellRenderer: (cellData: any) => {
          const formattedValue = new CurrencyPipe('en-US').transform(cellData.value, 'USD', 'symbol');
          return `<a class="pull-right" href="${location}/advance/${cellData.data.supplier_advance_key}">${formattedValue}</a>`;
        },
      },
      {
        field: 'reserve_total_amount',
        headerName: 'Reserve Total Amount',
        cellRenderer: CurrencyCellRenderer,
        width: 120,
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 60,
      },
      {
        field: 'origination_date',
        headerName: 'Origination Date',
        cellRenderer: DateCellWithoutLocalDateRenderer,
        width: 100,
        headerTooltip: 'The date is in NYC local time.',
      },
      {
        field: 'maturity_date',
        headerName: 'Maturity',
        cellRenderer: DateCellRenderer,
        width: 100,
      },
      {
        field: 'expiration_date',
        headerName: 'Expiration Date',
        cellRenderer: DateCellRenderer,
        width: 100,
      },
      {
        field: 'extra_reserve_percent',
        headerName: 'Extra Reserve Percent',
        cellRenderer: NumberCellRenderer,
        width: 60,
      },
      {
        field: 'extra_reserve_amount',
        headerName: 'Extra Reserve Amount',
        cellRenderer: CurrencyCellRenderer,
        width: 120,
      },
      {
        field: 'fee_percent',
        headerName: 'Fee',
        cellRenderer: NumberCellRenderer,
        width: 70,
      },
      {
        field: 'status',
        headerName: 'Advance Status',
      },
      {
        field: 'net_distribution',
        headerName: 'Net Distribution',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'outstanding_amount',
        headerName: 'Outstanding Amount',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'writeoff',
        headerName: 'Writeoff Amount',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'grace_period_end_date',
        headerName: 'Grace Period End Date',
        cellRenderer: DateCellRenderer,
        width: 100,
      },
      {
        field: 'grace_period_length',
        headerName: 'Grace Period Length (Days)',
        width: 80,
      },
      {
        field: 'no_rebate',
        headerName: 'No Rebate',
        width: 60,
      },
    ];
    this.subscriptions.push(
      this.approvedAdvances.subscribe((data) => {
        this.rows = data.results;
      }),
    );
  }
}
