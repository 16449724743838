<h1 mat-dialog-title>Add config</h1>

<div mat-dialog-content>
  <div [formGroup]="settingForm">
    <mat-form-field class="full-width">
      <input matInput placeholder="Config key" formControlName="config_key" />
      <mat-error>This field is required and cannot contain any spaces.</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-select placeholder="Config type" formControlName="config_type">
        <mat-option *ngFor="let configType of configTypes" [value]="configType" (click)="setConfigType(configType)">
          {{ configType }}
        </mat-option>
      </mat-select>
      <mat-error>This field is required.</mat-error>
    </mat-form-field>

    <div *ngIf="configType" [ngSwitch]="configType">
      <mat-form-field *ngSwitchCase="'String'" class="full-width">
        <input matInput *ngSwitchCase="'String'" placeholder="Value" formControlName="config_value" />
      </mat-form-field>

      <mat-form-field *ngSwitchCase="'Number'" class="full-width">
        <input matInput *ngSwitchCase="'Number'" placeholder="Value" formControlName="config_value" />
      </mat-form-field>

      <app-editor *ngSwitchCase="'Html'" formControlName="config_value" [toolsTheme]="TOOLS_THEME.EMAIL" #appEditor> </app-editor>

      <app-key-value-dictionary *ngSwitchCase="'Dict'"></app-key-value-dictionary>

      <app-list-template *ngSwitchCase="'List'"></app-list-template>

      <app-nested-list-template *ngSwitchCase="'Nested list'"></app-nested-list-template>

      <app-task-template *ngSwitchCase="'Task template'"></app-task-template>

      <!-- <app-email-base-template *ngSwitchCase="'Email base template'"></app-email-base-template>

      <app-email-frontend-template *ngSwitchCase="'Email frontend template'"></app-email-frontend-template>

      <app-frontend-email-config *ngSwitchCase="'Frontend email config'"></app-frontend-email-config>

      <app-batch-supplier-email *ngSwitchCase="'Batch supplier email'"></app-batch-supplier-email>

      <app-email-config *ngSwitchCase="'Email config'"></app-email-config> -->
    </div>
  </div>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="submit()" [disabled]="!settingForm.valid" tabindex="2" color="accent">Save</button>
  <button mat-raised-button (click)="closeDialog(false)" tabindex="-1" color="warn">Cancel</button>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
