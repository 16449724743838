import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Deserialize, DeserializeKeysFrom } from 'cerialize';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { ServiceUpdatePartialInterface } from '../../shared/interfaces/service-update-partial-interface/service-update-partial-interface';
import { SupplierMarketplaceDetailsModel } from '../model/supplier-marketplace.model';
import { SupplierMarketplaceFundingModel } from '../model/supplier-marketplace-funding.model';

@Injectable()
export class SupplierMarketplaceFundingService implements ServiceUpdatePartialInterface {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers';
  }

  fetchItems(supplierKey, mpSupKey, refresh: boolean = false) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${mpSupKey}`;
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient
      .get<SupplierMarketplaceDetailsModel>(endpointUrl, {
        params: queryParams,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(result, SupplierMarketplaceFundingModel);
        }),
      );
  }

  saveItemPart(itemKey: string, itemPart: any, ...params): Observable<any> {
    const mpSupKey = params[0]; // TODO nkler: how to resolve it dynamicly but without indexed array?
    return this.saveMarketplaceFundingDetails(itemKey, mpSupKey, itemPart);
  }

  saveMarketplaceFundingDetails(supplierKey, mpSupKey, data) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${mpSupKey}`;

    // FIXME nkler: should be fixed on reactive forms side - @Luke HELP NEEDED ;)
    data.advanceRate = +data.advanceRate;
    data.minimumReservePct = +data.minimumReservePct;
    data.creditLimit = +data.creditLimit;

    return this.httpClient.put(endpointUrl, data).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }
}
