<article class="tl-item">
  <div class="tl-body">
    <div class="tl-entry">
      <div class="tl-icon btn-icon-round btn-icon btn-icon-thin btn-tasks"><i class="material-icons">assignment</i></div>
      <div class="tl-content">
        <ul class="tl-data-summary">
          <li><span>#</span> {{ item.itemNumber }}</li>
          <li class="pill"><i class="material-icons">person</i>Author: {{ getName(item?.ownerUuid) || item?.createdByName }}</li>
          <li><i class="material-icons">event_note</i>Created At: {{ item.createdAt | fromUnix | date: 'short' }}</li>
          <li><i class="material-icons">update</i>Last Edited: {{ item.updatedAt | fromUnix | date: 'short' }}</li>
        </ul>
        <ul class="tl-action-buttons pull-right">
          <li *ngIf="hasAccessTo"><a app-confirm-delete (onConfirm)="deleteTask()"></a></li>
          <li>
            <a (click)="openEditTaskDialog()"><i class="material-icons">edit</i></a>
          </li>
          <li>
            <a ngxClipboard [cbContent]="getTaskLink()" matTooltip="Copy task link" (click)="handleCopyLink($event)"
              ><i class="material-icons">insert_link</i></a
            >
          </li>
        </ul>

        <div class="tl-title">
          <a (click)="openEditTaskDialog()">{{ item.title }}</a>
        </div>
        <ul class="tl-data-details">
          <li>
            <i class="material-icons">{{ item.assigneeType === 'group' ? 'group' : 'person' }}</i
            >Assignee: {{ getName(item.assigneeUuid) }}
          </li>
          <li><i class="material-icons">access_time</i>Due Date: {{ item.dueDate | fromUnix | date: 'shortDate' }}</li>
          <li *ngIf="statuses && item.status && statuses[item.status]">
            <i class="material-icons">assessment</i>Status: {{ statuses[item.status].label }}
          </li>
          <li class="pull-right">
            <a appTaskNextStatus [task]="item" (nextStatus)="setNextLabel($event)"
              ><i class="material-icons">fast_forward</i> {{ nextStatusLabel }}</a
            >
          </li>
        </ul>

        <!-- COMMENTS SECTION -->
        <hr />
        <app-comment-list
          [latestComments]="item.latestComments"
          [commentsCount]="item.commentsCount"
          [targetUuid]="item.uuid"
          [taskBody]="item.body"
          targetType="task"
          [createdAt]="item.createdAt"
          [updatedAt]="item.updatedAt"
        >
        </app-comment-list>
      </div>
    </div>
  </div>
</article>
