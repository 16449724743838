import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, flatMap, tap } from 'rxjs/operators';

import { ReportListModel } from '../../../../reports/model/report-list-model';
import { ReportsRepositoryService } from '../../../../reports/service/reports-repository.service';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-report-list',
  templateUrl: './supplier-report-list.component.html',
  styleUrls: ['./supplier-report-list.component.less'],
})
export class SupplierReportListComponent extends SubscriberComponent implements OnInit {
  public reports: Observable<ReportListModel[]>;

  reportList: any;
  supplierKey: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
    private readonly itemsRepo: ReportsRepositoryService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
        )
        .subscribe((data) => {
          this.getReportList(data.instantAccess);
        }),
    );
  }

  private getReportList(isInstantAccess: boolean): void {
    this.reportList = this.itemsRepo.getItems().pipe(
      flatMap((value) => {
        const reports = value
          .map((report) =>
            Object.assign(report, {
              displayName: report.label || report.name,
            }),
          )
          .filter((report) => {
            if (report.enabledForSupplier === 'all') {
              return true;
            } else {
              return isInstantAccess ? report.enabledForSupplier === 'instant-access' : report.enabledForSupplier === 'non-instant-access';
            }
          })
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
        return of(reports);
      }),
    );
  }
}
