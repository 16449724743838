import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-task-status-badge',
  templateUrl: './task-status-badge.component.html',
  styleUrls: ['./task-status-badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskStatusBadgeComponent {
  @Input() status;
  constructor() {}
}
