import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';

import { AVAILABLE_STATEMENTS, OTHER_MARKETPLACE_FLAG } from '../../../shared/consts/available-statements.const';
import { TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';

@Component({
  selector: 'app-add-marketplace-statement',
  templateUrl: './add-marketplace-statement.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMarketplaceStatementComponent extends BaseSupplierAction implements OnInit {
  readonly actionTitle = 'Add New Marketplace Statement';
  readonly availableStatements = AVAILABLE_STATEMENTS;

  constructor(
    readonly formBuilder: FormBuilder,
    readonly store: Store<AppState>,
    readonly dialogRef: MatDialogRef<BaseSupplierAction>,
    readonly cliService: CliService,
    readonly supplierService: SupplierService,
    readonly eventsService: TableEventsService,
    readonly toastrService: ToastrService,
    readonly cd: ChangeDetectorRef,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.push(this.store.select(selectSupplierDetails).subscribe((data) => this.assignMarketplaces(data)));
  }

  createForm(): void {
    this.supplierActionForm = this.formBuilder.group({
      sup_name: ['', Validators.required],
      mp_sup_key: ['', Validators.required],
      start_date: [null, Validators.required],
      end_date: [null],
      end_bal: ['', Validators.required],
      statement_id: ['', Validators.required],
      statement_code: ['', Validators.required],
      begin_bal: ['', Validators.required],
      mp_pmt_amount: [0, Validators.required],
    });
    this.supplierActionForm.disable();
  }

  submit(): void {
    if (!this.supplierActionForm.valid) {
      return;
    }

    const startDate = this.supplierActionForm.get('start_date').value;
    const endDate = this.supplierActionForm.get('end_date').value;

    const dataToSave = {
      mp_sup_key: this.supplierActionForm.get('mp_sup_key').value,
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      end_bal: this.supplierActionForm.get('end_bal').value,
      statement_id: this.supplierActionForm.get('statement_id').value.trim(),
      statement_code: this.mapStatementCode(this.supplierActionForm.get('statement_code').value),
      begin_bal: this.supplierActionForm.get('begin_bal').value,
      mp_pmt_amount: this.supplierActionForm.get('mp_pmt_amount').value,
    };

    this.supplierService
      .createMarketplaceStatement(dataToSave)
      .pipe(tap(() => this.toastrService.success('New marketplace statement saved successfully!')))
      .subscribe(
        () => this.dialogRef.close(true),
        (error) => this.toastrService.error(error && error.error && error.error.message ? error.error.message : 'Saving failed.'),
      );
  }

  private mapStatementCode(formValue: string): string | null {
    if (formValue === OTHER_MARKETPLACE_FLAG) {
      return null;
    }

    return formValue;
  }

  private assignMarketplaces(data: SupplierDetailsModel): void {
    this.supplierActionForm.enable();

    if (!data) {
      return;
    }

    this.supplierKey = data.supplierKey;
    this.supplierActionForm.controls.sup_name.setValue(data.legalName);
    this.marketplaces = data.marketplaces.map((mp) => {
      return {
        label: mp.externalSupplierId ? `${mp.marketplaceName} (${mp.externalSupplierId})` : mp.marketplaceName,
        mp_key: mp.mpSupKey,
      };
    });

    this.cd.detectChanges();
  }
}
