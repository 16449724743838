import { autoserialize } from 'cerialize';

export class FileTypeModel {
  @autoserialize
  public uuid: string;

  @autoserialize
  public name: string;

  @autoserialize
  public children: any[];

  constructor(uuid: string, name: string, children: any[]) {
    this.uuid = uuid;
    this.name = name;
    this.children = children;
  }
}
