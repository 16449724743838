import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { EventsService } from '../events.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-rules-history-list',
  templateUrl: './rules-history-list.component.html',
  styleUrls: ['./rules-history-list.component.scss'],
})
export class RulesHistoryListComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  loaded = false;
  pending = false;
  refresh$ = new BehaviorSubject('');

  dataSource = [];
  private last: any;

  resultsLength: number;
  itemsPerPage: number;
  pageIndex: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private eventsService: EventsService, private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.resultsLength = 0;
    this.pageIndex = 0;
  }

  ngAfterViewInit() {
    const pagination$ = this.paginator.page.pipe(
      startWith({ pageSize: 10 }),
      tap((event) => {
        if (event.pageSize !== this.itemsPerPage) {
          this.last = undefined;
        }
        this.itemsPerPage = event.pageSize;
      }),
    );

    const load$ = combineLatest(this.route.paramMap, this.refresh$, pagination$).pipe(
      filter(([params, ref, pageEvent]) => params.has('eventRuleId')),
      switchMap(([params, ref, pageEvent]) => {
        const page = pageEvent as PageEvent;
        return this.eventsService.historyList(params.get('eventRuleId'), page.pageSize, this.last);
      }),
      tap((resp) => {
        this.pending = false;
        this.last = resp.meta.last;
        this.resultsLength = resp.meta.total_items;
        if (!resp.meta.last) {
          this.loaded = true;
        }
      }),
      map((resp) => {
        return resp.data;
      }),
      map((resp) => {
        resp.map((event) => {
          if (event.executed_actions) {
            for (const action of event.executed_actions) {
              action.execution_date_formatted = `${new Date(action.execution_date * 1000)}`;
              action.extra.to_execute_at_formatted = `${new Date(action.extra.to_execute_at * 1000)}`;
            }
          }
        });

        return resp;
      }),
    );

    this.subscriptions.push(load$.subscribe((response) => (this.dataSource = response)));
  }
}

function safeGet(o, props) {
  return props.split('.').reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
}
