export class PurchaseFeeModel {
  feeScheduleKey: string;
  feeScheduleType: string;
  feeScheduleName: string;
  feeScheduleNames: string[];
  feePercentage: string;
  fixedFee: string;
  isCollapsed = true;
  isEditable = false;
  isTier = false;
  marketplaceName: string;
  marketplaceKey: string;
  tierStart: string;
  tierEnd: string;
}
