<td class="mdl-data-table__cell--non-numeric">
  <app-cli-action-name [actionData]="actionData"></app-cli-action-name>
</td>
<td class="mdl-data-table__cell--non-numeric">
  <app-cli-action-button
    [actionData]="actionData"
    [isDisabled]="isDisabled"
    (openCliActionDialog)="openCliActionDialog()"
  ></app-cli-action-button>
</td>
<td class="mdl-data-table__cell--non-numeric">
  <app-cli-action-status [progressValue]="progressValue" [executionStatus]="executionStatus"></app-cli-action-status>
</td>
<td class="mdl-data-table__cell--non-numeric">
  <app-cli-action-last-run *ngIf="userInfo" [userInfo]="userInfo"></app-cli-action-last-run>
</td>
<td class="mdl-data-table__cell--non-numeric">
  <app-cli-action-templates></app-cli-action-templates>
</td>
<td class="mdl-data-table__cell--non-numeric">
  <app-cli-action-results (click)="downloadResult()"></app-cli-action-results>
</td>
