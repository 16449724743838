import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { EmailListComponent } from '../crm-tools/emails/email-list/email-list.component';
import { EmailPreviewComponent } from '../crm-tools/emails/email-preview/email-preview.component';
import { FeatureFlagsGeneralGuard } from '../guards/feature-flags-general.guard';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';
import { RouteAccessResolver } from '../shared/utils/route-access-resolver';

export const routes: Routes = [
  {
    path: 'emails',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
      },
    },
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: EmailListComponent },
      { path: 'list/:filterId/filter', component: EmailPreviewComponent },
    ],
  },
];
