import { Component, Input, OnInit } from '@angular/core';

import { IFlexParticipationMarketplace } from '../../../service/flex-participation-interfaces';

@Component({
  selector: 'app-marketplace-status-indicator',
  templateUrl: './marketplace-status-indicator.component.html',
  styleUrls: ['./marketplace-status-indicator.component.scss'],
})
export class MarketplaceStatusIndicatorComponent implements OnInit {
  @Input() marketplace: IFlexParticipationMarketplace;

  public statusTooltip: string;
  public readyForFlex: boolean;
  constructor() {}

  ngOnInit() {
    this.readyForFlex = this.marketplace.readyForFlex;
    this.buildTooltip();
  }

  buildTooltip() {
    const { marketplaceName, reserve, readyForFlex } = this.marketplace;
    this.statusTooltip = ` ${marketplaceName} is ${!readyForFlex ? 'not' : ''} ready for freemium & flex participation,
      status: ${this.getStatusesFromMarketplace()},
      reserve: ${reserve}
    `;
  }

  getStatusesFromMarketplace() {
    const statuses = ['accepted', 'draft', 'hidden', 'rejected', 'suspended'];

    return statuses.reduce((tooltip: string, status: string) => {
      if (!this.marketplace[status]) {
        return tooltip;
      }

      if (!tooltip.length) {
        return status;
      }

      return `${tooltip},${status}`;
    }, '');
  }
}
