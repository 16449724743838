import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { snakeCaseToTitleCase } from '../../../../shared/functions/snake-case-to-title-case';
import { AppState } from '../../../../store/app.reducers';
import { DocumentationReportSection } from '../../../model/documentation/documentation-report-section.model';
import { DocumentationReportSectionData } from '../../../model/documentation/documentation-report-section-data.model';
import { DocumentationReportTable } from '../../../model/documentation/documentation-report-table.model';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { SupplierOwnerModel } from '../../../model/supplier-owner.model';
import { DocumentationService } from '../../../services/documentation/documentation.service';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent {
  public ownerName$: Observable<string>;
  public availableSections$: Observable<DocumentationReportSection[]>;
  public reportData$: Observable<Record<string, DocumentationReportTable>>;
  public supplierKey$: Observable<string>;
  public owner$: Observable<SupplierOwnerModel>;
  public requestsLoading = true;

  private supplierDetailsModel$: Observable<SupplierDetailsModel>;
  private readonly noOwnerErrorMessage = 'ERROR: No owners found for this supplier. Try refreshing the page.';

  constructor(
    private documentationService: DocumentationService,
    private store: Store<AppState>,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
  ) {
    this.initializeData();
  }

  initializeData(): void {
    this.supplierDetailsModel$ = this.store.select(selectSupplierDetails).pipe(
      filter((s) => !!s),
      shareReplay(1),
    );

    this.supplierKey$ = this.supplierDetailsModel$.pipe(map((supplier) => supplier.supplierKey));

    this.owner$ = this.supplierDetailsModel$.pipe(
      map((supplier) => this.pickOwner(supplier)),
      shareReplay(1),
    );

    this.ownerName$ = this.owner$.pipe(map((owner) => `${owner.firstName} ${owner.lastName}`));

    this.availableSections$ = combineLatest([this.supplierDetailsModel$, this.owner$]).pipe(
      switchMap(([supplier, owner]) => {
        return this.documentationService.fetchAvailableSectionsList(supplier.supplierKey, owner.loginKey);
      }),
      shareReplay(1),
    );

    this.reportData$ = this.availableSections$.pipe(
      map((sections) => {
        return this.getEmptyReportData(sections.map((s) => s.value));
      }),
    );
  }

  onFilterOptionsChanged(filteredSectionKeys: string[]): void {
    this.requestsLoading = true;
    this.reportData$ = combineLatest([this.availableSections$, this.supplierDetailsModel$, this.owner$]).pipe(
      switchMap(([allSections, supplier, owner]) => {
        const filteredSections = allSections.filter((s) => filteredSectionKeys.includes(s.value));
        return this.fetchReportData(filteredSections, supplier.supplierKey, owner.loginKey);
      }),
      startWith(this.getEmptyReportData(filteredSectionKeys)),
    );
  }

  private pickOwner(supplier: SupplierDetailsModel): SupplierOwnerModel {
    if (!supplier.owners || supplier.owners.length < 1) {
      this.toastrService.error(this.noOwnerErrorMessage);
      throw new Error(this.noOwnerErrorMessage);
    }

    const mainOwner = supplier.owners.find((owner) => owner.mainOwner) || supplier.owners[0];

    if (!mainOwner.SSN) {
      mainOwner.SSN = supplier.ownerSSN;
    }

    return mainOwner;
  }

  private fetchReportData(
    sections: DocumentationReportSection[],
    supplierKey: string,
    personKey: string,
  ): Observable<Record<string, DocumentationReportTable>> {
    this.requestsLoading = true;
    return this.documentationService
      .fetchSections(sections, supplierKey, personKey)
      .map((sectionsData: DocumentationReportSectionData[]) => this.mapSectionsToDocumentationReportTable(sectionsData))
      .pipe(
        catchError(() => {
          this.requestsLoading = false;
          return of(this.getErroneousSections(sections));
        }),
      );
  }

  private mapSectionsToDocumentationReportTable(sectionsData: DocumentationReportSectionData[]): Record<string, DocumentationReportTable> {
    this.requestsLoading = false;
    this.cd.detectChanges();

    const result = {};
    sectionsData.forEach((section) => {
      result[section.value] = this.mapSectionToDocumentationReportTable(section);
    });
    return result;
  }

  private mapSectionToDocumentationReportTable(section: DocumentationReportSectionData): DocumentationReportTable {
    const columns = ['Field', 'Value'];
    const rows = [];

    const keyValueObject = section && section.data && section.data[0] ? section.data[0] : {};

    Object.keys(keyValueObject).forEach((key) => {
      if (key) {
        const value = keyValueObject[key] ? keyValueObject[key] : '-';
        rows.push([key, value]);
      }
    });

    return {
      title: section.name,
      reports: {
        columns: columns.map(snakeCaseToTitleCase),
        rows,
      },
      type: section.value,
      loaded: true,
      loading: false,
      error: false,
    };
  }

  private getEmptyReportData(sectionKeys: string[] = []): Record<string, DocumentationReportTable> {
    return sectionKeys.reduce((sofar, key) => ({ ...sofar, [key]: this.getEmptySection(key) }), {});
  }

  private getEmptySection(sectionKey: string): DocumentationReportTable {
    return {
      title: 'Empty Section',
      reports: {
        columns: [],
        rows: [],
      },
      type: sectionKey,
      loaded: false,
      loading: true,
      error: false,
    };
  }

  private getErroneousSections(sections: DocumentationReportSection[]): Record<string, DocumentationReportTable> {
    const result = {};
    sections.forEach((section) => {
      result[section.value] = this.getErroneousSection(section.value);
    });
    return result;
  }

  private getErroneousSection(sectionKey: string): DocumentationReportTable {
    return {
      title: 'ERROR',
      reports: {
        columns: [],
        rows: [],
      },
      type: sectionKey,
      loaded: false,
      loading: false,
      error: true,
    };
  }
}
