import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined, isUndefined } from 'util';

import { CliService } from '../../../services/cli/cli.service';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { AppState } from './../../../../store/app.reducers';

@Component({
  selector: 'app-cancel-transaction',
  templateUrl: './cancel-transaction.component.html',
  styleUrls: ['./cancel-transaction.component.scss'],
})
export class CancelTransactionComponent extends SubscriberComponent implements OnInit {
  // Fields
  private supplierKey: string;

  public title: string;
  public transactionKey: string;
  public reason: string;

  // Flags
  private isOpperationSubmited = false;
  private isOpperationConfirmed = false;

  // Accessors
  public get invalid(): boolean {
    return isNullOrUndefined(this.reason) || this.reason.length === 0;
  }

  public get showEditionScreen(): boolean {
    return !this.isOpperationSubmited && !this.isOpperationConfirmed;
  }

  public get showConfirmationScreen(): boolean {
    return this.isOpperationSubmited && !this.isOpperationConfirmed;
  }

  constructor(
    private cliService: CliService,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<CancelTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();

    if (!isNullOrUndefined(data)) {
      this.title = data.title;
      this.transactionKey = data.transactionKey;
    }
  }

  // Methods
  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierData) => {
        if (!isUndefined(supplierData)) {
          this.supplierKey = supplierData.supplierKey;
        }
      }),
    );
  }

  public submitOnClick(): void {
    this.isOpperationSubmited = true;
  }

  public denyOnClick(): void {
    this.isOpperationSubmited = false;
  }

  public confirmOnClick(): void {
    const data = {
      reason: this.reason,
      supplier_key: this.supplierKey,
      xact_key: this.transactionKey,
    };

    this.subscriptions.push(
      this.cliService.cancelXact(data).subscribe(
        () => {
          this.toastrService.success('Transaction canceled successfuly.');
          this.closeDialog({ success: true });
        },
        (error) => {
          const message = error.error && error.error.message ? error.error.message : 'An error occurred while canceling transaction';
          this.toastrService.error(message);
        },
      ),
    );
  }

  public cancelOnClick(): void {
    this.closeDialog();
  }

  private closeDialog(payload?: any): void {
    this.dialogRef.close(payload);
  }
}
