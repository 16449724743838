import { autoserialize } from 'cerialize';

export class SupplierMarketplaceFundingModel {
  @autoserialize
  delayedCollectionFeeScheduleKey?: any;

  @autoserialize
  fundingPeriodGroup: string;

  @autoserialize
  accepted: boolean;

  @autoserialize
  currentFeeScheduleKey: string;

  @autoserialize
  lastOptOutDate?: any;

  @autoserialize
  mktPmtLag?: any;

  @autoserialize
  postTrialFeeScheduleKey: string;

  @autoserialize
  creditLimit: number;

  @autoserialize
  marketplacePaysBankFee: boolean;

  @autoserialize
  minFeeStartDateOverride?: any;

  @autoserialize
  marketplacePaysReserve: boolean;

  @autoserialize
  freeTrialEndDate?: any;

  @autoserialize
  lastOptInDate?: any;

  @autoserialize
  currMonthMinFee?: any;

  @autoserialize
  currMinFeeStartDate?: any;

  @autoserialize
  advanceRate: number;

  @autoserialize
  oldestOptInDate?: any;

  @autoserialize
  minFeeEndDateOverride?: any;

  @autoserialize
  oldestOptOutDate?: any;

  @autoserialize
  currMinFeeEndDate?: any;

  @autoserialize
  minimumReservePct: number;

  @autoserialize
  marketplacePaysCommission: boolean;

  @autoserialize
  balanceBasedFeesStartDate: string;

  @autoserialize
  concentrationLimit?: any;

  @autoserialize
  fundPartStatus: string;

  constructor(
    delayedCollectionFeeScheduleKey: any,
    fundingPeriodGroup: string,
    accepted: boolean,
    currentFeeScheduleKey: string,
    lastOptOutDate: any,
    mktPmtLag: any,
    postTrialFeeScheduleKey: string,
    creditLimit: number,
    marketplacePaysBankFee: boolean,
    minFeeStartDateOverride: any,
    marketplacePaysReserve: boolean,
    freeTrialEndDate: any,
    lastOptInDate: any,
    currMonthMinFee: any,
    currMinFeeStartDate: any,
    advanceRate: number,
    oldestOptInDate: any,
    minFeeEndDateOverride: any,
    oldestOptOutDate: any,
    currMinFeeEndDate: any,
    minimumReservePct: number,
    marketplacePaysCommission: boolean,
    balanceBasedFeesStartDate: string,
    concentrationLimit: any,
    fundPartStatus: string,
  ) {
    this.accepted = accepted;
    this.advanceRate = advanceRate;
    this.balanceBasedFeesStartDate = balanceBasedFeesStartDate;
    this.concentrationLimit = concentrationLimit;
    this.creditLimit = creditLimit;
    this.currentFeeScheduleKey = currentFeeScheduleKey;
    this.currMinFeeEndDate = currMinFeeEndDate;
    this.currMinFeeStartDate = currMinFeeStartDate;
    this.currMonthMinFee = currMonthMinFee;
    this.delayedCollectionFeeScheduleKey = delayedCollectionFeeScheduleKey;
    this.freeTrialEndDate = freeTrialEndDate;
    this.fundingPeriodGroup = fundingPeriodGroup;
    this.fundPartStatus = fundPartStatus;
    this.lastOptInDate = lastOptInDate;
    this.lastOptOutDate = lastOptOutDate;
    this.marketplacePaysBankFee = marketplacePaysBankFee;
    this.marketplacePaysCommission = marketplacePaysCommission;
    this.marketplacePaysReserve = marketplacePaysReserve;
    this.minFeeEndDateOverride = minFeeEndDateOverride;
    this.minFeeStartDateOverride = minFeeStartDateOverride;
    this.minimumReservePct = minimumReservePct;
    this.mktPmtLag = mktPmtLag;
    this.oldestOptInDate = oldestOptInDate;
    this.oldestOptOutDate = oldestOptOutDate;
    this.postTrialFeeScheduleKey = postTrialFeeScheduleKey;
  }
}
