import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { ConfigModel } from '../../../shared/configs/config.model';
import { ConfigsService, ConfigTypes } from '../../../shared/configs/service/configs.service';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { TOOLS_THEME } from '../../../shared/editor/editor.enum';
import { PermissionModel } from '../../../shared/users/permission.model';
import { UsersService } from '../../../shared/users/service/users.service';
import { UserModel } from '../../../shared/users/user.model';
import { AppState } from '../../../store/app.reducers';
import { SupplierListModel } from '../../../suppliers/model/supplier-list.model';
import { SupplierService } from '../../../suppliers/services/supplier.service';
import { selectSupplierDetails } from '../../../suppliers/store/supplier/supplier.actions';
import { EmailsService } from '../../services/emails.service';
import { CrmItemStatus } from '../../shared/crm.enums';
import { selectPendingCrmStates, SendSingleEmail } from '../../store/crm.actions';
import { EmailPreviewComponent } from '../email-preview/email-preview.component';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss'],
})
export class EmailSendComponent extends SubscriberComponent implements OnInit {
  readonly TOOLS_THEME = TOOLS_THEME;
  actionId = new Date().toString();
  emailSendForm: FormGroup;

  pendingSend = false;
  pendingPreview = false;

  loggedUser = null;
  showBcc = false;
  supplierName = 'Test';
  supplierEmail = 'test';

  template = new FormControl();
  templateOptions: Observable<ConfigModel[]>;

  @ViewChild('inputCC', { static: true }) inputCC: MatInput;
  cc = new FormControl();
  ccSearch: string;
  ccSelected = [];

  @ViewChild('inputBCC', { static: true }) inputBCC: MatInput;
  bcc = new FormControl();
  bccSearch: string;
  bccSelected = [];

  usersOptions: (UserModel | PermissionModel)[] = [];
  usersPending = true;

  supplier = new FormControl();
  filteredSuppliers: Observable<SupplierListModel[]>;
  defaultSupplierSearch = 'created_since:' + new Date().toISOString().slice(0, 10).replace(/-/g, '/');
  suppliersPending = false;

  constructor(
    public dialogRef: MatDialogRef<EmailSendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private usersService: UsersService,
    private supplierService: SupplierService,
    private configsService: ConfigsService,
    private emailsService: EmailsService,
    public dialog: MatDialog,
  ) {
    super();
  }

  displayUsersFn(user: UserModel): string {
    return !isNullOrUndefined(user) ? (typeof user === 'object' ? user.name : user) : '';
  }

  ngOnInit() {
    this.createForm();

    // Close dialog after save
    this.subscriptions.push(
      this.store.select(selectPendingCrmStates).subscribe((items) => {
        if (!isUndefined(items[this.actionId])) {
          if (items[this.actionId] === CrmItemStatus.SUCCESS) {
            this.pendingSend = false;
            this.closeDialog();
          }
        }
      }),
    );

    // Set default value for supplier search
    this.supplier.setValue(this.defaultSupplierSearch);

    if (!isNullOrUndefined(this.data)) {
      if (this.data.currentSupplier) {
        // Set current supplier as target
        this.subscriptions.push(
          this.store.select(selectSupplierDetails).subscribe((data) => {
            if (!isUndefined(data)) {
              this.supplierName = data.legalName;
              this.supplierEmail = data.contactEmail;
              this.emailSendForm.patchValue({
                to: data.contactEmail,
                supplierName: data.legalName,
                targetUuid: data.supplierKey,
              });
              this.supplier.setValue(data.legalName);
            }
          }),
        );
      }
    }

    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        this.loggedUser = user;
      }),
    );

    const name = (temp) => (temp.configValue.label || temp.configKey).toLowerCase();

    this.templateOptions = this.configsService
      .listByType(ConfigTypes.EMAIL_TEMPLATE)
      .pipe(map((resp) => resp.sort((a, b) => name(a).localeCompare(name(b)))));

    // Subscribe to receivers options
    this.subscriptions.push(
      this.usersService.getUsers().subscribe((data) => {
        this.usersPending = false;
        this.usersOptions = data;
      }),
    );

    // Update form with selected supplier
    this.subscriptions.push(
      this.supplier.valueChanges.pipe(debounceTime(500)).subscribe((search) => {
        this.suppliersPending = true;
        const query = search && typeof search === 'object' ? search.legalName : search === '' ? this.defaultSupplierSearch : search;
        this.filteredSuppliers = this.supplierService.getItems(new HttpParams().set('search', query)).pipe(
          tap(() => {
            this.suppliersPending = false;
          }),
        );
      }),
    );
  }

  createForm() {
    this.emailSendForm = this.formBuilder.group({
      to: ['', Validators.required],
      subject: ['', Validators.required],
      body: ['', Validators.required],
      supplierName: [''],
      config: ['default_config', Validators.required],
      bcc: [''],
      cc: [''],
      targetUuid: [''],
    });
  }

  setCC($event) {
    const selectedEmail = $event.option.value.email;
    if (this.ccSelected.indexOf(selectedEmail) < 0) {
      this.ccSelected.push(selectedEmail);
    }
    this.emailSendForm.get('cc').setValue(this.ccSelected);
    this.inputCC['nativeElement'].blur();
  }

  setBCC($event) {
    const selectedEmail = $event.option.value.email;
    if (this.bccSelected.indexOf(selectedEmail) < 0) {
      this.bccSelected.push(selectedEmail);
    }
    this.emailSendForm.get('bcc').setValue(this.bccSelected);
    this.inputBCC['nativeElement'].blur();
    this.bccSearch = null;
  }

  removeCC(value) {
    const removeIdx = this.ccSelected.indexOf(value);
    if (removeIdx > -1) {
      this.ccSelected.splice(removeIdx, 1);
    }
    this.emailSendForm.get('cc').setValue(this.ccSelected);
  }

  removeBCC(value) {
    const removeIdx = this.bccSelected.indexOf(value);
    if (removeIdx > -1) {
      this.bccSelected.splice(removeIdx, 1);
    }
    this.emailSendForm.get('bcc').setValue(this.bccSelected);
  }

  templateSelected($event) {
    const templateData = $event.value;

    const msg = 'Changing the template overwrites current data. Do you want to continue?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.setTemplate(templateData);
        }
      }),
    );
  }

  setTemplate(templateData) {
    templateData.body = this.compileTemplateDate(templateData.body);
    templateData.body = this.compileTemplateUser(templateData.body);
    this.emailSendForm.patchValue(templateData);
  }

  private compileTemplateDate(templateData): string {
    return templateData.replace(new RegExp('{{ currentDate . fmtDateOnly }}', 'g'), '');
  }

  private compileTemplateUser(templateData): string {
    const user = {};
    user['firstname'] = this.loggedUser.firstname;
    user['lastname'] = this.loggedUser.lastname;
    user['name'] = this.loggedUser.name;

    const templateStr = templateData;
    _.templateSettings.interpolate = /{{ user.([\s\S]+?) }}/g;
    const compiled = _.template(templateStr);
    return compiled(user);
  }

  sendEmail(renderedMessage) {
    this.pendingSend = true;
    const formData = this.emailSendForm.value;
    formData.message = renderedMessage.message;
    formData.messageHtml = renderedMessage.messageHtml;
    formData.subject = renderedMessage.subject;
    this.store.dispatch(new SendSingleEmail({ data: formData, actionId: this.actionId }));
  }

  openPreview() {
    const formData = this.emailSendForm.value;
    const previewData = {
      messageHtml: formData.body,
      subject: formData.subject,
      config: formData.config,
      targetUuid: formData.targetUuid,
    };
    this.pendingPreview = true;

    this.subscriptions.push(
      this.emailsService.previewEmail(previewData).subscribe((data) => {
        formData.message = data.message;
        formData.messageHtml = data.messageHtml;
        formData.subject = data.subject;

        const dialogRef = this.dialog.open(EmailPreviewComponent, { width: DIALOG_SIZE.DEFAULT.width, data: formData });

        this.subscriptions.push(
          dialogRef.afterClosed().subscribe((isEmailSend) => {
            if (isEmailSend) {
              this.closeDialog();
            }
          }),
        );
      }),
    );
  }

  closeDialog(pyaload?): void {
    this.dialogRef.close(pyaload);
  }
}
