<div class="card">
  <div class="card-content">
    <app-underwriting-initial-card-header
      [chipText]="'Automated'"
      [chipColor]="'accent'"
      [title]="'Amazon API'"
      [status]="headerStatus"
      [type]="headerType"
    >
    </app-underwriting-initial-card-header>
    <div class="table-wrapper">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="10%" class="text-right">Target</th>
            <th width="10%" class="text-right">Value</th>
            <th width="20%" class="text-right">Decision</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="UWRules?.amazon_loan">
            <td>Does the supplier's 30 day rolling average of revenue equal or exceed $35k?</td>
            <td class="text-right">less than $35k</td>
            <td class="text-right">
              {{ amazonHealth['30days_rolling_avg']?.value * 1 | currency: 'USD':'symbol':'1.0-0' }}
            </td>
            <td class="text-right">
              <span *ngIf="amazonHealth['30days_rolling_avg']?.value * 1 > amazonHealthValue" class="text-danger"> Needs API </span>
              <span *ngIf="amazonHealth['30days_rolling_avg']?.value * 1 < amazonHealthValue" class="text-success"> OK </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
