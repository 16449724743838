import { OnboardingActions, RESET_ALL_FORMS } from './onboarding.actions';

export interface IOnboardingState {
  resetTimestamp: string;
}

const initialState = {
  resetTimestamp: '',
};

const onboardingActionsMap = new Map();
onboardingActionsMap.set(RESET_ALL_FORMS, (state: any, actionPayload: any) => {
  return {
    ...state,
    resetTimestamp: new Date().getMilliseconds().toString(),
  };
});

export function onboardingReducer(state: IOnboardingState = initialState, action: OnboardingActions) {
  const onboardingFunction = onboardingActionsMap.get(action.type);
  if (!onboardingFunction) {
    return state;
  }
  return onboardingFunction(state);
}
