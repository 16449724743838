<h1 mat-dialog-title>{{ actionTitle }}</h1>

<div *ngIf="showForm" class="confirmation">
  {{ message }}
</div>

<div *ngIf="showForm" mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Yes</button>
  <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">No</button>
</div>

<div *ngIf="isSuccess">
  <div class="alert alert-success">
    {{ successMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Close</button>
  </div>
</div>

<div *ngIf="isError">
  <div class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Close</button>
  </div>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
