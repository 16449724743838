import { CurrencyPipe } from '@angular/common';
import { isUndefined } from 'util';

import { UwStatus } from '../../../../../enums/uw-status';
import { PeriodsCalculationsService } from '../service/periods-calculations.service';
import {
  ADD_PERIOD,
  CalcActions,
  CalcActionsWithPayload,
  CALCULATOR_HARD_RESET,
  CALCULATOR_RESET,
  REMOVE_PERIOD,
  SET_APPROVED_AMOUNT,
  SET_DEFAULT_PROGRESSIVE_ADVANCE_SETTINGS,
  SET_ELIGIBILITY_DATA,
  SET_EXPECTED_DURATION,
  SET_EXPECTED_MONTHLY_EARNINGS,
  SET_FEE,
  SET_FORCE_FULL,
  SET_GRACE_PERIOD_END_DATE,
  SET_GRACE_PERIOD_SAVED_END_DATE,
  SET_NET_DELIVERY_AMOUNT,
  SET_NEW_OFFER,
  SET_NO_REBATE,
  SET_NO_REBATE_SAVED,
  SET_PERIOD_LENGTH,
  SET_REQUEST_NOTES,
  SET_REQUESTED_AMOUNT,
  SET_RESERVE_FOR_REPAYMENTS,
  SET_RETURN_STRATEGY,
  SET_STATE_FROM_PERSISTED_DATA,
  SET_TO_EDITABLE,
  SET_TO_READONLY,
  SET_UNDERWRITING_NOTES,
  SET_UNDERWRITING_RISK_RATING,
  SET_UW_STATUS,
} from './calculator.actions';
import { AdvanceOfferConnectedInputs, CalculatorState, Period } from './calculator-interfaces';

const currencyPipe = new CurrencyPipe('en-US');

const MAX_RESERVE_RATIO = 40;
export const INITIAL_RESERVE_RATIO = 25;
export const MAX_TOTAL_PERIOD_LENGTH = 30;
export const MAX_TOTAL_FEE = 40;
export const MIN_TOTAL_FEE = 5;
export const MAX_PERIOD_COUNT = 3;
const MIN_PERIOD_COUNT = 1;

export const initialState: CalculatorState = {
  periods: [new Period(0, 0)],
  requested: new AdvanceOfferConnectedInputs(),
  recommended: new AdvanceOfferConnectedInputs(),
  approvedAmount: undefined,
  netDeliveryAmount: undefined,
  reserveForRepayments: INITIAL_RESERVE_RATIO,
  totalPeriodLength: 0,
  totalFee: 0,
  averageFee: 0,
  underwritingRiskRating: -1,
  defaultProgressiveAdvanceSettings: {
    ForEveryRiskRating: [],
    WhenNoRiskRating: [],
  },
  isTotalPeriodToLong: false,
  isTotalFeeIncorrect: true,
  isMpReserveForPaymentEmpty: false,
  isAddDisabled: false,
  isRemoveDisabled: true,
  isReadonly: false,
  isTouched: false,
  isNewOffer: false,
  eligibilityData: undefined,
  requestNotes: undefined,
  underwriterNotes: undefined,
  gracePeriodEndDate: undefined,
  gracePeriodSavedEndDate: undefined,
  isEmptyPeriodLength: false,
  isEmptyFee: false,
  noRebate: false,
  noRebateSaved: false,
  returnStrategy: 'no_return',
  returnStrategySaved: 'no_return',
  forceFull: true,
  forceFullSaved: true,
  expectedMonthlyEarnings: undefined,
  expectedDuration: undefined,
  uwStatus: UwStatus.PENDING,
};

const calculatorMap = new Map();
calculatorMap.set(CALCULATOR_HARD_RESET, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...initialState,
    defaultProgressiveAdvanceSettings: state.defaultProgressiveAdvanceSettings,
  };
});
calculatorMap.set(SET_STATE_FROM_PERSISTED_DATA, (state: CalculatorState, actionPayload: any): CalculatorState => {
  const periods = actionPayload.periods;
  const riskRating = actionPayload.riskRating;
  return {
    ...state,
    ...compute(state, periods),
    underwritingRiskRating: riskRating,
  };
});
calculatorMap.set(SET_REQUEST_NOTES, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    requestNotes: actionPayload,
  };
});
calculatorMap.set(SET_UNDERWRITING_NOTES, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    underwriterNotes: actionPayload,
  };
});
calculatorMap.set(SET_GRACE_PERIOD_END_DATE, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    gracePeriodEndDate: actionPayload,
  };
});

calculatorMap.set(SET_GRACE_PERIOD_SAVED_END_DATE, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    gracePeriodSavedEndDate: actionPayload,
  };
});

calculatorMap.set(SET_NO_REBATE, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    noRebate: actionPayload,
  };
});

calculatorMap.set(SET_NO_REBATE_SAVED, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    noRebateSaved: actionPayload,
  };
});

calculatorMap.set(SET_ELIGIBILITY_DATA, (state: CalculatorState, actionPayload: any): CalculatorState => {
  const periods = fillAdvanceOfferWithDefaults(state, actionPayload.riskRating);
  let totalFee = 0;
  if (periods) {
    totalFee = PeriodsCalculationsService.countTotalFee(periods);
  }
  const netDeliveryAmount = calculateNetDeliveryAmount(actionPayload.offer_amount, totalFee);
  return {
    ...state,
    recommended: {
      ...state.recommended,
      givenAmount: actionPayload.offer_amount,
      grossAmount: actionPayload.offer_amount,
      netDeliveryAmount,
      descriptionRiskRanking: actionPayload.no_data ? 'Not Applicable' : actionPayload.ranking,
      descriptionGrossAmount: actionPayload.no_data ? 'Not Applicable' : currencyPipe.transform(actionPayload.offer_amount, 'USD'),
      descriptionNetDeliveryAmount: actionPayload.no_data ? 'Not Applicable' : currencyPipe.transform(netDeliveryAmount, 'USD'),
      descriptionReserveAmount: actionPayload.no_data
        ? 'Not Applicable'
        : currencyPipe.transform(actionPayload.offer_amount - netDeliveryAmount, 'USD'),
    },
    eligibilityData: actionPayload,
  };
});
calculatorMap.set(SET_NEW_OFFER, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    isNewOffer: actionPayload,
  };
});
calculatorMap.set(SET_TO_EDITABLE, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    isReadonly: false,
  };
});
calculatorMap.set(SET_TO_READONLY, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    isReadonly: true,
  };
});
calculatorMap.set(SET_DEFAULT_PROGRESSIVE_ADVANCE_SETTINGS, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    defaultProgressiveAdvanceSettings: actionPayload,
  };
});
calculatorMap.set(CALCULATOR_RESET, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...initialState,
    periods: [new Period(0, 0)],
    defaultProgressiveAdvanceSettings: state.defaultProgressiveAdvanceSettings,
    isNewOffer: state.isNewOffer,
    recommended: {
      ...state.recommended,
    },
    requested: {
      ...state.requested,
    },
    eligibilityData: {
      ...state.eligibilityData,
    },
    requestNotes: state.requestNotes,
    underwriterNotes: state.underwriterNotes,
  };
});
calculatorMap.set(SET_NET_DELIVERY_AMOUNT, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    approvedAmount: calculateApprovedAmount(actionPayload, state.totalFee),
    netDeliveryAmount: actionPayload,
    isTouched: true,
  };
});
calculatorMap.set(SET_APPROVED_AMOUNT, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    approvedAmount: parseFloat(actionPayload),
    netDeliveryAmount: calculateNetDeliveryAmount(actionPayload, state.totalFee),
    isTouched: true,
  };
});
calculatorMap.set(SET_RETURN_STRATEGY, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    returnStrategy: actionPayload,
    isTouched: true,
  };
});
calculatorMap.set(SET_EXPECTED_MONTHLY_EARNINGS, (state: CalculatorState, actionPayload: any): CalculatorState => {
  let ratio = parseFloat(actionPayload);
  if (valueIsLessThan(ratio, 1)) {
    ratio = 1;
  }
  return {
    ...state,
    expectedMonthlyEarnings: ratio,
    isTouched: true,
  };
});
calculatorMap.set(SET_UW_STATUS, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    uwStatus: actionPayload,
    isTouched: true,
  };
});
calculatorMap.set(SET_EXPECTED_DURATION, (state: CalculatorState, actionPayload: any): CalculatorState => {
  const maxPossibleValue = state.totalPeriodLength;
  let ratio = parseFloat(actionPayload);
  if (ratio > maxPossibleValue) {
    ratio = maxPossibleValue;
  }
  if (valueIsLessThan(ratio, 1)) {
    ratio = 1;
  }
  return {
    ...state,
    expectedDuration: ratio,
    isTouched: true,
  };
});
calculatorMap.set(SET_REQUESTED_AMOUNT, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    requested: {
      ...state.requested,
      givenAmount: parseFloat(actionPayload),
      netDeliveryAmount: parseFloat(actionPayload),
      grossAmount: calculateApprovedAmount(actionPayload, state.totalFee),
    },
  };
});
calculatorMap.set(SET_RESERVE_FOR_REPAYMENTS, (state: CalculatorState, actionPayload: any): CalculatorState => {
  let ratio = parseFloat(actionPayload);
  if (ratio > MAX_RESERVE_RATIO) {
    ratio = MAX_RESERVE_RATIO;
  }
  if (ratio < 0) {
    ratio = 0;
  }
  return {
    ...state,
    reserveForRepayments: ratio,
    isMpReserveForPaymentEmpty: ratio <= 0,
  };
});
calculatorMap.set(REMOVE_PERIOD, (state: CalculatorState, actionPayload: any): CalculatorState => {
  const periods = [...state.periods];
  if (periods.length > 1) {
    periods.splice(actionPayload, 1);
  }
  return {
    ...state,
    ...compute(state, periods),
  };
});
calculatorMap.set(ADD_PERIOD, (state: CalculatorState, actionPayload: any): CalculatorState => {
  if (state.periods.length > 2) {
    return state;
  }
  const periods = [...state.periods, new Period(0, 0)];
  return {
    ...state,
    ...compute(state, periods),
  };
});
calculatorMap.set(SET_PERIOD_LENGTH, (state: CalculatorState, actionPayload: any): CalculatorState => {
  const periods = [...state.periods];
  periods[actionPayload.periodNumber].periodLength = parseFloat(actionPayload.newValue);
  return {
    ...state,
    ...compute(state, periods),
  };
});
calculatorMap.set(SET_FEE, (state: CalculatorState, actionPayload: any): CalculatorState => {
  const periods = [...state.periods];
  periods[actionPayload.periodNumber].fee = parseFloat(actionPayload.newValue);
  return {
    ...state,
    ...compute(state, periods),
  };
});
calculatorMap.set(SET_RETURN_STRATEGY, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    returnStrategy: actionPayload,
  };
});
calculatorMap.set(SET_FORCE_FULL, (state: CalculatorState, actionPayload: any): CalculatorState => {
  return {
    ...state,
    forceFull: actionPayload,
  };
});
calculatorMap.set(SET_UNDERWRITING_RISK_RATING, (state: CalculatorState, actionPayload: any): CalculatorState => {
  const newPeriods = fillAdvanceOfferWithDefaults(state, actionPayload);
  if (!newPeriods) {
    return state;
  }
  return {
    ...state,
    ...compute(state, newPeriods),
    underwritingRiskRating: actionPayload,
  };
});

function isWithPayload(action: any): action is CalcActionsWithPayload {
  return action.payload !== undefined;
}

function calculateNetDeliveryAmount(approvedAmount: number, currentTotalFee: number) {
  if (isUndefined(approvedAmount)) {
    return undefined;
  }
  if (!currentTotalFee) {
    return approvedAmount;
  }
  return PeriodsCalculationsService.standardRound(((100 - currentTotalFee) * approvedAmount) / 100, 2);
}

function calculateApprovedAmount(netDeliveryAmount: number, currentTotalFee: number) {
  return PeriodsCalculationsService.standardRound((netDeliveryAmount / (100 - currentTotalFee)) * 100, 2);
}

function compute(entryState: CalculatorState, periods?: Period[]) {
  const totalPeriodLength = PeriodsCalculationsService.countTotalLength(periods);
  const totalFee = PeriodsCalculationsService.countTotalFee(periods);
  const averageFee = PeriodsCalculationsService.countAverage(totalPeriodLength, totalFee);

  const isEmptyFee = PeriodsCalculationsService.isEmptyFee(periods);
  const isEmptyPeriodLength = PeriodsCalculationsService.isEmptyPeriodLength(periods);

  let isAddDisabled: boolean = entryState.isAddDisabled;
  let isRemoveDisabled: boolean = entryState.isRemoveDisabled;

  const isTotalFeeIncorrect = totalFee > MAX_TOTAL_FEE || totalFee < MIN_TOTAL_FEE;
  const isTotalPeriodToLong = totalPeriodLength > MAX_TOTAL_PERIOD_LENGTH;
  if (periods) {
    isAddDisabled = periods.length >= MAX_PERIOD_COUNT;
    isRemoveDisabled = periods.length <= MIN_PERIOD_COUNT;
  }
  const netDeliveryAmount = calculateNetDeliveryAmount(entryState.approvedAmount, totalFee);

  return {
    periods,
    totalFee,
    totalPeriodLength,
    averageFee,
    isTotalFeeIncorrect,
    isTotalPeriodToLong,
    isAddDisabled,
    isRemoveDisabled,
    netDeliveryAmount,
    isTouched: true,
    isEmptyFee,
    isEmptyPeriodLength,
  };
}

function fillAdvanceOfferWithDefaults(state: CalculatorState, riskRating: number): Period[] | void {
  return PeriodsCalculationsService.getPeriodsFromDefaults(state.defaultProgressiveAdvanceSettings, riskRating);
}

export function calculatorReducers(state: CalculatorState = initialState, action: CalcActions) {
  const calculatorFunction = calculatorMap.get(action.type);
  if (!calculatorFunction) {
    return state;
  }
  let payload: any;
  if (isWithPayload(action)) {
    payload = action.payload;
  } else {
    payload = null;
  }
  return calculatorFunction(state, payload);
}

export function valueIsLessThan(valueToCheck: number, isLessThanValue: number) {
  return (valueToCheck || valueToCheck === 0) && valueToCheck < isLessThanValue;
}
