import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { AppConfigService } from '../../../../../../config';

@Injectable()
export class FlexParticipationService {
  readonly apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = this.appConfig.env.internalApiUrl;
  }

  getEstimations(supplierKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}suppliers/${supplierKey}/flex/estimations`;
    return this.httpClient.get(endpoint);
  }

  putFlexParticipation(mpSupKey: string, flexParticipation: number): Observable<any> {
    const endpoint = `${this.apiUrl}marketplace_suppliers/${mpSupKey}/flex_participation`;
    return this.httpClient.put(endpoint, { participationRate: flexParticipation });
  }

  putFundingRate(mpSupKey: string, fundingRate: number): Observable<any> {
    const endpoint = `${this.apiUrl}marketplace_suppliers/${mpSupKey}/flex_participation`;
    return this.httpClient.put(endpoint, { fundingRate });
  }

  putFlexFreemium(mpSupKey: string, freemium: number): Observable<any> {
    const endpoint = `${this.apiUrl}marketplace_suppliers/${mpSupKey}/freemium`;
    return this.httpClient.put(endpoint, { freemiumFee: freemium });
  }

  putFlexFreemiumFeeRate(mpSupKey: string, freemium: number): Observable<any> {
    const endpoint = `${this.apiUrl}marketplace_suppliers/${mpSupKey}/freemium`;
    return this.httpClient.put(endpoint, { freemiumFeeRate: freemium });
  }

  putEnableFlex(supplierKey: string, enabled: boolean): Observable<any> {
    const endpoint = `${this.apiUrl}suppliers/${supplierKey}/flex/enabled`;
    return this.httpClient.put(endpoint, { enabled });
  }
}
