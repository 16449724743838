import { ChoosenSearchStates, CrmStates, SupplierInfoStates } from '../layout.enums';
import * as fromLayoutActions from './layout.actions';
import { ACTIONS_HIDE, ACTIONS_SHOW, CHOOSE_NEW_SEARCH, CHOOSE_OLD_SEARCH, SEARCH_BUTTON_HIDE, SEARCH_BUTTON_SHOW } from './layout.actions';

const routeRegexes = {
  supplierList: new RegExp('^/suppliers/list(.*)$'),
  supplierGeneral: new RegExp('^/suppliers/(.*)/general(.*)$'),
  supplierDefault: new RegExp('^/suppliers/(.*)$'),
};

export interface UserLayoutConfig {
  [key: string]: {
    crmPanelState: CrmStates;
    supplierInfoOpenState: SupplierInfoStates;
    searchButtonVisible: boolean;
    actionsVisible: boolean;
  };
}

export interface LayoutState {
  choosenSearch: ChoosenSearchStates;
  crmPanelState: CrmStates;
  appStageLabel: string;
  supplierInfoOpenState: SupplierInfoStates;
  userLayoutConfig: UserLayoutConfig;
  searchButtonVisible: boolean;
  actionsVisible: boolean;
  currentRoute: string;
}

const defaultLayoutConfig = {
  crmPanelState: CrmStates.HIDDEN,
  supplierInfoOpenState: SupplierInfoStates.HIDDEN,
  searchButtonVisible: true,
  actionsVisible: false,
};

const initialState: LayoutState = {
  choosenSearch: ChoosenSearchStates.NEW_SEARCH,
  crmPanelState: CrmStates.HIDDEN,
  supplierInfoOpenState: SupplierInfoStates.HIDDEN,
  searchButtonVisible: true,
  actionsVisible: false,
  appStageLabel: '',
  userLayoutConfig: {
    supplierList: {
      ...defaultLayoutConfig,
    },
    supplierGeneral: {
      crmPanelState: CrmStates.FIXED,
      supplierInfoOpenState: SupplierInfoStates.OPEN,
      searchButtonVisible: true,
      actionsVisible: true,
    },
    supplierDefault: {
      crmPanelState: CrmStates.CLOSED,
      supplierInfoOpenState: SupplierInfoStates.OPEN,
      searchButtonVisible: true,
      actionsVisible: true,
    },
    default: {
      ...defaultLayoutConfig,
    },
  },
  currentRoute: 'default',
};

const persistedState = loadState();

export function layoutReducer(state = persistedState || initialState, action: fromLayoutActions.LayoutActions) {
  switch (action.type) {
    case CHOOSE_NEW_SEARCH:
      return {
        ...state,
        choosenSearch: ChoosenSearchStates.NEW_SEARCH,
      };
    case CHOOSE_OLD_SEARCH:
      return {
        ...state,
        choosenSearch: ChoosenSearchStates.OLD_SEARCH,
      };
    case SEARCH_BUTTON_SHOW:
      return {
        ...state,
        searchButtonVisible: true,
      };
    case SEARCH_BUTTON_HIDE:
      return {
        ...state,
        searchButtonVisible: false,
      };

    case ACTIONS_SHOW:
      return {
        ...state,
        actionsVisible: true,
      };

    case ACTIONS_HIDE:
      return {
        ...state,
        actionsVisible: false,
      };
    case fromLayoutActions.CRM_PANEL_OPEN:
      return constructNewState(state, 'crmPanelState', CrmStates.OPEN, state.currentRoute);

    case fromLayoutActions.CRM_PANEL_CLOSE:
      return constructNewState(state, 'crmPanelState', CrmStates.CLOSED, state.currentRoute);

    case fromLayoutActions.CRM_PANEL_FIX:
      return constructNewState(state, 'crmPanelState', CrmStates.FIXED, state.currentRoute);

    case fromLayoutActions.CRM_PANEL_HIDE:
      return constructNewState(state, 'crmPanelState', CrmStates.HIDDEN, state.currentRoute);

    case fromLayoutActions.SUPPLIER_INFO_OPEN:
      return constructNewState(state, 'supplierInfoOpenState', SupplierInfoStates.OPEN, state.currentRoute);

    case fromLayoutActions.SUPPLIER_INFO_CLOSE:
      return constructNewState(state, 'supplierInfoOpenState', SupplierInfoStates.CLOSED, state.currentRoute);

    case fromLayoutActions.SUPPLIER_INFO_HIDE:
      return constructNewState(state, 'supplierInfoOpenState', SupplierInfoStates.HIDDEN, state.currentRoute);

    case fromLayoutActions.LAYOUT_UPDATE: {
      const activeRoute = resolveRouteByAction(action);
      return {
        ...state,
        ...state.userLayoutConfig[activeRoute],
        currentRoute: activeRoute,
      };
    }

    default:
      return state;
  }
}

function constructNewState(oldState: LayoutState, property, value, currentRoute): LayoutState {
  const newState = { ...oldState };
  newState[property] = value;

  if (currentRoute !== 'default') {
    newState.userLayoutConfig[currentRoute][property] = value;
  }
  saveState(newState);
  return newState;
}

function resolveRouteByAction(action: any): string {
  const url = action.payload;
  for (const key of Object.keys(routeRegexes)) {
    if (routeRegexes[key].test(url)) {
      return key;
    }
  }
  return 'default';
}

export function loadState(): LayoutState {
  try {
    const serializedState = localStorage.getItem('layoutState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

export function saveState(state: LayoutState) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('layoutState', serializedState);
  } catch (err) {
    console.error('Local storage is not avaliable!');
  }
}
