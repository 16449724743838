import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { SupplierDetailsModel } from '../../model/supplier.model';
import { AppConfigService } from './../../../config';
import { DeleteSupplierAccountDto } from './delete-supplier-account.interface';
import { DeleteSupplierAccountGetDetailsDto } from './delete-supplier-account-get-details.dto';

@Injectable()
export class DeleteSupplierAccountService {
  internalApiUrl: string;
  supplierDetailsModel: SupplierDetailsModel;

  constructor(private readonly httpClient: HttpClient, private readonly appConfig: AppConfigService) {
    this.internalApiUrl = appConfig.env.internalApiUrl;
  }

  deleteAccount(supplierKey: string): Observable<DeleteSupplierAccountDto> {
    return this.httpClient.post<DeleteSupplierAccountDto>(`${this.internalApiUrl}supplier/${supplierKey}/remove_supplier_account`, {});
  }

  canBeRemoved(supplierKey: string): Observable<boolean> {
    return this.fetchData(supplierKey).pipe(
      map((data) => data.can_be_removed),
      take(1),
      catchError(() => of(false)),
    );
  }

  private fetchData(supplierKey: string): Observable<DeleteSupplierAccountGetDetailsDto> {
    return this.httpClient.get<DeleteSupplierAccountGetDetailsDto>(`${this.internalApiUrl}supplier/${supplierKey}/remove_supplier_account`);
  }
}
