import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../store/app.reducers';
import { CrmItem } from '../../../models/crm-item.type';
import { listAnimation } from '../../../shared/cms.animations';
import { FetchCrmEvents, selectCrmEvents } from '../../../store/crm.actions';
import { ActionTabComponent } from '../action-tab-base.component';

@Component({
  selector: 'app-action-tab-events',
  templateUrl: './action-tab-events.component.html',
  animations: [trigger('listAnimation', [transition('* => *', [useAnimation(listAnimation)])])],
})
export class ActionTabEventsComponent extends ActionTabComponent {
  @Input() crmItems: { [uuid: string]: CrmItem };
  selector = selectCrmEvents;
  fetchAction = FetchCrmEvents;
  limit = 20;

  constructor(store: Store<AppState>) {
    super(store);
  }

  calculateLast() {
    if (this.last) {
      this.last = this.last.from;
    }
  }
}
