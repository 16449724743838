<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="card-title">
          {{ title }}
        </div>
        <div class="card-actions pull-right">
          <button mat-raised-button class="action" color="warn" (click)="onDelete()">Delete Report</button>
        </div>
      </div>

      <form class="custom-form" [formGroup]="editReportForm" (ngSubmit)="onSubmit()">
        <div class="form-content">
          <fieldset class="form-section small-fieldset">
            <mat-form-field>
              <mat-select placeholder="Choose Database View/Table" formControlName="reportName">
                <mat-option *ngFor="let record of dbObjectsTable" [value]="record">
                  {{ record }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Report Name" formControlName="reportLabel" />
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Enable at Supplier Level" formControlName="enabledForSupplier">
                <mat-option *ngFor="let record of enabledForSupplierDictionary" [value]="record.id">
                  {{ record.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Google Spreadsheet URL" formControlName="googleSheetsUrl" />
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Workflow ID" formControlName="workflowId" />
            </mat-form-field>
            <mat-checkbox formControlName="enabledForReports">Report is visible on report list</mat-checkbox>
          </fieldset>

          <h6>Sorting</h6>
          <fieldset class="form-section small-fieldset">
            <mat-form-field>
              <mat-select placeholder="Fields" formControlName="sortingByField">
                <mat-option *ngFor="let record of fieldsNamesTable" [value]="record">
                  {{ record }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Order" formControlName="sortingOrder">
                <mat-option *ngFor="let record of sortingOrderDictionary" [value]="record.id">
                  {{ record.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </fieldset>

          <h6>Fields</h6>
          <fieldset class="form-section">
            <div class="box table-responsive">
              <table class="mdl-data-table" formArrayName="fields">
                <thead>
                  <tr>
                    <th class="mdl-data-table__cell--non-numeric">Show Col.</th>
                    <th class="mdl-data-table__cell--non-numeric">Field name and Label</th>
                    <th class="mdl-data-table__cell--non-numeric">Type</th>
                    <th class="mdl-data-table__cell--non-numeric">Filterable</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-template ngFor let-record [ngForOf]="fields.controls" let-i="index">
                    <tr [formGroupName]="i">
                      <td class="mdl-data-table__cell--non-numeric">
                        <mat-checkbox formControlName="fieldShow"></mat-checkbox>
                      </td>
                      <td class="mdl-data-table__cell--non-numeric">
                        <mat-form-field class="field-text">
                          <input matInput [placeholder]="getPlaceholder(i)" type="text" formControlName="fieldLabel" />
                        </mat-form-field>
                      </td>
                      <td class="mdl-data-table__cell--non-numeric">
                        <mat-form-field class="field-text">
                          <input matInput type="text" formControlName="fieldType" />
                        </mat-form-field>
                      </td>
                      <td class="mdl-data-table__cell--non-numeric">
                        <mat-checkbox formControlName="fieldFilterable"></mat-checkbox>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </fieldset>
        </div>
        <div class="form-submit">
          <button mat-raised-button class="btn btn-cancel pull-right" color="warn" (click)="onCancel()">Cancel</button>
          <button mat-raised-button class="btn btn-success pull-right" type="submit" color="accent">Save</button>
        </div>
      </form>
    </div>
  </div>
</section>
