import { autoserialize, autoserializeAs } from 'cerialize';

import { CrmUserModel } from './crm-user.model';
import { TaskModel } from './task.model';

export class EventDataModel {
  @autoserialize public changes?: any;
  @autoserialize public eventId?: string;
  @autoserialize public extra: any;
  @autoserialize public logger: any;
  @autoserialize public level?: number;
  @autoserialize public message: string;
  @autoserialize public modules?: any;
  @autoserialize public platform?: string;
  @autoserialize public repos?: any;
  @autoserialize public requestData?: any;
  @autoserialize public sdk?: any;
  @autoserialize public serverName?: string;
  @autoserializeAs(TaskModel) public task?: TaskModel;
  @autoserialize public tags?: any;
  @autoserialize public timestamp?: string;
  @autoserialize public targetType?: string;
  @autoserialize public subject?: string;
  @autoserializeAs(CrmUserModel) public user: CrmUserModel;

  constructor(
    changes: any,
    eventId: string,
    extra: any,
    level: number,
    message: string,
    modules: any,
    platform: string,
    repos: any,
    requestData: any,
    sdk: any,
    serverName: string,
    task: TaskModel,
    tags: any,
    timestamp: string,
    targetType: string,
    subject: string,
    logger: string,
    user: CrmUserModel,
  ) {
    this.changes = changes;
    this.eventId = eventId;
    this.extra = extra || {};
    this.logger = logger;
    this.level = level;
    this.message = message;
    this.modules = modules;
    this.platform = platform;
    this.repos = repos;
    this.requestData = requestData;
    this.sdk = sdk;
    this.serverName = serverName;
    this.task = task;
    this.tags = tags;
    this.timestamp = timestamp;
    this.targetType = targetType;
    this.subject = subject;
    this.user = user;
  }
}
