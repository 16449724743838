/* eslint-disable */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { DefaultRadiobuttons } from '../common/consts/underwritings-initial-constants';
/* eslint-enable */

@Component({
  selector: 'app-amazon-revenue',
  templateUrl: './amazon-revenue.component.html',
})
export class AmazonRevenueComponent implements OnChanges {
  @Input() amazonRevenueOverride: any;
  @Input() amazonSales: any;
  @Input() computingDataIsInProgress: boolean;

  @Output() onAmazonRevenueOverride = new EventEmitter();

  headerType: UnderwritingInitialCardHeaderStatusType;
  headerStatus: string;
  radiobuttons = DefaultRadiobuttons;

  ngOnChanges(changes) {
    this.setHeaderDetails();
  }

  setHeaderDetails() {
    if (this.isSuccessHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.SUCCESS;
      this.headerStatus = 'Valid';
    }
    if (this.isDangerHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.DANGER;
      this.headerStatus = 'Needs review';
    }
  }

  isSuccessHeaderType() {
    return this.amazonRevenueOverride[0].value;
  }

  isDangerHeaderType() {
    return !this.amazonRevenueOverride[0].value;
  }

  radiobuttonsChange(value) {
    if (!this.amazonRevenueOverride[0]) {
      return;
    }
    this.amazonRevenueOverride[0].value = value;
    this.onAmazonRevenueOverride.emit(this.amazonRevenueOverride);
  }
}
