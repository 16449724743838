import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { CookieService } from '../services/cookie-service/cookie-service';
import { EVA_COOKIE_TOKEN_KEY } from './const/eva';
import { EVA_IFRAME_SELECTOR } from './const/eva-iframe-selector.enum';

@Injectable()
export class EvaService {
  private evaUrl = environment.evaApiUrl;
  private token: string;

  constructor(private cookieService: CookieService) {}

  static getIframeIdBySelector(selector: EVA_IFRAME_SELECTOR): string | null {
    const el = document.querySelector(`${selector} iframe`);
    if (!el) {
      return null;
    }

    return el.id;
  }

  setAuth(token: string): void {
    this.token = token;
    this.passTokenToEva();
  }

  clearAuth(): void {
    this.cookieService.delete(EVA_COOKIE_TOKEN_KEY);
  }

  getUrl(subUrl: string, queryParams: { [key: string]: string } = {}): string {
    let httpParams = new HttpParams();
    Object.keys(queryParams).forEach((key) => (httpParams = httpParams.set(key, queryParams[key])));
    const queryParamsString = httpParams.toString();

    if (this.getCurrentHostname() === 'localhost') {
      return `${environment.evaApiUrl}${subUrl}?auth=${this.token}&${queryParamsString}`;
    }
    return `${this.evaUrl}${subUrl}?${queryParamsString}`;
  }

  private passTokenToEva(): void {
    if (this.getCurrentHostname() === 'localhost') {
      return;
    }

    if (this.cookieService.doesCookieExist(EVA_COOKIE_TOKEN_KEY)) {
      this.cookieService.delete(EVA_COOKIE_TOKEN_KEY, { path: '/', domain: environment.domain });
    }

    this.cookieService.setCookie(EVA_COOKIE_TOKEN_KEY, this.token, {
      path: '/',
      domain: environment.domain,
      expires: 1,
    });
  }

  private getCurrentHostname(): string {
    return window.location.hostname;
  }
}
