<mat-card class="productPaymentGateways__matCard">
  <div class="productPaymentGateways__matCard__title">
    <h5>Payment Gateways</h5>
    <div>
      <p [ngStyle]="{ color: productStatusColor }">{{ productStatus | uppercase }}</p>
    </div>
  </div>
  <mat-card-content>
    <mat-table *ngIf="dataSource.data.length" class="productPaymentGateways__matCard__table" [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productPaymentGateways__matCard__table__cell">
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="marketplace">
        <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productPaymentGateways__matCard__table__cell">
          {{ element.marketplace }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productPaymentGateways__matCard__table__cell">
          {{ element.status }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="serviceColumns" class="productPaymentGateways__matCard__table__headerRow"></mat-header-row>
      <mat-row *matRowDef="let row; columns: serviceColumns" class="productPaymentGateways__matCard__table__row"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
