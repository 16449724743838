/* eslint-disable */
import { UwToolEntity } from './../../../../../shared/models/uw-tool-entity.model';
import { UW_TOOL_STATUS } from '../../../../../shared/consts/uw-tool.enum';
import { filter } from 'rxjs/operators';
import { selectUwTools } from './../../store/underwriting.selectors';
import { UnderwritingFetchTools } from './../../store/underwriting.actions';
import { Store } from '@ngrx/store';
import { FeatureFlagsService } from './../../../../../shared/feature-flags/feature-flags.service';
import { BUTTONS_CONFIG } from './underwriting-initial-footer.config';
import { DIALOG_SIZE } from './../../../../../shared/dialog.config';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SupplierDetailsModel } from '../../../../model/supplier.model';
import { ReviewFinalOfferDialogComponent } from '../review-final-offer-dialog/review-final-offer-dialog.component';
import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { SupplierUnderwritingService } from '../../supplier-underwriting.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorVisualizationService } from '../../../../../shared/services/error-visualization/error-visualization.service';
import { FEATURE_FLAGS } from '../../../../../shared/feature-flags/feature-flags.const';
import { AppState } from '../../../../../store/app.reducers';
import { BUTTON_TYPE, ActionButton } from './underwriting-initial-footer.const';
/* eslint-enable */

@Component({
  selector: 'app-underwriting-initial-footer',
  templateUrl: './underwriting-initial-footer.component.html',
  styleUrls: ['./underwriting-initial-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnderwritingInitialFooterComponent extends SubscriberComponent implements OnInit, OnChanges {
  @Input() supplierKey: string;
  @Input() isRestrictedUWView: boolean;
  @Input() set UWDecisionKey(key: string) {
    this.localUWDecisionKey = key;
    this.changeButtonsPropsByUWDecision();
  }

  @Input() set UWDecision(decision: string) {
    this.localUWDecision = decision;
    this.changeButtonsPropsByUWDecision();
  }

  @Input() set supplierDetailsModel(model: SupplierDetailsModel) {
    this.localSupplierDetailsModel = model;

    if (this.localSupplierDetailsModel && this.localSupplierDetailsModel.schema === 'amzn_exp_onb') {
      this.changeButtonsProps(false, false);
    } else {
      this.changeButtonsPropsByUWDecision();
    }

    this.fetchUwToolsResult();
  }

  @Input() footerButtonLoading: boolean;
  @Input() set dataReady(isReady: boolean) {
    this.isDataReady = isReady;
    if (!isReady) {
      return;
    }
    this.changeButtonsPropsByUWDecision();
  }

  @Output() onSaveUWClick = new EventEmitter();

  readonly buttonsConfig: ActionButton[] = BUTTONS_CONFIG;

  clickedButton: BUTTON_TYPE = null;

  localSupplierDetailsModel = null;
  localUWDecision = null;
  localUWDecisionKey = null;
  isDataReady = false;

  private uwTools: UwToolEntity[];
  private approveStatus = null;

  constructor(
    private supplierUnderwritingService: SupplierUnderwritingService,
    private dialogService: MatDialog,
    private errorVisualizationService: ErrorVisualizationService,
    private cd: ChangeDetectorRef,
    private featureFlagsService: FeatureFlagsService,
    private store$: Store<AppState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store$
        .select(selectUwTools)
        .pipe(filter((tools) => this.isUwToolsEnabled() && !!tools))
        .subscribe((tools) => {
          this.uwTools = tools;
          this.updateButtonStatusByUw();
        }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.footerButtonLoading && !this.footerButtonLoading) {
      this.resetButtonsLoading();
    }
  }

  fetchUwToolsResult(): void {
    if (!this.isUwToolsEnabled()) {
      return;
    }

    if (!this.calculateGeneralDisableStatus()) {
      return;
    }

    this.store$.dispatch(new UnderwritingFetchTools({ supplier: this.localSupplierDetailsModel }));
  }

  resetButtonsLoading(): void {
    Object.values(this.buttonsConfig)
      .filter((button) => button.type !== BUTTON_TYPE.VERIFIED)
      .forEach((button) => (button.loading = false));

    this.cd.detectChanges();
  }

  saveUW(value: BUTTON_TYPE): void {
    this.clickedButton = value;
    this.checkButtonSpinners();
    this.onSaveUWClick.emit(value);
  }

  onButtonClick(type: BUTTON_TYPE): void {
    if (type === BUTTON_TYPE.VERIFIED) {
      this.openReviewFinalOfferDialog(type);
      return;
    }

    this.saveUW(type);
  }

  checkButtonSpinners(): void {
    if (!this.clickedButton || this.clickedButton === BUTTON_TYPE.VERIFIED) {
      return;
    }

    const button = this.getButtonByType(this.clickedButton);
    button.loading = true;

    this.cd.detectChanges();
  }

  openReviewFinalOfferDialog(decision: BUTTON_TYPE): void {
    this.subscriptions.push(
      this.supplierUnderwritingService.getFinalOffer(this.supplierKey).subscribe(
        (response) => {
          if (this.dialogService.openDialogs.length === 0) {
            const dialogRef = this.dialogService.open(ReviewFinalOfferDialogComponent, {
              data: response,
              width: DIALOG_SIZE.RWD_50_AUTO_HEIGHT.width,
            });

            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.saveUW(decision);
              }
            });
          }
        },
        (err) => {
          this.errorVisualizationService.showError({ name: 'Final Offer Modal error:', errorObject: err });
        },
      ),
    );
  }

  private isUwToolsEnabled(): boolean {
    return this.featureFlagsService.isEnabled(FEATURE_FLAGS.UNDERWRITING_CHECK_TOOLS_BEFORE_APPROVING);
  }

  private getStatusForVerifiedButton(button: ActionButton, disabled: boolean): boolean {
    return button.type === BUTTON_TYPE.VERIFIED && this.isUwToolsEnabled() && this.approveStatus !== null && disabled === false;
  }

  private changeButtonsProps(disabled: boolean, includeUwDecision: boolean, disabledReason: CallableFunction = null): void {
    Object.values(this.buttonsConfig).forEach((button) => {
      button.disabled = disabled;

      if (this.getStatusForVerifiedButton(button, disabled)) {
        button.disabled = !this.approveStatus;
      }

      if (disabledReason) {
        button.disabledReason = disabledReason(button);
      }
    });

    this.cd.detectChanges();
  }

  private calculateGeneralDisableStatus(): boolean {
    return !this.localUWDecisionKey || this.localUWDecision === BUTTON_TYPE.VERIFIED || this.localUWDecision === BUTTON_TYPE.REJECTED;
  }

  private changeButtonsPropsByUWDecision(): void {
    const status = this.calculateGeneralDisableStatus();
    this.changeButtonsProps(status, true, this.getDisabledReason.bind(this, status));
  }

  private getButtonByType(type: BUTTON_TYPE): ActionButton {
    return this.buttonsConfig.find((button) => button.type === type);
  }

  private getDisabledReason(generalDisableStatus: boolean, button: ActionButton): string {
    if (!this.isDataReady) {
      return null;
    }

    if (!this.localUWDecision) {
      return 'To approve, please move the customer to the Underwriting Stage in Pipedrive.';
    }

    if (!generalDisableStatus && this.getStatusForVerifiedButton(button, !button.disabled)) {
      return 'All checks in Underwriting Tool must be verified in order to approve the customer.';
    }

    return 'To change underwriting decision, click on the "Underwriting Script" button and move the customer to the Underwriting stage in Pipedrive.';
  }

  private updateButtonStatusByUw(): void {
    if (!this.uwTools) {
      return;
    }

    this.approveStatus = this.uwTools.every((tool) =>
      [UW_TOOL_STATUS.VERIFIED, UW_TOOL_STATUS.VERIFIED_ACCESS, UW_TOOL_STATUS.VERIFIED_ACCESS_AND_ADVANCE].includes(tool.status),
    );

    this.changeButtonsPropsByUWDecision();
  }
}
