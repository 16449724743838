import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-fee-summary',
  templateUrl: './fee-summary.component.html',
  styleUrls: ['./fee-summary.component.scss'],
})
export class FeeSummaryComponent implements OnInit, OnChanges {
  @Input() summaryData?: any;
  @Input() isTotalFeeIncorrect?: boolean;
  @Input() isTotalPeriodToLong?: boolean;

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['feePeriods', 'periodLength', 'fee'];

  constructor() {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.summaryData);
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.summaryData);
  }
}
