import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { IEnvironment } from '../../../../environments/environment.interface';
import { AppConfigService } from '../../../config';
import { AppState } from './../../../store/app.reducers';
import { B2bWorkflowService } from './../../../suppliers/services/workflow/b2b-workflow.service';

@Injectable()
export class BatchImportService {
  internalApiUrl: string;
  actionsToolsUrl: string;
  cliApiUrl: string;
  b2bDocumentsApiUrl: string;
  gciId: IEnvironment['workflowGciId'];
  downloadUrl: string;

  constructor(
    private appConfig: AppConfigService,
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private b2bWorkflowService: B2bWorkflowService,
  ) {
    this.internalApiUrl = appConfig.env.internalApiUrl;
    this.actionsToolsUrl = appConfig.env.actionsToolsUrl;
    this.cliApiUrl = appConfig.env.cliApiUrl;
    this.b2bDocumentsApiUrl = appConfig.env.b2bDocumentsApiUrl;
    this.gciId = appConfig.env.workflowGciId;
  }

  getFormData(data: any) {
    const formData = new FormData();

    Object.keys(data).map((key) => {
      if (key === 'file') {
        formData.append(key, data[key].files[0], data[key].files[0].name);
      } else {
        formData.append(key, data[key]);
      }
    });

    return formData;
  }

  addNewMBAccounts(data: any) {
    const formData = this.getFormData(data);

    return this.httpClient.post(`${this.internalApiUrl}deposit_accounts/csv_upload`, formData);
  }

  addPreFundedCards(data: any) {
    const formData = this.getFormData(data);

    return this.httpClient.post(`${this.internalApiUrl}payment/configs/csv_upload`, formData);
  }

  getNotes(data: any, user: any): Observable<any> {
    const fileReader$ = Observable.create((observer: any) => {
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const notes = [];
        const lines = e.target.result.split('\n');
        for (let i = 1; i < lines.length; i++) {
          const line = lines[i].split(',');

          if (line[0]) {
            const note = {
              target_type: 'note',
              target_uuid: line[0],
              body: line[1],
              is_pinned: line[2] === 'true',
              user,
            };
            notes.push(note);
          }
        }
        observer.next(notes);
        observer.complete();
      };
      reader.readAsText(data.file.files[0]);
    });
    return fileReader$;
  }

  addBulkNotes(data: any, user: any) {
    return this.getNotes(data, user).pipe(
      mergeMap((notes) => {
        return this.httpClient.post(`${this.actionsToolsUrl}notes/bulk-update/`, notes);
      }),
    );
  }

  addSupplierRebates(data: any) {
    const formData = this.getFormData(data);

    return this.httpClient.post(`${this.cliApiUrl}efs_csv_upload`, formData);
  }

  addSupplierPromos(data: any) {
    const formData = this.getFormData(data);

    return this.httpClient.post(`${this.internalApiUrl}supplier_credit`, formData);
  }

  addWeeklyPurchases() {
    return this.httpClient.post(`${this.cliApiUrl}purchase_rcv`, {});
  }

  getLastExecution(name: string): Observable<any> {
    return this.b2bWorkflowService.getLastExecution(name);
  }

  getListOfActivities(name: string): Observable<any> {
    return this.getLastExecution(name).pipe(
      mergeMap((data) => {
        return this.b2bWorkflowService.getListOfActivities(data.task.id);
      }),
    );
  }
}
