import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '../../../config';
import { TransactionDetails, TransactionDetailsMapper } from '../card-transactions-details/transaction-details.models';
import { MarqetaTransaction, MarqetaTransactionMapper } from './marqeta-transaction.model';

@Injectable()
export class SupplierMarqetaService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers';
  }

  getMarqeta(supplierKey: string, data: any, filters = null): Observable<{ results: MarqetaTransaction[]; total: number }> {
    const endpoint = `${this.apiUrl}/${supplierKey}/pcard/get_general_report_v2`;
    const dataWithParams = { ...data, ...this.getPreparedDataParams(filters) };

    return this.httpClient.post(endpoint, dataWithParams).pipe(
      map((dataTransactions: { results: any[]; total: number }) => {
        if (dataTransactions && dataTransactions.hasOwnProperty('results')) {
          dataTransactions.results = dataTransactions.results.map(MarqetaTransactionMapper.map);

          return dataTransactions;
        }

        return dataTransactions;
      }),
    );
  }

  getMarqetaTransaction(supplierKey: string, transactionId: string): Observable<TransactionDetails> {
    return this.httpClient
      .get<any>(`${this.apiUrl}/${supplierKey}/pcard/${transactionId}/transaction_details`)
      .pipe(map((data) => TransactionDetailsMapper.map(data)));
  }

  getMarqetaMarqetaStatuses(supplierKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}/${supplierKey}/pcard/get_general_report_v2/statuses`;

    return this.httpClient.get<any>(endpoint);
  }

  private getPreparedDataParams(paramsData): Object {
    const params = {};
    if (paramsData !== null) {
      for (const key in paramsData) {
        if (paramsData.hasOwnProperty(key) && paramsData[key] !== '') {
          params[key] = paramsData[key];

          if (paramsData['transaction_date__gt']) {
            params['startTransactionDate'] = paramsData['transaction_date__gt'];
          }
          if (paramsData['transaction_date__lt']) {
            params['endTransactionDate'] = paramsData['transaction_date__lt'];
          }
          delete params['transaction_date__gt'];
          delete params['transaction_date__lt'];
        }
      }
    } else {
      params['limit'] = '50';
      params['offset'] = '0';
    }
    return params;
  }

  private getPreparedParams(paramsData): HttpParams {
    let params = new HttpParams();

    if (paramsData !== null) {
      for (const key in paramsData) {
        if (paramsData.hasOwnProperty(key)) {
          if (paramsData[key] !== '') {
            params = params.append(key, paramsData[key].toString());
          }
        }
      }
    } else {
      params = params.append('limit', '50');
      params = params.append('soffset', '0');
    }

    return params;
  }
}
