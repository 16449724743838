import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { isNull, isNullOrUndefined } from 'util';

import { AppConfigService } from '../../../../../../config';
import { SubscriberComponent } from '../../../../../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from '../../../../../../shared/dialog.config';
import { LookupAbstractService } from '../../../../../../shared/lookup-service/lookup-abstract.service';
import { ReasonDialogComponent } from '../../../../../../shared/reason-dialog/reason-dialog.component';
import { ErrorVisualizationService } from '../../../../../../shared/services/error-visualization/error-visualization.service';
import { SupplierMarketplaceOptInPeriodsService } from '../../../../../services/supplier-marketplace-opt-in-periods.service';
import { ProductMatrixService } from '../service/product-matrix.service';
import { ProductMatrixInitializeTablesService } from '../service/product-matrix-initialize-tables.service';
import { IAccess, IAccessMarketplaceData, IProducts } from '../service/product-matrix-interfaces';

@Component({
  selector: 'app-product-instant-access',
  templateUrl: './product-instant-access.component.html',
  styleUrls: ['./product-instant-access.component.scss'],
})
export class ProductInstantAccessComponent extends SubscriberComponent implements OnInit {
  @Input() supplierKey: string;
  @Input() response: IProducts;

  public dataSource: MatTableDataSource<any>;
  public serviceColumns = [
    'marketplace',
    'dueFromMp',
    'mpStatement',
    'optInDate',
    'optOutDate',
    'reserveRate',
    'fee',
    'uwStatus',
    'customerSelection',
  ];
  public uwStatuses = ['verified', 'pending', 'incomplete', 'skipped', 'sales resolution', 'rejected'];
  public productStatus: string;
  public reasons: Observable<any[]>;
  public mpData: IAccessMarketplaceData;
  public data: any[];
  public productStatusColor: string;
  public uwStatusColor: string;

  constructor(
    private appConfig: AppConfigService,
    private httpClient: HttpClient,
    private lookupRepo: LookupAbstractService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private productService: ProductMatrixService,
    private supplierMarketplaceOptInPeriodsService: SupplierMarketplaceOptInPeriodsService,
    private errorVisualizationService: ErrorVisualizationService,
  ) {
    super();
  }

  ngOnInit() {
    this.data = [];
    this.uwStatusColor = '';
    this.setUpDataFromResponse(this.response.instant_access);
    this.dataSource = new MatTableDataSource(this.data);
    this.reasons = this.lookupRepo.getCodes('optin.AddDel');
  }

  private setUpDataFromResponse(product: IAccess): void {
    this.productStatus = ProductMatrixInitializeTablesService.setProductStatus(product.onboarding_outcome.onboarding_result);
    this.productStatusColor = ProductMatrixInitializeTablesService.setProductStatusColor(this.productStatus);
    this.mpData = ProductMatrixInitializeTablesService.checkIfNotSupported(product.mp_data);
    ProductMatrixInitializeTablesService.setDataSource(this.mpData, this.data, this.setFieldsForSpecificProduct);
  }

  private setFieldsForSpecificProduct(mp: IAccessMarketplaceData) {
    return {
      dueFromMp: mp.due_from_marketplace ? ProductMatrixInitializeTablesService.transformIntoCurrency(mp.due_from_marketplace) : '-',
      mpStatement: mp.last_statement ? ProductMatrixInitializeTablesService.transformIntoCurrency(mp.last_statement.end_bal) : '-',
      optInDate: mp.current_opt_in.start ? mp.current_opt_in.start : '-',
      optOutDate: mp.current_opt_in.end ? mp.current_opt_in.end : '-',
      fee: mp.parameters.current.fee_schedule.fee_tiers ? mp.parameters.current.fee_schedule.fee_tiers[0].fee_percent : '-',
      reserveRate: mp.parameters.current.reserve_rates.reserve_percent ? `${mp.parameters.current.reserve_rates.reserve_percent} %` : '-',
      uwStatus: mp.uw_status,
      customerSelection: mp.rates_status ? mp.rates_status : '-',
    };
  }

  public openReasonDialog(optInDate: string, optOutDate: string, index: number): void {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      data: {
        reasons: this.reasons,
        warning: 'Caution! This action will change supplier status and may affect supplier workflow.',
        dates: {
          optIn: new Date(optInDate),
          optOut: new Date(optOutDate),
        },
        index,
      },
      width: DIALOG_SIZE.RWD_60.width,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!isNullOrUndefined(result)) {
        this.updateOptInDatesTableValues(result);
        this.optInDatesUpdateRequest(result, index);
      }
    });
  }

  private updateOptInDatesTableValues(result: any): void {
    const data = [...this.dataSource.data];
    data[result.index].optInDate = this.datePipe.transform(result.dates.optIn, 'MM/dd/yyyy');
    data[result.index].optOutDate = this.datePipe.transform(result.dates.optOut, 'MM/dd/yyyy');
    this.dataSource.data = data;
  }

  private optInDatesUpdateRequest(result: any, index: number) {
    const dataToSave = {
      reasonCode: result.reasonCode,
      reasonNote: result.reasonNote,
      startDate: this.datePipe.transform(result.dates.optIn, 'MM/dd/yyyy'),
      endDate: this.datePipe.transform(result.dates.optOut, 'MM/dd/yyyy'),
    };
    const mpSupplierKey = ProductMatrixInitializeTablesService.getMarketplaceSupplierKey(this.mpData, index);
    const optInKey = this.mpData[mpSupplierKey].current_opt_in.opt_in_key;

    if (isNull(optInKey)) {
      return this.subscriptions.push(
        this.supplierMarketplaceOptInPeriodsService.addOptInPeriod(this.supplierKey, mpSupplierKey, dataToSave).subscribe(
          () => {},
          (err: any) => {
            this.errorVisualizationService.showError({ name: 'Opt Dates Error', errorObject: err });
          },
        ),
      );
    }
    this.subscriptions.push(
      this.productService.putOptDates(this.supplierKey, mpSupplierKey, optInKey, dataToSave).subscribe(
        () => {},
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Opt Dates Error', errorObject: err });
        },
      ),
    );
  }

  public uwStatusUpdateRequest(e: any, index: number): void {
    const key = ProductMatrixInitializeTablesService.getMarketplaceSupplierKey(this.mpData, index);
    const data = {
      status: e.value,
    };
    this.subscriptions.push(
      this.productService.postUnderwritingStatus(key, 'instant_access', data).subscribe(
        () => {},
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'UW Status Error', errorObject: err });
        },
      ),
    );
  }

  public getUnderwritingStatusColor(uwStatus: string): string {
    return ProductMatrixInitializeTablesService.getUnderwritingStatusColor(uwStatus);
  }
}
