<article class="article">
  <mat-tab-group>
    <mat-tab label="Overview"
      ><div class="row" *ngIf="supplierKey">
        <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-xl-8 col-lg-8'">
          <app-supplier-onboarding-details
            (runOnboardingWorkflow)="runOnboardingWorkflow()"
            [onboardingTaskProgres]="onboardingTaskProgres"
          ></app-supplier-onboarding-details>
          <app-supplier-sales-information [marketplaceSaleInformation]="marketplaceSaleInformation"></app-supplier-sales-information>
          <app-service-table></app-service-table>
          <app-supplier-onboarding-marketplace-access
            [supplierKey]="supplierKey"
            [amazonMarketplaceDetailsLoading]="amazonMarketplaceDetailsLoading"
          ></app-supplier-onboarding-marketplace-access>
          <app-supplier-onboarding-marketplace-permissions [supplierKey]="supplierKey"></app-supplier-onboarding-marketplace-permissions>
        </div>
        <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-xl-4 col-lg-4'">
          <app-supplier-onboarding-compliances
            [keys]="{ supplierKey: supplierKey, suppMPKeys: suppMPKeys }"
          ></app-supplier-onboarding-compliances>
        </div></div
    ></mat-tab>
    <mat-tab *ngFor="let iframeDefinition of iframesDefinitions" [label]="iframeDefinition.name"
      ><app-iframe *ngIf="iframeDefinition.url" [url]="iframeDefinition.url"></app-iframe>
    </mat-tab>
  </mat-tab-group>
</article>
