import { checkIfDateBetween } from './check-if-date-between';

export const checkForSpecialOccasion = () => {
  const currentDate = new Date();
  let activeOccasion = '';

  Object.keys(SPECIAL_OCCASIONS_SETTINGS).forEach((occasionKey: string) => {
    const settings: any = SPECIAL_OCCASIONS_SETTINGS[occasionKey];

    const checkStartDate = new Date(
      settings.startDateYear || currentDate.getFullYear(),
      settings.startDateMonth || currentDate.getMonth(),
      settings.startDateDay || settings.startDateDay,
      settings.startDateHour || 0,
    );

    const checkEndDate = new Date(
      settings.endDateYear || currentDate.getFullYear(),
      settings.endDateMonth || currentDate.getMonth(),
      settings.endDateDay || settings.startDateDay,
      settings.endDateHour || 0,
    );

    if (checkEndDate < checkStartDate) {
      checkEndDate.setFullYear(checkEndDate.getFullYear() + 1);
    }

    if (checkIfDateBetween(checkStartDate, checkEndDate, currentDate)) {
      activeOccasion = occasionKey;
    }
  });

  return activeOccasion;
};

export const SPECIAL_OCCASIONS_SETTINGS = {
  HALLOWEEN: {
    startDateHour: 0,
    startDateDay: 30,
    startDateMonth: 9,
    startDateYear: null,
    endDateHour: 9,
    endDateDay: 3,
    endDateMonth: 10,
    endDateYear: null,
  },
  APRIL: {
    startDateHour: 23,
    startDateDay: 31,
    startDateMonth: 2,
    endDateHour: 9,
    endDateDay: 2,
    endDateMonth: 3,
  },
  NEW_YEAR: {
    startDateHour: 0,
    startDateDay: 28,
    startDateMonth: 11,
    endDateHour: 23,
    endDateDay: 1,
    endDateMonth: 0,
  },
};
