import { Component, Input } from '@angular/core';

import { SupplierDetailsModel } from '../../../../model/supplier.model';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-supplier-business-address',
  templateUrl: './supplier-business-address.component.html',
  styleUrls: ['./supplier-business-address.component.scss'],
})
export class SupplierBusinessAddressComponent {
  @Input() supplierDetailsModel: SupplierDetailsModel;
  @Input() businessCodeLabel: string;
  @Input() customTitle: string;

  readonly userPermission = USER_PERMISSION;
}
