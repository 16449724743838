import { autoserialize } from 'cerialize';

interface ThemedOnboardingModelDTO {
  achAvailable: boolean;
  cssFile: string;
  instantAccess: boolean;
  instantPayouts: boolean;
  loadNetEarnings: boolean;
  offerAdvance: boolean;
  offerPcard: boolean;
  onboardingTemplateCode?: string;
  openInvoices: boolean;
  ppmAvailable: boolean;
  schedPayEnabled: boolean;
  site: string;
  supplierSite: string;
  wirAvailable: boolean;
  mpCreationPolicy?: string;
  mpTemplates?: any[];
  plaidStep?: boolean;
  sendTransferEmail?: boolean;
  efsRebatePercent?: number;
  minPaymentAmount?: number;
}

export class OnboardingModel {
  @autoserialize
  onboardingTemplateCode: string;

  @autoserialize
  minPaymentAmount: number;

  @autoserialize
  efsRebatePercent: number;

  @autoserialize
  instantAccess: boolean;

  @autoserialize
  schedPayEnabled: boolean;

  @autoserialize
  ppmAvailable: boolean;

  @autoserialize
  efsAvailable: boolean;

  @autoserialize
  achAvailable: boolean;

  @autoserialize
  wirAvailable: boolean;

  @autoserialize
  pendAapprovNeeded: boolean;

  @autoserialize
  offerAdvance: boolean;

  @autoserialize
  offerPcard: boolean;

  @autoserialize
  supplierSite: string;

  @autoserialize
  loadNetEarnings: boolean;

  @autoserialize
  mpTemplates?: Array<OnboardingMpModel>;

  constructor(
    onboardingTemplateCode: string,
    minPaymentAmount: number,
    efsRebatePercent: number,
    instantAccess: boolean,
    schedPayEnabled: boolean,
    ppmAvailable: boolean,
    efsAvailable: boolean,
    achAvailable: boolean,
    wirAvailable: boolean,
    pendAapprovNeeded: boolean,
    offerAdvance: boolean,
    offerPcard: boolean,
    supplierSite: string,
    loadNetEarnings: boolean,
    mpTemplates?: Array<OnboardingMpModel>,
  ) {
    this.onboardingTemplateCode = onboardingTemplateCode;
    this.minPaymentAmount = minPaymentAmount;
    this.efsRebatePercent = efsRebatePercent;
    this.instantAccess = instantAccess;
    this.schedPayEnabled = schedPayEnabled;
    this.ppmAvailable = ppmAvailable;
    this.efsAvailable = efsAvailable;
    this.achAvailable = achAvailable;
    this.wirAvailable = wirAvailable;
    this.pendAapprovNeeded = pendAapprovNeeded;
    this.offerAdvance = offerAdvance;
    this.offerPcard = offerPcard;
    this.supplierSite = supplierSite;
    this.loadNetEarnings = loadNetEarnings;
    this.mpTemplates = mpTemplates;
  }
}

export class OnboardingMpModel {
  @autoserialize
  onboardingTemplateCode: string;

  @autoserialize
  marketplaceKey: string;

  @autoserialize
  fundingPeriodGroupCode: string;

  @autoserialize
  feeScheduleKey: string;

  @autoserialize
  reservePercent: number;

  @autoserialize
  minReservePercent: number;

  @autoserialize
  rcvPurchThreshold: number;

  @autoserialize
  hideSplashScreen: boolean;

  @autoserialize
  feeFromReserve: boolean;

  @autoserialize
  monthlyMinFee: number;

  @autoserialize
  marketplaceName?: string;

  @autoserialize
  feeScheduleName?: string;

  @autoserialize
  onboardingTemplateMpKey?: string;

  constructor(
    onboardingTemplateCode: string,
    marketplaceKey: string,
    fundingPeriodGroupCode: string,
    feeScheduleKey: string,
    reservePercent: number,
    minReservePercent: number,
    rcvPurchThreshold: number,
    hideSplashScreen: boolean,
    feeFromReserve: boolean,
    monthlyMinFee: number,
    marketplaceName?: string,
    feeScheduleName?: string,
    onboardingTemplateMpKey?: string,
  ) {
    this.onboardingTemplateCode = onboardingTemplateCode;
    this.marketplaceKey = marketplaceKey;
    this.marketplaceName = marketplaceName;
    this.fundingPeriodGroupCode = fundingPeriodGroupCode;
    this.feeScheduleKey = feeScheduleKey;
    this.feeScheduleName = feeScheduleName;
    this.reservePercent = reservePercent;
    this.minReservePercent = minReservePercent;
    this.rcvPurchThreshold = rcvPurchThreshold;
    this.hideSplashScreen = hideSplashScreen;
    this.feeFromReserve = feeFromReserve;
    this.monthlyMinFee = monthlyMinFee;
    this.onboardingTemplateMpKey = onboardingTemplateMpKey;
  }
}

export class ThemeOnboardingModel {
  @autoserialize
  onboardingTemplateCode?: string;

  @autoserialize
  achAvailable: boolean;

  @autoserialize
  cssFile: string;

  @autoserialize
  instantAccess: boolean;

  @autoserialize
  instantPayouts: boolean;

  @autoserialize
  loadNetEarnings: boolean;

  @autoserialize
  offerAdvance: boolean;

  @autoserialize
  offerPcard: boolean;

  @autoserialize
  openInvoices: boolean;

  @autoserialize
  ppmAvailable: boolean;

  @autoserialize
  schedPayEnabled: boolean;

  @autoserialize
  site: string;

  @autoserialize
  supplierSite: string;

  @autoserialize
  wirAvailable: boolean;

  @autoserialize
  mpCreationPolicy?: string;

  @autoserialize
  mpTemplates?: any[];

  @autoserialize
  plaidStep: boolean;

  @autoserialize
  sendTransferEmail: boolean;

  @autoserialize
  efsRebatePercent: number;

  @autoserialize
  minPaymentAmount: number;

  constructor(params: ThemedOnboardingModelDTO) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        this[key] = params[key];
      }
    }
  }
}
