import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store/src/store';
import { Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { selectAuthToken } from '../../auth/store/auth.actions';
import { AppConfigService } from '../../config';
import { AppState } from '../../store/app.reducers';
import { BatchModel } from '../models/batch.model';
import { EmailModel } from '../models/email.model';
import { EventModel } from '../models/event.model';
import { CrmMetaModel } from '../models/meta.model';
import { EmailsServiceInterface } from './emails.service.interface';

@Injectable()
export class EmailsService implements EmailsServiceInterface {
  private apiUrl: string;
  private headers: { [name: string]: string } = {};

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient, private store: Store<AppState>) {
    this.apiUrl = appConfig.env.messagingUrl;

    this.headers['X-InstanceId'] = '' + appConfig.env.instanceId;
    this.headers['X-EventLogger-InstanceId'] = '' + appConfig.env.instanceId;

    this.store.select(selectAuthToken).subscribe((jwtToken) => {
      this.headers['Authorization'] = jwtToken;
    });
  }

  sendEmail(data: EmailModel): Observable<EmailModel> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (data.targetUuid) {
      headers['X-Actions-TargetUUID'] = data.targetUuid;
    }

    return this.httpClient
      .post(this.apiUrl + 'email/rendered/', Serialize(data, EmailModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data']['email_object'], EmailModel);
        }),
      );
  }

  previewEmail(data: Partial<EmailModel>): Observable<EmailModel> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (data.targetUuid) {
      headers['X-Actions-TargetUUID'] = data.targetUuid;
    }

    return this.httpClient
      .post(this.apiUrl + 'email/preview/', Serialize(data, EmailModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], EmailModel);
        }),
      );
  }

  addEmail(data: EmailModel): Observable<EmailModel> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (data.targetUuid) {
      headers['X-Actions-TargetUUID'] = data.targetUuid;
    }

    return this.httpClient
      .post(this.apiUrl + 'emails/', Serialize(data, EmailModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], EmailModel);
        }),
      );
  }

  editEmail(uuid: string, data: EmailModel): Observable<EmailModel> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (data.targetUuid) {
      headers['X-Actions-TargetUUID'] = data.targetUuid;
    }

    return this.httpClient
      .put(this.apiUrl + 'emails/' + uuid + '/', Serialize(data, EmailModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], EmailModel);
        }),
      );
  }

  getEmail(uuid: string): Observable<EmailModel> {
    const headers = { ...this.headers };

    return this.httpClient
      .get(this.apiUrl + 'emails/' + uuid + '/', {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], EmailModel);
        }),
      );
  }

  deleteEmail(uuid: string, targetUuid: string): Observable<Partial<EmailModel>> {
    const headers = { ...this.headers };

    if (!isNullOrUndefined(targetUuid)) {
      headers['X-Actions-TargetUUID'] = targetUuid;
    }

    return this.httpClient.delete(`${this.apiUrl}emails/${uuid}/`, {
      observe: 'body',
      responseType: 'json',
      headers: new HttpHeaders(headers),
    });
  }

  getEvents(uuid: string): Observable<EventModel[]> {
    return this.httpClient
      .get(`${this.apiUrl}emails/${uuid}/events/`, {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((res) => {
          return res['data'].map((item) => {
            DeserializeKeysFrom(UnderscoreCase);
            return Deserialize(item, EventModel);
          });
        }),
      );
  }

  approveEmail(emailUuid: string) {
    return this.httpClient
      .get(`${this.apiUrl}email/approve/${emailUuid}`, {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  cancelEmail(emailUuid: string) {
    return this.httpClient
      .get(`${this.apiUrl}email/cancel/${emailUuid}`, {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  approveBatch(batchUuid: string) {
    return this.httpClient
      .get(`${this.apiUrl}email/batch_approve/${batchUuid}`, {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  cancelBatch(batchUuid: string) {
    return this.httpClient
      .get(`${this.apiUrl}email/batch_cancel/${batchUuid}`, {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  sendBatch(supplierKeys, config) {
    return this.httpClient.post(
      `${this.apiUrl}email/batch/`,
      {
        suppliers_keys: supplierKeys,
        config,
      },
      { headers: new HttpHeaders(this.headers) },
    );
  }

  getBatchEmails(uuid: string): Observable<BatchModel[]> {
    const url = `${this.appConfig.env.internalApiUrl}batch_emails/${uuid}`;
    return this.httpClient
      .get(url, {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((res: any[]) => {
          return res.map((item) => Deserialize(item, BatchModel));
        }),
      );
  }

  listEmails(
    limit: number = 10,
    orderBy: any,
    filters: any,
    last: any,
    useAsyncPipe = false,
  ): Observable<{ data: BatchModel[]; meta: CrmMetaModel }> {
    let params = new HttpParams().set('limit', '' + limit);
    if (!isUndefined(last)) {
      params = params.set('last', JSON.stringify(last));
    }
    if (!isUndefined(orderBy)) {
      params = params.set('order_by', JSON.stringify(orderBy));
    }
    if (!isUndefined(filters)) {
      params = params.set('q', JSON.stringify(filters));
    }
    const headers = { ...this.headers };

    return this.httpClient
      .get(this.apiUrl + 'email/', {
        headers: new HttpHeaders(headers),
        params,
      })
      .pipe(
        handleBackendErrors(useAsyncPipe),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return {
            data: res['data'].map((item) => Deserialize(item, BatchModel)),
            meta: Deserialize(res['meta'], CrmMetaModel),
          };
        }),
      );
  }

  batchDeleteEmails(emailsIds) {
    return this.httpClient.post(`${this.apiUrl}emails/batch-delete/`, { uuids: emailsIds }, { headers: new HttpHeaders(this.headers) });
  }
}

// TODO nkler: we should not use async pipe / subscribe on same endpoint or catchError localy
function handleBackendErrors(useAsyncPipe) {
  return catchError((error) => {
    if (useAsyncPipe) {
      return observableOf({
        data: [],
        meta: {},
      });
    } else {
      return observableThrowError(error);
    }
  });
}
