import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { AppConfigService, themeConfigData } from './config';
import { SubscriberComponent } from './shared/component-subscriber/subscriber.component';
import { DynamicEmbedderService } from './shared/dynamic-embedder.service';
import { EVA_IFRAME_SELECTOR } from './shared/eva/const/eva-iframe-selector.enum';
import { EvaService } from './shared/eva/eva.service';
import { EvaCommunicationService } from './shared/eva/eva-communication.service';
import { FEATURE_FLAGS } from './shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from './shared/feature-flags/feature-flags.service';
import { CommunicationService } from './shared/iframe/communication/communication.service';
import { CommunicationMessage, MessageFromIframe } from './shared/iframe/communication/communication-message.model';
import { MESSAGE_TYPE } from './shared/iframe/communication/message-type.enum';
import { CrmStates, SupplierInfoStates } from './shared/layout/layout.enums';
import * as fromLayoutActions from './shared/layout/store/layout.actions';
import { LayoutState } from './shared/layout/store/layout.reducers';
import { USER_PERMISSION } from './shared/users/user-permissions.enum';
import { changeParentRouteCommand } from './shared/utils/communication-commands.util';
import { VpnTesterService } from './shared/vpn-tester.service';
import { AppState } from './store/app.reducers';
import { SupplierDetailsModel } from './suppliers/model/supplier.model';
import { selectPaymentMethods, selectSupplierDetails, UpdateSupplierDetailsModelByEva } from './suppliers/store/supplier/supplier.actions';

@Component({
  selector: 'app-internal-client',
  templateUrl: './app.component.html',
})
export class AppComponent extends SubscriberComponent implements OnInit {
  public title = 'Internal Client';
  public ThemeConfig: any;
  public layoutState$: Observable<LayoutState>;
  public crmPanelStates = CrmStates;
  public supplierInfoStates = SupplierInfoStates;

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly appConfig: AppConfigService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly dynamicEmbedder: DynamicEmbedderService,
    private readonly communicationService: CommunicationService,
    private readonly toastrService: ToastrService,
    private readonly vpnTesterService: VpnTesterService,
    private readonly featureFlagsService: FeatureFlagsService,
    private evaCommunicationService: EvaCommunicationService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
    this.initVpnTester();
  }

  ngOnInit() {
    this.ThemeConfig = themeConfigData(this.appConfig.env.envName);
    this.layoutState$ = this.store.select('layout');
    this.subscriptions.push(
      this.store.select('auth').subscribe((data) => {
        if (!isNullOrUndefined(data) && !isNullOrUndefined(data.user)) {
          const perms = data.user.perms.map((value) => value.permCode);
          this.permissionsService.loadPermissions(perms);
          this.hasRestrictedUWAccessPermission();
        }
      }),
    );

    this.subscriptions.push(
      this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe((evt) => {
        document.body.scrollTop = 0;
        this.store.dispatch(new fromLayoutActions.LayoutUpdate((<NavigationEnd>evt).urlAfterRedirects));

        this.sendFeebackToTopBar(evt as NavigationEnd);
      }),
    );

    this.listenEvaCommunication();

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter(Boolean))
        .subscribe((details: SupplierDetailsModel) => this.evaCommunicationService.updateSupplierDetailsStore(details)),
    );
    // this.initVpnTester();
  }

  private hasRestrictedUWAccessPermission() {
    const permission = this.permissionsService.getPermission(USER_PERMISSION.REST_UW_ACCESS);
    if (!isNullOrUndefined(permission)) {
      this.document.body.classList.add('restricted-access');
    } else {
      this.document.body.classList.remove('restricted-access');
    }
  }

  private listenEvaCommunication(): void {
    this.subscriptions.push(this.communicationService.incomingMessage$.subscribe((message) => this.doActionIfMessage(message)));
  }

  private doActionIfMessage(message: MessageFromIframe): void {
    const type = message.payload.type;

    switch (type) {
      case MESSAGE_TYPE.GENERAL__SHOW_TOAST:
        this.doActionIfToast(message);
        break;
      case MESSAGE_TYPE.GENERAL__UPDATE_SUPPLIER_DETAILS_STORE:
        this.doActionIfUpdateSupplierDetailsStore(message);
        break;
      case MESSAGE_TYPE.GET_STORE__SUPPLIER_DETAILS:
        this.doActionIfEvaNeedsSupplierDetails(message);
        break;
      case MESSAGE_TYPE.GENERAL__NAVIGATE_BY_LINK:
        this.doActionIfEvaNeedsRedirection(message);
        break;
      case MESSAGE_TYPE.GENERAL__ONLY_CHANGE_ROUTE:
        this.doActionIfEvaNeedsRedirection(message);
        break;
      case MESSAGE_TYPE.SUPPLIER_MENU__GET_MENU_CONDITIONS:
        this.doActionIfEvaNeedsSupplierMenuCondition(message);
        break;
    }
  }

  private doActionIfToast(message: MessageFromIframe): void {
    if (message.payload.data.type === 'success') {
      this.toastrService.success(message.payload.data.message);
      return;
    }

    this.toastrService.error(message.payload.data.message);
  }

  private doActionIfUpdateSupplierDetailsStore(message: MessageFromIframe): void {
    this.store.dispatch(
      new UpdateSupplierDetailsModelByEva({ supplier: message.payload.data.data, supplierKey: message.payload.data.supplierKey }),
    );
  }

  private doActionIfEvaNeedsSupplierDetails(message: MessageFromIframe): void {
    this.store
      .select(selectSupplierDetails)
      .pipe(
        filter((details) => details && details.supplierKey === message.payload.data.supplierKey),
        first(),
      )
      .subscribe((details) => {
        const msg = {
          type: MESSAGE_TYPE.UPDATE_EVA_STORE__SUPPLIER_DETAILS,
          data: { details },
        };

        this.communicationService.sendMessageToChild(message.pluginIframeId, msg);
      });
  }

  private doActionIfEvaNeedsRedirection(message: MessageFromIframe): void {
    this.router.navigate([message.payload.data.url]);
  }

  private doActionIfEvaNeedsSupplierMenuCondition(message: MessageFromIframe): void {
    this.subscriptions.push(
      combineLatest([this.store.select(selectSupplierDetails), this.store.select(selectPaymentMethods)])
        .pipe(filter(([supplierDetails, paymentMethods]) => !!(supplierDetails && paymentMethods)))
        .subscribe(([supplierDetails, paymentMethods]) => {
          const isAmazonLending = supplierDetails.isAmazonSeller > 0;
          const isSellerCard =
            Object.keys(paymentMethods)
              .map((item) => paymentMethods[item].paymentType)
              .indexOf('EFS') > -1 || supplierDetails.pcardAvailable;

          const messageToEva: CommunicationMessage = {
            type: MESSAGE_TYPE.SUPPLIER_MENU__GET_MENU_CONDITIONS,
            data: {
              isAmazonLending,
              isSellerCard,
            },
          };
          this.communicationService.sendMessageToChild(message.pluginIframeId, messageToEva);
        }),
    );
  }

  private sendFeebackToTopBar(evt: NavigationEnd): void {
    const frameId = EvaService.getIframeIdBySelector(EVA_IFRAME_SELECTOR.TOP_BAR);

    if (!frameId) {
      return;
    }

    this.communicationService.sendMessageToChild(frameId, changeParentRouteCommand(evt.url));
  }

  private initVpnTester(): void {
    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.CHECK_VPN)) {
      this.vpnTesterService.init();
    }
  }
}
