import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { ALL_OPTION } from './all-option';
import { ICheckboxFiltersOption } from './checkbox-filters-option.interface';

@Component({
  selector: 'app-checkbox-filters',
  templateUrl: './checkbox-filters.component.html',
})
export class CheckboxFiltersComponent implements OnChanges {
  @Input() options: ICheckboxFiltersOption[] = [];
  @Input() showAllOption = true;
  @Output() selectionChange = new EventEmitter<ICheckboxFiltersOption[]>();

  ngOnChanges(changes) {
    if (this.showAllOption) {
      this.addAllOption();
    }
  }

  addAllOption() {
    if (this.options && !this.isAllOption(this.options[0])) {
      this.options.unshift({
        value: ALL_OPTION,
        checked: this.getAllOptionStatus(),
        title: ALL_OPTION,
      });
    }
  }

  onCheckboxChange(event, option: ICheckboxFiltersOption) {
    option.checked = event.checked;
    if (this.isAllOption(option)) {
      this.setStatusForAllOptions(option.checked);
    }
    this.checkOptions();
  }

  checkOptions() {
    if (this.showAllOption) {
      this.checkAllOption();
    }

    this.selectionChange.emit(this.options);
  }

  checkAllOption() {
    const allSelected = this.getAllOptionStatus();
    this.options[0].checked = allSelected;
  }

  getAllOptionStatus() {
    return this.options.slice(1).every((option) => option.checked);
  }

  setStatusForAllOptions(checked: boolean) {
    this.options.forEach((option) => (option.checked = checked));
  }

  isAllOption(option: ICheckboxFiltersOption) {
    return option && option.value === ALL_OPTION;
  }

  selectedOptions() {
    return this.options.filter((option) => option.checked);
  }
}
