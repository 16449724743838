<div class="task-actions-wrapper">
  <button mat-raised-button [matMenuTriggerFor]="appMenu">
    Status
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #appMenu="matMenu">
    <button mat-menu-item *ngFor="let status of availableStatuses" (click)="changeStatus(status.key)">{{ status.name }}</button>
  </mat-menu>

  <button mat-raised-button [matMenuTriggerFor]="priorityMenu">
    Priority
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #priorityMenu="matMenu">
    <button mat-menu-item *ngFor="let priority of availablePriorities" (click)="changePrio(priority.key)">
      {{ priority.name }}
    </button>
  </mat-menu>

  <div class="change-due-date">
    <button mat-raised-button (click)="toogleCalendar()">Due date</button>
    <mat-calendar (selectedChange)="changeDate($event)" [selected]="dueDate" *ngIf="showCalendar"></mat-calendar>
  </div>

  <div class="change-assignee">
    <button mat-raised-button (click)="toogleAssignee()">Assign to</button>
    <app-assignee-picker *ngIf="showAsigneePicker" (onSelected)="changeAssignee($event)"></app-assignee-picker>
  </div>

  <button mat-raised-button (click)="changeStatus(TaskStatuses.CLOSED)">Close</button>
  <button mat-raised-button ngxClipboard [cbContent]="getEditUrls()" (click)="handleTaskUrlCopy($event)">
    <mat-icon>link</mat-icon>
  </button>
  <button mat-raised-button (click)="deleteTasks()">
    <mat-icon>delete</mat-icon>
  </button>
</div>
