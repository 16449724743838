import { Action, createSelector } from '@ngrx/store';
import { isUndefined } from 'util';

import { AppState } from '../../store/app.reducers';
import { CrmItem } from '../models/crm-item.type';
import { EmailModel } from '../models/email.model';
import { NoteDataModel } from '../models/note-data.model';
import { TaskModel } from '../models/task.model';
import { CrmState, CrmStateModel } from './crm.reducers';

export const NO_ACTION = 'NO_ACTION';
export const SET_TARGET_UUID = 'SET_TARGET_UUID';
export const ADD_CRM_MODEL = 'ADD_CRM_MODEL';
export const UPDATE_CRM_MODEL = 'UPDATE_CRM_MODEL';
export const DELETE_CRM_MODEL = 'DELETE_CRM_MODEL';
export const FETCH_CRM_ALL = 'FETCH_CRM_ALL';
export const FETCH_CRM_NOTES = 'FETCH_CRM_NOTES';
export const FETCH_CRM_FILES = 'FETCH_CRM_FILES';
export const FETCH_CRM_TASKS = 'FETCH_CRM_TASKS';
export const FETCH_CRM_EVENTS = 'FETCH_CRM_EVENTS';
export const FETCH_CRM_EMAILS = 'FETCH_CRM_EMAILS';
export const FETCH_CRM_BATCH_EMAILS = 'FETCH_CRM_BATCH_EMAILS';
export const FETCH_CRM_PINNED_NOTES = 'FETCH_CRM_PINNED_NOTES';
export const FETCH_CRM_DATA_ERROR = 'FETCH_CRM_DATA_ERROR';

export const CRM_ITEM_REQUEST = 'CRM_ITEM_REQUEST';
export const CRM_ITEM_RESPONSE_SUCCESS = 'CRM_ITEM_RESPONSE_SUCCESS';
export const CRM_ITEM_RESPONSE_ERROR = 'CRM_ITEM_RESPONSE_ERROR';
export const CRM_ITEM_CLEAR_STATUS = 'CRM_ITEM_CLEAR_STATUS';
export const CRM_CLEAR_CURRENT_STATE = 'CRM_CLEAR_CURRENT_STATE';
export const CRM_BEFORE_TASK_SAVE = 'CRM_BEFORE_TASK_SAVE';
export const CRM_AFTER_TASK_SAVE = 'CRM_AFTER_TASK_SAVE';

export const ADD_CRM_NOTE = 'ADD_CRM_NOTE';
export const EDIT_CRM_NOTE = 'EDIT_CRM_NOTE';
export const PIN_CRM_NOTE = 'PIN_CRM_NOTE';
export const DELETE_CRM_NOTE = 'DELETE_CRM_NOTE';

export const ADD_CRM_TASK = 'ADD_CRM_TASK';
export const EDIT_CRM_TASK = 'EDIT_CRM_TASK';
export const DELETE_CRM_TASK = 'DELETE_CRM_TASK';

export const ADD_CRM_FILE = 'ADD_CRM_FILE';
export const DELETE_CRM_FILE = 'DELETE_CRM_FILE';

export const SEND_SINGLE_EMAIL = 'SEND_SINGLE_EMAIL';

function createTimelineStreamSelector(streamName: string) {
  return createSelector(selectCrmStateModel, (crmCurrentState: CrmStateModel) => {
    if (!isUndefined(crmCurrentState[streamName])) {
      return crmCurrentState[streamName];
    }
  });
}

class CrmItemService {
  public payload: { actionId: string };

  constructor(payload: { actionId: string }) {
    this.payload = payload;
  }
}

class CrmTaskService {
  constructor(public payload: { data: TaskModel; uuid: string }) {}
}

export class CrmFetchListService {
  public payload: { refresh?: string; limit?: number; last?: any };

  constructor(payload?: { refresh?: string; limit?: number; last?: any }) {
    this.payload = payload;
  }
}

export class CrmItemRequest extends CrmItemService implements Action {
  readonly type = CRM_ITEM_REQUEST;
}
export class CrmItemResponseSuccess extends CrmItemService implements Action {
  readonly type = CRM_ITEM_RESPONSE_SUCCESS;
}
export class CrmItemResponseError extends CrmItemService implements Action {
  readonly type = CRM_ITEM_RESPONSE_ERROR;
}
export class CrmItemClearStatus extends CrmItemService implements Action {
  readonly type = CRM_ITEM_CLEAR_STATUS;
}
export class CrmBeforeTaskSave extends CrmTaskService implements Action {
  readonly type = CRM_BEFORE_TASK_SAVE;
}
export class CrmAfterTaskSave extends CrmTaskService implements Action {
  readonly type = CRM_AFTER_TASK_SAVE;
}
export class CrmClearCurrentState implements Action {
  readonly type = CRM_CLEAR_CURRENT_STATE;
}

export class SetTargetUuid implements Action {
  readonly type = SET_TARGET_UUID;
  constructor(public payload: { targetUuid: string }) {}
}

export class UpdateCrmModel implements Action {
  readonly type = UPDATE_CRM_MODEL;

  constructor(public payload: CrmStateModel, public targetUuid: string, public alterKeys: string[]) {}
}

export class AddCrmModel implements Action {
  readonly type = ADD_CRM_MODEL;

  constructor(public payload: { newItem: CrmItem; updateStreams: string[] }, public targetUuid: string) {}
}

export class DeleteCrmModel implements Action {
  readonly type = DELETE_CRM_MODEL;

  constructor(public payload: { itemsToDelete: string[] }, public targetUuid: string) {}
}

export class FetchCrmAll extends CrmFetchListService implements Action {
  readonly type = FETCH_CRM_ALL;
}
export class FetchCrmNotes extends CrmFetchListService implements Action {
  readonly type = FETCH_CRM_NOTES;
}
export class FetchCrmPinnedNotes extends CrmFetchListService implements Action {
  readonly type = FETCH_CRM_PINNED_NOTES;
}
export class FetchCrmFiles extends CrmFetchListService implements Action {
  readonly type = FETCH_CRM_FILES;
}
export class FetchCrmTasks extends CrmFetchListService implements Action {
  readonly type = FETCH_CRM_TASKS;
}
export class FetchCrmEvents extends CrmFetchListService implements Action {
  readonly type = FETCH_CRM_EVENTS;
}
export class FetchCrmEmails extends CrmFetchListService implements Action {
  readonly type = FETCH_CRM_EMAILS;
}
export class FetchCrmBatchEmails extends CrmFetchListService implements Action {
  readonly type = FETCH_CRM_BATCH_EMAILS;
}

export class AddCrmNote implements Action {
  readonly type = ADD_CRM_NOTE;
  public payload: { data: NoteDataModel; actionId: string };

  constructor(payload: { data: NoteDataModel; actionId: string }) {
    this.payload = payload;
  }
}

export class EditCrmNote implements Action {
  readonly type = EDIT_CRM_NOTE;
  public payload: { data: NoteDataModel; uuid: string };

  constructor(payload: { data: NoteDataModel; uuid: string }) {
    this.payload = payload;
  }
}

export class PinCrmNote implements Action {
  readonly type = PIN_CRM_NOTE;
  public payload: { data: any; uuid: string };

  constructor(payload: { uuid: string; data: any }) {
    this.payload = payload;
  }
}

export class DeleteCrmNote implements Action {
  readonly type = DELETE_CRM_NOTE;
  public payload: { uuid: string };

  constructor(payload: { uuid: string }) {
    this.payload = payload;
  }
}

export class AddCrmTask implements Action {
  readonly type = ADD_CRM_TASK;
  public payload: { data: TaskModel; actionId: string };

  constructor(payload: { data: TaskModel; actionId: string }) {
    this.payload = payload;
  }
}

export class EditCrmTask extends CrmTaskService implements Action {
  readonly type = EDIT_CRM_TASK;
}

export class DeleteCrmTask implements Action {
  readonly type = DELETE_CRM_TASK;

  constructor(public payload: { uuid: string; targetUuid: string }) {}
}

export class AddCrmFile implements Action {
  readonly type = ADD_CRM_FILE;
  public payload: { data: any; actionId: string };

  constructor(payload: { data: any; actionId: string }) {
    this.payload = payload;
  }
}

export class DeleteCrmFile implements Action {
  readonly type = DELETE_CRM_FILE;

  constructor(public payload: { uuid: string; b2bUuid: string; targetUuid: string }) {}
}

export class SendSingleEmail implements Action {
  readonly type = SEND_SINGLE_EMAIL;
  public payload: { data: EmailModel; actionId: string };

  constructor(payload: { data: EmailModel; actionId: string }) {
    this.payload = payload;
  }
}

const selectCrm = (state: AppState) => state.crm;

export const selectCrmTargetUuid = createSelector(selectCrm, (crm: CrmState) => {
  if (!isUndefined(crm.targetUuid)) {
    return crm.targetUuid;
  }
});

export const selectCrmStateModel = createSelector(selectCrm, (crm: CrmState) => {
  if (!isUndefined(crm.crmCurrentState)) {
    return crm.crmCurrentState;
  }
});

export const selectCrmItems = createSelector(selectCrmStateModel, (crmCurrentState: CrmStateModel) => {
  if (!isUndefined(crmCurrentState.crmItems)) {
    return crmCurrentState.crmItems;
  }
});

export const selectPendingCrmStates = createSelector(selectCrm, (crm: CrmState) => {
  if (!isUndefined(crm.crmPendingItems)) {
    return crm.crmPendingItems;
  }
});

export const selectCrmAll = createTimelineStreamSelector('all');
export const selectCrmNotes = createTimelineStreamSelector('notes');
export const selectCrmFiles = createTimelineStreamSelector('files');
export const selectCrmTasks = createTimelineStreamSelector('tasks');
export const selectCrmEvents = createTimelineStreamSelector('events');
export const selectCrmEmails = createTimelineStreamSelector('emails');
export const selectCrmBatchEmails = createTimelineStreamSelector('batchEmails');
export const selectCrmPinnedNotes = createTimelineStreamSelector('pinnedNotes');

export type Actions =
  | CrmBeforeTaskSave
  | CrmAfterTaskSave
  | CrmItemRequest
  | CrmItemResponseSuccess
  | CrmItemResponseError
  | CrmItemClearStatus
  | CrmClearCurrentState
  | SetTargetUuid
  | AddCrmModel
  | UpdateCrmModel
  | DeleteCrmModel
  | FetchCrmAll
  | FetchCrmNotes
  | AddCrmNote
  | EditCrmNote
  | PinCrmNote
  | DeleteCrmNote
  | AddCrmTask
  | EditCrmTask
  | DeleteCrmTask
  | SendSingleEmail;
