import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { SupplierService } from '../../../services/supplier.service';
import { CliService } from './../../../services/cli/cli.service';
import { ReversiblePayment } from './failed-payment.model';

@Component({
  selector: 'app-failed-payment',
  templateUrl: './failed-payment.component.html',
  styleUrls: ['./failed-payment.component.scss'],
})
export class FailedPaymentComponent implements OnInit {
  // Fields
  public readonly reportName: string = 'xacts';

  public reversiblePayments: ReversiblePayment[];
  public transactionKey: string;
  public subPaymentKey: string;
  public reason: string;
  public dictionary: any;

  private baseHttpParams: any;
  private supplierKey: string;

  // Flags
  private isOpperationSubmited = false;
  private isOpperationConfirmed = false;
  private isErrorOccured = false;
  private isEmptyResultset = false;
  private isLoaded = false;

  // Accessors
  public get loaded(): boolean {
    return this.isLoaded;
  }

  public get invalid(): boolean {
    return isNullOrUndefined(this.subPaymentKey) || isNullOrUndefined(this.reason) || this.reason.length === 0;
  }

  public get disabled(): boolean {
    return this.showConfirmationScreen;
  }

  public get showEditionScreen(): boolean {
    return !this.showErrorScreen && !this.isOpperationSubmited && !this.isOpperationConfirmed;
  }

  public get showConfirmationScreen(): boolean {
    return !this.showErrorScreen && this.isOpperationSubmited && !this.isOpperationConfirmed;
  }

  public get showErrorScreen(): boolean {
    return this.isErrorOccured || this.isEmptyResultset;
  }

  constructor(
    private cliService: CliService,
    private service: SupplierService,
    private toastrService: ToastrService,
    private pipe: CurrencyPipe,
    public dialogRef: MatDialogRef<FailedPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (!isNullOrUndefined(data)) {
      this.supplierKey = data.supplierKey;
      this.transactionKey = data.transactionKey;
    }
  }

  // Methods
  public ngOnInit(): void {
    this.baseHttpParams = {
      limit: 9999,
      offset: 0,
    };

    this.getData();
  }

  public okOnClick(): void {
    this.closeDialog();
  }

  public submitOnClick(): void {
    this.isOpperationSubmited = true;
  }

  public denyOnClick(): void {
    this.isOpperationSubmited = false;
  }

  public confirmOnClick(): void {
    if (!this.invalid) {
      const payments = this.reversiblePayments.filter(
        (item) => item.detailData && item.detailData.sup_pmt_sup_pmt_cfg_key === this.subPaymentKey,
      );

      if (payments && payments.length === 1) {
        const data = {
          post_date: Date.now() / 1000,
          supplier_key: this.supplierKey,
          reason: this.reason,
          amount: payments[0].detailAmount,
          marketplace: '',
        };

        this.cliService.addFailedPayment(this.subPaymentKey, data).subscribe(
          (success) => {
            this.toastrService.success('Failed payment transaction created successfuly.');
            this.closeDialog({ success: true });
          },
          (error) => {
            const message =
              error.error && error.error.message ? error.error.message : 'An error occurred while creating failed payment transaction.';
            this.toastrService.error(message);
          },
        );
      }
    }
  }

  public cancelOnClick(): void {
    this.closeDialog();
  }

  private getData(): void {
    this.service.getSupplierNestedReport(this.supplierKey, this.reportName, this.transactionKey, this.baseHttpParams).subscribe(
      (data) => {
        if (data && data.results && data.results.length > 0) {
          this.reversiblePayments = data.results;
          this.dictionary = this.reversiblePayments
            .filter((item) => item.detailData !== undefined)
            .map((item) => {
              return {
                label: item.detailText + ' - ' + this.pipe.transform(item.detailAmount, 'USD', 'symbol'),
                id: item.detailData ? item.detailData.sup_pmt_sup_pmt_cfg_key : '',
              };
            });
        } else {
          this.isEmptyResultset = true;
        }

        this.isLoaded = true;
      },
      (error) => {
        this.isErrorOccured = true;
        this.isLoaded = true;
      },
    );
  }

  private closeDialog(payload?: any): void {
    this.dialogRef.close(payload);
  }
}
