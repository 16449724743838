import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { AppState } from '../../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';
import { SupplierAdvancesService } from './../../service/supplier-advances.service';

@Component({
  selector: 'app-offer-eligibility-details',
  templateUrl: './offer-eligibility-details.component.html',
  styleUrls: ['./offer-eligibility-details.component.scss'],
})
export class OfferEligibilityDetailsComponent extends SubscriberComponent implements OnInit {
  @Input() elegibilityData;
  isLoading = true;
  mlOfferDetails: any;

  private mlOfferKey: string;
  private supplierKey: string;

  constructor(
    private readonly supplierAdvanceService: SupplierAdvancesService,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;

          this.subscriptions.push(
            this.route.params.subscribe((mlOfferKeyParams) => {
              this.mlOfferKey = mlOfferKeyParams['mlOfferKey'];

              this.subscriptions.push(
                this.supplierAdvanceService.getMlOfferDetails(this.supplierKey, this.mlOfferKey).subscribe((mlOfferDetails) => {
                  this.isLoading = false;
                  this.mlOfferDetails = mlOfferDetails;
                }),
              );
            }),
          );
        }),
    );
  }
}
