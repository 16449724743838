import { Action } from '@ngrx/store';

import { GlobalSetting } from '../models/global-setting.model';

export enum GLOBAL_SETTINGS_ACTION {
  FETCH_ALL = '[GLOBAL SETTINGS] Fetch All',
  FETCH_ALL_SUCCESS = '[GLOBAL SETTINGS] Fetch All Success',
  UPDATE_CONFIG = '[GLOBAL SETTINGS] Update Config',
}

export class GlobalSettingsFetchAll implements Action {
  readonly type = GLOBAL_SETTINGS_ACTION.FETCH_ALL;
}

export class GlobalSettingsFetchAllSuccess implements Action {
  readonly type = GLOBAL_SETTINGS_ACTION.FETCH_ALL_SUCCESS;

  constructor(public payload: { configs: GlobalSetting[] }) {}
}

export class GlobalSettingsUpdateConfig implements Action {
  readonly type = GLOBAL_SETTINGS_ACTION.UPDATE_CONFIG;

  constructor(public payload: { config: GlobalSetting }) {}
}

export type GlobalSettingsActions = GlobalSettingsFetchAll | GlobalSettingsFetchAllSuccess | GlobalSettingsUpdateConfig;
