import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import Logger from '../../../shared/logger';
import { TableEventsService } from '../../../shared/table-events.service';
import { trackByProp } from '../../../shared/utils/track-by-prop.util';
import { AppState } from '../../../store/app.reducers';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';

@Component({
  selector: 'app-add-zero-marketplace-payment',
  templateUrl: './add-zero-marketplace-payment.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddZeroMarketplacePaymentComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  readonly trackByKey = trackByProp('mp_statement_key');
  actionTitle = 'Add Zero Marketplace Payment';
  marketplaceStatements = [];

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.supplierKey = data.supplierKey;

          this.getMarketplaceStatements();
          this.marketplaces = data.marketplaces.map((element) => {
            return {
              label: element.externalSupplierId
                ? element.marketplaceName + ' (' + element.externalSupplierId + ')'
                : element.marketplaceName,
              id: element.mpSupKey,
            };
          });
        }
      }),
    );
    super.ngOnInit();
  }

  createForm(): void {
    this.supplierActionForm = this.formBuilder.group({
      mp_sup_key: ['', Validators.required],
      mp_pmt_external_id: ['', Validators.required],
      fp_end_date: [moment().toDate(), Validators.required],
      post_date: [moment().subtract(1, 'days').toDate(), Validators.required],
      reason: ['', Validators.required],
      mp_statement_key: [''],
      exclude_from_mp_statement_balance: [{ value: false, disabled: true }],
    });

    this.subscriptions.push(
      this.supplierActionForm
        .get('fp_end_date')
        .valueChanges.pipe(
          distinctUntilChanged(),
          filter(() => !this.isSaved),
        )
        .subscribe((value) => {
          if (value) {
            this.supplierActionForm.controls.mp_statement_key.clearValidators();
            this.supplierActionForm.controls.mp_statement_key.setValue('');
          } else {
            this.supplierActionForm.controls.mp_statement_key.setValidators([Validators.required]);
          }
          this.supplierActionForm.controls.mp_statement_key.updateValueAndValidity();
        }),
    );

    this.subscriptions.push(
      this.supplierActionForm
        .get('mp_statement_key')
        .valueChanges.pipe(
          distinctUntilChanged(),
          filter(() => !this.isSaved),
        )
        .subscribe((value) => {
          if (value) {
            this.supplierActionForm.controls.fp_end_date.clearValidators();
            this.supplierActionForm.controls.fp_end_date.setValue(null);
          } else {
            this.supplierActionForm.controls.fp_end_date.setValidators([Validators.required]);
          }
          this.supplierActionForm.controls.fp_end_date.updateValueAndValidity();
          this.toggleExcludeFromMp(value);
        }),
    );

    this.supplierActionForm.updateValueAndValidity();
  }

  toggleExcludeFromMp(value: any): void {
    const excludeFromMp = this.supplierActionForm.get('exclude_from_mp_statement_balance');
    if (value) {
      excludeFromMp.enable();
      return;
    }
    excludeFromMp.setValue(false);
    excludeFromMp.disable();
  }

  toggleDisabling(fieldName: string, value: any): void {
    if (this.isSaved) {
      return;
    }
    const field = this.supplierActionForm.get(fieldName);

    if (value) {
      if (field.enabled) {
        field.disable();
      }
    } else {
      if (field.disabled) {
        field.enable();
      }
    }
  }

  triggerToggleDisabling() {
    this.toggleDisabling('fp_end_date', this.supplierActionForm.get('mp_statement_key').value);
    this.toggleDisabling('mp_statement_key', this.supplierActionForm.get('fp_end_date').value);
  }

  submit() {
    this.isConfirmed = true;
    this.isLoadingResult = true;
    const dataToSave = {
      supplier_key: this.supplierKey,
      ...this.supplierActionForm.value,
    };

    dataToSave.post_date = dataToSave.post_date.toISOString().substring(0, 10);
    if (dataToSave.fp_end_date) {
      dataToSave.fp_end_date = dataToSave.fp_end_date.toISOString().substring(0, 10);
    }

    if (!dataToSave.fp_end_date) {
      delete dataToSave.fp_end_date;
    }
    if (!dataToSave.mp_statement_key) {
      delete dataToSave.mp_statement_key;
    }

    this.subscriptions.push(
      this.cliService.addPayment(dataToSave).subscribe(
        () => {
          this.onSuccess('Zero marketplace payment added successfuly.');
        },
        (response) => {
          this.onError(response.error?.message || 'Unknown error. Propably request was cutted by API Gateway');
        },
      ),
    );
  }

  getMarketplaceStatements() {
    this.isLoadingResult = true;
    this.subscriptions.push(
      this.supplierService
        .getMarketplaceStatements(this.supplierKey, '')
        .pipe(finalize(() => (this.isLoadingResult = false)))
        .subscribe(
          (response: any) => {
            if (!isNullOrUndefined(response)) {
              this.marketplaceStatements = response.results && response.results.length > 0 ? response.results : [];
            }
          },
          (error) => Logger.error(error),
        ),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
