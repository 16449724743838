<mat-card class="calculator">
  <div class="calculator__header">
    <div class="calculator__header__periodFee">Fee Period</div>
    <div class="calculator__header__periodLength">Period Length</div>
    <div class="calculator__header__fee">Fee %</div>
    <div class="calculator__header__add">
      <button mat-icon-button color="primary" (click)="addPeriod()" [disabled]="isAddDisabled || isReadonly">
        <mat-icon aria-label="Add new row">add_circle</mat-icon>
      </button>
    </div>
  </div>

  <app-fee-calculator-row *ngFor="let period of periods; let i = index; trackBy: identify" [periodNumber]="i"></app-fee-calculator-row>

  <div class="calculator__total">
    <div class="calculator__total__label">Total</div>
    <div [ngClass]="{ 'text-warn': isTotalPeriodToLong }" class="calculator__total__periodLength">{{ totalPeriodLength }} Weeks</div>
    <div [ngClass]="{ 'text-warn': isTotalFeeIncorrect }" class="calculator__total__fee">{{ totalFee }}%</div>
    <div></div>
  </div>

  <div class="calculator__average">
    <div class="calculator__average__label">Average Fee %</div>
    <div></div>
    <div class="calculator__average__fee">{{ averageFee }}%</div>
    <div></div>
  </div>
</mat-card>
