<div class="card" *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
  <div class="card-content">
    <app-underwriting-initial-card-header
      [chipText]="'Automated'"
      [chipColor]="'accent'"
      [title]="'International Supplier'"
      [status]="headerStatus"
      [type]="headerType"
    >
    </app-underwriting-initial-card-header>

    <div class="table-wrapper" *ngIf="supplierDetailsModel?.incorporatedInTheUS === false; else notInternationalSupplier">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="20%" class="text-right">Target</th>
            <th width="20%" class="text-right">Actual</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of internationalSupplier; let $index = index">
            <td>{{ item?.text }}</td>
            <td class="text-right">
              {{ item?.target ? 'Yes' : 'No' }}
            </td>
            <td *ngIf="dataFetch" class="text-right">
              {{ item?.actual ? 'Yes' : 'No' }}
            </td>
            <td *ngIf="!dataFetch" class="text-right">-</td>
          </tr>
          <tr>
            <td colspan="2">Override International Supplier results</td>
            <td colspan="2" class="text-right" width="20%">
              <app-radiobuttons
                [value]="internationalSupplierOverride[0].value"
                [labelPosition]="'before'"
                [options]="radiobuttons"
                (onChange)="radiobuttonsChange($event)"
              >
              </app-radiobuttons>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #notInternationalSupplier>
  <p>Not an International Supplier.</p>
</ng-template>
