<ng-template #loadingCard>
  <div class="card-content">
    <app-loading-spinner [size]="1"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #loadingForm>
  <div class="form-content">
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #loading>
  <div class="row">
    <div class="col text-center" data-test="loadingMarketplace">Loading marketplace...</div>
  </div>
</ng-template>

<div *ngIf="marketplaceDetailsModel && fundingModel; else loading" class="row">
  <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-12' : 'col-lg-5 col-sm-6 col-xs-12'">
    <div class="card" *ngIf="formGroups && formGroups[FUNDING_FORM]; else loadingForm; let fundingForm">
      <div class="card-content">
        <div class="card-title">Summary</div>
        <form app-card-formgroup [isGroupEditable]="false">
          <mat-card-header>
            <div mat-card-avatar>
              <img class="marketplace-img" *ngIf="marketplaceDetailsModel?.icon; else marketplaceIcon" [src]="marketplaceIconSanitized" />
              <ng-template #marketplaceIcon>
                <i class="material-icons marketplace-icon">store_mall_directory</i>
              </ng-template>
            </div>
            <mat-card-title>
              <h3>
                {{ marketplaceDetailsModel.marketplaceName }}
                <span
                  *ngIf="fundingForm.get('fundPartStatus').value; else loadingForm; let fundPartStatus"
                  class="badge"
                  [ngClass]="{
                    'badge-warning': fundPartStatus === 'Not Sent',
                    'badge-info': fundPartStatus === 'Sent',
                    'badge-danger': fundPartStatus === 'Rejected',
                    'badge-success': fundPartStatus === 'Approved'
                  }"
                >
                  {{ fundPartStatus }}
                </span>
              </h3>
            </mat-card-title>
            <mat-card-subtitle>
              <pre ngxClipboard [cbContent]="marketplaceDetailsModel.mpSupKey" matTooltip="Copy MP-Sup Key to clipboard">{{
                marketplaceDetailsModel.mpSupKey
              }}</pre>
            </mat-card-subtitle>
            <mat-chip-list class="pull-right">
              <mat-chip *ngIf="marketplaceDetailsModel.draft" [color]="'default'">Draft</mat-chip>
              <mat-chip *ngIf="marketplaceDetailsModel.marketplaceStatus.hidden" [color]="'accent'">Hidden</mat-chip>
            </mat-chip-list>
          </mat-card-header>
        </form>
        <div class="divider divider-sm"></div>

        <app-opt-in-table [mpSupKey]="routeMpSupKey"></app-opt-in-table>

        <form
          app-card-formgroup
          *ngIf="fundingForm"
          #formGeneralSettings
          [formGroup]="fundingForm"
          (onSave)="saveFundingFormGroup(fundingForm, FUNDING_FORM, customRequestMapper.bind(this))"
        >
          <app-inline-edit-select
            *ngIf="formGeneralSettings.inEdit"
            [formControlName]="'fundPartStatus'"
            [formGroup]="fundingForm"
            [label]="'Status'"
            [disableInline]="true"
            [inGroupEdit]="formGeneralSettings.inEdit"
            [items]="fundStatusList"
            (onSave)="saveSingleField('fundPartStatus', $event)"
            [helpText]="'Modification may interrupt payments until supplier accepts.'"
          >
          </app-inline-edit-select>

          <app-inline-edit-text
            [formControlName]="'advanceRate'"
            [formGroup]="fundingForm"
            [label]="'Advance Rate (%)'"
            [disableInline]="true"
            [type]="'number'"
            [formatter]="advanceRateFormatter"
            [inGroupEdit]="formGeneralSettings.inEdit"
            [helpText]="'Modification may interrupt payments until supplier accepts.'"
            (onSave)="saveSingleField('advanceRate', $event)"
          >
          </app-inline-edit-text>

          <div class="row">
            <app-inline-edit-text
              type="number"
              class="col-md-12"
              [formControlName]="'minimumReservePct'"
              [formGroup]="fundingForm"
              [disableInline]="true"
              [formatter]="minimumReservePctFormatter"
              [label]="'Minimum Reserve (%)'"
              [inGroupEdit]="formGeneralSettings.inEdit"
              (onSave)="saveSingleField('minimumReservePct', $event)"
            >
            </app-inline-edit-text>

            <app-inline-edit-text
              type="text"
              [displayType]="'currency'"
              class="col-md-6"
              [formControlName]="'creditLimit'"
              [formGroup]="fundingForm"
              [label]="'Credit Limit'"
              [disableInline]="true"
              [inGroupEdit]="formGeneralSettings.inEdit"
              (onSave)="saveSingleField('creditLimit', $event)"
            >
            </app-inline-edit-text>

            <app-inline-edit-text
              type="number"
              class="col-md-6"
              [formControlName]="'concentrationLimit'"
              [formGroup]="fundingForm"
              [label]="'Concentration Limit'"
              [disableInline]="true"
              [inGroupEdit]="formGeneralSettings.inEdit"
              (onSave)="saveSingleField('concentrationLimit', $event)"
            >
            </app-inline-edit-text>

            <div class="col-md-12">
              <div
                class="funding-period-group"
                ngxClipboard
                [cbContent]="fundingForm.get('fundingPeriodGroup').value"
                matTooltip="Click to copy value"
              >
                Funding period:
                <span class="badge badge-pill badge-light">{{ fundingForm.get('fundingPeriodGroup').value }}</span>
                <a><i class="nav-icon material-icons">content_copy</i></a>
              </div>
            </div>
          </div>
        </form>

        <form
          app-card-formgroup
          *ngIf="formGroups && formGroups[REVENUE_FORM]; else loadingForm; let revenueForm"
          #formGeneralSettings
          [formGroup]="revenueForm"
          (onSave)="saveFormGroup(revenueForm, REVENUE_FORM, customRequestMapper.bind(this))"
        >
          <div class="row">
            <app-inline-edit-text
              type="text"
              class="col-md-6"
              [formControlName]="'estimatedMonthlyRevenue'"
              [formGroup]="revenueForm"
              [label]="'Estimated Monthly Revenue'"
              [disableInline]="true"
              [inGroupEdit]="formGeneralSettings.inEdit"
              (onSave)="saveSingleField('estimatedMonthlyRevenue', $event)"
            >
            </app-inline-edit-text>

            <app-inline-edit-select
              class="col-md-6"
              [formControlName]="'lengthTimeSelling'"
              [formGroup]="revenueForm"
              [label]="'Length of Time Selling'"
              [disableInline]="true"
              [inGroupEdit]="formGeneralSettings.inEdit"
              [items]="sellingTimeRanges$"
            >
            </app-inline-edit-select>

            <div class="col-md-12">
              <button
                [disabled]="disableRefreshButton"
                (click)="refreshEstimatedReveneueRowData()"
                class="refresh-button"
                mat-raised-button
                color="primary"
              >
                Refresh
              </button>
            </div>
          </div>
        </form>

        <form
          app-card-formgroup
          *ngIf="formGroups[PAYMENT_FORM]; else loadingForm; let paymentForm"
          #formPayment
          [title]="'Payment'"
          [formGroup]="paymentForm"
          (onSave)="saveFormGroup(paymentForm, PAYMENT_FORM, customRequestMapper.bind(this))"
        >
          <div class="row">
            <app-inline-edit-text
              class="col-md-6"
              type="text"
              [formControlName]="'paymentTerms'"
              [formGroup]="paymentForm"
              [label]="'Payment Terms'"
              [inGroupEdit]="formPayment.inEdit"
              (onSave)="saveSingleField('paymentTerms', $event, PAYMENT_FORM, customRequestMapper.bind(this))"
            >
            </app-inline-edit-text>

            <app-inline-edit-yes-no
              class="col-md-6"
              [formControlName]="'loadTransactionWithDelayPayment'"
              [formGroup]="paymentForm"
              [inGroupEdit]="formPayment.inEdit"
              [label]="'Load Transaction With Delay Payment'"
              (onSave)="saveSingleField('loadTransactionWithDelayPayment', $event, PAYMENT_FORM, customRequestMapper.bind(this))"
              type="text"
            >
            </app-inline-edit-yes-no>
          </div>
          <div class="row mt-3">
            <app-inline-edit-text
              type="number"
              class="col-md-6"
              [formControlName]="'paymentTermsDays'"
              [formGroup]="paymentForm"
              [label]="'Payment Terms Days'"
              [inGroupEdit]="formPayment.inEdit"
              (onSave)="saveSingleField('paymentTermsDays', $event, PAYMENT_FORM, customRequestMapper.bind(this))"
            >
            </app-inline-edit-text>

            <app-inline-edit-text
              type="number"
              class="col-md-6"
              [formControlName]="'paymentFrequency'"
              [formGroup]="paymentForm"
              [label]="'Payment Frequency'"
              [inGroupEdit]="formPayment.inEdit"
              (onSave)="saveSingleField('paymentFrequency', $event, PAYMENT_FORM, customRequestMapper.bind(this))"
            >
            </app-inline-edit-text>
          </div>
          <div class="row mt-3">
            <app-inline-edit-yes-no
              class="col-md-6"
              [formControlName]="'overrideDepositAccount'"
              [formGroup]="paymentForm"
              [inGroupEdit]="formPayment.inEdit"
              [label]="'Override diversion check for deposit account'"
              (onSave)="saveSingleField('overrideDepositAccount', $event, PAYMENT_FORM, customRequestMapper.bind(this))"
              type="text"
            >
            </app-inline-edit-yes-no>

            <app-inline-edit-yes-no
              class="col-md-6"
              formControlName="overrideDailyPlaidCheck"
              [formGroup]="paymentForm"
              [inGroupEdit]="formPayment.inEdit"
              [label]="'Override daily Plaid check and debit creation'"
              (onSave)="saveSingleField('overrideDailyPlaidCheck', $event, PAYMENT_FORM, customRequestMapper.bind(this))"
              type="text"
              [hidden]="!hasFinancePermission"
            >
            </app-inline-edit-yes-no>
          </div>
          <div class="row mt-3">
            <app-inline-edit-yes-no
              class="col-md-6"
              [formControlName]="'overrideDeltaCheck'"
              [formGroup]="paymentForm"
              [inGroupEdit]="formPayment.inEdit"
              [label]="'Override Delta Check for this Marketplace'"
              (onSave)="saveSingleField('overrideDeltaCheck', $event, PAYMENT_FORM, customRequestMapper.bind(this))"
              type="text"
            >
            </app-inline-edit-yes-no>
          </div>
        </form>
        <form
          app-card-formgroup
          *ngIf="formGroups[INSTANT_FORM]; else loadingForm; let instantForm"
          #formInstant
          [title]="'Instant Advance'"
          [formGroup]="instantForm"
          (onSave)="saveFormGroup(instantForm, INSTANT_FORM, customRequestMapper.bind(this))"
        >
          <div class="row">
            <app-inline-edit-yes-no
              class="col-md-6"
              [formControlName]="'payAdvance'"
              [formGroup]="instantForm"
              [inGroupEdit]="formInstant.inEdit"
              [label]="'Pay Advance'"
              (onSave)="saveSingleField('payAdvance', $event, INSTANT_FORM, customRequestMapper.bind(this))"
              type="text"
            >
            </app-inline-edit-yes-no>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-12' : 'col-lg-5 col-sm-6 col-xs-12'">
    <div class="card">
      <div class="card-content">
        <app-marketplace-access-details
          [title]="'Marketplace Access Details'"
          data-test="marketplaceAccessDetails"
        ></app-marketplace-access-details>
      </div>
    </div>
    <div class="card min-height">
      <div class="card-content">
        <div class="card-title">
          API Settings
          <button *ngIf="isAmazonSellerCentral()" mat-raised-button color="primary" (click)="checkAmazonApi()">Check API</button>
        </div>
        <form
          app-card-formgroup
          *ngIf="formGroups[API_STATUS_FORM]; else loadingForm; let apiStatusForm"
          #formApiStatus
          [formGroup]="apiStatusForm"
          [showClear]="false"
          (onSave)="saveFormGroup(apiStatusForm, API_STATUS_FORM, customRequestMapper.bind(this))"
        >
          <div class="row">
            <app-inline-edit-select
              class="col-6"
              [formControlName]="'apiStatus'"
              [formGroup]="apiStatusForm"
              [label]="'API Status'"
              [inGroupEdit]="formApiStatus.inEdit"
              [items]="apiStatuses"
              [showCopy]="true"
              (onSave)="saveSingleField('apiStatus', $event)"
            >
            </app-inline-edit-select>

            <app-inline-edit-text
              class="col-6"
              [formControlName]="'ascSellerId'"
              [formGroup]="apiStatusForm"
              [label]="'Seller ID'"
              [inGroupEdit]="formApiStatus.inEdit"
              [showCopy]="true"
              (onSave)="saveSingleField('ascSellerId', $event)"
            >
            </app-inline-edit-text>

            <app-inline-edit-text
              class="col-6"
              [formControlName]="'ascMarketplaceId'"
              [formGroup]="apiStatusForm"
              [label]="'Marketplace ID'"
              [inGroupEdit]="formApiStatus.inEdit"
              [showCopy]="true"
              (onSave)="saveSingleField('ascMarketplaceId', $event)"
            >
            </app-inline-edit-text>

            <app-inline-edit-text
              class="col-6"
              [formControlName]="'ascDevAccountNumber'"
              [formGroup]="apiStatusForm"
              [label]="'Developer Account Number'"
              [inGroupEdit]="formApiStatus.inEdit"
              [showCopy]="true"
              (onSave)="saveSingleField('ascDevAccountNumber', $event)"
            >
            </app-inline-edit-text>

            <app-inline-add-text
              class="col-6"
              [formControlName]="'ascAwsAccessKeyId'"
              [formGroup]="apiStatusForm"
              [label]="'AWS Access Key ID'"
              [inGroupEdit]="formApiStatus.inEdit"
              [showCopy]="false"
              (onSave)="saveSingleField('ascAwsAccessKeyId', $event)"
            >
            </app-inline-add-text>

            <app-inline-edit-text
              class="col-6"
              [formControlName]="'ascAwsSecretKey'"
              [formGroup]="apiStatusForm"
              [label]="'AWS Secret Key'"
              [inGroupEdit]="formApiStatus.inEdit"
              [showCopy]="true"
              (onSave)="saveSingleField('ascAwsSecretKey', $event)"
            >
            </app-inline-edit-text>

            <app-inline-edit-date-and-time
              class="col-6"
              [formControlNamee]="'lastCheckedTs'"
              [formGroup]="apiStatusForm"
              [label]="'Last Checked'"
              [disableInline]="true"
              [isReadOnly]="true"
              [inGroupEdit]="formApiStatus.inEdit"
              [showCopy]="true"
              [disabled]="true"
              (onSave)="saveSingleField('lastCheckedTs', $event)"
            >
            </app-inline-edit-date-and-time>

            <app-inline-edit-date-and-time
              class="col-6"
              [formControlNamee]="'apiLastCheckedTs'"
              [formGroup]="apiStatusForm"
              [label]="'API Last Checked'"
              [disabled]="true"
              [disableInline]="true"
              [isReadOnly]="true"
              [showCopy]="true"
              (onSave)="saveSingleField('apiLastCheckedTs', $event)"
            >
            </app-inline-edit-date-and-time>
          </div>
        </form>
      </div>
    </div>

    <div class="card" *ngIf="marketplaceDetailsModel.marketplaceType !== 'marketplace'">
      <div class="card-content">
        <div class="card-title">Payment Processor Settings</div>
        <form
          app-card-formgroup
          *ngIf="formGroups[PAYMENT_PROCESSOR_SETTINGS]; else loadingForm; let paymentProcessorSettingsForm"
          #formPaymentProcessorSettings
          [formGroup]="paymentProcessorSettingsForm"
          (onSave)="savePaymentProcessorSettingsForm(paymentProcessorSettingsForm)"
        >
          <div class="row">
            <app-inline-edit-text
              class="col-6"
              [formControlName]="'bankAccountNumber'"
              [formGroup]="paymentProcessorSettingsForm"
              [label]="'Bank account #'"
              [inGroupEdit]="formPaymentProcessorSettings.inEdit"
              [showCopy]="true"
              (onSave)="savePaymentProcessorSettingsForm('bankAccountNumber', $event)"
            >
            </app-inline-edit-text>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
