/* eslint-disable */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { BusinessComponent } from '../../trans-union/business/business.component';
import { UccComponent } from '../../trans-union/ucc/ucc.component';
import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { DefaultRadiobuttons } from '../common/consts/underwritings-initial-constants';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';
/* eslint-enable */

@Component({
  selector: 'app-credit-check',
  templateUrl: './credit-check.component.html',
})
export class CreditCheckComponent implements OnChanges {
  @Input() transUnionOverride: any;
  @Input() credictCheckDecision: any;
  @Input() transUnion: any;
  @Input() credictCheck: any;
  @Input() computingDataIsInProgress: boolean;

  @Output() onTransUnionOverrideChange = new EventEmitter();

  readonly userPermission = USER_PERMISSION;

  headerType: UnderwritingInitialCardHeaderStatusType;
  headerStatus: string;
  radiobuttons = DefaultRadiobuttons;

  constructor(private dialogService: MatDialog, private toastr: ToastrService) {}

  ngOnChanges(changes) {
    this.setHeaderDetails();
  }

  setHeaderDetails() {
    if (this.isSuccessHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.SUCCESS;
      this.headerStatus = 'Valid';
    }
    if (this.isDangerHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.DANGER;
      this.headerStatus = 'Needs review';
    }
  }

  isSuccessHeaderType() {
    return this.transUnionOverride[1].value || (!this.transUnionOverride[1].value && !this.credictCheckDecision);
  }

  isDangerHeaderType() {
    return !this.transUnionOverride[1].value && this.credictCheckDecision;
  }

  radiobuttonsChange(value) {
    if (!this.transUnionOverride[1]) {
      return;
    }
    this.transUnionOverride[1].value = value;
    this.onTransUnionOverrideChange.emit(this.transUnionOverride);
  }

  openBusinessReport() {
    if (this.transUnion.results.business) {
      const dialogRef = this.dialogService.open(BusinessComponent, {
        data: this.transUnion.results.business.company,
        width: '600px',
      });

      dialogRef.afterClosed().subscribe(() => {});
    } else {
      this.toastr.info('There is no data matching business check');
    }
  }

  openUccReport() {
    if (this.transUnion.results.person) {
      const dialogRef = this.dialogService.open(UccComponent, {
        data: this.transUnion.results.business.ucc_filings,
        width: '600px',
      });

      dialogRef.afterClosed().subscribe(() => {});
    } else {
      this.toastr.info('There is no data matching UCC Filing check');
    }
  }
}
