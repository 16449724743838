import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { CreditCardValidator } from '../../../card-number.validator';

@Component({
  selector: 'app-payment-type-efs',
  templateUrl: './payment-type-efs.component.html',
})
export class PaymentTypeEfsComponent implements OnInit {
  @Input() paymentConfig: any;
  @Output() configForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    if (this.paymentConfig && this.paymentConfig.hasOwnProperty('paymentConfig')) {
      this.paymentConfig.paymentConfig.efs['startDate'] = new Date(this.paymentConfig.paymentConfig.efs['startDate']);
      this.form.patchValue(this.paymentConfig.paymentConfig);
    }
  }

  createForm() {
    this.form = this.fb.group({
      efs: this.fb.group({
        startDate: new Date(),
        companyId: ['', Validators.required],
        cardNumber: ['', [Validators.required, CreditCardValidator.validateCardNumber]],
        cardExp: ['', [Validators.required, CreditCardValidator.validateCardExpiry]],
        cardCvc: ['', [Validators.required, CreditCardValidator.validateCardCvc]],
      }),
    });

    this.form.valueChanges.pipe(debounceTime(500)).subscribe((values) => {
      this.configForm.emit(this.form);
    });
  }
}
