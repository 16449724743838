import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from 'elasticsearch';
import { from as observableFrom, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AppState } from '../../store/app.reducers';
import { EventRule } from './event-rule.models';

@Injectable()
export class EventsService {
  private es;

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {
    this.es = new Client({
      host: environment.eventRulesOldUrl + 'es',
      log: 'warning',
    });
  }

  endpoint = environment.eventRulesOldUrl + 'event_rules/';

  create(rule) {
    const headers = { 'X-InstanceId': '8001' };
    return this.httpClient.post(this.endpoint, rule, { headers }) as Observable<Response<EventRule>>;
  }

  get(uuid) {
    const headers = { 'X-InstanceId': '8001' };
    return this.httpClient.get(`${this.endpoint}${uuid}/`, { headers }) as Observable<Response<EventRule>>;
  }

  list() {
    const headers = { 'X-InstanceId': '8001' };
    return this.httpClient.get(this.endpoint, { headers }) as Observable<ListResponse<EventRule>>;
  }

  save(uuid, rule) {
    const headers = { 'X-InstanceId': '8001' };
    return this.httpClient.put(`${this.endpoint}${uuid}/`, rule, { headers }) as Observable<Response<EventRule>>;
  }

  delete(uuid) {
    const headers = { 'X-InstanceId': '8001' };
    return this.httpClient.delete(`${this.endpoint}${uuid}/`, { headers }) as Observable<Response<EventRule>>;
  }

  historyList(uuid, limit: number = 10, last?) {
    const params: any = {
      limit,
      order_by: JSON.stringify({ field: 'created_at', direction: 'desc' }),
      q: JSON.stringify({ filters: [{ name: 'event_rule_uuid', op: 'eq', val: uuid }] }),
    };
    if (last) {
      params.last = JSON.stringify(last);
    }
    return this.httpClient.get(`${this.endpoint}history/`, { params }) as Observable<any>;
  }

  query(query: any = { match_all: {} }, limit = 30, page = 1, sort = { created_at: 'desc' }): Observable<any> {
    return this.esRequest({
      from: (page - 1) * limit,
      size: limit,
      body: {
        query,
        sort,
      },
    });
  }

  private esRequest(params) {
    return this.store.select('auth').pipe(
      take(1),
      switchMap((authState) =>
        observableFrom(
          this.es.search(
            Object.assign(
              {
                index: 'em_events',
                type: 'em_events',
                headers: { 'X-Session-Token': authState.user.jwtToken },
              },
              params,
            ),
          ),
        ),
      ),
    );
  }
}

export interface ListResponse<T> {
  data: Array<T>;
  meta: {};
}

export interface Response<T> {
  data: T;
  meta: {};
}
