import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
})
export class ConfigsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
