<div class="page-err">
  <div class="err-container text-center d-flex flex-column justify-content-center align-items-center h-100">
    <div class="err">
      <h2><strong>Page not found</strong></h2>
    </div>

    <div class="err-body mdl-typography--text-left d-flex flex-column">
      <strong class="err-row-text">Steps to troubleshoot:</strong>
      <div class="err-row-text">1 - Review URL and re-enter the url in the browser and try again</div>
      <div class="err-row-text">2 - Please contact your system administrator if the problem is not resolved</div>
      <div class="d-flex justify-content-center">
        <a mat-raised-button [routerLink]="['/']" class="btn-lg err-btn">Go Back to Home Page</a>
      </div>
    </div>
  </div>
</div>
