<article class="article" xmlns="http://www.w3.org/1999/html" *ngIf="supplierKey">
  <app-underwriting-initial-header
    [UWDecision]="UWDecision"
    [actualHistoryUsed]="actualHistoryUsed"
    [uwTaskProgress]="uwTaskProgress"
    [underwritingActionStatusKey]="underwritingActionStatusKey"
    [underwritingScriptLoading]="underwritingScriptLoading"
    (onRunUnderwritingClick)="runUnderwriting()"
  >
  </app-underwriting-initial-header>
  <div class="row" *ngIf="uwTaskProgress.isRunning">
    <app-underwriting-workflow [uwTaskProgress]="uwTaskProgress"> </app-underwriting-workflow>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-lg-4">
      <app-kyc-documentation [supplierKey]="supplierKey"> </app-kyc-documentation>

      <app-underwriting-files
        [amazonFilesDecision]="amazonFilesDecision"
        [underwritingImages]="underwritingImages"
        [complianceDoctypes]="complianceDoctypes"
        [isRestrictedUWView]="isRestrictedUWView"
      >
      </app-underwriting-files>
    </div>

    <div class="col-12 col-sm-6 col-lg-4">
      <!-- Agreement Review -->
      <app-agreement-review
        [agreementReview]="agreementReview"
        [supplierDetailsModel]="supplierDetailsModel"
        [agreementReviewDecision]="agreementReviewDecision"
        [computingDataIsInProgress]="computingDataIsInProgress"
        (onAgreementReviewChange)="onAgreementReviewChange($event)"
      >
      </app-agreement-review>

      <!-- Amazon Information -->
      <app-amazon-information
        [amazonInformationDecision]="amazonInformationDecision"
        [amazonInformation]="amazonInformation"
        [computingDataIsInProgress]="computingDataIsInProgress"
        (onAmazonInformationChange)="onAmazonInformationChange($event)"
      >
      </app-amazon-information>

      <!-- Personal Background Check -->
      <app-personal-background-check
        [computingDataIsInProgress]="computingDataIsInProgress"
        [transUnion]="transUnion"
        [transUnionOverride]="transUnionOverride"
        [personalBgCheck]="personalBgCheck"
        [supplierKey]="supplierKey"
        [transUnionActionStatusKey]="transUnionActionStatusKey"
        [personalBgCheckDecision]="personalBgCheckDecision"
        (onTransUnionOverrideChange)="onTransUnionOverrideChange($event)"
        (onTransUnionDataUpdate)="onTransUnionDataUpdate($event)"
      >
      </app-personal-background-check>

      <!-- Credit Check -->
      <app-credit-check
        [computingDataIsInProgress]="computingDataIsInProgress"
        [transUnionOverride]="transUnionOverride"
        [credictCheckDecision]="credictCheckDecision"
        [transUnion]="transUnion"
        [credictCheck]="credictCheck"
        (onTransUnionOverrideChange)="onTransUnionOverrideChange($event)"
      >
      </app-credit-check>

      <!-- International Supplier -->
      <app-international-supplier
        [supplierDetailsModel]="supplierDetailsModel"
        [internationalSupplierOverride]="internationalSupplierOverride"
        [internationalSupplierDecision]="internationalSupplierDecision"
        [internationalSupplier]="internationalSupplier"
        [dataFetch]="dataFetch"
        (onInternationalSupplierOverrideChange)="onInternationalSupplierOverrideChange($event)"
      >
      </app-international-supplier>
    </div>
    <div class="col-12 col-sm-6 col-lg-4" *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
      <!-- Amazon Advertising -->
      <app-amazon-advertising
        [computingDataIsInProgress]="computingDataIsInProgress"
        [amazonAdvertising]="amazonAdvertising"
        [amazonHealth]="amazonHealth"
        [amazonAdvertisings]="amazonAdvertisings"
        [amazonAdvertisingOverride]="amazonAdvertisingOverride"
        (onAmazonAdvertisingOverride)="onAmazonAdvertisingOverride($event)"
      >
      </app-amazon-advertising>

      <!-- Amazon Lending -->
      <app-amazon-lending
        [computingDataIsInProgress]="computingDataIsInProgress"
        [amazonLendingOverride]="amazonLendingOverride"
        [amazonLoanDecision]="amazonLoanDecision"
        [UWRules]="UWRules"
        [amazonHealth]="amazonHealth"
        (onAmazonLendingOverride)="onAmazonLendingOverride($event)"
      >
      </app-amazon-lending>

      <!-- Amazon Revenue -->
      <app-amazon-revenue
        [computingDataIsInProgress]="computingDataIsInProgress"
        [amazonRevenueOverride]="amazonRevenueOverride"
        [amazonSales]="amazonSales"
        (onAmazonRevenueOverride)="onAmazonRevenueOverride($event)"
      >
      </app-amazon-revenue>

      <!-- Account Health -->
      <app-account-health
        [benchmarkStatus]="benchmarkStatus"
        [supplierKey]="supplierKey"
        [amazonHealth]="amazonHealth"
        [isRestrictedUWView]="isRestrictedUWView"
        [editRules]="editRules"
        [UWRules]="UWRules"
        (onEditRulesClick)="startEditRules()"
        (rulesSaved)="onRulesSaved()"
      >
      </app-account-health>

      <!-- Amazon API -->
      <app-amazon-api [amazonLoanDecision]="amazonLoanDecision" [UWRules]="UWRules" [amazonHealth]="amazonHealth"> </app-amazon-api>
    </div>
  </div>

  <ng-container *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
    <!-- Underwriting History -->
    <app-initial-underwriting-history
      [underwritingHistory]="underwritingHistory"
      (onGetUnderwritingHistoryRecordClick)="onGetUnderwritingHistoryRecordClick($event)"
    >
    </app-initial-underwriting-history>

    <!-- Footer -->
    <app-underwriting-initial-footer
      #uwFooter
      [footerButtonLoading]="footerButtonLoading"
      [supplierKey]="supplierKey"
      [UWDecision]="UWDecision"
      [UWDecisionKey]="UWDecisionKey"
      [isRestrictedUWView]="isRestrictedUWView"
      [supplierDetailsModel]="supplierDetailsModel"
      [dataReady]="!computingDataIsInProgress"
      (onSaveUWClick)="saveUW($event)"
    >
    </app-underwriting-initial-footer>
  </ng-container>
</article>
