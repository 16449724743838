import { Injectable } from '@angular/core';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';
import { isString } from 'util';

@Injectable()
export class CsvExportService {
  constructor() {}

  private dataToString(data) {
    let output = '';
    for (const n in data) {
      if (data.hasOwnProperty(n) && isString(data[n])) {
        output += data[n];
      }
    }
    return output;
  }

  public exportCsv(data, filename) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    fileSaver.saveAs(blob, filename);
  }

  public exportTransactions(data, filename, type) {
    const exportFilename = filename + '_' + moment().format('MMDDYYYY') + (type || '') + '.csv';
    this.exportCsv(this.dataToString(data), exportFilename);
  }
}
