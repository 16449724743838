<div class="container-fluid">
  <mat-card>
    <mat-card-title>
      <div class="col">
        <div class="row">
          <div class="card-title">Global Settings</div>
          <button *ngIf="hasCRMPermission" mat-raised-button class="ml-auto" (click)="openNewSettingComponent()" color="accent">
            <mat-icon>add</mat-icon>Add new
          </button>
        </div>
      </div>
    </mat-card-title>

    <mat-table [dataSource]="globalSettings">
      <ng-container matColumnDef="instance" cdkColumnDef="instance">
        <mat-header-cell *matHeaderCellDef>Instance</mat-header-cell>
        <mat-cell *matCellDef="let setting">{{ setting.instance_id }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="key">
        <mat-header-cell *matHeaderCellDef>Key</mat-header-cell>
        <mat-cell *matCellDef="let setting">{{ setting.config_key }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
        <mat-cell *matCellDef="let setting">{{ setting.config_value.title }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let setting">{{ setting.config_type }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
        <mat-cell *matCellDef="let setting" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{
          setting.config_value | json
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let setting">
          <button *ngIf="hasCRMPermission" mat-raised-button (click)="openEditSettingComponent(setting)" color="primary">Edit</button>
          <button *ngIf="hasCRMPermission" mat-raised-button (click)="openDeleteSettingComponent(setting)" color="warn">Delete</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>
    </mat-table>
  </mat-card>
</div>
