import { Component, Input, OnInit } from '@angular/core';

import { UnderwritingInitialCardHeaderStatusType } from './underwriting-initial-card-header-status-type';

@Component({
  selector: 'app-underwriting-initial-card-header',
  templateUrl: './underwriting-initial-card-header.component.html',
})
export class UnderwritingInitialCardHeaderComponent implements OnInit {
  @Input() chipText: string;
  @Input() chipColor: string;
  @Input() title: string;
  @Input() type: UnderwritingInitialCardHeaderStatusType;
  @Input() status: string;

  underwritingInitialCardHeaderStatusType = UnderwritingInitialCardHeaderStatusType;

  constructor() {}

  ngOnInit() {}
}
