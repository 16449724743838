<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="title">Fee Settings</div>
        <div class="actions">
          <button [routerLink]="['/manage/fee-settings/add']" mat-raised-button [color]="'accent'">
            <mat-icon>add</mat-icon>
            Add New
          </button>
        </div>
      </div>

      <div class="search-panel">
        <div class="inputs">
          <form name="material_login_form" [formGroup]="filterForm">
            <fieldset>
              <mat-form-field class="field-text">
                <mat-select
                  name="options"
                  placeholder="Fee Category"
                  (selectionChange)="setFeeScheduleForSelectedCategory($event)"
                  formControlName="category"
                >
                  <mat-option *ngFor="let item of feeScheduleCategories" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="field-text">
                <mat-select
                  name="options"
                  placeholder="Fee Schedule Type"
                  (selectionChange)="setFeeScheduleForSelectedType($event)"
                  formControlName="type"
                >
                  <mat-option *ngFor="let item of feeScheduleTypes" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="field-text">
                <mat-select
                  name="options"
                  placeholder="Fee Schedule Name"
                  (selectionChange)="setFeeScheduleForSelectedName($event)"
                  formControlName="name"
                >
                  <mat-option *ngFor="let item of feeScheduleNames" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button mat-raised-button class="action-button" [disabled]="isDisabledClear" (click)="clearFilters()">Clear</button>
            </fieldset>
          </form>
        </div>
      </div>

      <div class="settings-table">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="feeScheduleCategory">
            <mat-header-cell *matHeaderCellDef>Fee Schedule Category</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.feeScheduleCategory }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="feeScheduleType">
            <mat-header-cell *matHeaderCellDef>Fee Schedule Type</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.feeScheduleType }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="feeScheduleName" disabled>
            <mat-header-cell *matHeaderCellDef>Fee Schedule Name</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index"> {{ element.feeScheduleName }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tierStart">
            <mat-header-cell *matHeaderCellDef>Tier Start</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.tierStart | currency }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tierEnd">
            <mat-header-cell *matHeaderCellDef>Tier End</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.tierEnd | currency }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="feePercentage">
            <mat-header-cell *matHeaderCellDef>Fee Percentage</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.feePercent / 100 | percent: '1.0-2' }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fixedFee">
            <mat-header-cell *matHeaderCellDef>Fixed Fee</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.feeFixed | currency }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="freeAccess">
            <mat-header-cell *matHeaderCellDef>Free Access</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-slide-toggle [(ngModel)]="element.freeAccess" (change)="onFreeAccessChange(element.feeScheduleKey, $event.checked)">
              </mat-slide-toggle>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              <button
                mat-raised-button
                class="action-button"
                [color]="'primary'"
                [routerLink]="['/manage/fee-settings', element.id, 'edit']"
              >
                Edit
              </button>
              <button mat-raised-button class="action-button" [color]="'warn'" (click)="openDeleteFeeScheduleComponent(element)">
                Delete
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</section>
