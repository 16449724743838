import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
})
export class ProcessListComponent implements OnInit {
  public processesList: Array<any> = [
    {
      name: 'Payment Batches',
      path: '/processes/supplier-payments/batches',
    },
  ];

  ngOnInit() {}
}
