import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cli-action-button',
  templateUrl: './cli-action-button.component.html',
})
export class CliActionButtonComponent implements OnInit {
  @Input() actionData: any;
  @Input() isDisabled: boolean;
  @Output() openCliActionDialog = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  openActionDialog(): any {
    this.openCliActionDialog.emit(this.actionData);
  }
}
