<div class="card">
  <div class="card-content">
    <div *ngIf="uccData" class="row">
      <p class="bold">{{ uccData.filingNumber }} &nbsp; ({{ uccData.officeCity }}, &nbsp; {{ uccData.officeCounty }})</p>
      <p class="col-md-12">
        Filing Type:
        <span class="bold">
          {{ uccData.filingType }}
        </span>
      </p>
      <p class="col-md-12">
        Filing Number:
        <span class="bold">
          {{ uccData.filingNumber }}
        </span>
      </p>
      <p class="col-md-12">
        Filing Date:
        <span class="bold">
          {{ uccData.filingDate | date: 'M/d/y' }}
        </span>
      </p>
      <p class="col-md-12">
        Expiration Date:
        <span class="bold">
          {{ uccData.expirationDate | date: 'M/d/y' }}
        </span>
      </p>
      <p class="col-md-12">
        Filing Office Name:
        <span class="bold">
          {{ uccData.officeName }}
        </span>
      </p>
      <p class="col-md-12">
        Filing Office Address:
        <span class="bold">
          {{ uccData.officeAddress }}
        </span>
      </p>
      <p class="col-md-12 title-underline">Debtors:</p>
      <div class="margin-left row">
        <p class="col-md-12">
          Business Name:
          <span class="bold">
            {{ uccData.debtorBusinessName }}
          </span>
        </p>
        <p class="col-md-12">
          Name:
          <span class="bold">
            {{ uccData.debtorName }}
          </span>
        </p>
        <p class="col-md-12">
          Address:
          <span class="bold">
            {{ uccData.debtorAddress }}
          </span>
        </p>
      </div>
      <p class="col-md-12 title-underline">Secured Party:</p>
      <div class="margin-left row">
        <p class="col-md-12">
          Business Name:
          <span class="bold">
            {{ uccData.securedPartyBusinessName }}
          </span>
        </p>
        <p class="col-md-12">
          Name:
          <span class="bold">
            {{ uccData.securedPartyName }}
          </span>
        </p>
        <p class="col-md-12">
          Address:
          <span class="bold">
            {{ uccData.securedPartyAddress }} - {{ uccData.securedPartyAddress.securedPartyCity }} -
            {{ uccData.securedPartyAddress.securedPartyCounty }}
          </span>
        </p>
      </div>

      <p class="col-md-12 title-underline">Collateral Items:</p>
      <div class="margin-left row">
        <p *ngFor="let type of collateralTypes" class="col-md-12">
          Type:
          <span *ngIf="type" class="bold">
            {{ type }}
          </span>
          <span *ngIf="!type" class="bold"> Not Applicable </span>
        </p>
      </div>
    </div>

    <div class="row">
      <button mat-raised-button class="btn-w-md btn-w-md btn-margin" (click)="redirectToList()" [color]="'warn'">Back</button>
    </div>
  </div>
</div>
