import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';

import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { PermissionModel } from './../../../shared/users/permission.model';
import { UsersService } from './../../../shared/users/service/users.service';
import { UserModel } from './../../../shared/users/user.model';
import { UserPermModel } from './../../../shared/users/user-perm.model';

const MESSAGE_ARE_YOU_SURE_TO_DELETE = 'Are you sure you want to delete user?';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent extends SubscriberComponent implements OnInit {
  // Fields
  public editUserForm: FormGroup;
  public isLocked = false;

  public user: UserModel;
  public permissions: PermissionModel[];

  private currentPermission: UserPermModel;

  private loginKey: string;
  private loginId: string;

  // Flags
  private isLoaded = false;

  // Accessors
  public get title(): string {
    return !this.isLoaded ? 'Loading...' : `${this.loginId}`;
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private service: UsersService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.loginKey = params['loginKey'];

        this.getPermissions();
        this.getData();
      }),
    );

    this.setupComponent();
  }

  public toggleLock(): void {
    this.isLocked = !this.isLocked;
    this.disableForm();
  }

  public onSubmit(): void {
    if (this.editUserForm.valid) {
      this.subscriptions.push(
        this.updateUserData(this.editUserForm.value).subscribe(
          (firstSuccess) => {
            if (this.canUpdateUserPermissions(this.editUserForm.value)) {
              this.subscriptions.push(
                this.updateUserPermissions(this.editUserForm.value).subscribe(
                  (secondSuccess) => {
                    this.toastrService.success('User data has been successfully updated.');
                    this.router.navigate(['../../list'], { relativeTo: this.route });
                  },
                  (secondError) => {
                    // [SM] Temporary workaround
                    if (secondError && secondError.status === 201) {
                      this.toastrService.success('User data has been successfully updated.');
                      this.router.navigate(['../../list'], { relativeTo: this.route });
                    } else {
                      this.toastrService.error('Saving user data failed.');
                    }
                  },
                ),
              );
            } else {
              this.toastrService.success('User data has been successfully updated.');
              this.router.navigate(['../../list'], { relativeTo: this.route });
            }
          },
          (firstError) => {
            this.toastrService.error('Saving user data failed.');
          },
        ),
      );
    }
  }

  public onCancel(): void {
    this.router.navigate(['../../list'], { relativeTo: this.route });
  }

  public onDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: MESSAGE_ARE_YOU_SURE_TO_DELETE },
      width: DIALOG_SIZE.SMALL.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.service.deleteLogin(this.loginKey).subscribe(
              (value) => {
                this.router.navigate(['../../list'], { relativeTo: this.route });
                this.toastrService.success('User successfully deleted');
              },
              (error) => {
                this.toastrService.error('User not deleted');
              },
            ),
          );
        }
      }),
    );
  }

  private setupComponent(): void {
    this.editUserForm = this.formBuilder.group({
      firstname: [{ value: '', disabled: false }, Validators.required],
      lastname: [{ value: '', disabled: false }, Validators.required],
      email: [{ value: '', disabled: false }, Validators.required],
      loginId: [{ value: '', disabled: false }, Validators.required],
      authyId: [{ value: '', disabled: false }],
      countryCode: [{ value: '', disabled: false }],
      phoneNumber: [{ value: '', disabled: false }],
      permission: [{ value: '', disabled: false }, Validators.required],
    });
  }

  private disableForm(): void {
    if (this.editUserForm) {
      this.isLocked ? this.editUserForm.disable() : this.editUserForm.enable();
    }
  }

  private getPermissions(): void {
    this.subscriptions.push(
      this.service.getPermissions().subscribe(
        (data) => {
          if (data) {
            let perm = data.filter((item) => item.assignable === true);
            perm = perm.sort((a: PermissionModel, b: PermissionModel) => {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }

              return 0;
            });

            this.permissions = perm;
          }
        },
        (error) => {
          this.toastrService.error('Getting permissions list failed.');
        },
      ),
    );
  }

  private getData(): void {
    this.subscriptions.push(
      this.service.getLogin(this.loginKey).subscribe(
        (data) => {
          if (data) {
            this.loginId = data.loginId;
            this.currentPermission = data.perms && data.perms.length ? data.perms[0] : undefined;

            if (this.editUserForm) {
              this.editUserForm.patchValue({
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                loginId: data.loginId,
                authyId: data.authyId,
                countryCode: data.countryCode,
                phoneNumber: data.phoneNumber,
                permission: this.currentPermission ? this.currentPermission.permCode : undefined,
              });
            }

            this.isLoaded = true;
          }
        },
        (error) => {
          this.toastrService.error('Getting users data failed.');
        },
      ),
    );
  }

  private updateUserData(data: any): Observable<any> {
    const user = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      loginId: data.loginId,
      authyId: data.authyId,
      countryCode: data.countryCode,
      phoneNumber: data.phoneNumber,
    };

    return this.service.saveLogin(this.loginKey, <UserModel>user);
  }

  private canUpdateUserPermissions(data: any): boolean {
    return !this.currentPermission || (this.currentPermission && this.currentPermission.permCode !== data.permission) ? true : false;
  }

  private updateUserPermissions(data: any): Observable<any> {
    let result: Observable<any> = null;

    if (data) {
      if (!this.currentPermission) {
        // we have user without permissions - add new one
        const newPermissions = {
          permCode: data.permission,
          supplierKey: this.loginKey,
        };

        result = this.service.addPermissionToLogin(this.loginKey, newPermissions);
      } else if (this.currentPermission && this.currentPermission.permCode !== data.permission) {
        // we have user with permissions - remove old and add new one
        const permission = {
          permCode: data.permission,
          supplierKey: this.loginKey,
        };

        result = forkJoin([
          this.service.removePermissionFromLogin(this.loginKey, this.currentPermission.loginPermKey),
          this.service.addPermissionToLogin(this.loginKey, permission),
        ]);
      }

      return result;
    }
  }
}
