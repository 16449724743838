<div class="example-container mat-elevation-z8">
  <mat-card>
    <mat-card-title>
      <div class="row">
        <div class="col-md-6">
          Marketplaces templates for <span class="temp-name">{{ onboardingTemplateCode }}</span>
        </div>
        <div *ngIf="hasCRMPermission" class="col-md-6 title-button">
          <button [disabled]="!canEdit" mat-raised-button color="accent" (click)="openAddNewMpOnboarding()">
            <mat-icon class="mat-22">add</mat-icon>
            Add New
          </button>
        </div>
      </div>
    </mat-card-title>

    <mat-card-content>
      <div class="onboarding-table">
        <mat-table #table [dataSource]="dataSource" matSort [matSortDirection]="'desc'" [matSortActive]="'type'">
          <ng-container matColumnDef="marketplace">
            <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.marketplaceName }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="fundingPeriodGroupCode">
            <mat-header-cell *matHeaderCellDef>Funding Period Group Code</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.fundingPeriodGroupCode }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="feeSchedule">
            <mat-header-cell *matHeaderCellDef>Fee Schedule</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.feeScheduleName }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="reservePercent">
            <mat-header-cell *matHeaderCellDef>Reserve Percent</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.reservePercent }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="minReservePercent">
            <mat-header-cell *matHeaderCellDef>Minimum Reserve Percent</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.minReservePercent }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                *ngIf="hasCRMPermission"
                [disabled]="!canEdit"
                mat-raised-button
                class="mat-primary"
                (click)="openEditMpOnboarding(element)"
              >
                Edit
              </button>
              <button *ngIf="hasCRMPermission" mat-raised-button (click)="openDeleteMpOnboarding(element)" color="warn">Delete</button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
