<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div *ngFor="let field of fields" class="form-row">
      <app-df-field [field]="field" [form]="form"></app-df-field>
    </div>

    <div class="form-row">
      <button mat-raised-button color="primary" [disabled]="!form.valid" class="btn-w-md pull-right">Save</button>
    </div>
  </form>
</div>
