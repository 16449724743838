<div class="report flex-container">
  <app-data-table-ag-grid
    *ngIf="supplierKey"
    [supplierKey]="supplierKey"
    [customGridOptions]="customGridOptions"
    style="height: 80%; width: 100%"
  >
    <div top-container-before>
      <button mat-raised-button style="margin-right: 5px" color="primary" (click)="goBack()">Go Back</button>
    </div>
  </app-data-table-ag-grid>
  <div></div>
</div>
