import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../config';
import { WinbackReason } from './../../shared/models/winback.model';

@Injectable()
export class WinbackService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
  }

  fetchReasons(): Observable<WinbackReason[]> {
    const endpointUrl = `${this.apiUrl}winback/reasons`;

    return this.httpClient.get(endpointUrl) as Observable<WinbackReason[]>;
  }
}
