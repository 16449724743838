import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';

import { KycFileUpload } from '../../suppliers/model/compliance.model';
import { KycFileUploadResponse } from './../../suppliers/model/compliance.model';
import { B2bDocumentService } from './../b2b-document/b2b-document.service';
import { FEATURE_FLAGS } from './../feature-flags/feature-flags.const';
import { FeatureFlagsService } from './../feature-flags/feature-flags.service';
import { FileInputModel } from './../input-file/input-file.model';
import { FileCompliance, PybDocument } from './../pyb-documents/pyb-documents.interface';
import { PybDocumentsService } from './../pyb-documents/pyb-documents.service';

@Injectable()
export class AppsDocumentService {
  private service: B2bDocumentService | PybDocumentsService;

  constructor(
    private b2bDocumentService: B2bDocumentService,
    private featureFlagsService: FeatureFlagsService,
    private pybDocumentsService: PybDocumentsService,
    private sanitizer: DomSanitizer,
  ) {
    this.service = this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS) ? this.pybDocumentsService : this.b2bDocumentService;
  }

  getToken() {
    if (!this.isB2bDocumentService()) {
      return of(null);
    }

    return (this.service as B2bDocumentService).getToken();
  }

  getFileDownloadUrlWithToken(gci: string | number, uuid: string, token: string, isThumbnail = false): Observable<string | Blob> {
    if (this.isB2bDocumentService()) {
      return (this.service as B2bDocumentService).getFileDownloadUrlWithToken(gci, uuid, token);
    }

    return this.getFileDownloadUrl(gci, uuid, isThumbnail);
  }

  getFileDownloadUrl(gci: string | number, uuid: string, isThumbnail = false): Observable<string | Blob> {
    if (this.isB2bDocumentService()) {
      return (this.service as B2bDocumentService).getFileDownloadUrl(gci, uuid);
    }

    return isThumbnail
      ? (this.service as PybDocumentsService).downloadThumbnail(uuid)
      : (this.service as PybDocumentsService).downloadFile(uuid);
  }

  uploadFile(
    data: KycFileUpload | FileInputModel[],
    singleKycFile: boolean,
    proxy = false,
    supplierKey: string = null,
    compliance: FileCompliance = null,
  ): Observable<KycFileUploadResponse | PybDocument[]> {
    if (this.isB2bDocumentService()) {
      return (this.service as B2bDocumentService).uploadFile(data as KycFileUpload);
    }

    return proxy
      ? (this.service as PybDocumentsService).uploadFileProxy(
          singleKycFile ? [(data as KycFileUpload).file] : (data as FileInputModel[]),
          supplierKey,
          compliance,
        )
      : (this.service as PybDocumentsService).uploadFile(singleKycFile ? [(data as KycFileUpload).file] : (data as FileInputModel[]));
  }

  sanitize(blobOrUrl: string | Blob): SafeResourceUrl {
    let data = blobOrUrl;

    if (!this.isB2bDocumentService()) {
      data = URL.createObjectURL(blobOrUrl as Blob);
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.HTML, data));
  }

  uploadKycFileMapper(data: KycFileUploadResponse | any[]): KycFileUploadResponse {
    if (this.isB2bDocumentService()) {
      return data as KycFileUploadResponse;
    }

    const file = data[0];

    return {
      status: 'OK',
      doc: {
        name: file.name,
        thumbnailUUID: file.thumbnail?.id,
        uuid: file.id,
      },
    };
  }

  private isB2bDocumentService(): boolean {
    return this.service instanceof B2bDocumentService;
  }
}
