<h1 *ngIf="data?.title" mat-dialog-title>
  {{ data.title }}
</h1>

<mat-dialog-actions *ngIf="data?.text && !hasHtmlContent">
  <p class="text">{{ data.text }}</p>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="data?.text && hasHtmlContent">
  <p class="text" [innerHTML]="data.text | safe"></p>
</mat-dialog-actions>

<mat-dialog-actions class="actions-buttons pull-right">
  <button mat-raised-button (click)="close()" class="cancel-button" [color]="'warn'" tabindex="-1">Close</button>
</mat-dialog-actions>
