import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { isUndefined } from 'util';

import { TableEventsService } from '../../../../../shared/table-events.service';
import { AppState } from '../../../../../store/app.reducers';
import { CliService } from '../../../../services/cli/cli.service';
import { FetchSupplierPaymentConfigs, selectPaymentMethods, selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../../../base-supplier-action/base-supplier-action';
import { ConfirmDialogComponent } from './../../../../../shared/confirm-dialog/confirm-dialog.component';
import { ErrorVisualizationService } from './../../../../../shared/services/error-visualization/error-visualization.service';
import { PaymentConfigModel } from './../../../../model/payment-config.model';
import { SupplierService } from './../../../../services/supplier.service';

interface IPaymentListPosition {
  key: string;
  description: string;
}

interface IPaymentConfigsModels {
  [paymentConfigKey: string]: PaymentConfigModel;
}

@Component({
  selector: 'app-supplier-payment-debit-request',
  templateUrl: './supplier-payment-debit-request.component.html',
  styleUrls: ['./supplier-payment-debit-request.component.scss'],
})
export class SupplierPaymentDebitRequestComponent extends BaseSupplierAction implements OnInit {
  defaultAccount: string;
  fundTransferRequestForm: FormGroup;
  marketplaces: {
    label: string;
    id: string;
  }[];
  mpSupplierKeyMap: Map<string, string> = new Map<string, string>();

  paymentsList: IPaymentListPosition[];
  supplierKey: string;
  debitAmountProposal: number;

  readonly minimumAmmount = 0.01;
  readonly maximumAmmount = 99999999.99;

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
    private errorVisualizationService: ErrorVisualizationService,
    private toastrService: ToastrService,
    private dialogService: MatDialog,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.setSupplierKey(data);
          this.populateMarketplaces(data);
        }
      }),
    );
    this.store.dispatch(
      new FetchSupplierPaymentConfigs({
        fresh: true,
      }),
    );
    super.ngOnInit();
  }

  setSupplierKey(data) {
    this.supplierKey = data.supplierKey;
  }

  populateMarketplaces(data) {
    this.marketplaces = data.marketplaces.map((element) => {
      this.mpSupplierKeyMap.set(element.marketplaceCode, element.mpSupKey);
      return {
        label: element.externalSupplierId ? `${element.marketplaceName} (${element.externalSupplierId})` : element.marketplaceName,
        id: element.marketplaceCode,
      };
    });
  }

  mapResultsToPaymentPositions(results: IPaymentConfigsModels): IPaymentListPosition[] {
    return Object.keys(results)
      .map((resultKey) => {
        const result = results[resultKey];
        if (this.isNotACH(result)) {
          return;
        }
        if (this.isNotApproved(result)) {
          return;
        }

        if (this.isDefault(result)) {
          this.setAccountAsDefault(result);
        }

        const enabledForDebitString = result.enableForDebit ? ' - Enabled for Debit' : '';
        const description = `${result.paymentType} (${this.getLast4NumbersOfAccount(result)})${enabledForDebitString}`;
        const key = result.paymentConfigKey;
        return { key, description };
      })
      .filter((position) => !isUndefined(position));
  }

  isNotACH(result: PaymentConfigModel) {
    return result.paymentType !== 'ACH';
  }

  isNotApproved(result: PaymentConfigModel) {
    return result.configStatus !== 'approved';
  }

  isDefault(result: PaymentConfigModel) {
    return !!result.isDefault;
  }

  getLast4NumbersOfAccount(result: PaymentConfigModel) {
    return result.paymentConfig.beneficiary.accountNumber.substr(-4);
  }

  setAccountAsDefault(result: PaymentConfigModel) {
    this.fundTransferRequestForm.patchValue({
      supPmtCfgKey: result.paymentConfigKey,
    });
    this.defaultAccount = result.paymentConfigKey;
  }

  openFundTransferRequestConfirmationModal() {
    const msg = 'Please click Yes to proceed with this Transfer Request. If you would like to not proceed and go back, please click No.';
    const previewDialog = this.dialogService.open(ConfirmDialogComponent, {
      height: 'auto',
      data: {
        message: msg,
      },
    });

    previewDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const amonutChanged = this.debitAmountProposal !== this.fundTransferRequestForm.get('amount').value;
        this.fundTransferRequestForm.addControl('amount_changed', new FormControl(amonutChanged));
        this.supplierService.addSupplierFundRecovery(this.supplierKey, this.fundTransferRequestForm.value).subscribe(
          (response) => {
            this.toastrService.success(response.message);
            this.dialogService.closeAll();
          },
          (error) => {
            if (error) {
              this.errorVisualizationService.showError({
                name: "Can't add Transfer Request",
                errorObject: error,
              });
            } else {
              this.toastrService.error("Can't add Transfer Request");
            }
          },
        );
      }
    });
  }

  createForm() {
    this.fundTransferRequestForm = this.formBuilder.group({
      marketplace_code: ['', Validators.required],
      statement_id: [''],
      supPmtCfgKey: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(this.minimumAmmount), Validators.max(this.maximumAmmount)]],
      is_passthrough_debit: [false],
    });
    this.populatePayments();
  }

  populatePayments() {
    this.subscriptions.push(
      this.store.select(selectPaymentMethods).subscribe((payment) => {
        if (isUndefined(payment)) {
          return;
        }
        this.paymentsList = this.mapResultsToPaymentPositions(payment);
      }),
    );
  }

  submit() {}

  closeDialog() {
    this.dialogService.closeAll();
  }

  selectMarketplace(marketplaceCode: string) {
    this.loadStatementsForAddSupplierFundRecovery(this.supplierKey, this.mpSupplierKeyMap.get(marketplaceCode));
  }

  selectStatement(statementId: string): void {
    const debitAmount = this.statements.find((statement) => statement.id === statementId).debitAmount;
    this.debitAmountProposal = debitAmount;
    this.fundTransferRequestForm.get('amount').setValue(debitAmount);
  }
}
