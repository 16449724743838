<div *ngIf="searchSingleResult" class="searchOverlaySingleResult">
  <div class="searchOverlaySingleResult__item__title">
    <div class="searchOverlaySingleResult__item__title__icon">
      <mat-icon>person</mat-icon>
    </div>
    <div class="searchOverlaySingleResult__item__title__details">
      <div>
        <span class="searchOverlaySingleResult__item__title__details__link">
          <a
            *ngIf="!hasRestrictedAccessPermission"
            [routerLink]="['/suppliers', searchSingleResult.supplierKey, 'general']"
            [innerHTML]="searchSingleResult.legalName_displayed | safe"
            (click)="simplyCloseOverlay()"
          ></a>
          <a
            *ngIf="hasRestrictedAccessPermission"
            [routerLink]="['/suppliers', searchSingleResult.supplierKey, 'underwriting', 'initial']"
            [innerHTML]="searchSingleResult.legalName_displayed | safe"
            (click)="closeOverlayRestrict(searchSingleResult.supplierKey)"
          ></a>
        </span>
      </div>
      <div>
        <span [innerHTML]="searchSingleResult.supplierKey_displayed | safe"></span>
      </div>
    </div>
  </div>

  <div class="searchOverlaySingleResult__item__highlightFields" [ngClass]="tempFields.length > 2 ? 'wrappedFields' : 'noWrappedFields'">
    <div *ngFor="let field of tempFields; let i = index" class="searchOverlaySingleResult__item__highlightFields__field">
      <span *ngIf="field.fieldName !== 'ssn' && field.fieldName !== 'ein'; else allBigLetters">{{ field.fieldName | capitalize }}</span>
      <ng-template #allBigLetters>{{ field.fieldName.toUpperCase() }}</ng-template>
      <span [innerHTML]="field.fieldValue | safe"></span>
    </div>
  </div>
</div>
