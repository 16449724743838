export const GetPersonalBgCheck = (
  personData,
  guarantorAgainstBankkrupcies,
  guarantorAgainstJudgements,
  guarantorAgainstLiens,
  transUnionPerson,
  trafficRecords,
  criminalRecords,
) => {
  return [
    {
      text: 'Does the Guarantor have any bankruptcy?',
      target: '0',
      value: personData.PersonSearchOutputRecords.TLOPersonSearchOutputRecord[0].NumberOfBankruptcies,
      automated: Number(guarantorAgainstBankkrupcies) === 0,
    },
    {
      text: 'Does the Guarantor have any judgement?',
      target: '0',
      value: personData.PersonSearchOutputRecords.TLOPersonSearchOutputRecord[0].NumberOfJudgments,
      automated: Number(guarantorAgainstJudgements) === 0,
    },
    {
      text: 'Does the Guarantor have any lien against them?',
      target: '0',
      value: personData.PersonSearchOutputRecords.TLOPersonSearchOutputRecord[0].NumberOfLiens,
      automated: Number(guarantorAgainstLiens) === 0,
    },
    {
      text: 'Does the Guarantor have a criminal record for any event, excluding driving infractions?',
      target: 'Criminal: 0',
      value: transUnionPerson.comprehensive.CriminalRecordsMatch
        ? 'Criminal: ' + transUnionPerson.comprehensive.CriminalRecordsMatch.TLOCriminalSearchMatch.length + ', Traffic: ' + trafficRecords
        : 'no data',
      automated: criminalRecords === 0,
    },
  ];
};
