import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { GatewayService, marketplaceTypes } from '../../../../marketplaces/service/gateway.service';
import { SetPossibleGateways } from '../../../../marketplaces/store/marketplace.actions';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { SupplierMarketplaceListModel } from '../../../model/supplier-marketplace.model';
import { FetchSupplierMarketplaces, selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { selectSupplierMarketplacesList } from './../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-marketplace-list',
  templateUrl: './supplier-marketplace-list.component.html',
  styleUrls: ['./supplier-marketplace-list.component.scss'],
})
export class SupplierMarketplaceListComponent extends SubscriberComponent implements OnInit {
  supplierDetails: SupplierDetailsModel;
  supplierMarketplaces: SupplierMarketplaceListModel[];
  loaded = false;

  constructor(
    private readonly store: Store<AppState>,
    private readonly gatewayService: GatewayService,
    private readonly cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((details) => !!(details && details.supplierKey)),
          distinctUntilChanged(),
        )
        .subscribe((supplierDetailsModel: SupplierDetailsModel) => {
          this.loaded = false;
          this.supplierDetails = supplierDetailsModel;
          this.supplierMarketplaces = [];
          this.cd.detectChanges();
          this.store.dispatch(new FetchSupplierMarketplaces({ supplierDetailsModel, refresh: true }));
        }),
    );

    this.subscriptions.push(
      this.store
        .select(selectSupplierMarketplacesList)
        .pipe(filter((list: SupplierMarketplaceListModel[]) => !!(list && list.length)))
        .subscribe((list: SupplierMarketplaceListModel[]) => {
          this.supplierMarketplaces = list
            .filter((element: SupplierMarketplaceListModel) => marketplaceTypes.STANDARD_MARKETPLACE === element.marketplaceType)
            .sort(this.compareMarketplaceOrder);
          this.loaded = true;
        }),
    );

    this.subscriptions.push(
      this.gatewayService.getList().subscribe((resp: any) => {
        this.store.dispatch(new SetPossibleGateways(resp));
      }),
    );
  }

  private compareMarketplaceOrder(a: SupplierMarketplaceListModel, b: SupplierMarketplaceListModel): number {
    if (a.marketplaceName < b.marketplaceName) {
      return -1;
    }
    if (a.marketplaceName > b.marketplaceName) {
      return 1;
    }
    return 0;
  }
}
