import { Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { SupplierDetailsModel } from './../../model/supplier.model';
import { TwoFa } from './models/twofa.model';
import { Supplier2FaService } from './supplier-2fa.service';

interface TwoFaData extends TwoFa {
  phoneNumberTxt: string;
}

@Component({
  selector: 'app-supplier-2fa',
  templateUrl: './supplier-2fa.component.html',
  styleUrls: ['./supplier-2fa.component.scss'],
})
export class Supplier2FaComponent extends SubscriberComponent implements OnDestroy {
  @Input() set selectSupplierDetails$(stream: Observable<SupplierDetailsModel>) {
    this.suplierDetails$ = stream.pipe(debounceTime(100)).subscribe((data) => this.init(data));
  }

  supplierKey: string = null;

  private dataSubject = new BehaviorSubject(null);

  private suplierDetails$: Subscription;

  constructor(private readonly supplier2FaService: Supplier2FaService) {
    super();
  }

  get data$(): Observable<TwoFaData> {
    return this.dataSubject.asObservable();
  }

  onDelete(): void {
    this.setLoading();
    this.subscriptions.push(
      this.supplier2FaService.delete(this.supplierKey).subscribe(
        () => this.doDelete(),
        () => this.doDelete(),
      ),
    );
  }

  private castData(data: TwoFa): TwoFaData {
    return { ...data, phoneNumberTxt: data.phone_number ? `+${data.country_code} ${data.phone_number}` : null };
  }

  private fetchData(): void {
    this.subscriptions.push(
      this.supplier2FaService
        .fetch(this.supplierKey)
        .pipe(map((data) => this.castData(data)))
        .subscribe((data) => this.dataSubject.next(data)),
    );
  }

  private init(data: SupplierDetailsModel): void {
    this.setLoading();

    if (!data) {
      return;
    }

    this.supplierKey = data.supplierKey;
    this.fetchData();
  }

  private doDelete(): void {
    this.dataSubject.next({});
  }

  private setLoading(): void {
    this.dataSubject.next(null);
  }

  ngOnDestroy(): void {
    if (this.suplierDetails$ && !this.suplierDetails$.closed) {
      this.suplierDetails$.unsubscribe();
    }
  }
}
