import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { BaseSupplierAction } from '../../../suppliers/supplier/base-supplier-action/base-supplier-action';
import { BatchImportService } from '../service/batch-imports.service';
import { TableEventsService } from './../../../shared/table-events.service';
import { AppState } from './../../../store/app.reducers';
import { CliService } from './../../../suppliers/services/cli/cli.service';
import { SupplierService } from './../../../suppliers/services/supplier.service';

@Component({
  selector: 'app-supplier-rebates',
  templateUrl: './supplier-rebates.component.html',
  styleUrls: ['../batch-imports.component.scss'],
})
export class SupplierRebatesComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  actionTitle = 'Upload supplier rebates';
  label = 'Add supplier rebates';
  maxDate = moment().toDate();

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    dialogService: MatDialog,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
    private batchService: BatchImportService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createForm() {
    this.supplierActionForm = this.formBuilder.group({
      file_type: ['', Validators.required],
      file: [''],
      override_dupe_check: [true],
      post_date: [moment().subtract(1, 'days').toDate()],
      start_date: [moment().subtract(2, 'days').toDate()],
      end_date: [moment().subtract(1, 'days').toDate()],
    });
  }

  submit() {
    this.isConfirmed = true;
    this.isLoadingResult = true;

    this.supplierActionForm.patchValue({
      supplier_key: this.supplierKey,
    });

    const dataToSave = this.supplierActionForm.value;
    dataToSave['post_date'] = moment(dataToSave.post_date).format('YYYY-MM-DD');
    dataToSave['start_date'] = moment(dataToSave.start_date).format('YYYY-MM-DD');
    dataToSave['end_date'] = moment(dataToSave.end_date).format('YYYY-MM-DD');

    const request = this.batchService.addSupplierRebates(dataToSave);

    this.subscriptions.push(
      request.subscribe(
        (success) => {
          this.onSuccess('Task started successfully');
        },
        (response) => {
          this.onError(response.error.message);
        },
      ),
    );
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
