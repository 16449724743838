import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { SubscriberComponent } from '../../component-subscriber/subscriber.component';
import { CommunicationService } from '../communication/communication.service';
import { MessageFromIframe } from '../communication/communication-message.model';
import { MESSAGE_TYPE } from '../communication/message-type.enum';
import { ResizeHTMLElement, ResizeItem } from './resize-iframe.model';

@Injectable({ providedIn: 'root' })
export class ResizeIframeService extends SubscriberComponent {
  private readonly IFRAME_MESSAGES = [
    MESSAGE_TYPE.RESIZE_IFRAME_SIZE,
    MESSAGE_TYPE.RESET_IFRAME_SIZE,
    MESSAGE_TYPE.RESIZE_IFRAME_HEIGHT_SIZE,
    MESSAGE_TYPE.RESIZE_IFRAME_WIDTH_SIZE,
  ];
  private readonly DEFAULT_SIZE = '100%';
  private readonly ELEMENT_NOT_FOUND = 'Element not found!';

  constructor(private readonly communicationService: CommunicationService) {
    super();
    const subscription = this.communicationService.incomingMessage$
      .pipe(
        filter((item: MessageFromIframe) => item && item.payload && this.IFRAME_MESSAGES.includes(item.payload.type)),
        map((item: MessageFromIframe) => ({
          id: item.pluginIframeId,
          type: item.payload.type,
          width: item.payload.data.width,
          height: item.payload.data.height,
          differentFrameSelector: item.payload.data.differentFrameSelector,
        })),
      )
      .subscribe((item: ResizeItem) => this.handleMessage(item));

    this.subscriptions.push(subscription);
  }

  private handleMessage(item: ResizeItem): void {
    switch (item.type) {
      case MESSAGE_TYPE.RESIZE_IFRAME_SIZE:
        return this.resizeIframeSize(item);
      case MESSAGE_TYPE.RESIZE_IFRAME_WIDTH_SIZE:
        return this.resizeIframeWidthSize(item);
      case MESSAGE_TYPE.RESIZE_IFRAME_HEIGHT_SIZE:
        return this.resizeIframeHeightSize(item);
      case MESSAGE_TYPE.RESET_IFRAME_SIZE:
        return this.resetIframeSize(item);
    }
  }

  private resizeIframeSize(item: ResizeItem): void {
    const element: ResizeHTMLElement = this.getElement(item);
    element.height = item.height;
    element.width = item.width;
  }

  private resetIframeSize(item: ResizeItem): void {
    const element: ResizeHTMLElement = this.getElement(item);
    element.height = element.width = this.DEFAULT_SIZE;
  }

  private resizeIframeWidthSize(item: ResizeItem): void {
    this.getElement(item).width = item.width;
  }

  private resizeIframeHeightSize(item: ResizeItem): void {
    this.getElement(item).height = item.height;
  }

  private getElement(item: ResizeItem): ResizeHTMLElement {
    let result: HTMLElement;
    if (item.differentFrameSelector) {
      result = document.querySelector(`${item.differentFrameSelector} iframe`) as any;
    } else {
      result = document.getElementById(item.id) as any;
    }

    if (!result) {
      throw new Error(this.ELEMENT_NOT_FOUND);
    }

    return result as unknown as ResizeHTMLElement;
  }
}
