import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-supplier-payment-details-header',
  templateUrl: './supplier-payment-details-header.component.html',
  styleUrls: ['./supplier-payment-details-header.component.scss'],
})
export class SupplierPaymentDetailsHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() task: any;
  @Input() lastUpdatedPayments: any;
  @Input() lastAddedPayments: any;
  @Input() showApproveOrSubmitLabel: any;
  @Input() isDebit: boolean;
  @Input() isAutoDebit: boolean;
  @Input() showTaskStatus: boolean;
  @Input() showLastUpdateStatus: boolean;
  @Input() showLastAddedPaymentsStatus: boolean;
  @Input() showRefresh: boolean;
  @Input() showAction: boolean;
  @Input() isSubmitted: boolean;
  @Input() showApproveOrSubbmitLabel: string;

  @Output() onRefreshViewOnClick = new EventEmitter();
  @Output() onAddPaymentsOnClick = new EventEmitter();
  @Output() onUpdatePaymentOnClick = new EventEmitter();
  @Output() onApproveOrSubmitPaymentOnClick = new EventEmitter();
  @Output() onEditBatchOnClick = new EventEmitter();
  @Output() unlock = new EventEmitter();

  submitBtnText: string;

  private readonly MSG_RESUBMIT = 'Resubmit';
  private readonly MSG_SUBMIT = 'Submit';

  constructor() {}

  ngOnInit() {
    this.submitButtonCopyFactory();
  }

  submitButtonCopyFactory() {
    this.submitBtnText = this.isSubmitted ? this.MSG_RESUBMIT : this.MSG_SUBMIT;
  }

  onUnlock(): void {
    this.unlock.emit();
  }

  refreshViewOnClick() {
    this.onRefreshViewOnClick.emit();
  }

  addPaymentsOnClick() {
    this.onAddPaymentsOnClick.emit();
  }

  updatePaymentOnClick() {
    this.onUpdatePaymentOnClick.emit();
  }

  approveOrSubmitPaymentOnClick() {
    this.onApproveOrSubmitPaymentOnClick.emit();
  }

  editBatchOnClick() {
    this.onEditBatchOnClick.emit();
  }
}
