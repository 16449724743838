<div class="advance-line-history-container" *ngIf="advanceLineHistory">
  <div class="card-header">
    <div class="card-title">Approved Advances Lines</div>
  </div>
  <div *ngFor="let record of advanceLineHistory; let rowIndex = index">
    <app-approved-advance-lines-table
      [record]="record"
      [rowIndex]="rowIndex"
      [columns]="columns"
      (onAdvanceLineKeyClick)="redirectToAdvanceLineDetails($event)"
      (onReUnderwriteClick)="onReUnderwriteClick($event)"
    >
    </app-approved-advance-lines-table>

    <div class="expanded-section height_visible" [class.height_hidden]="!record.panelExpanded">
      <div class="expanded-section__content">
        <div class="expanded-section__item">
          <app-approved-advances [approvedAdvances]="record.advanceLineInnerTableData"></app-approved-advances>
        </div>
        <div class="expanded-section__item">
          <app-underwritings
            [needToLoadData]="record.panelExpanded"
            [supplierKey]="record!.advanceLineData!.filteredData[0]!.supplier_key"
            [advanceLineKey]="record!.advanceLineData!.filteredData[0]!.advance_line_key"
          >
          </app-underwritings>
        </div>
      </div>
    </div>
  </div>
</div>
