<ul class="nav" appAccordionNav appAppendSubmenuIcon appHighlightActiveItems #menuWrapper>
  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
    <app-task-count-badge filterType="currentUser" data-test="sidebarYourTasks"></app-task-count-badge>
  </li>
  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
    <app-task-count-badge filterType="currentUserGroup" data-test="sidebarGroupTasks"></app-task-count-badge>
  </li>
  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]" class="nav-divider"></li>
  <li appOpenSearchOverlay [hidden]="!showSearchButton">
    <a mat-button data-test="sidebarSearch">
      <i class="nav-icon material-icons">search</i>
      <span class="nav-text">Search</span>
    </a>
  </li>
  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]" [hidden]="!showActionsMenu">
    <a mat-button data-test="sidebarActions">
      <i class="nav-icon material-icons">apps</i>
      <span class="nav-text">Actions</span>
    </a>
    <ul>
      <li>
        <a mat-button class="mat-button prepend-icon" data-test="sidebarImport">
          <span>Import</span>
        </a>
        <ul>
          <li>
            <a mat-button class="mat-button" (click)="loadEarnings()" data-test="sidebarLoadEarnings">
              <span>Load Earnings</span>
            </a>
          </li>
          <li [hidden]="!shouldShowRemitance">
            <a mat-button class="mat-button" (click)="loadRemittance()" data-test="sidebarLoadRemittance">
              <span>Load Remittance</span>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openAddChargebackDialog()" data-test="sidebarAddChargeback">
          <span>Add chargeback</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openAddRecoveryDialog()" data-test="sidebarAddBadDebtRecovery">
          <span>Add Bad Debt Recovery</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openAddReceivableDialog()" data-test="sidebarAddReceivable">
          <span>Add receivable</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openAddWriteoffDialog()" data-test="sidebarAddWriteoff">
          <span>Add writeoff</span>
        </a>
      </li>
      <li *ngIf="isSupplierActive">
        <a mat-button class="mat-button prepend-icon" (click)="openAddNewDefaultContractDialog()" data-test="sidebarAddNewDefaultContract">
          <span>Add New Default Contract</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openEditMpStatementDialog()" data-test="sidebarEditMPStatement">
          <span>Edit MP Statement</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openAddMpPaymentDialog()" data-test="sidebarAddMPPayment">
          <span>Add MP Payment</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openAddZeroPaymentDialog()" data-test="sidebarAddZeroMPPayment">
          <span>Add Zero MP Payment</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openAdjustFeeDialog()" data-test="sidebarAdjustPayabilityFee">
          <span>Adjust Payability Fee</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="openAddFundTransferRequest()" data-test="sidebarAddFundTransferRequest">
          <span>Add Fund Transfer Request</span>
        </a>
      </li>
    </ul>
  </li>
  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
    <a mat-button [routerLink]="['/marketplaces']" data-test="sidebarMarketplaces">
      <i class="nav-icon material-icons">business</i>
      <span class="nav-text">Marketplaces</span>
    </a>
  </li>
  <ng-container *appEnabledByFeatureFlag="featureFlags.UNDERWRITING_TRACKER">
    <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
      <a mat-button [routerLink]="['/underwriting']" data-test="sidebarUnderwritingTracker">
        <i class="nav-icon material-icons">list</i>
        <span class="nav-text">UW Tracker</span>
      </a>
    </li>
  </ng-container>
  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
    <a mat-button data-test="sidebarReports">
      <i class="nav-icon material-icons">content_paste</i>
      <span class="nav-text">Reports</span>
    </a>
    <ul>
      <li>
        <a [routerLink]="['/reports']" mat-button class="mat-button prepend-icon" data-test="sidebarInternalReports">
          <span>Internal Reports</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/reports/external']" data-test="sidebarExternalReports">
          <span>External Reports</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/reports/waiting-suppliers']" data-test="sidebarWaitingSuppliersReports">
          <span>Waiting Suppliers Report</span>
        </a>
      </li>
    </ul>
  </li>

  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
    <a mat-button data-test="sidebarPayments">
      <i class="nav-icon material-icons">apps</i>
      <span class="nav-text">Payments</span>
    </a>
    <ul>
      <li>
        <a
          [routerLink]="['/processes/supplier-payments/batches']"
          mat-button
          class="mat-button prepend-icon"
          data-test="sidebarFundTransferBatches"
        >
          <span>Fund Transfer Batches</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/proceses/approval']" mat-button class="mat-button prepend-icon" data-test="sidebarBalanceApproval">
          <span>Balance Approval</span>
        </a>
      </li>
    </ul>
  </li>

  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
    <a mat-button [routerLink]="['/emails']" data-test="sidebarEmails">
      <i class="nav-icon material-icons">email</i>
      <span class="nav-text">Emails</span>
    </a>
  </li>
  <li *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
    <a mat-button class="mat-button manage" data-test="sidebarManage">
      <i class="nav-icon material-icons">card_travel</i>
      <span class="nav-text">Manage</span>
    </a>
    <ul>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/batch-imports']" data-test="sidebarBulkActions">
          <span>Bulk Actions</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/event-rules']" data-test="sidebarEventRules">
          <span>Event Rules</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/event-rules-2']" data-test="sidebarEventRulesV2">
          <span>Event Rules v2.0 (beta)</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/users']" data-test="sidebarUsers">
          <span>Users</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/perm-groups']" data-test="sidebarPermissions">
          <span>Permissions</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" (click)="navigateToEvents()" data-test="sidebarEvents">
          <span>Events</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/onboarding']" data-test="sidebarOnboarding">
          <span>Onboarding</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/email-config']" data-test="sidebarEmailSettings">
          <span>Email Settings</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/global-settings']" data-test="sidebarGlobalSettings">
          <span>Global Settings</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/fee-settings']" data-test="sidebarFeeSettings">
          <span>Fee Settings</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/contracts']" data-test="sidebarContracts">
          <span>Contracts</span>
        </a>
      </li>
      <li>
        <a mat-button class="mat-button prepend-icon" [routerLink]="['/manage/month-end-close']" data-test="sidebarContracts">
          <span>Month End Close</span>
        </a>
      </li>
    </ul>
  </li>
  <li *ngxPermissionsOnly="userPermission.DEV">
    <a mat-button class="mat-button manage" [routerLink]="['/admin']" data-test="sidebarDevAdmin">
      <i class="nav-icon material-icons">security</i>
      <span class="nav-text">DEV Admin</span>
    </a>
  </li>
</ul>
