import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { FeatureFlagsGeneralGuard } from '../guards/feature-flags-general.guard';
import { SubPermissionsGuard } from '../guards/sub-permissions.guard';
import { ExternalReports } from '../shared/external-report/external-reports.enum';
import { FeatureFlagGuard } from '../shared/feature-flags/feature-flag.guard';
import { SupplierKeyResolver } from '../shared/resolvers/supplier-key.resolver';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';
import { RouteAccessResolver } from '../shared/utils/route-access-resolver';
import { AdditionalBusinessOwnersComponent } from './supplier/additional-business-owners/additional-business-owners.component';
import { CardTransactionsDetailsComponent } from './supplier/card-transactions-details/card-transactions-details.component';
import { SupplierComponent } from './supplier/supplier.component';
import { ActiveAdvanceDetailsComponent } from './supplier/supplier-advances/active-advance/active-advance-details/active-advance-details.component';
import { ActiveAdvanceLineDetailsComponent } from './supplier/supplier-advances/active-advance/active-advance-line-details/active-advance-line-details/active-advance-line-details.component';
import { BaseAdvancesComponent } from './supplier/supplier-advances/base-advances/base-advances.component';
import { NewOfferComponent } from './supplier/supplier-advances/offer-eligibility/new-offer/new-offer.component';
import { OfferEligibilityDetailsComponent } from './supplier/supplier-advances/offer-eligibility/offer-eligibilty-details/offer-eligibility-details.component';
import { PendingOfferComponent } from './supplier/supplier-advances/pending-offer/pending-offer.component';
import { InstantAdvanceUnderwritingComponent } from './supplier/supplier-advances/pending-offer/pending-offer-calculator/pending-offer-progressive-details/instant-advance-underwriting/instant-advance-underwriting.component';
import { SupplierAdvancesComponent } from './supplier/supplier-advances/supplier-advances.component';
import { SupplierAmazonLendingComponent } from './supplier/supplier-amazon-lending/supplier-amazon-lending.component';
import { SupplierAmazonLendingListComponent } from './supplier/supplier-amazon-lending/supplier-amazon-lending-list/supplier-amazon-lending-list.component';
import { SupplierAmazonLendingLoanComponent } from './supplier/supplier-amazon-lending/supplier-amazon-lending-loan/supplier-amazon-lending-loan.component';
/* eslint-disable */
import { SupplierContractsComponent } from './supplier/supplier-contracts/supplier-contracts.component';
import { SupplierEfsAccountComponent } from './supplier/supplier-efs-account/supplier-efs-account.component';
import { PaymentFeesComponent } from './supplier/supplier-fees/payment-fees/payment-fees.component';
import { PurchaseFeesComponent } from './supplier/supplier-fees/purchase-fees/purchase-fees.component';
import { EditMarketplaceComponent } from './supplier/supplier-fees/reserve/edit-marketplace/edit-marketplace.component';
import { ReserveComponent } from './supplier/supplier-fees/reserve/reserve.component';
import { SupplierFeesComponent } from './supplier/supplier-fees/supplier-fees.component';
import { SupplierGeneralComponent } from './supplier/supplier-general/supplier-general.component';
import { SupplierHealthComponent } from './supplier/supplier-health/supplier-health.component';
import { SupplierMarketplaceStatementsComponent } from './supplier/supplier-marketplace-statements/supplier-marketplace-statements.component';
import { SupplierMarketplaceEditComponent } from './supplier/supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-edit.component';
import { SupplierMarketplaceListComponent } from './supplier/supplier-marketplaces/supplier-marketplace-list/supplier-marketplace-list.component';
import { SupplierMarketplacesComponent } from './supplier/supplier-marketplaces/supplier-marketplaces.component';
import { SupplierMarqetaAccountComponent } from './supplier/supplier-marqeta-account/supplier-marqeta-account.component';
import { SupplierOnboardingComponent } from './supplier/supplier-onboarding/supplier-onboarding.component';
import { IncomingPaymentAddComponent } from './supplier/supplier-payment/incoming-payment-add/incoming-payment-add.component';
import { OutgoingPaymentAddComponent } from './supplier/supplier-payment/outgoing-payment-add/outgoing-payment-add.component';
import { SupplierPaymentComponent } from './supplier/supplier-payment/supplier-payment.component';
import { SupplierPaymentListComponent } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-list.component';
import { SupplierReceivablesComponent } from './supplier/supplier-receivables/supplier-receivables.component';
import { SupplierReportDetailsComponent } from './supplier/supplier-reports/supplier-report-details/supplier-report-details.component';
import { SupplierReportListComponent } from './supplier/supplier-reports/supplier-report-list/supplier-report-list.component';
import { SupplierReportsComponent } from './supplier/supplier-reports/supplier-reports.component';
import { CategorizationComponent } from './supplier/supplier-summary/categorization/categorization.component';
import { SupplierSummaryComponent } from './supplier/supplier-summary/supplier-summary.component';
import { SupplierTransactionDetailsComponent } from './supplier/supplier-transaction-details/supplier-transaction-details.component';
import { SupplierTransactionSummaryComponent } from './supplier/supplier-transaction-summary/supplier-transaction-summary.component';
import { BackgroundReportsComponent } from './supplier/supplier-underwriting/background-reports/background-reports.component';
import { DocumentationComponent } from './supplier/supplier-underwriting/documentation/documentation.component';
import { SupplierInformationComponent } from './supplier/supplier-underwriting/supplier-information/supplier-information.component';
import { SupplierUnderwritingComponent } from './supplier/supplier-underwriting/supplier-underwriting.component';
import { UccCreateComponent } from './supplier/supplier-underwriting/ucc-main/ucc-create/ucc-create.component';
import { UccListComponent } from './supplier/supplier-underwriting/ucc-main/ucc-list/ucc-list.component';
import { UccMainComponent } from './supplier/supplier-underwriting/ucc-main/ucc-main.component';
import { UccTransUnionComponent } from './supplier/supplier-underwriting/ucc-main/ucc-trans-union/ucc-trans-union.component';
import { UnderwritingDailyComponent } from './supplier/supplier-underwriting/underwriting-daily/underwriting-daily.component';
import { UnderwritingInitialComponent } from './supplier/supplier-underwriting/underwriting-initial/underwriting-initial.component';
import { UnderwritingReportTabComponent } from './supplier/supplier-underwriting/underwriting-report-tab/underwriting-report-tab.component';
import { SupplierUnderwritingBetaComponent } from './supplier/supplier-underwriting-beta/supplier-underwriting-beta.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';

/* eslint-enable */

export const RESTRICTED_UW_ACCESS_CONFIG = {
  canActivate: [FeatureFlagsGeneralGuard, NgxPermissionsGuard],
  data: {
    permissions: {
      except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
    },
  },
};

const RESTRICTED_AUDITORS_ACCESS_CONFIG = {
  ...RESTRICTED_UW_ACCESS_CONFIG,
  ...{ data: { permissions: { except: [USER_PERMISSION.REST_UW_ACCESS] } } },
};

const RESTRICTED_REST_UW_ACCESS_ONLY_CONFIG = {
  ...RESTRICTED_UW_ACCESS_CONFIG,
  ...{ data: { permissions: { except: [USER_PERMISSION.ADVANCE_AUDITORS] } } },
};

export const RESTRICTED_UW_ACCESS_CONFIG_MARKETPLACE_STATEMENTS = {
  ...RESTRICTED_UW_ACCESS_CONFIG,
  data: {
    ...RESTRICTED_UW_ACCESS_CONFIG.data,
    reportName: 'mp_statements',
    reportConfigName: 'v_mp_statements',
    supplierKeyInQueryParamsNotNeeded: true,
  },
};

export const routes: Routes = [
  {
    path: 'suppliers',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard],
    children: [
      { path: '', redirectTo: '../', pathMatch: 'full' },
      { path: 'list', component: SupplierListComponent },
      {
        path: ':supplierKey',
        component: SupplierComponent,
        resolve: { supplierKey: SupplierKeyResolver },
        children: [
          { path: '', redirectTo: 'general', pathMatch: 'full' },
          {
            path: 'general',
            component: SupplierGeneralComponent,
            ...RESTRICTED_AUDITORS_ACCESS_CONFIG,
          },
          {
            path: 'additional-business-owners',
            component: AdditionalBusinessOwnersComponent,
          },
          { path: 'additional-business-owners/new', component: AdditionalBusinessOwnersComponent, data: { isNew: true } },
          {
            path: 'summary',
            component: SupplierSummaryComponent,
            children: [
              { path: '', redirectTo: 'categorization', pathMatch: 'full' },
              { path: 'categorization', component: CategorizationComponent },
            ],
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          { path: 'onboarding', component: SupplierOnboardingComponent, ...RESTRICTED_UW_ACCESS_CONFIG },
          {
            path: 'new-underwriting',
            component: SupplierUnderwritingBetaComponent,
            canLoad: [FeatureFlagGuard],
            canActivate: [FeatureFlagGuard],
          },
          {
            path: 'underwriting',
            component: SupplierUnderwritingComponent,
            ...RESTRICTED_REST_UW_ACCESS_ONLY_CONFIG,
            children: [
              { path: '', redirectTo: 'initial', pathMatch: 'full' },
              {
                path: 'initial',
                component: UnderwritingInitialComponent,
              },
              {
                path: 'background-reports',
                component: BackgroundReportsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: USER_PERMISSION.FINANCE,
                  },
                },
              },
              {
                path: 'daily',
                component: UnderwritingDailyComponent,
                ...RESTRICTED_UW_ACCESS_CONFIG,
              },
              {
                path: 'supplier-information',
                component: SupplierInformationComponent,
              },
              {
                path: 'documentation',
                component: DocumentationComponent,
                canLoad: [FeatureFlagGuard],
                canActivate: [FeatureFlagGuard],
              },
              {
                path: 'bank-transactions-report',
                component: UnderwritingReportTabComponent,
                data: { reportId: ExternalReports.PLAID },
              },
              {
                path: 'bank-transactions-categories',
                component: UnderwritingReportTabComponent,
                data: { reportId: ExternalReports.BANK_CATEGORIES },
              },
              {
                path: 'access-onboarding-predictions',
                component: UnderwritingReportTabComponent,
                data: { reportId: ExternalReports.ACCESS_ONBOARDING_PREDICTIONS },
              },
              {
                path: 'access-daily-predictions',
                component: UnderwritingReportTabComponent,
                data: { reportId: ExternalReports.ACCESS_DAILY_PREDICTIONS },
              },
              {
                path: 'ucc',
                component: UccMainComponent,
                children: [
                  { path: '', redirectTo: 'list', pathMatch: 'full' },
                  { path: 'list', component: UccListComponent },
                  { path: 'create', component: UccCreateComponent },
                  { path: 'edit/:id', component: UccCreateComponent },
                  { path: 'transUnion/:id', component: UccTransUnionComponent },
                ],
                ...RESTRICTED_UW_ACCESS_CONFIG,
              },
            ],
          },
          {
            path: 'payment',
            component: SupplierPaymentComponent,
            children: [
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: 'list', component: SupplierPaymentListComponent },
              {
                path: 'new',
                component: OutgoingPaymentAddComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
                  },
                },
              },
              {
                path: 'edit/:paymentConfigKey',
                component: OutgoingPaymentAddComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
                  },
                },
              },
              {
                path: 'deposit-account-edit/:depositAccountKey',
                component: IncomingPaymentAddComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
                  },
                },
              },
            ],
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'health',
            component: SupplierHealthComponent,
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'statements',
            component: SupplierMarketplaceStatementsComponent,
            ...RESTRICTED_UW_ACCESS_CONFIG_MARKETPLACE_STATEMENTS,
          },
          {
            path: 'reports',
            component: SupplierReportsComponent,
            children: [
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: 'list', component: SupplierReportListComponent },
              { path: ':reportName', component: SupplierReportDetailsComponent },
            ],
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'fees',
            component: SupplierFeesComponent,
            children: [
              { path: '', redirectTo: 'purchase', pathMatch: 'full' },
              { path: 'purchase', component: PurchaseFeesComponent },
              { path: 'payment', component: PaymentFeesComponent },
              { path: 'reserve', component: ReserveComponent },
              { path: 'reserve/edit/:marketplaceKey', component: EditMarketplaceComponent },
            ],
          },
          {
            path: 'marketplaces',
            component: SupplierMarketplacesComponent,
            children: [
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: 'list', component: SupplierMarketplaceListComponent },
              {
                path: 'edit/:mpSupplierKey',
                component: SupplierMarketplaceEditComponent,
              },
            ],
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'transaction-summary',
            component: SupplierTransactionSummaryComponent,
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'transaction-details',
            component: SupplierTransactionDetailsComponent,
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'receivables',
            component: SupplierReceivablesComponent,
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'advances',
            component: SupplierAdvancesComponent,
            children: [
              { path: '', component: BaseAdvancesComponent },
              { path: 'new', component: NewOfferComponent },
              { path: 'advance/:advanceKey', component: ActiveAdvanceDetailsComponent },
              { path: 'advance-line/:advanceLineKey', component: ActiveAdvanceLineDetailsComponent },
              { path: 'pending-request/:requestKey', component: PendingOfferComponent },
              { path: 'ml-offer/:mlOfferKey', component: OfferEligibilityDetailsComponent },
              { path: 'progressive-details', component: InstantAdvanceUnderwritingComponent },
              { path: 'progressive-details/:advanceOfferRequestKey', component: InstantAdvanceUnderwritingComponent },
            ],
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'amazon-lending',
            component: SupplierAmazonLendingComponent,
            children: [
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              { path: 'list', component: SupplierAmazonLendingListComponent },
              {
                path: 'add-loan',
                component: SupplierAmazonLendingLoanComponent,
                canActivate: [SubPermissionsGuard],
                data: { requireOneOfSubPermissions: [USER_PERMISSION.MP_LOANS_MODIFY] },
              },
              {
                path: 'loan/:loanKey',
                component: SupplierAmazonLendingLoanComponent,
                canActivate: [SubPermissionsGuard],
                data: { requireOneOfSubPermissions: [USER_PERMISSION.MP_LOANS_DELETE, USER_PERMISSION.MP_LOANS_MODIFY] },
              },
            ],
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'card-transactions/efs',
            component: SupplierEfsAccountComponent,
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'card-transactions/marqeta/:marqetaKey',
            component: CardTransactionsDetailsComponent,
          },
          {
            path: 'card-transactions/marqeta',
            component: SupplierMarqetaAccountComponent,
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
          {
            path: 'contracts',
            component: SupplierContractsComponent,
            ...RESTRICTED_UW_ACCESS_CONFIG,
          },
        ],
      },
    ],
  },
];
