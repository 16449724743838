<article class="article">
  <div class="row">
    <div class="col-md-12">
      <div class="card bg-color-white">
        <div class="card-content">
          <div class="card-title">Delinquecy</div>
          <mat-table *ngIf="riskDelinquency; else loading" #table [dataSource]="riskDelinquency">
            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef> Date</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.supplier_risk_date | date: 'MM/dd/yy' }}</mat-cell>
            </ng-container>

            <!-- State Column -->
            <ng-container matColumnDef="state">
              <mat-header-cell *matHeaderCellDef> State</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.delinquency_state }}</mat-cell>
            </ng-container>

            <!--  Incremental Risk Grade Column -->
            <ng-container matColumnDef="incrementalRiskGrade">
              <mat-header-cell *matHeaderCellDef> Incremental Risk Grade</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.supplier_incremental_risk_grade }}</mat-cell>
            </ng-container>

            <!-- Current Risk Grade Column -->
            <ng-container matColumnDef="currentRiskGrade">
              <mat-header-cell *matHeaderCellDef> Current Risk Grade</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.current_risk_grade }}</mat-cell>
            </ng-container>

            <!--  Risk Overwritten Column -->
            <ng-container matColumnDef="riskOverwritten">
              <mat-header-cell *matHeaderCellDef> Risk Overwritten</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.risk_score_override ? 'Yes' : 'No' }}</mat-cell>
            </ng-container>

            <!--  Current ADA Column -->
            <ng-container matColumnDef="currentADA">
              <mat-header-cell *matHeaderCellDef> Current ADA</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.current_ada_percentage }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="currentADACurr">
              <mat-header-cell *matHeaderCellDef> Current ADA</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.current_ada | currency }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="delinquencyColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: delinquencyColumns"></mat-row>
          </mat-table>
        </div>

        <div class="card-action">
          <button
            *ngIf="!isRiskInfo(); else addDelinquencyPanel"
            mat-raised-button
            class="btn-w-md pull-right"
            (click)="addDelinquencyRecord()"
            [color]="'primary'"
          >
            Add Delinquency Record
          </button>

          <ng-template #addDelinquencyPanel>
            <div class="row" [formGroup]="riskForm">
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Delinquency Type" formControlName="delinquencyType">
                    <mat-option *ngFor="let type of delinquencyTypes | async" [value]="type.delinquency_type">
                      {{ type.delinquency_type_label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Delinquency Age" formControlName="delinquencyAge">
                    <mat-option *ngFor="let type of delinquencyAges | async" [value]="type.delinquency_age">
                      {{ type.delinquency_age_label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-select placeholder="Scenario" formControlName="delinquencyScenario">
                    <mat-option *ngFor="let type of delinquencyScenarios | async" [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 text-center">
                <button mat-raised-button class="btn-w-md" (click)="saveRiskInfo()" [color]="'primary'">Save</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-xl-6 col-lg-6'">
      <div class="card bg-color-white">
        <div class="card-content">
          <div class="card-title">Risk Related Information</div>
          <form
            *ngIf="riskForm; else loading"
            class="supplier-contact-data"
            app-card-formgroup
            #formGroupRisk
            [formGroup]="riskForm"
            (onSave)="saveRiskInfo()"
          >
            <app-inline-edit-number-slider
              type="number"
              [formControlName]="'riskScoreOverride'"
              [formGroup]="riskForm"
              [label]="'Risk Score Override'"
              [min]="0"
              [max]="5"
              [step]="1"
              [thumbLabel]="true"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-number-slider>

            <app-inline-edit-text
              type="number"
              [formControlName]="'thirdPartyCreditScore'"
              [formGroup]="riskForm"
              [label]="'Credit Score'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-text>

            <app-inline-edit-text
              type="number"
              [formControlName]="'credit2beCreditScore'"
              [formGroup]="riskForm"
              [label]="'Credit2Be Credit Score'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-text>

            <app-inline-edit-text
              type="number"
              [formControlName]="'dnbCreditScore'"
              [formGroup]="riskForm"
              [label]="'DnB Credit Score'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-text>

            <app-inline-edit-text
              type="number"
              [formControlName]="'uccScore'"
              [formGroup]="riskForm"
              [label]="'UCC Score'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-text>

            <app-inline-edit-switch
              [formControlName]="'pbSeniorUcc'"
              [formGroup]="riskForm"
              [label]="'Payability is the senior UCC'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-switch>

            <app-inline-edit-text
              type="number"
              [formControlName]="'validityScore'"
              [formGroup]="riskForm"
              [label]="'Validity Score'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-text>

            <app-inline-edit-switch
              [formControlName]="'personalValidityAgreement'"
              [formGroup]="riskForm"
              [label]="'Personal Validity Agreement'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-switch>

            <app-inline-edit-text
              type="number"
              [formControlName]="'incScore'"
              [formGroup]="riskForm"
              [label]="'Inc Score'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-text>

            <app-inline-edit-select
              type="number"
              [formControlName]="'businessType'"
              [formGroup]="riskForm"
              [label]="'Business type'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
              [items]="businessTypes"
            >
            </app-inline-edit-select>

            <app-inline-edit-text
              type="number"
              [formControlName]="'geoScore'"
              [formGroup]="riskForm"
              [label]="'Geo Score'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
            >
            </app-inline-edit-text>

            <app-inline-edit-select
              [formControlName]="'country'"
              [formGroup]="riskForm"
              [label]="'Country'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
              [items]="countries"
            >
            </app-inline-edit-select>

            <app-inline-edit-select
              [formControlName]="'fulfillment'"
              [formGroup]="riskForm"
              [label]="'Fulfillment'"
              [inGroupEdit]="formGroupRisk.inEdit"
              disableInline="true"
              [items]="fulfillments"
            >
            </app-inline-edit-select>
          </form>
        </div>
      </div>
    </div>

    <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-xl-6 col-lg-6'">
      <div class="card bg-color-white">
        <div class="card-content">
          <div class="card-title">Amazon Health</div>
          <div class="table-responsive" *ngIf="amazonHealth; else loading">
            <table class="amazon-health-table mdl-data-table">
              <tbody>
                <tr ngxClipboard [cbContent]="amazonHealth.health_standing" matTooltip="Copy Health Standing">
                  <td class="text-muted">Health Standing</td>
                  <td class="text-left">{{ amazonHealth.health_standing || 'No data' }}</td>
                </tr>
                <tr ngxClipboard [cbContent]="amazonHealth.health_standing_details" matTooltip="Copy Health Standing Details">
                  <td class="text-muted">Health Standing Details</td>
                  <td class="text-left" style="white-space: normal">{{ amazonHealth.health_standing_details || 'No data' }}</td>
                </tr>
                <tr ngxClipboard [cbContent]="amazonHealth.account_performance_status" matTooltip="Copy Account Performance Status">
                  <td class="text-muted">Account Performance Status</td>
                  <td class="text-left">{{ amazonHealth.account_performance_status || 'No data' }}</td>
                </tr>
                <tr ngxClipboard [cbContent]="amazonHealth.account_performance_info" matTooltip="Copy Account Performance Info">
                  <td class="text-muted">Account Performance Info</td>
                  <td class="text-left">
                    <div *ngIf="amazonHealth.account_performance_info; else noData">
                      <div class="row" *ngFor="let key of amazonHealth.account_performance_info | keys">
                        <dt class="col-sm-6">{{ key }}</dt>
                        <dd class="col-sm-6 text-right" *ngIf="amazonHealth.account_performance_info[key].constructor.name !== 'Object'">
                          {{ amazonHealth.account_performance_info[key] }}
                        </dd>
                        <dd class="col-sm-6 text-right" *ngIf="amazonHealth.account_performance_info[key].constructor.name == 'Object'">
                          <details>
                            <summary>Show details</summary>
                            <pre class="account-pre">{{ amazonHealth.account_performance_info[key] | json }}</pre>
                          </details>
                        </dd>
                      </div>
                    </div>
                    <ng-template #noData>No data</ng-template>
                  </td>
                </tr>
                <tr ngxClipboard [cbContent]="amazonHealth.account_warning" matTooltip="Copy Account Warning">
                  <td class="text-muted">Account Warning</td>
                  <td class="text-left">{{ amazonHealth.account_warning || 'No data' }}</td>
                </tr>
                <tr ngxClipboard [cbContent]="amazonHealth.last_check_ts" matTooltip="Copy Last Check Time">
                  <td class="text-muted">Last Check Time</td>
                  <td class="text-left">{{ amazonHealth.last_check_ts || 'No data' }}</td>
                </tr>
                <tr ngxClipboard [cbContent]="amazonHealth.amazon_recent_payment" matTooltip="Copy Amazon Recent Payment">
                  <td class="text-muted">Amazon Recent Payment</td>
                  <td class="text-left">{{ amazonHealth.amazon_recent_payment || 'No data' }}</td>
                </tr>
                <tr ngxClipboard [cbContent]="amazonHealth.recent_payment_date" matTooltip="Copy Recent Payment Date">
                  <td class="text-muted">Recent Payment Date</td>
                  <td class="text-left">{{ amazonHealth.recent_payment_date || 'No data' }}</td>
                </tr>
                <tr ngxClipboard [cbContent]="amazonHealth.amazon_account_number" matTooltip="Copy Amazon Account Number">
                  <td class="text-muted">Amazon Account Number</td>
                  <td class="text-left">{{ amazonHealth.amazon_account_number || 'No data' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</article>

<ng-template #loading>
  <div>
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>
