<div *ngIf="!isRestrictedUWView" class="bottom-fixed-box" [ngClass]="{ noAction: footerButtonLoading }">
  <app-spinner-button
    *ngFor="let button of buttonsConfig"
    (onClick)="onButtonClick(button.type)"
    [disabled]="button.disabled"
    [loading]="button.loading"
    [color]="button.color"
    [customWidth]="'9.5rem'"
    [text]="button.name"
    [appDisabledReason]="button.disabledReason"
    [disableWithReason]="button.disabled"
  >
  </app-spinner-button>
</div>
