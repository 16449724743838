<mat-card class="onboardingPaymentOptions__card">
  <mat-card-content>
    <h5>Payments options to enable</h5>

    <form class="onboardingPaymentOptions__card__form" [formGroup]="paymentOptionsForm">
      <div>
        <mat-checkbox class="onboardingPaymentOptions__card__form__checkbox" formControlName="wirePayment">Wire</mat-checkbox>

        <mat-checkbox class="onboardingPaymentOptions__card__form__checkbox" formControlName="achPayment">ACH</mat-checkbox>

        <mat-checkbox class="onboardingPaymentOptions__card__form__checkbox" formControlName="sendTransferEmail"
          >Enable Auto Transfer Email</mat-checkbox
        >
      </div>

      <div>
        <mat-form-field class="onboardingPaymentOptions__card__form__field">
          <label>
            <input matInput placeholder="EFS Rebate Percent" type="number" formControlName="efsRebatePercent" />
          </label>
        </mat-form-field>

        <mat-form-field class="onboardingPaymentOptions__card__form__field">
          <label>
            <input matInput placeholder="Minimum Payment Amount" type="number" formControlName="minPaymentAmount" />
          </label>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>
