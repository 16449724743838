<section class="container-fluid">
  <article class="article">
    <h2 class="article-title">Processes</h2>
    <div class="box box-default table-box table-striped table-responsive mdl-shadow--2dp">
      <table class="mdl-data-table">
        <thead>
          <tr>
            <th class="mdl-data-table__cell--non-numeric">Name</th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-processListModel [ngForOf]="processesList">
            <tr>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="supplier-details-link" [routerLink]="[processListModel.path]">
                  {{ processListModel.name }}
                </a>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </article>
</section>
