import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { SupplierGeneralFormTypes } from './supplier-general-form-types';

@Injectable()
export class SupplierGeneralForm {
  public incorporatedInTheUS$: Observable<any>;

  constructor(private formBuilder: FormBuilder) { }

  initGeneralFormGroups(supplierDetailsModel) {

    const formGroups: { [key: string]: FormGroup } = {};

    formGroups[SupplierGeneralFormTypes.CONTACT_NAME_FORM] = this.formBuilder.group({
      contactFirstName: supplierDetailsModel.contactFirstName,
      contactLastName: supplierDetailsModel.contactLastName,
      contactRole: [{ value: this.getContactPersonsTitle(supplierDetailsModel), disabled: true }],
    });

    formGroups[SupplierGeneralFormTypes.CONTACT_DATA_FORM] = this.formBuilder.group({
      contactEmail: supplierDetailsModel.contactEmail,
      phoneNumber: supplierDetailsModel.phoneNumber,
      // TO DO change it after backend prepare data
      dateOfBirth: supplierDetailsModel.dateOfBirth,
      ownerSSN: supplierDetailsModel.ownerSSN,
      businessWebsite: supplierDetailsModel.businessWebsite,
    });

    formGroups[SupplierGeneralFormTypes.INSTANT_MESSENGER_FORM] = this.formBuilder.group({
      instantMessengerSlug: supplierDetailsModel.instantMessenger?.slug,
      instantMessengerType: supplierDetailsModel.instantMessenger?.type,
    });

    formGroups[SupplierGeneralFormTypes.LEGAL_FORM] = this.formBuilder.group({
      legalName: [supplierDetailsModel.legalName, Validators.required],
      businessTypeCode: [supplierDetailsModel.businessTypeCode],
    });

    formGroups[SupplierGeneralFormTypes.ADDRESS_FORM] = this.formBuilder.group({
      addr1: supplierDetailsModel.address?.addr1,
      addr2: supplierDetailsModel.address?.addr2,
      country: supplierDetailsModel.address?.country,
      state: supplierDetailsModel.address?.state,
      city: supplierDetailsModel.address?.city,
      postalCode: supplierDetailsModel.address?.postalCode,
    });

    formGroups[SupplierGeneralFormTypes.TIMEZONE_FORM] = this.formBuilder.group({
      timezone: supplierDetailsModel.timezone,
    });

    formGroups[SupplierGeneralFormTypes.TAX_FORM] = this.formBuilder.group({
      taxId: [supplierDetailsModel.taxId, [Validators.minLength(9), Validators.maxLength(9)]],
    });

    formGroups[SupplierGeneralFormTypes.INCORPORATION_FORM] = this.formBuilder.group({
      incorporatedInTheUS: this.formBuilder.control(supplierDetailsModel.incorporatedInTheUS),
      stateOfIncorporation: this.formBuilder.control(supplierDetailsModel.stateOfIncorporation),
      // TO DO change it after backend prepare data
      dateEstablished: supplierDetailsModel.dateEstablished,
    });

    this.incorporatedInTheUS$ = formGroups[SupplierGeneralFormTypes.INCORPORATION_FORM].controls['incorporatedInTheUS'].valueChanges;
    console.log('--------------------- supplierDetailsModel --------', supplierDetailsModel);
    formGroups[SupplierGeneralFormTypes.GENERAL_INFO_FORM] = this.formBuilder.group({
      instantAccess: [supplierDetailsModel.instantAccess, Validators.required],
      cancelationStatus: [supplierDetailsModel.cancelationStatus],
      pendApprovNeeded: [supplierDetailsModel.pendApprovNeeded],
      sendAutoEmail: [supplierDetailsModel.sendAutoEmail],
    });

    formGroups[SupplierGeneralFormTypes.AUTO_PAYMENT_FORM] = this.formBuilder.group({
      hasAutoTransferEnabled: [supplierDetailsModel.status.hasAutoTransferEnabled],
      hasForwardPaymentEnabled: [supplierDetailsModel.status.hasForwardPaymentEnabled],
    });

    return formGroups;
  }

  initFundingFormGroups(fundingParticularsModel) {
    const formGroups: { [key: string]: FormGroup } = {};

    formGroups[SupplierGeneralFormTypes.FUNDING_PARTICULARS_FORM] = this.formBuilder.group({
      legalSigned: [fundingParticularsModel.legalSigned, Validators.required],
      fundPartStatus: fundingParticularsModel.fundPartStatus,
      fundFacilityLimit: fundingParticularsModel.fundFacilityLimit,
      guarantor: fundingParticularsModel.guarantor,
      supplierCovenants: fundingParticularsModel.supplierCovenants,
    });

    return formGroups;
  }

  initShippingAddressFormGroup(mainOwnerObject) {
    const formGroups: { [key: string]: FormGroup } = {};

    formGroups[SupplierGeneralFormTypes.SHIPPING_ADDRESS_FORM] = this.formBuilder.group({
      address: mainOwnerObject.address,
      address2: mainOwnerObject.address2,
      country: mainOwnerObject.country,
      stateCode: mainOwnerObject.stateCode,
      city: mainOwnerObject.city,
      zipPostalCode: mainOwnerObject.zipPostalCode,
    });

    return formGroups;
  }

  getContactPersonsTitle(supplierDetailsModel: any) {
    return supplierDetailsModel?.businessTypeCode === 'SP' ? '' : 'Controlling Officer/Beneficial Owner';
  }
}
