export const SearchResponseFixtureOld = {
  results: [
    {
      cancellationStatus: 2,
      dealInfo: 'string',
      legalName: '<em>Aid</em>a <em>Mu</em>rillo',
      marketplaceSupplierKey: 'string',
      marketplaceWithLargestDue: 'Wallmart',
      marketplaces: ['Amazon', 'Wallmart', 'Shopify'],
      payabilityStatus: 1,
      registrationDate: '2014-02-01T09:28:56.321-10:00',
      salesRep: 'string',
      services: {
        access: true,
        advance: true,
      },
      supplierAdvanceKey: 'string',
      supplierEin: 'string',
      supplierFirstName: 'Aida',
      supplierKey: 'e6ae8c95-fcfb-4637-9d4e-a0ca59e5fed3',
      supplierLastName: 'Murillo',
      supplierPhoneNumber: 'string',
      supplierSsn: 'string',
    },
    {
      cancellationStatus: 5,
      dealInfo: 'string',
      legalName: 'TopShelf Merch',
      marketplaceSupplierKey: 'string',
      marketplaceWithLargestDue: 'Amazon',
      marketplaces: ['Amazon', 'Wallmart', 'Shopify'],
      payabilityStatus: 1,
      registrationDate: '2014-02-01T09:28:56.321-10:00',
      salesRep: 'string',
      services: {
        access: true,
        advance: true,
      },
      supplierAdvanceKey: 'string',
      supplierEin: 'string',
      supplierFirstName: 'string',
      supplierKey: '0e68510e-a989-430e-96b4-2bedf3ea347e',
      supplierLastName: 'string',
      supplierPhoneNumber: 'string',
      supplierSsn: 'string',
    },
  ],
  totalCount: 10,
};

export const SearchResponseFixture = {
  results: [
    {
      resultType: 'supplier',
      supplier: {
        supplierKey: '7ff2c175-7ca8-4b58-9b9b-0f828b130166',
        legalName: '<em>Yarek</em> LLC',
        firstName: '<em>Jaros</em>law',
        lastName: 'Wojtas',
        email: 'jarekw@payability.com',
        phone: '+48602659049',
        SSN: '1234567890',
        EIN: '0987654321',
      },
      registrationDate: '2018-05-15',
      salesRep: { name: 'Johny Mnemonic', email: 'johnym@payability.com' },
      dealInfo: 'deal info 1',
      marketplaces: [
        {
          marketplaceSupplierKey: '7ff2c175-7ab8-4b58-9b9b-0f828b130166',
          name: 'Marketplace A',
          due: 123.45,
        },
        {
          marketplaceSupplierKey: '7ff2c175-7cd8-4b58-9b9b-0f828b130166',
          name: 'Marketplace B',
          due: 678.9,
        },
      ],
      services: {
        instantAccess: true,
        instantAdvance: true,
      },
      payabilityStatus: 'pending',
      cancellationStatus: 'pending',
    },
    {
      resultType: 'person',
      supplier: {
        supplierKey: 'e6ae8c95-fcfb-4637-9d4e-a0ca59e5fed3',
        legalName: '<em>Aida</em> Murillo',
        firstName: '<em>Aid</em>a',
        lastName: 'M<em>uri</em>llo',
        email: 'aidam@payability.com',
        phone: '+11234567890',
        SSN: '1234567890',
        EIN: '0987654321',
      },
      registrationDate: '2011-05-15',
      salesRep: {
        name: 'Luke Skywalker',
        email: 'jedi@payability.com',
      },
      dealInfo: 'deal info 1',
      marketplaces: [
        {
          marketplaceSupplierKey: '7ff2c175-7ab8-3b58-9b9b-0f828b130166',
          name: 'Marketplace D',
          due: 123.45,
        },
        {
          marketplaceSupplierKey: '7ff2c175-7cd8-5b58-9b9b-0f828b130166',
          name: 'Marketplace E',
          due: 678.9,
        },
      ],
      services: {
        instantAccess: true,
        instantAdvance: true,
      },
      payabilityStatus: 'pending',
      cancellationStatus: 'pending',
    },
    {
      resultType: 'supplier',
      supplier: {
        supplierKey: 'e6ae8c95-fcfb-4637-9d4e-a0ca59e5fed3',
        legalName: '<em>Aida</em> Murillo',
        firstName: '<em>Aid</em>a',
        lastName: 'M<em>uri</em>llo',
        email: 'aidam@payability.com',
        phone: '+11234567890',
        SSN: '1234567890',
        EIN: '0987654321',
      },
      registrationDate: '2011-05-15',
      salesRep: { name: 'Luke Skywalker', email: 'jedi@payability.com' },
      dealInfo: 'deal info 1',
      marketplaces: [
        {
          marketplaceSupplierKey: '7ff2c175-7ab8-3b58-9b9b-0f828b130166',
          name: 'Marketplace D',
          due: 123.45,
        },
        {
          marketplaceSupplierKey: '7ff2c175-7cd8-5b58-9b9b-0f828b130166',
          name: 'Marketplace E',
          due: 678.9,
        },
      ],
      services: {
        instantAccess: true,
        instantAdvance: true,
      },
      payabilityStatus: 'pending',
      cancellationStatus: 'pending',
    },
    {
      resultType: 'person',
      supplier: {
        supplierKey: '7ff2c175-7ca8-4b58-9b9b-0f828b130166',
        legalName: '<em>Yarek</em> LLC',
        firstName: '<em>Jaros</em>law',
        lastName: 'Wojtas',
        email: 'jarekw@payability.com',
        phone: '+48602659049',
        SSN: '1234567890',
        EIN: '0987654321',
      },
      registrationDate: '2018-05-15',
      salesRep: {
        name: 'Johny Mnemonic',
        email: 'johnym@payability.com',
      },
      dealInfo: 'deal info 1',
      marketplaces: [
        {
          marketplaceSupplierKey: '7ff2c175-7ab8-4b58-9b9b-0f828b130166',
          name: 'Marketplace A',
          due: 123.45,
        },
        {
          marketplaceSupplierKey: '7ff2c175-7cd8-4b58-9b9b-0f828b130166',
          name: 'Marketplace B',
          due: 678.9,
        },
      ],
      services: { instantAccess: true, instantAdvance: true },
      payabilityStatus: 'pending',
      cancellationStatus: 'pending',
    },
  ],
  totalCount: 567,
};
