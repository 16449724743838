import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, finalize, map } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { ICON_CLASS } from '../../../../../shared/enums/icon-class.enum';
import { LookupRepository } from '../../../../../shared/lookup-service/lookup-repository';
import { USER_PERMISSION } from '../../../../../shared/users/user-permissions.enum';
import { SupplierOwnerModel } from '../../../../model/supplier-owner.model';
import { DocumentationService } from '../../../../services/documentation/documentation.service';

@Component({
  selector: 'app-documentation-card-header',
  templateUrl: './documentation-card-header.component.html',
})
export class DocumentationCardHeaderComponent extends SubscriberComponent {
  @Input() reportTitle = '';

  @Input() set supplierKey(key: string) {
    this._supplierKey = key;

    if (this._supplierKey && this._owner) {
      this.fetchLastReportTimestamp();
    }
  }

  @Input() set owner(o: SupplierOwnerModel) {
    this._owner = o;

    if (this._supplierKey && this._owner) {
      this.fetchLastReportTimestamp();
    }
  }

  runAu10tixButtonDisabled$: Observable<boolean> = of(true);
  lastReportTimestamp$: Observable<string>;
  loading = false;
  readonly buttonLabel = 'Run Au10tix Check';
  readonly reportSubtitle = 'Photo I.D Au10tix response';
  readonly iconClass = ICON_CLASS;

  private _supplierKey: string;
  private _owner: SupplierOwnerModel;
  private readonly reportGenerationInProgressMessage =
    'The Au10tix check is running. Please refresh the page in a few minutes to see the new report.';
  private readonly supplierDataNotInitializedMessage = 'Required data for Au10tix check are missed. Please try again later.';
  private readonly supplierDataMissingMessage = 'Unable to run the Au10tix check for this supplier. Insufficient data.';
  private readonly supplierNoDocumentsMessage = 'Unable to run the Au10tix check as supplier does not have KYC documents';
  private readonly permissionsRequiredForRunningAu10tix = [USER_PERMISSION.FINANCE, USER_PERMISSION.DEV];

  constructor(
    private documentationService: DocumentationService,
    private toastrService: ToastrService,
    private lookupService: LookupRepository,
    private permissionsService: NgxPermissionsService,
  ) {
    super();
    this.setRunAu10tixButtonDisabled();
  }

  onRunAu10tixCheckClick(): void {
    if (!this._supplierKey || !this._owner) {
      this.toastrService.error(this.supplierDataNotInitializedMessage);
      return;
    }
    this.loading = true;

    this.subscriptions.push(
      this.lookupService.getKycDocuments(this._supplierKey).subscribe((documents) => {
        if (!documents || !documents.length) {
          this.showSupplierNoDocumentsMessage();
          return;
        }
        const documentId =
          documents[0] && documents[0].satisfied_by && documents[0].satisfied_by.file ? documents[0].satisfied_by.file.file_id : null;

        if (!documentId) {
          this.showSupplierNoDocumentsMessage();
          return;
        }
        const subscription = this.documentationService
          .generateNewReport(this._supplierKey, this._owner.loginKey, documentId)
          .pipe(
            finalize(() => {
              this.loading = false;
            }),
          )
          .subscribe({
            next: () => this.toastrService.success(this.reportGenerationInProgressMessage),
            error: () => this.toastrService.error(this.supplierDataMissingMessage),
          });

        this.subscriptions.push(subscription);
      }),
    );
  }

  private showSupplierNoDocumentsMessage(): void {
    this.toastrService.error(this.supplierNoDocumentsMessage);
    this.loading = false;
  }

  private fetchLastReportTimestamp(): void {
    this.loading = true;

    this.lastReportTimestamp$ = this.documentationService.fetchLastReportTimestamp(this._supplierKey, this._owner.loginKey).pipe(
      map((timestamp) => formatDate(timestamp, 'medium', 'en-US')),
      catchError((error) => (error.status === 404 ? of('never') : throwError(error))),
      finalize(() => {
        this.loading = false;
      }),
    );
  }

  private setRunAu10tixButtonDisabled(): void {
    this.runAu10tixButtonDisabled$ = fromPromise(this.permissionsService.hasPermission(this.permissionsRequiredForRunningAu10tix)).pipe(
      map((hasOneOfRequiredPerms) => !hasOneOfRequiredPerms),
    );
  }
}
