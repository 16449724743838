import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPreview',
})
export class FormatPreviewPipe implements PipeTransform {
  transform(value: any, type: string, defaultValue: string = null): string {
    return this.formatByType(value, type, defaultValue);
  }

  private formatByType(text: string, type: string, defaultValue: string): string {
    let formattedValue;

    if (text) {
      switch (type) {
        case 'password':
          // TODO nkler: add "show password" btn
          formattedValue = text
            .split('')
            .map((passChar) => '*')
            .join('');
          break;
        default:
          formattedValue = text;
      }
    } else {
      formattedValue = defaultValue;
    }

    return formattedValue;
  }
}
