import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store/src/store';
import { Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { selectAuthToken } from '../../auth/store/auth.actions';
import { AppConfigService } from '../../config';
import { AppState } from '../../store/app.reducers';
import { EventModel } from '../models/event.model';
import { CrmMetaModel } from '../models/meta.model';
import { TaskModel } from '../models/task.model';
import { TasksAbstractService } from './tasks-abstract.service';

@Injectable()
export class TasksService extends TasksAbstractService {
  private apiUrl: string;
  private headers: { [name: string]: string } = {};

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient, private store: Store<AppState>) {
    super();

    this.apiUrl = appConfig.env.actionsToolsUrl;

    this.headers['X-InstanceId'] = '' + appConfig.env.instanceId;
    this.headers['X-EventLogger-InstanceId'] = '' + appConfig.env.instanceId;

    this.store.select(selectAuthToken).subscribe((jwtToken) => {
      this.headers['Authorization'] = jwtToken;
    });
  }

  addTask(data: TaskModel): Observable<TaskModel> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (data.targetUuid) {
      headers['X-Actions-TargetUUID'] = data.targetUuid;
    }

    return this.httpClient
      .post(this.apiUrl + 'tasks/', Serialize(data, TaskModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], TaskModel);
        }),
      );
  }

  editTask(uuid: string, data: TaskModel): Observable<TaskModel> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (data.targetUuid) {
      headers['X-Actions-TargetUUID'] = data.targetUuid;
    }

    return this.httpClient
      .put(this.apiUrl + 'tasks/' + uuid + '/', Serialize(data, TaskModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], TaskModel);
        }),
      );
  }

  getTask(uuid: string): Observable<TaskModel> {
    const headers = { ...this.headers };

    return this.httpClient
      .get(this.apiUrl + 'tasks/' + uuid + '/', {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], TaskModel);
        }),
      );
  }

  deleteTask(uuid: string, targetUuid: string): Observable<Partial<TaskModel>> {
    const headers = { ...this.headers };

    if (!isNullOrUndefined(targetUuid)) {
      headers['X-Actions-TargetUUID'] = targetUuid;
    }

    return this.httpClient.delete(`${this.apiUrl}tasks/${uuid}/`, {
      observe: 'body',
      responseType: 'json',
      headers: new HttpHeaders(headers),
    });
  }

  getEvents(uuid: string): Observable<EventModel[]> {
    return this.httpClient
      .get(`${this.apiUrl}tasks/${uuid}/events/`, {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((res) => {
          return res['data'].map((item) => {
            DeserializeKeysFrom(UnderscoreCase);
            return Deserialize(item, EventModel);
          });
        }),
      );
  }

  /** GRID FEATURES */
  listTasks(
    limit: number = 10,
    orderBy: any,
    filters: any,
    last: any,
    useAsyncPipe = false,
  ): Observable<{ data: TaskModel[]; meta: CrmMetaModel }> {
    let params = new HttpParams().set('limit', '' + limit);
    if (!isUndefined(last)) {
      params = params.set('last', JSON.stringify(last));
    }
    if (!isUndefined(orderBy)) {
      params = params.set('order_by', JSON.stringify(orderBy));
    }
    if (!isUndefined(filters)) {
      params = params.set('q', JSON.stringify(filters));
    }

    const headers = { ...this.headers };

    return this.httpClient
      .get(this.apiUrl + 'tasks/', {
        headers: new HttpHeaders(headers),
        params,
      })
      .pipe(
        handleBackendErrors(useAsyncPipe),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return {
            data: res['data'].map((item) => Deserialize(item, TaskModel)),
            meta: Deserialize(res['meta'], CrmMetaModel),
          };
        }),
      );
  }

  batchDeleteTasks(tasksIds) {
    // little hack to send delete with payload, it's not prohibited and gives us cleaner API
    return this.httpClient.request('delete', `${this.apiUrl}tasks/`, { headers: new HttpHeaders(this.headers), body: { uuids: tasksIds } });
  }

  batchUpdateTasks(tasks, data) {
    const body = tasks.reduce((acc, task) => {
      SerializeKeysTo(UnderscoreCase);
      acc[task.uuid] = Serialize(
        Object.assign(data, {
          ownerUuid: task.ownerUuid,
          createdByName: task.createdByName,
        }),
        TaskModel,
      );
      return acc;
    }, {});
    return this.httpClient.patch(`${this.apiUrl}tasks/`, body, { headers: new HttpHeaders(this.headers) });
  }
}

// TODO nkler: we should not use async pipe / subscribe on same endpoint or catchError localy
function handleBackendErrors(useAsyncPipe) {
  return catchError((error) => {
    if (useAsyncPipe) {
      return observableOf({
        data: [],
        meta: {},
      });
    } else {
      return observableThrowError(error);
    }
  });
}
