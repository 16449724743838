import { Action } from '@ngrx/store';

import { IPaymentGateway } from '../model/gateway.model';
import { MartketplaceActions, SET_POSSIBLE_GATEWAYS } from './marketplace.actions';

export interface IMarketplacesState {
  possiblePaymentGateways: IPaymentGateway[];
}

export interface IMarketplaceAction extends Action {
  payload: any;
}

export const initialState = {
  possiblePaymentGateways: [],
};

const marketplacesActionsMap = new Map();
marketplacesActionsMap.set(SET_POSSIBLE_GATEWAYS, (state: IMarketplacesState, actionPayload: IPaymentGateway[]): IMarketplacesState => {
  return {
    ...state,
    possiblePaymentGateways: actionPayload,
  };
});

export function marketplacesReducer(state: IMarketplacesState = initialState, action: MartketplaceActions) {
  let payload: any;
  const marketplacesFunction = marketplacesActionsMap.get(action.type);
  if (!marketplacesFunction) {
    return state;
  }
  if (isWithPayload(action)) {
    payload = action.payload;
  } else {
    payload = null;
  }
  return marketplacesFunction(state, payload);
}

function isWithPayload(action: any): action is IMarketplaceAction {
  return action.payload !== undefined;
}
