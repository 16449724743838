import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '../../../../config';
import { CalculatorState } from '../pending-offer/pending-offer-calculator/store/calculator-interfaces';

@Injectable()
export class SupplierAdvancesService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
  }

  createOfferRequest(supplierKey: string, data: any) {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/create_request`;

    return this.httpClient.post(endpointUrl, data);
  }

  getActiveAdvance(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/active_advance`;

    return this.httpClient.get(endpointUrl);
  }

  getAdvanceLiablity(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/advance_liability`;

    return this.httpClient.get(endpointUrl);
  }

  getAdvancesHistory(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}/supplier_advances`;

    return this.httpClient.get(endpointUrl);
  }

  getAdvanceRequest(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/advance_request`;

    return this.httpClient.get(endpointUrl);
  }

  getMlOffer(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/ml_offer`;

    return this.httpClient.get(endpointUrl).pipe(
      map((data: any) => {
        data.riskRating = this.getRiskRating(data);
        return data;
      }),
    );
  }

  getMlOfferDetails(supplierKey: string, mlOfferKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/ml_offer_details`;
    const params = new HttpParams().set('ml_offer_key', mlOfferKey);

    return this.httpClient.get(endpointUrl, { params });
  }

  getOfferDetails(requestKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/offer_details`;
    const params = new HttpParams().set('request_key', requestKey);
    return this.httpClient.get(endpointUrl, { params });
  }

  getReofferData(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/re_offer_eligibility`;

    return this.httpClient.get(endpointUrl);
  }

  getRequestsHistory(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/requests_history`;

    return this.httpClient.get(endpointUrl);
  }

  postOfferRequest(supplierKey: string, data: any) {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/update_uw_request`;

    return this.httpClient.post(endpointUrl, data);
  }

  postRequest(data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/update_offer_request`;

    return this.httpClient.post(endpointUrl, data);
  }

  postReoffer(supplierKey: string, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/re_offer`;

    return this.httpClient.post(endpointUrl, data);
  }

  getAdvanceLineHistory(supplierKey: string) {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/advance_line_history`;
    return this.httpClient.get(endpointUrl);
  }

  getActiveAdvanceLine(supplierKey: string) {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/advance_line/${supplierKey}/last`;
    return this.httpClient.get(endpointUrl);
  }

  getAdvanceLineByKey(advanceLineKey: string) {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/advance_line/${advanceLineKey}`;
    return this.httpClient.get(endpointUrl);
  }

  private getRiskRating(eligibilityData: any): Number {
    return parseInt(eligibilityData.ranking, 10) - 1;
  }

  postUnderwritingStatus(supplierKey: string, advanceOfferRequestKey: string, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${supplierKey}/manually_update_request_uw_data/${advanceOfferRequestKey}`;

    return this.httpClient.post(endpointUrl, data);
  }

  getAdvanceLineUwHistory(supplierKey: string, advanceLineKey: string) {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}/advance_line/${advanceLineKey}/uw_history`;
    return this.httpClient.get(endpointUrl);
  }

  updateAdvanceLine(supplierKey: string, advanceLineKey: string, state: CalculatorState) {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}/advance_line/${advanceLineKey}/update`;
    const dataToSave = this.prepareDataToSave(state);
    return this.httpClient.patch(endpointUrl, dataToSave);
  }

  retractOffer(proposed_advance_line_key: string) {
    const endpointUrl = `${this.apiUrl}supplier_instant_advance/${proposed_advance_line_key}/retract`;
    return this.httpClient.post(endpointUrl, { proposed_advance_line_key });
  }

  prepareDataToSave(state: CalculatorState) {
    return {
      approved_amount: +state.netDeliveryAmount,
      extra_reserve_percent: state.reserveForRepayments,
      force_full: state.forceFull,
      grace_period_length: state.gracePeriodEndDate,
      no_rebate: state.noRebate,
      return_strategy: state.returnStrategy,
      risk_rating: state.underwritingRiskRating,
      periods: state.periods,
      underwriter_notes: state.underwriterNotes,
      expected_monthly_earnings: state.expectedMonthlyEarnings,
      expected_duration: state.expectedDuration,
      uw_status: state.uwStatus,
    };
  }
}
