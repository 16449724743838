import { Directive, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { AddRecoveryComponent } from './add-recovery.component';

@Directive({
  selector: '[appOpenAddRecovery]',
})
export class OpenAddRecoveryDirective {
  modalComponent = AddRecoveryComponent;

  @HostListener('click') onClick() {
    this.openDialog();
  }

  constructor(public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(this.modalComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
