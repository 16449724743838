import { ComponentType } from '@angular/cdk/portal';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { IEnvironment } from '../../../environments/environment.interface';
import { AppConfigService } from './../../config';
import { AppsDocumentService } from './../../shared/apps-document/apps-document.service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { B2bWorkflowService } from './../../suppliers/services/workflow/b2b-workflow.service';
import { BulkNotesComponent } from './bulk-notes/bulk-notes.component';
import { CliActionService } from './cli-action/cli-action-service/cli-action.service';
import { DepositAccountsComponent } from './deposit-accounts/deposit-accounts.component';
import { PreFundedCardsComponent } from './pre-funded-cards/pre-funded-cards.component';
import { BatchImportService } from './service/batch-imports.service';
import { SupplierPromosComponent } from './supplier-promos/supplier-promos.component';
import { SupplierRebatesComponent } from './supplier-rebates/supplier-rebates.component';
import { UccUploadComponent } from './ucc-upload/ucc-upload';
import { WeeklyPurchasesComponent } from './weekly-purchases/weekly-purcheses.component';

@Component({
  selector: 'app-batch-imports',
  templateUrl: './batch-imports.component.html',
  styleUrls: ['./batch-imports.component.scss'],
})
export class BatchImportsComponent extends SubscriberComponent implements OnInit {
  efsRebatesUser: string;
  efsRebatesTime: string;
  efsRebatesProgress: number;
  efsRebatesStatus: number;
  isEfsRebatesLoading = false;

  weeklyPurchasesUser: string;
  weeklyPurchasesTime: string;
  weeklyPurchasesProgress: number;
  weeklyPurchasesStatus: number;
  isWeeklyPurchasesLoading = false;

  gciId: IEnvironment['workflowGciId'];

  readonly dialogConfig: MatDialogConfig = {
    width: '70vh',
  };

  public actions: {};

  constructor(
    public dialog: MatDialog,
    private b2bWorkflowService: B2bWorkflowService,
    private batchImportService: BatchImportService,
    appConfig: AppConfigService,
    private cliActionService: CliActionService,
    private appsDocumentService: AppsDocumentService,
  ) {
    super();
    this.gciId = appConfig.env.workflowGciId;
  }

  ngOnInit() {
    this.getActionList();
    this.getLastRebatesExecusion();
    this.subscriptions.push(
      this.b2bWorkflowService.subscribeTask('upload-efs-rebates', (data) => {
        this.efsRebatesStatus = data.status;
        this.efsRebatesProgress = data.progressCount === 0 ? 0 : this.roundProgress(data.progressStep / data.progressCount);
      }),
    );

    this.getLastWeeklyPurchasesExecusion();

    this.subscriptions.push(
      this.b2bWorkflowService.subscribeTask('weekly-receivable-purchase', (data) => {
        this.weeklyPurchasesStatus = data.status;
        this.weeklyPurchasesProgress = data.progressCount === 0 ? 0 : this.roundProgress(data.progressStep / data.progressCount);
      }),
    );
  }

  getLastRebatesExecusion() {
    this.subscriptions.push(
      this.b2bWorkflowService.getLastExecution('upload-efs-rebates').subscribe((data) => {
        const dataAny = <any>data;
        if (dataAny && dataAny.task) {
          this.efsRebatesTime = dataAny.task.startedAtStr;
          const input = JSON.parse(dataAny.task.input);
          this.efsRebatesUser = input.login_id.split('@').shift();
          this.efsRebatesProgress =
            dataAny.task.progressCount === 0 ? 0 : this.roundProgress(dataAny.task.progressStep / dataAny.task.progressCount);
          this.efsRebatesStatus = dataAny.task.status;
        }
      }),
    );
  }

  getLastWeeklyPurchasesExecusion() {
    this.subscriptions.push(
      this.b2bWorkflowService.getLastExecution('weekly-receivable-purchase').subscribe((data) => {
        const dataAny = <any>data;
        if (dataAny && dataAny.task) {
          this.weeklyPurchasesTime = dataAny.task.startedAtStr;
          const input = JSON.parse(dataAny.task.input);
          this.weeklyPurchasesUser = input.login_id.split('@').shift();
          this.weeklyPurchasesProgress =
            dataAny.task.progressCount === 0 ? 0 : this.roundProgress(dataAny.task.progressStep / dataAny.task.progressCount);
          this.weeklyPurchasesStatus = dataAny.task.status;
        }
      }),
    );
  }

  roundProgress(progress: number) {
    return Math.round(progress * 10000) / 100;
  }

  openDialog(component: ComponentType<{}> | TemplateRef<{}>): MatDialogRef<{}> {
    return this.dialog.open(component, this.dialogConfig);
  }

  openDepositAccountsDialog() {
    this.openDialog(DepositAccountsComponent);
  }

  openUccUpload() {
    this.openDialog(UccUploadComponent);
  }

  openPrefundedCardsDialog() {
    this.openDialog(PreFundedCardsComponent);
  }

  openBulkNotesDialog() {
    this.openDialog(BulkNotesComponent);
  }

  openSupplierPromosDialog() {
    this.openDialog(SupplierPromosComponent);
  }

  openEfsRebatesDialog() {
    const dialogRef = this.openDialog(SupplierRebatesComponent);

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(() => {
        this.getLastRebatesExecusion();
      }),
    );
  }

  openWeeklyPurchasesDialog() {
    const dialogRef = this.openDialog(WeeklyPurchasesComponent);

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(() => {
        this.getLastWeeklyPurchasesExecusion();
      }),
    );
  }

  downloadExample(fileName) {
    return window.open('/assets/examples/' + fileName, '_blank');
  }

  downloadLogFile(name: string) {
    this.subscriptions.push(
      this.batchImportService.getListOfActivities(name).subscribe((data) => {
        data.map((item) => {
          try {
            const result = JSON.parse(item.result.substr(item.result.indexOf('{')));
            const uuid = result.b2b_file.result_file.doc?.uuid ? result.b2b_file.result_file.doc.uuid : result.b2b_file.result_file.id;
            this.subscriptions.push(
              this.appsDocumentService.getFileDownloadUrl(this.gciId, uuid).subscribe((url) => {
                this.b2bWorkflowService.downloadFile(name, url);
              }),
            );
          } catch (err) {}
        });
      }),
    );
  }

  private getActionList() {
    this.subscriptions.push(
      this.cliActionService.getActionsList().subscribe((data) => {
        this.actions = data;
      }),
    );
    return this.actions;
  }
}
