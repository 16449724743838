import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { BackgroundReportGenerationResultDto } from '../../model/background-report/background-report-generation-result.dto';
import { BackgroundReportTaskCorrelationResponseDto } from '../../model/background-report/background-report-task-correlation-response.dto';
import { DocumentationReportSection } from '../../model/documentation/documentation-report-section.model';
import { DocumentationReportSectionData } from '../../model/documentation/documentation-report-section-data.model';
import { DocumentationReportSectionDataDto } from '../../model/documentation/documentation-report-section-data-dto.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentationService {
  private readonly reportFetchingApi = environment.s3FilesProviderApi;
  private readonly reportGenerationApi = environment.automaticMarketplaceApiUrl;
  private readonly resourceType = 'au10tix/identity_document_validation';
  private readonly processName = 'validate_identity_document';

  private readonly missingParamsForReportGenerationErrorMsg =
    'Cannot make a request to generate new report. Some of the required parameters are missing.';
  private readonly paramNames = {
    supplierKey: 'supplier_key',
    personId: 'person_id',
    firstName: 'first_name',
    lastName: 'last_name',
    ssn: 'ssn',
    sections: 'sections',
    resourceType: 'resource_type',
    processName: 'process_name',
    taskName: 'task_name',
    taskInput: 'task_input',
    documentId: 'document_id',
  };

  constructor(private httpClient: HttpClient) {}

  fetchAvailableSectionsList(supplierKey: string, personId: string): Observable<DocumentationReportSection[]> {
    const url = `${this.reportFetchingApi}sections`;

    const params = this.setReportFetchingParams(supplierKey, personId);
    return this.httpClient.get<DocumentationReportSection[]>(url, { params });
  }

  private setReportFetchingParams(supplierKey: string, personId: string, sectionKeys: string[] = []): HttpParams {
    let params: HttpParams = new HttpParams()
      .set(this.paramNames.supplierKey, supplierKey)
      .set(this.paramNames.personId, personId)
      .set(this.paramNames.resourceType, this.resourceType);

    if (sectionKeys.length > 0) {
      params = params.set(this.paramNames.sections, sectionKeys.join(','));
    }

    return params;
  }

  fetchSection(section: DocumentationReportSection, supplierKey: string, personId: string): Observable<DocumentationReportSectionData> {
    const url = `${this.reportFetchingApi}resources`;
    const params = this.setReportFetchingParams(supplierKey, personId, [section.value]);
    return this.httpClient
      .get<DocumentationReportSectionDataDto>(url, { params })
      .pipe(map((res) => this.mapSectionDataResponse(res, section)));
  }

  fetchSections(
    sections: DocumentationReportSection[],
    supplierKey: string,
    personId: string,
  ): Observable<DocumentationReportSectionData[]> {
    if (!sections || !sections.length || !supplierKey || !personId) {
      return of([]);
    }
    const url = `${this.reportFetchingApi}resources`;
    const sectionKeys = sections.map((section) => section.value);
    const params = this.setReportFetchingParams(supplierKey, personId, sectionKeys);
    return this.httpClient.get<DocumentationReportSectionDataDto>(url, { params }).pipe(
      map((res) => this.mapSectionsDataResponse(res, sections)),
      catchError(() => of([])),
    );
  }

  private mapSectionsDataResponse(
    response: DocumentationReportSectionDataDto,
    sections: DocumentationReportSection[],
  ): DocumentationReportSectionData[] {
    if (!sections || response.result) {
      return [];
    }
    return sections.map((section) => {
      return {
        value: section.value,
        name: section.name,
        data: response[section.value],
      };
    });
  }

  private mapSectionDataResponse(
    response: DocumentationReportSectionDataDto,
    section: DocumentationReportSection,
  ): DocumentationReportSectionData {
    return {
      value: section.value,
      name: section.name,
      data: response[section.value],
    };
  }

  generateNewReport(supplierKey: string, personId: string, documentId: string): Observable<BackgroundReportTaskCorrelationResponseDto> {
    if (![supplierKey, personId, documentId].every((v) => !!v)) {
      return throwError(new TypeError(this.missingParamsForReportGenerationErrorMsg));
    }

    const url = `${this.reportGenerationApi}tasks/execute`;

    const requestBody = {
      [this.paramNames.taskName]: this.processName,
      [this.paramNames.taskInput]: {
        [this.paramNames.supplierKey]: supplierKey,
        [this.paramNames.personId]: personId,
        [this.paramNames.documentId]: documentId,
      },
    };
    return this.httpClient.post<BackgroundReportTaskCorrelationResponseDto>(url, requestBody);
  }

  fetchLastReportTimestamp(supplierKey: string, personId: string): Observable<string> {
    const url = `${this.reportGenerationApi}tasks/result`;

    const params: HttpParams = new HttpParams()
      .set(this.paramNames.supplierKey, supplierKey)
      .set(this.paramNames.personId, personId)
      .set(this.paramNames.processName, this.processName);

    return this.httpClient.get<BackgroundReportGenerationResultDto>(url, { params }).pipe(map((res) => res.timestamp));
  }
}
