import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

import { SubscriberComponent } from './../../component-subscriber/subscriber.component';
import { LongTermContractsService } from './../../services/long-term-contracts/long-term-contracts.service';

@Component({
  selector: 'app-ag-currency-cell',
  templateUrl: './active-contracts-status-cell.component.html',
  styleUrls: ['./active-contracts-status-cell.component.scss'],
})
export class ActiveContractsStatusCellComponent extends SubscriberComponent implements ICellRendererAngularComp {
  constructor(private longTermContractsService: LongTermContractsService) {
    super();
  }

  params: any;

  onCellValueChange() {
    if (this.params.value === 'voided') {
      this.subscriptions.push(this.longTermContractsService.setContractStatus(this.params.data.contractKey).subscribe((response) => {}));
    }
  }

  refresh(params: any): boolean {
    if (params.value !== this.params.value) {
      this.params = params;
    }
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  agInit(params: any): void {
    this.params = params;
  }
}
