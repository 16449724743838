import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { IExternalReport } from '../../../../reports/model/i-external-report';
import { ExternalReportsService } from '../../../../reports/service/external-reports.service';
import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-categorization',
  templateUrl: './categorization.component.html',
})
export class CategorizationComponent extends SubscriberComponent implements OnInit {
  url: string;
  supplierKey: string;

  constructor(
    private readonly externalReportsService: ExternalReportsService,
    private readonly sanitizer: DomSanitizer,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
          mergeMap(() => this.externalReportsService.getDetail('SC-AMZ')),
        )
        .subscribe((response: IExternalReport[]) => {
          const externalReport: IExternalReport = response[0];
          if (externalReport) {
            const customParamUrl = externalReport.customParamUrl;
            this.url = customParamUrl.replace('<supplier_key>', this.supplierKey);
          }
        }),
    );
  }
}
