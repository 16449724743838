import { AbstractControl } from '@angular/forms';

export class PasswordMatchValidator {
  static MatchPassword(abstractControl: AbstractControl) {
    // TODO nkler: extend validator to get form fields names as attributes

    const password = abstractControl.get('password').value;
    const retypePassword = abstractControl.get('retypePassword').value;

    if (password !== retypePassword) {
      abstractControl.get('retypePassword').setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }
}
