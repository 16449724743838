import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-instant-payouts',
  templateUrl: './instant-payouts.component.html',
  styleUrls: ['./instant-payouts.component.scss'],
})
export class InstantPayoutsComponent implements OnInit {
  @Input() dataProducts: any;

  public displayedInstantPayoutsColumns = ['marketplace', 'uwStatus'];
  public dataSourceIPayouts: MatTableDataSource<any>;

  constructor() {}

  ngOnInit() {
    const source = [];
    for (const key in this.dataProducts.instant_payouts) {
      if (this.dataProducts.instant_payouts.hasOwnProperty(key)) {
        const mp = this.dataProducts.instant_payouts[key];
        source.push({
          marketplace: key,
          uwStatus: mp.uw_status,
        });
      }
    }
    this.dataSourceIPayouts = new MatTableDataSource(source);
  }
}
