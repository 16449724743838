<td>
  <span
    ngClass="{
      status: true,
      'status-verified': plaidRow.accountMatched,
      'status-rejected': !plaidRow.accountMatched
    }"
  >
    {{ (plaidRow.accountMatched ? 'verified' : 'rejected') | capitalize }}
  </span>
</td>
<td class="plaidData__institution">{{ plaidRow.institution }}</td>
<td class="plaidData__accountName">{{ plaidRow.accountName }}</td>
<td class="plaidData__accountBalance">{{ plaidRow.balance | currency: 'USD' }}</td>
<td>
  <mat-checkbox
    [checked]="plaidRow.receivingPayments"
    [disabled]="isNullOrUndefined(plaidRow.achContract)"
    (click)="receivePayments()"
  ></mat-checkbox>
</td>
<td>
  <mat-checkbox [checked]="plaidRow.achContract" [disabled]="isNullOrUndefined(plaidRow.achContract)" (click)="addToAch()"></mat-checkbox>
</td>
<td *ngIf="indexOfRow === 0" [rowSpan]="rowsCount">
  <mat-checkbox [checked]="itemLoginRequired" (click)="toggleItemLoginRequired()"></mat-checkbox>
</td>
