export class SupplierMarketplaceFormTypes {
  static readonly FUNDING_FORM = 'fundingForm';
  static readonly REVENUE_FORM = 'revenueForm';
  static readonly PAYMENT_FORM = 'paymentForm';
  static readonly INSTANT_FORM = 'instantForm';
  static readonly FEE_SETTINGS_FORM = 'feeSettingsForm';
  static readonly FEE_DATES_FORM = 'feeDatesForm';
  static readonly API_STATUS_FORM = 'apiStatusForm';
  static readonly MARKETPLACE_ACCESS_FORM = 'marketplaceAccessForm';
  static readonly AUTH_TOKEN_FORM = 'authTokenForm';
  static readonly PAYMENT_PROCESSOR_SETTINGS = 'paymentProcessorSettings';
}
