<div class="card" *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
  <div class="card-content">
    <app-underwriting-initial-card-header
      [chipText]="'Automated'"
      [chipColor]="'accent'"
      [title]="'Personal Background Check'"
      [status]="headerStatus"
      [type]="headerType"
    >
    </app-underwriting-initial-card-header>
    <div class="row mb-2">
      <div class="col-6">
        <app-spinner-button
          *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]"
          [disabled]="!transUnionActionStatusKey"
          (onClick)="getTransUnionData()"
          [loading]="transUnionDataLoading"
          [customWidth]="'12.7rem'"
          [text]="'Run Background Check'"
        >
        </app-spinner-button>
        <p *ngIf="transUnion && transUnion.results && transUnion.results.timestamp; else noTUdone">
          Last checked: {{ transUnion.results.timestamp | date: "MM/dd/yyyy 'at' h:mma" }}
        </p>
        <ng-template #noTUdone>
          <p>Last checked: never</p>
        </ng-template>
      </div>
      <div class="col-6">
        <button
          mat-raised-button
          color="primary"
          (click)="openBackgroundReport()"
          *ngIf="transUnion && transUnion.results && transUnion.results.person"
          class="alignmentButton"
        >
          See Background Report
        </button>
        <p *ngIf="transUnion && transUnion.results && transUnion.results.person">
          Records found: {{ transUnion.results.person.data.NumberOfRecordsFound }}
        </p>
      </div>
    </div>
    <div class="table-wrapper">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="20%" class="text-right">Target</th>
            <th width="20%" class="text-right">Value</th>
            <th width="10%" class="text-right">Automated</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of personalBgCheck; let $index = index">
            <td>{{ item?.text }}</td>
            <td class="text-right">
              {{ item?.target }}
            </td>
            <td class="text-right">
              {{ item?.value }}
            </td>
            <td class="text-right" width="20%">
              <span *ngIf="item?.automated" class="text-success"> Valid </span>
              <span *ngIf="!item?.automated" class="text-danger"> Needs review </span>
            </td>
          </tr>
          <tr *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
            <td colspan="3">Override Personal Background Check results</td>
            <td colspan="2" class="text-right" width="20%">
              <ng-container *ngIf="!computingDataIsInProgress">
                <app-radiobuttons
                  [value]="transUnionOverride[0].value"
                  [labelPosition]="'before'"
                  [options]="radiobuttons"
                  (onChange)="radiobuttonsChange($event)"
                >
                </app-radiobuttons>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
