import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EditorModule as TinyMceModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { LoadingSpinnerModule } from './../loading-spinner/loading-spinner.module';
import { EditorComponent } from './editor.component';

@NgModule({
  imports: [CommonModule, TinyMceModule, ReactiveFormsModule, LoadingSpinnerModule],
  declarations: [EditorComponent],
  exports: [EditorComponent],
  providers: [{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
})
export class EditorModule {}
