import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { isNullOrUndefined, isObject, isString, isUndefined } from 'util';

import { PermissionModel } from '../../../../shared/users/permission.model';
import { UsersService } from '../../../../shared/users/service/users.service';
import { UserModel } from '../../../../shared/users/user.model';
import { taskPriorities } from '../../task-priorities';
import { taskStatuses } from '../../task-statuses';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-task-grid-filters',
  templateUrl: './task-grid-filters.component.html',
  styleUrls: ['./task-grid-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskGridFiltersComponent extends SubscriberComponent implements OnInit {
  @Output()
  readonly filtersChange = new EventEmitter<any>();
  @Input()
  assigneeOptions: (UserModel | PermissionModel)[];

  availableStatuses: Observable<any[]>;
  availablePriorities: Observable<any[]>;

  availableAuthors: (UserModel | PermissionModel)[] = [];
  filteredAuthors: Observable<UserModel[]>;

  availableAssignees: (UserModel | PermissionModel)[] = [];
  filteredAssignees: Observable<(UserModel | PermissionModel)[]>;

  filters: FormGroup;

  taskId = new FormControl();
  assignee = new FormControl();
  author = new FormControl();
  createdAfter = new FormControl();
  createdBefore = new FormControl();

  private _filterSetData: any;
  private _filterSetDataChange$: Subject<number> = new Subject<number>();

  constructor(private formBuilder: FormBuilder, private usersService: UsersService) {
    super();
  }

  @Input()
  set filterSetData(filters: any) {
    this._filterSetData = filters;
    this._filterSetDataChange$.next(this._filterSetData);
  }

  get filterSetData(): any {
    return this._filterSetData;
  }

  createForm(): void {
    this.filters = this.formBuilder.group({
      status: [''],
      taskId: [''],
      priority: [''],
      author: [''],
      assignee: [''],
      createdAfter: [''],
      createdBefore: [''],
    });

    this.subscriptions.push(
      this._filterSetDataChange$.subscribe(() => {
        this.transformFilterSetToFields();
      }),
    );

    this.subscriptions.push(this.filters.get('createdAfter').valueChanges.subscribe((value) => this.createdAfter.setValue(value)));
    this.subscriptions.push(this.filters.get('createdBefore').valueChanges.subscribe((value) => this.createdBefore.setValue(value)));
    this.subscriptions.push(
      this.filters.get('author').valueChanges.subscribe((ownerUuid) => {
        this.author.reset();

        if (isString(ownerUuid) && ownerUuid) {
          const founded = this.availableAuthors.find((authorModel) => {
            return authorModel.key === ownerUuid;
          });

          if (founded) {
            this.author.setValue(founded);
          }
        }
      }),
    );
    this.subscriptions.push(
      this.filters.get('assignee').valueChanges.subscribe((assigneeData) => {
        this.assignee.reset();
        if (assigneeData && !isUndefined(assigneeData.key)) {
          const founded = this.availableAssignees.find((assigneeModel) => {
            return assigneeModel.key === assigneeData.key;
          });

          if (founded) {
            this.assignee.setValue(founded);
          }
        }
      }),
    );
  }

  transformFilterSetToFields(): void {
    if (!this.filterSetData) {
      return;
    }

    this.filters.reset();
    Object.keys(this.filterSetData).map((filterKey) => {
      let filterField = this.filters.get(filterKey);

      // FIXME nkler: this should not be treated like an exception, needs to have two way transformer
      if ('owner_uuid' === filterKey) {
        filterField = this.filters.get('author');
      }

      if (filterField) {
        filterField.setValue(this.filterSetData[filterKey]);
      }
    });
  }

  ngOnInit(): void {
    this.availableStatuses = of(taskStatuses);
    this.availablePriorities = of(taskPriorities);

    const users$ = this.usersService.users.pipe(filter((data) => !!data));
    const usersAndGroups$ = this.usersService.getPreparedCombinedList();
    this.subscriptions.push(
      combineLatest([users$, usersAndGroups$]).subscribe((results) => {
        this.availableAuthors = results[0];
        this.availableAssignees = results[1];

        this.createForm();

        this.filteredAuthors = this.author.valueChanges.pipe(
          startWith(''),
          map((author) => (author ? this.filterUsers(author, this.availableAuthors) : this.availableAuthors.slice())),
        );
        this.filteredAssignees = this.assignee.valueChanges.pipe(
          startWith(''),
          map((assignee) => (assignee ? this.filterUsers(assignee, this.availableAssignees) : this.availableAssignees.slice())),
        );

        this.subscriptions.push(
          this.filters.valueChanges.subscribe((filters) => {
            this.filtersChange.next(filters);
          }),
        );

        this.subscriptions.push(
          this.taskId.valueChanges.pipe(debounceTime(1000), startWith('')).subscribe((value: string) => {
            this.filters.patchValue({
              taskId: value,
            });
          }),
        );

        this._filterSetDataChange$.next(this._filterSetData);
      }),
    );
  }

  getStatusName(statusKey): void {
    const foundItem = taskStatuses.find((item) => item.key === statusKey);

    if (foundItem) {
      return foundItem.name;
    }
  }

  clearValue(fieldKey, autoCompleteField): void {
    const fieldToReset = this.filters.get(fieldKey);
    if (fieldToReset) {
      fieldToReset.reset();
    }

    autoCompleteField.reset();
  }

  setAuthor($event): void {
    const author = $event.option.value;
    this.filters.patchValue({
      author: author.loginKey,
    });
  }

  setAssignee($event): void {
    const assignee = $event.option.value;

    this.filters.patchValue({
      assignee: {
        key: assignee.key,
        type: assignee.type,
      },
    });
  }

  displayAssigneeFn(user: UserModel): string {
    return !isNullOrUndefined(user) ? (typeof user === 'object' ? user.name : user) : '';
  }

  setDate(type: string, event: MatDatepickerInputEvent<Date>): void {
    const newValues = {};
    newValues[type] = event.value;

    this.filters.patchValue(newValues);
  }

  clearDate(type: string, dateField): void {
    const newValues = {};
    newValues[type] = undefined;

    this.filters.patchValue(newValues);
    dateField.reset();
  }

  private filterUsers(name: any, availableItems): Array<any> {
    const userName = isObject(name) ? name.name : name;

    return availableItems.filter((authorModel) => {
      if (authorModel.type === 'user') {
        return authorModel.name.toLowerCase().indexOf(userName.toLowerCase()) === 0;
      } else {
        return authorModel.name.toLowerCase().indexOf(userName.toLowerCase()) === 0;
      }
    });
  }
}
