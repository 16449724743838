import { autoserializeAs, inheritSerialization } from 'cerialize';

import { BaseCrmModel } from './base-crm.model';
import { FileDataModel } from './file-data.model';

@inheritSerialization(BaseCrmModel)
export class FileModel extends BaseCrmModel {
  @autoserializeAs(FileDataModel) public data: FileDataModel;

  constructor(
    createdAt: string,
    ownerUuid: string,
    targetType: string,
    targetUuid: string,
    type: string,
    uuid: string,
    updatedAt: string,
    data: FileDataModel,
  ) {
    super(createdAt, ownerUuid, targetType, targetUuid, type, uuid, updatedAt);
    this.data = data;
  }
}
