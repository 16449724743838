import { NgIf } from '@angular/common';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { FEATURE_FLAGS } from './feature-flags.const';
import { FeatureFlagsService } from './feature-flags.service';

@Directive({ selector: '[appEnabledByFeatureFlag]' })
export class EnabledByFeatureFlagDirective {
  @Input() set appEnabledByFeatureFlag(featureName: FEATURE_FLAGS) {
    this.setNgIf(featureName);
  }

  private ngIfDirective: NgIf;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
    this.ngIfDirective.ngIf = false;
  }

  private setNgIf(featureName: FEATURE_FLAGS): void {
    this.ngIfDirective.ngIf = this.featureFlagsService.isEnabled(featureName);
  }
}
