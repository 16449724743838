import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { UsersService } from '../shared/users/service/users.service';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';

@Injectable()
export class SubPermissionsGuard implements CanActivate, CanLoad {
  constructor(private readonly userService: UsersService, private readonly router: Router, private readonly toast: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkUrl(route);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkUrl(route);
  }

  private checkUrl(route: ActivatedRouteSnapshot | Route): Observable<boolean> {
    if (!route.data || !route.data.requireOneOfSubPermissions || !route.data.requireOneOfSubPermissions.length) {
      this.handleUnauthorized();
      return of(false);
    }

    return this.hasOneOfSubPermissions(route.data.requireOneOfSubPermissions).pipe(
      tap((isAllowed) => {
        if (!isAllowed) {
          this.handleUnauthorized();
        }
      }),
    );
  }

  private hasOneOfSubPermissions(subPerms: USER_PERMISSION[]): Observable<boolean> {
    const hasSubPerms$ = subPerms.map((subPerm) => this.userService.checkPermission(subPerm));

    return combineLatest(hasSubPerms$).pipe(
      take(1),
      map((hasSubPerms) => hasSubPerms.some((hasSubPerm) => hasSubPerm)),
    );
  }

  private handleUnauthorized(): void {
    this.toast.error('Access denied.');
    this.router.navigate(['/']);
  }
}
