import { Component } from '@angular/core';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FEATURE_FLAGS } from '../../../shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';

interface Tab {
  route: string;
  label: string;
  mustHavePerm?: string;
  cantHavePerm?: string;
  enabledByFeatureFlag?: FEATURE_FLAGS;
}

@Component({
  selector: 'app-supplier-underwriting',
  templateUrl: './supplier-underwriting.component.html',
})
export class SupplierUnderwritingComponent {
  private static readonly ALL_TABS: Tab[] = [
    {
      route: 'initial',
      label: 'Initial',
    },
    {
      route: 'background-reports',
      label: 'Background Reporting',
      mustHavePerm: USER_PERMISSION.FINANCE,
      cantHavePerm: USER_PERMISSION.REST_UW_ACCESS,
    },
    {
      route: 'daily',
      label: 'Daily',
      cantHavePerm: USER_PERMISSION.REST_UW_ACCESS,
    },
    {
      route: 'ucc',
      label: 'UCC',
      cantHavePerm: USER_PERMISSION.REST_UW_ACCESS,
    },
    {
      route: 'supplier-information',
      label: 'Supplier Information',
    },
    {
      route: 'documentation',
      label: 'Documentation',
      cantHavePerm: USER_PERMISSION.REST_UW_ACCESS,
    },
    {
      route: 'bank-transactions-report',
      label: 'Bank Transactions Report',
      cantHavePerm: USER_PERMISSION.REST_UW_ACCESS,
    },
    {
      route: 'bank-transactions-categories',
      label: 'Bank Transactions Categories',
      enabledByFeatureFlag: FEATURE_FLAGS.BANK_CATEGORIES,
      cantHavePerm: USER_PERMISSION.REST_UW_ACCESS,
    },
    {
      route: 'access-onboarding-predictions',
      label: 'Access On-boarding Predictions',
      enabledByFeatureFlag: FEATURE_FLAGS.ACCESS_ONBOARDING_PREDICTIONS,
      cantHavePerm: USER_PERMISSION.REST_UW_ACCESS,
    },
    {
      route: 'access-daily-predictions',
      label: 'Access Daily Predictions',
      enabledByFeatureFlag: FEATURE_FLAGS.ACCESS_DAILY_PREDICTIONS,
      cantHavePerm: USER_PERMISSION.REST_UW_ACCESS,
    },
  ];

  public tabs$: Observable<Tab[]>;

  constructor(private permissionsService: NgxPermissionsService, private featureFlagsService: FeatureFlagsService) {
    this.tabs$ = this.permissionsService.permissions$.pipe(
      map((perms) => this.filterTabsByPerms(SupplierUnderwritingComponent.ALL_TABS, perms)),
    );
  }

  private filterTabsByPerms(tabs: Tab[], perms: NgxPermissionsObject): Tab[] {
    return tabs
      .filter((tab) => (tab.enabledByFeatureFlag ? this.featureFlagsService.isEnabled(tab.enabledByFeatureFlag) : true))
      .filter((tab) => (tab.mustHavePerm ? perms[tab.mustHavePerm] !== undefined : true))
      .filter((tab) => (tab.cantHavePerm ? perms[tab.cantHavePerm] === undefined : true));
  }
}
