<section class="container-fluid flex-container">
  <div class="header box box-default">
    <div class="navigation">
      <a [routerLink]="['/processes/review']" title="Go to processes review supplier list" class="close-button">
        <i class="material-icons">arrow_back</i>
      </a>
    </div>
    <div class="info">
      <h6>{{ data?.results?.results[0]?.supplier_name }}</h6>
    </div>
    <div class="actions">
      <button mat-raised-button [color]="'accent'">Refresh View</button>
      <button mat-raised-button [color]="'primary'">Add Payments</button>
      <button mat-raised-button [color]="'primary'">Update</button>
      <button mat-raised-button [color]="'primary'">Approve</button>
      <button mat-raised-button [color]="'accent'">Edit</button>
    </div>
  </div>
</section>
