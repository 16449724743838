import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { KeyValue } from './../models/key-value';

const zeroPad = (num, places) => String(num).padStart(places, '0');

@Component({
  selector: 'app-time-select-picker',
  templateUrl: './time-select-picker.component.html',
})
export class TimeSelectComponent implements OnInit {
  @Input() set value(val: string) {
    this.val = val;
  }
  @Input() last = false;
  @Input() quantum = 15;

  @Output() changedTime = new EventEmitter<string>();
  @Output() removedTime = new EventEmitter<void>();

  hours: KeyValue<string>[] = [];
  val = null;

  ngOnInit(): void {
    this.hours = this.generateHours(this.quantum);
  }

  onChangeTime(): void {
    this.changedTime.next(this.val);
  }

  onRemoveTime(e: PointerEvent): void {
    e.preventDefault();
    e.stopPropagation();

    this.removedTime.next();
  }

  private generateHours(quantum = 15): KeyValue<string>[] {
    const hours = [];
    const numQinH = 60 / quantum;
    const meridiem = ['AM', 'PM'];

    for (let i = 0; i < 24; ++i) {
      for (let j = 0; j < numQinH; ++j) {
        const time12 = zeroPad(i % 12, 2) + ':' + (j * quantum || '00');
        const time24 = zeroPad(i, 2) + ':' + (j * quantum || '00');
        hours.push({ name: time12 + ' ' + meridiem[(i / 12) | 0], value: time24 });
      }
    }

    return hours;
  }
}
