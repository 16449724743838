import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

import { AppConfigService } from '../../config';
import { SupplierDetailsModel } from '../model/supplier.model';

@Injectable()
export class SupplierReferralService {
  private static slugify(input: string): string {
    let slug = '';

    if (!isNullOrUndefined(input)) {
      // make lower case and trim
      slug = input.toLowerCase().trim();

      // replace invalid chars with spaces
      slug = slug.replace(/[^a-z0-9\s-]/g, ' ');

      // replace multiple spaces or hyphens
      slug = slug.replace(/[\s-]+/g, '');
    }

    return slug;
  }

  constructor(public appConfig: AppConfigService) {}

  generateLink(supplierModel: SupplierDetailsModel): string {
    const sanitizedNameLength = 10;
    const keyPartLength = 5;

    if (this.appConfig.env.supplierReferralUrl === undefined) {
      throw new Error('Referral domain URL not set.');
    }
    const slugifiedName = SupplierReferralService.slugify(supplierModel ? supplierModel.legalName : '');

    if (slugifiedName.length > 0) {
      const shortName = slugifiedName.substring(0, sanitizedNameLength) + supplierModel.supplierKey.substring(0, keyPartLength);

      return this.appConfig.env.supplierReferralUrl + shortName;
    }

    return '';
  }
}
