import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-supplier-business-details',
  templateUrl: './supplier-business-details.component.html',
  styleUrls: ['./supplier-business-details.component.scss'],
})
export class SupplierBusinessDetailsComponent {
  @Input() formCheckboxesLastBox: FormGroup;
}
