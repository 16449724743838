import { Injectable } from '@angular/core';

import { HeaderTemplateRendererService } from './../data-table-ag-grid-header-template-renderer.service';

@Injectable({
  providedIn: 'root',
})
export class HeadersWithSummedValuesSettingService {
  formattedHeaders: any;

  constructor(private headerTemplateRendererService: HeaderTemplateRendererService) {}

  formatHeaders(summedHeadersValues, columnApi, columnDefs) {
    this.formattedHeaders = columnDefs;
    Object.keys(summedHeadersValues).forEach((key) => {
      if (columnApi.getAllColumns()) {
        if (columnApi.getColumn(key)) {
          const oldDef = columnApi.getColumn(key).getColDef();
          if (oldDef) {
            const total = {
              value: summedHeadersValues[key] ? summedHeadersValues[key] : '',
            };
            const newFields = {
              headerComponentParams: {
                total: summedHeadersValues[key],
                template: this.headerTemplateRendererService.createTemplate(total),
              },
            };
            const newColDef = { ...oldDef, ...newFields };
            this.formattedHeaders.forEach((e, i) => {
              if (e.field === key) {
                this.formattedHeaders[i] = newColDef;
              }
            });
          }
        }
      }
    });
  }
}
