<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="navigation">
          <a [routerLink]="['/manage/fee-settings']" title="Go to Fee Settings" class="close-button">
            <i class="material-icons">arrow_back</i>
          </a>
        </div>
        <div class="title">Edit config</div>
      </div>

      <form class="custom-form" name="edit_form" [formGroup]="editForm">
        <div class="form-content">
          <fieldset class="form-section">
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Fee Category" formControlName="category" />
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Fee Schedule Type" formControlName="scheduleType" />
            </mat-form-field>
            <mat-form-field class="field-text form-control-feedback">
              <input matInput type="text" placeholder="Fee Schedule Name" formControlName="scheduleName" />
              <mat-error>
                <span *ngIf="!editForm.get('scheduleName').valid">Fee Schedule Name is required</span>
              </mat-error>
            </mat-form-field>
          </fieldset>
        </div>
      </form>

      <div class="card-header">
        <div class="title">Fee Details</div>
        <div class="actions">
          <button mat-raised-button [color]="'accent'" (click)="addTier()" [disabled]="!isEditable">
            <mat-icon>add</mat-icon>
            Add Tier
          </button>
        </div>
      </div>

      <div class="settings-table mat-table">
        <mat-table *ngIf="dataSource" [dataSource]="dataSource">
          <ng-container matColumnDef="paymentTypeCode">
            <mat-header-cell *matHeaderCellDef class="table-fields">Fee Type Code</mat-header-cell>
            <mat-cell *matCellDef="let element" class="table-fields">
              <mat-form-field>
                <mat-select [(ngModel)]="element.paymentTypeCode">
                  <mat-option *ngFor="let item of feeScheduleData[0].paymentTypes" [value]="item.key">
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="pmtBatchFeeName">
            <mat-header-cell *matHeaderCellDef class="table-fields">Fee Delivery</mat-header-cell>
            <mat-cell *matCellDef="let element" class="table-fields">
              <mat-form-field>
                <mat-select [(ngModel)]="element.pmtBatchFeeName">
                  <mat-option
                    *ngFor="let item of feeScheduleData[0].deliveryTypes"
                    [value]="item.value"
                    (click)="setDeliveryKey(element, item)"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tierStart">
            <mat-header-cell *matHeaderCellDef class="table-fields">Start</mat-header-cell>
            <mat-cell *matCellDef="let element" class="table-fields">
              <mat-form-field floatLabel="never">
                <input matInput [value]="element.tierStart" type="number" [(ngModel)]="element.tierStart" [disabled]="!isEditable" />
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tierEnd">
            <mat-header-cell *matHeaderCellDef class="table-fields">End</mat-header-cell>
            <mat-cell *matCellDef="let element" class="table-fields">
              <mat-form-field floatLabel="never">
                <input matInput [value]="element.tierEnd" type="number" [(ngModel)]="element.tierEnd" [disabled]="!isEditable" />
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="feePercentage">
            <mat-header-cell *matHeaderCellDef class="table-fields">Percentage Fee</mat-header-cell>
            <mat-cell *matCellDef="let element" class="table-fields">
              <mat-form-field floatLabel="never">
                <input
                  matInput
                  [value]="element.feePercent"
                  type="number"
                  [(ngModel)]="element.feePercent"
                  [disabled]="!isEditable"
                  required
                />
                <mat-error>
                  <span *ngIf="!element.feePercent">Percentage Fee is required</span>
                </mat-error>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fixedFee">
            <mat-header-cell *matHeaderCellDef class="table-fields">Fixed Fee</mat-header-cell>
            <mat-cell *matCellDef="let element" class="table-fields">
              <mat-form-field floatLabel="never">
                <input matInput [value]="element.feeFixed" type="number" [(ngModel)]="element.feeFixed" [disabled]="!isEditable" />
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="minPmtFee">
            <mat-header-cell *matHeaderCellDef class="table-fields">Minimum Payment Fee</mat-header-cell>
            <mat-cell *matCellDef="let element" class="table-fields">
              <mat-form-field floatLabel="never">
                <input
                  matInput
                  [value]="element.minPmtFee"
                  type="number"
                  [(ngModel)]="element.minPmtFee"
                  [disabled]="category === 'PAYMENT' ? !isEditable : true"
                />
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="table-fields">Action</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index" class="table-fields">
              <button mat-raised-button [color]="'warn'" (click)="removeTier(i)" [disabled]="!isEditable">Delete</button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
      <div class="form-submit">
        <button mat-raised-button class="btn btn-cancel pull-right" color="warn" (click)="onCancel()">Cancel</button>
        <button
          mat-raised-button
          class="btn btn-success pull-right"
          (click)="submit(dataSource.data)"
          color="accent"
          [disabled]="!editForm.valid || !areTiersValid()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</section>
