import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
})
export class ChipComponent {
  @Input() text: string;
  @Input() selected = true;
  @Input() chipColor = 'primary';
}
