import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../config';
import { IDiscount } from '../../interfaces/discount';
@Injectable({
  providedIn: 'root',
})
export class LongTermContractsService {
  private apiUrl: string;

  constructor(private httpClient: HttpClient, appConfig: AppConfigService) {
    this.apiUrl = `${appConfig.env.internalApiUrl}long_term_contract`;
  }

  getExistingContractForAddingDefaultContract(marketplaceKey: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/template/${marketplaceKey}/standard`);
  }

  getExistingContracts(): Observable<any> {
    const endpoint = `${this.apiUrl}/template`;
    return this.httpClient.get(endpoint);
  }

  setDefaultContract(templateGroupKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}/template/${templateGroupKey}`;
    return this.httpClient.patch(endpoint, {
      isDefault: true,
    });
  }

  getContractsForSupplier(supplierKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}/${supplierKey}`;
    return this.httpClient.get(endpoint);
  }

  getHistoricalContractsForSupplier(supplierKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}/${supplierKey}/history`;
    return this.httpClient.get(endpoint);
  }

  setContractStatus(contractKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}/${contractKey}`;
    return this.httpClient.patch(endpoint, { status: 'voided' });
  }

  pushContractToDashboard(marketplaceSupplierKey: string, groupTemplateKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}/${marketplaceSupplierKey}/create`;
    return this.httpClient.post(endpoint, { groupTemplateKey });
  }

  createDefaultTemplate(marketplaceKey: string, discounts: IDiscount[]): Observable<any> {
    const endpoint = `${this.apiUrl}/template/${marketplaceKey}/create`;
    return this.httpClient.post(endpoint, {
      type: 'standard',
      isDefault: true,
      contractTemplates: discounts,
    });
  }

  getActiveContract(marketplaceSupplierKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}/${marketplaceSupplierKey}/active`;
    return this.httpClient.get(endpoint);
  }
}
