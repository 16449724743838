import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../store/app.reducers';
import { SupplierMarketplaceListModel } from '../../../model/supplier-marketplace.model';
import { selectSupplierMarketplaces } from '../../../store/supplier/supplier.actions';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from '../supplier-onboarding.service';

@Component({
  selector: 'app-onboarding-process-modal',
  templateUrl: './onboarding-process-modal.component.html',
})
export class OnboardingProcessModalComponent implements OnInit {
  form: FormGroup;
  selectedMp;
  supplierMarketplacesState$;
  supplierMarketplacesState;
  marketPlaces: FormArray = new FormArray([]);

  constructor(
    public dialogRef: MatDialogRef<OnboardingProcessModalComponent>,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
  ) {
    this.setMarketplaces();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      skipScraping: new FormControl(false),
      sendEmail: new FormControl(false),
      marketPlaces: this.marketPlaces,
    });
  }

  prepareFormArray(marketPlaces) {
    for (const marketPlace of marketPlaces) {
      const mpForm = this.formBuilder.group({
        isChecked: [!marketPlace.isNotApproved],
        data: [marketPlace],
      });

      this.marketPlaces.push(mpForm);
    }
  }

  private getFormControl = (val: any) => new FormControl(val);

  private setMarketplaces() {
    this.supplierMarketplacesState$ = this.store.select(selectSupplierMarketplaces);

    this.supplierMarketplacesState$.subscribe((data) => {
      this.supplierMarketplacesState = data;
      if (data && data[0]) {
        this.prepareFormArray(data);
        this.selectedMp = data.filter((mp: SupplierMarketplaceListModel) => mp.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY)[0];
      }
    });
  }

  private getPreparedData() {
    const mpArray = this.marketPlaces.value;
    const selectedMp = [];

    for (let i = 0; i < mpArray.length; i++) {
      if (mpArray[i].isChecked) {
        selectedMp.push(mpArray[i].data.mpSupKey);
      }
    }

    return selectedMp;
  }

  close() {
    const data = this.form.value;
    data['mpKeys'] = this.getPreparedData();
    data['sendEmail'] = data.sendEmail ? data.sendEmail : false;

    this.dialogRef.close(data);
  }
}
