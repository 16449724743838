import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { LookupAbstractService } from '../../../shared/lookup-service/lookup-abstract.service';
import { FeeSettingsService } from '../service/fee-settings.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { FeeSettingsDeleteComponent } from './../fee-settings-delete/fee-settings-delete.component';
import { FeeScheduleListModel } from './../model/fee-schedule-list.model';

@Component({
  selector: 'app-fee-settings-list',
  templateUrl: './fee-settings-list.component.html',
  styleUrls: ['./../fee-settings.component.scss', './fee-settings-list.component.scss'],
})
export class FeeSettingsListComponent extends SubscriberComponent implements OnInit {
  public feeCategories = ['Purchase', 'Payment'];
  displayedColumns = [
    'feeScheduleCategory',
    'feeScheduleType',
    'feeScheduleName',
    'tierStart',
    'tierEnd',
    'feePercentage',
    'fixedFee',
    'freeAccess',
    'action',
  ];

  filterForm: FormGroup;
  feeSchedules$: Observable<any>;
  feeSchedules: Array<FeeScheduleListModel>;
  feeFiltered: any;
  dataSource: any;
  feeScheduleCategories: any;
  feeScheduleTypes = new Set<string>();
  feeScheduleNames = new Set<string>();
  isDisabledType = true;
  isDisabledName = true;
  isDisabledClear = true;
  feesData: Array<FeeScheduleListModel> = new Array<FeeScheduleListModel>();

  constructor(
    private lookupRepo: LookupAbstractService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private feeSettingsService: FeeSettingsService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.getData();
  }

  getData(): void {
    this.feesData = new Array<FeeScheduleListModel>();
    this.feeSchedules$ = this.lookupRepo.getFeeSchedules();
    this.subscriptions.push(
      this.feeSchedules$.subscribe((result) => {
        this.feeSchedules = result;

        this.feeScheduleCategories = new Set(this.feeSchedules.map((feeSchedules) => feeSchedules.feeScheduleCategory));
        this.feeScheduleTypes = new Set(this.feeSchedules.map((feeSchedules) => feeSchedules.feeScheduleType));
        this.feeScheduleNames = new Set(this.feeSchedules.map((feeSchedules) => feeSchedules.feeScheduleName));

        this.feeSchedules.forEach((data) => {
          const fees = new FeeScheduleListModel();

          fees.feeScheduleCategory = data.feeScheduleCategory;
          fees.feeScheduleKey = data.feeScheduleKey;
          fees.feeScheduleName = data.feeScheduleName;
          fees.feeScheduleType = data.feeScheduleType;
          fees.feeScheduleTypeCode = data.feeScheduleTypeCode;
          fees.id = data.id;
          fees.isEditable = data.isEditable;
          if (data.feeScheduleTiers.length > 0) {
            fees.feeFixed = data.feeScheduleTiers[0].feeFixed;
            fees.feePercent = data.feeScheduleTiers[0].feePercent;
            fees.feeScheduleTierKey = data.feeScheduleTiers[0].feeScheduleTierKey;
            fees.paymentTypeCode = data.feeScheduleTiers[0].paymentTypeCode;
            fees.pmtBatchFeeCode = data.feeScheduleTiers[0].pmtBatchFeeCode;
            fees.pmtBatchFeeName = data.feeScheduleTiers[0].pmtBatchFeeName;
            fees.tierEnd = data.feeScheduleTiers[0].tierEnd;
            fees.tierStart = data.feeScheduleTiers[0].tierStart;
            fees.feeScheduleTiers = data.feeScheduleTiers;
          }
          fees.freeAccess = data.freeAccess;

          this.feesData.push(fees);
        });

        this.feeFiltered = this.feesData;
        this.dataSource = new MatTableDataSource(this.feeFiltered);
      }),
    );
  }

  enableType(): void {
    this.isDisabledType = false;
    this.disableInput();
  }

  setFeeScheduleForSelectedCategory(selectedFeeScheduleCategory) {
    this.isDisabledType = false;
    this.isDisabledName = true;
    this.disableInput();

    this.feeScheduleTypes = new Set(
      this.feeSchedules
        .filter((feeSchedules) => feeSchedules.feeScheduleCategory === selectedFeeScheduleCategory.value)
        .map((feeSchedules) => feeSchedules.feeScheduleType),
    );
    this.feeFiltered = this.feesData
      .filter((feeSchedules) => feeSchedules.feeScheduleCategory === selectedFeeScheduleCategory.value)
      .map((feeFiltered) => feeFiltered);
    this.feeScheduleTypes = new Set(this.feeFiltered.map((feeFiltered) => feeFiltered.feeScheduleType));
    this.dataSource = new MatTableDataSource(this.feeFiltered);
  }

  setFeeScheduleForSelectedType(selectedFeeScheduleType) {
    this.isDisabledName = false;
    this.disableInput();
    this.feeScheduleNames = new Set(
      this.feeSchedules
        .filter((feeSchedules) => feeSchedules.feeScheduleType === selectedFeeScheduleType.value)
        .map((feeSchedules) => feeSchedules.feeScheduleName),
    );
    this.feeFiltered = this.feesData
      .filter((feeSchedules) => feeSchedules.feeScheduleType === selectedFeeScheduleType.value)
      .map((feeFiltered) => feeFiltered);
    this.dataSource = new MatTableDataSource(this.feeFiltered);
  }

  setFeeScheduleForSelectedName(selectedFeeScheduleName) {
    this.isDisabledClear = false;
    this.feeFiltered = this.feesData
      .filter((feeSchedules) => feeSchedules.feeScheduleName === selectedFeeScheduleName.value)
      .map((feeFiltered) => feeFiltered);
    this.dataSource = new MatTableDataSource(this.feeFiltered);
  }

  createForm() {
    this.filterForm = this.formBuilder.group({
      category: [null],
      type: [null],
      name: [null],
    });
    this.disableInput();
  }

  clearFilters() {
    this.filterForm.reset();
    this.isDisabledType = true;
    this.isDisabledName = true;
    this.isDisabledClear = true;
    this.dataSource = new MatTableDataSource(this.feesData);
    this.disableInput();
  }

  disableInput() {
    if (this.filterForm) {
      this.isDisabledType ? this.filterForm.controls['type'].disable() : this.filterForm.controls['type'].enable();
      this.isDisabledName ? this.filterForm.controls['name'].disable() : this.filterForm.controls['name'].enable();
    }
  }

  openDeleteFeeScheduleComponent(feeScheduleModel: FeeScheduleListModel): void {
    const dialogRef = this.dialog.open(FeeSettingsDeleteComponent, {
      data: feeScheduleModel,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.clearFilters();
          this.getData();
        }
      }),
    );
  }

  onFreeAccessChange(feeScheduleKey, freeAccess) {
    this.subscriptions.push(
      this.feeSettingsService.freeAccessToggle(feeScheduleKey, freeAccess).subscribe(
        () => {
          this.toastrService.success('Free Access changed');
        },
        (response) => {
          this.toastrService.error(response.error.message);
        },
      ),
    );
  }
}
