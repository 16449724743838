import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PybMaterialModule } from '../../pyb-material/pyb-material.module';
import { PybAgCellDateComponent } from './cells';
import { PybAgCellCurrencyComponent } from './cells';
import { PybAgMatSelectCellComponent } from './cells';

const CELLS = [PybAgCellCurrencyComponent, PybAgCellDateComponent, PybAgMatSelectCellComponent];

@NgModule({
  declarations: [...CELLS],
  imports: [CommonModule, PybMaterialModule, FormsModule],
  exports: [...CELLS],
  entryComponents: [...CELLS],
})
export class PybAgGridModule {}
