<div class="actions-add-buttons text-center">
  <button class="mat-fab btn-notes" (click)="openAddNoteDialog()" data-test="quickviewMessageBtn">
    <i class="material-icons">message</i>
  </button>
  <button class="mat-fab btn-files" (click)="openUploadFileDialog()" data-test="quickviewUploadBtn">
    <i class="material-icons">attachment</i>
  </button>
  <button class="mat-fab btn-tasks" (click)="openAddTaskDialog()" data-test="quickviewTasksBtn">
    <i class="material-icons">assignment</i>
  </button>
  <button class="mat-fab btn-emails" (click)="openSendEmailDialog()" data-test="quickviewEmailBtn">
    <i class="material-icons">email</i>
  </button>
</div>
