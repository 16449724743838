import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigModel } from '../config.model';

export abstract class ConfigsAbstractService {
  public abstract list(): Observable<ConfigModel[]>;
  public abstract listByType(configType: string): Observable<ConfigModel[]>;
  public abstract add(data: ConfigModel): Observable<ConfigModel>;
  public abstract set(key: string, value: any): Observable<ConfigModel>;

  public get(key: string): Observable<any> {
    return this.list().pipe(map((configList) => configList.find((item) => item.configKey === key).configValue));
  }
}
