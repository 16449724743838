import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { CardFormgroupMessageService } from '../../../../shared/card-formgroup/card-formgroup-message.service';
import { ErrorLogger } from '../../../../shared/services/error-logger/error-logger.service';
import { AppState } from '../../../../store/app.reducers';
import { RxEditBaseComponent } from '../../../rx-edit-base.component';
import { SupplierGeneralForm } from '../supplier-general-form';
import { SupplierDetailsModel } from './../../../model/supplier.model';
import { SupplierGeneralFormTypes } from './../supplier-general-form-types';

@Component({
  selector: 'app-supplier-general-advance-auditor',
  templateUrl: './supplier-advance-auditor.component.html',
  styleUrls: ['./supplier-advance-auditor.component.scss'],
})
export class SupplierGeneralAdvanceAuditorComponent extends RxEditBaseComponent implements OnDestroy {
  @Input() set supplierDetails(model: SupplierDetailsModel) {
    this.setSupplierDetails(model);
  }

  private SUPPLIER_SERVICE_CLASS_NAME = 'SupplierService';
  readonly CONTACT_NAME_FORM = SupplierGeneralFormTypes.CONTACT_NAME_FORM;
  readonly CONTACT_DATA_FORM = SupplierGeneralFormTypes.CONTACT_DATA_FORM;

  supplierDetailsModel: SupplierDetailsModel;
  supplierSite = null;
  loaded = true;

  constructor(
    readonly store: Store<AppState>,
    readonly messageService: CardFormgroupMessageService,
    readonly errorLogger: ErrorLogger,
    private readonly supplierFormBuilder: SupplierGeneralForm,
    private readonly cd: ChangeDetectorRef,
  ) {
    super(store, messageService, errorLogger);
  }

  private setSupplierDetails(model: SupplierDetailsModel): void {
    this.supplierDetailsModel = model;

    if (!model) {
      return;
    }

    this.supplierSite = this.supplierDetailsModel.supplierSite;

    this.initForm();
  }

  private initForm(): void {
    if (!this.formGroups[SupplierGeneralFormTypes.CONTACT_NAME_FORM]) {
      this.formGroups = {
        ...this.supplierFormBuilder.initGeneralFormGroups(this.supplierDetailsModel),
      };
    }
  }

  protected resolveServiceClassBy(formGroupKey: string) {
    return this.SUPPLIER_SERVICE_CLASS_NAME;
  }

  protected resolveParamsBy(formGroupKey: string): any[] {
    return [];
  }

  protected prepareRequestPartData(formGroupKey, partData): any {
    return partData;
  }

  protected getSupplierKey(): string {
    return this.supplierDetailsModel.supplierKey;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
