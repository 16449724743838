import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { filter, finalize, mergeMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { selectLoggedUserModel } from '../../../../auth/store/auth.actions';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { LookupRepository } from '../../../../shared/lookup-service/lookup-repository';
import { MonitorService } from '../../../../shared/monitor.service';
import { USER_PERMISSION } from '../../../../shared/users/user-permissions.enum';
import { AppState } from '../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { UwTaskProgressModel } from '../../../model/uw-task-progress.model';
import { B2bWorkflowService } from '../../../services/workflow/b2b-workflow.service';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from '../../supplier-onboarding/supplier-onboarding.service';
import { SupplierUnderwritingService } from '../supplier-underwriting.service';
import {
  AddressPhotoMatches,
  AgreementEntityName,
  AgreementReview,
  AgreementSigned,
  AmazonAdvertisingLabel,
  AmazonAdvertisingOverride,
  AmazonAdvertisings,
  AmazonInformation,
  AmazonLendingLabel,
  AmazonLendingOverride,
  AmazonRevenue,
  AmazonRevenueOverride,
  BankingEntityName,
  BlockDecision,
  CredictCheck,
  InternationalSupplier,
  InternationalSupplierOverride,
  LegalNameMatches,
  ManualLabels,
  NotificationOverride,
  PersonalBgCheck,
  SosInfoMatches,
  StatusInternationalSupplier,
  StatusNotification,
  StatusTransUnion,
  StatusUccFiling,
  TransUnionOverride,
} from './common/consts/underwritings-initial-constants';
import { UWRules } from './common/interfaces/uw-rules';
import { UnderwritingInitialService } from './common/services/underwriting-initial.service';
import { GetCreditCheck } from './common/utils/get-credit-check';
import { GetInitialInternationalSupplier } from './common/utils/get-initial-international-supplier';
import { GetItemKeyValue } from './common/utils/get-item-key-value';
import { GetPersonalBgCheck } from './common/utils/get-personal-bg-check';
import { UnderwritingInitialFooterComponent } from './underwriting-initial-footer/underwriting-initial-footer.component';

@Component({
  selector: 'app-underwriting-initial',
  templateUrl: './underwriting-initial.component.html',
  styleUrls: ['./underwriting-initial.component.scss'],
})
export class UnderwritingInitialComponent extends SubscriberComponent implements OnInit {
  @ViewChild('uwFooter') uwFooter: UnderwritingInitialFooterComponent;

  private subscribeTask$: Subscription;
  private getRefresh$: Subscription;
  private getHistoryList$: Subscription;
  private getActionsService$: Subscription;
  private getActionsList$: Subscription;
  private getComplianceDoctypes$: Subscription;
  dataFetch = false;
  benchmarkStatus: any;
  finalizedTask = false;
  loggedUser: string;
  UWDecision: string;
  UWDecisionKey: string;
  UWRules: UWRules;
  supplierKey: string;
  blockDecision = BlockDecision;
  editRules = false;
  complianceStatuses: any;

  // ** Trans Union related ** //
  transUnion: any;
  guarantorAgainstBankkrupcies: string;
  guarantorAgainstJudgements: string;
  guarantorAgainstLiens: string;
  transUnionActionStatusKey: string;
  trafficRecords = 0;
  criminalRecords = 0;
  noCriminalBackground = true;
  // ** END Trans Union related stuff ** //

  uwTaskProgress: UwTaskProgressModel = new UwTaskProgressModel();
  underwritingActionStatusKey: string;
  underwritingFiles = [];
  underwritingImages = [];
  notificationFiles = [];

  // Amazon Health Rules related //
  UWRulesErrorStatus: any;
  UWRulesWarningStatus: any;
  // ** END Amazon Health Rules related ** //

  // manual checkboxes //
  legalNameMatches = LegalNameMatches;
  sosInfoMatches = SosInfoMatches;
  agreementSigned = AgreementSigned;
  addressPhotoMatches = AddressPhotoMatches;
  agreementEntityName = AgreementEntityName;
  bankingEntityName = BankingEntityName;
  statusTransUnion = StatusTransUnion;
  statusUccFiling = StatusUccFiling;
  amazonRevenue = AmazonRevenue;
  statusInternationalSupplier = StatusInternationalSupplier;
  amazonAdvertisingLabel = AmazonAdvertisingLabel;
  amazonLendingLabel = AmazonLendingLabel;
  statusNotification = StatusNotification;

  // sections //
  agreementReview = AgreementReview;
  agreementReviewDecision = this.agreementReview.filter((item) => item.value === false).length;
  amazonInformation = AmazonInformation;
  amazonInformationDecision = this.amazonInformation.filter((item) => item.value === false).length;
  personalBgCheck = PersonalBgCheck;
  personalBgCheckDecision = this.personalBgCheck.filter((item) => item.automated === true);
  credictCheck = CredictCheck;
  credictCheckDecision = this.credictCheck.filter((item) => item.automated === true);
  internationalSupplier = InternationalSupplier;
  internationalSupplierDecision = this.internationalSupplier.filter((item) => item.value === false).length;
  amazonAdvertisings = AmazonAdvertisings;
  amazonAdvertising = this.amazonAdvertisings[1];
  amazonLendingOverride = AmazonLendingOverride;
  transUnionOverride = TransUnionOverride;
  internationalSupplierOverride = InternationalSupplierOverride;
  amazonAdvertisingOverride = AmazonAdvertisingOverride;
  amazonRevenueOverride = AmazonRevenueOverride;
  notificationOverride = NotificationOverride;
  amazonFilesDecision: number;
  amazonLoanDecision: boolean;
  amazonHealth: any = {};
  amazonSales = [];

  underwritingHistory = [];
  actualHistoryUsed: any;
  supplierDetailsModel: SupplierDetailsModel;

  isRestrictedUWView = false;
  complianceDoctypes: any[];
  underwritingScriptLoading = false;
  computingDataIsInProgress = true;
  footerButtonLoading = false;

  readonly userPermission = USER_PERMISSION;

  constructor(
    private lookupService: LookupRepository,
    private route: ActivatedRoute,
    private supplierUnderwritingService: SupplierUnderwritingService,
    public dialogService: MatDialog,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private taskMonitorService: B2bWorkflowService,
    private store: Store<AppState>,
    private permissionsService: NgxPermissionsService,
    private monitorService: MonitorService,
    private underwritingInitialService: UnderwritingInitialService,
  ) {
    super();
  }

  ngOnInit() {
    this.checkUserPermission();
    this.getInitialData();
  }

  getInitialData() {
    this.getLoggedUser();
    this.getSupplier();
  }

  getLoggedUser() {
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => (user ? (this.loggedUser = user.email) : (this.loggedUser = ''))),
    );
  }

  getSupplier() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((supplierDetails: SupplierDetailsModel) => {
          this.supplierDetailsModel = supplierDetails;
          this.supplierKey = supplierDetails.supplierKey;
          this.setUnderwritingActionStatusKey();
          this.setComplianceStatuses();
          this.setComplianceDoctypes();
          this.getUnderwritingHistory();
          this.subscribeOnTask();
        }),
    );
  }

  subscribeOnTask() {
    const supplierUWTask = `amazon-reports-${this.supplierKey}`;

    if (this.subscribeTask$ && !this.subscribeTask$.closed) {
      this.subscribeTask$.unsubscribe();
    }

    if (this.getRefresh$ && !this.getRefresh$.closed) {
      this.getRefresh$.unsubscribe();
    }

    this.subscribeTask$ = this.taskMonitorService.subscribeTask(supplierUWTask, (uwStream) => {
      if (!this.finalizedTask) {
        this.uwTaskProgress.taskProgressData = uwStream;
        this.uwTaskProgress.isRunning = true;
        this.uwTaskProgress.iterationStep = uwStream.progressStep;
        this.uwTaskProgress.maxIterationStep = uwStream.progressCount;
      }
    });

    this.getRefresh$ = this.uwTaskProgress.getRefresh().subscribe(() => {
      this.finalizedTask = true;
      this.uwTaskProgress.isRunning = false;
      this.toastr.success('Updating underwriting data from Amazon');
      this.getUnderwritingHistory();
      this.toastr.info('Fetching new data');
    });

    this.subscriptions.push(this.getRefresh$, this.subscribeTask$);
  }

  setUnderwritingActionStatusKey() {
    if (this.getActionsService$ && !this.getActionsService$.closed) {
      this.getActionsService$.unsubscribe();
    }

    this.getActionsService$ = this.supplierUnderwritingService
      .getActionsService(this.supplierKey, '331d90cc-cf7a-4d9f-92bd-8855c1af08c5', 'actions', 'v2')
      .subscribe((uwCheck) => {
        if (uwCheck[0] && uwCheck[0].satisfied_by && uwCheck[0].satisfied_by[0].status === 'approved') {
          this.underwritingActionStatusKey = uwCheck[0].satisfied_by[0].compliance_action_status_key;
        }
      });

    this.subscriptions.push(this.getActionsService$);
  }

  setComplianceStatuses() {
    if (this.getActionsList$ && !this.getActionsList$.closed) {
      this.getActionsList$.unsubscribe();
    }

    this.getActionsList$ = this.supplierUnderwritingService.getActionsList(this.supplierKey).subscribe(
      (data) => {
        this.complianceStatuses = this.underwritingInitialService.getComplianceStatuses(data);
      },
      (err) => this.toastr.error(err),
    );

    this.subscriptions.push(this.getActionsList$);
  }

  setComplianceDoctypes() {
    if (this.getComplianceDoctypes$ && !this.getComplianceDoctypes$.closed) {
      this.getComplianceDoctypes$.unsubscribe();
    }

    this.getComplianceDoctypes$ = this.lookupService
      .getComplianceDoctypes()
      .subscribe((complianceDoctypes) => (this.complianceDoctypes = complianceDoctypes));

    this.subscriptions.push(this.getComplianceDoctypes$);
  }

  getUnderwritingHistory() {
    if (this.getHistoryList$ && !this.getHistoryList$.closed) {
      this.getHistoryList$.unsubscribe();
    }

    this.getHistoryList$ = this.supplierUnderwritingService.getHistoryList(this.supplierKey).subscribe((data) => {
      this.underwritingHistory = data;
      if (this.underwritingHistory.length) {
        this.actualHistoryUsed = this.underwritingHistory[0];
        this.getUnderwritingHistoryRecord(this.actualHistoryUsed, 0);
      } else {
        this.computingDataIsInProgress = false;
      }
      this.checkTransUnionData();
    });

    this.subscriptions.push(this.getHistoryList$);
  }

  onAgreementReviewChange(result) {
    this.agreementReview = result;
    this.agreementReviewChange();
  }

  agreementReviewChange() {
    this.agreementReviewDecision = this.agreementReview.filter((item) => item.value === false).length;
  }

  onAmazonInformationChange(result) {
    this.amazonInformation = [...result];
    this.amazonInformationChange();
  }

  onTransUnionOverrideChange(result) {
    this.transUnionOverride = [...result];
    this.transUnionOverrideChange();
  }

  onInternationalSupplierOverrideChange(result) {
    this.internationalSupplierOverride = [...result];
    this.internationalSupplierChange();
  }

  onAmazonAdvertisingOverride(result) {
    this.amazonAdvertisingOverride = [...result];
  }

  onAmazonLendingOverride(result) {
    this.amazonLendingOverride = [...result];
  }

  onAmazonRevenueOverride(result) {
    this.amazonRevenueOverride = [...result];
  }

  transUnionOverrideChange() {
    this.personalBgCheckDecision = this.personalBgCheck.filter((item) => item.automated === true);
  }

  amazonInformationChange() {
    this.amazonInformationDecision = this.amazonInformation.filter((item) => item.value === false).length;
  }

  amazonLoanChange() {
    if (this.amazonHealth['amazon_loan']) {
      this.amazonLoanDecision = this.amazonHealth['amazon_loan'].value !== 0;
    }
  }

  internationalSupplierChange() {
    this.internationalSupplierDecision = this.internationalSupplier.filter((item) => item.value === false).length;
  }

  runUnderwriting() {
    this.underwritingScriptLoading = true;
    this.supplierUnderwritingService
      .putActionStatusService(this.underwritingActionStatusKey, 'v2', {
        status: 'approved',
      })
      .pipe(finalize(() => (this.underwritingScriptLoading = false)))
      .subscribe((data) => {
        this.finalizedTask = false;
        this.getUnderwritingHistory();
        if (this.uwFooter) {
          this.uwFooter.fetchUwToolsResult();
        }
      });
  }

  checkTransUnionData() {
    this.supplierUnderwritingService
      .getActionsService(this.supplierKey, 'e6247370-bd22-4992-8054-7900a4afa789', 'actions', 'v2')
      .subscribe((data) => {
        this.onCheckTransUnionData(data);
      });
  }

  onCheckTransUnionData(data) {
    const transUnionStatus = data.filter((action) => {
      return action.compliance_action_type === 'credit_check';
    })[0];
    // No action statuscheckTransUnionData key - send POST to create one so you can fetch TU data //
    if (transUnionStatus.status === 'incomplete') {
      this.supplierUnderwritingService
        .postActionStatusService('v2', this.underwritingInitialService.getBodyForPostActionStatusService(this.supplierKey))
        .subscribe((tuKeyCreated) => {
          return (this.transUnionActionStatusKey = tuKeyCreated.compliance_action_status_key);
        });
    } else {
      // grab TU data //
      this.transUnion = transUnionStatus.satisfied_by[0];
      // Trans Union action status key exist. If there is data in results, compile them to view //
      if (transUnionStatus.satisfied_by[0].results) {
        this.compileTransUnionData();
      }
      return (this.transUnionActionStatusKey = this.transUnion.compliance_action_status_key);
    }
  }

  compileTransUnionData() {
    this.setTransUnionDetails();
    this.setGuarantorAgainstBankkrupcies();
    this.setGuarantorAgainstJudgements();
    this.setGuarantorAgainstLiens();
    this.setPersonalBgCheck();
  }

  setPersonalBgCheck() {
    if (this.transUnion && this.transUnion.results && this.transUnion.results.person) {
      const personData = this.transUnion.results.person.data;
      this.personalBgCheck = GetPersonalBgCheck(
        personData,
        this.guarantorAgainstBankkrupcies,
        this.guarantorAgainstJudgements,
        this.guarantorAgainstLiens,
        this.transUnion.results.person,
        this.trafficRecords,
        this.criminalRecords,
      );
    }
    if (
      this.transUnion.results &&
      this.transUnion.results &&
      this.transUnion.results.business &&
      this.transUnion.results.business.ucc_filings
    ) {
      const numberOfRecordsFound = this.transUnion.results.business.ucc_filings.NumberOfRecordsFound;
      if (numberOfRecordsFound) {
        this.credictCheck = GetCreditCheck(numberOfRecordsFound);
      }
    }
  }

  setGuarantorAgainstBankkrupcies() {
    if (this.transUnion && this.transUnion.results && this.transUnion.results.person) {
      this.guarantorAgainstBankkrupcies =
        this.transUnion.results.person.data.PersonSearchOutputRecords.TLOPersonSearchOutputRecord[0].NumberOfBankruptcies;
    }
  }

  setGuarantorAgainstJudgements() {
    if (this.transUnion && this.transUnion.results && this.transUnion.results.person) {
      this.guarantorAgainstJudgements =
        this.transUnion.results.person.data.PersonSearchOutputRecords.TLOPersonSearchOutputRecord[0].NumberOfJudgments;
    }
  }

  setGuarantorAgainstLiens() {
    if (this.transUnion && this.transUnion.results && this.transUnion.results.person) {
      this.guarantorAgainstLiens =
        this.transUnion.results.person.data.PersonSearchOutputRecords.TLOPersonSearchOutputRecord[0].NumberOfLiens;
    }
  }

  setTransUnionDetails() {
    if (!this.transUnion.results) {
      return;
    }
    if (this.transUnion && this.transUnion.results && this.transUnion.results.business) {
      this.transUnion.results.person = this.transUnion.results.business.person;
      this.transUnion.results.ucc_filings = this.transUnion.results.business.ucc_filings;
    }
    this.trafficRecords = 0;
    this.criminalRecords = 0;
    if (this.transUnion.results.person) {
      this.noCriminalBackground = false;
      if (this.transUnion.results.person.comprehensive.CriminalRecordsMatch) {
        this.transUnion.results.person.comprehensive.CriminalRecordsMatch.TLOCriminalSearchMatch.map((item) => {
          // first check if there is data is not returning null
          if (item.CriminalRecord.CrimeDetails) {
            if (item.CriminalRecord.CrimeDetails.Details[0].CrimeType === 'TRAFFIC') {
              this.trafficRecords++;
            } else {
              this.criminalRecords++;
            }
          }
        });
      }
    } else {
      this.noCriminalBackground = true;
    }
  }

  onGetUnderwritingHistoryRecordClick({ item, index }) {
    this.getUnderwritingHistoryRecord(item, index);
  }

  getUnderwritingHistoryRecord(item, index) {
    this.setBlockDecisionIsOn(index > 0);
    this.actualHistoryUsed = item;
    this.supplierUnderwritingService.getHistoryRecord(this.supplierKey, item.key).subscribe((results) => {
      this.setUWDecisionKey(results);
      this.setUWDecision(results);
      this.setBlockDecisionIsOn(this.UWDecision === 'verified' || this.UWDecision === 'rejected' || index > 0);
      if (this.rulesExists(results)) {
        this.setRules(results);
      }
      this.computeCompiledData(results.data.underwriting_output);
      if (results.data.credit_check_output[0]) {
        if (results.data.credit_check_output[0].status === 'incomplete') {
          this.checkTransUnionData();
        } else {
          this.transUnionActionStatusKey = results.data.credit_check_output[0].satisfied_by[0].compliance_action_status_key;
          this.transUnion = results.data.credit_check_output[0].satisfied_by[0];
          this.compileTransUnionData();
        }
      }
      this.resetFooterButtonsLoading();
    });
  }

  rulesExists(results) {
    if (!results || !results.data) {
      return false;
    }
    return results.data.rules && Object.keys(results.data.rules).length;
  }

  setRules(results) {
    if (!results || !results.data) {
      return;
    }
    this.UWRules = results.data.rules;
    const rulesResults = Object.values(results.data.rules).filter((ruleItem: any) => {
      if (ruleItem.information) {
        return ruleItem.information.field_type === 'numeric';
      }
    });
    this.UWRulesErrorStatus = rulesResults.filter((errItem: any) => errItem.error);
    this.UWRulesWarningStatus = rulesResults.filter((warnItem: any) => warnItem.warning);
  }

  setBlockDecisionIsOn(value: boolean) {
    this.blockDecision.isOn = value;
  }

  setUWDecision(results) {
    if (!results) {
      return;
    }
    this.UWDecision = results.status;
  }

  setUWDecisionKey(results) {
    if (!results) {
      return;
    }
    const amazonReports = results.data.general_raport.filter((grItem) => {
      return grItem.compliance_label === 'Amazon Reports';
    });
    if (!amazonReports || !amazonReports.length) {
      return;
    }
    this.UWDecisionKey = amazonReports[0].compliance_status_key;
  }

  computeAgreementReview(data) {
    try {
      this.setAgreementReview(data);
    } catch (e) {
      this.resetAgreementReview();
    }
  }

  computeAmazonInformation(data) {
    try {
      this.setAmazonInformation(data);
    } catch (e) {
      this.resetAmazonInformation();
    }
  }

  computeTransUnionOverride(data) {
    try {
      this.setTransUnionOverride(data);
    } catch (e) {
      this.resetTransUnionOverride();
    }
  }

  computeInternationalSupplierOverride(data) {
    try {
      this.setInternationalSupplierOverride(data);
    } catch (e) {
      this.resetInternationalSupplierOverride();
    }
  }

  computeAmazonAdvertisingOverride(data) {
    try {
      this.setAmazonAdvertisingOverride(data);
    } catch (e) {
      this.resetAmazonAdvertisingOverride();
    }
  }

  computeAmazonRevenueOverride(data) {
    try {
      this.setAmazonRevenueOverride(data);
    } catch (e) {
      this.resetAmazonRevenueOverride();
    }
  }

  computeAmazonLendingOverride(data) {
    try {
      this.setAmazonLendingOverride(data);
    } catch (e) {
      this.resetAmazonLendingOverride();
    }
  }

  computeNotificationOverride(data) {
    try {
      this.setNotificationOverride(data);
    } catch (e) {
      this.resetNotificationOverride();
    }
  }

  computeAmazonData(data) {
    try {
      // try to fetch amazon data processed by rule engine //
      const amazonResults = data.filter((action) => {
        return action.compliance_action_type === 'status';
      })[0].satisfied_by[0].results;
      this.amazonHealth = amazonResults.underwriting_with_errors;
      this.amazonSales = this.underwritingInitialService.getAmazonSales(this.amazonHealth);
      Object.values(this.amazonHealth).forEach((item: any) => {
        item.decision = item.error === false && item.warning === false;
      });
      this.amazonLoanDecision = this.amazonHealth['amazon_loan'].value !== 0;
    } catch (e) {}
  }

  computeUnderwritingFiles(data) {
    this.underwritingFiles = [];
    try {
      this.setUnderwritindFiles(data);
    } catch (e) {
      this.resetUnderwritindFiles();
    }
  }

  setAmazonAdvertising(data) {
    try {
      if (data[0].underwriting.advertising_cost_of_sales) {
        const advertising_cost_of_sales = Number(data[0].underwriting.advertising_cost_of_sales);
        if (advertising_cost_of_sales > 0) {
          this.amazonAdvertisings = this.underwritingInitialService.getAmazonAdvertisings();
          if (advertising_cost_of_sales > 5) {
            advertising_cost_of_sales > 10
              ? (this.amazonAdvertising = this.amazonAdvertisings[2])
              : (this.amazonAdvertising = this.amazonAdvertisings[1]);
          }
        } else {
          this.amazonAdvertising = this.amazonAdvertisings[3];
        }
      } else {
        this.resetAmazonAdvertising();
      }
    } catch (e) {
      this.resetAmazonAdvertising();
    }
  }

  computeInternationalSupplier() {
    try {
      this.setInternationalSupplier();
    } catch (e) {
      this.resetInternationalSupplier();
    }
  }

  setInternationalSupplier() {
    this.internationalSupplier = this.underwritingInitialService.getInternationalSupplier(
      this.supplierDetailsModel,
      this.internationalSupplier,
      this.amazonHealth,
    );
  }

  computeMarketData(data, marketData) {
    if (!data || !marketData) {
      return;
    }
    this.benchmarkStatus = this.underwritingInitialService.getBenchmarkStatus(marketData);
    this.setAmazonAdvertising(data);
    this.computeInternationalSupplier();
  }

  getMarketData(data) {
    this.supplierUnderwritingService
      .getAmazonDataService(this.supplierKey, AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY)
      .subscribe((marketData) => {
        this.computeMarketData(data, marketData);
      });
  }

  setAgreementReview(data) {
    const agreementReviewLabels = ['legal_name_matches', 'sos_info_matches', 'agreement_signed', 'address_photo_matches'];
    this.setComputeValue(data, this.agreementReview, agreementReviewLabels);
    if (this.supplierDetailsModel.businessTypeCode === 'SP') {
      this.agreementReview[1].value = true;
    }
  }

  setAmazonInformation(data) {
    const amazonInformationLabels = ['agreement_entity_name', 'banking_entity_name'];
    this.setComputeValue(data, this.amazonInformation, amazonInformationLabels);
  }

  setTransUnionOverride(data) {
    const transUnionLabels = ['status_trans_union', 'status_ucc_filing'];
    this.setComputeValue(data, this.transUnionOverride, transUnionLabels);
  }

  setInternationalSupplierOverride(data) {
    const internationalLabels = ['international_supplier'];
    this.setComputeValue(data, this.internationalSupplierOverride, internationalLabels);
  }

  setAmazonAdvertisingOverride(data) {
    const amazonAdvertisingLabel = ['amazon_advertising'];
    this.setComputeValue(data, this.amazonAdvertisingOverride, amazonAdvertisingLabel);
  }

  setAmazonRevenueOverride(data) {
    const amazonRevenueLabel = ['amazon_revenue'];
    this.setComputeValue(data, this.amazonRevenueOverride, amazonRevenueLabel);
  }

  setAmazonLendingOverride(data) {
    const amazonLendingLabel = ['amazon_lending'];
    this.setComputeValue(data, this.amazonLendingOverride, amazonLendingLabel);
  }

  setNotificationOverride(data) {
    const notificationLabels = ['notifications_and_suspensions'];
    this.setComputeValue(data, this.notificationOverride, notificationLabels);
  }

  setComputeValue(data, propertyToSet, labels: string[]) {
    labels.forEach((label, index) => {
      propertyToSet[index].value = this.getComputeStatus(data, label);
    });
  }

  getComputeStatus(data, item: string) {
    const holderStatus = data.filter((action) => {
      return action.slug === item;
    });
    return holderStatus[0].satisfied_by ? holderStatus[0].satisfied_by[0].status === 'approved' : false;
  }

  setUnderwritindFiles(data) {
    data
      .filter((action) => {
        return action.compliance_action_type === 'file';
      })[0]
      .satisfied_by.map((item) => {
        this.underwritingFiles.push({
          satisfied_by: item,
        });
      });
    this.notificationFiles = this.underwritingFiles.filter((notification) => notification.satisfied_by.label.includes('notification'));
    this.underwritingImages = this.underwritingFiles.filter((notification) => !notification.satisfied_by.label.includes('notification'));
    this.amazonFilesDecision = this.underwritingImages.filter((item) => item.satisfied_by.status !== 'approved').length;
  }

  resetAgreementReview() {
    this.agreementReview[0].value = false;
    this.agreementReview[1].value = false;
    this.agreementReview[2].value = false;
    this.agreementReview[3].value = false;
  }

  resetAmazonInformation() {
    this.amazonInformation[0].value = false;
    this.amazonInformation[1].value = false;
  }

  resetTransUnionOverride() {
    this.transUnionOverride[0].value = false;
    this.transUnionOverride[0].value = false;
  }

  resetInternationalSupplierOverride() {
    this.internationalSupplierOverride[0].value = false;
  }

  resetAmazonAdvertisingOverride() {
    this.amazonAdvertisingOverride[0].value = false;
  }

  resetAmazonRevenueOverride() {
    this.amazonRevenueOverride[0].value = false;
  }

  resetAmazonLendingOverride() {
    this.amazonLendingOverride[0].value = false;
  }

  resetNotificationOverride() {
    this.notificationOverride[0].value = false;
  }

  resetUnderwritindFiles() {
    this.underwritingFiles = [];
    this.notificationFiles = [];
    this.underwritingImages = [];
  }

  resetAmazonAdvertising() {
    this.amazonAdvertisings = ['No'];
    this.amazonAdvertising = this.amazonAdvertisings[0];
  }

  resetInternationalSupplier() {
    this.internationalSupplier = GetInitialInternationalSupplier(this.supplierDetailsModel);
  }

  computeCompiledData(data) {
    this.computingDataIsInProgress = true;
    this.computeAgreementReview(data);
    this.computeAmazonInformation(data);
    this.computeTransUnionOverride(data);
    this.computeInternationalSupplierOverride(data);
    this.computeAmazonAdvertisingOverride(data);
    this.computeAmazonRevenueOverride(data);
    this.computeAmazonLendingOverride(data);
    this.computeNotificationOverride(data);
    this.computeAmazonData(data);
    this.computeUnderwritingFiles(data);
    this.getMarketData(data);
    this.updateStatuses();
    this.computingDataIsInProgress = false;
  }

  updateStatuses() {
    this.dataFetch = true;
    this.agreementReviewChange();
    this.amazonInformationChange();
    this.amazonLoanChange();
    this.internationalSupplierChange();
  }

  startEditRules() {
    this.editRules = true;
  }

  onRulesSaved() {
    this.saveUW(this.UWDecision);
  }

  saveUW(decision) {
    this.footerButtonLoading = true;
    this.supplierUnderwritingService.getActionsService(this.supplierKey, '54ee1a87-f97a-4f57-afcd-8e72ce38f3f0', 'actions', 'v2').subscribe(
      (data) => {
        const manualLabels = ManualLabels;
        const agreementReviewKeys = [];
        manualLabels.forEach((item) => {
          const holderStatus = data.filter((action) => {
            return action.slug === item;
          })[0];
          if (holderStatus.status) {
            if (holderStatus.satisfied_by) {
              agreementReviewKeys.push(
                GetItemKeyValue(item, holderStatus.satisfied_by ? holderStatus.satisfied_by[0].compliance_action_status_key : ''),
              );
            } else {
              agreementReviewKeys.push(GetItemKeyValue(item));
            }
          }
        });
        const manualSettings = this.getManualSettings();
        const manualDecision = this.getManualDecision();
        const chainedArrrays = [];
        manualSettings.map((item, index) => {
          this.transformManualSetting(item, agreementReviewKeys, index, chainedArrrays, manualDecision);
        });
        this.editRules = false;
        this.saveUWSettings(chainedArrrays, decision);
      },
      () => this.resetFooterButtonsLoading(),
    );
  }

  saveUWSettings(chainedArrrays, decision) {
    if (!chainedArrrays.length) {
      this.updateUwHistory(decision);
      return;
    }

    forkJoin(chainedArrrays).subscribe(
      (successManual) => {
        this.updateUwHistory(decision);
      },
      () => this.resetFooterButtonsLoading(),
    );
  }

  updateUwHistory(decision): void {
    this.supplierUnderwritingService
      .putComplianceStatusService(this.UWDecisionKey, {
        supplier_key: this.supplierKey,
        compliance_key: this.complianceStatuses.amazonReports.compliance_key,
        required_for_funding: true,
        status: decision,
        notes: [],
        completed_by: this.loggedUser,
      })
      .subscribe((finaliseUW) => {
        this.toastr.success('Underwriting update history success');
        this.supplierUnderwritingService.getHistoryList(this.supplierKey).subscribe((nestData) => {
          this.underwritingHistory = nestData;
          if (this.underwritingHistory.length) {
            this.actualHistoryUsed = this.underwritingHistory[0];
            this.getUnderwritingHistoryRecord(this.actualHistoryUsed, 0);
          }
        });
        this.resetFooterButtonsLoading();
      });
  }

  resetFooterButtonsLoading() {
    this.footerButtonLoading = false;
  }

  transformManualSetting(item, agreementReviewKeys, index, chainedArrrays, manualDecision) {
    if (item) {
      if (agreementReviewKeys[index].key) {
        chainedArrrays.push(this.getActionStatusServiceApi(agreementReviewKeys[index].key, manualDecision, index, 'approved'));
      } else {
        chainedArrrays.push(
          this.getPostActionStatusServiceApi(manualDecision, index).pipe(
            mergeMap((checkboxData) => {
              return this.getActionStatusServiceApi(checkboxData.compliance_action_status_key, manualDecision, index, 'approved');
            }),
          ),
        );
      }
    } else {
      if (agreementReviewKeys[index].key) {
        chainedArrrays.push(this.getActionStatusServiceApi(agreementReviewKeys[index].key, manualDecision, index, 'incomplete'));
      }
    }
  }

  getPostActionStatusServiceApi(manualDecision, index) {
    return this.supplierUnderwritingService.postActionStatusService('v2', {
      compliance_key: this.complianceStatuses.amazonReports.compliance_key,
      compliance_action_key: manualDecision[index][1],
      compliance_action_type: 'status',
      supplier_key: this.supplierKey,
      status: 'approved',
    });
  }

  getActionStatusServiceApi(key, manualDecision, index, status) {
    return this.supplierUnderwritingService.putActionStatusService(key, 'v2', {
      compliance_key: this.complianceStatuses.amazonReports.compliance_key,
      compliance_action_key: manualDecision[index][1],
      compliance_action_type: 'status',
      supplier_key: this.supplierKey,
      status,
    });
  }

  getManualSettings() {
    return [
      this.agreementReview[0].value,
      this.agreementReview[1].value,
      this.agreementReview[2].value,
      this.agreementReview[3].value,
      this.amazonInformation[0].value,
      this.amazonInformation[1].value,
      this.transUnionOverride[0].value,
      this.transUnionOverride[1].value,
      this.amazonRevenueOverride[0].value,
      this.internationalSupplierOverride[0].value,
      this.amazonAdvertisingOverride[0].value,
      this.amazonLendingOverride[0].value,
      this.notificationOverride[0].value,
    ];
  }

  getManualDecision() {
    return [
      this.legalNameMatches,
      this.sosInfoMatches,
      this.agreementSigned,
      this.addressPhotoMatches,
      this.agreementEntityName,
      this.bankingEntityName,
      this.statusTransUnion,
      this.statusUccFiling,
      this.amazonRevenue,
      this.statusInternationalSupplier,
      this.amazonAdvertisingLabel,
      this.amazonLendingLabel,
      this.statusNotification,
    ];
  }

  private checkUserPermission() {
    const restrictedAccessPermission = this.permissionsService.getPermission(USER_PERMISSION.REST_UW_ACCESS);
    if (!isNullOrUndefined(restrictedAccessPermission)) {
      this.isRestrictedUWView = true;
    }
  }

  onTransUnionDataUpdate(TUResults) {
    this.transUnion = TUResults.filter((action) => {
      return action.compliance_action_type === 'credit_check';
    })[0].satisfied_by[0];
    this.compileTransUnionData();
  }
}
