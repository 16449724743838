<div class="card">
  <div class="card-content">
    <div class="col">
      <div class="row justify-content-between">
        <div class="title">
          <span class="card-title">Latest Advance Line Summary</span>
        </div>
        <div *ngIf="activeAdvanceLineData?.status == 'active'" class="dark-green-field">Active</div>
        <div *ngIf="activeAdvanceLineData?.status == 'pending'" class="yellow-field">Pending</div>
        <div *ngIf="activeAdvanceLineData?.status == 'Offer Expired'" class="gray-field">Expired</div>
        <div *ngIf="activeAdvanceLineData?.status == 'Repaid'" class="gray-field">Repaid</div>
        <div *ngIf="activeAdvanceLineData?.status == 'Rejected'" class="red-field">Rejected</div>
      </div>
    </div>
    <form [formGroup]="activeAdvanceLineForm" class="active-advance-form">
      <div>
        <mat-form-field class="full-width">
          <input matInput placeholder="Advance Limit" readonly [value]="activeAdvanceLineForm.get('limitAmount').value | currency" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Origination Date"
            readonly
            [value]="activeAdvanceLineForm.get('originationDate').value | date: 'MM/dd/yyyy'"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input class="display-none" matInput placeholder="No Rebate" readonly value="activeAdvanceLineForm.get('noRebate').value" />
          <mat-slide-toggle [color]="'primary'" [disabled]="true" [checked]="activeAdvanceLineForm.get('noRebate').value">
          </mat-slide-toggle>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input matInput placeholder="Return Strategy" readonly [value]="activeAdvanceLineForm.get('returnStrategy').value" />
        </mat-form-field>
      </div>
    </form>
    <button
      mat-raised-button
      class="accept-button"
      [color]="'primary'"
      (click)="redirectToDetails()"
      [disabled]="activeAdvanceLineData?.advance_total_amount == null"
      tabindex="-1"
    >
      View Details
    </button>
  </div>
</div>
