import { autoserialize, inheritSerialization } from 'cerialize';

import { USER_PERMISSION } from './user-permissions.enum';

export class PermissionBaseModel {
  @autoserialize
  generalPerm: boolean;

  @autoserialize
  permCode: USER_PERMISSION | string;

  @autoserialize
  permDesc: string;

  constructor(generalPerm: boolean, permCode: USER_PERMISSION | string, permDesc: string) {
    this.generalPerm = generalPerm;
    this.permCode = permCode;
    this.permDesc = permDesc;
  }
}

@inheritSerialization(PermissionBaseModel)
export class PermissionModel extends PermissionBaseModel {
  // Fields
  readonly type = 'group';

  @autoserialize
  assignable: boolean;

  @autoserialize
  generalPerm: boolean;

  @autoserialize
  permCode: USER_PERMISSION | string;

  @autoserialize
  permDesc: string;

  @autoserialize
  specSupPerm: boolean;

  // Accessors
  get id() {
    return this.permCode;
  }

  get key() {
    return this.permCode;
  }

  get name() {
    return this.permDesc;
  }

  constructor(assignable: boolean, generalPerm: boolean, permCode: USER_PERMISSION | string, permDesc: string, specSupPerm: boolean) {
    super(generalPerm, permCode, permDesc);

    this.assignable = assignable;
    this.specSupPerm = specSupPerm;
  }
}
