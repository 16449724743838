import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { ThemeOnboardingModel } from '../../../../shared/onboarding/onboarding.model';
import { AppState } from '../../../../store/app.reducers';

@Component({
  selector: 'app-onboarding-products',
  templateUrl: './onboarding-products.component.html',
  styleUrls: ['./onboarding-products.component.scss'],
})
export class OnboardingProductsComponent extends SubscriberComponent implements OnInit, OnChanges {
  @Input() templateData: ThemeOnboardingModel;

  @Output() formReady = new EventEmitter<FormGroup>();

  public productsForm: FormGroup;

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.formReady.emit(this.productsForm);
    this.subscriptions.push(
      this.store.select('onboarding').subscribe((result) => {
        if (result.resetTimestamp.length > 1) {
          this.productsForm.reset({
            iAccess: false,
            iAdvance: false,
            iTransfer: false,
            weeklyPayments: false,
            pybSellerCard: false,
            iPayout: false,
            openInvoices: false,
          });
        }
      }),
    );
  }

  private createForm(): void {
    this.productsForm = this.fb.group({
      iAccess: false,
      iAdvance: false,
      iTransfer: false,
      weeklyPayments: false,
      pybSellerCard: false,
      iPayout: false,
      openInvoices: false,
    });
  }

  private patchForm(template: ThemeOnboardingModel): void {
    this.productsForm.patchValue(
      {
        iAccess: template.instantAccess,
        iAdvance: template.offerAdvance,
        iTransfer: template.ppmAvailable,
        weeklyPayments: template.schedPayEnabled,
        pybSellerCard: template.offerPcard,
        iPayout: template.instantPayouts,
        openInvoices: template.openInvoices,
      },
      { onlySelf: true, emitEvent: true },
    );
  }

  ngOnChanges(changes) {
    if (changes.templateData && !changes.templateData.firstChange) {
      this.patchForm(this.templateData);
    }
  }
}
