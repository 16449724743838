import { isUndefined } from 'util';

export class OnboardingTaskProgressModel {
  DEFAULT_LABEL = 'Onboarding Workflow Process status';

  isRunning: boolean;
  currentLabel: string;
  iterationStep: number;
  maxIterationStep: number;
  taskName: string;
  itemName: string;

  private _step: number;
  private _count: number;
  private _finishedAt: string;

  public _taskProgressData: any;

  constructor() {
    this.taskProgressData = {
      step: 0,
      count: 0,
      finishedAt: null,
    };
  }

  fetchProgressValue(arg1, arg2, withPercent = false) {
    let percentVal;
    if (arg1 && arg2) {
      percentVal = ((arg1 / arg2) * 100).toFixed(1);
    }
    if (!isUndefined(percentVal)) {
      return withPercent ? percentVal + ' %' : percentVal;
    }
  }

  set taskProgressData(value: any) {
    this._taskProgressData = value;
  }

  get step(): number {
    return this._taskProgressData.step;
  }

  get count(): number {
    return this._taskProgressData.count;
  }

  get finishedAt(): string {
    return this._taskProgressData.finishedAt;
  }

  getCurrentLabel() {
    if (this._step === 0) {
      return 'Onboarding Workflow started';
    }

    if (this._finishedAt) {
      return 'Onboarding Workflow completed';
    }

    return this.DEFAULT_LABEL;
  }
}
