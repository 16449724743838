import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { MarketplaceListModel } from '../../../marketplaces/model/marketplace-list-model';
import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { OnboardingMpModel, ThemeOnboardingModel } from '../../../shared/onboarding/onboarding.model';
import { OnboardingService } from '../../../shared/onboarding/service/onboarding.service';
import { AppState } from '../../../store/app.reducers';
import { ResetAllForms } from '../../store/onboarding.actions';

@Component({
  selector: 'app-onboarding-add',
  templateUrl: './onboarding-add.component.html',
  styleUrls: ['./onboarding-add.component.scss'],
})
export class OnboardingAddComponent extends SubscriberComponent implements OnInit {
  public isClear: boolean;
  public templateData: any;
  public consolidateForm: FormGroup;
  public prefixTitle = 'Add New';
  private id: string;
  private initialTemplateData: any;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private onboardingTemplateService: OnboardingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.consolidateForm = this.fb.group({});
    this.id = this.route.snapshot.paramMap.get('onboardingTemplateKey');

    if (this.isInEditMode()) {
      this.prefixTitle = 'Update';
      this.getTemplateData();
    }
  }

  public formInitialized(name: string, form: FormGroup): void {
    this.consolidateForm.setControl(name, form);
  }

  public async sendForm() {
    let payload;
    let list;
    if (this.isInEditMode()) {
      payload = this.preparePayloadForUpdate();
      list = this.prepareReorderedMarketplaceList();
      await this.updateTemplateRequest(payload, list);
    } else {
      payload = this.preparePayloadForCreate();
      this.createTemplateRequest(payload);
    }
  }

  public resetForm(): void {
    this.store.dispatch(new ResetAllForms());
    this.isClear = true;
  }

  private createTemplateRequest(payload: any) {
    this.subscriptions.push(
      this.onboardingTemplateService.createTemplate(payload).subscribe(
        (response) => {
          if (response) {
            this.router.navigate(['/manage/onboarding/list'], { relativeTo: this.route });
          }
        },
        (err) => {
          if (err.error.message) {
            this.toastr.error(err.error.message);
          }
        },
      ),
    );
  }

  private async updateTemplateRequest(payload: any, list: any) {
    const updateTemplate = await this.updateTemplate(payload);
    if (!updateTemplate) {
      return;
    }
    if (!this.isCreationPolicySetToAll()) {
      const addMarketplaceToTemplate = await this.addMarketplaceToTemplate();
      if (!addMarketplaceToTemplate) {
        return;
      }
      const removeMarketplaceFromTemplate = await this.removeMarketplaceFromTemplate();
      if (!removeMarketplaceFromTemplate) {
        return;
      }
      if (list.reordered.length > 1) {
        const updateMarketplacesOrder = await this.updateMarketplacesOrder(list);
        if (!updateMarketplacesOrder) {
          return;
        }
      }
    }
    this.router.navigate(['/manage/onboarding/list'], { relativeTo: this.route });
  }

  private async updateTemplate(payload: any): Promise<boolean> {
    const response = this.onboardingTemplateService
      .updateTemplate(this.id, payload)
      .toPromise()
      .catch((err) => {
        this.toastr.error('Cannot update template');
      });
    return response;
  }

  private async addMarketplaceToTemplate(): Promise<boolean> {
    const toAdd = this.filterMarketplaces(this.templateData.mpTemplates, this.initialTemplateData);
    const dataToSave = {
      marketplaces_to_add: [],
    };
    if (toAdd.length < 1) {
      return true;
    }
    for (const addMarketplace of toAdd) {
      dataToSave.marketplaces_to_add.push({
        marketplace_key: addMarketplace.marketplaceKey,
        onboarding_template_code: this.id,
      });
    }
    const response = await this.onboardingTemplateService
      .addMarketplaceToTemplate(dataToSave)
      .toPromise()
      .catch((err) => {
        this.toastr.error('Cannot add marketplaces');
      });
    return response;
  }

  private async removeMarketplaceFromTemplate() {
    const toRemove = this.filterMarketplaces(this.initialTemplateData, this.templateData.mpTemplates);
    const dataToRemove = [];
    if (toRemove.length < 1) {
      return true;
    }
    for (const removeMarketplace of toRemove) {
      if (this.isMarketplace(removeMarketplace)) {
        dataToRemove.push({
          onboarding_template_mp_key: removeMarketplace.onboardingTemplateMpKey,
        });
      }
    }
    const response = await this.onboardingTemplateService
      .removeMarketplaceFromTemplate(this.id, dataToRemove)
      .toPromise()
      .catch((err) => {
        this.toastr.error('Cannot remove marketplaces');
      });
    return response;
  }

  private async updateMarketplacesOrder(list: any) {
    const response = this.onboardingTemplateService
      .updateMarketplacesOrder(this.id, list)
      .toPromise()
      .catch((err) => {
        this.toastr.error('Cannot reorder marketplaces');
      });
    return response;
  }

  private filterMarketplaces(firstMarketplacesList, secondMarketplacesList): OnboardingMpModel[] | MarketplaceListModel[] {
    return firstMarketplacesList.filter((marketplaceFromFirstList) => {
      for (const marketplaceFromSecondList of secondMarketplacesList) {
        if (marketplaceFromFirstList.marketplaceKey === marketplaceFromSecondList.marketplaceKey) {
          return false;
        }
      }
      return true;
    });
  }

  private isMarketplace(marketplace: OnboardingMpModel | MarketplaceListModel): marketplace is OnboardingMpModel {
    return (marketplace as OnboardingMpModel).onboardingTemplateMpKey !== undefined;
  }

  private isCreationPolicySetToAll(): boolean {
    return this.consolidateForm.controls['marketplaces'].get('marketplacesOption').value === 'show_all';
  }

  private preparePayloadForCreate(): ThemeOnboardingModel {
    return new ThemeOnboardingModel({
      // General Info
      onboardingTemplateCode: this.consolidateForm.controls['generalInformation'].get('templateName').value,
      supplierSite: this.consolidateForm.controls['generalInformation'].get('supplierSite').value,
      site: this.consolidateForm.controls['generalInformation'].get('siteUrlParameter').value,
      cssFile: this.consolidateForm.controls['generalInformation'].get('cssStylesheetName').value,
      loadNetEarnings: this.consolidateForm.controls['generalInformation'].get('loadNetEarnings').value,
      plaidStep: this.consolidateForm.controls['generalInformation'].get('plaidStep').value,
      // Marketplaces
      mpCreationPolicy: this.consolidateForm.controls['marketplaces'].get('marketplacesOption').value,
      mpTemplates: this.prepareMarketplaceList(),
      // Products
      instantAccess: this.consolidateForm.controls['products'].get('iAccess').value,
      offerAdvance: this.consolidateForm.controls['products'].get('iAdvance').value,
      ppmAvailable: this.consolidateForm.controls['products'].get('iTransfer').value,
      schedPayEnabled: this.consolidateForm.controls['products'].get('weeklyPayments').value,
      offerPcard: this.consolidateForm.controls['products'].get('pybSellerCard').value,
      instantPayouts: this.consolidateForm.controls['products'].get('iPayout').value,
      openInvoices: this.consolidateForm.controls['products'].get('openInvoices').value,
      // Payment Options
      wirAvailable: this.consolidateForm.controls['paymentOptions'].get('wirePayment').value,
      achAvailable: this.consolidateForm.controls['paymentOptions'].get('achPayment').value,
      sendTransferEmail: this.consolidateForm.controls['paymentOptions'].get('sendTransferEmail').value,
      efsRebatePercent: this.consolidateForm.controls['paymentOptions'].get('efsRebatePercent').value,
      minPaymentAmount: this.consolidateForm.controls['paymentOptions'].get('minPaymentAmount').value,
    });
  }

  private preparePayloadForUpdate(): ThemeOnboardingModel {
    return new ThemeOnboardingModel({
      // General Info
      supplierSite: this.consolidateForm.controls['generalInformation'].get('supplierSite').value,
      site: this.consolidateForm.controls['generalInformation'].get('siteUrlParameter').value,
      cssFile: this.consolidateForm.controls['generalInformation'].get('cssStylesheetName').value,
      loadNetEarnings: this.consolidateForm.controls['generalInformation'].get('loadNetEarnings').value,
      plaidStep: this.consolidateForm.controls['generalInformation'].get('plaidStep').value,
      // Products
      instantAccess: this.consolidateForm.controls['products'].get('iAccess').value,
      offerAdvance: this.consolidateForm.controls['products'].get('iAdvance').value,
      ppmAvailable: this.consolidateForm.controls['products'].get('iTransfer').value,
      schedPayEnabled: this.consolidateForm.controls['products'].get('weeklyPayments').value,
      offerPcard: this.consolidateForm.controls['products'].get('pybSellerCard').value,
      instantPayouts: this.consolidateForm.controls['products'].get('iPayout').value,
      openInvoices: this.consolidateForm.controls['products'].get('openInvoices').value,
      // Payment Options
      wirAvailable: this.consolidateForm.controls['paymentOptions'].get('wirePayment').value,
      achAvailable: this.consolidateForm.controls['paymentOptions'].get('achPayment').value,
      sendTransferEmail: this.consolidateForm.controls['paymentOptions'].get('sendTransferEmail').value,
      efsRebatePercent: this.consolidateForm.controls['paymentOptions'].get('efsRebatePercent').value,
      minPaymentAmount: this.consolidateForm.controls['paymentOptions'].get('minPaymentAmount').value,
    });
  }

  private prepareMarketplaceList(): any {
    const marketplaceList = this.consolidateForm.controls['marketplaces'].get('marketplaces').value;
    const mpCreationPolicy = this.consolidateForm.controls['marketplaces'].get('marketplacesOption').value;
    if (marketplaceList && mpCreationPolicy !== 'show_all') {
      return marketplaceList.map((marketplace: MarketplaceListModel) => {
        return {
          marketplace_key: marketplace.marketplaceKey,
        };
      });
    }
    return [];
  }

  private prepareReorderedMarketplaceList(): any {
    const marketplaceList = this.consolidateForm.controls['marketplaces'].get('marketplaces').value;
    if (marketplaceList) {
      const reorderedList = marketplaceList.map((marketplace: OnboardingMpModel) => {
        return marketplace.marketplaceKey;
      });
      return {
        reordered: reorderedList,
      };
    }
    return [];
  }

  private isInEditMode(): boolean {
    return !!this.id;
  }

  private getTemplateData(): void {
    this.subscriptions.push(
      this.onboardingTemplateService.getTemplateDetails(this.id).subscribe((result) => {
        if (result) {
          this.templateData = result;
          this.initialTemplateData = [...result.mpTemplates];
        }
      }),
    );
  }
}
