import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { CsvExportService } from '../../../shared/csv-export-service/csv-export.service';
import Logger from '../../../shared/logger';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { EfsService } from '../../services/efs.service';
import { PaymentConfigService } from '../../services/payment-config/payment-config.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { ROW_COUNT_COLUMN_DEF } from './../../../shared/data-table-grid/models/row-count-column-def';

export const cardType = {
  EFS: 'efs',
  MARQETA: 'marqeta',
};

@Component({
  selector: 'app-supplier-efs-account',
  templateUrl: './supplier-efs-account.component.html',
  styleUrls: ['./supplier-efs-account.component.scss'],
})
export class SupplierEfsAccountComponent extends SubscriberComponent implements OnInit {
  public creditInfo: any = {};
  public transactionDeliveryDetails: any = {};
  public chargeRecordDeliveries: any = {};

  public clearedTrasactionDeliveriesData: any;
  public clearedTrasactionDeliveriesDataLoading = true;
  public clearedTrasactionDeliveriesSettings = [
    ROW_COUNT_COLUMN_DEF,
    {
      field: 'transactionDate',
      headerName: 'Transaction date',
    },
    {
      field: 'transactionAmount',
      headerName: 'Transaction amount',
      type: 'currency',
    },
    {
      field: 'merchantName',
      headerName: 'Merchant name',
    },
    {
      field: 'clearingReferenceNumber',
      headerName: 'Transaction ID',
      cellClass: 'text-right',
      cellRenderer: (cellData: any) => {
        return `<a href="#/suppliers/${this.supplierKey}/card-transactions/${cellData.value}">${cellData.value}</a>`;
      },
    },
    {
      field: 'merchantCity',
      headerName: 'Merchant city',
    },
    {
      field: 'card_type',
      headerName: 'Card Type',
    },
  ];

  public rejectedTrasactionDeliveriesData: any;
  public rejectedTrasactionDeliveriesDataLoading = true;
  public rejectedTrasactionDeliveriesSettings = [
    ROW_COUNT_COLUMN_DEF,
    {
      field: 'transactionDate',
      headerName: 'Transaction date',
    },
    {
      field: 'transactionAmountMerchantCurrency',
      headerName: 'Rejected amount',
    },
    {
      field: 'rejectMessage',
      headerName: 'Rejection reason',
    },
    {
      field: 'merchantName',
      headerName: 'Merchant name',
    },
    {
      field: 'clearingReferenceNumber',
      headerName: 'Transaction ID',
      cellClass: 'text-right',
    },
    {
      field: 'merchantCity',
      headerName: 'Merchant city',
    },
    {
      field: 'card_type',
      headerName: 'Card Type',
    },
  ];

  public pendingTrasactionDeliveriesData: any;
  public pendingTrasactionDeliveriesDataLoading = true;
  public pendingTrasactionDeliveriesSettings = [
    ROW_COUNT_COLUMN_DEF,
    {
      field: 'transactionDate',
      headerName: 'Transaction date',
    },
    {
      field: 'transactionAmountUS',
      headerName: 'Transaction amount',
      type: 'currency',
    },
    {
      field: 'merchantName',
      headerName: 'Merchant name',
    },
    {
      field: 'clearingReferenceNumber',
      headerName: 'Transaction ID',
      cellClass: 'text-right',
    },
    {
      field: 'merchantCity',
      headerName: 'Merchant city',
    },
    {
      field: 'card_type',
      headerName: 'Card Type',
    },
  ];

  public efsPaymentsData;
  public efsPaymentsDataLoading = true;
  readonly efsPaymentsSettings = [
    ROW_COUNT_COLUMN_DEF,
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
    },
    {
      field: 'paymentAmount',
      headerName: 'Payment amount',
      tyoe: 'currency',
    },
    {
      field: 'documentNumber',
      headerName: 'Transaction ID',
      cellRenderer: (cellData: any) => {
        return `<a href="#/suppliers/${this.supplierKey}/card-transactions/${cellData.value}">${cellData.display}</a>`;
      },
    },
    {
      field: 'remitType',
      headerName: 'Reemit Type',
      cellClass: 'text-right',
    },
    {
      field: 'paymentCurrency',
      headerName: 'Currency',
    },
    {
      field: 'rebateAmount',
      headerName: 'Rebate Amount',
    },
    {
      field: 'rebatePercent',
      headerName: 'Rebate Percentage',
    },
    {
      field: 'card_type',
      headerName: 'Card Type',
    },
  ];

  public supplierInfo: SupplierDetailsModel;
  public supplierKey: string;

  public efsRebatePercent: number;
  public tableSettings: any;
  public cardTransactionsConfigForm: FormGroup = null;
  public isVelocityControlsFormVisible = false;

  public cardType = 'efs';

  time = 7;
  times = [3, 7, 14, 30, 60, 90];

  constructor(
    private paymentConfigsService: PaymentConfigService,
    private toastrService: ToastrService,
    private efsService: EfsService,
    private supplierService: SupplierService,
    private csvExportService: CsvExportService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private dialogService: MatDialog,
    private route: ActivatedRoute,
  ) {
    super();
  }

  updateEFSRebate() {
    const dataToSave = {
      ...this.supplierInfo,
      efsRebatePercent: this.efsRebatePercent,
    };

    this.subscriptions.push(
      this.supplierService.saveItem(this.supplierKey, new SupplierDetailsModel(dataToSave)).subscribe(
        () => {
          this.toastrService.success('EFS rebate percentage updated.');
        },
        () => {
          this.toastrService.error('Could not save EFS rebate percentage');
        },
      ),
    );
  }

  ngOnInit() {
    this.getCardType();
    this.initCardTransactionsConfigForm();
    this.initData();
  }

  getCardType() {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        if (params && params.hasOwnProperty('cardType')) {
          this.cardType = params['cardType'];
        }
      }),
    );
  }

  initData() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe(
        (supplierDetailsModel: SupplierDetailsModel) => {
          if (!isUndefined(supplierDetailsModel)) {
            this.supplierInfo = supplierDetailsModel;
            this.supplierKey = supplierDetailsModel.supplierKey;
            this.efsRebatePercent = supplierDetailsModel.efsRebatePercent;

            this.loadData();
            this.getCardTransactionConfig();
          }
        },
        (error) => {
          if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
            this.toastrService.error(error.error.message, 'Could not get supplier info');
          }
        },
      ),
    );
  }

  loadData() {
    this.subscriptions.push(
      this.paymentConfigsService
        .query(this.supplierKey)
        .pipe(
          map((data) => {
            const payments = data.map((payment) => payment.paymentType);
            const efsPayment = payments.indexOf('EFS') > -1 ? data[payments.indexOf('EFS')] : null;
            let companyId = null;

            if (!isNullOrUndefined(efsPayment)) {
              companyId = efsPayment.paymentConfig.efs.companyId;
            }

            return {
              companyNumber: companyId,
              keyFields: {
                transactionStartDate: moment().subtract(this.time, 'days').format('YYYY-MM-DD'),
              },
            };
          }),
        )
        .subscribe(
          (data) => {
            const shortData = {
              companyNumber: data.companyNumber,
            };
            const paymentsData = {
              companyNumber: data.companyNumber,
              startTransactionDate: data.keyFields.transactionStartDate,
            };

            this.getAllEFSData(data, paymentsData);
          },
          (error) => {
            if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
              this.toastrService.error(error.error.message, 'Could not fetch supplier payment configs');
            }
          },
        ),
    );
  }

  getAllEFSData(data, paymentsData) {
    this.efsPaymentsDataLoading = true;
    this.pendingTrasactionDeliveriesDataLoading = true;
    this.clearedTrasactionDeliveriesDataLoading = true;
    this.rejectedTrasactionDeliveriesDataLoading = true;

    this.subscriptions.push(
      this.efsService.getEFS(this.supplierKey, 'get_transaction_delivery_details', data).subscribe(
        (result) => {
          this.transactionDeliveryDetails = result;
        },
        (error) => {
          if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
            this.toastrService.error(error.error.message, 'Could not get Transaction Delivery Details data.');
          }
        },
      ),
    );

    this.subscriptions.push(
      this.efsService
        .getEFS(this.supplierKey, 'get_pending_trasaction_deliveries', data)
        .pipe(
          tap(() => {
            this.pendingTrasactionDeliveriesDataLoading = false;
          }),
        )
        .subscribe(
          (result) => {
            this.pendingTrasactionDeliveriesData = result[0].memoSet ? this.mapCardTypeValue(result[0].memoSet.MemoData) : [];
          },
          (error) => {
            this.pendingTrasactionDeliveriesDataLoading = false;
            if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
              this.toastrService.error(error.error.message, 'Could not get Pending Transaction Deliveries data.');
            }
          },
        ),
    );

    this.subscriptions.push(
      this.efsService
        .getEFS(this.supplierKey, 'get_cleared_trasaction_deliveries', data)
        .pipe(
          tap(() => {
            this.clearedTrasactionDeliveriesDataLoading = false;
          }),
        )
        .subscribe(
          (result) => {
            this.clearedTrasactionDeliveriesData = result[0].clearedSet ? this.mapCardTypeValue(result[0].clearedSet.Cleared) : [];
          },
          (error) => {
            this.clearedTrasactionDeliveriesDataLoading = false;
            if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
              this.toastrService.error(error.error.message, 'Could not get Cleared Trasaction Deliveries data.');
            }
          },
        ),
    );

    this.subscriptions.push(
      this.efsService
        .getEFS(this.supplierKey, 'get_rejected_trasaction_deliveries', data)
        .pipe(
          tap(() => {
            this.rejectedTrasactionDeliveriesDataLoading = false;
          }),
        )
        .subscribe(
          (result) => {
            this.rejectedTrasactionDeliveriesData = result[0].rejectsSet ? this.mapCardTypeValue(result[0].rejectsSet.Rejects) : [];
          },
          (error) => {
            this.rejectedTrasactionDeliveriesDataLoading = false;
            if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
              this.toastrService.error(error.error.message, 'Could not get Rejected Trasaction Deliveries data.');
            }
          },
        ),
    );

    this.subscriptions.push(
      this.efsService.getEFS(this.supplierKey, 'get_charge_record_deliveries', data).subscribe(
        (result) => {
          result = this.mapCardTypeValue(result);
          this.chargeRecordDeliveries = result;
        },
        (error) => {
          if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
            this.toastrService.error(error.error.message, 'Could not get Charge Record Deliveries data.');
          }
        },
      ),
    );

    this.subscriptions.push(
      this.efsService.getEFS(this.supplierKey, 'get_credit_info', data).subscribe(
        (result) => {
          this.creditInfo = result[0];
        },
        (error) => {
          if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
            this.toastrService.error(error.error.message, 'Could not get Credit Info data.');
          }
        },
      ),
    );

    this.subscriptions.push(
      this.efsService
        .getEFS(this.supplierKey, 'get_payments', paymentsData)
        .pipe(
          tap(() => {
            this.efsPaymentsDataLoading = false;
          }),
        )
        .subscribe(
          (result) => {
            if (!isNullOrUndefined(result[0].paymentData) && !isNullOrUndefined(result[0].paymentData.PaymentData)) {
              this.efsPaymentsData = result[0].paymentData.filter ? this.mapCardTypeValue(result[0].paymentData.PaymentData) : [];
            } else {
              this.efsPaymentsData = result && result.length > 0 ? this.mapCardTypeValue(result) : [];
            }
          },
          (error) => {
            this.efsPaymentsDataLoading = false;
            if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
              this.toastrService.error(error.error.message, 'Could not get Payments data.');
            }
          },
        ),
    );
  }

  exportAllData() {
    this.subscriptions.push(
      this.exportData({}).subscribe((data) => {
        this.csvExportService.exportTransactions(data, 'EFS', '_all');
      }),
    );
  }

  exportCurrentData() {
    this.subscriptions.push(
      this.exportData({
        start_date: moment().subtract(this.time, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      }).subscribe((data) => {
        this.csvExportService.exportTransactions(data, 'EFS', '_current');
      }),
    );
  }

  exportData(data) {
    return this.efsService.downloadEFS(this.supplierKey, {
      make_dump: true,
      keyFields: { numberOfTransactionsRequested: 1000 },
      ...data,
    });
  }

  public pendingTransaction() {
    this.subscriptions.push(
      this.paymentConfigsService.pendingTransactions(this.supplierKey).subscribe(
        () => {},
        () => {},
      ),
    );
    this.initData();
  }

  public rejectedTransaction() {
    this.subscriptions.push(
      this.paymentConfigsService.rejectedTransactions(this.supplierKey).subscribe(
        () => {},
        () => {},
      ),
    );
    this.initData();
  }

  public chargeTransaction() {
    this.subscriptions.push(
      this.paymentConfigsService.chargeTransactions(this.supplierKey).subscribe(
        () => {},
        () => {},
      ),
    );
    this.initData();
  }

  public reversalTransaction() {
    this.subscriptions.push(
      this.paymentConfigsService.reversalTransactions(this.supplierKey).subscribe(
        () => {},
        () => {},
      ),
    );
    this.initData();
  }

  public refundTransaction() {
    this.subscriptions.push(
      this.paymentConfigsService.refundTransactions(this.supplierKey).subscribe(
        () => {},
        () => {},
      ),
    );
    this.initData();
  }

  public saveCardTransactionsConfig({ value }) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        message: 'Editing this field will modify the internal velocity check for this supplier.',
        isProceedButtonVisible: true,
      },
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.updateCardTransactionsConfig(value);
        }
      }),
    );
  }

  public isProduction() {
    return window.location && window.location.href ? window.location.href.includes('internal.payability.com') : false;
  }

  private updateCardTransactionsConfig(value) {
    this.subscriptions.push(
      this.paymentConfigsService.updateCardTransactionsConfig(this.supplierKey, value).subscribe(
        () => this.toastrService.success('Card Transactions Configuration has been successfully updated'),
        (error) => Logger.error(error),
      ),
    );
  }

  private getCardTransactionConfig() {
    this.subscriptions.push(
      this.paymentConfigsService.getCardTransactionsConfig(this.supplierKey).subscribe(
        (response: any) => this.getCardTransactionConfigSuccess(response),
        (error) => Logger.error(error),
      ),
    );
  }

  private getCardTransactionConfigSuccess(response) {
    if (!isNullOrUndefined(response)) {
      this.patchCardTransactionsConfigForm(response);
    }
  }

  private mapCardTypeValue(transactionsData) {
    if (!isNullOrUndefined(transactionsData)) {
      const transactions = [];
      for (const value of transactionsData) {
        value.card_type = isNullOrUndefined(value.card_type) ? 'EFS' : value.card_type.toUpperCase();
        if (value.card_type === this.cardType.toUpperCase()) {
          transactions.push(value);
        }
      }

      return transactions;
    }

    return [];
  }

  private initCardTransactionsConfigForm() {
    this.cardTransactionsConfigForm = this.formBuilder.group({
      max_xact_count_per_hour: [null],
      max_xact_amount_at_once: [null],
      max_xact_amount_daily: [null],
    });
  }

  private patchCardTransactionsConfigForm(configObject) {
    if (!isNullOrUndefined(configObject)) {
      this.cardTransactionsConfigForm.patchValue(configObject);
      this.isVelocityControlsFormVisible = true;
    }
  }
}
