<h1 mat-dialog-title>Create transfer request</h1>
<div mat-dialog-content>
  <ng-container *ngIf="!loading; else loadingCard">
    <div class="alert alert-danger" *ngIf="supplierBalanceIsLowerThanMin">
      You can not create transfer request as supplier balance is lower than minimal transfer amount
    </div>
    <div [ngClass]="{ disabled: supplierBalanceIsLowerThanMin }">
      <form [formGroup]="form" *ngIf="form">
        <h6>Transfer to:</h6>
        <mat-radio-group *ngIf="radiobuttons && radiobuttons.length; else noMethods" formControlName="paymentConfigKey">
          <mat-radio-button *ngFor="let option of radiobuttons; trackBy: trackBy" [disabled]="option.disabled" [value]="option.value">
            <span matTooltip="Method is not approved" [matTooltipDisabled]="!option.disabled">
              <span class="d-flex align-items-center" [ngClass]="{ disabled: option.disabled }">
                <span>
                  {{ option.text }}
                </span>
                <span *ngIf="option.isDefault" class="badge badge-secondary badge-info ml-2 d-flex">default</span>
              </span>
            </span>
          </mat-radio-button>
        </mat-radio-group>

        <div class="d-flex align-items-center justify-content-between mt-2">
          <mat-form-field class="w-50">
            <input matInput placeholder="Transfer amount" type="number" step="0.01" formControlName="requestedAmount" />
            <mat-error>Minimum amount is {{ MIN_TRANSFER_AMOUNT }}$, maximum - {{ availableBalance }}$</mat-error>
          </mat-form-field>

          <mat-slide-toggle color="accent" formControlName="transferEntireBalance">
            <label>Transfer Entire Balance</label>
          </mat-slide-toggle>
        </div>
      </form>

      <div class="alert alert-danger mt-2">
        This action will create a fund transfer request and will lower the customer's available balance.
      </div>
    </div>
  </ng-container>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="form">
  <button mat-raised-button (click)="onSaveClick()" [disabled]="!form.valid" tabindex="2" color="accent">Save</button>
  <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
</div>

<ng-template #loadingCard>
  <div class="card-content">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #noMethods>
  <app-no-data [text]="'There is no available transfer methods'"></app-no-data>
</ng-template>
