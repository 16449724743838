<div class="card">
  <div class="card-content">
    <app-underwriting-initial-card-header
      [chipText]="'Automated'"
      [chipColor]="'accent'"
      [title]="'Amazon Revenue'"
      [status]="headerStatus"
      [type]="headerType"
    >
    </app-underwriting-initial-card-header>
    <div class="table-wrapper">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="20%" class="text-right">Target</th>
            <th width="20%" class="text-right">Value</th>
            <th width="10%" class="text-right">Automated</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-right" *ngFor="let sale of amazonSales; let $index = index">
            <td *ngIf="amazonSales.length === 0; else salesData" colspan="4">No revenue data available.</td>
            <ng-template #salesData>
              <td class="text-right">
                {{ sale?.date }}
              </td>
              <td class="text-right">$2,000+</td>
              <td class="text-right">
                {{ sale?.value | currency: 'USD':'symbol':'1.0-0' }}
              </td>
              <td class="text-right" width="20%">
                <span *ngIf="sale.value >= 2000" class="text-success"> Valid </span>
                <span *ngIf="sale.value < 2000" class="text-danger"> Needs review </span>
              </td>
            </ng-template>
          </tr>
          <tr>
            <td colspan="2">Override Amazon Revenue results</td>
            <td colspan="2" class="text-right" width="20%">
              <ng-container *ngIf="!computingDataIsInProgress">
                <app-radiobuttons
                  [value]="amazonRevenueOverride[0].value"
                  [labelPosition]="'before'"
                  [options]="radiobuttons"
                  (onChange)="radiobuttonsChange($event)"
                >
                </app-radiobuttons>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
