import { UwToolEntity } from './../../../../shared/models/uw-tool-entity.model';
export interface UnderwritingState {
  toolsLoaded: boolean;
  tools: UwToolEntity[];
}

export const UNDERWRITING_STATE: UnderwritingState = {
  toolsLoaded: false,
  tools: [],
};
