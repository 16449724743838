import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FadeOut } from '../../../../../shared/animations/fade-out';
import { ALL_OPTION } from '../../../../../shared/checkbox-filters/all-option';
import { ICheckboxFiltersOption } from '../../../../../shared/checkbox-filters/checkbox-filters-option.interface';
import { COOKIE_TYPES } from '../../../../../shared/services/cookie-service/cookie-options';
import { CookieService } from '../../../../../shared/services/cookie-service/cookie-service';
import { BackgroundReportSectionModel } from '../../../../model/background-report/background-report-section.model';
import { IBackgroundReportTable } from '../../../../model/background-report/background-report-table';
import { SupplierOwnerModel } from '../../../../model/supplier-owner.model';

@Component({
  selector: 'app-background-report-card',
  templateUrl: './background-report-card.component.html',
  styleUrls: ['./background-report-card.component.scss'],
  animations: [FadeOut],
})
export class BackgroundReportCardComponent implements OnInit {
  @Input() reportTitle: string;
  @Input() supplierKey: string;
  @Input() owner: SupplierOwnerModel;
  @Input() set availableSections(availableSections: BackgroundReportSectionModel[]) {
    if (availableSections === null || availableSections === undefined) {
      return;
    }

    this._availableSections = availableSections;
    this.filterOptions = availableSections.map((section) => {
      return {
        checked: this.hasCheckedOption(section.key) || this.hasCheckedOption(ALL_OPTION),
        value: section.key,
        title: section.sectionTitle,
      };
    });
    this.emitFilterOptions();
    this.setReportsToShow();
  }

  @Input() set allReportsMap(allReports: { [key: string]: IBackgroundReportTable }) {
    this._allReportsMap = allReports;
    this.setReportsToShow();
  }

  @Output() filterOptionsChanged = new EventEmitter<string[]>();
  filterOptions: ICheckboxFiltersOption[] = [];
  filteredReportsToShow: IBackgroundReportTable[] = [];
  _availableSections: BackgroundReportSectionModel[] = [];

  readonly selectReportText = 'Please, select a report';
  readonly noSectionsAvailableText = 'There is no report data available for this person';

  private _allReportsMap: { [key: string]: IBackgroundReportTable };

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    this.initSelectedReportOptions();
  }

  filtersSelectionChange(newOptions: ICheckboxFiltersOption[]) {
    this.filterOptions = newOptions;
    this.emitFilterOptions();
    this.saveSelectedReportOptions();
    this.setReportsToShow();
  }

  private emitFilterOptions() {
    const selectedReportTypes = this.filterOptions
      .filter((option) => option.value !== ALL_OPTION && option.checked === true)
      .map((option) => option.value);
    this.filterOptionsChanged.emit(selectedReportTypes);
  }

  private setReportsToShow() {
    this.filteredReportsToShow = [];
    this.filterOptions
      .filter((option) => option.checked)
      .forEach((option) => {
        if (this._allReportsMap && this._allReportsMap[option.value]) {
          this.filteredReportsToShow.push(this._allReportsMap[option.value]);
        }
      });
  }

  private hasCheckedOption(optionCheck: string): boolean {
    const findFilterOption = this.filterOptions.find((option) => option.value === optionCheck);
    return findFilterOption && findFilterOption.checked;
  }

  private saveSelectedReportOptions(): void {
    const optionsValue = JSON.stringify(this.filterOptions);
    this.cookieService.setCookie(COOKIE_TYPES.SELECTED_REPORT_OPTIONS, optionsValue);
  }

  private initSelectedReportOptions(): void {
    const cookie = this.cookieService.getCookie(COOKIE_TYPES.SELECTED_REPORT_OPTIONS);
    const selectedFilterOptionsCookie = cookie && JSON.parse(this.cookieService.getCookie(COOKIE_TYPES.SELECTED_REPORT_OPTIONS));
    if (!selectedFilterOptionsCookie) {
      this.filterOptions = [{ checked: true, value: ALL_OPTION, title: ALL_OPTION }];
    } else {
      this.filterOptions = selectedFilterOptionsCookie;
    }
    this.saveSelectedReportOptions();
  }
}
