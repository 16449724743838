import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BaseFilterControl } from '../base-filter-control';
import { FilteringPanelService } from './../../services/filtering-panel-service/filtering-panel.service';

@Component({
  selector: 'app-filter-control-input',
  templateUrl: './filter-control-input.component.html',
  styleUrls: ['./filter-control-input.component.scss'],
})
export class FilterControlInputComponent extends BaseFilterControl implements OnInit {
  inputValue$ = new Subject<string>();
  lastFilterValue: string;
  inputValueSub$: Subscription;
  readonly searchTimeout = 1000;

  constructor(protected filteringPanelService: FilteringPanelService) {
    super(filteringPanelService);
  }

  ngOnInit(): void {
    this.subscribeOnInputValue();
  }

  subscribeOnInputValue(): void {
    this.inputValueSub$ = this.inputValue$.pipe(debounceTime(this.searchTimeout), distinctUntilChanged()).subscribe((value) => {
      this.setValue(value);
    });
  }

  unsubscribeFromInputValue(): void {
    if (this.inputValueSub$) {
      this.inputValueSub$.unsubscribe();
    }
  }

  onKeyup(value: string): void {
    this.inputValue$.next(value);
  }

  onBlur(value: string): void {
    if (value === this.lastFilterValue) {
      return;
    }
    this.setValue(value);
    this.unsubscribeFromInputValue();
  }

  onFocus(): void {
    this.subscribeOnInputValue();
  }

  setValue(value: string): void {
    this.lastFilterValue = value;
    this.onValueChange(value);
  }
}
