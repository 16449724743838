import { IExternalReportDTO } from '../dto/external-report-dto';
import { IExternalReport } from './i-external-report';

export class ExternalReport implements IExternalReport {
  customDescription: any;
  customName: any;
  customParamType: string;
  customParamUrl: string;
  description: string;
  key: string;
  name: string;
  platform: string;
  url: string;

  constructor({
    Description: description,
    Key: key,
    Name: name,
    Platform: platform,
    Url: url,
    CustomDescription: customDescription,
    CustomName: customName,
    CustomParamType: customParamType,
    CustomParamUrl: customParamUrl,
  }: IExternalReportDTO) {
    Object.assign(this, {
      description,
      key,
      name,
      platform,
      url,
      customDescription,
      customName,
      customParamType,
      customParamUrl,
    });
  }
}
