/* tslint:disable */
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, mergeMap, share, switchMap } from 'rxjs/operators';

import { IEnvironment } from '../../../../environments/environment.interface';
import { AppConfigService } from '../../../config';
import { FEATURE_FLAGS } from '../../../shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { LookupRepository } from '../../../shared/lookup-service/lookup-repository';
import { DOCUMENT_TYPE } from '../../../shared/pyb-documents/pyb-documents.enum';
import { FileCompliance } from '../../../shared/pyb-documents/pyb-documents.interface';
import { AppState } from '../../../store/app.reducers';
import { ComplianceDoctype } from '../../model/compliance.model';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SupplierVerifyDocumentDialogComponent } from '../supplier-onboarding/supplier-verify-document-dialog/supplier-verify-document-dialog.component';
import { AppsDocumentService } from './../../../shared/apps-document/apps-document.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { SUPPLIER_STATUS } from './../../../shared/consts/supplier-status.enum';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';
import { KYC_DOCUMENTATION_STATUS } from './../../enums/kyc-documentation-status.enum';
import { KycDocument, KycFileUpload, KycFileUploadResponse, KycGeneralResponse, KycUpdateCompliance } from './../../model/compliance.model';
import { SupplierDetailsModel } from './../../model/supplier.model';
import { SupplierComplianceActionService } from './../../services/supplier-compliance-action.service';

/* eslint-enable */

interface ReplaceKycDocumentsModel {
  compliance_doctype_key: string;
  payload: KycFileUpload;
}

@Component({
  selector: 'app-kyc-documentation',
  templateUrl: './kyc-documentation.component.html',
  styleUrls: ['./kyc-documentation.component.scss'],
})
export class KycDocumentationComponent extends SubscriberComponent {
  @Input() set supplierKey(supplierKey: string) {
    this.localSupplierKey = supplierKey;
    this.setGciId();
    this.initListeners();
  }

  readonly userPermission = USER_PERMISSION;
  readonly supplierStatus = SUPPLIER_STATUS;
  readonly IDENTITY_TYPE_NAME = 'Identity';
  readonly KYC_DOCUMENTATION_STATUS = KYC_DOCUMENTATION_STATUS;
  readonly supplierDetails$ = this.store.select(selectSupplierDetails).pipe(
    filter((data) => !!data),
    share(),
  );

  localSupplierKey: string;
  signButtonLoading = false;
  actionsWithFiles: any[];
  supplierDetails: SupplierDetailsModel = null;

  private complianceDoctypes: ComplianceDoctype[];
  private gciId: IEnvironment['workflowGciId'];
  private getKycDocuments$ = new Subject();
  private replaceKycDocument$ = new Subject<ReplaceKycDocumentsModel>();

  private readonly kycDocumentsSub$ = this.getKycDocuments$.pipe(mergeMap(() => this.lookupService.getKycDocuments(this.localSupplierKey)));
  private readonly replaceKycDocumentSub$ = this.replaceKycDocument$.pipe(mergeMap((data) => this.doUploadFile(data)));

  constructor(
    private lookupService: LookupRepository,
    public dialogService: MatDialog,
    private permissionsService: NgxPermissionsService,
    private appsDocumentsService: AppsDocumentService,
    private appConfig: AppConfigService,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private supplierComplianceActionService: SupplierComplianceActionService,
    private store: Store<AppState>,
    private featureFlagsService: FeatureFlagsService,
  ) {
    super();
  }

  setGciId(): void {
    this.gciId = this.appConfig.env.workflowGciId;
  }

  initListeners(): void {
    this.subscriptions.push(
      combineLatest([this.lookupService.getComplianceDoctypes(), this.kycDocumentsSub$]).subscribe((data) => this.doKycInit(data)),
    );

    this.subscriptions.push(
      this.replaceKycDocumentSub$.subscribe((isOK) => {
        if (!isOK) {
          this.toastrService.error('Cannot upload document');
          return;
        }
        this.fetchKycDocuments();
      }),
    );

    this.fetchKycDocuments();
  }

  docTypeNameByKey(key) {
    if (this.complianceDoctypes) {
      let result;
      const foundedDocType = this.complianceDoctypes.filter(function (type) {
        return type.id === key;
      });

      if (foundedDocType[0] !== undefined && foundedDocType[0].label !== undefined) {
        result = foundedDocType[0].label;
      }

      return result;
    }
  }

  signContract() {
    this.signButtonLoading = true;
    this.subscriptions.push(
      this.lookupService
        .getDocuSignLink(this.localSupplierKey)
        .pipe(finalize(() => (this.signButtonLoading = false)))
        .subscribe((docuSignUrl) => {
          window.location.replace(docuSignUrl.url);
        }),
    );
  }

  openVerifyDocumentDialog(actionData, actionName: string) {
    if (!this.isRestrictedUWAccess()) {
      const dialogRef = this.dialogService.open(SupplierVerifyDocumentDialogComponent, {
        data: { actionData, actionName },
        width: '450px',
      });

      this.subscriptions.push(
        dialogRef.afterClosed().subscribe(() => {
          this.fetchKycDocuments();
        }),
      );
    }
  }

  onFileUpload(event: Event, action: KycDocument): void {
    const payload: KycFileUpload = {
      file: event.target,
      gci: this.appConfig.env.workflowGciId,
      wniosekId: '',
      docType: action.compliance_doctype_name,
    };

    this.replaceKycDocument$.next({
      compliance_doctype_key: action.satisfied_by.file.compliance_doctype_key,
      payload,
    });
  }

  onUploadFileClick(event: PointerEvent): void {
    (event.target as any).value = null;
  }

  private doKycInit([complianceDoctypes, data]): void {
    this.complianceDoctypes = complianceDoctypes;

    data.forEach((item) => {
      const thumbnailId = item.satisfied_by.file.thumbnail_id;
      if (!thumbnailId) {
        return;
      }
      item.downloadThumbnailUrlLoading = true;
      this.subscriptions.push(
        this.appsDocumentsService.getFileDownloadUrl(this.gciId, thumbnailId, true).subscribe(
          (downloadUrl) => {
            if (downloadUrl) {
              item.downloadThumbnailUrl = this.appsDocumentsService.sanitize(downloadUrl);
            }
            item.downloadThumbnailUrlLoading = false;
          },
          () => {
            item.downloadThumbnailUrlLoading = false;
          },
        ),
      );
    });

    this.actionsWithFiles = data.map((action) => {
      action.compliance_doctype_name = this.docTypeNameByKey(action.satisfied_by.file.compliance_doctype_key);

      return action;
    });
  }

  private doUploadFile(data: ReplaceKycDocumentsModel): Observable<boolean> {
    const fileCompliance: FileCompliance = {
      document_type: DOCUMENT_TYPE.IDENTIFICATION_DOCUMENT,
      compliance_key: '869f7aaf-e9bd-4f85-bfa6-6a0b0d5616a2',
    };

    return this.appsDocumentsService.uploadFile(data.payload, true, true, this.localSupplierKey, fileCompliance).pipe(
      map((res) => this.appsDocumentsService.uploadKycFileMapper(res)),
      filter((res: KycFileUploadResponse) => {
        if (res.status !== 'OK') {
          this.toastrService.error('Cannot upload document');
          return false;
        }

        return true;
      }),
      switchMap((res) => {
        if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
          this.fetchKycDocuments();
          return null;
        }
        return this.updateCompliance(data.compliance_doctype_key, res);
      }),
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  private updateCompliance(compliance_doctype_key: string, data: KycFileUploadResponse): Observable<KycGeneralResponse> {
    const { name, thumbnailUUID, uuid } = data.doc;

    const payload: KycUpdateCompliance = {
      status: KYC_DOCUMENTATION_STATUS.PENDING,
      file: {
        compliance_doctype_key,
        file_id: uuid,
        file_name: name,
        file_url: null,
        thumbnail_id: thumbnailUUID,
      },
    };
    return this.supplierComplianceActionService.updateCompliance(this.localSupplierKey, payload);
  }

  private fetchKycDocuments(): void {
    this.getKycDocuments$.next();
  }

  private isRestrictedUWAccess() {
    return this.permissionsService.getPermission(USER_PERMISSION.REST_UW_ACCESS) != null;
  }
}
