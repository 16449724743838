<div class="table-options">
  <div [class.hidden]="filtersSummary.length <= 0" class="options-summary">
    <div class="filters-summary">Filtered by: {{ filtersSummary }}</div>
  </div>

  <div class="options-menu">
    <button class="form-group-edit filter-opt" mat-icon-button (click)="openFilters()">
      <mat-icon class="material-icon">filter_list</mat-icon>
    </button>
  </div>

  <div class="options-content" [class.open]="panelOpened">
    <div class="options-wraper">
      <div class="table-filters" *ngIf="showFilters">
        <div class="params">
          <ng-template #controlsOutlet></ng-template>
        </div>

        <div class="actions">
          <button mat-raised-button class="action-button" (click)="clearFilters()">Clear</button>
        </div>
      </div>

      <!-- Search -->
      <div class="table-search" *ngIf="showSearch">
        <div class="params">
          <div class="search-arg"></div>
        </div>

        <div class="actions">
          <div class="search-summary"></div>
          <button mat-raised-button class="action-button" [color]="'primary'">Highlight</button>
          <button mat-raised-button class="action-button">Clear</button>
        </div>
      </div>
    </div>
  </div>
</div>
