import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { DeleteSupplierAccountService } from './delete-supplier-account.service';

@Component({
  selector: 'app-delete-supplier-account',
  templateUrl: './delete-supplier-account.component.html',
})
export class DeleteSupplierAccountComponent extends SubscriberComponent implements OnInit {
  @Input() supplierKey: string;

  canBeRemoved = false;

  constructor(
    public readonly deleteSupplierAccountService: DeleteSupplierAccountService,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.deleteSupplierAccountService.canBeRemoved(this.supplierKey).subscribe((status) => (this.canBeRemoved = status)),
    );
  }

  onDeleteAccountClick(): void {
    const msg = 'Do you want to delete the supplier account?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL.width,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.deleteAccount();
        }
      }),
    );
  }

  private deleteAccount(): void {
    this.subscriptions.push(
      this.deleteSupplierAccountService
        .deleteAccount(this.supplierKey)
        .pipe(catchError(() => this.handleError()))
        .subscribe((result) => {
          if (result.success) {
            this.toastrService.success('Account deletion process has started');
            this.router.navigate(['/']);

            return;
          }

          this.showErrorToast();
        }),
    );
  }

  private handleError(): Observable<never> {
    this.showErrorToast();

    return EMPTY;
  }

  private showErrorToast(): void {
    this.toastrService.error('The account deletion process has not been initiated. Please contact technical support.');
  }
}
