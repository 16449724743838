<div class="textValue">
  <label class="textValue__label">
    {{ label ? label : '-' }}
  </label>
  <p class="textValue__value" *ngIf="showValue">
    <span *ngIf="value; else emptyValueSpan">{{ value }}</span>
  </p>
  <ng-content></ng-content>
</div>

<ng-template #emptyValueSpan>
  <span>{{ emptyValue ? emptyValue : ' -empty value-' }}</span>
</ng-template>
