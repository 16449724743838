<section class="iconTextContainer d-flex">
  <div class="iconTextContainer__iconBox" *ngIf="iconClass">
    <i [class]="'iconTextContainer__icon fa' + ' ' + iconClass" aria-hidden="true"></i>
  </div>
  <div class="iconTextContainer__text">
    <p class="iconTextContainer__title" *ngIf="title">
      {{ title }}
    </p>
    <p class="iconTextContainer__subtitle" *ngIf="subtitle">
      {{ subtitle }}
    </p>
  </div>
</section>
