<ng-template #loadingForm>
  <div class="form-content">
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #noMarketplace>
  <h5>No Amazon Marketplace</h5>
</ng-template>

<ng-template #loadingCard>
  <div class="card-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>

<div *ngIf="!amazonMarketplaceDetailsLoading; else loadingCard">
  <div *ngIf="amazonMarketplaceDetails; else noMarketplace">
    <div class="marketplaceAccess__header">
      <div class="marketplaceAccess__header__title">
        <h5>{{ title }}</h5>
        <div data-test="marketplaceAccessStatus">
          <span class="status status-{{ amazonMarketplaceDetails.accessStatus }}"> {{ amazonMarketplaceDetails.accessStatus }}</span>
        </div>
      </div>

      <div class="marketplaceAccess__header__buttons">
        <button
          mat-raised-button
          color="accent"
          (click)="loginToMarketplace(amazonMarketplaceDetails); $event.preventDefault(); $event.stopPropagation()"
          [disabled]="!amazonMarketplaceDetails.externalLogin || !amazonMarketplaceDetails.externalPassword"
          data-test="marketplaceLoginButton"
        >
          Login
        </button>
      </div>
    </div>

    <form
      app-card-formgroup
      *ngIf="formGroups[MARKETPLACE_ACCESS_FORM]; else loadingForm; let marketplaceAccessForm"
      #formMpAccess
      [formGroup]="marketplaceAccessForm"
      (onSave)="saveFormGroup(marketplaceAccessForm, MARKETPLACE_ACCESS_FORM, customRequestMapper.bind(this))"
      data-test="marketplaceAccessForm"
    >
      <div class="row">
        <app-inline-edit-select
          *ngIf="formMpAccess.inEdit"
          class="col-md-12"
          [formControlName]="'accessStatus'"
          [formGroup]="marketplaceAccessForm"
          [label]="'Access Status'"
          [inGroupEdit]="formMpAccess.inEdit"
          [items]="accessStatuses"
          (onSave)="saveSingleField('accessStatus', $event, MARKETPLACE_ACCESS_FORM, customRequestMapper.bind(this))"
          data-test="accessStatusSelect"
        >
        </app-inline-edit-select>

        <app-inline-edit-text
          class="col-md-12"
          [formControlName]="'externalSupplierId'"
          [formGroup]="marketplaceAccessForm"
          [label]="'External Supplier ID'"
          [inGroupEdit]="formMpAccess.inEdit"
          [showCopy]="true"
          (onSave)="saveSingleField('externalSupplierId', $event, MARKETPLACE_ACCESS_FORM, customRequestMapper.bind(this))"
          data-test="externalSupplierdIDInput"
        >
        </app-inline-edit-text>

        <app-inline-edit-text
          class="col-md-12"
          [formControlName]="'externalLogin'"
          [formGroup]="marketplaceAccessForm"
          [label]="'Supplier Username'"
          [inGroupEdit]="formMpAccess.inEdit"
          [showCopy]="true"
          (onSave)="saveSingleField('externalLogin', $event, MARKETPLACE_ACCESS_FORM, customRequestMapper.bind(this))"
          data-test="supplierUsernameInput"
        >
        </app-inline-edit-text>

        <app-inline-edit-text
          type="password"
          class="col-md-12"
          [formControlName]="'externalPassword'"
          [formGroup]="marketplaceAccessForm"
          [label]="'Supplier Password'"
          [inGroupEdit]="formMpAccess.inEdit"
          (onSave)="saveSingleField('externalPassword', $event, MARKETPLACE_ACCESS_FORM, customRequestMapper.bind(this))"
          data-test="supplierPasswordInput"
        >
        </app-inline-edit-text>
      </div>
    </form>

    <app-supplier-auth-token
      *ngIf="
        amazonMarketplaceDetails.externalLogin ||
        amazonMarketplaceDetails.externalPassword ||
        amazonMarketplaceDetails.marketplaceKey == '37036286-6ab8-427e-8a5f-ce53cac22f3e'
      "
    ></app-supplier-auth-token>
  </div>
</div>
