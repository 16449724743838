import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ConfigModel } from '../../../shared/configs/config.model';
import { ConfigsService, ConfigTypes } from '../../../shared/configs/service/configs.service';
import { TOOLS_THEME } from '../../../shared/editor/editor.enum';
import {
  ALLOW_TEMPLATES_VARIABLES_MISCALENOUS,
  ALLOW_TEMPLATES_VARIABLES_SUPPLIER,
  ALLOW_TEMPLATES_VARIABLES_USER,
  EMAIL_TYPE_FORM_FIELDS,
} from '../email-form-fields';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { EditorComponent } from './../../../shared/editor/editor.component';

@Component({
  selector: 'app-email-edit-config-dialog',
  templateUrl: './email-edit-config-dialog.component.html',
  styleUrls: ['./email-edit-config-dialog.component.scss'],
})
export class EmailEditConfigDialogComponent extends SubscriberComponent implements OnInit {
  readonly TOOLS_THEME = TOOLS_THEME;
  form: FormGroup;
  useTemplate: boolean;
  templateVariables;

  @ViewChild(EditorComponent) appEditor: EditorComponent;

  constructor(
    public dialogRef: MatDialogRef<EmailEditConfigDialogComponent>,
    private formBuilder: FormBuilder,
    private configsService: ConfigsService,
    @Inject(MAT_DIALOG_DATA) public data: ConfigModel,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    const htmlValue = this.getHtmlValue();

    this.form = this.formBuilder.group({
      key: [{ value: this.data.configKey, disabled: true }],
      configType: [{ value: this.data.configType, disabled: true }],
      templateName: [this.data.configValue.template_name],
      description: [this.data.configValue.description],
      subject: [this.data.configValue.subject],
      fromAddress: [this.data.configValue.source],
      toAddress: [this.data.configValue.to],
      htmlTemplate: [htmlValue],
      textTemplate: [this.data.configValue.text],
    });

    if (this.data.configValue.templateVariables) {
      this.templateVariables = this.data.configValue.templateVariables;
    }

    if (htmlValue) {
      this.useTemplate = true;
    }
  }

  private getHtmlValue() {
    if (this.data.configValue.body) {
      return this.data.configValue.body;
    }
    if (this.data.configValue.html) {
      return this.data.configValue.html;
    }
    return '';
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  save() {
    const request = this.prepareRequest();
    this.subscriptions.push(
      this.configsService.set(this.data.configKey, request).subscribe(
        (response) => {
          this.closeDialog(response);
        },
        (e) => {
          for (const err of e.error.meta.errors) {
            this.toastrService.error(err.message);
          }
        },
      ),
    );
  }

  private prepareRequest() {
    const configValue = {};

    if (this.data.configType === ConfigTypes.EMAIL_TEMPLATE) {
      configValue['body'] = this.form.controls['htmlTemplate'].value;
      configValue['description'] = this.form.controls['description'].value || null;
      configValue['subject'] = this.form.controls['subject'].value;
      configValue['source'] = 'noreply@payability.com';
      configValue['template_name'] = this.form.controls['templateName'].value;
      return configValue;
    }

    if (this.data.configType === ConfigTypes.EMAIL_BASE_TEMPL) {
      configValue['html'] = this.form.controls['htmlTemplate'].value;
      configValue['text'] = this.form.controls['textTemplate'].value || null;
      configValue['source'] = this.form.controls['fromAddress'].value;
      return configValue;
    }

    if (this.data.configType === ConfigTypes.EMAIL_BACK_CONFIG) {
      if (this.useTemplate) {
        configValue['html'] = this.form.controls['htmlTemplate'].value;
        configValue['text'] = this.form.controls['textTemplate'].value || null;
      }
      configValue['description'] = this.form.controls['description'].value || null;
      configValue['source'] = this.form.controls['fromAddress'].value;
      configValue['subject'] = this.form.controls['subject'].value;
      configValue['template_name'] = this.form.controls['templateName'].value;
      configValue['to'] = this.prepareToAddresses();
      return configValue;
    }

    if (this.data.configType === ConfigTypes.EMAIL_FRONT_BATCH_CONFIG) {
      if (this.useTemplate) {
        configValue['html'] = this.form.controls['htmlTemplate'].value;
        configValue['text'] = this.form.controls['textTemplate'].value || null;
      }
      configValue['description'] = this.form.controls['description'].value || null;
      configValue['template_name'] = this.form.controls['templateName'].value;
      configValue['source'] = this.form.controls['fromAddress'].value;
      configValue['subject'] = this.form.controls['subject'].value;
      configValue['templateVariables'] = this.data.configValue.templateVariables;
      return configValue;
    }

    if ([ConfigTypes.EMAIL_FRONT_CONFIG, ConfigTypes.EMAIL_FRONTEND_AUTOMATIC_CONFIG].includes(this.form.controls['configType'].value)) {
      if (this.useTemplate) {
        configValue['html'] = this.form.controls['htmlTemplate'].value;
        configValue['text'] = this.form.controls['textTemplate'].value || null;
      }
      configValue['description'] = this.form.controls['description'].value || null;
      configValue['subject'] = this.form.controls['subject'].value;
      configValue['template_name'] = this.form.controls['templateName'].value;
      configValue['source'] = this.form.controls['fromAddress'].value;
      configValue['to'] = this.prepareToAddresses();
      return configValue;
    }
  }

  private prepareToAddresses() {
    const toAddress = this.form.controls['toAddress'].value;
    let array = [];
    if (toAddress) {
      if (Array.isArray(toAddress)) {
        array = toAddress;
      } else {
        array = toAddress
          .trim()
          .split(/[\s,]/)
          .map((key) => key.trim())
          .filter((key) => !!key);
      }
    }
    return array;
  }

  showField(fieldName: string): boolean {
    const emailTypeFormField = EMAIL_TYPE_FORM_FIELDS.find((element) => element.type === this.data.configType);
    return emailTypeFormField.fields.includes(fieldName);
  }

  toggleUseTemplate() {
    this.useTemplate = !this.useTemplate;
  }

  isTemplate(): boolean {
    return this.data.configType === ConfigTypes.EMAIL_TEMPLATE;
  }

  isConfig(): boolean {
    return this.data.configType === ConfigTypes.EMAIL_FRONT_CONFIG;
  }

  getAllowTemplatesVariablesSupplier() {
    return ALLOW_TEMPLATES_VARIABLES_SUPPLIER;
  }

  getAllowTemplatesVariablesUser() {
    return ALLOW_TEMPLATES_VARIABLES_USER;
  }

  getAllowTemplatesVariablesMiscalenous() {
    return ALLOW_TEMPLATES_VARIABLES_MISCALENOUS;
  }

  pasteInEditor(value: string) {
    this.appEditor.appendRawValue(value);
  }
}
