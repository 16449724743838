import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { ConfigModel } from '../../../shared/configs/config.model';
import { ConfigEmailTypes, ConfigsService } from '../../../shared/configs/service/configs.service';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { UsersService } from '../../../shared/users/service/users.service';
import { USER_PERMISSION } from '../../../shared/users/user-permissions.enum';
import { AppState } from '../../../store/app.reducers';
import { DeleteEmailSettingComponent } from '../delete-email-setting/delete-email-setting.component';
import { EmailAddNewConfigDialogComponent } from '../email-add-new-config-dialog/email-add-new-config-dialog.component';
import { EmailEditConfigDialogComponent } from '../email-edit-config-dialog/email-edit-config-dialog.component';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

class ConfigDataSource extends MatTableDataSource<ConfigModel> {
  constructor() {
    super();
    const prev = this.sortingDataAccessor;
    this.sortingDataAccessor = function (data, sortHeaderId) {
      if (sortHeaderId === 'title') {
        return (data.configValue.template_name || data.configKey).toLowerCase();
      }
      return prev(data, sortHeaderId);
    };
  }
}

@Component({
  selector: 'app-email-settings-list',
  templateUrl: './email-settings-list.component.html',
  styleUrls: ['./email-settings-list.component.scss'],
})
export class EmailSettingsListComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  dataSource = new ConfigDataSource();
  displayedColumns: string[] = ['configType', 'title', 'subject', 'description', 'action'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  canEdit: boolean;
  hasCRMPermission;

  private user;
  private permission = USER_PERMISSION.INT_EMAIL_SETTINGS;

  constructor(
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private configsService: ConfigsService,
    private usersService: UsersService,
    private store: Store<AppState>,
  ) {
    super();
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        this.user = user;

        this.subscriptions.push(
          this.usersService.checkPermission(USER_PERMISSION.CRM_ADMIN).subscribe((permitted) => {
            this.hasCRMPermission = permitted;
          }),
        );
      }),
    );

    this.dataSource = new ConfigDataSource();
  }

  ngOnInit() {
    this.canEdit = false;
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((loggedUser) => {
        this.getUserPermissions(loggedUser);
      }),
    );

    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  private getData() {
    this.subscriptions.push(
      this.configsService.list(true).subscribe((result) => {
        this.dataSource.data = result.filter((email) =>
          Object.values(ConfigEmailTypes)
            .map((e) => e.toString())
            .includes(email.configType),
        );
      }),
    );
  }

  getConfigEmailTitle(configElement: ConfigModel): string {
    return configElement.configValue.template_name || configElement.configKey;
  }

  emailEditConfigPreview(configElement: ConfigModel) {
    const dialogRef = this.dialog.open(EmailEditConfigDialogComponent, {
      ...DIALOG_SIZE.LARGE_AUTO_HEIGHT,
      data: configElement,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((data: ConfigModel) => {
        if (data) {
          this.toastrService.success('Object has been successfully updated');
          this.refreshRow(data);
        }
      }),
    );
  }

  emailAddNewConfig() {
    const dialogRef = this.dialog.open(EmailAddNewConfigDialogComponent, {
      ...DIALOG_SIZE.LARGE_AUTO_HEIGHT,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((data: ConfigModel) => {
        if (data) {
          this.toastrService.success('Object has been successfully added');
          this.getData();
        }
      }),
    );
  }

  openDeleteEmailSettingComponent(configElement: ConfigModel) {
    const dialogRef = this.dialog.open(DeleteEmailSettingComponent, {
      ...DIALOG_SIZE.RWD_50_AUTO_HEIGHT,
      data: configElement,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getData();
        }
      }),
    );
  }

  refreshRow(updatedItem: ConfigModel) {
    this.dataSource.data = this.dataSource.data.map((configItem: ConfigModel) => {
      if (configItem.configKey === updatedItem.configKey) {
        return updatedItem;
      } else {
        return configItem;
      }
    });
  }

  addRow(addedItem: ConfigModel) {
    this.dataSource.data.push(addedItem);
  }

  private getUserPermissions(loggedUser) {
    if (!loggedUser || !loggedUser.loginKey) {
      return;
    }

    this.subscriptions.push(
      this.usersService.getLoginPermissions(loggedUser.loginKey).subscribe((perms) => {
        perms.forEach((perm) => {
          if (!perm || !perm.permCode) {
            return;
          }

          this.subscriptions.push(
            this.usersService.getPermissionSubPerms(perm.permCode).subscribe((response) => {
              this.checkPermission(response);
            }),
          );
        });
      }),
    );
  }

  private checkPermission(permissions) {
    permissions.forEach((elem) => {
      if (elem.permCode.toString() === this.permission) {
        this.canEdit = true;
      }
    });
  }
}
