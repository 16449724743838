import { ModelInterface } from '../../shared/utils/model-interface';

export class MarketplaceListModel implements ModelInterface {
  feeScheduleName?: string;
  accessType: string;
  marketplaceKey: string;
  marketplaceCode: string;
  marketplaceDescription: string;
  marketplaceUrl: string;
  instructionPdfUrl: string;
  receivingDueDays: string;
  marketplacePeriodGroupCode: string;
  supplierWidgetPriority: number;
  marketplaceName: string;
  timezone: string;
  defaultReservePercent: number;
  allowEnroll: boolean;
  trusted: boolean;
  instantAccess: boolean;
  hideSplashScreen: boolean;
  addSupplierLogins: boolean;
  defaultFeeFromReserve: any;

  constructor(jsonObject) {
    if (jsonObject) {
      this.fromJSON(jsonObject);
    }
  }

  getKey(): string {
    return this.marketplaceKey;
  }

  fromJSON(jsonObject) {
    if (jsonObject) {
      this.feeScheduleName = jsonObject.feeScheduleName;
      this.accessType = jsonObject.accessType;
      this.marketplaceKey = jsonObject.marketplaceKey;
      this.marketplaceCode = jsonObject.marketplaceCode;
      this.marketplaceDescription = jsonObject.marketplaceDescription;
      this.marketplacePeriodGroupCode = jsonObject.marketplacePeriodGroupCode;
      this.marketplaceUrl = jsonObject.marketplaceUrl;
      this.receivingDueDays = jsonObject.receivingDueDays;
      this.instructionPdfUrl = jsonObject.instructionPdfUrl;
      this.supplierWidgetPriority = jsonObject.supplierWidgetPriority;
      this.marketplaceName = jsonObject.marketplaceName;
      this.defaultReservePercent = jsonObject.defaultReservePercent;
      this.allowEnroll = jsonObject.allowEnroll;
      this.trusted = jsonObject.trusted;
      this.timezone = jsonObject.timezone;
      this.instantAccess = jsonObject.instantAccess;
      this.hideSplashScreen = jsonObject.hideSplashScreen;
      this.addSupplierLogins = jsonObject.addSupplierLogins;
      this.defaultFeeFromReserve = jsonObject.defaultFeeFromReserve;
    }
  }
}
