<h1 mat-dialog-title>Link new payment gateway</h1>
<div mat-dialog-content>
  <mat-form-field class="paymentGatewayDialog__formField">
    <input matInput placeholder="Account Number" [(ngModel)]="accountNumber" />
  </mat-form-field>
  <mat-form-field class="paymentGatewayDialog__formField">
    <mat-select class="full-width" placeholder="Link with..." type="text" matInput [(ngModel)]="gatewayKey">
      <mat-option *ngFor="let gateway of this.data; let $index = index" [value]="gateway.marketplace_key">
        {{ gateway.marketplace_name }}
      </mat-option>
    </mat-select>
    <mat-error>Error</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="dialogRef.close()">Cancel</button>
  <button mat-button color="accent" [mat-dialog-close]="{ gatewayKey: gatewayKey, bankAccountNumber: accountNumber }">Link</button>
</div>
