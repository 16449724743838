import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { InlineEditBaseComponent } from '../base/inline-edit-base.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditYesNoComponent),
  multi: true,
};

@Component({
  selector: 'app-inline-edit-yes-no',
  styleUrls: ['../base/inline-edit-base.component.scss', './inline-edit-yes-no.component.scss'],
  templateUrl: './inline-edit-yes-no.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
})
export class InlineEditYesNoComponent extends InlineEditBaseComponent implements OnInit, OnDestroy {
  @Input() label = '';

  private readonly subscriptions: Subscription[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.setupYesNoInput();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  protected onFormGroupChange(): void {
    super.onFormGroupChange();
    this.setupYesNoInput();
  }

  private setupYesNoInput(): void {
    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((data) => {
        this.value = data;
      }),
    );
  }
}
