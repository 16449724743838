<section class="quickview-wrapper" id="quickview-crm">
  <a *ngIf="!isFixed" class="customizer-close" (click)="close()">
    <span class="material-icons">close</span>
  </a>

  <a class="customizer-toggle" (click)="toggleOpen()" data-test="crmToggleBtn">CRM TOOLS</a>

  <a class="collapsed-toggler" (click)="toggleFixed()" data-test="crmToggleFixedCheckbox">
    <i class="material-icons">{{ !isFixed ? 'radio_button_unchecked' : 'radio_button_checked' }}</i>
  </a>

  <app-actions-list-pinned class="pinned-section" data-test="crmPinnedSection"></app-actions-list-pinned>
  <app-actions-add class="add-new-section" data-test="crmAddNewSection"></app-actions-add>
  <app-actions-list class="timeline-section" data-test="crmActionsList"></app-actions-list>
</section>
