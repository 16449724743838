import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { snakeCaseToTitleCase } from '../../../../shared/functions/snake-case-to-title-case';
import { AppState } from '../../../../store/app.reducers';
import { BackgroundReportSectionModel } from '../../../model/background-report/background-report-section.model';
import { BackgroundReportSectionDataModel } from '../../../model/background-report/background-report-section-data.model';
import { IBackgroundReportTable } from '../../../model/background-report/background-report-table';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { SupplierOwnerModel } from '../../../model/supplier-owner.model';
import { BackgroundReportsService } from '../../../services/background-reports/background-reports.service';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-background-reports',
  templateUrl: './background-reports.component.html',
  styleUrls: ['./background-reports.component.scss'],
})
export class BackgroundReportsComponent {
  public ownerName$: Observable<string>;
  public availableSections$: Observable<BackgroundReportSectionModel[]>;
  public reportData$: Observable<Record<string, IBackgroundReportTable>>;
  public supplierKey$: Observable<string>;
  public owner$: Observable<SupplierOwnerModel>;

  private supplierDetailsModel$: Observable<SupplierDetailsModel>;
  private readonly noOwnerErrorMessage = 'ERROR: No owners found for this supplier. Try refreshing the page.';

  constructor(
    private backgroundReportsService: BackgroundReportsService,
    private store: Store<AppState>,
    private toastrService: ToastrService,
  ) {
    this.supplierDetailsModel$ = this.store.select(selectSupplierDetails).pipe(
      filter((s) => !!s),
      shareReplay(1),
    );

    this.supplierKey$ = this.supplierDetailsModel$.pipe(map((supplier) => supplier.supplierKey));

    this.owner$ = this.supplierDetailsModel$.pipe(
      map((supplier) => this.pickOwner(supplier)),
      shareReplay(1),
    );

    this.ownerName$ = this.owner$.pipe(map((owner) => owner.firstName + ' ' + owner.lastName));

    this.availableSections$ = combineLatest([this.supplierDetailsModel$, this.owner$]).pipe(
      switchMap(([supplier, owner]) => this.backgroundReportsService.fetchAvailableSectionsList(supplier.supplierKey, owner.loginKey)),
      shareReplay(1),
    );

    this.reportData$ = this.availableSections$.pipe(map((sections) => this.getEmptyReportData(sections.map((s) => s.key))));
  }

  private pickOwner(supplier: SupplierDetailsModel): SupplierOwnerModel {
    if (!supplier.owners || supplier.owners.length < 1) {
      this.toastrService.error(this.noOwnerErrorMessage);
      throw new Error(this.noOwnerErrorMessage);
    }

    const mainOwner = supplier.owners.find((owner) => owner.mainOwner) || supplier.owners[0];

    if (!mainOwner.SSN) {
      mainOwner.SSN = supplier.ownerSSN;
    }

    return mainOwner;
  }

  onFilterOptionsChanged(filteredSectionKeys: string[]): void {
    this.reportData$ = combineLatest([this.availableSections$, this.supplierDetailsModel$, this.owner$]).pipe(
      switchMap(([allSections, supplier, owner]) => {
        const filteredSections = allSections.filter((s) => filteredSectionKeys.includes(s.key));
        return this.fetchReportData(filteredSections, supplier.supplierKey, owner.loginKey);
      }),
      startWith(this.getEmptyReportData(filteredSectionKeys)),
    );
  }

  private fetchReportData(
    sections: BackgroundReportSectionModel[],
    supplierKey: string,
    personKey: string,
  ): Observable<Record<string, IBackgroundReportTable>> {
    const sectionsObservables = sections.map((section) =>
      this.backgroundReportsService
        .fetchSection(section, supplierKey, personKey)
        .map((sectionData) => this.mapSectionToIBackgroundReportTable(sectionData))
        .pipe(catchError(() => of(this.getErroneousSection(section.key)))),
    );

    return forkJoin(sectionsObservables).pipe(
      map((sectionsData) => sectionsData.reduce((sofar, section) => ({ ...sofar, [section.type]: section }), {})),
    );
  }

  private mapSectionToIBackgroundReportTable(section: BackgroundReportSectionDataModel): IBackgroundReportTable {
    const columns = this.extractColumnKeys(section.data);
    const rows = section.data.map((record) => this.buildRowData(record, columns));

    return {
      title: section.sectionTitle,
      reports: {
        columns: columns.map(snakeCaseToTitleCase),
        rows,
      },
      type: section.key,
      loaded: true,
      loading: false,
      error: false,
    };
  }

  private extractColumnKeys(rows: Record<string, any>[]): string[] {
    const allKeys = rows.reduce<string[]>((sofar, row) => [...sofar, ...Object.keys(row)], []);
    const uniqueKeys = [...new Set(allKeys)];
    return uniqueKeys;
  }

  private buildRowData(record: Record<string, any>, allColumns: string[]) {
    return allColumns.map((key) => (record[key] ? '' + record[key] : ''));
  }

  private getEmptyReportData(sectionKeys: string[]): Record<string, IBackgroundReportTable> {
    return sectionKeys.reduce((sofar, key) => ({ ...sofar, [key]: this.getEmptySection(key) }), {});
  }

  private getEmptySection(sectionKey: string): IBackgroundReportTable {
    return {
      title: 'Empty Section',
      reports: {
        columns: [],
        rows: [],
      },
      type: sectionKey,
      loaded: false,
      loading: true,
      error: false,
    };
  }

  private getErroneousSection(sectionKey: string): IBackgroundReportTable {
    return {
      title: 'ERROR',
      reports: {
        columns: [],
        rows: [],
      },
      type: sectionKey,
      loaded: false,
      loading: false,
      error: true,
    };
  }
}
