<h1 mat-dialog-title>{{ actionTitle }}</h1>

<div class="confirmation" *ngIf="isSaved">
  {{ confirmationMessage }}
</div>

<div [formGroup]="supplierActionForm" *ngIf="showForm">
  <mat-form-field class="full-width">
    <mat-select placeholder="Marketplace" formControlName="mp_sup_key">
      <mat-option *ngFor="let marketplace of marketplaces" [value]="marketplace.id">
        {{ marketplace.label }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Marketplace Payment External ID" formControlName="mp_pmt_external_id" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select placeholder="Mp statement key" formControlName="mp_statement_key">
      <mat-option value="">No statement</mat-option>
      <mat-option *ngFor="let statement of marketplaceStatements; trackBy: trackByKey" [value]="statement.mp_statement_key">
        {{ statement.start_date | date: 'MM/dd/yyyy' }} - {{ statement.end_date ? (statement.end_date | date: 'MM/dd/yyyy') : 'now' }} :
        {{ (statement.end_bal ? statement.end_bal : 0) | currency }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="endPicker" placeholder="End date" formControlName="fp_end_date" />
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="picker" placeholder="Posting date" formControlName="post_date" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Comment" formControlName="reason" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <div class="mt-2 mb-2">
    <mat-slide-toggle color="accent" formControlName="exclude_from_mp_statement_balance">
      Exclude Statement From MP Statement Balance</mat-slide-toggle
    >
  </div>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="showForm && !isSaved">
  <button mat-raised-button (click)="save()" [disabled]="!supplierActionForm.valid" tabindex="2" color="accent">Save</button>
  <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="isSaved">
  <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Yes</button>
  <button mat-raised-button (click)="editForm(); triggerToggleDisabling()" tabindex="-1" color="warn">No</button>
</div>

<div *ngIf="isSuccess">
  <div class="alert alert-success">
    {{ successMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="resetForm()" tabindex="2" color="accent">Add Another</button>
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Done</button>
  </div>
</div>

<div *ngIf="isError">
  <div class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="back()" tabindex="-1" color="accent">Back</button>
  </div>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
