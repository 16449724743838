export enum ReportColumnType {
  Date = 'timestamptz',
  Text = 'text',
  Currency = 'currency',
}

export interface ReportColumn {
  data: string;
  editor: boolean;
  type: Date | string;
  filter?: { label: string };
  cellRenderer?: (cellData: any) => string;
}

export interface ReportTableConfiguration {
  title: string;
  columns: ReportColumn[];
}
