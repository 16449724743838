<article class="article flex-container">
  <div class="card mb-0 flex-container">
    <div class="card-content flex-container">
      <app-data-table-ag-grid
        *ngIf="supplierKey && allPermissionsLoaded"
        [supplierKey]="supplierKey"
        [overrideColumnDef]="columnDef"
        [defaultReportName]="reportName"
        [remove]="remove"
        style="height: 80%; width: 100%"
      >
        <div top-container-before>
          <button mat-raised-button *ngIf="canModify" style="margin-right: 5px" color="primary" (click)="addNewStatement()">
            Add New Statement
          </button>
        </div>
      </app-data-table-ag-grid>
    </div>
  </div>
</article>
