import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { isUndefined } from 'util';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { AppConfigService } from '../../../config';
import { B2bDocumentService } from '../../../shared/b2b-document/b2b-document.service';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { PybDocumentsService } from '../../../shared/pyb-documents/pyb-documents.service';
import { UserModel } from '../../../shared/users/user.model';
import { getTimeOffset } from '../../../shared/utils/get-time-offset.util';
import { AppState } from '../../../store/app.reducers';
import { NoteModel } from '../../models/note.model';
import { ItemWithAttachmentComponent } from '../../shared/item-with-attachment.component';
import { DeleteCrmNote, PinCrmNote } from '../../store/crm.actions';
import { NoteAddEditComponent } from '../note-add-edit/note-add-edit.component';

@Component({
  selector: 'app-note-item',
  templateUrl: './note-item.component.html',
  styleUrls: ['../../shared/crm-tools.scss', '../../shared/timeline-item.scss', './note-item.component.scss'],
})
export class NoteItemComponent extends ItemWithAttachmentComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() item: NoteModel;
  @ViewChild('noteBody', { static: true }) noteBody: ElementRef;

  protected COLLAPSED_NOTE_HEIGHT = 41;

  public collapsed = true;
  public expandable = true;
  private subscriptions: Subscription[] = [];
  private heightCheckInterval;
  private checksCounter = 0;
  private offsetHeights = new Subject<number>();
  readonly correctTime = getTimeOffset;
  hasAccessTo = false;

  constructor(
    dialog: MatDialog,
    private readonly store: Store<AppState>,
    appConfig: AppConfigService,
    b2bDownloadService: B2bDocumentService,
    httpClient: HttpClient,
    renderer: Renderer2,
    featureFlagsService: FeatureFlagsService,
    pybDocuments: PybDocumentsService,
    toastrService: ToastrService,
    sanitizer: DomSanitizer,
    private readonly cd: ChangeDetectorRef,
  ) {
    super(dialog, b2bDownloadService, toastrService, httpClient, appConfig, renderer, featureFlagsService, pybDocuments, sanitizer);
  }

  ngOnInit() {
    if (!this.store) {
      return;
    }

    this.store.select(selectLoggedUserModel).subscribe((user: UserModel) => {
      if (!isUndefined(user)) {
        this.hasAccessTo = user.hasAccessTo(this.item, 'edit');
      }
    });
  }

  ngAfterViewInit() {
    this.setupInterval();
    this.findFiles();
    this.subscriptions.push(
      this.offsetHeights.subscribe((height) => {
        if (!isUndefined(height) && height < this.COLLAPSED_NOTE_HEIGHT) {
          this.expandable = false;
          this.collapsed = false;
        }
      }),
    );
  }

  ngOnChanges(changes) {
    if (changes.item.previousValue && changes.item.currentValue.data.body !== changes.item.previousValue.data.body) {
      this.cd.detectChanges();
      this.findFiles();
    }
  }

  toggleCollapse() {
    if (this.expandable) {
      this.collapsed = !this.collapsed;
    }
  }

  openEditNoteDialog() {
    const dialogRef = this.dialog.open(NoteAddEditComponent, {
      data: {
        note: this.item,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastrService.success('Note edited successfully');
      }
    });
  }

  pinNote() {
    this.store.dispatch(new PinCrmNote({ uuid: this.item.uuid, data: { isPinned: !this.item.isPinned } }));
  }

  deleteNote() {
    this.store.dispatch(new DeleteCrmNote({ uuid: this.item.uuid }));
  }

  setupInterval() {
    this.heightCheckInterval = setInterval(() => {
      this.offsetHeights.next(this.noteBody.nativeElement.offsetHeight);
      this.checksCounter++;
      if (this.checksCounter > 5) {
        clearInterval(this.heightCheckInterval);
      }
    }, 1000);
  }

  findFiles() {
    const elems = this.noteBody.nativeElement.querySelectorAll('span[pyb-b2b-preview]');
    for (const item of elems) {
      this.transformAttachment(item);
    }
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => {
      subscription.unsubscribe();
    });
  }
}
