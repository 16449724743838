<div class="example-container mat-elevation-z8">
  <mat-card>
    <mat-card-title>Event rule executions history</mat-card-title>
    <mat-card-content>
      <div class="row header-row">
        <div class="col-md-2 small-header">Action type</div>
        <div class="col-md-2 small-header">Execution date</div>
        <div class="col-md-2 small-header">Template</div>
        <div class="col-md-3 small-header">Receiver</div>
        <div class="col-md-2 small-header">Supplier</div>
      </div>
      <div *ngFor="let event of dataSource">
        <div class="event-uuid">Event uuid: {{ event.event_uuid }}</div>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let element of event.executed_actions">
            <mat-expansion-panel-header [ngStyle]="{ 'background-color': element?.error === 1 ? 'rgba(255, 0, 0, 0.14)' : 'transparent' }">
              <small class="col-md-2">
                <span class="badge badge-warning">
                  {{ element.action?.type }}
                </span>
                <span class="badge badge-success" *ngIf="element?.extra?.action_delayed_type"> delayed-action </span>
              </small>
              <small class="truncate col-md-2">{{ element?.execution_date | fromUnix | date: 'M/d/y h:mm a' }}</small>
              <small class="col-md-2 details-hidden">{{ element?.action?.templateName }}</small>
              <small class="col-md-3 details-hidden">{{ element?.extra?.receiver }}</small>
              <small class="col-md-2 details-hidden">{{ element?.supplier_name }}</small>
            </mat-expansion-panel-header>

            <div class="info-message" *ngIf="element?.extra?.action_delayed_type">
              <h6>Action will be executed after:</h6>
              <pre>{{ element?.extra?.to_execute_at | fromUnix | date: 'M/d/y h:mm a' }}</pre>
              <hr />
            </div>
            <div class="info-message" *ngIf="element?.error === 1">
              <h6>Error message:</h6>
              <pre>{{ element?.result | json }}</pre>
              <hr />
            </div>
            <pre>{{ element | json }}</pre>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[5, 25, 50]"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
