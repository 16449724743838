import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { InlineEditBaseComponent } from '../base/inline-edit-base.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditTextareaComponent),
  multi: true,
};

@Component({
  selector: 'app-inline-edit-textarea',
  styleUrls: ['../base/inline-edit-base.component.scss', './inline-edit-textarea.component.scss'],
  templateUrl: './inline-edit-textarea.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
})
export class InlineEditTextareaComponent extends InlineEditBaseComponent {
  @Input() label = ''; // Label value for input element
}
