<mat-card class="example-card">
  <mat-card-title> {{ isEditing ? 'Edit' : 'Create new' }} event subscription rule </mat-card-title>
  <mat-card-content>
    <mat-horizontal-stepper linear>
      <mat-step [stepControl]="selectorForm" label="Event selector">
        <app-selector-form #selector [topForm]="selectorForm" [loaded$]="loadEventRule$"></app-selector-form>
      </mat-step>

      <mat-step [stepControl]="actionsForm" label="Actions">
        <app-actions-form #actions [topForm]="actionsForm" [loaded$]="loadEventRule$"></app-actions-form>

        <mat-card-actions>
          <button mat-raised-button matStepperPrevious>Back</button>
          <!--<button mat-raised-button color="primary" matStepperNext>Next</button>-->
          <button mat-raised-button color="primary" [disabled]="actionsForm.invalid || selectorForm.invalid" (click)="sendButton$.next()">
            Save
          </button>
        </mat-card-actions>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>
