import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-supplier-marketplace-single-gateway',
  templateUrl: './supplier-marketplace-single-gateway.component.html',
  styleUrls: ['./supplier-marketplace-single-gateway.component.scss'],
})
export class SupplierMarketplaceSingleGatewayComponent extends SubscriberComponent implements OnInit {
  @Input()
  paymentGateway: any;

  @Input()
  index: number;

  @Output() disconnectGateway = new EventEmitter<string>();

  constructor(private dialog: MatDialog) {
    super();
  }

  ngOnInit() {}

  public disconnectGatewayBy(mpSupKey: string) {
    const msg = 'Are you sure you want to unlink a payment gateway?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.disconnectGateway.emit(mpSupKey);
        }
      }),
    );
  }
}
