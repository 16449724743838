import { Component, OnDestroy, OnInit } from '@angular/core';

import { LayoutService } from './../../shared/layout/layout.service';

@Component({
  selector: 'app-tasks',
  template: '<router-outlet></router-outlet>',
})
export class TasksComponent implements OnInit, OnDestroy {
  customClasses = 'height100vh';

  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void {
    this.layoutService.setClasses(this.customClasses);
  }

  ngOnDestroy(): void {
    this.layoutService.clearClasses();
  }
}
