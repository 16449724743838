import { ModelInterface } from '../../shared/utils/model-interface';

export class MarketplaceDefaultsModel {
  defaultFeeFromReserve?: boolean;
  defaultFeeSchedule?: string;
  defaultFreeTrialNumMpPeriods?: any;
  defaultFreeTrialTypeCode?: string;
  defaultFundingPeriodGroupCode?: string;
  defaultMinReservePercent?: number;
  defaultMpCanOptIn?: boolean;
  defaultMpPaysPb?: boolean;
  defaultPaymentFrequency?: any;
  defaultPaymentTerms?: any;
  defaultPaymentTermsDays?: any;
  defaultPbCanOptIn?: boolean;
  defaultRcvPurchaseThreshold?: number;
  defaultReservePercent?: number;

  constructor(jsonObject) {
    this.fromJSON(jsonObject);
  }

  fromJSON(jsonObject) {
    this.defaultFeeFromReserve = jsonObject.defaultFeeFromReserve;
    this.defaultFeeSchedule = jsonObject.defaultFeeSchedule;
    this.defaultFreeTrialNumMpPeriods = jsonObject.defaultFreeTrialNumMpPeriods;
    this.defaultFreeTrialTypeCode = jsonObject.defaultFreeTrialTypeCode;
    this.defaultFundingPeriodGroupCode = jsonObject.defaultFundingPeriodGroupCode;
    this.defaultMinReservePercent = jsonObject.defaultMinReservePercent;
    this.defaultMpCanOptIn = jsonObject.defaultMpCanOptIn;
    this.defaultMpPaysPb = jsonObject.defaultMpPaysPb;
    this.defaultPaymentFrequency = jsonObject.defaultPaymentFrequency;
    this.defaultPaymentTerms = jsonObject.defaultPaymentTerms;
    this.defaultPaymentTermsDays = jsonObject.defaultPaymentTermsDays;
    this.defaultPbCanOptIn = jsonObject.defaultPbCanOptIn;
    this.defaultRcvPurchaseThreshold = jsonObject.defaultRcvPurchaseThreshold;
    this.defaultReservePercent = jsonObject.defaultReservePercent;
  }
}

export class MarketplaceModel implements ModelInterface {
  feeScheduleName?: string;
  iconPng?: any;
  accessType: string;
  marketplaceKey: string;
  marketplaceCode: string;
  marketplaceDescription: string;
  address: MarketplaceAddressModel;
  marketplaceUrl: string;
  instructionPdfUrl: string;
  receivingDueDays: string;
  marketplacePeriodGroupCode: string;
  supplierWidgetPriority: number;
  marketplaceName: string;
  timezone: string;
  allowEnroll: boolean;
  trusted: boolean;
  instantAccess: boolean;
  instantAdvance: boolean;
  hideSplashScreen: boolean;
  addSupplierLogins: boolean;
  defaults?: MarketplaceDefaultsModel;
  riskScore: number;
  riskScoreOverride: number;
  creditScore: number;
  credit2beCreditScore: number;
  dnbCreditScore: number;
  allowUnderwritingTracker: boolean;
  constructor(jsonObject) {
    this.fromJSON(jsonObject);
  }

  getKey(): string {
    return this.marketplaceKey;
  }

  fromJSON(jsonObject) {
    this.feeScheduleName = jsonObject.feeScheduleName;
    this.accessType = jsonObject.accessType;
    this.marketplaceKey = jsonObject.marketplaceKey;
    this.marketplaceCode = jsonObject.marketplaceCode;
    this.marketplaceDescription = jsonObject.marketplaceDescription;
    this.address = new MarketplaceAddressModel(jsonObject.address);
    this.marketplacePeriodGroupCode = jsonObject.marketplacePeriodGroupCode;
    this.marketplaceUrl = jsonObject.marketplaceUrl;
    this.receivingDueDays = jsonObject.receivingDueDays;
    this.instructionPdfUrl = jsonObject.instructionPdfUrl;
    this.supplierWidgetPriority = jsonObject.supplierWidgetPriority;
    this.marketplaceName = jsonObject.marketplaceName;
    this.allowEnroll = jsonObject.allowEnroll;
    this.trusted = jsonObject.trusted;
    this.timezone = jsonObject.timezone;
    this.instantAccess = jsonObject.instantAccess;
    this.instantAdvance = jsonObject.instantAdvance;
    this.hideSplashScreen = jsonObject.hideSplashScreen;
    this.addSupplierLogins = jsonObject.addSupplierLogins;
    this.defaults = new MarketplaceDefaultsModel(jsonObject.defaults);
    this.riskScore = jsonObject.riskScore;
    this.riskScoreOverride = jsonObject.riskScoreOverride;
    this.creditScore = jsonObject.creditScore;
    this.credit2beCreditScore = jsonObject.credit2beCreditScore;
    this.dnbCreditScore = jsonObject.dnbCreditScore;
    this.iconPng = jsonObject.iconPng;
    this.allowUnderwritingTracker = jsonObject.allowUnderwritingTracker;
  }
}

export class MarketplaceAddressModel {
  addr1: string;
  addr2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;

  constructor(jsonObject) {
    this.fromJSON(jsonObject);
  }

  fromJSON(jsonObject) {
    this.addr1 = jsonObject.addr1;
    this.addr2 = jsonObject.addr2;
    this.city = jsonObject.city;
    this.state = jsonObject.state;
    this.country = jsonObject.country;
    this.postalCode = jsonObject.postalCode;
  }
}
