import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CancelEmitService } from './cancel-emit.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class CancelEmitModule {
  static forRoot(): ModuleWithProviders<CancelEmitModule> {
    return {
      ngModule: CancelEmitModule,
      providers: [CancelEmitService],
    };
  }

  static forChild(): ModuleWithProviders<CancelEmitModule> {
    return {
      ngModule: CancelEmitModule,
    };
  }
}
