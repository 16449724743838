import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isUndefined } from 'util';

import { TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';

export enum LoadRemittanceFileType {
  AMAZON_VC_REMIT = 'amazon_vc_remit',
  AMAZON_VE_REMIT = 'amazon_ve_remit',
  TARGET_REMIT = 'target_remit',
}

@Component({
  selector: 'app-load-remittance',
  templateUrl: './load-remittance.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadRemittanceComponent extends BaseSupplierAction implements OnInit {
  supplierKey: string;
  marketplaces: any = {};
  response: any;
  pending: boolean;

  fileTypes: any[] = [];

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  createForm() {
    this.supplierActionForm = this.formBuilder.group({
      supplier_key: [''],
      file_type: [''],
      file: [''],
      mp_pmt_external_id: [''],
      load_paid: false,
      no_chargeback_check: false,
      europe: false,
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.supplierKey = data.supplierKey;

          data.marketplaces.map((element) => {
            this.marketplaces[element.marketplaceName] = element.mpSupKey;
          });

          if (this.marketplaces['Amazon Vendor Central']) {
            this.fileTypes.push({
              label: 'Amazon Vendor Central Remittance',
              id: LoadRemittanceFileType.AMAZON_VC_REMIT,
              example: '',
            });
          }
          if (this.marketplaces['Amazon Vendor Express']) {
            this.fileTypes.push({
              label: 'Amazon Vendor Express Remittance',
              id: LoadRemittanceFileType.AMAZON_VE_REMIT,
              example: 'example_amazon_vendor_express_remittance.csv',
            });
          }
          if (this.marketplaces['Target']) {
            this.fileTypes.push({
              label: 'Target',
              id: LoadRemittanceFileType.TARGET_REMIT,
              example: 'example_target_remittance.csv',
            });
          }
        }
      }),
    );
    super.ngOnInit();
  }

  hasExample() {
    if (this.supplierActionForm && this.supplierActionForm.get('file_type').value) {
      const type = this.fileTypes.filter((item) => item.id === this.supplierActionForm.get('file_type').value);
      if (type[0].example) {
        return type.length && type[0].example.length ? type[0].example : false;
      } else {
        return false;
      }
    }
    return false;
  }

  downloadExample(fileName) {
    return window.open('/assets/examples/' + fileName, '_blank');
  }

  submit() {
    this.isConfirmed = true;
    this.isLoadingResult = true;

    this.supplierActionForm.patchValue({
      supplier_key: this.supplierKey,
    });

    const dataToSave = this.supplierActionForm.value;

    switch (dataToSave.file_type) {
      case LoadRemittanceFileType.AMAZON_VC_REMIT:
        dataToSave.mp_sup_key = this.marketplaces['Amazon Vendor Central'];
        break;
      case LoadRemittanceFileType.AMAZON_VE_REMIT:
        dataToSave.mp_sup_key = this.marketplaces['Amazon Vendor Central'];
        break;
      case LoadRemittanceFileType.TARGET_REMIT:
        dataToSave.mp_sup_key = this.marketplaces['Target'];
        break;
    }

    this.subscriptions.push(
      this.cliService.uploadCSV(dataToSave).subscribe(
        () => {
          this.onSuccess('Remittance loaded');
        },
        () => {
          this.onError('An error occured while loading remittance.');
        },
      ),
    );
  }
}
