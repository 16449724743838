<div class="card">
  <div class="card-content">
    <mat-table
      [dataSource]="collectedData"
      class="example-table"
      matSort
      matSortActive="created_at"
      matSortDisableClear
      matSortDirection="asc"
    >
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Date Column -->
      <ng-container matColumnDef="Date">
        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.timestamp.year + '/' + row.timestamp.month + '/' + row.timestamp.day }}</mat-cell>
      </ng-container>

      <!-- Daily Health Column -->
      <ng-container matColumnDef="Daily Health">
        <mat-header-cell *matHeaderCellDef>Daily Health</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span
            *ngIf="!row.dailyErrorDecision.length && !row.dailyWarningDecision.length && row.noData === false"
            class="label label-success"
            >OK</span
          >
          <span
            *ngIf="row.dailyWarningDecision.length && !row.dailyErrorDecision.length && row.noData === false"
            class="label label-warning"
            >Warning</span
          >
          <span *ngIf="row.noData" class="label label-warning">No data</span>
          <span *ngIf="row.dailyErrorDecision.length && row.noData === false" class="label label-danger">Needs review</span>
        </mat-cell>
      </ng-container>

      <!-- Order Defect Rate Column -->
      <ng-container matColumnDef="Order defect rate">
        <mat-header-cell *matHeaderCellDef>
          <div>
            <span class="m-0">Order defect rate</span>
            <br />
            <span class="text-danger">Have to be</span> &le; {{ collectedData?.order_defect_rate_rule.error_value }}
            <br />
            <span class="text-warning">Should be</span> &le; {{ collectedData?.order_defect_rate_rule.warning_value }}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row['Account Performance Info'] ? row['Account Performance Info']['Order Defect Rate'] : 'no data' }}
        </mat-cell>
      </ng-container>

      <!-- Late Shipment Column -->
      <ng-container matColumnDef="Late shipment">
        <mat-header-cell *matHeaderCellDef>
          <div>
            <span class="m-0">Late shipment</span>
            <br />
            <span class="text-danger">Have to be</span> &le; {{ collectedData?.late_shipment_rate_rule.error_value }}
            <br />
            <span class="text-warning">Should be</span> &le; {{ collectedData?.late_shipment_rate_rule.warning_value }}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row['Account Performance Info'] ? row['Account Performance Info']['Late Shipment Rate'] : 'no data' }}
        </mat-cell>
      </ng-container>

      <!-- Late Responses Column -->
      <ng-container matColumnDef="Late responses">
        <mat-header-cell *matHeaderCellDef>
          <div>
            <span class="m-0">Late responses</span>
            <br />
            <span class="text-danger">Have to be</span> &le; {{ collectedData?.late_responses_rule.error_value }}
            <br />
            <span class="text-warning">Should be</span> &le; {{ collectedData?.late_responses_rule.warning_value }}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row['Account Performance Info'] ? row['Account Performance Info']['Late responses'] : 'no data' }}
        </mat-cell>
      </ng-container>

      <!-- Cancellation rate Column -->
      <ng-container matColumnDef="Cancellation rate">
        <mat-header-cell *matHeaderCellDef>
          <div>
            <span class="m-0">Cancellation rate</span>
            <br />
            <span class="text-danger">Have to be</span> &le; {{ collectedData?.pre_fullfillment_cancel_rate_rule.error_value }}
            <br />
            <span class="text-warning">Should be</span> &le; {{ collectedData?.pre_fullfillment_cancel_rate_rule.warning_value }}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row['Account Performance Info'] ? row['Account Performance Info']['Cancellation Rate'] : 'no data' }}
        </mat-cell>
      </ng-container>

      <!-- Delivered on time Column -->
      <ng-container matColumnDef="Delivered on time">
        <mat-header-cell *matHeaderCellDef>
          <div>
            <span class="m-0">Delivered on time</span>
            <br />
            <span class="text-warning">Should be</span> &ge; {{ collectedData?.delivered_on_time_rate_rule.warning_value }}
            <br />
            <span class="text-danger">Have to be</span> &ge; {{ collectedData?.delivered_on_time_rate_rule.error_value }}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row['Account Performance Info'] ? row['Account Performance Info']['Delivered on time'] : 'no data' }}
        </mat-cell>
      </ng-container>

      <!-- Valid tracking rate Column -->
      <ng-container matColumnDef="Valid tracking rate">
        <mat-header-cell *matHeaderCellDef>
          <div>
            <span class="m-0">Valid tracking rate</span>
            <br />
            <span class="text-warning">Should be</span> &ge; {{ collectedData?.valid_tracking_rate_rule.warning_value }}
            <br />
            <span class="text-danger">Have to be</span> &ge; {{ collectedData?.valid_tracking_rate_rule.error_value }}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row['Account Performance Info'] ? row['Account Performance Info']['Valid Tracking Rate - All Categories'] : 'no data' }}
        </mat-cell>
      </ng-container>

      <!-- Return dissatisfaction rate Column -->
      <ng-container matColumnDef="Return dissatisfaction rate">
        <mat-header-cell *matHeaderCellDef>
          <div>
            <span class="m-0">Return dissatisfaction rate</span>
            <br />
            <span class="text-danger">Have to be</span> &le; {{ collectedData?.return_dissatisfaction_rate_rule.error_value }}
            <br />
            <span class="text-warning">Should be</span> &le; {{ collectedData?.return_dissatisfaction_rate_rule.warning_value }}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row['Account Performance Info'] ? row['Account Performance Info']['Return Dissatisfaction Rate'] : 'no data' }}
        </mat-cell>
      </ng-container>

      <!-- Account Status Column -->
      <ng-container matColumnDef="Account Status">
        <mat-header-cell *matHeaderCellDef>Account Status</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row['Account Status'] }}</mat-cell>
      </ng-container>

      <!-- Account Performacne Status Column -->
      <ng-container matColumnDef="Account Performance Status">
        <mat-header-cell *matHeaderCellDef>Account Performance Status</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row['Account Performance Status'] }}</mat-cell>
      </ng-container>

      <!-- Account Warning -->
      <ng-container matColumnDef="Warning">
        <mat-header-cell *matHeaderCellDef>Warning</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row['Warning'] }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div *ngIf="!collectedData.initialFilterRecords || !collectedData.initialFilterRecords.length">
      <div class="spinnerWrapper pt-3">
        <p>No records</p>
      </div>
    </div>
  </div>
</div>
