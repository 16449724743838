import {
  AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY,
  AUTHORIZE_MARKETPLACE_KEY,
  EBAY_MARKETPLACE_KEY,
  MORECOMMERCE_MARKETPLACE_KEY,
  NEWEGG_MARKETPLACE_KEY,
  PAYONEER_MARKETPLACE_KEY,
  PAYPAL_MARKETPLACE_KEY,
  PINGPONG_MARKETPLACE_KEY,
  SHOPIFY_MARKETPLACE_KEY,
  STRIPE_MARKETPLACE_KEY,
  TOPHATTER_MARKETPLACE_KEY,
  VOLUSION_MARKETPLACE_KEY,
  WALLMART_MARKETPLACE_KEY,
} from '../../../supplier/supplier-onboarding/supplier-onboarding.service';
import { MARKETPLACES } from './marketplaces';

export const MARKETPLACE_CALLBACK_MAP = {
  [AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY]: MARKETPLACES.AMAZON_MARKETPLACE,
  [TOPHATTER_MARKETPLACE_KEY]: MARKETPLACES.TOPHATTER_MARKETPLACE,
  [WALLMART_MARKETPLACE_KEY]: MARKETPLACES.WALLMART_MARKETPLACE,
  [SHOPIFY_MARKETPLACE_KEY]: MARKETPLACES.SHOPIFY_MARKETPLACE,
  [PAYONEER_MARKETPLACE_KEY]: MARKETPLACES.PAYONEER_MARKETPLACE,
  [MORECOMMERCE_MARKETPLACE_KEY]: MARKETPLACES.MORECOMMERCE_MARKETPLACE,
  [EBAY_MARKETPLACE_KEY]: MARKETPLACES.EBAY_MARKETPLACE,
  [VOLUSION_MARKETPLACE_KEY]: MARKETPLACES.VOLUSION_MARKETPLACE,
  [PAYPAL_MARKETPLACE_KEY]: MARKETPLACES.PAYPAL_MARKETPLACE,
  [STRIPE_MARKETPLACE_KEY]: MARKETPLACES.STRIPE_MARKETPLACE,
  [AUTHORIZE_MARKETPLACE_KEY]: MARKETPLACES.AUTHORIZE_MARKETPLACE,
  [NEWEGG_MARKETPLACE_KEY]: MARKETPLACES.NEWEGG_MARKETPLACE,
  [PINGPONG_MARKETPLACE_KEY]: MARKETPLACES.PINGPONG_MARKETPLACE,
};
