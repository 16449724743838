<section class="card" *ngIf="supplierDetailsModel">
  <div class="card-content">
    <app-title-with-icon
      *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]"
      [iconClass]="'fa-building-o'"
      [title]="supplierDetailsModel.legalName"
      [subtitle]="businessCodeLabel"
    >
    </app-title-with-icon>
    <app-title-with-icon
      *ngxPermissionsOnly="userPermission.REST_UW_ACCESS"
      [iconClass]="'fa-building-o'"
      [title]="supplierDetailsModel.legalName"
      [subtitle]="businessCodeLabel"
    >
    </app-title-with-icon>

    <div class="divider divider-sm"></div>

    <ng-content></ng-content>

    <div class="row">
      <p class="col-md-12 minor-title" *ngIf="supplierDetailsModel.businessTypeCode !== 'SP'; else addressTypeSP">
        {{ customTitle || 'Business Address' }}
      </p>

      <div class="col-md-12">
        <app-text-value [label]="'Address Line 1'" [value]="supplierDetailsModel.address.addr1"> </app-text-value>
      </div>

      <div class="col-md-12">
        <app-text-value [label]="'Address Line 2'" [value]="supplierDetailsModel.address.addr2"> </app-text-value>
      </div>

      <div class="col-md-6">
        <app-text-value [label]="'Country'" [value]="supplierDetailsModel.address.country"> </app-text-value>
      </div>

      <div class="col-md-6">
        <app-text-value [label]="'State'" [value]="supplierDetailsModel.address.state"> </app-text-value>
      </div>

      <div class="col-md-6">
        <app-text-value [label]="'City'" [value]="supplierDetailsModel.address.city"> </app-text-value>
      </div>

      <div class="col-md-6">
        <app-text-value [label]="'Zip'" [value]="supplierDetailsModel.address.postalCode"> </app-text-value>
      </div>
    </div>

    <div *ngIf="supplierDetailsModel.businessTypeCode !== 'SP'">
      <div class="divider divider-sm"></div>

      <div class="row">
        <p class="col-md-12 minor-title">TAX data</p>

        <div class="col-md-12">
          <app-text-value [label]="'EIN'" [value]="supplierDetailsModel.taxId"> </app-text-value>
        </div>
      </div>
    </div>

    <div class="divider divider-sm"></div>

    <div class="row">
      <p class="col-md-12 minor-title">Incorporation</p>

      <div class="col-md-4">
        <app-text-value [label]="'Incorporated in US?'" [showValue]="false">
          <div *ngIf="supplierDetailsModel.incorporatedInTheUS === true; else negativeCheck">
            <i class="material-icons">check</i>
          </div>
          <ng-template #negativeCheck>
            <i class="material-icons">close</i>
          </ng-template>
        </app-text-value>
      </div>

      <div class="col-md-4">
        <app-text-value [label]="'State of incorporation'" [value]="supplierDetailsModel.stateOfIncorporation"> </app-text-value>
      </div>

      <div class="col-md-4">
        <app-text-value [label]="'Business Start Date'" [value]="supplierDetailsModel.dateEstablished"> </app-text-value>
      </div>
    </div>
  </div>
</section>

<ng-template #addressTypeSP>
  <p class="col-md-12 minor-title">Address</p>
</ng-template>
