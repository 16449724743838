import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store/src/store';
import { Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import * as moment from 'moment';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isArray, isUndefined } from 'util';

import { selectAuthToken } from '../../auth/store/auth.actions';
import { AppConfigService } from '../../config';
import { AppState } from '../../store/app.reducers';
import { FilterSet } from '../models/task-filter.model';

@Injectable()
export class TasksFiltersService {
  private apiUrl: string;
  private headers: { [name: string]: string } = {};

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient, private store: Store<AppState>) {
    this.apiUrl = appConfig.env.actionsToolsUrl;

    this.headers['X-InstanceId'] = '' + appConfig.env.instanceId;
    this.headers['X-EventLogger-InstanceId'] = '' + appConfig.env.instanceId;

    this.store.select(selectAuthToken).subscribe((jwtToken) => {
      this.headers['Authorization'] = jwtToken;
    });
  }

  getFilterCounts(filterQueries) {
    SerializeKeysTo(UnderscoreCase);

    const headers = { ...this.headers };
    return this.httpClient
      .post(
        this.apiUrl + 'tasks/filters/counts/',
        { queries: Serialize(filterQueries, FilterSet) },
        {
          headers: new HttpHeaders(headers),
        },
      )
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], FilterSet);
        }),
      );
  }

  getUserFilterCounts(assignees) {
    const filtersQuery = [{ name: 'status', op: 'in', val: ['new', 'in-progr'] }];
    const headers = { ...this.headers };

    return this.httpClient
      .post(
        this.apiUrl + 'tasks/filters/counts/assignees/',
        { assignees, filters_query: filtersQuery },
        { headers: new HttpHeaders(headers) },
      )
      .pipe(map((resp) => resp['data']));
  }

  filterToQuery(filterUiModel) {
    const q = (name, op, val) => ({ name, op, val });
    const toTimestamp = (date) => moment(date).unix();
    const filterQuery = [];

    for (const key in filterUiModel) {
      if (filterUiModel.hasOwnProperty(key)) {
        const val = filterUiModel[key];
        if (val === null || isUndefined(val) || val === '') {
          delete filterUiModel[key];
          continue;
        }
        if (key === 'assignee') {
          filterQuery.push(q('assignee_uuid', 'eq', val.key));
          if (!isUndefined(val.type)) {
            filterQuery.push(q('assignee_type', 'eq', val.type));
          }
        } else if (key === 'createdBefore') {
          filterQuery.push(q('created_at', 'lt', toTimestamp(val)));
        } else if (key === 'createdAfter') {
          filterQuery.push(q('created_at', 'gt', toTimestamp(val)));
        } else if (key === 'item_number') {
          filterQuery.push(q('item_number', 'eq', parseInt(val, 10)));
        } else if (isArray(val)) {
          filterQuery.push(q(key, 'in', val));
        } else if (val.charAt(0) === '!') {
          filterQuery.push(q(key, 'ne', val.slice(1)));
        } else {
          filterQuery.push(q(key, 'eq', val));
        }
      }
    }

    return filterQuery;
  }
}
