<div *ngIf="dataSource" class="mb-4">
  <div>
    <h5>{{ title }}</h5>
  </div>
  <div *ngIf="dataSource.data === null">Loading...</div>

  <mat-table *ngIf="dataSource.data !== null" #table [dataSource]="dataSource">
    <ng-container matColumnDef="key">
      <mat-header-cell *matHeaderCellDef>Key</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.key }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="exec">
      <mat-header-cell *matHeaderCellDef>Current Functionality Status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-slide-toggle [(ngModel)]="element.exec" (change)="onChange()"
          ><span [class.text-warning]="element.exec !== element.globalSettings">{{
            element.exec ? 'Enabled' : 'Disabled'
          }}</span></mat-slide-toggle
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="globalSettings">
      <mat-header-cell *matHeaderCellDef>Global Settings Status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!element.globalSettings" class="text-muted">Disabled</span>
        <span *ngIf="element.globalSettings" class="text-info">Enabled</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="build">
      <mat-header-cell *matHeaderCellDef>Build Functionality Status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!element.build" class="text-muted">Disabled</span>
        <span *ngIf="element.build" class="text-info">Enabled</span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
