import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { isUndefined } from 'util';

import { ConfigsService, ConfigTypes } from '../../shared/configs/service/configs.service';
import { AppState } from '../../store/app.reducers';
import { TaskModel } from '../models/task.model';
import { EditCrmTask, selectCrmItems } from '../store/crm.actions';

@Directive({
  selector: '[appTaskNextStatus]',
})
export class TaskNextStatusDirective implements OnInit, OnChanges, OnDestroy {
  @Output() readonly taskUpdated = new EventEmitter<TaskModel>();
  @Output() readonly nextStatus = new EventEmitter<string>();
  readonly nextStatuses: { [key: string]: { value: string; label: string } } = {};

  private taskModel: TaskModel;
  private readonly subscriptions: Subscription[] = [];

  set task(value: TaskModel) {
    this.taskModel = value;
  }

  @Input()
  get task(): TaskModel {
    return this.taskModel;
  }

  constructor(
    private readonly element: ElementRef,
    private readonly store: Store<AppState>,
    private readonly configsService: ConfigsService,
  ) {}

  ngOnChanges(): void {
    if (this.nextStatuses && this.nextStatuses[this.taskModel.status]) {
      this.enableLink();
      this.nextStatus.emit(this.nextStatuses[this.taskModel.status].label);
    } else {
      this.disableLink();
    }
  }

  ngOnInit(): void {
    this.disableLink();
    this.configsService.get(ConfigTypes.TASK_STATUSES).subscribe((statuses) => {
      for (let i = 0; i < statuses.length - 1; i++) {
        const next = statuses[i + 1];
        this.nextStatuses[statuses[i].uuid] = { value: next.uuid, label: next.name };
      }
      this.ngOnChanges();
    });
    this.element.nativeElement.addEventListener('click', this.clickHandler);
  }

  ngOnDestroy(): void {
    this.element.nativeElement.removeEventListener('click', this.clickHandler);
    this.subscriptions.map((sub) => sub.unsubscribe());
  }

  clickHandler = ($event): void => {
    if (!isUndefined(this.nextStatuses[this.taskModel.status])) {
      const dataToSave: TaskModel = <TaskModel>{ ...this.taskModel, status: this.nextStatuses[this.taskModel.status].value };
      this.store.dispatch(new EditCrmTask({ data: dataToSave, uuid: this.taskModel.uuid }));

      this.subscriptions.push(
        this.store.select(selectCrmItems).subscribe((taskItems) => {
          if (taskItems) {
            const updatedTask = <TaskModel>taskItems[this.task.uuid];
            this.taskUpdated.emit(updatedTask);
          }
        }),
      );
    }

    $event.preventDefault();
    $event.stopPropagation();
  };

  enableLink(): void {
    this.element.nativeElement.removeAttribute('disabled');
    this.element.nativeElement.classList.remove('disabled');
  }

  disableLink(): void {
    this.element.nativeElement.setAttribute('disabled', 'disabled');
    this.element.nativeElement.classList.add('disabled');
  }
}
