import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../../config';
import { PHONE_NUMBER_SOURCE } from '../../../enums/phone-number-source';
import { SmsNumbers, SmsNumbersProvider, SmsRequestResponse } from './sms-numbers-interfaces';

@Injectable({
  providedIn: 'root',
})
export class SmsNumbersProviderService implements SmsNumbersProvider {
  readonly authToolsApiUrl: string;
  readonly messagingUrl: string;
  readonly defaultSource = PHONE_NUMBER_SOURCE.PLIVO;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.authToolsApiUrl = appConfig.env.authToolsApiUrl;
    this.messagingUrl = appConfig.env.messagingUrl;
  }

  getNumbers(supplierKey: string): Observable<SmsNumbers> {
    const ENDPOINT_URL = `${this.authToolsApiUrl}phone_number/?supplier_key=${supplierKey}`;
    return this.httpClient.get<SmsNumbers>(ENDPOINT_URL);
  }

  requestNumber(supplierKey: string, source: string): Observable<SmsRequestResponse> {
    const ENDPOINT_URL = `${this.messagingUrl}sms/purchase/`;
    return this.httpClient.post<SmsRequestResponse>(ENDPOINT_URL, {
      supplier_key: supplierKey,
      source,
    });
  }
}
