import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../config';

@Injectable()
export class AdditionalBusinsessOwnerService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = this.appConfig.env.internalApiUrl;
  }

  public getOwnersList(supplierKey: string): Observable<any[]> {
    const endpointUrl = this.apiUrl + `suppliers/owners/${supplierKey}`;
    return this.httpClient.get<any[]>(endpointUrl);
  }

  public removeOwner(supplierKey: string, loginKey: string): Observable<any> {
    const endpointUrl = this.apiUrl + `suppliers/owner/${supplierKey}/${loginKey}`;
    return this.httpClient.delete<any>(endpointUrl);
  }

  public editOwner(loginKey: string, body: any): Observable<any> {
    const endpointUrl = this.apiUrl + `suppliers/owner/${loginKey}`;
    return this.httpClient.put<any>(endpointUrl, body);
  }

  public addOwner(suppKey: string, body: any): Observable<any> {
    const endpointUrl = this.apiUrl + `suppliers/owners/${suppKey}/new`;
    return this.httpClient.put<any>(endpointUrl, body);
  }
}
