import { of } from 'rxjs';

export class SupplierMarketplaceDict {
  static readonly apiStatuses = of([
    { id: 'not-needed', label: 'Not needed' },
    { id: 'needed', label: 'Needed' },
    { id: 'in-review', label: 'In review' },
    { id: 'confirmed', label: 'Confirmed' },
    { id: 'rejected', label: 'Rejected' },
  ]);

  static readonly accessStatuses = of([
    { id: 'Incomplete', label: 'Incomplete' },
    { id: 'Pending', label: 'Pending' },
    { id: 'Verified', label: 'Verified' },
    { id: 'Rejected', label: 'Rejected' },
  ]);

  static readonly fundStatusList = of([
    { label: 'Not Sent', id: 'Not Sent', disabled: false },
    { label: 'Sent', id: 'Sent', disabled: false },
    { label: 'Rejected', id: 'Rejected', disabled: false },
    { label: 'Approved', id: 'Approved', disabled: true },
  ]);

  static readonly freeTrialFeeScheduleKeys = [
    '66328f49-4705-434d-bfad-dfbd3f17e79c',
    '0d06212b-50b6-4441-bee8-0dff6d86c4fa',
    '24f17603-bf35-4516-9992-965b6f8059df',
  ];
}
