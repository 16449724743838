import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ALL_OPTION } from '../../../../../shared/checkbox-filters/all-option';
import { ICheckboxFiltersOption } from '../../../../../shared/checkbox-filters/checkbox-filters-option.interface';
import { COOKIE_TYPES } from '../../../../../shared/services/cookie-service/cookie-options';
import { CookieService } from '../../../../../shared/services/cookie-service/cookie-service';
import { trackByIndex } from '../../../../../shared/utils/track-by-index.util';
import { DocumentationReportSection } from '../../../../model/documentation/documentation-report-section.model';
import { DocumentationReportTable } from '../../../../model/documentation/documentation-report-table.model';
import { SupplierOwnerModel } from '../../../../model/supplier-owner.model';

@Component({
  selector: 'app-documentation-card',
  templateUrl: './documentation-card.component.html',
  styleUrls: ['./documentation-card.component.scss'],
})
export class DocumentationCardComponent implements OnInit {
  @Input() reportTitle: string;
  @Input() supplierKey: string;
  @Input() owner: SupplierOwnerModel;
  @Input() set availableSections(availableSections: DocumentationReportSection[]) {
    if (availableSections === null || availableSections === undefined) {
      return;
    }

    this._availableSections = availableSections;
    this.filterOptions = availableSections.map((section) => {
      return {
        checked: this.hasCheckedOption(section.value) || this.hasCheckedOption(ALL_OPTION),
        value: section.value,
        title: section.name,
      };
    });
    this.emitFilterOptions();
    this.setReportsToShow();
  }

  @Input() set allReportsMap(allReports: { [key: string]: DocumentationReportTable }) {
    this._allReportsMap = allReports;
    this.setReportsToShow();
  }
  @Input() requestsLoading: boolean;

  @Output() filterOptionsChanged = new EventEmitter<string[]>();
  filterOptions: ICheckboxFiltersOption[] = [];
  filteredReportsToShow: DocumentationReportTable[] = [];
  _availableSections: DocumentationReportSection[] = [];

  readonly selectReportText = 'Please, select a section';
  readonly noSectionsAvailableText = 'There is no data available';
  readonly trackBy = trackByIndex;

  private _allReportsMap: { [key: string]: DocumentationReportTable };

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    this.initSelectedReportOptions();
  }

  filtersSelectionChange(newOptions: ICheckboxFiltersOption[]) {
    this.filterOptions = newOptions;
    this.emitFilterOptions();
    this.saveSelectedReportOptions();
    this.setReportsToShow();
  }

  private emitFilterOptions() {
    const selectedReportTypes = this.filterOptions
      .filter((option) => option.value !== ALL_OPTION && option.checked === true)
      .map((option) => option.value);
    this.filterOptionsChanged.emit(selectedReportTypes);
  }

  private setReportsToShow() {
    this.filteredReportsToShow = [];
    this.filterOptions
      .filter((option) => option.checked)
      .forEach((option) => {
        if (this._allReportsMap && this._allReportsMap[option.value]) {
          this.filteredReportsToShow.push(this._allReportsMap[option.value]);
        }
      });
  }

  private hasCheckedOption(optionCheck: string): boolean {
    const findFilterOption = this.filterOptions.find((option) => option.value === optionCheck);
    return findFilterOption && findFilterOption.checked;
  }

  private saveSelectedReportOptions(): void {
    const optionsValue = JSON.stringify(this.filterOptions);
    this.cookieService.setCookie(COOKIE_TYPES.SELECTED_DOCUMENTATION_OPTIONS, optionsValue);
  }

  private initSelectedReportOptions(): void {
    const cookie = this.cookieService.getCookie(COOKIE_TYPES.SELECTED_DOCUMENTATION_OPTIONS);
    const selectedFilterOptionsCookie = cookie && JSON.parse(this.cookieService.getCookie(COOKIE_TYPES.SELECTED_DOCUMENTATION_OPTIONS));
    if (!selectedFilterOptionsCookie) {
      this.filterOptions = [{ checked: true, value: ALL_OPTION, title: ALL_OPTION }];
    } else {
      this.filterOptions = selectedFilterOptionsCookie;
    }
    this.saveSelectedReportOptions();
  }
}
