import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ICorelation, IRealTimeValidator, IRealTimeValidatorDTO, ITRTaskDTO } from './real-time-validator.interfaces';
import { __CORELATION_DATA__, __REAL_TIME_VALIDATOR_DATA__ } from './real-time-validator-data.mock';

@Injectable()
export class RealTimeValidatorMockService implements IRealTimeValidator {
  getRealTimeValidation(supplierKey: string, loginKey: string): Observable<IRealTimeValidatorDTO> {
    return of(__REAL_TIME_VALIDATOR_DATA__ as IRealTimeValidatorDTO);
  }

  public requestValidation(supplierKey: string, loginKey: string, taskDTO: ITRTaskDTO): Observable<ICorelation> {
    return of(__CORELATION_DATA__);
  }

  public getValidationStatus(corelationId: string) {
    return of(corelationId);
  }
}
