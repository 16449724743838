import { Component, Input } from '@angular/core';

import { FadeOut } from '../../../../../shared/animations/fade-out';
import { DocumentationReportTable } from '../../../../model/documentation/documentation-report-table.model';

@Component({
  selector: 'app-documentation-report',
  templateUrl: './documentation-report.component.html',
  animations: [FadeOut],
})
export class DocumentationReportComponent {
  @Input() set report(r: DocumentationReportTable) {
    this.documentationReportTable = r;
    this.reportExists =
      !!this.documentationReportTable &&
      !!this.documentationReportTable.reports &&
      !!this.documentationReportTable.reports.columns &&
      !!this.documentationReportTable.reports.columns.length;
  }

  documentationReportTable: DocumentationReportTable;
  reportExists: boolean;
  errorText = 'Sorry, we can not load the data';
  readonly sectionHeight = '600px';
}
