import { Injectable } from '@angular/core';

import { SupplierDetailsModel } from '../../../model/supplier.model';
import { getMainOwner } from '../utils/get-main-owner';
import { IRealTimeInputFactory, ITRDataError, ITRTaskDTO, IUWOwner } from './real-time-validator.interfaces';

const THOMSON_REUTERS_TASK_NAME = 'thomson_reuters/request_personal_information_validation';

@Injectable({
  providedIn: 'root',
})
export class RealTimeInputFactoryService implements IRealTimeInputFactory {
  constructor() {}

  fromSupplier(supplier: SupplierDetailsModel, whichOwner?: number): ITRTaskDTO | ITRDataError {
    const OWNER = undefined === whichOwner ? getMainOwner(supplier) : <IUWOwner>supplier.owners[whichOwner];

    const SSN = this.formatSSN(OWNER.SSN);
    if (!SSN) {
      return <ITRDataError>{
        error: 'Run Clear ID API Error',
        message: 'SSN is incorrect',
      };
    }

    if (OWNER.dateOfBirth.length < 8) {
      return <ITRDataError>{
        error: 'Run Clear ID API Error',
        message: 'Date of Birth is incorrect',
      };
    }

    return {
      task_name: THOMSON_REUTERS_TASK_NAME,
      task_input: {
        supplier_key: supplier.supplierKey,
        person_id: OWNER.loginKey,
        first_name: OWNER.firstName,
        last_name: OWNER.lastName,
        birth_day: OWNER.dateOfBirth.substr(3, 2), // TODO: Get day filed by zero
        birth_month: OWNER.dateOfBirth.substr(0, 2),
        birth_year: OWNER.dateOfBirth.substr(6, 4),
        city: supplier.homeAddress.city,
        ssn: this.formatSSN(OWNER.SSN),
        state: supplier.homeAddress.state,
        street: supplier.homeAddress.addr1,
        zip_code: supplier.homeAddress.postalCode,
      },
    };
  }

  isSsnProperLength(ssn: string): boolean {
    if (ssn.length !== 9) {
      return false;
    }

    return true;
  }

  formatSSN(ssn?: string): string | undefined {
    if (!ssn) {
      return;
    }

    ssn = ssn.replace(/\D/g, '');

    if (ssn.length < 9) {
      return;
    }

    return `${ssn.substr(0, 3)}-${ssn.substr(3, 2)}-${ssn.substr(5)}`;
  }
}
