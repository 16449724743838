import { autoserialize } from 'cerialize';

import { SUPPLIER_STATUS } from './../../shared/consts/supplier-status.enum';

export class SupplierStatusModel {
  @autoserialize
  hasMarketplaces?: boolean;

  @autoserialize
  legalSigned?: boolean;

  @autoserialize
  instantAccess?: boolean;

  @autoserialize
  fundPartStatus?: string;

  @autoserialize
  ascApiStatus?: string;

  @autoserialize
  nextPayment?: string;

  @autoserialize
  enrollmentStart?: string;

  @autoserialize
  hasReceivablePurchase?: boolean;

  @autoserialize
  generalInfoUpdated?: boolean;

  @autoserialize
  efsAccount?: boolean;

  @autoserialize
  supplierStatus?: SUPPLIER_STATUS;

  @autoserialize
  pmtCfgStatus?: string;

  @autoserialize
  hasPaymentHistory?: boolean;

  @autoserialize
  pmtCfgProvided?: boolean;

  @autoserialize
  hasAutoTransferEnabled?: boolean;

  @autoserialize
  hasForwardPaymentEnabled?: boolean;

  @autoserialize
  supAdvStatus?: string;

  @autoserialize
  supAdvLiability = 0.0;

  constructor(
    hasMarketplaces: boolean,
    legalSigned: boolean,
    instantAccess: boolean,
    fundPartStatus: string,
    ascApiStatus: string,
    nextPayment: string,
    enrollmentStart: string,
    hasReceivablePurchase: boolean,
    generalInfoUpdated: boolean,
    efsAccount: boolean,
    supplierStatus: string,
    pmtCfgStatus: string,
    hasPaymentHistory: boolean,
    pmtCfgProvided: boolean,
    hasAutoTransferEnabled: boolean,
    hasForwardPaymentEnabled: boolean,
    supAdvStatus: string,
    supAdvLiability = 0,
  ) {
    this.hasMarketplaces = hasMarketplaces;
    this.legalSigned = legalSigned;
    this.instantAccess = instantAccess;
    this.fundPartStatus = fundPartStatus;
    this.ascApiStatus = ascApiStatus;
    this.nextPayment = nextPayment;
    this.enrollmentStart = enrollmentStart;
    this.hasReceivablePurchase = hasReceivablePurchase;
    this.generalInfoUpdated = generalInfoUpdated;
    this.efsAccount = efsAccount;
    this.supplierStatus = supplierStatus as SUPPLIER_STATUS;
    this.pmtCfgStatus = pmtCfgStatus;
    this.hasPaymentHistory = hasPaymentHistory;
    this.pmtCfgProvided = pmtCfgProvided;
    this.supAdvStatus = supAdvStatus;
    this.supAdvLiability = supAdvLiability;
    this.hasAutoTransferEnabled = hasAutoTransferEnabled;
    this.hasForwardPaymentEnabled = hasForwardPaymentEnabled;
  }
}
