<div class="example-container mat-elevation-z8">
  <mat-card>
    <mat-card-title>Event rules</mat-card-title>
    <button *ngIf="hasCRMPermission" class="add-rule" mat-fab routerLink="../add">
      <mat-icon>add</mat-icon>
    </button>
    <mat-card-content>
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Rule Name</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef> Created</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.created_at | fromUnix | date: 'MM/dd/yyyy' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="eventType">
          <mat-header-cell *matHeaderCellDef> Event type</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.eventType }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="badge badge-warning" *ngFor="let action of element.actions">
              {{ action.type }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="execution_count">
          <mat-header-cell *matHeaderCellDef> Executions</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.execution_count }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="last_executed">
          <mat-header-cell *matHeaderCellDef> Last executed</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.last_executed | fromUnix | date: 'MM/dd/yyy' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <mat-header-cell *matHeaderCellDef> Updated at</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.updated_at | fromUnix | date: 'MM/dd/yyy' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_by_name">
          <mat-header-cell *matHeaderCellDef> Created by</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.created_by_name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="previewHistory">
          <mat-header-cell *matHeaderCellDef fxFlex="40px"></mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="40px">
            <button mat-icon-button (click)="navigateToHistory(element.uuid)">
              <mat-icon>history</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="userActions">
          <mat-header-cell *matHeaderCellDef fxFlex="40px"></mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="40px">
            <button *ngIf="hasCRMPermission" mat-icon-button (click)="remove($event, element)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="navigateToEdit(row.uuid)"></mat-row>
      </mat-table>
    </mat-card-content>
    <!--<mat-paginator #paginator-->
    <!--[pageSize]="10"-->
    <!--[pageSizeOptions]="[5, 10, 20]">-->
    <!--</mat-paginator>-->
  </mat-card>
</div>
