import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isArray, isUndefined } from 'util';

import { CardFormgroupMessageService } from '../../../../shared/card-formgroup/card-formgroup-message.service';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { DialogService } from '../../../../shared/dialog/dialog.service';
import { Logger } from '../../../../shared/logger';
import { AppState } from '../../../../store/app.reducers';
import { UwStatus } from '../../../enums/uw-status';
import { SupplierCompilanceStatus } from '../../../model/supplier-compilance-status';
import { SupplierMarketplaceDetailsModel } from '../../../model/supplier-marketplace.model';
import { RxEditBaseComponent } from '../../../rx-edit-base.component';
import { SupplierComplianceActionService } from '../../../services/supplier-compliance-action.service';
import { selectSupplierMarketplaces } from '../../../store/supplier/supplier.actions';
import {
  ACCOUNT_ACCESS_INVITATION_KEY,
  AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY,
  AMAZON_SELLER_CENTRAM_PERM_KEY,
  SupplierOnboardingService,
} from '../supplier-onboarding.service';
import { ErrorLogger } from './../../../../shared/services/error-logger/error-logger.service';

@Component({
  selector: 'app-supplier-onboarding-marketplace-permissions',
  templateUrl: './supplier-onboarding-marketplace-permissions.component.html',
  styleUrls: ['./supplier-onboarding-marketplace-permissions.component.scss'],
})
export class SupplierOnboardingMarketplacePermissionsComponent extends RxEditBaseComponent implements OnInit {
  @Input() supplierKey: string;

  amazonMarketplaceDetails?: SupplierMarketplaceDetailsModel;
  amazonMarketplaceDetailsLoading?: boolean;
  amazonMarketplacePermissions?: SupplierCompilanceStatus;
  amazonMarketplaceAccess?: SupplierCompilanceStatus;
  complianceStatuses?: { amazonAccess: SupplierCompilanceStatus[]; amazonPermissions: SupplierCompilanceStatus[] };
  resendInvitationBusy = false;
  reloadPermissionsBusy = false;
  UwStatus = UwStatus;

  readonly MARKETPLACE_ACCESS_FORM = 'MARKETPLACE_ACCESS_FORM';
  readonly MARKETPLACE_PERMISSION_FORM = 'MARKETPLACE_PERMISSION_FORM';
  readonly SERVICE_CLASS_NAME = 'SupplierMarketplacesService';
  readonly availableStatusesForSelect: UwStatus[] = [UwStatus.INCOMPLETE, UwStatus.PENDING, UwStatus.REJECTED, UwStatus.VERIFIED];
  readonly permissionsTypes = of(['Orders', 'Reports', 'Settings', 'Amazon Lending', 'Inventory', 'Pricing', 'Advertising']);

  private readonly inviteStatusesToBeConfirmed: UwStatus[] = [UwStatus.PENDING, UwStatus.VERIFIED];
  private readonly resendInviteConfirmModalTitle: string = 'Confirm resending invitation';
  private readonly resendInviteConfirmMsgs: Partial<Record<UwStatus, string>> = {
    [UwStatus.PENDING]:
      'Amazon Invite status is currently set to "Pending" meaning an invite is currently in-process for this supplier. \
      Please confirm you would like to terminate the current invite workflow and reprocess this invite from start, \
      which will reset the Amazon Invite status to "Incomplete" and restart the invite process.',
    [UwStatus.VERIFIED]:
      'Amazon Invite status is currently set to "Verified" meaning an invite has previously been successfully \
      processed. Please confirm you would like to reprocess this invite from start, \
      which will reset the Amazon Invite status to "Incomplete" and restart the invite process.',
  };

  constructor(
    store: Store<AppState>,
    messageService: CardFormgroupMessageService,
    errorLogger: ErrorLogger,
    private formBuilder: FormBuilder,
    private complianceActionService: SupplierComplianceActionService,
    public dialogService: MatDialog,
    private onboardingService: SupplierOnboardingService,
    private toastr: ToastrService,
    private readonly dialog: DialogService,
  ) {
    super(store, messageService, errorLogger);
  }

  ngOnInit() {
    this.formGroups[this.MARKETPLACE_ACCESS_FORM] = this.formBuilder.group({
      status: '',
    });
    this.formGroups[this.MARKETPLACE_PERMISSION_FORM] = this.formBuilder.group({
      status: '',
    });

    this.fetchAccessAndPerm();

    this.amazonMarketplaceDetailsLoading = true;

    this.subscriptions.push(
      this.store.select(selectSupplierMarketplaces).subscribe(
        (marketplaces) => {
          this.amazonMarketplaceDetails = undefined;

          if (!isUndefined(marketplaces)) {
            this.amazonMarketplaceDetails = marketplaces.find((marketplaceModel: SupplierMarketplaceDetailsModel) => {
              return marketplaceModel.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY;
            });
          }

          this.amazonMarketplaceDetailsLoading = false;
        },
        (error) => {
          Logger.log(error);
          this.amazonMarketplaceDetailsLoading = false;
        },
      ),
    );
  }

  private fetchAccessAndPerm() {
    this.subscriptions.push(
      this.onboardingService.getAmazonMarketplaceAccess(this.supplierKey, true).subscribe((result) => {
        this.amazonMarketplaceAccess = result;
      }),
    );

    this.subscriptions.push(
      this.onboardingService
        .getAmazonMarketplacePermissions(this.supplierKey, true)
        .pipe(
          map((result) => {
            if (isArray(result) && result.length > 0) {
              return result[0];
            } else {
              return result;
            }
          }),
        )
        .subscribe((result: SupplierCompilanceStatus) => {
          this.amazonMarketplacePermissions = result;
        }),
    );

    this.subscriptions.push(
      this.onboardingService.getComplianceStatuses(this.supplierKey, true).subscribe((result) => {
        if (result.length === 0) {
          return;
        }
        this.complianceStatuses = {
          amazonAccess: result.filter(function (status) {
            return status.compliance_key === ACCOUNT_ACCESS_INVITATION_KEY;
          }),
          amazonPermissions: result.filter(function (status) {
            return status.compliance_key === AMAZON_SELLER_CENTRAM_PERM_KEY;
          }),
        };

        if (!isUndefined(this.formGroups[this.MARKETPLACE_ACCESS_FORM]) && !isUndefined(this.complianceStatuses)) {
          this.formGroups[this.MARKETPLACE_ACCESS_FORM].patchValue({
            status: this.complianceStatuses.amazonAccess[0].status,
          });
        }

        if (!isUndefined(this.formGroups[this.MARKETPLACE_PERMISSION_FORM]) && !isUndefined(this.complianceStatuses)) {
          this.formGroups[this.MARKETPLACE_PERMISSION_FORM].patchValue({
            status: this.complianceStatuses.amazonPermissions[0].status,
          });
        }
      }),
    );
  }

  public reloadPermissions() {
    this.reloadPermissionsBusy = true;

    const checkPermActionData = {
      supplier_key: this.supplierKey,
      compliance_key: AMAZON_SELLER_CENTRAM_PERM_KEY,
      compliance_action_key: this.amazonMarketplacePermissions.compliance_action_key,
      compliance_action_type: 'permissions', // TODO nkler: use const / readonly instead hard coded string
    };

    this.complianceActionService.createAction(checkPermActionData, null).subscribe(
      () => {
        this.toastr.info('Check permissions started');
        this.reloadPermissionsBusy = false;

        this.fetchAccessAndPerm();
      },
      (error) => {
        Logger.log(error);
        this.toastr.error('Check permissions failed');

        this.reloadPermissionsBusy = false;
      },
    );
  }

  public confirmRemoveMarketplaceAccess() {
    const msg = 'Are you sure you want to remove marketplace access?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.subscriptions.push(
          this.onboardingService
            .deleteMarketplaceAccess(this.complianceStatuses.amazonAccess[0], this.complianceStatuses.amazonPermissions[0])
            .subscribe(
              (result) => {
                this.fetchAccessAndPerm();
                this.toastr.success('Marketplace Access successfully removed');
              },
              (error) => {
                Logger.log(error);
                this.toastr.error('Could not get Permission Status info.');
              },
            ),
        );
      }
    });
  }

  onResendInvitation(): void {
    const invitationStatus: UwStatus | null | undefined =
      this.complianceStatuses && (this.complianceStatuses.amazonAccess[0].status as UwStatus);
    const confirmationRequired = invitationStatus && this.inviteStatusesToBeConfirmed.includes(invitationStatus);
    if (!confirmationRequired) {
      this.doResendInvitation();
      return;
    }

    this.dialog
      .openConfirm({ title: this.resendInviteConfirmModalTitle, body: this.resendInviteConfirmMsgs[invitationStatus] })
      .then((confirmedByUser) => {
        if (confirmedByUser) {
          this.doResendInvitation();
        }
      });
  }

  doResendInvitation(): void {
    this.resendInvitationBusy = true;
    const inviteActionData = {
      supplier_key: this.supplierKey,
      compliance_key: ACCOUNT_ACCESS_INVITATION_KEY,
      compliance_action_key: this.amazonMarketplaceAccess.compliance_action_key,
      compliance_action_type: 'invite', // TODO nkler: use const / readonly instead hard coded string
      invite: {
        ext_auth_login: this.amazonMarketplaceDetails.externalLogin,
      },
    };

    this.subscriptions.push(
      this.complianceActionService.createAction(inviteActionData, null).subscribe(
        () => {
          this.toastr.info('Invitation check started');
          this.resendInvitationBusy = false;

          this.fetchAccessAndPerm();
        },
        (error) => {
          Logger.log(error);
          this.toastr.error('Invitation check failed');

          this.resendInvitationBusy = false;
        },
      ),
    );
  }

  public saveActionStatuses(formId) {
    if (this.complianceStatuses.amazonAccess && this.complianceStatuses.amazonAccess[0]) {
      const amazonCompliance = [
        { ...this.complianceStatuses.amazonAccess[0], ...this.formGroups[this.MARKETPLACE_ACCESS_FORM].value },
        { ...this.complianceStatuses.amazonPermissions[0], ...this.formGroups[this.MARKETPLACE_PERMISSION_FORM].value },
      ];

      const actions = amazonCompliance.map((mp) => {
        let action: Observable<any>;
        if (!mp.compliance_status_key) {
          if (!isUndefined(mp)) {
            action = this.complianceActionService.createStatus({
              supplier_key: this.supplierKey,
              compliance_key: mp.compliance_key,
              status: mp.status,
            });
          }
        } else {
          if (!isUndefined(mp)) {
            action = this.complianceActionService.saveStatus(mp.compliance_status_key, {
              supplier_key: this.supplierKey,
              compliance_key: mp.compliance_key,
              status: mp.status,
            });
          }
        }
        return action;
      });

      forkJoin(actions).subscribe(
        (data) => {
          this.messageService.broadcast(this.formGroups[formId], 'saveSuccess');
          this.fetchAccessAndPerm();
        },
        (error) => {
          this.messageService.broadcast(this.formGroups[formId], 'saveError');
          this.toastr.error('Error while saving status!');
        },
      );
    }
  }

  protected resolveServiceClassBy(formGroupKey: string): string {
    return this.SERVICE_CLASS_NAME;
  }

  protected resolveParamsBy(formGroupKey: string): any[] {
    return [this.amazonMarketplaceDetails.marketplaceKey];
  }

  protected getSupplierKey(): string {
    return this.supplierKey;
  }
}
