import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
})
export class EmailSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
