import { Injectable } from '@angular/core';
import { isEqual, uniq } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EvaComponent } from '../eva/eva.component';
import { KeyValue } from '../models/key-value';
import { FEATURE_FLAGS } from './feature-flags.const';
import { FEATURE_FLAGS_ENV, FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY } from './feature-flags.enum';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable()
export class FeatureFlagsEvaService {
  private readonly EXEC_FLAGS_SESSION_KEY = 'FeatureExecFlagsEva';
  private readonly EXEC_FLAGS_SESSION_LAST_CHANGE_KEY = 'FeatureExecFlagsLastChangeEva';
  private readonly ALWAYS_ON_FLAGS = ['🎪'];
  private appEva: EvaComponent;
  private initialized = false;

  constructor(private featureFlagsService: FeatureFlagsService) {}

  setAppEva(component: EvaComponent): void {
    this.appEva = component;
  }

  reset(): void {
    this.initialized = false;
  }

  async initFF(availableFlags: string[] = [], buildProvidedFlags: FEATURE_FLAGS[] = []): Promise<boolean> {
    if (this.initialized) {
      // return false;
    }

    this.initialized = true;

    const globalSettingsFlagFromEva = await this.fetchAvailableFlagsGlobalSettingsStatus().toPromise();

    let execFlags = [];
    const lastChangedInStorage = this.featureFlagsService.getLastChangedByKey(FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY.EVA);
    const lastChangedInSession = this.getLastChangeExecFlagsFromSession();
    if (lastChangedInSession > lastChangedInStorage || lastChangedInStorage === 0) {
      execFlags = this.fetchAvailableFlagsExecStatus();
    }

    const recalculatedFlags = await this.featureFlagsService.getRecalculatedFlagsStatus(
      FEATURE_FLAGS_ENV.EVA,
      availableFlags,
      buildProvidedFlags,
      globalSettingsFlagFromEva,
      execFlags,
    );

    const flags = recalculatedFlags.map(({ key, exec }) => ({ [key]: exec }));

    this.storeAvailableFlagsExecStatus(flags);

    return true;
  }

  storeAndSend(flags: KeyValue<boolean>[]): void {
    const evaFlags = [];
    flags.forEach((flag, index) => {
      const ent = Object.entries(flag)[0];
      const key = ent[0];
      let value = ent[1];

      if (this.ALWAYS_ON_FLAGS.includes(key)) {
        value = true;
        flags[index][key] = value;
      }

      if (value) {
        evaFlags.push(key);
      }
    });

    this.storeAvailableFlagsExecStatus(flags);
    this.appEva.sendMessage().sendUpdatedConfig(evaFlags);
  }

  storeAvailableFlagsExecStatus(flags: KeyValue<boolean>[]): void {
    const oldFlags = this.fetchAvailableFlagsExecStatus();

    if (!isEqual(oldFlags, flags)) {
      sessionStorage.setItem(this.EXEC_FLAGS_SESSION_LAST_CHANGE_KEY, (+new Date()).toString());
    }
    sessionStorage.setItem(this.EXEC_FLAGS_SESSION_KEY, JSON.stringify(flags));
  }

  clearAvailableFlagsExecStatus(): void {
    sessionStorage.removeItem(this.EXEC_FLAGS_SESSION_KEY);
    sessionStorage.removeItem(this.EXEC_FLAGS_SESSION_LAST_CHANGE_KEY);
  }

  fetchAvailableFlagsExecStatus(): KeyValue<boolean>[] {
    const flags = sessionStorage.getItem(this.EXEC_FLAGS_SESSION_KEY);

    return flags === null ? [] : JSON.parse(flags);
  }

  fetchAvailableFlagsGlobalSettingsStatus(): Observable<FEATURE_FLAGS[]> {
    return this.featureFlagsService.fetchAvailableFlagsGlobalSettingsStatus(FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY.EVA).pipe(
      map((flags) => {
        return uniq([...flags, ...this.ALWAYS_ON_FLAGS]) as FEATURE_FLAGS[];
      }),
    );
  }

  sendFlags(flags: string[]): void {
    if (this.appEva) {
      this.appEva.sendMessage().sendUpdatedConfig(flags);
    }
  }

  storeState(flags: string[]): Observable<any> {
    return this.featureFlagsService.storeState(FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY.EVA, flags);
  }

  getLastChangeExecFlagsFromSession(): number {
    const timestamp = sessionStorage.getItem(this.EXEC_FLAGS_SESSION_LAST_CHANGE_KEY);
    return timestamp === null ? 0 : +timestamp;
  }
}
