<h1 mat-dialog-title *ngIf="!displayDates; else titleWithDates">Choose reason</h1>
<ng-template #titleWithDates>
  <h1 mat-dialog-title>Update Opt-In / Opt-Out Dates</h1>
</ng-template>
<div mat-dialog-content>
  <div *ngIf="displayDates">
    <mat-form-field>
      <input matInput [matDatepicker]="startPicker" placeholder="Choose Opt-In Date" [(ngModel)]="reason.dates.optIn" />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput [matDatepicker]="endPicker" placeholder="Choose Opt-Out Date" [(ngModel)]="reason.dates.optOut" />
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="select-group">
    <mat-form-field class="full-width">
      <mat-select placeholder="Reason" [(ngModel)]="reason.reasonCode" data-test="reasonSelect">
        <mat-option
          *ngFor="let item of reasons | async; trackBy: reasonByReasonCode"
          [value]="item.codeValue"
          [attr.data-test]="item.codeValue"
        >
          {{ item.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="shouldShowMarketplaceSelect">
      <mat-select placeholder="Marketplace" [(ngModel)]="reason.mp_sup_key">
        <mat-option *ngFor="let marketplace of marketplaces; trackBy: marketpalceByMarketplaceKey" [value]="marketplace.mpSupKey">
          {{ marketplace.marketplaceName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-editor [(ngModel)]="reason.reasonNote"> </app-editor>

  <div *ngIf="warning" class="warning-message">
    <div class="alert alert-danger">{{ warning }}</div>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    [disabled]="!shouldSubmit"
    (click)="doCloseModalWithReason()"
    tabindex="2"
    color="accent"
    data-test="saveButton"
  >
    Save
  </button>
  <button mat-raised-button [mat-dialog-close]="null" tabindex="-1" color="warn">Cancel</button>
</div>
