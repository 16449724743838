<article class="article flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="buttons-wrapper">
        <app-spinner-button
          class="refresh"
          [disabled]="isBlocked"
          (onClick)="refreshButton()"
          [color]="'primary'"
          [loading]="refreshLoading"
          [customWidth]="'8rem'"
          [spinnerColor]="'white'"
          [text]="'Refresh'"
        >
        </app-spinner-button>
        <app-actions-menu
          *ngIf="exportMenuOptions"
          class="export-opt"
          menuLabel="Export"
          [menuOptions]="exportMenuOptions"
        ></app-actions-menu>
      </div>
      <app-filtering-panel-component
        (searchValueEmitter)="onFilterChanged($event)"
        [configuration]="filterConfig"
      ></app-filtering-panel-component>
      <div *ngIf="rows">
        <app-data-table-grid [nodesCounted]="true" [rows]="rows" [columnDefs]="columns" [domLayout]="'autoHeight'" [offset]="offset">
        </app-data-table-grid>
        <app-pyb-pagination *ngIf="rows" [paginationData]="paginationData" (onPageChange)="onPageChange($event)"> </app-pyb-pagination>
      </div>
    </div>
  </div>
</article>
