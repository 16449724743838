import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { TOOLS_THEME } from '../../../shared/editor/editor.enum';
import { FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY } from '../../../shared/feature-flags/feature-flags.enum';
import { BaseSupplierAction } from '../../../suppliers/supplier/base-supplier-action/base-supplier-action';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { EditorComponent } from './../../../shared/editor/editor.component';
import { FeatureFlagsService } from './../../../shared/feature-flags/feature-flags.service';
import { GlobalSettingsService } from './../service/global-settings.service';
import { KeyValueDictionaryComponent } from './../templates/key-value-dictionary/key-value-dictionary.component';
import { ListTemplateComponent } from './../templates/list-template/list-template.component';
import { NestedListTemplateComponent } from './../templates/nested-list-template/nested-list-template.component';
import { TaskTemplateComponent } from './../templates/task-template/task-template.component';

@Component({
  selector: 'app-edit-setting',
  templateUrl: './edit-setting.component.html',
})
export class EditSettingComponent extends SubscriberComponent implements OnInit {
  @ViewChild(ListTemplateComponent) listTemplate: ListTemplateComponent;
  @ViewChild(NestedListTemplateComponent) nestedListTemplate: NestedListTemplateComponent;
  @ViewChild(KeyValueDictionaryComponent) keyValueDictionary: KeyValueDictionaryComponent;
  @ViewChild(TaskTemplateComponent) taskTemplate: TaskTemplateComponent;
  @ViewChild(EditorComponent) appEditor: EditorComponent;

  readonly TOOLS_THEME = TOOLS_THEME;

  configType: string;
  configValue: any;
  isLoadingResult = false;
  settingForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<BaseSupplierAction>,
    private globalSettingsService: GlobalSettingsService,
    private toastrService: ToastrService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();

    this.configType = this.data.config_type;
    this.configValue = this.data.config_value;
  }

  createForm() {
    this.settingForm = this.formBuilder.group({
      config_key: [this.data.config_key],
      config_type: [this.data.config_type],
      config_value: [this.data.config_value],
    });
    this.settingForm.get('config_key').disable();
    this.settingForm.get('config_type').disable();
  }

  submit() {
    this.isLoadingResult = true;
    this.fixEditorValue();

    const dataToSave = {
      ...this.settingForm.value,
    };
    switch (this.configType) {
      case 'number':
        dataToSave.config_value = +dataToSave.config_value;
        break;
      case 'dict':
        const keyValues = this.keyValueDictionary.keyValueDictionaryForm.get('keyValues').value;
        dataToSave.config_value = this.convertKeyValuesToObject(keyValues);
        break;
      case 'task_template':
        dataToSave.config_value = this.mapTaskTemplate(this.taskTemplate.taskTemplateForm.value);
        break;
      // case 'email_base_template':
      //   dataToSave.config_value = { ...this.emailBaseTemplate.emailBaseTemplateForm.value };
      //   break;
      // case 'email_frontend_template':
      //   dataToSave.config_value = { ...this.emailFrontendTemplate.emailFrontendTemplateForm.value };
      //   break;
      // case 'email_frontend_config':
      //   dataToSave.config_value = { ...this.frontendEmailConfig.frontendEmailConfigForm.value };
      //   break;
      // case 'email_frontend_batch_config':
      //   dataToSave.config_value = { ...this.batchSupplierEmail.batchSupplierEmailForm.value };
      //   dataToSave.useTemplates = true;
      //   break;
      case 'list':
        dataToSave.config_value = this.listTemplate.listTemplateForm.get('listElements').value;
        break;
      case 'nested_list':
        dataToSave.config_value = this.nestedListTemplate.nestedListTemplateForm.get('listElements').value;
        break;
      // case 'email_config':
      //   dataToSave.config_value = { ...this.emailConfig.emailConfigForm.value };
      //   break;
      default:
        break;
    }

    this.subscriptions.push(
      this.globalSettingsService.edit(this.data.config_key, dataToSave).subscribe(
        () => {
          this.toastrService.success('Global setting successfully updated');
          if (this.data.config_key === FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY.IA) {
            this.featureFlagsService.clearAvailableFlagsExecStatus();
            location.reload();
          }
          this.closeDialog(true);
        },
        (error) => {
          this.toastrService.error(error.error.meta.errors[0].message);
          this.closeDialog(false);
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }

  convertKeyValuesToObject(keyValues: any[]): Object {
    const object: Object = {};

    keyValues.forEach((keyValue) => {
      object[keyValue.key] = keyValue.value;
    });

    return object;
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  mapTaskTemplate(templateRawData) {
    const templateData = { ...templateRawData };
    templateData.assignee = {
      name: templateData['assigneeName'],
      email: templateData['assigneeEmail'],
      type: templateData['assigneeType'],
      uuid: templateData['assigneeUuid'],
    };

    delete templateData['assigneeName'];
    delete templateData['assigneeEmail'];
    delete templateData['assigneeType'];
    delete templateData['assigneeUuid'];
    return templateData;
  }

  private fixEditorValue(): void {
    if (!this.appEditor) {
      return;
    }
    this.settingForm.patchValue(
      { config_value: this.appEditor.getRawContent() },
      {
        emitEvent: false,
      },
    );
  }
}
