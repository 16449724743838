<section class="container-fluid flex-container">
  <app-supplier-payment-details-header
    *ngIf="batch"
    [isDebit]="isDebit"
    [isAutoDebit]="isAutoDebit"
    [title]="title"
    [description]="description"
    [task]="task"
    [lastAddedPayments]="lastAddedPayments"
    [lastUpdatedPayments]="lastUpdatedPayments"
    [showTaskStatus]="showTaskStatus"
    [showLastUpdateStatus]="showLastUpdateStatus"
    [showLastAddedPaymentsStatus]="showLastAddedPaymentsStatus"
    [showApproveOrSubbmitLabel]="showApproveOrSubbmitLabel"
    [showRefresh]="showRefresh"
    [showAction]="showAction"
    [isSubmitted]="isSubmitted"
    (onApproveOrSubmitPaymentOnClick)="approveOrSubbmitPaymentOnClick()"
    (onEditBatchOnClick)="editBatchOnClick()"
    (onUpdatePaymentOnClick)="updatePaymentOnClick()"
    (onAddPaymentsOnClick)="addPaymentsOnClick()"
    (onRefreshViewOnClick)="refreshViewOnClick()"
    (unlock)="onUnlock()"
  ></app-supplier-payment-details-header>

  <article class="article flex-container" *ngIf="isBatchInEditMode">
    <div class="card flex-container">
      <div class="card-content flex-container">
        <form class="custom-form" [formGroup]="editPaymentBatchForm" (ngSubmit)="onEditionSubmit()">
          <div class="form-content">
            <fieldset class="form-section">
              <mat-form-field class="field-text">
                <input matInput type="text" placeholder="Transaction Key" formControlName="name" />
              </mat-form-field>
              <mat-form-field (click)="picker.open()">
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" readonly formControlName="date" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="field-text">
                <input matInput type="time" placeholder="Choose a time" formControlName="time" />
              </mat-form-field>
            </fieldset>

            <fieldset class="form-section">
              <mat-radio-group class="full-width" formControlName="batchType" (click)="checkBatchOption()">
                <mat-radio-button [value]="BatchType.INSTANT">Is this batch for Instant Access payment requests? </mat-radio-button>
                <mat-radio-button [value]="BatchType.SCHEDULED">Is this batch for scheduled payments?</mat-radio-button>
                <mat-radio-button [value]="BatchType.ADVANCE">Is this batch for Instant Advance payment requests? </mat-radio-button>
              </mat-radio-group>
            </fieldset>

            <fieldset class="form-section locked-box">
              <mat-checkbox formControlName="locked">Is this batch locked?</mat-checkbox>
            </fieldset>

            <h6>Payment types to include</h6>
            <fieldset class="form-section">
              <mat-checkbox formControlName="addAchPmts">ACH</mat-checkbox>
              <mat-checkbox formControlName="addEfsPmts">EFS</mat-checkbox>
              <mat-checkbox formControlName="addPpmPmts">Debit Card Push Payments</mat-checkbox>
              <mat-checkbox formControlName="addWirPmts">Wire</mat-checkbox>
            </fieldset>

            <fieldset class="form-section">
              <mat-form-field class="full-width">
                <mat-select placeholder="Premium Fee Option" formControlName="paymentBatchFeeCode">
                  <mat-option *ngFor="let option of premiumFeeOption | async" [value]="option.codeValue">
                    {{ option.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </fieldset>

            <h6>Reviewers</h6>
            <fieldset class="form-section">
              <mat-checkbox *ngFor="let reviewer of reviewers">{{ reviewer.reviewerLoginId }}</mat-checkbox>
            </fieldset>
          </div>
          <div class="form-submit">
            <button mat-raised-button class="btn btn-cancel pull-right" color="warn" (click)="onEditionCancel()">Cancel</button>
            <button mat-raised-button class="btn btn-success pull-right" type="submit" color="accent">Save</button>
          </div>
        </form>
      </div>
    </div>
  </article>

  <article class="article flex-container" [hidden]="isBatchInEditMode">
    <div class="card flex-container">
      <div class="card-content flex-container">
        <ng-container *ngIf="rows">
          <app-data-table-grid
            [nodesCounted]="true"
            [rows]="rows"
            [columnDefs]="columnsData"
            [sideBar]="filterSideBar"
            [customGridOptions]="customGridOptions"
          ></app-data-table-grid>
        </ng-container>
        <div *ngIf="paymentsNotFound || paymentsLoading" class="d-flex justify-content-center mt-4">
          <span *ngIf="paymentsNotFound">Cannot fetch and display Payment Batches from the backend.</span>
          <span *ngIf="paymentsLoading">Fetching...</span>
        </div>
      </div>
    </div>
  </article>
</section>
