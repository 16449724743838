import { isObject } from 'util';

// Enums
export enum AccountBalance {
  All = '',
  Pass = 'Pass',
  Fail = 'Fail',
}

export enum DueFromMP {
  All = '',
  Pass = 'Pass',
  Fail = 'Fail',
}

export enum DerivedHealth {
  All = '',
  Ok = 'OK',
  AtRisk = 'At Risk',
}

export enum PayabilityAccounts {
  All = '',
  Active = 'Active',
  Pending = 'Pending',
  Suspended = 'Suspended',
}

export enum DerivedStatus {
  All = '',
  Approved = 'Approved',
  Hold = 'Hold',
}

export enum PaymentOverrideAmount {
  All = '',
  Yes = 'Yes',
  No = 'No',
}

export enum PaymentMlDecisionAmount {
  All = '',
  Yes = 'Approve',
  No = 'Unapprove',
}

export enum PaymentApproval {
  All = '',
  Yes = 'Approved',
  No = 'Unapproved',
  Reject = 'Rejected',
  None = 'None',
}

export enum MLRecomendation {
  All = '',
  Review = 'Review',
  Approve = 'Approve',
}

export enum BatchType {
  INSTANT,
  SCHEDULED,
  ADVANCE,
}

export enum EditableColumns {
  'paymentOverrideAmount',
  'confPayeeDiverted',
  'paymentOutcome.codeValue',
  'reviewerNote',
}

export function colorRenderer(cellParams): any {}

export function oneColorClassAssigner(cellData: any): string {
  return cellData.value > 0 ? 'fail' : '';
}

export function twoColorClassAssigner(cellData: any): string {
  if (cellData.value === 0) {
    return;
  }
  return cellData.value > 0 ? 'pass' : 'fail';
}

export function removeFromBatchRenderer(instance, td, row, col, prop, value, cellProperties): any {
  td.className = 'action';
  td.innerHTML = 'Remove from batch';
  return td;
}

function getCellCssClass(value: string): string {
  const greenValues = ['Approved', 'Approve', 'Confirmed', 'OK', 'Pass', 'Active'];
  const redValues = ['Cancel', 'Diverted', 'Fail', 'Missing', 'Suspended'];
  const yellowValues = ['Hold', 'At Risk'];

  if (greenValues.indexOf(value) > -1) {
    return ' pass';
  }
  if (redValues.indexOf(value) > -1) {
    return ' fail';
  }
  if (yellowValues.indexOf(value) > -1) {
    return ' warning';
  }
}

export function threeColorClassAssigner(cellParams: any, dictionaries?: any, type?: string): string {
  const greenValues = ['Approved', 'Approve', 'Confirmed', 'OK', 'Pass', 'Active'];
  const redValues = ['Cancel', 'Diverted', 'Fail', 'Missing', 'Suspended', 'Declined'];
  const yellowValues = ['Hold', 'At Risk'];

  if (dictionaries) {
    const dictionary = dictionaryAssigner(cellParams, dictionaries);
    const mappedColValue = displayNameAssigner(dictionary, cellParams);
    cellParams.value = mappedColValue;
  }

  if (greenValues.indexOf(cellParams.value) > -1) {
    return 'pass';
  }
  if (redValues.indexOf(cellParams.value) > -1) {
    return 'fail';
  }
  if (yellowValues.indexOf(cellParams.value) > -1) {
    return 'warning';
  }
}

export function getApprovalCellCssClass(cellData: any): string {
  const greenValues = ['success', 'Pass', 'Approved'];
  const redValues = ['danger', 'Fail', 'Unapproved'];
  const yellowValues = ['warning', 'Rejected'];

  if (greenValues.indexOf(cellData.value) > -1) {
    return ' pass';
  }
  if (redValues.indexOf(cellData.value) > -1) {
    return ' fail';
  }
  if (yellowValues.indexOf(cellData.value) > -1) {
    return ' warning';
  }
}

export function dictionaryAssigner(params: any, dictionaries: any) {
  if (params.colDef.field === 'paymentOutcome.codeValue') {
    params.colDef.field = 'paymentOutcome';
  }
  return dictionaries[params.colDef.field];
}

export function displayNameAssigner(dictionary: any, params: any, type?: string) {
  if (isObject(params.value)) {
    if (Object.keys(params.value).length === 0) {
      return '';
    }
    type === 'batch' ? (params.value = params.value.id) : (params.value = params.codeValue);
  }
  let cellValue = '';
  dictionary.forEach((paymentStatus) => {
    if (type === 'batch') {
      if (paymentStatus.id === params.value) {
        cellValue = paymentStatus.displayName;
        return;
      }
    }
    if (paymentStatus.codeValue === params.value) {
      cellValue = paymentStatus.displayName;
      return;
    }
  });
  return cellValue;
}

export function batchDeleteRenderer(params: any): string {
  return 'Remove from batch';
}
