<article class="tl-item">
  <div class="tl-body">
    <div class="tl-entry">
      <div class="tl-icon btn-icon-round btn-icon btn-icon-thin btn-notes">
        <i class="material-icons">message</i>
      </div>

      <div class="tl-content" [ngClass]="{ 'tl-pinned': item.isPinned }" data-test="crmNoteItem">
        <!-- TOP NOTE INFO -->
        <ul class="tl-data-summary">
          <li class="pill" *ngIf="item.data.user" data-test="crmNoteItemAuthor">
            <i class="material-icons">person</i> {{ item.data.user.email }}
          </li>
          <li data-test="crmNoteItemCreationTime">
            <i class="material-icons">event_note</i>{{ item.createdAt | amAdd: correctTime(item.createdAt):'minutes' | date: 'short' }}
          </li>
          <li data-test="crmNoteItemUpdateTime">
            <i class="material-icons">update</i>{{ item.updatedAt | amAdd: correctTime(item.updatedAt):'minutes' | date: 'short' }}
          </li>
        </ul>

        <!-- QUICK ACTIONS -->
        <ul class="tl-action-buttons pull-right" data-test="crmNoteQuickActions">
          <li><a *ngIf="hasAccessTo" app-confirm-delete (onConfirm)="deleteNote()" data-test="crmNoteItemQADelete"></a></li>
          <li>
            <a *ngIf="hasAccessTo" (click)="openEditNoteDialog()" data-test="crmNoteItemQAEdit"><i class="material-icons">edit</i></a>
          </li>
          <li>
            <a (click)="pinNote()" [ngClass]="{ active: item.isPinned }" data-test="crmNoteItemQAStar"
              ><i class="material-icons">star</i></a
            >
          </li>
        </ul>

        <!-- NOTE BODY -->
        <div class="tl-content-body" [ngClass]="{ collapsed: collapsed, expandable: expandable }">
          <div [innerHTML]="item.data.body | safe" #noteBody data-test="crmNoteItemContent"></div>
          <a class="btn btn-expand" [hidden]="!expandable" (click)="toggleCollapse()" data-test="crmNoteItemExpand">
            <i class="material-icons">more_horiz</i>
          </a>
        </div>

        <!-- COMMENTS SECTION -->
        <hr />
        <app-comment-list
          [latestComments]="item.latestComments"
          [commentsCount]="item.commentsCount"
          [targetUuid]="item.uuid"
          targetType="note"
          data-test="crmCommentList"
          [createdAt]="item.createdAt"
          [updatedAt]="item.updatedAt"
        >
        </app-comment-list>
      </div>
    </div>
  </div>
</article>
