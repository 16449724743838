import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-delinquency',
  templateUrl: './account-delinquency.component.html',
  styleUrls: ['./account-delinquency.component.less'],
})
export class AccountDelinquencyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
