import { Component, Input } from '@angular/core';

import { ISimpleTable } from './simple-table.interface';

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
})
export class SimpleTableComponent {
  @Input() data: ISimpleTable;
}
