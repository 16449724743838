import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { ErrorVisualizationService } from '../../shared/services/error-visualization/error-visualization.service';
import { PaymentBatchDownloaderAbstractService } from './payment-batch-downloader-abstract.service';

@Injectable()
export class PaymentBatchDownloaderService extends PaymentBatchDownloaderAbstractService {
  private readonly apiUrl: string;

  constructor(private httpClient: HttpClient, private errorVisualizationService: ErrorVisualizationService, appConfig: AppConfigService) {
    super();

    this.apiUrl = appConfig.env.internalApiUrl;
  }

  download(batchId: string): void {
    const endpoint = this.prepareEndpoint(batchId);

    this.httpClient
      .get(endpoint, {
        responseType: 'text' as const,
        observe: 'response',
        headers: new HttpHeaders(),
      })
      .pipe(
        catchError((error) => {
          this.errorVisualizationService.showError({
            name: 'Payment Batches Download Error',
            errorObject: error,
          });
          return Observable.throw(error);
        }),
      )
      .subscribe((data) => {
        if (!data) {
          this.errorVisualizationService.showError({
            name: 'No data!',
            errorObject: undefined,
          });
          return;
        }
        if (data && data.headers) {
          const fileType = data.headers.get('filetype').toLowerCase();
          const contentType = !!fileType ? fileType : data.headers.get('content-type');
          const filename = data.headers.get('filename');

          const blob = new Blob([data.body], { type: 'text/txt' });
          FileSaver.saveAs(blob, !!filename ? filename : this.prepareFilename(contentType, batchId));
        }
      });
  }

  private prepareFilename(contentType: string, batchId: string): string {
    return `${batchId}.${this.prepareExtension(contentType)}`;
  }

  private prepareExtension(contentType: string): string {
    return contentType.includes('xml') ? 'xml' : 'txt';
  }

  private prepareEndpoint(batchId: string): string {
    return this.addTimestampToUrl(`${this.apiUrl}paymentBatches/${batchId}/paymentFile?withAch=true`);
  }

  private addTimestampToUrl(url: string): string {
    const date = new Date();
    const timestamp = date.getTime();
    return `${url}&timestamp=${timestamp}`;
  }
}
