import { GlobalSetting } from '../models/global-setting.model';

export interface GlobalSettingsState {
  loading: boolean;
  configs: GlobalSetting[];
}

export const GLOBAL_SETTINGS_STATE: GlobalSettingsState = {
  loading: false,
  configs: null,
};
