import { isArray, isNull, isNullOrUndefined, isUndefined } from 'util';

import { CrmItem } from '../models/crm-item.type';
import { CrmItemStatus } from '../shared/crm.enums';
import * as fromCrmActions from './crm.actions';

export interface CrmItemCollection {
  [uuid: string]: CrmItem;
}

export interface CrmStateModel {
  crmItems: CrmItemCollection;
  all?: string[];
  pinnedNotes?: string[];
  notes?: string[];
  emails?: string[];
  batchEmails?: string[];
  tasks?: string[];
  events?: string[];
  files?: string[];
}

export interface CrmState {
  targetUuid: string;
  crmCurrentState: CrmStateModel;
  crmStatesList: { [targetUuid: string]: CrmStateModel };
  crmPendingItems: { [key: string]: CrmItemStatus };
}

function initialState() {
  return {
    targetUuid: null,
    crmCurrentState: {
      crmItems: null,
      all: [],
      notes: [],
      emails: [],
      batchEmails: [],
      tasks: [],
      events: [],
      files: [],
      pinnedNotes: [],
    },
    crmStatesList: {},
    crmPendingItems: {},
  };
}

export function crmReducers(state = initialState(), action: fromCrmActions.Actions) {
  const updatedCrmStates = { ...state.crmStatesList };
  const crmCurrentState: CrmStateModel = state.crmCurrentState;
  const updatedPendingItems = { ...state.crmPendingItems };
  let updatedCrmModel;

  switch (action.type) {
    case fromCrmActions.SET_TARGET_UUID:
      const targetUuid = action.payload.targetUuid;
      const stateForTarget = updatedCrmStates.hasOwnProperty(targetUuid)
        ? updatedCrmStates[targetUuid]
        : { ...initialState().crmCurrentState };

      updatedCrmStates[targetUuid] = stateForTarget;

      return {
        ...state,
        crmCurrentState: stateForTarget,
        crmStatesList: updatedCrmStates,
        targetUuid,
      };

    case fromCrmActions.UPDATE_CRM_MODEL:
      const updatedStreams = {};
      Object.keys(action.payload).map((key) => {
        if (!isUndefined(action.alterKeys) && action.alterKeys.indexOf(key) > -1) {
          updatedStreams[key] = action.payload[key];
        } else {
          if (isArray(action.payload[key])) {
            const combinedUuids = [...state.crmCurrentState[key], ...action.payload[key]];
            // Remove duplicates from uuids arrays
            updatedStreams[key] = combinedUuids.filter(function (item, pos) {
              return combinedUuids.indexOf(item) === pos;
            });
          } else {
            updatedStreams[key] = { ...state.crmCurrentState[key], ...action.payload[key] };
          }
        }
      });

      updatedCrmModel = {
        ...crmCurrentState,
        ...updatedStreams,
      };

      updatedCrmStates[action.targetUuid] = updatedCrmModel;
      return {
        ...state,
        targetUuid: action.targetUuid,
        crmCurrentState: updatedCrmModel,
        crmStatesList: updatedCrmStates,
      };

    case fromCrmActions.ADD_CRM_MODEL:
      const newItemUuid = action.payload.newItem.uuid;
      const streamsToUpdate = action.payload.updateStreams;

      const stateToUpdate =
        action.targetUuid === state.targetUuid
          ? crmCurrentState
          : !isUndefined(updatedCrmStates[action.targetUuid])
          ? updatedCrmStates[action.targetUuid]
          : initialState().crmCurrentState;

      if (isNull(stateToUpdate.crmItems)) {
        stateToUpdate.crmItems = {};
      }
      stateToUpdate.crmItems[newItemUuid] = action.payload.newItem;

      if (!isUndefined(streamsToUpdate)) {
        streamsToUpdate.map((stream) => {
          stateToUpdate[stream].unshift(newItemUuid);
        });
      }

      updatedCrmModel = {
        ...crmCurrentState,
      };

      updatedCrmStates[action.targetUuid] = updatedCrmModel;

      return {
        ...state,
        targetUuid: state.targetUuid,
        crmCurrentState: updatedCrmModel,
        crmStatesList: updatedCrmStates,
      };

    case fromCrmActions.DELETE_CRM_MODEL:
      const itemsToDelete = action.payload.itemsToDelete;

      const itemsUpdated = removeItemsFromCrmCollection(itemsToDelete, state.crmCurrentState.crmItems);
      updatedCrmModel = {
        ...crmCurrentState,
        crmItems: itemsUpdated,
        all: removeUuidsFromStream(itemsToDelete, state.crmCurrentState.all),
        notes: removeUuidsFromStream(itemsToDelete, state.crmCurrentState.notes),
        tasks: removeUuidsFromStream(itemsToDelete, state.crmCurrentState.tasks),
        events: removeUuidsFromStream(itemsToDelete, state.crmCurrentState.events),
        emails: removeUuidsFromStream(itemsToDelete, state.crmCurrentState.emails),
        pinnedNotes: removeUuidsFromStream(itemsToDelete, state.crmCurrentState.pinnedNotes),
      };
      updatedCrmStates[action.targetUuid] = updatedCrmModel;

      return {
        ...state,
        targetUuid: action.targetUuid,
        crmCurrentState: updatedCrmModel,
        crmStatesList: updatedCrmStates,
      };

    case fromCrmActions.EDIT_CRM_NOTE:
    case fromCrmActions.PIN_CRM_NOTE:
      updatedPendingItems[action.payload.uuid] = CrmItemStatus.PENDING;
      return {
        ...state,
        crmPendingItems: updatedPendingItems,
      };

    case fromCrmActions.CRM_ITEM_RESPONSE_SUCCESS:
      updatedPendingItems[action.payload.actionId] = CrmItemStatus.SUCCESS;
      return {
        ...state,
        crmPendingItems: updatedPendingItems,
      };

    case fromCrmActions.CRM_ITEM_RESPONSE_ERROR:
      updatedPendingItems[action.payload.actionId] = CrmItemStatus.ERROR;
      return {
        ...state,
        crmPendingItems: updatedPendingItems,
      };

    case fromCrmActions.CRM_ITEM_CLEAR_STATUS:
      if (updatedPendingItems.hasOwnProperty(action.payload.actionId)) {
        delete updatedPendingItems[action.payload.actionId];
      }
      return {
        ...state,
        crmPendingItems: updatedPendingItems,
      };

    case fromCrmActions.CRM_CLEAR_CURRENT_STATE:
      return {
        ...state,
        crmCurrentState: initialState().crmCurrentState,
      };

    default:
      return state;
  }
}

function removeItemsFromCrmCollection(itemsToDelete: string[], crmItems: CrmItemCollection): CrmItemCollection {
  const updatedCrmItems = { ...crmItems };

  if (!isNullOrUndefined(itemsToDelete) && itemsToDelete.length) {
    itemsToDelete.map((item) => {
      if (item in crmItems) {
        delete updatedCrmItems[item];
      }
    });
  }

  return updatedCrmItems;
}

function removeUuidsFromStream(itemsToDelete: string[], streamItems: string[]): string[] {
  if (!isNullOrUndefined(itemsToDelete) && !isNullOrUndefined(streamItems)) {
    return streamItems.filter((item) => itemsToDelete.indexOf(item) < 0);
  }
  return null;
}
