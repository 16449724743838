import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { MarketplaceListModel } from '../../../../marketplaces/model/marketplace-list-model';
import { MarketplacesAbstractService } from '../../../../marketplaces/service/marketplaces-abstract.service';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { ThemeOnboardingModel } from '../../../../shared/onboarding/onboarding.model';
import { AppState } from '../../../../store/app.reducers';

@Component({
  selector: 'app-onboarding-marketplaces',
  templateUrl: './onboarding-marketplaces.component.html',
  styleUrls: ['./onboarding-marketplaces.component.scss'],
})
export class OnboardingMarketplacesComponent extends SubscriberComponent implements OnInit, OnChanges {
  @Input() templateData: ThemeOnboardingModel;

  @Output() formReady = new EventEmitter<FormGroup>();

  public marketplacesForm: FormGroup;
  public selectedMarketplaces: any[] = [];
  public filteredMarketplaces: any[] = [];
  public initMarketplaces: Observable<MarketplaceListModel[]>;

  private id: string;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private itemsRepo: MarketplacesAbstractService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    super();
    this.createForm();
    this.initMarketplaces = itemsRepo.getItems();
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('onboardingTemplateKey');
    this.subscriptions.push(
      this.initMarketplaces.subscribe((initMarketplaces) => {
        this.filteredMarketplaces = initMarketplaces.sort((a, b): number => {
          return a.marketplaceName < b.marketplaceName ? -1 : a.marketplaceName > b.marketplaceName ? 1 : 0;
        });
      }),
    );
    this.formReady.emit(this.marketplacesForm);
    this.subscriptions.push(
      this.store.select('onboarding').subscribe((result) => {
        if (result.resetTimestamp.length > 1) {
          if (!this.isInEditMode()) {
            this.marketplacesForm.reset({
              marketplacesOption: '',
              marketplaces: '',
              selectedMarketplace: '',
            });
            this.selectedMarketplaces = [];
          }
        }
      }),
    );
  }

  private createForm(): void {
    this.marketplacesForm = this.fb.group({
      marketplacesOption: ['', [Validators.required]],
      marketplaces: [''],
      selectedMarketplace: [''],
    });
    this.marketplacesForm.setValidators([this.emptyListValidator]);
  }

  private emptyListValidator(group: FormGroup): any {
    if (group.get('marketplacesOption').value === 'show_all') {
      return null;
    }
    if (group.get('marketplaces').value.length < 1) {
      return { emptyMarketplaceList: true };
    }
    return null;
  }

  public addMarketplace(): any {
    const selected = this.marketplacesForm.get('selectedMarketplace').value;
    if (!selected) {
      return;
    }
    if (this.selectedMarketplaces.some((elem) => elem.marketplaceKey === selected.marketplaceKey)) {
      return this.toastr.error('Marketplace already added');
    }
    this.selectedMarketplaces.push(selected);
    this.marketplacesForm.get('selectedMarketplace').reset();
    this.marketplacesForm.get('marketplaces').setValue(this.selectedMarketplaces);
  }

  public isVisible(): boolean {
    return (
      this.marketplacesForm.get('marketplacesOption').value === 'auto_create' ||
      this.marketplacesForm.get('marketplacesOption').value === 'show_chosen'
    );
  }

  private patchForm(template: ThemeOnboardingModel): void {
    this.marketplacesForm.patchValue(
      {
        marketplacesOption: template.mpCreationPolicy,
        marketplaces: template.mpTemplates,
      },
      { onlySelf: true, emitEvent: true },
    );
    this.marketplacesForm.get('marketplacesOption').disable();
  }

  ngOnChanges(changes) {
    if (changes.templateData && !changes.templateData.firstChange) {
      this.patchForm(this.templateData);
      this.selectedMarketplaces = this.templateData.mpTemplates;
    }
  }

  private isInEditMode(): boolean {
    return !!this.id;
  }
}
