import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ConfigModel } from '../../../shared/configs/config.model';
import { ConfigEmailTypesNames, ConfigsService, ConfigTypes } from '../../../shared/configs/service/configs.service';
import { TOOLS_THEME } from '../../../shared/editor/editor.enum';
import {
  ALLOW_TEMPLATES_VARIABLES_MISCALENOUS,
  ALLOW_TEMPLATES_VARIABLES_SUPPLIER,
  ALLOW_TEMPLATES_VARIABLES_USER,
  EMAIL_TYPE_FORM_FIELDS,
} from '../email-form-fields';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { EditorComponent } from './../../../shared/editor/editor.component';
import { noSpaceValidator } from './../../../shared/forms/validators/no-space.validator';

@Component({
  selector: 'app-email-add-new-config-dialog',
  templateUrl: './email-add-new-config-dialog.component.html',
  styleUrls: ['./email-add-new-config-dialog.component.scss'],
})
export class EmailAddNewConfigDialogComponent extends SubscriberComponent implements OnInit {
  readonly TOOLS_THEME = TOOLS_THEME;
  form: FormGroup;
  optionsMenu: any[] = [];
  selectedControl = new FormControl('');
  selectedValue;
  useTemplate: boolean;

  @ViewChild(EditorComponent) appEditor: EditorComponent;

  constructor(
    public dialogRef: MatDialogRef<EmailAddNewConfigDialogComponent>,
    private formBuilder: FormBuilder,
    private configsService: ConfigsService,
    @Inject(MAT_DIALOG_DATA) public data: ConfigModel,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.optionsMenu = ConfigEmailTypesNames.filter((item) => item.can_add_new);

    this.form = this.formBuilder.group({
      key: ['', [Validators.required, noSpaceValidator]],
      configType: [{ value: '', disabled: true }],
      templateName: [''],
      description: [''],
      subject: [''],
      fromAddress: ['noreply@payability.com'],
      toAddress: [''],
      htmlTemplate: [''],
      textTemplate: [''],
    });

    this.subscriptions.push(
      this.selectedControl.valueChanges.subscribe((value) => {
        this.selectedValue = value;
        this.form.patchValue({
          configType: value.key,
        });
        this.useTemplate = true;
      }),
    );
  }

  showField(fieldName: string): boolean {
    const emailTypeFormField = EMAIL_TYPE_FORM_FIELDS.find((element) => element.type === this.selectedValue.key);
    return emailTypeFormField.fields.includes(fieldName);
  }

  toggleUseTemplate() {
    this.useTemplate = !this.useTemplate;
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  save() {
    const request = this.prepareRequest();
    this.subscriptions.push(
      this.configsService.add(request).subscribe(
        (response) => {
          this.closeDialog(response);
        },
        (e) => {
          for (const err of e.error.meta.errors) {
            this.toastrService.error(err.message);
          }
        },
      ),
    );
  }

  prepareRequest() {
    const config_key = this.form.controls['key'].value;
    const config_type = this.form.controls['configType'].value;
    const config_value = {};

    if (this.form.controls['configType'].value === ConfigTypes.EMAIL_TEMPLATE) {
      config_value['body'] = this.form.controls['htmlTemplate'].value;
      config_value['description'] = this.form.controls['description'].value || null;
      config_value['source'] = this.form.controls['fromAddress'].value;
      config_value['subject'] = this.form.controls['subject'].value;
      config_value['template_name'] = this.form.controls['templateName'].value;
      const request: ConfigModel = new ConfigModel(config_key, config_type, config_value);
      request.useTemplates = false;
      return request;
    }

    if (this.form.controls['configType'].value === ConfigTypes.EMAIL_BASE_TEMPL) {
      config_value['html'] = this.form.controls['htmlTemplate'].value;
      config_value['text'] = this.form.controls['textTemplate'].value || null;
      config_value['source'] = this.form.controls['fromAddress'].value;
      const request: ConfigModel = new ConfigModel(config_key, config_type, config_value);
      request.useTemplates = false;
      return request;
    }

    if (this.form.controls['configType'].value === ConfigTypes.EMAIL_BACK_CONFIG) {
      if (this.useTemplate) {
        config_value['html'] = this.form.controls['htmlTemplate'].value;
        config_value['text'] = this.form.controls['textTemplate'].value || null;
      }
      config_value['description'] = this.form.controls['description'].value || null;
      config_value['source'] = this.form.controls['fromAddress'].value;
      config_value['subject'] = this.form.controls['subject'].value;
      config_value['template_name'] = this.form.controls['templateName'].value;
      config_value['to'] = this.prepareToAddresses();
      const request: ConfigModel = new ConfigModel(config_key, config_type, config_value);
      request.useTemplates = this.useTemplate;
      return request;
    }

    if (this.form.controls['configType'].value === ConfigTypes.EMAIL_FRONT_BATCH_CONFIG) {
      if (this.useTemplate) {
        config_value['html'] = this.form.controls['htmlTemplate'].value;
        config_value['text'] = this.form.controls['textTemplate'].value || null;
      }
      config_value['templateVariables'] = { supplier: ALLOW_TEMPLATES_VARIABLES_SUPPLIER };
      config_value['description'] = this.form.controls['description'].value || null;
      config_value['source'] = this.form.controls['fromAddress'].value;
      config_value['subject'] = this.form.controls['subject'].value;
      config_value['template_name'] = this.form.controls['templateName'].value;
      const request: ConfigModel = new ConfigModel(config_key, config_type, config_value);
      request.useTemplates = this.useTemplate;
      return request;
    }

    if ([ConfigTypes.EMAIL_FRONT_CONFIG, ConfigTypes.EMAIL_FRONTEND_AUTOMATIC_CONFIG].includes(this.form.controls['configType'].value)) {
      if (this.useTemplate) {
        config_value['html'] = this.form.controls['htmlTemplate'].value;
        config_value['text'] = this.form.controls['textTemplate'].value || null;
      }
      config_value['description'] = this.form.controls['description'].value || null;
      config_value['source'] = this.form.controls['fromAddress'].value;
      config_value['subject'] = this.form.controls['subject'].value;
      config_value['template_name'] = this.form.controls['templateName'].value;
      config_value['to'] = this.prepareToAddresses();
      const request: ConfigModel = new ConfigModel(config_key, config_type, config_value);
      request.useTemplates = this.useTemplate;
      return request;
    }

    return null;
  }

  private prepareToAddresses() {
    const toAddress = this.form.controls['toAddress'].value;
    let array = [];
    if (toAddress) {
      if (Array.isArray(toAddress)) {
        array = toAddress;
      } else {
        array = toAddress
          .trim()
          .split(/[\s,]/)
          .map((key) => key.trim())
          .filter((key) => !!key);
      }
    }
    return array;
  }

  isBatch(): boolean {
    return this.selectedValue.key === ConfigTypes.EMAIL_FRONT_BATCH_CONFIG;
  }

  isTemplate(): boolean {
    return this.selectedValue.key === ConfigTypes.EMAIL_TEMPLATE;
  }

  getAllowTemplatesVariablesSupplier() {
    return ALLOW_TEMPLATES_VARIABLES_SUPPLIER;
  }

  getAllowTemplatesVariablesUser() {
    return ALLOW_TEMPLATES_VARIABLES_USER;
  }

  getAllowTemplatesVariablesMiscalenous() {
    return ALLOW_TEMPLATES_VARIABLES_MISCALENOUS;
  }

  pasteInEditor(value: string) {
    this.appEditor.appendRawValue(value);
  }
}
