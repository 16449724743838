<ng-template #loadingCard>
  <div class="card-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>

<div>
  <h4>Marketplace Access</h4>

  <app-smsnumbers [supplierKey]="supplierKey"></app-smsnumbers>

  <mat-card class="onboardingMarketplaceAccess__card">
    <mat-card-content>
      <app-marketplace-access-details
        *ngIf="!amazonMarketplaceDetailsLoading; else loadingCard"
        [title]="'Amazon Marketplace Access'"
      ></app-marketplace-access-details>
    </mat-card-content>
  </mat-card>
</div>
