import { SearchData } from './search-data';
import { SearchSingleResultModel } from './search-single-result-model';

export class SearchDataModel implements SearchData {
  results: SearchSingleResultModel[];
  totalCount: number;

  constructor(response) {
    this.totalCount = response.totalCount;
    this.results = [];
    for (let i = 0; i < response.results.length; i++) {
      this.results.push(new SearchSingleResultModel(response.results[i]));
    }
  }
}
