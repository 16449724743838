<ng-template #loadingCard>
  <div class="card-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>

<app-eva
  *ngIf="useEva && evaQueryParams"
  #appEva
  [url]="'onboarding/components/summary'"
  [queryParams]="evaQueryParams"
  class="eva-card onboardingDetails__eva"
></app-eva>

<mat-card *ngIf="!useEva" class="onboardingDetails__card">
  <mat-card-content class="onboardingDetails__card__content" *ngIf="onboardingDetails; else loadingCard">
    <div>
      <h5>Customer Onboarding</h5>
      <button
        *ngIf="supplierDetailsModel; else loadingCard"
        mat-raised-button
        color="accent"
        [hidden]="onboardingTaskProgress.isRunning"
        (click)="runOnboardingWorkflowEmit()"
      >
        Load Earnings
      </button>
      <div *ngIf="form && supplierDetailsModel" [formGroup]="form" class="onboardingDetails__card__content__div__checkbox">
        <mat-checkbox formControlName="isComplete" color="primary" [disabled]="true">Onboarding Complete</mat-checkbox>
      </div>
      <div *ngFor="let progress of onboardingTaskProgres" class="onboardingDetails__card__content__div__progress">
        <div [hidden]="!progress.isRunning">
          <div class="task-name">
            {{ progress.taskName }}
          </div>
          <div class="progress-bar">
            <mat-progress-bar
              class="example-margin"
              [color]="'primary'"
              [mode]="'determinate'"
              [value]="progress.fetchProgressValue(progress.iterationStep, progress.maxIterationStep, false)"
            >
            </mat-progress-bar>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h5>Onboarding Details</h5>
      <div class="onboardingDetails__card__content__div--intoFour">
        <div class="fake-field">
          <div class="label">Date of Registration</div>
          <div>
            {{ onboardingDetails.registration_date ? (onboardingDetails.registration_date | date: 'MM/dd/yyyy') : '-' }}
          </div>
        </div>
        <div class="fake-field">
          <div *ngIf="onboardingDetails.onboard_templates" class="label">
            <mat-form-field class="full-width">
              <mat-select [(ngModel)]="onbTemplateValue" (ngModelChange)="setOnboardingTemplate()" placeholder="Onboarding Template">
                <mat-option *ngFor="let template of onboardingTemplatesList" [value]="template">
                  {{ template }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="fake-field">
          <div class="label">URL</div>
          <div class="onboardingDetails__card__content__div--intoFour__url--hideLong">
            {{ onboardingDetails.url ? onboardingDetails.url : '-' }}
          </div>
        </div>

        <div class="fake-field">
          <div *ngIf="onboardingSchemasList.length > 1; else onboardingSchemaInfo" class="label">
            <mat-form-field class="full-width">
              <mat-select [(ngModel)]="onbSchemaValue" (ngModelChange)="setOnboardingSchema()" placeholder="{{ onboardingSchemaCopy }}">
                <mat-option *ngFor="let schema of onboardingSchemasList" [value]="schema">
                  {{ schema }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ng-template #onboardingSchemaInfo>
          <label for="onbSchemaValue" class="schemaLabel">{{ onboardingSchemaCopy }}</label>
          <div id="onbSchemaValue">{{ onbSchemaValue }}</div>
        </ng-template>
        <button
          *ngIf="supplierDetailsModel?.onboardingStatus"
          mat-raised-button
          color="accent"
          [disabled]="supplierStatus === ACTIVE_STATUS"
          (click)="onBackToOnboarding()"
        >
          Move back to onboarding
        </button>
      </div>
    </div>

    <div>
      <h5>Marketing Details</h5>
      <div class="onboardingDetails__card__content__div--intoFour">
        <div class="fake-field">
          <div class="label">Marketing Source</div>
          <div>{{ onboardingDetails.marketing_source ? onboardingDetails.marketing_source : '-' }}</div>
        </div>
        <div class="fake-field">
          <div class="label">Marketing Campaign</div>
          <div>{{ onboardingDetails.marketing_campaign ? onboardingDetails.marketing_campaign : '-' }}</div>
        </div>
        <div class="fake-field">
          <div class="label">Marketing Medium</div>
          <div>{{ onboardingDetails.marketing_medium ? onboardingDetails.marketing_medium : '-' }}</div>
        </div>
        <div class="fake-field">
          <div class="label">Marketing Content</div>
          <div>{{ onboardingDetails.marketing_content ? onboardingDetails.marketing_content : '-' }}</div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
