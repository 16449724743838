import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-supplier-home-address',
  templateUrl: './supplier-home-address.component.html',
  styleUrls: ['./supplier-home-address.component.scss'],
})
export class SupplierHomeAddressComponent {
  @Input() homeAddressForm: FormGroup;
  @Input() isDisabled: boolean;
  @Input() addressCountries: any;
  @Input() addressStates: any;
  @Input() customTitle: string;

  @Output() onSaveHomeAddressForm = new EventEmitter();

  saveHomeAddressForm() {
    this.onSaveHomeAddressForm.emit(this.homeAddressForm);
  }
}
