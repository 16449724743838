<h1 mat-dialog-title>Onboarding Workflow</h1>
<div *ngIf="form" [formGroup]="form">
  <mat-checkbox color="primary" formControlName="sendEmail">Send Email</mat-checkbox>
  <div>
    <mat-checkbox color="primary" formControlName="skipScraping">Skip Scraping</mat-checkbox>
  </div>

  <h1 mat-dialog-title>Marketplaces</h1>
  <div formArrayName="marketPlaces" class="row">
    <span *ngFor="let mp of form.controls.marketPlaces['controls']; let i = index" [formGroupName]="i" class="col-md-12">
      <mat-checkbox [disabled]="mp.value.data.isNotApproved" color="primary" formControlName="isChecked">{{
        mp.value.data.marketplaceName
      }}</mat-checkbox>
    </span>
  </div>
</div>
<div mat-dialog-actions class="pull-right">
  <button mat-raised-button (click)="close()" tabindex="2" color="primary" class="btn btn-primary">Proceed</button>
  <button mat-raised-button [mat-dialog-close]="false" tabindex="-1">Cancel</button>
</div>
