<div class="card-header">
  <div class="navigation">
    <a [routerLink]="[returnLink]" title="Go to advances list" class="close-button">
      <i class="material-icons">arrow_back</i>
    </a>
  </div>
  <div *ngIf="!loading && isNewOffer; else notNewOffer" class="title">Request Offer Details</div>
  <ng-template #notNewOffer>
    <div class="title">Pending Offer Request Details</div>
  </ng-template>
</div>
<div *ngIf="loading; else loaded">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-template #loaded>
  <div class="wrapper">
    <app-machine-learning-summary *ngIf="!isNewOffer"></app-machine-learning-summary>

    <mat-card class="offer-inputs-container">
      <mat-card-subtitle>
        <span *ngIf="isNewOffer; else notNewOfferTitle">Request Advance Line</span>

        <ng-template #notNewOfferTitle>
          <span>Manual Advance Line Inputs</span>
        </ng-template>
      </mat-card-subtitle>

      <mat-card-content class="card-content">
        <app-manual-advance-offer-inputs *ngIf="supplierKey" [supplierKey]="supplierKey"></app-manual-advance-offer-inputs>
      </mat-card-content>
    </mat-card>

    <app-advance-offer-summary *ngIf="!isNewOffer" [advanceOfferRequest]="advanceOfferRequest"></app-advance-offer-summary>
  </div>
</ng-template>
