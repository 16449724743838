import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridOptions } from 'ag-grid-community';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { DataTableAgGridComponent } from '../../../../shared/data-table-ag-grid/data-table-ag-grid.component';
import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-report-details',
  templateUrl: './supplier-report-details.component.html',
  styleUrls: ['./supplier-report-details.component.scss'],
})
export class SupplierReportDetailsComponent extends SubscriberComponent implements OnInit {
  @ViewChild(DataTableAgGridComponent)
  readonly table: DataTableAgGridComponent;

  reportName: string;

  supplierKey: string;
  customGridOptions: GridOptions;

  constructor(private readonly store: Store<AppState>, private readonly location: Location) {
    super();
  }

  ngOnInit() {
    this.customGridOptions = {
      sortable: true,
      cacheBlockSize: 50,
      paginationPageSize: 50,
    } as GridOptions;

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey), distinctUntilChanged()))
        .subscribe((data) => {
          const oldSupplierKey = this.supplierKey;
          this.supplierKey = data.supplierKey;

          if (this.table && this.table.gridApi && this.supplierKey !== oldSupplierKey) {
            this.table.supplierKey = this.supplierKey;
            this.table.refreshGrid(this.table.lastSearchParams);
          }
        }),
    );
  }

  goBack(): void {
    this.location.back();
  }
}
