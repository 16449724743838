import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { filter, finalize } from 'rxjs/operators';

import { IEnvironment } from '../../../../../../environments/environment.interface';
import { AppConfigService } from '../../../../../config';
import { AppsDocumentService } from '../../../../../shared/apps-document/apps-document.service';
import { B2bDocumentService } from '../../../../../shared/b2b-document/b2b-document.service';
import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { trackByIndex } from '../../../../../shared/utils/functions/track-by-index.util';
import { AppState } from '../../../../../store/app.reducers';
import { SupplierService } from '../../../../services/supplier.service';
import { B2bWorkflowService } from '../../../../services/workflow/b2b-workflow.service';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { BankAccountsDialogComponent } from '../bank-accounts-dialog/bank-accounts-dialog.component';
import { IPlaidResponseDto } from './iplaid-response-dto';
import { SupplierPlaidService } from './supplier-plaid.service';

@Component({
  selector: 'app-supplier-payment-plaid-information',
  templateUrl: './supplier-payment-plaid-information.component.html',
  styleUrls: ['./supplier-payment-plaid-information.component.scss'],
})
export class SupplierPaymentPlaidInformationComponent extends SubscriberComponent implements OnInit {
  private gciId: IEnvironment['workflowGciId'];
  private supplierKey: string;
  public loaded: boolean;
  public plaidData: IPlaidResponseDto[];
  readonly trackBy = trackByIndex;

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly b2bDocumentService: B2bDocumentService,
    private readonly b2bWorkflowService: B2bWorkflowService,
    private readonly route: ActivatedRoute,
    private readonly supplierService: SupplierService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly supplierPlaidService: SupplierPlaidService,
    private readonly appsDocumentService: AppsDocumentService,
  ) {
    super();
    this.gciId = appConfig.env.workflowGciId;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;
          this.getPlaidData();
        }),
    );
  }

  public getPlaidData(): void {
    this.loaded = false;
    this.supplierService.getPlaidBalanceInformation(this.supplierKey).subscribe(
      (data: IPlaidResponseDto) => {
        this.plaidData = [];
        Object.keys(data).map((e) => {
          const mappedObj: IPlaidResponseDto = {
            accounts: data[e].accounts,
            id: e,
            itemLoginRequired: data[e]['item_login_required'],
            getPlaidReportUuidLoading: false,
            runPlaidApiLoading: false,
            refreshBalanceLoading: false,
          };
          this.plaidData.push(mappedObj);
        });
        this.loaded = true;
      },
      () => {
        this.toastr.error('Something went wrong');
      },
    );
  }

  public runPlaidApi(element: IPlaidResponseDto): void {
    element.runPlaidApiLoading = true;
    this.supplierService
      .runPlaidApi(this.supplierKey, element.id)
      .pipe(finalize(() => (element.runPlaidApiLoading = false)))
      .subscribe(
        () => {
          this.toastr.success('You have requested a data update, this may take a few minutes');
        },
        () => {
          this.toastr.error('Something went wrong');
        },
      );
  }

  public downloadPlaidReport(element: IPlaidResponseDto) {
    const plaidData = element.accounts[0];
    this.getPlaidReportUuid(plaidData.lastRequested, plaidData.itemId, element);
  }

  public getPlaidReportUuid(lastRequestDate: string, itemId, element: IPlaidResponseDto): void {
    element.getPlaidReportUuidLoading = true;
    this.supplierService
      .getPlaidReportUuid(this.supplierKey, itemId)
      .pipe(finalize(() => (element.getPlaidReportUuidLoading = false)))
      .subscribe(
        (uuid: string) => {
          this.getFileDownloadUrl(uuid, lastRequestDate);
        },
        () => {
          this.toastr.error('Cannot download Plaid Asset Report');
        },
      );
  }

  private getFileDownloadUrl(uuid: string, element: string) {
    this.subscriptions.push(
      this.appsDocumentService.getFileDownloadUrl(this.gciId, uuid).subscribe((url) => {
        this.downloadFile(url, element);
      }),
    );
  }

  private downloadFile(url: string | Blob, element: string) {
    this.loaded = true;
    return this.b2bWorkflowService.downloadPdfFile(`${moment(element).format('MM/DD/YYYY')}_${this.supplierKey}`, url);
  }

  refreshBalanceClick(element: IPlaidResponseDto) {
    element.refreshBalanceLoading = true;
    this.subscriptions.push(
      this.supplierPlaidService
        .refreshBalance(this.supplierKey, element.id)
        .pipe(finalize(() => (element.refreshBalanceLoading = false)))
        .subscribe((data) => {
          this.getPlaidData();
        }),
    );
  }

  refreshAuthClick(element: IPlaidResponseDto) {
    this.dialog.open(BankAccountsDialogComponent, {
      width: '70vw',
      height: '70vh',
      data: {
        supplierKey: this.supplierKey,
        itemId: element.id,
      },
    });
  }
}
