import { Injectable } from '@angular/core';
import { of as observableOf } from 'rxjs';

@Injectable()
export class CliActionMockService {
  constructor() {}

  public getActionsList() {
    return observableOf([
      {
        command: 'test_messaging',
        name: 'test_messaging',
        implicit_params: [
          {
            name: 'limit',
            help: 'Suppliers limit',
            choices: [],
            required: false,
            type: 'int',
          },
          {
            name: 'email',
            help: 'Fake email',
            choices: [],
            required: true,
            type: null,
          },
          {
            name: 'single_supplier',
            help: 'Use single supplier',
            choices: [],
            required: false,
            type: 'bool',
          },
        ],
        b2bdocument_params: [],
        help: 'Create test batch email to suppliers with fake email',
      },
      {
        command: 'test_messaging_second',
        name: 'test_messaging_second',
        implicit_params: [
          {
            name: 'test',
            help: 'Suppliers limit',
            choices: ['choice_one', 'choice_two'],
            required: true,
            type: 'int',
          },
          {
            name: 'start_date',
            help: 'Fake email',
            choices: [],
            required: true,
            type: null,
          },
          {
            name: 'supplier_key',
            help: 'Use single supplier',
            choices: [],
            required: false,
            type: 'bool',
          },
        ],
        b2bdocument_params: [
          {
            name: 'upload_file_one',
            help: 'Suppliers limit',
            choices: [],
            required: false,
            type: 'int',
          },
          {
            name: 'upload_file_two',
            help: 'Fake email',
            choices: [],
            required: true,
            type: null,
          },
        ],
        help: 'Create test batch email to suppliers with fake email',
      },
      {
        command: 'upload',
        name: 'upload',
        implicit_params: [],
        b2bdocument_params: [
          {
            name: 'upload_file_one',
            help: 'Suppliers limit',
            choices: [],
            required: true,
            type: 'int',
          },
        ],
        help: 'Create test batch email to suppliers with fake email',
      },
    ]);
  }
}
