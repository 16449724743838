import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

function emailOrEmpty(control: AbstractControl): ValidationErrors | null {
  return control.value === '' ? null : Validators.email(control);
}

@Injectable()
export class RulesUtilsService {
  constructor() {}

  static _items(o) {
    return Object.keys(o).map((key) => [key, o[key]]);
  }

  static addAllOrNothingValidator(group: FormGroup) {
    for (const [inputName, input] of RulesUtilsService._items(group.controls)) {
      const otherInputs = Object.keys(group.controls)
        .filter((name) => name !== inputName)
        .map((name) => group.controls[name]);
      input.setValidators((ctrl) => {
        if (otherInputs.some((c) => Validators.required(c) === null) && Validators.required(ctrl) !== null) {
          return { required: true };
        }
        if (ctrl.value && ctrl.value !== '') {
          otherInputs.filter((c) => c.value === '' || !c.value).forEach((c) => c.updateValueAndValidity());
        }
        return null;
      });
    }
  }

  contextModel() {
    const group = new FormGroup({
      propName: new FormControl(),
      selector: new FormControl(),
    });
    RulesUtilsService.addAllOrNothingValidator(group);
    return group;
  }

  dataModel() {
    const group = new FormGroup({
      propName: new FormControl(),
      value: new FormControl(),
    });
    RulesUtilsService.addAllOrNothingValidator(group);
    return group;
  }

  toModel() {
    return new FormControl('', emailOrEmpty);
  }

  tryAddNext(array: FormArray, i, model) {
    if (i !== array.length - 1) {
      return;
    }

    if (array.at(i).value !== '') {
      array.push(model);
    }
  }
}
