import { Component, forwardRef, OnChanges, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { InlineEditTextComponent } from '../inline-edit-text.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineAddTextComponent),
  multi: true,
};

@Component({
  selector: 'app-inline-add-text',
  templateUrl: './inline-add-text.component.html',
  styleUrls: ['../../base/inline-edit-base.component.scss'],
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
})
export class InlineAddTextComponent extends InlineEditTextComponent implements OnInit, OnChanges {
  public initialValue: string;

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges() {
    this.initialValue = this._inputValue;
  }

  get maskingValue() {
    if (!this._inputValue) {
      return 'Private Key is not on file';
    }
    return 'Private Key is already on file';
  }

  edit(value) {
    this.onTouched();

    if (this.disabled) {
      return;
    }
    this.editing = true;
  }
}
