import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { EventSourcePolyfill } from 'ng-event-source';
import { Observable } from 'rxjs';

import { AppState } from './../store/app.reducers';
import Logger from './logger';

@Injectable()
export class SseService {
  private token: string;

  constructor(private zone: NgZone, private store: Store<AppState>) {
    this.store.select('auth').subscribe((authState) => {
      if (authState && authState.user) {
        this.token = authState.user.jwtToken;
      }
    });
  }

  getStream(sseEndpointUrl: string): Observable<any> {
    return new Observable<any>((observer) => {
      const eventSource = new EventSourcePolyfill(sseEndpointUrl, { headers: { 'X-Session-Token': this.token } });

      eventSource.onerror = (error) => observer.error(error);
      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          this.zone.run(() => observer.next(data));
        } catch (error) {
          Logger.log(error);
          observer.error(error);
        }
      };

      return () => {
        Logger.log('SseService - eventSource.close()');
        eventSource.close();
      };
    });
  }
}
