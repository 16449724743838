import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-onboarding-marketplace-access',
  templateUrl: './supplier-onboarding-marketplace-access.component.html',
  styleUrls: ['./supplier-onboarding-marketplace-access.component.scss'],
})
export class SupplierOnboardingMarketplaceAccessComponent implements OnInit {
  @Input() amazonMarketplaceDetailsLoading: boolean;
  @Input() supplierKey: string;

  constructor() {}

  ngOnInit() {}
}
