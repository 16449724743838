import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CliActionService } from '../cli-action-service/cli-action.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-cli-action-dialog',
  templateUrl: './cli-action-dialog.component.html',
})
export class CliActionDialogComponent extends SubscriberComponent implements OnInit {
  public title: string;
  public command: string;
  public actionFields: any[];
  public uploadFields: any[];
  public readyToSend: boolean;
  public showSpinner: boolean;

  private uploadUuids: {};
  private inputValues: {};

  constructor(
    public dialogRef: MatDialogRef<CliActionDialogComponent>,
    private cliActionService: CliActionService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    super();
    this.title = data.name;
    this.command = data.command;
    this.actionFields = data.implicit_params;
    this.uploadFields = data.b2bdocument_params;
  }

  ngOnInit() {
    this.readyToSend = this.isReadyToSend();
    this.uploadUuids = {};
    this.inputValues = {};
  }

  submit(): void {
    if (!this.readyToSend) {
      return;
    }
    const data = {
      command: this.command,
      implicit_params: this.inputValues,
      b2bdocument_params: this.uploadUuids,
    };
    this.subscriptions.push(this.cliActionService.runAction(data).subscribe(() => this.dialogRef.close()));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  isReadyToSend(): boolean {
    return false;
  }

  fileUuid(event: any) {
    this.showSpinner = false;
    this.uploadUuids = Object.assign(this.uploadUuids, event);
    this.readyToSend = this.isAttached() && this.isFullField();
  }

  isSpinner(e: boolean) {
    this.showSpinner = e;
  }

  public actionFieldChange(e: any) {
    this.inputValues = Object.assign(this.inputValues, e);
    this.readyToSend = this.isFullField() && this.isAttached();
  }

  private isFullField() {
    let isFilled = true;
    this.actionFields.forEach((action) => {
      if (action.required) {
        if (!this.inputValues.hasOwnProperty(action.name)) {
          isFilled = false;
        } else if (this.inputValues[action.name].length < 1) {
          isFilled = false;
        }
      }
    });
    return isFilled;
  }

  private isAttached() {
    let isFilled = true;
    this.uploadFields.forEach((action) => {
      if (action.required) {
        if (!this.uploadUuids.hasOwnProperty(action.name)) {
          isFilled = false;
        }
      }
    });
    return isFilled;
  }
}
