import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from '../../../../config';

@Injectable()
export class SupplierFeesService {
  private apiUrl: string;
  private internalApiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.internalApiUrl = appConfig.env.internalApiUrl;
    this.apiUrl = `${this.internalApiUrl}suppliers`;
  }

  getOverallFundingParticulars(supplierKey: string) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars`;

    return this.httpClient.get(endpointUrl);
  }

  setOverallFundingParticulars(supplierKey: string, data: any) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars`;

    return this.httpClient.put(endpointUrl, data);
  }

  getSupplierFeeData(supplierKey: string, marketplaceKey: string) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${marketplaceKey}`;

    return this.httpClient.get(endpointUrl);
  }

  setSupplierFeeData(supplierKey: string, marketplaceKey: string, feeScheduleKey: string) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${marketplaceKey}`;

    return this.httpClient.put(endpointUrl, { currentFeeScheduleKey: feeScheduleKey });
  }

  setSupplierEaringsType(supplierKey: string, isEarningsTypeNet: boolean) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}`;

    return this.httpClient.put(endpointUrl, { loadNetEarnings: isEarningsTypeNet });
  }
}
