import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NEVER, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { DIALOG_SIZE } from '../../../../shared/dialog.config';
import { MARKETPLACE } from '../../../../shared/enums/marketplace.enum';
import { IMatSelectOption } from '../../../../shared/interfaces/mat-select-option';
import { CrmStates } from '../../../../shared/layout/layout.enums';
import { LayoutState } from '../../../../shared/layout/store/layout.reducers';
import { UsersService } from '../../../../shared/users/service/users.service';
import { USER_PERMISSION } from '../../../../shared/users/user-permissions.enum';
import { AppState } from '../../../../store/app.reducers';
import { MarketplaceLoan } from '../../../model/marketplace-loan.model';
import { MarketplaceLoansService } from '../../../services/marketplace-loans.service';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-supplier-amazon-lending-loan',
  templateUrl: './supplier-amazon-lending-loan.component.html',
})
export class SupplierAmazonLendingLoanComponent extends SubscriberComponent implements OnInit {
  layoutState$: Store<LayoutState>;
  loanForm: FormGroup;
  loanKey?: string;
  availableMpSupKeys$: Observable<IMatSelectOption[]> = NEVER;
  canEditLoan$: Observable<boolean> = of(false);
  canDeleteLoan$: Observable<boolean> = of(false);
  panelOpenState = false;
  updatedTs = null;
  readonly crmPanelStates = CrmStates;
  readonly marketplaceLoanTypes: string[] = ['standard', 'custom'];
  readonly availablePaymentStatuses: string[] = ['On Time', 'Paid Off', 'Past Due'];

  get isEditMode(): boolean {
    return !!this.loanKey;
  }

  constructor(
    private readonly marketplacesLoanService: MarketplaceLoansService,
    private readonly toastrService: ToastrService,
    private readonly dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UsersService,
  ) {
    super();
    this.createForm();
    this.setAvailableMpSupKeys();
  }

  ngOnInit(): void {
    this.checkPermissions();
    this.layoutState$ = this.store.select('layout');

    this.subscriptions.push(
      this.route.params
        .pipe(
          tap((params) => (this.loanKey = params['loanKey'])),
          filter(() => this.isEditMode),
          switchMap(() => this.marketplacesLoanService.fetchMarketplaceLoan(this.loanKey)),
          tap((data) => {
            this.updatedTs = data.updatedTs as moment.Moment;
          }),
          map((data) => {
            data.nextPaymentDate = (data.nextPaymentDate as moment.Moment)?.toISOString();
            data.originationDate = (data.originationDate as moment.Moment)?.toISOString();
            data.maturityDate = (data.maturityDate as moment.Moment)?.toISOString();
            data.lastPaymentDate = (data.lastPaymentDate as moment.Moment)?.toISOString();
            data.nextSettlementDate = (data.nextSettlementDate as moment.Moment)?.toISOString();

            return data;
          }),
        )
        .subscribe((data) => this.patchFormWithLoanData(data)),
    );
  }

  createForm(): void {
    const loanFormControls = {
      loanExternalId: ['', Validators.required],
      mpSupKey: ['', Validators.required],
      paymentStatus: ['', Validators.required],
      nextPaymentDate: ['', Validators.required],
      nextPaymentAmount: ['', Validators.required],
      originationDate: ['', Validators.required],
      maturityDate: ['', Validators.required],
      loanAmount: ['', Validators.required],
      annualInterest: ['', Validators.required],
      lastPaymentDate: ['', Validators.required],
      lastPaymentAmount: ['', Validators.required],
      paymentPeriodDays: ['', Validators.required],
      daysLeftToPayment: ['', Validators.required],
      loanExtraReserveAmount: ['', Validators.required],
      loanType: ['', Validators.required],
      marketplaceLoanKey: [''],
      baseLoanProvisionReserved: [''],
      pastDueLoanProvisionReserved: [''],
      pastDueAmount: [''],
      pastDuePaymentPeriodDates: [''],
      daysLeftToPastDuePayment: [''],
      nextSettlementDate: [''],
      currentDate: [''],
    };

    this.loanForm = this.formBuilder.group(loanFormControls);
  }

  onSave(): void {
    this.loanForm.markAllAsTouched();
    if (!this.loanForm.valid) {
      return;
    }

    if (this.isEditMode) {
      this.editExistingLoan();
      return;
    }

    this.addNewLoan();
  }

  onCancel(): void {
    this.navigateToLoansList();
  }

  onDelete(): void {
    if (!this.isEditMode) {
      return;
    }

    const msg = 'Do you want to delete marketplace loan?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL.width,
    });

    this.subscriptions.push(
      dialogRef
        .afterClosed()
        .pipe(
          filter((confirm) => !!confirm),
          switchMap(() => this.marketplacesLoanService.deleteMarketplaceLoan(this.loanKey)),
        )
        .subscribe(
          () => {
            this.toastrService.success('Marketplace loan deleted successfully');
            this.navigateToLoansList();
          },
          (error) => this.handleHttpError(error, 'An error occured while deleting loan'),
        ),
    );
  }

  private patchFormWithLoanData(data: MarketplaceLoan): void {
    this.loanForm.patchValue(data);
    this.loanForm.get('loanExternalId').disable();
    this.loanForm.get('marketplaceLoanKey').disable();
    this.loanForm.get('pastDuePaymentPeriodDates').disable();
  }

  private editExistingLoan(): void {
    const dataToSave = this.loanForm.value;

    this.subscriptions.push(
      this.marketplacesLoanService.editMarketplaceLoan(this.loanKey, dataToSave).subscribe(
        () => {
          this.toastrService.success('Marketplace loan saved successfully');
          this.navigateToLoansList();
        },
        (error) => this.handleHttpError(error, 'An error occured while saving loan'),
      ),
    );
  }

  private addNewLoan(): void {
    const dataToSave = this.loanForm.value;

    this.subscriptions.push(
      this.marketplacesLoanService.createMarketplaceLoan(dataToSave).subscribe(
        () => {
          this.toastrService.success('Marketplace loan saved successfully');
          this.navigateToLoansList();
        },
        (error) => this.handleHttpError(error, 'An error occured while saving loan'),
      ),
    );
  }

  private setAvailableMpSupKeys(): void {
    this.availableMpSupKeys$ = this.store.select(selectSupplierDetails).pipe(
      filter((data) => !!data),
      map((data) => data.marketplaces || []),
      map((marketplaces) => marketplaces.map((mp) => ({ id: mp.mpSupKey, label: mp.marketplaceName }))),
      tap((marketplaces) => {
        const amazon = marketplaces.find((mp) => mp.label === MARKETPLACE.AMAZON_SELLER_CENTRAL);
        if (this.isEditMode || !amazon) {
          return;
        }
        this.loanForm.get('mpSupKey').setValue(amazon.id);
      }),
    );
  }

  private navigateToLoansList(): void {
    const targetRoute = this.isEditMode ? '../../list' : '../list';
    this.router.navigate([targetRoute], { relativeTo: this.route });
  }

  private handleHttpError(error: any, defaultMsg: string): void {
    const msg = error.error && error.error.message ? `Error: ${error.error.message}` : defaultMsg;
    this.toastrService.error(msg);
  }

  private checkPermissions(): void {
    this.canEditLoan$ = this.userService.checkPermission(USER_PERMISSION.MP_LOANS_MODIFY);
    this.canDeleteLoan$ = this.userService.checkPermission(USER_PERMISSION.MP_LOANS_DELETE);
  }
}
