import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubscriberComponent } from '../../../../../../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../../../../../../store/app.reducers';
import { PendingOfferRiskRatingService } from '../../../service/pending-offer-risk-rating.service';
import { PeriodsCalculationsService } from '../../../service/periods-calculations.service';
import { SetRequestedAmount } from '../../../store/calculator.actions';
import { IAdvanceOfferConnectedInputs } from '../../../store/calculator-interfaces';

@Component({
  selector: 'app-machine-learning-summary',
  templateUrl: './machine-learning-summary.component.html',
  styleUrls: ['./machine-learning-summary.component.scss'],
})
export class MachineLearningSummaryComponent extends SubscriberComponent implements OnInit {
  public isReadonly: boolean;

  public recommendedAmount: number;
  public requestedAmount: number;
  public requested: IAdvanceOfferConnectedInputs;
  public recommended: IAdvanceOfferConnectedInputs;
  public reserveForRepayments: number;

  public requestNotes: string;
  public riskRating: string;
  public elementData: any[];
  public forceFullSaved: boolean;

  constructor(private store: Store<AppState>, private service: PendingOfferRiskRatingService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select('calc').subscribe((calcValue) => {
        this.isReadonly = calcValue.isReadonly;
        this.requested = calcValue.requested;
        this.recommended = calcValue.recommended;
        this.requestedAmount = calcValue.requested.givenAmount;
        this.reserveForRepayments = calcValue.reserveForRepayments;
        if (calcValue.eligibilityData) {
          this.recommendedAmount = calcValue.eligibilityData.offer_amount;
          this.riskRating = calcValue.recommended.descriptionRiskRanking;
          const periods = PeriodsCalculationsService.getPeriodsFromDefaults(
            calcValue.defaultProgressiveAdvanceSettings,
            calcValue.eligibilityData.riskRating,
          );
          if (periods) {
            this.elementData = PeriodsCalculationsService.createPeriodSummary(periods);
          }
        }
        this.requestNotes = calcValue.requestNotes;
        this.forceFullSaved = calcValue.forceFullSaved;
      }),
    );
  }

  changeRequestedAmount(e) {
    this.store.dispatch(new SetRequestedAmount(e.target.value));
  }
}
