import { IGetRowsParams } from 'ag-grid-community';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

import { SupplierService } from './../../suppliers/services/supplier.service';

export class DataTableDatasource {
  public onGetRows = new Subject<{
    [key: string]: any;
  }>();
  requestSubscription$: Subscription;

  constructor(
    private readonly service: SupplierService,
    private reportName: string,
    private supplierKeyInQueryParamsNotNeeded: boolean,
    private supplierKey?: string,
    private filterParams?: any,
  ) {
    this.unsubscribe();
  }

  getRows(rowParams: IGetRowsParams): void {
    this.unsubscribe();
    let modifiedParams = {
      limit: rowParams.endRow - rowParams.startRow,
      offset: rowParams.startRow,
      ...this.filterParams,
    };
    if (!this.supplierKeyInQueryParamsNotNeeded && !modifiedParams.supplier_key) {
      modifiedParams.supplier_key = this.supplierKey;
    }
    if (rowParams.sortModel) {
      const sortModel = this.prepareSortModel(rowParams.sortModel);
      if (sortModel) {
        modifiedParams = {
          ...modifiedParams,
          order_by: sortModel,
        };
      }
    }
    const dataObservable =
      this.reportName === 'mp_statements'
        ? this.service.getSupplierReport(this.supplierKey, this.reportName, modifiedParams).pipe(catchError((err) => of(err)))
        : this.service.getReport(this.reportName, modifiedParams).pipe(catchError((err) => of(err)));

    this.requestSubscription$ = dataObservable.subscribe((data) => {
      this.onGetRows.next(data);
      rowParams.successCallback(data.results, data.total);
    });
  }

  unsubscribe(): void {
    if (this.requestSubscription$) {
      this.requestSubscription$.unsubscribe();
    }
  }

  prepareSortModel(sortModel: any) {
    if (sortModel.length > 0) {
      return sortModel[0].sort === 'asc' ? sortModel[0].colId : '-' + sortModel[0].colId;
    }
    return '';
  }
}
