<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="title">Users</div>
        <div class="actions"></div>
      </div>

      <div class="search-panel">
        <div class="inputs">
          <mat-form-field class="field-text">
            <input matInput type="text" [(ngModel)]="saUsername" placeholder="username" (keyup.enter)="search()" />
          </mat-form-field>
          <mat-form-field class="field-text">
            <mat-select name="options" [(ngModel)]="saPermissionCode" placeholder="profile">
              <mat-option>-- All profiles --</mat-option>
              <mat-option *ngFor="let permission of permissions" [value]="permission.permCode">
                {{ permission.permDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="actions">
          <button mat-raised-button class="action-button" (click)="search()" [color]="'primary'">Search</button>
          <button mat-raised-button class="action-button" (click)="clearSearch()">Clear</button>
        </div>
      </div>

      <div class="users-table">
        <mat-table #table [dataSource]="dataSource">
          <!-- Position Column -->
          <ng-container matColumnDef="loginId">
            <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.loginId }} </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="firstname">
            <mat-header-cell *matHeaderCellDef>First name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.firstname }} </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="lastname">
            <mat-header-cell *matHeaderCellDef>Last name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.lastname }} </mat-cell>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="permission">
            <mat-header-cell *matHeaderCellDef>Permission</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.permission }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-raised-button class="mat-primary" [routerLink]="['/manage/users', element.loginKey, 'edit']">Edit</button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator [length]="numberOfRecords" [pageSize]="basePageSize"></mat-paginator>
      </div>
    </div>
  </div>
</section>
