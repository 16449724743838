import { AfterViewInit, Directive, ElementRef } from '@angular/core';

import { LayoutService } from '../layout/layout.service';

@Directive({ selector: '[appToggleOffcanvasNav]' })
export class ToggleOffcanvasNavDirective implements AfterViewInit {
  constructor(private el: ElementRef, private layoutService: LayoutService) {}

  // @HostListener('click', ['$event'])
  onClick($event) {
    this.layoutService.toggleSidenavState();
  }

  ngAfterViewInit() {
    const $el = $(this.el.nativeElement);
    $el.on('click', ($event) => {
      this.onClick($event);
    });
  }
}
