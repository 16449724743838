import { Injectable } from '@angular/core';

import { COLS, POINTS, ROWS } from './constants';
import { IPiece } from './piece/piece.component';

@Injectable()
export class GameService {
  valid(p: IPiece, board: number[][]): boolean {
    return p.shape.every((row: number[], dy: number) => {
      return row.every((value: number, dx: number) => {
        const x = p.x + dx;
        const y = p.y + dy;
        return this.isEmpty(value) || (this.insideWalls(x) && this.aboveFloor(y) && this.notOccupied(board, x, y));
      });
    });
  }

  isEmpty(value: number): boolean {
    return value === 0;
  }

  insideWalls(x: number): boolean {
    return x >= 0 && x < COLS;
  }

  aboveFloor(y: number): boolean {
    return y <= ROWS;
  }

  notOccupied(board: number[][], x: number, y: number): boolean {
    return board[y] && board[y][x] === 0;
  }

  rotate(piece: IPiece): IPiece {
    const p: IPiece = JSON.parse(JSON.stringify(piece));
    for (let y = 0; y < p.shape.length; ++y) {
      for (let x = 0; x < y; ++x) {
        [p.shape[x][y], p.shape[y][x]] = [p.shape[y][x], p.shape[x][y]];
      }
    }
    p.shape.forEach((row) => row.reverse());
    return p;
  }

  getLinesClearedPoints(lines: number, level: number): number {
    const pointsArray = [0, POINTS.SINGLE, POINTS.DOUBLE, POINTS.TRIPLE, POINTS.TETRIS];
    const lineClearPoints = pointsArray[lines];

    return (level + 1) * lineClearPoints;
  }
}
