import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-with-icon',
  templateUrl: './title-with-icon.component.html',
  styleUrls: ['./title-with-icon.component.scss'],
})
export class TitleWithIconComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() iconClass: string;

  constructor() {}

  ngOnInit() {}
}
