<ng-container *ngIf="text">
  <button
    mat-raised-button
    [color]="color"
    (click)="onClick.emit()"
    *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]"
    [disabled]="disabled"
    [style.width]="customWidth"
    [ngClass]="{ noAction: loading }"
    [class]="customClasses"
    class="spinnerButton"
  >
    <app-loading-spinner [color]="spinnerColor" *ngIf="loading; else textTemplate" [size]="1"> </app-loading-spinner>
  </button>
</ng-container>

<ng-template #textTemplate>
  {{ text }}
</ng-template>
