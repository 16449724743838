import { autoserialize, autoserializeAs } from 'cerialize';

import { CrmUserModel } from './crm-user.model';
import { DocumentModel } from './document.model';

export class FileDataModel {
  @autoserializeAs(DocumentModel) public doc: DocumentModel;
  @autoserialize public status: string;
  @autoserializeAs(CrmUserModel) public user: CrmUserModel;

  constructor(doc: DocumentModel, status: string, user: CrmUserModel) {
    this.doc = doc;
    this.status = status;
    this.user = user;
  }
}
