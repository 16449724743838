import { Action, createSelector } from '@ngrx/store';

import { AppState } from '../../../store/app.reducers';
import { LayoutState } from './layout.reducers';

export const LAYOUT_UPDATE = 'LAYOUT_UPDATE';

export const CRM_PANEL_OPEN = 'CRM_PANEL_OPEN';
export const CRM_PANEL_CLOSE = 'CRM_PANEL_CLOSE';
export const CRM_PANEL_FIX = 'CRM_PANEL_FIX';
export const CRM_PANEL_HIDE = 'CRM_PANEL_HIDE';

export const SUPPLIER_INFO_OPEN = 'SUPPLIER_INFO_OPEN';
export const SUPPLIER_INFO_CLOSE = 'SUPPLIER_INFO_CLOSE';
export const SUPPLIER_INFO_HIDE = 'SUPPLIER_INFO_HIDE';

export const SEARCH_BUTTON_SHOW = 'SEARCH_BUTTON_SHOW';
export const SEARCH_BUTTON_HIDE = 'SEARCH_BUTTON_HIDE';

export const ACTIONS_SHOW = 'ACTIONS_SHOW';
export const ACTIONS_HIDE = 'ACTIONS_HIDE';

export const CHOOSE_NEW_SEARCH = 'CHOOSE_NEW_SEARCH';
export const CHOOSE_OLD_SEARCH = 'CHOOSE_OLD_SEARCH';

export class LayoutUpdate implements Action {
  readonly type = LAYOUT_UPDATE;
  constructor(public payload: string) {}
}

export class ChooseNewSearch implements Action {
  readonly type = CHOOSE_NEW_SEARCH;
}

export class ChooseOldSearch implements Action {
  readonly type = CHOOSE_OLD_SEARCH;
}

export class CrmPanelOpen implements Action {
  readonly type = CRM_PANEL_OPEN;
}

export class CrmPanelFix implements Action {
  readonly type = CRM_PANEL_FIX;
}

export class CrmPanelHide implements Action {
  readonly type = CRM_PANEL_HIDE;
}

export class CrmPanelClose implements Action {
  readonly type = CRM_PANEL_CLOSE;
}

export class SupplierInfoPanelOpen implements Action {
  readonly type = SUPPLIER_INFO_OPEN;
}

export class SupplierInfoPanelHide implements Action {
  readonly type = SUPPLIER_INFO_HIDE;
}

export class SupplierInfoPanelClose implements Action {
  readonly type = SUPPLIER_INFO_CLOSE;
}

export class SearchButtonShow implements Action {
  readonly type = SEARCH_BUTTON_SHOW;
}

export class SearchButtonHide implements Action {
  readonly type = SEARCH_BUTTON_HIDE;
}

export class ActionsShow implements Action {
  readonly type = ACTIONS_SHOW;
}

export class ActionsHide implements Action {
  readonly type = ACTIONS_HIDE;
}

const selectLayoutState = (state: AppState) => state.layout;

export const selectSearchButtonVisible = createSelector(selectLayoutState, (state: LayoutState) => state.searchButtonVisible);

export type LayoutActions =
  | LayoutUpdate
  | CrmPanelOpen
  | CrmPanelFix
  | CrmPanelClose
  | CrmPanelHide
  | SupplierInfoPanelOpen
  | SupplierInfoPanelHide
  | SupplierInfoPanelClose
  | SearchButtonShow
  | SearchButtonHide
  | ActionsShow
  | ActionsHide
  | ChooseNewSearch
  | ChooseOldSearch;
