import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NEVER, Observable } from 'rxjs';

import { IMatSelectOption } from '../../../../../../shared/interfaces/mat-select-option';
import { LookupAbstractService } from '../../../../../../shared/lookup-service/lookup-abstract.service';
import { ErrorVisualizationService } from '../../../../../../shared/services/error-visualization/error-visualization.service';
import { AppState } from '../../../../../../store/app.reducers';
import { PaymentConfigModel } from '../../../../../model/payment-config.model';
import { RoutingNumberValidationStatusConfig } from '../../../../../model/routing-number-validation-status.config.model';
import { SupplierDetailsModel } from '../../../../../model/supplier.model';
import { PaymentConfigService } from '../../../../../services/payment-config/payment-config.service';
import { SupplierService } from '../../../../../services/supplier.service';
import { OutgoingPaymentFormComponentBase } from '../outgoing-payment-form-component.base';
import { postalCodeUpdateValidators } from '../payment-type-util';

@Component({
  selector: 'app-payment-type-ach',
  templateUrl: './payment-type-ach.component.html',
  styleUrls: ['./payment-type-ach.component.scss'],
})
export class PaymentTypeAchComponent extends OutgoingPaymentFormComponentBase implements OnInit {
  @Input() paymentConfig?: PaymentConfigModel;
  @Input() selectedCountry = '';
  @Input() supplierDetailsModel?: SupplierDetailsModel;
  @Output() configForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  states$: Observable<IMatSelectOption[] | null> = NEVER;
  bankStates$: Observable<IMatSelectOption[] | null> = NEVER;
  enableForDebit: boolean;

  bankIdNumCtrlValidationStatus$: Observable<RoutingNumberValidationStatusConfig> = NEVER;

  constructor(
    private readonly fb: FormBuilder,
    private readonly paymentConfigService: PaymentConfigService,
    private readonly errorVisualizationService: ErrorVisualizationService,
    private readonly toastrService: ToastrService,
    supplierService: SupplierService,
    store: Store<AppState>,
    lookupRepo: LookupAbstractService,
  ) {
    super(supplierService, store, lookupRepo);
  }

  ngOnInit() {
    this.createForm();

    const beneficiaryAddressCountryControl = this.form.get(['beneficiary', 'address', 'country']);
    const bankAddressCountryControl = this.form.get(['bank', 'address', 'country']);

    this.subscriptions.push(
      beneficiaryAddressCountryControl.valueChanges.subscribe(
        postalCodeUpdateValidators(this.form.get(['beneficiary', 'address', 'postalCode'])),
      ),
    );
    this.subscriptions.push(
      bankAddressCountryControl.valueChanges.subscribe(postalCodeUpdateValidators(this.form.get(['bank', 'address', 'postalCode']))),
    );

    this.setFormDataBasedOnInputs();
    this.setValidationForBankIdNumCtrl();
    this.setIsInternationalPayment(bankAddressCountryControl);

    this.states$ = this.setAvailableStatesOnCountryChange(beneficiaryAddressCountryControl);
    this.bankStates$ = this.setAvailableStatesOnCountryChange(bankAddressCountryControl);
  }

  toggleDebitBankAccount($event) {
    this.paymentConfig.enableForDebit = $event.checked;
    this.subscriptions.push(
      this.paymentConfigService.save(this.supplierDetailsModel.supplierKey, this.paymentConfig).subscribe(
        () => this.toastrService.success('Successfully changed Enable for Debit'),
        (error) => this.errorVisualizationService.showError({ name: "Can't change Enable for Debit", errorObject: error }),
      ),
    );
  }

  private createForm() {
    this.form = this.fb.group({
      enableForDebit: '',
      beneficiary: this.fb.group({
        address: this.fb.group({
          addr1: '',
          city: '',
          addr2: '',
          country: '',
          postalCode: '',
          state: '',
          addr3: '',
        }),
        beneficiaryName: '',
        accountNumber: ['', Validators.pattern(/^[0-9]{1,}$/)],
        realMaskAccount: '',
        phoneNumber: '',
      }),
      bank: this.fb.group({
        name: '',
        address: this.fb.group({
          addr1: '',
          city: '',
          addr2: '',
          country: '',
          postalCode: '',
          state: '',
          addr3: '',
        }),
        Id: '',
        branchId: '',
      }),
    });
  }

  private setValidationForBankIdNumCtrl() {
    const bankIdNumCtrl = this.form.get(['bank', 'Id']);
    const countryCtrl = this.form.get(['bank', 'address', 'country']);

    this.bankIdNumCtrlValidationStatus$ = this.setBankIdNumValidation(bankIdNumCtrl, () =>
      this.checkBankIdNumControl(bankIdNumCtrl, countryCtrl, { isRequiredForInternationalPayments: true }),
    );
  }

  private setFormDataBasedOnInputs() {
    if (this.selectedCountry) {
      this.form.get(['bank', 'address', 'country']).setValue(this.selectedCountry);
    }

    if (!this.paymentConfig) {
      return;
    }

    if (this.paymentConfig.hasOwnProperty('paymentConfig')) {
      this.form.patchValue(this.paymentConfig.paymentConfig);
    }

    this.enableForDebit = this.paymentConfig.enableForDebit;
  }
}
