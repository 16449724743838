import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { MarketplaceListModel } from '../model/marketplace-list-model';
import { MarketplacesAbstractService } from '../service/marketplaces-abstract.service';

@Component({
  selector: 'app-marketplace-list',
  templateUrl: './marketplace-list.component.html',
  styleUrls: ['./marketplace-list.component.scss'],
})
export class MarketplaceListComponent implements OnInit {
  public marketplaces: Observable<MarketplaceListModel[]>;

  constructor(itemsRepo: MarketplacesAbstractService) {
    this.marketplaces = itemsRepo.getItems();
  }

  ngOnInit() {}
}
