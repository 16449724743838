export const GetCellClass = (value: string): string => {
  switch (value) {
    case 'MATCH':
      return 'idStatus -match';
    case 'PARTIAL MATCH':
      return 'idStatus -partial';
    case 'NO MATCH':
      return 'idStatus -notMatch';
    default:
      return 'idStatus';
  }
};
