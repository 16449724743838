<mat-form-field class="full-width" *ngIf="params.value === 'active'; else noDropdown">
  <mat-select [(value)]="params.value" (selectionChange)="onCellValueChange()">
    <mat-option [value]="'active'"> Active </mat-option>
    <mat-option [value]="'voided'"> Voided </mat-option>
  </mat-select>
</mat-form-field>
<ng-template #noDropdown>
  <div style="text-transform: capitalize">
    {{ params.value }}
  </div>
</ng-template>
