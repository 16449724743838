import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-actions-menu',
  templateUrl: './actions-menu.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ActionsMenuComponent implements OnInit {
  @Input() menuOptions: { [label: string]: any } = {};
  @Input() menuLabel: string;

  constructor() {}

  ngOnInit() {}
}
