import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { ThemeOnboardingModel } from '../../../../shared/onboarding/onboarding.model';
import { AppState } from '../../../../store/app.reducers';

@Component({
  selector: 'app-onboarding-general-information',
  templateUrl: './onboarding-general-information.component.html',
  styleUrls: ['./onboarding-general-information.component.scss'],
})
export class OnboardingGeneralInformationComponent extends SubscriberComponent implements OnInit, OnChanges {
  @Input() templateData: ThemeOnboardingModel;

  @Output() formReady = new EventEmitter<FormGroup>();

  public generalInformationForm: FormGroup;
  public supplierSites: string[] = ['supplier', 'supplier2', 'supplier3', 'morecommerce', 'c2fo', 'volusion'];
  public id: string;

  constructor(private fb: FormBuilder, private store: Store<AppState>, private route: ActivatedRoute) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('onboardingTemplateKey');
    this.formReady.emit(this.generalInformationForm);
    this.subscriptions.push(
      this.store.select('onboarding').subscribe((result) => {
        if (result.resetTimestamp.length > 1) {
          this.generalInformationForm.reset({
            templateName: '',
            supplierSite: '',
            siteUrlParameter: '',
            cssStylesheetName: '',
            loadNetEarnings: false,
            plaidStep: false,
          });
          if (this.isInEditMode() && this.templateData) {
            this.generalInformationForm.patchValue({
              templateName: this.templateData.onboardingTemplateCode,
            });
          }
        }
      }),
    );
  }

  private createForm(): void {
    this.generalInformationForm = this.fb.group({
      templateName: ['', Validators.required],
      supplierSite: [''],
      siteUrlParameter: ['', Validators.required],
      cssStylesheetName: [''],
      loadNetEarnings: false,
      plaidStep: false,
    });
  }

  private patchForm(template: ThemeOnboardingModel): void {
    this.generalInformationForm.patchValue(
      {
        templateName: template.onboardingTemplateCode,
        supplierSite: template.supplierSite,
        siteUrlParameter: template.site,
        cssStylesheetName: template.cssFile,
        loadNetEarnings: template.loadNetEarnings,
        plaidStep: template.plaidStep,
      },
      { onlySelf: true, emitEvent: true },
    );
    this.generalInformationForm.get('templateName').disable();
  }

  ngOnChanges(changes) {
    if (changes.templateData && !changes.templateData.firstChange) {
      this.patchForm(this.templateData);
    }
  }

  private isInEditMode(): boolean {
    return !!this.id;
  }
}
