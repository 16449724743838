import { Component, Input } from '@angular/core';

import { SupplierDetailsModel } from '../../../../model/supplier.model';

@Component({
  selector: 'app-supplier-owners',
  templateUrl: './supplier-owners.component.html',
})
export class SupplierOwnersComponent {
  @Input() owners: any[];
  @Input() isAdditionalOwnerCheckmarked: any[];
  @Input() additionalOwner: any;
  @Input() supplierDetailsModel: SupplierDetailsModel;
  @Input() isShown: boolean;
  @Input() isDisabled: boolean;
  @Input() additionalOwnerKey: any;
}
