<h1 mat-dialog-title>{{ actionTitle }}</h1>

<div class="confirmation" *ngIf="isSaved">
  {{ confirmationMessage }}
</div>

<div [formGroup]="supplierActionForm" *ngIf="showForm">
  <mat-form-field class="full-width">
    <mat-select placeholder="Marketplace" formControlName="marketplace">
      <mat-option *ngFor="let marketplace of marketplaces" [value]="marketplace.id">
        {{ marketplace.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="picker" placeholder="Post date" formControlName="post_date" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Amount" type="number" step="0.01" formControlName="amount" />
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Reason" formControlName="reason" />
  </mat-form-field>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="showForm && !isSaved">
  <button mat-raised-button (click)="save()" tabindex="2" color="accent">Save</button>
  <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="isSaved">
  <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Yes</button>
  <button mat-raised-button (click)="editForm()" tabindex="-1" color="warn">No</button>
</div>

<div *ngIf="isSuccess">
  <div class="alert alert-success">
    {{ successMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="resetForm()" tabindex="2" color="accent">Add Another</button>
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Done</button>
  </div>
</div>

<div *ngIf="isError">
  <div class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="back()" tabindex="-1" color="accent">Back</button>
  </div>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
