import { Directive, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { isNullOrUndefined, isUndefined } from 'util';

import { AppState } from '../../../store/app.reducers';
import { CrmItem } from '../../models/crm-item.type';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ActionTabComponent implements OnInit {
  @Input() crmItems: { [uuid: string]: CrmItem };
  subscriptions: Subscription[] = [];
  loaded = false;
  itemsLoaded = -1;
  last: any;
  limit = 5;
  uuids: string[];
  selector: any;
  fetchAction: any;
  pending = false;

  constructor(private store: Store<AppState>) {}

  fetchItems(nextPage = false) {
    this.pending = true;
    if (!nextPage && this.itemsLoaded < this.limit) {
      //  Load initial items
      this.store.dispatch(new this.fetchAction({ limit: this.limit, component: this }));
      this.subscriptions.push(
        this.store.select(this.selector).subscribe((response) => {
          this.pending = false;
          this.uuids = response;
          if (this.itemsLoaded < response.length) {
            this.itemsLoaded = response.length;
          } else {
            this.loaded = true;
          }
        }),
      );
    } else if (nextPage) {
      // Load next page
      this.calculateLast();
      this.store.dispatch(new this.fetchAction({ limit: this.limit, last: this.last, component: this }));
    }
  }

  ngOnInit() {}

  calculateLast() {
    if (!isNullOrUndefined(this.uuids)) {
      const lastUuid = this.uuids.slice(-1)[0];
      if (!isUndefined(lastUuid) && !isUndefined(this.crmItems[lastUuid])) {
        this.last = {
          target_uuid: this.crmItems[lastUuid].targetUuid,
          updated_at: this.crmItems[lastUuid].updatedAt,
          uuid: this.crmItems[lastUuid].uuid,
        };
      }
    }
  }

  onScroll() {
    if (!this.loaded && !this.pending) {
      this.fetchItems(true);
    }
  }
}
