import { HttpErrorResponse } from '@angular/common/http';
import { Action, createSelector } from '@ngrx/store';

import { AppState } from '../../../store/app.reducers';
import { PaymentConfigModel } from '../../model/payment-config.model';
import { SupplierDetailsModel, SupplierModel } from '../../model/supplier.model';
import { SupplierMarketplaceDetailsModel } from '../../model/supplier-marketplace.model';
import { SupplierState } from './supplier.reducers';

export const UPDATE_SUPPLIER_DETAILS_MODEL_BY_EVA = 'UPDATE_SUPPLIER_DETAILS_MODEL_BY_EVA';

export const SET_SUPPLIER_KEY = 'SET_SUPPLIER_KEY';
export const SET_SUPPLIERS_QUERY_STRING = 'SET_SUPPLIERS_QUERY_STRING';

export const UPDATE_SUPPLIER_MODEL = 'UPDATE_SUPPLIER_MODEL';
export const UPDATE_SUPPLIER_DETAILS_MODEL = 'UPDATE_SUPPLIER_DETAILS_MODEL';

export const FETCH_SUPPLIER_DETAILS = 'FETCH_SUPPLIER_DETAILS';
export const FETCH_SUPPLIER_DETAILS_ERROR = 'FETCH_SUPPLIER_DETAILS_ERROR';

export const FETCH_SUPPLIER_MARKETPLACES = 'FETCH_SUPPLIER_MARKETPLACES';
export const FETCH_SUPPLIER_MARKETPLACES_ERROR = 'FETCH_SUPPLIER_MARKETPLACES_ERROR';

export const FETCH_SUPPLIER_MARKETPLACE = 'FETCH_SUPPLIER_MARKETPLACE';
export const FETCH_SUPPLIER_MARKETPLACE_SUCCESS = 'FETCH_SUPPLIER_MARKETPLACE_SUCCESS';
export const FETCH_SUPPLIER_MARKETPLACE_ERROR = 'FETCH_SUPPLIER_MARKETPLACE_ERROR';

export const FETCH_SUPPLIER_MARKETPLACE_DETAILS_PART = 'FETCH_SUPPLIER_MARKETPLACE_DETAILS_PART';
export const FETCH_SUPPLIER_MARKETPLACE_DETAILS_PART_ERROR = 'FETCH_SUPPLIER_MARKETPLACE_DETAILS_PART_ERROR';

export const UPDATE_MODEL_PART = 'UPDATE_MODEL_PART';
export const UPDATE_MODEL_PART_SUCCESS = 'UPDATE_MODEL_PART_SUCCESS';
export const UPDATE_MODEL_PART_MAPS = 'UPDATE_MODEL_PART_MAPS';
export const UPDATE_MODEL_PART_ERROR = 'UPDATE_MODEL_PART_ERROR';

export const FETCH_SUPPLIER_PAYMENT_CONFIGS = 'FETCH_SUPPLIER_PAYMENT_CONFIGS';
export const FETCH_SUPPLIER_PAYMENT_CONFIGS_ERROR = 'FETCH_SUPPLIER_PAYMENT_CONFIGS_ERROR';
export const DELETE_SUPPLIER_PAYMENT_CONFIGS = 'DELETE_SUPPLIER_PAYMENT_CONFIGS';
export const DELETE_SUPPLIER_PAYMENT_CONFIGS_SUCCESS = 'DELETE_SUPPLIER_PAYMENT_CONFIGS_SUCCESS';
export const DELETE_SUPPLIER_PAYMENT_CONFIGS_ERROR = 'DELETE_SUPPLIER_PAYMENT_CONFIGS_ERROR';
export const CREATE_SUPPLIER_PAYMENT_CONFIGS = 'CREATE_SUPPLIER_PAYMENT_CONFIGS';
export const CREATE_SUPPLIER_PAYMENT_CONFIGS_SUCCESS = 'CREATE_SUPPLIER_PAYMENT_CONFIGS_SUCCESS';
export const CREATE_SUPPLIER_PAYMENT_CONFIGS_ERROR = 'CREATE_SUPPLIER_PAYMENT_CONFIGS_ERROR';
export const UDPATE_SUPPLIER_PAYMENT_CONFIGS = 'UDPATE_SUPPLIER_PAYMENT_CONFIGS';
export const UPDATE_SUPPLIER_PAYMENT_CONFIGS_SUCCESS = 'UPDATE_SUPPLIER_PAYMENT_CONFIGS_SUCCESS';
export const UPDATE_SUPPLIER_PAYMENT_CONFIGS_ERROR = 'UPDATE_SUPPLIER_PAYMENT_CONFIGS_ERROR';

export const TOGGLE_HIDE_SUPPLIER = 'TOGGLE_HIDE_SUPPLIER';
export const TOGGLE_HIDE_SUPPLIER_SUCCESS = 'TOGGLE_HIDE_SUPPLIER_SUCCESS';
export const TOGGLE_HIDE_SUPPLIER_ERROR = 'TOGGLE_HIDE_SUPPLIER_ERROR';

export const TOGGLE_SUSPEND_SUPPLIER = 'TOGGLE_SUSPEND_SUPPLIER';
export const TOGGLE_SUSPEND_SUPPLIER_SUCCESS = 'TOGGLE_SUSPEND_SUPPLIER_SUCCESS';
export const TOGGLE_SUSPEND_SUPPLIER_ERROR = 'TOGGLE_SUSPEND_SUPPLIER_ERROR';

export const TOGGLE_LOCK_SUPPLIER = 'TOGGLE_LOCK_SUPPLIER';
export const TOGGLE_LOCK_SUPPLIER_SUCCESS = 'TOGGLE_LOCK_SUPPLIER_SUCCESS';
export const TOGGLE_LOCK_SUPPLIER_ERROR = 'TOGGLE_LOCK_SUPPLIER_ERROR';

export const ADD_OPT_IN_ROW = 'ADD_OPT_IN_ROW';
export const ADD_OPT_IN_ROW_SUCCESS = 'ADD_OPT_IN_ROW_SUCCESS';
export const ADD_OPT_IN_ROW_ERROR = 'ADD_OPT_IN_ROW_ERROR';
export const ADD_OPT_IN_ROW_CLEAR = 'ADD_OPT_IN_ROW_CLEAR';

export const DELETE_OPT_IN_ROW = 'DELETE_OPT_IN_ROW';
export const DELETE_OPT_IN_ROW_SUCCESS = 'DELETE_OPT_IN_ROW_SUCCESS';
export const DELETE_OPT_IN_ROW_ERROR = 'DELETE_OPT_IN_ROW_ERROR';

export const UNLOAD_SUPPLIER = 'UNLOAD_SUPPLIER';

export const REQUEST_EFS_CARD = 'REQUEST_EFS_CARD';

export const PC_RESPONSE_SUCCESS = 'PC_RESPONSE_SUCCESS';
export const PC_RESPONSE_ERROR = 'PC_RESPONSE_ERROR';
export const PC_CLEAR_STATUS = 'PC_CLEAR_STATUS';

export const FETCH_COMPLIANCE_ACTIONS_WITH_FILES = 'FETCH_COMPLIANCE_ACTIONS_WITH_FILES';
export const FETCH_COMPLIANCE_ACTIONS_WITH_FILES_ERROR = 'FETCH_COMPLIANCE_ACTIONS_WITH_FILES_ERROR';

export const ADD_PAYMENT_GATEWAY = 'ADD_PAYMENT_GATEWAY';

export class UpdateSupplierDetailsModelByEva implements Action {
  readonly type = UPDATE_SUPPLIER_DETAILS_MODEL_BY_EVA;
  constructor(public payload: { supplier: Partial<SupplierDetailsModel>; supplierKey: string }) {}
}

export class SetSupplierKey implements Action {
  readonly type = SET_SUPPLIER_KEY;
  constructor(public payload: string) {}
}

export class UpdateSupplierModel implements Action {
  readonly type = UPDATE_SUPPLIER_MODEL;
  constructor(public payload: Partial<SupplierModel>, public supplierKey: string) {}
}

export class UpdateSupplierDetailsModel implements Action {
  readonly type = UPDATE_SUPPLIER_DETAILS_MODEL;
  constructor(public payload: { detailsModel: SupplierDetailsModel; supplierKey: string }) {}
}

export class FetchSupplierDetails implements Action {
  readonly type = FETCH_SUPPLIER_DETAILS;
  constructor(public payload: { supplierKey: string; refresh: boolean }) {}
}

export class FetchSupplierDetailsError implements Action {
  readonly type = FETCH_SUPPLIER_DETAILS_ERROR;
  constructor(public payload: { errorResponse: HttpErrorResponse }) {}
}

export class FetchSupplierMarketplaces implements Action {
  readonly type = FETCH_SUPPLIER_MARKETPLACES;
  constructor(public payload: { supplierDetailsModel: SupplierDetailsModel; refresh: boolean }) {}
}

export class FetchSupplierMarketplace implements Action {
  readonly type = FETCH_SUPPLIER_MARKETPLACE;
  constructor(public payload: { supplierDetailsModel: SupplierDetailsModel; marketplaceUuid: string; refresh: boolean }) {}
}

export class FetchSupplierMarketplaceSuccess implements Action {
  readonly type = FETCH_SUPPLIER_MARKETPLACE_SUCCESS;
  constructor(public payload: { marketplaceModel: SupplierMarketplaceDetailsModel }) {}
}
export class FetchSupplierMarketplaceError implements Action {
  readonly type = FETCH_SUPPLIER_MARKETPLACE_ERROR;
  constructor() {}
}

export class FetchSupplierMarketplacesError implements Action {
  readonly type = FETCH_SUPPLIER_MARKETPLACES_ERROR;
}

export class FetchSupplierMarketplaceDetailsPart implements Action {
  readonly type = FETCH_SUPPLIER_MARKETPLACE_DETAILS_PART;
  constructor(
    public payload: {
      supplierDetailsModel: SupplierDetailsModel;
      marketplaceModel: SupplierMarketplaceDetailsModel;
      serviceClassName: string;
      storeKey: string;
      refresh: boolean;
    },
  ) {}
}

export class FetchSupplierMarketplaceDetailsPartError implements Action {
  readonly type = FETCH_SUPPLIER_MARKETPLACE_DETAILS_PART_ERROR;
}

export class UpdateModelPart implements Action {
  readonly type = UPDATE_MODEL_PART;
  constructor(
    public payload: {
      supplierKey: string;
      serviceClassName: string;
      newValues: any;
      formGroupKey: string;
      propertyName: string;
      modelParams: any[];
    },
  ) {}
}

export class UpdateModelPartSuccess implements Action {
  readonly type = UPDATE_MODEL_PART_SUCCESS;
  constructor(public payload: { supplierKey: string; formGroupKey: string; newValues: any }) {}
}

export class UpdateModelPartError implements Action {
  readonly type = UPDATE_MODEL_PART_ERROR;
  constructor(public payload: { supplierKey: string; newValues: any; formGroupKey: string; error: any }) {}
}

export class UpdateModelPartMaps implements Action {
  readonly type = UPDATE_MODEL_PART_MAPS;
  constructor(public payload: { formGroupKey: string; mapType: string }) {}
}

export class ToggleHideSupplier implements Action {
  readonly type = TOGGLE_HIDE_SUPPLIER;
  constructor(public payload: boolean) {}
}

export class ToggleHideSupplierSuccess implements Action {
  readonly type = TOGGLE_HIDE_SUPPLIER_SUCCESS;
}

export class ToggleHideSupplierError implements Action {
  readonly type = TOGGLE_HIDE_SUPPLIER_ERROR;
  constructor(public payload: any) {}
}

export class ToggleSuspendSupplier implements Action {
  readonly type = TOGGLE_SUSPEND_SUPPLIER;
  constructor(public payload: { suspend: boolean; reason: any }) {}
}

export class ToggleSuspendSupplierSuccess implements Action {
  readonly type = TOGGLE_SUSPEND_SUPPLIER_SUCCESS;
}

export class ToggleSuspendSupplierError implements Action {
  readonly type = TOGGLE_SUSPEND_SUPPLIER_ERROR;
  constructor(public payload: any) {}
}

export class ToggleLockSupplier implements Action {
  readonly type = TOGGLE_LOCK_SUPPLIER;
  constructor(public payload: { lock: boolean }) {}
}

export class ToggleLockSupplierSuccess implements Action {
  readonly type = TOGGLE_LOCK_SUPPLIER_SUCCESS;
}

export class ToggleLockSupplierError implements Action {
  readonly type = TOGGLE_LOCK_SUPPLIER_ERROR;
  constructor(public payload: any) {}
}

export class AddOptInRow implements Action {
  readonly type = ADD_OPT_IN_ROW;
  constructor(
    public payload: {
      supplierDetailsModel: SupplierDetailsModel;
      marketplaceDetailsModel: SupplierMarketplaceDetailsModel;
      data: any[];
    },
  ) {}
}

export class AddOptInRowSuccess implements Action {
  readonly type = ADD_OPT_IN_ROW_SUCCESS;
  constructor(public payload: any) {}
}

export class AddOptInRowError implements Action {
  readonly type = ADD_OPT_IN_ROW_ERROR;
  constructor(public payload: any) {}
}

export class AddOptInRowClear implements Action {
  readonly type = ADD_OPT_IN_ROW_CLEAR;
}

export class DeleteOptInRow implements Action {
  readonly type = DELETE_OPT_IN_ROW;
  constructor(
    public payload: {
      optInPeriodKey: string;
      supplierDetailsModel: SupplierDetailsModel;
      marketplaceDetailsModel: SupplierMarketplaceDetailsModel;
      reason: { reasonCode: string; reasonNote: string };
    },
  ) {}
}

export class DeleteOptInRowSuccess implements Action {
  readonly type = DELETE_OPT_IN_ROW_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteOptInRowError implements Action {
  readonly type = DELETE_OPT_IN_ROW_ERROR;
  constructor(public payload: any) {}
}

export class UnloadSupplier implements Action {
  readonly type = UNLOAD_SUPPLIER;
}

export class FetchSupplierPaymentConfigs implements Action {
  readonly type = FETCH_SUPPLIER_PAYMENT_CONFIGS;
  public payload: { fresh: boolean };

  constructor(payload?: { fresh: boolean }) {
    this.payload = payload;
  }
}

export class DeleteSupplierPaymentConfigs implements Action {
  readonly type = DELETE_SUPPLIER_PAYMENT_CONFIGS;

  constructor(public payload: { paymentConfigKey: string; actionId: string }) {}
}

export class CreateSupplierPaymentConfigs implements Action {
  readonly type = CREATE_SUPPLIER_PAYMENT_CONFIGS;

  constructor(public payload: { paymentConfig: PaymentConfigModel; actionId: string }) {}
}

export class UpdateSupplierPaymentConfigs implements Action {
  readonly type = UDPATE_SUPPLIER_PAYMENT_CONFIGS;

  constructor(public payload: { paymentConfig: PaymentConfigModel; paymentConfigKey: string; actionId: string }) {}
}

export class CreateSupplierPaymentConfigsError implements Action {
  readonly type = CREATE_SUPPLIER_PAYMENT_CONFIGS_ERROR;
  constructor(public payload: any) {}
}

export class FetchSupplierPaymentConfigsError implements Action {
  readonly type = FETCH_SUPPLIER_PAYMENT_CONFIGS_ERROR;
}

export class SetSuppliersQueryString implements Action {
  readonly type = SET_SUPPLIERS_QUERY_STRING;
  constructor(public payload: { queryString: string }) {}
}

export class RequestEfsCard implements Action {
  readonly type = REQUEST_EFS_CARD;
  constructor(public payload: { url: string }) {}
}

export class FetchComplianceActionsWithFiles implements Action {
  readonly type = FETCH_COMPLIANCE_ACTIONS_WITH_FILES;
  constructor(public payload: { supplierKey: string; refresh: boolean }) {}
}

export class PaymentConfigSuccess implements Action {
  readonly type = PC_RESPONSE_SUCCESS;
  constructor(public payload: { actionId: string }) {}
}
export class PaymentConfigError implements Action {
  readonly type = PC_RESPONSE_ERROR;
  constructor(public payload: { actionId: string; error: any }) {}
}
export class PaymentConfigClearStatus implements Action {
  readonly type = PC_CLEAR_STATUS;
  constructor(public payload: { actionId: string }) {}
}

export const selectSupplier = (state: AppState) => {
  if (state.supplier !== undefined) {
    return state.supplier;
  }
};

export const selectSupplierModel = createSelector(selectSupplier, (state: SupplierState) => {
  if (state.supplierModel !== undefined) {
    return state.supplierModel;
  }
});

export const selectLastQueryString = createSelector(selectSupplier, (state: SupplierState) => state.lastQueryString);

export const selectSupplierDetails = createSelector(selectSupplierModel, (supplierModel: SupplierModel) => {
  if (supplierModel !== undefined && supplierModel.supplierDetailsModel !== undefined) {
    return supplierModel.supplierDetailsModel;
  }
});
export const selectSupplierDetailsError = createSelector(selectSupplier, (state: SupplierState) => {
  if (state.supplierError !== undefined) {
    return state.supplierError;
  }
});

export const selectFundingParticulars = createSelector(selectSupplier, (state: SupplierState) => {
  if (state.supplierModel !== undefined && state.supplierModel.currentFundingParticularsModel !== undefined) {
    return state.supplierModel.currentFundingParticularsModel;
  }
});

export const selectEfsCardRequested = createSelector(selectSupplier, selectSupplierDetails, (state: SupplierState, supplierDetails) =>
  state.supplierModel !== undefined && supplierDetails !== undefined ? supplierDetails.efsLastCardReqTs : undefined,
);

export const selectPaymentMethods = createSelector(selectSupplier, (state: SupplierState) =>
  state.supplierModel !== undefined ? state.supplierModel.paymentConfigsModels : undefined,
);

export const selectSupplierBalance = createSelector(selectSupplier, (state: SupplierState) =>
  state.supplierModel !== undefined ? state.supplierModel.balance : undefined,
);

export const selectSupplierDetailsAndFunding = createSelector(
  selectSupplierDetails,
  selectFundingParticulars,
  (supplierDetails, fundingParticulars) => [supplierDetails, fundingParticulars],
);

export const selectModelPartSaved = createSelector(selectSupplier, (state: SupplierState) => {
  if (state !== undefined && state.supplierModelPartSaved !== undefined) {
    return state.supplierModelPartSaved;
  }
});

export const selectModelPartErrors = createSelector(selectSupplier, (state: SupplierState) => {
  if (state !== undefined && state.supplierModelPartErrors !== undefined) {
    return state.supplierModelPartErrors;
  }
});

export const selectSupplierMarketplaces = createSelector(selectSupplier, (state: SupplierState) => {
  if (state.supplierModel !== undefined) {
    return state.supplierModel.marketplaces;
  }
});

export const selectSupplierMarketplacesList = createSelector(selectSupplier, (state: SupplierState) => {
  if (state.supplierModel !== undefined && state.supplierModel.supplierDetailsModel !== undefined) {
    const marketplaces = state.supplierModel.marketplaces;
    const detailedMarketpalces = state.supplierModel.supplierDetailsModel.marketplaces;

    if (marketplaces && detailedMarketpalces && marketplaces.length === detailedMarketpalces.length) {
      return state.supplierModel.marketplaces;
    }
  }
});

export const selectSupplierMarketplacesFundings = createSelector(selectSupplier, (state: SupplierState) => {
  if (state.supplierModel !== undefined) {
    return state.supplierModel.marketplaceFundingModels;
  }
});

export const selectSupplierMarketplacesOptInPeriods = createSelector(
  selectSupplier,
  selectSupplierDetails,
  selectSupplierMarketplaces,
  (state: SupplierState, supplierDetails, supplierMarketplaces) => {
    if (state.supplierModel !== undefined && supplierDetails !== undefined && supplierMarketplaces !== undefined) {
      return {
        optInPeriodModels: state.supplierModel.marketplaceOptInPeriodModels,
        supplierDetailsModel: supplierDetails,
        supplierMarketplacesModels: supplierMarketplaces,
      };
    }
  },
);

export const selectSupplierMarketplacesMinFeeOverrides = createSelector(selectSupplier, (state: SupplierState) => {
  if (state.supplierModel !== undefined) {
    return state.supplierModel.marketplaceMinFeeOverridesModels;
  }
});

export const selectAddOptInProggress = createSelector(selectSupplier, (state: SupplierState) => {
  return state.addOptInProgress;
});

export const selectAddOptInError = createSelector(selectSupplier, (state: SupplierState) => {
  return state.addOptInError;
});

export const selectCompleteKycDocuments = createSelector(selectSupplier, (state: SupplierState) => {
  if (state.supplierModel !== undefined && state.supplierModel.kycDocumentsStatuses !== undefined) {
    return state.supplierModel.kycDocumentsStatuses.filter(function (action) {
      return action.status !== 'incomplete';
    });
  }
});

export const selectPendingPCStates = createSelector(selectSupplier, (state: SupplierState) => {
  if (state !== undefined && state.paymentConfigsPending !== undefined) {
    return state.paymentConfigsPending;
  }
});

export class AddPaymentGateway implements Action {
  readonly type = ADD_PAYMENT_GATEWAY;
  constructor(public payload: any) {}
}

export type Actions =
  | UpdateSupplierDetailsModelByEva
  | SetSupplierKey
  | SetSuppliersQueryString
  | UpdateSupplierModel
  | UpdateSupplierDetailsModel
  | FetchSupplierDetails
  | FetchSupplierDetailsError
  | FetchSupplierPaymentConfigs
  | FetchSupplierPaymentConfigsError
  | FetchSupplierMarketplaces
  | FetchSupplierMarketplacesError
  | CreateSupplierPaymentConfigs
  | CreateSupplierPaymentConfigsError
  | DeleteSupplierPaymentConfigs
  | UpdateSupplierPaymentConfigs
  | ToggleHideSupplier
  | ToggleHideSupplierSuccess
  | ToggleHideSupplierError
  | ToggleSuspendSupplier
  | ToggleSuspendSupplierSuccess
  | ToggleSuspendSupplierError
  | ToggleLockSupplier
  | ToggleLockSupplierSuccess
  | ToggleLockSupplierError
  | AddOptInRow
  | AddOptInRowSuccess
  | AddOptInRowError
  | AddOptInRowClear
  | DeleteOptInRow
  | DeleteOptInRowSuccess
  | DeleteOptInRowError
  | UpdateModelPart
  | UpdateModelPartSuccess
  | UpdateModelPartMaps
  | UpdateModelPartError
  | UnloadSupplier
  | RequestEfsCard
  | FetchSupplierMarketplaceDetailsPart
  | FetchSupplierMarketplaceDetailsPartError
  | FetchComplianceActionsWithFiles
  | PaymentConfigSuccess
  | PaymentConfigError
  | PaymentConfigClearStatus
  | AddPaymentGateway
  | FetchSupplierMarketplace
  | FetchSupplierMarketplaceSuccess
  | FetchSupplierMarketplaceError;
