import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { TOOLS_THEME } from '../../../../shared/editor/editor.enum';

@Component({
  selector: 'app-email-config',
  templateUrl: './email-config.component.html',
})
export class EmailConfigComponent implements OnInit {
  readonly TOOLS_THEME = TOOLS_THEME;
  public emailConfigForm: FormGroup;

  @Input() configValue;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.configValue) {
      this.createEditForm();
    } else {
      this.createNewForm();
    }
  }

  createNewForm() {
    this.emailConfigForm = this.formBuilder.group({
      template_name: [''],
      description: [''],
      subject: [''],
      source: [''],
      to: [''],
      html: [''],
      text: [''],
    });
  }

  createEditForm() {
    this.emailConfigForm = this.formBuilder.group({
      template_name: this.configValue.template_name,
      description: this.configValue.description,
      subject: this.configValue.subject,
      source: this.configValue.source,
      to: this.configValue.to,
      html: this.configValue.html,
      text: this.configValue.text,
    });
  }
}
