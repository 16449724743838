import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { ThemeOnboardingModel } from '../../../../shared/onboarding/onboarding.model';
import { AppState } from '../../../../store/app.reducers';

enum DefaultInputValue {
  efsRebatePercent = 2,
  minPaymentAmount = 0.01,
}

@Component({
  selector: 'app-onboarding-payment-options',
  templateUrl: './onboarding-payment-options.component.html',
  styleUrls: ['./onboarding-payment-options.component.scss'],
})
export class OnboardingPaymentOptionsComponent extends SubscriberComponent implements OnInit, OnChanges {
  @Input() templateData: ThemeOnboardingModel;

  @Output() formReady = new EventEmitter<FormGroup>();

  public paymentOptionsForm: FormGroup;

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.formReady.emit(this.paymentOptionsForm);
    this.subscriptions.push(
      this.store.select('onboarding').subscribe((result) => {
        if (result.resetTimestamp.length > 1) {
          this.paymentOptionsForm.reset({
            wirePayment: false,
            achPayment: false,
            sendTransferEmail: false,
            efsRebatePercent: [DefaultInputValue.efsRebatePercent],
            minPaymentAmount: [DefaultInputValue.minPaymentAmount],
          });
        }
      }),
    );
  }

  private createForm(): void {
    this.paymentOptionsForm = this.fb.group({
      wirePayment: false,
      achPayment: false,
      sendTransferEmail: false,
      efsRebatePercent: [DefaultInputValue.efsRebatePercent],
      minPaymentAmount: [DefaultInputValue.minPaymentAmount],
    });
  }

  private patchForm(template: ThemeOnboardingModel): void {
    this.paymentOptionsForm.patchValue(
      {
        wirePayment: template.wirAvailable,
        achPayment: template.achAvailable,
        sendTransferEmail: template.sendTransferEmail,
        efsRebatePercent: template.efsRebatePercent,
        minPaymentAmount: template.minPaymentAmount,
      },
      { onlySelf: true, emitEvent: true },
    );
  }

  ngOnChanges(changes) {
    if (changes.templateData && !changes.templateData.firstChange) {
      this.patchForm(this.templateData);
    }
  }
}
