<mat-card class="productInstantAdvance__matCard">
  <div class="productInstantAdvance__matCard__title">
    <h5>Instant Advance</h5>
    <div>
      <p [ngStyle]="{ color: productStatusColor }">{{ productStatus | uppercase }}</p>
    </div>
  </div>
  <mat-card-content>
    <mat-table *ngIf="dataSource.data.length" class="productInstantAdvance__matCard__table" [dataSource]="dataSource">
      <ng-container matColumnDef="advanceStatus">
        <mat-header-cell *matHeaderCellDef>Advance Status</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.advanceStatus | capitalize }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="originationDate">
        <mat-header-cell *matHeaderCellDef>Origination Date</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.originationDate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="weeksSinceStart">
        <mat-header-cell *matHeaderCellDef>Weeks Since Start</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.weeksSinceStart }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="maxPaybackTerm">
        <mat-header-cell *matHeaderCellDef>Maximum Payback Term</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.maxPaybackTerm }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paybackAmount">
        <mat-header-cell *matHeaderCellDef>Payback Amount</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.paybackAmount }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amountCollected">
        <mat-header-cell *matHeaderCellDef>Amount Collected</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.amountCollected }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="outstandingBalance">
        <mat-header-cell *matHeaderCellDef>Outstanding Balance</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.outstandingBalance }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="feeReserve">
        <mat-header-cell *matHeaderCellDef>Fee Reserve</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.feeReserve }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="marketplace">
        <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantAdvance__matCard__table__cell">
          {{ element.marketplace }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="serviceColumns" class="productInstantAdvance__matCard__table__headerRow"></mat-header-row>
      <mat-row *matRowDef="let row; columns: serviceColumns" class="productInstantAdvance__matCard__table__row"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
