<div class="page-auth pyb-page-login">
  <div class="main-body">
    <div class="card card-white">
      <div class="card-content">
        <div *ngIf="loading">
          <app-spinner [forceShow]="true"></app-spinner>
        </div>

        <div class="pyb-logo-background">Payability</div>

        <app-google-auth-login (googleSignInSuccess)="onGoogleSignInSuccess($event)" (googleSignInFailure)="onGoogleSignInFailure()">
        </app-google-auth-login>

        <div class="or-divider"><span>or</span></div>

        <form
          name="material_login_form"
          class="mat-form-auth form-validation"
          (ngSubmit)="login()"
          [formGroup]="loginForm"
          novalidate
          data-test="loginForm"
        >
          <fieldset>
            <div class="form-group">
              <mat-form-field class="full-width mat-icon-left">
                <mat-icon class="material-icons">mail_outline</mat-icon>
                <input matInput placeholder="Email" formControlName="username" data-test="loginFormUsername" />
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width mat-icon-left">
                <mat-icon class="material-icons">lock_outline</mat-icon>
                <input matInput type="password" placeholder="Password" formControlName="password" data-test="loginFormPassword" />
              </mat-form-field>
            </div>

            <button [disabled]="loading || loginForm.invalid" mat-raised-button type="submit" color="primary" class="float-right">
              Login
            </button>
          </fieldset>
        </form>

        <div class="additional-info">
          <span *ngIf="feVersion" class="pull-left"> F: {{ feVersion }} </span>
          <a [routerLink]="['/reset-password']">Forgot your password?</a>
          <span *ngIf="beVersion" class="pull-right"> B: {{ beVersion }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
