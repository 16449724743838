import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';

import { InlineEditBaseComponent } from '../base/inline-edit-base.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditDateComponent),
  multi: true,
};

@Component({
  selector: 'app-inline-edit-date',
  styleUrls: ['../base/inline-edit-base.component.scss', './inline-edit-date.component.scss'],
  templateUrl: './inline-edit-date.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineEditDateComponent extends InlineEditBaseComponent implements OnInit, OnDestroy {
  @Input() label = '';
  @Input() type;
  dateControl: FormControl;

  private readonly subscriptions: Subscription[] = [];
  private readonly DATE_FORMAT = 'MM/DD/YYYY';

  ngOnInit(): void {
    super.ngOnInit();
    this.setupDateInput();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  protected onFormGroupChange(): void {
    super.onFormGroupChange();
    this.setupDateInput();
  }

  private parseDate(date: string): moment.Moment {
    return moment(date, this.DATE_FORMAT);
  }

  private setupDateInput(): void {
    const date = this.parseDate(this.formControl.value).toISOString();
    this.dateControl = new FormControl(date);

    this.subscriptions.push(
      this.dateControl.valueChanges.subscribe((newDate) => {
        this.formControl.setValue(this.parseDate(newDate).format(this.DATE_FORMAT));
      }),
    );
  }
}
