import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-action-field',
  templateUrl: './action-field.component.html',
  styleUrls: ['./action-field.component.scss'],
})
export class ActionFieldComponent implements OnInit {
  @Input() actionField: any;
  @Output() updateValue = new EventEmitter<any>();

  public shouldRenderSelect: boolean;

  private touched = false;
  private value = '';

  inputFormControl = new FormControl();
  selectFormControl = new FormControl();

  constructor() {}

  ngOnInit() {
    this.shouldRenderSelect = this.isSelect();
    this.setValidators();
  }

  isSelect(): boolean {
    return this.actionField.choices.length > 0;
  }

  fieldValueChange(val: any) {
    this.touched = true;
    this.value = val;
    const eventData = {};
    eventData[this.actionField.name] = val;
    this.updateValue.emit(eventData);
  }

  selectChange(event) {
    this.fieldValueChange(event.value);
  }

  inputChange(event) {
    this.fieldValueChange(event.target.value);
  }

  setValidators() {
    if (this.actionField.required) {
      if (this.isSelect()) {
        this.selectFormControl.setValidators(Validators.required);
      } else {
        this.inputFormControl.setValidators(Validators.required);
      }
    }
  }
}
