import { SelectionModel } from '@angular/cdk/collections';
import { PlatformLocation } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { DIALOG_SIZE } from '../../../../shared/dialog.config';
import { Logger } from '../../../../shared/logger';
import { TaskModel } from '../../../models/task.model';
import { TasksService } from '../../../services/tasks.service';
import { taskPriorities } from '../../task-priorities';
import { TaskStatuses, taskStatuses } from '../../task-statuses';

@Component({
  selector: 'app-task-list-actions',
  templateUrl: './task-list-actions.component.html',
  styleUrls: ['./task-list-actions.component.scss'],
})
export class TaskListActionsComponent extends SubscriberComponent {
  @Output() readonly onAction = new EventEmitter();
  @Input() selection: SelectionModel<TaskModel> = { selected: [] } as any;
  availableStatuses = taskStatuses;
  availablePriorities = taskPriorities;
  dueDate;
  showCalendar = false;
  TaskStatuses = TaskStatuses;
  showAsigneePicker = false;
  // Email to prevent sentry errors when no email
  private universalEmail = 'pyb-admin@payability.com';

  get taskIds() {
    return this.selection.selected.map((task) => task.uuid);
  }

  constructor(
    private tasksService: TasksService,
    private platformLocation: PlatformLocation,
    private dialog: MatDialog,
    private toastrService: ToastrService,
  ) {
    super();
  }

  changeStatus(key): void {
    this.subscriptions.push(this.tasksService.batchUpdateTasks(this.selection.selected, { status: key }).subscribe(this.onAction));
  }

  changePrio(key): void {
    this.subscriptions.push(this.tasksService.batchUpdateTasks(this.selection.selected, { priority: key }).subscribe(this.onAction));
  }

  changeDate(date): void {
    this.dueDate = date;
    this.subscriptions.push(
      this.tasksService.batchUpdateTasks(this.selection.selected, { dueDate: moment(date).unix() }).subscribe(this.onAction),
    );
    this.showCalendar = false;
  }

  changeAssignee(event): void {
    const assignee = event.source.value;
    if (isNullOrUndefined(assignee.email)) {
      assignee.email = this.universalEmail;
    }
    this.subscriptions.push(
      this.tasksService
        .batchUpdateTasks(this.selection.selected, {
          assigneeUuid: assignee.key,
          assigneeName: assignee.name,
          assigneeEmail: assignee.email,
          assigneeType: assignee.type,
        })
        .subscribe(this.onAction),
    );
    this.showAsigneePicker = false;
  }

  getEditUrls(): string {
    return this.taskIds.map((id) => this.tasksService.getAbsoluteUrl((<any>this.platformLocation).location.origin, id)).join('\n');
  }

  deleteTasks(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: `Do you want to delete selected tasks? (${this.taskIds.length})` },
      width: DIALOG_SIZE.SMALL.width,
    });
    this.subscriptions.push(
      dialogRef
        .afterClosed()
        .pipe(
          filter(Boolean),
          switchMap(() => this.tasksService.batchDeleteTasks(this.taskIds)),
          catchError((error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 403) {
                this.toastrService.error('Access denied!. You need owner or super user permissions to access data\n');
              } else {
                Logger.log(error);
              }
            }

            return observableThrowError(error);
          }),
        )
        .subscribe(this.onAction),
    );
  }

  handleTaskUrlCopy($event): void {
    this.toastrService.success('Task links copied to clipboard.');

    $event.preventDefault();
    $event.stopPropagation();
  }

  toogleCalendar(): void {
    this.showCalendar = !this.showCalendar;
    this.showAsigneePicker = false;
  }

  toogleAssignee(): void {
    this.showCalendar = false;
    this.showAsigneePicker = !this.showAsigneePicker;
  }
}
