<section class="card">
  <div class="card-content">
    <app-documentation-card-header [reportTitle]="reportTitle" [supplierKey]="supplierKey" [owner]="owner"> </app-documentation-card-header>
    <div class="d-flex justify-content-between mt-4">
      <div class="w-75 mr-3">
        <ng-container *ngIf="!requestsLoading; else spinner">
          <ng-container *ngIf="_availableSections.length > 0; else noSectionsForThisPerson">
            <ng-container *ngIf="filteredReportsToShow && filteredReportsToShow.length; else noSectionSelected">
              <ng-container *ngFor="let report of filteredReportsToShow; trackBy: trackBy">
                <app-documentation-report [report]="report"></app-documentation-report>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="w-25 ml-2 pl-5 filterWrapper">
        <app-checkbox-filters [options]="filterOptions" (selectionChange)="filtersSelectionChange($event)"> </app-checkbox-filters>
      </div>
    </div>
  </div>
</section>

<ng-template #noSectionSelected>
  <app-no-data [text]="selectReportText"></app-no-data>
</ng-template>

<ng-template #noSectionsForThisPerson>
  <app-no-data [text]="noSectionsAvailableText"></app-no-data>
</ng-template>

<ng-template #spinner>
  <div class="spinnerWrapper">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>
