import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { BaseSupplierAction } from '../../../suppliers/supplier/base-supplier-action/base-supplier-action';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { FeeSettingsService } from './../service/fee-settings.service';

@Component({
  selector: 'app-fee-settings-delete-component',
  templateUrl: './fee-settings-delete.component.html',
})
export class FeeSettingsDeleteComponent extends SubscriberComponent implements OnInit {
  confirmationMessage = '';
  isLoadingResult = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BaseSupplierAction>,
    private feeSettingsService: FeeSettingsService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.isEditable) {
      this.confirmationMessage = 'Are you sure you want to delete item? This can break functionality in other places.';
    } else {
      this.confirmationMessage = 'Cannot delete Fee Schedule. Fee Schedule is currently in use for existing suppliers.';
    }
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  submit() {
    this.isLoadingResult = true;

    this.subscriptions.push(
      this.feeSettingsService.deleteFeeSchedule(this.data.feeScheduleKey).subscribe(
        () => {
          this.toastrService.success('Fee schedule successfully deleted');
          this.closeDialog(true);
        },
        (error) => {
          this.toastrService.error(error.error.message);
          this.closeDialog(false);
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }
}
