export default {
  menubar: false,
  height: 200,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor noneditable',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ],
  toolbar: 'undo redo | formatselect | bold italic backcolor | \
    alignleft aligncenter alignright alignjustify',
  verify_html: false,
  paste_data_images: true,
  forced_root_block: 'p',
  noneditable_noneditable_class: 'is-locked',
};
