import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { isNullOrUndefined, isUndefined } from 'util';

import { DIALOG_SIZE } from '../../../../../shared/dialog.config';
import Logger from '../../../../../shared/logger';
import { LookupAbstractService } from '../../../../../shared/lookup-service/lookup-abstract.service';
import { ReasonDialogComponent } from '../../../../../shared/reason-dialog/reason-dialog.component';
import { ErrorVisualizationService } from '../../../../../shared/services/error-visualization/error-visualization.service';
import { AppState } from '../../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../../model/supplier.model';
import { SupplierMarketplaceDetailsModel } from '../../../../model/supplier-marketplace.model';
import { SupplierMarketplaceOptInPeriodModel } from '../../../../model/supplier-marketplace-optin-period.model';
import { SupplierMarketplacesService } from '../../../../services/supplier-marketplaces.service';
import {
  AddOptInRow,
  AddOptInRowClear,
  DeleteOptInRow,
  selectSupplierMarketplacesOptInPeriods,
} from '../../../../store/supplier/supplier.actions';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from '../../../supplier-onboarding/supplier-onboarding.service';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-opt-in-table',
  templateUrl: './opt-in-table.component.html',
  styleUrls: ['./opt-in-table.component.scss'],
})
export class OptInTableComponent extends SubscriberComponent implements OnInit {
  @Input() mpSupKey: string;

  supplierDetailsModel: SupplierDetailsModel;
  marketplaceDetailsModel: SupplierMarketplaceDetailsModel;

  optInPeriods: SupplierMarketplaceOptInPeriodModel[];

  showAddRow = false;
  addInProgress = false;

  isNotApproved: boolean;

  public reasonsCodeDomain = 'optin.AddDel';
  public reasons: Observable<any[]>;

  addOptInPeriodForm: FormGroup;

  private SUPPLIER_SUSPEND_STATUS = 'Suspended';
  private mpSuspendCodeDomain = 'susphist.Reason';
  private mpSuspendReasons: Observable<any[]>;

  public isSuspended: boolean;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private lookupService: LookupAbstractService,
    private marketplaceService: SupplierMarketplacesService,
    private errorVisualizationService: ErrorVisualizationService,
  ) {
    super();
  }

  ngOnInit() {
    this.reasons = this.lookupService.getCodes(this.reasonsCodeDomain);
    this.mpSuspendReasons = this.lookupService.getCodes(this.mpSuspendCodeDomain);

    this.subscriptions.push(
      this.store.select(selectSupplierMarketplacesOptInPeriods).subscribe((result) => {
        if (!isUndefined(result) && !isUndefined(result.supplierDetailsModel) && !isUndefined(result.supplierMarketplacesModels)) {
          const optInPeriodModels: { [mpSupKey: string]: SupplierMarketplaceOptInPeriodModel[] } = result.optInPeriodModels;
          const marketplaceDetailsModels: SupplierMarketplaceDetailsModel[] = result.supplierMarketplacesModels;

          this.supplierDetailsModel = result.supplierDetailsModel;
          this.marketplaceDetailsModel = this.getMarketplace(marketplaceDetailsModels, this.mpSupKey);
          if (this.marketplaceDetailsModel.isNotApproved) {
            this.isNotApproved = !!this.marketplaceDetailsModel.isNotApproved;
          }

          this.isSuspended = this.supplierDetailsModel.status.supplierStatus === this.SUPPLIER_SUSPEND_STATUS;

          if (optInPeriodModels && this.marketplaceDetailsModel) {
            const neededModel = !isUndefined(optInPeriodModels[this.marketplaceDetailsModel.mpSupKey])
              ? optInPeriodModels[this.marketplaceDetailsModel.mpSupKey]
              : undefined;

            if (!isUndefined(neededModel)) {
              this.optInPeriods = neededModel;
            }
          }
        }
      }),
    );

    this.subscriptions.push(
      this.store.select('supplier').subscribe((supplierState) => {
        if (this.addInProgress && supplierState.addOptInProgress === false) {
          if (!isUndefined(supplierState.addOptInError)) {
            const response = supplierState.addOptInError;
            let message = 'Error during add operation';

            if (!isUndefined(response.error)) {
              try {
                const parsedError = JSON.parse(response.error);
                message = parsedError.message;
              } catch (error) {
                Logger.log(error);
              }
            }
            this.toastrService.error(message);
          } else {
            this.toastrService.success('Added successfully');
            this.showAddRow = false;
            this.createForm();
          }

          this.store.dispatch(new AddOptInRowClear());
          this.addInProgress = false;
        }
      }),
    );

    this.createForm();
  }

  getMarketplace(marketplacesList: SupplierMarketplaceDetailsModel[], supplierMpKey: string): SupplierMarketplaceDetailsModel {
    const marketplacesModel: SupplierMarketplaceDetailsModel = marketplacesList.find((item: any) => {
      return item.mpSupKey === supplierMpKey ? item : undefined;
    });

    if (marketplacesModel) {
      return marketplacesModel;
    } else {
      throw Error('Supplier marketplace not found');
    }
  }

  createForm() {
    this.addOptInPeriodForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: [moment('9999-12-31').toDate(), Validators.required],
    });
  }

  addOptInRow() {
    const valid = this.addOptInPeriodForm.valid;
    const value = this.addOptInPeriodForm.getRawValue();

    if (value.startDate) {
      value.startDate = moment(value.startDate).format('MM/DD/YYYY');
    }

    if (value.endDate) {
      value.endDate = moment(value.endDate).format('MM/DD/YYYY');
    }

    if (valid) {
      const dialogRef = this.dialog.open(ReasonDialogComponent, {
        data: {
          reasons: this.reasons,
          warning: 'Caution! This action will change supplier status and may affect supplier workflow.',
        },
        width: DIALOG_SIZE.RWD_60.width,
      });

      this.subscriptions.push(
        dialogRef.afterClosed().subscribe((result) => {
          if (!isNullOrUndefined(result)) {
            this.addInProgress = true;

            value['reasonCode'] = result.reasonCode;
            value['reasonNote'] = result.reasonNote;

            this.store.dispatch(
              new AddOptInRow({
                supplierDetailsModel: this.supplierDetailsModel,
                marketplaceDetailsModel: this.marketplaceDetailsModel,
                data: value,
              }),
            );
          }
        }),
      );
    } else {
      throw new Error('INVALID FORM GROUP');
    }
  }

  deleteOptInPeriod(optInPeriodModel: SupplierMarketplaceOptInPeriodModel) {
    this.isNotApproved = false;
    this.marketplaceDetailsModel.isNotApproved = this.isNotApproved;

    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      data: {
        reasons: this.reasons,
        warning: 'Caution! This action will change supplier status and may affect supplier workflow.',
      },
      width: DIALOG_SIZE.RWD_60.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (!isNullOrUndefined(result)) {
          this.store.dispatch(
            new DeleteOptInRow({
              supplierDetailsModel: this.supplierDetailsModel,
              marketplaceDetailsModel: this.marketplaceDetailsModel,
              optInPeriodKey: optInPeriodModel.mpSupOptInPeriodKey,
              reason: result,
            }),
          );
        }
      }),
    );
  }

  public checkIfAmazon() {
    return this.marketplaceDetailsModel.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY;
  }

  public toggleMarketplaceSuspension() {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      data: {
        reasons: this.mpSuspendReasons,
        warning: '',
      },
      width: DIALOG_SIZE.RWD_60.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (!isNullOrUndefined(result)) {
          const dataToSave = {
            mp_sup_key: this.mpSupKey,
            mp_susp_reason_code: result.reasonCode,
            mp_susp_reason_note: result.reasonNote,
          };
          this.subscriptions.push(
            this.marketplaceService.saveMarketplaceSuspendReason(this.supplierDetailsModel.supplierKey, dataToSave).subscribe(
              (response) => {
                this.toastrService.success('Suspension reason added');
              },
              (err) => {
                this.toastrService.error(err.error.message);
              },
            ),
          );
        }
      }),
    );
  }

  public onIsNotApprovedChange(isNotApproved: boolean) {
    this.subscriptions.push(
      this.marketplaceService.saveMarketplaceDetails(this.supplierDetailsModel.supplierKey, this.mpSupKey, { isNotApproved }).subscribe(
        () => {
          this.toastrService.success('Not Yet Approved changed');
        },
        (err) => {
          this.errorVisualizationService.showError({ name: 'Not Yet Approved could not been changed', errorObject: err });
        },
      ),
    );
  }
}
