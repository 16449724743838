import { autoserialize } from 'cerialize';

export class DocumentModel {
  @autoserialize public docType: string;
  @autoserialize public docTypeUuid: string;
  @autoserialize public name: string;
  @autoserialize public thumbnails: string[];
  @autoserialize public uuid: string;

  constructor(docType: string, docTypeUuid: string, name: string, thumbnails: string[], uuid: string) {
    this.docType = docType;
    this.docTypeUuid = docTypeUuid;
    this.name = name;
    this.thumbnails = thumbnails;
    this.uuid = uuid;
  }

  public static OnDeserialized(instance: DocumentModel, json: any): void {
    instance.docType = json.docType;
    instance.docTypeUuid = json.docTypeUuid;
    instance.name = json.name;
    instance.thumbnails = json.thumbnails;
    instance.uuid = json.uuid;
  }
}
