import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { DIALOG_SIZE } from '../../../../shared/dialog.config';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from './../../../../shared/confirm-dialog/confirm-dialog.component';
import { LookupAbstractService } from './../../../../shared/lookup-service/lookup-abstract.service';
import { AppState } from './../../../../store/app.reducers';
import { PaymentFeeModel } from './../model/payment-fee.model';
import { SupplierFeesService } from './../service/supplier-fees.service';

@Component({
  selector: 'app-payment-fees',
  templateUrl: './payment-fees.component.html',
  styleUrls: ['./payment-fees.component.scss'],
})
export class PaymentFeesComponent extends SubscriberComponent implements OnInit {
  columnsToDisplay = [
    'feeScheduleType',
    'feeScheduleName',
    'feeScheduleTypeCode',
    'feeDelivery',
    'feePercentage',
    'minPmtFee',
    'fixedFee',
    'action',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  feeSchedule: any;
  feeSchedules: Array<any> = [];
  feeScheduleTypes: Set<any>;
  paymentFees: Array<PaymentFeeModel> = new Array<PaymentFeeModel>();
  isLoadingResult = false;

  private supplierKey: string;

  constructor(
    private store: Store<AppState>,
    private lookupRepo: LookupAbstractService,
    private toastrService: ToastrService,
    private matDialog: MatDialog,
    private supplierFeesService: SupplierFeesService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetails) => {
        if (supplierDetails != null) {
          this.supplierKey = supplierDetails.supplierKey;
        }
      }),
    );

    this.subscriptions.push(
      this.lookupRepo.getFeeSchedules().subscribe((feeSchedules) => {
        this.feeSchedules = feeSchedules.filter((feeSchedule) => feeSchedule.feeScheduleCategory === 'PAYMENT');
        this.feeScheduleTypes = new Set(this.feeSchedules.map((feeSchedule) => feeSchedule.feeScheduleType));

        this.subscriptions.push(
          this.supplierFeesService.getOverallFundingParticulars(this.supplierKey).subscribe((fundingParticulars: any) => {
            const paymentFee = new PaymentFeeModel();
            const tiers = new Array<PaymentFeeModel>();

            if (fundingParticulars != null && fundingParticulars.pmtFeeSchedule != null) {
              if (fundingParticulars.pmtFeeSchedule.pmtFeeScheduleKey != null) {
                paymentFee.feeScheduleKey = fundingParticulars.pmtFeeSchedule.pmtFeeScheduleKey;

                const feeSchedule = this.feeSchedules.filter(
                  (feeScheduleData) => feeScheduleData.feeScheduleKey === paymentFee.feeScheduleKey,
                )[0];

                paymentFee.feeScheduleType = feeSchedule.feeScheduleType;
                this.setFeeScheduleNames(paymentFee);
                paymentFee.feeScheduleName = feeSchedule.feeScheduleName;
                paymentFee.feeScheduleDelivery = feeSchedule.feeScheduleTiers[0].pmtBatchFeeName;
                paymentFee.paymentTypeCode = feeSchedule.feeScheduleTiers[0].paymentTypeCode;
                paymentFee.tierStart = feeSchedule.feeScheduleTiers[0].tierStart;
                paymentFee.tierEnd = feeSchedule.feeScheduleTiers[0].tierEnd;
                paymentFee.feePercentage = feeSchedule.feeScheduleTiers[0].feePercent;
                paymentFee.minPmtFee = feeSchedule.feeScheduleTiers[0].minPmtFee;
                paymentFee.fixedFee = feeSchedule.feeScheduleTiers[0].feeFixed;

                if (feeSchedule.feeScheduleTiers.length > 1) {
                  feeSchedule.feeScheduleTiers.forEach((feeScheduleTier) => {
                    const tier = new PaymentFeeModel();

                    tier.isTier = true;
                    tier.feeScheduleDelivery = feeScheduleTier.pmtBatchFeeName;
                    tier.paymentTypeCode = feeScheduleTier.paymentTypeCode;
                    tier.tierStart = feeScheduleTier.tierStart;
                    tier.tierEnd = feeScheduleTier.tierEnd;
                    tier.feePercentage = feeScheduleTier.feePercent;
                    tier.minPmtFee = feeScheduleTier.minPmtFee;
                    tier.fixedFee = feeScheduleTier.feeFixed;

                    tiers.push(tier);
                  });
                  tiers.shift();
                }
              } else {
                paymentFee.feeScheduleDelivery = '';
                paymentFee.tierStart = '';
                paymentFee.tierEnd = '';
                paymentFee.feePercentage = '';
                paymentFee.minPmtFee = '';
                paymentFee.fixedFee = '';
              }
            }

            this.paymentFees.push(paymentFee);

            tiers.forEach((tier) => {
              this.paymentFees.push(tier);
            });

            this.dataSource = new MatTableDataSource(this.paymentFees);
          }),
        );
      }),
    );
  }

  openConfirmationDialog(feeScheduleKey: string, feeScheduleName: string) {
    const message = 'Are you sure you want to save this fee schedule in a database?';

    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: { message },
      width: DIALOG_SIZE.SMALL.width,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.savePaymentFee(feeScheduleKey);
        }
      }),
    );
  }

  setFeeScheduleForSelectedName(selectedFeeScheduleName: string, purchaseFee: PaymentFeeModel) {
    const paymentFees = new Array<PaymentFeeModel>();
    const tiers = new Array<PaymentFeeModel>();
    const feeSchedule = this.feeSchedules.filter((feeScheduleData) => feeScheduleData.feeScheduleName === selectedFeeScheduleName)[0];

    purchaseFee.feeScheduleDelivery = feeSchedule.feeScheduleTiers[0].pmtBatchFeeName;
    purchaseFee.paymentTypeCode = feeSchedule.feeScheduleTiers[0].paymentTypeCode;
    purchaseFee.feeScheduleKey = feeSchedule.feeScheduleKey;
    purchaseFee.tierStart = feeSchedule.feeScheduleTiers[0].tierStart;
    purchaseFee.tierEnd = feeSchedule.feeScheduleTiers[0].tierEnd;
    purchaseFee.feePercentage = feeSchedule.feeScheduleTiers[0].feePercent;
    purchaseFee.minPmtFee = feeSchedule.feeScheduleTiers[0].minPmtFee;
    purchaseFee.fixedFee = feeSchedule.feeScheduleTiers[0].feeFixed;

    paymentFees.push(purchaseFee);

    if (feeSchedule.feeScheduleTiers.length > 1) {
      feeSchedule.feeScheduleTiers.forEach((feeScheduleTier) => {
        const tier = new PaymentFeeModel();

        tier.isTier = true;
        tier.feeScheduleDelivery = feeScheduleTier.pmtBatchFeeName;
        tier.paymentTypeCode = feeScheduleTier.paymentTypeCode;
        tier.tierStart = feeScheduleTier.tierStart;
        tier.tierEnd = feeScheduleTier.tierEnd;
        tier.feePercentage = feeScheduleTier.feePercent;
        tier.minPmtFee = feeScheduleTier.minPmtFee;
        tier.fixedFee = feeScheduleTier.feeFixed;

        tiers.push(tier);
      });
      tiers.shift();
      tiers.forEach((tier) => {
        paymentFees.push(tier);
      });
      this.dataSource = new MatTableDataSource(paymentFees);
    }
  }

  setFeeScheduleNames(fee: PaymentFeeModel) {
    fee.feeScheduleNames = this.feeSchedules
      .filter((feeSchedule) => feeSchedule.feeScheduleType === fee.feeScheduleType)
      .map((feeSchedule) => feeSchedule.feeScheduleName);
  }

  toggleEditMode(purchaseFee: PaymentFeeModel) {
    purchaseFee.isEditable = !purchaseFee.isEditable;
  }

  private savePaymentFee(feeScheduleKey: string) {
    const data: any = {};
    this.isLoadingResult = true;
    data.pmtFeeScheduleKey = feeScheduleKey;

    this.subscriptions.push(
      this.supplierFeesService.setOverallFundingParticulars(this.supplierKey, data).subscribe(
        () => {
          this.toastrService.success('Fee schedule saved successfully.');
        },
        (error) => {
          this.toastrService.error(error.error.message);
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }
}
