import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Serialize, SerializeKeysTo } from 'cerialize';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { AppConfigService } from '../../config';
import {
  SupplierPaymentBatchAction,
  SupplierPaymentBatchModel,
  SupplierPaymentNewBatchModel,
  SupplierPaymentWorkflowStatusModel,
} from '../model/supplier-payment-batch-model';
import { SupplierPaymentBatchListModel } from './../model/supplier-payment-batch-list-model';
import { PaymentBatchesAbstractService } from './payment-batches-abstract-service';

@Injectable()
export class PaymentBatchRepositoryService extends PaymentBatchesAbstractService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient, private toastrService: ToastrService) {
    super();

    this.apiUrl = appConfig.env.internalApiUrl;
  }

  public getItems(httpParams: HttpParams): Observable<any> {
    const endpointUrl = `${this.apiUrl}paymentBatches`;

    return this.httpClient.get<SupplierPaymentBatchListModel[]>(endpointUrl, { params: httpParams }).pipe(
      map((data) => {
        const items = (<any>data).results.map((item) => {
          if (item.paymentTypeCounts && item.paymentTypeCounts.length > 0) {
            let payments = '';
            for (let i = 0; i < item.paymentTypeCounts.length; i++) {
              payments += item.paymentTypeCounts[i].paymentType + ', ';
            }

            item.paymentTypes = payments.substring(0, payments.length - 2);
          }
          return item;
        });

        return { results: items, total: (<any>data).total };
      }),
      catchError((error) => {
        this.toastrService.error('Cannot fetch Payment Batches');
        return observableThrowError(error);
      }),
    );
  }

  public saveItem(uuid: string, item: SupplierPaymentBatchModel): Observable<SupplierPaymentBatchModel> {
    // [SM] TODO
    return null;
  }

  public getBatch(batchId: string): Observable<SupplierPaymentBatchModel> {
    const endpointUrl = `${this.apiUrl}paymentBatches/${batchId}`;

    return this.httpClient.get<SupplierPaymentBatchModel>(endpointUrl).pipe(
      catchError((error) => {
        this.toastrService.error('Cannot fetch Payment Batches');
        return observableThrowError(error);
      }),
    );
  }

  public addBatch(data: SupplierPaymentNewBatchModel): Observable<any> {
    const endpointUrl = `${this.apiUrl}paymentBatches`;

    SerializeKeysTo(CamelCase);

    return this.httpClient.post<SupplierPaymentNewBatchModel>(endpointUrl, Serialize(data, SupplierPaymentNewBatchModel)).pipe(
      catchError((error) => {
        this.toastrService.error('Cannot store Payment Batch');
        return observableThrowError(error);
      }),
    );
  }

  public updateBatch(batchId: string, data: SupplierPaymentBatchModel): Observable<any> {
    const endpointUrl = `${this.apiUrl}paymentBatches/${batchId}`;

    SerializeKeysTo(CamelCase);

    return this.httpClient.put<SupplierPaymentBatchModel>(endpointUrl, Serialize(data, SupplierPaymentBatchModel)).pipe(
      catchError((error) => {
        this.toastrService.error('Cannot update Payment Batch');
        return observableThrowError(error);
      }),
    );
  }

  public requestBatchAction(batchId: string, data: SupplierPaymentBatchAction): Observable<any> {
    const endpointUrl = `${this.apiUrl}paymentBatches/${batchId}/action`;

    SerializeKeysTo(CamelCase);

    return this.httpClient.post(endpointUrl, Serialize(data, SupplierPaymentBatchAction)).pipe(
      catchError((error) => {
        this.toastrService.error('Cannot make Payment Batch Action');
        return observableThrowError(error);
      }),
    );
  }

  public unlock(batchId: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}paymentBatches/${batchId}/unlock`;
    return this.httpClient.post(endpointUrl, {}).pipe(
      catchError((error) => {
        this.toastrService.error('Cannot unlock Payment Batch');
        return observableThrowError(error);
      }),
    );
  }

  public getPayments(batchId: string, httpParams: HttpParams, isDebit: boolean): Observable<any> {
    // eslint-disable-next-line max-len
    const endpointUrl = isDebit
      ? `${this.apiUrl}paymentBatches/${batchId}/get_debit_payments`
      : `${this.apiUrl}paymentBatches/${batchId}/payments`;

    return this.httpClient.get(endpointUrl, { params: httpParams }).pipe(
      map((data: any) => {
        return {
          results: data.results.map((item) => {
            if (isNullOrUndefined(item.paymentOutcome)) {
              item.paymentOutcome = {};
            }
            if (isNullOrUndefined(item.outcomeReason)) {
              item.outcomeReason = {};
            }

            return item;
          }),
          total: data.total,
        };
      }),
      catchError((error) => {
        this.toastrService.error('Cannot fetch Payment Batches');
        return observableThrowError(error);
      }),
    );
  }

  public updatePayment(batchId: string, paymentId: string, item: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}paymentBatches/${batchId}/payments/${paymentId}`;

    return this.httpClient.put(endpointUrl, item).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        this.toastrService.error('Cannot update Payment');
        return observableThrowError(error);
      }),
    );
  }

  public removePayment(batchId: string, paymentId: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}paymentBatches/${batchId}/payments/${paymentId}`;

    return this.httpClient.delete(endpointUrl).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        this.toastrService.error('Cannot delete Payment');
        return observableThrowError(error);
      }),
    );
  }

  public getStatus(batchId: string): Observable<SupplierPaymentWorkflowStatusModel> {
    const endpointUrl = `${this.apiUrl}paymentBatches/${batchId}/status`;

    return this.httpClient.get<SupplierPaymentWorkflowStatusModel>(endpointUrl).pipe(
      catchError((error) => {
        this.toastrService.error('Cannot fetch Payment status');
        return observableThrowError(error);
      }),
    );
  }
}
