import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pybtris-buttons',
  templateUrl: './pybtris-buttons.component.html',
  styleUrls: ['./pybtris-buttons.component.scss'],
})
export class PybtrisButtonsComponent implements OnInit {
  @Input() gameStarted: boolean;
  @Input() paused: boolean;

  @Output() onPlay = new EventEmitter<any>();
  @Output() onPause = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  play() {
    this.onPlay.emit();
  }

  pause() {
    this.onPause.emit();
  }

  reset() {
    this.onReset.emit();
  }
}
