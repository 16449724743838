import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';

import { LookupAbstractService } from '../../../shared/lookup-service/lookup-abstract.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { FeeSettingsService } from './../service/fee-settings.service';

@Component({
  selector: 'app-fee-settings-edit',
  templateUrl: './fee-settings-edit.component.html',
  styleUrls: ['./../fee-settings.component.scss'],
})
export class FeeSettingsEditComponent extends SubscriberComponent implements OnInit {
  displayedColumns = ['tierStart', 'tierEnd', 'feePercentage', 'fixedFee', 'action'];

  subscription: Subscription;
  feeID: string;
  feeData: any;
  feeSchedules$: Observable<any>;
  feeSchedules: any;
  feeScheduleData: any;
  dataSource: any;
  editForm: FormGroup;
  category: any;
  isEditable: boolean;
  tiers: Array<any>;
  i = 0;

  constructor(
    private route: ActivatedRoute,
    private lookupRepo: LookupAbstractService,
    private formBuilder: FormBuilder,
    private router: Router,
    private service: FeeSettingsService,
    private toastrService: ToastrService,
  ) {
    super();

    this.subscription = this.route.params.subscribe((params) => {
      this.feeID = params['id'];
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.service.getFeeScheduleData().subscribe((data) => {
        this.feeScheduleData = data;
      }),
    );
    this.createForm();
    this.loadData();
  }

  loadData() {
    this.feeSchedules$ = this.lookupRepo.getFeeSchedules();
    this.subscriptions.push(
      this.feeSchedules$.subscribe((result) => {
        this.feeSchedules = result;

        this.feeData = this.feeSchedules.filter((feeSchedules) => feeSchedules.id === this.feeID).map((feeData) => feeData);
        if (this.feeData != null && this.feeData.length > 0) {
          this.category = this.feeData[0].feeScheduleCategory;
          this.isEditable = this.feeData[0].isEditable;
          this.tiers = this.feeData[0].feeScheduleTiers;
          this.dataSource = new MatTableDataSource(this.feeData[0].feeScheduleTiers);
        }

        if (this.category === 'PAYMENT') {
          this.displayedColumns = [
            'paymentTypeCode',
            'pmtBatchFeeName',
            'tierStart',
            'tierEnd',
            'feePercentage',
            'fixedFee',
            'minPmtFee',
            'action',
          ];
        }

        this.updateForm();
      }),
    );
  }

  createForm() {
    this.editForm = new FormGroup({
      category: new FormControl('', []),
      scheduleType: new FormControl('', []),
      type: new FormControl('', []),
      scheduleName: new FormControl('', [Validators.required]),
      name: new FormControl('', []),
    });
    this.editForm.get('category').disable();
    this.editForm.get('scheduleType').disable();
  }

  updateForm() {
    if (this.feeData.length > 0) {
      this.editForm.patchValue({
        category: this.feeData[0].feeScheduleCategory,
        scheduleType: this.feeData[0].feeScheduleType,
        type: this.feeData[0].feeScheduleTiers[0].payment_type_code,
        scheduleName: this.feeData[0].feeScheduleName,
        name: [''],
      });
    }
  }

  addTier() {
    this.tiers.push({});
    this.dataSource = new MatTableDataSource(this.tiers);
  }

  removeTier(index: number): void {
    this.tiers.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.tiers);
  }

  submit(element) {
    const dataToSave = this.editForm.value;

    if (this.category === 'PURCHASE') {
      element.forEach((tier) => {
        tier['paymentTypeCode'] = null;
        tier['pmtBatchFeeCode'] = null;
        tier['pmtBatchFeeName'] = null;
      });
    } else {
      element.forEach((tier) => {
        tier['paymentTypeCode'] = element[this.i].paymentTypeCode;
        tier['pmtBatchFeeName'] = element[this.i].pmtBatchFeeName;
        this.i = this.i + 1;
      });
    }

    if (this.isEditable === false) {
      this.subscriptions.push(
        this.service.editScheduleName(this.feeID, this.feeData[0].feeScheduleTypeCode, dataToSave.scheduleName).subscribe(
          () => {
            this.toastrService.success('Fee Schedule Name saved succesfully');
          },
          (response) => {
            this.toastrService.error(response.error.message);
          },
        ),
      );
    } else {
      this.subscriptions.push(
        forkJoin([
          this.service.editScheduleName(this.feeID, this.feeData[0].feeScheduleTypeCode, dataToSave.scheduleName),
          this.service.editFeeSetting(this.feeID, element),
        ]).subscribe(
          () => {
            this.toastrService.success('Data saved succesfully');
          },
          (response) => {
            this.toastrService.error(response.error.message);
          },
        ),
      );
    }

    this.loadData();
    this.router.navigate(['../../list'], { relativeTo: this.route });
  }

  public onCancel(): void {
    this.router.navigate(['../../list'], { relativeTo: this.route });
  }

  areTiersValid(): boolean {
    let isValid = true;
    if (this.category === 'PURCHASE') {
      this.dataSource.data.forEach((tier) => {
        if (tier.tierStart == null || tier.tierEnd == null || tier.feePercent == null || tier.feeFixed == null) {
          isValid = false;
        }
      });
      return isValid;
    }
    if (this.category === 'PAYMENT') {
      this.dataSource.data.forEach((tier) => {
        if (
          tier.tierStart == null ||
          tier.tierEnd == null ||
          tier.feePercent == null ||
          tier.feeFixed == null ||
          tier.paymentTypeCode == null ||
          tier.pmtBatchFeeName == null ||
          tier.minPmtFee == null
        ) {
          isValid = false;
        }
      });
      return isValid;
    }
  }

  public setDeliveryKey(element, item) {
    element['pmtBatchFeeCode'] = item.key;
  }
}
