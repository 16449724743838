<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>

<div *ngIf="supplierDetailsModel$ | async; else loading; let supplierInfo" class="supplier-unlock-switch">
  <span *ngIf="supplierLocked; else unlockedSupplier">
    <button
      mat-raised-button
      class="btn-w-md btn-w-md"
      (click)="toggleLock(supplierInfo)"
      [disabled]="toggleInProgress"
      [ngClass]="{ 'btn-busy': toggleInProgress }"
      color="primary"
      data-test="unlockButton"
    >
      Unlock
    </button>
  </span>

  <ng-template #unlockedSupplier>
    <button
      mat-raised-button
      color="warn"
      class="btn-w-md"
      [disabled]="toggleInProgress || !requiredDataHasLoaded"
      [ngClass]="{ 'btn-busy': toggleInProgress }"
      (click)="toggleLock(supplierInfo)"
      data-test="lockButton"
    >
      Lock
    </button>
  </ng-template>
</div>
