<ng-template #loading>
  <div class="emails-filter-header">
    <app-loading-spinner [size]="1"></app-loading-spinner>
  </div>
</ng-template>

<div class="emails-filter-header" *ngIf="filters; else loading">
  <form [formGroup]="filters" #formFilters>
    <!-- STATUS -->
    <mat-form-field>
      <mat-select placeholder="Type" aria-label="type" formControlName="type" multiple>
        <mat-option *ngFor="let filterItem of availableFilters | async" [value]="filterItem.key">
          {{ filterItem.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- SUPPLIER NAME -->
    <mat-form-field>
      <input matInput placeholder="Supplier Name" aria-label="supplier-name" [formControl]="supplier_name" />
      <mat-icon
        matSuffix
        [hidden]="!supplier_name.value"
        (click)="clearValue('supplier_name', supplier_name); $event.preventDefault(); $event.stopPropagation()"
        >close
      </mat-icon>
    </mat-form-field>

    <!-- SUPPLIER KEY -->
    <mat-form-field>
      <input matInput placeholder="Supplier Key" aria-label="supplier_key" [formControl]="supplier_key" />
      <mat-icon
        matSuffix
        [hidden]="!supplier_key.value"
        (click)="clearValue('supplier_key', supplier_key); $event.preventDefault(); $event.stopPropagation()"
        >close
      </mat-icon>
    </mat-form-field>

    <!-- SUBJECT -->
    <mat-form-field>
      <input matInput placeholder="Subject" aria-label="Subject" [formControl]="subject" />
      <mat-icon
        matSuffix
        [hidden]="!subject.value"
        (click)="clearValue('subject', subject); $event.preventDefault(); $event.stopPropagation()"
        >close
      </mat-icon>
    </mat-form-field>

    <!-- CREATE AFTER -->
    <mat-form-field class="email-created-after-input">
      <input
        matInput
        placeholder="Created after"
        aria-label="Created after"
        [matDatepicker]="afterPicker"
        [formControl]="createdAfter"
        (click)="afterPicker.open()"
        (dateChange)="setDate('createdAfter', $event)"
      />
      <mat-icon
        matSuffix
        [hidden]="!createdAfter.value"
        (click)="clearDate('createdAfter', createdAfter); $event.preventDefault(); $event.stopPropagation()"
      >
        close
      </mat-icon>
      <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
      <mat-datepicker #afterPicker></mat-datepicker>
    </mat-form-field>

    <!-- CREATE BEFORE -->
    <mat-form-field class="email-created-before-input">
      <input
        matInput
        placeholder="Created before"
        aria-label="Created before"
        [matDatepicker]="beforePicker"
        (click)="beforePicker.open()"
        [formControl]="createdBefore"
        (dateChange)="setDate('createdBefore', $event)"
      />
      <mat-icon
        matSuffix
        [hidden]="!createdBefore.value"
        (click)="clearDate('createdBefore', createdBefore); $event.preventDefault(); $event.stopPropagation()"
      >
        close
      </mat-icon>
      <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
      <mat-datepicker #beforePicker></mat-datepicker>
    </mat-form-field>
  </form>
</div>
