<h1 mat-dialog-title>Upload file</h1>
<div mat-dialog-content>
  <form [formGroup]="fileUploadForm">
    <mat-form-field class="full-width">
      <app-input-file formControlName="file" valuePlaceholder="No file selected" required></app-input-file>
      <mat-icon matSuffix>folder</mat-icon>
    </mat-form-field>
    <div ngxErrors="file">
      <mat-error ngxError="required"> Please select a file </mat-error>
    </div>

    <mat-form-field class="full-width">
      <mat-select placeholder="File Type" [formControl]="fileType">
        <mat-optgroup *ngFor="let group of fileTypeOptions" [label]="group.name">
          <mat-option *ngFor="let type of group.children" [value]="type">
            {{ type.name }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <div ngxErrors="docType">
      <mat-error ngxError="required"> Please select a type </mat-error>
    </div>
    <!--<pre>-->
    <!--{{ fileUploadForm.value | json }}-->
    <!--</pre>-->
  </form>

  <!--<hr>-->
  <!--<div class="pyb-actions-file-category">-->
  <!--<div class="row">-->
  <!--<div class="form-group col-md-5">-->
  <!--<label class="floating-label">File type</label>-->
  <!--<ui-select ng-model="addActionsVm.fileType.selected"-->
  <!--title="Choose a file type" ng-class="{'missing':addActionsVm.missingFileType}"-->
  <!--on-select="addActionsVm.validateFileType($item, $model)">-->
  <!--<ui-select-match placeholder="Select a file type">{{$select.selected.name}}-->
  <!--</ui-select-match>-->
  <!--<ui-select-choices group-by="'parent'"-->
  <!--repeat="category in addActionsVm.fileTypes | propsFilter: {name: $select.search}">-->
  <!--<div ng-bind-html="category.name | highlight: $select.search"></div>-->
  <!--</ui-select-choices>-->
  <!--</ui-select>-->
  <!--</div>-->
  <!--<div class="form-group col-md-7 text-right">-->
  <!--<button class="btn btn-icon-right btn-action waves-effect" ng-click="openNewCategoryPanel = true">-->
  <!--Add new <i class="material-icons">add</i>-->
  <!--</button>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="clearfix"></div>-->
  <!--<pyb-actions-add-file-category opened="openNewCategoryPanel"></pyb-actions-add-file-category>-->
  <!--</div>-->
  <!--<div class="progress" ng-if="addActionsVm.saving">-->
  <!--<div class="indeterminate"></div>-->
  <!--</div>-->
  <!--</div>-->
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="uploadFile()" [disabled]="fileUploadForm.invalid" tabindex="2" [color]="'accent'">
    {{ loading ? '' : 'Save' }}
    <app-loading-spinner [size]="1" *ngIf="loading"></app-loading-spinner>
  </button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'" tabindex="-1">Cancel</button>
</div>

<!--<form [formGroup]="form" (ngSubmit)="onSubmit()">-->
<!--<div class="form-group">-->
<!--<label for="file">File</label>-->
<!--<input type="file" id="file" (change)="onFileChange($event)" #fileInput>-->
<!--<button type="button" class="btn btn-sm btn-default" (click)="clearFile()">clear file</button>-->
<!--</div>-->
<!--<button type="submit" [disabled]="form.invalid || loading" class="btn btn-success">Submit <i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></button>-->
<!--</form>-->
