<div class="row">
  <div
    class="accordion-wrapper"
    [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-12' : 'col-lg-6 col-md-12'"
  >
    <div *ngIf="editMode && payConfig.paymentType !== OutgoingPaymentMethod.PCARD" class="card">
      <div class="card-content">
        <div class="row">
          <div class="col-8">
            <mat-form-field class="full-width">
              <mat-select matInput [(ngModel)]="payConfigStatus" [placeholder]="'Status'">
                <mat-option *ngFor="let status of paymentConfigStatuses" [value]="status">{{ status }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <button mat-raised-button [color]="'accent'" (click)="changeStatus(payConfigStatus)" class="col-md-12">Save status</button>
          </div>
        </div>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" *ngIf="!editMode" hideToggle="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Country</mat-panel-title>
          <mat-panel-description>
            <img
              *ngIf="countryCtrl.value && getCountryFlag(countryCtrl.value)"
              src="data:image/png;base64,{{ getCountryFlag(countryCtrl.value) }}"
              alt="Country flag"
            />
            {{ countryCtrl.value ? getCountryName(countryCtrl.value) : 'Choose a country' }}
          </mat-panel-description>
          <mat-icon>language</mat-icon>
        </mat-expansion-panel-header>

        <mat-form-field class="full-width country-select">
          <input matInput placeholder="Country" aria-label="Country" [matAutocomplete]="auto" [formControl]="countryCtrl" />

          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCountrySelect($event)" [displayWith]="getCountryName.bind(this)">
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country.id">
              <img
                style="vertical-align: middle"
                aria-hidden
                src="data:image/png;base64,{{ country.flag }}"
                width="50"
                height="25"
                alt="Country flag"
              />
              <span>{{ country.label }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-action-row>
          <button mat-button color="primary" [disabled]="!paymentMethods" (click)="nextStep()">Next</button>
        </mat-action-row>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" *ngIf="!editMode" hideToggle="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Payment Method</mat-panel-title>
          <mat-panel-description>
            {{ payConfig.paymentType ? paymentMethodLabels[payConfig.paymentType] : 'Select payment method' }}
          </mat-panel-description>
          <mat-icon>payment</mat-icon>
        </mat-expansion-panel-header>
        <mat-radio-group [(ngModel)]="payConfig.paymentType" (change)="onPaymentSelect()" class="payment-radio-group">
          <mat-radio-button class="payment-radio-button" *ngFor="let method of paymentMethods" [value]="method.paymentMethod">
            {{ paymentMethodLabels[method.paymentMethod] }}
            ({{ (method.fee && (method.fee | currency: 'USD':true) + ' USD per payment') || 'free' }})
          </mat-radio-button>
        </mat-radio-group>

        <mat-action-row>
          <button *ngIf="!editMode" mat-button color="warn" (click)="prevStep()">Previous</button>
          <button mat-button color="primary" [disabled]="!payConfig.paymentType" (click)="nextStep()">Next</button>
        </mat-action-row>
      </mat-expansion-panel>

      <mat-expansion-panel
        [expanded]="step === 2 || editMode"
        (opened)="setStep(2)"
        hideToggle="true"
        [ngClass]="payConfig.paymentType === OutgoingPaymentMethod.PCARD ? 'marqeta-expansion-panel' : ''"
      >
        <div *ngIf="payConfig.paymentType === OutgoingPaymentMethod.PCARD" class="back-button">
          <a [routerLink]="['../../list']" class="close-button">
            <i class="material-icons">arrow_back</i>
          </a>
        </div>
        <mat-expansion-panel-header>
          <mat-panel-title>Payment Configuration</mat-panel-title>
          <mat-panel-description>Configure Payment</mat-panel-description>
          <span class="badge-wrapper" *ngIf="payConfig.paymentType === OutgoingPaymentMethod.PCARD">
            <span class="card-type-badge" *ngIf="isPhysicalCard()">
              {{ getShippingStatus().toLowerCase() }}
            </span>

            <span class="card-type-badge" *ngIf="payConfig.paymentConfig.pcard.pcard_status">
              {{ payConfig.paymentConfig.pcard.pcard_status.toLowerCase() }}
            </span>

            <span class="card-type-badge">
              {{ payConfig.paymentConfig.pcard.pcard_type_indicator.toLowerCase() }}
            </span>
          </span>

          <mat-icon *ngIf="payConfig.paymentType !== OutgoingPaymentMethod.PCARD">settings_application</mat-icon>
        </mat-expansion-panel-header>

        <div [ngSwitch]="payConfig.paymentType">
          <app-payment-type-ach
            *ngSwitchCase="OutgoingPaymentMethod.ACH"
            [paymentConfig]="payConfig"
            [selectedCountry]="countryCtrl.value"
            [supplierDetailsModel]="supplierDetails"
            (configForm)="configFormDataUpdate($event)"
          ></app-payment-type-ach>
          <app-payment-type-wire
            *ngSwitchCase="OutgoingPaymentMethod.WIR"
            [paymentConfig]="payConfig"
            [selectedCountry]="countryCtrl.value"
            (configForm)="configFormDataUpdate($event)"
          ></app-payment-type-wire>
          <app-payment-type-efs
            [paymentConfig]="payConfig"
            (configForm)="configFormDataUpdate($event)"
            *ngSwitchCase="OutgoingPaymentMethod.EFS"
          ></app-payment-type-efs>
          <app-payment-type-ppm
            [paymentConfig]="payConfig"
            (configForm)="configFormDataUpdate($event)"
            *ngSwitchCase="'OutgoingPaymentMethod.PPM'"
          ></app-payment-type-ppm>
          <app-payment-type-marqeta [payConfig]="payConfig" *ngSwitchCase="OutgoingPaymentMethod.PCARD"></app-payment-type-marqeta>
        </div>

        <mat-action-row *ngIf="payConfig.paymentType !== OutgoingPaymentMethod.PCARD">
          <button mat-button color="warn" (click)="removePaymentConfig()" [hidden]="!editMode" class="pull-left">Delete</button>
          <button *ngIf="!editMode" mat-button color="warn" (click)="prevStep()">Previous</button>
          <button mat-button color="primary" (click)="savePaymentConfig()" [disabled]="!paymentConfigForm">Save</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="accordion-wrapper card-table card col-lg-6 col-md-6 col-6" [hidden]="payConfig.paymentType !== OutgoingPaymentMethod.PCARD">
    <div class="card-content">
      <h2 class="title">Card Transition History</h2>

      <mat-table #table [dataSource]="cardTransitionsData" matSort>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.create_ts | date: 'M/d/y' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.status }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reason">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Reason</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.reason }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="notes">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Notes</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.notes }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="channel">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Channel</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.channel }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="cardTransitionHistoryColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: cardTransitionHistoryColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
    </div>
  </div>
</div>
