import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, mergeMap, share, take, tap } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { AppConfigService } from '../../../config';
import { CardFormgroupComponent } from '../../../shared/card-formgroup/card-formgroup.component';
import { CardFormgroupMessageService } from '../../../shared/card-formgroup/card-formgroup-message.service';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { FEATURE_FLAGS } from '../../../shared/feature-flags/feature-flags.const';
import { CrmStates } from '../../../shared/layout/layout.enums';
import { LayoutState } from '../../../shared/layout/store/layout.reducers';
import Logger from '../../../shared/logger';
import { LookupAbstractService } from '../../../shared/lookup-service/lookup-abstract.service';
import { ErrorLogger } from '../../../shared/services/error-logger/error-logger.service';
import { UsersService } from '../../../shared/users/service/users.service';
import { UserModel } from '../../../shared/users/user.model';
import { USER_PERMISSION } from '../../../shared/users/user-permissions.enum';
import { trackByIndex } from '../../../shared/utils/functions/track-by-index.util';
import { FetchUtilAttribute, selectCountries, selectTimezones, selectUsStates } from '../../../shared/utils/store/utils.actions';
import { AppState } from '../../../store/app.reducers';
import { FundingParticularsModel } from '../../model/funding-particulars.model';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { RxEditBaseComponent } from '../../rx-edit-base.component';
import { CancelEmitService } from '../../services/cancel-emit.service';
import { CancelSupplierService } from '../../services/cancel-supplier.service';
import { SupplierService } from '../../services/supplier.service';
import { SupplierReferralService } from '../../services/supplier-referral.service';
import { SupplierStatusesService } from '../../services/supplier-statuses.service';
import { selectFundingParticulars, selectSupplierDetails, selectSupplierDetailsAndFunding } from '../../store/supplier/supplier.actions';
import { AdditionalBusinsessOwnerService } from '../additional-business-owners/additional-businsess-owner.service';
import { CancelSupplierComponent } from '../cancel-supplier/cancel-supplier.component';
import { CreateTransferRequestComponent } from '../create-transfer-request/create-transfer-request.component';
import { SupplierChangePasswordDialogComponent } from '../supplier-change-password-dialog/supplier-change-password-dialog.component';
import { SupplierGeneralForm } from './supplier-general-form';
import { SupplierGeneralFormTypes } from './supplier-general-form-types';

@Component({
  selector: 'app-supplier-general',
  templateUrl: './supplier-general.component.html',
  styleUrls: ['./supplier-general.component.scss'],
})
export class SupplierGeneralComponent extends RxEditBaseComponent implements OnInit, OnDestroy {
  @ViewChild('formGroupShippingAddress')
  private readonly formGroupShippingAddress: CardFormgroupComponent;

  private SUPPLIER_SERVICE_CLASS_NAME = 'SupplierService';
  private FUNDING_PARTICULARS_SERVICE_CLASS_NAME = 'FundingParticularsService';
  private AUTO_PAYMENT_SERVICE_CLASS_NAME = 'AutoPaymentService';

  private supplierKey: string;
  private mainOwnerObject = null;
  private pendingCancellationInfo: any;

  readonly CONTACT_NAME_FORM = SupplierGeneralFormTypes.CONTACT_NAME_FORM;
  readonly CONTACT_DATA_FORM = SupplierGeneralFormTypes.CONTACT_DATA_FORM;
  readonly INSTANT_MESSENGER_FORM = SupplierGeneralFormTypes.INSTANT_MESSENGER_FORM;
  readonly LEGAL_FORM = SupplierGeneralFormTypes.LEGAL_FORM;

  readonly ADDRESS_FORM = SupplierGeneralFormTypes.ADDRESS_FORM;
  readonly TAX_FORM = SupplierGeneralFormTypes.TAX_FORM;
  readonly TIMEZONE_FORM = SupplierGeneralFormTypes.TIMEZONE_FORM;
  readonly INCORPORATION_FORM = SupplierGeneralFormTypes.INCORPORATION_FORM;
  readonly GENERAL_INFO_FORM = SupplierGeneralFormTypes.GENERAL_INFO_FORM;
  readonly AUTO_PAYMENT_FORM = SupplierGeneralFormTypes.AUTO_PAYMENT_FORM;

  readonly FUNDING_PARTICULARS_FORM = SupplierGeneralFormTypes.FUNDING_PARTICULARS_FORM;
  readonly SHIPPING_ADDRESS_FORM = SupplierGeneralFormTypes.SHIPPING_ADDRESS_FORM;

  readonly userPermission = USER_PERMISSION;
  readonly featureFlags = FEATURE_FLAGS;

  readonly crmPanelStates = CrmStates;
  readonly cancellationStatuses = of([
    { id: 'pending', label: 'Pending' },
    { id: 'canceled', label: 'Cancelled' },
    { id: 'collections', label: 'Collections' },
    { id: 'inactive', label: 'Inactive' },
    { id: 'none', label: 'None' },
  ]);
  readonly fundStatusList = of([
    { id: 'Not Selected', label: 'Not Selected' },
    { id: 'Selected', label: 'Selected' },
    { id: 'Sent', label: 'Sent' },
    { id: 'Rejected', label: 'Rejected' },
    { id: 'Approved', label: 'Approved' },
  ]);
  readonly trackBy = trackByIndex;
  readonly mask: string[] = ['*********'];

  cancelDisabled = false;

  supplierDetailsModel: SupplierDetailsModel;
  fundingParticularsModel: FundingParticularsModel;

  addressStates: Store<any>;
  timezones: Store<any>;
  addressCountries: Store<any>;
  businessTypes: Observable<any>;
  messengerTypes: Observable<any[]>;

  businessCodeLabel: Observable<string>;

  layoutState$: Store<LayoutState>;

  supplierGeneralModels$: Observable<any>;

  selectSupplierDetails$: Observable<any>;
  selectSupplierDetails2$: Subscription;
  selectFundingParticulars$: Observable<any>;

  supplierStatusClass: string;

  companyDBA: string;
  supplierSite = null;
  supplierSiteVersions = [];
  shippingAddressProcessing = false;
  owners = [];

  cancellationBtnText: string;
  cancellationBtnColor: string;
  cancellationStatusChecked: boolean;

  loaded = false;
  isAuthorizedToChangeSsn: boolean;
  hasAdvanceAuditorPermission = false;

  constructor(
    store: Store<AppState>,
    messageService: CardFormgroupMessageService,
    errorLogger: ErrorLogger,
    readonly supplierFormBuilder: SupplierGeneralForm,
    private readonly lookupRepo: LookupAbstractService,
    private readonly supplierReferralService: SupplierReferralService,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly cancelEmitService: CancelEmitService,
    private readonly cancelSupplierService: CancelSupplierService,
    private readonly route: ActivatedRoute,
    private readonly appConfig: AppConfigService,
    private readonly supplierService: SupplierService,
    private readonly router: Router,
    private readonly additionalBusinsessOwnerService: AdditionalBusinsessOwnerService,
    private readonly supplierStatusService: SupplierStatusesService,
    private readonly cd: ChangeDetectorRef,
    private readonly permissionsService: NgxPermissionsService,
    private readonly usersService: UsersService,
  ) {
    super(store, messageService, errorLogger);
    this.isCancelDisabled();

    this.store.dispatch(new FetchUtilAttribute({ attr: 'timezones' }));
    this.store.dispatch(new FetchUtilAttribute({ attr: 'usStates' }));
    this.store.dispatch(new FetchUtilAttribute({ attr: 'countries' }));

    this.supplierSiteVersions = this.appConfig.env.supplierSiteVersions;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.pipe(filter((ev) => ev instanceof NavigationEnd)).subscribe((params) => {
        this.loaded = false;
      }),
    );

    this.checkAdvanceAuditorPermission();

    this.subscriptions.push(
      this.cancelEmitService.onCancellationChange.subscribe(() => {
        setTimeout(() => {
          this.isCancelDisabled();
        }, 1500);
      }),
    );

    this.subscriptions.push(
      this.cancelEmitService.editOnCancellationChange.subscribe((value: string) => {
        setTimeout(() => {
          this.isCancelDisabled(value);
        }, 750);
      }),
    );

    this.subscriptions.push(
      this.cancelEmitService.onWinbackChange.subscribe(() => {
        this.saveSingleField('cancelationStatus', null, this.GENERAL_INFO_FORM);
      }),
    );

    this.isCancelDisabled();
    this.layoutState$ = this.store.select('layout');
    this.initLookupData();

    this.selectSupplierDetails$ = this.store.select(selectSupplierDetails).pipe(
      tap((result) => (this.loaded = !!result)),
      filter(Boolean),
      tap((supplierDetails: SupplierDetailsModel) => {
        this.companyDBA = supplierDetails.companyDBA;
        this.checkNewCancellation(supplierDetails);
        this.supplierSite = supplierDetails.supplierSite;
        this.setSupplierStatus(supplierDetails);
      }),
    );

    this.selectFundingParticulars$ = this.store.select(selectFundingParticulars).pipe(share(), filter(Boolean));

    this.supplierGeneralModels$ = this.store.select(selectSupplierDetailsAndFunding).pipe(share());

    this.subscriptions.push(
      this.supplierGeneralModels$.subscribe((results) => {
        if (!isUndefined(results[0]) && !isUndefined(results[1])) {
          this.fixResetValue();


          this.supplierDetailsModel = <SupplierDetailsModel>results[0];
          this.fundingParticularsModel = <FundingParticularsModel>results[1];

          if (this.supplierDetailsModel && this.supplierDetailsModel.owners) {
            this.owners = this.supplierDetailsModel.owners;
          }

          this.businessTypes.subscribe((types) => {
            const foundItem = types.find((item) => item.id === this.supplierDetailsModel.businessTypeCode);
            if (foundItem) {
              this.setBusinessCodeLabel(foundItem.label);
            }
          });

          // TODO nkler: maybe we should subscribe only once?
          if (isUndefined(this.formGroups[SupplierGeneralFormTypes.CONTACT_NAME_FORM])) {
            this.formGroups = {
              ...this.supplierFormBuilder.initGeneralFormGroups({
                ...this.supplierDetailsModel,
                cancelationStatus: this.convertNullToNoneString(this.supplierDetailsModel.cancelationStatus),
              }),
              ...(this.hasAdvanceAuditorPermission ? {} : this.supplierFormBuilder.initFundingFormGroups(this.fundingParticularsModel)),
            };
          } else {
            const allFields = {
              ...this.supplierDetailsModel,
              cancelationStatus: this.convertNullToNoneString(this.supplierDetailsModel.cancelationStatus),
              ...this.fundingParticularsModel,
            };
            Object.getOwnPropertyNames(this.formGroups).map((key: string) => {
              const formGroup: FormGroup = this.formGroups[key];

              if (key !== this.SHIPPING_ADDRESS_FORM) {
                formGroup.patchValue(allFields);
              }

              if (key === this.ADDRESS_FORM) {
                formGroup.patchValue(allFields.address);
              }

              if (key === this.INSTANT_MESSENGER_FORM) {
                formGroup.patchValue(allFields.instantMessenger);
              }
            });
          }
        }
      }),
    );
  }

  setSupplierStatus(supplierDetails: SupplierDetailsModel): void {
    if (!supplierDetails) {
      return;
    }
    this.supplierStatusClass = this.supplierStatusService.getSupplierStatusStyle(supplierDetails.onboardingTemplateCode);
  }

  toggleEditMode(el: any): void {
    el.isEditable = !el.isEditable;
  }

  saveAdditionalBusinessOwner(owner: any): void {
    owner['dateOfBirth'] = moment(owner['dateOfBirth']).format('MM/DD/YYYY');
    const data = owner;
    delete data['isEditable'];

    this.additionalBusinsessOwnerService.editOwner(data.loginKey, data).subscribe(
      () => {
        this.toastrService.success('Additional business owners has been edited');
      },
      (err) => {
        this.toastrService.error(err.error.message ? err.error.message : 'Editing Editing');
      },
    );
  }

  generateLink(supplier) {
    return this.supplierReferralService.generateLink(supplier);
  }

  saveSSN(formGroupKey: string, customMapper = null): void {
    if (!this.isAuthorizedToChangeSsn) {
      return;
    }

    this.saveSingleField('ownerSSN', formGroupKey, customMapper);
  }

  private fixResetValue(): void {
    // fix for receiving supplier from cache - in that scenario control component is calling resetValue fn,
    // which returns value form previous supplier, we have to reset whole component for a while
    this.loaded = false;

    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
    setTimeout(() => {
      this.loaded = true;
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    }, 100);
  }

  private isCancelDisabled(status?: string): void {
    if (status) {
      if (status === 'none' || status === 'inactive' || status === null) {
        this.cancelDisabled = false;
      } else {
        this.cancelDisabled = true;
      }

      return;
    }

    if (this.selectSupplierDetails2$ && !this.selectSupplierDetails2$.closed) {
      this.selectSupplierDetails2$.unsubscribe();
    }

    this.selectSupplierDetails2$ = this.store
      .select(selectSupplierDetails)
      .pipe(
        filter((data) => !!(data && data.supplierKey)),
        tap((data) => (this.supplierKey = data.supplierKey)),
        mergeMap(() => this.assignIsAuthorizedToChangeSsn()),
        tap(() => {
          if (!this.hasAdvanceAuditorPermission) {
            this.getSupplierMainOwner();
          }
        }),
        mergeMap(() => this.cancelSupplierService.getSupplier(this.supplierKey).pipe(take(1))),
      )
      .subscribe((data) => {
        if (data && data.hasOwnProperty('cancelationStatus')) {
          this.checkNewCancellation(data);
          if (data.cancelationStatus === 'pending') {
            this.cancelDisabled = false;
            this.getPendingCancellationInfo();
          }
        }
      });
  }

  private assignIsAuthorizedToChangeSsn(): Observable<[boolean, boolean]> {
    const permissions = [USER_PERMISSION.FINANCE, USER_PERMISSION.DEV, USER_PERMISSION.REST_UW_ACCESS];
    return this.store.select(selectLoggedUserModel).pipe(
      filter(Boolean),
      mergeMap((user: UserModel) =>
        combineLatest([
          this.usersService.checkGeneralPermissions(permissions, user),
          this.usersService.checkPermissions(permissions, user),
        ]),
      ),
      tap(([generalPermissions, subPermissions]) => (this.isAuthorizedToChangeSsn = generalPermissions || subPermissions)),
    );
  }

  private checkNewCancellation(data: SupplierDetailsModel): void {
    if (data.cancelationStatus === 'none' || data.cancelationStatus === 'inactive' || data.cancelationStatus === null) {
      this.cancellationBtnText = 'Request Cancellation';
      this.cancellationBtnColor = 'warn';
      this.pendingCancellationInfo = null;
      this.cancellationStatusChecked = true;
      this.cancelDisabled = false;
    }
  }

  private getPendingCancellationInfo(): void {
    this.cancellationBtnText = 'Update Cancellation';
    this.cancellationBtnColor = 'primary';
    this.subscriptions.push(
      this.cancelSupplierService.getPendingCancellationInfo(this.supplierKey).subscribe(
        (response) => {
          this.pendingCancellationInfo = response;
          this.cancellationStatusChecked = true;
        },
        (err) => {
          this.toastrService.error(err.error.message ? err.error.message : 'Something went wrong');
        },
      ),
    );
  }

  convertNoneStringToNull(value: string) {
    return value === 'none' ? null : value;
  }

  convertNullToNoneString(value: string) {
    return value === null ? 'none' : value;
  }

  convertNoneStringToNullInForm(form: FormGroup) {
    if (form.get('cancelationStatus').value && form.get('cancelationStatus').value === 'none') {
      form.patchValue({
        cancelationStatus: null,
      });
    }

    return form;
  }

  private initLookupData() {
    this.businessTypes = this.lookupRepo.getBusinessTypes();
    this.addressCountries = this.store.select(selectCountries);
    this.messengerTypes = this.lookupRepo.getIMTypes();
    this.addressStates = this.store.select(selectUsStates);
    this.timezones = this.store.select(selectTimezones);
  }

  protected resolveServiceClassBy(formGroupKey: string) {
    switch (formGroupKey) {
      case this.FUNDING_PARTICULARS_FORM:
        return this.FUNDING_PARTICULARS_SERVICE_CLASS_NAME;

      case this.AUTO_PAYMENT_FORM:
        return this.AUTO_PAYMENT_SERVICE_CLASS_NAME;

      default:
        return this.SUPPLIER_SERVICE_CLASS_NAME;
    }
  }

  protected resolveParamsBy(formGroupKey: string): any[] {
    return [];
  }

  protected prepareRequestPartData(formGroupKey, partData): any {
    switch (formGroupKey) {
      case this.INSTANT_MESSENGER_FORM:
        return {
          instantMessenger: {
            slug: partData.instantMessengerSlug,
            type: partData.instantMessengerType,
          },
        };
      case this.ADDRESS_FORM:
        return {
          address: partData,
        };

      case this.FUNDING_PARTICULARS_FORM:
        const fundingReqModel = new FundingParticularsModel();
        fundingReqModel.fundPartStatus = partData.fundPartStatus;
        fundingReqModel.legalSigned = partData.legalSigned;

        fundingReqModel.fundFacilityLimit = +partData.fundFacilityLimit;
        fundingReqModel.supplierCovenants = partData.supplierCovenants;
        fundingReqModel.guarantor = partData.guarantor;

        return fundingReqModel;

      default:
        return partData;
    }
  }

  protected getSupplierKey(): string {
    return this.supplierDetailsModel.supplierKey;
  }

  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(SupplierChangePasswordDialogComponent, {
      data: { supplierKey: this.supplierDetailsModel.supplierKey },
      width: DIALOG_SIZE.SMALL.width,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastrService.success('Password has been changed successfully');
      }
    });
  }

  openCancelSupplierDialog() {
    let dialogRef;
    if (!this.pendingCancellationInfo) {
      dialogRef = this.dialog.open(CancelSupplierComponent, {
        data: { supplierKey: this.supplierDetailsModel.supplierKey },
        width: DIALOG_SIZE.LARGE.width,
      });
    } else {
      dialogRef = this.dialog.open(CancelSupplierComponent, {
        data: this.pendingCancellationInfo,
        width: DIALOG_SIZE.LARGE.width,
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastrService.success('Cancellation has been successfully');
      }
    });
  }

  setBusinessCodeLabel(event) {
    this.businessCodeLabel = event;
  }

  chooseSupplierSiteVersion(version: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: "Are you sure you want to change the supplier's dashboard version?",
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const valueObject = {
          value: {
            supplierSite: version,
          },
          valid: true,
        };
        this.saveFormGroup(valueObject, this.CONTACT_NAME_FORM);
      }
    });
  }

  saveShippingAddressForm({ value }): void {
    this.subscriptions.push(
      this.supplierService.updateSupplierMainOwner(this.mainOwnerObject.loginKey, value).subscribe(
        () => {
          this.messageService.broadcast(this.formGroups[this.SHIPPING_ADDRESS_FORM], 'saveSuccess');
          this.formGroupShippingAddress.disableEditMode();
          this.fixResetValue();
        },
        (error) => {
          Logger.error(error);
          this.toastrService.error('Something went wrong');
          this.messageService.broadcast(this.formGroups[this.SHIPPING_ADDRESS_FORM], 'saveError');
        },
      ),
    );
  }

  private getSupplierMainOwner(): void {
    this.subscriptions.push(
      this.supplierService
        .getSupplierLogins(this.supplierKey)
        .pipe(take(1))
        .subscribe(
          (response: any) => this.getSupplierMainOwnerSuccess(response),
          (error) => Logger.log(error),
        ),
    );
  }

  private getSupplierMainOwnerSuccess(response: any[]) {
    if (!isNullOrUndefined(response)) {
      this.mainOwnerObject = response.filter((value) => value.mainOwner === true)[0];
      this.formGroups = {
        ...this.formGroups,
        ...this.supplierFormBuilder.initShippingAddressFormGroup(this.mainOwnerObject),
      };
    }
  }

  public viewAll() {
    this.router.navigate(['../additional-business-owners'], { relativeTo: this.route });
  }

  public addNewUser() {
    this.router.navigate(['../additional-business-owners/new'], { relativeTo: this.route });
  }

  public resetIncorporationForm(formGroup: FormGroup) {
    formGroup.get('incorporatedInTheUS').setValue(false);
  }

  onCreateTransferRequestClick() {
    const dialogRef = this.dialog.open(CreateTransferRequestComponent, {
      data: { supplierKey: this.supplierKey },
      width: DIALOG_SIZE.RWD_40.width,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.type) {
        const text = result.text;
        if (result.type === 'error') {
          this.toastrService.error(text);
        }
        if (result.type === 'success') {
          this.toastrService.success(text);
        }
      }
    });
  }

  private checkAdvanceAuditorPermission(): void {
    const permission = this.permissionsService.getPermission(USER_PERMISSION.ADVANCE_AUDITORS);
    this.hasAdvanceAuditorPermission = !isNullOrUndefined(permission);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.selectSupplierDetails2$ && !this.selectSupplierDetails2$.closed) {
      this.selectSupplierDetails2$.unsubscribe();
    }
  }
}
