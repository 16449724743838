import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

declare const BUILD_TIMESTAMP;

export enum TYPE {
  SCRIPT,
  STYLE,
}

@Injectable()
export class DynamicEmbedderService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  embed(src: string, type: TYPE = TYPE.SCRIPT): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement(this.getTag(type));
      this.getProps(script, type, src);
      this.renderer.appendChild(document.body, script);
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject();
      };
    });
  }

  private getTag(type: TYPE): string {
    return type === TYPE.SCRIPT ? 'script' : 'link';
  }

  private getProps(script, type: TYPE, src: string) {
    switch (type) {
      case TYPE.SCRIPT:
        script.type = 'text/javascript';
        (script as HTMLScriptElement).src = this.getAddr(src);
        break;
      case TYPE.STYLE:
        (script as HTMLLinkElement).rel = 'stylesheet';
        (script as HTMLLinkElement).href = this.getAddr(src);
        break;
    }
  }

  private getAddr(src: string) {
    return `${src}?_t=${BUILD_TIMESTAMP}`;
  }
}
