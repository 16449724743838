import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { FeeScheduleAddModel } from './../model/fee-schedule-add.model';
import { FeeSettingsService } from './../service/fee-settings.service';

@Component({
  selector: 'app-fee-settings-add',
  templateUrl: './fee-settings-add.component.html',
  styleUrls: ['./../fee-settings.component.scss'],
})
export class FeeSettingsAddComponent extends SubscriberComponent implements OnInit {
  displayedColumns = ['tierStart', 'tierEnd', 'feePercentage', 'fixedFee', 'action'];

  addForm: FormGroup;
  feeScheduleData: any;
  feeScheduleTypes: any;
  dataSource: any;
  tiers: Array<any>;
  tableIsHidden = true;
  category: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: FeeSettingsService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.subscriptions.push(
      this.service.getFeeScheduleData().subscribe((result) => {
        this.feeScheduleData = result;
      }),
    );
    this.tiers = [];
    this.removeTier(0);
  }

  createForm() {
    this.addForm = new FormGroup({
      category: new FormControl('', [Validators.required]),
      scheduleType: new FormControl('', [Validators.required]),
      scheduleName: new FormControl('', [Validators.required]),
    });
    this.addForm.get('scheduleType').disable();
    this.addForm.get('scheduleName').disable();
  }

  setFeeScheduleTypesForSelectedCategory(selectedFeeScheduleCategory) {
    this.addForm.get('scheduleType').enable();
    this.category = selectedFeeScheduleCategory.value;
    this.feeScheduleData
      .filter((feeSchedules) => feeSchedules.feeScheduleCategory === selectedFeeScheduleCategory.value)
      .map((feeSchedules) => (this.feeScheduleTypes = feeSchedules.feeScheduleTypes));
    if (selectedFeeScheduleCategory.value === 'PAYMENT') {
      this.addForm.get('scheduleType').setValue(this.feeScheduleTypes[0].value);
      this.addForm.get('scheduleName').enable();
      this.tiers = [{}];
      this.displayedColumns = [
        'paymentTypeCode',
        'pmtBatchFeeName',
        'tierStart',
        'tierEnd',
        'feePercentage',
        'fixedFee',
        'minPmtFee',
        'action',
      ];
    }
    if (selectedFeeScheduleCategory.value === 'PURCHASE') {
      this.addForm.get('scheduleName').disable();
      this.tiers = [{}];
      this.displayedColumns = ['tierStart', 'tierEnd', 'feePercentage', 'fixedFee', 'action'];
    }
    this.removeTier(0);
    this.dataSource = new MatTableDataSource(this.tiers);
    this.tableIsHidden = false;
  }

  enableFeeScheduleName() {
    this.addForm.get('scheduleName').enable();
  }

  addTier() {
    this.tiers.push({});
    this.dataSource = new MatTableDataSource(this.tiers);
  }

  removeTier(index: number): void {
    this.tiers.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.tiers);
  }

  areTiersValid(): boolean {
    let isValid = true;
    if (this.category === 'PURCHASE') {
      this.dataSource.data.forEach((tier) => {
        if (tier.tierStart == null || tier.tierEnd == null || tier.feePercent == null || tier.feeFixed == null) {
          isValid = false;
        }
      });
      return isValid;
    }
    if (this.category === 'PAYMENT') {
      this.dataSource.data.forEach((tier) => {
        if (
          tier.tierStart == null ||
          tier.tierEnd == null ||
          tier.feePercent == null ||
          tier.feeFixed == null ||
          tier.minPmtFee == null ||
          tier.paymentTypeCode == null ||
          tier.pmtBatchFeeName == null
        ) {
          isValid = false;
        }
      });
      return isValid;
    }
  }

  submit(element) {
    const fees = new FeeScheduleAddModel();
    fees.feeScheduleName = this.addForm.get('scheduleName').value;
    fees.feeScheduleTypeCode = this.addForm.get('scheduleType').value;
    if (this.category === 'PURCHASE') {
      element.forEach((tier) => {
        tier['paymentTypeCode'] = null;
        tier['pmtBatchFeeCode'] = null;
        tier['pmtBatchFeeName'] = null;
      });
    } else {
      element.forEach((tier, i) => {
        tier['paymentTypeCode'] = element[i].paymentTypeCode.key;
        tier['pmtBatchFeeCode'] = element[i].pmtBatchFeeName.key;
        tier['pmtBatchFeeName'] = element[i].pmtBatchFeeName.value;
      });
    }

    fees.feeScheduleTiers = element;
    this.subscriptions.push(
      this.service.addFeeSchedule(fees).subscribe(
        (success) => {
          this.toastrService.success('Fee Schedule created succesfully');
        },
        (response) => {
          this.toastrService.error(response.error.message);
        },
        () => {
          this.router.navigate(['/manage/fee-settings'], { relativeTo: this.route });
        },
      ),
    );
  }
}
