<h1 mat-dialog-title>Add batch email</h1>

<div class="row">
  <div class="col-md-6">
    <form class="row" [formGroup]="form" #formTemplate>
      <mat-form-field class="template-select full-width col-md-12">
        <input matInput placeholder="Template" aria-label="Template" [formControl]="template" [matAutocomplete]="templateAutocomplete" />
        <mat-autocomplete #templateAutocomplete="matAutocomplete" (optionSelected)="setTemplate($event)">
          <mat-option *ngFor="let template of filteredTemplates | async" [value]="template">
            <span>{{ template.configKey }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="full-width col-md-12" *ngIf="isEditing">
        <textarea
          matInput
          placeholder="Paste comma or new line separated suplier keys"
          aria-label="supplier-name"
          [formControl]="content"
          (focusout)="focusoutTextarea($event)"
          #inputTextarea
        ></textarea>
      </mat-form-field>
    </form>

    <div class="col-md-12" *ngIf="!isEditing">
      <strong
        ><span class="email-template-suppliers">will be sent to suppliers: ({{ suppliersToSend.length }})</span></strong
      >
      <mat-icon class="edit-icon" *ngIf="!isEditing" (click)="toggleEditingTextarea()">edit </mat-icon>
      <ul>
        <li *ngFor="let supplier of suppliersToSend">{{ supplier }}</li>
      </ul>
    </div>

    <div class="col-md-12">
      <ul class="error-list">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
  </div>

  <div class="col-md-6">
    <div class="message-body-wrapper" *ngIf="selectedTemplate">
      <div class="row email-template-header">
        <strong><span class="col-sm-10">Email Template Preview</span></strong>
      </div>
      <div class="row email-template-info" *ngIf="selectedTemplate.configValue.source">
        <span class="col-sm-2">From: </span>
        <span class="col-sm-10">{{ selectedTemplate.configValue.source }}</span>
      </div>
      <div class="row email-template-info" *ngIf="selectedTemplate.configValue.subject">
        <span class="col-sm-2">Subject: </span>
        <span class="col-sm-10">{{ selectedTemplate.configValue.subject }}</span>
      </div>
      <div class="row email-template-html" *ngIf="selectedTemplate.configValue.html">
        <div class="col-sm-12" [innerHtml]="selectedTemplate.configValue.html | safe"></div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="saveBatch()" [color]="'accent'" tabindex="-1" *ngIf="showCreateButton()">Create batch</button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'" tabindex="-1">Close</button>
</div>
