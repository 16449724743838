import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Deserialize, Serialize } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '../../../config';
import { DepositAccountModel } from '../../model/deposit-account.model';
import { DepositAccountServiceInterface } from './deposit-account-service-interface';

@Injectable()
export class DepositAccountService implements DepositAccountServiceInterface {
  private apiUrl: string;
  private documentsApiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
    this.documentsApiUrl = appConfig.env.documentsApiUrl;
  }

  get(depositAccountKey: string): Observable<DepositAccountModel> {
    return this.httpClient
      .get<DepositAccountModel>(`${this.apiUrl}deposit_accounts/${depositAccountKey}`, {
        observe: 'body',
        responseType: 'json',
      })
      .pipe(map((depositAccountData) => Deserialize(depositAccountData, DepositAccountModel)));
  }

  create(data: Partial<DepositAccountModel>): Observable<DepositAccountModel> {
    return this.httpClient
      .post<DepositAccountModel>(`${this.apiUrl}deposit_accounts`, Serialize(data))
      .pipe(map((depositAccountData) => Deserialize(depositAccountData, DepositAccountModel)));
  }

  update(depositAccountKey: string, data: DepositAccountModel): Observable<DepositAccountModel> {
    return this.httpClient
      .put<DepositAccountModel>(`${this.apiUrl}deposit_accounts/${depositAccountKey}`, Serialize(data))
      .pipe(map((depositAccountData) => Deserialize(depositAccountData, DepositAccountModel)));
  }

  remove(depositAccountKey: string, supplierKey): Observable<DepositAccountModel> {
    return this.httpClient
      .post(`${this.apiUrl}deposit_accounts/${depositAccountKey}`, {
        supplier_key: supplierKey,
      })
      .pipe(map((depositAccountData) => Deserialize(depositAccountData, DepositAccountModel)));
  }

  generateBankStatement(documentName: string, supplierKey: string) {
    return this.httpClient.get(`${this.documentsApiUrl}generate/${documentName}/${supplierKey}`, {
      responseType: 'blob',
    });
  }

  uploadCSV(data: any) {
    throw new Error('Not implemented!');
  }
}
