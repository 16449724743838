import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ConfigsService, ConfigTypes } from '../../../../../shared/configs/service/configs.service';
import { RulesUtilsService } from '../../../rules-utils.service';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';
import { UsersService } from './../../../../../shared/users/service/users.service';

export const MINUTES = 60;
export const DAYS = 24 * 60 * 60;
export const WEEKS = 7 * 24 * 60 * 60;

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent extends SubscriberComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() loaded$;
  to: FormArray;
  templatesOptions = [];
  usersList: any = [];

  timePeriods = [
    { value: MINUTES, name: 'minutes' },
    { value: DAYS, name: 'days' },
    { value: WEEKS, name: 'weeks' },
  ];

  defaultTimePeriod = 1;

  constructor(public util: RulesUtilsService, private configsService: ConfigsService, private usersService: UsersService) {
    super();
  }

  ngOnInit() {
    this.getEmailTemplatesList();
    this.subscriptions.push(this.usersService.getUsers().subscribe((results) => (this.usersList = results)));

    if (this.form) {
      this.form.get('delayTime').disable();
      this.form.get('delayPeriod').disable();
      if (this.loaded$) {
        this.subscriptions.push(
          this.loaded$
            .pipe(debounceTime(500))
            .distinctUntilChanged()
            .subscribe(() => {
              const value = this.form.get('delayEnabled').value;
              if (value) {
                this.form.get('delayTime').enable();
                this.form.get('delayPeriod').enable();
              } else {
                this.form.get('delayTime').disable();
                this.form.get('delayPeriod').disable();
              }
            }),
        );
      }

      this.to = this.form.get('to') as FormArray;
      this.subscriptions.push(
        this.form
          .get('delayEnabled')
          .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
          .subscribe((value) => {
            if (value) {
              this.form.get('delayTime').enable();
              this.form.get('delayPeriod').enable();
            } else {
              this.form.get('delayTime').disable();
              this.form.get('delayPeriod').disable();
            }
          }),
      );
    }
  }

  private getEmailTemplatesList() {
    this.subscriptions.push(
      this.configsService.list().subscribe((response) => {
        this.templatesOptions = response.filter(
          (config) =>
            config.configType === ConfigTypes.EMAIL_TEMPLATE ||
            config.configType === ConfigTypes.EMAIL_BACK_CONFIG ||
            config.configType === ConfigTypes.EMAIL_FRONT_BATCH_CONFIG,
        );
      }),
    );
  }
}
