import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-add-payment-note',
  templateUrl: './add-payment-note.component.html',
  styleUrls: ['./add-payment-note.component.scss'],
})
export class AddPaymentNoteComponent implements OnInit {
  // Fields
  public supplierName: string;
  public reviewerNote: string;

  private record: any;

  constructor(public dialogRef: MatDialogRef<AddPaymentNoteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (!isNullOrUndefined(data)) {
      this.supplierName = data.supplierName;
      this.reviewerNote = data.reviewerNote;
      this.record = data.record;
    }
  }

  // Methods
  public ngOnInit(): void {}

  public submitOnClick(): void {
    const data = {
      record: this.record,
      value: {
        reviewerNote: this.reviewerNote,
      },
    };

    this.dialogRef.close(data);
  }
}
