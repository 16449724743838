import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-marketplace-list',
  templateUrl: './header-marketplace-list.component.html',
  styleUrls: ['./header-marketplace-list.component.scss'],
})
export class HeaderMarketplaceListComponent implements OnInit {
  @Input() marketplacesNames = [];
  @Input() showFullName = false;
  @Input() showTooltips = true;

  constructor() {}

  ngOnInit() {}
}
