<div class="example-container mat-elevation-z8">
  <mat-card>
    <mat-card-title>
      <div class="row">
        <div class="col-md-6">Onboarding</div>
        <div *ngIf="hasCRMPermission" class="col-md-6 title-button">
          <button [disabled]="!canEdit" mat-raised-button color="accent" [routerLink]="['../add/']">
            <mat-icon class="mat-22">add</mat-icon>
            Add New
          </button>
        </div>
      </div>
    </mat-card-title>

    <mat-card-content>
      <div class="onboarding-table">
        <mat-table #table [dataSource]="dataSource" matSort [matSortDirection]="'desc'" [matSortActive]="'type'">
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.onboardingTemplateCode }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="minPaymentAmount">
            <mat-header-cell *matHeaderCellDef>Minimum Payment Amount</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.minPaymentAmount }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="efsRebatePercent">
            <mat-header-cell *matHeaderCellDef>EFS Rebate Percent</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.efsRebatePercent }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="instantAccess">
            <mat-header-cell *matHeaderCellDef>Instant Access</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox [checked]="element.instantAccess" disabled></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="schedPayEnabled">
            <mat-header-cell *matHeaderCellDef>Scheduled Payments Enabled</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox [checked]="element.schedPayEnabled" disabled></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="supplierSite">
            <mat-header-cell *matHeaderCellDef>Supplier Site</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.supplierSite }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                *ngIf="hasCRMPermission"
                [disabled]="!canEdit"
                mat-raised-button
                class="mat-primary"
                (click)="navigateToEditTemplate(element); $event.stopPropagation()"
              >
                Edit
              </button>
              <button
                *ngIf="hasCRMPermission"
                mat-raised-button
                (click)="openDeleteOnboarding(element); $event.stopPropagation()"
                color="warn"
              >
                Delete
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" (click)="navigateToMpTemplatesList(row)"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
