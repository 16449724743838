import { Injectable } from '@angular/core';

import { MESSAGE_TYPE } from '../iframe/communication/message-type.enum';
import { SupplierDetailsModel } from './../../suppliers/model/supplier.model';
import { CommunicationService } from './../iframe/communication/communication.service';
import { EVA_IFRAME_SELECTOR } from './const/eva-iframe-selector.enum';
import { EvaService } from './eva.service';
import { EvaPermissions } from './models/eva-permissions.model';

export interface EvaCommunicationCommands {
  refetchSupplierDetails(): void;
  sendUpdatedConfig(flags: string[], permissions?: EvaPermissions): void;
  resetFeatureFlags(): void;
  updateSupplierDetailsStore(details: SupplierDetailsModel): void;
}

@Injectable()
export class EvaCommunicationService implements EvaCommunicationCommands {
  private iframeId: string;

  constructor(private communicationService: CommunicationService) {}

  setIframeId(iframeId: string): void {
    this.iframeId = iframeId;
  }

  communication(): CommunicationService {
    return this.communicationService;
  }

  refetchSupplierDetails(): void {
    const message = {
      type: MESSAGE_TYPE.MAIN_HEADER__REFETCH_SUPPLIER_DETAILS,
    };
    this.communicationService.sendMessageToChild(this.iframeId, message);
  }

  updateSupplierDetailsStore(details: SupplierDetailsModel): void {
    const frameId = EvaService.getIframeIdBySelector(EVA_IFRAME_SELECTOR.TOP_BAR);

    if (!frameId) {
      return;
    }

    const message = {
      type: MESSAGE_TYPE.UPDATE_EVA_STORE__SUPPLIER_DETAILS,
      data: { details },
    };

    this.communicationService.sendMessageToChild(frameId, message);
  }

  sendUpdatedConfig(flags: string[], permissions: EvaPermissions = null): void {
    const message = {
      type: MESSAGE_TYPE.GENERAL__CONFIG_RESPONSE,
      data: { flags, permissions },
    };

    this.communicationService.sendMessageToChild(this.iframeId, message);
  }

  resetFeatureFlags(): void {
    const message = {
      type: MESSAGE_TYPE.FEATURE_FLAGS__RESET,
    };
    this.communicationService.sendMessageToChild(this.iframeId, message);
  }
}
