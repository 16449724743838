import { autoserialize, autoserializeAs, autoserializeIndexable, inheritSerialization } from 'cerialize';

import { BaseCrmModel } from './base-crm.model';
import { CommentModel } from './comment.model';
import { NoteDataModel } from './note-data.model';

@inheritSerialization(BaseCrmModel)
export class NoteModel extends BaseCrmModel {
  @autoserializeAs(NoteDataModel) public data: NoteDataModel;
  @autoserialize public isPinned: boolean;
  @autoserialize public commentsCount: number;
  @autoserialize public instanceId: number;
  @autoserializeIndexable(CommentModel) public latestComments: CommentModel[] = [];

  constructor(
    createdAt: string,
    ownerUuid: string,
    targetType: string,
    targetUuid: string,
    type: string,
    uuid: string,
    updatedAt: string,
    data: NoteDataModel,
    isPinned: boolean,
    commentsCount: number,
    instanceId: number,
    latestComments: CommentModel[],
  ) {
    super(createdAt, ownerUuid, targetType, targetUuid, type, uuid, updatedAt);
    this.data = data;
    this.isPinned = isPinned;
    this.commentsCount = commentsCount;
    this.instanceId = instanceId;
    this.latestComments = latestComments;
  }
}
