import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { FeatureFlagsEvaService } from '../../../shared/feature-flags/feature-flags-eva.service';
import { AppState } from '../../../store/app.reducers';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-contracts',
  templateUrl: './supplier-contracts.component.html',
  styleUrls: ['./supplier-contracts.component.scss'],
})
export class SupplierContractsComponent extends SubscriberComponent implements OnInit {
  readonly initEvaKey = 'EVA_SINGLE_APPLICATION_FOR_SUPPLIER';
  evaQueryParams: { [key: string]: string };
  initEva: boolean;

  constructor(private readonly store: Store<AppState>, private readonly featureFlagsService: FeatureFlagsEvaService) {
    super();

    const featureFlagsMap = this.featureFlagsService.fetchAvailableFlagsExecStatus() ?? [];
    this.initEva = !featureFlagsMap.find((e) => !!e[this.initEvaKey]);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.evaQueryParams = {
            supplierKey: data.supplierKey,
          };
        }),
    );
  }
}
