import { EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NEVER, Observable, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { IMatSelectOption } from '../../../../../shared/interfaces/mat-select-option';
import { LookupAbstractService } from '../../../../../shared/lookup-service/lookup-abstract.service';
import { FetchUtilAttribute, selectCountries } from '../../../../../shared/utils/store/utils.actions';
import { AppState } from '../../../../../store/app.reducers';
import { RoutingNumberValidationStatus } from '../../../../enums/routing-number-validation-status.enum';
import { RoutingNumberValidationStatusType } from '../../../../enums/routing-number-validation-status-type.enum';
import { RoutingNumberValidationStatusConfig } from '../../../../model/routing-number-validation-status.config.model';
import { SupplierService } from '../../../../services/supplier.service';

export abstract class OutgoingPaymentFormComponentBase extends SubscriberComponent {
  form?: FormGroup;
  countries$: Observable<{ countryCode: string; label: string }[]> = NEVER;
  isInternationalPayment$: Observable<boolean> = NEVER;

  abstract configForm: EventEmitter<FormGroup>;

  readonly routingNumberValidationStatuses: Record<RoutingNumberValidationStatus, RoutingNumberValidationStatusConfig> = {
    [RoutingNumberValidationStatus.INCORRECT_ROUTING_NUM]: { msg: 'Invalid routing number', type: RoutingNumberValidationStatusType.ERROR },
    [RoutingNumberValidationStatus.NONE]: { msg: '', type: RoutingNumberValidationStatusType.SUCCESS },
    [RoutingNumberValidationStatus.VERIFIED]: { msg: 'Verified', type: RoutingNumberValidationStatusType.SUCCESS },
    [RoutingNumberValidationStatus.PENDING_VERIFICATION]: { msg: 'Pending', type: RoutingNumberValidationStatusType.WARNING },
    [RoutingNumberValidationStatus.INSUFFICIENT_FOR_NATIONAL_PAYMENT]: {
      msg: 'Routing number and bank country required',
      type: RoutingNumberValidationStatusType.ERROR,
    },
    [RoutingNumberValidationStatus.INSUFFICIENT_FOR_INTERNATIONAL_PAYMENT]: {
      msg: 'Swift code required',
      type: RoutingNumberValidationStatusType.ERROR,
    },
  };

  protected readonly bankIdNumValidationDebounceTime = 500;
  protected readonly usaCountryCode = 'US';

  constructor(protected supplierService: SupplierService, protected store: Store<AppState>, protected lookupRepo: LookupAbstractService) {
    super();
    this.setAvailableCountries();
  }

  protected setValidationStatusForBankIdNumControl(status: RoutingNumberValidationStatusConfig, control: AbstractControl): void {
    control.setErrors(status.type === RoutingNumberValidationStatusType.SUCCESS ? null : { error: true });
  }

  protected setBankIdNumValidation(
    control: AbstractControl,
    validationFn: () => Observable<RoutingNumberValidationStatus>,
  ): Observable<RoutingNumberValidationStatusConfig> {
    return this.form.valueChanges.pipe(
      debounceTime(this.bankIdNumValidationDebounceTime),
      startWith({}),
      switchMap(() => validationFn()),
      map((key) => this.routingNumberValidationStatuses[key]),
      tap((status) => this.setValidationStatusForBankIdNumControl(status, control)),
      tap(() => this.configForm.emit(this.form)),
    );
  }

  protected checkBankIdNumControl(
    bankIdNumCtrl: AbstractControl,
    countryCtrl: AbstractControl,
    options: { isRequiredForInternationalPayments?: boolean } = {},
  ): Observable<RoutingNumberValidationStatus> {
    const isInternationalPayment = countryCtrl.value !== this.usaCountryCode;

    if (isInternationalPayment) {
      return options.isRequiredForInternationalPayments && !bankIdNumCtrl.value
        ? of(RoutingNumberValidationStatus.INSUFFICIENT_FOR_INTERNATIONAL_PAYMENT)
        : of(RoutingNumberValidationStatus.NONE);
    }

    if (!bankIdNumCtrl.value || !countryCtrl.value) {
      return of(RoutingNumberValidationStatus.INSUFFICIENT_FOR_NATIONAL_PAYMENT);
    }

    return this.supplierService.checkRoutingNum(countryCtrl.value, bankIdNumCtrl.value).pipe(
      map((res) => (res.error ? RoutingNumberValidationStatus.INCORRECT_ROUTING_NUM : RoutingNumberValidationStatus.VERIFIED)),
      startWith(RoutingNumberValidationStatus.PENDING_VERIFICATION),
    );
  }

  protected setAvailableCountries() {
    this.store.dispatch(new FetchUtilAttribute({ attr: 'countries' }));
    this.countries$ = this.store.select(selectCountries).pipe(shareReplay(1));
  }

  protected setAvailableStatesOnCountryChange(countryControl: AbstractControl): Observable<IMatSelectOption[] | null> {
    return countryControl.valueChanges.pipe(
      startWith(countryControl.value),
      filter((c) => !!c),
      switchMap(this.lookupRepo.getStates.bind(this.lookupRepo)),
      map((states) => (states && states.length ? states : null)),
    );
  }

  protected setIsInternationalPayment(countryControl: AbstractControl): void {
    this.isInternationalPayment$ = countryControl.valueChanges.pipe(
      startWith(countryControl.value || this.usaCountryCode),
      map((value) => value !== this.usaCountryCode),
    );
  }
}
