<div>
  <mat-table #table [dataSource]="dataSource">
    <!-- TYPE -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span>{{ getEmailTypeName(element.type) }}</span>
      </mat-cell>
    </ng-container>

    <!-- CREATED AT -->
    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef>Created at</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.created_at | fromUnix | date: 'M/d/y h:mm a' }}
      </mat-cell>
    </ng-container>

    <!-- SUBJECT -->
    <ng-container matColumnDef="subject">
      <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!showPreviewEmailLink(element)">
          {{ element.subject }}
        </span>
        <span class="show-preview-dialog" *ngIf="showPreviewEmailLink(element)" (click)="previewDialog(element)">
          {{ element.subject }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- FROM -->
    <ng-container matColumnDef="from">
      <mat-header-cell *matHeaderCellDef>From</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.source }}
      </mat-cell>
    </ng-container>

    <!-- STATUS -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-email-status-badge [status]="element.status"></app-email-status-badge>
      </mat-cell>
    </ng-container>

    <!-- RECEIVER -->
    <ng-container matColumnDef="receiver">
      <mat-header-cell *matHeaderCellDef>Receiver</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.to }}
      </mat-cell>
    </ng-container>

    <!-- AUTHOR -->
    <ng-container matColumnDef="author">
      <mat-header-cell *matHeaderCellDef>Author</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.created_by_name }}
      </mat-cell>
    </ng-container>

    <!-- SUPPLIER -->
    <ng-container matColumnDef="supplier">
      <mat-header-cell *matHeaderCellDef>Supplier</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.supplier_name }}
      </mat-cell>
    </ng-container>

    <!-- ACTIONS -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button color="accent" *ngIf="showPreviewButton(element)" matTooltip="Preview" (click)="previewDialog(element)">
          <mat-icon>open_in_new</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          *ngIf="showSendAndCancelSingleButton(element)"
          (click)="acceptDialog(element)"
          matTooltip="Done"
        >
          <mat-icon class="mat-24" aria-label="Done">done</mat-icon>
        </button>
        <button mat-icon-button *ngIf="showSendAndCancelSingleButton(element)" (click)="cancelDialog(element)" matTooltip="Cancel">
          <mat-icon class="mat-24" aria-label="Cancel">clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>

  <mat-paginator [pageSize]="itemsPerPage"></mat-paginator>
</div>
