import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isUndefined } from 'util';

import { AppConfigService } from '../../config';
import Logger from '../../shared/logger';
import { LookupRepository } from '../../shared/lookup-service/lookup-repository';
import { UsersService } from '../../shared/users/service/users.service';
import { AppState } from '../../store/app.reducers';
import {
  LoggedUserInterface,
  LOGIN,
  LOGIN_FAILED,
  LoginFailed,
  LOGOUT,
  Logout,
  TRY_LOGIN,
  TRY_LOGIN_GOOGLE,
  TryLogin,
  TryLoginGoogle,
} from './auth.actions';

export const CURRENT_USER_KEY = 'currentUser';
export const LOGIN_REDIRECT_URL = 'loginRedirectUrl';

interface TokenContainer {
  expires_at: number;
  expires_in: number;
  first_issued_at: number;
  id_token: string;
  idpId: string;
  login_hint: string;
  session_state: any;
  token_type: string;
}

@Injectable()
export class AuthEffects {
  clientId: any;

  @Effect()
  authLoginGoogle = this.actions$.ofType(TRY_LOGIN_GOOGLE).pipe(
    map((action: TryLoginGoogle) => {
      return action.payload;
    }),
    switchMap((googleAuthResult: any) => {
      const credential = googleAuthResult.credential;
      if (!credential) {
        const error = 'Something change in google auth response';
        Logger.log('authLoginGoogle - catchError', error);
        return of({
          type: LOGIN_FAILED,
          payload: error,
        });
      }

      return this.lookupRepository.getAuthGoogleLogin(credential).pipe(
        mergeMap((result) => {
          const currentUser: LoggedUserInterface = {
            jwtToken: result.token,
            username: result.decoded.email,
          };
          return this.handleFullLogin(currentUser);
        }),
        catchError((error) => {
          Logger.log('authLoginGoogle - catchError', error);
          return of({
            type: LOGIN_FAILED,
            payload: error,
          });
        }),
      );
    }),
  );

  @Effect()
  authLogin = this.actions$.ofType(TRY_LOGIN).pipe(
    map((action: TryLogin) => {
      return action.payload;
    }),
    switchMap((authData: { username: string; password: string }) => {
      const authEndpoint = `${this.appConfig.env.internalApiUrl}auth/login`;
      const headers: { [name: string]: string } = {
        Authorization: 'Basic ' + btoa(authData.username + ':' + authData.password),
      };

      return this.httpClient
        .get(authEndpoint, {
          headers: new HttpHeaders(headers),
        })
        .pipe(
          mergeMap((authResponse: any) => {
            const currentUser: LoggedUserInterface = {
              jwtToken: authResponse.token,
              username: authData.username,
            };

            return this.handleFullLogin(currentUser);
          }),
          catchError((error) => {
            Logger.log('authLogin - catchError', error);
            return of({
              type: LOGIN_FAILED,
              payload: error,
            });
          }),
        );
    }),
  );

  @Effect({ dispatch: false })
  loginFailedCleanup = this.actions$.ofType(LOGIN_FAILED).pipe(
    map((action: LoginFailed) => {
      return action.payload;
    }),
    tap(() => {
      try {
        this.clearStorages();
      } catch (err) {
        Logger.log('loginFailedCleanup - err', err);
        this.clearStorages();
      }
    }),
  );

  @Effect({ dispatch: false })
  authLogout = this.actions$.ofType(LOGOUT).pipe(
    map((action: Logout) => {
      return action.payload;
    }),
    tap((payload) => {
      const params: any = {};
      if (payload.message) {
        params.message = payload.message;
      }

      try {
        this.clearStorages();

        this.router.navigate(['/login'], { queryParams: params });
      } catch (error) {
        Logger.log('authLogout - error', error);
        this.clearStorages();

        this.router.navigate(['/login'], { queryParams: params });
      }
    }),
  );

  @Effect({ dispatch: false })
  login = this.actions$.ofType(LOGIN).pipe(
    withLatestFrom(this.store$),
    tap(() => {
      let redirectHashUri = sessionStorage.getItem(LOGIN_REDIRECT_URL);

      if (isUndefined(redirectHashUri) || !redirectHashUri) {
        redirectHashUri = '/';
      }

      this.ngZone.run(() => this.router.navigate([redirectHashUri]));
    }),
  );

  private handleFullLogin(currentUser): Observable<{ type: string; payload: { user: any } }> {
    const loadUser$ = this.usersService.getUser(currentUser.username, currentUser.jwtToken).pipe(
      mergeMap((user) => {
        const perms = user.perms.map((value) => value.permCode);
        this.permissionsService.loadPermissions(perms);

        return [
          {
            type: LOGIN,
            payload: {
              user: { ...user, ...currentUser },
            },
          },
        ];
      }),
    );

    return loadUser$;
  }

  private clearStorages(): void {
    localStorage.clear();
  }

  constructor(
    private appConfig: AppConfigService,
    private actions$: Actions,
    private httpClient: HttpClient,
    private router: Router,
    private store$: Store<AppState>,
    private lookupRepository: LookupRepository,
    private usersService: UsersService,
    private permissionsService: NgxPermissionsService,
    private ngZone: NgZone,
  ) {
    this.clientId = appConfig.env.googleAuthClientId;
  }
}
