import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNullOrUndefined, isUndefined } from 'util';

import { AppState } from '../../../../store/app.reducers';
import { CrmItem } from '../../../models/crm-item.type';
import { listAnimation } from '../../../shared/cms.animations';
import { FetchCrmTasks, selectCrmTasks } from '../../../store/crm.actions';
import { ActionTabComponent } from '../action-tab-base.component';

@Component({
  selector: 'app-action-tab-tasks',
  templateUrl: './action-tab-tasks.component.html',
  animations: [trigger('listAnimation', [transition('* => *', [useAnimation(listAnimation)])])],
})
export class ActionTabTasksComponent extends ActionTabComponent {
  @Input() crmItems: { [uuid: string]: CrmItem };
  selector = selectCrmTasks;
  fetchAction = FetchCrmTasks;

  constructor(store: Store<AppState>) {
    super(store);
  }

  calculateLast() {
    if (!isNullOrUndefined(this.uuids)) {
      const lastUuid = this.uuids.slice(-1)[0];
      if (!isUndefined(lastUuid) && !isUndefined(this.crmItems[lastUuid])) {
        this.last = {
          target_uuid: this.crmItems[lastUuid].targetUuid,
          updated_at: this.crmItems[lastUuid].updatedAt,
          uuid: this.crmItems[lastUuid].uuid,
          instance_id: this.crmItems[lastUuid].instanceId,
        };
      }
    }
  }
}
