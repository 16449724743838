import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { uniq } from 'lodash';

import { SubscriberComponent } from '../../../../../../shared/component-subscriber/subscriber.component';
import { IProducts } from '../service/product-matrix-interfaces';
import { ErrorVisualizationService } from './../../../../../../shared/services/error-visualization/error-visualization.service';
import { ProductMatrixService } from './../service/product-matrix.service';

@Component({
  selector: 'app-product-frequent-earning-loading',
  templateUrl: './product-frequent-earning-loading.component.html',
  styleUrls: ['./product-frequent-earning-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFrequentEarningLoadingComponent extends SubscriberComponent implements OnInit {
  readonly PRODUCT_TYPE = 'frequent_earning_loading';
  readonly DEFAULT_RUN_TIME = ['16:00', '21:00']; // UTC

  @Input() response: IProducts;

  serviceColumns = ['marketplace', 'run_time', 'enabled'];
  dataSource: MatTableDataSource<Element>;
  data: any[];
  mpData: any;
  notOffered = false;

  constructor(
    private cd: ChangeDetectorRef,
    private productService: ProductMatrixService,
    private errorVisualizationService: ErrorVisualizationService,
  ) {
    super();
  }

  ngOnInit() {
    this.data = [];
    this.setUpDataFromResponse(this.response.frequent_earning_loading);
    this.dataSource = new MatTableDataSource(this.data);
    this.notOffered = !this.data.length;
  }

  activationProductRequest(e: any, element: any): void {
    let action;

    if (e.checked) {
      action = this.productService.enableProduct(element.marketplaceKey, this.PRODUCT_TYPE, {
        run_times: [...element.run_time.filter((r) => !!r).map((r) => r + ':00')],
      });
    } else {
      action = this.productService.disableProduct(element.marketplaceKey, this.PRODUCT_TYPE, { run_times: [] });
      element.run_time = this.setDefaultRunTime([]);
      this.adjustRunTimeArr(element);
    }

    this.subscriptions.push(
      action.subscribe(
        () => {},
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Activation Product Error', errorObject: err });
        },
      ),
    );
  }

  private setUpDataFromResponse(product: any): void {
    this.castDataSourceData(product.mp_data);
  }

  private castDataSourceData(mpData: any[]): void {
    for (const key in mpData) {
      if (!mpData.hasOwnProperty(key)) {
        continue;
      }

      const index = this.castDataSourceDataRow(mpData[key], key);
      this.adjustRunTimeArr(this.data[index - 1]);
    }
  }

  onRemoveTime(element: any, i: number): void {
    element.run_time.splice(i, 1);

    this.adjustRunTimeArr(element);

    if (!element.enabled) {
      return;
    }

    this.callToBE(element);
  }

  onChangeTime(value: string, element: any, i: number): void {
    element.run_time[i] = value;
    this.adjustRunTimeArr(element);

    if (!element.enabled) {
      return;
    }

    this.callToBE(element);
  }

  private adjustRunTimeArr(element: any): void {
    const tmp = [...element.run_time];
    element.run_time = [];

    setTimeout(() => {
      element.run_time = uniq([...tmp.filter((r) => !!r), null]);
      this.cd.detectChanges();
    }, 1);
  }

  private runTimeDto(time: string): string {
    return time.slice(0, -3);
  }

  private castDataSourceDataRow(mp: any, key: string): number {
    const run_time = this.setDefaultRunTime([...mp.run_time.map((rt) => this.runTimeDto(rt))]);

    return this.data.push({
      marketplace: mp.marketplace_name ? mp.marketplace_name : '-',
      marketplaceKey: key,
      enabled: mp.is_enabled,
      run_time,
    });
  }

  private callToBE(element: any): void {
    // ! ugly hack. Needs to be fix later after refactor
    setTimeout(() => {
      this.activationProductRequest({ checked: element.enabled }, element);
    }, 2);
  }

  private setDefaultRunTime(runTime: string[]): string[] {
    if (runTime.length) {
      return runTime;
    }

    return [...this.DEFAULT_RUN_TIME];
  }
}
