import { IEnvironment } from '../environments/environment.interface';
import { checkForSpecialOccasion } from './shared/utils/functions/check-for-special-occasion';

export class AppConfigService {
  get version(): string {
    return this._version;
  }

  get theme(): ThemeConfig {
    return this._theme;
  }

  get env(): IEnvironment {
    return this._env;
  }

  set env(env: IEnvironment) {
    this._env = env;
  }

  constructor(private _version: string, private _theme: ThemeConfig, private _env: IEnvironment) {}
}

export interface ThemeConfig {
  brand: string;
  user: string;
  year: number;
  layoutBoxed: boolean;
  navCollapsed: boolean;
  crmFixedDefault: boolean;
  crmFixed: boolean;
  navBehind: boolean;
  fixedHeader: boolean;
  sidebarWidth: string;
  theme: string;
  colorOption: string;
  AutoCloseMobileNav: boolean;
  productLink: string;
  applySpecialOccasion: boolean;
  specialOccasion: string;
}

function prepareThemeConfig(themeName = 'dev') {
  const date = new Date();
  const year = date.getFullYear();

  const settings: ThemeConfig = {
    brand: 'Payability',
    user: 'Lisa',
    year,
    layoutBoxed: false, // true, false
    navCollapsed: true, // true, false
    crmFixedDefault: true, // true, false
    crmFixed: true, // true, false
    navBehind: false, // true, false
    fixedHeader: false, // true, false
    sidebarWidth: 'small', // small, middle, large
    theme: 'light', // light, gray, dark
    colorOption: '11', // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    AutoCloseMobileNav: true, // true, false. Automatically close sidenav on route change (Mobile only)
    productLink: 'https://payability.com',
    applySpecialOccasion: true,
    specialOccasion: checkForSpecialOccasion(),
  };

  switch (themeName) {
    case 'prod':
      settings.colorOption = '11';
      break;
    default:
      settings.colorOption = '12';
  }

  return settings;
}

export const themeConfigData = prepareThemeConfig;
