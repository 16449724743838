export const COLS = 10;
export const ROWS = 18;
export const BLOCK_SIZE = 20;
export const LINES_PER_LEVEL = 3;
export const TETRIMINOS_SIZE = 4;
export const SHADOW_SIZE = 2;
export const MESSAGE_Y = 6;
export const MESSAGE_X = 1.4;

export interface IRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IEdge {
  horizontal: IRect;
  vertical: IRect;
}

export interface IChiaroscuro {
  outside: IEdge;
  inside: IEdge;
}

export interface IShading {
  light: IChiaroscuro;
  shadow: IChiaroscuro;
}

export class Rect implements IRect {
  public x: number;
  public y: number;
  public width: number;
  public height: number;

  constructor(params: IRect) {
    this.x = params.x;
    this.y = params.y;
    this.width = params.width;
    this.height = params.height;
  }
}

export class Edge implements IEdge {
  public horizontal: IRect;
  public vertical: IRect;
  constructor(params: IEdge) {
    this.vertical = params.vertical;
    this.horizontal = params.horizontal;
  }
}

export class Chiaroscuro implements IChiaroscuro {
  public outside: IEdge;
  public inside: IEdge;
  constructor(params: IChiaroscuro) {
    this.outside = params.outside;
    this.inside = params.inside;
  }
}

export class Shading implements IShading {
  public light: IChiaroscuro;
  public shadow: IChiaroscuro;
  constructor(params: IShading) {
    this.light = params.light;
    this.shadow = params.shadow;
  }
}

export const INSIDE_GAP = 0.2;
export const INNER_WIDTH = 1 - 2 * INSIDE_GAP;
export const INNER_HEIGHT = 0.9 - 2 * INSIDE_GAP;

export const PSEUDO_3D = new Shading({
  light: {
    outside: {
      horizontal: {
        x: 0.05,
        y: 0.05,
        width: 0.95,
        height: 0.05,
      },
      vertical: {
        x: 0.05,
        y: 0.05,
        width: 0.05,
        height: 0.95,
      },
    },
    inside: {
      horizontal: {
        x: INSIDE_GAP,
        y: INSIDE_GAP,
        width: INNER_WIDTH,
        height: 0.05,
      },
      vertical: {
        x: INSIDE_GAP,
        y: INSIDE_GAP,
        width: 0.05,
        height: INNER_HEIGHT,
      },
    },
  },
  shadow: {
    outside: {
      horizontal: {
        x: 0,
        y: 0.9,
        width: 1,
        height: 0.1,
      },
      vertical: {
        x: 0.9,
        y: 0,
        width: 0.1,
        height: 1,
      },
    },
    inside: {
      horizontal: {
        x: INSIDE_GAP,
        y: 0.9 - INSIDE_GAP,
        width: INNER_WIDTH,
        height: 0.05,
      },
      vertical: {
        x: 0.95 - INSIDE_GAP,
        y: INSIDE_GAP,
        width: 0.05,
        height: INNER_HEIGHT,
      },
    },
  },
});

export const COLORS = [
  'none',
  'rgba(0, 225, 225)',
  'rgba(0, 0, 225)',
  'rgba(225, 132, 0)',
  'rgba(225, 225, 0)',
  'rgba(0, 225, 0)',
  'rgba(225, 0, 225)',
  'rgba(225, 0, 0)',
];
export const COLORSLIGHTER = [
  'none',
  'rgba(132, 255, 255)',
  'rgba(92, 92, 255)',
  'rgba(255, 195, 132)',
  'rgba(255, 255, 132)',
  'rgba(132, 255, 132)',
  'rgba(255, 132, 255)',
  'rgba(255, 132, 132)',
];
export const COLORSDARKER = [
  'none',
  'rgba(0, 132, 132)',
  'rgba(0, 0, 132)',
  'rgba(132, 65, 0)',
  'rgba(132, 132, 0)',
  'rgba(0, 132, 0)',
  'rgba(132, 0, 132)',
  'rgba(132, 0, 0)',
];
export const SHAPES = [
  [],
  [
    [0, 0, 0, 0],
    [1, 1, 1, 1],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  [
    [2, 0, 0],
    [2, 2, 2],
    [0, 0, 0],
  ],
  [
    [0, 0, 3],
    [3, 3, 3],
    [0, 0, 0],
  ],
  [
    [4, 4],
    [4, 4],
  ],
  [
    [0, 5, 5],
    [5, 5, 0],
    [0, 0, 0],
  ],
  [
    [0, 6, 0],
    [6, 6, 6],
    [0, 0, 0],
  ],
  [
    [7, 7, 0],
    [0, 7, 7],
    [0, 0, 0],
  ],
];

export class KEY {
  static readonly ESC = 27;
  static readonly SPACE = 32;
  static readonly LEFT = 37;
  static readonly UP = 38;
  static readonly RIGHT = 39;
  static readonly DOWN = 40;
  static readonly W = 87;
  static readonly S = 83;
  static readonly A = 65;
  static readonly D = 68;
}

export class POINTS {
  static readonly SINGLE = 200;
  static readonly DOUBLE = 600;
  static readonly TRIPLE = 1000;
  static readonly TETRIS = 2600;
  static readonly SOFT_DROP = 1;
  static readonly HARD_DROP = 3;
}

export class LEVEL {
  static readonly 0 = 800;
  static readonly 1 = 620;
  static readonly 2 = 530;
  static readonly 3 = 450;
  static readonly 4 = 370;
  static readonly 5 = 280;
  static readonly 6 = 190;
  static readonly 7 = 150;
  static readonly 8 = 130;
  static readonly 9 = 100;
  static readonly 10 = 80;
  static readonly 11 = 80;
  static readonly 12 = 80;
  static readonly 13 = 70;
  static readonly 14 = 70;
  static readonly 15 = 70;
  static readonly 16 = 50;
  static readonly 17 = 50;
  static readonly 18 = 50;
  static readonly 19 = 30;
  static readonly 20 = 30;
}
