<div class="page-auth pyb-page-reset-password">
  <div class="main-body">
    <div class="card card-white">
      <div class="card-content">
        <div class="pyb-logo-background">Payability</div>

        <form name="material_login_form" class="mat-form-auth form-validation">
          <fieldset>
            <div class="form-group">
              <mat-form-field class="full-width mat-icon-left no-margin-bottom">
                <mat-icon class="material-icons">mail_outline</mat-icon>
                <input required matInput placeholder="Email" type="email" name="email" />
              </mat-form-field>
            </div>
            <p class="text-center text-small no-margin">
              Enter your email address that you used to register. We'll send you an email with your username and a link to reset your
              password.
            </p>

            <div class="divider divider-md"></div>
            <button [routerLink]="['/']" mat-raised-button type="button" color="primary" class="float-right">Reset</button>
          </fieldset>
        </form>

        <div class="additional-info">
          <a [routerLink]="['/login']">Login</a>
        </div>
      </div>
    </div>
  </div>
</div>
