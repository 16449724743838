import { Observable } from 'rxjs';

import { RepositoryInterface } from '../../shared/utils/repository-interface';
import { ReportListModel } from './report-list-model';
import { EditableReportFieldModel, EditableReportModel, ReportModel } from './report-model';

export abstract class ReportsAbstractService implements RepositoryInterface {
  abstract getItems(): Observable<ReportListModel[]>;
  abstract saveItem(uuid: string, item: ReportModel): Observable<ReportModel>;

  abstract getViewsAndTables(): Observable<string[]>;
  abstract getReportConfiguration(reportName: string): Observable<any>;

  abstract getReport(uuid: string): Observable<EditableReportModel>;
  abstract addReport(data: EditableReportModel): Observable<EditableReportModel>;
  abstract updateReport(uuid: string, data: EditableReportModel): Observable<any>;
  abstract removeReport(uuid: string): Observable<any>;
  abstract addReportField(uuid: string, data: EditableReportFieldModel): Observable<any>;
  abstract updateReportField(uuid: string, subKey: string, data: EditableReportFieldModel): Observable<any>;
}
