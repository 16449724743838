import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-business-cmp',
  templateUrl: './business.component.html',
})
export class BusinessComponent implements OnInit {
  public businessData: any;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.businessData = this.data;
  }
}
