import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { FeatureFlagsGeneralGuard } from '../guards/feature-flags-general.guard';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';
import { RouteAccessResolver } from '../shared/utils/route-access-resolver';
import { ExternalReportListComponent } from './external-report-list/external-report-list.component';
import { ReportAddComponent } from './report-add/report-add.component';
import { ReportEditComponent } from './report-edit/report-edit.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportTableComponent } from './report-table/report-table.component';
import { WaitingSuppliersReportComponent } from './waiting-suppliers/waiting-suppliers.component';

export const routes: Routes = [
  {
    path: 'reports',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
      },
    },
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: ReportListComponent },
      { path: 'external', component: ExternalReportListComponent },
      { path: 'new', component: ReportAddComponent },
      { path: 'waiting-suppliers', component: WaitingSuppliersReportComponent },
      { path: ':reportKey/edit', component: ReportEditComponent },
      { path: ':reportName', component: ReportTableComponent, data: { supplierKeyInQueryParamsNotNeeded: true } },
    ],
  },
];
