<div class="example-container mat-elevation-z8">
  <mat-card>
    <mat-card-title>
      <div class="row">
        <div class="col-md-6">Email Settings</div>
        <div *ngIf="hasCRMPermission" class="col-md-6 title-button">
          <button [disabled]="!canEdit" mat-raised-button color="accent" (click)="emailAddNewConfig()">
            <mat-icon class="mat-22">add</mat-icon>Add New
          </button>
        </div>
      </div>
    </mat-card-title>

    <mat-card-content>
      <div class="email-settings-table">
        <mat-table #table [dataSource]="dataSource" matSort [matSortDirection]="'desc'" [matSortActive]="'type'">
          <ng-container matColumnDef="configType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <app-email-type-badge [type]="element.configType"></app-email-type-badge>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ getConfigEmailTitle(element) }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="subject">
            <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.configValue.subject }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.configValue.description }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                *ngIf="hasCRMPermission"
                [disabled]="!canEdit"
                mat-raised-button
                class="mat-primary"
                (click)="emailEditConfigPreview(element)"
              >
                Edit
              </button>
              <button *ngIf="hasCRMPermission" mat-raised-button (click)="openDeleteEmailSettingComponent(element)" color="warn">
                Delete
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
