import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Deserialize, DeserializeKeysFrom, SerializeKeysTo } from 'cerialize';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { ServiceUpdatePartialInterface } from '../../shared/interfaces/service-update-partial-interface/service-update-partial-interface';
import { SupplierMarketplaceMinFeeOverridesModel } from '../model/supplier-marketplace-min-fee-overrides.model';

@Injectable()
export class SupplierMarketplaceMinFeeOverridesService implements ServiceUpdatePartialInterface {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers';
  }

  fetchItems(supplierKey, mpSupKey, refresh: boolean = false) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${mpSupKey}/min_fee_overrides`;
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient
      .get<SupplierMarketplaceMinFeeOverridesModel>(endpointUrl, {
        params: queryParams,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(result, SupplierMarketplaceMinFeeOverridesModel);
        }),
      );
  }

  saveItemPart(itemKey: string, itemPart: any, ...params): Observable<any> {
    const mpSupKey = params[0]; // TODO nkler: how to resolve it dynamicly but without indexed array?
    return this.saveMarketplaceOptInPeriodsDetails(itemKey, mpSupKey, itemPart);
  }

  saveMarketplaceOptInPeriodsDetails(supplierKey, mpSupKey, data) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${mpSupKey}/min_fee_overrides`;

    SerializeKeysTo(CamelCase);
    data = Deserialize(data, SupplierMarketplaceMinFeeOverridesModel);

    return this.httpClient.put(endpointUrl, data).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }
}
