import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isUndefined } from 'util';

import { SupplierVerifyDocumentDialogComponent } from '../../../supplier-onboarding/supplier-verify-document-dialog/supplier-verify-document-dialog.component';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-underwriting-files',
  templateUrl: './underwriting-files.component.html',
  styleUrls: ['./underwriting-files.component.scss'],
})
export class UnderwritingFilesComponent implements OnInit {
  @Input() amazonFilesDecision: number;
  @Input() underwritingImages = [];
  @Input() complianceDoctypes = [];
  @Input() isRestrictedUWView: boolean;

  readonly userPermission = USER_PERMISSION;

  constructor(public dialogService: MatDialog) {}

  ngOnInit() {}

  docTypeNameByKey(key) {
    let result;
    const foundedDocType = this.complianceDoctypes.filter(function (type) {
      return type.id === key;
    });

    if (!isUndefined(foundedDocType[0]) && !isUndefined(foundedDocType[0].label)) {
      result = foundedDocType[0].label;
    }

    return result;
  }

  openVerifyDocumentDialog(actionData, actionName: string) {
    if (!this.isRestrictedUWView) {
      const dialogRef = this.dialogService.open(SupplierVerifyDocumentDialogComponent, {
        data: { actionData, actionName },
        width: '600px',
      });

      dialogRef.afterClosed().subscribe(() => {});
    }
  }
}
