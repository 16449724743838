<h1 mat-dialog-title>Edit config</h1>

<div mat-dialog-content>
  <div [formGroup]="settingForm">
    <mat-form-field class="full-width">
      <input matInput placeholder="Config key" formControlName="config_key" />
      <mat-error>This field is required.</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="Config type" formControlName="config_type" />
      <mat-error>This field is required.</mat-error>
    </mat-form-field>

    <div [ngSwitch]="data.config_type" class="full-width">
      <input matInput *ngSwitchCase="'string'" placeholder="Config value" formControlName="config_value" />

      <input matInput *ngSwitchCase="'number'" placeholder="Config value" formControlName="config_value" />

      <app-editor *ngSwitchCase="'html'" formControlName="config_value" [toolsTheme]="TOOLS_THEME.EMAIL" #appEditor> </app-editor>

      <app-key-value-dictionary [configValue]="configValue" *ngSwitchCase="'dict'"></app-key-value-dictionary>

      <app-list-template [configValue]="configValue" *ngSwitchCase="'list'"></app-list-template>

      <app-nested-list-template [configValue]="configValue" *ngSwitchCase="'nested_list'"></app-nested-list-template>

      <app-task-template [configValue]="configValue" *ngSwitchCase="'task_template'"></app-task-template>

      <!-- <app-email-base-template [configValue]='configValue' *ngSwitchCase="'email_base_template'"></app-email-base-template>

      <app-email-frontend-template [configValue]='configValue' *ngSwitchCase="'email_frontend_template'"></app-email-frontend-template>

      <app-frontend-email-config [configValue]='configValue' *ngSwitchCase="'email_frontend_config'"></app-frontend-email-config>

      <app-batch-supplier-email [configValue]='configValue' *ngSwitchCase="'email_frontend_batch_config'"></app-batch-supplier-email>

      <app-email-config [configValue]='configValue' *ngSwitchCase="'email_config'"></app-email-config> -->
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Save</button>
  <button mat-raised-button (click)="closeDialog(false)" color="warn" tabindex="-1">Cancel</button>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
