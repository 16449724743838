<ng-template #loadingCard>
  <div class="card-content">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #loadingFrom>
  <div class="form-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>

<article class="article">
  <div class="row">
    <!-- TODO nkler: this column width should be resolved by "CSS viewports" -->
    <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-xl-6 col-lg-6'">
      <ng-container *ngIf="!hasAdvanceAuditorPermission; else advanceAuditor">
        <div
          class="card"
          [ngClass]="{
            'ui-ribbon-container hidden-supplier ui-ribbon-danger':
              (supplierGeneralModels$ | async) && supplierDetailsModel && supplierDetailsModel.hide
          }"
        >
          <div *ngIf="(supplierGeneralModels$ | async) && supplierDetailsModel && supplierDetailsModel.hide" class="ui-ribbon-wrapper">
            <div class="ui-ribbon">HIDDEN</div>
          </div>

          <div class="card-content" *ngIf="loaded; else loadingCard; let supplierInfo">
            <div class="top-box">
              <form
                *ngIf="formGroups[CONTACT_NAME_FORM]; else loadingFrom; let contactNameForm"
                app-card-formgroup
                novalidate
                #formGroupContactName
                class="supplier-contact-name"
                [formGroup]="contactNameForm"
                [title]="formGroupContactName.inEdit ? 'Contact person info' : ''"
                (onSave)="saveFormGroup(contactNameForm, CONTACT_NAME_FORM)"
              >
                <div [hidden]="formGroupContactName.inEdit" class="icon-box">
                  <div class="ibox-icon">
                    <a><i class="fa fa-user" aria-hidden="true"></i></a>
                  </div>
                  <div class="title">{{ contactNameForm.value['contactFirstName'] }} {{ contactNameForm.value['contactLastName'] }}</div>
                  <div class="subtitle">
                    {{ contactNameForm.get('contactRole').value }}
                  </div>
                  <div class="d-flex">
                    <div class="fake-field w-50">
                      <div class="label">Supplier Site</div>
                      <div class="value" [matMenuTriggerFor]="siteMenu">
                        <span *ngIf="supplierSite; else noSupplierSite">
                          {{ supplierSite }}
                        </span>
                        <ng-template #noSupplierSite> ------ </ng-template>
                        <button class="edit-button" mat-icon-button>
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>

                      <mat-menu #siteMenu="matMenu" class="version">
                        <button
                          mat-menu-item
                          class="version__button"
                          [class.version__button--enabled]="version === supplierSite"
                          [disabled]="version === supplierSite"
                          (click)="chooseSupplierSiteVersion(version)"
                          *ngFor="let version of supplierSiteVersions; trackBy: trackBy"
                        >
                          {{ version }}
                        </button>
                      </mat-menu>
                    </div>

                    <div class="w-50 fake-field" *ngIf="supplierInfo.onboardingTemplateCode">
                      <div class="label">Onboarding Template</div>
                      <span class="value value_simple">
                        <span class="badge badge-light" [ngClass]="supplierStatusClass">
                          {{ supplierInfo.onboardingTemplateCode }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div [hidden]="!formGroupContactName.inEdit">
                  <app-inline-edit-text
                    [formControlName]="'contactFirstName'"
                    [formGroup]="contactNameForm"
                    [label]="'First name'"
                    [inGroupEdit]="formGroupContactName.inEdit"
                    [disableInline]="true"
                  >
                  </app-inline-edit-text>

                  <app-inline-edit-text
                    [formControlName]="'contactLastName'"
                    [formGroup]="contactNameForm"
                    [label]="'Last name'"
                    [disableInline]="true"
                    [inGroupEdit]="formGroupContactName.inEdit"
                  >
                  </app-inline-edit-text>

                  <app-inline-edit-text
                    [formControlName]="'contactRole'"
                    [formGroup]="contactNameForm"
                    [label]="'Role'"
                    [disableInline]="true"
                    [inGroupEdit]="formGroupContactName.inEdit"
                  >
                  </app-inline-edit-text>
                </div>
              </form>
            </div>

            <form
              *ngIf="formGroups[CONTACT_DATA_FORM]; else loadingFrom; let contactDataForm"
              app-card-formgroup
              novalidate
              #formGroupContact
              class="supplier-contact-data"
              [formGroup]="contactDataForm"
              [title]="formGroupContact.inEdit ? 'Person info' : ''"
              (onSave)="saveFormGroup(contactDataForm, CONTACT_DATA_FORM)"
              data-test="contactDataForm"
            >
              <div class="row">
                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'contactEmail'"
                  [formGroup]="contactDataForm"
                  [materialIcon]="'mail_outline'"
                  type="email"
                  [label]="'Contact email'"
                  [inGroupEdit]="formGroupContact.inEdit"
                  [showCopy]="true"
                  (onSave)="saveSingleField('contactEmail', $event, CONTACT_DATA_FORM)"
                  data-test="contactEmailInput"
                >
                </app-inline-edit-text>

                <app-inline-edit-date
                  class="col-md-6"
                  [formControlName]="'dateOfBirth'"
                  [materialIcon]="'cake'"
                  [formGroup]="contactDataForm"
                  type="text"
                  [label]="'Date of Birth'"
                  [inGroupEdit]="formGroupContact.inEdit"
                  (onSave)="saveSingleField('dateOfBirth', $event, CONTACT_DATA_FORM)"
                  data-test="dateOfBirthInput"
                >
                </app-inline-edit-date>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'phoneNumber'"
                  [materialIcon]="'settings_phone'"
                  [formGroup]="contactDataForm"
                  [label]="'Phone number'"
                  [inGroupEdit]="formGroupContact.inEdit"
                  (onSave)="saveSingleField('phoneNumber', $event, CONTACT_DATA_FORM)"
                  data-test="phoneNumberInput"
                >
                </app-inline-edit-text>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'ownerSSN'"
                  [formGroup]="contactDataForm"
                  [label]="'SSN'"
                  [mask]="mask"
                  [showToggleMask]="true"
                  [disableInline]="!isAuthorizedToChangeSsn"
                  [disable]="!isAuthorizedToChangeSsn"
                  [disabled]="!isAuthorizedToChangeSsn"
                  *ngIf="(isAuthorizedToChangeSsn && formGroupContact.inEdit) || !formGroupContact.inEdit"
                  [inGroupEdit]="formGroupContact.inEdit"
                  (onSave)="saveSSN($event, CONTACT_DATA_FORM)"
                  data-test="ssnInput"
                >
                </app-inline-edit-text>
              </div>
            </form>

            <form *ngIf="formGroups[CONTACT_DATA_FORM]; let contactDataFormExtended" novalidate [formGroup]="contactDataFormExtended">
              <app-inline-edit-text
                *ngIf="supplierDetailsModel.businessWebsite"
                class="col-md-6"
                [materialIcon]="'web'"
                [formControlName]="'businessWebsite'"
                [formGroup]="contactDataFormExtended"
                [clearable]="false"
                [disabled]="true"
                [disableInline]="true"
                [label]="'Store Website'"
              >
              </app-inline-edit-text>
            </form>

            <div class="supplier-actions">
              <button
                mat-raised-button
                class="btn-w-md pull-right"
                (click)="openChangePasswordDialog()"
                [color]="'primary'"
                data-test="changePasswordButton"
              >
                Change password
              </button>
              <app-suspend-supplier></app-suspend-supplier>
              <app-supplier-unlock></app-supplier-unlock>
              <app-toggle-hide-supplier></app-toggle-hide-supplier>
              <button
                *ngIf="cancellationStatusChecked"
                mat-raised-button
                color="{{ cancellationBtnColor }}"
                class="btn-w-md cancel-btn"
                (click)="openCancelSupplierDialog()"
              >
                {{ cancellationBtnText }}
              </button>
              <button mat-raised-button class="btn-w-md" color="warn" (click)="onCreateTransferRequestClick()">
                Create Transfer Request
              </button>
              <ng-container *appEnabledByFeatureFlag="featureFlags.DELETE_SUPPLIER">
                <app-delete-supplier-account
                  *ngxPermissionsOnly="[userPermission.DEV, userPermission.CUST_SERVICE, userPermission.FINANCE]"
                  [supplierKey]="supplierKey"
                >
                </app-delete-supplier-account>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="card" [ngClass]="{ 'hidden-supplier': supplierDetailsModel && supplierDetailsModel.hide }">
          <div class="card-content" *ngIf="loaded; else loadingCard">
            <div class="top-box">
              <form
                *ngIf="formGroups[LEGAL_FORM]; else loadingFrom; let legalForm"
                app-card-formgroup
                #formGroupLegal
                [title]="formGroupLegal.inEdit ? 'Bussiness info' : ''"
                [formGroup]="legalForm"
                (onSave)="saveFormGroup(legalForm, LEGAL_FORM)"
              >
                <div *ngIf="!formGroupLegal.inEdit; else showLegalForm" class="icon-box">
                  <div class="ibox-icon">
                    <a><i class="fa fa-building-o" aria-hidden="true"></i></a>
                  </div>

                  <div class="card-title d-inline-block">{{ legalForm.value['legalName'] }}</div>
                  <div class="supplier-login">
                    {{ businessCodeLabel }}
                  </div>
                  <div class="supplier-original-name" *ngIf="companyDBA">DBA Name: {{ companyDBA }}</div>
                </div>

                <ng-template #showLegalForm>
                  <app-inline-edit-text
                    [formControlName]="'legalName'"
                    [formGroup]="legalForm"
                    [label]="'Legal name'"
                    [inGroupEdit]="formGroupLegal.inEdit"
                    (onSave)="saveSingleField('legalName', $event, LEGAL_FORM)"
                  >
                  </app-inline-edit-text>
                  <app-inline-edit-select
                    [formControlName]="'businessTypeCode'"
                    [formGroup]="legalForm"
                    [label]="'Business Type'"
                    (translatedValue)="setBusinessCodeLabel($event)"
                    [inGroupEdit]="formGroupLegal.inEdit"
                    (onSave)="saveSingleField('businessTypeCode', $event, LEGAL_FORM)"
                    [items]="businessTypes"
                  >
                  </app-inline-edit-select>
                </ng-template>
              </form>
            </div>

            <form
              *ngIf="formGroups[ADDRESS_FORM]; else loadingFrom; let addressForm"
              app-card-formgroup
              [title]="'Business Address'"
              #formGroupAddress
              [formGroup]="addressForm"
              (onSave)="saveFormGroup(addressForm, ADDRESS_FORM)"
            >
              <div class="row">
                <app-inline-edit-text
                  class="col-md-12"
                  [formControlName]="'addr1'"
                  type="text"
                  [formGroup]="addressForm"
                  [label]="'Address Line 1'"
                  [inGroupEdit]="formGroupAddress.inEdit"
                  [disableInline]="true"
                >
                </app-inline-edit-text>

                <app-inline-edit-text
                  class="col-md-12"
                  [formControlName]="'addr2'"
                  type="text"
                  [formGroup]="addressForm"
                  [label]="'Address Line 2'"
                  [inGroupEdit]="formGroupAddress.inEdit"
                  [disableInline]="true"
                >
                </app-inline-edit-text>

                <app-inline-edit-select
                  class="col-md-6"
                  [formControlName]="'country'"
                  [label]="'Country'"
                  [formGroup]="addressForm"
                  [inGroupEdit]="formGroupAddress.inEdit"
                  [disableInline]="true"
                  [items]="addressCountries"
                >
                </app-inline-edit-select>

                <app-inline-edit-select
                  class="col-md-6"
                  [formControlName]="'state'"
                  [label]="'State'"
                  [formGroup]="addressForm"
                  [inGroupEdit]="formGroupAddress.inEdit"
                  [disableInline]="true"
                  [items]="addressStates"
                >
                </app-inline-edit-select>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'city'"
                  type="text"
                  [label]="'City'"
                  [formGroup]="addressForm"
                  [inGroupEdit]="formGroupAddress.inEdit"
                  [disableInline]="true"
                >
                </app-inline-edit-text>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'postalCode'"
                  type="text"
                  [formGroup]="addressForm"
                  [label]="'Zip'"
                  [inGroupEdit]="formGroupAddress.inEdit"
                  [disableInline]="true"
                >
                </app-inline-edit-text>
              </div>
            </form>

            <form
              *ngIf="formGroups[SHIPPING_ADDRESS_FORM]; let shippingAddressForm"
              app-card-formgroup
              [title]="'Shipping Address'"
              #formGroupShippingAddress
              [formGroup]="shippingAddressForm"
              (onSave)="saveShippingAddressForm(shippingAddressForm)"
            >
              <div class="row">
                <app-inline-edit-text
                  class="col-md-12"
                  [formControlName]="'address'"
                  type="text"
                  [formGroup]="shippingAddressForm"
                  [label]="'Address Line 1'"
                  [inGroupEdit]="formGroupShippingAddress.inEdit"
                  [disableInline]="true"
                >
                </app-inline-edit-text>

                <app-inline-edit-text
                  class="col-md-12"
                  [formControlName]="'address2'"
                  type="text"
                  [formGroup]="shippingAddressForm"
                  [label]="'Address Line 2'"
                  [inGroupEdit]="formGroupShippingAddress.inEdit"
                  [disableInline]="true"
                >
                </app-inline-edit-text>

                <app-inline-edit-select
                  class="col-md-6"
                  [formControlName]="'country'"
                  [label]="'Country'"
                  [formGroup]="shippingAddressForm"
                  [inGroupEdit]="formGroupShippingAddress.inEdit"
                  [disableInline]="true"
                  [items]="addressCountries"
                >
                </app-inline-edit-select>

                <app-inline-edit-select
                  class="col-md-6"
                  [formControlName]="'stateCode'"
                  [label]="'State'"
                  [formGroup]="shippingAddressForm"
                  [inGroupEdit]="formGroupShippingAddress.inEdit"
                  [disableInline]="true"
                  [items]="addressStates"
                >
                </app-inline-edit-select>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'city'"
                  type="text"
                  [label]="'City'"
                  [formGroup]="shippingAddressForm"
                  [inGroupEdit]="formGroupShippingAddress.inEdit"
                  [disableInline]="true"
                >
                </app-inline-edit-text>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'zipPostalCode'"
                  type="text"
                  [formGroup]="shippingAddressForm"
                  [label]="'Zip'"
                  [inGroupEdit]="formGroupShippingAddress.inEdit"
                  [disableInline]="true"
                >
                </app-inline-edit-text>
              </div>
            </form>

            <div *ngIf="supplierDetailsModel.businessTypeCode === 'LLC' || supplierDetailsModel.businessTypeCode === 'CORP'">
              <form
                *ngIf="formGroups[TAX_FORM]; else loadingFrom; let taxForm"
                app-card-formgroup
                #formGroupTax
                [title]="'TAX data'"
                [formGroup]="taxForm"
                (onSave)="saveFormGroup(taxForm, TAX_FORM)"
              >
                <app-inline-edit-text
                  [formControlName]="'taxId'"
                  [formGroup]="taxForm"
                  [isRequired]="false"
                  [label]="'EIN'"
                  [disableInline]="true"
                  [inGroupEdit]="formGroupTax.inEdit"
                  (onSave)="saveSingleField('taxId', $event, TAX_FORM)"
                >
                </app-inline-edit-text>
              </form>
            </div>

            <form
              *ngIf="formGroups[TIMEZONE_FORM]; else loadingFrom; let timezoneForm"
              app-card-formgroup
              #formGroupTimezone
              [title]="'Timezone'"
              [formGroup]="timezoneForm"
              (onSave)="saveFormGroup(timezoneForm, TIMEZONE_FORM)"
            >
              <app-inline-edit-select
                [formControlName]="'timezone'"
                [formGroup]="timezoneForm"
                [label]="'Timezone'"
                [disableInline]="true"
                [inGroupEdit]="formGroupTimezone.inEdit"
                (onSave)="saveSingleField('timezone', $event, TIMEZONE_FORM)"
                [items]="timezones"
              >
              </app-inline-edit-select>
            </form>

            <form
              *ngIf="formGroups[INCORPORATION_FORM]; else loadingFrom; let incorporationForm"
              app-card-formgroup
              novalidate
              [title]="'Incorporation'"
              #formGroupIncorporated
              [formGroup]="incorporationForm"
              (onReset)="resetIncorporationForm($event)"
              (onSave)="saveFormGroup(incorporationForm, INCORPORATION_FORM)"
            >
              <div class="row">
                <app-inline-edit-yes-no
                  class="col-md-4"
                  [formControlName]="'incorporatedInTheUS'"
                  [formGroup]="incorporationForm"
                  [label]="'Incorporated in US?'"
                  [inGroupEdit]="formGroupIncorporated.inEdit"
                  (onSave)="saveSingleField('incorporatedInTheUS', $event, INCORPORATION_FORM)"
                >
                </app-inline-edit-yes-no>

                <app-inline-edit-select
                  class="col-md-4"
                  [formControlName]="'stateOfIncorporation'"
                  [formGroup]="incorporationForm"
                  [label]="'State of incorporation'"
                  [inGroupEdit]="formGroupIncorporated.inEdit"
                  (onSave)="saveSingleField('stateOfIncorporation', $event, INCORPORATION_FORM)"
                  [disabled]="!supplierFormBuilder.incorporatedInTheUS$ | async"
                  [items]="addressStates"
                >
                </app-inline-edit-select>

                <app-inline-edit-date
                  class="col-md-4"
                  [formControlName]="'dateEstablished'"
                  [formGroup]="incorporationForm"
                  [label]="'Business Start Date'"
                  [inGroupEdit]="formGroupIncorporated.inEdit"
                  (onSave)="saveSingleField('dateEstablished', $event, INCORPORATION_FORM)"
                >
                </app-inline-edit-date>
              </div>
            </form>
          </div>
        </div>

        <div class="card" [ngClass]="{ 'hidden-supplier': supplierDetailsModel && supplierDetailsModel.hide }">
          <div class="card-content" *ngIf="owners; else loadingCard">
            <span class="card-title"
              >Users ({{ owners?.length }})
              <a (click)="addNewUser()">
                <mat-icon class="pull-right">add</mat-icon>
              </a>
            </span>

            <div class="table-wrapper">
              <table class="table table-condensed table-hover">
                <thead>
                  <tr>
                    <th width="20%">User</th>
                    <th width="20%">
                      <span>Login Email</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let owner of owners">
                    <td width="20%">{{ owner?.firstName }} {{ owner?.lastName }}</td>
                    <td width="20%">
                      {{ owner?.email }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button mat-raised-button class="btn-w-md pull-left" (click)="viewAll()" [color]="">View All</button>
          </div>
        </div>

        <ng-container *appEnabledByFeatureFlag="featureFlags.TWO_FA">
          <ng-container *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
            <app-supplier-2fa [selectSupplierDetails$]="selectSupplierDetails$"></app-supplier-2fa>
          </ng-container>
        </ng-container>

        <div class="card" [ngClass]="{ 'hidden-supplier': supplierDetailsModel && supplierDetailsModel.hide }">
          <div class="card-content" *ngIf="(selectSupplierDetails$ | async) && (selectFundingParticulars$ | async); else loadingCard">
            <span class="card-title"
              >Summary<a
                mat-raised-button
                color="primary"
                class="btn-sm pull-right"
                ngxClipboard
                [cbContent]="generateLink(supplierDetailsModel)"
                matTooltip="Click to copy referral link"
                >Referral Link</a
              >
            </span>

            <!-- Supplier Details Form -->
            <form
              *ngIf="formGroups[GENERAL_INFO_FORM]; else loadingFrom; let generalInfoForm"
              novalidate
              app-card-formgroup
              [title]="'General Info'"
              [formGroup]="generalInfoForm"
              #generalInfoComponent
              (onSave)="saveFormGroup(convertNoneStringToNullInForm(generalInfoForm), GENERAL_INFO_FORM)"
            >
              <div class="row">
                <app-inline-edit-yes-no
                  class="col-md-6"
                  [formControlName]="'instantAccess'"
                  [formGroup]="generalInfoForm"
                  [inGroupEdit]="generalInfoComponent.inEdit"
                  [label]="'InstantAccess'"
                  (onSave)="saveSingleField('instantAccess', $event, GENERAL_INFO_FORM)"
                  type="text"
                >
                </app-inline-edit-yes-no>

                <app-inline-edit-select
                  class="col-md-6"
                  [formControlName]="'cancelationStatus'"
                  [formGroup]="generalInfoForm"
                  [label]="'Cancelation Status'"
                  [inGroupEdit]="generalInfoComponent.inEdit"
                  (onSave)="saveSingleField('cancelationStatus', convertNoneStringToNull($event), GENERAL_INFO_FORM)"
                  [items]="cancellationStatuses"
                >
                </app-inline-edit-select>

                <app-inline-edit-yes-no
                  class="col-md-6"
                  [formControlName]="'pendApprovNeeded'"
                  [formGroup]="generalInfoForm"
                  [inGroupEdit]="generalInfoComponent.inEdit"
                  [label]="'Enable Auto-Approval'"
                  (onSave)="saveSingleField('pendApprovNeeded', $event, GENERAL_INFO_FORM)"
                  type="text"
                >
                </app-inline-edit-yes-no>

                <app-inline-edit-yes-no
                  class="col-md-6"
                  [formControlName]="'sendAutoEmail'"
                  [formGroup]="generalInfoForm"
                  [inGroupEdit]="generalInfoComponent.inEdit"
                  [label]="'Auto transfer Email'"
                  (onSave)="saveSingleField('sendAutoEmail', $event, GENERAL_INFO_FORM)"
                >
                </app-inline-edit-yes-no>
              </div>
            </form>
            <!-- END Supplier Details Form -->

            <!-- Funding Particular Form -->
            <form
              *ngIf="formGroups[FUNDING_PARTICULARS_FORM]; else loadingFrom; let fundingParticularsForm"
              app-card-formgroup
              [title]="'Funding Particulars'"
              [formGroup]="fundingParticularsForm"
              #fundingParticularsComponent
              (onSave)="saveFormGroup(fundingParticularsForm, FUNDING_PARTICULARS_FORM)"
            >
              <div class="row">
                <app-inline-edit-yes-no
                  class="col-md-6"
                  [formControlName]="'legalSigned'"
                  [formGroup]="fundingParticularsForm"
                  [inGroupEdit]="fundingParticularsComponent.inEdit"
                  [label]="'Legal Agreement Signed'"
                  [disableInline]="true"
                  (onSave)="saveSingleField('legalSigned', $event, FUNDING_PARTICULARS_FORM)"
                  type="text"
                >
                </app-inline-edit-yes-no>

                <app-inline-edit-select
                  class="col-md-6"
                  [formControlName]="'fundPartStatus'"
                  [label]="'Status'"
                  [formGroup]="fundingParticularsForm"
                  [disableInline]="true"
                  [inGroupEdit]="fundingParticularsComponent.inEdit"
                  (onSave)="saveSingleField('fundPartStatus', $event, FUNDING_PARTICULARS_FORM)"
                  [items]="fundStatusList"
                >
                </app-inline-edit-select>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'fundFacilityLimit'"
                  type="text"
                  [formGroup]="fundingParticularsForm"
                  [label]="'Funding Facility Limit'"
                  [disableInline]="true"
                  [inGroupEdit]="fundingParticularsComponent.inEdit"
                  (onSave)="saveSingleField('fundFacilityLimit', $event, FUNDING_PARTICULARS_FORM)"
                >
                </app-inline-edit-text>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'guarantor'"
                  type="text"
                  [formGroup]="fundingParticularsForm"
                  [label]="'Guarantor'"
                  [disableInline]="true"
                  [inGroupEdit]="fundingParticularsComponent.inEdit"
                  (onSave)="saveSingleField('guarantor', $event, FUNDING_PARTICULARS_FORM)"
                >
                </app-inline-edit-text>

                <app-inline-edit-text
                  class="col-md-6"
                  [formControlName]="'supplierCovenants'"
                  type="text"
                  [formGroup]="fundingParticularsForm"
                  [label]="'Further Supplier Covenants'"
                  [disableInline]="true"
                  [inGroupEdit]="fundingParticularsComponent.inEdit"
                  (onSave)="saveSingleField('supplierCovenants', $event, FUNDING_PARTICULARS_FORM)"
                >
                </app-inline-edit-text>
              </div>
            </form>
            <!-- END Funding Particular Form -->

            <!-- Auto Payment Form -->
            <form
              *ngIf="formGroups[AUTO_PAYMENT_FORM]; else loadingFrom; let autoPaymentForm"
              novalidate
              app-card-formgroup
              [title]="'Auto Payment'"
              [formGroup]="autoPaymentForm"
              (onSave)="saveFormGroup(autoPaymentForm, AUTO_PAYMENT_FORM)"
              #generalInfoComponent
            >
              <div class="row">
                <!-- Supplier auto payment -->
                <app-inline-edit-yes-no
                  class="col-md-6"
                  [formControlName]="'hasAutoTransferEnabled'"
                  [formGroup]="autoPaymentForm"
                  [inGroupEdit]="generalInfoComponent.inEdit"
                  [label]="'Has Auto Transfer'"
                  (onSave)="saveSingleField('hasAutoTransferEnabled', $event, AUTO_PAYMENT_FORM)"
                >
                </app-inline-edit-yes-no>
                <!-- END supplier auto payment -->

                <app-inline-edit-yes-no
                  class="col-md-6"
                  [formControlName]="'hasForwardPaymentEnabled'"
                  [formGroup]="autoPaymentForm"
                  [inGroupEdit]="generalInfoComponent.inEdit"
                  [label]="'Has Forward Payment'"
                  (onSave)="saveSingleField('hasForwardPaymentEnabled', $event, AUTO_PAYMENT_FORM)"
                >
                </app-inline-edit-yes-no>
                </div>
              </form>
              <!-- END Auto Payment Form -->

          </div>
        </div>
      </ng-container>
      <ng-template #advanceAuditor>
        <app-supplier-general-advance-auditor *ngIf="loaded; else loadingCard" [supplierDetails]="supplierDetailsModel">
        </app-supplier-general-advance-auditor>
      </ng-template>
    </div>
  </div>
</article>
