import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-mat-select-cell',
  templateUrl: './ag-mat-select-cell.component.html',
})
export class PybAgMatSelectCellComponent implements ICellRendererAngularComp {
  params: any;
  value: string;
  options: any;

  refresh(params: any): boolean {
    if (params.value !== this.params.value) {
      this.params = params;
    }
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  destroy() {}

  agInit(params: any): void {
    this.value = params.value;
    this.options = params.values;
  }

  getValue(): any {
    return this.value;
  }
}
