import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../../config';
import { ICorelation, IRealTimeValidator, IRealTimeValidatorDTO, ITRDataError, ITRTaskDTO } from './real-time-validator.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RealTimeValidatorService implements IRealTimeValidator {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = (appConfig.env.automaticMarketplaceApiUrl as string).endsWith('/')
      ? appConfig.env.automaticMarketplaceApiUrl
      : appConfig.env.automaticMarketplaceApiUrl + '/';
    this.apiUrl += 'tasks/';
  }

  public getRealTimeValidation(supplierKey: string, loginKey: string): Observable<IRealTimeValidatorDTO> {
    const endpointUrl = `${this.apiUrl}result`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('supplier_key', supplierKey);
    queryParams = queryParams.append('process_name', 'thomson_reuters/request_personal_information_validation');
    queryParams = queryParams.append('person_id', loginKey);
    queryParams = queryParams.append('detailed', 'true');

    return this.httpClient.get<IRealTimeValidatorDTO>(endpointUrl, {
      params: queryParams,
    });
  }

  public requestValidation(supplierKey: string, loginKey: string, taskDTO: ITRTaskDTO | ITRDataError): Observable<ICorelation> {
    const endpointUrl = `${this.apiUrl}execute`;

    return this.httpClient.post<ICorelation>(endpointUrl, taskDTO);
  }

  public getValidationStatus(corelationId: string) {
    const endpointUrl = `${this.apiUrl}${corelationId}`;
    return this.httpClient.get<any>(endpointUrl);
  }
}
