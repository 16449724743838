import { autoserialize } from 'cerialize';

export class SupplierMarketplaceOptInPeriodModel {
  @autoserialize
  mpSupOptInPeriodKey: string;

  @autoserialize
  endDate: string;

  @autoserialize
  startDate: string;

  constructor(mpSupOptInPeriodKey: string, endDate: string, startDate: string) {
    this.mpSupOptInPeriodKey = mpSupOptInPeriodKey;
    this.endDate = endDate;
    this.startDate = startDate;
  }
}
