import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { GlobalSetting } from '../models/global-setting.model';
import { AppConfigService } from './../../../config';
import { AppState } from './../../../store/app.reducers';
import { GlobalSettingsResponse } from './../models/global-setting.model';
import { GlobalSettingsFetchAll, GlobalSettingsUpdateConfig } from './../store/global-settings.actions';
import { selectConfigs, selectLoading } from './../store/global-settings.selectors';

@Injectable()
export class GlobalSettingsService {
  private readonly INSTANCE_ID_KEY = 'x-instanceid';
  private actionsToolsUrl: string;
  private headers: HttpHeaders;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient, private store$: Store<AppState>) {
    this.actionsToolsUrl = appConfig.env.actionsToolsUrl;
    this.headers = new HttpHeaders().set(this.INSTANCE_ID_KEY, '' + appConfig.env.instanceId);
  }

  getAll(force: boolean = false): Observable<GlobalSetting[]> {
    return this.store$.select(selectConfigs).pipe(
      withLatestFrom(this.store$.select(selectLoading)),
      map(([configs, loading]) => this.mapAllConfigs(force, configs, loading)),
      tap(() => (force = false)),
      filter((configs) => !!configs),
    );
  }

  getAllByType(type: string): Observable<GlobalSetting[]> {
    return this.httpClient
      .get(`${this.actionsToolsUrl}settings/${type}/items`, { headers: this.headers })
      .pipe(map((data: GlobalSettingsResponse) => this.castEntity(data)));
  }

  get(config_key: string, force: boolean = false): Observable<GlobalSetting> {
    let obs$ = this.fetchOne(config_key);

    if (!force) {
      obs$ = this.getAll().pipe(switchMap((data) => this.findOrFetchConfig(config_key, data)));
    }

    return this.listenAndRespond(obs$);
  }

  add(data: GlobalSetting): Observable<GlobalSetting> {
    const obs$ = this.httpClient
      .post(`${this.actionsToolsUrl}settings/`, data, { headers: this.headers })
      .pipe(map((response: GlobalSettingsResponse) => this.castEntity(response)));

    return this.listenAndRespond(obs$);
  }

  edit(config_key: string, data: Partial<GlobalSetting>): Observable<GlobalSetting> {
    const obs$ = this.httpClient
      .put(`${this.actionsToolsUrl}settings/${config_key}/`, data, { headers: this.headers })
      .pipe(map((response: GlobalSettingsResponse) => this.castEntity(response)));

    return this.listenAndRespond(obs$);
  }

  delete(config_key: string): Observable<Partial<GlobalSetting>> {
    const obs$ = this.httpClient
      .delete(`${this.actionsToolsUrl}settings/${config_key}/`, { headers: this.headers })
      .pipe(map((response: GlobalSettingsResponse) => this.castEntity(response)));

    return this.listenAndRespond(obs$);
  }

  fetchAll(): Observable<GlobalSetting[]> {
    return this.httpClient
      .get(`${this.actionsToolsUrl}settings/`, { headers: this.headers })
      .pipe(map((data: GlobalSettingsResponse) => this.castEntity(data)));
  }

  private mapAllConfigs(force: boolean, configs: GlobalSetting[], loading: boolean): GlobalSetting[] {
    if (loading) {
      return null;
    }

    if (force) {
      this.store$.dispatch(new GlobalSettingsFetchAll());
      return null;
    }

    if (configs) {
      return configs;
    }

    this.store$.dispatch(new GlobalSettingsFetchAll());

    return null;
  }

  private listenAndRespond(observer$: Observable<GlobalSetting | GlobalSetting[]>): Observable<GlobalSetting & GlobalSetting[]> {
    return observer$.pipe(
      catchError((error) => of(error)),
      switchMap((config) => (config instanceof HttpErrorResponse ? throwError(config) : of(config))),
      tap((config) => this.updateConfig(config)),
    );
  }

  private findOrFetchConfig(config_key: string, data: GlobalSetting[]): Observable<GlobalSetting> {
    const config = data.find((e) => e.config_key === config_key);
    return config ? of(config) : this.fetchOne(config_key);
  }

  private fetchOne(config_key: string): Observable<GlobalSetting> {
    return this.httpClient.get(`${this.actionsToolsUrl}settings/${config_key}/`, { headers: this.headers }).pipe(
      map((data: any) => this.castEntity(data)),
      catchError(() => of(null)),
    );
  }

  private castEntity(data: GlobalSettingsResponse): GlobalSetting & GlobalSetting[] {
    return data.data;
  }

  private updateConfig(config: GlobalSetting): void {
    if (!config) {
      return;
    }

    this.store$.dispatch(new GlobalSettingsUpdateConfig({ config }));
  }
}
