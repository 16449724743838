import { COLORS, SHAPES } from '../constants';

export interface IPiece {
  x: number;
  y: number;
  shape: number[][];
  typeId: number;
}

export class Piece implements IPiece {
  x: number;
  y: number;
  shape: number[][];
  typeId: number;

  constructor(
    private ctx: CanvasRenderingContext2D,
    private add3D: (ctx: CanvasRenderingContext2D, x: number, y: number, color: number) => void,
  ) {
    this.spawn();
  }

  spawn() {
    this.typeId = this.randomizeTetrominoType(COLORS.length - 1);
    this.shape = SHAPES[this.typeId];
    this.x = this.typeId === 4 ? 4 : 3;
    this.y = 0;
  }

  private addNextShadow(ctx: CanvasRenderingContext2D, x: number, y: number): void {
    ctx.fillStyle = 'black';
    ctx.fillRect(x, y, 1.025, 1.025);
  }

  draw() {
    this.shape.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value > 0) {
          this.ctx.fillStyle = COLORS[this.typeId];
          const currentX = this.x + x;
          const currentY = this.y + y;
          this.ctx.fillRect(currentX, currentY, 1, 1);
          this.add3D(this.ctx, currentX, currentY, this.typeId);
        }
      });
    });
  }

  drawNext(ctxNext: CanvasRenderingContext2D) {
    ctxNext.clearRect(0, 0, ctxNext.canvas.width, ctxNext.canvas.height);
    this.shape.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value > 0) {
          this.addNextShadow(ctxNext, x, y);
        }
      });
    });

    ctxNext.fillStyle = COLORS[this.typeId];
    this.shape.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value > 0) {
          ctxNext.fillStyle = COLORS[this.typeId];
          const currentX = x + 0.025;
          const currentY = y + 0.025;
          ctxNext.fillRect(currentX, currentY, 1 - 0.025, 1 - 0.025);
          this.add3D(ctxNext, currentX, currentY, this.typeId);
        }
      });
    });
  }

  move(p: IPiece) {
    this.x = p.x;
    this.y = p.y;
    this.shape = p.shape;
  }

  randomizeTetrominoType(noOfTypes: number): number {
    return Math.floor(Math.random() * noOfTypes + 1);
  }
}
