<div class="dailySummaryReport__title">
  <div>
    <h5>Daily Summary Report</h5>
  </div>
  <div>
    <button mat-button (click)="exportToCsv()">
      <i class="material-icons">archive</i>
      Export to CSV
    </button>
  </div>
</div>

<app-filtering-panel-component *ngIf="filterConfig" (searchValueEmitter)="getData($event)" [configuration]="filterConfig">
</app-filtering-panel-component>

<div *ngIf="dataRows" data-test="suppliersTransactionSummaryDailySummaryReport">
  <app-data-table-grid
    [rows]="dataRows"
    [columnDefs]="columnsPredefinitions"
    [treeData]="true"
    [summed]="summed"
    [customGridOptions]="customGridOptions"
  >
  </app-data-table-grid>
</div>
