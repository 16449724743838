import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { getNoRebate } from '../../models/get-no-rebate.function';
import { SubscriberComponent } from './../../../../../../shared/component-subscriber/subscriber.component';
import { SupplierAdvancesService } from './../../../service/supplier-advances.service';
import { IAdvanceLineData } from './../../models/advance-line-data.interface';

@Component({
  selector: 'app-active-advance-line-details',
  templateUrl: './active-advance-line-details.component.html',
  styleUrls: ['./active-advance-line-details.component.scss'],
})
export class ActiveAdvanceLineDetailsComponent extends SubscriberComponent implements OnInit {
  advanceLineKey: string;
  isLoading = true;
  activeAdvanceLineForm: FormGroup;

  constructor(private supplierAdvancesService: SupplierAdvancesService, private route: ActivatedRoute, private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.advanceLineKey = params.advanceLineKey;
      }),
    );
    this.setupComponent();
    this.getData();
  }

  private setupComponent(): void {
    this.activeAdvanceLineForm = this.fb.group({
      advanceLineStatus: [{ value: null }],
      weeksSinceOrigination: [{ value: null }],
      originationDate: [{ value: null }],
      approvedLimit: [{ value: null }],
      numberOfAdvances: [{ value: null }],
      maximumRepaymentReserve: [{ value: null }],
      returnStrategy: [{ value: null }],
      noRebate: [{ value: null }],
      forceFull: [{ value: null }],
    });
  }

  private getData(): void {
    this.subscriptions.push(
      this.supplierAdvancesService.getAdvanceLineByKey(this.advanceLineKey).subscribe((advanceLineData: IAdvanceLineData) => {
        if (advanceLineData) {
          this.isLoading = false;
          this.activeAdvanceLineForm.patchValue({
            advanceLineStatus: advanceLineData.status,
            weeksSinceOrigination: this.getWeeksSinceOrigination(advanceLineData.create_ts),
            originationDate: advanceLineData.create_ts,
            approvedLimit: advanceLineData.limit_amount,
            numberOfAdvances: this.getNumberOfAdvances(advanceLineData.advances),
            maximumRepaymentReserve: advanceLineData.max_extra_reserve_percent,
            returnStrategy: advanceLineData.return_strategy,
            noRebate: getNoRebate(advanceLineData.advance_templates),
            forceFull: advanceLineData.force_full,
          });
        }
      }),
    );
  }

  getNumberOfAdvances(dataAdvances: any[]) {
    return dataAdvances ? dataAdvances.length : [{ value: null }];
  }

  getWeeksSinceOrigination(originationDate: string) {
    const todayFormatted = moment(new Date(), 'MM-DD-YYYY');
    const originationDateFormatted = moment(new Date(originationDate), 'MM-DD-YYYY');
    const weeks = todayFormatted.diff(originationDateFormatted, 'weeks');
    return weeks;
  }
}
