<h1 mat-dialog-title>{{ actionTitle }}</h1>

<div class="confirmation" *ngIf="isSaved">
  {{ confirmationMessage }}
</div>

<div [formGroup]="supplierActionForm" *ngIf="showForm">
  <div class="row">
    <mat-form-field class="col-9">
      <mat-select placeholder="Earning Type" formControlName="file_type">
        <mat-option *ngFor="let type of fileTypes" [value]="type.id">
          {{ type.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="col-3">
      <button mat-raised-button color="primary" *ngIf="hasExample() as example" (click)="downloadExample(example)">Example</button>
    </div>
  </div>

  <mat-form-field class="full-width">
    <input matInput placeholder="Statement ID" formControlName="mp_pmt_external_id" />
  </mat-form-field>

  <mat-form-field class="full-width">
    <app-input-file formControlName="file" valuePlaceholder="No file selected" required></app-input-file>
    <mat-icon matSuffix>folder</mat-icon>
  </mat-form-field>

  <mat-slide-toggle color="accent" formControlName="load_paid" *ngIf="supplierActionForm.get('file_type').value === 'amazon_vc_inv'">
    Load Paid Invoices
  </mat-slide-toggle>

  <mat-slide-toggle
    color="accent"
    formControlName="no_chargeback_check"
    *ngIf="supplierActionForm.get('file_type').value === 'amazon_ve_remit'"
  >
    Disable chargeback amount check
  </mat-slide-toggle>

  <mat-slide-toggle color="accent" formControlName="europe" *ngIf="supplierActionForm.get('file_type').value === 'amazon_ve_remit'">
    The remittance was from Amazon Europe, and contains dates in dd/mm/yyyy format
  </mat-slide-toggle>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="showForm && !isSaved">
  <button mat-raised-button (click)="save()" [disabled]="!supplierActionForm.valid" tabindex="2" color="accent">Save</button>
  <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="isSaved">
  <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Yes</button>
  <button mat-raised-button (click)="editForm()" tabindex="-1" color="warn">No</button>
</div>

<div *ngIf="isSuccess">
  <div class="alert alert-success">
    {{ successMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="resetForm()" tabindex="2" color="accent">Add Another</button>
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Done</button>
  </div>
</div>

<div *ngIf="isError">
  <div class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Close</button>
  </div>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
