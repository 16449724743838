import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-frontend-email-config',
  templateUrl: './frontend-email-config.component.html',
})
export class FrontendEmailConfigComponent implements OnInit {
  public frontendEmailConfigForm: FormGroup;

  @Input() configValue;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.configValue) {
      this.createEditForm();
    } else {
      this.createNewForm();
    }
  }

  createNewForm() {
    this.frontendEmailConfigForm = this.formBuilder.group({
      description: [''],
      source: [''],
      subject: [''],
      to: [''],
      template_name: [''],
    });
  }

  createEditForm() {
    this.frontendEmailConfigForm = this.formBuilder.group({
      description: this.configValue.description,
      source: this.configValue.source,
      subject: this.configValue.subject,
      to: this.configValue.to,
      template_name: this.configValue.template_name,
    });
  }
}
