import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { interval, Observable } from 'rxjs';

import { AppConfigService } from '../../../config';
import { B2bWorkflowService } from '../../../suppliers/services/workflow/b2b-workflow.service';
import { AppsDocumentService } from './../../../shared/apps-document/apps-document.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from './../../../shared/dialog.config';
import { CliActionDialogComponent } from './cli-action-dialog/cli-action-dialog.component';
import { CliActionService } from './cli-action-service/cli-action.service';

@Component({
  selector: 'tr [app-cli-action]',
  templateUrl: './cli-action.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CliActionComponent extends SubscriberComponent implements OnInit {
  @Input() actionData;

  public executionStatus: number;
  public userInfo: {};
  public isDisabled: boolean;
  public progressValue: number;

  readonly baseForFakeTimer = 0.995;
  readonly frequencyOfBackendTimerSync = 7;
  readonly gciId = this.appConfig.env.workflowGciId;

  private progressTimer: Observable<number>;
  private fakeTimeElapsed: number;
  private progressSubscription: any;

  constructor(
    private dialog: MatDialog,
    private cliActionService: CliActionService,
    private appConfig: AppConfigService,
    private b2bWorkflowService: B2bWorkflowService,
    private appsDocumentService: AppsDocumentService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions = [];
    this.userInfo = {};
    this.subscriptions.push(this.getLastExecutionData(this.getDataAboutExecution));
  }

  openCliActionDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = DIALOG_SIZE.DEFAULT.width;
    dialogConfig.data = this.actionData;
    const dialogRef = this.dialog.open(CliActionDialogComponent, dialogConfig);
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(() => {
        this.subscriptions.push(this.getLastExecutionData(this.getDataAboutExecution));
      }),
    );
  }

  public downloadResult() {
    this.subscriptions.push(this.getLastExecutionData(this.downloadCallback));
  }

  private getLastExecutionData(callback: any) {
    return this.b2bWorkflowService.getLastExecution(this.actionData.b2btaskmanager_workflow_id).subscribe(
      (res) => {
        const bindedCallback = callback.bind(this);
        bindedCallback(res);
      },
      (err) => {},
    );
  }

  private downloadCallback(res: any) {
    if (!res.task) {
      return;
    }
    this.getDataAboutExecution(res);
    this.subscriptions.push(this.getFileDownloadUrl(res));
  }

  private checkStatusCallback(res: any) {
    this.progressSubscription.unsubscribe();
    this.getDataAboutExecution(res);
  }

  private getFileDownloadUrl(res) {
    return this.appsDocumentService.getFileDownloadUrl(this.gciId, res.task.logPath).subscribe((url) => {
      this.downloadFile(url);
    });
  }

  private downloadFile(url) {
    return this.b2bWorkflowService.downloadFile(this.actionData.command, url);
  }

  private getDataAboutExecution(data) {
    const dataAny = <any>data;
    if (dataAny && dataAny.task) {
      const input = JSON.parse(dataAny.task.input);
      this.userInfo['user'] = input.login_id.split('@').shift();
      this.userInfo['time'] = dataAny.task.startedAtStr;
      this.executionStatus = dataAny.task.status;
      this.fakeTimeElapsed = (new Date().getTime() - dataAny.task.startedAt) / 1000;

      if (this.executionStatus === 2) {
        this.isDisabled = true;
        this.progressTimer = interval(1000);
        this.progressSubscription = this.progressTimer.subscribe(() => {
          if (this.actionData.updates_progress) {
            this.realProgress(dataAny);
          } else {
            this.fakeProgress();
          }
        });
        this.subscriptions.push(this.progressSubscription);
      } else if (this.executionStatus === 1) {
        this.isDisabled = false;
        this.progressValue = 100;
      } else {
        this.isDisabled = false;
      }
    }
  }

  private fakeProgress() {
    this.progressValue = Math.floor(100 * (1 - Math.pow(this.baseForFakeTimer, this.takeFakeTime())));
    if (this.progressValue % this.frequencyOfBackendTimerSync === this.frequencyOfBackendTimerSync - 1) {
      this.getLastExecutionData(this.checkStatusCallback);
    }
  }

  private realProgress(data: any) {
    this.getLastExecutionData(this.checkStatusCallback);
    this.progressValue = Math.round(data.task.progressCount === 0 ? 0 : (data.task.progressStep / data.task.progressCount) * 100);
  }

  private takeFakeTime() {
    if (!isNaN(this.fakeTimeElapsed)) {
      return this.fakeTimeElapsed++;
    }
    this.fakeTimeElapsed = 0;
  }
}
