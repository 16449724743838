import { autoserialize, autoserializeAs, inheritSerialization } from 'cerialize';

import { ModelInterface } from '../../shared/utils/model-interface';

export class EditableReportFieldModel {
  @autoserialize
  public fieldFilterable: boolean;
  @autoserialize
  public fieldLabel: string;
  @autoserialize
  public fieldName: string;
  @autoserialize
  public fieldShow: boolean;
  @autoserialize
  public fieldType: string;
  @autoserialize
  public overrideKey: string;
}

export class ReportBaseModel implements ModelInterface {
  @autoserialize
  public reportKey: string;
  @autoserialize
  public enabledForReports: boolean;
  @autoserialize
  public enabledForSupplier = false;
  @autoserialize
  public workflowId: number;
  @autoserialize
  public googleSheetsUrl: string;
  @autoserialize
  public sortBy: string;

  getKey(): string {
    return null;
  }
}

export class ReportModel extends ReportBaseModel {
  @autoserialize
  public label: string;
  @autoserialize
  public name: string;

  constructor(jsonObject?) {
    super();
    if (jsonObject) {
      this.fromJSON(jsonObject);
    }
  }

  fromJSON(jsonObject) {
    this.label = jsonObject.label;
    this.name = jsonObject.name;
    this.reportKey = jsonObject.report_key;
    this.googleSheetsUrl = jsonObject.google_sheets_url;
    this.sortBy = jsonObject.sort_by;
    this.workflowId = jsonObject.workflow_id;
    this.enabledForReports = jsonObject.enabled_for_reports;
    this.enabledForSupplier = jsonObject.enabled_for_supplier;
  }
}

@inheritSerialization(ReportBaseModel)
export class EditableReportModel extends ReportBaseModel {
  @autoserialize
  public reportLabel: string;
  @autoserialize
  public reportName: string;
  @autoserializeAs(EditableReportFieldModel)
  public overrides: EditableReportFieldModel[];

  constructor() {
    super();
  }
}
