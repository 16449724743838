import { UwStatus } from '../../../../../enums/uw-status';

export interface IPeriod {
  periodLength: number;
  fee: number;
}

export interface IAdvanceOfferConnectedInputs {
  fee?: number;
  givenAmount?: number;
  grossAmount?: number;
  netDeliveryAmount?: number;
  reserveAmount?: number;
  riskRanking?: number;
  descriptionGrossAmount?: string;
  descriptionNetDeliveryAmount?: string;
  descriptionReserveAmount?: string;
  descriptionRiskRanking?: string;
}

export class Period implements IPeriod {
  periodLength: number;
  fee: number;

  constructor(periodLength: number, fee: number) {
    this.periodLength = periodLength;
    this.fee = fee;
  }
}

export class AdvanceOfferConnectedInputs implements IAdvanceOfferConnectedInputs {
  constructor(
    public fee?: number,
    public givenAmount?: number,
    public grossAmount?: number,
    public netDeliveryAmount?: number,
    public reserveAmount?: number,
    public riskRanking?: number,
    public descriptionGrossAmount?: string,
    public descriptionNetDeliveryAmount?: string,
    public descriptionReserveAmount?: string,
    public descriptionRiskRanking?: string,
  ) {}
}

export interface CalculatorState {
  periods?: IPeriod[];
  requested?: IAdvanceOfferConnectedInputs;
  recommended?: IAdvanceOfferConnectedInputs;
  approvedAmount?: number;
  netDeliveryAmount?: number;
  reserveForRepayments?: number;
  totalPeriodLength: number;
  totalFee: number;
  averageFee: number;
  underwritingRiskRating: number;
  defaultProgressiveAdvanceSettings: IProgressivePricingSettings;
  isTotalPeriodToLong: boolean;
  isTotalFeeIncorrect: boolean;
  isMpReserveForPaymentEmpty: boolean;
  isAddDisabled: boolean;
  isRemoveDisabled: boolean;
  isReadonly: boolean;
  isTouched: boolean;
  isNewOffer: boolean;
  eligibilityData: any;
  requestNotes: string;
  underwriterNotes: string;
  gracePeriodEndDate: number;
  gracePeriodSavedEndDate: number;
  noRebate: boolean;
  noRebateSaved: boolean;
  isEmptyPeriodLength: boolean;
  isEmptyFee: boolean;
  returnStrategy: string;
  returnStrategySaved: string;
  forceFull: boolean;
  forceFullSaved: boolean;
  expectedMonthlyEarnings: number;
  expectedDuration: number;
  uwStatus: UwStatus;
}

export interface UpdatePeriodRowI {
  periodNumber: number;
  newValue: number;
}

export interface IProgressivePricingSettings {
  ForEveryRiskRating: any[];
  WhenNoRiskRating: any[];
}

export interface IReofferReserveAndNetAmount {
  netDeliveryAmount: number;
  reofferReserve: number;
}

export interface ProgressivePricingOfferDTO {
  requestedAmount: number;
  approvedAmount: number;
  averageFee: number;
  reserveForRepayments: number;
  netDeliveryAmount: number;
  periods: IPeriod[];
  totalFee: number;
  totalPeriodLength: number;
  riskRating: number;
  requestNotes: string;
  underwriterNotes: string;
  recommendedAmount: number;
  extraReservePercent: number;
  weeklyFeePercent: number;
  requestKey: string;
  gracePeriodLength: number;
  noRebate: boolean;
  returnStrategy: string;
  forceFull: boolean;
  expectedMonthlyEarnings: number;
  expectedDuration: number;
}

export interface IAdvanceOfferRequest {
  advance_offer_request_key: string;
  approved_amount: number;
  create_ts: string;
  expiration_date: string;
  extra_reserve_amount: number;
  extra_reserve_percent: number;
  last_login_id: string;
  last_txid: number;
  offer_advance_reserve_percent: number;
  offer_status: string;
  periods: IPeriod[];
  recommended_amount: number;
  refin_of_sup_adv_key: string;
  request_notes: string;
  requested_advance_reserve_percent: number;
  requested_amount: number;
  requested_reserve_total_amount: number;
  reserve_total_amount: number;
  risk_rating: string;
  supplier_advance_key: string;
  supplier_advance_ml_key: string;
  supplier_key: string;
  underwriter_notes: string;
  underwriting_date: string;
  underwriting_risk_profile: string;
  underwritter_det_avg_mon_remittance: number;
  update_ts: string;
  uw_status: string;
  weekly_fee_percent: number;
}

export interface IEligibilityData {
  cancellation_rate: number;
  days_ok_count: number;
  delivery_rate: number;
  ml_offer_key: string;
  no_data: boolean;
  offer_amount: number;
  order_defect_rate: number;
  pyb_eligibility: number;
  ranking: string;
}
