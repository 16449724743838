import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import Logger from '../shared/logger';
import { AppState } from '../store/app.reducers';
import { hasCorrectToken, Logout } from './store/auth.actions';
import { AuthState } from './store/auth.reducers';

export const AUTH_HEADER_NAME = 'X-Session-Token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  readonly authHeaderName = AUTH_HEADER_NAME;

  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select('auth').pipe(
      take(1),
      switchMap((authState: AuthState) => {
        if (hasCorrectToken(authState)) {
          const jwtToken = authState.user.jwtToken;

          const authReq = req.clone({ headers: req.headers.set(this.authHeaderName, jwtToken) });
          return next.handle(authReq).pipe(
            catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401 && jwtToken) {
                  Logger.log(err);
                  this.store.dispatch(new Logout({ message: 'Auth token expired, please try to login again.' }));
                }
              }

              return observableThrowError(err);
            }),
          );
        } else {
          return next.handle(req);
        }
      }),
    );
  }
}
