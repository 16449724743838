import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../../config';

@Injectable()
export class PaymentHistoryService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
  }

  getCardTransactionHistory(supplierKey: string, cardKey: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}suppliers/${supplierKey}/${cardKey}/card_transition`);
  }
}
