import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { isUndefined } from 'util';

import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { CrmStates } from '../../../../shared/layout/layout.enums';
import { LayoutState } from '../../../../shared/layout/store/layout.reducers';
import { AppState } from '../../../../store/app.reducers';
import { DepositAccountModel } from '../../../model/deposit-account.model';
import { DepositAccountService } from '../../../services/deposit-account/deposit-account.service';
import { FetchSupplierDetails, selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-incoming-payment-add',
  templateUrl: './incoming-payment-add.component.html',
  styleUrls: ['./incoming-payment-add.component.less'],
})
export class IncomingPaymentAddComponent extends SubscriberComponent implements OnInit {
  depositAccount: DepositAccountModel;
  depositAccountKey: string;
  supplierKey: string;

  layoutState$: Store<LayoutState>;
  crmPanelStates = CrmStates;

  constructor(
    public dialogService: MatDialog,
    private depositAccountService: DepositAccountService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private toastrService: ToastrService,
    private router: Router,
  ) {
    super();
    this.layoutState$ = this.store.select('layout');
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.depositAccountKey = params['depositAccountKey'];

        if (!!this.depositAccountKey) {
          this.subscriptions.push(
            this.depositAccountService.get(this.depositAccountKey).subscribe((result) => {
              this.depositAccount = result;
            }, (error) => {
              const errorMessage = error.error?.message ?? 'Error while fetching deposit account details!'
              this.toastrService.error(errorMessage);
            }),
          );
        }
      }),
    );

    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.supplierKey = data.supplierKey;
        }
      }),
    );
  }

  saveDepositAccount() {
    this.subscriptions.push(
      this.depositAccountService.update(this.depositAccountKey, { ...this.depositAccount }).subscribe((result) => {
        this.depositAccount = result;
        this.router.navigate(['../../list'], { relativeTo: this.route });
        this.toastrService.success('Deposit account saved!');
      }),
    );
  }

  deleteDepositAccount() {
    const msg = 'Are you sure you want to delete deposit account for this supplier?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: '450px',
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.subscriptions.push(
            this.depositAccountService.remove(this.depositAccountKey, this.supplierKey).subscribe(
              () => {
                this.toastrService.success('Deposit account removed!');
                this.router.navigate(['../../list'], { relativeTo: this.route });
                setTimeout(() => this.store.dispatch(new FetchSupplierDetails({ supplierKey: this.supplierKey, refresh: true })), 0);
              },
              () => {
                this.toastrService.error('Deposit account could not been removed. Try again!');
              },
            ),
          );
        }
      }),
    );
  }
}
