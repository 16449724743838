import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { isUndefined } from 'util';

import { IEnvironment } from '../../../../environments/environment.interface';
import { AppConfigService } from '../../../config';
import { B2bDocumentService } from '../../../shared/b2b-document/b2b-document.service';
import { FEATURE_FLAGS } from '../../../shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { PybDocumentsService } from '../../../shared/pyb-documents/pyb-documents.service';
/* eslint-disable */

@Component({
  selector: '[pyb-b2b-preview]',
  template: `
    <span (mouseover)="loadDownloadUrl()">
      <ng-content></ng-content>
      <div>
        <ul class="tl-data-summary">
          <li *ngIf="docType === 'Image'" class="pill-btn">
            <a (click)="openPreviewModal()" class="tl-btn btn btn-primary"> <i class="material-icons">launch</i> Preview</a>
          </li>
          <li class="pill-btn">
            <a (click)="downloadFile($event)" [href]="downloadUrl" download="{{ filename }}" class="tl-btn btn btn-files"
              ><i class="material-icons">get_app</i> Download</a
            >
          </li>
        </ul>
      </div>
    </span>
  `,
})
export class B2bPreview implements OnInit {
  public downloadUrl;
  readonly gciId: IEnvironment['workflowGciId'];
  @Input() public uuid: string;
  @Input() public filename: string;
  @Input() public docType: string;

  constructor(
    public dialog: MatDialog,
    private appConfig: AppConfigService,
    private b2bDownloadService: B2bDocumentService,
    private httpClient: HttpClient,
    private featureFlagsService: FeatureFlagsService,
    private pybDocuments: PybDocumentsService,
    private toastrService: ToastrService,
  ) {
    this.gciId = appConfig.env.workflowGciId;
  }

  ngOnInit() {
    this.loadDownloadUrl();
  }

  loadDownloadUrl() {
    if (isUndefined(this.downloadUrl) && !this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      this.b2bDownloadService.getFileDownloadUrl(this.gciId, this.uuid).subscribe((url) => {
        this.downloadUrl = url;
      });
    }
  }

  checkImage(url) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, false);
    xhr.send();

    if (xhr.status === 404 || xhr.status === 302) {
      this.dialog.open(B2bPreviewDialogComponent, {
        data: { downloadUrl: null },
      });
      return;
    }

    this.dialog.open(B2bPreviewDialogComponent, {
      data: { downloadUrl: this.downloadUrl, filename: this.filename },
    });
  }

  openPreviewModal() {
    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      this.pybDocuments.downloadFile(this.uuid).subscribe((blob) => {
        this.dialog.open(B2bPreviewDialogComponent, {
          data: { downloadUrl: blob, filename: this.filename },
        });
      });
      return;
    }
    this.checkImage(this.downloadUrl);
  }

  downloadFile(event) {
    event.preventDefault();
    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      this.pybDocuments.downloadFile(this.uuid).subscribe((blob) => {
        FileSaver.saveAs(blob, this.filename);
      });
      return;
    }
    this.httpClient.get(this.downloadUrl, { responseType: 'blob' }).subscribe((file) => {
      FileSaver.saveAs(file, this.filename);
    });
  }
}

@Component({
  selector: 'app-b2b-preview-dialog',
  template: `
    <h1 mat-dialog-title>Preview document</h1>
    <div mat-dialog-content>
      <img *ngIf="image; else error" [src]="image" (error)="onError()" />
    </div>
    <ng-template #error><p>Could not load preview.</p></ng-template>
    <div mat-dialog-actions>
      <button mat-raised-button color="warn" (click)="close()" tabindex="-1">Close</button>
      <a *ngIf="image" mat-raised-button color="accent" (click)="downloadFile($event)" [href]="image" download tabindex="-1">Download</a>
    </div>
  `,
})
export class B2bPreviewDialogComponent implements OnInit {
  public image;

  constructor(
    public dialogRef: MatDialogRef<B2bPreviewDialogComponent>,
    private httpClient: HttpClient,
    private featureFlagsService: FeatureFlagsService,
    private pybDocuments: PybDocumentsService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { downloadUrl: any; filename: string },
  ) {}

  ngOnInit(): void {
    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      const objectURL = URL.createObjectURL(this.data.downloadUrl);
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return;
    }
    this.image = this.data.downloadUrl;
  }

  close(): void {
    this.dialogRef.close();
  }

  downloadFile(event) {
    event.preventDefault();
    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      FileSaver.saveAs(this.data.downloadUrl, this.data.filename);
      return;
    }
    this.httpClient.get(this.data.downloadUrl, { responseType: 'blob' }).subscribe((file) => {
      FileSaver.saveAs(file, this.data.filename);
    });
  }

  onError(): void {
    this.image = null;
  }
}
