<div class="additional-card-content">
  <div class="expected-monthly-earnings line-row">
    <span *ngIf="isNewOffer; else notNewExpectedMonthlyEarings">
      Expected Monthly Earnings:
      {{ requested.grossAmount | currency: 'USD' }}
    </span>

    <ng-template #notNewExpectedMonthlyEarings>
      <span>Expected Monthly Earnings:</span>
      <mat-form-field class="line-row__value">
        <input
          matInput
          (keyup)="changeExpectedMonthlyEarnings($event)"
          (mouseup)="changeExpectedMonthlyEarnings($event)"
          [(ngModel)]="expectedMonthlyEarnings"
          [disabled]="disableInputsWithoutRequestedAmount"
          type="number"
        />
      </mat-form-field>
    </ng-template>
  </div>
  <div class="expected-duration line-row">
    <span *ngIf="isNewOffer; else notNewExpectedDuration">
      Expected Duration:
      {{ requested.grossAmount }}
    </span>

    <ng-template #notNewExpectedDuration>
      <span>Expected Duration:</span>
      <mat-form-field class="line-row__value">
        <input
          matInput
          (keyup)="changeExpectedDuration($event)"
          (mouseup)="changeExpectedDuration($event)"
          [(ngModel)]="expectedDuration"
          [disabled]="disableInputsWithoutRequestedAmount"
          type="number"
        />
      </mat-form-field>
    </ng-template>
  </div>
  <div class="max-duration">
    <span>
      Max Duration:
      {{ totalPeriodLength }}
    </span>
  </div>
  <div class="line-fee">
    <span>
      Line Fee %:
      {{ averageFee }}
    </span>
  </div>
  <div class="line-max-repayment-reserve">
    <span> Line Max Repayment Reserve: 0 </span>
  </div>
  <div class="current-repayment-reserve">
    <span> Current Repayment Reserve: 0 </span>
  </div>

  <div class="underwriting-status line-row" *ngIf="showUwStatusSelector">
    <span> Underwriting Status: </span>
    <mat-form-field class="line-row__value">
      <mat-select [(ngModel)]="underwritingStatus" (selectionChange)="changeUwStatus($event)" [disabled]="isNewOffer">
        <mat-option *ngFor="let status of uwStatuses" [value]="status">
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
