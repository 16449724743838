import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-description-modal',
  templateUrl: './description-modal.component.html',
  styleUrls: ['./description-modal.component.scss'],
})
export class DescriptionModalComponent implements OnInit {
  public hasHtmlContent = false;

  private keyMapped = ['ml_balance_approval_details', 'exceptions', 'ml_financial_reason'];

  constructor(public dialogRef: MatDialogRef<DescriptionModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    this.checkIfNeedMap();
  }

  private checkIfNeedMap() {
    for (const key of this.keyMapped) {
      if (this.data && this.data.key && this.data.key === key) {
        this.mapData();
      }
    }
  }

  private mapData() {
    if (this.data.text) {
      this.hasHtmlContent = true;
      this.data.text = this.data.text.replace(/;/g, '<br>');
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
