<ng-template #loadingCard>
  <div class="card-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>

<mat-card class="onboardingSaleInfo__card">
  <mat-card-content *ngIf="marketplaceSaleInformation; else loadingCard">
    <h5>Sales Information</h5>

    <mat-table class="onboardingSaleInfo__card__table" [dataSource]="marketplaceSaleInformation">
      <ng-container matColumnDef="marketplace">
        <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.marketplace }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="monthlyRevenue">
        <mat-header-cell *matHeaderCellDef>Monthly Revenue</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.monthlyRevenue }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="monthlyGrossRevenue">
        <mat-header-cell *matHeaderCellDef>Monthly Gross Revenue</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.monthlyGrossRevenue }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lengthTimeSelling">
        <mat-header-cell *matHeaderCellDef>Time Selling</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.timeSelling }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="currentPayout">
        <mat-header-cell *matHeaderCellDef>Current Payout</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.currentPayout }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="netSales0to30">
        <mat-header-cell *matHeaderCellDef>Net Sales (Month 1)</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.netSales0to30 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="netSales30to60">
        <mat-header-cell *matHeaderCellDef>Net Sales (Month 2)</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.netSales30to60 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="netSales60to90">
        <mat-header-cell *matHeaderCellDef>Net Sales (Month 3)</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.netSales60to90 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="grossSales0to30">
        <mat-header-cell *matHeaderCellDef>Gross Sales (Month 1)</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.grossSales0to30 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="grossSales30to60">
        <mat-header-cell *matHeaderCellDef>Gross Sales (Month 2)</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.grossSales30to60 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="grossSales60to90">
        <mat-header-cell *matHeaderCellDef>Gross Sales (Month 3)</mat-header-cell>
        <mat-cell *matCellDef="let element" class="onboardingSaleInfo__card__table__cell">
          {{ element.grossSales60to90 }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="marketplaceSaleInformationColumn"
        class="onboardingSaleInfo__card__table__headerRow"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: marketplaceSaleInformationColumn" class="onboardingSaleInfo__card__table__row"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
