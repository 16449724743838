<div class="field-wrapper" [class]="fieldClass" [ngClass]="{ 'has-icon': materialIcon, 'in-group-edit': inGroupEdit }">
  <!-- EDIT MODE -->
  <div *ngIf="inEditMode(); else presentationMode" class="edit-mode">
    <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>

    <mat-form-field>
      <input
        #inlineEditControl
        matInput
        [formControl]="formControl"
        [name]="value"
        [type]="'password'"
        [autocomplete]="'new-password'"
        [placeholder]="label"
        (blur)="markAsTouched()"
      />
    </mat-form-field>

    <mat-error *ngIf="hasRequiredError()"> This field is <strong>required</strong> </mat-error>

    <mat-error *ngIf="hasMaxError()"> Value needs to be lower than initial value </mat-error>

    <div class="help-text-wrapper warning" *ngIf="helpText && !hasRequiredError()">
      {{ helpText }}
    </div>

    <div class="inline-buttons" *ngIf="isInlineEditOnly()">
      <button class="btn btn-xs btn-cancel" (click)="close($event)">Cancel <i class="material-icons">close</i></button>
      <button
        class="btn btn-xs btn-success btn-save"
        (click)="callSave(formControl.value)"
        [disabled]="hasRequiredError() || hasMaxError()"
      >
        Save <i class="material-icons">done</i>
      </button>
    </div>
  </div>

  <ng-template #presentationMode>
    <div class="presentation-mode" [ngClass]="{ 'disable-inline': disableInline }">
      <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>
      <div class="fake-field">
        <div class="label">
          {{ label }}
          <mat-icon *ngIf="showCopy" ngxClipboard [cbContent]="value" class="btn-copy" matTooltip="Click to copy {{ label }}"
            >content_copy</mat-icon
          >
        </div>
        <div class="value" *ngIf="!displayType; else formatedValue" (click)="edit(formControl.value)" (focus)="edit(formControl.value)">
          {{ maskingValue | formatPreview: type:defaultEmptyValue }}
        </div>
        <ng-template #formatedValue>
          <span *ngIf="isPercent">{{ formControl.value * 100 | formatPreview: type:defaultEmptyValue }}</span>
          <span *ngIf="isCurrency">{{ formControl.value | currency: 'USD':'symbol' | formatPreview: type:defaultEmptyValue }}</span>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
