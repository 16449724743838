<section class="app-overlay" appSearchOverlay>
  <div class="app-overlay-inner">
    <div class="overlay-header">
      <a id="overlay-close" class="overlay-close">
        <i class="material-icons">close</i>
      </a>
    </div>
    <div class="overlay-content">
      <input
        class="overlay-search-input"
        #ctrl="ngModel"
        [id]="'overlay-search-input'"
        [(ngModel)]="queryString"
        [placeholder]="placeholderPrompt"
        data-test="searchOverlayInput"
      />

      <div class="text-small text-muted">
        <span>Press <em>Esc</em> key to dismiss</span>
      </div>

      <div *ngIf="searchInProgress; then searchResultsLoading; else searchResultsDisplay"></div>

      <ng-template #searchResultsLoading>
        <app-loading-spinner [size]="3"></app-loading-spinner>
      </ng-template>

      <ng-template #searchResultsDisplay>
        <section *ngIf="!noResults; else displayNoResults" class="list-group">
          <mat-list class="row">
            <mat-list-item *ngFor="let result of suppliersItems; let i = index" class="list-items">
              <app-search-overlay-single-result
                [searchSingleResult]="result"
                [hasRestrictedAccessPermission]="hasRestrictedAccessPermission"
                (closeOverlay)="closeOverlay($event)"
                data-test="searchOverlaySingleResult"
              >
              </app-search-overlay-single-result>
              <div class="divider divider-md"></div>
            </mat-list-item>
          </mat-list>
        </section>
        <ng-template #displayNoResults>
          <h5>Sorry, no results were found</h5>
        </ng-template>
      </ng-template>
      <mat-paginator *ngIf="totalCount" [length]="totalCount" [pageSize]="paginationLimit" (page)="setPage($event)"></mat-paginator>
    </div>
  </div>
</section>
