<div>
  <span *ngIf="supplierError; else loadSupplier">
    <div class="supplier-not-found">
      <div class="err" *ngIf="supplierError.status > 0; else error0">
        <section class="errorsDifferentThen0And404" *ngIf="supplierError.status != 404 && supplierError.status != 400; else error404">
          <div class="err-icon" *ngIf="supplierError.status >= 500; else icon400">
            <i class="material-icons icon-size-7x icon-color-red">warning</i>
            <i class="material-icons icon-size-7x icon-color-yellow">warning</i>
          </div>
          <ng-template #icon400>
            <div class="err-icon">
              <i class="material-icons icon-size-7x">sentiment_very_dissatisfied</i>
            </div>
          </ng-template>
          <h1>{{ supplierError.status }}</h1>
          <h2>Sorry: {{ supplierError.statusText }}</h2>
          <h4>Debug message (please sent in to developers to better describe Your problem):</h4>
          <div *ngIf="(fetchedRequests$ | async).cachedRequests as cachedRequests">
            <textarea *ngIf="supplierError.status === 0" width="100%" rows="12" cols="130">
            {{ cachedRequests }}
            </textarea>
            <br />
            <button (click)="exportErrorStatusToCsv(cachedRequests, supplierError.status)">Export to CSV</button>
          </div>
          <textarea *ngIf="supplierError.status !== 0" width="100%" rows="12" cols="130">
                {{ supplierError.message }}
            {{ supplierError | json }}
          </textarea>
        </section>
        <ng-template #error404>
          <app-page-not-found></app-page-not-found>
        </ng-template>
      </div>
      <ng-template #error0>
        <div class="errorZero">
          <div class="errorZero__message">
            <div class="errorZero__message__inner">
              <h3>Lost connection to server...</h3>
              Please try again in few minutes.<br />
              It is important <strong>NOT</strong> to refresh the page immediately, because it may cause unnecessary increase in traffic and
              load on our servers. Please, take a short break, get away from the computer. Make Yourself a coffee, stretch, or do some
              exercises. If you absolutely want to stay, please play the game below for a while before refreshing the page.
            </div>
          </div>
          <div class="errorZero__game">
            <div class="errorZero__game__inner">
              <app-tetris></app-tetris>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </span>

  <ng-template #loadSupplier>
    <app-supplier-box-header-old></app-supplier-box-header-old>

    <section class="container-fluid no-breadcrumbs page-dashboard" *ngIf="fullSize">
      <div class="supplier-pages-wrapper">
        <router-outlet></router-outlet>
      </div>
      <app-crm-sidebar *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]"></app-crm-sidebar>
    </section>
  </ng-template>
</div>
