<div *ngIf="addComment; else commentAddLabel">
  <app-comment-add
    #addCommentComponent
    [hideButton]="hideButton"
    [targetUuid]="targetUuid"
    [targetType]="targetType"
    (onComment)="handleOnComment($event)"
    (onSuccess)="fetchComments()"
  >
  </app-comment-add>
</div>

<ng-template #commentAddLabel>
  <div class="comment-buttons">
    <a class="pull-left" (click)="addComment = !addComment" data-test="crmNoteAddCommentBtn">Add comment</a>

    <a class="pull-right" (click)="fetchComments()" *ngIf="commentsCount">
      <span *ngIf="!itemsLoaded; else refreshComments"> Load all comments ({{ commentsCount }}) </span>
      <ng-template #refreshComments> Reload comments </ng-template>
    </a>

    <a class="pull-right" (click)="showAllComments = !showAllComments" *ngIf="itemsLoaded || (commentsCount == 1 && taskBody)">
      {{ showAllComments ? 'Hide comments' : 'Show comments' }}
    </a>
  </div>
  <div class="clearfix"></div>
</ng-template>

<div class="comments-list">
  <hr />
  <div *ngIf="allItems && allItems.length > 0" [hidden]="!showAllComments">
    <div *ngFor="let item of allItems | orderBy: 'createdAt':true">
      <app-comment-item *ngIf="!item.type" [item]="item" (onRefresh)="fetchComments()"></app-comment-item>
      <app-event-task-item *ngIf="item.type === 'task-change-event'" [item]="item"></app-event-task-item>
    </div>
  </div>
  <hr *ngIf="allItems && allItems.length > 0" />
  <div class="task-author">
    <ul class="comment-data-summary">
      <li class="pill" data-test="crmCommentAuthor"><i class="material-icons">person</i>Author: {{ author }}</li>
      <li data-test="crmCommentCreatedUpdatedAt">
        <i class="material-icons">alarm</i>Created At: {{ localCreatedAt | amAdd: correctTime(localCreatedAt):'minutes' | date: 'short' }}
        <i class="material-icons">event_note</i>Last Edited:
        {{ localUpdatedAt | amAdd: correctTime(localUpdatedAt):'minutes' | date: 'short' }}
      </li>
    </ul>
  </div>
  <div
    *ngIf="taskBody?.trim() && ((allItems && allItems.length == 0) || (itemsLoaded && showAllComments))"
    [innerHtml]="taskBody | safe"
    class="task-body"
    #taskBodyElement
  ></div>
</div>
