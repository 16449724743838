import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as semver from 'semver';

import { selectAuthToken } from '../../../auth/store/auth.actions';
import Logger from '../../../shared/logger';
import { AppState } from '../../../store/app.reducers';
import { MARKETPLACE_CALLBACK_MAP } from './consts/marketplace-callback-map';
import { MARKETPLACES_FOR_EXTENTION_MAP } from './consts/marketplaces-for-extention-map';
import { AMAZON_SELLER_CENTRAL, SHOPIFY_MARKETPLACE, TOP_HATTER_MARKETPLACE, WALMART_MARKETPLACE } from './consts/marketplaces-settings';
import { IDataForExtension } from './models/data-for-extension.model';
import { ExtensionCallback } from './models/extension-callback.model';

@Injectable()
export class PybExtensionService {
  constructor(private toastrService: ToastrService) {}

  readonly subjectForMarketplaceLogin = {
    subject: 'loginMarketplace',
  };
  private payabilityWebExtensionId = 'kapbcbjofjjpgppmhilcmkgpjbpeolcm';
  public readonly extensionDebugMode = false;

  getInfoFromExtension(subject) {
    return new Promise((resolve, reject) => {
      const hasChromeRuntime = this.hasChromeRuntime();
      if (hasChromeRuntime === false) {
        return resolve(false);
      }

      const timeoutPromiseId = setTimeout(function () {
        resolve(false);
      }, 20000);

      const payabilityWebExtensionId = this.payabilityWebExtensionId;
      window.chrome.runtime.sendMessage(payabilityWebExtensionId, { subject }, function success(response) {
        clearTimeout(timeoutPromiseId);
        resolve(!response ? false : response);
      });
    });
  }

  isInstalled() {
    return this.getInfoFromExtension('isInstalled');
  }

  getVersion() {
    return this.getInfoFromExtension('getVersion');
  }

  isChrome() {
    return Boolean(window.chrome);
  }

  hasChromeRuntime() {
    return !!window && !!window.chrome && !!window.chrome.runtime;
  }

  openPrivateTab(url) {
    if (!this.hasChromeRuntime()) {
      return;
    }
    window.chrome.runtime.sendMessage(this.payabilityWebExtensionId, {
      subject: 'openPrivateTab',
      data: url,
    });
  }

  private prepareMarketplaceAuthData(marketplace, supplierKey, authToken) {
    return {
      user: marketplace.externalLogin,
      pass: marketplace.externalPassword,
      externalSupplierId: marketplace.externalSupplierId,
      supplierKey,
      authToken,
    };
  }

  sendMessage(data) {
    if (!this.hasChromeRuntime()) {
      return;
    }
    window.chrome.runtime.sendMessage(this.payabilityWebExtensionId, {
      ...this.subjectForMarketplaceLogin,
      data,
    });
  }

  loginAmazonSellerCentral(authData) {
    if (this.isChrome() && this.hasChromeRuntime()) {
      const authPluginData = {
        ...AMAZON_SELLER_CENTRAL,
        ...authData,
      };
      this.sendMessage(authPluginData);
    }
  }

  loginToTophatterMarketplace(authData) {
    if (this.isChrome() && this.hasChromeRuntime()) {
      const tophatterPluginData = {
        ...TOP_HATTER_MARKETPLACE,
        ...authData,
      };
      this.sendMessage(tophatterPluginData);
    }
  }

  public loginToWallmartMarketplace(authData) {
    if (this.isChrome() && this.hasChromeRuntime()) {
      const wallmartPluginData = {
        ...WALMART_MARKETPLACE,
        ...authData,
      };
      this.sendMessage(wallmartPluginData);
    }
  }

  public loginToShopifyMarketplace(authData) {
    const shopifyPluginData = {
      ...SHOPIFY_MARKETPLACE,
      ...authData,
      delay: 1500,
      clickDelay: 1000,
    };
    this.sendMessage(shopifyPluginData);
  }

  loginMarketplaceByExtension(dataForExtension: IDataForExtension, authData) {
    const authExtensionData = {
      ...dataForExtension.marketplaceData,
      ...authData,
    };

    if ('*' === authExtensionData.url) {
      authExtensionData.url = authData.externalSupplierId + (authData.externalSupplierId.endsWith('/') ? '' : '/') + 'admin';
    }
    this.sendMessage(authExtensionData);
  }

  public loginInto(marketplaceName, marketplaceData, supplierKey, authToken) {
    if (this.isChrome() && this.hasChromeRuntime()) {
      this.getVersion().then((version) => {
        const authData = this.prepareMarketplaceAuthData(marketplaceData, supplierKey, authToken);
        const dataForExtension = this.dataForExtension(marketplaceName);
        if (dataForExtension && typeof dataForExtension !== 'boolean') {
          if (this.isVersionSatisfiedBy(dataForExtension, version)) {
            this.loginMarketplaceByExtension(dataForExtension, authData);
          } else {
            this.toastrService.info(`You need Payability Web Extension ver: ${dataForExtension.marketplaceData.requiredVersion}!`);
          }
        }
      });
    }
  }

  private isVersionSatisfiedBy(dataForExtension: IDataForExtension, satisfiedBy: any): boolean {
    if (!dataForExtension.marketplaceData.requiredVersion) {
      return true;
    }

    if (typeof satisfiedBy === 'boolean') {
      return false;
    }

    if (semver.satisfies(satisfiedBy.toString(), dataForExtension.marketplaceData.requiredVersion)) {
      return true;
    }

    return false;
  }

  private dataForExtension(marketplaceName): boolean | IDataForExtension {
    const dtoForExtension = {
      extensionSubjectMarketplace: 'loginMarketplace',
    };
    const marketplacesToLogin = this.getMarketplacesToLogin(dtoForExtension);
    return marketplacesToLogin[marketplaceName] || marketplacesToLogin['default'];
  }

  getMarketplacesToLogin(dtoForExtension) {
    const marketplacesToLogin = {
      default: false,
    };
    Object.keys(MARKETPLACES_FOR_EXTENTION_MAP).forEach((key) => {
      marketplacesToLogin[key] = {
        ...dtoForExtension,
        marketplaceData: MARKETPLACES_FOR_EXTENTION_MAP[key],
      };
    });
    return marketplacesToLogin;
  }

  public loginToMarketplace(marketplaceDetailsModel, subscriptions: Subscription[], store: Store<AppState>, supplierKey: string) {
    const marketplaceKey = marketplaceDetailsModel.marketplaceKey;
    this.whenExtensionIsInstalled(
      subscriptions,
      store,
      this.marketplaceExtensionCallback(marketplaceKey),
      marketplaceDetailsModel,
      supplierKey,
    );
  }

  private loginToUnsuportedMarketplace: ExtensionCallback = (userAuthToken: string, marketplaceDetailsModel: any) => {
    const message = 'This version of the Internal Admin does not support logging into this market yet.';
    this.toastrService.info(message);
  };

  private marketplaceExtensionCallback(marketplaceType: string) {
    const marketplaceCallbacks = this.getMarketplaceCallback();
    if (this.extensionDebugMode) {
      console.table({ marketplaceType });
    }

    return marketplaceCallbacks[marketplaceType] || marketplaceCallbacks['default'];
  }

  getMarketplaceCallback() {
    const marketplaceCallbacks = {
      default: this.loginToUnsuportedMarketplace,
    };
    Object.keys(MARKETPLACE_CALLBACK_MAP).forEach((marketplaceKey) => {
      marketplaceCallbacks[marketplaceKey] = this.prepareLoginTo(MARKETPLACE_CALLBACK_MAP[marketplaceKey]);
    });
    return marketplaceCallbacks;
  }

  private prepareLoginTo(marketplaceLoginConfiguration: any): ExtensionCallback {
    return (userAuthToken: string, marketplaceDetailsModel: any, supplierKey: string) => {
      this.loginInto(marketplaceLoginConfiguration, marketplaceDetailsModel, supplierKey, userAuthToken);
    };
  }

  private whenExtensionIsInstalled(
    subscriptions: Subscription[],
    store: Store<AppState>,
    extensionCallback: ExtensionCallback,
    marketplaceDetailsModel: any,
    supplierKey: string,
  ): void {
    this.isInstalled().then((isInstalled) => {
      if (isInstalled === true) {
        subscriptions.push(
          store.select(selectAuthToken).subscribe(
            (userAuthToken: string) => extensionCallback(userAuthToken, marketplaceDetailsModel, supplierKey),
            (errorResponse: HttpErrorResponse) => this.logError(errorResponse),
          ),
        );
      } else {
        this.toastrService.info('You need to install Payability Web Extension');
      }
    });
  }

  private logError(error: HttpErrorResponse) {
    if (error.status !== 404) {
      Logger.error(error);
    }
  }
}
