<ng-template #noUploadsYet>
  <h5 class="text-muted">No uploads yet</h5>
</ng-template>

<ng-template #loadingCard>
  <div class="card-content spinnerWrapper">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>
<div class="card">
  <div class="card-content">
    <span class="card-title">KYC Documentation</span>
    <div *ngIf="actionsWithFiles; else loadingCard">
      <div *ngIf="actionsWithFiles.length; else noUploadsYet">
        <ul class="kyc-upload-list list-unstyled" *ngFor="let action of actionsWithFiles">
          <li>
            <table class="table table-condensed">
              <tbody class="borderless">
                <tr>
                  <td class="middle center" rowspan="2" style="width: 1%">
                    <ng-container *ngIf="!action.downloadThumbnailUrlLoading; else imageSpinner">
                      <img
                        class="preview-img"
                        *ngIf="action.downloadThumbnailUrl; else imageError"
                        [src]="action.downloadThumbnailUrl"
                        (click)="openVerifyDocumentDialog(action, action.compliance_doctype_name)"
                      />
                    </ng-container>

                    <ng-template #imageError>
                      <span class="preview-img image-error" (click)="openVerifyDocumentDialog(action, action.compliance_doctype_name)">
                        Error during fetching an image
                      </span>
                    </ng-template>
                  </td>
                  <td class="middle">
                    <h6>
                      <span class="kyc-file-doc-type">
                        <div *ngIf="action.satisfied_by.file.compliance_doctype_key">{{ action.compliance_doctype_name }}:</div>
                        <div *ngIf="action.compliance_action_key == '60b6955f-011c-49dd-932f-2b739b597c89'">Instant Access DocuSign:</div>
                        <div *ngIf="action.compliance_action_key == 'a1fcaf3f-f76f-48cd-82aa-e103c6959bcb'">Instant Advance DocuSign:</div>
                        <div *ngIf="action.compliance_action_key == 'a601fb69-eda6-4332-9b62-e12b78411216'">
                          Instant Access with Addendum DocuSign:
                        </div>
                        <div *ngIf="action.compliance_action_key == '6ffe5a64-c98a-4324-9459-d78c6de2a725'">
                          Instant Access and Instant Advance DocuSign:
                        </div>
                        <div *ngIf="action.compliance_action_key == '9adcf50e-c6c7-4b79-bb2a-d61b2950b83b'">Addendum DocuSign:</div>
                        <div *ngIf="action.compliance_action_key == 'fc267061-1944-429b-b98a-5e4fa2796de6'">
                          Long Term Contracts DocuSign:
                        </div>
                      </span>
                      <span
                        [ngClass]="{ 'text-success': action.satisfied_by.status == 'approved' || action.satisfied_by.status == 'signed' }"
                      >
                        {{ action.satisfied_by.status | capitalize }}
                      </span>
                      <span *ngIf="action.satisfied_by.status == 'rejected'" class="badge badge-pill badge-warning"> ! </span>
                      <span *ngIf="action.satisfied_by.status == 'pending'" class="badge badge-pill badge-info">?</span>
                    </h6>
                    <div class="form-group">
                      <div *ngIf="action.compliance_action_key != 'a1fcaf3f-f76f-48cd-82aa-e103c6959bcb'">
                        <ng-container *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
                          <button
                            (click)="openVerifyDocumentDialog(action, action.compliance_doctype_name)"
                            mat-raised-button
                            class="btn-w-md"
                          >
                            Verify
                          </button>
                          <div *ngIf="supplierDetails$ | async; let supplierDetails">
                            <div
                              *ngIf="
                                action.compliance_doctype_name === IDENTITY_TYPE_NAME &&
                                supplierDetails.status.supplierStatus !== supplierStatus.ACTIVE &&
                                action.status === KYC_DOCUMENTATION_STATUS.REJECTED
                              "
                            >
                              <input
                                #uploadFile
                                hidden="true"
                                type="file"
                                (click)="onUploadFileClick($event)"
                                (change)="onFileUpload($event, action)"
                              />
                              <button mat-raised-button color="primary" class="btn-w-md" (click)="uploadFile.click()">Upload New ID</button>
                            </div>
                          </div>
                        </ng-container>

                        <div class="divider divider-sm"></div>
                      </div>
                      <div *ngIf="action.compliance_action_key == 'a1fcaf3f-f76f-48cd-82aa-e103c6959bcb'">
                        <app-spinner-button
                          [disabled]="action.satisfied_by.status == 'signed'"
                          (onClick)="signContract()"
                          [color]="''"
                          [loading]="signButtonLoading"
                          [customWidth]="'8rem'"
                          [spinnerColor]="'black'"
                          [text]="'Sign'"
                        >
                        </app-spinner-button>
                        <div class="divider divider-sm"></div>
                      </div>
                    </div>
                    <a
                      class="download-link pull-left"
                      href="#"
                      appAppsDocumentDownload
                      [fileUuid]="action.satisfied_by.file.file_id"
                      [fileName]="action.satisfied_by.file.file_name"
                    >
                      Download image
                      <i class="material-icons">file_download</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #imageSpinner>
  <div class="card-content spinnerWrapper imageSpinnerWrapper">
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </div>
</ng-template>
