<fieldset [formGroup]="form">
  <div [ngSwitch]="field.controlType">
    <mat-form-field class="full-width" *ngSwitchCase="'string'">
      <input matInput placeholder="{{ field.label }}" [formControlName]="field.key" />
    </mat-form-field>

    <mat-radio-group class="mat-radio-group-spacing" *ngSwitchCase="'yesno'" [formControlName]="field.key">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="errorMessage" *ngIf="!isValid">{{ field.label }} is required</div>
</fieldset>
