import * as lscache from 'lscache';

import * as fromUtilsActions from './utils.actions';

const CACHE_TIMEOUT = 24 * 60; // in minutes
const UTILS_STATE_KEY = 'utilsState';

export interface UtilsState {
  timezones: any[];
  fundingPeriods: any[];
  usStates: any[];
  countries: any[];
}

const initialState: UtilsState = {
  timezones: undefined,
  fundingPeriods: undefined,
  usStates: undefined,
  countries: undefined,
};

const persistedState = loadState();

export function utilsReducers(state = persistedState || initialState, action: fromUtilsActions.UtilsActions) {
  switch (action.type) {
    case fromUtilsActions.SET_UTIL_ATTR:
      const attrName = action.payload.attr;
      const attrData = action.payload.data;

      const newState = { ...state };
      newState[attrName] = attrData;

      saveState(newState);

      return newState;

    default:
      return { ...state };
  }
}

export function loadState(): UtilsState {
  try {
    const serializedState = lscache.get(UTILS_STATE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

export function saveState(state: UtilsState) {
  try {
    const serializedState = JSON.stringify(state);
    lscache.set(UTILS_STATE_KEY, serializedState, CACHE_TIMEOUT);
  } catch (err) {
    console.error('Local storage is not avaliable!');
  }
}
