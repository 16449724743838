<ng-container *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS]">
  <app-supplier-box-header-eva>
    <ng-container *ngxPermissionsExcept="[userPermission.ADVANCE_AUDITORS]">
      <ng-container *ngTemplateOutlet="tabsTpl"> </ng-container>
    </ng-container>
  </app-supplier-box-header-eva>
</ng-container>

<ng-container *ngxPermissionsOnly="userPermission.REST_UW_ACCESS">
  <ng-template #loading>
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </ng-template>

  <div class="legal-info" *ngIf="selectSupplierDetails$ | async; else loading; let supplierInfo">
    <div class="supplier-name-key">
      <span class="supplier-name" ngxClipboard [cbContent]="supplierInfo.legalName">{{ supplierInfo.legalName }}</span>
      <div class="supplier-key" ngxClipboard [cbContent]="supplierInfo.supplierKey">
        <small>{{ supplierInfo.supplierKey }}</small>
      </div>
    </div>
  </div>
</ng-container>
