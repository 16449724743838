/* eslint-disable */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

import { SupplierUnderwritingService } from '../../supplier-underwriting.service';
import { TransUnionPersonComponent } from '../../trans-union/person/trans-union-person.component';
import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { DefaultRadiobuttons } from '../common/consts/underwritings-initial-constants';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';
/* eslint-enable */

@Component({
  selector: 'app-personal-background-check',
  templateUrl: './personal-background-check.component.html',
})
export class PersonalBackgroundCheckComponent extends SubscriberComponent implements OnChanges {
  @Input() transUnion: any;
  @Input() supplierKey: string;
  @Input() transUnionOverride: any;
  @Input() personalBgCheck = [];
  @Input() transUnionActionStatusKey: string;
  @Input() personalBgCheckDecision = [];
  @Input() computingDataIsInProgress: boolean;

  @Output() onTransUnionDataUpdate = new EventEmitter();
  @Output() onTransUnionOverrideChange = new EventEmitter();

  readonly userPermission = USER_PERMISSION;

  headerType: UnderwritingInitialCardHeaderStatusType;
  headerStatus: string;
  radiobuttons = DefaultRadiobuttons;
  transUnionDataLoading = false;

  constructor(
    private dialogService: MatDialog,
    private toastr: ToastrService,
    private supplierUnderwritingService: SupplierUnderwritingService,
  ) {
    super();
  }

  ngOnChanges(changes) {
    this.setHeaderDetails();
  }

  setHeaderDetails() {
    if (this.isSuccessHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.SUCCESS;
      this.headerStatus = 'Valid';
    }
    if (this.isDangerHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.DANGER;
      this.headerStatus = 'Needs review';
    }
  }

  isSuccessHeaderType() {
    return this.transUnionOverride[0].value || (!this.transUnionOverride[0].value && !this.personalBgCheckDecision);
  }

  isDangerHeaderType() {
    return !this.transUnionOverride[0].value && this.personalBgCheckDecision;
  }

  radiobuttonsChange(value) {
    if (!this.transUnionOverride[0]) {
      return;
    }
    this.transUnionOverride[0].value = value;
    this.onTransUnionOverrideChange.emit(this.transUnionOverride);
  }

  openBackgroundReport() {
    if (this.transUnion.results.person) {
      const dialogRef = this.dialogService.open(TransUnionPersonComponent, {
        data: this.transUnion.results.person,
        width: '600px',
      });
      this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
    } else {
      this.toastr.info('There is no data matching supplier personal check');
    }
  }

  getTransUnionData() {
    this.transUnionDataLoading = true;
    return this.supplierUnderwritingService
      .putActionStatusService(this.transUnionActionStatusKey, 'v2', {
        results: {},
        status: 'approved',
        download_ucc_data: true,
      })
      .pipe(finalize(() => (this.transUnionDataLoading = false)))
      .subscribe(
        (transUnionCheck) => {
          this.subscriptions.push(
            this.supplierUnderwritingService
              .getActionsService(this.supplierKey, 'e6247370-bd22-4992-8054-7900a4afa789', 'actions', 'v2')
              .pipe(finalize(() => (this.transUnionDataLoading = false)))
              .subscribe(
                (TUResults) => {
                  this.toastr.success(transUnionCheck.message);
                  this.onTransUnionDataUpdate.emit(TUResults);
                },
                (err) => this.toastr.error(err.error.message),
              ),
          );
        },
        (err) => {
          this.toastr.error(err.error.message);
        },
      );
  }
}
