import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { InlineEditBaseComponent } from '../base/inline-edit-base.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditTextComponent),
  multi: true,
};

@Component({
  selector: 'app-inline-edit-text',
  styleUrls: ['../base/inline-edit-base.component.scss'],
  templateUrl: './inline-edit-text.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
})
export class InlineEditTextComponent extends InlineEditBaseComponent implements OnInit, OnDestroy {
  @Input() label = '';
  @Input() type;
  @Input() displayType;
  @Input() formatter: Function = null;
  @Input() mask: string[];
  @Input() showToggleMask: boolean;

  private cachedMask: string[];
  private readonly subscriptions: Subscription[] = [];

  get isPercent(): boolean {
    return this.displayType === 'percent';
  }

  get isCurrency(): boolean {
    return this.displayType === 'currency';
  }

  get isValueExisting(): boolean {
    return this.formControl && !!this.formControl.value && this.formControl.value !== this.defaultEmptyValue;
  }

  get showMask(): boolean {
    return this.mask && this.isValueExisting;
  }

  get showToggleMaskBtn(): boolean {
    return this.showToggleMask && this.isValueExisting;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setupTextInput();
  }

  toggleMask(): void {
    if (this.mask) {
      this.cachedMask = this.mask;
      this.mask = null;
    } else {
      this.mask = this.cachedMask;
      this.cachedMask = null;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  protected onFormGroupChange(): void {
    super.onFormGroupChange();
    this.setupTextInput();
  }

  private setupTextInput(): void {
    if (!this.formControl || !this.formControl.valueChanges) {
      return;
    }

    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((data) => {
        this.value = data;
      }),
    );
  }
}
