<div class="ui-timline-container ui-timline-left" infiniteScroll [scrollWindow]="false" (scrolled)="onScroll()">
  <section class="ui-timeline" *ngIf="crmItems && uuids" [@listAnimation]="crmItems && uuids">
    <div *ngFor="let itemUuid of uuids">
      <app-task-item [item]="crmItems[itemUuid]"></app-task-item>
    </div>
    <article class="tl-item">
      <div class="tl-body">
        <div class="tl-entry">
          <div class="tl-icon btn-icon-round btn-icon btn-icon-thin btn-primary">
            <i class="material-icons" (click)="fetchItems()" [ngClass]="{ 'fa-spin': pending && !loaded }">refresh</i>
          </div>
          <div class="tl-load-more" *ngIf="!loaded">
            <a (click)="onScroll()">{{ pending ? 'Loading...' : 'Load more...' }}</a>
          </div>
        </div>
      </div>
    </article>
  </section>
</div>
