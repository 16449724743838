import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isUndefined } from 'util';

import { CancelEmitService } from '../../../suppliers/services/cancel-emit.service';
import { CANCELLATION_STATUSES } from '../../../suppliers/supplier/supplier-box-header/supplier-box-header.component';
import { CardFormgroupMessageService, CardFormgroupMessageType } from '../../card-formgroup/card-formgroup-message.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class InlineEditBaseComponent implements ControlValueAccessor, AfterViewChecked, OnInit, OnDestroy, AfterViewInit {
  @ViewChild('inlineEditControl', { static: false }) inlineEditControl: ElementRef;
  @Input() formControlName: string;
  @Input() showCopy = false;
  @Input() isRequired;
  @Input() disabled = false;
  @Input() inGroupEdit: boolean;
  @Input() compact: boolean;
  @Input() disable: boolean;
  @Input() disableInline: boolean;
  @Input() materialIcon: string;
  @Input() fieldClass = '';
  @Input() helpText = null;
  @Input() clearable = true;
  @Output() onSave = new EventEmitter();

  @Input() set formGroup(formGroup: FormGroup) {
    this._formGroup = formGroup;
    this.onFormGroupChange();
  }

  _inputValue: any;
  editing = false;
  defaultEmptyValue = '- empty value -';
  onChange: any = Function.prototype;
  onTouched: any = Function.prototype;

  get formGroup(): FormGroup {
    return this._formGroup;
  }

  get formControl(): AbstractControl {
    return this.formGroup.get(this.formControlName);
  }

  get value(): any {
    return this._inputValue;
  }

  set value(v: any) {
    v = this.castValueByType(v);
    if (v !== this._inputValue) {
      this._inputValue = v;
      const control = this.formGroup.get(this.formControlName);
      if (control) {
        control.setValue(this._inputValue, { emitEvent: false, onlySelf: true });
        try {
          this.onChange(this._inputValue);
        } catch (e) {
          setTimeout(() => this.onChange(this._inputValue));
        }
      }
    }
  }

  constructor(protected messageService: CardFormgroupMessageService, protected cancelEmitService: CancelEmitService) {}

  writeValue(value: any) {
    this.value = this.castValueByType(value);
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    this.onTouched();
  }

  edit(value) {
    this.onTouched();

    if (this.disabled) {
      return;
    }
    this.editing = true;
  }

  close() {
    this.resetValues();
  }

  resetValues() {
    this.editing = false;

    if (!isUndefined(this._preValue)) {
      this.value = this._preValue;
      this._preValue = undefined;
    }
  }

  callSave(value) {
    for (const status of CANCELLATION_STATUSES) {
      if (status.toUpperCase() === String(value).toUpperCase()) {
        this.cancelEmitService.setEditCancellation(value);
      }
    }

    this.onSave.emit(value);
    this.editing = false;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.setupBase();
  }

  ngOnDestroy(): void {
    this.resetValues();
    this.baseClassSubscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  ngAfterViewChecked() {
    if (isUndefined(this._preValue)) {
      this._preValue = this._inputValue;
    }
  }

  ngAfterViewInit() {
    if (this.formGroup && this.formControlName && this.formGroup.controls[this.formControlName]) {
      if (this.disabled) {
        setTimeout(() => {
          this.formGroup.controls[this.formControlName].disable();
        });
      }
    }
  }

  inEditMode = () => (this.editing && !this.disableInline) || this.inGroupEdit;
  isInlineEditOnly = () => this.editing && !this.inGroupEdit;
  hasRequiredError = () => this.formGroup && this.formGroup.get(this.formControlName).hasError('required');
  hasMaxError = () => this.formGroup && this.formGroup.get(this.formControlName).hasError('max');

  protected castValueByType(v: any): any {
    return v;
  }

  protected onFormGroupChange(): void {
    this.setupBase();
  }

  private readonly baseClassSubscriptions: Subscription[] = [];
  private _preValue: any;
  private _formGroup?: FormGroup;
  private _cancelSubscription: Subscription;

  private readonly messageEventHandlers: Partial<Record<CardFormgroupMessageType, () => void>> = {
    [CardFormgroupMessageType.UPDATE_INITIAL]: () => (this._preValue = this._inputValue),
    [CardFormgroupMessageType.CANCEL]: () => this.resetValues(),
    [CardFormgroupMessageType.GROUP_IN_EDIT]: () => this.resetValues(),
    [CardFormgroupMessageType.SAVE_SUCCESS]: () => (this._preValue = this._inputValue),
    [CardFormgroupMessageType.SAVE_GROUP]: () => {
      this.formGroup.get(this.formControlName).setValue(this.value);
      this.editing = false;
    },
    [CardFormgroupMessageType.CLEAR]: () => {
      if (this.clearable) {
        this.value = '';
      }
    },
  };

  private setupBase(): void {
    if (!this.formControlName) {
      throw new Error('Please init required "formControlName" attribute');
    }

    if (!this.formGroup) {
      throw new Error('Please init required "formGroup" attribute');
    }

    if (!this.messageService) {
      return;
    }

    this._inputValue = this.formControl.value;
    this.baseClassSubscriptions.push(
      this.messageService.subscribe(this.formGroup, (messageEvent) => {
        const handler = this.messageEventHandlers[messageEvent.actionType];
        if (!handler) {
          return;
        }

        handler();
      }),
    );
  }
}
