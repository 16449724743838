import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perm-groups',
  templateUrl: './perm-groups.component.html',
})
export class PermGroupsComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
