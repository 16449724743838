<mat-card id="supplierPaymentPrompts">
  <span>Plaid Link Prompt</span>
  <mat-divider></mat-divider>
  <div class="supplierPaymentPrompts__sliders">
    <mat-slide-toggle
      class="supplierPaymentPrompts__sliders__collections"
      [(ngModel)]="collectionAmount"
      (change)="changeCollectionPrompt()"
      [disabled]="advanceUnderwriting"
      >Collections Prompt</mat-slide-toggle
    >
    <mat-slide-toggle
      class="supplierPaymentPrompts__sliders__advance"
      [(ngModel)]="advanceUnderwriting"
      (change)="changeAdvanceUnderwritingPrompt()"
      [disabled]="collectionAmount"
      >Advance Underwriting Prompt</mat-slide-toggle
    >
  </div>
</mat-card>
