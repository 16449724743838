<div class="card orson">
  <div class="card-content">
    <mat-card-header>
      <mat-card-title>
        <img *ngIf="source" [src]="source" class="marketplaceImage" />
      </mat-card-title>
      <mat-card-subtitle [attr.data-test]="'suppMarketplace' + marketplace.marketplaceName">
        <h3>{{ marketplace.marketplaceName }}</h3>
      </mat-card-subtitle>
      <div class="right-col">
        <button
          mat-raised-button
          (click)="loginToMarketplace(marketplace); $event.preventDefault(); $event.stopPropagation()"
          [disabled]="!marketplace.externalLogin || !marketplace.externalPassword"
          data-test="marketplaceLoginBtn"
        >
          <mat-icon>vpn_key</mat-icon>
          Login
        </button>

        <button mat-raised-button color="primary" [routerLink]="['../edit/', marketplace.mpSupKey]" data-test="marketplaceSeeDetailsBtn">
          <mat-icon>info</mat-icon>
          See Marketplace Details
        </button>
        <button
          mat-raised-button
          color="accent"
          (click)="openNewPaymentGatewayModal()"
          [disabled]="filteredGatewayList.length < 1"
          data-test="marketplaceLinkPayGatwyBtn"
        >
          <mat-icon>add</mat-icon>
          Link new payment gateway
        </button>
      </div>
    </mat-card-header>
    <div class="card-actions">
      <mat-chip-list class="pull-right">
        <span class="badge badge-pill badge-secondary" *ngIf="marketplace.draft">Draft</span>
      </mat-chip-list>
    </div>

    <div class="linkedGateways">
      <app-supplier-marketplace-single-gateway
        *ngFor="let gateway of this.marketplace.paymentGateways; let i = index"
        [paymentGateway]="gateway"
        [index]="i"
        (disconnectGateway)="disconnectGatewayBy($event)"
      ></app-supplier-marketplace-single-gateway>
    </div>
  </div>
</div>
