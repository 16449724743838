import { PlatformLocation } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { debounceTime, delay, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { selectAuthToken } from '../../../../auth/store/auth.actions';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { EvaComponent } from '../../../../shared/eva/eva.component';
import { FEATURE_FLAGS } from '../../../../shared/feature-flags/feature-flags.const';
import { CommunicationService } from '../../../../shared/iframe/communication/communication.service';
import { MessageFromIframe } from '../../../../shared/iframe/communication/communication-message.model';
import { MESSAGE_SOURCE, MESSAGE_TYPE } from '../../../../shared/iframe/communication/message-type.enum';
import { SupplierInfoStates } from '../../../../shared/layout/layout.enums';
import { LayoutState } from '../../../../shared/layout/store/layout.reducers';
import { Logger } from '../../../../shared/logger';
import { AppState } from '../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { SupplierBalanceModel } from '../../../model/supplier-balance.model';
import { SupplierMarketplaceDetailsModel } from '../../../model/supplier-marketplace.model';
import { LoginAsSupplierService } from '../../../services/login-as-supplier/login-as-supplier.service';
import { PybExtensionService } from '../../../services/pyb-extension/pyb-extension.service';
import { selectSupplierBalance, selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from '../../supplier-onboarding/supplier-onboarding.service';
import { GlobalSettingsService } from './../../../../manage/global-settings/service/global-settings.service';
import { FeatureFlagsService } from './../../../../shared/feature-flags/feature-flags.service';
import { SupplierMarketplacesService } from './../../../services/supplier-marketplaces.service';
import { FetchSupplierMarketplaces, FetchSupplierPaymentConfigs } from './../../../store/supplier/supplier.actions';
import { SupplierBoxHeaderService } from './../supplier-box-header.service';

@Component({
  selector: 'app-supplier-box-header-eva',
  templateUrl: './supplier-box-header-eva.component.html',
  styleUrls: ['./supplier-box-header-eva.component.scss'],
})
export class SupplierBoxHeaderEvaComponent extends SubscriberComponent implements OnInit {
  @ViewChild('topBarComponent', { static: false }) topBarComponent: EvaComponent;
  readonly TOP_BAR_REFRESH_DELAY_DEFAULT = 1000;
  readonly TOP_BAR_REFRESH_DELAY_KEY = 'TOP_BAR_REFRESH_DELAY';

  selectSupplierDetails$: Observable<SupplierDetailsModel>;
  selectSupplierBalance$: Observable<SupplierBalanceModel>;

  supplierKey: string;
  currentPage: string;
  supplierDetailsModel: SupplierDetailsModel;
  layoutState$: Observable<LayoutState>;
  isOpen: boolean;
  isNewMenu: boolean;
  url: string;
  fullSize: boolean;
  private refreshTopBarDelay$ = this.globalSettingsService.get(this.TOP_BAR_REFRESH_DELAY_KEY, true).pipe(
    map((data) => (!!data ? data : { config_value: this.TOP_BAR_REFRESH_DELAY_DEFAULT })),
    filter((data) => data && data.config_value),
    map((data) => +data.config_value),
    first(),
    shareReplay(1),
  );

  private readonly REFETCH_DATA_DEBOUNCE_TIME = 300;

  constructor(
    private readonly toastrService: ToastrService,
    private readonly store: Store<AppState>,
    private readonly pybExtService: PybExtensionService,
    private readonly route: ActivatedRoute,
    private readonly loginAsSupplierService: LoginAsSupplierService,
    private readonly communicationService: CommunicationService,
    private readonly supplierBoxHeaderService: SupplierBoxHeaderService,
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly globalSettingsService: GlobalSettingsService,
    private readonly marketplaceService: SupplierMarketplacesService,
    private readonly platformLocation: PlatformLocation,
    private readonly elementRef: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isNewMenu = this.featureFlagsService.isEnabled(FEATURE_FLAGS.EVA_SUPPLIER_MENU);
    this.url = this.isNewMenu ? 'supplier/core' : 'supplier-main-bar';

    this.listenIframeMessages();
    this.setParams();
    this.setSupplierData();
    this.setLayout();
    this.listenExternalActions();

    this.subscriptions.push(this.refreshTopBarDelay$.subscribe());
  }

  selectSupplierDetails(): void {
    let firstTime = true;

    this.subscriptions.push(
      this.selectSupplierDetails$.subscribe((supplierDetailsModel: SupplierDetailsModel) => {
        this.supplierDetailsModel = supplierDetailsModel;

        if (supplierDetailsModel) {
          this.store.dispatch(
            new FetchSupplierMarketplaces({
              supplierDetailsModel: this.supplierDetailsModel,
              refresh: true,
            }),
          );
        }

        this.store.dispatch(new FetchSupplierPaymentConfigs());

        if (supplierDetailsModel) {
          if (!firstTime) {
            this.refetchSupplierDetailsInEva();
          }
          firstTime = false;
        }
      }),
    );
  }

  setSupplierData(): void {
    this.selectSupplierDetails$ = this.store.select(selectSupplierDetails);
    this.selectSupplierBalance$ = this.store.select(selectSupplierBalance);
    // ToDo - to refactor to the future. Let's send store from the Eva via postMessage instead of fetching all marketplaces
    this.selectSupplierDetails();
  }

  setLayout() {
    this.layoutState$ = this.store.select('layout');
    this.subscriptions.push(
      this.layoutState$.subscribe((data) => {
        this.isOpen = data.supplierInfoOpenState === SupplierInfoStates.OPEN;
      }),
    );
  }

  refetchSupplierDetailsInEva(): void {
    if (this.topBarComponent) {
      let delayTime: number;
      this.subscriptions.push(
        this.refreshTopBarDelay$
          .pipe(
            tap((time) => (delayTime = time ?? this.TOP_BAR_REFRESH_DELAY_DEFAULT)),
            delay(delayTime),
          )
          .subscribe(() => this.topBarComponent.sendMessage().refetchSupplierDetails()),
      );
    }
  }

  loginAmazonSellerCentral(): void {
    // Check if the Payability Web Extension is installed
    this.pybExtService.isInstalled().then((response) => {
      const isInstalled = response;

      if (isInstalled === true) {
        this.subscriptions.push(
          this.store
            .select(selectAuthToken)
            .pipe(
              first(),
              switchMap((userAuthToken) => this.prepareMarketplaceAuthData(userAuthToken)),
            )
            .subscribe(
              (authData) => {
                this.pybExtService.loginAmazonSellerCentral(authData);
              },
              (errorResponse: HttpErrorResponse) => {
                if (errorResponse.status !== 404) {
                  Logger.error(errorResponse);
                }
              },
            ),
        );
      } else {
        this.toastrService.info('You need to install Payability Web Extension');
      }
    });
  }

  private prepareMarketplaceAuthData(authToken: string): Observable<any> {
    const amazonMarketplace = this.supplierDetailsModel.marketplaces.find((marketplaceModel: SupplierMarketplaceDetailsModel) => {
      return marketplaceModel.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY;
    });

    if (!amazonMarketplace) {
      return of(null);
    }

    return this.marketplaceService.fetchMarketplaceDetails(this.supplierDetailsModel.supplierKey, amazonMarketplace.mpSupKey, true).pipe(
      map((marketplace) => {
        return {
          user: marketplace.externalLogin,
          pass: marketplace.externalPassword,
          supplierKey: this.supplierDetailsModel.supplierKey,
          authToken,
        };
      }),
    );
  }

  private setParams(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        if (this.supplierKey !== params['supplierKey']) {
          this.supplierKey = params['supplierKey'];
        }
        this.currentPage = this.platformLocation.hash.split(this.supplierKey)[1].split('/')[1];
      }),
    );
  }

  listenIframeMessages(): void {
    this.subscriptions.push(
      this.communicationService.incomingMessage$
        .pipe(
          filter((message) => {
            if (!message || !message.source) {
              return;
            }
            return message.source === MESSAGE_SOURCE.CHILD;
          }),
        )
        .subscribe((message) => {
          this.onReceiveMessageFromIframe(message);
        }),
    );
  }

  onReceiveMessageFromIframe(messageData: MessageFromIframe): void {
    const message = messageData.payload;
    if (!message || !message.type) {
      return;
    }

    switch (message.type) {
      case MESSAGE_TYPE.MAIN_HEADER__NAVIGATE_BY_DEAL_LINK: {
        this.navigateByLink(`https://payabilityllc.pipedrive.com/deal/${this.supplierDetailsModel.extDealId}`);
        break;
      }
      case MESSAGE_TYPE.MAIN_HEADER__LOGIN_AS_SUPPLIER: {
        this.loginAsSupplierService.loginAsSupplier(this.supplierKey);
        break;
      }
      case MESSAGE_TYPE.MAIN_HEADER__LOGIN_TO_AMAZON: {
        this.loginAmazonSellerCentral();
        break;
      }
      case MESSAGE_TYPE.FULL_SIZE_TOP_BAR: {
        this.fullSize = true;
        break;
      }
      case MESSAGE_TYPE.REVERT_TOP_BAR_SIZE: {
        this.fullSize = false;
        break;
      }
    }
  }

  private navigateByLink(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  private listenExternalActions(): void {
    this.subscriptions.push(
      this.supplierBoxHeaderService.refetchData$.pipe(debounceTime(this.REFETCH_DATA_DEBOUNCE_TIME)).subscribe(() => {
        this.refetchSupplierDetailsInEva();
      }),
    );
  }
}
