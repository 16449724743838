import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tetris',
  templateUrl: './tetris.component.html',
})
export class TetrisComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
