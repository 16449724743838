import { Injectable } from '@angular/core';
import { Deserialize } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isUndefined } from 'util';

import { ConfigsService } from '../../shared/configs/service/configs.service';
import { FileTypeModel } from '../models/file-type.model';
import { FileTypeCategoryModel } from '../models/file-type-category.model';
import { FileTypesServiceInterface } from './file-types.service.interface';

const DOCUMENTS_TYPES_CONFIG_KEY = 'document_types';

@Injectable()
export class FileTypesService implements FileTypesServiceInterface {
  constructor(private configsService: ConfigsService) {}

  getFileTypes(): Observable<FileTypeModel[]> {
    return this.configsService.get(DOCUMENTS_TYPES_CONFIG_KEY).pipe(
      map((nestedTypes) => {
        return nestedTypes.map((item) => {
          return Deserialize(item, FileTypeModel);
        });
      }),
    );
  }

  getFileCategories(): Observable<FileTypeCategoryModel[]> {
    return this.configsService.get(DOCUMENTS_TYPES_CONFIG_KEY).pipe(
      map((nestedTypes) => {
        return nestedTypes.map((item) => {
          return new FileTypeCategoryModel(item.uuid, item.name);
        });
      }),
    );
  }

  addTypeOrCategory(data: any) {
    const itemToSave = { ...data };
    return this.configsService.get(DOCUMENTS_TYPES_CONFIG_KEY).pipe(
      map((nestedTypes) => {
        if (!isUndefined(itemToSave.parentUuid)) {
          const category = nestedTypes.find((cat) => cat.uuid === itemToSave.parentUuid);
          delete itemToSave.parentUuid;
          delete itemToSave.parent;
          category.children.push(itemToSave);
        } else {
          nestedTypes.push(itemToSave);
        }
        return this.configsService.set(DOCUMENTS_TYPES_CONFIG_KEY, nestedTypes);
      }),
    );
  }
}
