import { CurrencyPipe } from '@angular/common';

const NOT_SUPPORTED = 'notSupported';

export class ProductMatrixInitializeTablesService {
  constructor() {}

  static setDataSource(mpData: any, dataResult: any, callback: (mp: any) => any) {
    for (const key in mpData) {
      if (mpData.hasOwnProperty(key)) {
        const mp = mpData[key];
        this.setDataForSingleMarketplace(mp, dataResult, callback);
      }
    }
  }

  static setDataForSingleMarketplace(mp: any, dataResult: any, callback: (mp: any) => any) {
    dataResult.push({
      ...this.setCommonFields(mp),
      ...callback(mp),
    });
  }

  static setCommonFields(mp: any) {
    return {
      marketplace: mp.marketplace_name ? mp.marketplace_name : '-',
    };
  }

  static getMarketplaceSupplierKey(mpData: any, index: number) {
    return Object.keys(mpData)[index];
  }

  static setProductStatus(productStatus: string) {
    switch (productStatus) {
      case 'no_offered': {
        return 'Not Offered';
      }
      case 'declined': {
        return 'Declined';
      }
      case 'accepted': {
        return 'Offered';
      }
    }
  }

  static checkIfNotSupported(mpData: any) {
    for (const key in mpData) {
      if (mpData.hasOwnProperty(key)) {
        if (mpData[key].status === NOT_SUPPORTED) {
          delete mpData[key];
        }
      }
    }
    return mpData;
  }

  static transformIntoCurrency(value: number): string {
    return new CurrencyPipe('en-US').transform(value, 'USD');
  }

  static setProductStatusColor(status: string): string {
    switch (status) {
      case 'Not Offered': {
        return '#F5AB2E';
      }
      case 'Declined': {
        return '#E00352';
      }
      case 'Offered': {
        return '#5584DF';
      }
    }
  }

  static getUnderwritingStatusColor(uwStatus: string): string {
    if (uwStatus === 'verified') {
      return 'success';
    }
    if (uwStatus === 'pending' || uwStatus === 'skipped' || uwStatus === 'sales resolution') {
      return 'pending';
    }
    if (uwStatus === 'incomplete' || uwStatus === 'rejected') {
      return 'unsuccess';
    }
  }
}
