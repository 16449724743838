import { Injectable, isDevMode } from '@angular/core';

import { AppConfigService } from '../config';

@Injectable()
export class Logger {
  constructor(private appConfig: AppConfigService) {}

  public static log(message?: any, ...optionalParams: any[]) {
    if (isDevMode()) {
      Logger.displayConsoleLog(message, ...optionalParams);
    }
  }

  public static error(message?: any, ...optionalParams: any[]) {
    if (isDevMode()) {
      Logger.displayConsoleError(message, ...optionalParams);
    }
  }

  public static ex(exception: any, ...optionalParams) {
    if (isDevMode()) {
      Logger.displayConsoleError(exception, ...optionalParams);
    }
  }

  private static displayConsoleLog(message?: any, ...optionalParams: any[]) {
    console.groupCollapsed('------- SILENT LOG DEBUG --------');
    console.log(message, ...optionalParams);
    console.groupEnd();
  }

  private static displayConsoleError(message?: any, ...optionalParams: any[]) {
    console.group('!!!!!!! SILENT LOG ERROR !!!!!!!');
    console.error(message, ...optionalParams);
    console.groupEnd();
  }
}

export default Logger;
