import { animate, state, style, transition, trigger } from '@angular/animations';
const MAX_HEIGHT = '500px';
const TIME_TO_ENTER = 600;
const TIME_TO_LEAVE = 300;

export const FadeOut = trigger('FadeOut', [
  state(
    'in',
    style({
      opacity: 1,
      maxHeight: '{{maxHeight}}',
      overflow: 'auto',
    }),
    { params: { maxHeight: MAX_HEIGHT } },
  ),

  transition(':enter', [style({ opacity: 0, maxHeight: '{{maxHeight}}', overflow: 'auto' }), animate(TIME_TO_ENTER)]),

  transition(':leave', animate(TIME_TO_LEAVE, style({ opacity: 0, maxHeight: 0, overflow: 'hidden' }))),
]);
