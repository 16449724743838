import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionModel } from '../permission.model';
import { UserModel } from '../user.model';
import { USER_PERMISSION } from './../user-permissions.enum';

export abstract class UsersAbstractService {
  public abstract getLogins(search: string, permCode: USER_PERMISSION | string): Observable<UserModel[]>;

  public abstract getLogin(loginId: string): Observable<UserModel>;

  public abstract saveLogin(loginId: string, data: UserModel): Observable<any>;

  public abstract getUser(loginId: string, authToken: string): Observable<UserModel>;

  public abstract getUsers(params?: any): Observable<UserModel[]>;

  public abstract getUsersQuery(params?: any): Observable<any>;

  public abstract getGroups(): Observable<PermissionModel[]>;

  public abstract getGroupUsers(groupId: string): Observable<UserModel[]>;

  public abstract getPermissions(): Observable<PermissionModel[]>;

  public abstract addPermissionToLogin(
    loginKey: string,
    data: { permCode: USER_PERMISSION | string; supplierKey: string },
  ): Observable<any>;

  public abstract removePermissionFromLogin(loginKey: string, loginPermKey: string): Observable<any>;

  public abstract checkPermission(permCode: USER_PERMISSION | string, user?: UserModel): Observable<boolean>;

  public abstract checkPermissions(permCodes: USER_PERMISSION[], user: UserModel): Observable<boolean>;

  public abstract checkGeneralPermission(permCode: USER_PERMISSION, user: UserModel): Observable<boolean>;

  public abstract checkGeneralPermissions(permCodes: USER_PERMISSION[], user: UserModel): Observable<boolean>;

  public getCombinedList(): Observable<(UserModel | PermissionModel)[]> {
    return combineLatest([this.getUsers(), this.getGroups()]).pipe(
      map(([users, groups]) => [].concat(groups).concat(users).sort(this.compareNames)),
    );
  }

  public compareNames(a: UserModel | PermissionModel, b: UserModel | PermissionModel): number {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }
}
