import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseField } from './fields/base-field';

@Injectable()
export class DynamicFormControlService {
  constructor() {}

  toFormGroup(fields: BaseField<any>[]) {
    const group: any = {};

    fields.forEach((field) => {
      group[field.key] = field.required ? new FormControl(field.value || '', Validators.required) : new FormControl(field.value || '');
    });
    return new FormGroup(group);
  }
}
