import { Action } from '@ngrx/store';

import { UwToolEntity } from './../../../../shared/models/uw-tool-entity.model';
import { SupplierDetailsModel } from './../../../model/supplier.model';

export enum UNDERWRITING_ACTION {
  FETCH_TOOLS = '[UNDERWRITING] Fetch Tools',
  FETCH_TOOLS_SUCCESS = '[UNDERWRITING] Fetch Tools Success',
  FETCH_TOOLS_FAILED = '[UNDERWRITING] Fetch Tools Failed',
}

export class UnderwritingFetchTools implements Action {
  readonly type = UNDERWRITING_ACTION.FETCH_TOOLS;

  constructor(public payload: { supplier: SupplierDetailsModel }) {}
}

export class UnderwritingFetchToolsSuccess implements Action {
  readonly type = UNDERWRITING_ACTION.FETCH_TOOLS_SUCCESS;

  constructor(public payload: { tools: UwToolEntity[] }) {}
}

export class UnderwritingFetchToolsFailed implements Action {
  readonly type = UNDERWRITING_ACTION.FETCH_TOOLS_FAILED;
}

export type UnderwritingActions = UnderwritingFetchTools | UnderwritingFetchToolsSuccess | UnderwritingFetchToolsFailed;
