import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { PermissionModel } from '../../../../shared/users/permission.model';
import { UsersService } from '../../../../shared/users/service/users.service';
import { UserModel } from '../../../../shared/users/user.model';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-task-template',
  templateUrl: './task-template.component.html',
})
export class TaskTemplateComponent extends SubscriberComponent implements OnInit {
  statuses = [
    { name: 'New', uuid: 'new' },
    { name: 'In-Progress', uuid: 'in-progr' },
    { name: 'Done', uuid: 'done' },
    { name: 'Closed', uuid: 'closed' },
  ];
  priorities = [
    { name: 'Low', uuid: 'low' },
    { name: 'Medium', uuid: 'medium' },
    { name: 'High', uuid: 'high' },
    { name: 'Critical', uuid: 'critical' },
  ];

  taskTemplateForm: FormGroup;

  assignee = new FormControl();
  assigneesPending = true;
  assigneeOptions: (UserModel | PermissionModel)[] = [];
  filteredAssignees: Observable<(UserModel | PermissionModel)[]>;

  @Input() configValue;

  constructor(private formBuilder: FormBuilder, private usersService: UsersService) {
    super();
  }

  ngOnInit() {
    if (this.configValue) {
      this.createEditForm();
    } else {
      this.createNewForm();
    }

    // Subscribe to assignee options
    this.subscriptions.push(
      this.usersService.getCombinedList().subscribe((data) => {
        this.assigneesPending = false;
        this.assigneeOptions = data;
        this.initAssigneeAutocomplete();
      }),
    );
  }

  initAssigneeAutocomplete() {
    this.filteredAssignees = this.assignee.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map((user: any) => (user && typeof user === 'object' ? user.name : user)),
      map((name) => {
        return name ? this.filterUsers(name) : this.assigneeOptions.slice();
      }),
    );
  }

  filterUsers(val: string): (UserModel | PermissionModel)[] {
    return !isUndefined(this.assigneeOptions)
      ? this.assigneeOptions.filter((user) => {
          return user.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
        })
      : [];
  }

  displayAssigneeFn(user: UserModel): string {
    return !isNullOrUndefined(user) ? (typeof user === 'object' ? user.name : user) : '';
  }

  setAssignee($event) {
    const assignee = $event.option.value;
    this.taskTemplateForm.patchValue({
      assigneeUuid: assignee.key,
      assigneeName: assignee.name,
      assigneeEmail: assignee.email,
      assigneeType: assignee.type,
    });
  }

  createNewForm() {
    this.taskTemplateForm = this.formBuilder.group({
      title: [''],
      status: [''],
      priority: [''],
      assigneeUuid: [''],
      assigneeName: [''],
      assigneeType: [''],
      assigneeEmail: [''],
      body: [''],
    });
  }

  createEditForm() {
    this.taskTemplateForm = this.formBuilder.group({
      title: this.configValue.title,
      status: this.configValue.status,
      priority: this.configValue.priority,
      assigneeUuid: this.configValue.assignee.uuid,
      assigneeName: this.configValue.assignee.name,
      assigneeType: this.configValue.assignee.type,
      assigneeEmail: this.configValue.assignee.email,
      body: this.configValue.body,
    });
  }
}
