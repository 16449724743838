import { PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { ConfigsService, ConfigTypes } from '../../../shared/configs/service/configs.service';
import { PermissionModel } from '../../../shared/users/permission.model';
import { UserModel } from '../../../shared/users/user.model';
import { AppState } from '../../../store/app.reducers';
import { TasksService } from '../../services/tasks.service';
import { DeleteCrmTask } from '../../store/crm.actions';
import { TaskAddEditComponent } from '../task-add-edit/task-add-edit.component';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { UsersService } from './../../../shared/users/service/users.service';

@Component({
  selector: 'app-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['../../shared/crm-tools.scss', '../../shared/timeline-item.scss', './task-item.component.scss'],
})
export class TaskItemComponent extends SubscriberComponent implements OnInit {
  @Input() item: any;
  nextStatusLabel: string;
  statuses: any = {};
  hasAccessTo = false;
  assigneeOptions: (UserModel | PermissionModel)[];

  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private tasksService: TasksService,
    private configsService: ConfigsService,
    private toastrService: ToastrService,
    private platformLocation: PlatformLocation,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  deleteTask() {
    this.store.dispatch(new DeleteCrmTask({ uuid: this.item.uuid, targetUuid: this.item.targetUuid || null }));
  }

  openEditTaskDialog() {
    this.dialog.open(TaskAddEditComponent, {
      disableClose: true,
      data: {
        task: this.item,
      },
    });
  }

  setNextLabel($event) {
    this.nextStatusLabel = $event;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        if (!!user) {
          this.hasAccessTo = user.hasAccessTo(this.item, 'edit');
        }
      }),
    );

    this.subscriptions.push(
      this.configsService.get(ConfigTypes.TASK_STATUSES).subscribe((statuses) => {
        for (let i = 0; i < statuses.length; i++) {
          this.statuses[statuses[i].uuid] = { value: statuses[i].uuid, label: statuses[i].name };
        }
      }),
    );

    const observableBatch: any[] = [];
    observableBatch.push(this.usersService.getCombinedList());
    this.subscriptions.push(
      combineLatest(observableBatch).subscribe(([assignees]) => {
        this.assigneeOptions = <(UserModel | PermissionModel)[]>assignees;
      }),
    );
  }

  getTaskLink() {
    return this.tasksService.getAbsoluteUrl((<any>this.platformLocation).location.origin, this.item.uuid);
  }

  getName(authorUuidKey) {
    if (this.assigneeOptions && this.assigneeOptions.length) {
      const foundItem = this.assigneeOptions
        .filter((item) => item.type === 'user')
        .find((item) => {
          return item.key === authorUuidKey;
        });
      if (foundItem) {
        if (foundItem instanceof UserModel) {
          return foundItem.fullname;
        } else {
          return authorUuidKey;
        }
      } else {
        return authorUuidKey;
      }
    }
  }

  handleCopyLink($event) {
    this.toastrService.success(`Task link ${this.getTaskLink()} copied to clipboard.`);

    $event.preventDefault();
    $event.stopPropagation();
  }
}
