import { PlatformLocation } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Deserialize } from 'cerialize';
import * as moment from 'moment';
import { merge as observableMerge, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { EventModel } from '../../crm-tools/models/event.model';
import { DIALOG_SIZE } from '../../shared/dialog.config';
import { EventsService } from '../event-rules/events.service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { EventPreviewComponent } from './event-preview/event-preview.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent extends SubscriberComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['type', 'message_content', 'created_at', 'user', 'target'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private _refresh$: Subject<string> = new Subject<string>();

  query: any = {};
  startQuery: any = {};
  currentSort = { created_at: 'desc' };
  page: number;
  limit: number;
  resultsLength: number;

  constructor(public dialog: MatDialog, private eventsService: EventsService, private platformLocation: PlatformLocation) {
    super();
    this.dataSource = new MatTableDataSource();
    this.page = 0;
    this.limit = 30;
    this.startQuery = {
      created_after: '',
      created_before: '',
      search: '',
      type: ['api-event', 'cli-event', 'event', 'messaging-log', 'messaging-result', 'task-change-event'],
    };
    this.query = this.prepareQuery(this.startQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.paginator.page.subscribe((page) => {
        this.page = page.pageIndex;
        this._refresh$.next();
      }),
    );

    this.subscriptions.push(
      this.sort.sortChange.subscribe((sort) => {
        this.currentSort = { created_at: sort.direction };
        this.paginator.pageIndex = 0;
        this.page = 0;
        this._refresh$.next();
      }),
    );

    this.subscriptions.push(
      observableMerge(this._refresh$).subscribe(() => {
        this.subscriptions.push(
          this.eventsService
            .query(this.query, this.limit, this.page + 1, this.currentSort)
            .pipe(
              map((result) => {
                this.resultsLength = result.hits.total.value;
                return this.mapResultToDatasource(result);
              }),
            )
            .subscribe(
              (result) => {
                this.dataSource.data = result;
              },
              () => {},
            ),
        );
      }),
    );

    this._refresh$.next();
  }

  private mapResultToDatasource(result: any) {
    const array: EventModel[] = [];
    result.hits.hits.forEach((element) => {
      array.push(Deserialize(element._source, EventModel));
    });
    return array;
  }

  openPreview(element: EventModel) {
    const dialogRef = this.dialog.open(EventPreviewComponent, {
      ...DIALOG_SIZE.XL,
      data: element,
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  filtersChange(filters) {
    this.query = this.prepareQuery(filters);
    this.paginator.pageIndex = 0;
    this.page = 0;
    this._refresh$.next();
  }

  private prepareQuery(filters) {
    return {
      event_type_code: filters['search'] || undefined,
    };

    // ToDo outdated - maybe will be added in the future
    let query = {};

    const must = [];
    if (filters['type'] && filters['type'].length > 0) {
      const terms = { terms: { type: filters['type'] } };
      must.push(terms);
    }
    if (filters['created_after']) {
      const range = { range: { created_at: { gt: moment(filters['created_after']).unix() } } };
      must.push(range);
    }
    if (filters['created_before']) {
      const range = { range: { created_at: { lte: moment(filters['created_before']).unix() } } };
      must.push(range);
    }
    if (filters['search']) {
      const match = {};
      match['fields'] = [
        'data.message',
        'data.subject',
        'data.targetType',
        'data.user_email',
        'target_uuid',
        'owner_uuid',
        'data.extra.eventTypeCode',
      ];
      match['query'] = filters['search'];
      must.push({ multi_match: match });
    }

    if (must.length > 0) {
      query = { bool: { must } };
    } else {
      query = { match_all: {} };
    }
    return query;
  }

  refreshEvents() {
    this._refresh$.next();
  }

  supplierPageUrl(targetUuid: string): string {
    const baseUrl: string = (<any>this.platformLocation).location.origin;
    return `${baseUrl}/#/suppliers/${targetUuid}/general`;
  }
}
