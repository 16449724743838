import { CurrencyPipe, Location, PercentPipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subject } from 'rxjs';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
import { debounceTime, map, takeWhile } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { DataTableQueryParams } from '../../../../shared/data-table/data-table.common';
import { DIALOG_SIZE } from '../../../../shared/dialog.config';
import { CurrencyCellRenderer } from '../../../../shared/functions/currency-cell-renderer';
import { LinkCellRenderer } from '../../../../shared/functions/link-cell-renderer';
import { LookupAbstractService } from '../../../../shared/lookup-service/lookup-abstract.service';
import { B2bWorkflowService } from '../../../../suppliers/services/workflow/b2b-workflow.service';
import {
  SupplierPaymentBatchAction,
  SupplierPaymentBatchModel,
  SupplierPaymentWorkflowStatusModel,
} from '../../../model/supplier-payment-batch-model';
import { SupplierPaymentModel } from '../../../model/supplier-payment-model';
import { PaymentBatchesAbstractService } from '../../../service/payment-batches-abstract-service';
import { AddPaymentNoteComponent } from '../../add-payment-note/add-payment-note.component';
import { ApprovePaymentComponent } from '../../approve-payment/approve-payment.component';
import {
  AccountBalance,
  batchDeleteRenderer,
  BatchType,
  DerivedHealth,
  DerivedStatus,
  dictionaryAssigner,
  displayNameAssigner,
  DueFromMP,
  MLRecomendation,
  oneColorClassAssigner,
  PayabilityAccounts,
  PaymentOverrideAmount,
  threeColorClassAssigner,
  twoColorClassAssigner,
} from '../../supplier-payments-details.common';
import { IColumnPredefinition } from './../../../../shared/data-table-grid/models/default-column-definition';

@Component({
  selector: 'app-supplier-payments-details',
  templateUrl: './supplier-payment-details.component.html',
  styleUrls: ['./supplier-payment-details.component.scss'],
})
export class SupplierPaymentsDetailsComponent extends SubscriberComponent implements OnInit, OnDestroy {
  public BatchType = BatchType;

  // Fields
  public source: Observable<any>;
  public preSource: Observable<any>;
  public payments: Observable<SupplierPaymentModel[]>;
  public config: Observable<any>;
  public batch: SupplierPaymentBatchModel;
  public workflowStatus: SupplierPaymentWorkflowStatusModel;
  public reviewers: any[];
  public task: any;
  public editPaymentBatchForm: FormGroup;

  public paymentBatchKey: string;
  public lastAddedPayments: string;
  public lastUpdatedPayments: string;

  public tableSettings: any;
  public baseHttpParams: any;
  public filterParams: any;
  private dictionaries: any;
  public filterSideBar: any;
  public customGridOptions: GridOptions = {
    headerHeight: 70,
    tooltipShowDelay: 0,
    tooltipMouseTrack: true,
  };

  private interval = 15000;
  readonly LAST_COLUMNS_PINNED = 5;

  // Task statuses
  statuses = [undefined, 'OK', 'Updating', undefined, 'Timeout', undefined, 'Terminated'];

  // Flags
  public batchOptionChecked = false;
  public isBatchInEditMode = false;
  public isPending = true;
  public isOutdated = false;
  private isAlive = true;
  public isDebit: boolean;
  public isAutoDebit: boolean;
  public isSubmitted: boolean;
  public paymentsNotFound = false;
  public paymentsLoading = true;

  // Subjects
  private filtersChanged: Subject<any> = new Subject<any>();
  rows: any;
  columnsData: IColumnPredefinition[];

  private readonly MSG_UNLOCKED = 'Batch action successfully unlocked.';
  private readonly MSG_BATCH_ACTION_FAILED = 'Request batch action failed.';
  private readonly MSG_TRY_AGAIN = 'Please try again or contact us.';
  private readonly MSG_LOADING = 'Loading...';

  private readonly FETCH_UPDATED_STATUS_DELAY = 2000;

  // Accessors
  public get title(): string {
    return this.isPending || !this.batch ? this.MSG_LOADING : `${this.batch.name}`;
  }

  public premiumFeeCodeDomain = 'pbatch.feeCode';
  public premiumFeeOption: Observable<any[]>;

  public get description(): string {
    if (this.isDebit) {
      return this.isPending || !this.batch
        ? ''
        : `Payment Total: ${this.pipe.transform(this.batch.paymentTotal, 'USD', 'symbol')}
      / Number of Payments: ${this.batch.numPayments}
      / Sent to Bank: ${this.batch.numSentToBank}`;
    }

    return this.isPending || !this.batch
      ? ''
      : `Payment Total: ${this.pipe.transform(this.batch.paymentTotal, 'USD', 'symbol')}
    / Sent to Bank: ${this.batch.numSentToBank}
    / Approved: ${this.batch.numApproved}
    / Cancelled: ${this.batch.numCancelled}
    / Hold: ${this.batch.numHold}`;
  }

  public get showTaskStatus(): boolean {
    return !isUndefined(this.task) && !isNullOrUndefined(this.task.status);
  }

  public get showLastUpdateStatus(): boolean {
    return !!this.lastUpdatedPayments;
  }

  public get showLastAddedPaymentsStatus(): boolean {
    return !!this.lastAddedPayments;
  }

  public get showApproveOrSubbmitLabel(): string {
    return this.workflowStatus ? this.workflowStatus.nextStepLabel : '';
  }

  public get showRefresh(): boolean {
    return !!(this.isOutdated && this.workflowStatus && this.workflowStatus.nextStepLabel);
  }

  public get showAction(): boolean {
    return !!(this.batch && this.workflowStatus && this.workflowStatus.nextStepLabel);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: PaymentBatchesAbstractService,
    private location: Location,
    private lookupService: LookupAbstractService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private b2bWorkflowService: B2bWorkflowService,
    private pipe: CurrencyPipe,
    private percentPipe: PercentPipe,
  ) {
    super();
  }

  // Methods
  public ngOnInit(): void {
    this.filterSideBar = {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: false,
            suppressFilterSearch: false,
          },
        },
      ],
      defaultToolPanel: '',
    };
    this.premiumFeeOption = this.lookupService
      .getCodes(this.premiumFeeCodeDomain)
      .pipe(map((items) => items.filter((item) => item.codeValue !== 'pb.fc:no_batch')));

    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        this.filterParams = params;
      }),
    );

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        // params.date;
        this.baseHttpParams = {
          limit: 9999,
          offset: 0,
        };

        this.paymentBatchKey = params['paymentKey'];
        this.getConfig();
        this.getData(this.paymentBatchKey);

        this.startMonitoring();
        this.getMonitoringStatus();
      }),
    );

    this.setupComponent();
  }

  public onUnlock(): void {
    this.subscriptions.push(
      this.service.unlock(this.batch.paymentBatchKey).subscribe(
        () => {
          this.toastrService.success(this.MSG_UNLOCKED);

          this.refreshViewOnClick();
          this.getPayments(this.paymentBatchKey);
        },
        (error) => {
          this.toastrService.error(error && error.message ? `${error.status}: ${this.MSG_TRY_AGAIN}` : this.MSG_BATCH_ACTION_FAILED);
        },
      ),
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.isAlive = false;
  }

  private setupComponent(): void {
    this.editPaymentBatchForm = this.formBuilder.group({
      name: [{ value: '' }, Validators.required],
      date: [{ value: '' }, Validators.required],
      time: [{ value: '' }, Validators.required],
      batchType: [],
      locked: [false],
      addAchPmts: [false],
      addEfsPmts: [false],
      addPpmPmts: [false],
      addWirPmts: [false],
      paymentBatchFeeCode: [{ value: '' }],
      reviewers: [''],
    });

    const debitColumns: IColumnPredefinition[] = [
      {
        field: 'supplierKey',
        headerName: 'Supplier Key',
        cellRenderer: LinkCellRenderer,
        width: 75,
      },
      {
        field: 'supplierName',
        headerName: 'Supplier Name',
        width: 300,
      },
      {
        field: 'debitAmount',
        headerName: 'Debit Amount',
        width: 100,
      },
      {
        field: 'isAutomaticDebit',
        headerName: 'Automatic Debit',
        width: 80,
        cellRenderer: (data) => {
          return data && data.value != null ? data.value.toString().toUpperCase() : 'FALSE';
        },
      },
      {
        field: 'beneficiaryName',
        headerName: 'Beneficiary Name',
        width: 100,
      },
      {
        field: 'beneficiaryAccountNumber',
        headerName: 'Beneficiary Account Number',
        width: 100,
      },
      {
        field: 'advanceOutstandingBalance',
        headerName: 'Advance Oustanding Balance',
        width: 100,
      },
      {
        field: 'liability',
        headerName: 'Liability',
        width: 95,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'mpStatementEndBalance',
        headerName: 'Statement Balance',
        width: 100,
      },
      {
        field: 'percentage',
        headerName: 'Percentage',
        width: 100,
      },
      {
        field: 'marketplaceName',
        headerName: 'Marketplace',
        width: 100,
      },
      {
        field: 'mpStatementCloseDate',
        headerName: 'MP Statement close date',
        width: 100,
      },
      {
        field: 'mpSupStartDate',
        headerName: 'Onboard Date',
        width: 100,
      },
      {
        field: 'bankName',
        headerName: 'Bank Name',
        width: 100,
      },
      {
        field: 'bankId',
        headerName: 'Bank ID',
        width: 100,
      },
      {
        field: 'wfPaymentKey',
        headerName: 'WF Payment Key',
        width: 100,
      },
      {
        field: 'paymentFileId',
        headerName: 'Payment File ID',
        width: 100,
      },
      {
        field: 'paymentId',
        headerName: 'Payment ID',
        width: 100,
      },
      {
        field: 'rcvPartyId',
        headerName: 'RCV Party ID',
        width: 100,
      },
      {
        field: 'paymentCreateTs',
        headerName: 'Payment Create TS',
        width: 100,
      },
      {
        field: 'paymentSentTs',
        headerName: 'Payment Sent TS',
        width: 100,
      },
      {
        field: 'paymentStatus',
        headerName: 'Payment Status',
        width: 100,
      },
      {
        field: 'createTs',
        headerName: 'Create TS',
        width: 100,
      },
      {
        field: 'updateTs',
        headerName: 'Update TS',
        width: 100,
      },
      {
        field: 'supFundRecoverKey',
        headerName: 'Sup Fund Recover Key',
        width: 100,
      },
      {
        field: 'removeFromBatch',
        headerName: 'Remove from Batch',
        headerClass: 'default',
        cellRenderer: batchDeleteRenderer,
        cellClass: 'active',
        onCellClicked: (params) => {
          this.tableRemovePaymentNew(params);
        },
        width: 145,
      },
    ];
    const creditColumns: IColumnPredefinition[] = [
      {
        field: 'supplier.supplierKey',
        headerName: 'Supplier Key',
        cellRenderer: LinkCellRenderer,
        width: 75,
      },
      {
        field: 'paymentRequestKey',
        headerName: 'Payment Request Key',
        width: 100,
      },
      {
        field: 'supplier.supplierName',
        headerName: 'Supplier Name',
        width: 300,
      },
      {
        field: 'paymentType',
        headerName: 'Payment Type',
        width: 60,
      },
      {
        field: 'paymentRequestAmount',
        headerName: 'Requested payment by Supplier',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'availBalance',
        headerName: 'Suppliers available balance (Payability)',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'pybDueFromMpBalance',
        headerName: 'Due from marketplace (Payability)',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'mpDueFromMpBalance',
        headerName: 'Due from marketplace (Amazon dashboard)',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'numPaymentRequests',
        headerName: '# of payment requests',
        headerClass: 'calc',
        width: 60,
      },
      {
        field: 'systemPaymentAmount',
        headerName: 'System payment amount',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'finalPaymentAmount',
        headerName: 'Final payment amount to supplier',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'paymentAdjustmentAmount',
        headerName: 'Pre-processing System Adjustment',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'paymentOverrideAmount',
        headerName: 'Modified Payment Amount',
        headerClass: 'edit',
        editable: true,
        filter: true,
        cellRenderer: CurrencyCellRenderer,
        cellEditorParams: (params) => this.cellEditorValueGetter(params),
        onCellValueChanged: (params) => {
          this.tableOnCellValueChangeNew(params);
        },
        width: 95,
      },
      {
        field: 'pendMpPmtSentLast7Days',
        headerName: 'Marketplace payment made in the last 7 days',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 105,
      },
      {
        field: 'dueFromMpDelta',
        headerName: 'Due from Marketplace Delta',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        cellClass: twoColorClassAssigner,
        width: 95,
      },
      {
        field: 'pastDueDays',
        headerName: 'Past Due Days',
        headerClass: 'calc',
        width: 45,
      },
      {
        field: 'pastDueAmount',
        headerName: 'Past Due Amount',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        cellClass: oneColorClassAssigner,
        width: 95,
      },
      {
        field: 'mpDueFromMpBalance',
        headerName: 'Due from marketplace',
        headerClass: 'calc',
        width: 95,
      },
      {
        field: 'supplierLiability',
        headerName: 'Liability',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'dilution',
        headerName: 'Dilution',
        headerClass: 'calc',
        width: 95,
        cellClass: (params) => {
          if (!params) {
            return '';
          }
          return '' + params.value === 'Fail' ? 'fail' : 'pass';
        },
        cellRenderer: (params) => {
          if (!params) {
            return '';
          }
          return '' + params.value === 'Fail' ? 'YES' : 'NO';
        },
      },
      {
        field: 'confAcctActiveAvailBal',
        headerName: 'Account Active & Avail Balance',
        headerClass: 'calc',
        cellClass: threeColorClassAssigner,
        filter: true,
        width: 85,
      },
      {
        field: 'confDueFromMpMatches',
        headerName: 'Due from Marketplace Matches',
        headerClass: 'calc',
        cellClass: threeColorClassAssigner,
        filter: true,
        width: 75,
      },
      {
        field: 'confPayeeDiverted',
        headerName: 'Payee Status',
        headerClass: 'edit',
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellClass: (params) => threeColorClassAssigner(params, this.dictionaries),
        cellEditorParams: (params) => this.cellEditorValueGetter(params),
        valueFormatter: (params) => this.cellEditorValueFormatter(params),
        onCellValueChanged: (params) => {
          this.tableOnCellValueChangeNew(params);
        },
        width: 150,
      },
      {
        field: 'confPastDue',
        headerName: 'Past Due',
        headerClass: 'calc',
        cellClass: threeColorClassAssigner,
        width: 75,
      },
      {
        field: 'confAcctHealthStatus',
        headerName: 'Derived Account Health Standing',
        headerClass: 'calc',
        cellClass: threeColorClassAssigner,
        filter: true,
        width: 75,
      },
      {
        field: 'confPybAcctStatus',
        headerName: 'Payability Account Status',
        headerClass: 'calc',
        cellClass: threeColorClassAssigner,
        filter: true,
        width: 75,
      },
      {
        field: 'systemRecomOutcome',
        headerName: '(System Derived) Approved, Canceled',
        headerClass: 'calc',
        cellClass: threeColorClassAssigner,
        filter: true,
        width: 75,
      },
      {
        field: 'predictedAccHealthStatus',
        headerName: 'ML Recomendation',
        headerClass: 'calc',
        cellClass: threeColorClassAssigner,
        filter: true,
        width: 95,
      },
      {
        field: 'predictionProbability',
        headerName: 'ML Probability',
        headerClass: 'calc',
        width: 70,
      },
      {
        field: 'paymentOutcome.codeValue',
        headerName: '(Manual) Approved, Canceled or Hold ',
        headerClass: 'edit',
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellClass: (params) => threeColorClassAssigner(params, this.dictionaries),
        cellEditorParams: (params) => this.cellEditorValueGetter(params),
        valueFormatter: (params) => this.cellEditorValueFormatter(params),
        onCellValueChanged: (params) => {
          this.tableOnCellValueChangeNew(params);
        },
        width: 90,
      },
      {
        field: 'nextPaymentDate',
        headerName: 'Next Payment Date (From Amazon)',
        headerClass: 'calc',
        width: 90,
      },
      {
        field: 'expectedPaymentAmount',
        headerName: 'Expected Payment Amount (From Amazon)',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 90,
      },
      {
        field: 'expectedPaymentOverallBalance',
        headerName: 'Expected Payment to Overall Account Balance For Standard Orders (From Amazon)',
        headerClass: 'calc',
        valueFormatter: (params) => this.percentPipe.transform(params.data.expectedPaymentOverallBalance),
        width: 120,
      },
      {
        field: 'amazonPercentageOfLiability',
        headerName: 'Payment As A Percentage Of Liability (For Amazon)',
        headerClass: 'calc',
        valueFormatter: (params) => {
          return isUndefined(params.data.amazonPercentageOfLiability) ? '' : params.data.amazonPercentageOfLiability + '%';
        },
        width: 120,
      },
      {
        field: 'acctHealthStandingDetails',
        headerName: 'Account Health Standing Details',
        headerClass: 'calc',
        onCellClicked: (params) => {
          this.tableShowNoteNew(params);
        },
        width: 150,
      },
      {
        field: 'existingException',
        headerName: 'Existing Exception',
        headerClass: 'calc',
        onCellClicked: (params) => {
          this.tableShowNoteNew(params);
        },
        width: 150,
      },
      {
        field: 'cancellationRequest',
        headerName: 'Cancellation requests',
        headerClass: 'calc',
        width: 85,
      },
      {
        field: 'shortToLongMovAvgRatio',
        headerName: '3 day MA / 21 day MA',
        headerClass: 'calc',
        width: 85,
      },
      {
        field: 'shortToLongMovAvgRatioCbk',
        headerName: '3 day MA / 21 day MA - chargebacks',
        headerClass: 'calc',
        width: 85,
      },
      {
        field: 'rcvPurchAfterLastSupPmt',
        headerName: 'Receivables purchased since last payment',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'mpPmtRsvReleaseAfterLastSupPmt',
        headerName: 'Reserves released since last payment',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'mpPmtExcAfterLastSupPmt',
        headerName: 'MP Pmt Excess since last payment',
        headerClass: 'calc',
        cellRenderer: CurrencyCellRenderer,
        width: 95,
      },
      {
        field: 'outcomeReason.codeValue',
        headerName: 'Cancel or Modified Reason',
        headerClass: 'default',
        width: 150,
      },
      {
        field: 'reviewerNote',
        headerName: 'Payment Notes',
        headerClass: 'edit',
        onCellClicked: (params) => {
          this.tableAddPaymentNoteNew(params);
        },
        width: 250,
      },
      {
        field: 'transmitStatus',
        headerName: 'Transmit Status',
        headerClass: 'default',
        headerIcon: 'icon-help',
        headerTooltip: 'This status works only with IA Pay and Weekly (only those types support auto send file functionality to the bank)',
        width: 80,
      },
      {
        field: 'removeFromBatch',
        headerName: 'Remove from Batch',
        headerClass: 'default',
        cellRenderer: batchDeleteRenderer,
        cellClass: 'active',
        onCellClicked: (params) => {
          this.tableRemovePaymentNew(params);
        },
        width: 145,
      },
    ];

    this.columnsData = this.isDebit ? debitColumns : creditColumns;

    for (let colIndex = 0; colIndex < this.LAST_COLUMNS_PINNED; colIndex++) {
      this.columnsData[colIndex].pinned = 'left';
    }
  }

  private getConfig(): void {
    this.config = forkJoin([
      this.lookupService.getPayeeStatuses(),
      this.lookupService.getOutcomeReasons(),
      this.lookupService.getPaymentOutcome(),
    ]).pipe(
      map((result) => {
        const empty = {
          label: '',
          displayName: '',
          id: null,
          codeValue: null,
        };

        return {
          dictionaries: {
            confPayeeDiverted: result[0] ? [empty, ...result[0]] : undefined,
            outcomeReason: result[1] ? [empty, ...result[1]] : undefined,
            paymentOutcome: result[2] ? [empty, ...result[2]] : undefined,
            systemRecomOutcome: [
              { label: 'All', id: DerivedStatus.All },
              { label: 'Approved', id: DerivedStatus.Approved },
              { label: 'Hold', id: DerivedStatus.Hold },
            ],
            paymentOverrideAmount: [
              { label: 'All', id: PaymentOverrideAmount.All },
              { label: 'Yes', id: PaymentOverrideAmount.Yes },
              { label: 'No', id: PaymentOverrideAmount.No },
            ],
            confAcctActiveAvailBal: [
              { label: 'All', id: AccountBalance.All },
              { label: 'Pass', id: AccountBalance.Pass },
              { label: 'Fail', id: AccountBalance.Fail },
            ],
            confDueFromMpMatches: [
              { label: 'All', id: DueFromMP.All },
              { label: 'Pass', id: DueFromMP.Pass },
              { label: 'Fail', id: DueFromMP.Fail },
            ],
            confAcctHealthStatus: [
              { label: 'All', id: DerivedHealth.All },
              { label: 'OK', id: DerivedHealth.Ok },
              { label: 'At Risk', id: DerivedHealth.AtRisk },
            ],
            confPybAcctStatus: [
              { label: 'All', id: PayabilityAccounts.All },
              { label: 'Active', id: PayabilityAccounts.Active },
              { label: 'Pending', id: PayabilityAccounts.Pending },
              { label: 'Suspended', id: PayabilityAccounts.Suspended },
            ],
            predictedAccHealthStatus: [
              { label: 'All', id: MLRecomendation.All },
              { label: 'Review', id: MLRecomendation.Review },
              { label: 'Approve', id: MLRecomendation.Approve },
            ],
          },
        };
      }),
    );

    this.subscriptions.push(
      this.config.subscribe((data) => {
        this.dictionaries = data.dictionaries;
      }),
    );
  }

  private getData(batchId: string, params?: any): void {
    if (!params) {
      params = this.baseHttpParams;
    }

    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      httpParams = httpParams.append(key, params[key]);
    });

    this.getReviewers();
    this.getBatch(batchId);

    this.startWorkflowStatusInterval();
  }

  private getReviewers(): void {
    this.subscriptions.push(
      this.lookupService.getReviewers().subscribe((data) => {
        this.reviewers = data;
      }),
    );
  }

  private getBatch(batchId: string): void {
    this.subscriptions.push(
      this.service.getBatch(batchId).subscribe(
        (data: SupplierPaymentBatchModel) => {
          this.batch = data;
          this.isDebit = data.debitPayments || data.internalTransfers;
          this.isAutoDebit = data.debitPayments;
          this.isSubmitted = data.submitted;

          const date = this.getPlannedPaymentDate(this.batch);
          const time = this.getPlannedPaymentTime(this.batch);
          let type = null;
          if (data.instantAccess) {
            type = BatchType.INSTANT;
          } else if (data.scheduledPayments) {
            type = BatchType.SCHEDULED;
          } else if (data.proposedAdvanceLine) {
            type = BatchType.ADVANCE;
          }
          this.setupComponent();
          this.getPayments();

          this.editPaymentBatchForm.patchValue({
            name: this.batch.name,
            date,
            time,
            batchType: type,
            locked: this.batch.locked,
            addAchPmts: this.batch.addAchPmts,
            addEfsPmts: this.batch.addEfsPmts,
            addPpmPmts: this.batch.addPpmPmts,
            addWirPmts: this.batch.addWirPmts,
            paymentBatchFeeCode: this.batch.paymentBatchFeeCode,
          });

          this.isPending = false;

          if (this.batch.proposedAdvanceLine || this.batch.instantAccess || this.batch.scheduledPayments) {
            this.batchOptionChecked = true;
            this.disabledForm();
          }
        },
        () => {
          this.paymentsNotFound = true;
          this.paymentsLoading = false;
        },
      ),
    );
  }

  private getPayments(httpParams?: any): void {
    this.paymentsNotFound = false;
    this.paymentsLoading = true;
    this.payments = this.service.getPayments(this.paymentBatchKey, httpParams, this.isDebit);

    this.preSource = Observable.combineLatest(this.payments, this.filtersChanged).pipe(
      map((data: any[]) => {
        const args = data[1] instanceof DataTableQueryParams ? data[1] : this.filterParams;
        let result;

        if (args) {
          if (data[0] && data[0].results) {
            const modifiedData = this.filterData(data[0].results, args).map((transactionData) => {
              if (transactionData && transactionData.length) {
                const mappedData = transactionData;
                for (let i = 0; i < (<any>data).length; i++) {
                  const item = (<any>transactionData)[i];
                  if (item.hasOwnProperty('shortToLongMovAvgRatioRcv') && item['shortToLongMovAvgRatioRcv'] !== 0) {
                    mappedData[i]['shortToLongMovAvgRatio'] = item['shortToLongMovAvgRatioRcv'];
                  }
                }

                return mappedData;
              }

              return transactionData;
            });

            result = {
              total: modifiedData.length,
              results: modifiedData,
            };
          }
        } else {
          result = data[0];
        }
        return result;
      }),
      debounceTime(0),
      // TODO: check multiple triggering
    );
    this.subscriptions.push(
      this.preSource.subscribe(
        (result) => {
          this.paymentsNotFound = false;
          this.paymentsLoading = false;
          this.rows = result.results;
        },
        () => {
          this.paymentsNotFound = true;
          this.paymentsLoading = false;
        },
      ),
    );

    this.filtersChanged.next({});
  }

  private startMonitoring(): void {
    const name = 'payment-batch-' + this.paymentBatchKey;

    this.b2bWorkflowService.subscribeTask(name, (event) => {
      const data = event;
      this.task.task = { ...this.task.task, ...data };
      this.task.lastUpdated = data.finishedAt ? new Date(data.finishedAt) : undefined;
      this.task.status = this.statuses[this.task.task.status];
      if (this.task.task.status === 2) {
        this.task.status =
          this.task.status + ' (' + (this.task.task.progressStep || 0) + ' of ' + (this.task.task.progressCount || 0) + ')';
      }
    });
  }

  private getMonitoringStatus(): void {
    const taskName = 'payment-batch-' + this.paymentBatchKey;

    this.subscriptions.push(
      this.b2bWorkflowService.getLastExecution(taskName).subscribe(
        (data) => {
          this.task = <any>data;

          if (isUndefined(this.task) || isNullOrUndefined(this.task.task)) {
            return false;
          }

          this.task.lastUpdated = this.task.task.finishedAt ? new Date(this.task.task.finishedAt) : undefined;
          this.task.status = this.statuses[this.task.task.status];
          if (this.task.task.status === 2) {
            this.task.status =
              this.task.status + ' (' + (this.task.task.progressStep || 0) + ' of ' + (this.task.task.progressCount || 0) + ')';
          }
          return true;
        },
        () => {
          this.toastrService.error('Could not fetch batch status');
        },
      ),
    );
  }

  private startWorkflowStatusInterval(): void {
    this.subscriptions.push(
      TimerObservable.create(0, this.interval)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe(() => {
          this.getWorkflowStatus();
        }),
    );
  }

  private getWorkflowStatus(): void {
    this.subscriptions.push(
      this.service.getStatus(this.paymentBatchKey).subscribe(
        (success) => {
          const status = success;
          if (this.lastAddedPayments) {
            if (this.lastAddedPayments !== status.lastPmtAddTs) {
              this.lastAddedPayments = status.lastPmtAddTs;
              this.isOutdated = true;
            }
          } else {
            this.lastAddedPayments = status.lastPmtAddTs;
          }

          // last updated payments
          if (this.lastUpdatedPayments) {
            if (this.lastUpdatedPayments !== status.lastPmtSourceDataUpdTs) {
              this.lastUpdatedPayments = status.lastPmtSourceDataUpdTs;
            }
          } else {
            this.lastUpdatedPayments = status.lastPmtSourceDataUpdTs;
          }

          this.workflowStatus = success;
        },
        () => {
          this.toastrService.error('Getting last update timestamp failed.');
          this.isAlive = false;
        },
      ),
    );
  }

  private filterData(data: any[], args: any): any[] {
    return data.filter((x: any) => {
      const paymentOverrideAmount = args.paymentOverrideAmount
        ? (args.paymentOverrideAmount === 'Yes' && !isNullOrUndefined(x.paymentOverrideAmount)) ||
          (args.paymentOverrideAmount === 'No' && isNullOrUndefined(x.paymentOverrideAmount))
          ? true
          : false
        : true;

      const systemRecomOutcome = args.systemRecomOutcome ? x.systemRecomOutcome === args.systemRecomOutcome : true;
      const confAcctActiveAvailBal = args.confAcctActiveAvailBal ? x.confAcctActiveAvailBal === args.confAcctActiveAvailBal : true;
      const confDueFromMpMatches = args.confDueFromMpMatches ? x.confDueFromMpMatches === args.confDueFromMpMatches : true;
      const confAcctHealthStatus = args.confAcctHealthStatus ? x.confAcctHealthStatus === args.confAcctHealthStatus : true;
      const confPybAcctStatus = args.confPybAcctStatus ? x.confPybAcctStatus === args.confPybAcctStatus : true;
      const predictedAccHealthStatus = args.predictedAccHealthStatus ? x.predictedAccHealthStatus === args.predictedAccHealthStatus : true;

      return (
        paymentOverrideAmount &&
        systemRecomOutcome &&
        confAcctActiveAvailBal &&
        confDueFromMpMatches &&
        confAcctHealthStatus &&
        confPybAcctStatus &&
        predictedAccHealthStatus
      );
    });
  }

  private updateUrl(args: any): void {
    const params: any = {};
    if (args.paymentOverrideAmount) {
      params.paymentOverrideAmount = args.paymentOverrideAmount;
    }
    if (args.systemRecomOutcome) {
      params.systemRecomOutcome = args.systemRecomOutcome;
    }
    if (args.confAcctActiveAvailBal) {
      params.confAcctActiveAvailBal = args.confAcctActiveAvailBal;
    }
    if (args.confDueFromMpMatches) {
      params.confDueFromMpMatches = args.confDueFromMpMatches;
    }
    if (args.confAcctHealthStatus) {
      params.confAcctHealthStatus = args.confAcctHealthStatus;
    }
    if (args.confPybAcctStatus) {
      params.confPybAcctStatus = args.confPybAcctStatus;
    }
    if (args.predictedAccHealthStatus) {
      params.predictedAccHealthStatus = args.predictedAccHealthStatus;
    }

    let baseUrl = '';
    const index = this.router.url.indexOf('?');
    if (index >= 0) {
      baseUrl = this.router.url.substr(0, index);
    } else {
      baseUrl = this.router.url;
    }

    const url = this.router.createUrlTree([baseUrl], { queryParams: params }).toString();
    this.location.go(url);
  }

  private getPlannedPaymentDateTime(): Date {
    let plannedPaymentDateTime: Date;

    const dateControl = this.editPaymentBatchForm.get('date');
    const timeControl = this.editPaymentBatchForm.get('time');

    if (dateControl && timeControl) {
      const date: Date = dateControl.value;
      const time: string = timeControl.value;

      if (time && time.indexOf(':') >= 0) {
        const hours = time.substring(0, time.indexOf(':'));
        const minutes = time.substring(time.indexOf(':') + 1);

        if (!isUndefined(parseInt(hours, 10)) && !isUndefined(parseInt(minutes, 10))) {
          plannedPaymentDateTime = new Date(date);
          plannedPaymentDateTime.setHours(parseInt(hours, 10));
          plannedPaymentDateTime.setMinutes(parseInt(minutes, 10));
        }
      }
    }

    return plannedPaymentDateTime;
  }

  private getPlannedPaymentDate(batch: SupplierPaymentBatchModel): Date {
    const date = new Date(batch.plannedPaymentTime);

    if (date) {
      date.setHours(0);
      date.setMinutes(0);
    }

    return date;
  }

  private getPlannedPaymentTime(batch: SupplierPaymentBatchModel): string {
    let time = '';
    const date = new Date(batch.plannedPaymentTime);

    if (date) {
      time = (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    }

    return time;
  }

  private tableOnCellValueChangeNew(data: any): void {
    if (data && data.data && data.colDef) {
      const dataValue = {
        [data.colDef.field]: data.colDef.field === 'paymentOverrideAmount' ? +data.newValue : data.newValue.codeValue,
      };
      const paymentKey = (<SupplierPaymentModel>data.data).proposedPaymentKey;
      this.subscriptions.push(
        this.service.updatePayment(this.paymentBatchKey, paymentKey, dataValue).subscribe(
          () => {
            this.toastrService.success('New value saved.');
          },
          (error) => {
            this.toastrService.error(error && error.message ? error.error.message : 'Saving failed.');
          },
        ),
      );
    }
  }

  private tableShowNoteNew(params: any): void {
    const message = params.data[params.colDef.field];
    if (!isNullOrUndefined(message) && message.length > 0) {
    }
  }

  private tableAddPaymentNoteNew(record: any): void {
    const dialogRef = this.dialog.open(AddPaymentNoteComponent, {
      data: {
        supplierName: record.data.supplier.supplierName,
        reviewerNote: record.data.reviewerNote,
        record: record.data,
      },
      width: DIALOG_SIZE.SMALL2.width,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.value && data.record) {
        const paymentKey = (<SupplierPaymentModel>data.record).proposedPaymentKey;
        this.subscriptions.push(
          this.service.updatePayment(this.paymentBatchKey, paymentKey, data.value).subscribe(
            () => {
              this.toastrService.success('New value saved.');

              record.reviewerNote = data.value['reviewerNote'];
            },
            (error) => {
              this.toastrService.error(error && error.message ? error.error.message : 'Saving failed.');
            },
          ),
        );
      }
    });
  }

  private tableRemovePaymentNew(params: any) {
    if (params) {
      const { data } = params;
      const paymentKey = this.isDebit ? data.wfPaymentKey : data.proposedPaymentKey;
      this.subscriptions.push(
        this.service.removePayment(this.paymentBatchKey, paymentKey).subscribe(
          () => {
            this.toastrService.success('Payment successfully removed from batch.');

            this.getBatch(this.paymentBatchKey);
            this.getPayments(this.paymentBatchKey);
          },
          (error) => {
            this.toastrService.error(error && error.message ? error.error.message : 'Payment removal failed.');
          },
        ),
      );
    }
  }

  private requestBatchAction(action: SupplierPaymentBatchAction): void {
    this.subscriptions.push(
      this.service.requestBatchAction(this.batch.paymentBatchKey, action).subscribe(
        () => {
          this.toastrService.success('Batch action successfully requested.');

          this.getPayments(this.paymentBatchKey);
          this.getBatch(this.paymentBatchKey);
          // refetch - sometimes status on the backend needs to have more time to update it
          setTimeout(() => {
            this.getBatch(this.paymentBatchKey);
          }, this.FETCH_UPDATED_STATUS_DELAY);
        },
        (error) => {
          this.toastrService.error(
            error && error.message ? `${error.status}: ${error.error.message} ${this.MSG_TRY_AGAIN}` : this.MSG_BATCH_ACTION_FAILED,
          );
        },
      ),
    );
  }

  refreshViewOnClick(): void {
    this.getBatch(this.paymentBatchKey);

    this.isOutdated = false;
  }

  addPaymentsOnClick(): void {
    const action = new SupplierPaymentBatchAction('addPayments');
    this.requestBatchAction(action);
  }

  updatePaymentOnClick(): void {
    const action = new SupplierPaymentBatchAction('updatePayments');
    this.requestBatchAction(action);
  }

  approveOrSubbmitPaymentOnClick(): void {
    const dialogRef = this.dialog.open(ApprovePaymentComponent, {
      data: {
        paymentTotal: this.batch.paymentTotal,
        numSentToBank: this.batch.numSentToBank,
        numPayments: this.batch.numPayments,
        numApproved: this.batch.numApproved,
        numCancelled: this.batch.numCancelled,
        numHold: this.batch.numHold,
        isDebit: this.isDebit,
        isSubmitted: this.isSubmitted,
        showEFF: this.workflowStatus.showEFFdate,
      },
      width: DIALOG_SIZE.SMALL.width,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.requestBatchAction(this.batchActionFactory(result));
        }
      }),
    );
  }

  batchActionFactory(result: any) {
    if (!this.isDebit) {
      if (result.token) {
        return new SupplierPaymentBatchAction(this.workflowStatus.nextStepAction, result.token);
      }
    } else {
      return new SupplierPaymentBatchAction('submitPayments', null, result.achPostDate);
    }
  }

  editBatchOnClick(): void {
    this.isBatchInEditMode = true;
  }

  onEditionSubmit(): void {
    if (this.editPaymentBatchForm.valid) {
      const plannedPaymentTime = this.getPlannedPaymentDateTime();
      const value = this.editPaymentBatchForm.value;
      const init = {
        plannedPaymentTime,
        instantAccess: value.batchType === BatchType.INSTANT,
        scheduledPayments: value.batchType === BatchType.SCHEDULED,
        proposedAdvanceLine: value.batchType === BatchType.ADVANCE,
        ...value,
      };
      Object.assign(this.batch, init);

      this.subscriptions.push(
        this.service.updateBatch(this.batch.paymentBatchKey, this.batch).subscribe(
          () => {
            this.toastrService.success('Batch info has been successfully updated.');
            this.isBatchInEditMode = false;
          },
          (error) => {
            this.toastrService.error(error && error.message ? error.error.message : 'Updating batch details failed.');
          },
        ),
      );
    }
  }

  onEditionCancel(): void {
    this.isBatchInEditMode = false;
  }

  tableOnLoaded(): void {}

  tableOnParamQueryChanged(args: DataTableQueryParams): void {
    this.updateUrl(args);
    this.filtersChanged.next(args);
  }

  disabledForm() {
    if (this.editPaymentBatchForm && this.editPaymentBatchForm.get('paymentBatchFeeCode')) {
      if (this.batchOptionChecked) {
        this.editPaymentBatchForm.controls['paymentBatchFeeCode'].enable();
      } else {
        this.editPaymentBatchForm.controls['paymentBatchFeeCode'].disable();
      }
    }
  }

  public checkBatchOption() {
    this.batchOptionChecked = true;
  }

  public cellEditorValueGetter(params: any) {
    const dictionary = dictionaryAssigner(params, this.dictionaries);
    return {
      values: dictionary,
    };
  }

  public cellEditorValueFormatter(params: any): string {
    if (!isNullOrUndefined(params.value)) {
      if (params.value.displayName || params.value.displayName === '') {
        return params.value.displayName;
      }
      const dictionary = dictionaryAssigner(params, this.dictionaries);
      return displayNameAssigner(dictionary, params, '');
    }
    return;
  }
}
