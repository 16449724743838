import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from '../../config';

@Injectable()
export class CancelSupplierService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
  }

  getActiveMarketPlaces(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}active_marketplaces/${supplierKey}`;
    return this.httpClient.get(endpointUrl);
  }

  getSupplier(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}?taxId=1`;
    return this.httpClient.get(endpointUrl);
  }

  getCancellationReasons(): Observable<any> {
    const endpointUrl = `${this.apiUrl}cancellation_reasons`;
    return this.httpClient.get(endpointUrl);
  }

  putCancellation(supplierKey: string, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}/acc_cancellation`;
    return this.httpClient.put(endpointUrl, data).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  getPendingCancellationInfo(supplierKey: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}suppliers/${supplierKey}/get_acc_cancellation_reason`);
  }

  updatePendingCancellation(supplierKey: string, data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}suppliers/${supplierKey}/change_acc_cancellation_reason`, data);
  }
}
