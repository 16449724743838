<table class="table overlayed">
  <caption>
    <div class="caption-row">
      <span class="table-title">
        {{ title }}
        <span *ngIf="pdata.length">({{ pdata.length }})</span>
      </span>
    </div>
    <div class="load-bar inderteminate"></div>
  </caption>
  <thead>
    <tr>
      <th *ngFor="let column of columns" [class]="selectedClass(column.variable)" (click)="changeSorting(column.variable)">
        {{ column.display }}
      </th>
      <ng-container *ngFor="let btn of gridbtns">
        <td *ngIf="!btn.ishide"></td>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of pdata | orderby: convertSorting()">
      <td *ngFor="let column of columns">
        {{ row[column.variable] | format: column.filter }}
      </td>
      <ng-container *ngFor="let btn of gridbtns">
        <td *ngIf="!btn.ishide">
          <button type="button" class="btn btn-primary" (click)="click(btn, row)">{{ btn.title }}</button>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
