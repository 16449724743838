import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-event-filters',
  templateUrl: './event-filters.component.html',
  styleUrls: ['./event-filters.component.scss'],
})
export class EventFiltersComponent extends SubscriberComponent implements OnInit {
  @Output()
  filtersChange = new EventEmitter<any>();

  filters: FormGroup;
  eventTypesSelect;
  search: FormControl = new FormControl();

  eventTypesSelectList = ['event', 'analitics', 'messaging-result', 'messaging-log', 'cli-event', 'api-event', 'task-change-event'];

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    // const filterEventTypes = this.eventTypesSelectList.filter((elem) => elem !== 'analitics');

    this.filters = this.formBuilder.group({
      // type: [filterEventTypes],
      // created_after: [''],
      // created_before: [''],
      search: [''],
    });

    this.subscriptions.push(
      this.search.valueChanges.pipe(debounceTime(800)).subscribe((value) => {
        this.filters.controls['search'].patchValue(value);
      }),
    );

    this.eventTypesSelect = of(this.eventTypesSelectList);

    this.subscriptions.push(
      this.filters.valueChanges.subscribe((filters) => {
        this.filtersChange.next(filters);
      }),
    );
  }

  getFieldValue(fieldName: string) {
    return this.filters.controls[fieldName].value;
  }

  clearFieldValue(fieldName: string) {
    this.filters.controls[fieldName].patchValue('');
  }

  clearSearch() {
    this.search.reset();
  }
}
