import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-amazon-lending',
  templateUrl: './supplier-amazon-lending.component.html',
})
export class SupplierAmazonLendingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
