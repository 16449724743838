import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CustomDialogData } from '../interfaces/confirm-dialog.interface';
import { DialogConfig } from '../interfaces/dialog-config.interface';
import { EvaConfirmDialogComponent } from './dialogs/confirm/eva-confirm-dialog.component';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public open(component: any, config: DialogConfig = { autoFocus: false }): void {
    this.dialog.open(component, config);
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }

  public openConfirm(data?: CustomDialogData): Promise<boolean> {
    const customData = data || ({} as CustomDialogData);
    const defaultConfig: CustomDialogData = {
      title: 'Are you sure you want to do this?',
    };
    const configData: CustomDialogData = {
      ...defaultConfig,
      ...customData,
    };

    return this.dialog.open(EvaConfirmDialogComponent, { data: configData }).afterClosed().toPromise();
  }
}
