import { ModelInterface } from '../../shared/utils/model-interface';

export class ReportListModel implements ModelInterface {
  public label: string;
  public name: string;
  public reportKey: string;

  public enabledForReports: boolean;
  public enabledForSupplier: string;
  public workflowId: string;
  public googleSheet: string;
  public sortBy: string;

  public task: any;
  public lastUpdated: Date;
  public taskStatus: string;

  constructor(jsonObject) {
    this.fromJSON(jsonObject);
  }

  getKey(): string {
    return this.reportKey;
  }

  fromJSON(jsonObject) {
    if (!jsonObject) {
      return;
    }

    this.label = jsonObject.label;
    this.name = jsonObject.name;
    this.reportKey = jsonObject.report_key;
    this.googleSheet = jsonObject.google_sheet;
    this.sortBy = jsonObject.sort_by;
    this.workflowId = jsonObject.workflow_id;
    this.enabledForReports = jsonObject.enabled_for_reports;
    this.enabledForSupplier = jsonObject.enabled_for_supplier;
  }
}
