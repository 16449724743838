import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { EmailConfirmDialogComponent } from '../../../../crm-tools/emails/email-confirm-dialog/email-confirm-dialog.component';
import { CardFormgroupMessageService } from '../../../../shared/card-formgroup/card-formgroup-message.service';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { PendingInterceptorService } from '../../../../shared/http-loader/pending-interceptor.service';
import { CrmStates } from '../../../../shared/layout/layout.enums';
import { LayoutState } from '../../../../shared/layout/store/layout.reducers';
import { AppState } from '../../../../store/app.reducers';
import { PaymentConfigModel } from '../../../model/payment-config.model';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { RxEditBaseComponent } from '../../../rx-edit-base.component';
import { DepositAccountService } from '../../../services/deposit-account/deposit-account.service';
import { SupplierService } from '../../../services/supplier.service';
import { DepositAccountModel } from '../../../model/deposit-account.model';

import {
  FetchSupplierDetails,
  FetchSupplierPaymentConfigs,
  selectEfsCardRequested,
  selectPaymentMethods,
  selectSupplierDetails,
} from '../../../store/supplier/supplier.actions';
import { ChooseCardTypeComponent } from '../../choose-card-type/choose-card-type.component';
import { ErrorLogger } from './../../../../shared/services/error-logger/error-logger.service';

@Component({
  selector: 'app-supplier-payment-list',
  templateUrl: './supplier-payment-list.component.html',
  styleUrls: ['./supplier-payment-list.component.scss'],
})
export class SupplierPaymentListComponent extends RxEditBaseComponent implements OnInit, OnDestroy {
  private readonly PHYSICAL_CARD_TYPE = 'PHYSICAL';
  paymentConfigList$: Observable<PaymentConfigModel[]>;
  efsCardRequested$: Observable<string>;
  supplierDetails: SupplierDetailsModel;
  SUPPLIER_SERVICE_CLASS_NAME = 'SupplierService';
  subscriptions: Subscription[] = [];
  layoutState$: Store<LayoutState>;
  crmPanelStates = CrmStates;
  ppmAvailable: boolean;
  isSpinnerVisible = false;
  depositAccount: DepositAccountModel;

  paymentConfigStatuses = {
    approved: 'badge-success',
    rejected: 'badge-danger',
    pending: 'badge-warning',
  };

  hasEfs = false;

  constructor(
    public dialogService: MatDialog,
    protected store: Store<AppState>,
    errorLogger: ErrorLogger,
    private formBuilder: FormBuilder,
    private depositAccountService: DepositAccountService,
    private supplierService: SupplierService,
    private toastrService: ToastrService,
    protected messageService: CardFormgroupMessageService,
    private pendingRequestInterceptorService: PendingInterceptorService,
  ) {
    super(store, messageService, errorLogger);

    this.layoutState$ = this.store.select('layout');
  }

  ngOnInit() {
    this.store.dispatch(new FetchSupplierPaymentConfigs({ fresh: true }));

    this.subscriptions.push(
      this.pendingRequestInterceptorService.pendingRequestsStatus.subscribe((hasPendingRequests) => {
        setTimeout(() => {
          this.isSpinnerVisible = hasPendingRequests;
        });
      }),
    );

    this.efsCardRequested$ = this.store.select(selectEfsCardRequested);
    this.paymentConfigList$ = this.store.select(selectPaymentMethods).pipe(
      map((results) => {
        if (!isUndefined(results)) {
          return Object.keys(results).map((key) => {
            if (results[key].paymentType === 'EFS') {
              this.hasEfs = true;
            }
            return results[key];
          });
        }
      }),
    );

    this.paymentConfigList$.pipe(share());
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        this.supplierDetails = data;
        if (data) {
          this.ppmAvailable = data.ppmAvailable;
          this.formGroups['DEBIT_CARD_PUSH_FORM'] = this.formBuilder.group({
            ppmAvailable: data.ppmAvailable,
          });
          this.refreshDepositeAccount()
        }
      }),
    );

  }

  /**
   * Referesh deposite account data
   * 
   * @returns Promise<void>
   */
  protected refreshDepositeAccount() {
    return this.depositAccountService.get(this.supplierDetails.depositAccountKey).subscribe((result) => {
      this.depositAccount = result;
    })
  }

  protected requestEfsCard() {
    this.supplierService.requestEfsCard(this.supplierDetails.supplierKey, location.toString()).subscribe(
      (result) => {
        this.toastrService.success('EFS Card request sent!');
        this.refreshSupplierDetails();
      },
      () => {
        this.toastrService.error('Error occured. EFS Card request could not be sent. Try again');
      },
    );
  }

  /**
   * Toogle the status of automatic payment on account details
   */
  protected changeAutomaticPaymentsStatus() {
    const statusCheck = this.depositAccount.autoPostingBlocked ? 'unblock' : 'block';
    const msg = `Are you sure you want to ` +
      `${statusCheck} automatic payments for this supplier?`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: '450px',
    });

    const updatedValues = {
      ...this.depositAccount,
      autoPostingBlocked: !this.depositAccount.autoPostingBlocked
    };
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.subscriptions.push(

          this.depositAccountService.update(this.depositAccount.depositAccountKey, updatedValues).subscribe((result) => {
            this.depositAccount = result;
            this.toastrService.success(`Automatic payment have been ${statusCheck}ed!`);
          }),

        );
      }
    });
  }

  protected deleteDepositAccount() {
    const msg = 'Are you sure you want to delete deposit account for this supplier?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.subscriptions.push(
          this.depositAccountService.remove(this.supplierDetails.depositAccountKey, this.supplierDetails.supplierKey).subscribe(
            (data) => {
              this.refreshSupplierDetails();
              this.toastrService.success('Deposit account removed!');
            },
            (error) => {
              this.toastrService.error('Deposit account could not been removed. Try again!');
            },
          ),
        );
      }
    });
  }

  protected generateBankStatement() {
    this.subscriptions.push(
      this.depositAccountService.generateBankStatement('FifthThirdBankAccount', this.supplierDetails.supplierKey).subscribe(
        (data) => {
          this.toastrService.success('Bank Statement Generated!');
          FileSaver.saveAs(data, 'Bank Statement');
        },
        () => {
          this.toastrService.error('Bank Statement could not been generated. Try again!');
        },
      ),
    );
  }

  protected getSupplierKey(): string {
    return this.supplierDetails.supplierKey;
  }

  protected resolveParamsBy(formGroupKey: string): any[] {
    return [];
  }

  protected resolveServiceClassBy(formGroupKey: string) {
    return this.SUPPLIER_SERVICE_CLASS_NAME;
  }

  assignDepositAccount() {
    const depositAccountDefaults = {
      accountName: this.supplierDetails.legalName,
      supplierKey: this.supplierDetails.supplierKey,
    };

    this.subscriptions.push(
      this.depositAccountService.create(depositAccountDefaults).subscribe((data) => {
        this.refreshSupplierDetails();
        this.toastrService.success('Deposit account created!');
      }),
    );
  }

  public chooseCardType() {
    const cardTypeDialog = this.dialogService.open(ChooseCardTypeComponent);
    this.subscriptions.push(cardTypeDialog.afterClosed().subscribe((cardConfig) => this.requestChosenCardType(cardConfig)));
  }

  public requestMarqetaCard(cardRequestObject: any) {
    this.supplierService.requestMarqetaCard(this.supplierDetails.supplierKey, cardRequestObject).subscribe(
      (result) => {
        this.toastrService.success('Marqeta Card request sent!');
        this.refreshSupplierDetails();
      },
      (error) => {
        const errorMsgFromApi = error.error.msg || error.error.message || error.error;
        this.toastrService.error(errorMsgFromApi);
      },
    );
  }

  public openModalUpdateMarqetaData() {
    const previewDialog = this.dialogService.open(EmailConfirmDialogComponent, {
      height: 'auto',
      width: '40%',
      data: { msg: 'This option will wipe out any existing business and user details at Marqeta. Are you sure you want to proceed ?' },
    });

    previewDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.updateDataFromMarqeta();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

  private updateDataFromMarqeta() {
    this.supplierService.updateDataFromMarqeta(this.supplierDetails.supplierKey).subscribe(
      (success) => {
        this.toastrService.success('Updated company and user information');
      },
      (error) => {
        this.toastrService.error(error && error.error && error.error.message ? error.error.message : 'Update from marqeta failed');
      },
    );
  }

  private requestChosenCardType(cardConfig: any) {
    if (!isNullOrUndefined(cardConfig)) {
      const cardRequestObject = this.prepareMarqetaCardRequestObject(cardConfig);
      this.requestMarqetaCard(cardRequestObject);
    }
  }

  private refreshSupplierDetails() {
    this.store.dispatch(
      new FetchSupplierDetails({
        supplierKey: this.supplierDetails.supplierKey,
        refresh: true,
      }),
    );
  }

  private prepareMarqetaCardRequestObject(cardConfig: any) {
    const cardRequestObject = {
      pcard_type_to_issue: [cardConfig.type],
    };

    if (cardConfig.type === this.PHYSICAL_CARD_TYPE) {
      cardRequestObject['recipient_address'] = cardConfig.address;
      cardRequestObject['expedite_card'] = cardConfig.expediteCard;
    }

    return cardRequestObject;
  }

  // private displayErrorStatement(error) {
  //   const marqetaCommonErrorMessage = 'Error occured. Marqeta Card request could not be sent. Try again';
  //   const errorMessage = (error && error.error && error.error.error_message ? error.error.error_message : marqetaCommonErrorMessage);

  //   this.toastrService.error(errorMessage);
  // }
}
