import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
// eslint-disable-next-line
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { DescriptionModalComponent } from '../../../processes/balance-approval/description-modal/description-modal.component';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { CsvExportService } from '../../../shared/csv-export-service/csv-export.service';
import Logger from '../../../shared/logger';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { EfsService } from '../../services/efs.service';
import { PaymentConfigService } from '../../services/payment-config/payment-config.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { ROW_COUNT_COLUMN_DEF } from './../../../shared/data-table-grid/models/row-count-column-def';
import { MarqetaTransaction } from './marqeta-transaction.model';
import { SupplierMarqetaService } from './supplier-marqeta.service';

@Component({
  selector: 'app-supplier-marqeta-account',
  templateUrl: './supplier-marqeta-account.component.html',
  styleUrls: ['./supplier-marqeta-account.component.scss'],
})
export class SupplierMarqetaAccountComponent extends SubscriberComponent implements OnInit {
  public cardTransactionHistory: Observable<{ results: MarqetaTransaction[]; total: number }>;
  public cardTransactionHistorySettings: any;
  public creditInfo: any = {};
  public historyConfig: any;
  public efsRebatePercent: number;
  public time = 7;
  public dateButtons = [
    { time: 3, isPicked: false },
    { time: 7, isPicked: true },
    { time: 14, isPicked: false },
  ];
  public filterKeys = [
    'transaction_date__gt',
    'transaction_date__lt',
    'process_date__gt',
    'process_date__lt',
    'transaction_key',
    'amount',
    'pyb_fee',
    'status',
  ];
  public isDataLoading = false;

  public cardTransactionsConfigForm: FormGroup = null;
  public cardCongigForm: FormGroup = null;
  public isVelocityControlsFormVisible = false;

  private supplierInfo: SupplierDetailsModel;
  private supplierKey: string;

  private paginationOptions = [10, 25, 50, 100];
  private baseHttpParams: any;
  private args = {};

  private paymentsData;
  private marqetaStatuses;

  cardTransactionHistoryData = null;
  readonly filterConfig = [
    {
      fieldName: 'startTransactionDate',
      label: 'Transaction Date (from)',
      fieldType: 'datePicker',
      defaultValue: null,
    },
    {
      fieldName: 'endTransactionDate',
      label: 'Transaction Date (to)',
      fieldType: 'datePicker',
      defaultValue: null,
    },
    {
      fieldName: 'process_date__gt',
      label: 'Post Date (from)',
      fieldType: 'datePicker',
      defaultValue: null,
    },
    {
      fieldName: 'process_date__lt',
      label: 'Post Date (to)',
      fieldType: 'datePicker',
      defaultValue: null,
    },
    {
      fieldName: 'transaction_key',
      label: 'Transaction ID',
      fieldType: 'input',
      defaultValue: null,
    },
    {
      fieldName: 'amount',
      label: 'Transaction Amount',
      fieldType: 'input',
      defaultValue: null,
    },
    {
      fieldName: 'pyb_fee',
      label: 'Rebate Amount',
      fieldType: 'input',
      defaultValue: null,
    },
    {
      fieldName: 'status',
      label: 'Transaction Status',
      fieldType: 'select',
      options: [
        { label: 'All', id: '' },
        { label: 'CLEARED', id: 'CLEARED' },
        { label: 'DECLINED', id: 'DECLINED' },
        { label: 'PENDING', id: 'PENDING' },
        { label: 'COMPLETED', id: 'COMPLETED' },
      ],
      defaultValue: '',
    },
  ];
  readonly cardTransactionHistoryColumnDefs = [
    ROW_COUNT_COLUMN_DEF,
    {
      field: 'transaction_date',
      headerName: 'Transaction Date',
    },
    {
      field: 'process_date',
      headerName: 'Post Date',
    },
    {
      field: 'transaction_key',
      headerName: 'Transaction ID',
      cellRenderer: (params) => {
        if (params.value === null) {
          return '';
        }
        return `<a href="#/suppliers/${this.supplierKey}/card-transactions/marqeta/${params.value}">${params.value}</a>`;
      },
    },
    {
      field: 'xact_type',
      headerName: 'Transaction Type',
    },
    {
      field: 'merchant_name',
      headerName: 'Merchant Name',
    },
    {
      field: 'merchant_city',
      headerName: 'Merchant City',
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 90,
    },
    {
      field: 'fees',
      headerName: 'Fees',
      width: 90,
    },
    {
      field: 'amount_currency',
      headerName: 'Currency',
      width: 90,
    },
    {
      field: 'amount',
      headerName: 'Transaction Amount',
    },
    {
      field: 'posted_amount',
      headerName: 'Posted Amount',
      width: 130,
    },
    {
      field: 'pyb_memo',
      headerName: 'Payability Decline Reason',
      width: 95,
      onCellClicked: ({ value }) => {
        this.openModal({ pyb_memo: value }, 'pyb_memo', 'Memo');
      },
    },
    {
      field: 'rejection_reason',
      headerName: 'Marqeta Decline Reason',
      width: 90,
      onCellClicked: ({ value }) => {
        this.openModal({ rejection_reason: value }, 'rejection_reason', 'Rejection Reason');
      },
    },
    {
      field: 'pyb_fee',
      headerName: 'Rebate Amount',
      cellClass: 'text-right',
    },
    {
      field: 'rebate_percent',
      headerName: 'Rebate Percentage',
    },
    {
      field: 'status',
      headerName: 'Transaction Status',
    },
  ];

  constructor(
    private toastrService: ToastrService,
    private csvExportService: CsvExportService,
    private store: Store<AppState>,
    private efsService: EfsService,
    private formBuilder: FormBuilder,
    private paymentConfigsService: PaymentConfigService,
    private supplierMarqetaService: SupplierMarqetaService,
    private supplierService: SupplierService,
    private dialogService: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.initCardTransactionsConfigForm();
    this.initData();
  }

  public isProduction() {
    return window.location && window.location.href ? window.location.href.includes('internal.payability.com') : false;
  }

  public exportAllData() {
    this.subscriptions.push(
      this.exportData({}).subscribe((data) => {
        this.csvExportService.exportTransactions(data, 'MARQETA', '_all');
      }),
    );
  }

  public exportCurrentData() {
    this.subscriptions.push(
      this.exportData({
        start_date: moment().subtract(this.time, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        startTransactionDate: this.paymentsData.startTransactionDate,
        ...this.args,
      }).subscribe((data) => {
        this.csvExportService.exportTransactions(data, 'MARQETA', '_current');
      }),
    );
  }

  public exportData(data) {
    return this.efsService.downloadMarqeta(this.supplierKey, {
      make_dump: true,
      keyFields: { numberOfTransactionsRequested: 1000 },
      ...data,
    });
  }

  public updateEFSRebate() {
    const efsRebate = this.cardCongigForm.get('efsRebatePercent').value;
    const efsRebatePercent = efsRebate === null ? null : +efsRebate < 0 ? 0 : +efsRebate;
    const dataToSave = {
      ...this.supplierInfo,
      efsRebatePercent,
    };

    this.subscriptions.push(
      this.supplierService.saveItem(this.supplierKey, new SupplierDetailsModel(dataToSave)).subscribe(
        () => {
          this.toastrService.success('EFS rebate percentage updated.');
        },
        () => {
          this.toastrService.error('Could not save EFS rebate percentage');
        },
      ),
    );
  }

  public saveCardTransactionsConfig({ value }) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        message: 'Editing this field will modify the internal velocity check for this supplier.',
        isProceedButtonVisible: true,
      },
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.updateCardTransactionsConfig(value);
        }
      }),
    );
  }

  private updateCardTransactionsConfig(value) {
    this.subscriptions.push(
      this.paymentConfigsService.updateCardTransactionsConfig(this.supplierKey, value).subscribe(
        () => this.toastrService.success('Card Transactions Configuration has been successfully updated'),
        (error) => Logger.error(error),
      ),
    );
  }

  private setHistoryConfig() {
    this.baseHttpParams = {
      limit: 20,
      offset: 0,
      treasury_approved: null,
    };

    this.historyConfig = of({
      dictionaries: this.getDictionariesData(),
      pagination: {
        paginationOptions: this.paginationOptions,
        default: this.baseHttpParams.limit ? this.baseHttpParams.limit : 50,
      },
    });
  }

  private getDictionariesData(): any {
    const dictionariesData: any = {};

    if (this.marqetaStatuses) {
      this.marqetaStatuses.map((data) => {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            dictionariesData[key] = data[key];
          }
        }
      });

      return dictionariesData;
    }

    return null;
  }

  public loadData(button = null) {
    if (button !== null && button.hasOwnProperty('time') && button.hasOwnProperty('isPicked')) {
      this.dateButtons = this.dateButtons.map((buttonMap) => {
        buttonMap.isPicked = false;
        return buttonMap;
      });

      this.time = button.time;
      button.isPicked = !button.isPicked;
    }

    this.isDataLoading = true;

    this.subscriptions.push(
      this.paymentConfigsService
        .query(this.supplierKey)
        .pipe(
          map((data) => {
            const payments = data.map((payment) => payment.paymentType);
            const efsPayment = payments.indexOf('EFS') > -1 ? data[payments.indexOf('EFS')] : null;
            let companyId = null;

            if (!isNullOrUndefined(efsPayment)) {
              companyId = efsPayment.paymentConfig.efs.companyId;
            }

            return {
              companyNumber: companyId,
              keyFields: {
                transactionStartDate: moment().subtract(this.time, 'days').format('YYYY-MM-DD'),
              },
            };
          }),
        )
        .subscribe(
          (data) => {
            this.paymentsData = {
              companyNumber: data.companyNumber,
              startTransactionDate: data.keyFields.transactionStartDate,
            };

            this.getAllMarqetaData(data);
          },
          (error) => {
            if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
              this.toastrService.error(error.error.message, 'Could not fetch supplier payment configs');
            }
          },
        ),
    );
  }

  public openModal(value, key, title): void {
    if (!value || !value[key]) {
      return;
    }

    const data = Object.assign({ text: value[key], title, key });

    const dialogRef = this.dialogService.open(DescriptionModalComponent, {
      width: '70vh',
      data,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  private initData() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe(
        (supplierDetailsModel: SupplierDetailsModel) => {
          if (!isUndefined(supplierDetailsModel)) {
            this.supplierInfo = supplierDetailsModel;
            this.supplierKey = supplierDetailsModel.supplierKey;
            this.efsRebatePercent = supplierDetailsModel.efsRebatePercent;
            this.cardCongigForm.get('efsRebatePercent').setValue(supplierDetailsModel.efsRebatePercent);

            this.setMarqetaMarqetaStatuses();
            this.loadData();
            this.getCardTransactionConfig();
          }
        },
        (error) => {
          if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
            this.toastrService.error(error.error.message, 'Could not get supplier info');
          }
        },
      ),
    );
  }

  getFilteredMarqeta(filters): void {
    this.getMarqeta(filters);
  }

  private setMarqetaMarqetaStatuses() {
    this.subscriptions.push(
      this.supplierMarqetaService.getMarqetaMarqetaStatuses(this.supplierKey).subscribe((data) => {
        this.marqetaStatuses = data;
        this.setHistoryConfig();
      }),
    );
  }

  private getAllMarqetaData(data, filters = null) {
    if (this.args === null || filters !== null) {
      this.args = { ...this.args, ...filters };
    }

    this.args = {};

    this.getMarqeta(this.args);

    this.subscriptions.push(
      this.efsService.getMarqeta(this.supplierKey, 'get_credit_info', data).subscribe(
        (result) => {
          this.creditInfo = result[0];
        },
        (error) => {
          if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
            this.toastrService.error(error.error.message, 'Could not get Credit Info data.');
          }
        },
      ),
    );
  }

  private getMarqeta(filters) {
    this.subscriptions.push(
      this.supplierMarqetaService
        .getMarqeta(this.supplierKey, this.paymentsData, filters)
        .pipe(
          finalize(() => {
            this.isDataLoading = false;
          }),
        )
        .subscribe(
          (transactionsData: { results: MarqetaTransaction[]; total: number }) => {
            this.cardTransactionHistory = null;
            this.cardTransactionHistory = of({
              results: transactionsData && transactionsData.results ? transactionsData.results : [],
              total: transactionsData.total > 0 ? transactionsData.total : 0,
            });

            this.cardTransactionHistoryData = transactionsData && transactionsData.results ? transactionsData.results : [];
          },
          (error) => {
            this.cardTransactionHistory = of({
              results: [],
              total: null,
            });

            if (error && error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
              this.toastrService.error(error.error.message, 'Could not get Transaction data.');
            }
          },
        ),
    );
  }

  private initCardTransactionsConfigForm() {
    this.cardTransactionsConfigForm = this.formBuilder.group({
      max_xact_count_per_hour: [null],
      max_xact_amount_at_once: [null],
      max_xact_amount_daily: [null],
      override_foreign_fraud_check: [null],
    });

    this.cardCongigForm = this.formBuilder.group({
      efsRebatePercent: [null],
    });
  }

  private getCardTransactionConfig() {
    this.subscriptions.push(
      this.paymentConfigsService.getCardTransactionsConfig(this.supplierKey).subscribe(
        (response: any) => this.getCardTransactionConfigSuccess(response),
        (error) => Logger.error(error),
      ),
    );
  }

  private getCardTransactionConfigSuccess(response) {
    if (!isNullOrUndefined(response)) {
      this.patchCardTransactionsConfigForm(response);
    }
  }

  private patchCardTransactionsConfigForm(configObject) {
    if (!isNullOrUndefined(configObject) && !isNullOrUndefined(this.cardTransactionsConfigForm)) {
      this.cardTransactionsConfigForm.patchValue(configObject);
      this.isVelocityControlsFormVisible = true;
    }
  }
}
