<mat-form-field>
  <mat-select placeholder="Time" [(ngModel)]="val" (selectionChange)="onChangeTime()">
    <mat-option *ngFor="let hour of hours" [value]="hour.value">
      {{ hour.name }}
    </mat-option>
  </mat-select>
  <button *ngIf="last" mat-icon-button matSuffix color="warn" (click)="onRemoveTime($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
