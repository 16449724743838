import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-approval-notes-modal',
  templateUrl: './approval-notes-modal.component.html',
  styleUrls: ['./approval-notes-modal.component.scss'],
})
export class ApprovalNotesModalComponent implements OnInit {
  public supplierName: string;
  public reviewerNote: string;

  private record: any;

  constructor(public dialogRef: MatDialogRef<ApprovalNotesModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    if (!isNullOrUndefined(this.data)) {
      this.supplierName = this.data.supplierName;
      this.reviewerNote = this.data.reviewerNote;
      this.record = this.data.record;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  public submitOnClick(): void {
    const data = {
      record: this.record,
      value: this.reviewerNote,
    };

    this.dialogRef.close(data);
  }
}
