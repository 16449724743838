<article class="article flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="d-flex">
        <div>
          <h4 class="dotted" matTooltip="Default view contains data from the last 30 days. Please use filters to adjust data range.">
            Transaction Details
          </h4>
        </div>
        <div class="ml-auto">
          <button class="mat-raised-button mat-primary mr-3" (click)="refreshButton()" [disabled]="isBlocked">Refresh</button>
          <button class="mat-raised-button mat-primary mr-3" (click)="exportCsv()">Export to CSV</button>
        </div>
      </div>

      <app-filtering-panel-component
        *ngIf="supplierKey"
        (searchValueEmitter)="getFilteredXact($event)"
        [customTriggerSearchAction]="true"
        [configuration]="xactFilterConfig"
      ></app-filtering-panel-component>
      <div *ngIf="xactData">
        <div [hidden]="isDataLoading">
          <app-data-table-grid [rows]="xactData" [columnDefs]="xactColumnDefs" [domLayout]="'autoHeight'"> </app-data-table-grid>
          <app-pyb-pagination
            [configuration]="paginationConfiguration"
            [paginationData]="paginationData"
            (onPageChange)="onPageChange($event)"
          >
          </app-pyb-pagination>
        </div>
        <app-loading-spinner *ngIf="isDataLoading" [size]="2"></app-loading-spinner>
      </div>
    </div>
  </div>
</article>
