import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { isUndefined } from 'util';

import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from './../../../shared/confirm-dialog/confirm-dialog.component';
import { ADD_TRANSACTION_EVENT, TableEventsService } from './../../../shared/table-events.service';
import { AppState } from './../../../store/app.reducers';
import { CliService } from './../../services/cli/cli.service';

@Component({
  selector: 'app-add-recovery',
  templateUrl: './add-recovery.component.html',
  styleUrls: ['./add-recovery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddRecoveryComponent extends SubscriberComponent implements OnInit {
  addRecoveryForm: FormGroup;
  marketplaces: any[];
  statements: any[];
  maxDate = moment().toDate();
  supplierKey: string;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddRecoveryComponent>,
    public dialogService: MatDialog,
    private cliService: CliService,
    private toastrService: ToastrService,
    private eventsService: TableEventsService,
  ) {
    super();
  }

  createForm() {
    this.addRecoveryForm = this.formBuilder.group({
      marketplace: [''],
      post_date: [moment().subtract(1, 'days').toDate()],
      amount: [''],
      reason: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.supplierKey = data.supplierKey;

          this.marketplaces = data.marketplaces.map((element) => {
            return {
              label: element.externalSupplierId
                ? element.marketplaceName + ' (' + element.externalSupplierId + ')'
                : element.marketplaceName,
              id: element.marketplaceName,
            };
          });
        }
      }),
    );
    this.createForm();
  }

  save() {
    const msg = 'Are you sure you want to proceed?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: '450px',
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.submit();
        }
      }),
    );
  }

  submit() {
    const dataToSave = {
      ...this.addRecoveryForm.value,
    };

    dataToSave['post_date'] = moment(dataToSave.post_date).format('YYYY-MM-DD');

    this.subscriptions.push(
      this.cliService.addRecovery(dataToSave, this.supplierKey).subscribe(
        () => {
          this.toastrService.success('Recovery added successfuly');
          this.eventsService.broadcast(ADD_TRANSACTION_EVENT);
          this.closeDialog({ success: true });
        },
        (error) => {
          this.toastrService.error(`An error occured while adding recovery: ${error.message}`);
        },
      ),
    );
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }
}
