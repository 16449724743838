import { autoserialize } from 'cerialize';

import { IPeriod, ProgressivePricingOfferDTO } from '../store/calculator-interfaces';

export class ProgressivePricingOfferModel {
  @autoserialize
  public requestedAmount: number;

  @autoserialize
  public approvedAmount: number;

  @autoserialize
  public averageFee: number;

  @autoserialize
  public reserveForRepayments: number;

  @autoserialize
  public netDeliveryAmount: number;

  @autoserialize
  public periods: IPeriod[];

  @autoserialize
  public totalFee: number;

  @autoserialize
  public totalPeriodLength: number;

  @autoserialize
  public riskRating: number;

  @autoserialize
  public requestNotes: string;

  @autoserialize
  public underwriterNotes: string;

  @autoserialize
  public recommendedAmount: number;

  @autoserialize
  public extraReservePercent: number;

  @autoserialize
  public weeklyFeePercent: number;

  @autoserialize
  public requestKey: string;

  @autoserialize
  public gracePeriodLength: number;

  @autoserialize
  public noRebate: boolean;

  @autoserialize
  public returnStrategy: string;

  @autoserialize
  public forceFull: boolean;

  @autoserialize
  public expectedMonthlyEarnings: boolean;

  @autoserialize
  public expectedDuration: boolean;

  constructor(params: ProgressivePricingOfferDTO) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        this[key] = params[key];
      }
    }
  }
}
