<div class="preloaderbar active" appPreloader><span class="bar"></span></div>
<div
  id="app-main"
  class="app-main full-height"
  [ngClass]="{
    'fixed-header': ThemeConfig.fixedHeader,
    'nav-collapsed': ThemeConfig.navCollapsed,
    'crm-fixed': (layoutState$ | async).crmPanelState === crmPanelStates.FIXED,
    'quickview-open':
      (layoutState$ | async).crmPanelState === crmPanelStates.OPEN || (layoutState$ | async).crmPanelState === crmPanelStates.FIXED,
    'sidebar-mobile-open': false,
    'supplier-info-open': (layoutState$ | async).supplierInfoOpenState === supplierInfoStates.OPEN,
    'supplier-info-close': (layoutState$ | async).supplierInfoOpenState === supplierInfoStates.CLOSED,
    'nav-behind': ThemeConfig.navBehind,
    'layout-boxed': ThemeConfig.layoutBoxed,
    'theme-gray': ThemeConfig.theme == 'gray',
    'theme-dark': ThemeConfig.theme == 'dark',
    'sidebar-sm': ThemeConfig.sidebarWidth == 'small',
    'sidebar-lg': ThemeConfig.sidebarWidth == 'large'
  }"
>
  <router-outlet></router-outlet>
</div>
