import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RepositoryInterface } from '../../shared/utils/repository-interface';
import { SupplierPaymentBatchListModel } from '../model/supplier-payment-batch-list-model';
import {
  SupplierPaymentBatchAction,
  SupplierPaymentBatchModel,
  SupplierPaymentNewBatchModel,
  SupplierPaymentWorkflowStatusModel,
} from '../model/supplier-payment-batch-model';

export abstract class PaymentBatchesAbstractService implements RepositoryInterface {
  abstract getItems(params: HttpParams): Observable<SupplierPaymentBatchListModel[]>;
  abstract saveItem(uuid: string, item: SupplierPaymentBatchModel): Observable<SupplierPaymentBatchModel>;

  abstract getBatch(batchId: string): Observable<SupplierPaymentBatchModel>;
  abstract addBatch(data: SupplierPaymentNewBatchModel): Observable<any>;
  abstract updateBatch(batchId: string, data: SupplierPaymentBatchModel): Observable<any>;
  abstract requestBatchAction(batchId: string, data: SupplierPaymentBatchAction): Observable<any>;
  abstract unlock(batchId: string): Observable<any>;

  abstract getPayments(batchId: string, httpParams: HttpParams, isDebit: boolean): Observable<any>;
  abstract updatePayment(batchId: string, paymentId: string, item: any): Observable<any>;
  abstract removePayment(batchId: string, paymentId: string): Observable<any>;

  abstract getStatus(batchId: string): Observable<SupplierPaymentWorkflowStatusModel>;
}
