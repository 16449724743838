import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { isUndefined } from 'util';

import { PendingInterceptorService } from './pending-interceptor.service';

@Directive({
  selector: '[appPreloader]',
})
export class PreloaderDirective implements AfterViewInit, OnDestroy {
  private hasPendingRequests: boolean;
  public pendingRequestsSubject = new Subject<boolean>();

  $element;

  constructor(private pendingRequestInterceptorService: PendingInterceptorService, private el: ElementRef, private router: Router) {
    this.pendingRequestInterceptorService.pendingRequestsStatus.subscribe((hasPendingRequests) => {
      this.pendingRequestsSubject.next(hasPendingRequests);
    });

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  ngAfterViewInit() {
    this.$element = $(this.el.nativeElement);
    this.pendingRequestsSubject.subscribe((visible) => {
      this.hasPendingRequests = visible;
      this.hasPendingRequests ? this.active() : this.hide();
    });
  }

  active() {
    if (this.$element) {
      this.$element.removeClass('hide').addClass('active');
    }
  }

  hide() {
    if (this.$element) {
      this.$element.addClass('hide').removeClass('active');
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (isUndefined(this.hasPendingRequests) || !this.hasPendingRequests) {
      if (event instanceof NavigationStart) {
        this.active();
      }
      if (event instanceof NavigationEnd) {
        this.hide();
      }

      // Set loading state to false in both of the below events to hide the spinner in case a request fails
      if (event instanceof NavigationCancel) {
        this.hide();
      }
      if (event instanceof NavigationError) {
        this.hide();
      }
    }
  }

  ngOnDestroy() {
    this.pendingRequestsSubject.unsubscribe();
  }
}
