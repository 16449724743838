import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { GoogleAuthLoginComponent } from './google-auth-login/google-auth-login.component';
import { GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig } from './google-auth-login/ng-gapi';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

const gapiClientConfig: NgGapiClientConfig = {
  client_id: '592848749680-3fs9ru3khk5u53i92hgf0v0giuhds1ee.apps.googleusercontent.com',
  discoveryDocs: ['https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'],
  scope: ['https://www.googleapis.com/auth/analytics.readonly', 'https://www.googleapis.com/auth/analytics'].join(' '),
};

@NgModule({
  declarations: [LoginComponent, LogoutComponent, GoogleAuthLoginComponent],
  imports: [
    SharedModule,
    AuthRoutingModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig,
    }),
  ],
  providers: [AuthGuard],
})
export class AuthModule {}
