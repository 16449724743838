import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isUndefined } from 'util';

import { selectAuthToken } from '../../auth/store/auth.actions';
import { AppConfigService } from '../../config';
import { AppState } from '../../store/app.reducers';
import { BaseCrmModel } from '../models/base-crm.model';
import { EmailModel } from '../models/email.model';
import { EventModel } from '../models/event.model';
import { FileModel } from '../models/file.model';
import { FileDataModel } from '../models/file-data.model';
import { CrmMetaModel } from '../models/meta.model';
import { NoteModel } from '../models/note.model';
import { NoteDataModel } from '../models/note-data.model';
import { TaskModel } from '../models/task.model';
import { CrmServiceInterface } from './crm.service.interface';

@Injectable()
export class CrmService implements CrmServiceInterface {
  private apiUrl: string;
  private eventloggerApiUrl: string;
  private messagesApiUrl: string;
  private headers: { [name: string]: string } = {};

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient, private store: Store<AppState>) {
    this.apiUrl = appConfig.env.actionsToolsUrl;
    this.eventloggerApiUrl = appConfig.env.eventLoggerUrl;
    this.messagesApiUrl = appConfig.env.messagingUrl;

    this.headers['X-InstanceId'] = '' + appConfig.env.instanceId;
    this.headers['X-EventLogger-InstanceId'] = '' + appConfig.env.instanceId;

    this.store.select(selectAuthToken).subscribe((jwtToken) => {
      this.headers['Authorization'] = jwtToken;
    });

    this.store.select('crm').subscribe((data) => {
      if (data.targetUuid) {
        this.headers['X-Actions-TargetUUID'] = data.targetUuid;
      }
    });
  }

  listActions(limit: number = 5, last?: any): Observable<{ data: BaseCrmModel[]; meta: CrmMetaModel }> {
    let params = new HttpParams().set('limit', '' + limit);
    if (!isUndefined(last)) {
      params = params.set('last', JSON.stringify(last));
    }

    return this.httpClient
      .get(this.apiUrl + 'activity/', {
        observe: 'body',
        responseType: 'json',
        params,
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);

          return {
            data: res['data'].map((item) => {
              switch (item.type) {
                case 'note':
                  return Deserialize(item, NoteModel);
                case 'task':
                  return Deserialize(item, TaskModel);
                case 'event':
                  return Deserialize(item, EventModel);
                case 'file':
                  return Deserialize(item, FileModel);
                case 'email':
                case 'email_supplier':
                  return Deserialize(item, EmailModel);
                case 'batch':
                  return Deserialize(item, EmailModel);
                default:
                  return Deserialize(item, BaseCrmModel);
              }
            }),
            meta: Deserialize(res['meta'], CrmMetaModel),
          };
        }),
      );
  }

  listActionsNotes(limit: number = 10, last?: any): Observable<{ data: NoteModel[]; meta: CrmMetaModel }> {
    return this.listActionsBase<NoteModel>(this.apiUrl + 'notes/', NoteModel, limit, last);
  }

  listActionsFiles(limit: number = 10, last?: any): Observable<{ data: FileModel[]; meta: CrmMetaModel }> {
    return this.listActionsBase<FileModel>(this.apiUrl + 'files/', FileModel, limit, last);
  }

  listActionsEvents(limit: number = 10, last?: any): Observable<{ data: EventModel[]; meta: CrmMetaModel }> {
    let params = new HttpParams().set('limit', '' + 2000000); // todo - ugly fix - check and fix it later
    const headers = { ...this.headers };
    headers['X-EventLogger-TargetUUID'] = headers['X-Actions-TargetUUID'];
    delete headers['X-Actions-TargetUUID'];

    if (!isUndefined(last)) {
      params = params.set('last', JSON.stringify(last));
    }

    return this.httpClient
      .get(this.eventloggerApiUrl + 'es-changelog', {
        observe: 'body',
        responseType: 'json',
        params,
        headers: new HttpHeaders(headers),
      })
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return {
            data: res['data'].map((item) => Deserialize(item, EventModel)),
            meta: Deserialize(res['meta'], CrmMetaModel),
          };
        }),
      );
  }

  listActionsEmails(limit: number = 10, last?: any): Observable<{ data: EmailModel[]; meta: CrmMetaModel }> {
    let params = new HttpParams().set('limit', '' + limit);
    const headers = { ...this.headers };
    headers['X-Actions-Type'] = 'email_supplier';
    delete headers['X-EventLogger-TargetUUID'];

    if (!isUndefined(last)) {
      params = params.set('last', JSON.stringify(last));
    }

    return this.httpClient
      .get(this.messagesApiUrl + 'email/', {
        observe: 'body',
        responseType: 'json',
        params,
        headers: new HttpHeaders(headers),
      })
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return {
            data: res['data'].map((item) => Deserialize(item, EmailModel)),
            meta: Deserialize(res['meta'], CrmMetaModel),
          };
        }),
      );
  }

  listActionsBatchEmails(limit: number = 10, last?: any): Observable<{ data: EmailModel[]; meta: CrmMetaModel }> {
    let params = new HttpParams().set('limit', '' + limit);
    const headers = { ...this.headers };
    headers['X-Actions-Type'] = 'email_supplier';
    delete headers['X-EventLogger-TargetUUID'];

    if (!isUndefined(last)) {
      params = params.set('last', JSON.stringify(last));
    }

    return this.httpClient
      .get(this.messagesApiUrl + 'email/batch/', {
        observe: 'body',
        responseType: 'json',
        params,
        headers: new HttpHeaders(headers),
      })
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return {
            data: res['data'].map((item) => Deserialize(item, EmailModel)),
            meta: Deserialize(res['meta'], CrmMetaModel),
          };
        }),
      );
  }

  listActionsTasks(limit: number = 10, last?: any): Observable<{ data: TaskModel[]; meta: CrmMetaModel }> {
    return this.listActionsBase<TaskModel>(this.apiUrl + 'tasks/', TaskModel, limit, last);
  }

  listActionsPinned(limit: number = 10, last?: any): Observable<{ data: NoteModel[]; meta: CrmMetaModel }> {
    return this.listActionsBase<NoteModel>(this.apiUrl + 'notes/pinned', NoteModel, limit, last);
  }

  addNote(data: NoteDataModel): Observable<NoteModel> {
    SerializeKeysTo(UnderscoreCase);

    return this.httpClient
      .post(this.apiUrl + 'notes/', Serialize(data, NoteDataModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], NoteModel);
        }),
      );
  }

  editNote(uuid: string, data: NoteDataModel): Observable<NoteModel> {
    SerializeKeysTo(UnderscoreCase);

    return this.httpClient
      .put(`${this.apiUrl}notes/${uuid}/`, Serialize(data, NoteDataModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], NoteModel);
        }),
      );
  }

  pinNote(uuid: string, data: Partial<NoteModel>): Observable<NoteModel> {
    return this.httpClient
      .patch(
        `${this.apiUrl}notes/${uuid}/`,
        { is_pinned: data.isPinned },
        {
          observe: 'body',
          responseType: 'json',
          headers: new HttpHeaders(this.headers),
        },
      )
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], NoteModel);
        }),
      );
  }

  deleteNote(uuid: string): Observable<Partial<NoteModel>> {
    return this.httpClient.delete(`${this.apiUrl}notes/${uuid}/`, {
      observe: 'body',
      responseType: 'json',
      headers: new HttpHeaders(this.headers),
    });
  }

  listActionsBase<T>(
    url: string,
    itemClass: any,
    limit,
    last,
    filters?: any,
    orderBy?: any,
  ): Observable<{ data: T[]; meta: CrmMetaModel }> {
    let params = new HttpParams().set('limit', '' + limit);
    if (!isUndefined(last)) {
      params = params.set('last', JSON.stringify(last));
    }
    if (!isUndefined(orderBy)) {
      params = params.set('order_by', JSON.stringify(orderBy));
    }
    if (!isUndefined(filters)) {
      params = params.set('q', JSON.stringify(filters));
    }

    return this.httpClient
      .get(url, {
        observe: 'body',
        responseType: 'json',
        params,
        headers: new HttpHeaders(this.headers),
      })
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return {
            data: res['data'].map((item) => Deserialize(item, itemClass)),
            meta: Deserialize(res['meta'], CrmMetaModel),
          };
        }),
      );
  }

  addFile(data: any): Observable<FileModel> {
    SerializeKeysTo(UnderscoreCase);

    const extendedHeaders = {
      ...this.headers,
      'X-Actions-TargetType': 'upload-file',
      'X-username': data.user.email,
    };

    return this.httpClient
      .post(this.apiUrl + 'files/', Serialize(data, FileDataModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(extendedHeaders),
      })
      .pipe(
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], FileModel);
        }),
      );
  }

  deleteFile(uuid: string, targetUuid: string, b2bUuid: string): Observable<Partial<FileModel>> {
    return this.httpClient.delete(`${this.apiUrl}files/${uuid}/${b2bUuid}/`, {
      observe: 'body',
      responseType: 'json',
      headers: new HttpHeaders(this.headers),
    });
  }
}
