<form [formGroup]="dailyForm" *ngIf="collectedData">
  <div>
    <div class="card col-12">
      <div class="card-content">
        <h4>Edit Health Rules</h4>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-4 align-self-center">
              <span class="m-0"> Order defect rate: </span>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <span matPrefix>Have to be &le; &nbsp;</span>
                <input
                  [min]="collectedData?.order_defect_rate_rule.warning_value"
                  max="100"
                  class="form-control"
                  type="number"
                  formControlName="order_defect_rate"
                  name="order_defect_rate"
                  id="order_defect_rate"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-4 align-self-center">
              <span class="text-warning">Should be</span> &le; {{ collectedData?.order_defect_rate_rule.warning_value }}
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-4 align-self-center">
              <span class="m-0"> Late shipment: </span>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <span matPrefix>Have to be &le; &nbsp;</span>
                <input
                  [min]="collectedData?.late_shipment_rate_rule.warning_value"
                  max="100"
                  class="form-control"
                  type="number"
                  formControlName="late_shipment_rate"
                  id="late_shipment_rate"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-4 align-self-center">
              <span class="text-warning">Should be</span> &le; {{ collectedData?.late_shipment_rate_rule.warning_value }}
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-4 align-self-center">
              <span class="m-0"> Late responses: </span>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <span matPrefix>Have to be &le; &nbsp;</span>
                <input
                  [min]="collectedData?.late_responses_rule.warning_value"
                  max="100"
                  class="form-control"
                  type="number"
                  formControlName="late_responses"
                  id="late_responses"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-4 align-self-center">
              <span class="text-warning">Should be</span> &le; {{ collectedData?.late_responses_rule.warning_value }}
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-4 align-self-center">
              <span class="m-0"> Cancellation rate: </span>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <span matPrefix>Have to be &le; &nbsp;</span>
                <input
                  [min]="collectedData?.pre_fullfillment_cancel_rate_rule.warning_value"
                  max="100"
                  class="form-control"
                  type="number"
                  formControlName="pre_fullfillment_cancel_rate"
                  id="pre_fullfillment_cancel_rate"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-4 align-self-center">
              <span class="text-warning">Should be</span> &le; {{ collectedData?.pre_fullfillment_cancel_rate_rule.warning_value }}
            </div>
          </div>
        </div>

        <div class="form-group col-12">
          <div class="row">
            <div class="col-4 align-self-center">
              <span class="m-0"> Delivered on time: </span>
            </div>
            <div class="col-4 align-self-center">
              <span class="text-warning">Should be</span> &ge; {{ collectedData?.delivered_on_time_rate_rule.warning_value }}
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <span matPrefix>Have to be &ge; &nbsp;</span>
                <input
                  min="0"
                  [max]="collectedData?.delivered_on_time_rate_rule.warning_value"
                  class="form-control"
                  type="number"
                  formControlName="delivered_on_time_rate"
                  id="delivered_on_time_rate"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-4 align-self-center">
              <span class="m-0"> Valid tracking rate: </span>
            </div>
            <div class="col-4 align-self-center">
              <span class="text-warning">Should be</span> &ge; {{ collectedData?.valid_tracking_rate_rule.warning_value }}
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <span matPrefix>Have to be &ge; &nbsp;</span>
                <input
                  min="0"
                  [max]="collectedData?.valid_tracking_rate_rule.warning_value"
                  class="form-control"
                  type="number"
                  formControlName="valid_tracking_rate"
                  id="valid_tracking_rate"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-4 align-self-center">
              <span class="m-0"> Return dissatisfaction rate: </span>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <span matPrefix>Have to be &le; &nbsp;</span>
                <input
                  [min]="collectedData?.return_dissatisfaction_rate_rule.warning_value"
                  max="100"
                  class="form-control"
                  type="number"
                  formControlName="return_dissatisfaction_rate"
                  id="return_dissatisfaction_rate"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-4 align-self-center">
              <span class="text-warning">Should be</span> &le; {{ collectedData?.return_dissatisfaction_rate_rule.warning_value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
