import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { BackgroundReportGenerationResultDto } from '../../model/background-report/background-report-generation-result.dto';
import { BackgroundReportSectionDto } from '../../model/background-report/background-report-section.dto';
import { BackgroundReportSectionModel } from '../../model/background-report/background-report-section.model';
import { BackgroundReportSectionDataDto } from '../../model/background-report/background-report-section-data.dto';
import { BackgroundReportSectionDataModel } from '../../model/background-report/background-report-section-data.model';
import { BackgroundReportTaskCorrelationResponseDto } from '../../model/background-report/background-report-task-correlation-response.dto';

@Injectable()
export class BackgroundReportsService {
  private readonly reportFetchingApi = environment.s3FilesProviderApi;
  private readonly reportGenerationApi = environment.automaticMarketplaceApiUrl;

  private readonly resourceType = 'thomson_reuters/background_reports/person';
  private readonly processName = 'download_personal_background_report';
  private readonly paramNames = {
    supplierKey: 'supplier_key',
    personId: 'person_id',
    firstName: 'first_name',
    lastName: 'last_name',
    ssn: 'ssn',
    sections: 'sections',
    resourceType: 'resource_type',
    processName: 'process_name',
    taskName: 'task_name',
    taskInput: 'task_input',
  };

  private readonly missingParamsForReportGenerationErrorMsg =
    'Cannot make a request to generate new report. Some of the required parameters are missing.';

  constructor(private httpClient: HttpClient) {}

  fetchSection(section: BackgroundReportSectionModel, supplierKey: string, personId: string): Observable<BackgroundReportSectionDataModel> {
    const url = `${this.reportFetchingApi}resources`;
    const params = this.setReportFetchingParams(supplierKey, personId, [section.key]);

    return this.httpClient
      .get<BackgroundReportSectionDataDto>(url, { params })
      .pipe(map((res) => this.mapSectionDataResponse(res, section)));
  }

  private mapSectionDataResponse(
    response: BackgroundReportSectionDataDto,
    section: BackgroundReportSectionModel,
  ): BackgroundReportSectionDataModel {
    return {
      key: section.key,
      sectionTitle: section.sectionTitle,
      data: response[section.key],
    };
  }

  fetchAvailableSectionsList(supplierKey: string, personId: string): Observable<BackgroundReportSectionModel[]> {
    const url = `${this.reportFetchingApi}sections`;
    const params = this.setReportFetchingParams(supplierKey, personId);

    return this.httpClient
      .get<BackgroundReportSectionDto[]>(url, { params })
      .pipe(map((sections) => sections.map((s) => ({ key: s.value, sectionTitle: s.name }))));
  }

  private setReportFetchingParams(supplierKey: string, personId: string, sectionKeys: string[] = []): HttpParams {
    let params: HttpParams = new HttpParams()
      .set(this.paramNames.supplierKey, supplierKey)
      .set(this.paramNames.personId, personId)
      .set(this.paramNames.resourceType, this.resourceType);

    if (sectionKeys.length > 0) {
      params = params.set(this.paramNames.sections, sectionKeys.join(','));
    }

    return params;
  }

  fetchLastReportTimestamp(supplierKey: string, personId: string): Observable<string> {
    const url = `${this.reportGenerationApi}tasks/result`;

    const params: HttpParams = new HttpParams()
      .set(this.paramNames.supplierKey, supplierKey)
      .set(this.paramNames.personId, personId)
      .set(this.paramNames.processName, this.processName);

    return this.httpClient.get<BackgroundReportGenerationResultDto>(url, { params }).pipe(map((res) => res.timestamp));
  }

  generateNewReport(
    supplierKey: string,
    personId: string,
    firstName: string,
    lastName: string,
    ssn: string,
  ): Observable<BackgroundReportTaskCorrelationResponseDto> {
    if (![supplierKey, personId, firstName, lastName, ssn].every((v) => !!v)) {
      return throwError(new TypeError(this.missingParamsForReportGenerationErrorMsg));
    }

    const url = `${this.reportGenerationApi}tasks/execute`;
    const requestBody = {
      [this.paramNames.taskName]: this.processName,
      [this.paramNames.taskInput]: {
        [this.paramNames.supplierKey]: supplierKey,
        [this.paramNames.personId]: personId,
        [this.paramNames.firstName]: firstName,
        [this.paramNames.lastName]: lastName,
        [this.paramNames.ssn]: this.formatSSN(ssn),
      },
    };
    return this.httpClient.post<BackgroundReportTaskCorrelationResponseDto>(url, requestBody);
  }

  private formatSSN(ssn: string): string {
    return ssn.replace(/[^\d]/g, '').replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  }
}
