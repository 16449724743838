import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNullOrUndefined } from 'util';

import { AppState } from '../../../../store/app.reducers';
import { CrmItem } from '../../../models/crm-item.type';
import { listAnimation } from '../../../shared/cms.animations';
import { FetchCrmAll, selectCrmAll } from '../../../store/crm.actions';
import { ActionTabComponent } from '../action-tab-base.component';

@Component({
  selector: 'app-action-tab-all',
  templateUrl: './action-tab-all.component.html',
  animations: [trigger('listAnimation', [transition('* => *', [useAnimation(listAnimation)])])],
})
export class ActionTabAllComponent extends ActionTabComponent {
  @Input() crmItems: { [uuid: string]: CrmItem };
  selector = selectCrmAll;
  fetchAction = FetchCrmAll;

  constructor(store: Store<AppState>) {
    super(store);
  }

  calculateLast() {
    if (!isNullOrUndefined(this.uuids)) {
      const last = {
        note: null,
        file: null,
        email: null,
        task: null,
        event: null,
        batch: null,
      };

      this.uuids.map((item) => {
        const crmItem = this.crmItems[item];
        switch (crmItem.type) {
          case 'note':
            last['note'] = {
              target_uuid: crmItem.targetUuid,
              uuid: crmItem.uuid,
              updated_at: crmItem.updatedAt,
            };
            break;
          case 'task':
            last['task'] = {
              target_uuid: crmItem.targetUuid,
              uuid: crmItem.uuid,
              updated_at: crmItem.updatedAt,
              instance_id: crmItem.instanceId,
            };
            break;
          case 'email':
            last['email'] = {
              target_uuid: crmItem.targetUuid,
              uuid: crmItem.uuid,
              created_at: crmItem.createdAt,
              instance_id: crmItem.instanceId,
            };
            break;
          case 'event':
            last['event'] = {
              target_uuid: crmItem.targetUuid,
              uuid: crmItem.uuid,
              created_at: crmItem.createdAt,
              instance_id: crmItem.instanceId,
            };
            break;
          case 'file':
            last['file'] = {
              target_uuid: crmItem.targetUuid,
              uuid: crmItem.uuid,
              created_at: crmItem.createdAt,
            };
            break;
          case 'batch':
            last['batch'] = {
              target_uuid: crmItem.targetUuid,
              uuid: crmItem.uuid,
              created_at: crmItem.createdAt,
              instance_id: crmItem.instanceId,
            };
            break;
        }
      });
      this.last = last;
    }
  }
}
