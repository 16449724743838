<article class="tl-item" *ngIf="item.data.task && changes">
  <div class="tl-body">
    <div class="tl-entry">
      <div class="tl-icon tl-small-icon btn-tasks"></div>
      <div class="tl-content">
        <!-- EVENT BODY -->
        <div class="tl-content-body task-event">
          {{ item.createdAt | amAdd: correctTime(item.createdAt):'minutes' | date: 'short' }}
          <strong>{{ item.data.user.email }}</strong>
          changed task #{{ item.data.task.itemNumber }}
          <strong>{{ item.data.task.title }}</strong>

          <span *ngFor="let key of changes | keys; let last = last">
            {{ !changes[key].label ? key : changes[key].label }}
            <span *ngIf="changes[key].old && changes[key].current" class="pill"
              >: from <strong>{{ changes[key].old }}</strong> to <strong>{{ changes[key].current }}</strong>
            </span>
            <span *ngIf="!last">, </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</article>

<div *ngIf="!item.data.task && changes" class="task-event task-context">
  {{ offsetToLocalTime(item.createdAt) }} - User <strong>{{ item.data.user.email }}</strong>
  changed
  <span *ngFor="let key of changes | keys; let last = last">
    {{ !changes[key].label ? key : changes[key].label }}
    <span *ngIf="changes[key].old && changes[key].current" class="pill">
      from <strong>{{ changes[key].old }}</strong> to <strong>{{ changes[key].current }}</strong>
    </span>
    <span *ngIf="!last">, </span>
  </span>
</div>
