import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { SupplierOwnerModel } from '../../../../model/supplier-owner.model';
import { BackgroundReportsService } from '../../../../services/background-reports/background-reports.service';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-background-report-card-header',
  templateUrl: './background-report-card-header.component.html',
})
export class BackgroundReportCardHeaderComponent extends SubscriberComponent {
  @Input() reportTitle = '';

  @Input() set supplierKey(key: string) {
    this._supplierKey = key;

    if (this._supplierKey && this._owner) {
      this.fetchLastReportTimestamp();
    }
  }

  @Input() set owner(o: SupplierOwnerModel) {
    this._owner = o;

    if (this._supplierKey && this._owner) {
      this.fetchLastReportTimestamp();
    }
  }

  lastReportTimestamp$: Observable<string>;
  loading = true;
  readonly buttonLabel = 'Run Background Check';
  readonly reportSubtitle = 'Primary Owner Background Report';
  readonly userPermission = USER_PERMISSION;

  private _supplierKey: string;
  private _owner: SupplierOwnerModel;
  private readonly reportGenerationInProgressMessage =
    'The background check is running. Please refresh the page in a few minutes to see the new report.';
  private readonly supplierDataNotInitializedMessage = 'Unable to run the background check. Please try again later.';
  private readonly supplierDataMissingMessage = 'Unable to run the background check for this supplier. Insufficient data.';

  constructor(private backgroundReportsService: BackgroundReportsService, private toastrService: ToastrService) {
    super();
  }

  onRunBackgroundCheckClick(): void {
    if (!this._supplierKey || !this._owner) {
      this.toastrService.error(this.supplierDataNotInitializedMessage);
      return;
    }

    this.loading = true;
    const subscription = this.backgroundReportsService
      .generateNewReport(this._supplierKey, this._owner.loginKey, this._owner.firstName, this._owner.lastName, this._owner.SSN)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => this.toastrService.success(this.reportGenerationInProgressMessage),
        error: (err) => this.toastrService.error(err instanceof TypeError ? this.supplierDataMissingMessage : err),
      });

    this.subscriptions.push(subscription);
  }

  private fetchLastReportTimestamp(): void {
    this.loading = true;

    this.lastReportTimestamp$ = this.backgroundReportsService.fetchLastReportTimestamp(this._supplierKey, this._owner.loginKey).pipe(
      map((timestamp) => formatDate(timestamp, 'medium', 'en-US')),
      catchError((error) => (error.status === 404 ? of('never') : throwError(error))),
      finalize(() => {
        this.loading = false;
      }),
    );
  }
}
