<mat-sidenav-container class="email-sidenav-fab-container">
  <section class="container-fluid">
    <article class="article">
      <div class="box box-default table-box table-striped table-responsive mdl-shadow--2dp">
        <mat-card-actions>
          <h2 class="article-title">Emails</h2>
          <button mat-button class="mat-raised-button mat-accent new-batch-button" style="float: right" (click)="newBatchEmailDialog()">
            Add new Batch
          </button>
        </mat-card-actions>

        <!-- FILTERS COMPONENT -->
        <div class="grid-filters-wrapper">
          <app-email-grid-filters (filtersChange)="filtersChange($event)"></app-email-grid-filters>
        </div>

        <div class="email-table-wrapper">
          <app-spinner [hidden]="!isLoadingResults" [forceShow]="true"></app-spinner>

          <mat-table #table matSort matSortActive="created_at" matSortDisableClear matSortDirection="desc" [dataSource]="dataSource">
            <!-- TYPE -->
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Type</mat-header-cell>
              <mat-cell *matCellDef="let element" [class.show-batch-group]="isBatchGroup(element.type)">
                {{ getEmailTypeName(element.type) }}
                <span *ngIf="isBatchGroup(element.type)">({{ element.count }})</span>
              </mat-cell>
            </ng-container>

            <!-- CREATED_AT -->
            <ng-container matColumnDef="created_at">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Created at</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.created_at | fromUnix | date: 'M/d/y h:mm a' }}
              </mat-cell>
            </ng-container>

            <!-- SUBJECT -->
            <ng-container matColumnDef="subject">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Subject</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="!showPreviewEmailLink(element)">
                  {{ element.subject }}
                </span>
                <span class="show-preview-dialog" *ngIf="showPreviewEmailLink(element)" (click)="previewEmailDialog(element)">
                  {{ element.subject }}
                </span>
              </mat-cell>
            </ng-container>

            <!-- FROM -->
            <ng-container matColumnDef="from">
              <mat-header-cell *matHeaderCellDef mat-sort-header>From</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a href="mailto: {{ element.source }}">{{ element.source }}</a>
              </mat-cell>
            </ng-container>

            <!-- STATUS -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <app-email-status-badge [status]="element.status"></app-email-status-badge>
              </mat-cell>
            </ng-container>

            <!-- RECEIVER -->
            <ng-container matColumnDef="receiver">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Receiver</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.to }}
              </mat-cell>
            </ng-container>

            <!-- AUTHOR -->
            <ng-container matColumnDef="author">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Author</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.created_by_name }}
              </mat-cell>
            </ng-container>

            <!-- SUPPLIER -->
            <ng-container matColumnDef="supplier">
              <mat-header-cell *matHeaderCellDef>Supplier</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.supplier_name }}
              </mat-cell>
            </ng-container>

            <!-- ACTIONS -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  color="primary"
                  (click)="approveBatchDialog(element)"
                  *ngIf="showApproveAndCancelAllButton(element)"
                  matTooltip="Approve whole batch"
                >
                  <mat-icon class="mat-24" aria-label="Approve whole batch">done_all</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="cancelBatchDialog(element)"
                  *ngIf="showApproveAndCancelAllButton(element)"
                  matTooltip="Cancel whole batch"
                >
                  <mat-icon class="mat-24" aria-label="Cancel whole batch">clear_all</mat-icon>
                </button>
                <button mat-icon-button color="primary" *ngIf="showSendAndCancelSingleButton(element)" matTooltip="Done">
                  <mat-icon class="mat-24" aria-label="Done">done</mat-icon>
                </button>
                <button mat-icon-button *ngIf="showSendAndCancelSingleButton(element)" matTooltip="Cancel">
                  <mat-icon class="mat-24" aria-label="Cancel">clear</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: columns; let index = index"
              (click)="expandRow(index, row)"
              [class.expanded]="isActive(index)"
              [class.batch-group]="isBatchGroup(row.type)"
              #myExpandedRow
            ></mat-row>
          </mat-table>

          <mat-paginator [length]="resultsLength" [pageSize]="itemsPerPage" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
        </div>
      </div>
    </article>
  </section>
</mat-sidenav-container>
