<form [formGroup]="form" novalidate>
  <div formGroupName="ppm" class="row">
    <p class="col-md-12"><strong>Account Information</strong></p>

    <div class="col-md-12 divider" *ngIf="editMode">
      Customer ID: <strong>{{ paymentConfig.paymentConfig.ppm.customerId }}</strong>
    </div>

    <mat-form-field class="col-md-12">
      <mat-select matInput placeholder="Account Type" formControlName="payeeType">
        <mat-option *ngFor="let type of payeeTypes" [value]="type.code">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-6">
      <input matInput placeholder="First Name" formControlName="cardholderFirstName" type="text" />
    </mat-form-field>

    <mat-form-field class="col-md-6">
      <input matInput placeholder="Last Name" formControlName="cardholderLastName" type="text" />
    </mat-form-field>

    <div formGroupName="cardholderMailAddr" class="col-md-12">
      <div class="row">
        <mat-form-field class="col-md-12">
          <input matInput placeholder="Address" formControlName="addr1" type="text" />
        </mat-form-field>

        <mat-form-field class="col-md-6">
          <input matInput placeholder="City" formControlName="city" type="text" />
        </mat-form-field>

        <mat-form-field class="col-md-6">
          <input matInput placeholder="Zip" formControlName="postalCode" type="text" />
        </mat-form-field>

        <div ngxErrors="ppm.cardholderMailAddr.postalCode" class="col-md-12">
          <mat-error ngxError="pattern" when="touched"> Zip code must have at least 5 digits. </mat-error>
        </div>

        <mat-form-field class="col-md-12">
          <mat-select matInput placeholder="Country" formControlName="countryCode">
            <mat-option *ngFor="let country of countries | async" [value]="country.countryCode">
              {{ country.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-md-12" *ngIf="states && states.length">
          <mat-select matInput placeholder="State" formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.id">
              {{ state.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field class="col-md-12">
      <input matInput placeholder="Phone Number" formControlName="cardholderPhoneWork" type="text" />
    </mat-form-field>

    <div ngxErrors="ppm.cardholderPhoneWork" class="col-md-12">
      <mat-error ngxError="pattern" when="touched"> This must be a valid phone number </mat-error>
    </div>

    <mat-form-field class="col-md-12">
      <input matInput placeholder="Email" formControlName="cardholderEmail" type="text" />
    </mat-form-field>

    <div ngxErrors="ppm.cardholderEmail" class="col-md-12">
      <mat-error ngxError="pattern" when="touched"> This must be a valid email </mat-error>
    </div>
    <div class="col-md-12 divider"></div>

    <p *ngIf="!editMode" class="col-md-12">
      <strong>Account Information</strong>
    </p>

    <mat-form-field *ngIf="!editMode" class="col-md-12">
      <input matInput placeholder="Debit Card Number" formControlName="cardNumber" type="text" />
    </mat-form-field>

    <div ngxErrors="ppm.cardNumber" class="col-md-12">
      <mat-error ngxError="error" when="touched"> This must be a valid card number </mat-error>
    </div>

    <mat-form-field *ngIf="!editMode" class="col-md-12">
      <input matInput placeholder="Expiration Date" formControlName="cardExpDate" type="text" />
    </mat-form-field>

    <div *ngIf="!editMode" ngxErrors="ppm.cardExpDate" class="col-md-12">
      <mat-error ngxError="error" when="touched"> This must be a valid Expiration Date (MM/YY) </mat-error>
    </div>

    <mat-form-field *ngIf="form.get('ppm').get('payeeType').value === 'individual' && !editMode" class="col-md-12">
      <input matInput placeholder="Date of birth" formControlName="cardholderDateOfBirth" type="text" />
    </mat-form-field>

    <div
      *ngIf="form.get('ppm').get('payeeType').value === 'individual' && !editMode"
      ngxErrors="ppm.cardholderDateOfBirth"
      class="col-md-12"
    >
      <mat-error ngxError="pattern" when="touched"> This must be a valid Date of Birth (YYYY-MM-DD) </mat-error>
    </div>

    <mat-form-field *ngIf="form.get('ppm').get('payeeType').value === 'individual' && !editMode" class="col-md-12">
      <input matInput placeholder="Cardholder SSN" formControlName="cardholderSsn" type="text" />
    </mat-form-field>

    <div *ngIf="form.get('ppm').get('payeeType').value === 'individual' && !editMode" ngxErrors="ppm.cardholderSsn" class="col-md-12">
      <mat-error ngxError="pattern" when="touched"> This must be a valid SSN (XXX-XX-XXXX) </mat-error>
    </div>

    <mat-form-field *ngIf="form.get('ppm').get('payeeType').value === 'business' && !editMode" class="col-md-12">
      <input matInput placeholder="Date of incorporation" formControlName="bizDateOfIncorporation" type="text" />
    </mat-form-field>

    <div
      *ngIf="form.get('ppm').get('payeeType').value === 'individual' && !editMode"
      ngxErrors="ppm.bizDateOfIncorporation"
      class="col-md-12"
    >
      <mat-error ngxError="pattern" when="touched"> This must be a valid Date of incorporation (YYYY-MM-DD) </mat-error>
    </div>

    <mat-form-field *ngIf="form.get('ppm').get('payeeType').value === 'business' && !editMode" class="col-md-12">
      <input matInput placeholder="Business EIN number" formControlName="bizFedEmployerIdNum" type="text" />
    </mat-form-field>

    <div *ngIf="form.get('ppm').get('payeeType').value === 'individual' && !editMode" ngxErrors="ppm.bizFedEmployerIdNum" class="col-md-12">
      <mat-error ngxError="pattern" when="touched"> This must be a valid Business EIN number (XX-XXXXXXX) </mat-error>
    </div>
  </div>
</form>
