import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { isUndefined } from 'util';

import { selectSupplierBalance, selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { TableEventsService } from './../../../shared/table-events.service';
import { AppState } from './../../../store/app.reducers';
import { CliService } from './../../services/cli/cli.service';
import { SupplierService } from './../../services/supplier.service';
import { BaseSupplierAction } from './../base-supplier-action/base-supplier-action';

@Component({
  selector: 'app-add-witeoff',
  templateUrl: './add-writeoff.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddWriteoffComponent extends BaseSupplierAction implements OnInit {
  actionTitle = 'Add writeoff';

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.supplierKey = data.supplierKey;

          this.marketplaces = data.marketplaces.map((element) => {
            return {
              label: element.externalSupplierId
                ? element.marketplaceName + ' (' + element.externalSupplierId + ')'
                : element.marketplaceName,
              id: element.marketplaceName,
            };
          });
        }
      }),
    );
    this.setFormAmount();
    super.ngOnInit();
  }

  setFormAmount() {
    this.subscriptions.push(
      this.store.select(selectSupplierBalance).subscribe((data) => {
        if (!isUndefined(data)) {
          if (data.potentialLiability > 0) {
            this.amount = data.potentialLiability;
          } else {
            this.amount = 0;
          }
        }
      }),
    );
  }

  createForm() {
    this.supplierActionForm = this.formBuilder.group({
      marketplace: [''],
      post_date: [moment().subtract(1, 'days').toDate()],
      amount: [this.amount],
      reason: ['', Validators.required],
    });
  }

  submit() {
    this.isConfirmed = true;
    const dataToSave = {
      ...this.supplierActionForm.value,
    };
    this.isLoadingResult = true;
    dataToSave['post_date'] = moment(dataToSave.post_date).format('YYYY-MM-DD');
    this.subscriptions.push(
      this.cliService.addWriteoff(dataToSave, this.supplierKey).subscribe(
        () => {
          this.onSuccess('Writeoff transaction created.');
        },
        (response) => {
          this.onError(response.error.message);
        },
      ),
    );
  }
}
