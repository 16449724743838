import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridOptions } from 'ag-grid-community';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { interval, Observable, of, Subscription } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';
import { finalize, map } from 'rxjs/operators';

import { ColumnPredef } from '../../../../../shared/data-table-grid/models/default-column-definition';
import { USER_PERMISSION } from '../../../../../shared/users/user-permissions.enum';
import { AppState } from '../../../../../store/app.reducers';
import { ContentSource } from '../../consts/content-source';
import { CONTENT_SOURCES } from '../../consts/content-sources';
import { PersonalChecksColumnNames } from '../../consts/personal-checks-column-names';
import { GetCellClass } from '../../utils/get-cell-class';
import { GetRowDataForPersonalChecks } from '../../utils/get-row-data-for-personal-checks';
import { isTrError } from '../../utils/is-tr-error';
import { PrepareColumn } from '../../utils/prepare-column';
import { TakeCriteriaFromSearchRecord } from '../../utils/take-criteria-from-search-record';
import { BaseUnderwritingComponent } from '../base-underwriting-component/base-underwriting.component';
import { RealTimeInputFactoryService } from '../real-time-input-factory.service';
import {
  ICorelation,
  INamedColumns,
  INormalizedCriteria,
  IOnReady,
  IRealTimeValidationRow,
  IRealTimeValidatorDTO,
  ISummary,
  ITRDataError,
  ITRTaskDTO,
  IUWOwner,
  TSearchRecord,
} from '../real-time-validator.interfaces';
import { RealTimeValidatorService } from '../real-time-validator.service';

@Component({
  selector: 'app-uw-personal-checks-card',
  templateUrl: './uw-personal-checks-card.component.html',
  styleUrls: ['./uw-personal-checks-card.component.scss'],
})
export class UwPersonalChecksCardComponent extends BaseUnderwritingComponent implements IOnReady {
  readonly TR_REFRESH_RATE = 4000;
  readonly MAX_ATTEMPTS_NUMBER = 10;
  readonly DEFAULT_FIELDS_ORDER = ['FirstName', 'LastName', 'SSN', 'BirthDate', 'StreetNumber', 'StreetName', 'City', 'State', 'ZipCode'];
  gridOptions: GridOptions;
  colDefs: ColumnPredef[] = [];
  rowData: IRealTimeValidationRow[] = [];
  normalizedCriteria: INormalizedCriteria;
  normalizedColumns: INormalizedCriteria[];
  namedColumns: INamedColumns = {
    [ContentSource.EXPERIAN_CREDIT_HEADER]: undefined,
    [ContentSource.EQUIFAX_CREDIT_HEADER]: undefined,
    [ContentSource.TRANS_UNION]: undefined,
    [ContentSource.UTILITY_LISTING]: undefined,
  };
  summary: ISummary;
  mainOwner: IUWOwner;
  noError = true;
  lastChecked: string;
  readyResponse = false;
  clearIdApiSubscription: Subscription;
  runClearIdApiLoading: boolean;
  noDataReason: string;
  attemptsNumber = 0;
  validationStatusLoading: boolean;
  showAttemptsError = false;
  runClearIdButtonDisabled$: Observable<boolean> = of(true);

  readonly runClearIdErrorText = 'Error occurred during Clear Id Action';

  private readonly permissionsRequiredForRunningClearId = [USER_PERMISSION.FINANCE, USER_PERMISSION.DEV];

  constructor(
    protected store: Store<AppState>,
    private toastrService: ToastrService,
    private realTimeValidatorService: RealTimeValidatorService,
    private realTimeInputFactoryService: RealTimeInputFactoryService,
    private cd: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
  ) {
    super(store);
    this.setRunClearIdDisabled();
  }

  onReady() {
    this.mainOwner = this.getMainOwner();
    this.collectDataFromTr();
  }

  getMainOwner() {
    const mainOwner = this.supplier.owners.find((owner) => owner.mainOwner);
    return mainOwner ? mainOwner : this.supplier.owners[0];
  }

  collectDataFromTr() {
    this.resetNoErrorStatus();
    const loginKey = this.mainOwner.loginKey;
    if (this.supplierKey && loginKey) {
      this.subscriptions.push(
        this.realTimeValidatorService
          .getRealTimeValidation(this.supplierKey, loginKey)
          .pipe(finalize(() => this.stopButtonLoading()))
          .subscribe(
            (response) => {
              if (response.status === 'failed') {
                this.setNoDataReason('Validation request returned failed status!');
                this.onError();
              } else {
                this.onReceiveTRValidationResponse(response);
              }
            },
            (err) => {
              this.stopButtonLoading();
              this.setNoDataReason(err.error.message);
              this.onError();
            },
          ),
      );
    }
  }

  setNoDataReason(message: string) {
    this.noDataReason = message;
  }

  onReceiveTRValidationResponse(realTimeValidationDTO: IRealTimeValidatorDTO) {
    this.resetNoErrorStatus();
    this.lastChecked = realTimeValidationDTO.timestamp;
    const personSearchResult = realTimeValidationDTO.result['ns2:EIDVPersonSearchResponse'].EIDVPersonSearchResults.EIDVPersonSearchResult;
    this.summary = personSearchResult.PersonEntities.PersonEntity.Summary;
    this.normalizedCriteria = personSearchResult.NormalizedCriteria;
    const searchRecords = personSearchResult.PersonEntities.PersonEntity.SearchRecords.SearchRecord;
    this.normalizedColumns = this.getNormalizedColumnsFromRecords(searchRecords);
    this.prepareTable();
  }

  getNormalizedColumnsFromRecords(searchRecords: TSearchRecord): INormalizedCriteria[] {
    if (!searchRecords) {
      return [];
    }

    if (Array.isArray(searchRecords)) {
      return searchRecords
        .filter((record) => CONTENT_SOURCES.includes(record.ContentSource as ContentSource))
        .map((record) => {
          this.namedColumns[record.ContentSource] = TakeCriteriaFromSearchRecord(record);
          return TakeCriteriaFromSearchRecord(record);
        });
    }

    if (!searchRecords.ContentSource) {
      return [];
    }

    if (!(CONTENT_SOURCES as string[]).includes(searchRecords.ContentSource)) {
      return [];
    }
    this.namedColumns[searchRecords.ContentSource] = TakeCriteriaFromSearchRecord(searchRecords);
  }

  onError() {
    this.noError = false;
  }

  prepareTable() {
    this.preparePersonalChecksColumns();
    this.preparePersonalChecksRows();
    this.cd.detectChanges();
  }

  preparePersonalChecksColumns() {
    const columnsNames = PersonalChecksColumnNames;
    this.colDefs = [];
    columnsNames.forEach((name) => this.colDefs.push(PrepareColumn(name.split(' ').join(''), name)));
    this.colDefs[this.colDefs.length - 1].cellClass = (params: { [key: string]: string }) => {
      return GetCellClass(params.value);
    };
  }

  preparePersonalChecksRows() {
    const CRITERIA: string[] = Object.keys(this.normalizedCriteria);
    const SORTED_CRITERIA = [];
    this.DEFAULT_FIELDS_ORDER.forEach((field) => {
      const sortedCriteria = CRITERIA.find((criteria) => field === criteria);
      if (sortedCriteria) {
        SORTED_CRITERIA.push(sortedCriteria);
      }
    });

    CRITERIA.forEach((criteria) => {
      const criteriaExists = !!SORTED_CRITERIA.find((sortedCriteria) => sortedCriteria === criteria);
      if (!criteriaExists) {
        SORTED_CRITERIA.push(criteria);
      }
    });

    this.rowData = [];
    for (let i = 0; i < SORTED_CRITERIA.length; i++) {
      this.rowData.push(
        GetRowDataForPersonalChecks({
          criteria: SORTED_CRITERIA,
          namedColumns: this.namedColumns,
          normalizedCriteria: this.normalizedCriteria,
          summary: this.summary,
          i,
        }),
      );
    }
    this.setGridOptions();
  }

  setGridOptions() {
    this.gridOptions = undefined;
    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.colDefs,
      context: {
        componentParent: this,
      },
    };
  }

  onRunClearIdApiClick() {
    this.resetData();
    const trTaskDTO = this.realTimeInputFactoryService.fromSupplier(this.supplier);

    this.checkRunClearIdApiError(trTaskDTO);
    this.runClearIdApi(trTaskDTO);
  }

  resetData() {
    this.resetNoErrorStatus();
    this.resetAttemptsNumber();
  }

  resetNoErrorStatus() {
    this.noError = true;
  }

  resetAttemptsNumber() {
    this.attemptsNumber = 0;
    this.showAttemptsError = false;
  }

  checkRunClearIdApiError(trTaskDTO: ITRTaskDTO | ITRDataError) {
    if (isTrError(trTaskDTO)) {
      this.toastrService.error(trTaskDTO.message);
      return;
    }
  }

  runClearIdApi(trTaskDTO: ITRTaskDTO | ITRDataError) {
    this.runClearIdApiLoading = true;
    this.realTimeValidatorService.requestValidation(this.supplierKey, this.mainOwner.loginKey, trTaskDTO).subscribe(
      (corelationData: ICorelation) => {
        this.onRunClearIdApiSuccessful(corelationData);
      },
      (err) => {
        this.runClearIdError();
        this.showErrorToast(err);
      },
    );
  }

  runClearIdError() {
    this.stopButtonLoading();
    this.onError();
  }

  showErrorToast(err: any): void {
    const errorFromTheApi =
      err && err.error && err.error.error_body && err.error.error_body.incorrect_fields && err.error.error_body.incorrect_fields.state;
    const errText = errorFromTheApi ? errorFromTheApi : this.runClearIdErrorText;
    this.toastrService.error(errText);
  }

  stopButtonLoading() {
    this.runClearIdApiLoading = false;
  }

  onRunClearIdApiSuccessful(corelationData: ICorelation) {
    this.readyResponse = false;
    this.gridOptions = undefined;
    this.clearIdApiSubscription = interval(this.TR_REFRESH_RATE).subscribe(
      () => {
        if (this.validationStatusLoading) {
          return;
        }
        if (!this.readyResponse) {
          this.getValidationStatus(corelationData);
        } else {
          this.unsubscribeFromClearIdApiSubscription();
        }
      },
      () => this.runClearIdError(),
    );
    this.subscriptions.push(this.clearIdApiSubscription);
  }

  checkAttemptsNumber() {
    this.increaseAttemptsNumber();
    if (this.attemptsNumber >= this.MAX_ATTEMPTS_NUMBER) {
      this.unsubscribeFromClearIdApiSubscription();
      this.showAttemptsError = true;
    }
  }

  increaseAttemptsNumber() {
    this.attemptsNumber++;
  }

  getValidationStatus(corelationData: ICorelation) {
    this.resetNoErrorStatus();
    this.validationStatusLoading = true;
    this.realTimeValidatorService
      .getValidationStatus(corelationData.correlation_id)
      .pipe(
        finalize(() => {
          this.validationStatusLoading = false;
          this.checkAttemptsNumber();
        }),
      )
      .subscribe((response: any) => {
        this.onValidationStatusSuccess(response);
        this.stopButtonLoading();
        if (response.error) {
          this.setNoDataReason(response.error);
          this.onError();
        }
      });
  }

  onValidationStatusSuccess(response: any) {
    if (['failed', 'processed'].includes(response.status)) {
      this.readyResponse = true;
      if (response.status === 'failed') {
        this.toastrService.error('KYC Validation error');
        return;
      }
      this.collectDataFromTr();
      this.toastrService.success('Clear ID API done!');
    }
  }

  unsubscribeFromClearIdApiSubscription() {
    if (this.clearIdApiSubscription) {
      this.clearIdApiSubscription.unsubscribe();
    }
  }

  private setRunClearIdDisabled(): void {
    this.runClearIdButtonDisabled$ = fromPromise(this.permissionsService.hasPermission(this.permissionsRequiredForRunningClearId)).pipe(
      map((hasOneOfRequiredPerms) => !hasOneOfRequiredPerms),
    );
  }
}
