import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AppConfigService } from '../config';

@Injectable()
export class VersionService {
  versionApiUrl: string;
  backendVersion: Observable<string>;

  constructor(private httpClient: HttpClient, appConfig: AppConfigService) {
    this.versionApiUrl = appConfig.env.internalApiUrl + 'version';
  }

  public getBackendVersion(): Observable<string> {
    if (!this.backendVersion) {
      this.backendVersion = this.httpClient.get<string>(this.versionApiUrl).pipe(shareReplay(1));
    }

    return this.backendVersion;
  }

  public getFrontendVersion(): string {
    return environment.VERSION;
  }
}
