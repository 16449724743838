import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { isUndefined } from 'util';

import { ConfigsService, ConfigTypes } from '../../../shared/configs/service/configs.service';
import { EventModel } from '../../models/event.model';
import { TaskModel } from '../../models/task.model';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-event-task-item',
  templateUrl: './event-task-item.component.html',
  styleUrls: ['../../shared/crm-tools.scss', '../../shared/timeline-item.scss', '../event-item/event-item.component.scss'],
})
export class EventTaskItemComponent extends SubscriberComponent implements OnInit {
  @Input() timeline = true;
  @Input() item: EventModel;
  @Input() task: TaskModel;

  priorityOptions = {};
  satusesOptions = {};
  changes: any;
  priorities: Observable<any>;
  statuses: Observable<any>;

  translationOptions = {
    status: () => {
      this.changes.status.old = this.satusesOptions[this.changes.status.old];
      this.changes.status.current = this.satusesOptions[this.changes.status.current];
    },
    priority: () => {
      this.changes.priority.old = this.priorityOptions[this.changes.priority.old];
      this.changes.priority.current = this.priorityOptions[this.changes.priority.current];
    },
    assignee_name: () => {
      this.changes.assignee_name.label = 'assignee';
    },
    body: () => {
      this.changes.body.label = "task's body";
      this.changes.body.old = undefined;
      this.changes.body.current = undefined;
    },
    updated_at: () => {
      delete this.changes['updated_at'];
    },
    assignee_type: () => {
      delete this.changes['assignee_type'];
    },
    assignee_uuid: () => {
      delete this.changes['assignee_uuid'];
    },
    assignee_email: () => {
      delete this.changes['assignee_email'];
    },
    task_url_template: () => {
      delete this.changes['task_url_template'];
    },
    dueDate: () => {
      this.changes['dueDate']['label'] = 'Due date';
      this.changes.dueDate.old = this.datePipe.transform(moment.unix(this.changes.dueDate.old).toDate(), 'shortDate');
      this.changes.dueDate.current = this.datePipe.transform(moment.unix(this.changes.dueDate.current).toDate(), 'shortDate');
    },
    due_date: () => {
      this.changes['due_date']['label'] = 'Due date';
      this.changes.due_date.old = this.datePipe.transform(moment.unix(this.changes.due_date.old).toDate(), 'shortDate');
      this.changes.due_date.current = this.datePipe.transform(moment.unix(this.changes.due_date.current).toDate(), 'shortDate');
    },
  };

  constructor(private configsService: ConfigsService, private datePipe: DatePipe) {
    super();
    this.statuses = this.configsService.get(ConfigTypes.TASK_STATUSES);
    this.priorities = this.configsService.get(ConfigTypes.TASK_PRIORITIES);
  }

  correctTime(oldTime) {
    const newTime = moment(oldTime).format();
    const offSetTime = moment.parseZone(newTime, 'YYYY-MM-DD HH:mm').utcOffset();
    return offSetTime;
  }

  offsetToLocalTime(serverTime): string {
    const offset: number = -60000 * new Date().getTimezoneOffset();
    const parsedDate = new Date(offset + Date.parse(serverTime));
    return parsedDate.toLocaleString();
  }

  ngOnInit() {
    this.subscriptions.push(
      forkJoin([this.statuses, this.priorities]).subscribe((results) => {
        results[0].map((item) => {
          this.satusesOptions[item.uuid] = item.name;
        });

        results[1].map((item) => {
          this.priorityOptions[item.uuid] = item.name;
        });

        this.changes = { ...this.item.data.changes };

        if (!isUndefined(this.changes)) {
          for (const k in this.changes) {
            if (this.changes.hasOwnProperty(k) && this.translationOptions.hasOwnProperty(k)) {
              this.translationOptions[k]();
            }
          }
        }

        if (Object.keys(this.changes).length === 0) {
          this.changes = null;
        }
      }),
    );
  }
}
