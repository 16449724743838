import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import Logger from '../../../shared/logger';
import { TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';

@Component({
  selector: 'app-add-marketplace-payment',
  templateUrl: './add-marketplace-payment.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddMarketplacePaymentComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  actionTitle = 'Add MP payment';
  public marketplaceStatements = [];
  minOverrideAdvanceRepaymentValueDisabled = 0;
  successPaymentMessage: { advanceRepaymentAmount: string; passThroughAmount: string };

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    this.showSpinner();
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe(
        (data) => {
          if (!isUndefined(data)) {
            this.supplierKey = data.supplierKey;

            this.getMarketplaceStatements();
            this.marketplaces = data.marketplaces.map((element) => {
              return {
                label: element.externalSupplierId
                  ? element.marketplaceName + ' (' + element.externalSupplierId + ')'
                  : element.marketplaceName,
                id: element.mpSupKey,
              };
            });
          }
        },
        () => this.hideSpinner(),
      ),
    );
    super.ngOnInit();
  }

  showSpinner() {
    this.isLoadingResult = true;
  }

  hideSpinner() {
    this.isLoadingResult = false;
  }

  createForm() {
    this.supplierActionForm = this.formBuilder.group({
      mp_sup_key: [''],
      mp_pmt_external_id: ['', Validators.required],
      fp_start_date: [''],
      fp_end_date: [moment().subtract(1, 'days').toDate()],
      start_date: [''],
      end_date: [''],
      pay_loan_rcv_first: [false],
      no_pay_later_statements: [false],
      compensed_by_supplier_amount: [undefined, Validators.min(0.01)],
      advance_repayment_amount: [null, this.overrideAdvanceRepaymentValueValidator.bind(this)],
      cross_post_stmt_type: [false],
      first_in_first_out: [false],
      post_date: [moment().subtract(1, 'days').toDate(), Validators.required],
      payment_amount: ['', Validators.required],
      paid_in_full: [''],
      reason: [''],
      mp_statement_key: [''],
      disable_excess_check: [false],
    });
  }

  overrideAdvanceRepaymentValueValidator(control: FormControl) {
    const value = control.value;
    if (!value) {
      return null;
    }
    const isValidOverrideAdvanceRepaymentValue = this.getValidOverrideAdvanceRepaymentValueStatus(value);
    return isValidOverrideAdvanceRepaymentValue ? null : { invalid: true };
  }

  getValidOverrideAdvanceRepaymentValueStatus(value: number) {
    return value && value >= this.minOverrideAdvanceRepaymentValueDisabled && value <= this.supplierActionForm.value.payment_amount;
  }

  backClick() {
    this.back();
  }

  submit() {
    this.showSpinner();
    const dataToSave = {
      supplier_key: this.supplierKey,
      ...this.supplierActionForm.value,
    };

    dataToSave['post_date'] = this.getFormattedDate(dataToSave.post_date);
    if (dataToSave['start_date']) {
      dataToSave['start_date'] = this.getFormattedDate(dataToSave.start_date);
    }
    if (dataToSave['end_date']) {
      dataToSave['end_date'] = this.getFormattedDate(dataToSave.end_date);
    }
    if (dataToSave['fp_start_date']) {
      dataToSave['fp_start_date'] = this.getFormattedDate(dataToSave.fp_start_date);
    }
    if (dataToSave['fp_end_date']) {
      dataToSave['fp_end_date'] = this.getFormattedDate(dataToSave.fp_end_date);
    }
    this.subscriptions.push(
      this.cliService
        .addPayment(dataToSave)
        .pipe(finalize(() => this.hideSpinner()))
        .subscribe(
          (response) => {
            this.onAddPaymentSuccess(response);
          },
          (response) => {
            this.onError(response.error.message);
          },
        ),
    );
  }

  getFormattedDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  onAddPaymentSuccess(response) {
    this.successPaymentMessage = {
      advanceRepaymentAmount: 'Advance Repayment Amount: ',
      passThroughAmount: 'Pass-Through Amount: ',
    };
    const successMessage = 'Marketplace payment added successfully.';
    const newAdvanceRepaymentAmount =
      response &&
      response.payment_info &&
      (response.payment_info.advance_repayment_amount || response.payment_info.advance_repayment_amount === 0)
        ? response.payment_info.advance_repayment_amount
        : '-';

    const newPassThroughAmount =
      response && response.payment_info && (response.payment_info.passthrough_amount || response.payment_info.passthrough_amount === 0)
        ? response.payment_info.passthrough_amount
        : '-';

    this.successPaymentMessage.advanceRepaymentAmount += newAdvanceRepaymentAmount;
    this.successPaymentMessage.passThroughAmount += newPassThroughAmount;

    this.onSuccess(successMessage);
  }

  public getMarketplaceStatements() {
    this.supplierService
      .getMarketplaceStatements(this.supplierKey)
      .pipe(finalize(() => this.hideSpinner()))
      .subscribe(
        (response: any) => {
          if (!isNullOrUndefined(response)) {
            this.marketplaceStatements = response.results && response.results.length > 0 ? response.results : [];
          }
        },
        (error) => Logger.error(error),
      );
  }

  paymentAmountChange() {
    if (
      !this.supplierActionForm ||
      !this.supplierActionForm.controls ||
      this.supplierActionForm.controls.advance_repayment_amount === undefined
    ) {
      return;
    }
    this.supplierActionForm.controls.advance_repayment_amount.updateValueAndValidity();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
