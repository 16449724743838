import { CONTENT_SOURCES } from '../consts/content-sources';
import { INamedColumns, INormalizedCriteria, ISummary } from '../real-time-validation/real-time-validator.interfaces';

export const GetRowDataForPersonalChecks = (columnAndCriteria: {
  criteria: string[];
  normalizedCriteria: INormalizedCriteria;
  namedColumns: INamedColumns;
  summary: ISummary;
  i: number;
}) => {
  const { criteria, normalizedCriteria, namedColumns, summary, i } = columnAndCriteria;
  return {
    row: `Row${i}`,
    value: criteria[i],
    FieldName: criteria[i],
    UserInput: normalizedCriteria[criteria[i]],
    ExperianCreditHeader: namedColumns[CONTENT_SOURCES[0]] ? namedColumns[CONTENT_SOURCES[0]][criteria[i]] : '--',
    EquifaxCreditHeader: namedColumns[CONTENT_SOURCES[1]] ? namedColumns[CONTENT_SOURCES[1]][criteria[i]] : '--',
    TransUnion: namedColumns[CONTENT_SOURCES[2]] ? namedColumns[CONTENT_SOURCES[2]][criteria[i]] : '--',
    UtilityListing: namedColumns[CONTENT_SOURCES[3]] ? namedColumns[CONTENT_SOURCES[3]][criteria[i]] : '--',
    ClearIDStatus: summary[criteria[i]] ? summary[criteria[i]]['@matchtype'] : '--',
  };
};
