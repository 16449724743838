import { autoserialize, autoserializeAs } from 'cerialize';

import { CrmUserModel } from './crm-user.model';

export class NoteDataModel {
  @autoserialize public body: string;
  @autoserializeAs(CrmUserModel) public user: CrmUserModel;

  constructor(body: string, user: CrmUserModel) {
    this.body = body;
    this.user = user;
  }
}
