<section class="card">
  <div class="card-content">
    <app-background-report-card-header [title]="reportTitle" [supplierKey]="supplierKey" [owner]="owner">
    </app-background-report-card-header>
    <div class="d-flex justify-content-between mt-4">
      <ng-container *ngIf="_availableSections.length > 0; else noSectionsForThisPerson">
        <div class="w-75 mr-3">
          <ng-container *ngIf="filteredReportsToShow && filteredReportsToShow.length; else noSectionSelected">
            <ng-container *ngFor="let report of filteredReportsToShow">
              <app-background-report [report]="report"></app-background-report>
            </ng-container>
          </ng-container>
        </div>
        <div class="w-25 ml-2 pl-5 filterWrapper">
          <app-checkbox-filters [options]="filterOptions" (selectionChange)="filtersSelectionChange($event)"> </app-checkbox-filters>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<ng-template #noSectionSelected>
  <app-no-data [text]="selectReportText"></app-no-data>
</ng-template>

<ng-template #noSectionsForThisPerson>
  <app-no-data [text]="noSectionsAvailableText"></app-no-data>
</ng-template>
