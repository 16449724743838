import { Component, Input } from '@angular/core';

import { ConfigEmailTypesNames } from '../../../shared/configs/service/configs.service';

@Component({
  selector: 'app-email-type-badge',
  templateUrl: './email-type-badge.component.html',
  styleUrls: ['./email-type-badge.component.scss'],
})
export class EmailTypeBadgeComponent {
  @Input() type;

  constructor() {}

  getTypeName(type: string): string {
    const foundItem = ConfigEmailTypesNames.find((item) => item.key === type);
    return foundItem ? foundItem.value : type;
  }
}
