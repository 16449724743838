import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { isUndefined } from 'util';

import { SupplierDetailsModel, SupplierModel } from '../../model/supplier.model';
import { ADD_PAYMENT_GATEWAY, FETCH_SUPPLIER_DETAILS } from './supplier.actions';
import * as supplierActions from './supplier.actions';

const SEARCH_FROM_LAST_DAYS = 1;
const QUERY_LS_KEY = 'suppliers_last_query';

export enum PaymentConfigStatus {
  SUCCESS,
  ERROR,
}

export interface SupplierState {
  supplierKey: string;
  lastQueryString: string;

  supplierModel?: SupplierModel;

  // TODO nkler: it seems that is a temporary solution
  supplierError?: HttpErrorResponse;

  suppliers: { [supplierKey: string]: SupplierModel };

  // TODO nkler: maybe it should be treated as a part of form states?
  supplierModelPartInEdit?: { [subformId: string]: any };
  supplierModelPartSaved?: { [subformId: string]: any };
  supplierModelPartErrors?: { [subformId: string]: any };

  toggleHideInEdit?: boolean;
  toggleHideError?: HttpErrorResponse;

  toggleSuspendInEdit?: boolean;
  toggleSuspendError?: HttpErrorResponse;

  addOptInProgress?: boolean;
  addOptInError?: HttpErrorResponse;

  deleteOptInProgress?: boolean;
  deleteOptInError?: HttpErrorResponse;

  paymentConfigsPending: { [key: string]: { status: PaymentConfigStatus; error: any } };
}

const initialState = {
  supplierKey: undefined,
  suppliers: {},

  supplierModelPartInEdit: {},
  supplierModelPartSaved: {},
  supplierModelPartErrors: {},

  lastQueryString: getInitialQueryString(),
  paymentConfigsPending: {},
};

export function createLastQuery(): string {
  const today = new Date();
  return `created_since:${new DatePipe('en-US').transform(today.setDate(today.getDate() - SEARCH_FROM_LAST_DAYS), 'MM/dd/yyyy')}`;
}

export function getInitialQueryString() {
  try {
    const lastQuery = JSON.parse(localStorage.getItem(QUERY_LS_KEY));
    if (lastQuery) {
      return lastQuery;
    }
  } catch (error) {
    // do nothing
  }

  return createLastQuery();
}

export function setPersistedQuery(storedQuery) {
  localStorage.setItem(QUERY_LS_KEY, JSON.stringify(storedQuery));
}

export function supplierReducers(state: SupplierState = initialState, action: supplierActions.Actions): SupplierState {
  const updatedSuppliers = { ...state.suppliers };
  const supplierModel: SupplierModel = state.supplierModel;

  const partsInEdit = state.supplierModelPartInEdit;
  const partsSaved = state.supplierModelPartSaved;
  const partsErrors = state.supplierModelPartErrors;
  const pcStatuses = { ...state.paymentConfigsPending };

  switch (action.type) {
    case ADD_PAYMENT_GATEWAY:
      const marketplaces = [...supplierModel.supplierDetailsModel.marketplaces, action.payload];
      const updatedSupplierDetailsModel = {
        ...supplierModel.supplierDetailsModel,
        marketplaces,
      };
      const changedSupplierModel = {
        ...supplierModel,
        supplierDetailsModel: new SupplierDetailsModel(updatedSupplierDetailsModel),
      };
      return {
        ...initialState,
        supplierModel: changedSupplierModel,
      };

    case FETCH_SUPPLIER_DETAILS:
      return {
        ...initialState,
        suppliers: updatedSuppliers,
        lastQueryString: state.lastQueryString,
      };

    case supplierActions.SET_SUPPLIER_KEY:
      if (action.payload !== state.supplierKey) {
        const cleanedSupplierState = {
          ...state,
        };
        delete cleanedSupplierState.supplierError;

        return {
          ...cleanedSupplierState,
          supplierModel: state.suppliers.hasOwnProperty(action.payload) ? { ...state.suppliers[action.payload] } : new SupplierModel(),
          supplierKey: action.payload,
        };
      } else {
        return {
          ...state,
          supplierKey: action.payload,
        };
      }
    case supplierActions.SET_SUPPLIERS_QUERY_STRING:
      setPersistedQuery(action.payload.queryString);

      return {
        ...state,
        lastQueryString: action.payload.queryString,
      };
    case supplierActions.FETCH_SUPPLIER_DETAILS_ERROR:
      return {
        ...state,
        supplierError: action.payload.errorResponse,
      };
    case supplierActions.UPDATE_SUPPLIER_MODEL:
      const updatedSupplierModel = {
        ...supplierModel,
        ...action.payload,
      };

      if (!isUndefined(action.supplierKey)) {
        updatedSupplierModel.supplierKey = action.supplierKey;
      }

      updatedSuppliers[action.supplierKey] = updatedSupplierModel;
      return {
        ...state,
        supplierModel: updatedSupplierModel,
        supplierKey: action.supplierKey,
        suppliers: updatedSuppliers,
      };

    case supplierActions.UPDATE_SUPPLIER_DETAILS_MODEL:
      const supplierKey = action.payload.supplierKey;

      supplierModel.supplierDetailsModel = action.payload.detailsModel;
      updatedSuppliers[supplierKey] = supplierModel;

      return {
        ...state,
        supplierModel,
        supplierKey,
        suppliers: updatedSuppliers,
      };

    case supplierActions.UNLOAD_SUPPLIER:
      const unloadedState = {
        ...initialState,
      };

      const suppliersAfterUnload = {
        suppliers: { ...state.suppliers },
      };

      return {
        ...unloadedState,
        ...suppliersAfterUnload,
        lastQueryString: state.lastQueryString,
      };
    case supplierActions.TOGGLE_HIDE_SUPPLIER:
      return {
        ...state,
        toggleHideInEdit: true,
      };
    case supplierActions.TOGGLE_HIDE_SUPPLIER_SUCCESS:
      const supplierDetailsModel: SupplierDetailsModel = supplierModel.supplierDetailsModel;
      supplierDetailsModel.hide = !supplierDetailsModel.hide;

      supplierModel.supplierDetailsModel = supplierDetailsModel;
      updatedSuppliers[supplierKey] = supplierModel;

      return {
        ...state,
        toggleHideInEdit: false,
        supplierModel,
        suppliers: updatedSuppliers,
      };

    case supplierActions.TOGGLE_SUSPEND_SUPPLIER_ERROR:
      return {
        ...state,
        toggleSuspendError: action.payload,
        toggleSuspendInEdit: false,
      };

    case supplierActions.TOGGLE_SUSPEND_SUPPLIER_SUCCESS:
      return {
        ...state,
        toggleSuspendInEdit: false,
      };

    case supplierActions.TOGGLE_HIDE_SUPPLIER_ERROR:
      return {
        ...state,
        toggleHideError: action.payload,
        toggleHideInEdit: false,
      };

    case supplierActions.ADD_OPT_IN_ROW:
      return {
        ...state,
        addOptInProgress: true,
      };

    case supplierActions.ADD_OPT_IN_ROW_SUCCESS:
      return {
        ...state,
        addOptInProgress: false,
      };

    case supplierActions.ADD_OPT_IN_ROW_ERROR:
      return {
        ...state,
        addOptInError: action.payload,
        addOptInProgress: false,
      };

    case supplierActions.ADD_OPT_IN_ROW_CLEAR:
      const addOptInStateClear = {
        ...state,
      };
      delete addOptInStateClear.addOptInError;
      delete addOptInStateClear.addOptInProgress;

      return {
        ...addOptInStateClear,
      };

    case supplierActions.DELETE_OPT_IN_ROW:
      return {
        ...state,
        deleteOptInProgress: true,
      };

    case supplierActions.DELETE_OPT_IN_ROW_SUCCESS:
      return {
        ...state,
        deleteOptInProgress: false,
      };

    case supplierActions.DELETE_OPT_IN_ROW_ERROR:
      return {
        ...state,
        deleteOptInError: action.payload,
        deleteOptInProgress: false,
      };

    case supplierActions.UPDATE_MODEL_PART:
      const newValues = action.payload.newValues;
      const currentlyInEdit = {
        ...partsInEdit,
      };

      const uiElementKey = action.payload.formGroupKey;
      currentlyInEdit[uiElementKey] = newValues;

      return {
        ...state,
        supplierModelPartInEdit: currentlyInEdit,
      };

    case supplierActions.UPDATE_MODEL_PART_SUCCESS:
      // PARTS IN EDIT
      const updatedPartsInEdit = {
        ...partsInEdit,
      };
      delete updatedPartsInEdit[action.payload.formGroupKey];

      // SAVED PARTS
      const updatedPartsSaved = {
        ...partsSaved,
      };
      updatedPartsSaved[action.payload.formGroupKey] = action.payload.newValues;

      const updatedDetailsModel = new SupplierDetailsModel(supplierModel.supplierDetailsModel);
      Object.assign(updatedDetailsModel, action.payload.newValues);

      // SUPPLIER FUNDING
      const updatedFundingModel = {
        ...supplierModel.currentFundingParticularsModel,
      };
      Object.assign(updatedFundingModel, action.payload.newValues);

      const newSupplierModel = {
        ...supplierModel,
      };

      newSupplierModel.supplierDetailsModel = updatedDetailsModel;
      newSupplierModel.currentFundingParticularsModel = updatedFundingModel;

      return {
        ...state,
        supplierModel: newSupplierModel,
        supplierModelPartInEdit: updatedPartsInEdit,
        supplierModelPartSaved: updatedPartsSaved,
      };
      return state;
    case supplierActions.UPDATE_MODEL_PART_ERROR:
      const updatedInEditAfterErr = {
        ...partsInEdit,
      };

      // PARTS IN EDIT
      delete updatedInEditAfterErr[action.payload.formGroupKey];

      // SAVED PARTS
      const updatedPartsErrors = {
        ...partsErrors,
      };
      updatedPartsErrors[action.payload.formGroupKey] = action.payload.error;

      return {
        ...state,
        supplierModelPartInEdit: updatedInEditAfterErr,
        supplierModelPartErrors: updatedPartsErrors,
      };

    case supplierActions.UPDATE_MODEL_PART_MAPS:
      // PARTS IN EDIT
      const partsSavedAfterClear = {
        ...partsSaved,
      };
      const partsErrorsAfterClear = {
        ...partsErrors,
      };

      if (action.payload.mapType === 'saved') {
        delete partsSavedAfterClear[action.payload.formGroupKey];
      } else if (action.payload.mapType === 'errors') {
        delete partsErrorsAfterClear[action.payload.formGroupKey];
      } else {
        throw Error('Invalid map type');
      }

      return {
        ...state,
        supplierModelPartSaved: partsSavedAfterClear,
        supplierModelPartErrors: partsErrorsAfterClear,
      };

    case supplierActions.PC_RESPONSE_SUCCESS:
      pcStatuses[action.payload.actionId] = { status: PaymentConfigStatus.SUCCESS, error: null };
      return {
        ...state,
        paymentConfigsPending: pcStatuses,
      };

    case supplierActions.PC_RESPONSE_ERROR:
      pcStatuses[action.payload.actionId] = { status: PaymentConfigStatus.ERROR, error: action.payload.error };
      return {
        ...state,
        paymentConfigsPending: pcStatuses,
      };

    case supplierActions.PC_CLEAR_STATUS:
      if (pcStatuses.hasOwnProperty(action.payload.actionId)) {
        delete pcStatuses[action.payload.actionId];
      }
      return {
        ...state,
        paymentConfigsPending: pcStatuses,
      };

    case supplierActions.FETCH_SUPPLIER_MARKETPLACE:
      if (state.supplierModel) {
        const state1 = { ...state };
        delete state.supplierModel.marketplaces;
        return {
          ...state1,
        };
      } else {
        return { ...state };
      }

    case supplierActions.FETCH_SUPPLIER_MARKETPLACE_SUCCESS:
      if (state.supplierModel) {
        let index = -1;

        if (supplierModel.marketplaces) {
          index = supplierModel.marketplaces.findIndex((m) => m.mpSupKey === action.payload.marketplaceModel.mpSupKey);
        }

        if (index < 0) {
          supplierModel.marketplaces = [action.payload.marketplaceModel];
        } else {
          supplierModel.marketplaces[index] = action.payload.marketplaceModel;
        }

        return {
          ...state,
          supplierModel,
        };
      } else {
        return { ...state };
      }

    default:
      return state;
  }
}
