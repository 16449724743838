import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// eslint-disable-next-line max-len
LicenseManager.setLicenseKey(
  'CompanyName=Payability,LicensedGroup=Payability,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-008723,ExpiryDate=29_June_2021_[v2]_MTYyNDkyMTIwMDAwMA==60a3864d16c0695d344777994f4753db',
);

if (environment.production) {
  enableProdMode();
}

export function main() {
  return platformBrowserDynamic().bootstrapModule(AppModule);
}

if (document.readyState === 'complete') {
  main();
} else {
  document.addEventListener('DOMContentLoaded', main);
}

window.onerror = function (msg, url, lineNo, columnNo, error) {
  try {
    (<any>window).analytics.track('WINDOW ERROR', {
      msg,
      url,
      lineNo,
      columnNo,
      error,
    });
  } catch (err) {}

  return false;
};
