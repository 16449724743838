<ng-container *ngxPermissionsExcept="userPermission.REST_UW_ACCESS">
  <app-supplier-box-header-eva>
    <ng-container *ngTemplateOutlet="tabsTpl"> </ng-container>
  </app-supplier-box-header-eva>
</ng-container>

<ng-container *ngxPermissionsOnly="userPermission.REST_UW_ACCESS">
  <ng-template #loading>
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </ng-template>

  <div class="legal-info" *ngIf="selectSupplierDetails$ | async; else loading; let supplierInfo">
    <div class="supplier-name-key">
      <span class="supplier-name" ngxClipboard [cbContent]="supplierInfo.legalName">{{ supplierInfo.legalName }}</span>
      <div class="supplier-key" ngxClipboard [cbContent]="supplierInfo.supplierKey">
        <small>{{ supplierInfo.supplierKey }}</small>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #tabsTpl>
  <mat-tab-group [selectedIndex]="activeIndex">
    <mat-tab label *ngFor="let tab of navLinks" #currentTab>
      <ng-template mat-tab-label>
        <a [routerLink]="tab.route" routerLinkActive #rla="routerLinkActive" [attr.data-test]="'supp' + tab.label" class="d-flex">
          {{ tab.label }}
        </a>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-template>
