import { ActionReducer } from '@ngrx/store';

import { environment } from '../environments/environment';

export const EnableStoreDebugging = false;

export function storeDebugger(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (showStoreDebugger()) {
      console.log('state', state);
      console.log('action', action);
    }
    return reducer(state, action);
  };
}

export const showStoreDebugger = () => {
  return environment.envName && environment.envName !== 'staging' && environment.envName !== 'prod' && EnableStoreDebugging;
};
