import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../../src/app/config';
import { MessageService } from '../../shared/message.service';
import { VersionService } from '../../shared/version.service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent extends SubscriberComponent implements OnInit {
  feVersion: string;
  $beVersion: Observable<string>;
  message: string;
  activeOccasionLogo = '';

  authUrl = null;

  constructor(
    private appConfig: AppConfigService,
    private route: ActivatedRoute,
    private versionService: VersionService,
    private messageService: MessageService,
  ) {
    super();
    this.feVersion = this.versionService.getFrontendVersion();
    this.$beVersion = this.versionService.getBackendVersion();
  }

  ngOnInit(): void {
    this.setMessageUsingUrlParams();
    this.setOccasionLogo();
  }

  private setOccasionLogo(): void {
    this.activeOccasionLogo = this.appConfig.theme.applySpecialOccasion ? this.appConfig.theme.specialOccasion : '';
  }

  private setMessageUsingUrlParams(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.message = this.messageService.getMessageByCode(params['messageCode']);
      }),
    );
  }
}
