<mat-tab-group [(selectedIndex)]="tabIndex" class="actions-list" (selectedTabChange)="loadTab($event)">
  
  <mat-tab label="ALL">
    <app-action-tab-all #allTab [crmItems]="crmItems" data-test="quickviewAllTab"></app-action-tab-all>
  </mat-tab>
  
  <mat-tab label="NOTES" id="notes">
    <app-action-tab-notes #notesTab [crmItems]="crmItems" data-test="quickviewNotesTab"></app-action-tab-notes>
  </mat-tab>
  
  <mat-tab label="TASKS" id="tasks">
    <app-action-tab-tasks #tasksTab [crmItems]="crmItems" data-test="quickviewTasksTab"></app-action-tab-tasks>
  </mat-tab>
  
  <mat-tab label="EVENTS" id="events">
    <app-action-tab-events #eventsTab [crmItems]="crmItems" data-test="quickviewEventsTab"></app-action-tab-events>
  </mat-tab>
  
  <mat-tab label="EMAILS" id="emails">
    <app-action-tab-emails #emailsTab [crmItems]="crmItems" data-test="quickviewEmailsTab"></app-action-tab-emails>
  </mat-tab>
  
  <mat-tab label="BATCH EMAILS" id="batch-emails">
    <app-action-tab-batch-emails #batchEmailsTab [crmItems]="crmItems" data-test="quickviewBatchEmailsTab"> </app-action-tab-batch-emails>
  </mat-tab>

  <!-- price calculator -->
  <mat-tab label="PRICING CALCULATOR" id="batch-emails">
    <app-action-tab-calculator #calculatorTab data-test="calculatorTab"> </app-action-tab-calculator>
  </mat-tab>


</mat-tab-group>
