<div class="iframeContainer">
  <iframe
    #iframe
    *ngIf="safeUrl$ | async as safeUrl"
    [src]="safeUrl"
    [id]="hash"
    frameborder="1"
    style="overflow: hidden; min-height: 60vw; border: none"
    [ngStyle]="{ visibility: loaded ? 'visible' : 'hidden' }"
    height="100%"
    width="100%"
    allowfullscreen
    (load)="onLoaded()"
  ></iframe>
  <div *ngIf="!loaded" class="iframeLoadingIndicator">
    <span>Loading...</span>
  </div>
</div>
