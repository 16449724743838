import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { SupplierMarketplaceDetailsModel } from '../../../../src/app/suppliers/model/supplier-marketplace.model';
import { ReasonDialogMode } from '../enums/reason-dialog-mode.enum';

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReasonDialogComponent {
  public reasons: Observable<any[]>;
  public warning: string;
  public marketplaces: SupplierMarketplaceDetailsModel[] = [];
  public reason = {
    reasonCode: '',
    reasonNote: '',
    dates: {
      optIn: null,
      optOut: null,
    },
    mp_sup_key: null,
    index: null,
  };
  public displayDates = false;
  public mode: ReasonDialogMode;
  public shouldShowMarketplaceSelect = false;

  constructor(public dialogRef: MatDialogRef<ReasonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.handleData();
  }

  handleData(): void {
    if (!isNullOrUndefined(this.data)) {
      this.reasons = this.data.reasons;
      this.warning = this.data.warning;
      this.marketplaces = this.data.marketplaces;
      this.mode = this.data.mode;
      if (!isNullOrUndefined(this.data.dates)) {
        return this.handleDates();
      }

      this.shouldShowMarketplaceSelect = this.mode === ReasonDialogMode.Suspend && !isNullOrUndefined(this.marketplaces);

      if (!isNullOrUndefined(this.marketplaces) && Array.isArray(this.marketplaces)) {
        this.reason.mp_sup_key = this.marketplaces.length === 1 ? this.marketplaces[0].mpSupKey : null;
      }

      delete this.reason.dates;
      delete this.reason.index;
    }
  }

  reasonByReasonCode(index: number, reason: any): string {
    return reason.codeValue;
  }

  marketpalceByMarketplaceKey(index: number, marketplace: SupplierMarketplaceDetailsModel): string {
    return marketplace.mpSupKey;
  }

  handleDates(): void {
    this.displayDates = true;
    this.reason.dates = this.data.dates;
    this.reason.index = this.data.index;
  }

  get shouldSubmit(): boolean {
    return this.shouldShowMarketplaceSelect ? !!this.reason.mp_sup_key && !!this.reason.reasonCode : !!this.reason.reasonCode;
  }

  doCloseModalWithReason(): void {
    this.dialogRef.close(this.reason);
  }
}
