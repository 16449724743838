import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { DataTableAgGridComponent } from '../../../shared/data-table-ag-grid/data-table-ag-grid.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { PermissionModel } from '../../../shared/users/permission.model';
import { UsersService } from '../../../shared/users/service/users.service';
import { UserModel } from '../../../shared/users/user.model';
import { USER_PERMISSION } from '../../../shared/users/user-permissions.enum';
import { AppState } from '../../../store/app.reducers';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { AddMarketplaceStatementComponent } from '../add-marketplace-statement/add-marketplace-statement.component';
import { EditMarketplaceStatementComponent } from '../edit-marketplace-statement/edit-marketplace-statement.component';

@Component({
  selector: 'app-supplier-marketplace-statements',
  templateUrl: './supplier-marketplace-statements.component.html',
  styleUrls: ['./supplier-marketplace-statements.component.scss'],
})
export class SupplierMarketplaceStatementsComponent extends SubscriberComponent implements OnInit {
  @ViewChild(DataTableAgGridComponent)
  readonly table: DataTableAgGridComponent;
  readonly reportName: string = 'mp_statements';
  readonly columnDef: { [key: string]: ColDef } = {
    statement_id: {
      cellRenderer: (params) => {
        if (!params.value) {
          return '';
        }

        if (this.canModify) {
          return `<span class="ag-cell-value"><a href="javascript:void(0)">${params.value}</a></span>`;
        }

        return `<div class="ag-cell-value">${params.value}</div>`;
      },
      onCellClicked: (params: CellClickedEvent) => this.openEditMpStatementDialog(params),
    },
  };

  remove: { removeFn: () => Observable<boolean>; disabledFn: (params) => boolean };
  supplierKey: string;
  allPermissionsLoaded: boolean;
  canModify: boolean;
  canDelete: boolean;
  eachPermissionLoaded: boolean[] = [];

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogService: MatDialog,
    private readonly supplierService: SupplierService,
    private readonly toastrService: ToastrService,
    private readonly userService: UsersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          distinctUntilChanged(),
        )
        .subscribe((data) => {
          const oldSupplierKey = this.supplierKey;
          this.supplierKey = data.supplierKey;

          if (this.table && this.table.gridApi && this.supplierKey !== oldSupplierKey) {
            this.table.supplierKey = this.supplierKey;
            this.table.refreshGrid(this.table.lastSearchParams);
          }
        }),
    );

    this.subscriptions.push(
      this.store
        .select(selectLoggedUserModel)
        .pipe(
          filter((loggedUser: UserModel) => !!(loggedUser && loggedUser.loginKey)),
          mergeMap((loggedUser: UserModel) => this.userService.getLoginPermissions(loggedUser.loginKey)),
        )
        .subscribe((permissions: PermissionModel[]) => this.loadConcretePermissions(permissions)),
    );
  }

  addNewStatement(): void {
    this.subscriptions.push(
      this.dialogService
        .open(AddMarketplaceStatementComponent)
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => this.refreshTable(result)),
    );
  }

  openEditMpStatementDialog(data: CellClickedEvent): void {
    if (!this.canModify) {
      return;
    }

    this.subscriptions.push(
      this.dialogService
        .open(EditMarketplaceStatementComponent, {
          width: DIALOG_SIZE.DEFAULT.width,
          data: data.data,
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => this.refreshTable(result)),
    );
  }

  private refreshTable(canRefresh: boolean): void {
    if (canRefresh) {
      this.table.refreshGrid(this.table.lastSearchParams);
    }
  }

  private loadConcretePermissions(permissions: PermissionModel[]): void {
    this.allPermissionsLoaded = false;
    this.eachPermissionLoaded = new Array(permissions.length).fill(false);
    this.subscriptions.push(
      ...permissions.map((permission: PermissionModel, index: number) =>
        this.userService.getPermissionSubPerms(permission.permCode).subscribe((p) => this.assignPermissions(p, index)),
      ),
    );
  }

  private assignPermissions(permissions: PermissionModel[], index: number): void {
    const mpStatementModify: PermissionModel = this.findPermission(permissions, USER_PERMISSION.MP_STATEMENTS_MODIFY);
    if (mpStatementModify) {
      this.canModify = !!mpStatementModify;
    }

    const mpStatementDelete: PermissionModel = this.findPermission(permissions, USER_PERMISSION.MP_STATEMENTS_DELETE);
    if (mpStatementDelete) {
      this.canDelete = !!mpStatementDelete;
    }

    this.eachPermissionLoaded[index] = true;
    if (this.eachPermissionLoaded.every((e) => !!e)) {
      this.allPermissionsLoaded = true;
      this.eachPermissionLoaded = [];

      this.remove = {
        removeFn: this.removeFn.bind(this),
        disabledFn: this.isRemoveBtnDisabled.bind(this),
      };
    }
  }

  private findPermission(permissions: PermissionModel[], key: string): PermissionModel {
    return permissions.find((p: PermissionModel) => p.permCode === key);
  }

  private removeFn(data): Observable<boolean> {
    if (!this.canDelete) {
      return of(false);
    }

    const confirmData = {
      data: {
        message: 'Are you sure you want to deleted this record? Once confirmed, this process cannot be undone',
        title: 'Delete Marketplace Statement',
      },
      width: DIALOG_SIZE.SMALL.width,
    };

    return this.dialogService
      .open(ConfirmDialogComponent, confirmData)
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        mergeMap(() => this.supplierService.deleteMarketplaceStatement(data.data.mp_statement_key)),
        tap(() => this.toastrService.success('Marketplace statement removed successfully!')),
        map(() => true),
        catchError((error) => {
          this.toastrService.error(error && error.error && error.error.message ? error.error.message : 'Removing failed.');
          return throwError(error);
        }),
      );
  }

  private isRemoveBtnDisabled(params): boolean {
    return (params.data ? params.data.xact_post_date : params.xact_post_date) || !this.canDelete;
  }
}
