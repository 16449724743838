import { IColumnPredefinition } from '../../data-table-grid/models/default-column-definition';

const AddHeaderIcon = (columnDefinition: IColumnPredefinition) =>
  ({
    ...columnDefinition,
    headerClass: [columnDefinition.headerClass || '', columnDefinition.headerIcon].join(' ').trim(),
  } as IColumnPredefinition);

const HeaderCellCheckAndUpdate = (columnDefinition: IColumnPredefinition): IColumnPredefinition =>
  columnDefinition.headerIcon ? AddHeaderIcon(columnDefinition) : columnDefinition;

export const HeaderCellIconRenderer = (columnDefinition: IColumnPredefinition[]): IColumnPredefinition[] =>
  columnDefinition.map(HeaderCellCheckAndUpdate);
