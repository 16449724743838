import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

import { SubscriberComponent } from '../../../../../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../../../../../store/app.reducers';
import { SupplierService } from '../../../../../../services/supplier.service';
import { selectSupplierDetails } from '../../../../../../store/supplier/supplier.actions';
import { SupplierAdvancesService } from '../../../../service/supplier-advances.service';
import { PendingOfferRiskRatingService } from '../../service/pending-offer-risk-rating.service';
import {
  CalculatorHardReset,
  SetApprovedAmount,
  SetDefaultProgressiveAdvanceSettings,
  SetEligibilityData,
  SetNewOffer,
  SetRequestedAmount,
  SetRequestNotes,
  SetReserveForRepayments,
  SetStateFromPersistedData,
  SetToEditable,
  SetToReadOnly,
  SetUnderwritingNotes,
  SetUnderwritingRiskRating,
} from '../../store/calculator.actions';
import { OfferStatuses } from '../../store/calculator.enums';
import { IAdvanceOfferRequest, IEligibilityData, IProgressivePricingSettings } from '../../store/calculator-interfaces';
import {
  SetExpectedDuration,
  SetExpectedMonthlyEarnings,
  SetForceFull,
  SetForceFullSaved,
  SetGracePeriodEndDate,
  SetNetDeliveryAmount,
  SetNoRebate,
  SetReturnStrategy,
  SetSavedGracedPeriodEndDate,
  SetSavedNoRebate,
  SetSavedReturnStrategy,
} from './../../store/calculator.actions';

@Component({
  selector: 'app-instant-advance-underwriting',
  templateUrl: './instant-advance-underwriting.component.html',
  styleUrls: ['./instant-advance-underwriting.component.scss'],
})
export class InstantAdvanceUnderwritingComponent extends SubscriberComponent implements OnInit {
  public riskRating: IProgressivePricingSettings;
  public requestKey: string;
  public returnLink: string;
  public loading: boolean;
  public isNewOffer: boolean;

  public advanceOfferRequest: IAdvanceOfferRequest;
  public eligibilityData: IEligibilityData;

  public supplierKey: string;

  constructor(
    private readonly riskRatingService: PendingOfferRiskRatingService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly supplierService: SupplierService,
    private readonly supplierAdvancesService: SupplierAdvancesService,
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.advanceOfferRequest = {} as IAdvanceOfferRequest;
    this.supplierKey = '';
    this.store.dispatch(new CalculatorHardReset());

    this.setSubscriptions();
  }

  getDefaultProgressiveAdvanceSettings() {
    this.subscriptions.push(
      this.riskRatingService.getDefaultProgressiveAdvanceSettings().subscribe((data: IProgressivePricingSettings) => {
        this.store.dispatch(new SetDefaultProgressiveAdvanceSettings(data));
        this.riskRating = data;
      }),
    );
  }

  private setSubscriptions() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;
          this.subscriptions.push(this.initCalculator(), this.handleUrlParams(), this.getMlOffer());
        }),
    );
  }

  private handleEligibilityData(eligibilityData: any) {
    if (eligibilityData) {
      this.store.dispatch(new SetEligibilityData(eligibilityData));
      if (this.isNewOffer) {
        this.setRiskRatingBasedOnEligibilityData(eligibilityData);
      }
    }
  }

  private setRiskRatingBasedOnEligibilityData(eligibilityData) {
    if (eligibilityData.no_data) {
      return this.store.dispatch(new SetUnderwritingRiskRating(-1));
    }
    this.store.dispatch(new SetUnderwritingRiskRating(eligibilityData.riskRating));
  }

  private getMlOffer(): Subscription | null {
    if (this.supplierKey) {
      return this.supplierAdvancesService.getMlOffer(this.supplierKey).subscribe((eligibilityData) => {
        this.handleEligibilityData(eligibilityData);
      });
    }
    return null;
  }

  private initCalculator() {
    return this.store.select('calc').subscribe((calcValue) => {
      this.isNewOffer = calcValue.isNewOffer;
      if (calcValue.defaultProgressiveAdvanceSettings.ForEveryRiskRating.length < 1) {
        this.getDefaultProgressiveAdvanceSettings();
      }
    });
  }

  private handleUrlParams() {
    return this.route.params.subscribe((params) => {
      this.requestKey = params['advanceOfferRequestKey'];
      if (this.requestKey) {
        return this.setUpForExistingOffer();
      }
      this.setUpForNewOffer();
    });
  }

  private setUpForExistingOffer() {
    this.returnLink = '../../';
    this.subscriptions.push(
      this.supplierAdvancesService.getOfferDetails(this.requestKey).subscribe((offerDetailsData) => {
        if (offerDetailsData) {
          this.dispatchDataToStore(offerDetailsData);
        }
      }),
    );
  }

  private setUpForNewOffer() {
    this.returnLink = '../';
    this.loading = false;
    this.store.dispatch(new SetNewOffer(true));
  }

  private dispatchDataToStore(data) {
    const COUNT_FROM_NET_AMOUNT = true;
    this.advanceOfferRequest = data;
    const periods = Array.isArray(this.advanceOfferRequest.periods) ? this.advanceOfferRequest.periods : [];
    const riskRating = this.advanceOfferRequest.risk_rating ? +this.advanceOfferRequest.risk_rating : -1;
    this.store.dispatch(new SetStateFromPersistedData({ periods, riskRating }));
    this.store.dispatch(new SetReserveForRepayments(data.extra_reserve_percent));

    COUNT_FROM_NET_AMOUNT
      ? this.store.dispatch(new SetNetDeliveryAmount(data.approved_amount))
      : this.store.dispatch(new SetApprovedAmount(data.approved_amount));

    this.store.dispatch(new SetRequestedAmount(data.requested_amount));
    this.store.dispatch(new SetRequestNotes(data.request_notes));
    this.store.dispatch(new SetUnderwritingNotes(data.underwriter_notes));
    this.store.dispatch(new SetExpectedMonthlyEarnings(data.expected_monthly_earnings));
    const expectedDuration = data.advance_templates && data.advance_templates.length ? data.advance_templates[0].expected_duration : null;
    this.store.dispatch(new SetExpectedDuration(expectedDuration));
    this.store.dispatch(new SetGracePeriodEndDate(data.grace_period_length));
    this.store.dispatch(new SetSavedGracedPeriodEndDate(data.grace_period_length));
    this.store.dispatch(new SetNoRebate(data.no_rebate));
    this.store.dispatch(new SetSavedNoRebate(data.no_rebate));
    this.store.dispatch(new SetReturnStrategy(data.return_strategy));
    this.store.dispatch(new SetSavedReturnStrategy(data.return_strategy));
    this.store.dispatch(new SetForceFull(data.force_full));
    this.store.dispatch(new SetForceFullSaved(data.force_full));
    if (OfferStatuses.PROCESSED === data.offer_status) {
      this.store.dispatch(new SetToReadOnly());
    } else {
      this.store.dispatch(new SetToEditable());
    }
    this.loading = false;
    this.store.dispatch(new SetNewOffer(false));
  }
}
