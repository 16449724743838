import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

type DateValue = Date | string | { date: string | Date; format: 'string' };

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

@Component({
  selector: 'app-ag-date-cell',
  template: '{{ date | date: format }}',
})
export class PybAgCellDateComponent implements ICellRendererAngularComp {
  date: DateValue;
  format = DEFAULT_DATE_FORMAT;

  refresh(params: any): boolean {
    if (params.value !== this.date) {
      this.date = params;
    }
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  agInit(params: any): void {
    this.date = params.value;
    if (params.dateFormat) {
      this.format = params.dateFormat;
    }
  }
}
