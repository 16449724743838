<div class="card">
  <div class="card-content">
    <app-underwriting-initial-card-header
      [chipText]="'Automated'"
      [chipColor]="'accent'"
      [title]="'Amazon Lending'"
      [status]="headerStatus"
      [type]="headerType"
    >
    </app-underwriting-initial-card-header>
    <div class="table-wrapper">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="10%" class="text-right">Target</th>
            <th width="10%" class="text-right">Value</th>
            <th width="20%" class="text-right">Decision</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="UWRules && UWRules['amazon_loan']">
            <td>Does supplier have a loan in place with Amazon? If yes, there may be an adjustment on reserve.</td>
            <td class="text-right">No</td>
            <td class="text-right" *ngIf="UWRules && UWRules['amazon_loan'].value === '0'">No</td>
            <td class="text-right" *ngIf="UWRules && UWRules['amazon_loan'].value !== '0'">
              Yes
              <br />
              {{ UWRules['amazon_loan'].value | currency: 'USD':'symbol':'1.0-0' }}
            </td>
            <td class="text-right">
              {{ UWRules['amazon_loan'].value === '0' ? 'No' : 'Yes' }}
            </td>
            <td class="text-right"></td>
          </tr>
          <tr>
            <td>Loan to sales ratio</td>
            <td class="text-right">0</td>
            <td class="text-right" *ngIf="amazonHealth['loan_to_sales_ratio']">
              {{ amazonHealth['loan_to_sales_ratio']['value'] + '%' }}
            </td>
            <td class="text-right" *ngIf="!amazonHealth['loan_to_sales_ratio']">
              {{ noDataText }}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2">Override Amazon Lending results</td>
            <td colspan="3" class="text-right" width="20%">
              <ng-container *ngIf="!computingDataIsInProgress">
                <app-radiobuttons
                  [value]="amazonLendingOverride[0].value"
                  [labelPosition]="'before'"
                  [options]="radiobuttons"
                  (onChange)="radiobuttonsChange($event)"
                >
                </app-radiobuttons>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
