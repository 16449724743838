import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { OnboardingDetails } from '../onboarding-details.model';
import { SupplierOnboardingService } from '../supplier-onboarding.service';

interface IComplianceList {
  compliances: MatTableDataSource<any>;
  mpLabel: string;
  mpKey: string;
  isIntegrated: boolean;
}

interface IComplianceModel {
  changeable: boolean;
  complianceKey: string;
  complianceLabel: string;
  complianceLevel: string;
  complianceType: string;
  description: string;
  requiredForFunding: boolean;
  status: string;
}

interface IKeys {
  supplierKey: string;
  suppMPKeys: any[];
}

@Component({
  selector: 'app-supplier-onboarding-compliances',
  templateUrl: './supplier-onboarding-compliances.component.html',
  styleUrls: ['./supplier-onboarding-compliances.component.scss'],
})
export class SupplierOnboardingCompliancesComponent extends SubscriberComponent implements OnInit {
  @Input() keys: IKeys;

  public dataList: Array<IComplianceList> = [];
  public marketplaceDetailsColumns: string[] = ['complianceLabel', 'status', 'requiredForFunding'];
  readonly accessStatuses = ['incomplete', 'pending', 'verified', 'rejected', 'skipped'];

  private readonly MSG_CANNOT_UPDATE_DATA = 'Cannot update data';
  private readonly MSG_STATUS_UPDATED = 'Status has been successfully updated';
  private readonly MSG_CANT_GET_ONBORDING_DETAILS = "Can't get data with Onboarding workflow details";
  private readonly MSG_PYB = 'Payability';
  private readonly MSG_ONBOARDING_STEPS = 'Payability Onboarding Steps';

  constructor(private onboardingService: SupplierOnboardingService, private toastr: ToastrService) {
    super();
  }

  ngOnInit() {
    this.setOnboardingWorkflowDetails();
  }

  private setOnboardingWorkflowDetails(): void {
    if (!this.keys.supplierKey) {
      return;
    }

    this.onboardingService.getOnboardingWorkflowDetail(this.keys.supplierKey).subscribe(
      (decisions: OnboardingDetails[]) => {
        if (Array.isArray(decisions)) {
          for (const decision of decisions) {
            const newDataSource = new MatTableDataSource();
            newDataSource['data'] = decision.compliances;
            this.dataList.push({
              compliances: newDataSource,
              mpLabel: decision.marketplaceName === this.MSG_PYB ? this.MSG_ONBOARDING_STEPS : decision.marketplaceName,
              mpKey: decision.marketplaceKey,
              isIntegrated: decision.isIntegrated,
            });
          }
        }
      },
      (err) => {
        this.toastr.error(err.error.message ? err.error.message : this.MSG_CANT_GET_ONBORDING_DETAILS);
      },
    );
  }

  public updateComplianceStatus(data: IComplianceList, element: IComplianceModel, event: any): void {
    let mpSupKey = '';
    if (this.keys.suppMPKeys) {
      for (const marketplace of this.keys.suppMPKeys) {
        if (marketplace.marketplaceKey === data.mpKey) {
          mpSupKey = marketplace.mpSupKey;
        }
      }
    }
    if (mpSupKey) {
      this.subscriptions.push(
        this.onboardingService.updateComplianceWithMpKey(mpSupKey, { status: event.value }).subscribe(
          () => this.toastr.success(this.MSG_STATUS_UPDATED),
          (err) => this.toastr.error(err.error.message ? err.error.message : this.MSG_CANNOT_UPDATE_DATA),
        ),
      );
      return;
    }
    this.subscriptions.push(
      this.onboardingService.updateComplianceWithoutMpKey(this.keys.supplierKey, element.complianceKey, { status: event.value }).subscribe(
        () => this.toastr.success(this.MSG_STATUS_UPDATED),
        (err) => this.toastr.error(err.error.message ? err.error.message : this.MSG_CANNOT_UPDATE_DATA),
      ),
    );
  }
}
