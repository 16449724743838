import { HttpErrorResponse } from '@angular/common/http';

import * as AuthActions from './auth.actions';
import { CLEAR_AUTH_STATE, LOGIN, LOGIN_FAILED, LOGOUT } from './auth.actions';
import { CURRENT_USER_KEY } from './auth.effects';

export interface AuthState {
  isAuthenticated: boolean;
  user?: any;
  authError?: HttpErrorResponse;
}

export const initialState: AuthState = {
  isAuthenticated: false,
};

function getPersistedState(): AuthState {
  try {
    const storedUser = JSON.parse(localStorage.getItem(CURRENT_USER_KEY));
    if (storedUser) {
      return {
        user: storedUser,
        isAuthenticated: true,
      };
    }
  } catch (error) {
    // do nothing
  }

  return initialState;
}

function setPersistedState(storedUser): void {
  localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(storedUser));
}

export function authReducers(state = getPersistedState() || initialState, action: AuthActions.AuthActions): AuthState {
  switch (action.type) {
    case LOGIN:
      const currentUser = action.payload.user;
      setPersistedState(currentUser);
      return {
        ...state,
        user: currentUser,
        isAuthenticated: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        authError: action.payload,
        isAuthenticated: false,
      };
    case CLEAR_AUTH_STATE:
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
