import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { UsersService } from '../../../shared/users/service/users.service';
import { UserModel } from '../../../shared/users/user.model';
import { USER_PERMISSION } from '../../../shared/users/user-permissions.enum';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { FetchSupplierDetails, selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { AdditionalBusinsessOwnerService } from './additional-businsess-owner.service';

export const emptyMock = [
  {
    loginKey: '',
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    stateCode: '',
    zipPostalCode: '',
    SSN: '',
    dateOfBirth: '',
    shares: '',
  },
];

@Component({
  selector: 'app-additional-business-owners',
  templateUrl: './additional-business-owners.component.html',
  styleUrls: ['./additional-business-owners.component.scss'],
})
export class AdditionalBusinessOwnersComponent extends SubscriberComponent implements OnInit {
  private supplierKey: string;

  readonly ERROR_TEXT = 'Something went wrong';
  readonly OWNER_ADDED_TEXT = 'New owner has been added';
  readonly DATA_EDITED_TEXT = 'Data has been edited';
  readonly additionalBusinessOwnersColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'address',
    'shares',
    'dateOfBirth',
    'SSN',
    'city',
    'stateCode',
    'edit',
  ];
  readonly dataList = new MatTableDataSource();
  readonly mask: string[] = ['*********'];

  onTouched: Function = Function.prototype; // Trascend the onTouch event
  isAuthorizedToChangeSsn: boolean;
  showSSN: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
    private readonly additionalBusinsessOwnerService: AdditionalBusinsessOwnerService,
    private readonly toastrService: ToastrService,
    private readonly usersService: UsersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data: SupplierDetailsModel) => !!(data && data.supplierKey)),
          tap((data: SupplierDetailsModel) => (this.supplierKey = data.supplierKey)),
          tap(() => this.setData()),
          mergeMap(() => this.assignIsAuthorizedToChangeSsn()),
        )
        .subscribe(),
    );
  }

  setData(isFirst = true): void {
    if (!this.supplierKey) {
      return;
    }

    this.subscriptions.push(
      this.additionalBusinsessOwnerService.getOwnersList(this.supplierKey).subscribe(
        (owners: any[]) => {
          this.dataList['data'] = owners;
          if (this.route && this.route.snapshot && this.route.snapshot.data && this.route.snapshot.data['isNew'] && isFirst) {
            this.dataList['data'] = [...emptyMock, ...this.dataList['data']];
          }
        },
        (err) => {
          this.toastrService.error(err.error.message ? err.error.message : 'Cant fetch data');
        },
      ),
    );
  }

  markAsTouched(): void {
    this.onTouched();
  }

  sortData(sort: Sort): void {
    this.dataList['data'] = this.dataList['data'].sort((a, b) => {
      return (a[sort.active] < b[sort.active] ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
    });
  }

  edit(el: any): void {
    this.subscriptions.push(
      this.additionalBusinsessOwnerService.editOwner(el.loginKey, el).subscribe(
        () => {
          this.setData();
          this.toastrService.success('Additional business owners has been removed');
        },
        () => {
          this.toastrService.success('Edit owner delete failed');
        },
      ),
    );
  }

  delete(el: any): void {
    this.subscriptions.push(
      this.additionalBusinsessOwnerService.removeOwner(this.supplierKey, el.loginKey).subscribe(
        () => {
          this.setData(false);
          this.toastrService.success('Additional business owners has been removed');
        },
        () => {
          this.toastrService.error('Additional owner delete failed');
        },
      ),
    );
  }

  toggleEditMode(el: any): void {
    el.isEditable = !el.isEditable;
  }

  save(el: any): void {
    const element = { ...el };
    if (!this.isAuthorizedToChangeSsn) {
      delete element['SSN'];
    }

    if (!element.loginKey) {
      this.addOwner(element);
      return;
    }
    this.editOwner(element);
  }

  addOwner(el: any): void {
    this.subscriptions.push(
      this.additionalBusinsessOwnerService.addOwner(this.supplierKey, el).subscribe(
        () => {
          this.setData(false);
          this.refreshSupplierDetails();
          this.toastrService.success(this.OWNER_ADDED_TEXT);
        },
        (err) => {
          this.toastrService.error(err.error.message ? err.error.message : this.ERROR_TEXT);
        },
      ),
    );
  }

  editOwner(el: any): void {
    this.subscriptions.push(
      this.additionalBusinsessOwnerService.editOwner(el.loginKey, el).subscribe(
        () => {
          this.setData();
          this.refreshSupplierDetails();
          this.toastrService.success(this.DATA_EDITED_TEXT);
        },
        (err) => {
          this.toastrService.error(err.error.message ? err.error.message : this.ERROR_TEXT);
        },
      ),
    );
  }

  toggleMask(): void {
    this.showSSN = !this.showSSN;
  }

  private refreshSupplierDetails(): void {
    this.store.dispatch(new FetchSupplierDetails({ supplierKey: this.supplierKey, refresh: true }));
  }

  private assignIsAuthorizedToChangeSsn(): Observable<[boolean, boolean]> {
    const permissions = [USER_PERMISSION.FINANCE, USER_PERMISSION.DEV, USER_PERMISSION.REST_UW_ACCESS];
    return this.store.select(selectLoggedUserModel).pipe(
      filter(Boolean),
      mergeMap((user: UserModel) =>
        combineLatest([
          this.usersService.checkGeneralPermissions(permissions, user),
          this.usersService.checkPermissions(permissions, user),
        ]),
      ),
      tap(([generalPermissions, subPermissions]) => (this.isAuthorizedToChangeSsn = generalPermissions || subPermissions)),
    );
  }
}
