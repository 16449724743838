<span>Instant Advance</span>
<mat-divider></mat-divider>

<mat-table [dataSource]="dataSourceIAdvance">
  <ng-container matColumnDef="netDeliveryAmount">
    <mat-header-cell *matHeaderCellDef>Net Delivery Amount</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.netDeliveryAmount }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="advanceFeeReserve">
    <mat-header-cell *matHeaderCellDef>Advance Fee Reserve</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.advanceFeeReserve }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="averageFee">
    <mat-header-cell *matHeaderCellDef>Average Fee</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.averageFee }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="mpToPayDown">
    <mat-header-cell *matHeaderCellDef>MP To Pay Down</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.mpToPayDown }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedInstantAdvanceColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedInstantAdvanceColumns"></mat-row>
</mat-table>

<app-fee-summary [summaryData]="elementData"></app-fee-summary>
