import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { LookupAbstractService } from '../../shared/lookup-service/lookup-abstract.service';
import { PaymentBatchesAbstractService } from '../service/payment-batches-abstract-service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { SupplierPaymentNewBatchModel } from './../model/supplier-payment-batch-model';

@Component({
  selector: 'app-process-add',
  templateUrl: './process-add.component.html',
  styleUrls: ['./process-add.component.scss'],
})
export class ProcessAddComponent extends SubscriberComponent implements OnInit {
  // Fields
  public addPaymentBatchForm: FormGroup;
  public isLocked = false;
  public batchOptionChecked = false;
  public batchTypes = [
    { value: 'credit', label: 'Credit' },
    { value: 'debit', label: 'Debit' },
  ];
  public premiumFeeCodeDomain = 'pbatch.feeCode';
  public premiumFeeOption: Observable<any[]>;
  isDebit: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: PaymentBatchesAbstractService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private lookupService: LookupAbstractService,
  ) {
    super();
  }

  // Methods
  public ngOnInit(): void {
    this.premiumFeeOption = this.lookupService
      .getCodes(this.premiumFeeCodeDomain)
      .pipe(map((items) => items.filter((item) => item.codeValue !== 'pb.fc:no_batch')));

    this.createForm();
    this.subscriptions.push(
      this.addPaymentBatchForm.get('type').valueChanges.subscribe((val) => {
        this.isDebit = val === 'debit';
        if (this.isDebit) {
          this.addPaymentBatchForm.patchValue({
            addWirPmts: false,
            addAchPmts: true,
            debitPayments: true,
            instantAccess: false,
            scheduledPayments: false,
            proposedAdvanceLine: false,
            paymentType: '',
          });
        } else {
          this.addPaymentBatchForm.patchValue({
            addWirPmts: true,
            debitPayments: false,
          });
        }
      }),
    );
  }

  public toggleLock(): void {
    this.isLocked = !this.isLocked;
    this.isLocked ? this.addPaymentBatchForm.disable() : this.addPaymentBatchForm.enable();
    if (this.batchOptionChecked) {
      this.addPaymentBatchForm.controls['paymentBatchFeeCode'].enable();
    } else {
      this.addPaymentBatchForm.controls['paymentBatchFeeCode'].disable();
    }
  }

  public pickerOnDateChange(event: MatDatepickerInputEvent<any>): void {
    const timeControl = this.addPaymentBatchForm.get('time');
    if (timeControl) {
      if (event.value && (isNullOrUndefined(timeControl.value) || timeControl.value === '')) {
        this.addPaymentBatchForm.patchValue({
          time: '12:00',
        });
      }
    }
  }

  public onSubmit(): void {
    if (this.addPaymentBatchForm.valid) {
      const plannedPaymentTime = this.getPlannedPaymentDateTime();
      const init = {
        reviewers: [],
        locked: false,
        plannedPaymentTime,
        ...this.addPaymentBatchForm.value,
      };
      init[this.addPaymentBatchForm.get('paymentType').value] = true;
      delete init['paymentType'];
      const newBatch = new SupplierPaymentNewBatchModel();
      Object.assign(newBatch, init);
      this.subscriptions.push(
        this.service.addBatch(newBatch).subscribe(
          (success) => {
            this.toastrService.success('New payment batch has been successfully added.');
            this.router.navigate(['../batches'], { relativeTo: this.route });
          },
          (error) => {
            this.toastrService.error('Failed to add new payment batch.');
          },
        ),
      );
    }
  }

  private createForm(): void {
    this.addPaymentBatchForm = this.formBuilder.group({
      type: [{ value: 'credit', disabled: false }, Validators.required],
      name: [{ value: '', disabled: false }, Validators.required],
      date: [{ value: '', disabled: false }, Validators.required],
      time: [{ value: '', disabled: false }, Validators.required],
      paymentType: '',
      instantAccess: [false],
      scheduledPayments: [false],
      proposedAdvanceLine: [false],
      addAchPmts: [true],
      addEfsPmts: [false],
      addPpmPmts: [false],
      addWirPmts: [true],
      addLlpPmts: [false],
      paymentBatchFeeCode: [{ value: 'pb.fc:normal', disabled: false }],
      debitPayments: [false],
    });
  }

  private getPlannedPaymentDateTime(): Date {
    let plannedPaymentDateTime: Date;

    const dateControl = this.addPaymentBatchForm.get('date');
    const timeControl = this.addPaymentBatchForm.get('time');

    if (dateControl && timeControl) {
      const date: Date = dateControl.value;
      const time: string = timeControl.value;

      if (time && time.indexOf(':') >= 0) {
        const hours = time.substring(0, time.indexOf(':'));
        const minutes = time.substring(time.indexOf(':') + 1);

        if (!isUndefined(parseInt(hours, 10)) && !isUndefined(parseInt(minutes, 10))) {
          plannedPaymentDateTime = new Date(date);
          plannedPaymentDateTime.setHours(parseInt(hours, 10));
          plannedPaymentDateTime.setMinutes(parseInt(minutes, 10));
        }
      }
    }

    return plannedPaymentDateTime;
  }

  public checkBatchOption(): void {
    this.batchOptionChecked = true;
    this.addPaymentBatchForm.controls['paymentBatchFeeCode'].enable();
  }
}
