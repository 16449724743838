import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from './../../../config';

@Injectable()
export class FeeSettingsService {
  private internalApiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.internalApiUrl = appConfig.env.internalApiUrl;
  }

  editFeeSetting(fee_schedule_key: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.internalApiUrl}fee_schedule_tier/${fee_schedule_key}`, { tiers: data });
  }

  editScheduleName(fee_schedule_key: any, feeScheduleTypeCode: any, feeScheduleName: any): Observable<any> {
    return this.httpClient.put(`${this.internalApiUrl}fee_schedules/${fee_schedule_key}`, { feeScheduleTypeCode, feeScheduleName });
  }

  getFeeScheduleData(): Observable<any> {
    return this.httpClient.get(`${this.internalApiUrl}fee_schedule_types`);
  }

  addFeeSchedule(data: any): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}fee_schedules`, data);
  }

  deleteFeeSchedule(fee_schedule_key: any): Observable<any> {
    return this.httpClient.delete(`${this.internalApiUrl}fee_schedule_remove/${fee_schedule_key}`);
  }

  freeAccessToggle(feeScheduleKey: string, freeAccess: boolean): Observable<any> {
    return this.httpClient.put(`${this.internalApiUrl}fee_schedules/${feeScheduleKey}/extra`, { freeAccess });
  }
}
