import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { isUndefined } from 'util';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { AppConfigService } from '../../../config';
import { B2bDocumentService } from '../../../shared/b2b-document/b2b-document.service';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { PybDocumentsService } from '../../../shared/pyb-documents/pyb-documents.service';
import { AppState } from '../../../store/app.reducers';
import { CommentModel } from '../../models/comment.model';
import { CommentsService } from '../../services/comments.service';
import { ItemWithAttachmentComponent } from '../../shared/item-with-attachment.component';
import { PermissionModel } from './../../../shared/users/permission.model';
import { UsersService } from './../../../shared/users/service/users.service';
import { UserModel } from './../../../shared/users/user.model';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss'],
})
export class CommentItemComponent extends ItemWithAttachmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() item: CommentModel;
  @Output() onRefresh = new EventEmitter();
  @ViewChild('commentBody') commentBody: ElementRef;

  body: string;
  isRepliesShown = false;
  editMode = false;
  hasAccessTo = false;

  private loggedUser$: Subscription;
  public subscriptions: Subscription[] = [];
  public assigneeOptions: (UserModel | PermissionModel)[];

  constructor(
    private usersService: UsersService,
    private commentsService: CommentsService,
    private store: Store<AppState>,
    appConfig: AppConfigService,
    dialog: MatDialog,
    b2bDownloadService: B2bDocumentService,
    httpClient: HttpClient,
    renderer: Renderer2,
    featureFlagsService: FeatureFlagsService,
    pybDocuments: PybDocumentsService,
    toastrService: ToastrService,
    sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
  ) {
    super(dialog, b2bDownloadService, toastrService, httpClient, appConfig, renderer, featureFlagsService, pybDocuments, sanitizer);
  }

  correctTime(oldTime) {
    const newTime = moment(oldTime).format();
    const offSetTime = moment.parseZone(newTime, 'YYYY-MM-DD HH:mm').utcOffset();
    return offSetTime;
  }

  showReplies() {
    this.isRepliesShown = true;
  }

  editComment() {
    const dataToSave = {
      body: this.body,
      edited_at: moment.utc().format(),
    };

    this.subscriptions.push(
      this.commentsService.editComment(this.item.uuid, this.item.targetUuid, dataToSave).subscribe((comment) => {
        this.body = comment.body;
        this.item.body = this.body;
        this.editMode = false;
        setTimeout(() => {
          this.findFiles();
        }, 100);
        this.toastrService.success('Comment successfully updated!');
      }),
    );
  }

  deleteComment() {
    this.subscriptions.push(
      this.commentsService.deleteComment(this.item.uuid, this.item.targetUuid).subscribe(() => {
        this.toastrService.success('Comment successfully deleted');
        this.onRefresh.emit('deleted');
      }),
    );
  }

  ngOnInit() {
    this.body = this.item.body;

    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        if (!isUndefined(user)) {
          this.hasAccessTo = user.hasAccessTo(this.item, 'edit');
        }
      }),
    );

    const observableBatch: any[] = [];
    observableBatch.push(this.usersService.getCombinedList());
    this.subscriptions.push(
      combineLatest(observableBatch).subscribe(([assignees]) => {
        this.assigneeOptions = <(UserModel | PermissionModel)[]>assignees;
      }),
    );
  }

  findFiles() {
    const elems = this.commentBody.nativeElement.querySelectorAll('span[pyb-b2b-preview]');
    for (const item of elems) {
      this.transformAttachment(item);
    }
  }

  ngAfterViewInit() {
    this.findFiles();
  }

  getName(authorUuidKey) {
    if (this.assigneeOptions && this.assigneeOptions.length) {
      const foundItem = this.assigneeOptions
        .filter((item) => item.type === 'user')
        .find((item) => {
          return item.key === authorUuidKey;
        });
      if (foundItem) {
        if (foundItem instanceof UserModel) {
          return foundItem.name;
        } else {
          return authorUuidKey;
        }
      } else {
        return authorUuidKey;
      }
    }
  }

  async onEdit() {
    this.editMode = true;
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
