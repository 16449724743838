import { Validators } from '@angular/forms';

const usCountryCode = 'US';

export const postalCodeValidatorForUs = Validators.pattern(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/);
export const postalCodeUpdateValidators = (postalCodeControl) => (countryCode) => {
  if (countryCode === usCountryCode) {
    postalCodeControl.setValidators(postalCodeValidatorForUs);
    postalCodeControl.markAsDirty();
    postalCodeControl.markAsTouched();
  } else {
    postalCodeControl.clearValidators();
  }

  postalCodeControl.updateValueAndValidity();
};
