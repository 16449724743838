import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { UsersService } from '../../../shared/users/service/users.service';
import { EventRule } from '../event-rule.models';
import { EventsService } from '../events.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-rules-list',
  templateUrl: './rules-list.component.html',
  styleUrls: ['./rules-list.component.scss'],
})
export class RulesListComponent extends SubscriberComponent implements OnInit {
  displayedColumns = [
    'name',
    'eventType',
    'actions',
    'created_at',
    'last_executed',
    'execution_count',
    'updated_at',
    'created_by_name',
    'previewHistory',
    'userActions',
  ];
  dataSource = new MatTableDataSource<EventRule>();
  hasCRMPermission;

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsService,
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.subscriptions.push(
      this.userService.checkPermission(USER_PERMISSION.CRM_ADMIN).subscribe((permitted) => {
        this.hasCRMPermission = permitted;
      }),
    );
  }

  ngOnInit() {
    this.eventsService.list().subscribe((response) => (this.dataSource.data = response.data));
  }

  remove($event, element) {
    $event.stopPropagation();

    if (this.hasCRMPermission) {
      this.subscriptions.push(
        this.eventsService
          .delete(element.uuid)
          .subscribe(() => (this.dataSource.data = this.dataSource.data.filter((e) => e.uuid !== element.uuid))),
      );
    }
  }

  navigateToHistory(uid: any): void {
    this.router.navigate(['../' + uid + '/history'], { relativeTo: this.route });
  }

  navigateToEdit(uid: any): void {
    if (this.hasCRMPermission) {
      this.router.navigate(['../' + uid + '/edit'], { relativeTo: this.route });
    }
  }
}
