import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { SubscriberComponent } from '../../../../../../shared/component-subscriber/subscriber.component';
import { IPlaidResponse, ItemLoginRequiredResponse } from '../iplaid-response-dto';
import { SupplierPlaidService } from '../supplier-plaid.service';

@Component({
  selector: 'tr [app-supplier-payment-plaid-information-row]',
  templateUrl: './supplier-payment-plaid-information-row.component.html',
  styleUrls: ['./supplier-payment-plaid-information-row.component.scss'],
})
export class SupplierPaymentPlaidInformationRowComponent extends SubscriberComponent {
  @Input() plaidRow: IPlaidResponse;
  @Input() supplierKey: string;
  @Input() indexOfRow: number;
  @Input() rowsCount: number;
  @Input() itemLoginRequired: boolean;

  isNullOrUndefined = isNullOrUndefined;

  constructor(private plaidService: SupplierPlaidService, private toastr: ToastrService) {
    super();
  }

  addToAch() {
    if (isNullOrUndefined(this.plaidRow.achContract)) {
      return;
    }

    this.subscriptions.push(
      this.plaidService.addToAch(this.supplierKey, this.plaidRow.accountId, !this.plaidRow.achContract).subscribe((response) => {
        this.toastr.success(`Successfully switched ${response.decision ? 'on' : 'off'}  'Include in ACH Debit Contract'`);
      }),
    );
  }
  toggleItemLoginRequired() {
    this.itemLoginRequired = !!this.itemLoginRequired;
    this.subscriptions.push(
      this.plaidService
        .setItemLoginRequired(this.supplierKey, this.plaidRow.itemId, !this.itemLoginRequired)
        .subscribe((response: ItemLoginRequiredResponse) => {
          const { decision } = response;
          this.itemLoginRequired = decision;
          this.toastr.success(`Successfully turned Plaid Update Mode ${decision ? 'on' : 'off'} for ${this.plaidRow.institution}`);
        }),
    );
  }

  receivePayments() {
    if (isNullOrUndefined(this.plaidRow.achContract)) {
      return;
    }
    this.subscriptions.push(
      this.plaidService
        .receivePayments(this.supplierKey, this.plaidRow.accountId, !this.plaidRow.receivingPayments)
        .subscribe((response) => {
          this.toastr.success(`Successfully switched ${response.decision ? 'on' : 'off'}  'Receiving Gateway / Platform Payments'`);
        }),
    );
  }
}
