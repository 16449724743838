import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { IExternalReportDTO } from '../dto/external-report-dto';
import { ExternalReport } from '../model/external-report';
import { IExternalReport } from '../model/i-external-report';

@Injectable()
export class ExternalReportsService {
  public apiListUrl: string;
  public apiDetailsUrl: string;

  constructor(private appConfig: AppConfigService, private http: HttpClient) {
    this.apiListUrl = appConfig.env.internalApiUrl + 'reports/external_list';
    this.apiDetailsUrl = appConfig.env.internalApiUrl + 'reports/external/';
  }

  public getList(): Observable<IExternalReport[]> {
    return this.http.get(`${this.apiListUrl}`).pipe(
      map((response: any) => {
        const mappedResult = [];
        for (const item of <IExternalReportDTO[]>response) {
          mappedResult.push(new ExternalReport(item));
        }
        return mappedResult;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  public getDetail(reportExternalKeys: string): Observable<IExternalReport[]> {
    return this.http.get(`${this.apiDetailsUrl}${reportExternalKeys}`).pipe(
      map((response: any) => {
        const mappedResult = [];
        for (const item of <IExternalReportDTO[]>response) {
          mappedResult.push(new ExternalReport(item));
        }
        return mappedResult;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }
}
