import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { ConfigsService, ConfigTypes } from '../../../../../shared/configs/service/configs.service';
import { RulesUtilsService } from '../../../rules-utils.service';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
})
export class CreateTaskComponent implements OnInit {
  @Input() form: FormGroup;
  dataMapping: FormArray;
  contextMapping: FormArray;
  data: FormArray;
  taskTemplatesOptions$: Observable<any>;

  constructor(public util: RulesUtilsService, private configsService: ConfigsService) {}

  ngOnInit() {
    this.loadAndSortTaskTemplatesOptions();
    if (this.form) {
      this.contextMapping = this.form.get('contextMapping') as FormArray;
      this.dataMapping = this.form.get('dataMapping') as FormArray;
      this.data = this.form.get('data') as FormArray;
    }
  }

  private loadAndSortTaskTemplatesOptions() {
    const sortData = this.configsService.listByType(ConfigTypes.TASK_TEMPLATE);
    this.taskTemplatesOptions$ = this.configsService.sortTaskTemplates(sortData);
  }
}
