export enum MARKETPLACES {
  AMAZON_MARKETPLACE = 'AMAZON_MARKETPLACE',
  TOPHATTER_MARKETPLACE = 'TOPHATTER_MARKETPLACE',
  WALLMART_MARKETPLACE = 'WALLMART_MARKETPLACE',
  SHOPIFY_MARKETPLACE = 'SHOPIFY_MARKETPLACE',
  PAYONEER_MARKETPLACE = 'PAYONEER_MARKETPLACE',
  MORECOMMERCE_MARKETPLACE = 'MORECOMMERCE_MARKETPLACE',
  EBAY_MARKETPLACE = 'EBAY_MARKETPLACE',
  VOLUSION_MARKETPLACE = 'VOLUSION_MARKETPLACE',
  PAYPAL_MARKETPLACE = 'PAYPAL_MARKETPLACE',
  STRIPE_MARKETPLACE = 'STRIPE_MARKETPLACE',
  AUTHORIZE_MARKETPLACE = 'AUTHORIZE_MARKETPLACE',
  NEWEGG_MARKETPLACE = 'NEWEGG_MARKETPLACE',
  PINGPONG_MARKETPLACE = 'PINGPONG_MARKETPLACE',
}
