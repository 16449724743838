import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { first, map, mergeMap, skipWhile } from 'rxjs/operators';

import { selectSupplierDetails, selectSupplierMarketplaces } from '../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { AppState } from './../../../../store/app.reducers';
import { SupplierMarketplaceFundingService } from './../../../services/supplier-marketplace-funding.service';
import { Reserve } from './../model/reserve.model';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.scss'],
})
export class ReserveComponent extends SubscriberComponent implements OnInit {
  columnsToDisplay = ['marketplace', 'advanceRate', 'minimumReserveRate', 'optInStart', 'optInEnd', 'action'];
  dataSource: MatTableDataSource<any>; // = new MatTableDataSource<Reserve[]>();
  marketplaceKey: string;
  supplierKey: string;
  isEditable = true;
  isLoadingResult = false;

  private reserves: Array<Reserve> = new Array<Reserve>();

  constructor(
    private store: Store<AppState>,
    private fundingService: SupplierMarketplaceFundingService,
    private service: SupplierMarketplaceFundingService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetails) => {
        if (supplierDetails != null) {
          this.supplierKey = supplierDetails.supplierKey;
        }
      }),
    );

    this.store
      .select(selectSupplierMarketplaces)
      .pipe(
        skipWhile((marketplaces) => marketplaces == null),
        first(),
        mergeMap((marketplaces) => {
          const observables: Array<Observable<any>> = new Array<Observable<any>>();

          marketplaces.forEach((marketplace) => {
            if (this.fundingService != null) {
              observables.push(
                this.fundingService.fetchItems(this.supplierKey, marketplace.mpSupKey).pipe(
                  map((fundingDetails) => {
                    fundingDetails.marketplaceName = marketplace.marketplaceName;
                    fundingDetails.marketplaceKey = marketplace.mpSupKey;
                    return fundingDetails;
                  }),
                ),
              );
            }
          });
          return forkJoin(observables);
        }),
      )
      .subscribe((fundingDetailsArray) => {
        fundingDetailsArray.forEach((fundingDetails) => {
          const reserve = new Reserve();
          reserve.marketplaceName = fundingDetails.marketplaceName;
          reserve.marketplaceKey = fundingDetails.marketplaceKey;
          reserve.advanceRate = fundingDetails.advanceRate;
          reserve.minimumReserveRate = fundingDetails.minimumReservePct;
          reserve.optInStart = fundingDetails.lastOptInDate;
          reserve.optInEnd = fundingDetails.lastOptOutDate;
          this.reserves.push(reserve);
        });
        this.dataSource = new MatTableDataSource(this.reserves);
      });
  }

  editAction() {
    this.isEditable = !this.isEditable;
  }

  submit(reserve: Reserve) {
    const data: any = {};
    this.isLoadingResult = true;
    data.advanceRate = reserve.advanceRate;
    data.minimumReservePct = reserve.minimumReserveRate;
    this.subscriptions.push(
      this.service.saveMarketplaceFundingDetails(this.supplierKey, reserve.marketplaceKey, data).subscribe(
        () => {
          this.toastrService.success('Data saved succesfully');
        },
        (response) => {
          this.toastrService.error(response.error.message);
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }
}
