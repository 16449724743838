<div class="card-dialog">
  <div class="checkbox-group" [formGroup]="cardTypeForm" *ngIf="!isPhysical">
    <h1 mat-dialog-title>Choose new Marqeta card type</h1>

    <mat-radio-group class="radio-group" formControlName="type">
      <mat-radio-button class="radio-group__button" value="VIRTUAL">Virtual</mat-radio-button>
      <mat-radio-button class="radio-group__button" value="PHYSICAL">Physical</mat-radio-button>
    </mat-radio-group>

    <div class="button-row">
      <button
        class="button-row__button"
        type="submit"
        mat-raised-button
        color="accent"
        [disabled]="cardTypeForm.invalid"
        (click)="chooseCardType(cardTypeForm)"
      >
        Save
      </button>
      <button class="button-row__button" type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    </div>
  </div>

  <form *ngIf="isPhysical" [formGroup]="shippingAddressForm">
    <div *ngIf="isMarqetaActionMode">
      <h2 mat-dialog-title class="divider">Please, choose a transition reason</h2>
      <mat-form-field>
        <mat-select [placeholder]="'Choose a transition reason'" [formControl]="actionReason" required>
          <mat-option *ngFor="let reason of data.transitionReasons" [value]="reason.id">{{ reason.description }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <h2 mat-dialog-title class="divider">Shipping Address</h2>
    <div class="row">
      <mat-form-field class="col-sm-12 col-xs-12">
        <input matInput placeholder="Address Line 1" formControlName="address1" type="text" />
      </mat-form-field>

      <mat-form-field class="col-sm-12 col-xs-12">
        <input matInput placeholder="Address Line 2" formControlName="address2" type="text" />
      </mat-form-field>

      <mat-form-field class="col-sm-6 col-xs-12">
        <input matInput placeholder="City" formControlName="city" type="text" />
      </mat-form-field>

      <mat-form-field class="col-sm-6 col-xs-12">
        <input matInput placeholder="Zip" formControlName="zip" type="text" />
      </mat-form-field>

      <mat-form-field class="col-sm-12 col-xs-12">
        <mat-select matInput placeholder="Country" formControlName="country">
          <mat-option *ngFor="let country of countries | async" [value]="country.countryCode">
            {{ country.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-sm-12 col-xs-12" *ngIf="states && states.length">
        <mat-select matInput placeholder="State" formControlName="state">
          <mat-option *ngFor="let state of states" [value]="state.id">
            {{ state.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-checkbox [(ngModel)]="expediteCard" [ngModelOptions]="{ standalone: true }"> Expedite Shipping </mat-checkbox>

    <div class="button-row">
      <button
        class="button-row__button"
        type="submit"
        mat-raised-button
        color="accent"
        [disabled]="shippingAddressForm.invalid || (isMarqetaActionMode && actionReason.invalid)"
        (click)="requestNewCard(shippingAddressForm)"
      >
        Save
      </button>
      <button class="button-row__button" type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    </div>
  </form>
</div>
