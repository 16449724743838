<div class="field-wrapper" [class]="fieldClass" [ngClass]="{ 'has-icon': materialIcon, 'in-group-edit': inGroupEdit }">
  <!-- EDIT MODE -->
  <div *ngIf="inEditMode(); else presentationMode" class="edit-mode">
    <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>

    <mat-form-field>
      <mat-select
        #inlineEditControl
        matInput
        [formControl]="formControl"
        [placeholder]="label"
        [required]="isRequired"
        (blur)="markAsTouched()"
        (selectionChange)="onSelectChange()"
      >
        <mat-option *ngFor="let item of itemsValue" [value]="item.id" [attr.data-test]="'option' + item.label">{{ item.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="hasRequiredError()"> This field is <strong>required</strong> </mat-error>

    <div class="help-text-wrapper warning" *ngIf="helpText && !hasRequiredError()">
      {{ helpText }}
    </div>

    <div class="inline-buttons" *ngIf="isInlineEditOnly()">
      <button class="btn btn-xs btn-cancel" (click)="close($event)">Cancel <i class="material-icons">close</i></button>
      <button class="btn btn-xs btn-success btn-save" (click)="callSave(formControl.value)">Save <i class="material-icons">done</i></button>
    </div>
  </div>

  <ng-template #presentationMode>
    <div class="presentation-mode" [ngClass]="{ 'disable-inline': disableInline }">
      <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>
      <div class="fake-field">
        <div class="label">
          {{ label }}
          <mat-icon *ngIf="showCopy" ngxClipboard [cbContent]="value" class="btn-copy" matTooltip="Click to copy {{ label }}"
            >content_copy
          </mat-icon>
        </div>
        <div class="value" (click)="edit(formControl.value)" (focus)="edit(formControl.value)">
          {{ friendlyValue || formControl.value || defaultEmptyValue }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
