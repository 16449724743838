<section class="mb-4">
  <ng-container *ngIf="documentationReportTable; else noData">
    <p class="card-title reportTitle">{{ documentationReportTable.title }}</p>
    <div [@FadeOut]="{ value: 'in', params: { maxHeight: sectionHeight } }">
      <ng-container *ngIf="!documentationReportTable.error; else reportError">
        <ng-container *ngIf="reportExists; else noData">
          <app-simple-table [data]="documentationReportTable.reports"></app-simple-table>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</section>

<ng-template #noData>
  <app-no-data></app-no-data>
</ng-template>

<ng-template #reportError>
  <app-no-data [text]="errorText"></app-no-data>
</ng-template>
