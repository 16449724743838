import { Component, Input, OnInit } from '@angular/core';

import { FadeOut } from '../../../../../shared/animations/fade-out';
import { IBackgroundReportTable } from '../../../../model/background-report/background-report-table';

@Component({
  selector: 'app-background-report',
  templateUrl: './background-report.component.html',
  styleUrls: ['./background-report.component.scss'],
  animations: [FadeOut],
})
export class BackgroundReportComponent implements OnInit {
  @Input() report: IBackgroundReportTable;
  errorText = 'Sorry, we can not load the data';

  constructor() {}

  ngOnInit() {}
}
