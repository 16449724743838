import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IFilterDTO } from './models/filter-dto.interface';

@Injectable({
  providedIn: 'root',
})
export class FilteringPanelService {
  public filterValueSource = new Subject<IFilterDTO>();
}
