<ng-template #loadingSpinner>
  <app-loading-spinner></app-loading-spinner>
</ng-template>

<div id="serviceProductMatrix" *ngIf="response; else loadingSpinner">
  <h4 class="serviceProductMatrix__title">Product Offerings</h4>

  <app-product-instant-access *ngIf="response.instant_access" [supplierKey]="supplierKey" [response]="response">
  </app-product-instant-access>
  <app-product-frequent-earning-loading
    *ngIf="response.frequent_earning_loading"
    [supplierKey]="supplierKey"
    [response]="response"
  ></app-product-frequent-earning-loading>
  <app-sub-product-freemium-flex-participation
    *ngIf="response.instant_access && flexDto"
    [supplierKey]="supplierKey"
    [flexDto]="flexDto"
  ></app-sub-product-freemium-flex-participation>

  <app-product-instant-advance *ngIf="response.instant_advance" [supplierKey]="supplierKey" [response]="response">
  </app-product-instant-advance>
  <app-product-instant-payout *ngIf="response.instant_payouts" [supplierKey]="supplierKey" [response]="response">
  </app-product-instant-payout>
  <app-product-open-invoices *ngIf="response.open_invoices" [response]="response"></app-product-open-invoices>
  <app-product-weekly-payments *ngIf="response.weekly_schedule" [supplierKey]="supplierKey" [response]="response">
  </app-product-weekly-payments>
  <app-product-payment-gateways *ngIf="response.payment_gateway" [response]="response"></app-product-payment-gateways>
</div>
