import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { SubscriberComponent } from '../../../../../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from '../../../../../../../shared/confirm-dialog/confirm-dialog.component';
import { ErrorVisualizationService } from '../../../../../../../shared/services/error-visualization/error-visualization.service';
import { FlexParticipationService } from '../../service/flex-participation.service';
import { IFlexParticipationMarketplace } from '../../service/flex-participation-interfaces';
import { flexField, IFlexColumnDefinition } from '../flex-column-definition';

@Component({
  selector: 'app-freemium-marketplace-row',
  templateUrl: './freemium-marketplace-row.component.html',
  styleUrls: ['./freemium-marketplace-row.component.scss'],
})
export class FreemiumMarketplaceRowComponent extends SubscriberComponent implements OnInit {
  @Input() marketplace: IFlexParticipationMarketplace;

  public flexFormGroup: FormGroup;
  public marketplaceName: string;
  public columns: IFlexColumnDefinition[];

  constructor(
    fb: FormBuilder,
    public dialog: MatDialog,
    private flexParticipationService: FlexParticipationService,
    private errorVisualizationService: ErrorVisualizationService,
    private toastrService: ToastrService,
  ) {
    super();
    this.flexFormGroup = fb.group({
      freemiumFee: '',
      freemiumFeeRate: '',
      fundingRate: '',
      participationRate: '',
    });
  }

  ngOnInit() {
    this.marketplaceName = this.marketplace.marketplaceName;
    this.prepareColumns();
    this.flexFormGroup.patchValue(this.marketplace);
  }

  prepareColumns() {
    this.columns = [
      {
        name: flexField.participationRate,
        connectedField: flexField.fundingRate,
        label: 'Participation Rate',
        methodName: 'putFlexParticipation',
      },
      {
        name: flexField.fundingRate,
        connectedField: flexField.participationRate,
        label: 'Funding Rate',
        methodName: 'putFundingRate',
      },
      {
        name: flexField.freemiumFee,
        connectedField: flexField.freemiumFeeRate,
        label: 'Freemium Fee',
        methodName: 'putFlexFreemium',
      },
      {
        name: flexField.freemiumFeeRate,
        connectedField: flexField.freemiumFee,
        label: 'Freemium Fee Rate',
        methodName: 'putFlexFreemiumFeeRate',
      },
    ];
  }

  trySaveField(field: IFlexColumnDefinition, value: number) {
    const msg = `Are you sure you want to update ${field.label}?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.saveField(field, value);
        return;
      }
      this.rollback();
    });
  }

  rollback() {
    this.flexFormGroup.patchValue(this.marketplace);
  }

  saveField(field: IFlexColumnDefinition, value: number) {
    this.subscriptions.push(
      this.flexParticipationService[field.methodName](this.marketplace.mpSupKey, value).subscribe(
        (response) => {
          this.toastrService.success(response.message);
          this.flexFormGroup.patchValue(response);
          this.marketplace = {
            ...this.marketplace,
            ...response,
          };
        },
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Flex saving Error', errorObject: err });
        },
      ),
    );
  }
}
