import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { FeatureFlagsService } from './feature-flags.service';

@Injectable()
export class FeatureFlagGuard implements CanActivate, CanLoad {
  constructor(private featureFlagsService: FeatureFlagsService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkUrl(route);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkUrl(route);
  }

  private checkUrl(route: ActivatedRouteSnapshot | Route): Observable<boolean> {
    if (!route.data) {
      return of(true);
    }

    if (!route.data.enabledByFeatureFlag) {
      return of(true);
    }

    const enabled = this.featureFlagsService.isEnabled(route.data.enabledByFeatureFlag);

    if (!enabled) {
      this.router.navigate(['/404']);
    }

    return of(enabled);
  }
}
