<mat-card class="productOpenInvoices__matCard">
  <div class="productOpenInvoices__matCard__title">
    <h5>Open Invoices</h5>
    <div>
      <p [ngStyle]="{ color: productStatusColor }">{{ productStatus | uppercase }}</p>
    </div>
  </div>
  <mat-card-content>
    <mat-table *ngIf="dataSource.data.length" class="productOpenInvoices__matCard__table" [dataSource]="dataSource">
      <ng-container matColumnDef="marketplace">
        <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productOpenInvoices__matCard__table__cell">
          {{ element.marketplace }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="openInvoices">
        <mat-header-cell *matHeaderCellDef>Open Invoices</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productOpenInvoices__matCard__table__cell">
          {{ element.openInvoices }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoicedOrders">
        <mat-header-cell *matHeaderCellDef>Invoiced Orders</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productOpenInvoices__matCard__table__cell">
          {{ element.invoicedOrders }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <mat-header-cell *matHeaderCellDef>Enabled</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productOpenInvoices__matCard__table__cell">
          <!--          <mat-checkbox [(ngModel)]="element.enabled" (change)="activationProductRequest($event, i)"></mat-checkbox>-->
          <mat-slide-toggle [(ngModel)]="element.enabled" (change)="activationProductRequest($event, i)"></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="serviceColumns" class="productOpenInvoices__matCard__table__headerRow"></mat-header-row>
      <mat-row *matRowDef="let row; columns: serviceColumns" class="productOpenInvoices__matCard__table__row"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
