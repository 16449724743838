<p>{{ title }}</p>

<button *ngIf="gridOptions.treeData" mat-button (click)="expandAllRows()">
  <i class="material-icons">{{ expandedButtonIcon }}</i>
  {{ expandedButtonText }}
</button>

<div style="width: 99%; padding: 1vh; overflow: scroll">
  <ag-grid-angular class="ag-theme-balham" [gridOptions]="gridOptions" [domLayout]="domLayout" [sideBar]="sideBar"> </ag-grid-angular>
</div>
