import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { TOOLS_THEME } from '../../../../shared/editor/editor.enum';

@Component({
  selector: 'app-batch-supplier-email',
  templateUrl: './batch-supplier-email.component.html',
})
export class BatchSupplierEmailComponent implements OnInit {
  readonly TOOLS_THEME = TOOLS_THEME;
  public batchSupplierEmailForm: FormGroup;

  @Input() configValue: any;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.configValue) {
      this.createEditForm();
    } else {
      this.createNewForm();
    }
  }

  createNewForm() {
    this.batchSupplierEmailForm = this.formBuilder.group({
      template_name: '',
      description: '',
      subject: '',
      source: '',
      html: '',
      text: '',
      templateVariables: '',
    });
  }

  createEditForm() {
    this.batchSupplierEmailForm = this.formBuilder.group({
      template_name: this.configValue.template_name,
      description: this.configValue.description,
      subject: this.configValue.subject,
      source: this.configValue.source,
      html: this.configValue.html,
      text: this.configValue.text,
    });
  }
}
