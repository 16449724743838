<h1 mat-dialog-title>Confirm Submission to Bank</h1>
<div mat-dialog-content>
  <div class="summary">
    <div *ngIf="!isDebit" class="row">
      <div class="col-md-6">
        <div>Payment Total: {{ paymentTotal }}</div>
        <div>Sent to Bank: {{ numSentToBank }}</div>
      </div>
      <div class="col-md-6">
        <div>Approved: {{ numApproved }}</div>
        <div>Cancelled: {{ numCancelled }}</div>
        <div>Hold: {{ numHold }}</div>
      </div>
    </div>
    <div class="row" *ngIf="isDebit">
      <div class="col-md-12">
        <div>Payment Total: {{ paymentTotal }}</div>
        <div>Number of Payments: {{ numPayments }}</div>
      </div>
      <div class="col-md-12">
        <div>Sent to Bank: {{ numSentToBank }}</div>
      </div>
    </div>
    <div *ngIf="isDebit">
      <div *ngIf="showEFF">
        <mat-form-field (click)="picker.open()">
          <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<mat-form-field *ngIf="!isDebit" class="field-text full-width">
  <input matInput type="text" placeholder="Authy Token" [(ngModel)]="authyToken" />
</mat-form-field>
<div *ngIf="isDebit">
  <div>
    {{ confirmationCopyFactory() }}
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="submitOnClick()" [disabled]="invalid" [color]="'accent'" tabindex="2">Yes</button>
  <button mat-raised-button (click)="cancelOnClick()" [color]="'warn'" tabindex="-1">Cancel</button>
</div>
