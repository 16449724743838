import { autoserialize } from 'cerialize';

export class MarketplaceStatusModel {
  @autoserialize
  validOptInDate?: boolean;

  @autoserialize
  optInDate?: string;

  @autoserialize
  paused?: boolean;

  @autoserialize
  optOutDate?: string;

  @autoserialize
  rejected?: boolean;

  @autoserialize
  suspended?: boolean;

  @autoserialize
  hidden?: boolean;

  @autoserialize
  fundPartStatus?: any;

  constructor(
    validOptInDate: boolean,
    optInDate: string,
    paused: boolean,
    optOutDate: string,
    rejected: boolean,
    suspended: boolean,
    hidden: boolean,
    fundPartStatus: any,
  ) {
    this.validOptInDate = validOptInDate;
    this.optInDate = optInDate;
    this.paused = paused;
    this.optOutDate = optOutDate;
    this.rejected = rejected;
    this.suspended = suspended;
    this.hidden = hidden;
    this.fundPartStatus = fundPartStatus;
  }
}
