import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../config';
import { CliServiceInterface } from './cli-service-interface';

@Injectable()
export class CliService implements CliServiceInterface {
  apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.cliApiUrl;
  }

  addChargeback(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}chargeback_add`, data);
  }

  addReceivable(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}receivable_add`, data);
  }

  addWriteoff(data: any, supplierId: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}debt_writeoff/${supplierId}`, data);
  }

  addRecovery(data: any, supplierId: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}debt_recover/${supplierId}`, data);
  }

  addPayment(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}add_mp_payment`, data);
  }

  addFailedPayment(supPaymentKey: string, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}pmt_failed/${supPaymentKey}`;
    return this.httpClient.post(endpointUrl, data);
  }

  adjustFee(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}fee_adjust`, data);
  }

  cancelXact(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}cancel_xact`, data);
  }

  uploadCSV(data: any): Observable<any> {
    const formData = new FormData();

    Object.keys(data).map((key) => {
      if (key === 'file') {
        formData.append(key, data[key].files[0], data[key].files[0].name);
      } else {
        formData.append(key, data[key]);
      }
    });

    return this.httpClient.post(`${this.apiUrl}csv_upload`, formData);
  }
}
