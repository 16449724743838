import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { isUndefined } from 'util';

import { TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';

@Component({
  selector: 'app-add-chargeback',
  templateUrl: './add-chargeback.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddChargebackComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  actionTitle = 'Add chargeback';
  isLoan = false;
  readonly loanMessage = `Note!
  If you want to add the "Loan Refinance" chargeback then the external ID has to contain "LoanPayment".`;

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.supplierKey = data.supplierKey;

          this.marketplaces = data.marketplaces.map((element) => {
            return {
              label: element.externalSupplierId
                ? element.marketplaceName + ' (' + element.externalSupplierId + ')'
                : element.marketplaceName,
              id: element.marketplaceCode,
            };
          });

          const amazonArray = data.marketplaces.filter((marketplace) => {
            return marketplace.marketplaceName === 'Amazon Seller Central';
          });

          if (amazonArray.length) {
            this.loadStatements(this.supplierKey, amazonArray[0].mpSupKey);
          }
        }
      }),
    );
    super.ngOnInit();
  }

  createForm() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    this.supplierActionForm = this.formBuilder.group({
      marketplace_code: ['', Validators.required],
      chargeback_external_id: ['', Validators.required],
      statement_id: [''],
      post_date: [new Date(), Validators.required],
      chargeback_amount: ['', Validators.required],
      reason: ['', Validators.required],
      is_loan_refinance: [false],
      passed_to_supplier: [true],
    });

    this.subscriptions.push(
      this.supplierActionForm.get('is_loan_refinance').valueChanges.subscribe((isLoanRefinance) => {
        this.isLoan = isLoanRefinance;
        this.supplierActionForm.get('passed_to_supplier').setValue(!isLoanRefinance);
        if (isLoanRefinance) {
          this.addStringLoanPaymentToChargebackExternal();
          this.supplierActionForm.get('passed_to_supplier').disable();
        } else {
          this.supplierActionForm.get('passed_to_supplier').enable();
        }
      }),
    );
  }

  addStringLoanPaymentToChargebackExternal() {
    const current = this.supplierActionForm.get('chargeback_external_id').value;
    this.supplierActionForm.get('chargeback_external_id').setValue(this.addSubbstringIfNotExists(current, 'LoanPayment'));
  }

  addSubbstringIfNotExists(current: string, substring: string) {
    if (current.indexOf(substring) > -1) {
      return current;
    }
    return `${substring} ${current}`;
  }

  submit() {
    this.supplierActionForm.updateValueAndValidity();
    this.isConfirmed = true;
    this.isLoadingResult = true;
    const dataToSave = {
      supplier_key: this.supplierKey,
      ...this.supplierActionForm.value,
    };

    dataToSave['post_date'] = moment(dataToSave.post_date).format('YYYY-MM-DD');

    this.subscriptions.push(
      this.cliService.addChargeback(dataToSave).subscribe(
        (success) => {
          this.onSuccess('Chargeback added successfuly.');
        },
        (response) => {
          this.onError(response.error.message);
        },
      ),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
