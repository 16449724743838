import { FormControl } from '@angular/forms';

import { UwReviewModel } from './uw-review-model';

export class UwReviewForm {
  MARQETA_KYC_FULL_NAME = new FormControl();
  MARQETA_KYC_SSN = new FormControl();
  MARQETA_KYC_DOB = new FormControl();
  MARQETA_KYC_RESIDENTAL_ADDRESS = new FormControl();
  MARQETA_KYC_OFAC_CHECK = new FormControl();
  MARQETA_KYC_BUSINESS_OFAC_CHECK = new FormControl();
  MARQETA_KYC_BUSINESS_ADDRESS = new FormControl();
  MARQETA_KYC_LEGAL_ENTITY = new FormControl();
  MARQETA_KYC_TIN = new FormControl();

  constructor(uwReview: UwReviewModel) {
    this.MARQETA_KYC_FULL_NAME.setValue(uwReview.fullNameVerified);
    this.MARQETA_KYC_SSN.setValue(uwReview.ssnVerified);
    this.MARQETA_KYC_DOB.setValue(uwReview.dobVerified);
    this.MARQETA_KYC_RESIDENTAL_ADDRESS.setValue(uwReview.residentialAddressVerified);
    this.MARQETA_KYC_OFAC_CHECK.setValue(uwReview.ofacCheckCompleted);
    this.MARQETA_KYC_BUSINESS_OFAC_CHECK.setValue(uwReview.businessOfacCheckCompleted);
    this.MARQETA_KYC_BUSINESS_ADDRESS.setValue(uwReview.businessAddressVerified);
    this.MARQETA_KYC_LEGAL_ENTITY.setValue(uwReview.legalEntityVerified);
    this.MARQETA_KYC_TIN.setValue(uwReview.tinVerified);
  }
}
