import { INormalizedCriteria, ISearchRecord } from '../real-time-validation/real-time-validator.interfaces';
import { GetTextFromScore } from './get-text-from-score';

export const TakeCriteriaFromSearchRecord = (searchRecord: ISearchRecord): INormalizedCriteria => {
  return {
    BirthDate: GetTextFromScore(searchRecord.BirthDate),
    City: GetTextFromScore(searchRecord.City),
    FirstName: GetTextFromScore(searchRecord.FirstName),
    LastName: GetTextFromScore(searchRecord.LastName),
    SSN: GetTextFromScore(searchRecord.SSN),
    State: GetTextFromScore(searchRecord.State),
    StreetName: GetTextFromScore(searchRecord.StreetName),
    StreetNumber: GetTextFromScore(searchRecord.StreetNumber),
    ZipCode: GetTextFromScore(searchRecord.ZipCode),
  };
};
