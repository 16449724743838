<ng-template #loadingSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

<mat-card *ngIf="plaidData && plaidData.length" class="card">
  <table *ngIf="loaded; else loadingSpinner">
    <tbody>
      <ng-container *ngFor="let element of plaidData; let index = index; trackBy: trackBy">
        <table [ngClass]="{ 'no-border-bottom': index === plaidData.length - 1 }" class="sub-table">
          <thead [ngStyle]="{ visibility: index === 0 ? 'visible' : 'collapse' }">
            <tr>
              <th>Bank Account Match</th>
              <th>Institution</th>
              <th>Account Name</th>
              <th>Account Balance</th>
              <th>Receiving Gateway / Platform Payments</th>
              <th>Include in ACH Debit Contract?</th>
              <th>Request item update</th>
            </tr>
          </thead>
          <tr
            app-supplier-payment-plaid-information-row
            *ngFor="let subElement of element.accounts; let subIndex = index; trackBy: trackBy"
            [plaidRow]="subElement"
            [indexOfRow]="subIndex"
            [rowsCount]="element.accounts.length"
            [itemLoginRequired]="element.itemLoginRequired"
            [supplierKey]="supplierKey"
          ></tr>
        </table>
        <ng-container *ngIf="element.accounts.length > 0">
          <div>
            <div class="w-100 d-flex flex-wrap justify-content-end">
              <app-spinner-button
                [text]="'Download'"
                [customWidth]="'9rem'"
                [loading]="element.getPlaidReportUuidLoading"
                (onClick)="downloadPlaidReport(element)"
              >
              </app-spinner-button>
              <app-spinner-button
                [text]="'Run Plaid API'"
                [customWidth]="'9rem'"
                [loading]="element.runPlaidApiLoading"
                (onClick)="runPlaidApi(element)"
              >
              </app-spinner-button>
              <app-spinner-button
                [text]="'Refresh Balance'"
                [customWidth]="'9rem'"
                [loading]="element.refreshBalanceLoading"
                [disabled]="true"
                (onClick)="refreshBalanceClick(element)"
              >
              </app-spinner-button>
              <app-spinner-button [text]="'Refresh Auth'" [customWidth]="'9rem'" [disabled]="true" (onClick)="refreshAuthClick(element)">
              </app-spinner-button>
            </div>
          </div>
          <p class="text-right textColor">Date Last Requested: {{ element.accounts[0].lastRequested | date: 'MM/dd/yyyy' }}</p>
        </ng-container>
        <div *ngIf="plaidData.length > index + 1" class="divider"></div>
      </ng-container>
    </tbody>
  </table>
</mat-card>
