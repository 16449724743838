<app-stage-info></app-stage-info>
<app-layout-sidenav></app-layout-sidenav>

<section id="page-container" class="app-page-container">
  <div class="app-content-wrapper">
    <div class="app-content {{ customClasses }}">
      <div class="full-height">
        <router-outlet></router-outlet>
      </div>
    </div>

    <a class="mat-fab mat-accent d-lg-none" appToggleOffcanvasNav>
      <i class="material-icons">menu</i>
    </a>
  </div>
</section>

<app-search-overlay></app-search-overlay>
