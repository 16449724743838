import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

/* eslint-disable */
import { PeriodsCalculationsService } from '../../../../supplier-advances/pending-offer/pending-offer-calculator/service/periods-calculations.service';
/* eslint-enable */

@Component({
  selector: 'app-instant-advance',
  templateUrl: './instant-advance.component.html',
  styleUrls: ['./instant-advance.component.scss'],
})
export class InstantAdvanceComponent implements OnInit {
  @Input() dataProducts: any;

  public dataSourceIAdvance: MatTableDataSource<any>;

  public displayedInstantAdvanceColumns = ['netDeliveryAmount', 'advanceFeeReserve', 'averageFee', 'mpToPayDown'];
  public elementData: any[];

  constructor() {}

  ngOnInit() {
    const source = [];
    const advance = this.dataProducts.instant_advance;
    source.push({
      netDeliveryAmount: advance.net_delivery_amount,
      advanceFeeReserve: `${advance.advance_fee_reserve} %`,
      averageFee: `${advance.average_fee} %`,
      mpToPayDown: advance.mp_to_pay_down.join(', '),
    });

    this.dataSourceIAdvance = new MatTableDataSource(source);
    const periods = advance.periods;
    this.elementData = periods ? PeriodsCalculationsService.createPeriodSummary(periods) : [];
  }
}
