import { Component, Input, OnInit } from '@angular/core';

import { PeriodsCalculationsService } from '../../../pending-offer/pending-offer-calculator/service/periods-calculations.service';

@Component({
  selector: 'app-short-offer-summary',
  templateUrl: './short-offer-summary.component.html',
  styleUrls: ['./short-offer-summary.component.scss'],
})
export class ShortOfferSummaryComponent implements OnInit {
  @Input() advance: any;

  public elementData: any[];

  constructor() {}

  ngOnInit() {
    if (this.advance) {
      const periods = this.advance.periods;
      this.elementData = periods ? PeriodsCalculationsService.createPeriodSummary(periods) : [];
    }
  }
}
