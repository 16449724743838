import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { LayoutService } from '../layout/layout.service';

@Directive({ selector: '[appSearchOverlay]' })
export class SearchOverlayDirective implements AfterViewInit, OnDestroy {
  readonly subscription: Subscription;

  constructor(private readonly el: ElementRef, private readonly layoutService: LayoutService) {
    this.subscription = layoutService.searchOverlayState$.pipe(distinctUntilChanged()).subscribe((state: 'close' | 'open') => {
      this.updateSearchOverlay(state);
    });
  }

  $el: JQuery<any>;
  $body: JQuery<any>;
  $searchInput: JQuery<any>;
  $closeOverlayBtn: JQuery<any>;

  ngAfterViewInit(): void {
    this.$el = $(this.el.nativeElement);
    this.$body = $('#body');
    this.$searchInput = this.$el.find('#overlay-search-input');
    this.$closeOverlayBtn = this.$el.find('.overlay-close');

    const escapeKey = 27;
    this.$el.on('keyup', (e) => {
      if (e.keyCode === escapeKey) {
        this.layoutService.updateSearchOverlayState('close');
      }
    });

    this.$closeOverlayBtn.on('click', (e) => {
      this.layoutService.updateSearchOverlayState('close');
      e.preventDefault();
    });
  }

  openOverlay(): void {
    if (isNullOrUndefined(this.$body)) {
      this.$body = $('#body');
    }
    this.$body.addClass('overlay-active');
    if (this.$searchInput) {
      setTimeout(() => {
        this.$searchInput.focus();
      }, 100);
    }
  }

  closeOverlay(): void {
    this.$body.removeClass('overlay-active');
  }

  updateSearchOverlay(state: 'open' | 'close'): void {
    state === 'open' ? this.openOverlay() : this.closeOverlay();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
