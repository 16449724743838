<div class="col">
  <div class="row mt-4 mb-4 ml-2">
    <div>Purchase Earnings</div>
  </div>
  <div class="row mt-4 mb-4 ml-2 align-items-center">
    <div>Gross</div>
    <mat-slide-toggle [(ngModel)]="isLoadNetEarnings" class="ml-2 mr-2"></mat-slide-toggle>
    <div>Net</div>
    <button mat-raised-button color="accent" [disabled]="isLoadingResult" class="ml-2" (click)="saveEarningsType()">Save</button>
  </div>
</div>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="marketplace">
    <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.marketplaceName }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="feeScheduleType">
    <mat-header-cell *matHeaderCellDef>Fee Schedule Type</mat-header-cell>
    <mat-cell *matCellDef="let fee; let i = index">
      <div *ngIf="!fee.isTier && !fee.isEditable" [ngClass]="{ 'show-tiers-group': hasTiers(fee) }" (click)="toggleCollapse(i, fee)">
        {{ fee?.feeScheduleType }}
      </div>
      <mat-select *ngIf="!fee.isTier && fee.isEditable" placeholder="Type" aria-label="type" [(ngModel)]="fee.feeScheduleType">
        <mat-option *ngFor="let feeScheduleType of feeScheduleTypes" [value]="feeScheduleType" (click)="setFeeScheduleNames(fee)">
          {{ feeScheduleType }}
        </mat-option>
      </mat-select>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="feeScheduleName" disabled>
    <mat-header-cell *matHeaderCellDef>Fee Schedule Name</mat-header-cell>
    <mat-cell *matCellDef="let fee; let i = index">
      <div *ngIf="!fee.isTier && !fee.isEditable" [ngClass]="{ 'show-tiers-group': hasTiers(fee) }" (click)="toggleCollapse(i, fee)">
        {{ fee?.feeScheduleName }}
      </div>
      <mat-select *ngIf="!fee.isTier && fee.isEditable" placeholder="Name" aria-label="name" [(ngModel)]="fee.feeScheduleName">
        <mat-option
          *ngFor="let feeScheduleName of fee.feeScheduleNames"
          [value]="feeScheduleName"
          (click)="setFeeScheduleForSelectedName(feeScheduleName, fee)"
        >
          {{ feeScheduleName }}
        </mat-option>
      </mat-select>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="tierStart">
    <mat-header-cell *matHeaderCellDef>Tier Start</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.tierStart | currency: 'USD' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="tierEnd">
    <mat-header-cell *matHeaderCellDef>Tier End</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.tierEnd | currency: 'USD' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="feePercentage">
    <mat-header-cell *matHeaderCellDef>Fee Percentage</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.feePercentage / 100 | percent: '1.0-4' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="fixedFee">
    <mat-header-cell *matHeaderCellDef>Fixed Fee</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.fixedFee | currency: 'USD' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let fee">
      <button mat-icon-button *ngIf="!fee.isTier" (click)="toggleEditMode(fee)"><mat-icon>edit</mat-icon></button>
      <button
        mat-raised-button
        [disabled]="isLoadingResult"
        *ngIf="fee.isEditable"
        (click)="openConfirmationDialog(fee.marketplaceKey, fee.feeScheduleKey)"
        color="accent"
      >
        Save
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>
</mat-table>
