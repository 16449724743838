import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { isUndefined } from 'util';

import { MARKETPLACE } from '../../../shared/enums/marketplace.enum';
import { TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';
import { ReceivableService } from './service/receivable.service';

@Component({
  selector: 'app-add-receivable',
  templateUrl: './add-receivable.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddReceivableComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  actionTitle = 'Add Receivable';

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
    private receivableService: ReceivableService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  @HostListener('submit')
  ngOnInit() {
    this.selectSupplierDetails();
    super.ngOnInit();
  }

  selectSupplierDetails(): void {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!data) {
          return;
        }
        this.setData(data);
      }),
    );
  }

  setData(data: SupplierDetailsModel): void {
    if (!isUndefined(data)) {
      this.supplierKey = data.supplierKey;

      this.marketplaces = data.marketplaces.map((element) => {
        return {
          label: element.externalSupplierId ? element.marketplaceName + ' (' + element.externalSupplierId + ')' : element.marketplaceName,
          id: element.marketplaceCode,
        };
      });

      const amazonArray = data.marketplaces.filter((marketplace) => {
        return marketplace.marketplaceName === MARKETPLACE.AMAZON_SELLER_CENTRAL;
      });

      if (amazonArray.length) {
        this.loadStatements(this.supplierKey, amazonArray[0].mpSupKey);
      }
    }
  }

  createForm() {
    this.supplierActionForm = this.formBuilder.group({
      marketplace_code: ['', Validators.required],
      receivable_external_id: ['', Validators.required],
      statement_id: [''],
      post_date: [new Date(), Validators.required],
      purchase: [true],
      receivable_amount: ['', Validators.required],
      is_loan: [false],
      reason: ['', Validators.required],
    });
  }

  submit() {
    this.isConfirmed = true;
    this.isLoadingResult = true;
    const dataToSave = {
      supplier_key: this.supplierKey,
      ...this.supplierActionForm.value,
    };

    dataToSave['post_date'] = moment(dataToSave.post_date).format('YYYY-MM-DD');

    this.subscriptions.push(
      this.cliService.addReceivable(dataToSave).subscribe(
        () => {
          this.onSuccess('Receivable added successfuly.');
        },
        (response) => {
          this.onError(response.error.message);
        },
      ),
    );
    this.receivableService.blockButton();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
