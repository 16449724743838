import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-approve-payment',
  templateUrl: './approve-payment.component.html',
  styleUrls: ['./approve-payment.component.scss'],
})
export class ApprovePaymentComponent {
  // Fields
  public paymentTotal: string;
  public numSentToBank: string;
  public numPayments: string;
  public numApproved: string;
  public numCancelled: string;
  public numHold: string;
  public authyToken: string;
  public isDebit: boolean;
  public isSubmitted: boolean;
  public date: any;
  public showEFF: boolean;

  // Accessors
  public get invalid(): boolean {
    if (this.isDebit) {
      return false;
    }
    return isNullOrUndefined(this.authyToken) || this.authyToken.length === 0;
  }

  constructor(public dialogRef: MatDialogRef<ApprovePaymentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (!isNullOrUndefined(data)) {
      this.paymentTotal = data.paymentTotal;
      this.numSentToBank = data.numSentToBank;
      this.numPayments = data.numPayments;
      this.numApproved = data.numApproved;
      this.numCancelled = data.numCancelled;
      this.numHold = data.numHold;
      this.isDebit = data.isDebit;
      this.isSubmitted = data.isSubmitted;
      this.showEFF = data.showEFF;
    }
  }

  public confirmationCopyFactory() {
    return this.isSubmitted
      ? 'You have already submitted Payment Batch. Are You sure You want to resubmit ?'
      : 'Please click Yes if you would like to submit debit request to bank. If not, please click Cancel.';
  }

  public submitOnClick(): void {
    const data = !this.isDebit
      ? {
          token: this.authyToken,
        }
      : this.showEFF
      ? {
          achPostDate: moment(this.date).format('MM-DD-YYYY'),
        }
      : {};

    this.dialogRef.close(data);
  }

  public cancelOnClick(): void {
    this.dialogRef.close();
  }
}
