<div class="actions-list-pinned-item tl-item" data-test="crmNotePinned">
  <div class="pt-3 pr-3 pl-3 pb-2 tl-body">
    <div class="pull-right">
      <a (click)="onUnpinNote()" class="actions-list-pinned-item__starring" data-test="crmNotePinnedStar"
        ><i class="material-icons">star</i></a
      >
    </div>
    <div>
      <i class="material-icons mr-2" data-test="crmNotePinnedAuthor">person</i>{{ item.data.user.email }}
      <i class="material-icons mr-2 ml-4" data-test="crmNotePinnedUpdateTime">update</i
      >{{ item.updatedAt | amAdd: correctTime(item.updatedAt):'minutes' | date: 'short' }}
    </div>
    <div
      class="actions-list-pinned-item__content mt-3 tl-entry"
      [innerHTML]="item.data.body | safe"
      #noteBody
      data-test="crmNotePinnedContent"
    ></div>
  </div>
</div>
