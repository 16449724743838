import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { MarketplaceListModel } from '../../../marketplaces/model/marketplace-list-model';
import { MarketplaceModel } from '../../../marketplaces/model/marketplace-model';
import { MarketplacesAbstractService } from '../../../marketplaces/service/marketplaces-abstract.service';
import { ConfigEmailTypesNames } from '../../../shared/configs/service/configs.service';
import { LookupAbstractService } from '../../../shared/lookup-service/lookup-abstract.service';
import { OnboardingMpModel } from '../../../shared/onboarding/onboarding.model';
import { OnboardingService } from '../../../shared/onboarding/service/onboarding.service';
import { FetchUtilAttribute, selectFundingPeriods } from '../../../shared/utils/store/utils.actions';
import { AppState } from '../../../store/app.reducers';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-onboarding-mp-add-edit-dialog',
  templateUrl: './onboarding-mp-add-edit-dialog.component.html',
  styleUrls: ['./onboarding-mp-add-edit-dialog.component.scss'],
})
export class OnboardingMpAddEditDialogComponent extends SubscriberComponent implements OnInit {
  form: FormGroup;
  optionsMenu: any[] = [];
  selectedControl = new FormControl('');
  selectedValue;
  editMode = false;

  public fundingPeriods: any;
  marketplaceForm = new FormControl();
  marketplaces: MarketplaceListModel[];
  filteredMarketplaces: Observable<MarketplaceListModel[]>;
  feeScheduleForm = new FormControl();
  feeSchedules;
  filteredFeeSchedules: Observable<any>;

  onboardingTemplateCode;

  constructor(
    public dialogRef: MatDialogRef<OnboardingMpAddEditDialogComponent>,
    private formBuilder: FormBuilder,
    private onboardingService: OnboardingService,
    private marketplaceService: MarketplacesAbstractService,
    private lookupRepo: LookupAbstractService,
    @Inject(MAT_DIALOG_DATA) public data: OnboardingMpModel,
    private toastrService: ToastrService,
    private store: Store<AppState>,
  ) {
    super();
    if (data.marketplaceKey) {
      this.editMode = true;
    }

    this.onboardingTemplateCode = this.data.onboardingTemplateCode;

    this.store.dispatch(new FetchUtilAttribute({ attr: 'fundingPeriods' }));
    this.subscriptions.push(
      marketplaceService.getItems().subscribe((marketplaces) => {
        const name = (temp) => temp.marketplaceName.toLowerCase();
        this.marketplaces = marketplaces.sort((a, b) => name(a).localeCompare(name(b)));
        this.initMarketplacesAutocomplete();
      }),
    );
    this.subscriptions.push(
      this.lookupRepo.getFeeSchedules({ feeScheduleCategory: 'PURCHASE' }).subscribe((schedules) => {
        const name = (temp) => temp.feeScheduleName.toLowerCase();
        this.feeSchedules = schedules.sort((a, b) => name(a).localeCompare(name(b)));
        this.initFeeSchedulesAutocomplete();
      }),
    );
  }

  ngOnInit() {
    this.optionsMenu = ConfigEmailTypesNames.filter((item) => item.can_add_new);

    this.fundingPeriods = this.store.select(selectFundingPeriods);

    if (!isNullOrUndefined(this.data.marketplaceKey)) {
      this.form = this.formBuilder.group({
        onboardingTemplateCode: [this.data.onboardingTemplateCode, Validators.required],
        onboardingTemplateMpKey: [this.data.onboardingTemplateMpKey, Validators.required],
        marketplaceKey: [this.data.marketplaceKey, Validators.required],
        fundingPeriodGroupCode: [this.data.fundingPeriodGroupCode, Validators.required],
        feeScheduleKey: [this.data.feeScheduleKey, Validators.required],
        reservePercent: [this.data.reservePercent, Validators.required],
        minReservePercent: [this.data.minReservePercent, Validators.required],
        rcvPurchThreshold: [this.data.rcvPurchThreshold, Validators.required],
        hideSplashScreen: [this.data.hideSplashScreen, Validators.required],
        feeFromReserve: [this.data.feeFromReserve, Validators.required],
        monthlyMinFee: [this.data.monthlyMinFee, Validators.required],
      });
      this.marketplaceForm.setValue(this.data.marketplaceName);
      this.feeScheduleForm.setValue(this.data.feeScheduleName);
    } else {
      this.form = this.formBuilder.group({
        onboardingTemplateCode: [this.onboardingTemplateCode, Validators.required],
        marketplaceKey: ['', Validators.required],
        fundingPeriodGroupCode: ['', Validators.required],
        feeScheduleKey: ['', Validators.required],
        reservePercent: ['', Validators.required],
        minReservePercent: ['', Validators.required],
        rcvPurchThreshold: ['', Validators.required],
        hideSplashScreen: [false, Validators.required],
        feeFromReserve: [false, Validators.required],
        monthlyMinFee: ['', Validators.required],
      });
    }
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  save() {
    const request = this.prepareRequest();
    if (this.editMode) {
      this.subscriptions.push(
        this.onboardingService.setMp(this.data.onboardingTemplateMpKey, request).subscribe((response) => {
          this.closeDialog(response);
        }),
      );
    } else {
      this.subscriptions.push(
        this.onboardingService.addMp(request).subscribe((response) => {
          this.closeDialog(response);
        }),
      );
    }
  }

  prepareRequest() {
    const onboarding_template_code = this.onboardingTemplateCode;
    const marketplace_key = this.form.controls['marketplaceKey'].value;
    const funding_period_group_code = this.form.controls['fundingPeriodGroupCode'].value;
    const fee_schedule_key = this.form.controls['feeScheduleKey'].value;
    const reserve_percent = this.form.controls['reservePercent'].value;
    const min_reserve_percent = this.form.controls['minReservePercent'].value;
    const rcv_purch_threshold = this.form.controls['rcvPurchThreshold'].value;
    const hide_splash_screen = this.form.controls['hideSplashScreen'].value;
    const fee_from_reserve = this.form.controls['feeFromReserve'].value;
    const monthly_min_fee = this.form.controls['monthlyMinFee'].value;

    return new OnboardingMpModel(
      onboarding_template_code,
      marketplace_key,
      funding_period_group_code,
      fee_schedule_key,
      reserve_percent,
      min_reserve_percent,
      rcv_purch_threshold,
      hide_splash_screen,
      fee_from_reserve,
      monthly_min_fee,
    );
  }

  displayMarketplaceName(marketplace: MarketplaceModel): string {
    return !isNullOrUndefined(marketplace) ? (typeof marketplace === 'object' ? marketplace.marketplaceName : marketplace) : '';
  }

  setMarketplace($event) {
    this.form.controls['marketplaceKey'].setValue($event.option.value.marketplaceKey);
  }

  initMarketplacesAutocomplete() {
    this.filteredMarketplaces = this.marketplaceForm.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map((mp: any) => (mp && typeof mp === 'object' ? mp.marketplaceName : mp)),
      map((name) => {
        return name ? this.filterMarketplaces(name) : this.marketplaces.slice();
      }),
    );
  }

  filterMarketplaces(val: string): MarketplaceListModel[] {
    return !isUndefined(this.marketplaces)
      ? this.marketplaces.filter((mp) => {
          return mp.marketplaceName.toLowerCase().indexOf(val.toLowerCase()) === 0;
        })
      : [];
  }

  displayFeeScheduleName(schedule): string {
    return !isNullOrUndefined(schedule) ? (typeof schedule === 'object' ? schedule.feeScheduleName : schedule) : '';
  }

  setFeeSchedule($event) {
    this.form.controls['feeScheduleKey'].setValue($event.option.value.feeScheduleKey);
  }

  initFeeSchedulesAutocomplete() {
    this.filteredFeeSchedules = this.feeScheduleForm.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map((mp: any) => (mp && typeof mp === 'object' ? mp.feeScheduleName : mp)),
      map((name) => {
        return name ? this.filterFeeSchedules(name) : this.feeSchedules.slice();
      }),
    );
  }

  filterFeeSchedules(val: string) {
    return !isUndefined(this.feeSchedules)
      ? this.feeSchedules.filter((mp) => {
          return mp.feeScheduleName.toLowerCase().indexOf(val.toLowerCase()) === 0;
        })
      : [];
  }
}
