import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { FeatureFlagsGeneralGuard } from '../guards/feature-flags-general.guard';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';
import { RouteAccessResolver } from '../shared/utils/route-access-resolver';
import { BatchImportsComponent } from './batch-imports/batch-imports.component';
import { ConfigsComponent } from './configs/configs.component';
import { ContractsComponent } from './contracts/contracts.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { EmailSettingsListComponent } from './email-settings/email-settings-list/email-settings-list.component';
import { RulesAddEditComponent } from './event-rules/rules-add-edit/rules-add-edit.component';
import { RulesHistoryListComponent } from './event-rules/rules-history-list/rules-history-list.component';
import { RulesListComponent } from './event-rules/rules-list/rules-list.component';
import { EventRules2Component } from './event-rules-2/event-rules-2.component';
import { EventsComponent } from './events/events.component';
import { FeeSettingsComponent } from './fee-settings/fee-settings.component';
import { FeeSettingsAddComponent } from './fee-settings/fee-settings-add/fee-settings-add.component';
import { FeeSettingsEditComponent } from './fee-settings/fee-settings-edit/fee-settings-edit.component';
import { FeeSettingsListComponent } from './fee-settings/fee-settings-list/fee-settings-list.component';
import { GlobalSettingsComponent } from './global-settings/global-settings.component';
import { MonthEndCloseComponent } from './month-end-close/month-end-close.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingAddComponent } from './onboarding/onboarding-add/onboarding-add.component';
import { OnboardingListComponent } from './onboarding/onboarding-list/onboarding-list.component';
import { OnboardingMpListComponent } from './onboarding/onboarding-mp-list/onboarding-mp-list.component';
import { PermGroupsComponent } from './perm-groups/perm-groups.component';
import { PermGroupsAddComponent } from './perm-groups/perm-groups-add/perm-groups-add.component';
import { PermGroupsEditComponent } from './perm-groups/perm-groups-edit/perm-groups-edit.component';
import { PermGroupsListComponent } from './perm-groups/perm-groups-list/perm-groups-list.component';
import { UserAddComponent } from './users/user-add/user-add.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UsersComponent } from './users/users.component';

export const routes: Routes = [
  {
    path: 'manage',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
      },
    },
    children: [
      { path: '', redirectTo: 'users/list', pathMatch: 'full' },
      {
        path: 'users',
        component: UsersComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'new', component: UserAddComponent },
          { path: ':loginKey/edit', component: UserEditComponent },
          { path: 'list', component: UserListComponent },
        ],
      },
      {
        path: 'perm-groups',
        component: PermGroupsComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'new', component: PermGroupsAddComponent },
          { path: ':permGroupKey/edit', component: PermGroupsEditComponent },
          { path: 'list', component: PermGroupsListComponent },
        ],
      },
      { path: 'batch-imports', component: BatchImportsComponent },
      { path: 'global-settings', component: GlobalSettingsComponent },
      { path: 'configs', component: ConfigsComponent },
      {
        path: 'event-rules',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: RulesListComponent },
          { path: 'add', component: RulesAddEditComponent },
          { path: ':eventRuleId/edit', component: RulesAddEditComponent },
          { path: ':eventRuleId/history', component: RulesHistoryListComponent },
        ],
      },
      {
        path: 'event-rules-2',
        component: EventRules2Component,
      },
      {
        path: 'month-end-close',
        component: MonthEndCloseComponent,
      },
      {
        path: 'onboarding',
        component: OnboardingComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: OnboardingListComponent },
          { path: ':onboardingTemplateKey/marketplaces', component: OnboardingMpListComponent },
          { path: 'add', component: OnboardingAddComponent },
          { path: ':onboardingTemplateKey/edit', component: OnboardingAddComponent },
        ],
      },
      {
        path: 'email-config',
        component: EmailSettingsComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: EmailSettingsListComponent },
        ],
      },
      { path: 'events', component: EventsComponent },
      {
        path: 'fee-settings',
        component: FeeSettingsComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: FeeSettingsListComponent },
          { path: ':id/edit', component: FeeSettingsEditComponent },
          { path: 'add', component: FeeSettingsAddComponent },
        ],
      },
      {
        path: 'contracts',
        component: ContractsComponent,
      },
    ],
  },
];
