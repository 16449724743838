<div [formGroup]="taskTemplateForm">
  <mat-form-field class="full-width">
    <input matInput placeholder="Title" formControlName="title" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select placeholder="Status" formControlName="status">
      <mat-option *ngFor="let status of statuses" [value]="status.uuid">
        {{ status.name }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select placeholder="Priority" formControlName="priority">
      <mat-option *ngFor="let priority of priorities" [value]="priority.uuid">
        {{ priority.name }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input type="text" placeholder="Assignee" aria-label="Assignee" matInput [formControl]="assignee" [matAutocomplete]="autoAssignee" />

    <app-loading-spinner [size]="1" matSuffix *ngIf="assigneesPending"></app-loading-spinner>
    <mat-autocomplete #autoAssignee="matAutocomplete" [displayWith]="displayAssigneeFn" (optionSelected)="setAssignee($event)">
      <mat-option *ngFor="let option of filteredAssignees | async" [value]="option">
        <i class="material-icons option-icon">{{ option.type === 'group' ? 'people' : 'person' }}</i> {{ option.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <app-editor formControlName="body"> </app-editor>
</div>
