<div class="http-loader" *ngIf="isSpinnerVisible || forceShow">
  <div class="payability-loader">
    <div class="part part-1"></div>
    <div class="part part-2"></div>
    <div class="part part-3"></div>
    <div class="part part-4"></div>
    <div class="part part-5"></div>
    <div class="part part-6"></div>
    <div class="part part-7"></div>
    <div class="part part-8"></div>
    <div class="part part-9"></div>
  </div>
</div>
