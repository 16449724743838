import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-review-final-offer-dialog',
  templateUrl: './review-final-offer-dialog.component.html',
  styleUrls: ['./review-final-offer-dialog.component.scss'],
})
export class ReviewFinalOfferDialogComponent implements OnInit {
  public isOffer: boolean;
  public isIAccessEnabled: boolean;
  public isIAdvanceEnabled: boolean;
  public isIPayoutsEnabled: boolean;
  public dataProducts: any;
  public elementData: any[];

  constructor(public dialogRef: MatDialogRef<ReviewFinalOfferDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.isOffer = false;
    this.isIAccessEnabled = false;
    this.isIAdvanceEnabled = false;
    this.isIPayoutsEnabled = false;
    if (!this.data || !Object.keys(this.data.result).length) {
      return this.isOffer;
    }
    this.isOffer = true;
    this.setUpViewByProductType(this.data.type);
    this.dataProducts = this.data.result;
  }

  public onSave(): void {
    this.dialogRef.close('success');
  }

  private setUpViewByProductType(type: string): void {
    switch (type) {
      case 'instant_access': {
        this.isIAccessEnabled = true;
        break;
      }
      case 'instant_advance': {
        this.isIAdvanceEnabled = true;
        break;
      }
      case 'instant_payouts': {
        this.isIPayoutsEnabled = true;
        break;
      }
      case 'instant_access_advance': {
        this.isIAccessEnabled = true;
        this.isIAdvanceEnabled = true;
        break;
      }
    }
  }
}
