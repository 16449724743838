import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-auth-remove-access',
  templateUrl: './marketplace-auth-remove-access.component.html',
  styleUrls: ['./marketplace-auth-remove-access.component.less'],
})
export class MarketplaceAuthRemoveAccessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
