import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { capitalizeFirstLetter } from '../functions/capitalize-first-letter';
import { SubscriberComponent } from './../component-subscriber/subscriber.component';
import { FilterControlDatepickerComponent } from './controls/filter-control-datepicker/filter-control-datepicker.component';
import { FilterControlInputComponent } from './controls/filter-control-input/filter-control-input.component';
import { FilterControlSelectComponent } from './controls/filter-control-select/filter-control-select.component';
import { FilteringPanelService } from './services/filtering-panel-service/filtering-panel.service';

@Component({
  selector: 'app-filtering-panel-component',
  templateUrl: './filtering-panel.component.html',
  styleUrls: ['./filtering-panel.component.scss'],
})
export class FilteringPanelComponent extends SubscriberComponent implements OnInit {
  @Input() configuration = [];
  @Input() customTriggerSearchAction = false;
  @Output() searchValueEmitter = new EventEmitter();
  @ViewChild('controlsOutlet', { read: ViewContainerRef })
  public controlsOutletRef: ViewContainerRef;
  public isOptionsPanelOpen: boolean;
  public panelOpened = false;
  public showFilters = false;
  public showSearch = false;
  public showFiltersInitialOpened: boolean;
  public controlsConfiguration = {};
  public filtersSummary = '';
  private shouldClearValue = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    protected filteringPanelService: FilteringPanelService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filteringPanelService.filterValueSource.subscribe((data) => {
        const newConfiguration = {
          ...this.controlsConfiguration,
          [data['fieldName']]: data['value'],
        };

        if (JSON.stringify(newConfiguration) === JSON.stringify(this.controlsConfiguration)) {
          return;
        }
        this.controlsConfiguration = {
          ...newConfiguration,
        };
        this.cleanObject(this.controlsConfiguration);
        this.setFilterSummary();
        this.searchAction();
      }),
    );
    this.checkForInitialConfiguration();
  }

  openFilters() {
    this.panelOpened = !this.panelOpened;
    this.showFilters = true;
    this.showSearch = false;
    if (this.showFilters && !this.showFiltersInitialOpened) {
      this.showFiltersInitialOpened = true;
      this.changeDetectorRef.detectChanges();
      this.createControls();
    }
  }

  openSearch() {
    this.panelOpened = !this.panelOpened;
    this.showFilters = false;
    this.showSearch = true;
    this.showFiltersInitialOpened = false;
  }

  checkForInitialConfiguration() {
    if (!this.configuration) {
      if (this.customTriggerSearchAction) {
        this.searchAction();
      }
      return;
    }

    for (const control of this.configuration) {
      if (control.defaultValue) {
        this.controlsConfiguration = {
          ...this.controlsConfiguration,
          [control['fieldName']]: control['defaultValue'],
        };
      }
    }
    this.setFilterSummary();
    if (this.customTriggerSearchAction) {
      this.searchAction();
    }
  }

  createControls() {
    for (const control of this.configuration) {
      if (control.fieldType === 'input') {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FilterControlInputComponent);
        const componentRef = this.controlsOutletRef.createComponent(componentFactory);
        componentRef.instance.label = control.label;
        componentRef.instance.fieldName = control.fieldName;
        componentRef.instance.fieldType = control.fieldType;
        if (control.defaultValue) {
          componentRef.instance.value = control.defaultValue;
        }

        if (this.shouldClearValue) {
          componentRef.instance.value = null;
        }
      }
      if (control.fieldType === 'select') {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FilterControlSelectComponent);
        const componentRef = this.controlsOutletRef.createComponent(componentFactory);
        componentRef.instance.label = control.label;
        componentRef.instance.options = control.options;
        componentRef.instance.fieldName = control.fieldName;
        componentRef.instance.fieldType = control.fieldType;
        if (control.defaultValue) {
          componentRef.instance.value = control.defaultValue;
          this.controlsConfiguration = {
            ...this.controlsConfiguration,
            [control['fieldName']]: control['defaultValue'],
          };
        }

        if (this.shouldClearValue) {
          componentRef.instance.value = null;
        }
      }
      if (control.fieldType === 'datePicker') {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FilterControlDatepickerComponent);
        const componentRef = this.controlsOutletRef.createComponent(componentFactory);
        componentRef.instance.label = control.label;
        componentRef.instance.isConnecting = control.isConnecting;
        componentRef.instance.fieldName = control.fieldName;
        componentRef.instance.fieldType = control.fieldType;
        if (control.defaultValue) {
          componentRef.instance.value = control.defaultValue;
        }

        if (this.shouldClearValue) {
          componentRef.instance.value = null;
        }
      }
    }

    this.shouldClearValue = false;
  }

  cleanObject(obj: Object) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
  }

  setFilterSummary() {
    this.filtersSummary = '';
    Object.keys(this.controlsConfiguration).map((key, index) => {
      index === Object.keys(this.controlsConfiguration).length - 1
        ? (this.filtersSummary += capitalizeFirstLetter(key))
        : (this.filtersSummary += capitalizeFirstLetter(key) + ', ');
    });
  }

  searchAction() {
    this.searchValueEmitter.emit(this.controlsConfiguration);
  }

  clearFilters() {
    this.shouldClearValue = true;
    this.controlsOutletRef.clear();
    this.controlsConfiguration = {};
    this.createControls();
    this.searchAction();
  }
}
