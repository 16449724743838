import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-key-value-dictionary',
  templateUrl: './key-value-dictionary.component.html',
})
export class KeyValueDictionaryComponent implements OnInit {
  keyValueDictionaryForm: FormGroup;
  keyValues: FormArray = new FormArray([]);

  @Input() configValue: any;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.configValue) {
      this.createEditForm();
    } else {
      this.createNewForm();
    }

    this.keyValues = this.keyValueDictionaryForm.get('keyValues') as FormArray;
  }

  addKeyValue() {
    this.keyValues.push(this.createNewKeyValue());
  }

  removeKeyValue(i: number) {
    if (this.keyValues.length > 1) {
      this.keyValues.removeAt(i);
    }
  }

  private createEditForm() {
    for (const key in this.configValue) {
      if (key) {
        this.keyValues.push(this.createEditKeyValue(key, this.configValue[key]));
      }
    }

    this.keyValueDictionaryForm = this.formBuilder.group({
      keyValues: this.keyValues,
    });
  }

  private createEditKeyValue(key: any, value: any): FormGroup {
    return this.formBuilder.group({
      key,
      value,
    });
  }

  private createNewForm() {
    this.keyValueDictionaryForm = this.formBuilder.group({
      keyValues: this.formBuilder.array([this.createNewKeyValue()]),
    });
  }

  private createNewKeyValue(): FormGroup {
    return this.formBuilder.group({
      key: '',
      value: '',
    });
  }
}
