import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { isUndefined } from 'util';

import { TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';

@Component({
  selector: 'app-adjust-payability-fee',
  templateUrl: './adjust-payability-fee.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
})
export class AdjustPayabilityFeeComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  actionTitle = 'Adjust Payability Fee';

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.supplierKey = data.supplierKey;

          this.marketplaces = data.marketplaces.map((element) => {
            return {
              label: element.externalSupplierId
                ? element.marketplaceName + ' (' + element.externalSupplierId + ')'
                : element.marketplaceName,
              id: element.mpSupKey,
            };
          });
        }
      }),
    );
    super.ngOnInit();
  }

  createForm() {
    this.supplierActionForm = this.formBuilder.group({
      mp_sup_key: ['', Validators.required],
      mp_pmt_external_id: [''],
      post_date: [moment().subtract(1, 'days').toDate(), Validators.required],
      amount: ['', Validators.required],
      paid_in_full: [''],
      reason: ['', Validators.required],
    });
  }

  submit() {
    this.isConfirmed = true;
    this.isLoadingResult = true;

    const dataToSave = {
      supplier_key: this.supplierKey,
      ...this.supplierActionForm.value,
    };

    dataToSave['post_date'] = moment(dataToSave.post_date).format('YYYY-MM-DD');

    this.subscriptions.push(
      this.cliService.adjustFee(dataToSave).subscribe(
        () => {
          this.onSuccess('Payability fee adjusted successfuly.');
        },
        (response) => {
          this.onError(response.error.message);
        },
      ),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
