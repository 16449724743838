import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { IEnvironment } from '../../../../../environments/environment.interface';
import { AppConfigService } from '../../../../config';
import { SupplierChangePasswordDialogComponent } from '../../supplier-change-password-dialog/supplier-change-password-dialog.component';
import { AppsDocumentService } from './../../../../shared/apps-document/apps-document.service';
import { STATUS_APPROVED, STATUS_REJECTED, SupplierVerifyDocumentService } from './supplier-verify-document.service';

@Component({
  selector: 'app-supplier-verify-document-dialog',
  templateUrl: './supplier-verify-document-dialog.component.html',
  styleUrls: ['./supplier-verify-document-dialog.component.scss'],
})
export class SupplierVerifyDocumentDialogComponent implements OnInit {
  isJpg: any;
  isPdf: any;
  downloadFullUrl: any;
  public actionName: string;
  public actionData: any;
  public documentRejected;
  public documentApproved;
  public pdfLoaded = false;
  private gciId: IEnvironment['workflowGciId'];

  constructor(
    public dialogRef: MatDialogRef<SupplierChangePasswordDialogComponent>,
    private appConfig: AppConfigService,
    private verifyService: SupplierVerifyDocumentService,
    private appsDownloadService: AppsDocumentService,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.gciId = appConfig.env.workflowGciId;
  }

  ngOnInit() {
    this.actionName = this.data.actionName;
    this.actionData = this.data.actionData;
    this.appsDownloadService.getFileDownloadUrl(this.gciId, this.actionData.satisfied_by.file.file_id).subscribe((downloadUrl) => {
      this.dialogRef.updateSize('80vw', '80vh');
      this.downloadFullUrl = this.appsDownloadService.sanitize(downloadUrl);
      this.pdfLoaded = true;
    });

    if (this.getCurrentStatus() === STATUS_REJECTED) {
      this.documentRejected = true;
    } else if (this.getCurrentStatus() === STATUS_APPROVED) {
      this.documentApproved = true;
    }

    if (this.actionData.satisfied_by.file.file_name.includes('.pdf')) {
      this.isPdf = true;
    } else {
      this.isPdf = false;
    }
  }

  getCurrentStatus() {
    return this.actionData.satisfied_by.status;
  }

  rejectDocument(): void {
    this.changeStatus(STATUS_REJECTED);
  }

  approveDocument(): void {
    this.changeStatus(STATUS_APPROVED);
  }

  private changeStatus(newStatus: string) {
    this.verifyService.setFileStatus(this.actionData.satisfied_by, newStatus).subscribe((result) => {
      this.toastrService.success(`File status has been updated to ${newStatus}`);
      this.dialogRef.close();
    });
  }
}
