<section class="container-fluid">
  <article class="article">
    <div class="article-header">
      <h2 class="article-title">Marketplace Directory</h2>
      <div class="actions">
        <button [routerLink]="'/marketplaces/add'" mat-raised-button [color]="'accent'">
          <mat-icon>add</mat-icon>
          Add New
        </button>
      </div>
    </div>

    <div class="box box-default table-box table-striped table-responsive mdl-shadow--2dp">
      <table class="mdl-data-table">
        <thead>
          <tr>
            <th class="mdl-data-table__cell--non-numeric">Marketplace Name</th>
            <th class="mdl-data-table__cell--non-numeric">Code</th>
            <th class="mdl-data-table__cell--non-numeric">Fee Schedule Name</th>
            <th class="mdl-data-table__cell--non-numeric">Default Reserve Percent</th>
            <th class="mdl-data-table__cell--non-numeric">Allow Enroll</th>
            <th class="mdl-data-table__cell--non-numeric">Instant Access</th>
            <th class="mdl-data-table__cell--non-numeric">Marketplace Key</th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-marketplaceModel [ngForOf]="marketplaces | async">
            <tr>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="supplier-details-link" [routerLink]="['/marketplaces', marketplaceModel.getKey(), 'edit']">
                  {{ marketplaceModel.marketplaceName }}
                </a>
              </td>
              <td class="mdl-data-table__cell--non-numeric">{{ marketplaceModel.marketplaceCode }}</td>
              <td class="mdl-data-table__cell--non-numeric">{{ marketplaceModel.feeScheduleName }}</td>
              <td class="mdl-data-table__cell--non-numeric">{{ marketplaceModel.defaultReservePercent }}</td>
              <td class="mdl-data-table__cell--non-numeric">{{ marketplaceModel.allowEnroll }}</td>
              <td class="mdl-data-table__cell--non-numeric">{{ marketplaceModel.instantAccess }}</td>
              <td class="mdl-data-table__cell--non-numeric">{{ marketplaceModel.marketplaceKey }}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </article>
</section>
