import { Component, Input } from '@angular/core';

import { UWDailyDataSource } from '../uw-daily-data-source';

@Component({
  selector: 'app-underwrating-daily-table',
  templateUrl: './underwrating-daily-table.component.html',
  styleUrls: ['./underwrating-daily-table.component.scss'],
})
export class UnderwratingDailyTableComponent {
  @Input() collectedData: UWDailyDataSource | null;
  @Input() displayedColumns: string[] = [];
}
