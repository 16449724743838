import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { TOOLS_THEME } from '../../../shared/editor/editor.enum';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { EditorComponent } from './../../../shared/editor/editor.component';
import { noSpaceValidator } from './../../../shared/forms/validators/no-space.validator';
import { GlobalSettingsService } from './../service/global-settings.service';
import { KeyValueDictionaryComponent } from './../templates/key-value-dictionary/key-value-dictionary.component';
import { ListTemplateComponent } from './../templates/list-template/list-template.component';
import { NestedListTemplateComponent } from './../templates/nested-list-template/nested-list-template.component';
import { TaskTemplateComponent } from './../templates/task-template/task-template.component';

@Component({
  selector: 'app-new-setting',
  templateUrl: './new-setting.component.html',
})
export class NewSettingComponent extends SubscriberComponent implements OnInit {
  @ViewChild(ListTemplateComponent) listTemplate: ListTemplateComponent;
  @ViewChild(NestedListTemplateComponent) nestedListTemplate: NestedListTemplateComponent;
  @ViewChild(KeyValueDictionaryComponent) keyValueDictionary: KeyValueDictionaryComponent;
  @ViewChild(TaskTemplateComponent) taskTemplate: TaskTemplateComponent;
  @ViewChild(EditorComponent) appEditor: EditorComponent;

  readonly TOOLS_THEME = TOOLS_THEME;

  configType: string;
  configTypes = ['Dict', 'String', 'Number', 'List', 'Nested list', 'Html', 'Task template'];
  isLoadingResult = false;
  settingForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<NewSettingComponent>,
    private formBuilder: FormBuilder,
    private globalSettingsService: GlobalSettingsService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  setConfigType(configType: string) {
    this.configType = configType;
  }

  submit() {
    this.isLoadingResult = true;
    this.fixEditorValue();

    const configType = this.settingForm.get('config_type');

    configType.setValue(configType.value.toLowerCase().replace(/\s/g, '_'));

    if (configType.value === 'batch_supplier_email') {
      configType.setValue('email_frontend_batch_config');
    } else if (configType.value === 'frontend_email_config') {
      configType.setValue('email_frontend_config');
    }

    const dataToSave = {
      ...this.settingForm.value,
    };

    switch (this.configType) {
      case 'Number':
        dataToSave.config_value = +this.settingForm.get('config_value').value;
        break;
      case 'Dict':
        const keyValues = this.keyValueDictionary.keyValueDictionaryForm.get('keyValues').value;
        dataToSave.config_value = this.convertKeyValuesToObject(keyValues);
        break;
      case 'Task template':
        dataToSave.config_value = this.mapTaskTemplate(this.taskTemplate.taskTemplateForm.value);
        break;
      // case 'Email base template':
      //   dataToSave.config_value = { ...this.emailBaseTemplate.emailBaseTemplateForm.value };
      //   break;
      // case 'Email frontend template':
      //   dataToSave.config_value = { ...this.emailFrontendTemplate.emailFrontendTemplateForm.value };
      //   break;
      // case 'Frontend email config':
      //   dataToSave.config_value = { ...this.frontendEmailConfig.frontendEmailConfigForm.value };
      //   break;
      // case 'Batch supplier email':
      //   dataToSave.config_value = { ...this.batchSupplierEmail.batchSupplierEmailForm.value };
      //   dataToSave.useTemplates = true;
      //   break;
      case 'List':
        dataToSave.config_value = this.listTemplate.listTemplateForm.get('listElements').value;
        break;
      case 'Nested list':
        dataToSave.config_value = this.nestedListTemplate.nestedListTemplateForm.get('listElements').value;
        break;
      // case 'Email config':
      //   dataToSave.config_value = { ...this.emailConfig.emailConfigForm.value };
      //   break;
      default:
        break;
    }

    this.subscriptions.push(
      this.globalSettingsService.add(dataToSave).subscribe(
        () => {
          this.toastrService.success('Global setting successfully added');
          this.closeDialog(true);
        },
        (error) => {
          this.toastrService.error(error.error.meta.errors[0].message);
          this.closeDialog(false);
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }

  private convertKeyValuesToObject(keyValues: any[]): Object {
    const object: Object = {};

    keyValues.forEach((keyValue) => {
      object[keyValue.key] = keyValue.value;
    });

    return object;
  }

  private createForm() {
    this.settingForm = this.formBuilder.group({
      config_key: ['', [Validators.required, noSpaceValidator]],
      config_type: ['', Validators.required],
      config_value: [''],
    });
  }

  mapTaskTemplate(templateRawData) {
    const templateData = { ...templateRawData };
    templateData.assignee = {
      name: templateData['assigneeName'],
      email: templateData['assigneeEmail'],
      type: templateData['assigneeType'],
      uuid: templateData['assigneeUuid'],
    };

    delete templateData['assigneeName'];
    delete templateData['assigneeEmail'];
    delete templateData['assigneeType'];
    delete templateData['assigneeUuid'];
    return templateData;
  }

  private fixEditorValue(): void {
    if (!this.appEditor) {
      return;
    }
    this.settingForm.patchValue(
      { config_value: this.appEditor.getRawContent() },
      {
        emitEvent: false,
      },
    );
  }
}
