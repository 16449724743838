import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-email-edit-variables',
  templateUrl: './email-edit-variables.component.html',
  styleUrls: ['./email-edit-variables.component.scss'],
})
export class EmailEditVariablesComponent implements OnInit {
  @Input()
  variables: any;

  @Input()
  title: any;

  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  pasteInEditor(value: string) {
    this.change.emit(value);
  }
}
