import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { AppState } from '../../../store/app.reducers';
import { LayoutState } from '../store/layout.reducers';

@Component({
  selector: 'app-fab-holder-nav',
  templateUrl: './fab-holder-nav.component.html',
  styleUrls: ['./fab-holder-nav.component.scss'],
})
export class FabHolderNavComponent implements OnInit {
  public layoutState$: Observable<LayoutState>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.layoutState$ = this.store.select('layout').pipe(share());
  }
}
