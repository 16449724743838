import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorComponent as TinyEditorComponent } from '@tinymce/tinymce-angular';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { SubscriberComponent } from '../component-subscriber/subscriber.component';
import { PybDocumentsService } from './../pyb-documents/pyb-documents.service';
import defaultConfig from './config/default.config';
import emailConfig from './config/email.config';
import { TOOLS_THEME } from './editor.enum';
import { PybUploadResponseFile } from './pyb-upload-response.interface';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EditorComponent),
  multi: true,
};

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR],
})
export class EditorComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  @ViewChild(TinyEditorComponent) editor: TinyEditorComponent;
  @ViewChild('overlay') overlay: ElementRef;
  @Input() toolsTheme: TOOLS_THEME = TOOLS_THEME.DEFAULT;
  @Input() format: 'raw' | 'text' = 'raw';

  config: Record<string, any> | undefined = defaultConfig;
  value = new FormControl('');
  editorLoaded = false;

  onChange = (text) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  loader = false;

  private imgTodoCount = 0;

  constructor(private pybDocumentsService: PybDocumentsService) {
    super();
  }

  ngOnInit(): void {
    this.setTheme();
  }

  ngAfterViewInit(): void {
    this.listenEditorActions();
    this.detectChanges();
    this.dragAndDropFile();
  }

  writeValue(value: string): void {
    this.correctImagesAndSetContent(value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (this.touched) {
      return;
    }

    this.onTouched();
    this.touched = true;
  }

  appendRawValue(value: string, format = this.format): void {
    this.editor.editor.setContent(this.editor.editor.getContent({ format: this.format }) + value, { format });
  }

  getRawContent(): string {
    return this.editor.editor.getContent({ format: 'raw' });
  }

  private setTheme(): void {
    switch (this.toolsTheme) {
      case TOOLS_THEME.EMAIL:
        this.config = { ...emailConfig };
        break;
    }
  }

  private detectChanges(): void {
    this.subscriptions.push(
      this.value.valueChanges.subscribe((value) => {
        this.editor && this.editor.editor && this.onChange(this.editor.editor.getContent({ format: this.format }));
      }),
    );
  }

  private listenEditorActions(): void {
    this.subscriptions.push(
      this.editor.onInit.subscribe(() => {
        this.editorLoaded = true;
      }),
    );

    this.subscriptions.push(
      this.editor.onPaste.subscribe(({ editor, event }) => {
        const textContent = event.clipboardData.getData('text');
        const fileContent = event.clipboardData.files;

        if (textContent && !fileContent.length) {
          return;
        }

        // image
        event.preventDefault();
        this.doUploadFile([fileContent[0]]);
      }),
    );

    this.subscriptions.push(
      this.editor.onKeyUp.subscribe(({ editor, event }) => {
        this.removeImage(editor, event);
      }),
    );
  }

  private removeImage(editor, event): void {
    if (event.keyCode !== 8 && event.keyCode !== 46) {
      // Backspace || Delete
      return;
    }

    const node = editor.selection.getNode();
    if (!node.matches('[pyb-b2b-preview]') && !node.matches('.file-download')) {
      // check that it is our pyb-document
      return;
    }

    if (node.matches('[pyb-b2b-preview]')) {
      node.remove();
    } else {
      node.parentNode.remove();
    }
    this.appendRawValue('', 'raw');
  }

  private dragAndDropFile(): void {
    this.subscriptions.push(
      this.editor.onDrop.subscribe(async (e: { editor; event }) => {
        const event = e.event;

        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();

        this.doUploadFile(event.dataTransfer.files);
      }),
    );
  }

  private doUploadFile(inputFiles: File[]): void {
    this.addLoader();

    const files: File[] = [...inputFiles];
    this.subscriptions.push(
      this.uploadFile(files)
        .pipe(take(1))
        .subscribe(
          (resp) => {
            this.insertContent(resp);
            this.removeLoaders();
          },
          () => {
            this.removeLoaders();
          },
        ),
    );
  }

  private uploadFile(files: File[]): Observable<any> {
    return this.pybDocumentsService.uploadFile([{ files } as any]).pipe(
      map((resp) => (resp ? resp[0] : null)),
      switchMap((resp) => this.fetchThumbnail(resp)),
    );
  }

  private fetchThumbnail(file: PybUploadResponseFile): Observable<{ file: PybUploadResponseFile; resp: Blob }> {
    return this.pybDocumentsService.downloadThumbnail(file.thumbnail?.id, false).pipe(
      map((resp) => {
        return {
          file,
          resp,
        };
      }),
    );
  }

  private insertContent(file: { file: PybUploadResponseFile; resp: Blob }): void {
    const blob = window.URL.createObjectURL(file.resp);
    const template = `
    <span pyb-b2b-preview class="is-locked" uuid="${file.file.id}" document-id="${file.file.id}" filename="${file.file.name}" thumbnail-id="${file.file.thumbnail?.id}">
      <a href="#" class="file-download is-locked" style="display: inline-block; margin: 5px;">
        <img src="${blob}" width="auto" height="auto">
      </a>
    </span> `;
    this.value.setValue(this.value.value + template);
  }

  private addLoader(): void {
    this.loader = true;
    this.editor.editor.getBody().setAttribute('contenteditable', false);
  }

  private removeLoaders(): void {
    this.loader = false;
    this.editor.editor.getBody().setAttribute('contenteditable', true);
  }

  private correctImagesAndSetContent(text: string): void {
    if (!text) {
      this.value.setValue(text);
      return;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const thumbnailObjs = doc.querySelectorAll('span[thumbnail-id]');

    this.imgTodoCount = thumbnailObjs.length;
    if (this.imgTodoCount) {
      thumbnailObjs.forEach((obj) => {
        this.convertImagesToBlobFormat(doc, obj);
      });
    } else {
      this.value.setValue(doc.body.innerHTML, { emitEvent: false });
    }
  }

  private convertImagesToBlobFormat(doc: Document, obj: Element): void {
    const thumbId = obj.getAttribute('thumbnail-id');
    const imgObj = obj.querySelector('img');

    this.subscriptions.push(
      this.pybDocumentsService
        .downloadThumbnail(thumbId, false)
        .pipe(take(1))
        .subscribe(
          (resp) => {
            const blob = window.URL.createObjectURL(resp);
            imgObj.src = blob;
            this.setValueIfImagesRdy(doc);
          },
          () => this.setValueIfImagesRdy(doc),
        ),
    );
  }

  private setValueIfImagesRdy(doc: Document): void {
    if (--this.imgTodoCount <= 0) {
      this.value.setValue(doc.body.innerHTML, { emitEvent: false });
    }
  }
}
