import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-auth-code-get',
  templateUrl: './marketplace-auth-code-get.component.html',
  styleUrls: ['./marketplace-auth-code-get.component.less'],
})
export class MarketplaceAuthCodeGetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
