<div class="field-wrapper" [class]="fieldClass" [ngClass]="{ 'has-icon': materialIcon, 'in-group-edit': inGroupEdit }">
  <!-- EDIT MODE -->
  <div *ngIf="inEditMode(); else presentationMode" class="edit-mode">
    <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>

    <mat-form-field>
      <textarea
        matInput
        [formControl]="formGroup.get(formControlName)"
        #inlineEditControl
        [name]="value"
        [(ngModel)]="value"
        [type]="type"
        [required]="isRequired"
        [placeholder]="label"
        (blur)="markAsTouched()"
      >
      </textarea>
    </mat-form-field>

    <mat-error *ngIf="hasRequiredError()"> This field is <strong>required</strong> </mat-error>

    <div class="help-text-wrapper warning" *ngIf="helpText && !hasRequiredError()">
      {{ helpText }}
    </div>

    <div class="inline-buttons" *ngIf="isInlineEditOnly()">
      <button class="btn btn-xs btn-cancel" (click)="close($event)">Cancel <i class="material-icons">close</i></button>
      <button class="btn btn-xs btn-success btn-save" (click)="callSave(value)">Save <i class="material-icons">done</i></button>
    </div>
  </div>

  <ng-template #presentationMode>
    <div class="presentation-mode" [ngClass]="{ 'disable-inline': disableInline }">
      <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>
      <div class="fake-field">
        <div class="label">
          {{ label }}
          <mat-icon *ngIf="showCopy" ngxClipboard [cbContent]="value" class="btn-copy" matTooltip="Click to copy {{ label }}"
            >content_copy</mat-icon
          >
        </div>
        <div class="value" (click)="edit(value)" (focus)="edit(value)">{{ value || defaultEmptyValue }}</div>
      </div>
    </div>
  </ng-template>
</div>
