<div class="card" *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
  <div class="card-content">
    <span class="card-title">Underwriting Files and Images</span>
    <div class="mb-2">
      Status:
      <span *ngIf="!amazonFilesDecision" class="text-success"> Valid </span>
      <span *ngIf="amazonFilesDecision" class="text-danger"> Needs review </span>
    </div>
    <ng-template #noUploadsYet>
      <h5 class="text-muted">No uploads yet</h5>
    </ng-template>

    <ng-template #loadingCard>
      <div class="card-content">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    </ng-template>

    <div *ngIf="underwritingImages; else loadingCard; let actionsWithFiles">
      <div *ngIf="actionsWithFiles.length; else noUploadsYet">
        <ul class="uw-upload-list list-unstyled" *ngFor="let action of actionsWithFiles">
          <li>
            <table class="table table-condensed">
              <tbody class="borderless">
                <tr>
                  <td class="middle center" rowspan="2" style="width: 60%">
                    <img
                      class="preview-img"
                      *ngIf="action.satisfied_by.file.file_url; else imageError"
                      [src]="action.satisfied_by.file.file_url"
                      (click)="openVerifyDocumentDialog(action, docTypeNameByKey(action.satisfied_by.file.compliance_doctype_key))"
                    />
                    <ng-template #imageError>
                      <span
                        class="preview-img image-error"
                        (click)="openVerifyDocumentDialog(action, docTypeNameByKey(action.satisfied_by.file.compliance_doctype_key))"
                        >Error during fetching an image</span
                      >
                    </ng-template>
                  </td>
                  <td class="middle">
                    <h6>
                      <span [ngClass]="{ 'text-success': action.satisfied_by.status == 'approved' }">{{
                        action.satisfied_by.status | capitalize
                      }}</span>
                      <span *ngIf="action.satisfied_by.status == 'rejected'" class="badge badge-pill badge-warning">!</span>
                      <span *ngIf="action.satisfied_by.status == 'pending'" class="badge badge-pill badge-info">?</span>
                    </h6>
                    <div class="form-group">
                      <button
                        *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]"
                        (click)="openVerifyDocumentDialog(action, docTypeNameByKey(action.satisfied_by.file.compliance_doctype_key))"
                        mat-raised-button
                        class="btn-w-md"
                      >
                        Verify
                      </button>
                      <div class="divider divider-sm"></div>
                    </div>
                    <a
                      class="download-link pull-left"
                      href="#"
                      appAppsDocumentDownload
                      [fileUuid]="action.satisfied_by.file.file_id"
                      [fileName]="action.satisfied_by.file.file_name"
                      >Download image <i class="material-icons">file_download</i></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
