import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../../config';

@Injectable()
export class UccService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers/';
  }

  getPayabilityUccList(supplierKey: string): Observable<any> {
    const params = new HttpParams().set('limit', '9999');
    const endpointUrl = this.apiUrl + `${supplierKey}/ucc/filing`;
    return this.httpClient.get<any[]>(endpointUrl, { params });
  }

  getPayabilityUcc(supplierKey: string, uccKey: string): Observable<any> {
    const endpointUrl = this.apiUrl + `${supplierKey}/ucc/filing/${uccKey}`;
    return this.httpClient.get<any[]>(endpointUrl);
  }

  getTransUnionUcc(supplierKey: string, uccKey: string): Observable<any> {
    const endpointUrl = this.apiUrl + `${supplierKey}/ucc/data/${uccKey}`;
    return this.httpClient.get<any[]>(endpointUrl);
  }

  getTransunionUccList(supplierKey: string): Observable<any> {
    const params = new HttpParams().set('limit', '9999');
    const endpointUrl = this.apiUrl + `${supplierKey}/ucc/data`;
    return this.httpClient.get<any[]>(endpointUrl, { params });
  }

  postUcc(supplierKey: string, body: object): Observable<any> {
    const endpointUrl = this.apiUrl + `${supplierKey}/ucc/filing`;
    return this.httpClient.post(endpointUrl, body);
  }

  updateUcc(supplierKey: string, uccKey: string, body: object): Observable<any> {
    const endpointUrl = this.apiUrl + `${supplierKey}/ucc/filing/${uccKey}`;
    return this.httpClient.put(endpointUrl, body);
  }

  deleteUcc(supplierKey: string, uccKey: string, body: object): Observable<any> {
    const endpointUrl = this.apiUrl + `${supplierKey}/ucc/filing/${uccKey}`;
    return this.httpClient.delete(endpointUrl, body);
  }
}
