<div class="documentationContainer">
  <app-documentation-card
    [availableSections]="availableSections$ | async"
    [allReportsMap]="reportData$ | async"
    [reportTitle]="ownerName$ | async"
    [supplierKey]="supplierKey$ | async"
    [owner]="owner$ | async"
    [requestsLoading]="requestsLoading"
    (filterOptionsChanged)="onFilterOptionsChanged($event)"
  >
  </app-documentation-card>
</div>
