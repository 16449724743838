import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../../shared/shared.module';
import { AdvanceOfferSummaryComponent } from './pending-offer-progressive-details/instant-advance-underwriting/advance-offer-summary/advance-offer-summary.component';
import { FeeCalculatorComponent } from './pending-offer-progressive-details/instant-advance-underwriting/fee-calculator/fee-calculator.component';
import { FeeCalculatorRowComponent } from './pending-offer-progressive-details/instant-advance-underwriting/fee-calculator/fee-calculator-row/fee-calculator-row.component';
import { FeeSummaryComponent } from './pending-offer-progressive-details/instant-advance-underwriting/fee-summary/fee-summary.component';
import { InstantAdvanceUnderwritingComponent } from './pending-offer-progressive-details/instant-advance-underwriting/instant-advance-underwriting.component';
import { MachineLearningSummaryComponent } from './pending-offer-progressive-details/instant-advance-underwriting/machine-learning-summary/machine-learning-summary.component';
import { AdditinalManualAdvanceOfferInputsComponent } from './pending-offer-progressive-details/instant-advance-underwriting/manual-advance-offer-inputs/additinal-manual-advance-offer-inputs/additinal-manual-advance-offer-inputs.component';
/* eslint-disable */
import { ManualAdvanceOfferInputsComponent } from './pending-offer-progressive-details/instant-advance-underwriting/manual-advance-offer-inputs/manual-advance-offer-inputs.component';
import { PendingOfferRiskRatingService } from './service/pending-offer-risk-rating.service';
/* eslint-enable */

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    InstantAdvanceUnderwritingComponent,
    MachineLearningSummaryComponent,
    ManualAdvanceOfferInputsComponent,
    AdvanceOfferSummaryComponent,
    FeeSummaryComponent,
    FeeCalculatorComponent,
    FeeCalculatorRowComponent,
    AdditinalManualAdvanceOfferInputsComponent,
  ],
  exports: [FeeCalculatorComponent, FeeSummaryComponent, ManualAdvanceOfferInputsComponent],
  providers: [PendingOfferRiskRatingService],
})
export class PendingOfferCalculatorModule {}
