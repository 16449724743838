import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { BaseSupplierAction } from '../../../suppliers/supplier/base-supplier-action/base-supplier-action';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { GlobalSettingsService } from './../service/global-settings.service';

@Component({
  selector: 'app-delete-setting',
  templateUrl: './delete-setting.component.html',
})
export class DeleteSettingComponent extends SubscriberComponent implements OnInit {
  confirmationMessage = 'Are you sure you want to delete item? This can break functionality in other places.';
  isLoadingResult = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BaseSupplierAction>,
    private globalSettingsService: GlobalSettingsService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {}

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  submit() {
    this.isLoadingResult = true;

    this.subscriptions.push(
      this.globalSettingsService.delete(this.data.config_key).subscribe(
        () => {
          this.toastrService.success('Global setting successfully deleted');
          this.closeDialog(true);
        },
        (error) => {
          this.toastrService.error(error.error.meta.errors[0].message);
          this.closeDialog(false);
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }
}
