import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataTableQueryParams } from '../data-table/data-table.common';
import { SubscriberComponent } from './../component-subscriber/subscriber.component';
import { PaginationConfigDTO } from './pagination-config-dto';
import { IPaginationConfiguration } from './pagination-configuration';
import { IPaginationData } from './pagination-data';

const DEFAULT_MAX_PAGES = 10;
const DEFAULT_PER_PAGE_OPTIONS = [5, 10, 20, 50];

@Component({
  selector: 'app-pyb-pagination',
  templateUrl: './pyb-pagination.component.html',
  styleUrls: ['./pyb-pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PybPaginationComponent extends SubscriberComponent implements OnInit {
  @Input() configuration?: IPaginationConfiguration;
  @Input() set paginationData(value: IPaginationData) {
    if (value) {
      if (value.resetPageCounter) {
        this.currentPage = 0;
        delete value.resetPageCounter;
      }
      this._paginationData = value;
      if (this.configuration) {
        this.calculatePageRange();
      }
    }
  }

  @Output() onPageChange?: EventEmitter<DataTableQueryParams> = new EventEmitter<DataTableQueryParams>();

  currentPage: number;
  lastPage: number;
  pageRangeFrom: number;
  pageRangeTo: number;
  pages: Set<number>;
  _paginationData: IPaginationData;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    if (!this.configuration) {
      this.setDefaultConfiguration();
    }

    if (!this._paginationData) {
      this.setPaginationDataFromRoute();
    }

    this.calculatePageRange();
  }

  setDefaultConfiguration(): void {
    this.configuration = new PaginationConfigDTO();
    this.configuration.maxPagesShown = DEFAULT_MAX_PAGES;
    this.configuration.perPageOptions = DEFAULT_PER_PAGE_OPTIONS;
    this.configuration.selectedPerPage = this.configuration.defaultPerPage = DEFAULT_PER_PAGE_OPTIONS[2];
  }

  setPaginationDataFromRoute(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        const { limit, offset, total, itemMax, itemMin } = params;

        this._paginationData = {
          limit,
          offset,
          total: total ? total : 0,
          itemMax,
          itemMin,
        };

        if (!itemMin) {
          this.setItemMinDependOnOffset();
        }

        if (!itemMax) {
          this.setItemMaxDependOnOffsetAndTotal();
        }

        this.calculateCurrentPageBasedOnPaginationDataOffset();
      }),
    );
  }

  setItemMinDependOnOffset(): void {
    if (this._paginationData) {
      this._paginationData.itemMin = this._paginationData.offset;
    }
  }

  setItemMaxDependOnOffsetAndTotal(): void {
    if (this._paginationData) {
      this._paginationData.itemMin = this.getIndexOfLastItemOnPage();
    }
  }

  getIndexOfLastItemOnPage(): number {
    const lastNumberOfItem = this._paginationData.offset + this.configuration.selectedPerPage;
    if (!lastNumberOfItem || lastNumberOfItem > this._paginationData.total) {
      return this._paginationData.total;
    }
    return lastNumberOfItem;
  }

  changeItemsPerPage(): void {
    this.currentPage = Math.floor(this._paginationData.offset / this.configuration.selectedPerPage);
    this.updateQueryParams();
  }

  navClick(page: number): void {
    if (page < 0 || page > this.lastPage) {
      return;
    }

    this.currentPage = page;
    this.updateQueryParams();
  }

  updateQueryParams(): void {
    const queryParams = new DataTableQueryParams();

    this.calculatePageRange();

    queryParams.limit = this.configuration.selectedPerPage;
    queryParams.offset = queryParams.limit * this.currentPage;

    this._paginationData.limit = queryParams.limit;
    this._paginationData.offset = queryParams.offset;

    this.onPageChange.emit(queryParams);
  }

  calculatePageRange(): void {
    this.calculateLastPage();
    this.calculateCurrentPage();

    const halfRange = Math.floor(this.configuration.maxPagesShown / 2);

    this.pageRangeFrom = this.currentPage >= halfRange ? this.currentPage - halfRange : 0;
    this.pageRangeTo = Math.min(this.pageRangeFrom + this.configuration.maxPagesShown - 1, this.lastPage);

    this.pages = new Set<number>();
    for (let i = this.pageRangeFrom; i <= this.pageRangeTo; i++) {
      this.pages.add(i);
    }

    this._paginationData.itemMin = this.currentPage * this.configuration.selectedPerPage + 1;
    this._paginationData.itemMax = this._paginationData.itemMin + this.configuration.selectedPerPage - 1;
  }

  calculateCurrentPageBasedOnPaginationDataOffset(): void {
    this.currentPage = Math.floor(this._paginationData.offset / this.configuration.selectedPerPage);
  }

  calculateCurrentPage(): void {
    if (!this.currentPage) {
      this.currentPage = 0;
    }

    if (this.currentPage > this.lastPage) {
      this.currentPage = this.lastPage;
    }
  }

  calculateLastPage(): void {
    if (!this.configuration || !this.configuration.selectedPerPage || !this._paginationData || !this._paginationData.total) {
      this.lastPage = Infinity;
      return;
    }

    this.lastPage = Math.floor(this._paginationData.total / this.configuration.selectedPerPage);
  }
}
