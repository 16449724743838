<ng-template #loading>
  <app-spinner></app-spinner>
  <div class="row">
    <div class="col text-center">Loading marketplaces...</div>
  </div>
</ng-template>

<div *ngIf="loaded; else loading" class="MarketplacesList">
  <div *ngIf="supplierMarketplaces.length; else noMarketplaces" class="MarketplacesList__SingleRow">
    <app-supplier-single-marketplace
      *ngFor="let marketplace of supplierMarketplaces"
      [marketplace]="marketplace"
      [supplierKey]="supplierDetails?.supplierKey"
    ></app-supplier-single-marketplace>
  </div>

  <ng-template #noMarketplaces data-test="marketplacesNoMarketplace">
    <p>No marketplaces yet</p>
  </ng-template>
</div>
