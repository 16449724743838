<article class="tl-item">
  <div class="tl-body">
    <div class="tl-entry">
      <div class="tl-icon btn-icon-round btn-icon btn-icon-thin btn-emails"><i class="material-icons">email</i></div>
      <div class="tl-content">
        <ul class="tl-data-summary">
          <li><i class="material-icons">event_note</i>{{ item.createdAt | fromUnix | date: 'short' }}</li>
          <li class="text-uppercase pill {{ currentStatus?.className }}">
            <i class="material-icons">{{ currentStatus?.icon }}</i
            >{{ item.status }}
          </li>
          <li class="pill"><i class="material-icons">call_made</i> {{ item.createdByName || item.source }}</li>
          <li class="pill"><i class="material-icons">call_received</i>{{ item.supplierName }} &lt;{{ item.to[0] }}&gt;</li>
        </ul>
        <ul class="tl-action-buttons pull-right">
          <li>
            <a (click)="openEmail()"><i class="material-icons">launch</i></a>
          </li>
        </ul>

        <div class="tl-title">
          <a (click)="openEmail()"
            ><strong>{{ item.subject }}</strong></a
          >
        </div>
        <hr />
        <div class="tl-body">
          <p>{{ item.message }}</p>
        </div>
      </div>
    </div>
  </div>
</article>
