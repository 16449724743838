import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';

import { DIALOG_SIZE } from '../../shared/dialog.config';
import { UsersService } from '../../shared/users/service/users.service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../shared/users/user-permissions.enum';
import { DeleteSettingComponent } from './delete-setting/delete-setting.component';
import { EditSettingComponent } from './edit-setting/edit-setting.component';
import { GlobalSetting } from './models/global-setting.model';
import { NewSettingComponent } from './new-setting/new-setting.component';
import { GlobalSettingsService } from './service/global-settings.service';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
})
export class GlobalSettingsComponent extends SubscriberComponent implements OnInit {
  columnsToDisplay = ['instance', 'key', 'title', 'type', 'value', 'action'];
  globalSettings: MatTableDataSource<GlobalSetting>;
  hasCRMPermission: boolean;

  private configTypesToDisplay = ['task_template', 'nested_list', 'list', 'dict', 'html', 'number', 'string'];

  constructor(private globalSettingsService: GlobalSettingsService, private dialog: MatDialog, private userService: UsersService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.userService.checkPermission(USER_PERMISSION.CRM_ADMIN).subscribe((permitted) => {
        this.hasCRMPermission = permitted;
      }),
    );

    this.getData();
  }

  openDeleteSettingComponent(setting: any) {
    const dialogRef = this.dialog.open(DeleteSettingComponent, {
      data: setting,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getData();
        }
      }),
    );
  }

  openEditSettingComponent(setting: any) {
    const dialogRef = this.dialog.open(EditSettingComponent, {
      ...DIALOG_SIZE.RWD_50_AUTO_HEIGHT,
      data: setting,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getData();
        }
      }),
    );
  }

  openNewSettingComponent() {
    const dialogRef = this.dialog.open(NewSettingComponent, {
      ...DIALOG_SIZE.RWD_50_AUTO_HEIGHT,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getData();
        }
      }),
    );
  }

  private getData() {
    this.subscriptions.push(
      this.globalSettingsService
        .getAll(true)
        .pipe(first())
        .subscribe((data) => {
          const filteredData = data.filter((globalSetting) => this.configTypesToDisplay.includes(globalSetting.config_type));
          this.globalSettings = new MatTableDataSource(filteredData);
        }),
    );
  }
}
