import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { DataTableQueryParams } from '../../shared/data-table/data-table.common';
import { IBalanceApproval } from './balance-approval';
import { IBalanceApprovalDTO, IOutcomeReason, IReviewStatistics } from './balance-approval-interfaces';

@Injectable()
export class BalanceApprovalService implements IBalanceApproval {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
  }

  public getData(limit: number = 25, offset: number = 0, approved: DataTableQueryParams | null = null): Observable<IBalanceApprovalDTO> {
    let params = new HttpParams();
    params = params.append('limit', limit.toString());
    if (offset !== undefined) {
      params = params.append('offset', offset.toString());
    }

    if (approved !== null) {
      for (const key in approved) {
        if (approved.hasOwnProperty(key)) {
          if (approved[key] !== '' && approved[key] !== undefined && key !== 'limit' && key !== 'offset') {
            params = params.append(key, approved[key].toString());
          }
        }
      }
    }

    const endpointUrl = this.apiUrl + 'pending_review/';
    return this.httpClient
      .get<IBalanceApprovalDTO>(endpointUrl, {
        params,
      })
      .pipe(
        map((data) => {
          if (data && data.hasOwnProperty('total') && data.hasOwnProperty('results')) {
            const mappedData = data as IBalanceApprovalDTO;
            for (let i = 0; i < mappedData.results.length; i++) {
              const item = mappedData.results[i];

              if (item.hasOwnProperty('short_to_long_mov_avg_ratio_rcv') && item['short_to_long_mov_avg_ratio_rcv'].Value !== 0) {
                mappedData.results[i]['short_to_long_mov_avg_ratio'] = item['short_to_long_mov_avg_ratio_rcv'];
              }
            }

            return mappedData;
          }

          return data;
        }),
      );
  }

  public getHeader(): Observable<IReviewStatistics> {
    const endpointUrl = this.apiUrl + 'pending_review/statistic';
    return this.httpClient.get<IReviewStatistics>(endpointUrl);
  }

  public setDecision(key: string, value: string, suppKey: string, mlKey: string, reason?: string): Observable<any> {
    const endpointUrl = this.apiUrl + 'pending_review/' + suppKey;
    const data = {
      [key]: value,
      supplier_transfer_approval_ml_key: mlKey,
    };

    if (reason) {
      data['reasons'] = reason;
    }

    return this.httpClient.put<any[]>(endpointUrl, data);
  }

  public getDictionaries(): Observable<IOutcomeReason[]> {
    const endpointUrl = this.apiUrl + 'lookup/codes/payment.approval.outcomeReason';

    return this.httpClient.get<any>(endpointUrl);
  }
}
