<article class="article" *ngIf="!isLoading; else loading">
  <div class="flex-container">
    <div class="header box box-default">
      <div class="navigation">
        <a [routerLink]="['../../']" title="Go to advances list" class="close-button">
          <i class="material-icons">arrow_back</i>
        </a>
      </div>
      <div class="info">
        <h6>Advance Line Information</h6>
      </div>
      <div class="actions"></div>
    </div>
    <div class="col-xl-4 col-lg-4">
      <div class="card">
        <div class="card-content">
          <div class="card-title">Advance Line Details</div>
          <form [formGroup]="activeAdvanceLineForm">
            <mat-form-field>
              <input matInput placeholder="Advance Line Status" formControlName="advanceLineStatus" type="text" readonly />
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Weeks Since Origination" formControlName="weeksSinceOrigination" type="text" readonly />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Origination Date"
                [value]="activeAdvanceLineForm.get('originationDate').value | date: 'MM/dd/yyyy'"
                readonly
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Approved Limit"
                [value]="activeAdvanceLineForm.get('approvedLimit').value | currency: 'USD'"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Maximum Repayment Reserve" formControlName="maximumRepaymentReserve" readonly />
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Number of Advances" formControlName="numberOfAdvances" readonly />
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Return Strategy" formControlName="returnStrategy" readonly />
            </mat-form-field>
            <mat-form-field>
              <input class="display-none" matInput placeholder="No Rebate" readonly [value]="activeAdvanceLineForm.get('noRebate').value" />
              <mat-slide-toggle [color]="'primary'" [disabled]="true" [checked]="activeAdvanceLineForm.get('noRebate').value">
              </mat-slide-toggle>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
  </div>
</article>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</ng-template>
