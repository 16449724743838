import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../store/app.reducers';
import { LayoutService } from '../layout/layout.service';
import * as fromLayoutActions from '../layout/store/layout.actions';

@Directive({ selector: '[appOpenSearchOverlay]' })
export class OpenSearchOverlayDirective implements AfterViewInit {
  constructor(private el: ElementRef, private layoutService: LayoutService, private store: Store<AppState>) {}

  ngAfterViewInit() {
    const $el = $(this.el.nativeElement);
    const $body = $('#body');

    $el.on('click', (e) => {
      if ($el && $el[0] && $el[0].dataset && $el[0].dataset.old) {
        this.store.dispatch(new fromLayoutActions.ChooseOldSearch());
      } else {
        this.store.dispatch(new fromLayoutActions.ChooseNewSearch());
      }
      this.openOverlay();
    });
  }

  openOverlay() {
    this.layoutService.updateSearchOverlayState('open');
  }
}
