import { SupplierDetailsModel } from '../../../../../model/supplier.model';

export const GetInitialInternationalSupplier = (supplierDetailsModel: SupplierDetailsModel) => {
  return [
    {
      text: 'Does the supplier live outside of the US but sell in the US?',
      target: false,
      warning: true,
      actual: true,
      value: false,
    },
    {
      text: 'Sells between $15K-$50K per month?',
      target: true,
      warning: true,
      actual: false,
      value: false,
    },
    {
      text: 'Is bank located in the US? If yes, 3% fee, but if located outside of US, 5%',
      target: true,
      warning: false,
      actual: false,
      value: false,
    },
    {
      text: 'Is the supplier a sole proprietorship?',
      target: true,
      warning: false,
      actual: supplierDetailsModel ? supplierDetailsModel.businessTypeCode === 'SP' : false,
      value: supplierDetailsModel ? supplierDetailsModel.businessTypeCode === 'SP' : false,
    },
    {
      text: 'Has the supplier been selling on Amazon for at least one year?',
      target: true,
      warning: true,
      actual: false,
      value: false,
    },
  ];
};
