import { autoserialize } from 'cerialize';

import { ModelInterface } from '../../shared/utils/model-interface';

export class SupplierPaymentNewBatchModel {
  @autoserialize
  public name: string;
  @autoserialize
  public reviewers: any;
  @autoserialize
  public plannedPaymentTime: string;

  @autoserialize
  public scheduledPayments: boolean;
  @autoserialize
  public instantAccess: boolean;
  @autoserialize
  public proposedAdvanceLine: boolean;
  @autoserialize
  public locked: boolean;

  @autoserialize
  public addAchPmts: boolean;
  @autoserialize
  public addEfsPmts: boolean;
  @autoserialize
  public addPpmPmts: boolean;
  @autoserialize
  public addWirPmts: boolean;
  @autoserialize
  public addLlpPmts: boolean;

  @autoserialize
  public paymentBatchFeeCode?: string;
  @autoserialize
  public debitPayments: boolean;
}

export class SupplierPaymentBatchModel implements ModelInterface {
  @autoserialize
  public name: string;
  @autoserialize
  public instantAccess: string;
  @autoserialize
  public proposedAdvanceLine: string;
  @autoserialize
  public paymentTypeCounts: string;
  @autoserialize
  public paymentTotal: string;
  @autoserialize
  public numSentToBank: string;
  @autoserialize
  public numPayments: number;
  @autoserialize
  public numApproved: number;
  @autoserialize
  public numCancelled: number;
  @autoserialize
  public numHold: number;
  @autoserialize
  public reviewers: string;

  @autoserialize
  public plannedPaymentTime: Date;
  @autoserialize
  public scheduledPayments: string;
  @autoserialize
  public submitted: boolean;
  @autoserialize
  public locked: string;
  @autoserialize
  public paymentBatchKey: string;

  @autoserialize
  public addAchPmts: boolean;
  @autoserialize
  public addEfsPmts: boolean;
  @autoserialize
  public addPpmPmts: boolean;
  @autoserialize
  public addWirPmts: boolean;
  @autoserialize
  public addLlpPmts: boolean;

  @autoserialize
  public paymentBatchFeeCode?: string;

  @autoserialize
  public debitPayments: boolean;

  @autoserialize
  public internalTransfers: boolean;

  constructor(
    name: string,
    instantAccess: string,
    proposedAdvanceLine: string,
    paymentTypeCounts: string,
    paymentTotal: string,
    numHold: number,
    numPayments: number,
    numApproved: number,
    numCancelled: number,
    reviewers: string,
    plannedPaymentTime: Date,
    scheduledPayments: string,
    submitted: boolean,
    locked: string,
    paymentBatchKey: string,
    addAchPmts: boolean,
    addEfsPmts: boolean,
    addPpmPmts: boolean,
    addWirPmts: boolean,
    addLlpPmts: boolean,
    debitPayments: boolean,
    paymentBatchFeeCode?: string,
    internalTransfers?: boolean,
  ) {
    this.name = name;
    this.instantAccess = instantAccess;
    this.proposedAdvanceLine = proposedAdvanceLine;
    this.paymentTypeCounts = paymentTypeCounts;
    this.paymentTotal = paymentTotal;
    this.numHold = numHold;
    this.numPayments = numPayments;
    this.numApproved = numApproved;
    this.numCancelled = numCancelled;
    this.reviewers = reviewers;

    this.plannedPaymentTime = plannedPaymentTime;
    this.scheduledPayments = scheduledPayments;
    this.submitted = submitted;
    this.locked = locked;
    this.paymentBatchKey = paymentBatchKey;

    this.addAchPmts = addAchPmts;
    this.addEfsPmts = addEfsPmts;
    this.addPpmPmts = addPpmPmts;
    this.addWirPmts = addWirPmts;
    this.addLlpPmts = addLlpPmts;

    this.paymentBatchFeeCode = paymentBatchFeeCode;
    this.debitPayments = debitPayments;
    this.internalTransfers = internalTransfers;
  }

  getKey(): string {
    return null;
  }

  fromJSON(jsonObject: Object): void {}
}

export class SupplierPaymentBatchAction {
  @autoserialize
  public type: string;
  @autoserialize
  public authyToken: string;
  @autoserialize
  public achPostDate: string;

  constructor(type: string, authyToken?: string, achPostDate?: string) {
    this.type = type;
    if (authyToken) {
      this.authyToken = authyToken;
    }
    if (achPostDate) {
      this.achPostDate = achPostDate;
    }
  }
}

export class SupplierPaymentWorkflowStatusModel {
  @autoserialize
  public lastPmtAddTs: string;
  @autoserialize
  public lastPmtSourceDataUpdTs: string;
  @autoserialize
  public lastPmtUpdTs: string;
  @autoserialize
  public nextStepAction: string;
  @autoserialize
  public nextStepAuthyTokenRequired: boolean;
  @autoserialize
  public nextStepLabel: string;
  @autoserialize
  public updatedAfterAddPayments: any[];
  @autoserialize
  public showEFFdate: boolean;

  constructor(
    lastPmtAddTs: string,
    lastPmtSourceDataUpdTs: string,
    lastPmtUpdTs: string,
    nextStepAction: string,
    nextStepAuthyTokenRequired: boolean,
    nextStepLabel: string,
    updatedAfterAddPayments: any[],
    showEFFdate: boolean,
  ) {
    this.lastPmtAddTs = lastPmtAddTs;
    this.lastPmtSourceDataUpdTs = lastPmtSourceDataUpdTs;
    this.lastPmtUpdTs = lastPmtUpdTs;
    this.nextStepAction = nextStepAction;

    this.nextStepAuthyTokenRequired = nextStepAuthyTokenRequired;
    this.nextStepLabel = nextStepLabel;
    this.updatedAfterAddPayments = updatedAfterAddPayments;
    this.showEFFdate = showEFFdate;
  }
}
