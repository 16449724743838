export const OTHER_MARKETPLACE_FLAG = 'OTHER';

export const AVAILABLE_STATEMENTS = [
  { value: 'Amazon Open Invoices', label: 'Amazon Open Invoices' },
  { value: 'Amazon Invoiced Orders', label: 'Amazon Invoiced Orders' },
  { value: 'Amazon.com', label: 'Amazon.com' },
  { value: 'Walmart', label: 'Walmart' },
  { value: 'Amazon Canada', label: 'Amazon Canada' },
  { value: OTHER_MARKETPLACE_FLAG, label: 'Other marketplace' },
];
