import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { SubscriberComponent } from '../../../../../../shared/component-subscriber/subscriber.component';
import { ErrorVisualizationService } from '../../../../../../shared/services/error-visualization/error-visualization.service';
import { ProductMatrixService } from '../service/product-matrix.service';
import { ProductMatrixInitializeTablesService } from '../service/product-matrix-initialize-tables.service';
import { IProducts } from '../service/product-matrix-interfaces';

@Component({
  selector: 'app-product-open-invoices',
  templateUrl: './product-open-invoices.component.html',
  styleUrls: ['./product-open-invoices.component.scss'],
})
export class ProductOpenInvoicesComponent extends SubscriberComponent implements OnInit {
  @Input() response: IProducts;

  public serviceColumns = ['marketplace', 'openInvoices', 'invoicedOrders', 'enabled'];
  public productStatus: string;
  public dataSource: MatTableDataSource<Element>;
  public mpData: any;
  public data: any[];
  public productStatusColor: string;

  constructor(private productService: ProductMatrixService, private errorVisualizationService: ErrorVisualizationService) {
    super();
  }

  ngOnInit() {
    this.data = [];
    this.setUpDataFromResponse(this.response.open_invoices);
    this.dataSource = new MatTableDataSource(this.data);
  }

  private setUpDataFromResponse(product: any): void {
    this.productStatus = ProductMatrixInitializeTablesService.setProductStatus(product.onboarding_outcome.onboarding_result);
    this.productStatusColor = ProductMatrixInitializeTablesService.setProductStatusColor(this.productStatus);
    this.mpData = ProductMatrixInitializeTablesService.checkIfNotSupported(product.mp_data);
    ProductMatrixInitializeTablesService.setDataSource(this.mpData, this.data, this.setFieldsForSpecificProduct);
  }

  private setFieldsForSpecificProduct(mp: any) {
    return {
      openInvoices: mp.open_invoices ? mp.open_invoices : '-',
      invoicedOrders: mp.invoiced_orders ? mp.invoiced_orders : '-',
      enabled: mp.is_enabled,
    };
  }

  public activationProductRequest(e: any, index: number) {
    const key = ProductMatrixInitializeTablesService.getMarketplaceSupplierKey(this.mpData, index);
    if (e.checked) {
      return this.subscriptions.push(
        this.productService.enableProduct(key, 'open_invoices').subscribe(
          () => {},
          (err: any) => {
            this.errorVisualizationService.showError({ name: 'Activation Product Error', errorObject: err });
          },
        ),
      );
    }
    this.subscriptions.push(
      this.productService.disableProduct(key, 'open_invoices').subscribe(
        () => {},
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Activation Product Error', errorObject: err });
        },
      ),
    );
  }
}
