import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { ServiceUpdatePartialInterface } from '../../shared/interfaces/service-update-partial-interface/service-update-partial-interface';
import { FundingParticularsModel } from '../model/funding-particulars.model';

@Injectable()
export class FundingParticularsService implements ServiceUpdatePartialInterface {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers';
  }

  fetchFundingParticulars(supplierKey: string, refresh: boolean = false): Observable<FundingParticularsModel> {
    const endpoint = `${this.apiUrl}/${supplierKey}/funding_particulars`;
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient.get<FundingParticularsModel>(endpoint, { params: queryParams }).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  saveItemPart(itemKey: string, itemPart: FundingParticularsModel): Observable<any> {
    return this.httpClient.put(`${this.apiUrl}/${itemKey}/funding_particulars`, itemPart).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }
}
