/* eslint-disable */
import { VpnTesterService } from './shared/vpn-tester.service';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { MatSortModule } from '@angular/material/sort';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as $ from 'jquery';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { AppRoutesModule } from './app-routes.module';
import { AppWelcomeModule } from './app-welcome/app-welcome.module';
import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './auth/auth-interceptor';
import { AuthEffects } from './auth/store/auth.effects';
import { AppConfigService, themeConfigData } from './config';
import { CrmToolsModule } from './crm-tools/crm-tools.module';
import { CrmEffects } from './crm-tools/store/crm.effects';
import { FeatureFlagsGeneralGuard } from './guards/feature-flags-general.guard';
import { SubPermissionsGuard } from './guards/sub-permissions.guard';
import { GlobalSettingsEffects } from './manage/global-settings/store/global-settings.effects';
import { ManageModule } from './manage/manage.module';
import { MarketplacesModule } from './marketplaces/marketplaces.module';
import { ProcessesModule } from './processes/processes.module';
import { ReportsModule } from './reports/reports.module';
import { ReportsRepositoryService } from './reports/service/reports-repository.service';
import { HttpCacheAbstract } from './shared/cache/http-cache-abstract';
import { HttpCacheLocalStorage } from './shared/cache/http-cache-local-storage';
import { DialogModule } from './shared/dialog/dialog.module';
import { DynamicEmbedderService } from './shared/dynamic-embedder.service';
import { AppErrorHandler } from './shared/error-handler';
import { NgHttpLoaderModule } from './shared/http-loader/ng-http-loader.module';
import { FetchedRequestsInterceptor } from './shared/interceptors/fetched-requests.interceptor';
import { LayoutService } from './shared/layout/layout.service';
import { MainLayoutComponent } from './shared/layout/main-layout.component';
import { LookupAbstractService } from './shared/lookup-service/lookup-abstract.service';
import { LookupRepository } from './shared/lookup-service/lookup-repository';
import { SupplierKeyResolver } from './shared/resolvers/supplier-key.resolver';
import { ErrorLogger } from './shared/services/error-logger/error-logger.service';
import { ErrorParserService } from './shared/services/error-parser/error-parser.service';
import { ErrorVisualizationService } from './shared/services/error-visualization/error-visualization.service';
import { SharedModule } from './shared/shared.module';
import { UsersService } from './shared/users/service/users.service';
import { RouteAccessResolver } from './shared/utils/route-access-resolver';
import { UtilsEffects } from './shared/utils/store/utils.effects';
import { APP_REDUCERS } from './store/app.reducers';
import { storeDebugger } from './store-debugger';
import { CancelEmitModule } from './suppliers/services/cancel-emit.module';
import { DepositAccountService } from './suppliers/services/deposit-account/deposit-account.service';
import { PaymentConfigService } from './suppliers/services/payment-config/payment-config.service';
import { SupplierEffects } from './suppliers/store/supplier/supplier.effects';
import { ReceivableService } from './suppliers/supplier/add-receivable/service/receivable.service';
import { UnderwritingEffects } from './suppliers/supplier/supplier-underwriting/store/underwriting.effects';
import { SuppliersModule } from './suppliers/suppliers.module';
import { UnderwritingModule } from './underwriting/underwriting.module';
import { EvaCommunicationService } from './shared/eva/eva-communication.service';

window['$'] = window['jQuery'] = $;
/* eslint-enable */

export const initAppConfig = () => {
  return new AppConfigService('0.0.1', themeConfigData(environment.envName), environment);
};

@NgModule({
  declarations: [AppComponent, MainLayoutComponent],
  imports: [
    AppWelcomeModule,
    BrowserModule,
    CancelEmitModule.forRoot(),
    HttpModule,
    StoreModule.forRoot(APP_REDUCERS, { metaReducers: [storeDebugger] }),
    EffectsModule.forRoot([AuthEffects, GlobalSettingsEffects, SupplierEffects, CrmEffects, UtilsEffects, UnderwritingEffects]),
    StoreRouterConnectingModule,
    StoreDevtoolsModule.instrument(),
    HttpClientModule,
    BrowserAnimationsModule,
    NgHttpLoaderModule,
    AuthModule,
    ManageModule,
    MarketplacesModule,
    ReportsModule,
    AdminModule,
    SuppliersModule,
    ProcessesModule,
    SharedModule,
    CrmToolsModule,
    MatSortModule,
    NgxPermissionsModule.forRoot(),
    DialogModule,
    UnderwritingModule,
    AppRoutesModule, // it must be the last on the list because of "default" routes redirects
  ],
  providers: [
    ErrorParserService,
    ErrorVisualizationService,
    ReceivableService,
    LayoutService,
    RouteAccessResolver,
    DatePipe,
    ReportsRepositoryService,
    NgxPermissionsService,
    DynamicEmbedderService,
    SupplierKeyResolver,
    ErrorLogger,
    FeatureFlagsGeneralGuard,
    SubPermissionsGuard,
    EvaCommunicationService,
    VpnTesterService,
    { provide: HttpCacheAbstract, useClass: HttpCacheLocalStorage },
    { provide: AppConfigService, useFactory: initAppConfig },
    { provide: PaymentConfigService, useClass: PaymentConfigService },
    { provide: DepositAccountService, useClass: DepositAccountService },
    { provide: LookupAbstractService, useClass: LookupRepository },
    { provide: UsersService, useClass: UsersService },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FetchedRequestsInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private readonly meta: Meta) {
    this.tryAddNoIndex();
  }

  private tryAddNoIndex(): void {
    const bannedHrefs: string[] = ['volusion', 'pro', 'lendio', 'neweggcapital', 'morecommerce', 'crm2', 'c2fo'];
    const shouldBeBanned: boolean = bannedHrefs.some((element: string) => window.location.href.includes(element));
    if (shouldBeBanned) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }
  }
}
