import { CurrencyPipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { DataTableQueryParams } from '../../shared/data-table/data-table.common';
import { DIALOG_SIZE } from '../../shared/dialog.config';
import { TrueFalseFilterAmount } from '../../shared/enums/true-false-filter-amount.enum';
import { CurrencyCellRenderer } from '../../shared/functions/currency-cell-renderer';
import { LinkCellRenderer } from '../../shared/functions/link-cell-renderer';
import { PybAgMatSelectCellComponent } from '../../shared/pyb-ag-grid/cells';
import { PaginationConfigDTO } from '../../shared/pyb-pagination/pagination-config-dto';
import { IPaginationData } from '../../shared/pyb-pagination/pagination-data';
import {
  dictionaryAssigner,
  displayNameAssigner,
  getApprovalCellCssClass,
  PaymentApproval,
} from '../supplier-payments-details/supplier-payments-details.common';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { ApprovalNotesModalComponent } from './approval-notes-modal/approval-notes-modal.component';
import { BalanceApprovalService } from './balance-approval.service';
import { IBalanceApprovalDTO, IReviewStatistics } from './balance-approval-interfaces';
import { DescriptionModalComponent } from './description-modal/description-modal.component';

const DEFAULT_MAX_PAGES = 5;
const DEFAULT_PER_PAGE = 25;
const QUERY_SUFIX = '.Value';

export enum MLApprovedOptions {
  All = 'None',
  Yes = 'True',
  No = 'False',
}

@Component({
  selector: 'app-balance-approval',
  templateUrl: './balance-approval.component.html',
  styleUrls: ['./balance-approval.component.scss'],
})
export class BalanceApprovalComponent extends SubscriberComponent implements OnInit {
  public tableSettings: any;
  public editPaymentBatchForm: FormGroup;
  public filterParams: any;
  public source;
  public baseHttpParams: any;
  public payments: any;
  public config;
  public filterKeys = ['supplier_name', 'supplier_key', 'treasury_approved', 'ml_balance_approval', 'ml_financial', 'ml_approved'];
  public headerInformationData: IReviewStatistics;
  private filterOption: any = {};
  private dictionaries: any;
  private filtersChanged: Subject<any> = new Subject<any>();
  private paginationOptions = [5, 10, 25, 50, 100];
  paginationConfiguration: PaginationConfigDTO = new PaginationConfigDTO();

  public paginationData?: IPaginationData;
  public columns: ColDef[];

  public filterConfig = [
    {
      fieldName: 'supplier_name',
      label: 'Supplier Name',
      fieldType: 'input',
    },
    {
      fieldName: 'supplier_key',
      label: 'Supplier Key',
      fieldType: 'input',
    },
    {
      fieldName: 'treasury_approved',
      label: 'Treasury Approve',
      fieldType: 'select',
      options: [
        { label: '', id: PaymentApproval.All },
        { label: 'Approved', id: PaymentApproval.Yes },
        { label: 'Rejected', id: PaymentApproval.Reject },
        { label: 'None', id: PaymentApproval.None },
      ],
      defaultValue: PaymentApproval.None,
    },
    {
      fieldName: 'ml_financial',
      label: 'ML Health Check',
      fieldType: 'select',
      options: [
        { label: 'All', id: TrueFalseFilterAmount.All },
        { label: 'Pass', id: TrueFalseFilterAmount.Yes },
        { label: 'Fail', id: TrueFalseFilterAmount.No },
      ],
      defaultValue: TrueFalseFilterAmount.All,
    },
    {
      fieldName: 'ml_balance_approval',
      label: 'ML Account Health Check',
      fieldType: 'select',
      options: [
        { label: 'All', id: TrueFalseFilterAmount.All },
        { label: 'Pass', id: TrueFalseFilterAmount.Yes },
        { label: 'Fail', id: TrueFalseFilterAmount.No },
      ],
    },
    {
      fieldName: 'ml_approved',
      label: 'ML Approval',
      fieldType: 'select',
      options: [
        { label: 'All', id: TrueFalseFilterAmount.All },
        { label: 'True', id: TrueFalseFilterAmount.Yes },
        { label: 'False', id: TrueFalseFilterAmount.No },
      ],
    },
  ];
  rows: any;
  offset: number;

  constructor(
    private balanceApprovalService: BalanceApprovalService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private pipe: CurrencyPipe,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {
    super();
    this.paginationConfiguration.maxPagesShown = DEFAULT_MAX_PAGES;
    this.paginationConfiguration.perPageOptions = this.paginationOptions;
    this.paginationConfiguration.selectedPerPage = DEFAULT_PER_PAGE;

    this.baseHttpParams = {
      limit: DEFAULT_PER_PAGE,
      treasury_approved: PaymentApproval.None,
    };

    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        if (params.limit) {
          this.baseHttpParams.limit = params.limit;
        }
        if (params.offset) {
          this.baseHttpParams.offset = params.offset;
        }

        this.paginationData = {
          limit: this.baseHttpParams.limit,
          offset: this.baseHttpParams.offset,
          total: 0,
        };

        for (const key of this.filterKeys) {
          const sufixedKey = `${key}.${QUERY_SUFIX}`;
          if (params[sufixedKey]) {
            this.baseHttpParams[key] = params[sufixedKey];
          }
        }
      }),
    );
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.balanceApprovalService.getHeader().subscribe((data) => {
        this.headerInformationData = data;
      }),
    );

    this.getPayments();
    this.getConfig();

    this.setupComponent();
  }

  private getConfig(): void {
    this.config = forkJoin([this.balanceApprovalService.getDictionaries()]).pipe(
      map((result) => {
        const empty = {
          label: '',
          displayName: '',
          id: null,
          codeValue: null,
        };
        return {
          dictionaries: {
            'ml_approved.Value': [
              { label: 'All', id: MLApprovedOptions.All },
              { label: 'True', id: MLApprovedOptions.Yes },
              { label: 'False', id: MLApprovedOptions.No },
            ],
            treasury_approved: [
              empty,
              ...[
                { displayName: 'Approved', codeValue: 'pp.po:canceled', label: 'Approved' },
                { displayName: 'Rejected', codeValue: 'pp.po:approved', label: 'Rejected' },
              ],
            ],
            'treasury_approved.Value': [
              { label: '', id: PaymentApproval.All },
              { label: 'Approved', id: PaymentApproval.Yes },
              { label: 'Rejected', id: PaymentApproval.Reject },
            ],
            reasons: result[0]
              ? [
                  empty,
                  ...result[0].map((el) => {
                    el['Value'] = el.displayName;
                    el['label'] = el.displayName;
                    return el;
                  }),
                ]
              : undefined,
            'ml_balance_approval.Value': [
              { label: 'All', id: TrueFalseFilterAmount.All },
              { label: 'Pass', id: TrueFalseFilterAmount.Yes },
              { label: 'Fail', id: TrueFalseFilterAmount.No },
            ],
            'ml_financial.Value': [
              { label: 'All', id: TrueFalseFilterAmount.All },
              { label: 'Pass', id: TrueFalseFilterAmount.Yes },
              { label: 'Fail', id: TrueFalseFilterAmount.No },
            ],
          },
          pagination: {
            paginationOptions: this.paginationOptions,
            default: this.baseHttpParams.limit ? this.baseHttpParams.limit : DEFAULT_PER_PAGE,
          },
        };
      }),
    );

    this.subscriptions.push(
      this.config.subscribe((data) => {
        this.dictionaries = data.dictionaries;
      }),
    );
  }

  private setupComponent(): void {
    this.editPaymentBatchForm = this.formBuilder.group({
      name: [{ value: '' }, Validators.required],
      date: [{ value: '' }, Validators.required],
      time: [{ value: '' }, Validators.required],
      instantAccess: [false],
      scheduledPayments: [false],
      locked: [false],
      addAchPmts: [false],
      addEfsPmts: [false],
      addPpmPmts: [false],
      addWirPmts: [false],
      reviewers: [''],
    });

    this.columns = [
      {
        field: 'supplier_key.Value',
        headerName: 'Supplier Key',
        width: 170,
        headerClass: 'db',
        cellRenderer: LinkCellRenderer,
      },
      {
        field: 'supplier_name.Value',
        headerName: 'Supplier Name',
        width: 150,
        headerClass: 'db',
      },
      {
        field: 'ml_approved.Value',
        headerName: 'ML Approval',
        width: 100,
        headerClass: 'db',
      },
      {
        field: 'current_transfer_balance.Value',
        headerName: 'Pending Available',
        width: 100,
        headerClass: 'db',
      },
      {
        field: 'avail_balance.Value',
        headerName: 'Supplier available balance',
        width: 100,
        cellRenderer: CurrencyCellRenderer,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'db',
      },
      {
        field: 'pyb_due_from_mp_balance.Value',
        headerName: 'Due from marketplace (Payability)',
        width: 130,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'db',
      },
      {
        field: 'mp_due_from_mp_balance.Value',
        headerName: 'MP statement Balance',
        width: 100,
        headerClass: 'calc',
      },
      {
        field: 'mp_payments_7days.Value',
        headerName: 'Marketplace payment made in last 7 days',
        width: 125,
        headerClass: 'calc',
      },
      {
        field: 'due_from_mp_delta.Value',
        headerName: 'Due from marketplace delta',
        width: 130,
        cellRenderer: CurrencyCellRenderer,
        headerClass: 'calc',
      },
      {
        field: 'mp_due_from_marketplace_balance.Value',
        headerName: 'Due from marketplace',
        width: 100,
        cellRenderer: CurrencyCellRenderer,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'liability.Value',
        headerName: 'Liability',
        width: 90,
        cellRenderer: CurrencyCellRenderer,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'dilution.Value',
        headerName: 'Dilution',
        width: 90,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'short_to_long_mov_avg_ratio.Value',
        headerName: '3 day MA / 21 day MA',
        width: 100,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'short_to_long_mov_avg_ratio_cbk.Value',
        headerName: '3 day MA / 21 day MA - chargebacks',
        width: 125,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'past_due_days.Value',
        headerName: 'Past due days',
        width: 95,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'past_due_amount.Value',
        headerName: 'Past due amount',
        width: 95,
        cellRenderer: CurrencyCellRenderer,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'past_due_check.Value',
        headerName: 'Past due check pass',
        width: 95,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'payability_status.Value',
        headerName: 'Supplier status',
        width: 95,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'check_payee_diverted.Value',
        headerName: 'Payee diverted',
        width: 95,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'ml_balance_approval.Value',
        headerName: 'ML Account Health Check',
        width: 95,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'ml_financial.Value',
        headerName: 'ML Health check',
        width: 95,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'ml_balance_approval_details.Value',
        headerName: 'ML Account Health Standing Details',
        width: 115,
        onCellClicked: (record: any) => {
          this.openModal(record.data, 'ml_balance_approval_details', 'ML Account Health Standing Details');
        },
        headerClass: 'calc',
      },
      {
        field: 'ml_financial_reason.Value',
        headerName: 'ML Health Standing Details',
        width: 115,
        onCellClicked: (record: any) => {
          this.openModal(record.data, 'ml_financial_reason', 'ML Health Standing Details');
        },
        headerClass: 'calc',
      },

      {
        field: 'exceptions.Value',
        headerName: 'Special conditions',
        width: 95,
        onCellClicked: (record: any) => {
          this.openModal(record.data, 'exceptions', 'Special conditions');
        },
        headerClass: 'calc',
      },
      {
        field: 'existing_exceptions.Value',
        headerName: 'Existing exceptions',
        width: 95,
        onCellClicked: (record: any) => {
          this.openModal(record.data, 'existing_exceptions', 'Existing exceptions');
        },
        headerClass: 'calc',
      },
      {
        field: 'cancelation_status.Value',
        headerName: 'Cancelation status',
        width: 95,
        headerClass: 'calc',
      },
      {
        field: 'rcv_purchased_24.Value',
        headerName: 'Receivables purchased in last 24h',
        width: 125,
        cellRenderer: CurrencyCellRenderer,
        headerClass: 'calc',
      },
      {
        field: 'rcv_released_24.Value',
        headerName: 'Reserve released',
        width: 95,
        cellRenderer: CurrencyCellRenderer,
        headerClass: 'calc',
      },
      {
        field: 'mp_pmt_excess_24.Value',
        headerName: 'MP Pmt excess',
        width: 95,
        cellRenderer: CurrencyCellRenderer,
        headerClass: 'calc',
      },
      {
        field: 'last_end_balance.Value',
        headerName: 'Has income in last 15 days',
        width: 95,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        headerClass: 'calc',
      },
      {
        field: 'treasury_approved.Value',
        headerName: 'Treasury Approved',
        width: 95,
        cellEditorFramework: PybAgMatSelectCellComponent,
        onCellValueChanged: (data) => {
          this.treasuryApprovedValueChange(data);
        },
        cellEditorParams: (params) => this.cellEditorValueGetter(params),
        headerClass: 'edit',
        editable: true,
      },
      {
        field: 'treasury_approved_by.Value',
        headerName: 'Treasury Decision By',
        width: 135,
        headerClass: 'calc',
      },
      {
        field: 'treasury_approved_ts.Value',
        headerName: 'Treasury Decision Timestamp',
        width: 135,
        headerClass: 'calc',
      },
      {
        field: 'reasons.Value',
        headerName: 'Reason',
        width: 150,
        cellClass: (cellValue) => getApprovalCellCssClass(cellValue),
        onCellValueChanged: (data) => {
          this.reasonChange(data);
        },
        headerClass: 'edit',
        editable: true,
      },
      {
        field: 'notes.Value',
        headerName: 'Notes',
        width: 150,
        onCellClicked: (record: any) => {
          this.openNotesModal(record.data, 'notes');
        },
        headerClass: 'edit',
        editable: true,
      },
    ];
  }

  getCellCssClass(value: string): string {
    const greenValues = ['Approved', 'Approve', 'Confirmed', 'OK', 'Pass', 'Active', 'success'];
    const redValues = ['Cancel', 'Diverted', 'Fail', 'Missing', 'Suspended', 'danger'];
    const yellowValues = ['Hold', 'At Risk', 'warning'];

    if (greenValues.indexOf(value) > -1) {
      return ' pass';
    }
    if (redValues.indexOf(value) > -1) {
      return ' fail';
    }
    if (yellowValues.indexOf(value) > -1) {
      return ' warning';
    }
  }

  private updateUrl(args: any): void {
    const params: any = {};
    if (args.limit) {
      params.limit = args.limit;
    }
    if (args.offset) {
      params.offset = args.offset;
    }

    for (const filterKey of this.filterKeys) {
      if (args[filterKey]) {
        params[filterKey + '.Value'] = args[filterKey];
      }
    }

    let baseUrl = '';
    const index = this.router.url.indexOf('?');
    if (index >= 0) {
      baseUrl = this.router.url.substr(0, index);
    } else {
      baseUrl = this.router.url;
    }

    const url = this.router.createUrlTree([baseUrl], { queryParams: params }).toString();
    this.location.go(url);
  }

  public getPayments(params?: any): void {
    if (!params) {
      params = this.baseHttpParams;
    }
    this.subscriptions.push(
      this.balanceApprovalService.getData(params.limit, params.offset, params).subscribe((data: IBalanceApprovalDTO) => {
        this.offset = params.offset;
        this.rows = data.results;
      }),
    );
  }

  public treasuryApprovedValueChange(data: any): void {
    if (data && data.data && data.data['treasury_approved']) {
      const reason = data.data['reasons'].Value ? data.data['reasons'].Value : null;

      if (data.newValue) {
        data.value = data.newValue;
      }

      if (!data.value) {
        return;
      }

      this.subscriptions.push(
        this.balanceApprovalService
          .setDecision(
            'treasury_approved',
            data.value,
            data.data['supplier_key'].Value,
            data.data['supplier_transfer_approval_ml_key'].Value,
            reason,
          )
          .subscribe(
            (success) => {
              data.data['treasury_approved'].Value = data.value;
              data.data['treasury_approved_ts'].Value = success.results.treasury_approved_ts.Value;
              data.data['treasury_approved_by'].Value = success.results.treasury_approved_by.Value;
              const node = data.node;
              node.setData({ ...node.data });
              if (data.stopEditing) {
                data.stopEditing();
              }

              this.toastrService.success('New value saved.');
            },
            (error) => {
              this.toastrService.error(error && error.message ? error.error.message : 'Saving failed.');
            },
          ),
      );
    }
  }

  public reasonChange(data: any): void {
    if (data && data.record && data.record['reasons'] && this.dictionaries && this.dictionaries['reasons']) {
      for (const item of this.dictionaries['reasons']) {
        if (data.record['reasons'].Value === item.displayName) {
          data.record['reasons'].codeValue = item.codeValue;
        }
      }
    }
  }

  public openModal(value, key, title): void {
    if (!value || !value[key] || value[key].Value.length === 0) {
      return;
    }

    const data = Object.assign({ text: value[key].Value, title, key });

    const dialogRef = this.dialog.open(DescriptionModalComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
      data,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {}));
  }

  public openNotesModal(value, key): void {
    const dialogRef = this.dialog.open(ApprovalNotesModalComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
      data: {
        supplierName: value['supplier_name'].Value,
        reviewerNote: value[key].Value,
        record: value,
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((data) => {
        if (!isUndefined(data) && !isUndefined(data.value) && !isUndefined(data.record)) {
          const prevNoteValue = value['notes'].Value;
          value['notes'].Value = data.value;

          this.subscriptions.push(
            this.balanceApprovalService
              .setDecision('notes', data.value, data.record['supplier_key'].Value, data.record['supplier_transfer_approval_ml_key'].Value)
              .subscribe(
                () => {
                  value['notes'].Value = data.value;
                  this.toastrService.success('New value saved.');
                },
                (error) => {
                  this.toastrService.error(error && error.message ? error.error.message : 'Saving failed.');
                  value['notes'].Value = prevNoteValue;
                },
              ),
          );
        }
      }),
    );
  }

  public cellEditorValueGetter(params: any) {
    const dictionary = dictionaryAssigner(params, this.dictionaries);
    if (params.charPress === 'a' || params.charPress === 'r') {
      params.value = params.charPress === 'a' ? PaymentApproval.Yes : PaymentApproval.Reject;
      this.treasuryApprovedValueChange(params);
      return {
        values: dictionary,
        value: params.value,
      };
    }

    return {
      values: dictionary,
    };
  }

  public cellEditorValueFormatter(params: any): string {
    if (!isNullOrUndefined(params.value)) {
      if (params.value.label || params.value.label === '') {
        return params.value.label;
      }
      const dictionary = dictionaryAssigner(params, this.dictionaries);
      return displayNameAssigner(dictionary, params, 'batch');
    }
    return;
  }

  onPageChange(dataTableQueryParams: DataTableQueryParams): void {
    this.baseHttpParams = {
      ...this.baseHttpParams,
      ...dataTableQueryParams,
    };
    this.updateUrl(this.baseHttpParams);
    this.getPayments();
    this.getConfig();
  }
}
