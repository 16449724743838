import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss'],
})
export class TransactionModalComponent implements OnInit {
  transaction;
  payload;

  constructor(public dialogRef: MatDialogRef<TransactionModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (!isNullOrUndefined(this.data)) {
      this.data.details = this.transaction;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
