import * as moment from 'moment';

export function DateCellWithoutLocalDateRenderer(cellData: { [key: string]: any }, variant?: string): string {
  if (cellData.value !== null && cellData.value !== '') {
    const formattedDate = moment(new Date(cellData.value), 'YYYY-MM-DD').format('MM/DD/YYYY');

    return formattedDate;
  } else {
    return '';
  }
}

export function DateCellRenderer(cellData: { [key: string]: any }, variant?: string): string {
  if (variant === 'paymentBatches') {
    return 'paymentBatch';
  }
  if (cellData.value !== null && cellData.value !== '') {
    const formattedDate = new Date(cellData.value).toLocaleDateString().replace(/\./g, '-');
    return formattedDate;
  } else {
    return '';
  }
}

export function DateCellRendererWithHour(cellData: { [key: string]: any }, variant?: string): string {
  if (cellData.value != null) {
    const formattedDate = new Date(cellData.value).toLocaleDateString('en-US', {
      hour: 'numeric',
    });
    return formattedDate;
  } else {
    return '';
  }
}

export function TodaysDateCellRenderer(): string {
  const today = new Date().toLocaleDateString().replace(/\./g, '/');
  return today;
}

export function PrepareRenderer(withHour: boolean) {
  if (withHour) {
    return DateCellRendererWithHour;
  }
  return DateCellRenderer;
}
