<h1 mat-dialog-title>Change password</h1>
<div mat-dialog-content>
  <form [formGroup]="passwordForm">
    <app-inline-edit-text
      formControlName="password"
      [inGroupEdit]="true"
      [formGroup]="passwordForm"
      label="New password"
      type="password"
      [disableInline]="true"
      data-test="newPasswordInput"
    >
    </app-inline-edit-text>
    <app-inline-edit-text
      formControlName="retypePassword"
      [inGroupEdit]="true"
      [formGroup]="passwordForm"
      label="Retype new password"
      type="password"
      [disableInline]="true"
      data-test="retypeNewPasswordInput"
    >
    </app-inline-edit-text>

    <mat-error *ngIf="showErrorPassMsg">Password does not match the retyped password</mat-error>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    (click)="savePasswordFormGroup()"
    [disabled]="passwordForm?.invalid"
    tabindex="2"
    color="accent"
    data-test="saveButton"
  >
    Save
  </button>

  <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
</div>
