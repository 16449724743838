<div class="d-flex align-items-center justify-content-between">
  <app-title-with-icon [iconClass]="iconClass.USER" [title]="reportTitle" [subtitle]="reportSubtitle"> </app-title-with-icon>
  <div class="d-flex">
    <div class="mr-5">
      <app-spinner-button
        *ngxPermissionsExcept="'REST_UW_ACCESS'"
        [loading]="loading"
        [customWidth]="'15.5rem'"
        [text]="buttonLabel"
        [disabled]="runAu10tixButtonDisabled$ | async"
        (onClick)="onRunAu10tixCheckClick()"
      >
      </app-spinner-button>
      <p class="position-absolute">
        <span
          >Last checked:
          <ng-container *ngIf="lastReportTimestamp$ | async as lastReportTimestamp">
            {{ lastReportTimestamp ? lastReportTimestamp : '-' }}
          </ng-container>
        </span>
      </p>
    </div>
  </div>
</div>
