import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { OnboardingModel } from '../../../shared/onboarding/onboarding.model';
import { OnboardingService } from '../../../shared/onboarding/service/onboarding.service';
import { UsersService } from '../../../shared/users/service/users.service';
import { AppState } from '../../../store/app.reducers';
import { DeleteOnboardingComponent } from '../delete-onboarding-setting/delete-onboarding.component';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';

class ConfigDataSource extends MatTableDataSource<OnboardingModel> {
  constructor() {
    super();
    const prev = this.sortingDataAccessor;
    this.sortingDataAccessor = function (data, sortHeaderId) {
      if (sortHeaderId === 'title') {
        return data.onboardingTemplateCode.toLowerCase();
      }
      return prev(data, sortHeaderId);
    };
  }
}

@Component({
  selector: 'app-onboarding-list',
  templateUrl: './onboarding-list.component.html',
  styleUrls: ['./onboarding-list.component.scss'],
})
export class OnboardingListComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  dataSource = new ConfigDataSource();
  displayedColumns: string[] = [
    'title',
    'minPaymentAmount',
    'efsRebatePercent',
    'instantAccess',
    'schedPayEnabled',
    'supplierSite',
    'action',
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  canEdit: boolean;
  hasCRMPermission;

  private user;
  private permission = USER_PERMISSION.INT_GLOBAL_SETTINGS;

  constructor(
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private onboardingService: OnboardingService,
    private usersService: UsersService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        this.user = user;

        this.subscriptions.push(
          this.usersService.checkPermission(USER_PERMISSION.CRM_ADMIN, this.user).subscribe((permitted) => {
            this.hasCRMPermission = permitted;
          }),
        );
      }),
    );

    this.dataSource = new ConfigDataSource();
  }

  ngOnInit() {
    this.canEdit = false;
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((loggedUser) => {
        this.getUserPermissions(loggedUser);
      }),
    );

    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  private getData() {
    this.subscriptions.push(
      this.onboardingService.list(true).subscribe((result) => {
        this.dataSource.data = result;
      }),
    );
  }

  openDeleteOnboarding(configElement: OnboardingModel) {
    const dialogRef = this.dialog.open(DeleteOnboardingComponent, {
      ...DIALOG_SIZE.RWD_50_AUTO_HEIGHT,
      data: configElement,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        this.getData();
      }),
    );
  }

  addRow(addedItem: OnboardingModel) {
    this.dataSource.data.push(addedItem);
  }

  navigateToMpTemplatesList(elem: any): void {
    if (this.hasCRMPermission) {
      this.router.navigate(['../' + elem.onboardingTemplateCode + '/marketplaces'], { relativeTo: this.route });
    }
  }

  private getUserPermissions(loggedUser) {
    this.subscriptions.push(
      this.usersService.getLoginPermissions(loggedUser.loginKey).subscribe((perms) => {
        perms.forEach((perm) => {
          this.subscriptions.push(
            this.usersService.getPermissionSubPerms(perm.permCode).subscribe((response) => {
              this.checkPermission(response);
            }),
          );
        });
      }),
    );
  }

  private checkPermission(permissions) {
    permissions.forEach((elem) => {
      if (elem.permCode.toString() === this.permission) {
        this.canEdit = true;
      }
    });
  }

  public navigateToEditTemplate(elem: any) {
    if (this.hasCRMPermission) {
      this.router.navigate(['../' + elem.onboardingTemplateCode + '/edit'], { relativeTo: this.route });
    }
  }
}
