<mat-card class="advanceOfferSummary__card">
  <mat-card-subtitle> Advance Line Summary</mat-card-subtitle>
  <mat-card-content>
    <div>
      <p>Author: {{ summaryOffer.lastUserLogin }}</p>
      <p>Approved Limit: {{ approvedAmount | currency: 'USD' }}</p>
      <p>Maximum Repayment Reserve%: {{ reserveForRepayments ? reserveForRepayments + '%' : '-' }}</p>
      <p>Underwriting Date: {{ summaryOffer && summaryOffer.underwritingDate ? summaryOffer.underwritingDate : '-' }}</p>
      <p>Expected Monthly Earnings: {{ expectedMonthlyEarnings ? expectedMonthlyEarnings : '-' }}</p>
      <p>Expected Duration: {{ expectedDuration ? expectedDuration : '-' }}</p>
      <p>Max Duration: {{ totalPeriodLength ? totalPeriodLength : '-' }}</p>
      <p>Line Fee %: {{ averageFee ? averageFee : '-' }}</p>
      <p>Line Max Repayment Reserve: 0</p>
      <p>Current Repayment Reserve: 0</p>
      <p>
        Underwriting Status:
        <mat-form-field>
          <mat-select
            [(ngModel)]="summaryOffer.underwritingStatus"
            (selectionChange)="uwStatusChangeRequest($event)"
            [disabled]="isReadonly"
          >
            <mat-option *ngFor="let status of AdvanceUwStatuses" [value]="status">
              {{ status | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </p>
    </div>
    <div class="right-column">
      <app-fee-summary
        *ngIf="elementData && forceFullSaved"
        [summaryData]="elementData"
        [isTotalFeeIncorrect]="isTotalFeeIncorrect"
        [isTotalPeriodToLong]="isTotalPeriodToLong"
      >
      </app-fee-summary>
      <div class="undertable">
        <p>Creation Date: {{ summaryOffer.createDate }}</p>
        <p>Last Updated: {{ summaryOffer.updateDate }}</p>
        <p>Offer Status: {{ summaryOffer.offerStatus }}</p>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <button mat-raised-button color="warn" [disabled]="isReadonly">Reset Advance Line</button>
    <button mat-raised-button color="primary" *ngIf="isRetractVisible" [disabled]="isRetractDisabled" (click)="retractOffer()">
      Retract Offer
    </button>
    <button mat-raised-button color="primary" [disabled]="!isSaveEnabled" (click)="updatePendingOffer()">Save</button>
  </mat-card-footer>
</mat-card>
