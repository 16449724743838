import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IPaymentGateway } from '../model/gateway.model';

export enum marketplaceTypes {
  STANDARD_MARKETPLACE = 'marketplace',
  PAYMENT_GATEWAY = 'payment_gateway',
}

@Injectable()
export class GatewayService {
  private apiUrlListGateways = environment.internalApiUrl + 'payment_gateways';

  constructor(private http: HttpClient) {}

  getList(): Observable<any[]> {
    return this.http.get(this.apiUrlListGateways).pipe(
      tap((gateways: IPaymentGateway[]) => {
        this.sortGateways(gateways);
      }),
    );
  }

  public sortGateways(gateways: IPaymentGateway[]): void {
    gateways.sort(this.compareGatewaysOrder);
  }

  private compareGatewaysOrder(firstGateway: IPaymentGateway, secondGateway: IPaymentGateway): number {
    if (firstGateway.marketplace_name < secondGateway.marketplace_name) {
      return -1;
    }
    if (firstGateway.marketplace_name > secondGateway.marketplace_name) {
      return 1;
    }
    return 0;
  }

  connectMarketplace(marketplaceSupplierKey: string, data: any) {
    const apiUrlCreateConnection = `${environment.internalApiUrl}supplier/${marketplaceSupplierKey}/create_and_connect/${data.gatewayKey}`;
    return this.http.post(apiUrlCreateConnection, { bankAccountNumber: data.bankAccountNumber });
  }

  disconnectMarketplace(marketplaceSupplierKey: string, gatewayKey: string) {
    const apiUrlCreateConnection = `${environment.internalApiUrl}supplier/${marketplaceSupplierKey}/disconnect/${gatewayKey}`;
    return this.http.post(apiUrlCreateConnection, null);
  }
}
