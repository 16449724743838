import { autoserialize } from 'cerialize';

export class CrmMetaModel {
  @autoserialize public count: number;
  @autoserialize public last: any;

  constructor(count: number, last: any) {
    this.count = count;
    this.last = last;
  }
}
