<mat-form-field class="field-text filter-control">
  <input
    matInput
    type="text"
    style="height: 20px"
    [(ngModel)]="value"
    (keyup)="onKeyup(value)"
    [placeholder]="label"
    (blur)="onBlur(value)"
    (focus)="onFocus()"
  />
</mat-form-field>
