import { Component, Input } from '@angular/core';

import { UwTaskProgressModel } from '../../../../model/uw-task-progress.model';

@Component({
  selector: 'app-underwriting-workflow',
  templateUrl: './underwriting-workflow.component.html',
  styleUrls: ['./underwriting-workflow.component.scss'],
})
export class UnderwritingWorkflowComponent {
  @Input() uwTaskProgress: UwTaskProgressModel;

  getProgressBarValue(): number {
    return +((this.uwTaskProgress.iterationStep / this.uwTaskProgress.maxIterationStep) * 100).toFixed(1);
  }
}
