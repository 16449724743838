<span *ngIf="noError; else error">
  <section class="subcard" *ngIf="gridOptions; else spinner">
    <ag-grid-angular class="uwbpersonal__grid ag-theme-balham" [gridOptions]="gridOptions"></ag-grid-angular>
  </section>
  <ng-template #spinner>
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </ng-template>
</span>
<ng-template #error>
  <p class="subcard mb-4">
    <span>Sorry, there is no TR data for this owner</span>
    <span *ngIf="noDataReason">:&nbsp;</span>
    <span>{{ noDataReason }}</span>
  </p>
</ng-template>
<ng-container *ngIf="showAttemptsError">
  <p class="subcard mb-4 alert alert-danger">
    <span>Maximum number of attempts was exceeded</span>
  </p>
</ng-container>

<section class="d-flex align-items-center mt-4">
  <app-spinner-button
    class="mr-2"
    (onClick)="onRunClearIdApiClick()"
    [color]="'primary'"
    [loading]="runClearIdApiLoading"
    [customWidth]="'10rem'"
    [spinnerColor]="'white'"
    [text]="'Run Clear ID API'"
    [disabled]="runClearIdButtonDisabled$ | async"
  ></app-spinner-button>
  <span> Last checked: {{ lastChecked ? lastChecked : '-' }} </span>
</section>
