import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isUndefined } from 'util';

import { TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { CliService } from '../../services/cli/cli.service';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { BaseSupplierAction } from '../base-supplier-action/base-supplier-action';

@Component({
  selector: 'app-load-earnings',
  templateUrl: './load-earnings.component.html',
  styleUrls: ['../base-supplier-action/base-supplier-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadEarningsComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  fileTypes: any[] = [
    {
      label: 'General Earnings file',
      id: 'earnings',
      example: 'example_general_earnings.csv',
    },
    {
      label: 'General Receivable file',
      id: 'receivables',
      example: 'example_general_receivable.csv',
    },
  ];

  actionTitle = 'Load Earnings';
  marketplaces: any = {};
  hasInstantAccess = false;

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isUndefined(data)) {
          this.supplierKey = data.supplierKey;
          this.hasInstantAccess = data.status.instantAccess;

          data.marketplaces.map((element) => {
            this.marketplaces[element.marketplaceName] = element.mpSupKey;
          });

          if (this.marketplaces['Amazon Vendor Central']) {
            this.fileTypes.push({
              label: 'Amazon Vendor Central Invoice',
              id: 'amazon_vc_inv',
              example: 'example_amazon_vendor_central_invoice.csv',
            });
          }
          if (this.marketplaces['Newegg Sellers']) {
            this.fileTypes.push({
              label: 'Newegg Sellers',
              id: 'newegg_sellers',
              example: 'example_newegg_sellers.xls',
            });
          }
          if (this.marketplaces['Newegg Vendors']) {
            this.fileTypes.push({
              label: 'Newegg Vendors',
              id: 'newegg_vendors',
              example: 'example_newegg_sellers.xls',
            });
          }
        }
      }),
    );
    super.ngOnInit();
  }

  createForm() {
    this.supplierActionForm = this.formBuilder.group({
      supplier_key: [''],
      file_type: [''],
      file: [''],
      load_paid: false,
      no_chargeback_check: false,
      europe: false,
      instant_purchase: this.hasInstantAccess,
    });
  }

  hasExample() {
    if (this.supplierActionForm && this.supplierActionForm.get('file_type').value) {
      const type = this.fileTypes.filter((item) => item.id === this.supplierActionForm.get('file_type').value);
      return type.length && type[0].example.length ? type[0].example : false;
    }
    return false;
  }

  downloadExample(fileName) {
    return window.open('/assets/examples/' + fileName, '_blank');
  }

  submit() {
    this.isConfirmed = true;
    this.isLoadingResult = true;

    this.supplierActionForm.patchValue({
      supplier_key: this.supplierKey,
    });

    const dataToSave = this.supplierActionForm.value;

    if (dataToSave.file_type === 'amazon_vc_inv') {
      dataToSave.mp_sup_key = this.marketplaces['Amazon Vendor Central'];
    } else if (dataToSave.file_type === 'amazon_ve_remit') {
      dataToSave.mp_sup_key = this.marketplaces['Amazon Vendor Express'];
    } else if (dataToSave.file_type === 'newegg_sellers') {
      dataToSave.mp_sup_key = this.marketplaces['Newegg Sellers'];
    } else if (dataToSave.file_type === 'newegg_vendors') {
      dataToSave.mp_sup_key = this.marketplaces['Newegg Vendors'];
    }

    const request =
      dataToSave.file_type === 'newegg_sellers' || dataToSave.file_type === 'newegg_vendors'
        ? this.supplierService.uploadNewEgg(this.supplierKey, dataToSave)
        : this.cliService.uploadCSV(dataToSave);

    this.subscriptions.push(
      request.subscribe(
        () => {
          this.onSuccess('Earnings loaded successfully');
        },
        (response) => {
          this.onError(response.error.message);
        },
      ),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
