import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../../environments/environment';
import { TwoFa } from './models/twofa.model';

@Injectable()
export class Supplier2FaService {
  private internalApiUrl: string;

  constructor(private readonly httpClient: HttpClient) {
    this.internalApiUrl = environment.internalApiUrl;
  }

  fetch(supplierKey: string): Observable<TwoFa> {
    return this.httpClient.get(`${this.internalApiUrl}suppliers/${supplierKey}/tfa`) as Observable<TwoFa>;
  }

  delete(supplierKey: string): Observable<any> {
    return this.httpClient.delete(`${this.internalApiUrl}suppliers/${supplierKey}/tfa`) as Observable<any>;
  }
}
