import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnderwritingInitialService {
  constructor() {}

  getComplianceStatuses(data) {
    return {
      amazonInvite: data.filter((action) => {
        return action.compliance_key === 'c5f8e904-75f3-4090-9783-fadafe2162fb';
      })[0],
      amazonPermissions: data.filter((action) => {
        return action.compliance_key === '331d90cc-cf7a-4d9f-92bd-8855c1af08c5';
      })[0],
      amazonReports: data.filter((action) => {
        return action.compliance_key === '54ee1a87-f97a-4f57-afcd-8e72ce38f3f0';
      })[0],
      customFiles: data.filter((action) => {
        return action.compliance_key === 'ef8cb878-078f-46b3-aa3d-7f8fac073e19';
      })[0],
    };
  }

  getBodyForPostActionStatusService(supplierKey: string) {
    return {
      compliance_key: 'e6247370-bd22-4992-8054-7900a4afa789',
      compliance_action_key: '86b1c85a-4282-4465-93b5-4e1c58a88665',
      compliance_action_type: 'credit_check',
      supplier_key: supplierKey,
      status: 'pending',
    };
  }

  getAmazonSales(amazonHealth) {
    const amazonSales = [];
    try {
      amazonHealth.monthly_sales.value.slice(-3).map((salesData) => {
        amazonSales.push({
          date: Object.keys(salesData)[0],
          value: Number(Object.values(salesData)[0]),
        });
      });
      return amazonSales;
    } catch {
      return amazonSales;
    }
  }

  getAmazonAdvertisings() {
    return [
      'Yes - no action required',
      'Yes - spend greater than 5% of revenue, increase reserve by 5% or switch payments to CC',
      'Yes - spend is greater than 10% of revenue, increase reserve by 10% or switch payments to CC',
    ];
  }

  getInternationalSupplier(supplierDetailsModel, internationalSupplier, amazonHealth) {
    const incorporatedInTheUS = supplierDetailsModel.incorporatedInTheUS;
    if (incorporatedInTheUS) {
      internationalSupplier.map((item) => {
        item.warning = false;
        item.actual = false;
        item.value = false;
      });
    } else {
      if (amazonHealth) {
        if (amazonHealth.seller_outside_US) {
          internationalSupplier[0].warning = amazonHealth.seller_outside_US.warning;
          internationalSupplier[0].actual = amazonHealth.seller_outside_US.warning;
          internationalSupplier[0].value = !amazonHealth.seller_outside_US.value;
        }
        if (amazonHealth.sales_in_range) {
          internationalSupplier[1].warning = amazonHealth.sales_in_range.warning;
          internationalSupplier[1].actual = amazonHealth.sales_in_range.value;
          internationalSupplier[1].value = !!amazonHealth.sales_in_range.value;
        }
        if (supplierDetailsModel.incorporatedInTheUS) {
          internationalSupplier[2].warning = supplierDetailsModel.incorporatedInTheUS;
          internationalSupplier[2].actual = supplierDetailsModel.incorporatedInTheUS;
          internationalSupplier[2].value = supplierDetailsModel.incorporatedInTheUS;
        }
        if (supplierDetailsModel.businessTypeCode) {
          internationalSupplier[3].warning = false;
          internationalSupplier[3].actual = supplierDetailsModel.businessTypeCode === 'SP';
          internationalSupplier[3].value = supplierDetailsModel.businessTypeCode === 'SP';
        }
        if (amazonHealth.year_continuous_sale) {
          internationalSupplier[4].warning = !amazonHealth.year_continuous_sale.value;
          internationalSupplier[4].actual = amazonHealth.year_continuous_sale.value;
          internationalSupplier[4].value = !!amazonHealth.year_continuous_sale.value;
        }
      }
    }
    return internationalSupplier;
  }

  getBenchmarkStatus(marketData) {
    const raw_json = marketData[0];
    if (raw_json) {
      return raw_json['Account Performance Status'];
    }
    return;
  }
}
