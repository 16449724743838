<h1 mat-dialog-title>Add Recovery</h1>

<div [formGroup]="addRecoveryForm">
  <mat-form-field class="full-width">
    <mat-select placeholder="Marketplace" formControlName="marketplace">
      <mat-option *ngFor="let marketplace of marketplaces" [value]="marketplace.id">
        {{ marketplace.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="picker" placeholder="Post date" formControlName="post_date" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Amount" type="number" step="0.01" formControlName="amount" />
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Reason" formControlName="reason" />
  </mat-form-field>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="save()" tabindex="2" [color]="'accent'">Save</button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'" tabindex="-1">Cancel</button>
</div>
