import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class LayoutService {
  now = Math.random();

  preloaderState$ = new Subject<string>();
  searchOverlayState$ = new Subject<string>();

  private echartsSource = new Subject<boolean>();

  // TODO: Set this up from Cookies or default
  public sidenavOpenState$ = new BehaviorSubject<boolean>(false);

  private customClassesSubject = new BehaviorSubject<string>('');
  customClasses$ = this.customClassesSubject.asObservable().pipe(distinctUntilChanged());

  updatePreloaderState(state: string): void {
    this.preloaderState$.next(state);
  }

  updateSearchOverlayState(state: 'close' | 'open'): void {
    this.searchOverlayState$.next(state);
  }

  toggleSidenavState(): void {
    const previousState = this.sidenavOpenState$.value;
    this.sidenavOpenState$.next(!previousState);
  }

  setClasses(classes: string): void {
    this.customClassesSubject.next(classes);
  }

  clearClasses(): void {
    this.customClassesSubject.next('');
  }
}
