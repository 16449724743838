<section class="mb-4">
  <ng-container *ngIf="report; else noData">
    <ng-container *ngIf="!report.loading; else spinner">
      <p class="card-title reportTitle">{{ report.title }}</p>
      <div [@FadeOut]="{ value: 'in', params: { maxHeight: '600px' } }">
        <ng-container *ngIf="!report.error; else reportError">
          <ng-container *ngIf="report && report.reports && report.reports.columns && report.reports.columns.length; else noData">
            <app-simple-table [data]="report.reports"></app-simple-table>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</section>

<ng-template #spinner>
  <div class="spinnerWrapper">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #noData>
  <app-no-data></app-no-data>
</ng-template>

<ng-template #reportError>
  <app-no-data [text]="errorText"></app-no-data>
</ng-template>
