import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as JWT from 'jwt-decode';
import { map } from 'rxjs/operators';
import { isUndefined } from 'util';

import { hasCorrectToken, Logout, selectLoggedUserModel } from '../../auth/store/auth.actions';
import { LOGIN_REDIRECT_URL } from '../../auth/store/auth.effects';
import { AppState } from '../../store/app.reducers';

@Injectable()
export class RouteAccessResolver implements CanActivate {
  private loginPageRoute = '/login';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private platformLocation: PlatformLocation,
    private store: Store<AppState>,
  ) {}

  canActivate() {
    return this.store.select(selectLoggedUserModel).pipe(
      map((authState) => {
        if (!isUndefined(authState)) {
          if (isUndefined(authState.loginId) || !authState.loginId) {
            // check if has filed loginId
            return this.handleLogout();
          } else if (hasCorrectToken(authState) && this.isTokenExpired(authState.user.jwtToken)) {
            // check if jwtToken is not expired?
            return this.handleLogout();
          }

          return true;
        } else {
          return this.handleLogout();
        }
      }),
    );
  }

  private handleLogout() {
    // that kind of replacing works both with # links and URIs
    const backUrl = window.location.href.replace(window.location.origin, '').replace(/^\/#\/(.*)$/, '/$1');
    if (this.loginPageRoute !== backUrl) {
      sessionStorage.setItem(LOGIN_REDIRECT_URL, backUrl);
    }

    this.store.dispatch(new Logout());
    this.router.navigate([this.loginPageRoute]);

    return false;
  }

  private isTokenExpired(jwtToken: string) {
    if (jwtToken) {
      const encodedToken: any = JWT(jwtToken);
      if (!isUndefined(encodedToken.token) && encodedToken.token < Date.now() / 1000) {
        return true;
      }
    }

    return false;
  }
}
