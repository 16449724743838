<ng-template #loading>
  <div class="tasks-filter-header">
    <app-loading-spinner [size]="1"></app-loading-spinner>
  </div>
</ng-template>

<div class="tasks-filter-header" *ngIf="filters; else loading">
  <form [formGroup]="filters" #formFilters>
    <mat-form-field class="task-id-input">
      <input matInput placeholder="Task #" aria-label="Task #" [formControl]="taskId" data-test="filtersTaskIdInput" />
      <mat-icon
        class="small-icon"
        matSuffix
        [hidden]="!taskId.value"
        (click)="clearValue('taskId', taskId); $event.preventDefault(); $event.stopPropagation()"
        >close</mat-icon
      >
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Status" formControlName="status" multiple data-test="filtersSelectStatus">
        <mat-option *ngFor="let statusItem of availableStatuses | async" [value]="statusItem.key">{{ statusItem.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Priority" formControlName="priority" multiple data-test="filtersSelectPriority">
        <mat-option *ngFor="let priorityItem of availablePriorities | async" [value]="priorityItem.key">{{ priorityItem.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="author-select">
      <input
        matInput
        placeholder="Author"
        aria-label="Author"
        [formControl]="author"
        [matAutocomplete]="authorAutocomplete"
        data-test="filtersAuthorInput"
      />
      <mat-icon
        class="small-icon"
        matSuffix
        [hidden]="!author.value"
        (click)="clearValue('author', author); $event.preventDefault(); $event.stopPropagation()"
        >close</mat-icon
      >

      <mat-autocomplete #authorAutocomplete="matAutocomplete" (optionSelected)="setAuthor($event)" [displayWith]="displayAssigneeFn">
        <mat-option *ngFor="let author of filteredAuthors | async" [value]="author">
          <span>{{ author.name }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="assignee-select">
      <input
        matInput
        placeholder="Assignee"
        aria-label="Assignee"
        [formControl]="assignee"
        [matAutocomplete]="assigneeAutocomplete"
        data-test="filtersAssigneeInput"
      />
      <mat-icon
        class="small-icon"
        matSuffix
        [hidden]="!assignee.value"
        (click)="clearValue('assignee', assignee); $event.preventDefault(); $event.stopPropagation()"
        >close</mat-icon
      >

      <mat-autocomplete #assigneeAutocomplete="matAutocomplete" (optionSelected)="setAssignee($event)" [displayWith]="displayAssigneeFn">
        <mat-option *ngFor="let assignee of filteredAssignees | async" [value]="assignee">
          <i class="material-icons option-icon">{{ assignee.type === 'group' ? 'people' : 'person' }}</i> {{ assignee.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="task-created-after-input">
      <input
        matInput
        readonly
        placeholder="Created after"
        aria-label="Created after"
        [matDatepicker]="afterPicker"
        [formControl]="createdAfter"
        (click)="afterPicker.open()"
        (dateChange)="setDate('createdAfter', $event)"
        data-test="filtersCreatedAfterInput"
      />
      <mat-icon
        class="small-icon"
        matSuffix
        [hidden]="!createdAfter.value"
        (click)="clearDate('createdAfter', createdAfter); $event.preventDefault(); $event.stopPropagation()"
        >close</mat-icon
      >

      <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
      <mat-datepicker #afterPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="task-created-before-input">
      <input
        matInput
        placeholder="Created before"
        aria-label="Created before"
        [matDatepicker]="beforePicker"
        (click)="beforePicker.open()"
        [formControl]="createdBefore"
        (dateChange)="setDate('createdBefore', $event)"
        data-test="filtersCreatedBeforeInput"
      />

      <mat-icon
        class="small-icon"
        matSuffix
        [hidden]="!createdBefore.value"
        (click)="clearDate('createdBefore', createdBefore); $event.preventDefault(); $event.stopPropagation()"
        >close</mat-icon
      >

      <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
      <mat-datepicker #beforePicker></mat-datepicker>
    </mat-form-field>
  </form>
</div>
