<article class="tl-item">
  <div class="tl-body">
    <div class="tl-entry">
      <div class="tl-icon btn-icon-round btn-icon btn-icon-thin btn-notes-payment">
        <i class="material-icons">attach_money</i>
      </div>
      <div class="tl-content">
        <!-- TOP NOTE INFO -->
        <ul class="tl-data-summary">
          <li class="pill"><i class="material-icons">person</i> {{ item.data.user.email }}</li>
          <li><i class="material-icons">event_note</i>{{ item.createdAt | date: 'short' }}</li>
          <li><i class="material-icons">update</i>{{ item.updatedAt | date: 'short' }}</li>
        </ul>

        <!-- QUICK ACTIONS -->
        <ul class="tl-action-buttons pull-right">
          <li><a app-confirm-delete (onConfirm)="deleteNote()"></a></li>
          <li>
            <a><i class="material-icons">edit</i></a>
          </li>
          <li>
            <a [ngClass]="{ active: item.isPinned }"><i class="material-icons">star</i></a>
          </li>
        </ul>

        <!-- NOTE BODY -->
        <div class="tl-content-body" #noteBody [ngClass]="{ collapsed: collapsed }">
          <div [innerHTML]="item.data.body | safe"></div>
          <a class="btn btn-expand" (click)="toggleCollapse()"><i class="material-icons">more_horiz</i></a>
        </div>

        <!-- COMMENTS SECTION -->
        <hr />
        <div [ngClass]="{ 'add-comment-active': addComment }">
          <a>Add comment</a>
        </div>
        <div *ngIf="item.latestComments && item.latestComments.length > 0">
          <hr />
          <app-comment-item [item]="item.latestComments[0]"></app-comment-item>
        </div>
      </div>
    </div>
  </div>
</article>
