<div class="status-bagde" [ngSwitch]="type">
  <span class="badge" style="background-color: #ef5350" *ngSwitchCase="'analitics'">{{ type | uppercase }}</span>
  <span class="badge" style="background-color: #66bb6a" *ngSwitchCase="'api-event'">{{ type | uppercase }}</span>
  <span class="badge" style="background-color: #5bc0de" *ngSwitchCase="'cli-event'">{{ type | uppercase }}</span>
  <span class="badge" style="background-color: #2196f3" *ngSwitchCase="'event'">{{ type | uppercase }}</span>
  <span class="badge" style="background-color: #ffd500" *ngSwitchCase="'messaging-log'">{{ type | uppercase }}</span>
  <span class="badge" style="background-color: #ff5b77" *ngSwitchCase="'messaging-result'">{{ type | uppercase }}</span>
  <span class="badge" style="background-color: #7e57c2" *ngSwitchCase="'task-change-event'">{{ type | uppercase }}</span>
  <span class="badge" style="background-color: #ffca28" *ngSwitchCase="'underwriting-event'">{{ type | uppercase }}</span>
</div>
