/* eslint-disable */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { SupplierDetailsModel } from '../../../../model/supplier.model';
import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { DefaultRadiobuttons } from '../common/consts/underwritings-initial-constants';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';
/* eslint-enable */

@Component({
  selector: 'app-international-supplier',
  templateUrl: './international-supplier.component.html',
})
export class InternationalSupplierComponent implements OnChanges {
  @Input() supplierDetailsModel: SupplierDetailsModel;
  @Input() internationalSupplierOverride: any;
  @Input() internationalSupplierDecision: any;
  @Input() internationalSupplier: any;
  @Input() dataFetch: boolean;

  @Output() onInternationalSupplierOverrideChange = new EventEmitter();

  readonly userPermission = USER_PERMISSION;

  headerType: UnderwritingInitialCardHeaderStatusType;
  headerStatus: string;
  radiobuttons = DefaultRadiobuttons;

  ngOnChanges(changes) {
    if (this.supplierDetailsModel) {
      this.setHeaderDetails();
    }
  }

  setHeaderDetails() {
    if (!this.supplierDetailsModel.incorporatedInTheUS) {
      if (this.isSuccessHeaderType()) {
        this.headerType = UnderwritingInitialCardHeaderStatusType.SUCCESS;
        this.headerStatus = 'Valid';
      }
      if (this.isDangerHeaderType()) {
        this.headerType = UnderwritingInitialCardHeaderStatusType.DANGER;
        this.headerStatus = 'Needs review';
      }
    }
  }

  isSuccessHeaderType() {
    return (
      this.internationalSupplierOverride[0].value || (!this.internationalSupplierOverride[0].value && !this.internationalSupplierDecision)
    );
  }

  isDangerHeaderType() {
    return !this.internationalSupplierOverride[0].value && !this.internationalSupplierDecision;
  }

  radiobuttonsChange(value) {
    if (!this.internationalSupplierOverride[0]) {
      return;
    }
    this.internationalSupplierOverride[0].value = value;
    this.onInternationalSupplierOverrideChange.emit(this.internationalSupplierOverride);
  }
}
