<div *ngIf="form" [formGroup]="form">
  <mat-form-field class="full-width">
    <mat-select placeholder="Template name" formControlName="templateName">
      <mat-option>None</mat-option>
      <mat-option *ngFor="let template of taskTemplatesOptions$ | async" [value]="template.configKey">
        {{ template.configValue.label ? template.configValue.label : template.configKey }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Advanced </mat-panel-title>
    </mat-expansion-panel-header>

    <div formArrayName="data">
      <h5 class="mb-0">Item data</h5>
      <small> Task properties like <i>priority</i> etc. </small>
      <div *ngFor="let control of data.controls; let i = index" [formGroupName]="i" class="row">
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Task property" formControlName="propName" (keyup)="util.tryAddNext(data, i, util.dataModel())" />
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Value" formControlName="value" />
          <button matSuffix class="col-md-1" mat-icon-button (click)="data.removeAt(i)" *ngIf="data.length > 1">
            <mat-icon>c</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div formArrayName="contextMapping">
      <h5 class="mb-0">Template context mapping</h5>
      <small> If template needs some context variables you can specify them here. </small>
      <div *ngFor="let control of contextMapping.controls; let i = index" [formGroupName]="i" class="row">
        <mat-form-field class="col-md-6">
          <input
            matInput
            placeholder="Template property"
            formControlName="propName"
            (keyup)="util.tryAddNext(contextMapping, i, util.contextModel())"
          />
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Selector" formControlName="selector" />
          <button matSuffix mat-icon-button (click)="contextMapping.removeAt(i)" *ngIf="contextMapping.length > 1">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div formArrayName="dataMapping">
      <h5 class="mb-0">Item data mapping</h5>
      <small> Task property will be updated with data from event pointed by <b>selector</b>. </small>
      <div *ngFor="let control of dataMapping.controls; let i = index" [formGroupName]="i" class="row">
        <mat-form-field class="col-md-6">
          <input
            matInput
            placeholder="Task property"
            formControlName="propName"
            (keyup)="util.tryAddNext(dataMapping, i, util.contextModel)"
          />
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Selector" formControlName="selector" />
          <button matSuffix mat-icon-button (click)="dataMapping.removeAt(i)" *ngIf="dataMapping.length > 1">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</div>
