import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { DataTableQueryParams, DataTableSettingsRecord } from '../../../../shared/data-table/data-table.common';
import { SupplierService } from '../../../services/supplier.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { ROW_COUNT_COLUMN_DEF } from './../../../../shared/data-table-grid/models/row-count-column-def';

@Component({
  selector: 'app-nested-transaction',
  templateUrl: './nested-transaction.component.html',
  styleUrls: ['./nested-transaction.component.scss'],
})
export class NestedTransactionComponent extends SubscriberComponent implements OnInit {
  // Fields
  public readonly reportName: string = 'xacts';
  public tableSettings: DataTableSettingsRecord;
  public title: string;
  public instanceId = 'nestedHotDataTable';

  xactData = [];
  isDataLoading = false;
  readonly xactColumnDefs = [
    ROW_COUNT_COLUMN_DEF,
    {
      field: 'detailText',
      headerName: 'Description',
      width: 250,
    },
    {
      field: 'detailAmount',
      headerName: 'Amount',
    },
    {
      field: 'availForSupplierChange',
      headerName: 'Change',
    },
    {
      field: 'availForSupplierBalance',
      headerName: 'Balance',
    },
    {
      field: 'reserveChange',
      headerName: 'Change',
    },
    {
      field: 'reserveBalance',
      headerName: 'Balance',
    },
    {
      field: 'dueFromMarketChange',
      headerName: 'Non-specific Change',
      width: 95,
    },
    {
      field: 'dueFromMarketBalance',
      headerName: 'Non-specific Balance',
      width: 95,
    },
    {
      field: 'dueFromMarketUnpurchChange',
      headerName: 'Un-purch Change',
      width: 95,
    },
    {
      field: 'dueFromMarketUnpurchBalance',
      headerName: 'Un-purch Balance',
      width: 95,
    },
    {
      field: 'dueFromMarketPurchChange',
      headerName: 'Purch Change',
    },
    {
      field: 'dueFromMarketPurchBalance',
      headerName: 'Purch Balance',
    },
    {
      field: 'reserveBaseChange',
      headerName: 'Change',
    },
    {
      field: 'reserveBaseBalance',
      headerName: 'Balance',
    },
    {
      field: 'balBasedFeeBaseChange',
      headerName: 'Change',
    },
    {
      field: 'balBasedFeeBaseBalance',
      headerName: 'Balance',
    },
  ];

  private supplierKey: string;
  private transactionKey: string;
  private readonly MAX_LIMIT = 99999999;

  constructor(
    private service: SupplierService,
    public dialogRef: MatDialogRef<NestedTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    if (!isNullOrUndefined(data)) {
      this.title = data.title;
      this.supplierKey = data.supplierKey;
      this.transactionKey = data.transactionKey;
    }
  }

  // Methods
  public ngOnInit(): void {
    this.getData();
  }

  private getData(params?: DataTableQueryParams): void {
    if (!params) {
      params = {
        limit: this.MAX_LIMIT,
        offset: 0,
      };
    }

    this.isDataLoading = true;
    this.subscriptions.push(
      this.service
        .getSupplierNestedReport(this.supplierKey, this.reportName, this.transactionKey, params)
        .pipe(
          tap(() => {
            this.isDataLoading = false;
          }),
        )
        .subscribe((data) => {
          this.xactData = data.results;
        }),
    );
  }
}
