export default {
  menubar: true,
  height: 300,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor noneditable',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount link',
  ],
  toolbar:
    'formatselect fontsizeselect fontselect | bold italic backcolor | \
    alignleft aligncenter alignright alignjustify | link code',
  verify_html: false,
  paste_data_images: true,
  forced_root_block: 'p',
  noneditable_noneditable_class: 'is-locked',
};
