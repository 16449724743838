import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SupplierMarketplaceDetailsModel } from '../../../model/supplier-marketplace.model';
import { SupplierMarketplaceFundingModel } from '../../../model/supplier-marketplace-funding.model';
import { SupplierMarketplaceFormTypes } from './supplier-marketplace-form-types';

@Injectable()
export class SupplierMarketplaceForm {
  constructor(private formBuilder: FormBuilder) {}

  initAuthTokenFormGroups(authTokenModel: { token: string; fake: boolean }) {
    const formGroups: { [key: string]: FormGroup } = {};

    formGroups[SupplierMarketplaceFormTypes.AUTH_TOKEN_FORM] = this.formBuilder.group({
      authToken: authTokenModel.token,
    });

    return formGroups;
  }

  initFundingFormGroups(fundingModel: SupplierMarketplaceFundingModel) {
    const formGroups: { [key: string]: FormGroup } = {};

    formGroups[SupplierMarketplaceFormTypes.FUNDING_FORM] = this.formBuilder.group({
      fundPartStatus: fundingModel.fundPartStatus,
      advanceRate: fundingModel.advanceRate * 100,
      minimumReservePct: fundingModel.minimumReservePct * 100,
      creditLimit: fundingModel.creditLimit,
      concentrationLimit: fundingModel.concentrationLimit,
      fundingPeriodGroup: fundingModel.fundingPeriodGroup,
    });

    formGroups[SupplierMarketplaceFormTypes.FEE_SETTINGS_FORM] = this.formBuilder.group({
      currentFeeScheduleKey: fundingModel.currentFeeScheduleKey,
      postTrialFeeScheduleKey: fundingModel.postTrialFeeScheduleKey,
      mktPmtLag: fundingModel.mktPmtLag,
      freeTrialEndDate: fundingModel.freeTrialEndDate,
    });

    formGroups[SupplierMarketplaceFormTypes.FEE_DATES_FORM] = this.formBuilder.group({
      currMinFeeStartDate: fundingModel.currMinFeeStartDate,
      currMinFeeEndDate: fundingModel.currMinFeeEndDate,
    });

    return formGroups;
  }

  initGeneralFormGroups(marketplaceModel: SupplierMarketplaceDetailsModel) {
    const formGroups: { [key: string]: FormGroup } = {};

    formGroups[SupplierMarketplaceFormTypes.REVENUE_FORM] = this.formBuilder.group({
      estimatedMonthlyRevenue: marketplaceModel.estimatedMonthlyRevenue,
      lengthTimeSelling: marketplaceModel.lengthTimeSelling,
    });

    formGroups[SupplierMarketplaceFormTypes.PAYMENT_FORM] = this.formBuilder.group({
      paymentTerms: marketplaceModel.paymentTerms,
      paymentTermsDays: marketplaceModel.paymentTermsDays,
      paymentFrequency: marketplaceModel.paymentFrequency,
      loadTransactionWithDelayPayment: marketplaceModel.loadTransactionWithDelayPayment,
      overrideDepositAccount: marketplaceModel.overrideDepositAccount,
      overrideDeltaCheck: marketplaceModel.overrideDeltaCheck,
      overrideDailyPlaidCheck: marketplaceModel.overrideDailyPlaidCheck,
    });

    formGroups[SupplierMarketplaceFormTypes.INSTANT_FORM] = this.formBuilder.group({
      payAdvance: marketplaceModel.payAdvance,
    });

    formGroups[SupplierMarketplaceFormTypes.API_STATUS_FORM] = this.formBuilder.group({
      apiStatus: marketplaceModel.apiStatus,
      ascSellerId: marketplaceModel.ascSellerId,
      ascMarketplaceId: marketplaceModel.ascMarketplaceId,
      ascDevAccountNumber: marketplaceModel.ascDevAccountNumber,
      ascAwsAccessKeyId: marketplaceModel.ascAwsAccessKeyId,
      ascAwsSecretKey: marketplaceModel.ascAwsSecretKey,
      lastCheckedTs: this.prepareDate(marketplaceModel.lastCheckedTs),
      apiLastCheckedTs: this.prepareDate(marketplaceModel.apiLastCheckedTs),
    });

    formGroups[SupplierMarketplaceFormTypes.PAYMENT_PROCESSOR_SETTINGS] = this.formBuilder.group({
      bankAccountNumber: marketplaceModel.bankAccountNumber,
    });

    return formGroups;
  }

  initMarketplaceAccessFormGroup(marketplaceModel: SupplierMarketplaceDetailsModel) {
    const formGroups: { [key: string]: FormGroup } = {};

    formGroups[SupplierMarketplaceFormTypes.MARKETPLACE_ACCESS_FORM] = this.formBuilder.group({
      accessStatus: marketplaceModel.accessStatus,
      externalSupplierId: marketplaceModel.externalSupplierId,
      externalLogin: marketplaceModel.externalLogin,
      externalPassword: marketplaceModel.externalPassword,
    });

    return formGroups;
  }

  private prepareDate(date: any): Date {
    if (date !== null && String(new Date(date)) !== 'Invalid Date') {
      return new Date(date);
    }

    if (date && String(new Date(date.slice(0, -3))) !== 'Invalid Date') {
      return new Date(date.slice(0, -3));
    } else {
      return null;
    }
  }
}
