import { PlatformLocation } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppConfigService } from '../../../config';

const HASH_LENGTH_TO_SHOW = 8;

@Component({
  selector: 'app-stage-info',
  templateUrl: './app-stage-info.component.html',
  styleUrls: ['./app-stage-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppStageInfoComponent implements OnInit {
  stageName$: Observable<string>;

  private appBaseUrl: string;

  constructor(private platformLocation: PlatformLocation, private appConfig: AppConfigService) {
    this.appBaseUrl = (<any>this.platformLocation).location.origin.split('//')[1];
  }

  ngOnInit() {
    this.stageName$ = this.getStageName();
  }

  getStageName(): Observable<string> {
    // return of(`${this.getAppBaseUrl()} ${this.getAdditionalInformation()}`);
    return of('');
  }

  getAppBaseUrl(): string {
    return this.removeConstantPartsFromUrl(this.appBaseUrl);
  }

  getAdditionalInformation(): string {
    if (!this.appConfig) {
      return '';
    }

    if (!('test' === this.appConfig.env.envName)) {
      return '';
    }

    return `${this.getConnectedBackend()} ${this.getGitHash()} ${this.getBuildDate()}`;
  }

  getConnectedBackend(): string {
    const backendUrl = this.removeConstantPartsFromUrl(this.appConfig.env.internalApiUrl.split('//')[1].split('/')[0]);
    if (!backendUrl.includes('test')) {
      return '';
    }
    return backendUrl;
  }

  getGitHash(): string {
    if (!this.appConfig.env.gitHash) {
      return '';
    }

    return `[${this.appConfig.env.gitHash.substring(0, HASH_LENGTH_TO_SHOW)}]`;
  }

  removeConstantPartsFromUrl(url: string): string {
    return url.replace('.payability.com', '').replace('-api', '').replace('-internal', '');
  }

  getBuildDate(): string {
    if (!this.appConfig.env.lastBuildDate) {
      return '';
    }

    return this.appConfig.env.lastBuildDate;
  }
}
