import { Injectable } from '@angular/core';

export const STATUSES = {
  supFundPartStatusDictionary: {
    'Not Selected': 'badge badge-light',
    Selected: 'badge badge-warning',
    Sent: 'badge badge-light',
    Rejected: 'badge badge-danger',
    Declined: 'badge badge-danger',
    Approved: 'badge text-success',
  },

  mpFundPartStatusDictionary: {
    'Not Selected': 'badge badge-light',
    Sent: 'badge badge-light',
    'Not Sent': 'badge badge-light',
    Rejected: 'badge badge-danger',
    Declined: 'badge badge-danger',
    Approved: 'badge text-success',
  },

  payConfigStatusDictionary: {
    Approved: 'badge text-success',
    'Not provided': 'badge badge-muted',
    Pending: 'badge badge-warning',
    Rejected: 'badge badge-danger',
  },

  supplierStatusDictionary: {
    Active: 'badge text-success',
    Suspended: 'badge badge-warning',
  },

  supplierCancelationStatusDictionary: {
    none: 'badge badge-light',
    pending: 'badge badge-warning',
    canceled: 'badge badge-danger',
  },

  advanceStatusDictionary: {
    Active: 'badge text-success',
    'Offer Pending': 'badge badge-warning',
    Closed: 'badge badge-light',
    None: 'badge badge-light',
  },
};

@Injectable()
export class SupplierStatusesService {
  public getSupFundPartStatusStyle(status) {
    return STATUSES.supFundPartStatusDictionary[status] || 'badge badge-light';
  }

  public getMpFundPartStatusStyle(status) {
    return STATUSES.mpFundPartStatusDictionary[status] || 'badge badge-light';
  }

  public getSupplierStatusStyle(status) {
    return STATUSES.supplierStatusDictionary[status] || 'badge badge-light';
  }

  public getPayConfigStatusStyle(status) {
    return STATUSES.payConfigStatusDictionary[status] || 'badge badge-light';
  }

  public getCancelationStatusStyle(status) {
    return STATUSES.supplierCancelationStatusDictionary[status] || 'badge badge-light';
  }

  public getAdvanceStatusStyle(status) {
    return STATUSES.advanceStatusDictionary[status] || 'badge badge-light';
  }
}
