<div class="ui-timline-container ui-timline-left" infiniteScroll [scrollWindow]="false" (scrolled)="onScroll()">
  <section class="ui-timeline" *ngIf="crmItems && uuids" [@listAnimation]="crmItems && uuids" [@listAnimation]="crmItems && uuids">
    <div *ngFor="let itemUuid of uuids" [ngSwitch]="crmItems[itemUuid].type">
      <app-note-item *ngSwitchCase="'note'" [item]="crmItems[itemUuid]" data-test="crmAllItemNote"></app-note-item>
      <app-note-payment-item
        *ngSwitchCase="'payment-note'"
        [item]="crmItems[itemUuid]"
        data-test="crmAllItemNotePayment"
      ></app-note-payment-item>
      <app-event-item *ngSwitchCase="'event'" [item]="crmItems[itemUuid]" data-test="crmAllItemEvent"></app-event-item>
      <app-task-item *ngSwitchCase="'task'" [item]="crmItems[itemUuid]" data-test="crmAllItemTask"></app-task-item>
      <app-file-item *ngSwitchCase="'file'" [item]="crmItems[itemUuid]" data-test="crmAllItemFile"></app-file-item>
      <app-email-item *ngSwitchCase="'email_supplier'" [item]="crmItems[itemUuid]" data-test="crmAllItemEmailSupplier"></app-email-item>
      <app-email-item *ngSwitchCase="'email'" [item]="crmItems[itemUuid]" data-test="crmAllItemEmail"></app-email-item>
      <app-email-item *ngSwitchCase="'batch'" [item]="crmItems[itemUuid]" data-test="crmAllItemBatchEmail"></app-email-item>
    </div>
    <div>
      <article class="tl-item">
        <div class="tl-body">
          <div class="tl-entry">
            <div class="tl-icon btn-icon-round btn-icon btn-icon-thin btn-primary" data-test="crmAllTabRefresh">
              <i class="material-icons" (click)="fetchItems()" [ngClass]="{ 'fa-spin': pending && !loaded }">refresh</i>
            </div>
            <div class="tl-load-more" *ngIf="!loaded">
              <a (click)="onScroll()">{{ pending ? 'Loading...' : 'Load more...' }}</a>
            </div>
          </div>
        </div>
      </article>
    </div>
  </section>
</div>
