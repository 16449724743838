<div>
  <mat-card class="mb-4">
    <mat-card-title>
      <div class="row">
        <div class="col-md-6">Payability UCCs</div>
      </div>
      <button [disabled]="isRestrictedUWView" (click)="redirect()" color="accent" class="add-button" mat-raised-button>Add UCC</button>
    </mat-card-title>

    <mat-card-content>
      <div class="email-settings-table">
        <mat-table #table [dataSource]="dataSource" matSort [matSortDirection]="'desc'">
          <ng-container matColumnDef="Date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date Filed</mat-header-cell>
            <mat-cell class="link pointer" *matCellDef="let element"
              ><span (click)="redirect(element)"> {{ element.filingDate | date: 'M/d/y' }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Filing Number</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.filingNumber }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="subject">
            <mat-header-cell *matHeaderCellDef>Filing Type</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.filingType }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fillingId">
            <mat-header-cell *matHeaderCellDef>Filing Id</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.filingId }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="senior">
            <mat-header-cell *matHeaderCellDef>Senior</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ isSenior(element.senior) }} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="payabilityDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: payabilityDisplayedColumns"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>
      <div class="row">
        <div class="col-md-6">
          UCCs from Reuters S2S
          <p *ngIf="transUnion && transUnion.results && transUnion.results.timestamp; else noTUdone">
            Last checked: {{ transUnion.results.timestamp | date: "MM/dd/yyyy 'at' h:mma" }}
          </p>
          <ng-template #noTUdone>
            <p>Last checked: never</p>
          </ng-template>
          <p *ngIf="transUnion && transUnion.results && transUnion.results.person">
            Records found: {{ transUnion.results.person.data.NumberOfRecordsFound }}
          </p>
        </div>
      </div>
    </mat-card-title>

    <mat-card-content>
      <div class="email-settings-table">
        <mat-table #table1 [dataSource]="transunionDataSource">
          <ng-container matColumnDef="filingDate">
            <mat-header-cell *matHeaderCellDef>Date field</mat-header-cell>
            <mat-cell class="link pointer" *matCellDef="let element"
              ><span (click)="redirect(element)"> {{ element.filingDate | date: 'M/d/y' }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="filingNumber">
            <mat-header-cell *matHeaderCellDef>Filing Number</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.filingNumber }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="filingType">
            <mat-header-cell *matHeaderCellDef>Filing Type</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.filingType }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="debtorName">
            <mat-header-cell *matHeaderCellDef>Debtor Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.debtorName }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="debtorState">
            <mat-header-cell *matHeaderCellDef>Debtor State</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.debtorState }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="securedPartyName">
            <mat-header-cell *matHeaderCellDef>Secured Party Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.securedPartyName }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="securedPartyBusinessName">
            <mat-header-cell *matHeaderCellDef>Secured Party Business Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.securedPartyBusinessName }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="collateralDescription">
            <mat-header-cell *matHeaderCellDef>Collateral Description</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.collateralDescription">
                {{ element.collateralDescription }}
              </span>
              <span *ngIf="!element.collateralDescription"> Not Applicable </span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator #paginatorTransUnion [pageSize]="10" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
