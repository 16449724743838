// This component will be removed after check that IA works good without handsontable
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

import { SubscriberComponent } from '../component-subscriber/subscriber.component';
import { HeadersWithSummedValuesSettingService } from '../data-table-ag-grid/services/headers-with-summed-values-setting.service';
import { contextMenuItemsGetter } from '../functions/get-context-menu-items';
import { HeaderCellIconRenderer } from '../utils/functions/header-cell-icon-renderer';
import { AgGridDataReshaperService } from './ag-greed-data-reshaper.service';
import { IColumnPredefinition } from './models/default-column-definition';
import { ROW_COUNT_COLUMN_DEF } from './models/row-count-column-def';

const EXPAND_COLLAPSE_BUTTON_VALUES = {
  expanded: 'Expand All',
  collapsed: 'Collapse All',
};

const EXPAND_COLLAPSE_ICON_VALUES = {
  expandedIcon: 'unfold_more',
  collapsedIcon: 'unfold_less',
};

@Component({
  selector: 'app-data-table-grid',
  templateUrl: './data-table-grid.component.html',
  styleUrls: ['./data-table-grid.component.scss'],
})
export class DataTableGridComponent extends SubscriberComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() rows: any;
  @Input() set columnDefs(columnDefs: IColumnPredefinition[]) {
    this.columnDefinitions = HeaderCellIconRenderer(columnDefs);
  }
  @Input() summed?: any;
  @Input() sideBar: any;
  @Input()
  set treeData(value: boolean) {
    this.gridOptions.treeData = value;
  }
  @Input() offset: number;
  @Input() nodesCounted: boolean;

  @Input() instanceId?: string;
  @Input() tableSettings?: any;
  @Input() exportOptions?: any;
  @Input() domLayout?: string;
  @Input() customGridOptions: GridOptions;

  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  columnDefinitions: IColumnPredefinition[];
  gridOptions: GridOptions | { treeData: any; api: GridApi };
  isExpanded = false;
  expandedButtonText: string;
  expandedButtonIcon: string;
  gridApi: GridApi;
  isReady = false;
  private columnApi;

  constructor(
    private reshaperService: AgGridDataReshaperService,
    private headerWithSummedValuesSettingsService: HeadersWithSummedValuesSettingService,
  ) {
    super();

    this.gridOptions = {
      getContextMenuItems: contextMenuItemsGetter,
      onGridReady: (event) => this.onGridReady(event),
      onSortChanged: (event) => this.sortingChanged(),
      api: new GridApi(),
      popupParent: document.querySelector('body'),
      headerHeight: 50,
      defaultColDef: {
        suppressMenu: true,
        width: 150,
        suppressSizeToFit: false,
        resizable: true,
        sortable: true,
        filter: true,
      },
      autoGroupColumnDef: {
        headerName: '',
        width: 40,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: () => '',
        },
      },
      groupDefaultExpanded: 0,
      getDataPath(data) {
        return data.dataPath;
      },
      treeData: false,
      enableRangeSelection: true,
      pagination: false,
      cacheBlockSize: 20,
    } as GridOptions;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isReady) {
      return;
    }

    const changesInRows: SimpleChange = changes.rows;
    if (!changesInRows || !changesInRows.currentValue) {
      return;
    }
    this.gridOptions.api.setRowData(changesInRows.currentValue);
    this.countNodes();

    if (changesInRows.currentValue.length !== changesInRows.previousValue.length) {
      this.gridOptions.api.redrawRows();
    }
  }

  expandAllRows() {
    this.gridOptions.api.forEachNode((node) => {
      node.expanded = !node.expanded;
      this.isExpanded = node.expanded;
      this.expandCollapseValuesSetter();
    });
    this.gridOptions.api.onGroupExpandedOrCollapsed();
  }

  expandCollapseValuesSetter() {
    this.expandedButtonText = this.isExpanded ? EXPAND_COLLAPSE_BUTTON_VALUES.collapsed : EXPAND_COLLAPSE_BUTTON_VALUES.expanded;
    this.expandedButtonIcon = this.isExpanded ? EXPAND_COLLAPSE_ICON_VALUES.collapsedIcon : EXPAND_COLLAPSE_ICON_VALUES.expandedIcon;
  }

  ngOnInit() {
    this.expandCollapseValuesSetter();
    this.gridOptions = {
      ...this.gridOptions,
      ...this.customGridOptions,
    };
  }

  countNodes() {
    this.columnDefinitions.unshift(ROW_COUNT_COLUMN_DEF);
    this.gridOptions.api.setRowData(this.rows);
  }

  onGridReady(gridParams: GridReadyEvent): void {
    this.columnApi = gridParams.columnApi;
    this.gridApi = gridParams.api;
    if (this.nodesCounted) {
      this.countNodes();
    }
    this.gridOptions.api.setColumnDefs(this.columnDefinitions);
    this.gridOptions.api.setRowData(this.rows);
    this.isReady = true;
  }

  sortingChanged() {
    this.gridApi.redrawRows();
  }
}
