import { Injectable } from '@angular/core';
import { CookieService as NgxCoookieService } from 'ngx-cookie-service';

import { CookieOptions } from './cookie-options';

@Injectable()
export class CookieService {
  constructor(private cookieService: NgxCoookieService) {}

  doesCookieExist(name: string): boolean {
    return this.cookieService.check(name);
  }

  getCookie(cookieName: string): string {
    return this.cookieService.get(cookieName);
  }

  setCookie(name: string, value: string, options: CookieOptions = {}): void {
    const { expires, path, domain, secure, sameSite } = options;
    this.cookieService.set(name, value, expires, path, domain, secure, sameSite);
  }

  delete(name: string, options: CookieOptions = {}): void {
    const { path, domain, secure, sameSite } = options;
    this.cookieService.delete(name, path, domain, secure, sameSite);
  }

  getAllCookies(): { [key: string]: string } {
    return this.cookieService.getAll();
  }

  deleteAllCookies(): void {
    return this.cookieService.deleteAll();
  }
}
