import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { BaseFilterControl } from '../base-filter-control';
import { FilteringPanelService } from './../../services/filtering-panel-service/filtering-panel.service';

@Component({
  selector: 'app-filter-control-datepicker',
  templateUrl: './filter-control-datepicker.component.html',
  styleUrls: ['./filter-control-datepicker.component.scss'],
})
export class FilterControlDatepickerComponent extends BaseFilterControl implements OnInit {
  @ViewChild('el', { static: true }) el: ElementRef;

  isConnecting: boolean;

  constructor(protected filteringPanelService: FilteringPanelService) {
    super(filteringPanelService);
  }

  ngOnInit() {
    if (this.el?.nativeElement) {
      this.el.nativeElement.value = this.value;
    }
  }
}
