import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-marketplaces',
  templateUrl: './supplier-marketplaces.component.html',
})
export class SupplierMarketplacesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
