import { Injectable } from '@angular/core';
import { Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import { Observable, of as observableOf } from 'rxjs';

import { SupplierAdvancesService } from '../../../service/supplier-advances.service';
import { ProgressivePricingOfferModel } from '../models/progressive-pricing-offer.model';
import { CalculatorState } from '../store/calculator-interfaces';
import { ProgressivePricingSettings } from './calculator-data-fixture';

@Injectable()
export class PendingOfferRiskRatingService {
  private defaultProgressiveAdvanceSettings: any;

  constructor(private supplierAdvancesService: SupplierAdvancesService) {}

  prepareDataToSave(state: CalculatorState, requestKey?: string) {
    SerializeKeysTo(UnderscoreCase);
    const dataToSave = new ProgressivePricingOfferModel({
      requestedAmount: state.requested.givenAmount,
      approvedAmount: state.approvedAmount,
      averageFee: state.averageFee,
      reserveForRepayments: state.reserveForRepayments,
      netDeliveryAmount: state.netDeliveryAmount,
      periods: state.periods,
      totalFee: state.totalFee,
      totalPeriodLength: state.totalPeriodLength,
      riskRating: state.underwritingRiskRating,
      requestNotes: state.requestNotes,
      underwriterNotes: state.underwriterNotes,
      recommendedAmount: state.recommended.givenAmount,
      extraReservePercent: state.reserveForRepayments,
      weeklyFeePercent: 1,
      requestKey,
      gracePeriodLength: state.gracePeriodEndDate,
      noRebate: state.noRebate,
      returnStrategy: state.returnStrategy,
      forceFull: state.forceFull,
      expectedMonthlyEarnings: state.expectedMonthlyEarnings,
      expectedDuration: state.expectedDuration,
    });
    const serializedData = Serialize(dataToSave, ProgressivePricingOfferModel);
    return serializedData;
  }

  getDefaultProgressiveAdvanceSettings(): Observable<any> {
    if (!this.defaultProgressiveAdvanceSettings) {
      this.defaultProgressiveAdvanceSettings = ProgressivePricingSettings;
    }
    return observableOf(this.defaultProgressiveAdvanceSettings);
  }

  saveProgressivePricingOffer(state: CalculatorState, supplierKey: string): Observable<any> {
    return this.supplierAdvancesService.createOfferRequest(supplierKey, this.prepareDataToSave(state));
  }

  updateProgressivePricingOffer(state: CalculatorState, requestKey: string): Observable<any> {
    const newState = { ...state, approvedAmount: +state.netDeliveryAmount };
    return this.supplierAdvancesService.postRequest(this.prepareDataToSave(newState, requestKey));
  }
}
