<section class="space-lg">
  <div class="container-fluid">
    <div class="pb-3">
      <button mat-raised-button class="mat-raised mr-3" [routerLink]="['feature-flags']" routerLinkActive="mat-accent">
        Feature Flags
      </button>
      <button mat-raised-button class="mat-raised" [routerLink]="['qa']" routerLinkActive="mat-accent">QA</button>
    </div>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
