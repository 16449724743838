<div class="row">
  <form *ngIf="form" [formGroup]="form" novalidate class="col-md-4">
    <mat-form-field class="full-width">
      <input matInput placeholder="Rule name*" formControlName="name" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-select placeholder="Event Type*" formControlName="eventType">
        <mat-option *ngFor="let type of eventTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-select placeholder="Target Type" formControlName="targetType">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let targetType of eventTargets" [value]="targetType">
          {{ targetType }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="Message contains" formControlName="message" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="Event Identifier" formControlName="eventTypeCode" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="User" formControlName="user" />
    </mat-form-field>

    <div formArrayName="filters">
      <div *ngFor="let control of filters.controls; let i = index">
        <mat-form-field class="full-width">
          <input matInput placeholder="Custom filter" [formControlName]="i" (keyup)="tryAddNext(filters, i, filterModel)" />
          <mat-hint align="start">Example: data.extra.payload.advanceTotalAmount > 500</mat-hint>
          <button matSuffix mat-icon-button (click)="filters.removeAt(i)" [disabled]="i == filters.length - 1">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="py-2 text-right">
      <button mat-raised-button color="primary" matStepperNext>Next</button>
    </div>
  </form>
  <div class="col-md-8 px-4">
    <b>Found {{ eventsCount$ | async }} events</b>
    <span *ngIf="(selectorValue$ | async)?.filters?.length > 0">(custom filters do not affect results)</span>
    <!--<mat-paginator #pagination [length]="pagesCount$ | async "-->
    <!--[pageSize]="10"-->
    <!--[pageSizeOptions]="[5, 10, 25, 100]">-->
    <!--</mat-paginator>-->
    <mat-accordion>
      <mat-expansion-panel *ngFor="let event of exampleEvents$ | async">
        <mat-expansion-panel-header>
          <small class="truncate col-md-5">{{ event.data.message || event.data.subject || event.data.targetType }}</small>
          <small class="col-md-4">{{ event.created_at | fromUnix | date: 'M/d/y h:mm a' }}</small>
          <small class="col-md-3">{{ event?.data?.user?.email }}</small>
        </mat-expansion-panel-header>

        <pre>{{ event | json }}</pre>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
