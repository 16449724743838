<h1 mat-dialog-title>Confirmation required</h1>

<div class="confirmation">
  {{ confirmationMessage }}
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Yes</button>
  <button mat-raised-button (click)="closeDialog(false)" color="warn" tabindex="-1">No</button>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
