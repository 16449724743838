import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  template: `
    <div class="spinner-holder">
      <mat-progress-spinner
        [mode]="'indeterminate'"
        [diameter]="this.size * 20"
        [strokeWidth]="this.size * 2"
        [ngClass]="color"
      ></mat-progress-spinner>
    </div>
  `,
  styles: [
    `
      .spinner-holder {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }

      .small .mat-progress-spinner {
        width: 60px;
        height: 60px;
      }

      ::ng-deep .white circle {
        stroke: white;
      }
      .white ::ng-deep circle {
        stroke: white;
      }
      .black ::ng-deep circle {
        stroke: black;
      }
    `,
  ],
})
export class LoadingSpinnerComponent {
  @Input() size = 5;
  @Input() color;

  constructor() {}
}
