import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectLoggedUserModel } from '../../../../../auth/store/auth.actions';
import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { UserModel } from '../../../../../shared/users/user.model';
import { AppState } from '../../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../../model/supplier.model';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { IOnReady } from '../real-time-validator.interfaces';

@Component({ template: '' })
export class BaseUnderwritingComponent extends SubscriberComponent implements OnInit, IOnReady {
  public ready = false;
  public supplier: SupplierDetailsModel;
  public supplierKey: string;
  public loggedUser$: Observable<UserModel>;

  constructor(protected store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplier: SupplierDetailsModel) => {
        if (supplier !== undefined) {
          this.supplier = supplier;
          this.supplierKey = supplier.supplierKey;
          this.preReady();
        }
      }),
    );
    this.loggedUser$ = this.store.select(selectLoggedUserModel);
  }

  private preReady(): void {
    this.ready = true;
    this.onReady();
  }

  onReady(): void {
    console.error('Admin: Please implements onReady function!!');
  }
}
