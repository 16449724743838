import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cli-action-results',
  templateUrl: './cli-action-results.component.html',
  styleUrls: ['./cli-action-results.component.scss'],
})
export class CliActionResultsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
