import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-supplier-list',
  template: '',
})
export class SupplierListComponent {
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.navigate(['../../welcome'], { relativeTo: this.route });
  }
}
