import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, merge, share, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { eventTargets, eventTypes, Filter } from '../../event-rule.models';
import { EventsService } from '../../events.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-selector-form',
  templateUrl: './selector-form.component.html',
  styleUrls: ['./selector-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectorFormComponent extends SubscriberComponent implements OnInit {
  @Input() topForm: FormGroup;
  @Input() loaded$: Observable<any>;
  form: FormGroup;
  eventTypes = eventTypes;
  eventTargets = eventTargets;
  filters: FormArray;
  exampleEvents$: Observable<any>;
  eventsCount$: Observable<number>;
  public selectorValue$: Observable<any>;

  // @ViewChild(MatPaginator) pagination;

  constructor(private fb: FormBuilder, private eventsService: EventsService, private toastrService: ToastrService) {
    super();

    this.form = this.fb.group({
      eventType: ['', Validators.required],
      name: ['', Validators.required],
      targetType: [''],
      message: [''],
      eventTypeCode: [''],
      user: [''],
      filters: this.fb.array([this.filterModel()]),
    });
    this.filters = this.form.get('filters') as FormArray;

    this.selectorValue$ = this.form.valueChanges.pipe(
      filter(() => this.form.valid),
      debounceTime(500),
      distinctUntilChanged(),
      map((value) => Object.assign({}, value)),
      map(this.skipEmpty),
      map(this.parseCustomFilters.bind(this)),
      share(),
    );

    if (this.selectorValue$) {
      const events$ = of({ hits: { total: { value: 0 }, hits: [] } }).pipe(
        first(),
        tap(() => this.toastrService.info('Elasticsearch is disabled. No events will be shown.')),
      );

      // Deprecated. Only for historical purposes.
      // const events$ = this.selectorValue$.pipe(
      //   map(this.toElasticSearchQuery),
      //   switchMap((query) => this.eventsService.query(query, 10)),
      //   catchError((err) => {
      //     this.toastrService.error('Error while querying elasticsearch');
      //     throw err;
      //   }),
      //   share(),
      // );

      this.exampleEvents$ = events$.pipe(map((resp) => resp.hits.hits.map((e) => e._source)));
      this.eventsCount$ = new BehaviorSubject(0).pipe(merge(events$.pipe(map((resp) => resp.hits.total.value))));
    }
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.topForm) && !isNullOrUndefined(this.form)) {
      this.topForm.addControl('selector', this.form);
    }

    if (this.loaded$) {
      this.subscriptions.push(
        this.loaded$.subscribe((eventRule) => {
          this.filters.removeAt(0);
          for (const filterType of eventRule.filters) {
            this.filters.push(this.filterModel(filterType));
          }
          if (this.form) {
            this.form.patchValue(eventRule);
          }
        }),
      );
    }
  }

  filterModel = (val = '') => this.fb.control(val, Validators.pattern(Filter.regex));

  tryAddNext(array: FormArray, i, model) {
    if (i !== array.length - 1) {
      return;
    }

    if (array.at(i).value !== '') {
      array.push(model());
    }
  }

  private skipEmpty(value) {
    value.filters = value.filters.filter((f) => f);
    for (const key in value) {
      if (value[key] === '') {
        delete value[key];
      }
    }
    return value;
  }

  private parseCustomFilters(value) {
    if (value && value.filters) {
      value.filters = value.filters.map((filterType) => Filter.fromExpression(filterType));
      return value;
    }
  }

  private toElasticSearchQuery(value) {
    const { eventType, targetType, message, user, eventTypeCode } = value;

    const conditions: any[] = [{ term: { type: eventType } }];
    if (targetType) {
      conditions.push({ term: { 'data.target_type': targetType } });
    }

    if (message) {
      conditions.push(phraseMatch('data.message', message));
    }

    if (user) {
      conditions.push(phraseMatch('data.user.email', user));
    }

    if (eventTypeCode) {
      conditions.push(phraseMatch('data.extra.eventTypeCode', eventTypeCode));
    }

    // TODO filter also using custom filters, or at least mark matching events in ui

    return {
      bool: {
        must: conditions,
      },
    };

    function phraseMatch(prop, query) {
      return {
        match_phrase: {
          [prop]: {
            query,
          },
        },
      };
    }
  }
}
