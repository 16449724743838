import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { ConfirmDialogComponent } from '../../../../../../shared/confirm-dialog/confirm-dialog.component';
import { AppState } from '../../../../../../store/app.reducers';
import { PaymentConfigModel } from '../../../../../model/payment-config.model';
import { SupplierService } from '../../../../../services/supplier.service';
import { FetchSupplierPaymentConfigs, selectSupplierDetails } from '../../../../../store/supplier/supplier.actions';
import { ChooseCardTypeComponent } from '../../../../choose-card-type/choose-card-type.component';
import { SubscriberComponent } from './../../../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-payment-type-marqeta',
  templateUrl: './payment-type-marqeta.component.html',
  styleUrls: ['./payment-type-marqeta.component.scss'],
})
export class PaymentTypeMarqetaComponent extends SubscriberComponent implements OnInit {
  private readonly REQUEST_CONFIRM = {
    ACTIVATE: 'Do you want to activate card?',
    BLOCK: 'Do you want to block card?',
    REPLACE: 'Do you want to replace card?',
    REMOVE: 'Are you sure you want to proceed with this action? Deleting a card will remove this card for this supplier',
  };
  private readonly REQUEST_SUCCESS = {
    ACTIVATED: 'Marqeta card has been successfully activated',
    BLOCKED: 'Marqeta card has been successfully blocked',
    REPLACED: 'Marqeta card has been successfully replaced',
    SAVED: 'Marqeta card has been successfully saved',
    REMOVED: 'Marqeta card has been successfully removed',
  };
  private readonly REQUEST_ERROR = {
    ACTIVATED: 'Error occured. Marqeta card could not be activated. Try again',
    BLOCKED: 'Error occured. Marqeta card could not be blocked. Try again',
    REPLACED: 'Error occured. Marqeta card could not be replaced. Try again',
    SAVED: 'Error occured. Marqeta card could not be saved. Try again',
    REMOVED: 'Error occured. Marqeta card could not be removed. Try again',
  };
  private readonly CARD_STATUS = {
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',
    UNACTIVATED: 'UNACTIVATED',
    TERMINATED: 'TERMINATED',
  };
  private readonly PHYSICAL_CARD_TYPE = 'PHYSICAL';
  private cachedCardNumber: string;
  private hiddenCardNumberPrefix = 'XXXX XXXX XXXX';
  private supplierDetails;
  public pCardForm: FormGroup;
  public paymentConfig: PaymentConfigModel;
  public isLoading = false;

  @Input() set payConfig(paymentConfig: PaymentConfigModel) {
    this.paymentConfig = paymentConfig;
    this.patchCardForm();
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private supplierService: SupplierService,
    private dialogService: MatDialog,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.getSupplierDetails();
    this.patchCardForm();
  }

  public hasPcard(): boolean {
    return (
      !isNullOrUndefined(this.paymentConfig) &&
      !isNullOrUndefined(this.paymentConfig.paymentConfig) &&
      !isNullOrUndefined(this.paymentConfig.paymentConfig.pcard)
    );
  }

  public isPhysical() {
    if (this.hasPcard() && !isNullOrUndefined(this.paymentConfig.paymentConfig.pcard.pcard_type_indicator)) {
      return this.paymentConfig.paymentConfig.pcard.pcard_type_indicator === this.PHYSICAL_CARD_TYPE;
    }
  }

  public isActive() {
    if (this.hasPcard() && !isNullOrUndefined(this.paymentConfig.paymentConfig.pcard.pcard_status)) {
      return this.paymentConfig.paymentConfig.pcard.pcard_status === this.CARD_STATUS.ACTIVE;
    }
  }

  public isBlocked() {
    if (this.hasPcard() && !isNullOrUndefined(this.paymentConfig.paymentConfig.pcard.pcard_status)) {
      return this.paymentConfig.paymentConfig.pcard.pcard_status === this.CARD_STATUS.SUSPENDED;
    }
  }

  public isUnactivated() {
    if (this.hasPcard() && !isNullOrUndefined(this.paymentConfig.paymentConfig.pcard.pcard_status)) {
      return this.paymentConfig.paymentConfig.pcard.pcard_status === this.CARD_STATUS.UNACTIVATED;
    }
  }

  public isTerminated() {
    if (this.hasPcard() && !isNullOrUndefined(this.paymentConfig.paymentConfig.pcard.pcard_status)) {
      return this.paymentConfig.paymentConfig.pcard.pcard_status === this.CARD_STATUS.TERMINATED;
    }
  }

  public activateMarqetaCard() {
    const dialogRef = this.getDialogObjectWithoutReasons(this.REQUEST_CONFIRM.ACTIVATE);
    this.subscriptions.push(dialogRef.afterClosed().subscribe((isConfirmed) => this.sendActivateCardRequest(isConfirmed)));
  }

  public blockMarqetaCard() {
    const dialogRef = this.getDialogObject(this.REQUEST_CONFIRM.BLOCK);
    this.subscriptions.push(dialogRef.afterClosed().subscribe((isConfirmed) => this.sendBlockCardRequest(isConfirmed)));
  }

  public replaceMarqetaCard() {
    if (this.isPhysical()) {
      const dialogData = {
        data: {
          replacementMode: true,
          withReasons: true,
        },
      };
      const dialogRef = this.dialogService.open(ChooseCardTypeComponent, dialogData);
      this.subscriptions.push(dialogRef.afterClosed().subscribe((configObject) => this.replacePhysicalMarqetaCard(configObject)));
    } else {
      const dialogRef = this.getDialogObject(this.REQUEST_CONFIRM.REPLACE);
      this.subscriptions.push(dialogRef.afterClosed().subscribe((reasonCode) => this.replaceVirtualMarqetaCard(reasonCode)));
    }
  }

  public removeMarqetaCard() {
    const dialogRef = this.getDialogObject(this.REQUEST_CONFIRM.REMOVE);
    this.subscriptions.push(dialogRef.afterClosed().subscribe((reasonCode) => this.sendRemoveCardRequest(reasonCode)));
  }

  public getShippingStatus() {
    return !isNullOrUndefined(this.paymentConfig.paymentConfig.pcard.pcard_shipping_status)
      ? this.paymentConfig.paymentConfig.pcard.pcard_shipping_status
      : 'INITIATED';
  }

  private createForm() {
    this.pCardForm = this.formBuilder.group({
      startDate: [null, Validators.required],
      cardNumber: ['', Validators.required],
      cardExp: ['', Validators.required],
      cardCvc: ['', Validators.required],
    });
  }

  private patchCardForm() {
    if (
      !isNullOrUndefined(this.paymentConfig) &&
      !isNullOrUndefined(this.paymentConfig.paymentConfig) &&
      !isNullOrUndefined(this.paymentConfig.paymentConfig.pcard) &&
      !isNullOrUndefined(this.pCardForm)
    ) {
      const pCard = this.paymentConfig.paymentConfig.pcard;
      pCard.cardExp = new DatePipe('en-US').transform(pCard.cardExp, 'MM/yy');
      this.cachedCardNumber = pCard.cardNumber;
      const lastFourAccountNumbers = this.cachedCardNumber.substring(this.cachedCardNumber.length - 4, this.cachedCardNumber.length);
      pCard.cardNumber = `${this.hiddenCardNumberPrefix} ${lastFourAccountNumbers}`;
      this.pCardForm.patchValue(pCard);
    }
  }

  private getSupplierDetails() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          this.supplierDetails = data;
        }
      }),
    );
  }

  private marqetaRequestSuccess(statement: string) {
    this.store.dispatch(new FetchSupplierPaymentConfigs({ fresh: true }));
    this.toastrService.success(statement);
    this.isLoading = false;
  }

  private marqetaRequestError(statement: string) {
    this.toastrService.error(statement);
    this.isLoading = false;
  }

  private getConfirmationMessageObject(message: string) {
    return {
      data: {
        message,
        withReasons: true,
      },
    };
  }

  private getDialogObject(message: string) {
    const messageObject = this.getConfirmationMessageObject(message);
    return this.dialogService.open(ConfirmDialogComponent, messageObject);
  }

  private getDialogObjectWithoutReasons(message: string) {
    const messageObject = {
      data: {
        message,
      },
    };
    return this.dialogService.open(ConfirmDialogComponent, messageObject);
  }

  private sendActivateCardRequest(isConfirmed) {
    if (isConfirmed) {
      this.isLoading = true;
      this.subscriptions.push(
        this.supplierService
          .activateMarqetaCard(this.supplierDetails.supplierKey, this.paymentConfig.paymentConfig.pcard.card_token)
          .subscribe(
            () => this.marqetaRequestSuccess(this.REQUEST_SUCCESS.ACTIVATED),
            () => this.marqetaRequestError(this.REQUEST_ERROR.ACTIVATED),
          ),
      );
    }
  }

  private sendBlockCardRequest(reasonCode) {
    if (reasonCode && !isNullOrUndefined(reasonCode)) {
      this.isLoading = true;
      this.subscriptions.push(
        this.supplierService
          .blockMarqetaCard(this.supplierDetails.supplierKey, this.paymentConfig.paymentConfig.pcard.card_token, {
            reason: reasonCode,
          })
          .subscribe(
            () => this.marqetaRequestSuccess(this.REQUEST_SUCCESS.BLOCKED),
            () => this.marqetaRequestError(this.REQUEST_ERROR.BLOCKED),
          ),
      );
    }
  }

  private sendReplaceCardRequest(requestObject) {
    if (!isNullOrUndefined(this.paymentConfig)) {
      this.isLoading = true;
      this.subscriptions.push(
        this.supplierService
          .replaceMarqetaCard(this.supplierDetails.supplierKey, this.paymentConfig.paymentConfig.pcard.card_token, requestObject)
          .subscribe(
            () => {
              this.marqetaRequestSuccess(this.REQUEST_SUCCESS.REPLACED);
              this.navigateToPaymentListView();
            },
            () => this.marqetaRequestError(this.REQUEST_ERROR.REPLACED),
          ),
      );
    }
  }

  private sendRemoveCardRequest(reasonCode) {
    if (reasonCode && !isNullOrUndefined(reasonCode)) {
      this.isLoading = true;
      this.subscriptions.push(
        this.supplierService
          .removeMarqetaCard(this.supplierDetails.supplierKey, this.paymentConfig.paymentConfig.pcard.card_token, {
            reason: reasonCode,
          })
          .subscribe(
            () => {
              this.marqetaRequestSuccess(this.REQUEST_SUCCESS.REMOVED);
              this.navigateToPaymentListView();
            },
            () => this.marqetaRequestError(this.REQUEST_ERROR.REMOVED),
          ),
      );
    }
  }

  private preparePhysicalCardReplaceRequestObject(configObject) {
    return {
      recipient_address: configObject.address,
      reason: configObject.reason,
    };
  }

  private navigateToPaymentListView() {
    this.router.navigate(['../../list'], { relativeTo: this.route });
  }

  private replacePhysicalMarqetaCard(configObject) {
    if (configObject && !isNullOrUndefined(configObject) && !isNullOrUndefined(configObject.address)) {
      const requestObject = this.preparePhysicalCardReplaceRequestObject(configObject);
      this.sendReplaceCardRequest(requestObject);
    }
  }

  private replaceVirtualMarqetaCard(reasonCode) {
    if (reasonCode && !isNullOrUndefined(reasonCode)) {
      this.sendReplaceCardRequest({
        reason: reasonCode,
      });
    }
  }
}
