export enum FEATURE_FLAGS {
  SUPPLIER_ACCOUNT_HEALTH = 'SUPPLIER_ACCOUNT_HEALTH',
  TASKS_MID_VER_SIMPLE_QUERY = 'TASKS_MID_VER_SIMPLE_QUERY',
  UNDERWRITING_TRACKER = 'UNDERWRITING_TRACKER',
  BANK_CATEGORIES = 'BANK_CATEGORIES',
  EVA_ONBOARDING_SUMMARY = 'EVA_ONBOARDING_SUMMARY',
  ACCESS_ONBOARDING_PREDICTIONS = 'ACCESS_ONBOARDING_PREDICTIONS',
  ACCESS_DAILY_PREDICTIONS = 'ACCESS_DAILY_PREDICTIONS',
  TWO_FA = 'TWO_FA',
  UNDERWRITING_CHECK_TOOLS_BEFORE_APPROVING = 'UNDERWRITING_CHECK_TOOLS_BEFORE_APPROVING',
  PYB_DOCUMENTS = 'PYB_DOCUMENTS',
  EVA_SUPPLIER_MENU = 'EVA_SUPPLIER_MENU',
  CHECK_VPN = 'CHECK_VPN',
  DELETE_SUPPLIER = 'DELETE_SUPPLIER',
}
