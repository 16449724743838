<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="title">Waiting Suppliers Report</div>
      </div>

      <div *ngIf="isReady; else loading">
        <app-data-table-grid [nodesCounted]="true" [rows]="rows" [columnDefs]="columns" domLayout="autoHeight" [offset]="offset">
        </app-data-table-grid>
      </div>

      <ng-template #loading>
        <app-loading-spinner [size]="2"></app-loading-spinner>
      </ng-template>
    </div>
  </div>
</section>
