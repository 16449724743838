import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { IExternalReport } from '../model/i-external-report';
import { ExternalReportsService } from '../service/external-reports.service';

@Component({
  selector: 'app-external-report-list',
  templateUrl: './external-report-list.component.html',
  styleUrls: ['./external-report-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExternalReportListComponent implements OnInit {
  public reports$: Observable<IExternalReport[]>;

  constructor(public externalReportsService: ExternalReportsService) {}

  ngOnInit() {
    this.reports$ = this.externalReportsService.getList();
  }
}
