import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DynamicFormControlService } from '../dynamic-form-control.service';
import { BaseField } from '../fields/base-field';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [DynamicFormControlService],
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: BaseField<any>[] = [];
  form: FormGroup;
  payLoad = '';

  constructor(private qcs: DynamicFormControlService) {}

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.fields);
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.value);
  }
}
