import { Routes } from '@angular/router';

import { AuthGuard } from '../../auth/guards/auth.guard';
import { FeatureFlagsGeneralGuard } from '../../guards/feature-flags-general.guard';
import { WelcomeComponent } from '../welcome/welcome.component';

export const routes: Routes = [
  {
    path: 'welcome',
    canActivate: [AuthGuard, FeatureFlagsGeneralGuard],
    children: [
      { path: '', component: WelcomeComponent },
      { path: ':messageCode', component: WelcomeComponent },
    ],
  },
];
