import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { CustomDialogData } from '../../../interfaces/confirm-dialog.interface';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './eva-confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvaConfirmDialogComponent {
  get isStaticContentBody(): boolean {
    if (!this.data.body) {
      return;
    }

    return typeof this.data.body === 'string';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: CustomDialogData,
    private readonly cd: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<EvaConfirmDialogComponent>,
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
