<form *ngIf="paymentsList; else loading" [formGroup]="fundTransferRequestForm">
  <h1 mat-dialog-title>{{ paymentsList.length > 0 ? 'Add Fund Transfer Request' : 'No ACH accounts available. ' }}</h1>

  <div *ngIf="paymentsList.length > 0">
    <mat-form-field class="full-width">
      <mat-select (selectionChange)="selectMarketplace($event.value)" placeholder="Marketplace" formControlName="marketplace_code">
        <mat-option *ngFor="let marketplace of marketplaces" [value]="marketplace.id">
          {{ marketplace.label }}
        </mat-option>
      </mat-select>
      <mat-error>This field is required.</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="statements && statements.length > 0">
      <mat-select (selectionChange)="selectStatement($event.value)" placeholder="Statement" formControlName="statement_id">
        <mat-option [value]="">No statement</mat-option>
        <mat-option *ngFor="let statement of statements" [value]="statement.id">
          {{ statement.label }}
        </mat-option>
      </mat-select>
      <mat-error>This field is required.</mat-error>
    </mat-form-field>

    <div class="mb-4">
      <mat-form-field class="full-width">
        <mat-select formControlName="supPmtCfgKey" placeholder="Account Number">
          <mat-option *ngFor="let paymentPosition of paymentsList" [value]="paymentPosition.key">
            {{ paymentPosition.description }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <input id="value" matInput type="number" placeholder="Amount" formControlName="amount" min="minimumAmount" max="maximumAmount" />
        <mat-error> Please enter amount value larger than 0.01$ and lower than 100 0000 000$ </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-between">
    <div [hidden]="paymentsList.length === 0">
      <mat-slide-toggle formControlName="is_passthrough_debit">Apply In Full To Available Balance</mat-slide-toggle>
    </div>
    <div>
      <button
        (click)="openFundTransferRequestConfirmationModal()"
        mat-raised-button
        color="primary"
        [disabled]="fundTransferRequestForm.invalid"
      >
        Submit
      </button>
      <button mat-raised-button color="warn" (click)="closeDialog()">Cancel</button>
    </div>
  </div>
</form>
<ng-template #loading>
  <app-loading-spinner [size]="4"></app-loading-spinner>
</ng-template>
