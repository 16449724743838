<div class="col-12">
  <div class="card">
    <div class="card-content">
      <div class="card-title">Underwriting Workflow</div>
      <p class="text-center" [hidden]="!uwTaskProgress.isRunning">
        {{ uwTaskProgress.fetchProgressValue(uwTaskProgress.iterationStep, uwTaskProgress.maxIterationStep) }}
      </p>
      <div [hidden]="!uwTaskProgress.isRunning">
        <mat-progress-bar class="example-margin" [color]="'primary'" [mode]="'determinate'" [value]="getProgressBarValue()">
        </mat-progress-bar>
      </div>
    </div>
  </div>
</div>
