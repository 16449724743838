import { Component, Input } from '@angular/core';

import { PendingInterceptorService } from '../pending-interceptor.service';
import { SubscriberComponent } from './../../component-subscriber/subscriber.component';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent extends SubscriberComponent {
  public isSpinnerVisible: boolean;

  @Input() public forceShow = false;

  constructor(private pendingRequestInterceptorService: PendingInterceptorService) {
    super();
    this.subscriptions.push(
      this.pendingRequestInterceptorService.pendingRequestsStatus.subscribe((hasPendingRequests) => {
        setTimeout(() => {
          this.isSpinnerVisible = hasPendingRequests;
        });
      }),
    );
  }
}
