import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AdminBaseComponent } from './base/base.component';
import { AdminFeatureFlagsComponent } from './feature-flags/feature-flags.component';
import { AdminFeatureFlagsEnvComponent } from './feature-flags/feature-flags-env/feature-flags-env.component';
import { AdminQaComponent } from './qa/qa.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [AdminFeatureFlagsComponent, AdminBaseComponent, AdminFeatureFlagsEnvComponent, AdminQaComponent],
})
export class AdminModule {}
