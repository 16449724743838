import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { selectLoggedUserModel } from '../../../../auth/store/auth.actions';
import { CardFormgroupComponent } from '../../../../shared/card-formgroup/card-formgroup.component';
import { CardFormgroupMessageService } from '../../../../shared/card-formgroup/card-formgroup-message.service';
import { CrmStates } from '../../../../shared/layout/layout.enums';
import { LayoutState } from '../../../../shared/layout/store/layout.reducers';
import { LookupAbstractService } from '../../../../shared/lookup-service/lookup-abstract.service';
import { UsersService } from '../../../../shared/users/service/users.service';
import { AppState } from '../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { SupplierService } from '../../../services/supplier.service';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SupplierGeneralForm } from '../../supplier-general/supplier-general-form';
import { SupplierUnderwritingService } from '../supplier-underwriting.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../../../shared/users/user-permissions.enum';
import { SupplierGeneralFormTypes } from './../../supplier-general/supplier-general-form-types';

@Component({
  selector: 'app-supplier-information',
  templateUrl: './supplier-information.component.html',
  styleUrls: ['./supplier-information.component.scss'],
})
export class SupplierInformationComponent extends SubscriberComponent implements OnInit {
  readonly userPermission = USER_PERMISSION;

  supplierDetailsModel: SupplierDetailsModel;

  formCheckboxesLastBox: FormGroup;
  homeAddressForm: FormGroup;

  addressCountries: any;
  addressStates: any;
  businessTypes: any;
  owners: any[];
  businessCodeLabel: string;
  contactPersonsTitle: string;
  mainOwner: any;
  additionalOwner: any;
  additionalOwnerKey: any;
  isMainOwnerCheckmarked: any;
  isAdditionalOwnerCheckmarked: any[];

  isShown: boolean;
  isDisabled: boolean;
  isSoleProp: boolean;
  persmLoaded = false;

  private PERMISSIONS = [USER_PERMISSION.INT_SUPER_USER, USER_PERMISSION.FINANCE, USER_PERMISSION.DEV, USER_PERMISSION.CUST_SERVICE];

  layoutState$: Store<LayoutState>;
  crmPanelStates = CrmStates;

  supplierGeneralModels$: Observable<any>;

  @ViewChild('formGroupHomeAddress') private formGroupHomeAddress: CardFormgroupComponent;

  readonly CONTACT_DATA_FORM = SupplierGeneralFormTypes.CONTACT_DATA_FORM;
  formGroups: { [key: string]: FormGroup } = {};

  constructor(
    private store: Store<AppState>,
    private lookupRepo: LookupAbstractService,
    private userService: UsersService,
    private underwritingService: SupplierUnderwritingService,
    private supplierService: SupplierService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private messageService: CardFormgroupMessageService,
    private supplierFormBuilder: SupplierGeneralForm,
    private permissionsService: NgxPermissionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutState$ = this.store.select('layout');

    this.addressCountries = this.lookupRepo.getCountries();
    this.addressStates = this.lookupRepo.getUSStates();
    this.businessTypes = this.lookupRepo.getBusinessTypes();

    this.supplierGeneralModels$ = this.store.select(selectSupplierDetails);
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((loggedUser) => {
        this.getUserPermissions(loggedUser);
      }),
    );

    this.subscriptions.push(
      this.supplierGeneralModels$.subscribe((results) => {
        if (!isNullOrUndefined(results)) {
          this.supplierDetailsModel = <SupplierDetailsModel>results;

          this.businessTypes.subscribe((types) => {
            const foundItem = types.find((item) => item.id === this.supplierDetailsModel.businessTypeCode);
            if (foundItem) {
              this.businessCodeLabel = foundItem.label;
            }
          });

          this.contactPersonsTitle = this.supplierFormBuilder.getContactPersonsTitle(this.supplierDetailsModel);

          this.isSoleProp = this.supplierDetailsModel.businessTypeCode === 'SP' ? true : false;

          if (this.supplierDetailsModel.owners.length !== 1) {
            this.isShown = false;
            this.subscriptions.push(
              this.underwritingService.getMarqetaKycCompliances(this.supplierDetailsModel.supplierKey).subscribe(
                (data) => {
                  this.mainOwner = data;
                },
                (error) => {
                  this.toastrService.error(error.message);
                },
              ),
            );
            this.subscriptions.push(
              this.underwritingService.getOwnersMarqetaKycCompliances(this.supplierDetailsModel.supplierKey).subscribe(
                (data) => {
                  this.additionalOwner = [];
                  this.owners.map((owner) => {
                    Object.keys(data).map((key) => {
                      if (owner.loginKey === key) {
                        this.additionalOwner.push(data[key]);
                        return this.additionalOwner;
                      } else {
                        return;
                      }
                    });
                  });
                  this.additionalOwnerKey = [];
                  this.owners.map((owner) => {
                    Object.keys(data).map((key) => {
                      if (owner.loginKey === key) {
                        this.additionalOwnerKey.push(key);
                        return this.additionalOwnerKey;
                      } else {
                        return;
                      }
                    });
                  });
                },
                (error) => {
                  this.toastrService.error(error.message);
                },
              ),
            );
          } else {
            this.subscriptions.push(
              this.underwritingService.getMarqetaKycCompliances(this.supplierDetailsModel.supplierKey).subscribe(
                (data) => {
                  this.mainOwner = data;
                },
                (error) => {
                  this.toastrService.error(error.message);
                },
              ),
            );
          }

          if (this.supplierDetailsModel.businessTypeCode !== 'SP') {
            this.subscriptions.push(
              this.underwritingService
                .checkIdentityCompany(this.supplierDetailsModel.supplierKey, this.supplierDetailsModel)
                .subscribe((data) => {
                  if (data.response === 'MATCH') {
                    this.setControlsValue(this.formCheckboxesLastBox, true);
                  }
                }),
            );
          }
        }

        if (this.supplierDetailsModel && this.supplierDetailsModel.owners) {
          this.owners = this.supplierDetailsModel.owners;

          this.isMainOwnerCheckmarked = [];
          this.isAdditionalOwnerCheckmarked = [];

          for (const owner of this.supplierDetailsModel.owners) {
            if (owner.mainOwner) {
              this.subscriptions.push(
                this.underwritingService.checkIdentitySole(this.supplierDetailsModel.supplierKey, owner).subscribe((data) => {
                  if (data.response === 'MATCH') {
                    this.isMainOwnerCheckmarked.push(true);
                  } else {
                    this.isMainOwnerCheckmarked.push(false);
                  }
                }),
              );
            } else {
              this.subscriptions.push(
                this.underwritingService.checkIdentitySole(this.supplierDetailsModel.supplierKey, owner).subscribe((data) => {
                  if (data.response === 'MATCH') {
                    this.isAdditionalOwnerCheckmarked.push(true);
                  } else {
                    this.isAdditionalOwnerCheckmarked.push(false);
                  }
                }),
              );
            }
          }
        }
        if (this.supplierDetailsModel) {
          this.homeAddressForm = this.formBuilder.group({
            addr1: [''],
            addr2: [''],
            country: [''],
            state: [''],
            city: [''],
            postalCode: [''],
          });
          this.formCheckboxesLastBox = this.formBuilder.group({
            tinVerified: [{ value: '', disabled: true }],
            legalEntityVerified: [{ value: '', disabled: true }],
          });

          this.homeAddressForm.patchValue(this.supplierDetailsModel.homeAddress);

          this.getAdditionalSupplierInfo();
        }
      }),
    );
  }

  private getUserPermissions(loggedUser) {
    this.subscriptions.push(
      this.userService.getLoginPermissions(loggedUser.loginKey).subscribe((perms) => {
        perms.forEach((perm) => {
          this.isDisabled = !this.PERMISSIONS.includes(perm.permCode);
          this.persmLoaded = true;
        });
      }),
    );
  }

  setControlsValue(form, arg: boolean) {
    Object.values(form.controls).forEach((control: any) => {
      control.patchValue(arg);
    });
  }

  public saveHomeAddressForm({ value }) {
    this.subscriptions.push(
      this.supplierService.updateSupplierHomeAddress(this.supplierDetailsModel.supplierKey, value).subscribe(
        () => {
          this.messageService.broadcast(this.homeAddressForm, 'saveSuccess');
        },
        (error) => {
          this.toastrService.error(error.message);
          this.messageService.broadcast(this.homeAddressForm, 'saveError');
        },
      ),
    );
  }

  private getAdditionalSupplierInfo(): void {
    const hasRestUwAccessPerm = !!this.permissionsService.getPermission('REST_UW_ACCESS');
    const mainOwner = this.supplierDetailsModel.owners?.filter((value) => value.mainOwner === true)[0];

    if (this.formGroups[SupplierGeneralFormTypes.CONTACT_DATA_FORM] === undefined) {
      this.formGroups = {
        [SupplierGeneralFormTypes.CONTACT_DATA_FORM]: this.formBuilder.group({
          legalName: hasRestUwAccessPerm ? `${mainOwner?.firstName} ${mainOwner?.lastName}` : this.supplierDetailsModel.legalName,
          dateOfBirth: this.supplierDetailsModel.dateOfBirth,
          ownerSSN: this.supplierDetailsModel.ownerSSN,
        }),
      };
    }
  }
}
