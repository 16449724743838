<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>

<div *ngIf="supplierDetailsModel$ | async; else loading; let supplierInfo" class="supplier-hide-switch">
  <span *ngIf="!supplierInfo.hide; else unhideSupplier">
    <button
      mat-raised-button
      class="btn-w-md btn-w-md"
      (click)="toggleSupplierHidden(supplierInfo)"
      [disabled]="toggleInProgress"
      [ngClass]="{ 'btn-busy': toggleInProgress }"
      [color]="'warn'"
    >
      Hide Supplier
    </button>
  </span>

  <ng-template #unhideSupplier>
    <button
      mat-raised-button
      class="btn-w-md"
      [disabled]="toggleInProgress"
      [ngClass]="{ 'btn-busy': toggleInProgress }"
      (click)="toggleSupplierHidden(supplierInfo)"
    >
      Unhide Supplier
    </button>
  </ng-template>
</div>
