import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../../../../config';

@Injectable()
export class ProductMatrixService {
  readonly apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = this.appConfig.env.internalApiUrl;
  }

  getProducts(supplierKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}supplier/${supplierKey}/products`;
    return this.httpClient.get(endpoint);
  }

  postUnderwritingStatus(mpSupplierKey: string, productType: string, data: any): Observable<any> {
    const endpoint = `${this.apiUrl}supplier/${mpSupplierKey}/${productType}/uw_status`;
    return this.httpClient.post(endpoint, data);
  }

  putOptDates(supplierKey: string, mpSupplierKey: string, optInKey: string, data: any): Observable<any> {
    const endpoint = `${this.apiUrl}suppliers/${supplierKey}/funding_particulars/${mpSupplierKey}/opt_in_periods/${optInKey}`;
    return this.httpClient.put(endpoint, data);
  }

  enableProduct(mpSupplierKey: string, productType: string, payload = {}): Observable<any> {
    const endpoint = `${this.apiUrl}supplier/${mpSupplierKey}/${productType}/enable`;
    return this.httpClient.post(endpoint, payload);
  }

  disableProduct(mpSupplierKey: string, productType: string, payload = {}): Observable<any> {
    const endpoint = `${this.apiUrl}supplier/${mpSupplierKey}/${productType}/disable`;
    return this.httpClient.post(endpoint, payload);
  }
}
