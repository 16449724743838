import { SelectionModel } from '@angular/cdk/collections';
import { PlatformLocation } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { isArray } from 'util';

import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from '../../../../../shared/dialog.config';
import { FEATURE_FLAGS } from '../../../../../shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from '../../../../../shared/feature-flags/feature-flags.service';
import { PermissionModel } from '../../../../../shared/users/permission.model';
import { UsersService } from '../../../../../shared/users/service/users.service';
import { UserModel } from '../../../../../shared/users/user.model';
import { AppState } from '../../../../../store/app.reducers';
import { CrmMetaModel } from '../../../../models/meta.model';
import { TaskModel } from '../../../../models/task.model';
import { TasksService } from '../../../../services/tasks.service';
import { CrmItemClearStatus } from '../../../../store/crm.actions';
import { TASK_COLUMN } from '../../../consts/task-column';
import { TaskAddEditComponent } from '../../../task-add-edit/task-add-edit.component';
import { taskStatuses } from '../../../task-statuses';
import { TaskGridFiltersSetsComponent } from '../../task-grid-filters-sets/task-grid-filters-sets.component';
import { ConfigsService } from './../../../../../shared/configs/service/configs.service';
import { FetchState, Filters, LOADED_STATE, RefreshTasks } from './models';
@Component({
  selector: 'app-task-list-transition',
  styleUrls: ['task-list-transition.component.scss', '../../../../shared/crm-tools-buttons.scss'],
  templateUrl: 'task-list-transition.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListTransitionComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  readonly ITEMS_PER_PAGE = 100;
  readonly FIRST_PAGE_LIMIT = 50;
  readonly DEFAULT_DEBOUNCE_TIME = 200;
  readonly defaultSorting = { field: TASK_COLUMN.CREATED_AT, direction: 'desc' };
  readonly GLOBAL_SETTINGS_TASKS_LIMIT_KEY = 'TASKS_MID_VER_LIMIT';
  readonly columns = [
    TASK_COLUMN.ID,
    TASK_COLUMN.ITEM_NUMBER,
    TASK_COLUMN.TITLE,
    TASK_COLUMN.AUTHOR,
    TASK_COLUMN.CREATED_AT,
    TASK_COLUMN.STATUS,
    TASK_COLUMN.PRIORITY,
    TASK_COLUMN.DUE_DATE,
    TASK_COLUMN.ASSIGNEE,
    TASK_COLUMN.SUPPLIER,
    TASK_COLUMN.ACTIONS,
  ];
  readonly columnsOrderByMap = {
    [TASK_COLUMN.TITLE]: 'title',
    [TASK_COLUMN.CREATED_AT]: 'created_at',
    [TASK_COLUMN.AUTHOR]: 'created_by_name',
    [TASK_COLUMN.PRIORITY]: 'priority_int',
    [TASK_COLUMN.DUE_DATE]: 'due_date',
    [TASK_COLUMN.ASSIGNEE]: 'assinge_uuid',
    [TASK_COLUMN.SUPPLIER]: 'supplier_name',
  };
  readonly loadedState = LOADED_STATE;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(TaskGridFiltersSetsComponent) filterSet: TaskGridFiltersSetsComponent;

  fetchState: FetchState = {
    loadedState: LOADED_STATE.NONE,
    lastItem: undefined,
  };

  refresh$: Subject<RefreshTasks> = new Subject();
  filtersSetChanged$: Subject<Filters> = new Subject();
  filtersChange$: Subject<Filters> = new Subject();
  filtersChanged$: Subject<void> = new Subject();
  baseFiltersChanged$: Subject<void> = new Subject();

  baseFiltersSet: Filters = null;
  updateFiltersCountH = null;
  assigneeOptions: (UserModel | PermissionModel)[];
  resultsLength = 0;
  isLoadingResults = false;
  loadingError = false;
  dataSource = new MatTableDataSource();
  routingFilterNr: number;

  selection = new SelectionModel<Element>(true, []);
  dialogRef: MatDialogRef<TaskAddEditComponent>;

  nextPageLimit = 1000;

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private toastrService: ToastrService,
    private usersService: UsersService,
    private tasksService: TasksService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private platformLocation: PlatformLocation,
    private featureFlagsService: FeatureFlagsService,
    private configsService: ConfigsService,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.setUpSubscriptions();
    this.filtersListener();

    this.subscriptions.push(
      this.configsService
        .get(this.GLOBAL_SETTINGS_TASKS_LIMIT_KEY)
        .pipe(take(1))
        .subscribe((limit) => {
          this.nextPageLimit = limit;

          this.subscribeOnAllFiltersChanges();
        }),
    );
  }

  ngAfterViewInit(): void {
    this.getAssignees();
    this.initDataSource();
  }

  initDataSource(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item: TaskModel, property: string) => {
      switch (property) {
        case 'assignee':
          return item.assigneeName;
        case 'due_date':
          return item.dueDate;
        case 'status':
          return item.status;
        case 'created_at':
          return item.createdAt;
        case 'item_number':
          return item.itemNumber;
        default:
          return item[property];
      }
    };

    this.dataSource.filterPredicate = ((data: TaskModel, filters) => {
      const status =
        !filters.status ||
        (filters.status &&
          ((filters.status as Array<string>).indexOf(data.status) !== -1 || (filters.status as Array<string>).length === 0));
      const priority =
        !filters.priority ||
        (filters.priority &&
          ((filters.priority as Array<string>).indexOf(data.priority) !== -1 || (filters.priority as Array<string>).length === 0));

      return status && priority;
    }) as (PeriodicElement, string) => boolean;
  }

  setUpSubscriptions(): void {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params) => {
        if (params['filterId'] !== undefined) {
          this.routingFilterNr = params['filterId'];
          this.cd.detectChanges();
        }
      }),
    );

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (this.shouldOpenEditForm(params)) {
          this.getTaskAndOpenInEditor(params['taskUuid']);
        }
      }),
    );
  }

  filtersListener(): void {
    this.subscriptions.push(
      this.filtersChange$.pipe(distinctUntilChanged(), debounceTime(this.DEFAULT_DEBOUNCE_TIME)).subscribe((filters) => {
        this.checkActionByFilters(filters);
        this.filtersChanged$.next();
      }),
    );

    this.subscriptions.push(
      this.filtersSetChanged$
        .pipe(
          distinctUntilChanged(),
          debounceTime(this.DEFAULT_DEBOUNCE_TIME),
          tap((filters) => {
            this.baseFiltersSet = {
              assignee: filters.assignee,
              author: filters.owner_uuid,
              createdAfter: filters.createdAfter,
              createdBefore: filters.createdBefore,
              priority: filters.priority,
              status: filters.status,
              taskId: filters.taskId ? filters.taskId : null,
            };
          }),
        )
        .subscribe(() => {
          this.baseFiltersChanged$.next();
        }),
    );

    this.subscriptions.push(
      this.baseFiltersChanged$.pipe(take(1)).subscribe(() => {
        this.triggerFetchTasks(true);
      }),
    );

    this.subscriptions.push(
      this.baseFiltersChanged$.pipe(withLatestFrom(this.filtersChanged$)).subscribe(() => {
        this.triggerFetchTasks(true);
      }),
    );
  }

  checkActionByFilters(filters: Filters): boolean {
    let shouldRefetchData = false;

    if (this.baseFiltersSet === null) {
      return false;
    }

    Object.entries(filters).every((oneFilter) => {
      const [key, value] = oneFilter;
      const baseValue = this.baseFiltersSet[key];

      switch (key) {
        case 'status':
        case 'priority':
          const test =
            !isEqual(value, baseValue) &&
            !!value &&
            !!baseValue &&
            !!value &&
            isArray(value) &&
            (value.length === 0 ||
              !(value as Array<string>).every((elem) => (isArray(baseValue) ? (baseValue as Array<string>) : []).includes(elem)));
          if (test) {
            shouldRefetchData = true;
            return false;
          }
          break;
        case 'author':
          // eslint-disable-next-line eqeqeq
          if (value != baseValue) {
            shouldRefetchData = true;
            return false;
          }
          break;
        case 'assignee':
          // eslint-disable-next-line eqeqeq
          if ((value && (value as any).key) != (baseValue && (baseValue as any).key)) {
            shouldRefetchData = true;
            return false;
          }
          break;
        default:
          const valueT = value === '' ? null : value;
          const baseValueT = baseValue === '' ? null : baseValue;
          // eslint-disable-next-line eqeqeq
          if (valueT != baseValueT) {
            shouldRefetchData = true;
            return false;
          }
      }

      return true;
    });

    if (shouldRefetchData) {
      this.dataSource.filter = null;
      this.updateFilterSet({
        ...filters,
        owner_uuid: filters.author,
      });
      this.filtersChange(filters);
      this.triggerFetchTasks(true);
    } else {
      this.dataSource.filter = filters as any;
    }

    return shouldRefetchData;
  }

  triggerFetchTasks(first: boolean): void {
    if (first) {
      this.dataSource.data = [];
      this.fetchState.loadedState = LOADED_STATE.NONE;
      this.fetchState.lastItem = undefined;
    }
    this.refresh$.next({ timestamp: Date.now().toString(), first });
  }

  tasksQueryConfig() {
    const mappedFilters = { ...this.baseFiltersSet, item_number: this.baseFiltersSet.taskId, owner_uuid: this.baseFiltersSet.author };
    delete mappedFilters.taskId;
    delete mappedFilters.author;
    const filters = this.filterToQuery(mappedFilters);

    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.TASKS_MID_VER_SIMPLE_QUERY)) {
      return {
        orderBy: this.defaultSorting,
        filters: filters.length === 0 ? undefined : { filters },
      };
    } else {
      return {
        orderBy: this.defaultSorting,
        filters: { filters },
      };
    }
  }

  fetchFirstPageOfTasks(): Observable<{ data: TaskModel[]; meta: CrmMetaModel }> {
    const { orderBy, filters } = this.tasksQueryConfig();

    this.isLoadingResults = true;

    return this.tasksService.listTasks(this.FIRST_PAGE_LIMIT, orderBy, filters, this.fetchState.lastItem);
  }

  fetchNextBatchOfTasks(): Observable<{ data: TaskModel[]; meta: CrmMetaModel }> {
    const { orderBy, filters } = this.tasksQueryConfig();

    return this.tasksService.listTasks(this.nextPageLimit, orderBy, filters, this.fetchState.lastItem);
  }

  subscribeOnAllFiltersChanges(): void {
    this.subscriptions.push(
      this.refresh$
        .pipe(
          distinctUntilChanged(),
          debounceTime(this.DEFAULT_DEBOUNCE_TIME),
          filter((data) => !!this.assigneeOptions && !!this.baseFiltersSet),
          switchMap((data) => {
            return forkJoin([of(data), data.first ? this.fetchFirstPageOfTasks() : this.fetchNextBatchOfTasks()]).pipe(
              catchError(() => {
                return this.tasksReceivedError();
              }),
            );
          }),
          map(([config, data]) => ({
            data: this.tasksReceivedSuccessfully(data, config),
            first: config.first,
          })),
          map(({ data, first }) => {
            if (first) {
              this.dataSource.data = data;
              if (this.fetchState.lastItem !== undefined) {
                this.fetchNewBundle();
              }
            } else if (this.fetchState.loadedState !== LOADED_STATE.ALL) {
              this.dataSource.data = [...this.dataSource.data, ...data];
              if (this.fetchState.lastItem !== undefined) {
                this.fetchNewBundle();
              }
            }

            return data;
          }),
          filter(({ first }) => {
            return !first && this.fetchState.lastItem === undefined;
          }),
        )
        .subscribe(() => {
          this.fetchState.loadedState = LOADED_STATE.ALL;
        }),
    );
  }

  fetchNewBundle(): void {
    if (this.fetchState.lastItem !== undefined) {
      this.triggerFetchTasks(false);
    }
  }

  tasksReceivedSuccessfully(data, config) {
    this.isLoadingResults = false;
    this.loadingError = false;

    if (!data) {
      return [];
    }

    this.fetchState.lastItem = data && data.meta ? data.meta.last : undefined;

    if (config.first) {
      this.resultsLength = data && data.meta ? data.meta.count : 0;
      this.fetchState.loadedState = LOADED_STATE.FIRST_PAGE;
    }

    return data ? data.data : [];
  }

  tasksReceivedError(): Observable<any> {
    this.fetchState.loadedState = LOADED_STATE.ERROR;
    this.dataSource.data = [];
    this.fetchState.lastItem = undefined;

    this.isLoadingResults = false;
    this.loadingError = true;

    return of([{ first: null }, null]);
  }

  updateFilterSet(filters): void {
    const matSort = this.dataSource.sort;
    const disableClear = false;
    const start = this.defaultSorting.direction as any;

    if (matSort) {
      matSort.sort({ id: null, start, disableClear });
      matSort.sort({ id: this.defaultSorting.field, start, disableClear });
    }

    const ctx = filters.filters != null ? filters.filters : filters;
    this.filtersSetChanged$.next(ctx);
  }

  filtersChange(filters: Filters): void {
    this.filtersChange$.next(filters);
  }

  setAssigneeOptions(assignees: (UserModel | PermissionModel)[]): void {
    this.assigneeOptions = assignees;
  }

  getAssignees(): void {
    this.subscriptions.push(
      this.usersService.getPreparedCombinedList().subscribe((assignees) => {
        this.setAssigneeOptions(assignees);
        this.triggerFetchTasks(true);
      }),
    );
  }

  filterToQuery(filterUiModel) {
    const q = (name, op, val) => ({ name, op, val });
    const toTimestamp = (date) => moment(date).unix();
    const filterQuery = [];

    for (const key in filterUiModel) {
      if (filterUiModel.hasOwnProperty(key)) {
        const val = filterUiModel[key];
        if (val === null || val === undefined || val === '') {
          delete filterUiModel[key];
          continue;
        }

        if (isArray(val) && val.length === 0) {
          continue;
        }

        if (key === 'assignee') {
          filterQuery.push(q('assignee_uuid', 'eq', val.key));
          filterQuery.push(q('assignee_type', 'eq', val.type));
        } else if (key === 'createdBefore') {
          filterQuery.push(q('created_at', 'lt', toTimestamp(val)));
        } else if (key === 'createdAfter') {
          filterQuery.push(q('created_at', 'gt', toTimestamp(val)));
        } else if (key === 'item_number') {
          filterQuery.push(q('item_number', 'eq', parseInt(val, 10)));
        } else if (isArray(val)) {
          filterQuery.push(q(key, 'in', val));
        } else if (val.charAt(0) === '!') {
          filterQuery.push(q(key, 'ne', val.slice(1)));
        } else {
          filterQuery.push(q(key, 'eq', val));
        }
      }
    }

    return filterQuery;
  }

  delayedUpdateFilterCounts(): void {
    if (this.filterSet) {
      if (this.updateFiltersCountH) {
        clearTimeout(this.updateFiltersCountH);
      }
      this.updateFiltersCountH = setTimeout(() => {
        this.filterSet.updateFiltersCounts();
      }, 1000);
    }
  }

  get isAllFetched(): boolean {
    return this.fetchState.loadedState === LOADED_STATE.ALL;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  selectAll($event?): boolean {
    this.dataSource.data.forEach((row) => this.selection.select(<Element>row));
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    return false;
  }

  selectNone($event?): boolean {
    this.selection.clear();
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    return false;
  }

  shouldOpenEditForm(params: object): boolean {
    return params['taskUuid'] !== undefined;
  }

  getTaskAndOpenInEditor(uuid: string): void {
    this.subscriptions.push(
      this.tasksService.getTask(uuid).subscribe((taskModel: TaskModel) => {
        this.openEditTaskDialog(taskModel);
        this.addSubscribtionsToEditDialog();
      }),
    );
  }

  openEditTaskDialog(taskModel: TaskModel): void {
    this.dialogRef = this.dialog.open(TaskAddEditComponent, {
      disableClose: true,
      data: {
        task: taskModel,
      },
    });
  }

  addSubscribtionsToEditDialog(): void {
    this.subscriptions.push(
      this.dialogRef.beforeClosed().subscribe(() => {
        this.beforeEditDialogClose();
      }),
    );

    this.subscriptions.push(
      this.dialogRef.afterClosed().subscribe((result) => {
        this.afterEditDialogCLose(result);
      }),
    );
  }

  beforeEditDialogClose(): void {
    if (!this.routingFilterNr) {
      this.router.navigate(['/tasks'], { queryParams: {} });
    } else {
      this.router.navigate(['/tasks/list', this.routingFilterNr, 'filter'], { queryParams: {} });
    }
  }

  afterEditDialogCLose(result): void {
    this.resetDatagridState();
    this.delayedUpdateFilterCounts();

    if (result !== undefined && result.actionId !== undefined) {
      if (result.task !== undefined) {
        this.refreshTaskRow(result.task);
      }

      this.store.dispatch(new CrmItemClearStatus({ actionId: result.actionId }));
      this.toastrService.success('Item saved successfully!');
    }
  }

  refreshTaskRow(updatedTask): void {
    this.dataSource.data = this.dataSource.data.map((taskItem: TaskModel) => {
      return taskItem.uuid === updatedTask.uuid ? updatedTask : taskItem;
    });
  }

  gotoEditUrl($event, taskUuid: string): void {
    if (!this.routingFilterNr) {
      this.router.navigate(['/tasks'], { queryParams: { taskUuid } });
    } else {
      this.router.navigate(['/tasks/list', this.routingFilterNr, 'filter'], { queryParams: { taskUuid } });
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  getFullEditUrl(taskUuid: string): string {
    return this.tasksService.getAbsoluteUrl((<any>this.platformLocation).location.origin, taskUuid);
  }

  updateTaskRow(taskModel: TaskModel): void {
    if (taskModel) {
      this.refreshTaskRow(taskModel);
    }
  }

  handleTaskUrlCopy($event, taskUuid: string): void {
    this.toastrService.success(`Task link ${this.getFullEditUrl(taskUuid)} copied to clipboard.`);
    $event.preventDefault();
    $event.stopPropagation();
  }

  getStatusName(statusKey: string) {
    const foundItem = taskStatuses.find((item) => item.key === statusKey);
    if (foundItem) {
      return foundItem.name;
    }
  }
  getAssigneeName(assigneeName, assigneeType) {
    if (this.assigneeOptions && this.assigneeOptions.length) {
      const foundItem = this.assigneeOptions.find((item) => {
        if (item.type === assigneeType) {
          return item.name === assigneeName;
        }
      });
      if (foundItem) {
        if (foundItem instanceof UserModel || foundItem instanceof PermissionModel) {
          return foundItem.name;
        } else {
          return assigneeName;
        }
      } else {
        return assigneeName;
      }
    }
  }

  getAuthorName(authorUuidKey: string) {
    if (this.assigneeOptions && this.assigneeOptions.length) {
      const foundItem = this.assigneeOptions
        .filter((item) => item.type === 'user')
        .find((item) => {
          return item.key === authorUuidKey;
        });

      if (foundItem) {
        if (foundItem instanceof UserModel) {
          return foundItem.name;
        } else {
          return authorUuidKey;
        }
      } else {
        return authorUuidKey;
      }
    }
  }

  resetDatagridState(): void {
    this.selectNone();
    this.triggerFetchTasks(true);
  }

  openAddTaskDialog(): void {
    this.dialogRef = this.dialog.open(TaskAddEditComponent, {
      ...DIALOG_SIZE.DEFAULT,
      disableClose: true,
      data: {
        currentSupplier: false,
      },
    });

    this.subscriptions.push(
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.toastrService.success('Task added successfully');
        }
      }),
    );

    this.subscriptions.push(
      this.dialogRef.beforeClosed().subscribe(() => {
        this.resetDatagridState();
      }),
    );
  }
}
