<div class="card">
  <div class="card-content" *ngIf="this.data$ | async; let twoFaData; else: loading">
    <span class="card-title">Two-Factor Authentication</span>

    <div class="row mt-3 font-weight-bold">
      <div class="col-5">Phone Number</div>
      <div class="col-5">Token</div>
      <div class="col-2"></div>
    </div>
    <div class="row mt-1">
      <div class="col-5 text-muted align-self-center">{{ twoFaData?.phoneNumberTxt || '-' }}</div>
      <div class="col-5 text-muted align-self-center">{{ twoFaData?.authy_id || '-' }}</div>
      <div class="col-2 align-self-center">
        <div
          *ngIf="twoFaData?.is_tfa_active"
          app-confirm-delete
          class="delete-optin text-center"
          [closeOnConfirm]="true"
          icon="delete"
          (onConfirm)="onDelete()"
        ></div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="card-content">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>
