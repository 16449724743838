export enum EVA_IFRAME_SELECTOR {
  TOP_BAR = 'app-supplier-box-header-eva',
  QA = 'app-admin-qa',
  CONTRACTS = 'app-contracts',
  FEATURE_FLAGS = 'app-admin-feature-flags',
  EVENT_RULES = 'app-event-rules-2',
  SUPPLIER_CONTRACTS = 'app-supplier-contracts',
  ONBOARDING_DETAILS = 'app-supplier-onboarding-details',
  UNDERWRITING_BETA = 'app-supplier-underwriting-beta',
  TRACKER = 'app-tracker',
}
