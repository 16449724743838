import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from '../../supplier-onboarding/supplier-onboarding.service';
import { RuleEngine } from '../models/rule-engine.interface';
import { RuleEngineItem } from '../models/rule-rngine-item.interface';
import { SupplierUnderwritingService } from '../supplier-underwriting.service';

export class UWDailyDataSource extends DataSource<any> {
  previousStep = 1;
  todayDate = new Date();
  previousMonth = new Date(this.todayDate.setMonth(this.todayDate.getMonth() - this.previousStep));
  isLoadingResults = true;
  initialFilterRecords = [];
  order_defect_rate_rule: RuleEngineItem;
  late_shipment_rate_rule: RuleEngineItem;
  late_responses_rule: RuleEngineItem;
  pre_fullfillment_cancel_rate_rule: RuleEngineItem;
  delivered_on_time_rate_rule: RuleEngineItem;
  valid_tracking_rate_rule: RuleEngineItem;
  return_dissatisfaction_rate_rule: RuleEngineItem;

  constructor(
    private supplierUnderwritingService: SupplierUnderwritingService,
    private supplierKey: string,
    private numericRules: RuleEngine,
  ) {
    super();
    this.order_defect_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'order_defect_rate';
    })[0];
    this.late_shipment_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'late_shipment_rate';
    })[0];
    this.late_responses_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'late_responses';
    })[0];
    this.pre_fullfillment_cancel_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'pre_fullfillment_cancel_rate';
    })[0];
    this.delivered_on_time_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'delivered_on_time';
    })[0];
    this.valid_tracking_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'valid_tracking_rate';
    })[0];
    this.return_dissatisfaction_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'return_dissatisfaction_rate';
    })[0];
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any> {
    const queryDate = this.previousMonth.getFullYear() + '-' + (this.previousMonth.getMonth() + 1) + '-' + this.previousMonth.getDate();

    return this.supplierUnderwritingService
      .getDailyUnderwritingData(this.supplierKey, AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY, queryDate)
      .pipe(
        switchMap((data) => {
          // clear records from scrapper - do not include records without timestamps as they are worthless //
          const initialFilterRecords = data.filter((item) => {
            return item.Timestamp;
          });
          initialFilterRecords.map((item) => {
            item.timestamp = {
              year: item.Timestamp.split('-')[0],
              month: item.Timestamp.split('-')[1],
              day: item.Timestamp.split('-')[2],
            };
            try {
              item.dailyData = [
                {
                  name: 'Order defect rate',
                  comparison: '<=',
                  warning_value:
                    Number(this.order_defect_rate_rule.warning_value) >= 0 ? Number(this.order_defect_rate_rule.warning_value) : 'no data',
                  error_value:
                    Number(this.order_defect_rate_rule.error_value) >= 0 ? Number(this.order_defect_rate_rule.error_value) : 'no data',
                  value: item['Account Performance Info']['Order Defect Rate'] || 'N/A',
                  isWarning:
                    Number(item['Account Performance Info']['Order Defect Rate'].split('%')[0]) >
                      Number(this.order_defect_rate_rule.warning_value) &&
                    Number(item['Account Performance Info']['Order Defect Rate'].split('%')[0]) <
                      Number(this.order_defect_rate_rule.error_value),
                  isError:
                    Number(item['Account Performance Info']['Order Defect Rate'].split('%')[0]) >
                    Number(this.order_defect_rate_rule.error_value),
                  decision: Number((item['Account Performance Info']['Order Defect Rate'] || 'N/A').split('%')[0]) <= 1,
                },
                {
                  name: 'Late shipment',
                  comparison: '<=',
                  warning_value:
                    Number(this.late_shipment_rate_rule.warning_value) >= 0
                      ? Number(this.late_shipment_rate_rule.warning_value)
                      : 'no data',
                  error_value:
                    Number(this.late_shipment_rate_rule.error_value) >= 0 ? Number(this.late_shipment_rate_rule.error_value) : 'no data',
                  value: item['Account Performance Info']['Late Shipment Rate'] || 'N/A',
                  isWarning:
                    Number(item['Account Performance Info']['Late Shipment Rate'].split('%')[0]) >
                      Number(this.late_shipment_rate_rule.warning_value) &&
                    Number(item['Account Performance Info']['Late Shipment Rate'].split('%')[0]) <
                      Number(this.late_shipment_rate_rule.error_value),
                  isError:
                    Number(item['Account Performance Info']['Late Shipment Rate'].split('%')[0]) >
                    Number(this.late_shipment_rate_rule.error_value),
                  decision: Number((item['Account Performance Info']['Late Shipment Rate'] || 'N/A').split('%')[0]) <= 4,
                },
                {
                  name: 'Late responses',
                  comparison: '<=',
                  warning_value:
                    Number(this.late_responses_rule.warning_value) >= 0 ? Number(this.late_responses_rule.warning_value) : 'no data',
                  error_value: Number(this.late_responses_rule.error_value) >= 0 ? Number(this.late_responses_rule.error_value) : 'no data',
                  value: item['Account Performance Info']['Late responses'] || 'N/A',
                  isWarning:
                    Number(item['Account Performance Info']['Late responses'].split('%')[0]) >
                      Number(this.late_responses_rule.warning_value) &&
                    Number(item['Account Performance Info']['Late responses'].split('%')[0]) < Number(this.late_responses_rule.error_value),
                  isError:
                    Number(item['Account Performance Info']['Late responses'].split('%')[0]) > Number(this.late_responses_rule.error_value),
                  decision: Number((item['Account Performance Info']['Late responses'] || 'N/A').split('%')[0]) <= 20,
                },
                {
                  name: 'Cancellation rate',
                  comparison: '<=',
                  warning_value:
                    Number(this.pre_fullfillment_cancel_rate_rule.warning_value) >= 0
                      ? Number(this.pre_fullfillment_cancel_rate_rule.warning_value)
                      : 'no data',
                  error_value:
                    Number(this.pre_fullfillment_cancel_rate_rule.error_value) >= 0
                      ? Number(this.pre_fullfillment_cancel_rate_rule.error_value)
                      : 'no data',
                  value: item['Account Performance Info']['Cancellation Rate'] || 'N/A',
                  isWarning:
                    Number(item['Account Performance Info']['Cancellation Rate'].split('%')[0]) >
                      Number(this.pre_fullfillment_cancel_rate_rule.warning_value) &&
                    Number(this.pre_fullfillment_cancel_rate_rule.value) < Number(this.pre_fullfillment_cancel_rate_rule.error_value),
                  isError:
                    Number(item['Account Performance Info']['Cancellation Rate'].split('%')[0]) >
                    Number(this.pre_fullfillment_cancel_rate_rule.error_value),
                  decision: Number((item['Account Performance Info']['Cancellation Rate'] || 'N/A').split('%')[0]) <= 2.5,
                },
                {
                  name: 'Delivered on time',
                  comparison: '>=',
                  warning_value:
                    Number(this.delivered_on_time_rate_rule.warning_value) >= 0
                      ? Number(this.delivered_on_time_rate_rule.warning_value)
                      : 'no data',
                  error_value:
                    Number(this.delivered_on_time_rate_rule.error_value) >= 0
                      ? Number(this.delivered_on_time_rate_rule.error_value)
                      : 'no data',
                  value: item['Account Performance Info']['Delivered on time'] || 'N/A',
                  isWarning:
                    Number(item['Account Performance Info']['Delivered on time'].split('%')[0]) <
                      Number(this.delivered_on_time_rate_rule.warning_value) &&
                    Number(this.delivered_on_time_rate_rule.value) > Number(this.delivered_on_time_rate_rule.error_value),
                  isError:
                    Number(item['Account Performance Info']['Delivered on time'].split('%')[0]) <
                    Number(this.delivered_on_time_rate_rule.error_value),
                  decision: Number((item['Account Performance Info']['Delivered on time'] || 'N/A').split('%')[0]) > 95,
                },
                {
                  name: 'Valid tracking rate',
                  comparison: '>=',
                  warning_value:
                    Number(this.valid_tracking_rate_rule.warning_value) >= 0
                      ? Number(this.valid_tracking_rate_rule.warning_value)
                      : 'no data',
                  error_value:
                    Number(this.valid_tracking_rate_rule.error_value) >= 0 ? Number(this.valid_tracking_rate_rule.error_value) : 'no data',
                  value: item['Account Performance Info']['Valid Tracking Rate - All Categories'] || 'N/A',
                  isWarning:
                    Number(item['Account Performance Info']['Valid Tracking Rate - All Categories'].split('%')[0]) <
                      Number(this.valid_tracking_rate_rule.warning_value) &&
                    Number(this.valid_tracking_rate_rule.value) > Number(this.valid_tracking_rate_rule.error_value),
                  isError:
                    Number(item['Account Performance Info']['Valid Tracking Rate - All Categories'].split('%')[0]) <
                    Number(this.valid_tracking_rate_rule.error_value),
                  decision: Number((item['Account Performance Info']['Valid Tracking Rate - All Categories'] || 'N/A').split('%')[0]) > 95,
                },
                {
                  name: 'Return dissatisfaction rate',
                  comparison: '<=',
                  warning_value:
                    Number(this.return_dissatisfaction_rate_rule.warning_value) >= 0
                      ? Number(this.return_dissatisfaction_rate_rule.warning_value)
                      : 'no data',
                  error_value:
                    Number(this.return_dissatisfaction_rate_rule.error_value) >= 0
                      ? Number(this.return_dissatisfaction_rate_rule.error_value)
                      : 'no data',
                  value: item['Account Performance Info']['Return Dissatisfaction Rate'] || 'N/A',
                  isWarning:
                    Number(item['Account Performance Info']['Return Dissatisfaction Rate'].split('%')[0]) >
                      Number(this.return_dissatisfaction_rate_rule.warning_value) &&
                    Number(this.return_dissatisfaction_rate_rule.value) < Number(this.return_dissatisfaction_rate_rule.error_value),
                  isError:
                    Number(item['Account Performance Info']['Return Dissatisfaction Rate'].split('%')[0]) >
                    Number(this.return_dissatisfaction_rate_rule.error_value),
                  decision: Number((item['Account Performance Info']['Return Dissatisfaction Rate'] || 'N/A').split('%')[0]) < 10,
                },
              ];
              item.noData = false;
            } catch (e) {
              item.dailyData = [
                { name: 'Order defect rate', comparison: '<=', target: '1%', value: 'no data', decision: 'no data' },
                { name: 'Late shipment', comparison: '<=', target: '4%', value: 'no data', decision: 'no data' },
                { name: 'Late responses', comparison: '<=', target: '20%', value: 'no data', decision: 'no data' },
                { name: 'Cancellation rate', comparison: '<=', target: '2.5%', value: 'no data', decision: 'no data' },
                { name: 'Delivered on time', comparison: '=>', target: '80%', value: 'no data', decision: 'no data' },
                { name: 'Valid tracking rate', comparison: '>=', target: '95%', value: 'no data', decision: 'no data' },
                { name: 'Return dissatisfaction rate', comparison: '<=', target: '10%', value: 'no data', decision: 'no data' },
              ];
              item.noData = true;
            }
            item.dailyErrorDecision = item.dailyData.filter(function (record) {
              if (record.isError) {
                return record;
              }
            });
            item.dailyWarningDecision = item.dailyData.filter(function (record) {
              if (record.isWarning) {
                return record;
              }
            });
          });
          this.isLoadingResults = false;
          return of(initialFilterRecords);
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch errors.
          return of([]);
        }),
      );
  }
  disconnect() {}
}
