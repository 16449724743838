import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { ClickOutsideModule } from 'ng-click-outside';

import { CrmToolsModule } from '../crm-tools/crm-tools.module';
import { AddPaymentNoteComponent } from '../processes/supplier-payments-details/add-payment-note/add-payment-note.component';
import { ApprovePaymentComponent } from '../processes/supplier-payments-details/approve-payment/approve-payment.component';
// eslint-disable-next-line max-len
import { SupplierPaymentsDetailsComponent } from '../processes/supplier-payments-details/supplier-payment-details/supplier-payment-details/supplier-payment-details.component';
/* eslint-disable */
import { SupplierPaymentDetailsHeaderComponent } from '../processes/supplier-payments-details/supplier-payment-details-header/supplier-payment-details-header/supplier-payment-details-header.component';
import { B2bDocumentService } from '../shared/b2b-document/b2b-document.service';
import { CardFormgroupMessageService } from '../shared/card-formgroup/card-formgroup-message.service';
import { NgHttpLoaderModule } from '../shared/http-loader/ng-http-loader.module';
import { CommunicationService } from '../shared/iframe/communication/communication.service';
import { LookupRepository } from '../shared/lookup-service/lookup-repository';
import { MonitorService } from '../shared/monitor.service';
import { PybDocumentsService } from '../shared/pyb-documents/pyb-documents.service';
import { CookieService } from '../shared/services/cookie-service/cookie-service';
import { SharedModule } from '../shared/shared.module';
import { SseService } from '../shared/sse.service';
import { TableEventsService } from '../shared/table-events.service';
import { AppsDocumentService } from './../shared/apps-document/apps-document.service';
import { BackgroundReportsService } from './services/background-reports/background-reports.service';
import { CancelSupplierService } from './services/cancel-supplier.service';
import { CliService } from './services/cli/cli.service';
import { EfsService } from './services/efs.service';
import { FundingParticularsService } from './services/funding-particulars.service';
import { MarketplaceLoansService } from './services/marketplace-loans.service';
import { PybExtensionService } from './services/pyb-extension/pyb-extension.service';
import { SupplierService } from './services/supplier.service';
import { SupplierAuthTokenService } from './services/supplier-auth-token.service';
import { SupplierComplianceActionService } from './services/supplier-compliance-action.service';
import { SupplierMarketplaceFundingService } from './services/supplier-marketplace-funding.service';
import { SupplierMarketplaceMinFeeOverridesService } from './services/supplier-marketplace-min-fee-overrides.service';
import { SupplierMarketplaceOptInPeriodsService } from './services/supplier-marketplace-opt-in-periods.service';
import { SupplierMarketplacesService } from './services/supplier-marketplaces.service';
import { SupplierReferralService } from './services/supplier-referral.service';
import { SupplierStatusesService } from './services/supplier-statuses.service';
import { WinbackService } from './services/winback.service';
import { B2bWorkflowService } from './services/workflow/b2b-workflow.service';
import { ActionsImportMenuComponent } from './supplier/actions-import-menu/actions-import-menu.component';
import { AddChargebackComponent } from './supplier/add-chargeback/add-chargeback.component';
import { AddContractComponent } from './supplier/add-contract/add-contract.component';
import { AddMarketplacePaymentComponent } from './supplier/add-marketplace-payment/add-marketplace-payment.component';
import { OpenAddMpPaymentDirective } from './supplier/add-marketplace-payment/open-add-mp-payment.directive';
import { AddMarketplaceStatementComponent } from './supplier/add-marketplace-statement/add-marketplace-statement.component';
import { AddReceivableComponent } from './supplier/add-receivable/add-receivable.component';
import { OpenAddReceivableDirective } from './supplier/add-receivable/open-add-receivable.directive';
import { AddRecoveryComponent } from './supplier/add-recovery/add-recovery.component';
import { OpenAddRecoveryDirective } from './supplier/add-recovery/open-add-recovery.directive';
import { AddWriteoffComponent } from './supplier/add-writeoff/add-writeoff.component';
import { OpenAddWriteoffDirective } from './supplier/add-writeoff/open-add-writeoff.directive';
import { AddZeroMarketplacePaymentComponent } from './supplier/add-zero-marketplace-payment/add-zero-marketplace-payment.component';
import { OpenAddZeroMpPaymentDirective } from './supplier/add-zero-marketplace-payment/open-add-zero-mp-payment.directive';
import { AdditionalBusinessOwnersComponent } from './supplier/additional-business-owners/additional-business-owners.component';
import { AdditionalBusinsessOwnerService } from './supplier/additional-business-owners/additional-businsess-owner.service';
import { AdjustPayabilityFeeComponent } from './supplier/adjust-payability-fee/adjust-payability-fee.component';
import { CancelSupplierComponent } from './supplier/cancel-supplier/cancel-supplier.component';
import { CardTransactionsDetailsComponent } from './supplier/card-transactions-details/card-transactions-details.component';
import { ChooseCardTypeComponent } from './supplier/choose-card-type/choose-card-type.component';
import { CreateTransferRequestComponent } from './supplier/create-transfer-request/create-transfer-request.component';
import { DeleteSupplierAccountComponent } from './supplier/delete-supplier-account/delete-supplier-account.component';
import { DeleteSupplierAccountService } from './supplier/delete-supplier-account/delete-supplier-account.service';
import { EditMarketplaceStatementComponent } from './supplier/edit-marketplace-statement/edit-marketplace-statement.component';
import { KycDocumentationComponent } from './supplier/kyc-documentation/kyc-documentation.component';
import { LoadEarningsComponent } from './supplier/load-earnings/load-earnings.component';
import { LoadRemittanceComponent } from './supplier/load-remittance/load-remittance.component';
import { MarketplaceAccessDetailsComponent } from './supplier/marketplace-access-details/marketplace-access-details.component';
import { SupplierComponent } from './supplier/supplier.component';
import { Supplier2FaModule } from './supplier/supplier-2fa/supplier-2fa.module';
import { ActiveAdvanceDetailsComponent } from './supplier/supplier-advances/active-advance/active-advance-details/active-advance-details.component';
import { PayAdvanceComponent } from './supplier/supplier-advances/active-advance/active-advance-details/pay-advance/pay-advance.component';
import { ShortOfferSummaryComponent } from './supplier/supplier-advances/active-advance/active-advance-details/short-offer-summary/short-offer-summary.component';
import { ActiveAdvanceLineComponent } from './supplier/supplier-advances/active-advance/active-advance-line.component';
import { ActiveAdvanceLineDetailsComponent } from './supplier/supplier-advances/active-advance/active-advance-line-details/active-advance-line-details/active-advance-line-details.component';
import { AdvanceOfferInputsModalComponent } from './supplier/supplier-advances/advance-offer-inputs-modal/advance-offer-inputs-modal.component';
import { ApprovedAdvanceLinesComponent } from './supplier/supplier-advances/approved-advance-lines/approved-advance-lines.component';
import { ApprovedAdvanceLinesTableComponent } from './supplier/supplier-advances/approved-advance-lines/approved-advance-lines-table/approved-advance-lines-table.component';
import { ApprovedAdvancesComponent } from './supplier/supplier-advances/approved-advances/approved-advances.component';
import { BaseAdvancesComponent } from './supplier/supplier-advances/base-advances/base-advances.component';
import { NewOfferComponent } from './supplier/supplier-advances/offer-eligibility/new-offer/new-offer.component';
import { OfferEligibilityDetailsComponent } from './supplier/supplier-advances/offer-eligibility/offer-eligibilty-details/offer-eligibility-details.component';
import { PendingOfferComponent } from './supplier/supplier-advances/pending-offer/pending-offer.component';
import { PendingOfferCalculatorModule } from './supplier/supplier-advances/pending-offer/pending-offer-calculator/pending-offer-calculator.module';
import { PendingOfferDetailsComponent } from './supplier/supplier-advances/pending-offer/pending-offer-details/pending-offer-details.component';
import { RequestedAdvancesComponent } from './supplier/supplier-advances/requested-advances/requested-advances.component';
import { SupplierAdvancesService } from './supplier/supplier-advances/service/supplier-advances.service';
import { SupplierAdvancesComponent } from './supplier/supplier-advances/supplier-advances.component';
import { UnderwritingsComponent } from './supplier/supplier-advances/underwritings/underwritings.component';
import { SupplierAmazonLendingComponent } from './supplier/supplier-amazon-lending/supplier-amazon-lending.component';
import { SupplierAmazonLendingListComponent } from './supplier/supplier-amazon-lending/supplier-amazon-lending-list/supplier-amazon-lending-list.component';
import { SupplierAmazonLendingLoanComponent } from './supplier/supplier-amazon-lending/supplier-amazon-lending-loan/supplier-amazon-lending-loan.component';
import { SupplierBoxHeaderEvaComponent } from './supplier/supplier-box-header/eva/supplier-box-header-eva.component';
import { HeaderMarketplaceListComponent } from './supplier/supplier-box-header/header-marketplace-list/header-marketplace-list.component';
import { SupplierBoxHeaderComponent } from './supplier/supplier-box-header/supplier-box-header.component';
import { SupplierBoxHeaderService } from './supplier/supplier-box-header/supplier-box-header.service';
import { SupplierBoxHeaderOldComponent } from './supplier/supplier-box-header/supplier-box-header-old/supplier-box-header.component';
import { SupplierChangePasswordDialogComponent } from './supplier/supplier-change-password-dialog/supplier-change-password-dialog.component';
import { SupplierContractsComponent } from './supplier/supplier-contracts/supplier-contracts.component';
import { SupplierEfsAccountComponent } from './supplier/supplier-efs-account/supplier-efs-account.component';
import { PaymentFeesComponent } from './supplier/supplier-fees/payment-fees/payment-fees.component';
import { PurchaseFeesComponent } from './supplier/supplier-fees/purchase-fees/purchase-fees.component';
import { EditMarketplaceComponent } from './supplier/supplier-fees/reserve/edit-marketplace/edit-marketplace.component';
import { ReserveComponent } from './supplier/supplier-fees/reserve/reserve.component';
import { SupplierFeesService } from './supplier/supplier-fees/service/supplier-fees.service';
import { SupplierFeesComponent } from './supplier/supplier-fees/supplier-fees.component';
import { SupplierGeneralAdvanceAuditorComponent } from './supplier/supplier-general/advance-auditor/supplier-advance-auditor.component';
import { SupplierGeneralComponent } from './supplier/supplier-general/supplier-general.component';
import { SupplierGeneralForm } from './supplier/supplier-general/supplier-general-form';
import { AccountDelinquencyComponent } from './supplier/supplier-health/account-delinquency/account-delinquency.component';
import { SupplierHealthComponent } from './supplier/supplier-health/supplier-health.component';
import { SupplierMarketplaceStatementsComponent } from './supplier/supplier-marketplace-statements/supplier-marketplace-statements.component';
import { FeeScheduleTableComponent } from './supplier/supplier-marketplaces/supplier-marketplace-edit/fee-schedule-table/fee-schedule-table.component';
import { FindSchedule } from './supplier/supplier-marketplaces/supplier-marketplace-edit/fee-schedule-table/find-fee-schedule.pipe';
import { MinFeeOverridesTableComponent } from './supplier/supplier-marketplaces/supplier-marketplace-edit/min-fee-overrides-table/min-fee-overrides-table.component';
import { OptInTableComponent } from './supplier/supplier-marketplaces/supplier-marketplace-edit/opt-in-table/opt-in-table.component';
import { SupplierAuthTokenComponent } from './supplier/supplier-marketplaces/supplier-marketplace-edit/supplier-auth-token/supplier-auth-token.component';
import { SupplierMarketplaceEditComponent } from './supplier/supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-edit.component';
import { SupplierMarketplaceForm } from './supplier/supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-form';
import { SupplierMarketplaceListComponent } from './supplier/supplier-marketplaces/supplier-marketplace-list/supplier-marketplace-list.component';
import { SupplierMarketplaceSingleGatewayComponent } from './supplier/supplier-marketplaces/supplier-marketplace-list/supplier-marketplace-single-gateway/supplier-marketplace-single-gateway.component';
import { SupplierNewPaymentGatewayDialogComponent } from './supplier/supplier-marketplaces/supplier-marketplace-list/supplier-new-payment-gateway-dialog/supplier-new-payment-gateway-dialog.component';
import { SupplierSingleMarketplaceComponent } from './supplier/supplier-marketplaces/supplier-marketplace-list/supplier-single-marketplace/supplier-single-marketplace.component';
import { SupplierMarketplacesComponent } from './supplier/supplier-marketplaces/supplier-marketplaces.component';
import { SupplierMarqetaService } from './supplier/supplier-marqeta-account/supplier-marqeta.service';
import { SupplierMarqetaAccountComponent } from './supplier/supplier-marqeta-account/supplier-marqeta-account.component';
import { MarketplaceAuthCodeGetComponent } from './supplier/supplier-onboarding/marketplace-auth-code-get/marketplace-auth-code-get.component';
import { MarketplaceAuthCodeSaveComponent } from './supplier/supplier-onboarding/marketplace-auth-code-save/marketplace-auth-code-save.component';
import { MarketplaceAuthInviteResentComponent } from './supplier/supplier-onboarding/marketplace-auth-invite-resent/marketplace-auth-invite-resent.component';
import { MarketplaceAuthRecheckPermComponent } from './supplier/supplier-onboarding/marketplace-auth-recheck-perm/marketplace-auth-recheck-perm.component';
import { MarketplaceAuthRemoveAccessComponent } from './supplier/supplier-onboarding/marketplace-auth-remove-access/marketplace-auth-remove-access.component';
import { OnboardingProcessModalComponent } from './supplier/supplier-onboarding/onboarding-process-modal/onboarding-process-modal.component';
import { ProductFrequentEarningLoadingComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/product-frequent-earning-loading/product-frequent-earning-loading.component';
import { ProductInstantAccessComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/product-instant-access/product-instant-access.component';
import { ProductInstantAdvanceComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/product-instant-advance/product-instant-advance.component';
import { ProductInstantPayoutComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/product-instant-payout/product-instant-payout.component';
import { ProductOpenInvoicesComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/product-open-invoices/product-open-invoices.component';
import { ProductPaymentGatewaysComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/product-payment-gateways/product-payment-gateways.component';
import { ProductWeeklyPaymentsComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/product-weekly-payments/product-weekly-payments.component';
import { FlexParticipationService } from './supplier/supplier-onboarding/services-product-matrix/service-table/service/flex-participation.service';
import { ProductMatrixService } from './supplier/supplier-onboarding/services-product-matrix/service-table/service/product-matrix.service';
import { ServiceTableComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/service-table.component';
import { FreemiumMarketplaceHeadersComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/sub-product-freemium-flex-participation/freemium-marketplace-headers/freemium-marketplace-headers.component';
import { FreemiumMarketplaceRowComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/sub-product-freemium-flex-participation/freemium-marketplace-row/freemium-marketplace-row.component';
import { MarketplaceStatusIndicatorComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/sub-product-freemium-flex-participation/freemium-marketplace-row/marketplace-status-indicator/marketplace-status-indicator.component';
import { SubProductFreemiumFlexParticipationComponent } from './supplier/supplier-onboarding/services-product-matrix/service-table/sub-product-freemium-flex-participation/sub-product-freemium-flex-participation.component';
import { SMSNumbersComponent } from './supplier/supplier-onboarding/smsnumbers/smsnumbers.component';
import { SupplierOnboardingComponent } from './supplier/supplier-onboarding/supplier-onboarding.component';
import { SupplierOnboardingService } from './supplier/supplier-onboarding/supplier-onboarding.service';
import { SupplierOnboardingCompliancesComponent } from './supplier/supplier-onboarding/supplier-onboarding-compliances/supplier-onboarding-compliances.component';
import { SupplierOnboardingDetailsComponent } from './supplier/supplier-onboarding/supplier-onboarding-details/supplier-onboarding-details.component';
import { SupplierOnboardingMarketplaceAccessComponent } from './supplier/supplier-onboarding/supplier-onboarding-marketplace-access/supplier-onboarding-marketplace-access.component';
import { SupplierOnboardingMarketplacePermissionsComponent } from './supplier/supplier-onboarding/supplier-onboarding-marketplace-permissions/supplier-onboarding-marketplace-permissions.component';
import { SupplierSalesInformationComponent } from './supplier/supplier-onboarding/supplier-sales-information/supplier-sales-information.component';
import { SupplierVerifyDocumentService } from './supplier/supplier-onboarding/supplier-verify-document-dialog/supplier-verify-document.service';
import { SupplierVerifyDocumentDialogComponent } from './supplier/supplier-onboarding/supplier-verify-document-dialog/supplier-verify-document-dialog.component';
import { IncomingPaymentAddComponent } from './supplier/supplier-payment/incoming-payment-add/incoming-payment-add.component';
import { OutgoingPaymentAddComponent } from './supplier/supplier-payment/outgoing-payment-add/outgoing-payment-add.component';
import { PaymentHistoryService } from './supplier/supplier-payment/outgoing-payment-add/payment-history.service';
import { PaymentTypeAchComponent } from './supplier/supplier-payment/outgoing-payment-add/payment-types/payment-type-ach/payment-type-ach.component';
import { PaymentTypeEfsComponent } from './supplier/supplier-payment/outgoing-payment-add/payment-types/payment-type-efs/payment-type-efs.component';
import { PaymentTypeMarqetaComponent } from './supplier/supplier-payment/outgoing-payment-add/payment-types/payment-type-marqeta/payment-type-marqeta.component';
import { PaymentTypePpmComponent } from './supplier/supplier-payment/outgoing-payment-add/payment-types/payment-type-ppm/payment-type-ppm.component';
import { PaymentTypeWireComponent } from './supplier/supplier-payment/outgoing-payment-add/payment-types/payment-type-wire/payment-type-wire.component';
import { SupplierPaymentComponent } from './supplier/supplier-payment/supplier-payment.component';
import { BankAccountsDialogComponent } from './supplier/supplier-payment/supplier-payment-list/bank-accounts-dialog/bank-accounts-dialog.component';
import { SupplierPaymentDebitRequestComponent } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-debit-request/supplier-payment-debit-request.component';
import { SupplierPaymentListComponent } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-list.component';
import { SupplierPaymentManualLinkComponent } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-manual-link/supplier-payment-manual-link.component';
import { SupplierPaymentManualLinkFormComponent } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-manual-link/supplier-payment-manual-link-form/supplier-payment-manual-link-form.component';
import { SupplierPaymentPlaidInformationComponent } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-plaid-information/supplier-payment-plaid-information.component';
import { SupplierPaymentPlaidInformationRowComponent } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-plaid-information/supplier-payment-plaid-information-row/supplier-payment-plaid-information-row.component';
import { SupplierPlaidService } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-plaid-information/supplier-plaid.service';
import { SupplierPaymentPromptsComponent } from './supplier/supplier-payment/supplier-payment-list/supplier-payment-prompts/supplier-payment-prompts.component';
import { AddReceiveableComponent } from './supplier/supplier-receivables/add-receiveable/add-receiveable.component';
import { SupplierReceivablesComponent } from './supplier/supplier-receivables/supplier-receivables.component';
import { SupplierReportDetailsComponent } from './supplier/supplier-reports/supplier-report-details/supplier-report-details.component';
import { SupplierReportListComponent } from './supplier/supplier-reports/supplier-report-list/supplier-report-list.component';
import { SupplierReportsComponent } from './supplier/supplier-reports/supplier-reports.component';
import { ImportLoadEarningsComponent } from './supplier/supplier-statements/import-load-earnings/import-load-earnings.component';
import { SupplierStatementsComponent } from './supplier/supplier-statements/supplier-statements.component';
import { CategorizationComponent } from './supplier/supplier-summary/categorization/categorization.component';
import { SupplierSummaryComponent } from './supplier/supplier-summary/supplier-summary.component';
import { CancelTransactionComponent } from './supplier/supplier-transaction-details/cancel-transaction/cancel-transaction.component';
import { FailedPaymentComponent } from './supplier/supplier-transaction-details/failed-payment/failed-payment.component';
import { NestedTransactionComponent } from './supplier/supplier-transaction-details/nested-transaction/nested-transaction.component';
import { SupplierTransactionDetailsComponent } from './supplier/supplier-transaction-details/supplier-transaction-details.component';
import { DailySummaryReportComponent } from './supplier/supplier-transaction-summary/daily-summary-report/daily-summary-report.component';
import { SupplierTransactionSummaryComponent } from './supplier/supplier-transaction-summary/supplier-transaction-summary.component';
import { BackgroundReportComponent } from './supplier/supplier-underwriting/background-reports/background-report/background-report.component';
import { BackgroundReportCardComponent } from './supplier/supplier-underwriting/background-reports/background-report-card/background-report-card.component';
import { BackgroundReportCardHeaderComponent } from './supplier/supplier-underwriting/background-reports/background-report-card-header/background-report-card-header.component';
import { BackgroundReportsComponent } from './supplier/supplier-underwriting/background-reports/background-reports.component';
import { DocumentationComponent } from './supplier/supplier-underwriting/documentation/documentation.component';
import { DocumentationCardComponent } from './supplier/supplier-underwriting/documentation/documentation-card/documentation-card.component';
import { DocumentationCardHeaderComponent } from './supplier/supplier-underwriting/documentation/documentation-card-header/documentation-card-header.component';
import { DocumentationReportComponent } from './supplier/supplier-underwriting/documentation/documentation-report/documentation-report.component';
import { BaseUnderwritingComponent } from './supplier/supplier-underwriting/real-time-validation/base-underwriting-component/base-underwriting.component';
import { RealTimeValidatorMockService } from './supplier/supplier-underwriting/real-time-validation/real-time-validator.mock.service';
import { UwPersonalChecksCardComponent } from './supplier/supplier-underwriting/real-time-validation/uw-personal-checks-card/uw-personal-checks-card.component';
import { MainVerificationCheckboxesComponent } from './supplier/supplier-underwriting/supplier-information/main-verification-checkboxes/main-verification-checkboxes.component';
import { SupplierBusinessAddressComponent } from './supplier/supplier-underwriting/supplier-information/supplier-business-address/supplier-business-address.component';
import { SupplierBusinessDetailsComponent } from './supplier/supplier-underwriting/supplier-information/supplier-business-details/supplier-business-details.component';
import { SupplierHomeAddressComponent } from './supplier/supplier-underwriting/supplier-information/supplier-home-address/supplier-home-address.component';
import { SupplierInformationComponent } from './supplier/supplier-underwriting/supplier-information/supplier-information.component';
import { SupplierOwnersComponent } from './supplier/supplier-underwriting/supplier-information/supplier-owners/supplier-owners.component';
import { UwReviewComponent } from './supplier/supplier-underwriting/supplier-information/uw-review/uw-review.component';
import { SupplierUnderwritingComponent } from './supplier/supplier-underwriting/supplier-underwriting.component';
import { SupplierUnderwritingService } from './supplier/supplier-underwriting/supplier-underwriting.service';
import { BusinessComponent } from './supplier/supplier-underwriting/trans-union/business/business.component';
import { TransUnionPersonComponent } from './supplier/supplier-underwriting/trans-union/person/trans-union-person.component';
import { UccComponent } from './supplier/supplier-underwriting/trans-union/ucc/ucc.component';
import { UccService } from './supplier/supplier-underwriting/ucc-main/ucc.service';
import { UccCreateComponent } from './supplier/supplier-underwriting/ucc-main/ucc-create/ucc-create.component';
import { UccListComponent } from './supplier/supplier-underwriting/ucc-main/ucc-list/ucc-list.component';
import { UccMainComponent } from './supplier/supplier-underwriting/ucc-main/ucc-main.component';
import { UccTransUnionComponent } from './supplier/supplier-underwriting/ucc-main/ucc-trans-union/ucc-trans-union.component';
import { UnderwratingDailyTableComponent } from './supplier/supplier-underwriting/underwriting-daily/underwrating-daily-table/underwrating-daily-table.component';
import { UnderwritingDailyComponent } from './supplier/supplier-underwriting/underwriting-daily/underwriting-daily.component';
import { UnderwritingDailyEditComponent } from './supplier/supplier-underwriting/underwriting-daily/underwriting-daily-edit/underwriting-daily-edit.component';
import { AccountHealthComponent } from './supplier/supplier-underwriting/underwriting-initial/account-health/account-health.component';
import { AgreementReviewComponent } from './supplier/supplier-underwriting/underwriting-initial/agreement-review/agreement-review.component';
import { AmazonAdvertisingComponent } from './supplier/supplier-underwriting/underwriting-initial/amazon-advertising/amazon-advertising.component';
import { AmazonApiComponent } from './supplier/supplier-underwriting/underwriting-initial/amazon-api/amazon-api.component';
import { AmazonInformationComponent } from './supplier/supplier-underwriting/underwriting-initial/amazon-information/amazon-information.component';
import { AmazonLendingComponent } from './supplier/supplier-underwriting/underwriting-initial/amazon-lending/amazon-lending.component';
import { AmazonRevenueComponent } from './supplier/supplier-underwriting/underwriting-initial/amazon-revenue/amazon-revenue.component';
import { UnderwritingInitialCardHeaderComponent } from './supplier/supplier-underwriting/underwriting-initial/common/components/underwriting-initial-card-header/underwriting-initial-card-header.component';
import { CreditCheckComponent } from './supplier/supplier-underwriting/underwriting-initial/credit-check/credit-check.component';
import { InitialUnderwritingHistoryComponent } from './supplier/supplier-underwriting/underwriting-initial/initial-underwriting-history/initial-underwriting-history.component';
import { InternationalSupplierComponent } from './supplier/supplier-underwriting/underwriting-initial/international-supplier/international-supplier.component';
import { PersonalBackgroundCheckComponent } from './supplier/supplier-underwriting/underwriting-initial/personal-background-check/personal-background-check.component';
import { InstantAccessComponent } from './supplier/supplier-underwriting/underwriting-initial/review-final-offer-dialog/instant-access/instant-access.component';
import { InstantAdvanceComponent } from './supplier/supplier-underwriting/underwriting-initial/review-final-offer-dialog/instant-advance/instant-advance.component';
import { InstantPayoutsComponent } from './supplier/supplier-underwriting/underwriting-initial/review-final-offer-dialog/instant-payouts/instant-payouts.component';
import { ReviewFinalOfferDialogComponent } from './supplier/supplier-underwriting/underwriting-initial/review-final-offer-dialog/review-final-offer-dialog.component';
import { UnderwritingFilesComponent } from './supplier/supplier-underwriting/underwriting-initial/underwriting-files/underwriting-files.component';
import { UnderwritingInitialComponent } from './supplier/supplier-underwriting/underwriting-initial/underwriting-initial.component';
import { UnderwritingInitialFooterComponent } from './supplier/supplier-underwriting/underwriting-initial/underwriting-initial-footer/underwriting-initial-footer.component';
import { UnderwritingInitialHeaderComponent } from './supplier/supplier-underwriting/underwriting-initial/underwriting-initial-header/underwriting-initial-header.component';
import { UnderwritingWorkflowComponent } from './supplier/supplier-underwriting/underwriting-initial/underwriting-workflow/underwriting-workflow.component';
import { UnderwritingReportTabComponent } from './supplier/supplier-underwriting/underwriting-report-tab/underwriting-report-tab.component';
import { SupplierUnderwritingBetaComponent } from './supplier/supplier-underwriting-beta/supplier-underwriting-beta.component';
import { UnlockSupplierComponent } from './supplier/supplier-unlock/supplier-unlock.component';
import { SuspendSupplierComponent } from './supplier/suspend-supplier/suspend-supplier.component';
import { ToggleHideSupplierComponent } from './supplier/toggle-hide-supplier/toggle-hide-supplier.component';
import { TransactionModalComponent } from './supplier/transaction-modal/transaction-modal.component';
import { WinbackConfirmationModalComponent } from './supplier/winback-confirmation-modal/winback-confirmation-modal.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { AutoPaymentService } from './services/auto-payment.service';

/* eslint-enable */

@NgModule({
  imports: [
    SharedModule,
    HttpClientModule,
    CrmToolsModule,
    NgHttpLoaderModule,
    ReactiveFormsModule,
    PendingOfferCalculatorModule,
    AgGridModule,
    ClickOutsideModule,
    Supplier2FaModule,
  ],
  declarations: [
    SupplierComponent,
    SupplierListComponent,
    SupplierComponent,
    SupplierGeneralComponent,
    SupplierOnboardingComponent,
    SupplierPaymentComponent,
    SupplierPaymentListComponent,
    SupplierMarketplacesComponent,
    SupplierMarketplaceEditComponent,
    SupplierMarketplaceListComponent,
    SupplierMarketplaceStatementsComponent,
    SupplierStatementsComponent,
    SupplierReportsComponent,
    SupplierTransactionSummaryComponent,
    SupplierTransactionDetailsComponent,
    CancelTransactionComponent,
    FailedPaymentComponent,
    NestedTransactionComponent,
    SupplierReceivablesComponent,
    SupplierHealthComponent,
    SupplierReportDetailsComponent,
    SupplierReportListComponent,
    MarketplaceAuthCodeGetComponent,
    MarketplaceAuthCodeSaveComponent,
    MarketplaceAuthInviteResentComponent,
    MarketplaceAuthRecheckPermComponent,
    MarketplaceAuthRemoveAccessComponent,
    AddReceiveableComponent,
    SupplierAuthTokenComponent,
    IncomingPaymentAddComponent,
    OutgoingPaymentAddComponent,
    SupplierBoxHeaderComponent,
    SupplierBoxHeaderOldComponent,
    SupplierBoxHeaderEvaComponent,
    AccountDelinquencyComponent,
    ImportLoadEarningsComponent,
    SupplierChangePasswordDialogComponent,
    SupplierVerifyDocumentDialogComponent,
    ToggleHideSupplierComponent,
    DeleteSupplierAccountComponent,
    OptInTableComponent,
    FeeScheduleTableComponent,
    FindSchedule,
    MinFeeOverridesTableComponent,
    PaymentTypeAchComponent,
    PaymentTypeWireComponent,
    PaymentTypeEfsComponent,
    PaymentTypePpmComponent,
    SupplierUnderwritingComponent,
    UnderwritingInitialComponent,
    UnderwritingDailyComponent,
    KycDocumentationComponent,
    MarketplaceAccessDetailsComponent,
    BusinessComponent,
    TransUnionPersonComponent,
    UccComponent,
    TransUnionPersonComponent,
    ActiveAdvanceLineComponent,
    ActiveAdvanceDetailsComponent,
    NewOfferComponent,
    OfferEligibilityDetailsComponent,
    PendingOfferComponent,
    PendingOfferDetailsComponent,
    RequestedAdvancesComponent,
    ApprovedAdvancesComponent,
    SupplierAdvancesComponent,
    BaseAdvancesComponent,
    PayAdvanceComponent,
    AddChargebackComponent,
    AddReceivableComponent,
    OpenAddReceivableDirective,
    AddWriteoffComponent,
    OpenAddWriteoffDirective,
    AddRecoveryComponent,
    OpenAddRecoveryDirective,
    AddMarketplacePaymentComponent,
    OpenAddMpPaymentDirective,
    AddZeroMarketplacePaymentComponent,
    OpenAddZeroMpPaymentDirective,
    ActionsImportMenuComponent,
    LoadEarningsComponent,
    LoadRemittanceComponent,
    SuspendSupplierComponent,
    CancelSupplierComponent,
    LoadRemittanceComponent,
    SupplierPaymentDetailsHeaderComponent,
    SupplierPaymentsDetailsComponent,
    AddPaymentNoteComponent,
    ApprovePaymentComponent,
    SupplierAmazonLendingComponent,
    SupplierAmazonLendingListComponent,
    SupplierAmazonLendingLoanComponent,
    AdjustPayabilityFeeComponent,
    SupplierEfsAccountComponent,
    UccMainComponent,
    UccListComponent,
    UccCreateComponent,
    UccTransUnionComponent,
    OnboardingProcessModalComponent,
    ChooseCardTypeComponent,
    PaymentTypeMarqetaComponent,
    SupplierFeesComponent,
    PaymentFeesComponent,
    PurchaseFeesComponent,
    ReserveComponent,
    OnboardingProcessModalComponent,
    EditMarketplaceComponent,
    AdditionalBusinessOwnersComponent,
    SupplierInformationComponent,
    CardTransactionsDetailsComponent,
    TransactionModalComponent,
    SupplierMarqetaAccountComponent,
    AddMarketplaceStatementComponent,
    EditMarketplaceStatementComponent,
    UwReviewComponent,
    MainVerificationCheckboxesComponent,
    ShortOfferSummaryComponent,
    ReviewFinalOfferDialogComponent,
    InstantAccessComponent,
    InstantAdvanceComponent,
    InstantPayoutsComponent,
    ShortOfferSummaryComponent,
    ServiceTableComponent,
    ProductInstantAccessComponent,
    ProductInstantAdvanceComponent,
    ProductInstantPayoutComponent,
    ProductOpenInvoicesComponent,
    ProductWeeklyPaymentsComponent,
    ProductPaymentGatewaysComponent,
    ProductFrequentEarningLoadingComponent,
    ShortOfferSummaryComponent,
    SupplierSingleMarketplaceComponent,
    SupplierNewPaymentGatewayDialogComponent,
    SupplierMarketplaceSingleGatewayComponent,
    DailySummaryReportComponent,
    SupplierSalesInformationComponent,
    SupplierOnboardingCompliancesComponent,
    SupplierOnboardingMarketplaceAccessComponent,
    SupplierOnboardingMarketplacePermissionsComponent,
    SupplierOnboardingDetailsComponent,
    SupplierPaymentPlaidInformationComponent,
    SupplierPaymentPromptsComponent,
    SupplierSummaryComponent,
    CategorizationComponent,
    SubProductFreemiumFlexParticipationComponent,
    FreemiumMarketplaceRowComponent,
    FreemiumMarketplaceHeadersComponent,
    MarketplaceStatusIndicatorComponent,
    SupplierPaymentDebitRequestComponent,
    SupplierPaymentPlaidInformationRowComponent,
    SupplierPaymentDebitRequestComponent,
    ApprovedAdvanceLinesComponent,
    ActiveAdvanceLineDetailsComponent,
    AddContractComponent,
    SupplierContractsComponent,
    BankAccountsDialogComponent,
    UwPersonalChecksCardComponent,
    BaseUnderwritingComponent,
    SupplierHomeAddressComponent,
    SupplierBusinessAddressComponent,
    SupplierBusinessDetailsComponent,
    SupplierOwnersComponent,
    UnderwritingFilesComponent,
    AgreementReviewComponent,
    PersonalBackgroundCheckComponent,
    CreditCheckComponent,
    InternationalSupplierComponent,
    AmazonInformationComponent,
    UnderwritingInitialCardHeaderComponent,
    UnderwritingInitialHeaderComponent,
    AmazonAdvertisingComponent,
    AmazonLendingComponent,
    AmazonRevenueComponent,
    AccountHealthComponent,
    AmazonApiComponent,
    InitialUnderwritingHistoryComponent,
    UnderwritingInitialFooterComponent,
    UnderwritingWorkflowComponent,
    BackgroundReportsComponent,
    BackgroundReportCardComponent,
    BackgroundReportCardHeaderComponent,
    BackgroundReportComponent,
    UnderwritingDailyEditComponent,
    UnderwratingDailyTableComponent,
    UnderwritingsComponent,
    AdvanceOfferInputsModalComponent,
    ApprovedAdvanceLinesTableComponent,
    HeaderMarketplaceListComponent,
    SMSNumbersComponent,
    DocumentationComponent,
    DocumentationCardComponent,
    DocumentationCardHeaderComponent,
    DocumentationReportComponent,
    CreateTransferRequestComponent,
    SupplierPaymentManualLinkComponent,
    SupplierPaymentManualLinkFormComponent,
    WinbackConfirmationModalComponent,
    SupplierUnderwritingBetaComponent,
    UnderwritingReportTabComponent,
    SupplierGeneralAdvanceAuditorComponent,
    UnlockSupplierComponent,
  ],
  providers: [
    SupplierPlaidService,
    SupplierStatusesService,
    SupplierReferralService,
    SupplierService,
    AutoPaymentService,
    SupplierVerifyDocumentService,
    SupplierMarketplacesService,
    SupplierOnboardingService,
    SupplierUnderwritingService,
    FundingParticularsService,
    B2bDocumentService,
    AppsDocumentService,
    PybDocumentsService,
    CardFormgroupMessageService,
    SupplierGeneralForm,
    SupplierMarketplaceForm,
    SupplierMarketplaceFundingService,
    SupplierMarketplaceOptInPeriodsService,
    SupplierMarketplaceMinFeeOverridesService,
    SupplierAuthTokenService,
    SupplierAdvancesService,
    MarketplaceLoansService,
    PybExtensionService,
    EfsService,
    LookupRepository,
    SseService,
    B2bWorkflowService,
    SupplierComplianceActionService,
    CliService,
    TableEventsService,
    CancelSupplierService,
    UccService,
    SupplierFeesService,
    AdditionalBusinsessOwnerService,
    MonitorService,
    PaymentHistoryService,
    SupplierMarqetaService,
    DeleteSupplierAccountService,
    FlexParticipationService,
    RealTimeValidatorMockService,
    ProductMatrixService,
    CookieService,
    BackgroundReportsService,
    WinbackService,
    SupplierBoxHeaderService,
    CommunicationService,
  ],
  entryComponents: [
    BusinessComponent,
    TransUnionPersonComponent,
    UccComponent,
    AddChargebackComponent,
    AddContractComponent,
    AddReceivableComponent,
    AddWriteoffComponent,
    AddRecoveryComponent,
    AddMarketplacePaymentComponent,
    AddZeroMarketplacePaymentComponent,
    SupplierChangePasswordDialogComponent,
    SupplierVerifyDocumentDialogComponent,
    CancelSupplierComponent,
    CancelTransactionComponent,
    FailedPaymentComponent,
    NestedTransactionComponent,
    LoadEarningsComponent,
    LoadRemittanceComponent,
    AddPaymentNoteComponent,
    AdjustPayabilityFeeComponent,
    ApprovePaymentComponent,
    PayAdvanceComponent,
    OfferEligibilityDetailsComponent,
    NewOfferComponent,
    OnboardingProcessModalComponent,
    ChooseCardTypeComponent,
    AddMarketplaceStatementComponent,
    EditMarketplaceStatementComponent,
    TransactionModalComponent,
    ReviewFinalOfferDialogComponent,
    SupplierNewPaymentGatewayDialogComponent,
    SupplierPaymentDebitRequestComponent,
    ApprovedAdvancesComponent,
    AdvanceOfferInputsModalComponent,
    CreateTransferRequestComponent,
    BankAccountsDialogComponent,
    WinbackConfirmationModalComponent,
  ],
})
export class SuppliersModule { }
