export enum MESSAGE_SOURCE {
  PARENT = 'parent',
  CHILD = 'child',
}

export enum MESSAGE_TYPE {
  GENERAL__CONFIG_REQUEST = '[GENERAL] Config Request',
  GENERAL__CONFIG_RESPONSE = '[GENERAL] Config Response',
  GENERAL__CONFIG_UPDATE = '[GENERAL] Config Update',

  GENERAL__NAVIGATE_BY_LINK = '[GENERAL] Navigate By Link',
  GENERAL__ONLY_CHANGE_ROUTE = '[GENERAL] Only Change Route',
  GENERAL__SHOW_TOAST = '[GENERAL] Show Toast',
  GENERAL__UPDATE_SUPPLIER_DETAILS_STORE = '[GENERAL] Update Supplier Details Store',
  GENERAL__CHANGE_PARENT_ROUTE = '[GENERAL] Change Parent Route',

  MAIN_HEADER__NAVIGATE_BY_DEAL_LINK = '[MAIN HEADER] Navigate By Deal Link',
  MAIN_HEADER__LOGIN_AS_SUPPLIER = '[MAIN HEADER] Login As Supplier',
  MAIN_HEADER__LOGIN_TO_AMAZON = '[MAIN HEADER] Login To Amazon',
  MAIN_HEADER__REFETCH_SUPPLIER_DETAILS = '[MAIN HEADER] Refetch Supplier Details',

  CONTRACTS__REFRESH = '[CONTRACTS] Refresh page',

  ONBOARDING__INIT_LOAD_EARNINGS_PROCESS = '[ONBOARDING] Init Load Earnings Process',

  FEATURE_FLAGS__RESET = '[FEATURE FLAGS] Reset',

  GET_STORE__SUPPLIER_DETAILS = '[GET STORE] Supplier Details',
  UPDATE_EVA_STORE__SUPPLIER_DETAILS = '[UPDATE EVA STORE] Supplier Details',
  SUPPLIER_MENU__GET_MENU_CONDITIONS = '[SUPPLIER MENU] Get Menu Conditions',

  RESIZE_IFRAME_SIZE = '[RESIZE] Resize Iframe Size',
  RESIZE_IFRAME_WIDTH_SIZE = '[RESIZE] Resize Iframe Width Size',
  RESIZE_IFRAME_HEIGHT_SIZE = '[RESIZE] Resize Iframe Height Size',
  RESET_IFRAME_SIZE = '[RESIZE] Reset Iframe Size',

  FULL_SIZE_TOP_BAR = '[RESIZE] Full Size Top Bar',
  REVERT_TOP_BAR_SIZE = '[RESIZE] Revert Top Bar Size',
}
