import { Pipe, PipeTransform } from '@angular/core';
import { isString, isUndefined } from 'util';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(value: any, count, char): string {
    return this.ellipsis(value, count, char);
  }

  private ellipsis(text: string, count: number, char: string = '...'): string {
    if (isUndefined(count) || count < 2) {
      return text;
    }

    if (isUndefined(text) || !isString(text)) {
      return '';
    }

    if (count >= text.length) {
      return text;
    }

    return [text.slice(0, Math.floor(count / 2)).replace(/\s+$/, ''), char, text.slice(-Math.ceil(count / 2)).replace(/^\s+/, '')].join('');
  }
}
