/* eslint-disable */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { DefaultRadiobuttons } from '../common/consts/underwritings-initial-constants';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';
/* eslint-enable */

@Component({
  selector: 'app-amazon-information',
  templateUrl: './amazon-information.component.html',
})
export class AmazonInformationComponent {
  @Input() amazonInformationDecision: any;
  @Input() amazonInformation: any;
  @Input() computingDataIsInProgress: boolean;

  @Output() onAmazonInformationChange = new EventEmitter();

  readonly userPermission = USER_PERMISSION;

  underwritingInitialCardHeaderStatusType = UnderwritingInitialCardHeaderStatusType;
  radiobuttons = DefaultRadiobuttons;

  amazonInformationChange(value, index) {
    if (!this.amazonInformation || !this.amazonInformation[index]) {
      return;
    }
    this.amazonInformation[index].value = value;
    this.onAmazonInformationChange.emit(this.amazonInformation);
  }
}
