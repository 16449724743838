<div class="field-wrapper" [class]="fieldClass" [ngClass]="{ 'has-icon': materialIcon, 'in-group-edit': inGroupEdit }">
  <!-- EDIT MODE -->
  <div *ngIf="inEditMode() || isInlineEditOnly(); else presentationMode" class="edit-mode">
    <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>

    <mat-form-field>
      <input
        matInput
        placeholder="{{ label }}"
        [formControl]="formControl"
        [name]="value"
        [type]="type"
        [placeholder]="label"
        (blur)="markAsTouched()"
        data-test="dateInput"
      />

      <input
        matInput
        [matDatepicker]="inlineEditControl"
        placeholder="{{ label }}"
        [name]="value"
        [type]="type"
        [placeholder]="label"
        [formControl]="dateControl"
        class="display-none"
      />

      <mat-datepicker-toggle matSuffix [for]="inlineEditControl"></mat-datepicker-toggle>
      <mat-datepicker #inlineEditControl></mat-datepicker>
    </mat-form-field>

    <mat-error *ngIf="hasRequiredError()"> This field is <strong>required</strong> </mat-error>

    <div class="help-text-wrapper warning" *ngIf="helpText && !hasRequiredError()">
      {{ helpText }}
    </div>

    <div class="inline-buttons" *ngIf="isInlineEditOnly()">
      <button class="btn btn-xs btn-cancel" (click)="close($event)">Cancel <i class="material-icons">close</i></button>
      <button class="btn btn-xs btn-success btn-save" (click)="callSave(formControl.value)">Save <i class="material-icons">done</i></button>
    </div>
  </div>

  <ng-template #presentationMode>
    <div class="presentation-mode" [ngClass]="{ 'disable-inline': disableInline }">
      <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>
      <div class="fake-field">
        <div class="label">
          {{ label }}
          <mat-icon *ngIf="showCopy" ngxClipboard [cbContent]="value" class="btn-copy" matTooltip="Click to copy {{ label }}"
            >content_copy</mat-icon
          >
        </div>
        <div class="value" (click)="edit(value)" (focus)="edit(value)">{{ (value | date: 'MM/dd/yyyy') || defaultEmptyValue }}</div>
      </div>
    </div>
  </ng-template>
</div>
