import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isNullOrUndefined, isObject } from 'util';

import { PermissionModel } from '../../../../shared/users/permission.model';
import { UsersService } from '../../../../shared/users/service/users.service';
import { UserModel } from '../../../../shared/users/user.model';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

// TODO: mabe we can refactor grid filters to use this component.
@Component({
  selector: 'app-assignee-picker',
  templateUrl: './assignee-picker.component.html',
})
export class AssigneePickerComponent extends SubscriberComponent implements OnInit {
  availableAssignees: (UserModel | PermissionModel)[] = [];
  filteredAssignees: Observable<(UserModel | PermissionModel)[]>;
  assignee = new FormControl();

  @Output() onSelected = new EventEmitter();

  constructor(private usersService: UsersService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.usersService.getCombinedList().subscribe((options) => {
        this.availableAssignees = options;
      }),
    );

    this.filteredAssignees = this.assignee.valueChanges.pipe(
      startWith(''),
      map((assignee) => (assignee ? this.filterUsers(assignee, this.availableAssignees) : this.availableAssignees.slice())),
    );
  }

  private filterUsers(name: any, availableItems) {
    const userName = isObject(name) ? name.name : name;

    return availableItems.filter((authorModel) => {
      if (authorModel.type === 'user') {
        return authorModel.name.toLowerCase().indexOf(userName.toLowerCase()) === 0;
      } else {
        return authorModel.name.toLowerCase().indexOf(userName.toLowerCase()) === 0;
      }
    });
  }

  displayAssigneeFn(user: UserModel): string {
    return !isNullOrUndefined(user) ? (typeof user === 'object' ? user.name : user) : '';
  }
}
