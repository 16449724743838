import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ResetPasswordComponent } from './reset-password/reset-password.component';

const moduleRoutes: Routes = [{ path: 'reset-password', component: ResetPasswordComponent }];

@NgModule({
  imports: [RouterModule.forChild(moduleRoutes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
