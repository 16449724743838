<h1 mat-dialog-title class="text-center">Report validation summary</h1>
<div mat-dialog-content class="text-left">
  <table class="table table-condensed table-hover">
    <thead>
      <tr>
        <th>Property</th>
        <th width="20%">Value</th>
        <th width="20%">Decision</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Number of Liens</td>
        <td>{{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.NumberOfLiens || 'no data' }}</td>
        <td>
          {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.NumberOfLiens === '0' ? 'Ok' : 'Needs review' }}
        </td>
      </tr>
      <tr>
        <td>Number of bankruptcies</td>
        <td>{{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.NumberOfBankruptcies || 'no data' }}</td>
        <td>
          {{
            personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.NumberOfBankruptcies === '0'
              ? 'Ok'
              : 'Needs review'
          }}
        </td>
      </tr>
      <tr>
        <td>Number of bankruptcy records</td>
        <td>{{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.NumberOfBankruptcyRecords || 'no data' }}</td>
        <td>
          {{
            personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.NumberOfBankruptcyRecords === '0'
              ? 'Ok'
              : 'Needs review'
          }}
        </td>
      </tr>
      <tr>
        <td>Number of judgments</td>
        <td>{{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.NumberOfJudgments || 'no data' }}</td>
        <td>
          {{
            personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.NumberOfJudgments === '0' ? 'Ok' : 'Needs review'
          }}
        </td>
      </tr>
      <tr>
        <td>Criminal records founds</td>
        <td>{{ personData?.comprehensive?.CriminalRecordsMatch?.TLOCriminalSearchMatch?.length || 'no data' }}</td>
        <td>{{ personData?.comprehensive?.CriminalRecordsMatch?.TLOCriminalSearchMatch?.length > 0 ? 'Needs review' : 'Ok' }}</td>
      </tr>
      <tr>
        <td>Drivers licenses</td>
        <td>
          {{
            personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.DriversLicenses?.TLODriversLicense[1]
              ?.DriversLicenseNumber || '-'
          }}
        </td>
      </tr>
      <tr>
        <td>Professional licenses</td>
        <td>
          {{
            personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.ProfessionalLicenses?.TLOProfessionalLicense[0]
              ?.LicenseType || '-'
          }}
          {{
            personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.ProfessionalLicenses?.TLOProfessionalLicense[0]
              ?.ReportToken || '-'
          }}
        </td>
      </tr>
      <tr>
        <td>Sex offender records</td>
        <td>
          {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.SexOffenderRecords || '-' }}
        </td>
      </tr>
      <tr>
        <td>Possible sex offender</td>
        <td>
          {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.PossibleSexOffender || '-' }}
        </td>
      </tr>
    </tbody>
  </table>

  <hr />

  <h4>Personal information</h4>
  <div class="row">
    <div class="col-6">
      <strong>Name:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Names?.BasicName[0]?.FirstName || '-' }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Middle Name:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Names?.BasicName[0]?.MiddleName || '-' }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Last Name:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Names?.BasicName[0]?.LastName || '-' }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Professional Suffix:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Names?.BasicName[0]?.ProfessionalSuffix || '-' }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Title:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Names?.BasicName[0]?.Title || '-' }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Date of Birth:</strong>
    </div>
    <div class="col-6">
      {{
        personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.DatesOfBirth?.BasicDateOfBirthRecord[0]?.DateOfBirth
          ?.Day +
          '/' +
          personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.DatesOfBirth?.BasicDateOfBirthRecord[0]?.DateOfBirth
            ?.Month +
          '/' +
          personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.DatesOfBirth?.BasicDateOfBirthRecord[0]?.DateOfBirth
            ?.Year
      }}
    </div>
    <div class="col-6">-</div>
  </div>
  <hr />

  <h4>Liens ({{ personData?.comprehensive?.Liens?.TLOPersonalLienFiling?.length || '-' }})</h4>
  <div *ngFor="let lien of personData?.comprehensive?.Liens?.TLOPersonalLienFiling">
    <div class="row">
      <div class="col-2">
        {{ $index + 1 }}
      </div>
      <div class="col-10">
        <div class="row">
          <div class="col-6">
            <strong> Kind of tax: </strong>
          </div>
          <div class="col-6">
            {{ lien?.KindOfTax || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Total lien amount: </strong>
          </div>
          <div class="col-6">
            {{ (lien?.TotalLienAmount | currency) || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Deed category type: </strong>
          </div>
          <div class="col-6">
            {{ lien?.DeedCategoryType || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Damar document type: </strong>
          </div>
          <div class="col-6">
            {{ lien?.DamarDocumentType || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Lien case number: </strong>
          </div>
          <div class="col-6">
            {{ lien?.CourtCaseNumber || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court business name: </strong>
          </div>
          <div class="col-6">
            {{ lien?.Court?.BusinessName || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court phone number: </strong>
          </div>
          <div class="col-6">
            {{ lien?.Court?.Phone || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Attorney name: </strong>
          </div>
          <div class="col-6">
            {{ lien?.AttorneyName || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Filing location: </strong>
          </div>
          <div class="col-6">
            {{ lien?.FilingLocation || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Recording date: </strong>
          </div>
          <div class="col-6">
            {{ lien?.RecordingDate?.Day + '/' + lien?.RecordingDate?.Month + '/' + lien?.RecordingDate?.Year }}
          </div>
          <div class="col-6">-</div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Plaintiff name: </strong>
          </div>
          <div class="col-6">
            {{ lien?.Plaintiff?.BusinessName || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Recording book number: </strong>
          </div>
          <div class="col-6">
            {{ lien?.RecordingBookNumber || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Recording page number: </strong>
          </div>
          <div class="col-6">
            {{ lien?.RecordingPageNumber || '-' }}
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
  <hr />

  <h4>Bankrupcy ({{ personData?.comprehensive?.Bankruptcies?.TLOBankruptcy?.length || '-' }})</h4>
  <div *ngFor="let bankrupcy of personData?.comprehensive?.Bankruptcies?.TLOBankruptcy; let $index = index">
    <div class="row">
      <div class="col-2">
        {{ $index + 1 }}
      </div>
      <div class="col-10">
        <div class="row">
          <div class="col-6">
            <strong> Notice type: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.NoticeType || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Liability amount: </strong>
          </div>
          <div class="col-6">
            {{ (bankrupcy?.LiabilityAmount | currency) || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Deed category type: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.DeedCategoryType || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Damar document type: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.DamarDocumentType || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Lien case number: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.CaseNumber || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court District: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.CourtDistrict || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court Division: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.CourtDivision || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court Id: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.CourtId || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court Phone: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.CourtPhone || '-' }}
          </div>
        </div>
        <div *ngFor="let debtor of bankrupcy?.Debtors?.TLOBankruptcyDebtor; let $index = index">
          <div class="row">
            <div class="col-6">
              <strong> Debtor {{ $index + 1 }} Name </strong>
            </div>
            <div class="col-6">
              {{ debtor?.Title }}
              {{ debtor?.FirstName || '-' }}
              {{ debtor?.MiddleName }}
              {{ debtor?.LastName || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Debtor {{ $index + 1 }} Phone </strong>
            </div>
            <div class="col-6">
              {{ debtor?.Phone }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Debtor {{ $index + 1 }} Address Line </strong>
            </div>
            <div class="col-6">
              {{ debtor?.Address?.Line1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Debtor {{ $index + 1 }} Address City </strong>
            </div>
            <div class="col-6">
              {{ debtor?.Address?.City }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Debtor {{ $index + 1 }} Address Zip </strong>
            </div>
            <div class="col-6">
              {{ debtor?.Address?.Zip }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Debtor {{ $index + 1 }} Address Zip4 </strong>
            </div>
            <div class="col-6">
              {{ debtor?.Address?.Zip4 }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Debtor {{ $index + 1 }} Address State </strong>
            </div>
            <div class="col-6">
              {{ debtor?.Address?.State }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Attorney name: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.AttorneyPhone || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Case Number: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.CaseNumber || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Judge name: </strong>
          </div>
          <div class="col-6">{{ bankrupcy?.JudgeName || '-' }} ({{ bankrupcy?.JudgeInitials || '-' }})</div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> File date: </strong>
          </div>
          <div class="col-6">
            {{ (bankrupcy?.FileDate?.Day || '-' + '/') + (bankrupcy?.FileDate?.Month || '-' + '/') + (bankrupcy?.FileDate?.Year || '-') }}
          </div>
          <div class="col-6">-</div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Claim date: </strong>
          </div>
          <div class="col-6">
            {{
              (bankrupcy?.ClaimDate?.Day || '-' + '/') + (bankrupcy?.ClaimDate?.Month || '-' + '/') + (bankrupcy?.ClaimDate?.Year || '-')
            }}
          </div>
          <div class="col-6">-</div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Case status date: </strong>
          </div>
          <div class="col-6">
            {{
              (bankrupcy?.CaseStatusDate?.Day || '-' + '/') +
                (bankrupcy?.CaseStatusDate?.Month || '-' + '/') +
                (bankrupcy?.CaseStatusDate?.Year || '-')
            }}
          </div>
          <div class="col-6">-</div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Closed date: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.ClosedDate?.Day + '/' + bankrupcy?.ClosedDate?.Month + '/' + bankrupcy?.ClosedDate?.Year }}
          </div>
          <div class="col-6">-</div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Plaintiff name: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.Plaintiff?.BusinessName || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Sec341 meeting location: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.Sec341MeetingLocation || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Law firm: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.LawFirm || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Trustee business name: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.TrusteeBusinessName || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Trustee email address: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.TrusteeEmailAddress || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Trustee phone: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.TrusteePhone || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Voluntary: </strong>
          </div>
          <div class="col-6">
            {{ bankrupcy?.Voluntary || '-' }}
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
  <hr />

  <h4>Judgments ({{ personData?.comprehensive?.Judgments?.TLOPersonalJudgmentFiling?.length || '-' }})</h4>
  <div *ngFor="let judgement of personData?.comprehensive?.Judgments?.TLOPersonalJudgmentFiling; let $index = index">
    <div class="row">
      <div class="col-2">
        {{ $index + 1 }}
      </div>
      <div class="col-10">
        <div class="row">
          <div class="col-6">
            <strong> Total judgment amount </strong>
          </div>
          <div class="col-6">
            {{ judgement?.TotalJudgmentAmount || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court case number: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.CourtCaseNumber || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court business name: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.Court?.BusinessName || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Court phone number: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.Court?.Phone || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Attorney name: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.AttorneyName || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Filing location: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.FilingLocation || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Filing date: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.FilingDate?.Day || '-' + '/' + judgement?.FilingDate?.Month || '-' + '/' + judgement?.FilingDate?.Year || '-' }}
          </div>
          <div class="col-6" *ngIf="!judgement?.FilingDate?.Day">-</div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Plaintiff name: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.Plaintiff?.BusinessName || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Recording book number: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.RecordingBookNumber || '-' }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong> Recording page number: </strong>
          </div>
          <div class="col-6">
            {{ judgement?.RecordingPageNumber || '-' }}
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
  <hr />

  <h4>Criminal records ({{ personData?.comprehensive?.CriminalRecordsMatch?.TLOCriminalSearchMatch?.length }})</h4>
  <div>
    <div *ngFor="let criminal of personData?.comprehensive?.CriminalRecordsMatch?.TLOCriminalSearchMatch; let $index = index">
      <div class="row">
        <div class="col-2">
          {{ $index + 1 }}
        </div>
        <div class="col-10">
          <div class="row">
            <div class="col-6">
              <strong> Offense description: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.OffenseDescription1 || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Case type: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.CaseType || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Crime type: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.CrimeType || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Source name: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.SourceName || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Source state: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.SourceState || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Disposition: </strong>
            </div>
            <div class="col-6">
              {{
                criminal?.CriminalRecord?.CrimeDetails?.Details[criminal?.CriminalRecord?.CrimeDetails?.Details?.length - 1]?.Disposition ||
                  '-'
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Offense code: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.OffenseCode || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Grade of offense: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.GradeOfOffense || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Offense date: </strong>
            </div>
            <div class="col-6">
              {{
                (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.OffenseDate?.Day || '-' + '/') +
                  (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.OffenseDate?.Month || '-' + '/') +
                  (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.OffenseDate?.Year || '-')
              }}
            </div>
            <div class="col-6">-</div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Violation date: </strong>
            </div>
            <div class="col-6">
              {{
                (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.ViolationDate?.Day || '-' + '/') +
                  (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.ViolationDate?.Month || '-' + '/') +
                  (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.ViolationDate?.Year || '-')
              }}
            </div>
            <div class="col-6">-</div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Arrest date: </strong>
            </div>
            <div class="col-6">
              {{
                (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.ArrestDate?.Day || '-' + '/') +
                  (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.ArrestDate?.Month || '-' + '/') +
                  (criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.ArrestDate?.Year || '-')
              }}
            </div>
            <div class="col-6">-</div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Victim: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.Victim || '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong> Victim is minor: </strong>
            </div>
            <div class="col-6">
              {{ criminal?.CriminalRecord?.CrimeDetails?.Details[0]?.VictimIsMinor || '-' }}
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
    <hr />
  </div>

  <h4>Address</h4>
  <div class="row">
    <div class="col-6">
      <strong>City:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address?.City }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>State:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address?.State }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Zip:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address?.Zip }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Zip4:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address?.Zip4 }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>County:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address?.County }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Address Line:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address?.Line1 }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Address Line 2:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address?.Line2 }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Address Line 3:</strong>
    </div>
    <div class="col-6">
      {{ personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address?.Line3 }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Subdivision Name:</strong>
    </div>
    <div class="col-6">
      {{
        personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.Address
          ?.SubdivisionName || '-'
      }}
    </div>
  </div>
  <hr />

  <h4>Address reports</h4>

  <div class="row">
    <div class="col-6">
      <strong>UCC Filings at address</strong>
    </div>
    <div class="col-6">
      {{
        personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]
          ?.UCCFilingsAtAddress || '-'
      }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Liens at address</strong>
    </div>
    <div class="col-6">
      {{
        personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.LiensAtAddress || '-'
      }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Judgments at address</strong>
    </div>
    <div class="col-6">
      {{
        personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.JudgmentsAtAddress ||
          '-'
      }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Bankruptcies at address</strong>
    </div>
    <div class="col-6">
      {{
        personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]
          ?.BankruptciesAtAddress || '-'
      }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Criminal record matches V005</strong>
    </div>
    <div class="col-6">
      {{
        personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]
          ?.CriminalRecordMatchesV005 || '-'
      }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <strong>Business at address</strong>
    </div>
    <div class="col-6">
      {{
        personData?.data?.PersonSearchOutputRecords?.TLOPersonSearchOutputRecord[0]?.Addresses?.BasicAddressRecord[0]?.BusinessAtAddress ||
          '-'
      }}
    </div>
  </div>
</div>
