import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';

export function RemoveCellRenderer(disabled: boolean): string {
  return `
    <div class="d-table m-auto">
      <mat-icon class="mat-icon material-icons ${disabled ? 'gray' : 'pointer'}" role="img" aria-hidden="true">delete_outline</mat-icon>
    </div>
    `;
}

export function RemoveColumn(
  removeFn: (params) => Observable<void>,
  optionalConfig: ColDef = null,
  disabledFn: (params) => boolean = () => false,
): ColDef {
  let config: ColDef = {
    field: 'delete_col',
    headerName: '',
    colId: 'delete_col',
    width: 50,
    sortable: false,
    resizable: false,
    suppressMenu: true,
    onCellClicked: (params) => (disabledFn(params.data) ? '' : removeFn(params)),
    cellRenderer: (params) => RemoveCellRenderer(disabledFn(params)),
  };

  if (optionalConfig) {
    config = { ...config, ...optionalConfig };
  }

  return config;
}
