import { IProgressivePricingSettings, Period } from '../store/calculator-interfaces';

const COUNT_PRECISION = 3;

export class PeriodsCalculationsService {
  constructor() {}

  static countTotalLength(periods: Period[]) {
    if (!periods) {
      return 0;
    }
    let totalLength = 0;
    for (let i = 0; i < periods.length; i++) {
      if (periods[i].periodLength) {
        totalLength += Number(periods[i].periodLength);
      }
    }
    return PeriodsCalculationsService.standardRound(totalLength);
  }

  static countTotalFee(periods: Period[]) {
    if (!periods) {
      return 0;
    }
    let fee = 0;
    let periodFee = 0;
    for (let i = 0; i < periods.length; i++) {
      if (periods[i].periodLength && periods[i].fee) {
        periodFee = periods[i].fee;
        if (!periodFee) {
          periodFee = 0;
        }
        fee += Number(periodFee) * Number(periods[i].periodLength);
      }
    }
    return PeriodsCalculationsService.standardRound(fee);
  }

  static countAverage(totalPeriodLength: number, totalFee: number) {
    if (!totalPeriodLength) {
      return 0;
    }

    if (!totalFee) {
      return 0;
    }

    return PeriodsCalculationsService.standardRound(totalFee / totalPeriodLength);
  }

  static standardRound(value: number, precision: number = COUNT_PRECISION): number {
    const divider = Math.pow(10, precision);
    return Math.round(value * divider) / divider;
  }

  static getPeriodsFromDefaults(progressivePricingSettings: IProgressivePricingSettings, riskRating: number): Period[] | void {
    if (riskRating < -1) {
      return;
    }

    if (riskRating === -1) {
      return progressivePricingSettings.WhenNoRiskRating;
    }

    if (!progressivePricingSettings) {
      return;
    }
    if (riskRating >= progressivePricingSettings.ForEveryRiskRating.length) {
      return;
    }

    const rating = progressivePricingSettings.ForEveryRiskRating[riskRating];

    if (!rating || !rating.length || !Array.isArray(rating)) {
      return [];
    }

    const periods = [];

    for (let i = 0; i < rating.length; i++) {
      periods.push(new Period(rating[i].periodLength, PeriodsCalculationsService.standardRound(rating[i].fee, COUNT_PRECISION)));
    }
    return periods;
  }

  static createPeriodSummary(periods: Period[]): any[] {
    const elementData = [];
    const totalPeriodLength = this.countTotalLength(periods);
    const totalFee = this.countTotalFee(periods);
    const averageFee = this.countAverage(totalPeriodLength, totalFee);

    for (let i = 0; i < periods.length; i++) {
      const periodLength = periods[i].periodLength ? periods[i].periodLength : 0;
      const periodFee = periods[i].fee ? periods[i].fee : 0;
      elementData.push({
        feePeriods: `Period ${i + 1}`,
        periodLength: `${periodLength} Weeks`,
        fee: `${periodFee} %`,
      });
    }
    elementData.push(
      {
        feePeriods: 'Total',
        periodLength: `${totalPeriodLength} Weeks`,
        fee: `${totalFee} %`,
      },
      {
        feePeriods: 'Average Fee %',
        periodLength: '',
        fee: `${averageFee} %`,
      },
    );
    return elementData;
  }

  static isEmptyFee(periods: Period[]): boolean {
    return !!periods.find((period) => !period.fee || period.fee <= 0);
  }

  static isEmptyPeriodLength(periods: Period[]): boolean {
    return !!periods.find((period) => !period.periodLength || period.periodLength <= 0);
  }
}
