<table class="mdl-data-table">
  <thead>
    <tr>
      <th class="mdl-data-table__cell--non-numeric">Start</th>
      <th class="mdl-data-table__cell--non-numeric">End</th>
      <th>Monthly Min Fee</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let fee of minFeesOverrides">
      <td class="mdl-data-table__cell--non-numeric">{{ fee.startDate | date: 'shortDate' }}</td>
      <td class="mdl-data-table__cell--non-numeric">{{ fee.endDate | date: 'shortDate' }}</td>
      <td>{{ fee.monthlyMinFee | currency: 'USD':true }}</td>
      <td app-confirm-delete class="delete-optin text-center" (onConfirm)="deleteMinFeeOverride()">
        <button mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="showAddRow; else addRow">
      <td class="mdl-data-table__cell--non-numeric">
        <mat-form-field>
          <input matInput [matDatepicker]="pickerFrom" placeholder="Choose a start date" />
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
      </td>
      <td class="mdl-data-table__cell--non-numeric">
        <mat-form-field>
          <input matInput [matDatepicker]="pickerTo" placeholder="Choose an end date" />
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field>
          <input #inlineEditControl matInput type="text" placeholder="Amount" />
        </mat-form-field>
      </td>
      <td class="text-center">
        <button mat-raised-button color="primary">
          <mat-icon>check</mat-icon>
        </button>
      </td>
    </tr>
    <ng-template #addRow>
      <td colspan="4" class="text-center">
        <a class="text-muted" (click)="showAddRow = true">Add row</a>
      </td>
    </ng-template>
  </tbody>
</table>
