<form [formGroup]="uwForm" (ngSubmit)="saveRules(uwForm)" class="uw-form">
  <div class="col-12"></div>
  <div class="card">
    <div [hidden]="editRules" class="card-content">
      <app-underwriting-initial-card-header [title]="'Account Health'" [status]="headerStatus" [type]="headerType">
      </app-underwriting-initial-card-header>
      <div class="col-12 p-0">
        <button mat-raised-button color="primary" [disabled]="isRestrictedUWView" (click)="editRulesClick($event)">Edit Rules</button>
      </div>
    </div>
    <div class="table-wrapper" [hidden]="editRules">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="10%" class="text-right">Error [%]</th>
            <th width="10%" class="text-right">Warning [%]</th>
            <th width="10%" class="text-right">Value [%]</th>
            <th width="10%" class="text-right">Decision</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Order defect rate</td>
            <td class="text-right">{{ UWRules?.order_defect_rate?.target?.error }}</td>
            <td class="text-right">{{ UWRules?.order_defect_rate?.target?.warning }}</td>
            <td class="text-right">{{ UWRules?.order_defect_rate?.value?.split('%')[0] || noDataText }}</td>
            <td class="text-right">
              <span class="text-danger" *ngIf="UWRules?.order_defect_rate?.error && UWRules.order_defect_rate?.value !== 'N/A'"
                >Needs review
              </span>
              <span
                class="text-warning"
                *ngIf="
                  (UWRules?.order_defect_rate?.warning &&
                    UWRules?.order_defect_rate?.value !== 'N/A' &&
                    !UWRules?.order_defect_rate?.error) ||
                  !UWRules?.order_defect_rate?.value
                "
                >Warning
              </span>
              <span
                class="text-success"
                *ngIf="
                  !UWRules?.order_defect_rate?.warning &&
                  !UWRules?.order_defect_rate?.error &&
                  UWRules?.order_defect_rate?.value !== 'N/A' &&
                  UWRules?.order_defect_rate?.value
                "
                >OK
              </span>
              <span class="text-info" *ngIf="UWRules?.order_defect_rate?.value === 'N/A'">N/A </span>
            </td>
          </tr>

          <!-- LATE SHIPMENT RATE-->

          <tr>
            <td>Late shipment rate</td>
            <td class="text-right">{{ UWRules?.late_shipment_rate?.target?.error }}</td>
            <td class="text-right">{{ UWRules?.late_shipment_rate?.target?.warning }}</td>
            <td class="text-right">{{ UWRules?.late_shipment_rate?.value?.split('%')[0] || noDataText }}</td>
            <td class="text-right">
              <span class="text-danger" *ngIf="UWRules?.late_shipment_rate?.error && UWRules?.late_shipment_rate?.value !== 'N/A'">
                Needs review
              </span>
              <span
                class="text-warning"
                *ngIf="
                  (UWRules?.late_shipment_rate?.warning &&
                    UWRules?.late_shipment_rate?.value !== 'N/A' &&
                    !UWRules?.late_shipment_rate?.error) ||
                  !UWRules?.late_shipment_rate?.value
                "
              >
                Warning
              </span>
              <span
                class="text-success"
                *ngIf="
                  !UWRules?.late_shipment_rate?.warning &&
                  !UWRules?.late_shipment_rate?.error &&
                  UWRules?.late_shipment_rate?.value !== 'N/A' &&
                  UWRules?.late_shipment_rate?.value
                "
              >
                OK
              </span>
              <span class="text-info" *ngIf="UWRules?.late_shipment_rate?.value === 'N/A'"> N/A </span>
            </td>
          </tr>

          <!-- LATE RESPONSE -->

          <tr>
            <td>Late response</td>
            <td class="text-right">{{ UWRules?.late_responses?.target?.error }}</td>
            <td class="text-right">{{ UWRules?.late_responses?.target?.warning }}</td>
            <td class="text-right">{{ UWRules?.late_responses?.value?.split('%')[0] || noDataText }}</td>
            <td class="text-right">
              <span class="text-danger" *ngIf="UWRules?.late_responses?.error && UWRules?.late_responses?.value !== 'N/A'">
                Needs review
              </span>
              <span
                class="text-warning"
                *ngIf="
                  (UWRules?.late_responses?.warning && UWRules?.late_responses?.value !== 'N/A' && !UWRules?.late_responses?.error) ||
                  !UWRules?.late_responses?.value
                "
              >
                Warning
              </span>
              <span
                class="text-success"
                *ngIf="
                  !UWRules?.late_responses?.warning &&
                  !UWRules?.late_responses?.error &&
                  UWRules?.late_responses?.value !== 'N/A' &&
                  UWRules?.late_responses?.value
                "
              >
                OK
              </span>
              <span class="text-info" *ngIf="UWRules?.late_responses?.value === 'N/A'">N/A</span>
            </td>
          </tr>

          <!-- Cancellation RATE -->

          <tr>
            <td>Cancellation rate</td>
            <td class="text-right">{{ UWRules?.pre_fullfillment_cancel_rate?.target?.error }}</td>
            <td class="text-right">{{ UWRules?.pre_fullfillment_cancel_rate?.target?.warning }}</td>
            <td class="text-right">
              {{ amazonHealth?.pre_fullfillment_cancel_rate?.value?.split('%')[0] || noDataText }}
            </td>
            <td class="text-right">
              <span
                class="text-danger"
                *ngIf="UWRules?.pre_fullfillment_cancel_rate?.error && UWRules?.pre_fullfillment_cancel_rate?.value !== 'N/A'"
              >
                Needs review
              </span>
              <span
                class="text-warning"
                *ngIf="
                  (UWRules?.pre_fullfillment_cancel_rate?.warning &&
                    UWRules?.pre_fullfillment_cancel_rate?.value !== 'N/A' &&
                    !UWRules?.pre_fullfillment_cancel_rate?.error) ||
                  !UWRules?.pre_fullfillment_cancel_rate?.value
                "
              >
                Warning
              </span>
              <span
                class="text-success"
                *ngIf="
                  !UWRules?.pre_fullfillment_cancel_rate?.warning &&
                  !UWRules?.pre_fullfillment_cancel_rate?.error &&
                  UWRules?.pre_fullfillment_cancel_rate?.value !== 'N/A' &&
                  UWRules?.pre_fullfillment_cancel_rate?.value
                "
              >
                OK
              </span>
              <span class="text-info" *ngIf="UWRules?.pre_fullfillment_cancel_rate?.value === 'N/A'">N/A</span>
            </td>
          </tr>

          <!-- delivered_on_time_rate -->
          <tr>
            <td>Delivered on time</td>
            <td class="text-right">{{ UWRules?.delivered_on_time?.target?.warning }}</td>
            <td class="text-right">{{ UWRules?.delivered_on_time?.target?.error }}</td>
            <td class="text-right">{{ UWRules?.delivered_on_time?.value?.split('%')[0] || noDataText }}</td>
            <td class="text-right">
              <span class="text-danger" *ngIf="UWRules?.delivered_on_time?.error && UWRules?.delivered_on_time?.value !== 'N/A'">
                Needs review
              </span>
              <span
                class="text-warning"
                *ngIf="
                  (UWRules?.delivered_on_time?.warning &&
                    UWRules?.delivered_on_time?.value !== 'N/A' &&
                    !UWRules?.delivered_on_time?.error) ||
                  !UWRules?.delivered_on_time?.value
                "
              >
                Warning
              </span>
              <span
                class="text-success"
                *ngIf="
                  !UWRules?.delivered_on_time?.warning &&
                  !UWRules?.delivered_on_time?.error &&
                  UWRules?.delivered_on_time?.value !== 'N/A' &&
                  UWRules?.delivered_on_time?.value
                "
              >
                OK
              </span>
              <span class="text-info" *ngIf="UWRules?.delivered_on_time?.value === 'N/A'">N/A</span>
            </td>
          </tr>

          <!-- valid_tracking_rate -->

          <tr>
            <td>Valid tracking rate</td>
            <td class="text-right">{{ UWRules?.valid_tracking_rate?.target?.warning }}</td>
            <td class="text-right">{{ UWRules?.valid_tracking_rate?.target?.error }}</td>
            <td class="text-right">{{ UWRules?.valid_tracking_rate?.value?.split('%')[0] || noDataText }}</td>
            <td class="text-right">
              <span class="text-danger" *ngIf="UWRules?.valid_tracking_rate?.error && UWRules?.valid_tracking_rate?.value !== 'N/A'">
                Needs review
              </span>
              <span
                class="text-warning"
                *ngIf="
                  (UWRules?.valid_tracking_rate?.warning &&
                    UWRules?.valid_tracking_rate?.value !== 'N/A' &&
                    !UWRules?.valid_tracking_rate?.error) ||
                  !UWRules?.valid_tracking_rate?.value
                "
              >
                Warning
              </span>
              <span
                class="text-success"
                *ngIf="
                  !UWRules?.valid_tracking_rate?.warning &&
                  !UWRules?.valid_tracking_rate?.error &&
                  UWRules?.valid_tracking_rate?.value !== 'N/A' &&
                  UWRules?.valid_tracking_rate?.value
                "
              >
                OK
              </span>
              <span class="text-info" *ngIf="UWRules?.valid_tracking_rate?.value === 'N/A'">N/A</span>
            </td>
          </tr>

          <!-- return_dissatisfaction_rate -->

          <tr>
            <td>Return dissatisfaction rate</td>
            <td class="text-right">{{ UWRules?.return_dissatisfaction_rate?.target?.error }}</td>
            <td class="text-right">{{ UWRules?.return_dissatisfaction_rate?.target?.warning }}</td>
            <td class="text-right">
              {{ UWRules?.return_dissatisfaction_rate?.value?.split('%')[0] || noDataText }}
            </td>
            <td class="text-right">
              <span
                class="text-danger"
                *ngIf="UWRules?.return_dissatisfaction_rate?.error && UWRules?.return_dissatisfaction_rate?.value !== 'N/A'"
              >
                Needs review
              </span>
              <span
                class="text-warning"
                *ngIf="
                  (UWRules?.return_dissatisfaction_rate?.warning &&
                    UWRules?.return_dissatisfaction_rate?.value !== 'N/A' &&
                    UWRules?.return_dissatisfaction_rate?.error) ||
                  !UWRules?.return_dissatisfaction_rate?.value
                "
              >
                Warning
              </span>
              <span
                class="text-success"
                *ngIf="
                  !UWRules?.return_dissatisfaction_rate?.warning &&
                  !UWRules?.return_dissatisfaction_rate?.error &&
                  UWRules?.return_dissatisfaction_rate?.value !== 'N/A' &&
                  UWRules?.return_dissatisfaction_rate?.value
                "
              >
                OK
              </span>
              <span class="text-info" *ngIf="UWRules?.return_dissatisfaction_rate?.value === 'N/A'">N/A</span>
            </td>
          </tr>
          <tr>
            <td>Health stats within Amazon benchmarks?</td>
            <td></td>
            <td class="text-right">OK</td>
            <td class="text-right">{{ benchmarkStatus === 'OK' ? 'OK' : 'Needs Review' }}</td>
            <td class="text-right">
              <span class="text-danger" *ngIf="benchmarkStatus !== 'OK'">Need review</span>
              <span class="text-success" *ngIf="benchmarkStatus === 'OK'">OK</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div [hidden]="!editRules" class="card-content">
      <div class="row">
        <div class="col-6">
          <h3>Edit Health Rules</h3>
        </div>
        <div class="col-6">
          <button type="submit" class="pull-right ml-3" mat-raised-button color="accent">Save Rules</button>
        </div>
      </div>
      <div class="row">
        <div class="col-4">Name</div>
        <div class="col-5 text-danger">Have to be</div>
        <div class="col-3 text-warning">Should be</div>
      </div>
      <div class="form-group col-12">
        <div class="row">
          <div class="col-4 align-self-center">
            <span class="m-0">Order defect rate:</span>
          </div>
          <div class="col-5">
            &le;
            <mat-form-field class="example-full-width">
              <input
                [min]="collectedData?.order_defect_rate_rule?.warning_value"
                max="100"
                class="form-control"
                type="number"
                formControlName="order_defect_rate"
                name="order_defect_rate"
                id="order_defect_rate"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-3 align-self-center">&le;{{ collectedData?.order_defect_rate_rule?.warning_value }}</div>
        </div>
      </div>
      <div class="form-group col-12">
        <div class="row">
          <div class="col-4 align-self-center">
            <span class="m-0"> Late shipment: </span>
          </div>
          <div class="col-5">
            &le;
            <mat-form-field class="example-full-width">
              <input
                [min]="collectedData?.late_shipment_rate_rule.warning_value"
                max="100"
                class="form-control"
                type="number"
                formControlName="late_shipment_rate"
                id="late_shipment_rate"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-3 align-self-center">
            &le;
            {{ collectedData?.late_shipment_rate_rule.warning_value }}
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <div class="row">
          <div class="col-4 align-self-center">
            <span class="m-0"> Late responses: </span>
          </div>
          <div class="col-5">
            &le;
            <mat-form-field class="example-full-width">
              <input
                [min]="collectedData?.late_responses_rule.warning_value"
                max="100"
                class="form-control"
                type="number"
                formControlName="late_responses"
                id="late_responses"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-3 align-self-center">&le;{{ collectedData?.late_responses_rule.warning_value }}</div>
        </div>
      </div>
      <div class="form-group col-12">
        <div class="row">
          <div class="col-4 align-self-center">
            <span class="m-0">Cancellation rate:</span>
          </div>
          <div class="col-5">
            &le;
            <mat-form-field class="example-full-width">
              <input
                [min]="collectedData?.pre_fullfillment_cancel_rate_rule.warning_value"
                max="100"
                class="form-control"
                type="number"
                formControlName="pre_fullfillment_cancel_rate"
                id="pre_fullfillment_cancel_rate"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-3 align-self-center">
            &le;
            {{ collectedData?.pre_fullfillment_cancel_rate_rule.warning_value }}
          </div>
        </div>
      </div>

      <div class="form-group col-12">
        <div class="row">
          <div class="col-4 align-self-center">
            <span class="m-0">Delivered on time:</span>
          </div>
          <div class="col-5">
            &ge;
            <mat-form-field class="example-full-width">
              <input
                min="0"
                [max]="collectedData?.delivered_on_time_rate_rule.warning_value"
                class="form-control"
                type="number"
                formControlName="delivered_on_time_rate"
                id="delivered_on_time_rate"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-3 align-self-center">&ge; {{ collectedData?.delivered_on_time_rate_rule.warning_value }}</div>
        </div>
      </div>
      <div class="form-group col-12">
        <div class="row">
          <div class="col-4 align-self-center">
            <span class="m-0">Valid tracking rate:</span>
          </div>
          <div class="col-5">
            &ge;
            <mat-form-field class="example-full-width">
              <input
                min="0"
                [max]="collectedData?.valid_tracking_rate_rule.warning_value"
                class="form-control"
                type="number"
                formControlName="valid_tracking_rate"
                id="valid_tracking_rate"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-3 align-self-center">&ge; {{ collectedData?.valid_tracking_rate_rule.warning_value }}</div>
        </div>
      </div>
      <div class="form-group col-12">
        <div class="row">
          <div class="col-4 align-self-center">
            <span class="m-0">Return dissatisfaction rate:</span>
          </div>
          <div class="col-5">
            &le;
            <mat-form-field class="example-full-width">
              <input
                [min]="collectedData?.return_dissatisfaction_rate_rule?.warning_value"
                max="100"
                class="form-control"
                type="number"
                formControlName="return_dissatisfaction_rate"
                id="return_dissatisfaction_rate"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-3 align-self-center">&le; {{ collectedData?.return_dissatisfaction_rate_rule.warning_value }}</div>
        </div>
      </div>
    </div>
  </div>
</form>
