import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { DIALOG_SIZE } from '../../../../../shared/dialog.config';
import { AppState } from '../../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from './../../../../../shared/confirm-dialog/confirm-dialog.component';
import { SupplierAdvancesService } from './../../service/supplier-advances.service';

@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.scss'],
})
export class NewOfferComponent extends SubscriberComponent implements OnInit {
  newOfferForm: FormGroup;
  isRequestSending = false;

  private supplierKey;
  private data;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly supplierAdvancesService: SupplierAdvancesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastrService: ToastrService,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit() {
    this.createNewForm();

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
          mergeMap((data) => this.supplierAdvancesService.getMlOffer(data.supplierKey)),
        )
        .subscribe((eligibilityData) => {
          this.data = eligibilityData;
          this.patchValue();
        }),
    );
  }

  hasRequiredError = () => this.newOfferForm && this.newOfferForm.get('requested_amount').hasError('required');

  openConfirmation() {
    const requestedAmount = this.newOfferForm.get('requested_amount').value;
    const recommendedAmount = this.newOfferForm.get('recommended_amount').value;
    const additionalMessage = 'Requested amount is greater than recommended amount.';

    let message = 'Are you sure you want to create new offer request? ';

    if (requestedAmount > recommendedAmount) {
      message = message.concat(additionalMessage);
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message },
      width: DIALOG_SIZE.SMALL.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.sendOfferRequest();
        }
      }),
    );
  }

  sendOfferRequest() {
    const formData = { ...this.newOfferForm.value };

    formData.recommended_amount = this.newOfferForm.controls['recommended_amount'].value;
    formData.reserve_total_amount = this.newOfferForm.controls['reserve_total_amount'].value;
    formData.extra_reserve_percent = this.newOfferForm.controls['extra_reserve_percent'].value;
    formData.weekly_fee_percent = this.newOfferForm.controls['weekly_fee_percent'].value;

    this.supplierAdvancesService.createOfferRequest(this.supplierKey, formData).subscribe(
      () => {
        this.toastrService.success('Offer added successfully');
        this.redirectToBase();
      },
      (error) => {
        this.toastrService.error(error.error.message);
        this.redirectToBase();
      },
    );
  }

  redirectToBase() {
    this.router.navigate(['./../'], { relativeTo: this.route });
  }

  private createNewForm() {
    this.newOfferForm = this.formBuilder.group({
      recommended_amount: [{ value: '', disabled: true }, Validators.required],
      requested_amount: ['', Validators.required],
      reserve_total_amount: [{ value: null, disabled: true }],
      extra_reserve_percent: [{ value: 25, disabled: true }, Validators.required],
      weekly_fee_percent: [{ value: 1, disabled: true }, Validators.required],
      ml_offer_key: [''],
      request_notes: [''],
      net_recommended: [{ value: '', disabled: true }],
      net_requested: [{ value: '', disabled: true }],
    });
  }

  private patchValue() {
    this.newOfferForm.patchValue({
      recommended_amount: this.data.offer_amount,
      requested_amount: this.data.offer_amount,
      reserve_total_amount: this.data.offer_amount * 0.2,
      ml_offer_key: this.data.ml_offer_key,
      net_recommended: this.data.offer_amount * 0.8,
      net_requested: this.data.offer_amount * 0.8,
    });

    this.subscriptions.push(
      this.newOfferForm.controls['requested_amount'].valueChanges.subscribe((value) => {
        this.newOfferForm.controls['net_requested'].setValue(+Number(value * 0.8).toFixed(2));
        this.newOfferForm.controls['reserve_total_amount'].setValue(+Number(value * 0.2).toFixed(2));
      }),
    );
  }
}
