<nav
  class="app-sidebar"
  appAutoCloseMobileNav
  #sidebar
  [ngClass]="{
    'bg-color-dark': ['11', '31'].indexOf(themeConfig.colorOption) >= 0,
    'bg-color-light': themeConfig.colorOption === '21',
    'bg-color-primary': ['12', '22', '32'].indexOf(themeConfig.colorOption) >= 0,
    'bg-color-success': ['13', '23', '33'].indexOf(themeConfig.colorOption) >= 0,
    'bg-color-info': ['14', '24', '34'].indexOf(themeConfig.colorOption) >= 0,
    'bg-color-warning': ['15', '25', '35'].indexOf(themeConfig.colorOption) >= 0,
    'bg-color-danger': ['16', '26', '36'].indexOf(themeConfig.colorOption) >= 0
  }"
>
  <section class="sidebar-header">
    <a [routerLink]="['/']" class="brand" data-test="sidebarLogo">
      <div class="pyb-logo-dark-background"></div>
    </a>
    <a class="collapsednav-toggler" (click)="toggleCollapsedNav()">
      <i class="material-icons">{{ themeConfig.navCollapsed ? 'radio_button_unchecked' : 'radio_button_checked' }}</i>
    </a>
  </section>

  <section class="sidebar-content">
    <app-layout-sidenav-menu></app-layout-sidenav-menu>
  </section>

  <section class="sidebar-footer version">
    <a class="text-version fe-version" *ngIf="feVersion" data-test="sidebarFeVersion">F: {{ feVersion }} </a>
    <br />
    <a class="text-version" *ngIf="beVersion" data-test="sidebarBeVersion">B: {{ beVersion }} </a>
    <br />
  </section>

  <section class="sidebar-footer">
    <ul class="nav" *ngIf="(authState | async).isAuthenticated">
      <li>
        <a mat-button aria-label="menu" [matMenuTriggerFor]="appNotification" target="_blank"
          ><i class="nav-icon material-icons" data-test="sidebarLoggedUser">account_circle</i
          ><span class="nav-text">{{ (loggedUser$ | async)?.name }}</span></a
        >
      </li>
      <mat-menu #appNotification="matMenu" x-position="after" y-position="below" overlapTrigger="false">
        <mat-list dense>
          <mat-list-item>
            <mat-icon mat-list-icon>account_circle</mat-icon>
            <a mat-line data-test="sidebarUserEmail"
              ><strong>{{ (loggedUser$ | async)?.name }}</strong></a
            >
          </mat-list-item>
          <mat-list-item>
            <mat-icon mat-list-icon>exit_to_app</mat-icon>
            <a mat-line [routerLink]="['/logout']" data-test="sidebarLogout">Logout</a>
          </mat-list-item>
        </mat-list>
      </mat-menu>
      <li>
        <a mat-button aria-label="menu" target="_blank" [href]="themeConfig.productLink" data-test="sidebarHelpSupport"
          ><i class="nav-icon material-icons">help</i><span class="nav-text"><span>Help</span> & <span>Support</span></span></a
        >
      </li>
    </ul>
  </section>
</nav>
