import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PREFIX_EVNET_RULE } from './validators/event-bridge-action-name.validator';

@Component({
  selector: 'app-send-to-event-bridge',
  templateUrl: './send-to-event-bridge.component.html',
})
export class SendToEventBridgeComponent implements OnInit {
  @Input() form: FormGroup;
  prefix: string = PREFIX_EVNET_RULE;

  constructor() {}

  ngOnInit() {}
}
