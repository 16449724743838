import { autoserialize } from 'cerialize';

import { USER_PERMISSION } from './../../shared/users/user-permissions.enum';

export interface FilterDefinition {
  predefined: FilterSet[];
  custom: FilterSet[];
}

export class FilterSet {
  @autoserialize
  title: string;

  @autoserialize
  isGroup? = false; // eslint-disable-line

  @autoserialize
  count?: number;

  @autoserialize
  filters?: { [filterKey: string]: any };

  @autoserialize
  filtersQuery?: { [filterKey: string]: any };

  subFilters?: any[];
  permCode?: USER_PERMISSION | string;
  membersCount?: number;
}
