import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { KeyValue } from '../models/key-value';
import { EvaComponent } from './../eva/eva.component';
import { FEATURE_FLAGS } from './feature-flags.const';
import { FEATURE_FLAGS_ENV, FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY } from './feature-flags.enum';
import { FeatureFlagEntity } from './feature-flags.model';
import { FeatureFlagsService } from './feature-flags.service';
import { FeatureFlagsEvaService } from './feature-flags-eva.service';

@Injectable()
export class FeatureFlagsAdminService {
  private appEva: EvaComponent;

  constructor(private featureFlagsService: FeatureFlagsService, private featureFlagsEvaService: FeatureFlagsEvaService) {}

  setAppEva(component: EvaComponent): void {
    this.appEva = component;
  }

  storeAvailableFlagsExecStatus(env: FEATURE_FLAGS_ENV, flags: KeyValue<boolean>[]): void {
    if (env === FEATURE_FLAGS_ENV.IA) {
      this.featureFlagsService.storeAvailableFlagsExecStatus(flags);
    } else {
      // Eva
      if (this.appEva) {
        this.featureFlagsEvaService.storeAndSend(flags);
      }
    }
  }

  clearAvailableFlagsExecStatus(): void {
    this.featureFlagsService.clearAvailableFlagsExecStatus();
  }

  getAvailableFlags(): string[] {
    return this.featureFlagsService.getAvailableFlags();
  }

  getRecalculatedFlagsStatus(
    env: FEATURE_FLAGS_ENV = FEATURE_FLAGS_ENV.IA,
    availableFlags: string[] = [],
    buildProvidedFlags: FEATURE_FLAGS[] = [],
    globalSettingsProvidedFlags: FEATURE_FLAGS[] = [],
    availableFlagExecStatus: KeyValue<boolean>[] = [],
  ): Promise<FeatureFlagEntity[]> {
    return this.featureFlagsService.getRecalculatedFlagsStatus(
      env,
      availableFlags,
      buildProvidedFlags,
      globalSettingsProvidedFlags,
      availableFlagExecStatus,
    );
  }

  fetchAvailableFlagsGlobalSettingsStatus(settingKey: any): Observable<FEATURE_FLAGS[]> {
    return this.featureFlagsService.fetchAvailableFlagsGlobalSettingsStatus(settingKey);
  }

  storeState(flags: string[]): Observable<any> {
    return this.featureFlagsService.storeState(FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY.IA, flags);
  }

  getEnabledFlags(flags): FeatureFlagEntity[] & string[] {
    return this.featureFlagsService.getEnabledFlags(flags);
  }

  clearGlobalSettingsFetchedFlags(): void {
    this.featureFlagsService.clearGlobalSettingsFetchedFlags();
  }
}
