<div class="grid">
  <div class="gameInfo">
    <div>
      <span class="pybLogo"></span>
      <h3 class="pybtrisTitle">Tris</h3>
      <p>In-game controls</p>
      <dl>
        <dt><strong>W</strong> or arrow UP</dt>
        <dd>Rotate</dd>

        <dt><strong>S</strong> or arrow DOWN</dt>
        <dd>one line down</dd>

        <dt><strong>A</strong> or arrow LEFT</dt>
        <dd>one column left</dd>

        <dt><strong>D</strong> or arrow RIGHT</dt>
        <dd>one column right</dd>

        <dt><strong>SPACE</strong></dt>
        <dd>drop to the ground</dd>
      </dl>
    </div>
  </div>
  <canvas #board class="gameBoard"></canvas>
  <div class="scoreBoard">
    <div>
      <p>Score: {{ points }}</p>
      <p>Multiplier: {{ pointsMultiplier }}</p>
      <p>Level: {{ level }}</p>
      <p>High Score:</p>
      <p>{{ highScore }}</p>
      <p>Next Block:</p>
      <canvas #next class="next"></canvas>
    </div>
    <app-pybtris-buttons
      [gameStarted]="gameStarted"
      [paused]="paused"
      (onPlay)="play()"
      (onPause)="pause()"
      (onReset)="reset()"
    ></app-pybtris-buttons>
  </div>
</div>
