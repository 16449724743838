import { UNDERWRITING_ACTION, UnderwritingActions } from './underwriting.actions';
import { UNDERWRITING_STATE, UnderwritingState } from './underwriting.state';

export function underwritingReducers(state = UNDERWRITING_STATE, action: UnderwritingActions): UnderwritingState {
  switch (action.type) {
    case UNDERWRITING_ACTION.FETCH_TOOLS:
      return {
        ...state,
        tools: [],
        toolsLoaded: false,
      };
    case UNDERWRITING_ACTION.FETCH_TOOLS_SUCCESS:
      return {
        ...state,
        tools: [...action.payload.tools],
        toolsLoaded: true,
      };
    case UNDERWRITING_ACTION.FETCH_TOOLS_FAILED:
      return {
        ...state,
        tools: [],
        toolsLoaded: true,
      };
    default:
      return state;
  }
}
