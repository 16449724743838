import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { SubscriberComponent } from '../../../../../../../../shared/component-subscriber/subscriber.component';
import { ErrorVisualizationService } from '../../../../../../../../shared/services/error-visualization/error-visualization.service';
import { AppState } from '../../../../../../../../store/app.reducers';
import { PendingOfferRiskRatingService } from '../../../service/pending-offer-risk-rating.service';
import { PeriodsCalculationsService } from '../../../service/periods-calculations.service';
import {
  CalculatorReset,
  SetApprovedAmount,
  SetNetDeliveryAmount,
  SetRequestedAmount,
  SetRequestNotes,
  SetReserveForRepayments,
  SetUnderwritingNotes,
  SetUnderwritingRiskRating,
} from '../../../store/calculator.actions';
import { CalculatorInputFields } from '../../../store/calculator.enums';
import { CalculatorState, IAdvanceOfferConnectedInputs } from '../../../store/calculator-interfaces';
import {
  SetExpectedDuration,
  SetExpectedMonthlyEarnings,
  SetForceFull,
  SetGracePeriodEndDate,
  SetNoRebate,
  SetReturnStrategy,
  SetUwStatus,
} from './../../../store/calculator.actions';

@Component({
  selector: 'app-manual-advance-offer-inputs',
  templateUrl: './manual-advance-offer-inputs.component.html',
  styleUrls: ['./manual-advance-offer-inputs.component.scss'],
})
export class ManualAdvanceOfferInputsComponent extends SubscriberComponent implements OnInit {
  @Input() supplierKey: string;
  @Input() showUwStatusSelector = false;

  public approvedAmount: number;
  public requestedAmount: number;
  public netDeliveryAmount: number;
  public reserveForRepayments: number;
  public underwritingRiskRating: number;
  public isMpReserveForPaymentEmpty: boolean;
  public totalFee: number;
  public isReadonly: boolean;
  public isNewOffer: boolean;
  public disableRequestedAmount: boolean;
  public disableReturnStrategy: boolean;
  public disableInputsWithoutRequestedAmount: boolean;
  public isSaveEnabled: boolean;
  public elementData: any[];
  public underwritingRiskRatingDescription: string;
  public recommendedAmount: number;
  public recommendedAmountDescription: string;
  public requested: IAdvanceOfferConnectedInputs;
  public recommended: IAdvanceOfferConnectedInputs;
  public recommendedNetAmountDescription: string;
  public requestNotes: string;
  public underwriterNotes: string;
  public underwritingStatus: string;
  public gracePeriodEndDate: number;
  public gracePeriodSavedEndDate: number;
  public noRebate: boolean;
  public noRebateSaved: boolean;
  public changeCount: number;
  public returnStrategy: string;
  public returnStrategySaved: string;
  public forceFull: boolean;
  public forceFullSaved: boolean;
  public oneAdvancePerLimit: boolean;
  public expectedMonthlyEarnings: number;
  public expectedDuration: number;
  public totalPeriodLength: number;
  public averageFee: number;

  public returnStrategyOptions = [
    {
      value: 'upon_closing',
      label: 'Upon Closing',
    },
    {
      value: 'no_return',
      label: 'No Return',
    },
  ];

  constructor(
    private store: Store<AppState>,
    private service: PendingOfferRiskRatingService,
    private currencyPipe: CurrencyPipe,
    private toastr: ToastrService,
    private errorVisualizationService: ErrorVisualizationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.changeCount = 0;
    this.subscriptions.push(
      this.store.select('calc').subscribe((calcValue) => {
        this.changeCount++;
        this.isNewOffer = calcValue.isNewOffer;
        this.approvedAmount = calcValue.approvedAmount;
        this.requestedAmount = calcValue.requested.givenAmount;
        this.netDeliveryAmount = calcValue.netDeliveryAmount;
        this.reserveForRepayments = calcValue.reserveForRepayments;
        this.underwritingStatus = calcValue.uwStatus;
        this.underwritingRiskRating = calcValue.underwritingRiskRating >= 0 ? calcValue.underwritingRiskRating : -1;
        this.underwritingRiskRatingDescription =
          this.underwritingRiskRating >= 0 ? `${this.underwritingRiskRating + 1}r` : 'Not Applicable';
        this.isMpReserveForPaymentEmpty = calcValue.isMpReserveForPaymentEmpty;
        this.totalFee = calcValue.totalFee;
        this.isReadonly = calcValue.isReadonly;
        if (this.isNewOffer) {
          this.initForNewOffer(calcValue);
        }
        this.disableRequestedAmount = calcValue.isReadonly || !calcValue.isNewOffer;
        this.disableInputsWithoutRequestedAmount = calcValue.isNewOffer || calcValue.isReadonly;
        this.isSaveEnabled = calcValue.requested.givenAmount > 0 && !calcValue.isReadonly;
        this.disableReturnStrategy = calcValue.isReadonly;
        this.requested = calcValue.requested;
        this.recommended = calcValue.recommended;
        this.underwriterNotes = calcValue.underwriterNotes;
        this.gracePeriodSavedEndDate = calcValue.gracePeriodSavedEndDate;
        this.gracePeriodEndDate = this.changeCount > 1 ? calcValue.gracePeriodEndDate : this.gracePeriodSavedEndDate;
        this.noRebateSaved = calcValue.noRebateSaved;
        this.noRebate = this.changeCount > 1 ? calcValue.noRebate : this.noRebateSaved;
        this.returnStrategy = this.changeCount > 1 ? calcValue.returnStrategy : this.returnStrategySaved;
        this.returnStrategySaved = calcValue.returnStrategySaved;
        this.forceFull = this.changeCount > 1 ? calcValue.forceFull : this.forceFullSaved;
        this.forceFullSaved = calcValue.forceFullSaved;
        this.totalPeriodLength = calcValue.totalPeriodLength;
        this.averageFee = calcValue.averageFee;
        this.expectedMonthlyEarnings = calcValue.expectedMonthlyEarnings;
        this.expectedDuration = calcValue.expectedDuration;
      }),
    );
  }

  changeAmount(e, fieldName) {
    const fieldMap = new Map();
    fieldMap.set(CalculatorInputFields.APPROVED, () => new SetApprovedAmount(e.target.value));
    fieldMap.set(CalculatorInputFields.REQUESTED, () => new SetRequestedAmount(e.target.value));
    fieldMap.set(CalculatorInputFields.NET_DELIVERY, () => new SetNetDeliveryAmount(e.target.value));
    fieldMap.set(CalculatorInputFields.RESERVE_FOR_REPAYMENTS, () => new SetReserveForRepayments(e.target.value));
    fieldMap.set(CalculatorInputFields.UNDER_WRITING_RISK_RATING, () => new SetUnderwritingRiskRating(e.value));
    fieldMap.set(CalculatorInputFields.REQUEST_NOTES, () => new SetRequestNotes(e.target.value));
    fieldMap.set(CalculatorInputFields.UNDERWRITING_NOTES, () => new SetUnderwritingNotes(e.target.value));
    fieldMap.set(
      CalculatorInputFields.GRACE_PERIOD_END_DATE,
      () => new SetGracePeriodEndDate((e.target.value = e.target.value ? +e.target.value : undefined)),
    );
    fieldMap.set(CalculatorInputFields.NO_REBATE, () => new SetNoRebate(e.checked));
    fieldMap.set(CalculatorInputFields.RETURN_STRATEGY, () => new SetReturnStrategy(e));

    fieldMap.set(CalculatorInputFields.FORCE_FULL, () => new SetForceFull(e));
    fieldMap.set(CalculatorInputFields.EXPEXTED_MONTHLY_EARINGS, () => new SetExpectedMonthlyEarnings(e.target.value));
    fieldMap.set(CalculatorInputFields.EXPECTED_DURATION, () => new SetExpectedDuration(e.target.value));
    fieldMap.set(CalculatorInputFields.UW_STATUS, () => new SetUwStatus(e.value));
    this.store.dispatch(fieldMap.get(fieldName)());
  }

  changeApprovedAmount(e) {
    this.changeAmount(e, CalculatorInputFields.APPROVED);
  }

  changeRequestedAmount(e) {
    this.changeAmount(e, CalculatorInputFields.REQUESTED);
  }

  changeNetDeliveryAmount(e) {
    this.changeAmount(e, CalculatorInputFields.NET_DELIVERY);
  }

  changeMpReserveForPayment(e) {
    this.changeAmount(e, CalculatorInputFields.RESERVE_FOR_REPAYMENTS);
  }

  changeUnderwritingRiskRating(e) {
    this.changeAmount(e, CalculatorInputFields.UNDER_WRITING_RISK_RATING);
  }

  changeGracePeriodEndDate(e) {
    this.changeAmount(e, CalculatorInputFields.GRACE_PERIOD_END_DATE);
  }

  changeNoRebate(e) {
    this.changeAmount(e, CalculatorInputFields.NO_REBATE);
  }

  changeReturnStrategy(e) {
    this.changeAmount(e, CalculatorInputFields.RETURN_STRATEGY);
  }

  changeForceFull(e) {
    this.changeAmount(e, CalculatorInputFields.FORCE_FULL);
  }

  resetOffer() {
    this.store.dispatch(new CalculatorReset());
  }

  changeRequestNotes(e) {
    this.changeAmount(e, CalculatorInputFields.REQUEST_NOTES);
  }

  changeUnderwritingNotes(e) {
    this.changeAmount(e, CalculatorInputFields.UNDERWRITING_NOTES);
  }

  onAdditionalFieldChange(data: { event: any; type: CalculatorInputFields }) {
    if (!data) {
      return;
    }
    this.changeAmount(data.event, data.type);
  }

  redirectToBase() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  initForNewOffer(calcValue: CalculatorState) {
    const periods = calcValue.periods;
    if (periods.length) {
      this.elementData = PeriodsCalculationsService.createPeriodSummary(periods);
    }
  }

  saveDataFromStore() {
    this.subscriptions.push(
      this.store.select('calc').subscribe((calcValues) => {
        this.subscriptions.push(
          this.service.saveProgressivePricingOffer(calcValues, this.supplierKey).subscribe(
            (data) => {
              this.toastr.success('Offer was requested successfully');
              this.redirectToBase();
              return data;
            },
            (err: any) => {
              this.errorVisualizationService.showError({
                name: 'Offer Error',
                errorObject: err,
              });
            },
          ),
        );
      }),
    );
  }

  onReturnStrategyChange() {
    this.changeReturnStrategy(this.returnStrategy);
    if (!this.returnStrategyOptions || !this.returnStrategyOptions[1]) {
      return;
    }
    this.forceFull = this.returnStrategy === this.returnStrategyOptions[1].value;
    this.changeForceFull(this.forceFull);
  }
}
