import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-supplier-sales-information',
  templateUrl: './supplier-sales-information.component.html',
  styleUrls: ['./supplier-sales-information.component.scss'],
})
export class SupplierSalesInformationComponent implements OnInit {
  @Input() marketplaceSaleInformation: MatTableDataSource<any>;

  marketplaceSaleInformationColumn: String[] = [
    'marketplace',
    'monthlyRevenue',
    'monthlyGrossRevenue',
    'lengthTimeSelling',
    'currentPayout',
    'netSales0to30',
    'netSales30to60',
    'netSales60to90',
    'grossSales0to30',
    'grossSales30to60',
    'grossSales60to90',
  ];
  constructor() {}

  ngOnInit() {}
}
