import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { MarketplaceModel } from '../../../marketplaces/model/marketplace-model';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { OnboardingMpModel } from '../../../shared/onboarding/onboarding.model';
import { OnboardingService } from '../../../shared/onboarding/service/onboarding.service';
import { UsersService } from '../../../shared/users/service/users.service';
import { AppState } from '../../../store/app.reducers';
import { DeleteOnboardingComponent } from '../delete-onboarding-setting/delete-onboarding.component';
import { OnboardingMpAddEditDialogComponent } from '../onboarding-mp-add-edit-dialog/onboarding-mp-add-edit-dialog.component';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';

class ConfigDataSource extends MatTableDataSource<OnboardingMpModel> {
  constructor() {
    super();
    const prev = this.sortingDataAccessor;
    this.sortingDataAccessor = function (data, sortHeaderId) {
      if (sortHeaderId === 'title') {
        return data.onboardingTemplateCode.toLowerCase();
      }
      return prev(data, sortHeaderId);
    };
  }
}

@Component({
  selector: 'app-onboarding-mp-list',
  templateUrl: './onboarding-mp-list.component.html',
  styleUrls: ['./onboarding-mp-list.component.scss'],
})
export class OnboardingMpListComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['marketplace', 'fundingPeriodGroupCode', 'feeSchedule', 'reservePercent', 'minReservePercent', 'action'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource = new ConfigDataSource();
  onboardingTemplateCode;
  canEdit: boolean;
  hasCRMPermission;

  public marketplace: MarketplaceModel;

  private user;
  private permission = USER_PERMISSION.INT_GLOBAL_SETTINGS;
  private templateKey: string;

  constructor(
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private onboardingService: OnboardingService,
    private usersService: UsersService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) {
    super();
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        this.user = user;
        this.subscriptions.push(
          this.usersService.checkPermission(USER_PERMISSION.CRM_ADMIN, this.user).subscribe((permitted) => {
            this.hasCRMPermission = permitted;
          }),
        );
      }),
    );

    this.dataSource = new ConfigDataSource();
  }

  ngOnInit() {
    this.canEdit = false;
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((loggedUser) => {
        this.getUserPermissions(loggedUser);
      }),
    );

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.templateKey = params['onboardingTemplateKey'];
        this.getData();
      }),
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  private getData() {
    this.subscriptions.push(
      this.onboardingService.getTemplate(this.templateKey).subscribe((result) => {
        this.onboardingTemplateCode = result.onboardingTemplateCode;
        this.dataSource.data = result.mpTemplates;
      }),
    );
  }

  openAddNewMpOnboarding() {
    const dialogRef = this.dialog.open(OnboardingMpAddEditDialogComponent, {
      ...DIALOG_SIZE.LARGE_AUTO_HEIGHT,
      data: {
        onboardingTemplateCode: this.onboardingTemplateCode,
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((data: OnboardingMpModel) => {
        if (data) {
          this.toastrService.success('Object has been successfully added');
        }
        this.getData();
      }),
    );
  }

  openEditMpOnboarding(configElement: OnboardingMpModel) {
    const dialogRef = this.dialog.open(OnboardingMpAddEditDialogComponent, {
      ...DIALOG_SIZE.LARGE_AUTO_HEIGHT,
      data: configElement,
      autoFocus: false,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((data: OnboardingMpModel) => {
        if (data) {
          this.toastrService.success('Object has been successfully updated');
        }
        this.getData();
      }),
    );
  }

  openDeleteMpOnboarding(configElement: OnboardingMpModel) {
    const dialogRef = this.dialog.open(DeleteOnboardingComponent, {
      ...DIALOG_SIZE.RWD_50_AUTO_HEIGHT,
      data: configElement,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        this.getData();
      }),
    );
  }

  addRow(addedItem: OnboardingMpModel) {
    this.dataSource.data.push(addedItem);
  }

  private getUserPermissions(loggedUser) {
    if (!loggedUser || !loggedUser.loginKey) {
      return;
    }

    this.subscriptions.push(
      this.usersService.getLoginPermissions(loggedUser.loginKey).subscribe((perms) => {
        perms.forEach((perm) => {
          if (!perm || !perm.permCode) {
            return;
          }

          this.subscriptions.push(
            this.usersService.getPermissionSubPerms(perm.permCode).subscribe((response) => {
              this.checkPermission(response);
            }),
          );
        });
      }),
    );
  }

  private checkPermission(permissions) {
    permissions.forEach((elem) => {
      if (elem.permCode.toString() === this.permission) {
        this.canEdit = true;
      }
    });
  }
}
