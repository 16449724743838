<section class="app-footer">
  <div class="container-fluid">
    <span class="float-left">
      <span
        >Copyright © <a class="brand" target="_blank" [href]="ThemeConfig.productLink">{{ ThemeConfig.brand }}</a>
        {{ ThemeConfig.year }}</span
      >
    </span>
    <span class="float-right">
      <span>Built with Love <i class="material-icons">favorite_border</i></span>
    </span>
  </div>
</section>
