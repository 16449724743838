import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-auth-invite-resent',
  templateUrl: './marketplace-auth-invite-resent.component.html',
  styleUrls: ['./marketplace-auth-invite-resent.component.less'],
})
export class MarketplaceAuthInviteResentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
