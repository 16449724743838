import * as backgroundReportsActions from './background-reports.actions';
import { SET_SUPPLIER_BACKGROUND_REPORT } from './background-reports.actions';

export interface BackgroundReportsState {
  reports: { [supplierKey: string]: any };
}

const initialState: BackgroundReportsState = {
  reports: {},
};

export function backgroundReportsReducer(
  state: BackgroundReportsState = initialState,
  action: backgroundReportsActions.Actions,
): BackgroundReportsState {
  switch (action.type) {
    case SET_SUPPLIER_BACKGROUND_REPORT: {
      const reports = state.reports;
      reports[action.payload.supplierId] = action.payload.report;
      return {
        ...state,
        reports: { ...reports },
      };
    }
    default:
      return state;
  }
}
