import { Action } from '@ngrx/store';

export const SET_SUPPLIER_BACKGROUND_REPORT = 'SET_SUPPLIER_BACKGROUND_REPORT';

export class SetSupplierBackgroundReport implements Action {
  readonly type = SET_SUPPLIER_BACKGROUND_REPORT;
  constructor(public payload: { supplierId: string; report: any }) {}
}

export type Actions = SetSupplierBackgroundReport;
