<div>
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let tabLink of navLinks"
      [routerLink]="tabLink.route"
      [routerLinkActive]
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ tabLink.label }}
    </a>
  </nav>

  <router-outlet></router-outlet>
</div>
