import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../../config';

export interface CliActionInterface {
  command: string;
  implicit_params: any;
  b2bdocument_params: any;
}

@Injectable()
export class CliActionService {
  cliApiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.cliApiUrl = appConfig.env.cliApiUrl;
  }

  public getActionsList(): Observable<any> {
    return this.httpClient.get(`${this.cliApiUrl}internal_b2bdocument_clis`);
  }

  public runAction(data: CliActionInterface): Observable<any> {
    return this.httpClient.post(`${this.cliApiUrl}execute_internal_b2bdocument_command_workflow`, data);
  }
}
