<ng-container *ngIf="data && data.columns && data.columns.length">
  <table class="table table-condensed table-hover">
    <thead>
      <tr>
        <th *ngFor="let column of data.columns">
          {{ column }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data.rows">
        <td *ngFor="let value of row">
          {{ value }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
