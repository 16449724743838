export class PaymentFeeModel {
  feeScheduleKey: string;
  feeScheduleType: string;
  feeScheduleName: string;
  feeScheduleNames: string[];
  feeScheduleDelivery: string;
  feeScheduleDelivieries: string[];
  feePercentage: string;
  minPmtFee: string;
  fixedFee: string;
  isEditable = false;
  isHidden = true;
  isTier = false;
  paymentTypeCode: string;
  tierStart: string;
  tierEnd: string;
}
