import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { GoogleApiConfig, NgGapiClientConfig } from './config/GoogleApiConfig';

export const NG_GAPI_CONFIG: InjectionToken<NgGapiClientConfig> = new InjectionToken<NgGapiClientConfig>('ng-gapi.config');

@Injectable()
export class GoogleApiService {
  private readonly gapiUrl: string = 'https://accounts.google.com/gsi/client';
  private config: GoogleApiConfig;

  constructor(@Inject(NG_GAPI_CONFIG) config: NgGapiClientConfig) {
    this.config = new GoogleApiConfig(config);
    this.loadGapi().subscribe();
  }

  public onLoad(): Observable<void> {
    return this.loadGapi();
  }

  public getConfig(): GoogleApiConfig {
    return this.config;
  }

  private loadGapi(): Observable<void> {
    return Observable.create((observer: Observer<boolean>) => {
      const node = document.createElement('script');
      node.src = this.gapiUrl;
      node.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(node);
      node.onload = (): void => {
        observer.next(true);
        observer.complete();
      };
    });
  }
}
