export enum COOKIE_TYPES {
  SELECTED_REPORT_OPTIONS = 'selected_report_options',
  SELECTED_DOCUMENTATION_OPTIONS = 'selected_documentation_options',
}

export interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'Lax' | 'None' | 'Strict';
}
