import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubscriberComponent } from '../../../../../../../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../../../../../../../store/app.reducers';
import { RemovePeriod, SetFee, SetPeriodLength, UpdatePeriodRowValues } from '../../../../store/calculator.actions';

@Component({
  selector: 'app-fee-calculator-row',
  templateUrl: './fee-calculator-row.component.html',
  styleUrls: ['./fee-calculator-row.component.scss'],
})
export class FeeCalculatorRowComponent extends SubscriberComponent implements OnInit {
  @Input() periodNumber: number;

  public periodLength = 0;
  public fee = 0;
  public isRemoveDisabled: boolean;
  public isReadonly: boolean;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select('calc').subscribe((calcValue) => {
        if (calcValue.periods[this.periodNumber]) {
          this.periodLength = calcValue.periods[this.periodNumber].periodLength;
          this.fee = calcValue.periods[this.periodNumber].fee;
        }
        this.isRemoveDisabled = calcValue.isRemoveDisabled;
        this.isReadonly = calcValue.isReadonly;
      }),
    );
  }

  removeMe() {
    this.store.dispatch(new RemovePeriod(this.periodNumber));
  }

  changePeriodLength($event) {
    const updatePeriodRowValues = new UpdatePeriodRowValues(this.periodNumber, $event.target.value);
    this.store.dispatch(new SetPeriodLength(updatePeriodRowValues));
  }

  changeFee($event) {
    const updatePeriodRowValues = new UpdatePeriodRowValues(this.periodNumber, $event.target.value);
    this.store.dispatch(new SetFee(updatePeriodRowValues));
  }
}
