<div class="comment-item">
  <div class="comment-content">
    <ul class="comment-data-summary">
      <li class="pill"><i class="material-icons">person</i>Author: {{ getName(item.ownerUuid) }}</li>
      <li>
        <i class="material-icons">alarm</i>Created At: {{ item.createdAt | amAdd: correctTime(item.createdAt):'minutes' | date: 'short' }}
        <i class="material-icons">event_note</i>Last Edited:
        {{ item.updatedAt | amAdd: correctTime(item.updatedAt):'minutes' | date: 'short' }}
      </li>
    </ul>
    <ul class="comment-action-buttons pull-right">
      <li *ngIf="hasAccessTo"><a app-confirm-delete (onConfirm)="deleteComment()"></a></li>
      <li *ngIf="hasAccessTo">
        <a><i class="material-icons" (click)="onEdit()">edit</i></a>
      </li>
    </ul>

    <div><span>The comment is:</span></div>
    <div *ngIf="!editMode; else editPane" class="comment-body" [innerHtml]="item.body | safe" #commentBody></div>
    <ng-template #editPane>
      <div class="edit-comment-form">
        <app-editor [(ngModel)]="body"></app-editor>
        <button class="cancel-btn" mat-mini-fab (click)="editMode = false" color="warn">
          <i class="material-icons">close</i>
        </button>
        <button class="save-btn" mat-mini-fab (click)="editComment()" color="accent">
          <i class="material-icons">send</i>
        </button>
      </div>
    </ng-template>

    <div class="comment-replies" *ngIf="item.comments && item.comments.length > 0 && isRepliesShown">
      <app-comment-item *ngFor="let childItem of item.comments" [item]="childItem"></app-comment-item>
    </div>
  </div>
</div>
