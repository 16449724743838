<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="card-title">Bulk Actions</div>
      </div>

      <div class="box">
        <table class="mdl-data-table">
          <thead>
            <tr>
              <th class="mdl-data-table__cell--non-numeric">Action Name</th>
              <th class="mdl-data-table__cell--non-numeric"></th>
              <th class="mdl-data-table__cell--non-numeric">Action Status</th>
              <th class="mdl-data-table__cell--non-numeric">Last Run [user/time]</th>
              <th class="mdl-data-table__cell--non-numeric">Template</th>
              <th class="mdl-data-table__cell--non-numeric">Results</th>
            </tr>
          </thead>
          <tbody *ngIf="actions">
            <tr>
              <td class="mdl-data-table__cell--non-numeric">Add new MB accounts</td>
              <td class="mdl-data-table__cell--non-numeric">
                <button class="btn-w-md mat-raised-button mat-primary" (click)="openDepositAccountsDialog()">Upload</button>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="mat-button" (click)="downloadExample('deposit_account_upload_template.csv')">
                  <i class="nav-icon material-icons">get_app</i>
                </a>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
            </tr>
            <tr>
              <td class="mdl-data-table__cell--non-numeric">Add new pre-funded cards</td>
              <td class="mdl-data-table__cell--non-numeric">
                <button class="btn-w-md mat-raised-button mat-primary" (click)="openPrefundedCardsDialog()">Upload</button>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="mat-button" (click)="downloadExample('example_pre-funded_card_bulk_upload.csv')">
                  <i class="nav-icon material-icons">get_app</i>
                </a>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
            </tr>
            <tr>
              <td class="mdl-data-table__cell--non-numeric">Add bulk notes</td>
              <td class="mdl-data-table__cell--non-numeric">
                <button class="btn-w-md mat-raised-button mat-primary" (click)="openBulkNotesDialog()">Upload</button>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="mat-button" (click)="downloadExample('bulk_notes_template.csv')">
                  <i class="nav-icon material-icons">get_app</i>
                </a>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
            </tr>

            <tr>
              <td class="mdl-data-table__cell--non-numeric">Add supplier promos</td>
              <td class="mdl-data-table__cell--non-numeric">
                <button class="btn-w-md mat-raised-button mat-primary" (click)="openSupplierPromosDialog()">Start</button>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="mat-button" (click)="downloadExample('supplier_promos_template.xlsx')">
                  <i class="nav-icon material-icons">get_app</i>
                </a>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
            </tr>

            <tr>
              <td class="mdl-data-table__cell--non-numeric">Add EFS rebates</td>
              <td class="mdl-data-table__cell--non-numeric">
                <button class="btn-w-md mat-raised-button mat-primary" [disabled]="efsRebatesStatus == 2" (click)="openEfsRebatesDialog()">
                  Start
                </button>
              </td>
              <td class="mdl-data-table__cell--non-numeric">
                <div class="progress-spinner">
                  <mat-progress-spinner mode="determinate" [value]="efsRebatesProgress"></mat-progress-spinner>
                  <div class="inside-text">{{ efsRebatesProgress }}%</div>
                </div>
              </td>
              <td class="mdl-data-table__cell--non-numeric">
                <div class="info">
                  <div>
                    <i class="material-icons"> person </i>
                  </div>
                  <div>
                    <p>{{ efsRebatesUser }}</p>
                    <p>{{ efsRebatesTime }}</p>
                  </div>
                </div>
              </td>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="mat-button" (click)="downloadExample('supplier_rebates_credits_template.csv')">
                  <i class="nav-icon material-icons">get_app</i>
                </a>
                <a class="mat-button" (click)="downloadExample('supplier_rebates_due_template.csv')">
                  <i class="nav-icon material-icons">get_app</i>
                </a>
              </td>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="mat-button" (click)="downloadLogFile('upload-efs-rebates')">
                  <i class="nav-icon material-icons">assignment</i>
                </a>
              </td>
            </tr>

            <tr>
              <td class="mdl-data-table__cell--non-numeric">Add weekly purchases</td>
              <td class="mdl-data-table__cell--non-numeric">
                <button
                  class="btn-w-md mat-raised-button mat-primary"
                  [disabled]="weeklyPurchasesStatus == 2"
                  (click)="openWeeklyPurchasesDialog()"
                >
                  Start
                </button>
              </td>
              <td class="mdl-data-table__cell--non-numeric">
                <div class="progress-spinner">
                  <mat-progress-spinner mode="determinate" [value]="weeklyPurchasesProgress"></mat-progress-spinner>
                  <div class="inside-text">{{ weeklyPurchasesProgress }}%</div>
                </div>
              </td>
              <td class="mdl-data-table__cell--non-numeric">
                <div class="info">
                  <div>
                    <i class="material-icons"> person </i>
                  </div>
                  <div>
                    <p>{{ weeklyPurchasesUser }}</p>
                    <p>{{ weeklyPurchasesTime }}</p>
                  </div>
                </div>
              </td>
              <td class="mdl-data-table__cell--non-numeric"></td>
              <td class="mdl-data-table__cell--non-numeric">
                <a class="mat-button" (click)="downloadLogFile('weekly-receivable-purchase')">
                  <i class="nav-icon material-icons">assignment</i>
                </a>
              </td>
            </tr>
            <tr app-cli-action *ngFor="let action of actions" [actionData]="action"></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
