import { autoserialize, autoserializeAs, autoserializeIndexable } from 'cerialize';

import { TARGET_MARKETPLACE_KEY } from '../supplier/supplier-onboarding/supplier-onboarding.service';
import { FundingParticularsModel } from './funding-particulars.model';
import { PaymentConfigModel } from './payment-config.model';
import { SupplierBalanceModel } from './supplier-balance.model';
import { SupplierMarketplaceDetailsModel, SupplierMarketplaceListModel } from './supplier-marketplace.model';
import { SupplierMarketplaceFundingModel } from './supplier-marketplace-funding.model';
import { SupplierMarketplaceMinFeeOverridesModel } from './supplier-marketplace-min-fee-overrides.model';
import { SupplierMarketplaceOptInPeriodModel } from './supplier-marketplace-optin-period.model';
import { SupplierOwnerModel } from './supplier-owner.model';
import { SupplierStatusModel } from './supplier-status.model';

export class SupplierModel {
  public supplierKey;

  public supplierDetailsModel?: SupplierDetailsModel;
  public currentFundingParticularsModel?: FundingParticularsModel;

  public marketplaces?: SupplierMarketplaceDetailsModel[];

  public marketplaceFundingModels?: { [mpSupKey: string]: SupplierMarketplaceFundingModel };
  public marketplaceOptInPeriodModels?: { [mpSupKey: string]: SupplierMarketplaceOptInPeriodModel[] };
  public marketplaceMinFeeOverridesModels?: { [mpSupKey: string]: SupplierMarketplaceMinFeeOverridesModel[] };

  public paymentConfigsModels?: { [paymentConfigKey: string]: PaymentConfigModel };
  public balance?: SupplierBalanceModel;

  public efsCardRequested?: boolean;

  public kycDocumentsStatuses?: any[]; // TODO nkler: add type!!!
  public complianceAmazonAccessStatus?;
  public complianceAmazonPermissions?;

  public companyDBA: string;
}

export class SupplierAddressModel {
  @autoserialize
  public addr1: string;

  @autoserialize
  public country: string;

  @autoserialize
  public state: string;

  @autoserialize
  public city: string;

  @autoserialize
  public postalCode: string;

  @autoserialize
  public addr2: string;
}

class InstantMessengerModel {
  @autoserialize
  slug: string;

  @autoserialize
  type = 'Skype';
}

export interface ISupplierDetailsDTO {
  companyDBA?: string;
  supplierKey?: string;
  contactEmail?: string;
  depositAccountKey?: string;
  contactLastName?: string;
  instantAccess?: boolean;
  hide?: boolean;
  phoneNumber?: string;
  stateOfIncorporation?: string;
  efsRebatePercent?: number;
  marketplaces?: SupplierMarketplaceListModel[];
  extStageName?: string;
  wirAvailable?: string;
  instantMessenger?: InstantMessengerModel;
  created?: string;
  extStatus?: string;
  achAvailable?: boolean;
  extOwnerName?: string;
  taxId?: string;
  timezone?: string;
  businessTypeCode?: string;
  ppmAvailable?: boolean;
  owners?: SupplierOwnerModel[];
  status?: SupplierStatusModel;
  legalName?: string;
  address?: SupplierAddressModel;
  homeAddress?: SupplierAddressModel;
  onboardingStatus?: boolean;
  onboardingTemplateCode?: string;
  contactFirstName?: string;
  cancelationStatus?: string;
  incorporatedInTheUS?: boolean;
  extDealId?: number;
  oldestOptIn?: string;
  fundFacilityLimit?: string;
  efsAvailable?: boolean;
  modified?: boolean;
  efsLastCardReqTs?: string;
  loadNetEarnings?: boolean;
  supplierSite?: string;
  pendApprovNeeded?: boolean;
  pcardAvailable?: boolean;
  dateOfBirth?: any;
  ownerSSN?: string;
  dateEstablished?: any;
  writeoff?: boolean;
  schema?: string;
  supplierLocked?: boolean;
}

export class SupplierDetailsModel {
  @autoserialize
  public companyDBA: string;

  @autoserialize
  public supplierKey: string;

  @autoserialize
  public contactEmail: string;

  @autoserialize
  public depositAccountKey?: string;

  @autoserialize
  public contactLastName?: string;

  @autoserialize
  public instantAccess?: boolean;

  @autoserialize
  public hide?: boolean;

  @autoserialize
  public phoneNumber?: string;

  @autoserialize
  public stateOfIncorporation?: string;

  @autoserialize
  public efsRebatePercent?: number;

  @autoserializeIndexable(SupplierMarketplaceListModel)
  public marketplaces?: SupplierMarketplaceListModel[];

  @autoserialize
  public extStageName?: string;

  @autoserialize
  public wirAvailable?: string;

  @autoserializeAs(InstantMessengerModel)
  public instantMessenger?: InstantMessengerModel;

  @autoserialize
  public created?: string;

  @autoserialize
  public extStatus?: string;

  @autoserialize
  public achAvailable?: boolean;

  @autoserialize
  public extOwnerName?: string;

  @autoserialize
  public taxId?: string;

  @autoserialize
  public timezone?: string;

  @autoserialize
  public businessTypeCode?: string;

  @autoserialize
  public ppmAvailable = false;

  @autoserialize
  public owners?: SupplierOwnerModel[];

  @autoserializeAs(SupplierStatusModel)
  public status?: SupplierStatusModel;

  @autoserialize
  public legalName?: string;

  @autoserializeAs(SupplierAddressModel)
  public address?: SupplierAddressModel;

  @autoserializeAs(SupplierAddressModel)
  public homeAddress?: SupplierAddressModel;

  @autoserialize
  public onboardingStatus?: boolean;

  @autoserialize
  public onboardingTemplateCode?: string;

  @autoserialize
  public contactFirstName?: string;

  @autoserialize
  public cancelationStatus?: string;

  @autoserialize
  public incorporatedInTheUS?: boolean;

  @autoserialize
  public extDealId?: number;

  @autoserialize
  public oldestOptIn?: string;

  @autoserialize
  public fundFacilityLimit?: string;

  @autoserialize
  public efsAvailable?: boolean;

  @autoserialize
  public modified?: boolean;

  @autoserialize
  public efsLastCardReqTs?: string;

  @autoserialize
  public loadNetEarnings?: boolean;

  @autoserialize
  public supplierSite?: string;

  @autoserialize
  public pendApprovNeeded?: boolean;

  @autoserialize
  public pcardAvailable?: boolean;

  @autoserialize
  public dateOfBirth?: any;

  @autoserialize
  public ownerSSN?: string;

  @autoserialize
  public dateEstablished: any;

  @autoserialize
  public writeoff: boolean;

  @autoserialize
  public sendAutoEmail?: boolean;

  @autoserialize
  public businessWebsite?: string | null;

  @autoserialize
  public schema?: string;

  @autoserialize
  public supplierLocked?: boolean;

  constructor(supplierDetailsDTO?: ISupplierDetailsDTO) {
    Object.assign(this, supplierDetailsDTO);
  }

  get isTarget(): boolean {
    if (!(this.marketplaces === undefined) && this.marketplaces.length > 0) {
      return (
        this.marketplaces.filter((marketplace: SupplierMarketplaceListModel) => {
          return marketplace.marketplaceKey === TARGET_MARKETPLACE_KEY;
        }).length > 0
      );
    }
    return false;
  }

  get isAmazonVendor(): boolean {
    if (!(this.marketplaces === undefined) && this.marketplaces.length > 0) {
      return (
        this.marketplaces.filter((marketplace) => {
          return marketplace.marketplaceName === 'Amazon Vendor Central' || marketplace.marketplaceName === 'Amazon Vendor Express';
        }).length > 0
      );
    }
    return false;
  }

  get isAmazonSeller() {
    if (!(this.marketplaces === undefined) && this.marketplaces.length > 0) {
      return this.marketplaces.filter((marketplace) => {
        return marketplace.marketplaceName === 'Amazon Seller Central';
      }).length;
    }
    return false;
  }

  get isEFS() {
    return true;
  }
}
