import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cli-action-name',
  templateUrl: './cli-action-name.component.html',
  styleUrls: ['./cli-action-name.component.scss'],
})
export class CliActionNameComponent implements OnInit {
  @Input() actionData;

  constructor() {}

  ngOnInit() {}
}
