import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-value',
  templateUrl: './text-value.component.html',
  styleUrls: ['./text-value.component.scss'],
})
export class TextValueComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() showValue = true;
  @Input() emptyValue = null;
}
