import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-instant-access',
  templateUrl: './instant-access.component.html',
  styleUrls: ['./instant-access.component.scss'],
})
export class InstantAccessComponent implements OnInit {
  @Input() dataProducts: any;

  public displayedInstantAccessColumns = ['marketplace', 'reserveRate', 'fee', 'uwStatus'];
  public dataSourceIAccess: MatTableDataSource<any>;

  constructor() {}

  ngOnInit() {
    const source = [];
    for (const key in this.dataProducts.instant_access) {
      if (this.dataProducts.instant_access.hasOwnProperty(key)) {
        const mp = this.dataProducts.instant_access[key];
        source.push({
          marketplace: key,
          reserveRate: `${mp.reserve_percent} %`,
          fee: `${mp.fee} %`,
          uwStatus: mp.uw_status,
        });
      }
    }
    this.dataSourceIAccess = new MatTableDataSource(source);
  }
}
