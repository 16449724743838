import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

import { HttpCacheAbstract } from './http-cache-abstract';

interface CachedValue {
  data: any;
  timestamp: number;
}

@Injectable()
export class HttpCacheLocalStorage extends HttpCacheAbstract {
  private readonly localStorageCacheKeyPrefix = 'ng-cache-';
  private readonly validCacheTimespan = 5 * 60 * 1000;

  constructor() {
    super();
  }

  /**
   * Returns a cached response, if any, or null if not present.
   */
  get(req: HttpRequest<any>): HttpResponse<any> | null {
    try {
      const key = this.buildLocalStorageCacheKey(req);
      const valueFromCache = this.getValue(key);
      return valueFromCache ? new HttpResponse<any>(valueFromCache) : null;
    } catch (err) {
      return null;
    }
  }

  private getValue(key): any | null {
    try {
      const valueFromStorage: CachedValue = JSON.parse(localStorage.getItem(key));
      const currentTimestamp = new Date().getTime();

      if (currentTimestamp - valueFromStorage.timestamp < this.validCacheTimespan) {
        return valueFromStorage.data;
      } else {
        localStorage.removeItem(key);
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  /**
   * Adds or updates the response in the cache.
   */
  put(req: HttpRequest<any>, resp: HttpResponse<any>): void {
    try {
      this.putValue(this.buildLocalStorageCacheKey(req), resp);
    } catch (err) {
      // do nothing
    }
  }

  private putValue(key: string, data: any): void {
    const timestamp = new Date().getTime();
    const valueToBeCached: CachedValue = { data, timestamp };
    localStorage.setItem(key, JSON.stringify(valueToBeCached));
  }

  private buildLocalStorageCacheKey(req: HttpRequest<any>): string {
    return this.localStorageCacheKeyPrefix + <string>Md5.hashAsciiStr(JSON.stringify(req));
  }
}
