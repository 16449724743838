import { ActionReducerMap } from '@ngrx/store';

import * as fromAuth from '../auth/store/auth.reducers';
import * as fromCrm from '../crm-tools/store/crm.reducers';
import * as fromGlobalSettings from '../manage/global-settings/store/global-settings.reducers';
import * as fromOnboarding from '../manage/store/onboarding.reducer';
import * as fromMarketplace from '../marketplaces/store/marketplace.reducer';
import * as fromFetchedRequests from '../shared/interceptors/fetched-requests.reducers';
import * as fromLayout from '../shared/layout/store/layout.reducers';
import * as fromUtils from '../shared/utils/store/utils.reducers';
import * as fromBackgroundReports from '../suppliers/store/background-reports/background-reports.reducers';
import * as fromSupplier from '../suppliers/store/supplier/supplier.reducers';
/* eslint-disable */
import * as fromCalc from '../suppliers/supplier/supplier-advances/pending-offer/pending-offer-calculator/store/calculator.reducers';
import { CalculatorState } from '../suppliers/supplier/supplier-advances/pending-offer/pending-offer-calculator/store/calculator-interfaces';
import * as fromUnderwriting from '../suppliers/supplier/supplier-underwriting/store/underwriting.reducers';
import { GlobalSettingsState } from './../manage/global-settings/store/global-settings.state';
import { UnderwritingState } from './../suppliers/supplier/supplier-underwriting/store/underwriting.state';
/* eslint-enable */

export interface AppState {
  auth: fromAuth.AuthState;
  globalSettings: GlobalSettingsState;
  layout: fromLayout.LayoutState;
  supplier: fromSupplier.SupplierState;
  crm: fromCrm.CrmState;
  utils: fromUtils.UtilsState;
  calc: CalculatorState;
  marketplace: fromMarketplace.IMarketplacesState;
  onboarding: fromOnboarding.IOnboardingState;
  errors: fromFetchedRequests.IFetchedRequestsState;
  backgroundReports: fromBackgroundReports.BackgroundReportsState;
  underwriting: UnderwritingState;
}

export const APP_REDUCERS: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducers,
  globalSettings: fromGlobalSettings.globalSettingsReducers,
  layout: fromLayout.layoutReducer,
  supplier: fromSupplier.supplierReducers,
  crm: fromCrm.crmReducers,
  utils: fromUtils.utilsReducers,
  calc: fromCalc.calculatorReducers,
  marketplace: fromMarketplace.marketplacesReducer,
  onboarding: fromOnboarding.onboardingReducer,
  errors: fromFetchedRequests.errorsReducer,
  backgroundReports: fromBackgroundReports.backgroundReportsReducer,
  underwriting: fromUnderwriting.underwritingReducers,
};
