<div class="card-header">
  <div class="navigation">
    <a [routerLink]="['/manage/onboarding/list']" title="Go to Onboarding list" class="close-button">
      <i class="material-icons">arrow_back</i>
    </a>
  </div>
  <div class="title">Go back to Onboarding list</div>
</div>

<div class="col-xl-8 col-lg-8">
  <h4>{{ prefixTitle }} Onboarding Template</h4>

  <app-onboarding-general-information
    (formReady)="formInitialized('generalInformation', $event)"
    [templateData]="templateData"
  ></app-onboarding-general-information>

  <h5>Marketplaces</h5>

  <app-onboarding-marketplaces
    (formReady)="formInitialized('marketplaces', $event)"
    [templateData]="templateData"
  ></app-onboarding-marketplaces>

  <h5>Products</h5>

  <app-onboarding-products (formReady)="formInitialized('products', $event)" [templateData]="templateData"></app-onboarding-products>

  <h5>Transfer options</h5>

  <app-onboarding-payment-options
    (formReady)="formInitialized('paymentOptions', $event)"
    [templateData]="templateData"
  ></app-onboarding-payment-options>

  <div class="onboardingAdd__div__buttons">
    <button mat-raised-button color="warn" (click)="resetForm()">
      <i class="material-icons">format_clear</i>
      Clear
    </button>

    <button mat-raised-button color="primary" (click)="sendForm()" [disabled]="!consolidateForm.valid">
      <i class="material-icons">save</i>
      Save Onboarding Template
    </button>
  </div>
</div>
