import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../store/app.reducers';
import { CrmItem } from '../../../models/crm-item.type';
import { listAnimation } from '../../../shared/cms.animations';
import { FetchCrmNotes, selectCrmNotes } from '../../../store/crm.actions';
import { ActionTabComponent } from '../action-tab-base.component';

@Component({
  selector: 'app-action-tab-notes',
  templateUrl: './action-tab-notes.component.html',
  animations: [trigger('listAnimation', [transition('* => *', [useAnimation(listAnimation)])])],
})
export class ActionTabNotesComponent extends ActionTabComponent {
  @Input() crmItems: { [uuid: string]: CrmItem };
  selector = selectCrmNotes;
  fetchAction = FetchCrmNotes;

  constructor(store: Store<AppState>) {
    super(store);
  }
}
