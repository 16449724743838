import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isUndefined } from 'util';

import {
  FetchSupplierMarketplaceDetailsPart,
  FetchSupplierMarketplaces,
  selectSupplierDetails,
  selectSupplierMarketplaces,
  selectSupplierMarketplacesFundings,
  selectSupplierMarketplacesMinFeeOverrides,
} from '../../../../store/supplier/supplier.actions';
import { CardFormgroupMessageService } from './../../../../../shared/card-formgroup/card-formgroup-message.service';
import { CrmStates } from './../../../../../shared/layout/layout.enums';
import { LayoutState } from './../../../../../shared/layout/store/layout.reducers';
import { LookupAbstractService } from './../../../../../shared/lookup-service/lookup-abstract.service';
import { ErrorLogger } from './../../../../../shared/services/error-logger/error-logger.service';
import { AppState } from './../../../../../store/app.reducers';
import { SupplierDetailsModel } from './../../../../model/supplier.model';
import { SupplierMarketplaceDetailsModel } from './../../../../model/supplier-marketplace.model';
import { SupplierMarketplaceFundingModel } from './../../../../model/supplier-marketplace-funding.model';
import { SupplierMarketplaceMinFeeOverridesModel } from './../../../../model/supplier-marketplace-min-fee-overrides.model';
import { RxEditBaseComponent } from './../../../../rx-edit-base.component';
import { SupplierMarketplaceDict } from './../../../supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-dict';
import { SupplierMarketplaceForm } from './../../../supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-form';
import { SupplierMarketplaceFormTypes } from './../../../supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-form-types';

@Component({
  selector: 'app-edit-marketplace',
  templateUrl: './edit-marketplace.component.html',
  styleUrls: ['./edit-marketplace.component.scss'],
})
export class EditMarketplaceComponent extends RxEditBaseComponent implements OnInit {
  routeMpSupKey: string;

  readonly SUPPLIER_MARKETPLACE_SERVICE_CLASS_NAME = 'SupplierMarketplacesService';
  readonly SUPPLIER_MARKETPLACE_FUNDING_SERVICE_CLASS_NAME = 'SupplierMarketplaceFundingService';
  readonly SUPPLIER_MARKETPLACE_OPT_IN_PERIODS_SERVICE_CLASS_NAME = 'SupplierMarketplaceOptInPeriodsService';
  readonly SUPPLIER_MARKETPLACE_MIN_FEE_OVERRIDES_SERVICE_CLASS_NAME = 'SupplierMarketplaceMinFeeOverridesService';

  supplierDetailsModel: SupplierDetailsModel;
  marketplaceDetailsModel: SupplierMarketplaceDetailsModel;

  sellingTimeRanges$: Observable<any>;
  feeSchedules$: Observable<any>;

  readonly apiStatuses = SupplierMarketplaceDict.apiStatuses;
  readonly fundStatusList = SupplierMarketplaceDict.fundStatusList;
  readonly freeTrialFeeScheduleKeys = SupplierMarketplaceDict.freeTrialFeeScheduleKeys;

  readonly FUNDING_FORM = SupplierMarketplaceFormTypes.FUNDING_FORM;
  readonly PAYMENT_FORM = SupplierMarketplaceFormTypes.PAYMENT_FORM;
  readonly REVENUE_FORM = SupplierMarketplaceFormTypes.REVENUE_FORM;

  readonly FEE_SETTINGS_FORM = SupplierMarketplaceFormTypes.FEE_SETTINGS_FORM;
  readonly FEE_DATES_FORM = SupplierMarketplaceFormTypes.FEE_DATES_FORM;
  readonly API_STATUS_FORM = SupplierMarketplaceFormTypes.API_STATUS_FORM;

  fundingModel: SupplierMarketplaceFundingModel;
  minFeeOverridesModels: SupplierMarketplaceMinFeeOverridesModel[];
  layoutState$: Store<LayoutState>;
  crmPanelStates = CrmStates;
  marketplaceIconSanitized: any;

  constructor(
    store: Store<AppState>,
    messageService: CardFormgroupMessageService,
    errorLogger: ErrorLogger,
    private route: ActivatedRoute,
    private lookupRepo: LookupAbstractService,
    private marketplaceFormBuilder: SupplierMarketplaceForm,
    private sanitization: DomSanitizer,
  ) {
    super(store, messageService, errorLogger);
  }

  ngOnInit() {
    this.layoutState$ = this.store.select('layout');

    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetailsModel: SupplierDetailsModel) => {
        this.supplierDetailsModel = supplierDetailsModel;

        if (!isUndefined(supplierDetailsModel)) {
          this.store.dispatch(new FetchSupplierMarketplaces({ supplierDetailsModel, refresh: true }));
        }
      }),
    );

    this.sellingTimeRanges$ = this.lookupRepo.getSellingTimeRanges();
    this.feeSchedules$ = this.lookupRepo.getFeeSchedules();

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.routeMpSupKey = params['marketplaceKey'];
        this.store.select(selectSupplierMarketplaces).subscribe((marketPlacesList: SupplierMarketplaceDetailsModel[]) => {
          if (!isUndefined(marketPlacesList)) {
            this.marketplaceDetailsModel = this.getMarketplace(marketPlacesList, this.routeMpSupKey);

            if (this.marketplaceDetailsModel.icon) {
              this.marketplaceIconSanitized = this.sanitization.bypassSecurityTrustResourceUrl(
                this.sanitization.sanitize(SecurityContext.HTML, this.marketplaceDetailsModel.icon),
              );
            }

            // // TODO nkler: map -> loop
            this.store.dispatch(
              new FetchSupplierMarketplaceDetailsPart({
                marketplaceModel: this.marketplaceDetailsModel,
                supplierDetailsModel: this.supplierDetailsModel,
                serviceClassName: this.SUPPLIER_MARKETPLACE_FUNDING_SERVICE_CLASS_NAME,
                storeKey: 'marketplaceFundingModels',
                refresh: true,
              }),
            );

            this.initGeneralForms();
          }
        });
      }),
    );

    // TODO nkler: map -> loop
    this.subscriptions.push(
      this.store
        .select(selectSupplierMarketplacesFundings)
        .subscribe((fundingModels: { [mpSupKey: string]: SupplierMarketplaceFundingModel }) => {
          if (fundingModels && this.marketplaceDetailsModel) {
            const neededModel = !isUndefined(fundingModels[this.marketplaceDetailsModel.mpSupKey])
              ? fundingModels[this.marketplaceDetailsModel.mpSupKey]
              : undefined;

            if (!isUndefined(neededModel)) {
              this.fundingModel = neededModel;
              this.initFundingForms();

              this.store.dispatch(
                new FetchSupplierMarketplaceDetailsPart({
                  marketplaceModel: this.marketplaceDetailsModel,
                  supplierDetailsModel: this.supplierDetailsModel,
                  serviceClassName: this.SUPPLIER_MARKETPLACE_OPT_IN_PERIODS_SERVICE_CLASS_NAME,
                  storeKey: 'marketplaceOptInPeriodModels',
                  refresh: true,
                }),
              );
            }
          }
        }),
    );
    this.subscriptions.push(
      this.store
        .select(selectSupplierMarketplacesMinFeeOverrides)
        .subscribe((minFeeOverridesModels: { [mpSupKey: string]: SupplierMarketplaceMinFeeOverridesModel[] }) => {
          if (minFeeOverridesModels && this.marketplaceDetailsModel) {
            const neededModel = !isUndefined(minFeeOverridesModels[this.marketplaceDetailsModel.mpSupKey])
              ? minFeeOverridesModels[this.marketplaceDetailsModel.mpSupKey]
              : undefined;

            if (!isUndefined(neededModel)) {
              this.minFeeOverridesModels = neededModel;
            }
          }
        }),
    );
  }

  getMarketplace(marketplacesList: SupplierMarketplaceDetailsModel[], supplierMpKey: string): SupplierMarketplaceDetailsModel {
    const marketplacesModel: SupplierMarketplaceDetailsModel = marketplacesList.find((item: any) => {
      return item.mpSupKey === supplierMpKey ? item : undefined;
    });

    if (marketplacesModel) {
      return marketplacesModel;
    } else {
      throw Error('Supplier marketplace not found');
    }
  }

  initFundingForms() {
    if (isUndefined(this.formGroups[SupplierMarketplaceFormTypes.FUNDING_FORM])) {
      this.formGroups = {
        ...this.formGroups,
        ...this.marketplaceFormBuilder.initFundingFormGroups(this.fundingModel),
      };
    }
  }

  initGeneralForms() {
    if (isUndefined(this.formGroups[SupplierMarketplaceFormTypes.REVENUE_FORM])) {
      this.formGroups = {
        ...this.formGroups,
        ...this.marketplaceFormBuilder.initGeneralFormGroups(this.marketplaceDetailsModel),
      };
    }
  }

  getSupplierKey() {
    return this.supplierDetailsModel.supplierKey;
  }

  resolveServiceClassBy(formGroupName: string) {
    switch (formGroupName) {
      case this.FUNDING_FORM:
      case this.FEE_SETTINGS_FORM:
      case this.FEE_DATES_FORM:
        return this.SUPPLIER_MARKETPLACE_FUNDING_SERVICE_CLASS_NAME;

      default:
        return this.SUPPLIER_MARKETPLACE_SERVICE_CLASS_NAME;
    }
  }

  protected prepareRequestPartData(formGroupName, partData) {
    const wholeModelState = {
      ...this.marketplaceDetailsModel,
      ...partData,
    };
    return wholeModelState;
  }

  protected resolveParamsBy(formGroupName: string): any[] {
    return [this.marketplaceDetailsModel.mpSupKey];
  }

  showPostFeeTrialFeeSchedude(marketplaceDetailsModel) {
    if (!marketplaceDetailsModel || !marketplaceDetailsModel.funding) {
      return false;
    }
    return this.freeTrialFeeScheduleKeys.indexOf(marketplaceDetailsModel.currentFeeScheduleKey) !== -1;
  }
}
