import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isArray, isUndefined } from 'util';

import { selectLoggedUserModel } from '../../../../auth/store/auth.actions';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { FeatureFlagsService } from '../../../../shared/feature-flags/feature-flags.service';
import { TableEventsService, TASK_COUNTERS_UPDATE } from '../../../../shared/table-events.service';
import { PermissionModel } from '../../../../shared/users/permission.model';
import { UsersService } from '../../../../shared/users/service/users.service';
import { UserModel } from '../../../../shared/users/user.model';
import { AppState } from '../../../../store/app.reducers';
import { FilterDefinition, FilterSet } from '../../../models/task-filter.model';
import { TasksFiltersService } from '../../../services/tasks-filters.service';
import { TaskStatuses } from '../../task-statuses';

export const UNRESOLVED_FILTER = { value: [TaskStatuses.NEW, TaskStatuses.IN_PROGR], label: 'Unresolved' };

@Component({
  selector: 'app-task-grid-filters-sets',
  templateUrl: './task-grid-filters-sets.component.html',
  styleUrls: ['./task-grid-filters-sets.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskGridFiltersSetsComponent extends SubscriberComponent implements OnInit {
  @Input()
  assigneeOptions: (UserModel | PermissionModel)[];

  private _routingFilterNr: number;

  @Input()
  set routingFilterNr(value: number) {
    this._routingFilterNr = value;
    this.routingFilterNrChange$.next(this._routingFilterNr);
  }

  routingFilterNrChange$: Subject<number> = new Subject<number>();

  @Output()
  onFilterPicked: EventEmitter<FilterSet> = new EventEmitter<FilterSet>();

  countersLoadingInProgress = false;
  filtersOptions: FilterDefinition;

  filterUiModel = {};

  constructor(
    private store: Store<AppState>,
    private usersService: UsersService,
    private tasksFiltersService: TasksFiltersService,
    private tableEvents: TableEventsService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((loggedUser) => {
        if (!isUndefined(loggedUser)) {
          this.getFiltersConfig(loggedUser.loginKey);
          this.subscriptions.push(
            this.routingFilterNrChange$.subscribe(() => {
              this.setFiltersByIdParam();
            }),
          );

          this.subscriptions.push(
            this.initGroupFilters().subscribe(() => {
              this.updateFiltersCounts();

              // init first hit
              this.routingFilterNrChange$.next(this._routingFilterNr);
            }),
          );
        }
      }),
    );
  }

  initGroupFilters() {
    return this.usersService.groups.pipe(
      filter((data) => !!data),
      map((groupsList) => {
        return groupsList.map((groupItem) => {
          this.filtersOptions.predefined.push({
            isGroup: true,
            permCode: groupItem.permCode,
            title: groupItem.permDesc,
            subFilters: [],
            filters: {
              assignee: {
                type: 'group',
                key: groupItem.permCode,
              },
              createdAfter: moment().subtract(1, 'month').toDate(),
              status: UNRESOLVED_FILTER.value,
            },
          });
        });
      }),
    );
  }

  setFiltersByIdParam() {
    if (this._routingFilterNr && !isUndefined(this.filtersOptions.predefined[this._routingFilterNr])) {
      this.pickFilter(this.filtersOptions.predefined[this._routingFilterNr]);
    }
  }

  refreshFiltersCounts() {
    this.updateFiltersCounts();
  }

  equals(a, b) {
    if (!isUndefined(a) && !isUndefined(b) && !isUndefined(a.assignee) && !isUndefined(b.assignee)) {
      const isAssigneeEqual = this.deepEquals(a.assignee.key, b.assignee.key);

      const tempA = { ...a };
      tempA.assignee = undefined;
      const tempB = { ...b };
      tempB.assignee = undefined;

      const otherFieldsEqual = this.deepEquals(tempA, tempB);
      return isAssigneeEqual && otherFieldsEqual;
    } else {
      return this.deepEquals(a, b);
    }
  }

  private pickFilter(filterDefinition) {
    const filters = { ...filterDefinition.filters };

    // if (filters.assignee) {
    //   filters.assignee = this.assigneeOptions.find(assignee => assignee['uuid'] === filters.assignee.uuid);
    // }

    this.filterUiModel = { ...filters };
    for (const filterKey in filters) {
      if (isArray(filters[filterKey])) {
        this.filterUiModel[filterKey] = filters[filterKey];
      }
    }

    this.triggerSubfilters(filters);
  }

  triggerSubfilters(filterItem) {
    this.onFilterPicked.next(filterItem);
    if (!filterItem.isGroup) {
      return;
    }
    if (filterItem.subFilters && filterItem.subFilters.length) {
      filterItem.subFiltersVisible = !filterItem.subFiltersVisible;
    } else {
      this.subscriptions.push(
        this.usersService.getGroupUsers(filterItem.permCode).subscribe((result) => {
          for (const user of result) {
            filterItem.subFilters.push({
              title: user.name,
              filters: {
                assignee: {
                  type: 'user',
                  key: user.loginKey,
                },
                createdAfter: moment().subtract(1, 'month').toDate(),
              },
              uuid: user.loginKey,
            });
          }
          filterItem.subFiltersVisible = true;
          this.updateFiltersCountsGroups();
        }),
      );
    }
  }

  updateFiltersCountsGroups() {
    this.filtersOptions.predefined
      .filter((item) => item.isGroup && item.subFilters.length)
      .map((item) => {
        const assignees = item.subFilters.map((subfilter) => subfilter.uuid);
        assignees.push(item.permCode);
        this.subscriptions.push(
          this.tasksFiltersService.getUserFilterCounts(assignees).subscribe((result) => {
            let acc = 0;
            item.subFilters.map((elem) => {
              elem.count = result[elem.uuid];
              acc += elem.count;
            });
            item.membersCount = acc;
          }),
        );
      });
  }

  updateFiltersCounts() {
    if (this.tableEvents) {
      this.tableEvents.broadcast(TASK_COUNTERS_UPDATE);
    }
    this.countersLoadingInProgress = true;

    let predefinedFilters: FilterSet[] = [];

    if (this.filtersOptions) {
      predefinedFilters = [...this.filtersOptions.predefined];
    }

    const preparedFilterQueries: FilterSet[] = predefinedFilters.map((filterItem: FilterSet) => {
      const filterQuery: FilterSet = {
        title: filterItem.title,
        filtersQuery: this.filterToQuery(filterItem.filters),
      };

      return filterQuery;
    });

    return this.tasksFiltersService.getFilterCounts(preparedFilterQueries).subscribe(
      (queriesWithCounts) => {
        this.filtersOptions.predefined = predefinedFilters.map((filterItem: FilterSet) => {
          const filterWithCount = { ...filterItem };

          const founded = queriesWithCounts.find((item) => {
            return item.title === filterItem.title;
          });

          if (founded) {
            filterWithCount.count = founded.count;
          }

          return filterWithCount;
        });

        this.countersLoadingInProgress = false;
      },
      (error) => {
        this.countersLoadingInProgress = false;
      },
    );
  }

  filterToQuery(filters) {
    return this.tasksFiltersService.filterToQuery(filters);
  }

  /**
   * Deep equal similar to 'angular.equals' from AngularJS
   *
   * @see https://stackoverflow.com/questions/40597658/equivalent-of-angular-equals-in-angular2
   *
   * @param x
   * @param y
   * @returns
   */
  private deepEquals(x, y) {
    if (x === y) {
      return true; // if both x and y are null or undefined and exactly the same
    } else if (!(x instanceof Object) || !(y instanceof Object)) {
      return false; // if they are not strictly equal, they both need to be Objects
    } else if (x.constructor !== y.constructor) {
      // they must have the exact same prototype chain, the closest we can do is
      // test their constructor.
      return false;
    } else {
      for (const p in x) {
        if (!x.hasOwnProperty(p)) {
          continue; // other properties were tested using x.constructor === y.constructor
        }
        if (!y.hasOwnProperty(p)) {
          return false; // allows to compare x[ p ] and y[ p ] when set to undefined
        }
        if (x[p] === y[p]) {
          continue; // if they have the same strict value or identity then they are equal
        }
        if (typeof x[p] !== 'object') {
          return false; // Numbers, Strings, Functions, Booleans must be strictly equal
        }
        if (!this.deepEquals(x[p], y[p])) {
          return false;
        }
      }
      for (const p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
          return false;
        }
      }
      return true;
    }
  }

  private getFiltersConfig(loggedUserUUID) {
    const predefined = [
      {
        title: 'All unresolved',
        filters: {
          status: UNRESOLVED_FILTER.value,
        },
      },
      {
        title: 'Assigned to me',
        filters: {
          assignee: {
            key: loggedUserUUID,
            type: 'user',
          },
          status: UNRESOLVED_FILTER.value,
        },
      },
      {
        title: 'Reported by me',
        filters: {
          owner_uuid: loggedUserUUID,
          status: [TaskStatuses.NEW],
        },
      },
      {
        title: 'IN PROGRESS',
        filters: {
          status: [TaskStatuses.IN_PROGR],
        },
      },
      {
        title: 'Created in last 5 days',
        filters: {
          createdAfter: moment().subtract(5, 'days').toDate(),
          status: UNRESOLVED_FILTER.value,
        },
      },
    ];

    this.filtersOptions = {
      predefined,
      custom: [],
    };
  }
}
