export enum EmailTypes {
  'EMAIL_SUPPLIER' = 'email_supplier',
  'NOTE_EMAIL' = 'email_note',
  'BATCH_GROUP' = 'batch group',
  'AUTO_EMAIL' = 'email',
  'TASK_NOTIFICATION' = 'task notification',
  'BATCH_EMAIL' = 'batch',
}

export const emailTypes: any[] = [
  {
    name: 'Sent from supplier page',
    key: EmailTypes.EMAIL_SUPPLIER,
    filter: true,
  },
  {
    name: 'Note email',
    key: EmailTypes.NOTE_EMAIL,
    filter: true,
  },
  {
    name: 'Batch group',
    key: EmailTypes.BATCH_GROUP,
    filter: true,
  },
  {
    name: 'Automatic email',
    key: EmailTypes.AUTO_EMAIL,
    filter: true,
  },
  {
    name: 'Task Notification',
    key: EmailTypes.TASK_NOTIFICATION,
    filter: true,
  },
  {
    name: 'Batch email',
    key: EmailTypes.BATCH_EMAIL,
    filter: false,
  },
];

export enum EmailStatuses {
  'SENT' = 'sent',
  'PENDING' = 'pending',
  'CANCELLED' = 'cancelled',
  'SENDING' = 'sending',
}

export const emailStatuses = [
  {
    name: 'sent',
    key: EmailStatuses.SENT,
  },
  {
    name: 'pending',
    key: EmailStatuses.PENDING,
  },
  {
    name: 'cancelled',
    key: EmailStatuses.CANCELLED,
  },
  {
    name: 'sending',
    key: EmailStatuses.SENDING,
  },
];

export enum EmailActions {
  APPROVE = 'approve',
  CANCEL = 'cancel',
}

export const emailActions = [
  {
    name: 'approve',
    key: EmailActions.APPROVE,
  },
  {
    name: 'cancel',
    key: EmailActions.CANCEL,
  },
];
