import { transition, trigger, useAnimation } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { AppConfigService } from '../../../config';
import { B2bDocumentService, B2bDocumentTokenModel } from '../../../shared/b2b-document/b2b-document.service';
import { FEATURE_FLAGS } from '../../../shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { PybDocumentsService } from '../../../shared/pyb-documents/pyb-documents.service';
import { getTimeOffset } from '../../../shared/utils/get-time-offset.util';
import { CommentsService } from '../../services/comments.service';
import { TasksService } from '../../services/tasks.service';
import { commentsListAnimation } from '../../shared/cms.animations';
import { ItemWithAttachmentComponent } from '../../shared/item-with-attachment.component';
import { CommentAddComponent } from './../comment-add/comment-add.component';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  animations: [trigger('listAnimation', [transition('* => *', [useAnimation(commentsListAnimation)])])],
})
export class CommentListComponent extends ItemWithAttachmentComponent implements OnInit {
  @Input() hideButton = false;
  @Input() latestComments: any[];
  @Input() commentsCount: number;
  @Input() targetUuid: string;
  @Input() targetType: string;
  @Input() tasksContext = false;
  @Input() taskBody = null;
  @Input() addComment = false;
  @Input() author: string;
  @Input() set createdAt(createdAt: string | number) {
    this.localCreatedAt = typeof createdAt === 'number' ? this.formatDate(createdAt) : createdAt;
  }
  @Input() set updatedAt(updatedAt: string | number) {
    this.localUpdatedAt = typeof updatedAt === 'number' ? this.formatDate(updatedAt) : updatedAt;
  }
  @Output() onComment = new EventEmitter();

  @ViewChild('taskBodyElement') taskBodyElement: ElementRef;
  @ViewChild('addCommentComponent') addCommentComponent: CommentAddComponent;

  readonly correctTime = getTimeOffset;
  allItems: any[];
  itemsLoaded = false;
  showAllComments = true;
  localCreatedAt: string;
  localUpdatedAt: string;

  constructor(
    private commentsService: CommentsService,
    private tasksService: TasksService,
    dialog: MatDialog,
    b2bDownloadService: B2bDocumentService,
    httpClient: HttpClient,
    appConfig: AppConfigService,
    renderer: Renderer2,
    featureFlagsService: FeatureFlagsService,
    pybDocuments: PybDocumentsService,
    toastrService: ToastrService,
    sanitizer: DomSanitizer,
  ) {
    super(dialog, b2bDownloadService, toastrService, httpClient, appConfig, renderer, featureFlagsService, pybDocuments, sanitizer);
  }

  ngOnInit() {
    this.allItems = this.latestComments;
    if (this.tasksContext) {
      this.fetchComments();
    }
  }

  formatDate(timestamp: number): string {
    return moment.unix(timestamp).utc().format('YYYY-MM-DD HH:mm:ss');
  }

  async findFiles() {
    if (!isNullOrUndefined(this.taskBody)) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.taskBody, 'text/html');
      const spans = doc.querySelectorAll('span[pyb-b2b-preview]');
      for (const index in spans) {
        if (spans.hasOwnProperty(index)) {
          const uuid = spans[index].getAttribute('thumbnail-id');
          if (uuid) {
            const blob = await this.pybDocuments.downloadThumbnail(uuid).toPromise();
            const objectURL = URL.createObjectURL(blob);
            if (doc.querySelectorAll('img')[index]) {
              doc.querySelectorAll('img')[index].src = objectURL;
            }
          }
        }
      }
      this.taskBody = doc.body.innerHTML;
    }
  }

  ngAfterViewInit() {
    this.findFiles();
  }

  fetchComments() {
    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      this.commentsService.getCommentsList(this.targetUuid).subscribe((comments) => {
        this.allItems = comments;
        this.showAllComments = true;
        this.itemsLoaded = true;
      });
      this.findFiles();
      return;
    }
    const events$ = this.tasksContext ? this.tasksService.getEvents(this.targetUuid) : of([]);
    forkJoin([this.commentsService.getCommentsList(this.targetUuid), events$, this.b2bDownloadService.getToken()]).subscribe(
      ([comments, events, b2bToken]) => {
        this.allItems = this.replaceB2bToken([...comments, ...events], b2bToken);
        this.showAllComments = true;
        this.itemsLoaded = true;
      },
    );
  }

  handleOnComment(data: any) {
    this.onComment.emit(data);
  }

  getCurrentCommentData() {
    if (this.addCommentComponent) {
      return this.addCommentComponent.getCurrentCommentData();
    }

    return null;
  }

  private replaceB2bToken(comments: any[], token: B2bDocumentTokenModel): any[] {
    const regex = /(b2bdocument_token=[=\_\?a-zA-Z0-9\.:\/\-]*)/g;
    return comments.map((comment) => {
      if (!comment.body) {
        return comment;
      }

      const replacedBody = comment.body.replace(regex, `b2bdocument_token=${token.token}`);
      return { ...comment, body: replacedBody };
    });
  }
}
