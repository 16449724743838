import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { selectAuthToken } from '../../auth/store/auth.actions';
import { AppConfigService } from '../../config';
import { AppState } from '../../store/app.reducers';
import { CommentModel } from '../models/comment.model';
import { CommentsServiceInterface } from './comments.service.interface';

export class CommentsService implements CommentsServiceInterface {
  private apiUrl: string;
  private headers: { [name: string]: string } = {};

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient, private store: Store<AppState>) {
    this.apiUrl = appConfig.env.actionsToolsUrl;

    this.headers['X-InstanceId'] = '' + appConfig.env.instanceId;
    this.headers['X-EventLogger-InstanceId'] = '' + appConfig.env.instanceId;

    this.store.select(selectAuthToken).subscribe((jwtToken) => {
      this.headers['Authorization'] = jwtToken;
    });
  }

  getCommentsList(targetUuid: string): Observable<CommentModel[]> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (targetUuid) {
      headers['X-Actions-TargetUUID'] = targetUuid;
    }

    return this.httpClient
      .get(this.apiUrl + 'comments/', {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) =>
          res['data'].map((item) => {
            DeserializeKeysFrom(UnderscoreCase);
            return Deserialize(item, CommentModel);
          }),
        ),
      );
  }

  addComment(targetUuid: string, data: CommentModel): Observable<CommentModel> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (targetUuid) {
      headers['X-Actions-TargetUUID'] = targetUuid;
    }

    return this.httpClient
      .post(this.apiUrl + 'comments/', Serialize(data, CommentModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], CommentModel);
        }),
      );
  }

  editComment(uuid: string, targetUuid: string, data: Partial<CommentModel>): Observable<CommentModel> {
    SerializeKeysTo(UnderscoreCase);

    const headers = {
      ...this.headers,
    };

    if (targetUuid) {
      headers['X-Actions-TargetUUID'] = targetUuid;
    }

    return this.httpClient
      .patch(`${this.apiUrl}comments/${uuid}/${targetUuid}/`, Serialize(data, CommentModel), {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
        map((res) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(res['data'], CommentModel);
        }),
      );
  }

  deleteComment(uuid: string, targetUuid: string): any {
    const headers = {
      ...this.headers,
    };

    if (targetUuid) {
      headers['X-Actions-TargetUUID'] = targetUuid;
    }

    return this.httpClient
      .delete(`${this.apiUrl}comments/${uuid}/${targetUuid}/`, {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders(headers),
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        }),
      );
  }
}
