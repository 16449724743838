import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SupplierBoxHeaderService {
  private refetchDataSubject = new Subject<void>();
  refetchData$ = this.refetchDataSubject.asObservable();

  refetchData(): void {
    this.refetchDataSubject.next();
  }
}
