import { autoserialize } from 'cerialize';

export class SupplierMarketplaceMinFeeOverridesModel {
  @autoserialize
  minFeeOverrideKey: string;

  @autoserialize
  monthlyMinFee: number;

  @autoserialize
  endDate: string;

  @autoserialize
  startDate: string;

  constructor(minFeeOverrideKey: string, monthlyMinFee: number, endDate: string, startDate: string) {
    this.minFeeOverrideKey = minFeeOverrideKey;
    this.monthlyMinFee = monthlyMinFee;
    this.endDate = endDate;
    this.startDate = startDate;
  }
}
