import { HttpRequest } from '@angular/common/http';

import { FETCH_REQUEST, FetchedRequestsActions } from './fetched-requests.actions';

const REQUESTS_AMOUNT = 50;

export interface IFetchedRequestsState {
  cachedRequests: any[];
}

const initialState = {
  cachedRequests: [],
};

const fetchedRequestsMap = new Map();

function cachedRequestArrayHandler(array, incomingRequest: HttpRequest<any>) {
  let newArr = array;
  if (array.length === REQUESTS_AMOUNT) {
    newArr = array.slice(0, -1);
  }
  newArr.unshift(JSON.stringify(incomingRequest, null, 1));
  return newArr;
}

fetchedRequestsMap.set(FETCH_REQUEST, (state: any, actionPayload: any) => {
  return {
    ...state,
    cachedRequests: [...cachedRequestArrayHandler(state.cachedRequests, actionPayload)],
  };
});

export function errorsReducer(state: IFetchedRequestsState = initialState, action: FetchedRequestsActions) {
  const onboardingFunction = fetchedRequestsMap.get(action.type);
  if (!onboardingFunction) {
    return state;
  }
  return onboardingFunction(state, action.payload);
}
