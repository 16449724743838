import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-auth-code-save',
  templateUrl: './marketplace-auth-code-save.component.html',
  styleUrls: ['./marketplace-auth-code-save.component.less'],
})
export class MarketplaceAuthCodeSaveComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
