import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-initial-underwriting-history',
  templateUrl: './initial-underwriting-history.component.html',
})
export class InitialUnderwritingHistoryComponent implements OnInit {
  @Input() underwritingHistory = [];
  @Output() onGetUnderwritingHistoryRecordClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  getUnderwritingHistoryRecordClick(item, index) {
    this.onGetUnderwritingHistoryRecordClick.emit({ item, index });
  }
}
