<div class="row">
  <mat-tab-group class="col-md-11">
    <mat-tab *ngFor="let actionForm of topForm?.controls; let i = index">
      <ng-template mat-tab-label>
        Action {{ i + 1 }}
        <button mat-icon-button>
          <mat-icon (click)="topForm.removeAt(i)">close</mat-icon>
        </button>
      </ng-template>

      <div class="pt-2">
        <app-action-form #actions [topForm]="actionForm" [action$]="loaded$" [index]="i"></app-action-form>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="col-md-1">
    <button mat-mini-fab (click)="addAction()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
<!--<pre>-->
<!--{{topForm.value|json }}-->
<!--</pre>-->
