import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { BaseSupplierAction } from '../../../suppliers/supplier/base-supplier-action/base-supplier-action';
import { TableEventsService } from './../../../shared/table-events.service';
import { AppState } from './../../../store/app.reducers';
import { CliService } from './../../../suppliers/services/cli/cli.service';
import { SupplierService } from './../../../suppliers/services/supplier.service';
import { BatchImportService } from './../service/batch-imports.service';

@Component({
  selector: 'app-weekly-purchases',
  templateUrl: './weekly-purchases.component.html',
  styleUrls: ['../batch-imports.component.scss'],
})
export class WeeklyPurchasesComponent extends BaseSupplierAction implements OnInit, OnDestroy {
  actionTitle = 'Weekly purchases';
  message = 'Are you sure you want to perform weekly purchases task?';

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    dialogService: MatDialog,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
    private batchService: BatchImportService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createForm() {}

  submit() {
    this.isConfirmed = true;
    this.isLoadingResult = true;

    const request = this.batchService.addWeeklyPurchases();

    this.subscriptions.push(
      request.subscribe(
        (success) => {
          this.onSuccess('Task started successfully');
        },
        (response) => {
          this.onError('Task failed to start');
        },
      ),
    );
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
