<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="navigation">
          <a [routerLink]="['/manage/perm-groups/list']" title="Go to permission groups list" class="close-button">
            <i class="material-icons">arrow_back</i>
          </a>
        </div>
        <div class="title">
          {{ title }}
        </div>
        <div class="actions">
          <button mat-raised-button class="action" color="warn" (click)="onDelete()">Delete Group</button>
        </div>
      </div>

      <form class="custom-form" [formGroup]="editPermissionGroupForm" (ngSubmit)="onSubmit()">
        <div class="form-content">
          <div class="form-actions">
            <a class="lock-button" title="{{ 'Click to ' + ((isLocked && 'unlock') || 'lock') }}" (click)="toggleLock()">
              <i class="material-icons">{{ isLocked ? 'lock' : 'lock_open' }}</i>
            </a>
          </div>

          <fieldset class="form-section">
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Permission Code" formControlName="permCode" />
            </mat-form-field>
          </fieldset>

          <h6>Permission Details</h6>
          <fieldset class="form-section">
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Permission Description" formControlName="permDesc" />
            </mat-form-field>
            <mat-checkbox formControlName="generalPerm">This is a general permission</mat-checkbox>
            <mat-checkbox formControlName="specSupPerm">This permission is tied to specific suppliers</mat-checkbox>
          </fieldset>

          <div class="row">
            <div class="col-md-6">
              <h6>Permissions</h6>
              <fieldset class="form-section" formArrayName="permissions">
                <ng-template ngFor let-record [ngForOf]="permissions.controls" let-i="index">
                  <div [formGroupName]="i">
                    <mat-checkbox formControlName="selected">{{ getPlaceholder(record) }}</mat-checkbox>
                  </div>
                </ng-template>
              </fieldset>
            </div>
            <div class="col-md-6">
              <h6>Root Permissions</h6>
              <fieldset class="form-section" formArrayName="rootPermissions">
                <ng-template ngFor let-record [ngForOf]="rootPermissions.controls" let-i="index">
                  <div [formGroupName]="i">
                    <mat-checkbox formControlName="selected">{{ getPlaceholder(record) }}</mat-checkbox>
                  </div>
                </ng-template>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="form-submit">
          <button mat-raised-button class="btn btn-cancel pull-right" color="warn" (click)="onCancel()">Cancel</button>
          <button mat-raised-button class="btn btn-success pull-right" type="submit" color="accent" [disabled]="isLocked">Save</button>
        </div>
      </form>
    </div>
  </div>
</section>
