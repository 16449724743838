import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../store/app.reducers';
import { AccessCalculatorService } from '../../../services/action-tab-calculator.service';
import { listAnimation } from '../../../shared/cms.animations';
import { ActionTabComponent } from '../action-tab-base.component';
import { ICalculatorResults, IFeeCalculatorResult } from './action-tab-calculator.types';
import { BehaviorSubject, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-action-tab-calculator',
  templateUrl: './action-tab-calculator.component.html',
  styleUrls: ['./action-tab-calculator.style.scss'],
  animations: [trigger('listAnimation', [transition('* => *', [useAnimation(listAnimation)])])],
})
export class ActionTabCalculatorComponent extends ActionTabComponent implements OnInit {
  calculatorForm: FormGroup;
  supplierKey: string;
  loading$ = new BehaviorSubject<boolean>(false);
  defaultCalculatorResults: ICalculatorResults = {
    free_access_risk: {
      lower_limit_price: 0,
      risk_score: 0,
      target_price: 0,
    },
    instant_access_risk: {
      lower_limit_price: 0,
      risk_score: 0,
      target_price: 0,
    }
  };
  calculatorResults: ICalculatorResults = this.defaultCalculatorResults;
  oldCalculatorResults: ICalculatorResults = this.defaultCalculatorResults;
  oldUserInputs: any;

  lastHistoryUser: string;
  lastTimeStamp: string;

  sellerCategoryOptions = [];
  uccSeniorOptions = [];
  repaymentMethodOptions = [];
  fbaOptions = [];
  timeSellingOptions = [];
  uwSizeOptions = [];

  constructor(
    store: Store<AppState>,
    private formBuilder: FormBuilder,
    private calculatorService: AccessCalculatorService,
    private readonly cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private readonly toastService: ToastrService,
  ) {
    super(store);
    this.route.params.subscribe(params => {
      const { supplierKey } = params;
      this.supplierKey = supplierKey;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.calculatorService.fetchCalculated(this.supplierKey).subscribe(
      (result: IFeeCalculatorResult) => {
        this.fbaOptions = result?.calculator_fields?.FBAInputs ?? [];
        this.repaymentMethodOptions = result?.calculator_fields?.RepaymentMethodInputs ?? [];
        this.timeSellingOptions = result?.calculator_fields?.TimeSellingInputs ?? [];
        this.uccSeniorOptions = result?.calculator_fields?.SeniorLienInputs ?? [];
        this.uwSizeOptions = result?.calculator_fields?.SellerSizeInputs ?? [];
        this.sellerCategoryOptions = result?.calculator_fields?.SupplierCategoryInputs ?? [];

        if (typeof result.calculator_history !== 'string') {
          if (result.calculator_history?.user) {
            this.lastHistoryUser = result.calculator_history?.user.split('@')[0];
          }

          if (result.calculator_history?.timestamp) {
            this.lastTimeStamp = result.calculator_history?.timestamp.split('T')[0]
              + ' ' + result.calculator_history?.timestamp.split('T')[1].split('.')[0];
          }

          if (result.calculator_history?.calculator_results) {
            this.oldCalculatorResults = result.calculator_history.calculator_results;
            this.saveOldInputs(result.calculator_history?.calculator_user_input);
          }
        }
      }
    );
  }

  public saveOldInputs(userInputs: Record<string, any>): void {
    this.oldUserInputs = {
      seller_size: this.uwSizeOptions.filter(
        val => val.value === userInputs?.seller_size)[0].label,
      supplier_category: this.sellerCategoryOptions.filter(
        val => val.value === userInputs?.supplier_category)[0].label,
      fba: this.fbaOptions.filter(
        val => val.value === userInputs?.fba)[0].label,
      senior_lien: this.uccSeniorOptions.filter(
        val => val.value === userInputs?.senior_lien)[0].label,
      time_selling: this.timeSellingOptions.filter(
        val => val.value === userInputs?.time_selling)[0].label,
      repayment_method: this.repaymentMethodOptions.filter(
        val => val.value === userInputs?.repayment_method)[0].label,
    };
  }

  public saveResults(): void {
    if (!this.calculatorForm.valid) {
      this.calculatorForm.markAllAsTouched();
      this.cdr.markForCheck();
      return;
    }

    this.loading$.next(true);

    const payload = {
      calc_results: this.calculatorResults,
      calc_user_input: this.calculatorForm.value
    };

    this.calculatorService.save(payload, this.supplierKey)
      .subscribe({
        next: (_result) => {
          this.oldCalculatorResults = this.calculatorResults;
          this.saveOldInputs(this.calculatorForm.value);
          this.toastService.success('Calculated results saved successfully!');
          this.loading$.next(false);
        },
        error: (_err) => {
          this.calculatorResults = this.defaultCalculatorResults;
          this.toastService.error('Error saving results, Please try again!');
          this.loading$.next(false);
        },
      });
  }


  private createForm(): void {
    this.calculatorForm = this.formBuilder.group({
      seller_size: [null, Validators.required],
      supplier_category: [null, Validators.required],
      fba: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      senior_lien: [null, Validators.required],
      time_selling: [null, Validators.required],
      repayment_method: [null, Validators.required],
    });

    // Subscribe to form value changes
    this.calculatorForm.valueChanges.subscribe(changes => {
      this.onValueChanged(changes);
    });

  }

  private onValueChanged(changes: any): void {
    if (!this.calculatorForm.valid) {
      this.calculatorForm.markAllAsTouched();
      this.cdr.markForCheck();
      return;
    }
    this.loading$.next(true);
    this.calculatorService.calculate(changes, this.supplierKey)
      .subscribe({
        next: (result) => {
          this.calculatorResults = result;
          this.loading$.next(false);
        },
        error: (_err) => {
          this.calculatorResults = this.defaultCalculatorResults;
          this.loading$.next(false);
        },
      });
  }

}
