import { HttpClient } from '@angular/common/http';
import { Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { IEnvironment } from '../../../environments/environment.interface';
import { AppConfigService } from '../../config';
import { B2bDocumentService } from '../../shared/b2b-document/b2b-document.service';
import { FEATURE_FLAGS } from '../../shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from '../../shared/feature-flags/feature-flags.service';
import { PybDocumentsService } from '../../shared/pyb-documents/pyb-documents.service';
import { B2bPreviewDialogComponent } from './b2b-preview/b2b-preview.component';

export class ItemWithAttachmentComponent {
  private uuid: string;
  private filename: string;
  private previewText: any;
  private downloadText: any;
  readonly gciId: IEnvironment['workflowGciId'];

  constructor(
    public dialog: MatDialog,
    public b2bDownloadService: B2bDocumentService,
    public toastrService: ToastrService,
    private httpClient: HttpClient,
    private appConfig: AppConfigService,
    private renderer: Renderer2,
    public featureFlagsService: FeatureFlagsService,
    public pybDocuments: PybDocumentsService,
    private sanitizer: DomSanitizer,
  ) {
    this.gciId = appConfig ? appConfig.env.workflowGciId : null;
  }

  transformAttachment(item: Element) {
    const oldLink = item.querySelector('a.file-download');
    const imgH = item.querySelector('img');

    if (!imgH) {
      return;
    }

    if (!isNullOrUndefined(oldLink)) {
      oldLink.removeAttribute('href');
    }

    // this.replaceImageSrc(imgH);

    const oldSrc = imgH.getAttribute('src');
    if (!oldSrc) {
      return;
    }

    let url;

    try {
      url = new URL(oldSrc);
    } catch (err) {
      url = '';
    }

    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      const thumbnailId = item.getAttribute('thumbnail-id');
      this.pybDocuments.downloadThumbnail(thumbnailId).subscribe((blob) => {
        this.preRenderImg(item, oldLink, null, blob as any);
        const objectURL = URL.createObjectURL(blob);
        url = new URL(objectURL);
        imgH.setAttribute('src', url.toString());
      });
      return;
    }
    this.b2bDownloadService.getToken().subscribe((data) => {
      this.preRenderImg(item, oldLink, data.token);
      url.search = `?b2bdocument_token=${data.token}`;
      imgH.setAttribute('src', url.toString());
    });
  }

  openPreviewDialog(): void {
    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      this.pybDocuments.downloadFile(this.uuid).subscribe((blob) => {
        this.dialog.open(B2bPreviewDialogComponent, {
          data: { downloadUrl: blob, filename: this.filename },
        });
      });
      return;
    }
    this.getAttachmentUrln().subscribe((url) => {
      this.dialog.open(B2bPreviewDialogComponent, {
        data: { downloadUrl: url, filename: this.filename },
      });
    });
  }

  download(): void {
    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      this.pybDocuments.downloadFile(this.uuid).subscribe((blob) => {
        this.changeDownloadButtonText('Downloading ...');
        FileSaver.saveAs(blob, this.filename);
        this.changeDownloadButtonText();
      });
      return;
    }
    this.getAttachmentUrln().subscribe((url) => {
      this.changeDownloadButtonText('Downloading ...');
      this.httpClient.get(url, { responseType: 'blob' }).subscribe((file) => {
        FileSaver.saveAs(file, this.filename);
        this.changeDownloadButtonText();
      });
    });
  }

  changeDownloadButtonText(text: string = 'Download'): void {
    this.downloadText.replaceData(0, 100, text);
  }

  getAttachmentUrln(): Observable<string> {
    if (!this.uuid) {
      return;
    }
    return this.b2bDownloadService.getFileDownloadUrl(this.gciId, this.uuid);
  }

  getAttachmentUrlWithRefreshedToken(token: string): Observable<string> {
    return this.b2bDownloadService.getFileDownloadUrlWithToken(this.gciId, this.uuid, token);
  }

  setAttachmentAttributes(elementPath: any): string[] {
    const uuid = elementPath.getAttribute('uuid');
    const filename = elementPath.getAttribute('filename');
    return [uuid, filename];
  }

  private preRenderImg(item: Element, oldLink: Element, token: string = null, url: string = null): void {
    // Button wrapper
    const ul = this.renderer.createElement('ul');
    ul.classList.add('tl-data-summary');

    // Preview button holder
    const previewLi = this.renderer.createElement('li');
    previewLi.classList.add('pill-btn');

    // Download button holder
    const downloadLi = this.renderer.createElement('li');
    downloadLi.classList.add('pill-btn');

    // Preview button
    const previewA = this.renderer.createElement('a');
    previewA.classList.add('tl-btn', 'btn', 'btn-primary');

    // Download button
    const downloadA = this.renderer.createElement('a');
    downloadA.classList.add('tl-btn', 'btn', 'btn-files');

    // Preview icon
    const downloadI = this.renderer.createElement('i');
    downloadI.classList.add('material-icons');
    this.renderer.appendChild(downloadI, this.renderer.createText('get_app'));

    // Preview icon
    const previewI = this.renderer.createElement('i');
    previewI.classList.add('material-icons');
    this.renderer.appendChild(previewI, this.renderer.createText('launch'));

    this.previewText = this.renderer.createText('Preview');
    this.downloadText = this.renderer.createText('Download');

    this.renderer.appendChild(previewA, previewI);
    this.renderer.appendChild(previewA, this.previewText);
    this.renderer.appendChild(previewLi, previewA);

    this.renderer.appendChild(downloadA, downloadI);
    this.renderer.appendChild(downloadA, this.downloadText);
    this.renderer.appendChild(downloadLi, downloadA);

    this.renderer.appendChild(ul, previewLi);
    this.renderer.appendChild(ul, downloadLi);
    this.renderer.appendChild(item, ul);

    if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
      this.renderImg(url, downloadA, oldLink, previewA);
      return;
    }
    this.getAttachmentUrlWithRefreshedToken(token).subscribe((urlWithToken) => {
      this.renderImg(urlWithToken, downloadA, oldLink, previewA);
    });
  }

  private renderImg(url, downloadA, oldLink, previewA): void {
    const urlWithName = `${url}&file_name=${this.filename}`;
    this.renderer.setAttribute(downloadA, 'href', urlWithName);
    this.renderer.setAttribute(downloadA, 'download', this.filename);
    this.renderer.listen(downloadA, 'click', (event) => {
      event.preventDefault();
      const elementPath = event.srcElement.parentElement.parentElement.parentElement;
      [this.uuid, this.filename] = this.setAttachmentAttributes(elementPath);
      this.download();
    });
    this.renderer.listen(previewA, 'click', (event) => {
      const elementPath = event.srcElement.parentElement.parentElement.parentElement;
      [this.uuid, this.filename] = this.setAttachmentAttributes(elementPath);
      this.openPreviewDialog();
    });
    this.renderer.listen(oldLink, 'click', (event) => {
      const elementPath = event.srcElement.parentElement.parentElement;
      [this.uuid, this.filename] = this.setAttachmentAttributes(elementPath);
      this.openPreviewDialog();
    });
  }
}
