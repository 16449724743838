import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { CardFormgroupComponent } from '../../../../shared/card-formgroup/card-formgroup.component';
import { CardFormgroupMessageService } from '../../../../shared/card-formgroup/card-formgroup-message.service';
import { CrmStates } from '../../../../shared/layout/layout.enums';
import { LayoutState } from '../../../../shared/layout/store/layout.reducers';
import { LookupAbstractService } from '../../../../shared/lookup-service/lookup-abstract.service';
import { AppState } from '../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { SupplierMarketplaceDetailsModel } from '../../../model/supplier-marketplace.model';
import { SupplierMarketplaceFundingModel } from '../../../model/supplier-marketplace-funding.model';
import { SupplierMarketplaceMinFeeOverridesModel } from '../../../model/supplier-marketplace-min-fee-overrides.model';
import { RxEditBaseComponent } from '../../../rx-edit-base.component';
import { SupplierMarketplacesService } from '../../../services/supplier-marketplaces.service';
import {
  FetchSupplierMarketplace,
  FetchSupplierMarketplaceDetailsPart,
  FetchSupplierMarketplaces,
  selectSupplierDetails,
  selectSupplierMarketplaces,
  selectSupplierMarketplacesFundings,
  selectSupplierMarketplacesMinFeeOverrides,
} from '../../../store/supplier/supplier.actions';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from '../../supplier-onboarding/supplier-onboarding.service';
import { SupplierUnderwritingService } from '../../supplier-underwriting/supplier-underwriting.service';
import { selectLoggedUserModel } from './../../../../auth/store/auth.actions';
import { ErrorLogger } from './../../../../shared/services/error-logger/error-logger.service';
import { USER_PERMISSION } from './../../../../shared/users/user-permissions.enum';
import { SHOPIFY_MARKETPLACE_KEY } from './../../supplier-onboarding/supplier-onboarding.service';
import { SupplierMarketplaceDict } from './supplier-marketplace-dict';
import { SupplierMarketplaceForm } from './supplier-marketplace-form';
import { SupplierMarketplaceFormTypes } from './supplier-marketplace-form-types';
const REVENUE_FORM_TIMEOUT_LENGTH = 2000;

@Component({
  selector: 'app-supplier-marketplace-edit',
  templateUrl: './supplier-marketplace-edit.component.html',
  styleUrls: ['./supplier-marketplace-edit.component.scss'],
})
export class SupplierMarketplaceEditComponent extends RxEditBaseComponent implements OnInit {
  marketplaceIconSanitized: any;
  routeMpSupKey: string;

  readonly SUPPLIER_MARKETPLACE_SERVICE_CLASS_NAME = 'SupplierMarketplacesService';
  readonly SUPPLIER_MARKETPLACE_FUNDING_SERVICE_CLASS_NAME = 'SupplierMarketplaceFundingService';
  readonly SUPPLIER_MARKETPLACE_OPT_IN_PERIODS_SERVICE_CLASS_NAME = 'SupplierMarketplaceOptInPeriodsService';
  readonly SUPPLIER_MARKETPLACE_MIN_FEE_OVERRIDES_SERVICE_CLASS_NAME = 'SupplierMarketplaceMinFeeOverridesService';

  readonly STORE_KEY_FUNDING = 'marketplaceFundingModels';
  readonly STORE_KEY_OPT_IN_PERIOD = 'marketplaceOptInPeriodModels';

  supplierDetailsModel: SupplierDetailsModel;
  marketplaceDetailsModel: SupplierMarketplaceDetailsModel;

  sellingTimeRanges$: Observable<any>;
  feeSchedules$: Observable<any>;

  readonly apiStatuses = SupplierMarketplaceDict.apiStatuses;
  readonly fundStatusList = SupplierMarketplaceDict.fundStatusList;
  readonly freeTrialFeeScheduleKeys = SupplierMarketplaceDict.freeTrialFeeScheduleKeys;

  readonly FUNDING_FORM = SupplierMarketplaceFormTypes.FUNDING_FORM;
  readonly PAYMENT_FORM = SupplierMarketplaceFormTypes.PAYMENT_FORM;
  readonly REVENUE_FORM = SupplierMarketplaceFormTypes.REVENUE_FORM;
  readonly INSTANT_FORM = SupplierMarketplaceFormTypes.INSTANT_FORM;

  readonly FEE_SETTINGS_FORM = SupplierMarketplaceFormTypes.FEE_SETTINGS_FORM;
  readonly FEE_DATES_FORM = SupplierMarketplaceFormTypes.FEE_DATES_FORM;
  readonly API_STATUS_FORM = SupplierMarketplaceFormTypes.API_STATUS_FORM;

  readonly PAYMENT_PROCESSOR_SETTINGS = SupplierMarketplaceFormTypes.PAYMENT_PROCESSOR_SETTINGS;

  fundingModel: SupplierMarketplaceFundingModel;
  minFeeOverridesModels: SupplierMarketplaceMinFeeOverridesModel[];

  layoutState$: Store<LayoutState>;
  crmPanelStates = CrmStates;
  buttonRefreshEnabledMarketplaces = [AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY, SHOPIFY_MARKETPLACE_KEY];

  @ViewChild('formPaymentProcessorSettings') private formPaymentProcessorSettings: CardFormgroupComponent;
  disableRefreshButton = true;
  hasFinancePermission = false;

  constructor(
    store: Store<AppState>,
    messageService: CardFormgroupMessageService,
    errorLogger: ErrorLogger,
    private route: ActivatedRoute,
    private lookupRepo: LookupAbstractService,
    private marketplaceFormBuilder: SupplierMarketplaceForm,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private supplierUnderwritingService: SupplierUnderwritingService,
    private supplierMarketplacesService: SupplierMarketplacesService,
    private sanitization: DomSanitizer,
    private permissionsService: NgxPermissionsService,
  ) {
    super(store, messageService, errorLogger);
  }

  ngOnInit() {
    this.layoutState$ = this.store.select('layout');

    this.checkFinancePermissions();

    this.sellingTimeRanges$ = this.lookupRepo.getSellingTimeRanges();
    this.feeSchedules$ = this.lookupRepo.getFeeSchedules();

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.routeMpSupKey = params['mpSupplierKey'];

        this.subscriptions.push(
          this.store.select(selectSupplierDetails).subscribe((supplierDetailsModel: SupplierDetailsModel) => {
            this.supplierDetailsModel = supplierDetailsModel;

            if (supplierDetailsModel != null) {
              this.store.dispatch(
                new FetchSupplierMarketplace({
                  supplierDetailsModel,
                  marketplaceUuid: this.routeMpSupKey,
                  refresh: true,
                }),
              );
            }
          }),
        );

        this.subscriptions.push(
          this.store
            .select(selectSupplierMarketplaces)
            .pipe(skipWhile((marketPlacesList) => marketPlacesList == null))
            .subscribe((marketPlacesList: SupplierMarketplaceDetailsModel[]) => {
              if (!isUndefined(marketPlacesList)) {
                this.marketplaceDetailsModel = this.getMarketplace(marketPlacesList, this.routeMpSupKey);

                if (this.marketplaceDetailsModel.icon) {
                  this.marketplaceIconSanitized = this.sanitization.bypassSecurityTrustResourceUrl(
                    this.sanitization.sanitize(SecurityContext.HTML, this.marketplaceDetailsModel.icon),
                  );
                }

                if (this.buttonRefreshEnabledMarketplaces.includes(this.marketplaceDetailsModel.marketplaceKey)) {
                  this.disableRefreshButton = false;
                }

                if (this.supplierDetailsModel) {
                  this.store.dispatch(
                    new FetchSupplierMarketplaceDetailsPart({
                      marketplaceModel: this.marketplaceDetailsModel,
                      supplierDetailsModel: this.supplierDetailsModel,
                      serviceClassName: this.SUPPLIER_MARKETPLACE_FUNDING_SERVICE_CLASS_NAME,
                      storeKey: this.STORE_KEY_FUNDING,
                      refresh: true,
                    }),
                  );
                }
                this.initGeneralForms();
              }
            }),
        );
      }),
    );

    // TODO nkler: map -> loop
    this.subscriptions.push(
      this.store
        .select(selectSupplierMarketplacesFundings)
        .subscribe((fundingModels: { [mpSupKey: string]: SupplierMarketplaceFundingModel }) => {
          if (fundingModels && this.marketplaceDetailsModel) {
            const neededModel = !isUndefined(fundingModels[this.marketplaceDetailsModel.mpSupKey])
              ? fundingModels[this.marketplaceDetailsModel.mpSupKey]
              : undefined;

            if (!isUndefined(neededModel)) {
              this.fundingModel = neededModel;
              this.initFundingForms();

              this.store.dispatch(
                new FetchSupplierMarketplaceDetailsPart({
                  marketplaceModel: this.marketplaceDetailsModel,
                  supplierDetailsModel: this.supplierDetailsModel,
                  serviceClassName: this.SUPPLIER_MARKETPLACE_OPT_IN_PERIODS_SERVICE_CLASS_NAME,
                  storeKey: this.STORE_KEY_OPT_IN_PERIOD,
                  refresh: true,
                }),
              );
            }
          }
        }),
    );
    this.subscriptions.push(
      this.store
        .select(selectSupplierMarketplacesMinFeeOverrides)
        .subscribe((minFeeOverridesModels: { [mpSupKey: string]: SupplierMarketplaceMinFeeOverridesModel[] }) => {
          if (minFeeOverridesModels && this.marketplaceDetailsModel) {
            const neededModel = !isUndefined(minFeeOverridesModels[this.marketplaceDetailsModel.mpSupKey])
              ? minFeeOverridesModels[this.marketplaceDetailsModel.mpSupKey]
              : undefined;

            if (!isUndefined(neededModel)) {
              this.minFeeOverridesModels = neededModel;
            }
          }
        }),
    );
  }

  getMarketplace(marketplacesList: SupplierMarketplaceDetailsModel[], supplierMpKey: string): SupplierMarketplaceDetailsModel {
    const marketplacesModel: SupplierMarketplaceDetailsModel = marketplacesList.find((item: any) => {
      return item.mpSupKey === supplierMpKey ? item : undefined;
    });

    if (marketplacesModel) {
      return marketplacesModel;
    } else {
      throw Error('Supplier marketplace not found');
    }
  }

  initFundingForms() {
    if (isUndefined(this.formGroups[SupplierMarketplaceFormTypes.FUNDING_FORM])) {
      this.formGroups = {
        ...this.formGroups,
        ...this.marketplaceFormBuilder.initFundingFormGroups(this.fundingModel),
      };
    }
  }

  initGeneralForms() {
    if (isUndefined(this.formGroups[SupplierMarketplaceFormTypes.REVENUE_FORM])) {
      this.formGroups = {
        ...this.formGroups,
        ...this.marketplaceFormBuilder.initGeneralFormGroups(this.marketplaceDetailsModel),
      };
    }
  }

  customRequestMapper(modelData) {
    const data = { ...modelData };

    if (!this.hasFinancePermission) {
      delete data.overrideDailyPlaidCheck;
    }

    return data;
  }

  public maskAwsAccessKeyId(): any {
    return false;
  }

  getSupplierKey() {
    return this.supplierDetailsModel.supplierKey;
  }

  resolveServiceClassBy(formGroupName: string) {
    switch (formGroupName) {
      case this.FUNDING_FORM:
      case this.FEE_SETTINGS_FORM:
      case this.FEE_DATES_FORM:
        return this.SUPPLIER_MARKETPLACE_FUNDING_SERVICE_CLASS_NAME;

      default:
        return this.SUPPLIER_MARKETPLACE_SERVICE_CLASS_NAME;
    }
  }

  protected prepareRequestPartData(formGroupName, partData) {
    const wholeModelState = {
      ...this.marketplaceDetailsModel,
      ...partData,
    };
    return wholeModelState;
  }

  protected resolveParamsBy(formGroupName: string): any[] {
    return [this.marketplaceDetailsModel.mpSupKey];
  }

  showPostFeeTrialFeeSchedude(marketplaceDetailsModel) {
    if (!marketplaceDetailsModel || !marketplaceDetailsModel.funding) {
      return false;
    }
    return this.freeTrialFeeScheduleKeys.indexOf(marketplaceDetailsModel.currentFeeScheduleKey) !== -1;
  }

  refreshEstimatedReveneueRowData() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetailsModel: SupplierDetailsModel) => {
        this.supplierDetailsModel = supplierDetailsModel;

        if (!isUndefined(supplierDetailsModel)) {
          this.store.dispatch(
            new FetchSupplierMarketplaces({
              supplierDetailsModel,
              refresh: true,
            }),
          );
        }

        this.subscriptions.push(
          this.route.params.subscribe((params) => {
            this.routeMpSupKey = params['mpSupplierKey'];
            this.subscriptions.push(
              this.store
                .select(selectSupplierMarketplaces)
                .pipe(skipWhile((marketPlacesList) => marketPlacesList == null))
                .subscribe((marketPlacesList: SupplierMarketplaceDetailsModel[]) => {
                  if (!isUndefined(marketPlacesList)) {
                    this.marketplaceDetailsModel = this.getMarketplace(marketPlacesList, this.routeMpSupKey);
                  }

                  delete this.formGroups[SupplierMarketplaceFormTypes.REVENUE_FORM];
                  if (this.formGroups) {
                    setTimeout(() => {
                      this.formGroups[SupplierMarketplaceFormTypes.REVENUE_FORM] = this.formBuilder.group({
                        estimatedMonthlyRevenue: this.marketplaceDetailsModel.estimatedMonthlyRevenue,
                        lengthTimeSelling: this.marketplaceDetailsModel.lengthTimeSelling,
                      });
                    }, REVENUE_FORM_TIMEOUT_LENGTH);
                  }
                }),
            );
          }),
        );

        setTimeout(() => {
          this.toastrService.success('Data has been successfully fetched.');
        }, 1800);
      }),
    );
    this.supplierMarketplacesService
      .updateSalesData(this.marketplaceDetailsModel.marketplaceKey, this.supplierDetailsModel.supplierKey)
      .subscribe(
        (res) => {
          this.toastrService.success('Updating sales data success.');
        },
        (err) => {
          this.toastrService.error('Updating sales data failed.');
        },
      );
  }

  public checkAmazonApi() {
    const TEXT_MESSAGES = {
      prefix: 'Amazon Merchant Token & Seller ID are',
      sameWithAccess: 'the same and managed to connect with Amazon',
      sameNoAccess: 'the same but failed to connect with Amazon',
      differentWithAccess: 'different but managed to connect with Amazon',
      differentNoAccess: 'different and failed to connect with Amazon',
    };
    this.subscriptions.push(
      this.supplierUnderwritingService.getAmazonApiInfo(this.supplierDetailsModel.supplierKey).subscribe(
        (amazonApiData) => {
          if (amazonApiData && !isNullOrUndefined(amazonApiData)) {
            if (amazonApiData.MerchantToken === amazonApiData.SellerId && amazonApiData.connected_to_amazon_api) {
              this.toastrService.success(`${TEXT_MESSAGES.prefix} ${TEXT_MESSAGES.sameWithAccess}`);
            } else if (amazonApiData.MerchantToken === amazonApiData.SellerId && !amazonApiData.connected_to_amazon_api) {
              this.toastrService.error(`${TEXT_MESSAGES.prefix} ${TEXT_MESSAGES.sameNoAccess}`);
            } else if (amazonApiData.MerchantToken !== amazonApiData.SellerId && amazonApiData.connected_to_amazon_api) {
              this.toastrService.warning(`${TEXT_MESSAGES.prefix} ${TEXT_MESSAGES.differentWithAccess}`);
            } else {
              this.toastrService.error(`${TEXT_MESSAGES.prefix} ${TEXT_MESSAGES.differentNoAccess}`);
            }
          }
        },
        (err) => this.toastrService.error(err && err.error && err.error.message ? err.error.message : 'Something went wrong'),
      ),
    );
  }

  public isAmazonSellerCentral() {
    return this.marketplaceDetailsModel.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY;
  }

  public savePaymentProcessorSettingsForm(form, event?) {
    if (!event) {
      return this.updatePaymentProcessorSettingsRequest(form.value);
    }
    this.updatePaymentProcessorSettingsRequest({ [form]: event });
  }

  advanceRateFormatter(data: number): number {
    return Math.round(data);
  }

  minimumReservePctFormatter(data: number): number {
    return Math.round(data);
  }

  private updatePaymentProcessorSettingsRequest(value: any): void {
    this.subscriptions.push(
      this.supplierMarketplacesService.updatePaymentProcessorSettings(this.routeMpSupKey, value).subscribe(
        (response) => {
          this.messageService.broadcast(this.formGroups[this.PAYMENT_PROCESSOR_SETTINGS], 'saveSuccess');
          this.formPaymentProcessorSettings.disableEditMode();
        },
        (err) => {
          this.messageService.broadcast(this.formGroups[this.PAYMENT_PROCESSOR_SETTINGS], 'saveError');
          this.toastrService.error(err.error.message);
        },
      ),
    );
  }

  private checkFinancePermissions(): void {
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        this.permissionsService.hasPermission(USER_PERMISSION.FINANCE).then((permitted) => {
          this.hasFinancePermission = permitted;
        });
      }),
    );
  }
}
