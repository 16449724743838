import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { isNullOrUndefined, isUndefined } from 'util';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { ReasonDialogMode } from '../../../shared/enums/reason-dialog-mode.enum';
import { LookupAbstractService } from '../../../shared/lookup-service/lookup-abstract.service';
import { ReasonDialogComponent } from '../../../shared/reason-dialog/reason-dialog.component';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { SupplierMarketplaceListModel } from '../../model/supplier-marketplace.model';
import { selectSupplierDetails, ToggleSuspendSupplier } from '../../store/supplier/supplier.actions';

@Component({
  selector: 'app-suspend-supplier',
  templateUrl: './suspend-supplier.component.html',
  styleUrls: ['./suspend-supplier.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuspendSupplierComponent extends SubscriberComponent implements OnInit {
  public supplierDetailsModel$: Store<SupplierDetailsModel>;
  public supplierMarketplaces: SupplierMarketplaceListModel[] = [];
  public toggleInProgress = false;
  public requiredDataHasLoaded = false;

  public supplierSuspended: boolean;

  public suspendCodeDomain = 'susphist.Suspend';
  public suspendReasons: Observable<any[]>;

  public unsuspendCodeDomain = 'susphist.Unsuspend';
  public unsuspendReasons: Observable<any[]>;

  public supplierKey: string;

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private lookupService: LookupAbstractService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.suspendReasons = this.lookupService.getCodes(this.suspendCodeDomain);
    this.unsuspendReasons = this.lookupService.getCodes(this.unsuspendCodeDomain);

    this.supplierDetailsModel$ = this.store.select(selectSupplierDetails);
    this.subscriptions.push(
      this.store.select('supplier').subscribe((supplierState) => {
        if (
          !isUndefined(supplierState) &&
          !isUndefined(supplierState.supplierModel) &&
          !isUndefined(supplierState.supplierModel.supplierDetailsModel) &&
          !isUndefined(supplierState.supplierModel.supplierDetailsModel.marketplaces)
        ) {
          this.supplierSuspended = supplierState.supplierModel.supplierDetailsModel.status.supplierStatus === 'Suspended';
          this.supplierMarketplaces = [...supplierState.supplierModel.supplierDetailsModel.marketplaces];
          this.requiredDataHasLoaded = true;
        }

        if (supplierState.toggleSuspendInEdit === false) {
          if (!isUndefined(supplierState.toggleSuspendError)) {
            const errorMessage = supplierState.toggleSuspendError?.error?.message ?? 'Error during update operation';
            this.toastrService.error(errorMessage, null, { timeOut: 3000 });
          } else {
            this.toastrService.success('Updated successfully');
          }

          this.toggleInProgress = supplierState.toggleSuspendInEdit;
        }
      }),
    );

  }

  toggleSuspend(): void {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      data: {
        reasons: this.supplierSuspended ? this.unsuspendReasons : this.suspendReasons,
        warning: 'Caution! This action will change supplier status and affects supplier workflow.',
        marketplaces: this.supplierMarketplaces,
        mode: this.supplierSuspended ? ReasonDialogMode.Unsuspend : ReasonDialogMode.Suspend,
      },
      width: DIALOG_SIZE.RWD_60.width,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {

        if (!isNullOrUndefined(result)) {
          const dataToSave = !this.supplierSuspended ? { ...result } : { reason_code: result.reasonCode, reason_note: result.reasonNote };
          this.store.dispatch(new ToggleSuspendSupplier({ suspend: !this.supplierSuspended, reason: dataToSave }));
        }
      }),
    );
  }
}
