import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { filter, finalize } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { MonitorService } from '../../../../shared/monitor.service';
import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { UNDERWRITING_DAILY_COLUMNS } from '../consts/underwriting-daily-columns';
import { SupplierUnderwritingService } from '../supplier-underwriting.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../../../shared/users/user-permissions.enum';
import { UnderwritingDailyEditComponent } from './underwriting-daily-edit/underwriting-daily-edit.component';
import { UWDailyDataSource } from './uw-daily-data-source';

@Component({
  selector: 'app-underwriting-daily',
  templateUrl: './underwriting-daily.component.html',
})
export class UnderwritingDailyComponent extends SubscriberComponent implements OnInit {
  @ViewChild(UnderwritingDailyEditComponent)
  underwritingDailyEditComponent: UnderwritingDailyEditComponent;

  dailyViewOn = false;
  editRules = false;
  collectedData: UWDailyDataSource | null;
  selectedIndex = 0;
  supplierKey: string;
  displayedColumns = UNDERWRITING_DAILY_COLUMNS;
  public isRestrictedUWView = false;
  dataLoading = true;
  readonly userPermission = USER_PERMISSION;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly supplierUnderwritingService: SupplierUnderwritingService,
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly store: Store<AppState>,
    private readonly permissionsService: NgxPermissionsService,
    private readonly monitorService: MonitorService,
  ) {
    super();
  }

  ngOnInit() {
    this.setInitialData();
  }

  setInitialData() {
    this.monitorService.setUntracked();
    this.checkUserPermission();

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;
          this.onRouteChange();
        }),
    );
  }

  onRouteChange() {
    this.monitorService.send(this.supplierKey);
    this.subscriptions.push(
      this.supplierUnderwritingService
        .getAmazonHealthRules(this.supplierKey)
        .pipe(finalize(() => (this.dataLoading = false)))
        .subscribe((data) => {
          const numericRules = data.filter((item) => item.field_type === 'numeric');
          this.collectedData = new UWDailyDataSource(this.supplierUnderwritingService, this.supplierKey, numericRules);
        }),
    );
  }

  startEditRules(e) {
    e.preventDefault();
    e.stopPropagation();
    // store temp rules for back //
    this.editRules = true;
  }

  saveRulesClick() {
    if (!this.underwritingDailyEditComponent || !this.underwritingDailyEditComponent.dailyForm) {
      return;
    }
    this.editRules = false;
    this.saveRules();
  }

  saveRules() {
    const updatedRules = this.underwritingDailyEditComponent.getUpdatedRules();
    this.supplierUnderwritingService.updateAmazonHealthRules(this.supplierKey, updatedRules).subscribe(
      () => {
        this.onSaveRulesSuccess();
      },
      (err) => {
        this.onSaveRulesError(err);
      },
    );
  }

  onSaveRulesSuccess() {
    this.toastr.success('Account Health rule change saved properly!');
    this.setInitialData();
  }

  onSaveRulesError(err) {
    this.toastr.error(err.error.message, 'Problems with saving Account Health!', {
      enableHtml: true,
    });
  }

  private checkUserPermission() {
    const restrictedAccessPermission = this.permissionsService.getPermission(USER_PERMISSION.REST_UW_ACCESS);

    if (!isNullOrUndefined(restrictedAccessPermission)) {
      this.isRestrictedUWView = true;
    }
  }
}
