<form [formGroup]="flexFormGroup" class="freemiumRow">
  <div class="freemiumRow__marketplaceName">
    {{ marketplaceName
    }}<app-marketplace-status-indicator *ngIf="marketplace" [marketplace]="marketplace"></app-marketplace-status-indicator>
  </div>
  <app-inline-number
    *ngFor="let column of columns; index as i"
    [formControlName]="column.name"
    [formGroup]="flexFormGroup"
    [label]="column.label"
    (onSave)="trySaveField(column, $event)"
    >%</app-inline-number
  >
</form>
