import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { trackByIndex } from '../../../shared/utils/track-by-index.util';
import { FEATURE_FLAGS_ENV } from './../../../shared/feature-flags/feature-flags.enum';
import { FeatureFlagEntity } from './../../../shared/feature-flags/feature-flags.model';
import { KeyValue } from './../../../shared/models/key-value';

@Component({
  selector: 'app-admin-feature-flags-env',
  templateUrl: './feature-flags-env.component.html',
})
export class AdminFeatureFlagsEnvComponent {
  @Output() readonly changed: EventEmitter<KeyValue<boolean>[]> = new EventEmitter();
  @Input() envKey: FEATURE_FLAGS_ENV;
  @Input() title: string;

  readonly trackBy = trackByIndex;
  readonly SETTINGS_KEY = 'config';

  @Input() set data(data: FeatureFlagEntity[]) {
    this.dataSource.data = data;
  }

  public dataSource = new MatTableDataSource<FeatureFlagEntity>();
  public displayedColumns = ['key', 'exec', 'globalSettings', 'build'];

  onChange(): void {
    const flags = this.dataSource.data.map(({ key, exec }) => ({ [key]: exec }));
    this.changed.emit(flags);
  }
}
