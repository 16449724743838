import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { isNull, isNullOrUndefined } from 'util';

import { SubscriberComponent } from '../../../../../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from '../../../../../../shared/dialog.config';
import { LookupAbstractService } from '../../../../../../shared/lookup-service/lookup-abstract.service';
import { ReasonDialogComponent } from '../../../../../../shared/reason-dialog/reason-dialog.component';
import { ErrorVisualizationService } from '../../../../../../shared/services/error-visualization/error-visualization.service';
import { SupplierMarketplaceOptInPeriodsService } from '../../../../../services/supplier-marketplace-opt-in-periods.service';
import { ProductMatrixService } from '../service/product-matrix.service';
import { ProductMatrixInitializeTablesService } from '../service/product-matrix-initialize-tables.service';
import { IProducts } from '../service/product-matrix-interfaces';

@Component({
  selector: 'app-product-weekly-payments',
  templateUrl: './product-weekly-payments.component.html',
  styleUrls: ['./product-weekly-payments.component.scss'],
})
export class ProductWeeklyPaymentsComponent extends SubscriberComponent implements OnInit {
  @Input() supplierKey: string;
  @Input() response: IProducts;

  public serviceColumns = ['marketplace', 'lastPurchaseDate', 'pastDue', 'optInDate', 'optOutDate', 'enabled'];
  public dataSource: MatTableDataSource<any>;
  public productStatus: string;
  public reasons: Observable<any[]>;
  public mpData: any;
  public data: any[];
  public productStatusColor: string;

  constructor(
    private lookupRepo: LookupAbstractService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private productService: ProductMatrixService,
    private supplierMarketplaceOptInPeriodsService: SupplierMarketplaceOptInPeriodsService,
    private errorVisualizationService: ErrorVisualizationService,
  ) {
    super();
  }

  ngOnInit() {
    this.data = [];
    this.setUpDataFromResponse(this.response.weekly_schedule);
    this.dataSource = new MatTableDataSource(this.data);
    this.reasons = this.lookupRepo.getCodes('optin.AddDel');
  }

  private setUpDataFromResponse(product: any): void {
    this.productStatus = ProductMatrixInitializeTablesService.setProductStatus(product.onboarding_outcome.onboarding_result);
    this.productStatusColor = ProductMatrixInitializeTablesService.setProductStatusColor(this.productStatus);
    this.mpData = ProductMatrixInitializeTablesService.checkIfNotSupported(product.mp_data);
    ProductMatrixInitializeTablesService.setDataSource(this.mpData, this.data, this.setFieldsForSpecificProduct);
  }

  private setFieldsForSpecificProduct(mp: any) {
    return {
      lastPurchaseDate: mp.last_purchase_date ? mp.last_purchase_date : '-',
      pastDue: mp.past_due ? mp.past_due : '-',
      optInDate: mp.current_opt_in.start ? mp.current_opt_in.start : '-',
      optOutDate: mp.current_opt_in.end ? mp.current_opt_in.end : '-',
      enabled: mp.is_enabled,
    };
  }

  public openReasonDialog(optInDate: string, optOutDate: string, index: number): void {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      data: {
        reasons: this.reasons,
        warning: 'Caution! This action will change supplier status and may affect supplier workflow.',
        dates: {
          optIn: new Date(optInDate),
          optOut: new Date(optOutDate),
        },
        index,
      },
      width: DIALOG_SIZE.RWD_60.width,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!isNullOrUndefined(result)) {
        this.updateTableValues(result);
        this.optInDatesUpdateRequest(result, index);
      }
    });
  }

  private updateTableValues(result: any): void {
    const data = [...this.dataSource.data];
    data[result.index].optInDate = this.datePipe.transform(result.dates.optIn, 'MM/dd/yyyy');
    data[result.index].optOutDate = this.datePipe.transform(result.dates.optOut, 'MM/dd/yyyy');
    this.dataSource.data = data;
  }

  private optInDatesUpdateRequest(result: any, index: number) {
    const dataToSave = {
      reasonCode: result.reasonCode,
      reasonNote: result.reasonNote,
      startDate: this.datePipe.transform(result.dates.optIn, 'MM/dd/yyyy'),
      endDate: this.datePipe.transform(result.dates.optOut, 'MM/dd/yyyy'),
    };
    const mpSupplierKey = ProductMatrixInitializeTablesService.getMarketplaceSupplierKey(this.mpData, index);
    const optInKey = this.mpData[mpSupplierKey].current_opt_in.opt_in_key;

    if (isNull(optInKey)) {
      return this.subscriptions.push(
        this.supplierMarketplaceOptInPeriodsService.addOptInPeriod(this.supplierKey, mpSupplierKey, dataToSave).subscribe(
          () => {},
          (err: any) => {
            this.errorVisualizationService.showError({ name: 'Opt Dates Error', errorObject: err });
          },
        ),
      );
    }
    this.subscriptions.push(
      this.productService.putOptDates(this.supplierKey, mpSupplierKey, optInKey, dataToSave).subscribe(
        () => {},
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Opt Dates Error', errorObject: err });
        },
      ),
    );
  }

  public activationProductRequest(e: any, index: number) {
    const key = ProductMatrixInitializeTablesService.getMarketplaceSupplierKey(this.mpData, index);
    if (e.checked) {
      return this.subscriptions.push(
        this.productService.enableProduct(key, 'weekly_schedule').subscribe(
          () => {},
          (err: any) => {
            this.errorVisualizationService.showError({ name: 'Activation Product Error', errorObject: err });
          },
        ),
      );
    }
    this.subscriptions.push(
      this.productService.disableProduct(key, 'weekly_schedule').subscribe(
        () => {},
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Activation Product Error', errorObject: err });
        },
      ),
    );
  }
}
