import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

export interface GridAction {
  action: string;
  values: {
    key: string;
    value: string;
  }[];
}

@Component({
  selector: 'app-data-grid',
  styleUrls: ['./data-grid.style.scss'],
  templateUrl: './data-grid.component.html',
})
export class DataGridComponent implements OnChanges {
  // Input Variables
  @Input() columns: any[];
  @Input() data: any[];
  @Input() sort: any;
  @Input() gridbtns: any[];
  @Input() hdrbtns: any[];
  @Input() isshowfilter: boolean;
  @Input() isExporttoCSV: boolean;
  @Input() exportFileName: string;
  @Input() filter: any;
  @Input() title: string;

  // Output Variable
  @Output()
  btnclick: EventEmitter<GridAction> = new EventEmitter<GridAction>();

  // Local Variable
  pdata: any[];
  listFilter: string;
  searchTitle = 'Search:';

  ngOnChanges(changes: any) {
    if (JSON.stringify(changes).indexOf('data') !== -1) {
      this.pdata = this.data;
    }
    this.criteriaChange(this.listFilter);
  }

  selectedClass(columnName: string): any {
    return columnName === this.sort.column ? 'sort-' + this.sort.descending : false;
  }

  changeSorting(columnName: string): void {
    const sort = this.sort;
    if (sort.column === columnName) {
      sort.descending = !sort.descending;
    } else {
      sort.column = columnName;
      sort.descending = false;
    }
  }

  convertSorting(): string {
    return this.sort.descending ? '-' + this.sort.column : this.sort.column;
  }

  click(btn: any, row: any): void {
    const keyds = <GridAction>{};
    keyds.action = btn.action;

    if (row != null) {
      keyds.values = [];
      btn.keys.forEach((key: any) => {
        keyds.values.push({ key, value: row[key] });
      });
    }
    this.btnclick.emit(keyds);
  }

  criteriaChange(value: any) {
    if (this.filter != null) {
      if (value !== '[object Event]') {
        this.listFilter = value;
        this.pdata = this.filter.transform(this.data, this.listFilter);
      }
    }
  }

  // exporttoCSV() {
  //     let exprtcsv: any[] = [];
  //     (<any[]>JSON.parse(JSON.stringify(this.data))).forEach(x => {
  //         var obj = new Object();
  //         var frmt = new Format();
  //         for (var i = 0; i < this.columns.length; i++) {
  //             let transfrmVal = frmt.transform(x[this.columns[i].variable], this.columns[i].filter);
  //             obj[this.columns[i].display] = transfrmVal;
  //         }
  //         exprtcsv.push(obj);
  //     }
  //     );
  //     DataGridUtil.downloadcsv(exprtcsv, this.exportFileName);
  // }
}
