<div>
  <div class="page-header">
    <div>
      <h4>Feature Flags</h4>
    </div>
    <div>
      <button mat-raised-button [color]="'warn'" (click)="onReset()">Reset Flags</button>
      <button mat-raised-button [color]="'primary'" (click)="onShare()">Copy Settings URL</button>
      <button mat-raised-button [color]="'accent'" (click)="onReload()">Apply Current Flags</button>
    </div>
  </div>

  <app-admin-feature-flags-env
    *ngFor="let env of environments$ | async"
    [envKey]="env.env"
    [data]="env.data"
    [title]="env.title"
    (changed)="onChange(env.env, $event)"
  ></app-admin-feature-flags-env>

  <div class="text-right mt-4">
    <button mat-raised-button [color]="'primary'" (click)="onSaveState()">Save current state to the Global Settings</button>
  </div>
</div>

<app-eva class="display-none" [url]="'404'" #appEva></app-eva>
