import { Component, EventEmitter, Input, Output } from '@angular/core';

import { USER_PERMISSION } from './../users/user-permissions.enum';

@Component({
  selector: 'app-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss'],
})
export class SpinnerButtonComponent {
  @Input() disabled = false;
  @Input() loading = false;
  @Input() text = '';
  @Input() color = 'primary';
  @Input() spinnerColor = 'white';
  @Input() customWidth: string;
  @Input() customClasses: string;
  @Output() onClick = new EventEmitter();

  readonly userPermission = USER_PERMISSION;
}
