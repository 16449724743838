import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class PendingReviewService {
  private apiUrl = environment.internalApiUrl;

  constructor(private httpClient: HttpClient) {}

  getPendingReview(params: HttpParams): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}pending_review/`, { params });
  }

  updatePendingReview(supplierKey: string, supplier_transfer_approval_ml_key: string, treasury_approved: boolean) {
    const data: any = {};
    const endpointUrl = `${this.apiUrl}/pending_review/${supplierKey}`;
    data.supplier_transfer_approval_ml_key = supplier_transfer_approval_ml_key;
    data.treasury_approved = treasury_approved;
    return this.httpClient.put(endpointUrl, data);
  }
}
