import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { AppState } from '../../../store/app.reducers';

@Injectable()
export class ErrorLogger {
  constructor(private store$: Store<AppState>) {}

  log(title: string, data: any, withStoreDump = true): void {
    if (withStoreDump) {
      this.store$.pipe(take(1)).subscribe((dump) => {
        const store = this.prepareDump(dump);
        this.fullstoryEvent(title, { data });
        this.fullstoryEvent(title, { store });
      });
    } else {
      this.fullstoryEvent(title, { data });
    }
  }

  private prepareDump(storeData: any): any {
    const data = { ...storeData };

    delete data['auth'];
    delete data['backgroundReports'];
    delete data['calc'];
    delete data['crm'];
    delete data['layout'];
    delete data['utils'];

    return data;
  }

  private fullstoryEvent(title: string, data: any): void {
    try {
      (<any>window).analytics.track(title, data);
    } catch (err) {}
  }
}
