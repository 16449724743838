import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent implements OnInit {
  public customGridOptions: any;

  ngOnInit() {
    this.customGridOptions = {
      sortable: true,
      cacheBlockSize: 50,
      paginationPageSize: 50,
    } as GridOptions;
  }
}
