<form [formGroup]="form" novalidate>
  <div class="debitAccountToggle">
    <mat-slide-toggle [checked]="enableForDebit" [disabled]="false" (change)="toggleDebitBankAccount($event)"
      >Primary Debit Account</mat-slide-toggle
    >
  </div>
  <div formGroupName="beneficiary" class="row">
    <mat-form-field class="col-md-12">
      <input matInput placeholder="Beneficiary Name" formControlName="beneficiaryName" type="text" />
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <input matInput placeholder="Account number" formControlName="accountNumber" type="text" />
    </mat-form-field>
    <div ngxErrors="beneficiary.accountNumber" class="col-md-12">
      <mat-error ngxError="pattern" when="dirty"> You must provide correct account number </mat-error>
    </div>
    <mat-form-field class="col-md-12">
      <input matInput placeholder="Last 4 digits of actual bank account" formControlName="realMaskAccount" type="text" />
    </mat-form-field>
  </div>
  <div formGroupName="bank" class="row">
    <mat-form-field class="col-md-12">
      <input
        matInput
        [placeholder]="(isInternationalPayment$ | async) ? 'Swift code' : 'Routing number'"
        formControlName="Id"
        type="text"
      />
    </mat-form-field>
    <mat-error class="col-md-12" *ngIf="bankIdNumCtrlValidationStatus$ | async as status" [ngClass]="'text-' + status.type">{{
      status.msg
    }}</mat-error>

    <mat-form-field class="col-md-12">
      <input matInput placeholder="Branch ID - if your bank requires this, please include it." formControlName="branchId" type="text" />
    </mat-form-field>
    <div class="col-md-12 divider"></div>
  </div>

  <div formGroupName="beneficiary" class="row">
    <div formGroupName="address" class="col-md-12">
      <p>
        <strong>Beneficiary Address</strong>
      </p>
      <div class="row">
        <mat-form-field class="col-md-12">
          <input matInput placeholder="Address Line 1" formControlName="addr1" type="text" />
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <input matInput placeholder="Address Line 2 (optional)" formControlName="addr2" type="text" />
        </mat-form-field>

        <mat-form-field class="col-md-6">
          <input matInput placeholder="City" formControlName="city" type="text" />
        </mat-form-field>

        <mat-form-field class="col-md-6">
          <input matInput placeholder="Zip" formControlName="postalCode" type="text" />
        </mat-form-field>

        <div ngxErrors="beneficiary.address.postalCode" class="col-md-12">
          <mat-error ngxError="pattern" when="dirty"> Zip code must have 5 digits </mat-error>
        </div>
        <mat-form-field class="col-md-12">
          <mat-select matInput placeholder="Country" formControlName="country">
            <mat-option *ngFor="let country of countries$ | async" [value]="country.countryCode">
              {{ country.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-md-12" *ngIf="states$ | async as states">
          <mat-select placeholder="State" formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.id">{{ state.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-12 divider"></div>
  </div>

  <div formGroupName="bank" class="row">
    <div formGroupName="address" class="col-md-12">
      <p>
        <strong>Bank Address</strong>
      </p>
      <div class="row">
        <mat-form-field class="col-md-12">
          <input matInput placeholder="Address Line 1" formControlName="addr1" type="text" />
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <input matInput placeholder="Address Line 2 (optional)" formControlName="addr2" type="text" />
        </mat-form-field>

        <mat-form-field class="col-md-6">
          <input matInput placeholder="City" formControlName="city" type="text" />
        </mat-form-field>

        <mat-form-field class="col-md-6">
          <input matInput placeholder="Zip" formControlName="postalCode" type="text" />
        </mat-form-field>

        <div ngxErrors="bank.address.postalCode" class="col-md-12">
          <mat-error ngxError="pattern" when="dirty"> Zip code must have 5 digits </mat-error>
        </div>

        <mat-form-field class="col-md-12">
          <mat-select matInput placeholder="Country" formControlName="country">
            <mat-option *ngFor="let country of countries$ | async" [value]="country.countryCode">
              {{ country.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-md-12" *ngIf="bankStates$ | async as states">
          <mat-select placeholder="State" formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.id">{{ state.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
