import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, debounceTime, switchMap, withLatestFrom } from 'rxjs/operators';
import { isNull, isUndefined } from 'util';

import { ErrorVisualizationService } from '../../shared/services/error-visualization/error-visualization.service';
import { CRM_OPEN_ALL_TAB, TableEventsService } from '../../shared/table-events.service';
import { AppState } from '../../store/app.reducers';
import { CrmItem } from '../models/crm-item.type';
import { EmailModel } from '../models/email.model';
import { NoteModel } from '../models/note.model';
import { NoteDataModel } from '../models/note-data.model';
import { TaskModel } from '../models/task.model';
import { CrmService } from '../services/crm.service';
import { EmailsService } from '../services/emails.service';
import { TasksService } from '../services/tasks.service';
import { TASK_COUNTERS_UPDATE } from './../../shared/table-events.service';
import { USER_PERMISSION } from './../../shared/users/user-permissions.enum';
import {
  ADD_CRM_FILE,
  ADD_CRM_MODEL,
  ADD_CRM_NOTE,
  ADD_CRM_TASK,
  AddCrmFile,
  AddCrmNote,
  AddCrmTask,
  CRM_AFTER_TASK_SAVE,
  CRM_BEFORE_TASK_SAVE,
  CrmAfterTaskSave,
  CrmItemResponseError,
  CrmItemResponseSuccess,
  DELETE_CRM_FILE,
  DELETE_CRM_MODEL,
  DELETE_CRM_NOTE,
  DELETE_CRM_TASK,
  DeleteCrmFile,
  DeleteCrmNote,
  DeleteCrmTask,
  EDIT_CRM_NOTE,
  EDIT_CRM_TASK,
  EditCrmNote,
  EditCrmTask,
  FETCH_CRM_ALL,
  FETCH_CRM_BATCH_EMAILS,
  FETCH_CRM_EMAILS,
  FETCH_CRM_EVENTS,
  FETCH_CRM_FILES,
  FETCH_CRM_NOTES,
  FETCH_CRM_PINNED_NOTES,
  FETCH_CRM_TASKS,
  FetchCrmAll,
  FetchCrmBatchEmails,
  FetchCrmEmails,
  FetchCrmEvents,
  FetchCrmFiles,
  FetchCrmNotes,
  FetchCrmPinnedNotes,
  FetchCrmTasks,
  NO_ACTION,
  PIN_CRM_NOTE,
  PinCrmNote,
  SEND_SINGLE_EMAIL,
  SendSingleEmail,
  SET_TARGET_UUID,
  SetTargetUuid,
  UPDATE_CRM_MODEL,
} from './crm.actions';
import { CrmItemCollection } from './crm.reducers';

@Injectable()
export class CrmEffects {
  readonly RELOAD_TASKS_COUNTER_PROPAGATION_DELAY = 5000;

  @Effect()
  handleTargetUuidChange = this.actions$.ofType(SET_TARGET_UUID).pipe(
    withLatestFrom(this.store$),
    switchMap((data) => {
      const action: SetTargetUuid = <SetTargetUuid>data[0];
      const appState: AppState = <AppState>data[1];

      const isAdvanceAuditorUser = this.permissionsService.getPermission(USER_PERMISSION.ADVANCE_AUDITORS);

      if (isAdvanceAuditorUser) {
        return of();
      }

      if (action.payload.targetUuid !== appState.supplier.supplierKey || isNull(appState.crm.crmCurrentState.crmItems)) {
        this.tableEventsService.broadcast(CRM_OPEN_ALL_TAB);
        return [new FetchCrmAll({ refresh: '' + moment().unix() }), new FetchCrmPinnedNotes({ refresh: '' + moment().unix(), limit: 5 })];
      } else {
        return of();
      }
    }),
  );

  @Effect()
  getAllItems = this.actions$
    .ofType(FETCH_CRM_ALL)
    .pipe(debounceTime(100), withLatestFrom(this.store$), switchMap(this.fetchStream<FetchCrmAll>('listActions', 'all')));

  @Effect()
  getNoteItems = this.actions$
    .ofType(FETCH_CRM_NOTES)
    .pipe(debounceTime(100), withLatestFrom(this.store$), switchMap(this.fetchStream<FetchCrmNotes>('listActionsNotes', 'notes')));

  @Effect()
  getPinedNoteItems = this.actions$
    .ofType(FETCH_CRM_PINNED_NOTES)
    .pipe(
      debounceTime(100),
      withLatestFrom(this.store$),
      switchMap(this.fetchStream<FetchCrmPinnedNotes>('listActionsPinned', 'pinnedNotes')),
    );

  @Effect()
  getFileItems = this.actions$
    .ofType(FETCH_CRM_FILES)
    .pipe(debounceTime(100), withLatestFrom(this.store$), switchMap(this.fetchStream<FetchCrmFiles>('listActionsFiles', 'files')));

  @Effect()
  getTaskItems = this.actions$
    .ofType(FETCH_CRM_TASKS)
    .pipe(debounceTime(100), withLatestFrom(this.store$), switchMap(this.fetchStream<FetchCrmTasks>('listActionsTasks', 'tasks')));

  @Effect()
  getEventItems = this.actions$
    .ofType(FETCH_CRM_EVENTS)
    .pipe(debounceTime(100), withLatestFrom(this.store$), switchMap(this.fetchStream<FetchCrmEvents>('listActionsEvents', 'events')));

  @Effect()
  getEmailItems = this.actions$
    .ofType(FETCH_CRM_EMAILS)
    .pipe(debounceTime(100), withLatestFrom(this.store$), switchMap(this.fetchStream<FetchCrmEmails>('listActionsEmails', 'emails')));

  @Effect()
  getBatchEmailItems = this.actions$
    .ofType(FETCH_CRM_BATCH_EMAILS)
    .pipe(
      debounceTime(100),
      withLatestFrom(this.store$),
      switchMap(this.fetchStream<FetchCrmBatchEmails>('listActionsBatchEmails', 'batchEmails')),
    );

  @Effect()
  addNoteItem = this.actions$.ofType(ADD_CRM_NOTE).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: AddCrmNote = <AddCrmNote>result[0];
      const appState: AppState = <AppState>result[1];
      const targetUuid: string = appState.crm.targetUuid;
      const dataToSave: NoteDataModel = action.payload.data;

      return this.crmService.addNote(dataToSave).pipe(
        switchMap((savedNote) => {
          return [
            new CrmItemResponseSuccess({ actionId: action.payload.actionId }),
            {
              type: ADD_CRM_MODEL,
              targetUuid,
              payload: {
                newItem: savedNote,
                updateStreams: ['all', 'notes'],
              },
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: action.payload.actionId }));
        }),
      );
    }),
  );

  @Effect()
  editNoteItem = this.actions$.ofType(EDIT_CRM_NOTE).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: EditCrmNote = <EditCrmNote>result[0];
      const appState: AppState = <AppState>result[1];
      const targetUuid: string = appState.crm.targetUuid;
      const dataToSave: NoteDataModel = action.payload.data;
      const uuid: string = action.payload.uuid;

      return this.crmService.editNote(uuid, dataToSave).pipe(
        switchMap((savedNote) => {
          const crmItems = { ...appState.crm.crmCurrentState.crmItems };
          crmItems[savedNote.uuid] = savedNote;

          return [
            new CrmItemResponseSuccess({ actionId: savedNote.uuid }),
            {
              type: UPDATE_CRM_MODEL,
              targetUuid,
              payload: {
                crmItems,
              },
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: uuid }));
        }),
      );
    }),
  );

  @Effect()
  pinNoteItem = this.actions$.ofType(PIN_CRM_NOTE).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: PinCrmNote = <PinCrmNote>result[0];
      const appState: AppState = <AppState>result[1];
      const targetUuid: string = appState.crm.targetUuid;
      const dataToSave: Partial<NoteModel> = action.payload.data;
      const uuid: string = action.payload.uuid;

      if (appState.crm.crmCurrentState.pinnedNotes.length > 4 && dataToSave.isPinned) {
        this.toastrService.error('The maximum number of pinned notes has been reached');
        return of({ type: NO_ACTION });
      }

      return this.crmService.pinNote(uuid, dataToSave).pipe(
        switchMap((patchedItem) => {
          const updatedCrmItems = {};
          updatedCrmItems[uuid] = patchedItem;

          const updatedPinnedNotes = this.resolvePinnedItems(patchedItem, appState.crm.crmCurrentState.pinnedNotes);

          return [
            new CrmItemResponseSuccess({ actionId: uuid }),
            {
              type: UPDATE_CRM_MODEL,
              targetUuid,
              payload: {
                crmItems: updatedCrmItems,
                pinnedNotes: updatedPinnedNotes,
              },
              alterKeys: ['pinnedNotes'],
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: uuid }));
        }),
      );
    }),
  );

  @Effect()
  deleteNoteItem = this.actions$.ofType(DELETE_CRM_NOTE).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: DeleteCrmNote = <DeleteCrmNote>result[0];
      const appState: AppState = <AppState>result[1];
      const targetUuid: string = appState.crm.targetUuid;
      const uuid: string = action.payload.uuid;

      return this.crmService.deleteNote(uuid).pipe(
        switchMap((response) => {
          return [
            new CrmItemResponseSuccess({ actionId: uuid }),
            {
              type: DELETE_CRM_MODEL,
              targetUuid,
              payload: {
                itemsToDelete: [uuid],
              },
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: uuid }));
        }),
      );
    }),
  );

  @Effect()
  addTaskItem = this.actions$.ofType(ADD_CRM_TASK).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: AddCrmTask = <AddCrmTask>result[0];
      const appState: AppState = <AppState>result[1];
      const dataToSave: TaskModel = action.payload.data;
      const targetUuid = dataToSave.targetUuid;

      return this.tasksService.addTask(dataToSave).pipe(
        switchMap((savedTask) => {
          this.reloadCounts();

          return [
            new CrmItemResponseSuccess({ actionId: action.payload.actionId }),
            {
              type: ADD_CRM_MODEL,
              targetUuid,
              payload: {
                newItem: savedTask,
                updateStreams: ['all', 'tasks'],
              },
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: action.payload.actionId }));
        }),
      );
    }),
  );

  @Effect()
  beforeTaskSave = this.actions$.ofType(CRM_BEFORE_TASK_SAVE).pipe(
    withLatestFrom(this.store$),
    switchMap(() => {
      return of({ type: NO_ACTION });
    }),
  );

  @Effect()
  afterTaskSave = this.actions$.ofType(CRM_AFTER_TASK_SAVE).pipe(
    withLatestFrom(this.store$),
    switchMap(() => {
      return of({ type: NO_ACTION });
    }),
  );

  @Effect()
  editTaskItem = this.actions$.ofType(EDIT_CRM_TASK).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: EditCrmTask = <EditCrmTask>result[0];
      const appState: AppState = <AppState>result[1];
      const targetUuid: string = appState.crm.targetUuid;
      const dataToSave: TaskModel = action.payload.data;
      const uuid: string = action.payload.uuid;

      return this.tasksService.editTask(uuid, dataToSave).pipe(
        switchMap((savedTask) => {
          const crmItems = { ...appState.crm.crmCurrentState.crmItems };
          crmItems[savedTask.uuid] = savedTask;

          return [
            new CrmItemResponseSuccess({ actionId: savedTask.uuid }),
            new CrmAfterTaskSave({ data: dataToSave, uuid }),
            {
              type: UPDATE_CRM_MODEL,
              targetUuid,
              payload: {
                crmItems,
              },
            },
          ];
        }),
        catchError((err) => {
          this.errorVisualizationService.showError({ name: 'Edit task error:', errorObject: err });
          return of(new CrmItemResponseError({ actionId: uuid }));
        }),
      );
    }),
  );

  @Effect()
  deleteTaskItem = this.actions$.ofType(DELETE_CRM_TASK).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: DeleteCrmTask = <DeleteCrmTask>result[0];
      const appState: AppState = <AppState>result[1];
      const targetUuid: string = action.payload.targetUuid;
      const uuid: string = action.payload.uuid;

      return this.tasksService.deleteTask(uuid, targetUuid).pipe(
        switchMap((response) => {
          return [
            new CrmItemResponseSuccess({ actionId: uuid }),
            {
              type: DELETE_CRM_MODEL,
              targetUuid,
              payload: {
                itemsToDelete: [uuid],
              },
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: uuid }));
        }),
      );
    }),
  );

  @Effect()
  addFileItem = this.actions$.ofType(ADD_CRM_FILE).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: AddCrmFile = <AddCrmFile>result[0];
      const appState: AppState = <AppState>result[1];
      const dataToSave = action.payload.data;
      const targetUuid: string = appState.crm.targetUuid;

      return this.crmService.addFile(dataToSave).pipe(
        switchMap((savedFile) => {
          return [
            new CrmItemResponseSuccess({ actionId: action.payload.actionId }),
            {
              type: ADD_CRM_MODEL,
              targetUuid,
              payload: {
                newItem: { ...savedFile, type: 'file' },
                updateStreams: ['all', 'files'],
              },
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: action.payload.actionId }));
        }),
      );
    }),
  );

  @Effect()
  deleteFileItem = this.actions$.ofType(DELETE_CRM_FILE).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: DeleteCrmFile = <DeleteCrmFile>result[0];
      const appState: AppState = <AppState>result[1];
      const targetUuid: string = action.payload.targetUuid;
      const uuid: string = action.payload.uuid;
      const b2bUuid: string = action.payload.b2bUuid;

      return this.crmService.deleteFile(uuid, targetUuid, b2bUuid).pipe(
        switchMap((response) => {
          return [
            new CrmItemResponseSuccess({ actionId: uuid }),
            {
              type: DELETE_CRM_MODEL,
              targetUuid,
              payload: {
                itemsToDelete: [uuid],
              },
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: uuid }));
        }),
      );
    }),
  );

  @Effect()
  sendSingleEmail = this.actions$.ofType(SEND_SINGLE_EMAIL).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: SendSingleEmail = <SendSingleEmail>result[0];
      const appState: AppState = <AppState>result[1];
      const dataToSave: EmailModel = action.payload.data;
      const targetUuid = dataToSave.targetUuid;

      return this.emailsService.sendEmail(dataToSave).pipe(
        switchMap((savedEmail) => {
          return [
            new CrmItemResponseSuccess({ actionId: action.payload.actionId }),
            {
              type: ADD_CRM_MODEL,
              targetUuid,
              payload: {
                newItem: savedEmail,
                updateStreams: ['all', 'emails'],
              },
            },
          ];
        }),
        catchError((err) => {
          return of(new CrmItemResponseError({ actionId: action.payload.actionId }));
        }),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private crmService: CrmService,
    private tasksService: TasksService,
    private emailsService: EmailsService,
    private tableEventsService: TableEventsService,
    private errorVisualizationService: ErrorVisualizationService,
    private toastrService: ToastrService,
    private readonly permissionsService: NgxPermissionsService,
  ) {}

  reloadCounts(): void {
    setTimeout(() => {
      this.tableEventsService.broadcast(TASK_COUNTERS_UPDATE);
    }, this.RELOAD_TASKS_COUNTER_PROPAGATION_DELAY);
  }

  resolvePinnedItems(item: NoteModel, pinned: string[]) {
    const pinnedUuids = [...pinned];
    if (item.isPinned && !pinnedUuids.includes(item.uuid)) {
      pinnedUuids.unshift(item.uuid);
    }

    if (!item.isPinned && pinnedUuids.includes(item.uuid)) {
      const idx = pinnedUuids.indexOf(item.uuid, 0);
      pinnedUuids.splice(idx, 1);
    }
    return pinnedUuids;
  }

  fetchStream<T>(serviceFn, streamId) {
    return (result) => {
      const fetchAction: T = <T>result[0];
      const appState: AppState = <AppState>result[1];
      const targetUuid: string = appState.crm.targetUuid;
      let limit = 5;
      let last;
      let component;

      if (fetchAction.hasOwnProperty('payload') && (<any>fetchAction).payload) {
        limit = (<any>fetchAction).payload.limit;
        last = (<any>fetchAction).payload.last;
        component = (<any>fetchAction).payload.component;
      }

      if (!isUndefined(targetUuid)) {
        return this.crmService[serviceFn](limit, last).pipe(
          switchMap((results: any) => {
            const payload = {};
            const transformedItems = transformItemsList(results.data);
            if (results.meta && results.meta.last && component) {
              component.last = results.meta.last;
            }
            payload[streamId] = transformedItems.itemUuids;
            payload['crmItems'] = transformedItems.crmItemsList;

            return of({
              type: UPDATE_CRM_MODEL,
              targetUuid,
              payload,
            });
          }),
        );
      } else {
        return of();
      }
    };
  }
}

function transformItemsList(newItems: CrmItem[]): { crmItemsList: CrmItemCollection; itemUuids: string[] } {
  const transformedItems = {};
  const itemUuids = newItems.map((item) => {
    transformedItems[item.uuid] = item;
    return item.uuid;
  });

  return {
    crmItemsList: transformedItems,
    itemUuids,
  };
}
