const ForEveryRiskRating = [
  [
    { periodLength: 5, fee: 0.8 },
    { periodLength: 10, fee: 0.8 },
    { periodLength: 10, fee: 0.3 },
  ],
  [
    { periodLength: 5, fee: 0.8 },
    { periodLength: 10, fee: 0.8 },
    { periodLength: 10, fee: 0.4 },
  ],
  [
    { periodLength: 5, fee: 0.8 },
    { periodLength: 10, fee: 0.8 },
    { periodLength: 10, fee: 0.4 },
  ],
  [
    { periodLength: 5, fee: 1.5 },
    { periodLength: 5, fee: 1.25 },
    { periodLength: 10, fee: 0.625 },
  ],
  [
    { periodLength: 5, fee: 1.5 },
    { periodLength: 5, fee: 1.25 },
    { periodLength: 10, fee: 0.625 },
  ],
  [
    { periodLength: 5, fee: 2.25 },
    { periodLength: 5, fee: 1.75 },
    { periodLength: 10, fee: 0.5 },
  ],
  [
    { periodLength: 5, fee: 2.25 },
    { periodLength: 5, fee: 1.75 },
    { periodLength: 10, fee: 0.5 },
  ],
  [
    { periodLength: 5, fee: 2.5 },
    { periodLength: 5, fee: 2 },
    { periodLength: 10, fee: 0.75 },
  ],
  [
    { periodLength: 5, fee: 2.5 },
    { periodLength: 5, fee: 2 },
    { periodLength: 10, fee: 0.75 },
  ],
  [
    { periodLength: 5, fee: 2.5 },
    { periodLength: 5, fee: 2 },
    { periodLength: 10, fee: 0.75 },
  ],
];

const WhenNoRiskRating = [
  { periodLength: 5, fee: 2.5 },
  { periodLength: 5, fee: 2 },
  { periodLength: 10, fee: 0.75 },
];

export const ProgressivePricingSettings = {
  ForEveryRiskRating,
  WhenNoRiskRating,
};
