/* eslint-disable */
import { IAdvanceLineData } from './../active-advance/models/advance-line-data.interface';
import { of } from 'rxjs/observable/of';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplierAdvancesService } from './../service/supplier-advances.service';
import { Component, OnInit } from '@angular/core';
import { PeriodsCalculationsService } from '../pending-offer/pending-offer-calculator/service/periods-calculations.service';
import { MatDialog } from '@angular/material/dialog';
import { AdvanceOfferInputsModalComponent } from '../advance-offer-inputs-modal/advance-offer-inputs-modal.component';
import { SetAdvanceLineToStore } from '../../../utils/set-advance-line-to-store';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from '../../../../shared/dialog.config';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { filter } from 'rxjs/operators';

/* eslint-enable */

@Component({
  selector: 'app-approved-advance-lines',
  templateUrl: './approved-advance-lines.component.html',
  styleUrls: ['./approved-advance-lines.component.scss'],
})
export class ApprovedAdvanceLinesComponent extends SubscriberComponent implements OnInit {
  advanceLineHistory: IAdvanceLineData[];
  supplierKey: string;
  columns = [
    'show_inner_table',
    'advance_line_key',
    'limit_amount',
    'outstanding_limit_amount',
    'max_extra_reserve_percent',
    'create_ts',
    'status',
    'total_extra_reserve',
    'total_fee',
    'advances_number',
    'return_strategy',
    'underwriting_date',
    'expected_monthly_earnings',
    'expected_duration',
    'max_duration',
    'average_fee',
    're_underwrite',
  ];

  constructor(
    private readonly supplierAdvancesService: SupplierAdvancesService,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;
          this.loadData();
        }),
    );
  }

  loadData() {
    this.subscriptions.push(
      this.supplierAdvancesService.getAdvanceLineHistory(this.supplierKey).subscribe((advanceLineHistory: IAdvanceLineData[]) => {
        const advanceLinesTablesData = [];
        advanceLineHistory.forEach((advanceLine) => {
          advanceLine.advances.forEach((advance) => {
            advance.active = advance.active ? 'Yes' : 'No';
          });

          const periods =
            advanceLine.advance_templates && advanceLine.advance_templates.length ? advanceLine.advance_templates[0].periods : null;
          const totalPeriodLength = PeriodsCalculationsService.countTotalLength(periods);
          const totalFee = PeriodsCalculationsService.countTotalFee(periods);
          const averageFee = PeriodsCalculationsService.countAverage(totalPeriodLength, totalFee);
          const expectedDuration =
            advanceLine.advance_templates && advanceLine.advance_templates.length
              ? advanceLine.advance_templates[0].expected_duration
              : null;

          advanceLine['totalPeriodLength'] = totalPeriodLength;
          advanceLine['averageFee'] = averageFee;
          advanceLine['expectedDuration'] = expectedDuration;
          const advanceLineDataObject = {
            advanceLineData: new MatTableDataSource([advanceLine]),
            advanceLineInnerTableData: of({ results: advanceLine.advances }),
            panelExpanded: false,
          };
          advanceLinesTablesData.push(advanceLineDataObject);
        });

        this.advanceLineHistory = advanceLinesTablesData;
      }),
    );
  }

  redirectToAdvanceLineDetails(advanceLineKey: string) {
    this.router.navigate([`./advance-line/${advanceLineKey}`], { relativeTo: this.route.parent });
  }

  onReUnderwriteClick(setting: IAdvanceLineData) {
    this.openAdvanceOfferInputsModal(setting);
  }

  openAdvanceOfferInputsModal(setting: IAdvanceLineData) {
    const requestKey = setting.advance_line_key;
    this.subscriptions.push(
      this.supplierAdvancesService.getAdvanceLineByKey(requestKey).subscribe((offerDetailsData) => {
        if (offerDetailsData) {
          SetAdvanceLineToStore(this.store, offerDetailsData);
        }
      }),
    );

    const dialogRef = this.dialog.open(AdvanceOfferInputsModalComponent, {
      ...DIALOG_SIZE.MEDIUM2,
      data: {
        setting,
        supplierKey: setting.supplier_key,
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((data) => {
        if (data && data.needTableReloading) {
          this.loadData();
        }
      }),
    );
  }
}
