import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ErrorInterface } from '../error-parser/error-interface';
import { ErrorParserService } from '../error-parser/error-parser.service';

@Injectable()
export class ErrorVisualizationService {
  readonly ERROR_SHOW_TIMEOUT: number = 9000;

  constructor(private toastrService: ToastrService, private errorParserService: ErrorParserService) {}

  showError(errorData: ErrorInterface): void {
    this.toastError(this.parseError(errorData));
  }

  private parseError(errorData: ErrorInterface): string {
    return this.errorParserService.parse(errorData);
  }

  private toastError(message: string): void {
    this.toastrService.error(message, null, { timeOut: this.ERROR_SHOW_TIMEOUT });
  }
}
