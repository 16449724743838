import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { isUndefined } from 'util';

import { selectLoggedUserModel } from '../../../../../auth/store/auth.actions';
import { AppConfigService } from '../../../../../config';
import { AppState } from '../../../../../store/app.reducers';
import { AppsDocumentService } from './../../../../../shared/apps-document/apps-document.service';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-action-upload',
  templateUrl: './action-upload.component.html',
})
export class ActionUploadComponent extends SubscriberComponent implements OnInit {
  @Input() uploadField;
  @Output() fileUuid = new EventEmitter<any>();
  @Output() isSpinner = new EventEmitter<boolean>();
  fileUploadForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private appsDocumentService: AppsDocumentService,
    private store: Store<AppState>,
    private appConfig: AppConfigService,
    private toastrService: ToastrService,
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        if (!isUndefined(user)) {
          this.fileUploadForm.patchValue({
            user_login: user.name,
            user_email: user.email,
          });
        }
      }),
    );
  }

  createForm() {
    this.fileUploadForm = this.formBuilder.group({
      file: [''],
      gci: [''],
      wniosekId: [''],
      docType: ['csv'],
      docTypeUuid: [''],
      user_login: [''],
      user_email: [''],
    });
  }

  uploadFile() {
    this.isSpinner.emit(true);
    const dataToSave = this.fileUploadForm.value;
    dataToSave.gci = this.appConfig.env.workflowGciId;
    this.subscriptions.push(
      this.appsDocumentService
        .uploadFile(this.fileUploadForm.value, true)
        .pipe(map((res) => this.appsDocumentService.uploadKycFileMapper(res)))
        .subscribe(
          (response: any) => {
            const b2bUploaded = <any>{ ...response };
            b2bUploaded.doc.docType = dataToSave.docType;
            b2bUploaded.doc.docTypeUuid = dataToSave.docTypeUuid;
            b2bUploaded['user'] = {
              login: dataToSave.user_login,
              email: dataToSave.user_email,
            };
            const eventData = {};
            eventData[this.uploadField.name] = response.doc.uuid;
            this.fileUuid.emit(eventData);
          },
          (error) => {
            this.isSpinner.emit(false);
            if (error.error && error.error.detail) {
              this.toastrService.error(error.error.detail);
            }
          },
        ),
    );
  }
}
