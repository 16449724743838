<article class="tl-item">
  <div class="tl-body">
    <div class="tl-entry">
      <div class="tl-icon btn-icon-round btn-icon btn-icon-thin btn-warning btn-files"><i class="material-icons">attachment</i></div>
      <div class="tl-content">
        <ul class="tl-data-summary">
          <li>
            <i class="material-icons">event_note</i>{{ item.createdAt | amAdd: correctTime(item.createdAt):'minutes' | date: 'short' }}
          </li>
          <li class="pill"><i class="material-icons">folder</i> {{ item.data.doc.docType }}</li>
        </ul>
        <ul class="tl-action-buttons pull-right">
          <li>
            <a disabled matTooltip="Function not avaliable"><i class="material-icons">delete</i></a>
          </li>
        </ul>

        <!--<pre>{{ item |json }}</pre>-->

        <div pyb-b2b-preview [uuid]="item.data.doc.uuid" [filename]="item.data.doc.name" [docType]="item.data.doc.docType">
          <img *ngIf="thumbnail" [src]="thumbnail" />
        </div>
      </div>
    </div>
  </div>
</article>
