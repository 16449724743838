<article class="article">
  <div class="card">
    <div class="card-content">
      <div class="header box box-default">
        <div class="navigation">
          <a [routerLink]="['./../']" title="Go to advances list" class="close-button">
            <i class="material-icons">arrow_back</i>
          </a>
        </div>
        <div class="info">
          <h6>Offer Details</h6>
        </div>
        <div class="actions"></div>
      </div>

      <form [formGroup]="newOfferForm">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput placeholder="Recommended Amount" formControlName="recommended_amount" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <input matInput placeholder="Requested Amount" formControlName="requested_amount" type="number" />
                <mat-error *ngIf="hasRequiredError()">This field is required.</mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <input matInput placeholder="Reserve Total amount" formControlName="reserve_total_amount" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <input matInput placeholder="Extra reserve percent" formControlName="extra_reserve_percent" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <input matInput placeholder="Weekly fee percent" formControlName="weekly_fee_percent" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput placeholder="Net delivery amount (Recommended)" formControlName="net_recommended" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <input matInput placeholder="Net Delivery Amount (Requested)" formControlName="net_requested" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>

              <textarea placeholder="Notes" class="notes" formControlName="request_notes"></textarea>
            </div>
          </div>
          <div class="card-actions">
            <button
              mat-raised-button
              class="accept-button"
              [color]="'accent'"
              (click)="openConfirmation()"
              [disabled]="newOfferForm.invalid"
              tabindex="2"
            >
              Request Offer
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</article>
