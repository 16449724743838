import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { PHONE_NUMBER_SOURCE } from '../../../enums/phone-number-source';
import { SmsNumber, SmsNumberBase, SmsNumbers, SmsNumbersBase, SmsRequestResponse } from './sms-numbers-interfaces';
import { SmsNumbersProviderService } from './sms-numbers-provider.service';

@Component({
  selector: 'app-smsnumbers',
  templateUrl: './smsnumbers.component.html',
  styleUrls: ['./smsnumbers.component.scss'],
})
export class SMSNumbersComponent extends SubscriberComponent implements OnInit {
  @Input() supplierKey: string;
  smsNumbers: SmsNumbers;
  requestNewNumberLoading: boolean;
  numberFromForm: FormGroup;
  fromNumberSource = new FormControl(PHONE_NUMBER_SOURCE.PLIVO);
  numberSource: PHONE_NUMBER_SOURCE = PHONE_NUMBER_SOURCE.PLIVO;

  readonly mobileNumberSources = [PHONE_NUMBER_SOURCE.PLIVO, PHONE_NUMBER_SOURCE.TWILIO];

  readonly displayedInstantPayoutsColumns = ['phoneNumber', 'sid', 'updatedAt', 'source'];
  public dataSourceSmsNumbers: MatTableDataSource<SmsNumber>;

  constructor(private smsNumbersProviderService: SmsNumbersProviderService, private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.numberFromForm = this.formBuilder.group({
      fromNumberSource: [PHONE_NUMBER_SOURCE.PLIVO],
    });

    this.subscriptions.push(
      this.smsNumbersProviderService.getNumbers(this.supplierKey).subscribe((smsNumbers: SmsNumbers) => {
        this.smsNumbers = smsNumbers;
        this.updateNumbersTable();
      }),
    );
  }

  requestNewNumber() {
    this.requestNewNumberLoading = true;
    this.subscriptions.push(
      this.smsNumbersProviderService
        .requestNumber(this.supplierKey, this.numberSource)
        .subscribe((smsRequestResponse: SmsRequestResponse) => {
          if (!this.smsNumbers) {
            this.smsNumbers = new SmsNumbersBase();
          }

          const smsNumber = new SmsNumberBase();
          smsNumber.updateFromSmsRequestResponse(smsRequestResponse);

          const smsNumberToUpdate = smsNumber;
          if (!smsNumberToUpdate.source) {
            smsNumberToUpdate.source = this.numberSource;
          }
          this.smsNumbers.data.push(smsNumberToUpdate);

          this.updateNumbersTable();
          this.requestNewNumberLoading = false;
        }),
    );
  }

  updateNumbersTable() {
    this.dataSourceSmsNumbers = new MatTableDataSource(this.smsNumbers.data);
  }

  numberFromSelected($event) {
    this.numberSource = $event.value;
  }
}
