import { autoserialize } from 'cerialize';

export class DepositAccountModel {
  @autoserialize accountName: string;
  @autoserialize bankName: string;
  @autoserialize currency: string;
  @autoserialize routingNumber: string;
  @autoserialize supplierKey: string;
  @autoserialize accountNumber?: string;
  @autoserialize depositAccountKey?: string;
  @autoserialize autoPostingBlocked?: boolean;

  constructor(
    accountName: string,
    bankName: string,
    currency: string,
    routingNumber: string,
    supplierKey: string,
    depositAccountKey?: string,
    accountNumber?: string,
    autoPostingBlocked?: boolean,
  ) {
    this.accountName = accountName;
    this.bankName = bankName;
    this.currency = currency;
    this.routingNumber = routingNumber;
    this.supplierKey = supplierKey;
    this.depositAccountKey = depositAccountKey;
    this.accountNumber = accountNumber;
    this.autoPostingBlocked = autoPostingBlocked;
  }
}
