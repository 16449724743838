<div class="add-contract-wrapper">
  <h1 mat-dialog-title>{{ actionTitle }}</h1>

  <div *ngIf="rows">
    <app-data-table-grid [rows]="rows" [columnDefs]="columns" [domLayout]="'autoHeight'"></app-data-table-grid>
  </div>

  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Cancel</button>
    <button mat-raised-button (click)="pushToDashboard()" tabindex="2" color="accent">Push to Dashboard</button>
  </div>
</div>
