<article class="article">
  <div class="card">
    <div class="card-content">
      <div class="card-title">Card Transactions</div>

      <div class="row balance">
        <span class="data-item col-sm-2 col-xs-6">
          <span class="value text-primary">
            {{
              creditInfo?.availableBalance && creditInfo?.availableBalance !== 'None'
                ? (creditInfo?.availableBalance | currency)
                : 'Loading...'
            }}
          </span>
          <span class="label text-muted">Available Balance</span>
        </span>
        <span class="data-item col-sm-2 col-xs-6">
          <span class="value text-primary">
            {{
              creditInfo?.arBilledBalance && creditInfo?.arBilledBalance !== 'None'
                ? (creditInfo?.arBilledBalance | currency)
                : 'Loading...'
            }}
          </span>
          <span class="label text-muted">Total Card Spend</span>
        </span>
        <span class="data-item col-sm-2 col-xs-6">
          <span class="value text-primary">
            {{ creditInfo?.creditLimit && creditInfo?.creditLimit !== 'None' ? (creditInfo?.creditLimit | currency) : 'Loading...' }}
          </span>
          <span class="label text-muted">Credit Limit</span>
        </span>
        <span class="data-item col-sm-2 col-xs-6">
          <span class="value text-primary">{{ creditInfo?.lastUsedDate || 'Loading...' }}</span>
          <span class="label text-muted">Last Used Date</span>
        </span>
      </div>

      <div class="row">
        <div class="col-4">
          <mat-form-field class="full-width">
            <input matInput placeholder="Percentage" [(ngModel)]="efsRebatePercent" type="number" />
            <button mat-button color="primary" class="btn-sm" matSuffix (click)="updateEFSRebate()">Save</button>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="full-width">
            <mat-select placeholder="Show Data from Last" [(ngModel)]="time" (selectionChange)="loadData()">
              <mat-option *ngFor="let timeOpt of times" [value]="timeOpt"> {{ timeOpt }} days </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4">
          <button mat-raised-button color="primary" matSuffix (click)="exportCurrentData()">Export Current</button>
          <button mat-raised-button color="primary" matSuffix (click)="exportAllData()">Export All</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h6>Captured Transactions</h6>
          <div *ngIf="efsPaymentsData && !efsPaymentsDataLoading; else loadingCaptured">
            <app-data-table-grid [rows]="efsPaymentsData" [columnDefs]="efsPaymentsSettings" [domLayout]="'autoHeight'">
            </app-data-table-grid>
          </div>
          <ng-template #loadingCaptured>
            <app-loading-spinner [size]="2"></app-loading-spinner>
          </ng-template>
        </div>

        <div class="col-12">
          <h6>Pending Transaction Deliveries</h6>
          <div *ngIf="pendingTrasactionDeliveriesData && !pendingTrasactionDeliveriesDataLoading; else loadingPendingTransactions">
            <app-data-table-grid
              [rows]="pendingTrasactionDeliveriesData"
              [columnDefs]="pendingTrasactionDeliveriesSettings"
              [domLayout]="'autoHeight'"
            >
            </app-data-table-grid>
          </div>
          <ng-template #loadingPendingTransactions>
            <app-loading-spinner [size]="2"></app-loading-spinner>
          </ng-template>
        </div>

        <div class="col-12">
          <h6>Reversed Transactions</h6>
          <div *ngIf="clearedTrasactionDeliveriesData && !clearedTrasactionDeliveriesDataLoading; else loadingReverseTransactions">
            <app-data-table-grid
              [rows]="clearedTrasactionDeliveriesData"
              [columnDefs]="clearedTrasactionDeliveriesSettings"
              [domLayout]="'autoHeight'"
            >
            </app-data-table-grid>
          </div>
          <ng-template #loadingReverseTransactions>
            <app-loading-spinner [size]="2"></app-loading-spinner>
          </ng-template>
        </div>

        <div class="col-12">
          <h6>Rejected Transaction Deliveries</h6>
          <div *ngIf="rejectedTrasactionDeliveriesData && !rejectedTrasactionDeliveriesDataLoading; else loadingRejectedTransactions">
            <app-data-table-grid
              [rows]="rejectedTrasactionDeliveriesData"
              [columnDefs]="rejectedTrasactionDeliveriesSettings"
              [domLayout]="'autoHeight'"
            >
            </app-data-table-grid>
          </div>
          <ng-template #loadingRejectedTransactions>
            <app-loading-spinner [size]="2"></app-loading-spinner>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-action-row" *ngIf="!isProduction()">
    <button mat-raised-button color="primary" (click)="pendingTransaction()">Pending Transaction</button>
    <button mat-raised-button color="primary" (click)="rejectedTransaction()">Rejected Transaction</button>
    <button mat-raised-button color="primary" (click)="chargeTransaction()">Charge Transaction</button>
    <button mat-raised-button color="primary" (click)="reversalTransaction()">Reversal Transaction</button>
    <button mat-raised-button color="primary" (click)="refundTransaction()">Refund Transaction</button>
  </div>
</article>
