import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { SubscriberComponent } from './../../../../../../shared/component-subscriber/subscriber.component';
import { SupplierService } from './../../../../../services/supplier.service';
import { PayoffType } from './pay-advance.common';

@Component({
  selector: 'app-pay-advance',
  templateUrl: './pay-advance.component.html',
})
export class PayAdvanceComponent extends SubscriberComponent implements OnInit {
  payoffType: PayoffType = PayoffType.outstandingBalance;
  payoffAmount: number = null;
  payoffTypeEnum: any = PayoffType;

  private supplierKey: string;
  private advanceKey: string;
  private isOpperationSubmited = false;
  private isOpperationConfirmed = false;
  public writeOff = false;

  get invalid(): boolean {
    return this.payoffType === PayoffType.ammount && isNullOrUndefined(this.payoffAmount) ? true : false;
  }

  get disabledAll(): boolean {
    return this.showConfirmationScreen;
  }

  get disabledInput(): boolean {
    return this.payoffType === PayoffType.outstandingBalance ? true : false;
  }

  get connfirmationInfo(): string {
    return this.payoffType === PayoffType.outstandingBalance
      ? "This action will reduce the Supplier's all available balance."
      : `This action will reduce the Supplier's available balance by ${this.payoffAmount}.`;
  }

  get showEditionScreen(): boolean {
    return !this.isOpperationSubmited && !this.isOpperationConfirmed;
  }

  get showConfirmationScreen(): boolean {
    return this.isOpperationSubmited && !this.isOpperationConfirmed;
  }

  constructor(
    private service: SupplierService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<PayAdvanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();

    if (!isNullOrUndefined(data)) {
      this.supplierKey = data.supplierKey;
      this.advanceKey = data.advanceKey;
    }
  }

  ngOnInit(): void { }

  disableWriteOff(): boolean {
    if (this.payoffType !== PayoffType.outstandingBalance) {
      this.writeOff = false;
      return true;
    }
    return false;
  }

  cancelOnClick(): void {
    this.closeDialog();
  }

  confirmOnClick(): void {
    if (!this.invalid) {
      const data = {
        type: 'payoff',
        payoff_amount: this.payoffAmount,
        writeoff_advance: this.writeOff,
      };

      this.subscriptions.push(
        this.service.payAdvance(this.supplierKey, this.advanceKey, data).subscribe(
          () => {
            this.toastrService.success('Supplier available balance was reduced.');
            this.closeDialog({ success: true });
          },
          (err) => {
            const error_message: string = err.status === 500 ? err.message : err.error?.message;
            this.toastrService.error(error_message, null, { timeOut: 3000 });
          },
        ),
      );
    }
  }

  submitOnClick(): void {
    this.isOpperationSubmited = true;
  }

  denyOnClick(): void {
    this.isOpperationSubmited = false;
  }

  private closeDialog(payload?: any): void {
    this.dialogRef.close(payload);
  }
}
