<div>
  <div class="page-header">
    <div>
      <h4>QA Eva</h4>
    </div>
  </div>
</div>

<div class="eva">
  <app-eva [url]="'qa'" #appEva></app-eva>
</div>
