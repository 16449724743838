import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { AppConfigService } from '../../../../../config';
import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { ErrorVisualizationService } from '../../../../../shared/services/error-visualization/error-visualization.service';
import { AppState } from '../../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { FlexParticipationService } from './service/flex-participation.service';
import { IFlexParticipation } from './service/flex-participation-interfaces';
import { ProductMatrixService } from './service/product-matrix.service';
import { IProducts } from './service/product-matrix-interfaces';

@Component({
  selector: 'app-service-table',
  templateUrl: './service-table.component.html',
  styleUrls: ['./service-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ServiceTableComponent extends SubscriberComponent implements OnInit {
  public supplierKey: string;
  public response: IProducts;
  public flexDto: IFlexParticipation;

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly route: ActivatedRoute,
    private readonly productService: ProductMatrixService,
    private readonly flexService: FlexParticipationService,
    private readonly errorVisualizationService: ErrorVisualizationService,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;
          this.getProducts();
        }),
    );
  }

  private getProducts(): void {
    this.subscriptions.push(
      this.productService.getProducts(this.supplierKey).subscribe(
        (response: IProducts) => {
          this.response = response;
          if (this.whenInstantAccessIsIn(response)) {
            this.getFlexParticipation();
          }
        },
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Services Error', errorObject: err });
        },
      ),
    );
  }

  private whenInstantAccessIsIn(response: IProducts) {
    return !!response.instant_access;
  }

  private getFlexParticipation(): void {
    this.subscriptions.push(
      this.flexService.getEstimations(this.supplierKey).subscribe(
        (response: IFlexParticipation) => {
          this.flexDto = response;
        },
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Services Error', errorObject: err });
        },
      ),
    );
  }
}
