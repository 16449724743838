import { SearchResultMarketplace, SearchResultPerson, SearchResultSalesRep, SearchSingleResult } from './search-single-result';

export class SearchSingleResultModel implements SearchSingleResult {
  cancellationStatus: string;
  dealInfo: string;
  ein: string;
  email: string;
  firstName: string;
  lastName: string;
  legalName: string;
  marketplaces: SearchResultMarketplace[];
  onboardingStatus: boolean;
  payabilityCardAvailable: boolean;
  payabilityStatus: string;
  persons: SearchResultPerson[];
  phone: string;
  salesRep: SearchResultSalesRep;
  ssn: string;
  supplierKey: string;

  supplierKey_displayed?: string;
  legalName_displayed?: string;
  firstName_displayed?: string;
  lastName_displayed?: string;
  email_displayed?: string;
  phone_displayed?: string;
  ssn_displayed?: string;
  ein_displayed?: string;

  constructor(params: SearchSingleResult) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        this[key] = params[key];
      }
    }
  }
}
