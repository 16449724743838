import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import { AppState } from '../../store/app.reducers';
import { CrmItem } from '../models/crm-item.type';
import { FetchCrmPinnedNotes, PinCrmNote, selectCrmItems, selectCrmPinnedNotes } from '../store/crm.actions';
import { CrmItemCollection } from '../store/crm.reducers';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-actions-list-pinned',
  templateUrl: './actions-list-pinned.component.html',
  styleUrls: ['./actions-list-pinned.component.scss'],
})
export class ActionsListPinnedComponent extends SubscriberComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public subscriptions: Subscription[] = [];
  public pinnedNotesUuids: string[];
  public crmItems: CrmItemCollection;
  noteCount: number;
  currentNote: CrmItem;
  loading = false;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchCrmPinnedNotes());

    this.subscriptions.push(
      combineLatest([this.store.select(selectCrmItems), this.store.select(selectCrmPinnedNotes)]).subscribe(
        ([crmItems, crmPinnedNotes]) => {
          if (crmItems && crmPinnedNotes.length) {
            this.loading = false;
            this.crmItems = crmItems;
            this.pinnedNotesUuids = crmPinnedNotes;

            this.noteCount = crmPinnedNotes.length;

            this.currentNote = this.getPinnedNote(0);
            if (this.paginator) {
              this.paginator.pageIndex = 0;
            }
            return;
          }
          this.currentNote = null;
        },
      ),
    );
  }

  onPageChange(event: PageEvent) {
    this.currentNote = this.getPinnedNote(event.pageIndex);
  }

  getPinnedNote(index: number) {
    return this.crmItems[this.pinnedNotesUuids[index]];
  }

  onUnpinNote() {
    this.loading = true;
    this.store.dispatch(new PinCrmNote({ uuid: this.currentNote.uuid, data: { isPinned: false } }));
  }
}
