export class UwReviewModel {
  fullNameVerified: boolean;
  ssnVerified: boolean;
  dobVerified: boolean;
  residentialAddressVerified: boolean;
  ofacCheckCompleted: boolean;
  businessOfacCheckCompleted: boolean;
  businessAddressVerified: boolean;
  legalEntityVerified: boolean;
  tinVerified: boolean;

  constructor(
    MARQETA_KYC_FULL_NAME?: boolean,
    MARQETA_KYC_SSN?: boolean,
    MARQETA_KYC_DOB?: boolean,
    MARQETA_KYC_RESIDENTAL_ADDRESS?: boolean,
    MARQETA_KYC_OFAC_CHECK?: boolean,
    MARQETA_KYC_BUSINESS_OFAC_CHECK?: boolean,
    MARQETA_KYC_BUSINESS_ADDRESS?: boolean,
    MARQETA_KYC_LEGAL_ENTITY?: boolean,
    MARQETA_KYC_TIN?: boolean,
  ) {
    this.fullNameVerified = MARQETA_KYC_FULL_NAME;
    this.ssnVerified = MARQETA_KYC_SSN;
    this.dobVerified = MARQETA_KYC_DOB;
    this.residentialAddressVerified = MARQETA_KYC_RESIDENTAL_ADDRESS;
    this.ofacCheckCompleted = MARQETA_KYC_OFAC_CHECK;
    this.businessOfacCheckCompleted = MARQETA_KYC_BUSINESS_OFAC_CHECK;
    this.businessAddressVerified = MARQETA_KYC_BUSINESS_ADDRESS;
    this.legalEntityVerified = MARQETA_KYC_LEGAL_ENTITY;
    this.tinVerified = MARQETA_KYC_TIN;
  }
}
