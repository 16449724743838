import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { FEATURE_FLAGS } from '../../../../shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from '../../../../shared/feature-flags/feature-flags.service';
import { AppState } from '../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { SupplierBalanceModel } from '../../../model/supplier-balance.model';
import { selectPaymentMethods, selectSupplierBalance, selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { USER_PERMISSION } from './../../../../shared/users/user-permissions.enum';

export const CANCELLATION_STATUSES = ['Pending', 'Canceled', 'Collections', 'Inactive', 'None'];

@Component({
  selector: 'app-supplier-box-header-old',
  templateUrl: './supplier-box-header.component.html',
  styleUrls: ['./supplier-box-header.component.scss'],
})
export class SupplierBoxHeaderOldComponent extends SubscriberComponent implements OnInit {
  readonly userPermission = USER_PERMISSION;

  selectSupplierDetails$: Observable<SupplierDetailsModel>;
  selectSupplierBalance$: Observable<SupplierBalanceModel>;

  public supplierKey: string;
  public supplierDetailsModel: SupplierDetailsModel;
  public isOpen: boolean;
  public isEFS = false;
  public isAmazonLending: any = false;
  public activeIndex;
  readonly defaultNavLinks = [
    { route: 'general', label: 'General' },
    { route: 'summary', label: 'Summary' },
    { route: 'onboarding', label: 'Onboarding' },
    { route: 'new-underwriting', label: 'Underwriting β' },
    { route: 'underwriting', label: 'Underwriting' },
    { route: 'payment/list', label: 'Payment Accounts' },
    { route: 'fees', label: 'Fees' },
    { route: 'marketplaces', label: 'Marketplaces' },
    { route: 'statements', label: 'Marketplace Statements' },
    { route: 'reports', label: 'Reports' },
    { route: 'transaction-summary', label: 'Transaction Summary' },
    { route: 'transaction-details', label: 'Transaction Details' },
    { route: 'receivables', label: 'Receivables' },
    { route: 'advances', label: 'Advances' },
    { route: 'contracts', label: 'Access+' },
  ];
  public navLinks: any[];
  public hasRestrictedAccessPermission = false;

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly platformLocation: PlatformLocation,
    private readonly route: ActivatedRoute,
    private readonly permissionsService: NgxPermissionsService,
    private readonly featureFlagsService: FeatureFlagsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.hasRestrictedUWAccessPermission();
    this.setSupplierKey();
    this.checkNavigation();
    this.setSupplierData();
    this.selectPaymentMethods();
  }

  resolveNavigation(): void {
    if (this.hasRestrictedAccessPermission) {
      this.navLinks = [{ route: 'underwriting', label: 'Underwriting' }];
    } else {
      const newNavLinks = [...this.defaultNavLinks];

      if (this.isEFS || (this.supplierDetailsModel && this.supplierDetailsModel.pcardAvailable)) {
        newNavLinks.push({ route: 'card-transactions/marqeta', label: 'Seller Card' });
      }

      if (this.isAmazonLending) {
        newNavLinks.push({ route: 'amazon-lending/list', label: 'Amazon Lending' });
      }

      if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.SUPPLIER_ACCOUNT_HEALTH)) {
        newNavLinks.push({ route: 'health', label: 'Account Health' });
      }

      this.navLinks = newNavLinks;
    }

    const INDEX_AFTER_SUPPLIER_KEY = 3;
    const routeAfterSupplierKey = this.platformLocation.hash.split('/').slice(INDEX_AFTER_SUPPLIER_KEY);

    this.activeIndex =
      this.navLinks.findIndex((nav) => {
        const navFirstElement = nav.route.split('/')[0];
        return routeAfterSupplierKey.find((route) => route === navFirstElement);
      }) || 0;
  }

  selectSupplierDetails(): void {
    this.subscriptions.push(
      this.selectSupplierDetails$.subscribe((supplierDetailsModel: SupplierDetailsModel) => {
        this.supplierDetailsModel = supplierDetailsModel;

        if (supplierDetailsModel) {
          this.isAmazonLending = supplierDetailsModel.isAmazonSeller > 0;
          this.resolveNavigation();
        }
      }),
    );
  }

  checkNavigation(): void {
    this.resolveNavigation();
    this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe(() => {
      this.resolveNavigation();
    });
  }

  selectPaymentMethods(): void {
    this.subscriptions.push(
      this.store.select(selectPaymentMethods).subscribe((paymentConfigs) => {
        if (paymentConfigs) {
          const payments = Object.keys(paymentConfigs).map((item) => paymentConfigs[item].paymentType);
          this.isEFS = payments.indexOf('EFS') > -1;
          this.resolveNavigation();
        }
      }),
    );
  }

  setSupplierData(): void {
    this.selectSupplierDetails$ = this.store.select(selectSupplierDetails);
    this.selectSupplierBalance$ = this.store.select(selectSupplierBalance);
    this.selectSupplierDetails();
  }

  private setSupplierKey(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        if (this.supplierKey !== params['supplierKey']) {
          this.supplierKey = params['supplierKey'];
        }
      }),
    );
  }

  private hasRestrictedUWAccessPermission() {
    const permission = this.permissionsService.getPermission('REST_UW_ACCESS');
    this.hasRestrictedAccessPermission = !!permission;
  }
}
