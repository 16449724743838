import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Deserialize, DeserializeKeysFrom, Serialize } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '../../../config';
import { PaymentConfigModel } from '../../model/payment-config.model';
import { PaymentConfigServiceInterface } from './payment-config-service-interface';

@Injectable()
export class PaymentConfigService implements PaymentConfigServiceInterface {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
  }

  query(supplierKey: string): Observable<PaymentConfigModel[]> {
    return this.httpClient
      .get<PaymentConfigModel[]>(`${this.apiUrl}suppliers/${supplierKey}/payment/configs`, {
        observe: 'body',
        responseType: 'json',
      })
      .pipe(
        map((paymentConfigs) =>
          paymentConfigs.map((item) => {
            DeserializeKeysFrom(CamelCase);
            return Deserialize(item, PaymentConfigModel);
          }),
        ),
      );
  }

  get(supplierKey: string, paymentConfigKey: string): Observable<PaymentConfigModel> {
    return this.httpClient
      .get<PaymentConfigModel>(`${this.apiUrl}suppliers/${supplierKey}/payment/configs/${paymentConfigKey}`, {
        observe: 'body',
        responseType: 'json',
      })
      .pipe(
        map((paymentConfig) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(paymentConfig, PaymentConfigModel);
        }),
      );
  }

  create(supplierKey: string, data: PaymentConfigModel): Observable<PaymentConfigModel> {
    return this.httpClient.post<PaymentConfigModel>(`${this.apiUrl}suppliers/${supplierKey}/payment/configs`, Serialize(data)).pipe(
      map((paymentConfig) => {
        DeserializeKeysFrom(CamelCase);
        return Deserialize(paymentConfig, PaymentConfigModel);
      }),
    );
  }

  /* eslint-disable */
  save(supplierKey: string, data: PaymentConfigModel): Observable<PaymentConfigModel> {
    return this.httpClient
      .put<PaymentConfigModel>(`${this.apiUrl}suppliers/${supplierKey}/payment/configs/${data.paymentConfigKey}`, Serialize(data))
      .map((paymentConfig) => {
        DeserializeKeysFrom(CamelCase);
        return Deserialize(paymentConfig, PaymentConfigModel);
      });
  }

  /* eslint-enable */
  remove(supplierKey: string, paymentConfigKey: string) {
    return this.httpClient.delete<PaymentConfigModel>(`${this.apiUrl}suppliers/${supplierKey}/payment/configs/${paymentConfigKey}`);
  }

  pendingTransactions(supplierKey: string) {
    return this.httpClient.post(`${this.apiUrl}suppliers/${supplierKey}/jit/simulate/pending`, null);
  }

  rejectedTransactions(supplierKey: string) {
    return this.httpClient.post(`${this.apiUrl}suppliers/${supplierKey}/jit/simulate/rejected`, null);
  }

  chargeTransactions(supplierKey: string) {
    return this.httpClient.post(`${this.apiUrl}suppliers/${supplierKey}/jit/simulate/charge`, null);
  }

  reversalTransactions(supplierKey: string) {
    return this.httpClient.post(`${this.apiUrl}suppliers/${supplierKey}/jit/simulate/cleared`, null);
  }

  refundTransactions(supplierKey: string) {
    return this.httpClient.post(`${this.apiUrl}suppliers/${supplierKey}/jit/simulate/refund`, null);
  }

  getCardTransactionsConfig(supplierKey: string) {
    return this.httpClient.get(`${this.apiUrl}suppliers/${supplierKey}/velocity/limits`);
  }

  updateCardTransactionsConfig(supplierKey: string, configObject) {
    return this.httpClient.put(`${this.apiUrl}suppliers/${supplierKey}/velocity/limits`, configObject);
  }
}
