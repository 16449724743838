<h1 mat-dialog-title>Are you sure you would like to log supplier-saved reasons?</h1>
<div class="row">
  <div class="col-6">
    <div class="prop">
      <label>Saved Marketplaces:</label>
      <span>{{ marketplacesTxt }}</span>
    </div>
    <div class="prop">
      <label>Saved Products:</label>
      <span>{{ productsTxt }}</span>
    </div>
    <div class="prop">
      <label>Sales Rep:</label>
      <span>{{ salesRep }}</span>
    </div>
  </div>
  <div class="col-6">
    <div class="prop">
      <label>Reason:</label>
      <span>{{ reason }}</span>
      <div *ngIf="reason_description" class="font-weight-bold">{{ reason_description }}</div>
    </div>
  </div>
</div>
<div class="row justify-content-md-center">
  <div class="col-md-8">
    <div class="d-flex justify-content-space-evenly">
      <button mat-raised-button (click)="onCancel()" class="cancel-btn" tabindex="-1" color="warn">Go Back</button>
      <button mat-raised-button (click)="onConfirm()" tabindex="2" color="accent">Confirm</button>
    </div>
  </div>
</div>
