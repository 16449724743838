<h1 mat-dialog-title class="text-center">Report Business Check summary</h1>
<div mat-dialog-content>
  <div *ngFor="let report of businessData?.BusinessComboSearchOutputRecords?.TLOBusinessComboSearchOutputRecord">
    <div *ngIf="report?.RecordType === 'CorporateFiling'">
      <h4>Corporate Filing report summary</h4>
      <div class="row">
        <div class="col-xs-6">
          <strong>Business name:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.BusinessName || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Business name type:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.BusinessNameType || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Business duns number:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.BusinessNameDunsNumber || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Registration type:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.RegistrationType || '-' }}
        </div>
        <div class="col-xs-6" *ngIf="!report?.CorporateFiling?.FileDate?.Day">-</div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Corporation type:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.CorporationType || '-' }}
        </div>
        <div class="col-xs-6" *ngIf="!report?.CorporateFiling?.FileDate?.Day">-</div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Incorporation state:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.IncorporationState || '-' }}
        </div>
        <div class="col-xs-6" *ngIf="!report?.CorporateFiling?.FileDate?.Day">-</div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing number:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingNumber || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing office name:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingOfficeName || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing office DUNS number:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingOfficeDunsNumber || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>File date:</strong>
        </div>
        <div class="col-xs-6" *ngIf="report?.CorporateFiling?.FileDate?.Day">
          {{
            report?.CorporateFiling?.FileDate?.Day +
              '/' +
              report?.CorporateFiling?.FileDate?.Month +
              '/' +
              report?.CorporateFiling?.FileDate?.Year
          }}
        </div>
        <div class="col-xs-6" *ngIf="!report?.CorporateFiling?.FileDate?.Day">-</div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing date:</strong>
        </div>
        <div class="col-xs-6">
          {{
            report?.CorporateFiling?.FilingDate?.Day +
              '/' +
              report?.CorporateFiling?.FilingDate?.Month +
              '/' +
              report?.CorporateFiling?.FilingDate?.Year
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing Office City:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingOfficeAddress?.City || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing Office County:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingOfficeAddress?.County || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing Office Line:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingOfficeAddress?.Line1 || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing Office State:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingOfficeAddress?.State || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing Office Zip:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingOfficeAddress?.Zip || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Filing Office Zip4:</strong>
        </div>
        <div class="col-xs-6">
          {{ report?.CorporateFiling?.FilingOfficeAddress?.Zip4 || '-' }}
        </div>
      </div>
    </div>
    <hr />
  </div>

  <div *ngIf="businessData?.BusinessComboSearchOutputRecords?.TLOBusinessComboSearchOutputRecord[0]?.RecordType !== 'CorporateFiling'">
    <h4>Business report summary</h4>

    <table class="table table-hover">
      <thead>
        <tr>
          <th>Property</th>
          <th width="20%">Value</th>
          <th width="20%">Decision</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Lien debt or count</td>
          <td>
            {{
              $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
                ?.LienDebtorCount || '-'
            }}
          </td>
          <td>
            {{
              $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
                ?.LienDebtorCount > 0
                ? 'Needs review'
                : 'Ok'
            }}
          </td>
        </tr>
        <tr>
          <td>Lien holder count</td>
          <td>
            {{
              $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
                ?.LienHolderCount || '-'
            }}
          </td>
          <td>
            {{
              $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
                ?.LienHolderCount > 0
                ? 'Needs review'
                : 'Ok'
            }}
          </td>
        </tr>
        <tr>
          <td>Bankruptcy subject count</td>
          <td>
            {{
              $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
                ?.BankruptcySubjectCount || '-'
            }}
          </td>
          <td>
            {{
              $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
                ?.BankruptcySubjectCount > 0
                ? 'Needs review'
                : 'Ok'
            }}
          </td>
        </tr>
        <tr>
          <td>Bankruptcy creditor count</td>
          <td>
            {{
              $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
                ?.BankruptcyCreditorCount || '-'
            }}
          </td>
          <td>
            {{
              $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
                ?.BankruptcyCreditorCount > 0
                ? 'Needs review'
                : 'Ok'
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <hr />
    <h4
      *ngIf="
        $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]?.BusinessContacts
          ?.TLOBusinessContacts?.length
      "
    >
      Business Contacts ({{
        $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]?.BusinessContacts
          ?.TLOBusinessContacts?.length
      }})
    </h4>
    <div
      *ngFor="
        let contact of $ctrl?.transUnion?.results?.business?.company?.BusinessComboSearchOutputRecords?.TLOBusinessSearchOutputRecord[0]
          ?.BusinessContacts?.TLOBusinessContacts
      "
    >
      <h3>
        {{ $index + 1 }}?. {{ contact?.Name?.Title }} {{ contact?.Name?.FirstName }} {{ contact?.Name?.MiddleName }}
        {{ contact?.Name?.LastName }}
      </h3>

      <div class="row">
        <div class="col-xs-6">
          <strong>Title:</strong>
        </div>
        <div class="col-xs-6">
          {{ contact?.Title || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Contact type:</strong>
        </div>
        <div class="col-xs-6">
          {{ contact?.ContactType || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Contact type:</strong>
        </div>
        <div class="col-xs-6">
          {{ contact?.ContactType || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>City:</strong>
        </div>
        <div class="col-xs-6">
          {{ contact?.Address?.City || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>County:</strong>
        </div>
        <div class="col-xs-6">
          {{ contact?.Address?.County || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Line:</strong>
        </div>
        <div class="col-xs-6">
          {{ contact?.Address?.Line1 || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>State:</strong>
        </div>
        <div class="col-xs-6">
          {{ contact?.Address?.State || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <strong>Zip:</strong>
        </div>
        <div class="col-xs-6">
          {{ contact?.Address?.Zip || '-' }}
        </div>
      </div>
    </div>
  </div>
</div>
