import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { DefaultRadiobuttons, NoDataText } from '../common/consts/underwritings-initial-constants';

@Component({
  selector: 'app-amazon-advertising',
  templateUrl: './amazon-advertising.component.html',
  styleUrls: ['./amazon-advertising.component.scss'],
})
export class AmazonAdvertisingComponent implements OnChanges {
  @Input() amazonAdvertisingOverride: any;
  @Input() amazonAdvertising: any;
  @Input() amazonHealth: any;
  @Input() amazonAdvertisings: any;
  @Input() computingDataIsInProgress: boolean;

  @Output() onAmazonAdvertisingOverride = new EventEmitter();

  headerType: UnderwritingInitialCardHeaderStatusType;
  headerStatus: string;
  radiobuttons = DefaultRadiobuttons;
  noDataText = NoDataText;

  dangerHeaderLines = [
    'Yes - spend greater than 5% of revenue, increase reserve by 5% or switch payments to CC',
    'Yes - spend is greater than 10% of revenue, increase reserve by 10% or switch payments to CC)',
  ];

  successHeaderLines = ['No', 'Yes - no action required'];

  ngOnChanges(changes) {
    this.setHeaderDetails();
  }

  setHeaderDetails() {
    if (this.isSuccessHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.SUCCESS;
      this.headerStatus = 'Valid';
    }
    if (this.isDangerHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.DANGER;
      this.headerStatus = 'Needs review';
    }
  }

  isSuccessHeaderType() {
    return (
      this.amazonAdvertisingOverride[0].value ||
      (!this.amazonAdvertisingOverride[0].value &&
        (this.amazonAdvertising === this.successHeaderLines[0] || this.amazonAdvertising === this.successHeaderLines[1]))
    );
  }

  isDangerHeaderType() {
    return (
      !this.amazonAdvertisingOverride[0].value &&
      (this.amazonAdvertising === this.dangerHeaderLines[0] || this.amazonAdvertising === this.dangerHeaderLines[1])
    );
  }

  radiobuttonsChange(value) {
    if (!this.amazonAdvertisingOverride[0]) {
      return;
    }
    this.amazonAdvertisingOverride[0].value = value;
    this.onAmazonAdvertisingOverride.emit(this.amazonAdvertisingOverride);
  }
}
