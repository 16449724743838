import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ConfigsService } from '../../../shared/configs/service/configs.service';
import { BaseSupplierAction } from '../../../suppliers/supplier/base-supplier-action/base-supplier-action';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-delete-email-setting',
  templateUrl: './delete-email-setting.component.html',
})
export class DeleteEmailSettingComponent extends SubscriberComponent {
  confirmationMessage = 'Are you sure you want to delete item? This can break functionality in other places.';
  isLoadingResult = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BaseSupplierAction>,
    private configsService: ConfigsService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  submit() {
    this.isLoadingResult = true;

    this.subscriptions.push(
      this.configsService.deleteEmailSetting(this.data.configKey).subscribe(
        (success) => {
          this.toastrService.success('Email setting successfully deleted');
          this.closeDialog(true);
        },
        (error) => {
          this.toastrService.error(error.error.meta.errors[0].message);
          this.closeDialog(false);
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }
}
