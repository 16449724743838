export enum LOADED_STATE {
  NONE,
  FIRST_PAGE,
  ALL,
  ERROR,
}

export interface FetchState {
  loadedState: LOADED_STATE;
  lastItem: any;
}

export interface RefreshTasks {
  timestamp: string;
  first: boolean;
}

export interface Filters {
  assignee:
    | undefined
    | {
        key: string;
        type: string;
      };
  owner_uuid?: undefined | string;
  createdAfter: undefined | Date;
  createdBefore: undefined | Date;
  priority: undefined | string[];
  status: undefined | string[];
  taskId: undefined | string;
  author?: undefined | string;
}
