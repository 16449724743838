<h1 mat-dialog-title>Email Preview</h1>

<div class="row">
  <div class="col-md-8 email-summary">
    <div class="row" *ngIf="data.created_by_name">
      <span class="col-sm-2">Sender: </span>
      <span class="col-sm-10"
        ><strong>{{ data.created_by_name }}</strong></span
      >
    </div>
    <div class="row" *ngIf="data.source">
      <span class="col-sm-2">From: </span>
      <span class="col-sm-10"
        ><strong
          ><a href="mailto: {{ data.source }}">{{ data.source }}</a></strong
        ></span
      >
    </div>
    <div class="row" *ngIf="data.to">
      <span class="col-sm-2">To: </span>
      <span class="col-sm-10" *ngIf="data.supplierName">
        <strong
          ><a href="mailto: {{ data.to }}">{{ data.supplierName }} <{{ data.to }}></a></strong
        >
      </span>
      <span class="col-sm-10" *ngIf="!data.supplierName">
        <strong *ngFor="let email of data.to"
          ><a href="mailto: {{ email }}">{{ email }}</a></strong
        >
      </span>
    </div>
    <div class="row">
      <span class="col-sm-2 subject">Subject: </span>
      <span class="col-sm-10">{{ data.subject }}</span>
    </div>
  </div>

  <div class="col-md-4" *ngIf="data.target_uuid">
    <div>
      <span>
        Supplier key: <br />
        <small>{{ data.target_uuid }}</small>
      </span>
    </div>

    <div>
      <a mat-raised-button color="accent" target="_blank" [href]="supplierPageUrl()">Open supplier</a>
      <a mat-raised-button ngxClipboard [cbContent]="data.target_uuid" color="primary">Copy Supplier Key</a>
    </div>
  </div>
</div>

<div class="p2 text-center" *ngIf="data.message">
  <small
    ><a (click)="toggleTextVersion()">(show {{ showTextVersion ? 'html' : 'text' }} version)</a></small
  >
</div>

<div class="message-body-wrapper" *ngIf="!showTextVersion; else textVersion" [innerHtml]="messageHtml | safe"></div>

<ng-template #textVersion>{{ data.message }}</ng-template>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="sendEmail()" [color]="'accent'" tabindex="2">Send email</button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'" tabindex="-1">Close</button>
</div>
