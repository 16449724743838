import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line
  selector: '[app-confirm-delete]', // ts-enable-line
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent implements OnInit {
  @Input() closeOnConfirm = false;
  @Input() icon: string;
  @Output() onCancel = new EventEmitter();
  @Output() onConfirm = new EventEmitter();

  open = false;

  constructor(private element: ElementRef) {
    this.element.nativeElement.click = this.openOption;
  }

  confirm() {
    this.onConfirm.emit();
    if (this.closeOnConfirm) {
      this.open = false;
    }
  }

  cancel() {
    this.onCancel.emit();
    this.open = false;
  }

  ngOnInit() {
    if (!this.icon) {
      this.icon = 'delete';
    }
  }

  openOption() {
    this.open = true;
  }
}
