<mat-card class="productInstantPayouts__matCard">
  <div class="productInstantPayouts__matCard__title">
    <h5>Instant Payouts</h5>
    <div>
      <p [ngStyle]="{ color: productStatusColor }">{{ productStatus | uppercase }}</p>
    </div>
  </div>
  <mat-card-content>
    <mat-table *ngIf="dataSource.data.length" class="productInstantPayouts__matCard__table" [dataSource]="dataSource">
      <ng-container matColumnDef="marketplace">
        <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantPayouts__matCard__table__cell">
          {{ element.marketplace }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mpStatement">
        <mat-header-cell *matHeaderCellDef>MP Statement</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productInstantPayouts__matCard__table__cell">
          {{ element.mpStatement }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="uwStatus">
        <mat-header-cell *matHeaderCellDef>UW Status</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productInstantPayouts__matCard__table__cell">
          <mat-select
            class="productInstantPayouts__matCard__table__cell__status-{{ getUnderwritingStatusColor(element.uwStatus) }}"
            [(ngModel)]="element.uwStatus"
            (selectionChange)="uwStatusUpdateRequest($event, i)"
          >
            <mat-option *ngFor="let status of uwStatuses" [value]="status">{{ status | capitalize }}</mat-option>
          </mat-select>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <mat-header-cell *matHeaderCellDef>Enabled</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productInstantPayouts__matCard__table__cell">
          <!--          <mat-checkbox [(ngModel)]="element.enabled" (change)="activationProductRequest($event, i)"></mat-checkbox>-->
          <mat-slide-toggle [(ngModel)]="element.enabled" (change)="activationProductRequest($event, i)"></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="serviceColumns" class="productInstantPayouts__matCard__table__headerRow"></mat-header-row>
      <mat-row *matRowDef="let row; columns: serviceColumns" class="productInstantPayouts__matCard__table__row"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
