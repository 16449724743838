import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { EmailModel } from '../../models/email.model';
import { EmailsService } from '../../services/emails.service';
import { EmailPreviewComponent } from '../email-preview/email-preview.component';

@Component({
  selector: 'app-email-item',
  templateUrl: './email-item.component.html',
  styleUrls: ['../../shared/timeline-item.scss', './email-item.component.scss'],
})
export class EmailItemComponent implements OnInit {
  @Input() item: EmailModel;
  currentStatus: any;

  private STATUSES = {
    sent: {
      className: 'pill-success',
      icon: 'done',
    },
    canceled: {
      className: 'pill-danger',
      icon: 'close',
    },
    pending: {
      className: 'pill-warning',
      icon: 'query_builder',
    },
    sending: {
      icon: 'query_builder',
      className: 'pill-warning',
    },
  };

  constructor(public dialog: MatDialog, private emailsService: EmailsService) {}

  ngOnInit() {
    this.currentStatus = this.resolveStatus();
  }

  resolveStatus() {
    return this.STATUSES[this.item.status];
  }

  openEmail() {
    const dialogRef = this.dialog.open(EmailPreviewComponent, { width: DIALOG_SIZE.DEFAULT.width, data: this.item });
  }
}
