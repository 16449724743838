import 'ag-grid-enterprise';

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription } from 'rxjs';
import { interval } from 'rxjs/observable/interval';

import { ReportColumnType } from '../../reports/report-table/report-table.interfaces';
import { DataTableQueryParams } from '../data-table/data-table.common';
import { CurrencyCellRenderer } from '../functions/currency-cell-renderer';
import { EditColumn } from '../functions/edit-cell-renderer';
import { contextMenuItemsGetter } from '../functions/get-context-menu-items';
import { NumberCellRenderer } from '../functions/number-cell-renderer';
import { RemoveColumn } from '../functions/remove-cell-renderer';
import { PybAgCellDateComponent } from '../pyb-ag-grid/cells/ag-date-cell.component';
import { toLowercaseUnderscore } from '../utils/to-lowercase-underscore.util';
import { SupplierService } from './../../suppliers/services/supplier.service';
import { SubscriberComponent } from './../component-subscriber/subscriber.component';
import { CsvExportService } from './../csv-export-service/csv-export.service';
import { DataTableDatasource } from './data-table-ag-grid.datasource';
import { IDataTableAgGridConfig } from './models/data-table-ag-grid-config.interface';
import { DataTableAgGridDataTypes } from './models/data-table-ag-grid-data-types.enum';
import { IDataTableAgGridHeaderDef } from './models/data-table-ag-grid-header-def.interface';
import { HeadersWithSummedValuesSettingService } from './services/headers-with-summed-values-setting.service';

LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  'CompanyName=Payability,LicensedGroup=Payability,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-008723,ExpiryDate=29_June_2021_[v2]_MTYyNDkyMTIwMDAwMA==60a3864d16c0695d344777994f4753db',
);

@Component({
  selector: 'app-data-table-ag-grid',
  templateUrl: './data-table-ag-grid.component.html',
  styleUrls: ['./data-table-ag-grid.component.scss'],
})
export class DataTableAgGridComponent extends SubscriberComponent implements OnInit {
  @Input() supplierKey: string;
  @Input() defaultReportName?: string;
  @Input() customGridOptions?: any;
  @Input() remove: { removeFn: (data) => Observable<any>; disabledFn: (params) => boolean };
  @Input() editFn: (data) => Observable<any>;
  @Input() overrideColumnDef: { [key: string]: ColDef } = {};

  gridOptions: GridOptions;
  gridApi: GridApi;
  columnApi: ColumnApi;
  columnDefs: ColDef[];
  reportName: string;
  lastSearchParams: any;
  baseHttpParams: DataTableQueryParams = {
    limit: 20,
    offset: 0,
  };

  remove$: Subscription;
  edit$: Subscription;
  hiddenColumns: string[] = [];
  reportTitle: string;
  headersTotalCount: { [key: string]: number };
  dataResults: any[] = [];
  filterConfig = [];
  reportConfig: Observable<any>;
  supplierKeyInQueryParamsNotNeeded: boolean;
  reportConfigName: any;
  currentFilterValues: any;
  loading: boolean;
  requestSubscription$: Subscription;
  datasource: DataTableDatasource;
  readonly gridRefreshPeriod: number = 69;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly supplierService: SupplierService,
    private readonly csvExportService: CsvExportService,
    private readonly toastrService: ToastrService,
    private readonly headerWithSummedValuesSettingsService: HeadersWithSummedValuesSettingService,
  ) {
    super();
  }

  ngOnInit() {
    this.gridOptions = {
      getContextMenuItems: contextMenuItemsGetter,
      onGridReady: (event) => this.onGridReady(event),
      enableRangeSelection: true,
      enableServerSideSorting: true,
      pagination: true,
      cacheBlockSize: 20,
      headerHeight: 50,
      paginationPageSize: 20,
      rowModelType: 'infinite',
      popupParent: document.querySelector('body'),
      ...this.customGridOptions,
    } as GridOptions;

    this.subscriptions.push(
      this.route.data.subscribe((routeData) => {
        if (routeData.reportConfigName) {
          this.reportConfigName = routeData.reportConfigName;
        }

        const reportNameObservable: Observable<Params> = routeData.reportName
          ? of({ reportName: routeData.reportName })
          : this.route.params;
        this.supplierKeyInQueryParamsNotNeeded = routeData.supplierKeyInQueryParamsNotNeeded;
        reportNameObservable.subscribe((routeParams) => {
          this.reportName = routeParams.reportName;
          if (!this.reportName) {
            this.reportName = 'v_mp_statements';
          }
          this.getConfig();
          this.checkIfColumnsArePresent();
          this.checkIfColumnApiIsAvailable();
        });
      }),
    );
  }

  createColumnDefs(columnsConfig: IDataTableAgGridHeaderDef[]): ColDef[] {
    const headersFormatted: ColDef[] = [];

    columnsConfig.map((column: IDataTableAgGridHeaderDef) => {
      if (!this.hiddenColumns.includes(column.data)) {
        const colId = column.filter && column.filter.label ? column.filter.label : toLowercaseUnderscore(column.data);
        let columnModel: ColDef = {
          field: colId,
          colId,
          headerName: this.labelFormatter(column.data),
          sortable: true,
          resizable: true,
          suppressMenu: true,
        };
        if (column.type) {
          this.cellRendererAssigner(column.type, columnModel);
        }

        if (column.filter) {
          this.filterAssigner(column.type, column.filter);
        }

        if (column.cellRenderer) {
          columnModel.cellRenderer = column.cellRenderer;
        }

        if (this.overrideColumnDef[colId]) {
          columnModel = { ...columnModel, ...this.overrideColumnDef[colId] };
        }

        headersFormatted.push(columnModel);
      }
    });

    if (this.editFn) {
      headersFormatted.push(EditColumn(this.onEdit.bind(this)));
    }

    if (this.remove) {
      headersFormatted.push(RemoveColumn(this.onRemove.bind(this), null, this.remove.disabledFn));
    }

    return headersFormatted;
  }

  cellRendererAssigner(columnType: string, columnModel: ColDef): void {
    switch (columnType) {
      case DataTableAgGridDataTypes.Currency:
        columnModel.cellRenderer = CurrencyCellRenderer;
        break;
      case DataTableAgGridDataTypes.Number:
        columnModel.cellRenderer = NumberCellRenderer;
        break;
      case DataTableAgGridDataTypes.Date:
      case ReportColumnType.Date:
        columnModel.cellRendererFramework = PybAgCellDateComponent;
        columnModel.cellStyle = { textAlign: 'center' };
        break;
    }
  }

  filterAssigner(columnType: string, columnFilter: any): void {
    switch (columnType) {
      case DataTableAgGridDataTypes.Text:
        this.filterConfig = [
          ...this.filterConfig,
          {
            fieldName: columnFilter.label,
            label: this.labelFormatter(columnFilter.label),
            fieldType: 'input',
          },
        ];
        break;
      case DataTableAgGridDataTypes.Date:
        this.filterConfig = [
          ...this.filterConfig,
          {
            fieldName: columnFilter.label + '__gt',
            label: this.labelFormatter(columnFilter.label),
            fieldType: 'datePicker',
            isConnecting: true,
          },
          {
            fieldName: columnFilter.label + '__lt',
            label: '',
            fieldType: 'datePicker',
          },
        ];
        break;
      default:
        this.filterConfig = [
          ...this.filterConfig,
          {
            fieldName: columnFilter.label,
            label: this.labelFormatter(columnFilter.label),
            fieldType: 'input',
          },
        ];
    }
  }

  labelFormatter(filterLabel: string) {
    return filterLabel
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  onBtnExportCurrent() {
    const params = {
      make_dump: true,
      ...this.currentFilterValues,
    };
    this.subscriptions.push(
      this.supplierService.getReport(this.reportName, params).subscribe(
        (data) => this.csvExportService.exportTransactions(data, this.reportName, '_all'),
        () => this.toastrService.error('Could not fetch report data.'),
      ),
    );
  }

  autoSizeColumns() {
    const allColumnIds = [];
    this.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column['colId']);
    });
    this.columnApi.autoSizeColumns(allColumnIds);
  }

  configTitleParser(configTitle: string) {
    this.reportTitle = configTitle.includes('v_') ? `V  ${configTitle.charAt(2).toUpperCase()}${configTitle.slice(3)}` : configTitle;
  }

  onGridReady(gridParams: GridReadyEvent): void {
    this.columnApi = gridParams.columnApi;
    this.gridApi = gridParams.api;

    if (this.reportConfig) {
      this.subscriptions.push(
        this.reportConfig.subscribe((config: IDataTableAgGridConfig) => {
          if (!config) {
            return;
          }

          if (!this.reportTitle) {
            this.configTitleParser(config.title);
          }

          this.columnDefs = this.createColumnDefs(config.columns);
          this.gridApi.setColumnDefs(this.columnDefs);
          this.loadData();
        }),
      );
    }
  }

  refreshGrid(filterParams: any): void {
    this.reportConfig = this.supplierService.getReportConfig(this.reportConfigName ? this.reportConfigName : this.reportName);
    this.subscriptions.push(
      this.reportConfig.subscribe((config: IDataTableAgGridConfig) => {
        if (!config || !config.title) {
          return;
        }
        this.configTitleParser(config.title);
        this.columnDefs = this.createColumnDefs(config.columns);
        this.gridApi.setColumnDefs(this.columnDefs);
        this.loadData(filterParams);
      }),
    );
  }

  loadData(filterParams?: any): void {
    this.loading = true;
    this.currentFilterValues = filterParams;
    if (this.requestSubscription$ && this.datasource) {
      this.datasource.unsubscribe();
      this.requestSubscription$.unsubscribe();
    }

    this.lastSearchParams = filterParams;
    this.initDatasource(filterParams);
    this.handleGetRows();

    this.gridApi.setDatasource(this.datasource);
  }

  private getConfig(): void {
    this.reportConfig = this.supplierService.getReportConfig(this.reportConfigName ? this.reportConfigName : this.reportName);
  }

  private initDatasource(filterParams): void {
    this.datasource = new DataTableDatasource(
      this.supplierService,
      this.reportName,
      this.supplierKeyInQueryParamsNotNeeded,
      this.supplierKey,
      filterParams,
    );
  }

  private handleGetRows() {
    this.requestSubscription$ = this.datasource.onGetRows.subscribe(
      (data) => {
        this.loading = false;
        this.headersTotalCount = data.summed;
        if (!data || !data.results || !data.results.length) {
          this.gridApi.showNoRowsOverlay();
          return;
        }
        this.dataResults = data.results;
        this.checkIfColumnApiIsAvailable();
      },
      () => (this.loading = false),
    );

    this.subscriptions.push(this.requestSubscription$);
  }

  private checkIfColumnsArePresent() {
    const columnsCheckerInterval = interval(this.gridRefreshPeriod).subscribe(() => {
      if (!this.columnApi || !this.columnApi.getAllColumns()) {
        return;
      }

      this.autoSizeColumns();
      columnsCheckerInterval.unsubscribe();
    });
  }

  private checkIfColumnApiIsAvailable() {
    const columnApiCheckerInterval = interval(this.gridRefreshPeriod).subscribe(() => {
      if (!this.columnApi || !this.headersTotalCount || !this.columnDefs) {
        return;
      }

      this.setHeadersWithSummedAmount(this.headersTotalCount);
      columnApiCheckerInterval.unsubscribe();
    });
  }

  private setHeadersWithSummedAmount(summedHeadersValues) {
    this.headerWithSummedValuesSettingsService.formatHeaders(summedHeadersValues, this.columnApi, this.columnDefs);
    this.gridApi.setColumnDefs(this.headerWithSummedValuesSettingsService.formattedHeaders);
  }

  private onRemove(data): void {
    if (this.remove$ && !this.remove$.closed) {
      this.remove$.unsubscribe();
    }

    this.remove$ = this.remove.removeFn(data).subscribe((shouldRefresh: boolean) => {
      if (shouldRefresh) {
        this.loadData(this.lastSearchParams);
      }
    });
    this.subscriptions.push(this.remove$);
  }

  private onEdit(data): void {
    if (this.edit$ && !this.edit$.closed) {
      this.edit$.unsubscribe();
    }

    this.edit$ = this.editFn(data).subscribe((shouldRefresh: boolean) => {
      if (shouldRefresh) {
        this.loadData(this.lastSearchParams);
      }
    });
    this.subscriptions.push(this.edit$);
  }
}
