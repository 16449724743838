import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UwStatus } from '../../../../../../../../enums/uw-status';
import { ALL_UW_STATUSES } from '../../../../../../../../utils/all-uw-statuses';
import { CalculatorInputFields } from '../../../../store/calculator.enums';
import { IAdvanceOfferConnectedInputs } from '../../../../store/calculator-interfaces';

@Component({
  selector: 'app-additinal-manual-advance-offer-inputs',
  templateUrl: './additinal-manual-advance-offer-inputs.component.html',
  styleUrls: ['../manual-advance-offer-inputs.component.scss'],
})
export class AdditinalManualAdvanceOfferInputsComponent {
  @Input() showUwStatusSelector = false;
  @Input() isNewOffer: boolean;
  @Input() underwritingStatus: UwStatus;
  @Input() expectedDuration: number;
  @Input() expectedMonthlyEarnings: number;
  @Input() totalPeriodLength: number;
  @Input() averageFee: number;
  @Input() disableInputsWithoutRequestedAmount: boolean;
  @Input() requested: IAdvanceOfferConnectedInputs;
  @Output() onFieldChange = new EventEmitter<{ event: any; type: CalculatorInputFields }>();

  uwStatuses = ALL_UW_STATUSES;

  changeExpectedMonthlyEarnings(e) {
    this.onFieldChange.emit({ event: e, type: CalculatorInputFields.EXPEXTED_MONTHLY_EARINGS });
  }

  changeExpectedDuration(e) {
    this.onFieldChange.emit({ event: e, type: CalculatorInputFields.EXPECTED_DURATION });
  }

  changeUwStatus(e) {
    this.onFieldChange.emit({ event: e, type: CalculatorInputFields.UW_STATUS });
  }
}
