import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-freemium-marketplace-headers',
  templateUrl: './freemium-marketplace-headers.component.html',
  styleUrls: ['./freemium-marketplace-headers.component.scss'],
})
export class FreemiumMarketplaceHeadersComponent implements OnInit {
  public flexColumns = ['Marketplace', 'Participation Rate', 'Funding Rate', 'Freemium Fee', 'Free Funding Rate'];

  constructor() {}

  ngOnInit() {}
}
