import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppState } from './../../../store/app.reducers';
import { GlobalSettingsService } from './../service/global-settings.service';
import { GLOBAL_SETTINGS_ACTION, GlobalSettingsFetchAllSuccess } from './global-settings.actions';

@Injectable()
export class GlobalSettingsEffects {
  @Effect()
  fetchAll$ = this.actions$.ofType(GLOBAL_SETTINGS_ACTION.FETCH_ALL).pipe(
    switchMap(() => this.globalSettingsService.fetchAll()),
    catchError(() => of(null)),
    map((configs) => new GlobalSettingsFetchAllSuccess({ configs })),
  );

  constructor(private actions$: Actions, private store$: Store<AppState>, private globalSettingsService: GlobalSettingsService) {}
}
