import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { OnboardingService } from '../../../shared/onboarding/service/onboarding.service';
import { BaseSupplierAction } from '../../../suppliers/supplier/base-supplier-action/base-supplier-action';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-delete-onboarding',
  templateUrl: './delete-onboarding.component.html',
})
export class DeleteOnboardingComponent extends SubscriberComponent implements OnInit {
  confirmationMessage = 'Are you sure you want to delete item? This can break functionality in other places.';
  isLoadingResult = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BaseSupplierAction>,
    private onboardingService: OnboardingService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {}

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  submit() {
    this.isLoadingResult = true;

    if (this.data.onboardingTemplateMpKey) {
      this.subscriptions.push(
        this.onboardingService.removeMp(this.data.onboardingTemplateMpKey).subscribe(
          () => {
            this.toastrService.success('Item setting successfully deleted');
            this.closeDialog(true);
          },
          (error) => {
            this.toastrService.error(error.error.meta.errors[0].message);
            this.closeDialog(false);
          },
          () => {
            this.isLoadingResult = false;
          },
        ),
      );
    } else {
      this.subscriptions.push(
        this.onboardingService.remove(this.data.onboardingTemplateCode).subscribe(
          () => {
            this.toastrService.success('Item setting successfully deleted');
            this.closeDialog(true);
          },
          (error) => {
            this.toastrService.error(error.error.meta.errors[0].message);
            this.closeDialog(false);
          },
          () => {
            this.isLoadingResult = false;
          },
        ),
      );
    }
  }
}
