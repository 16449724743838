import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appSticky]',
})
export class StickyDirective {
  private _minY = 100;
  private _className = 'stick';

  @Input('stickMin') set stickMin(minY: number) {
    this._minY = minY || this._minY;
  }

  @Input('stickClass') set stickClass(className: string) {
    this._className = className || this._className;
  }

  constructor(private _element: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  handleScrollEvent(e) {
    if (window.pageYOffset > this._minY) {
      this._element.nativeElement.classList.add(this._className);
    } else {
      this._element.nativeElement.classList.remove(this._className);
    }
  }
}
