import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cli-action-status',
  templateUrl: './cli-action-status.component.html',
  styleUrls: ['./cli-action-status.component.scss'],
})
export class CliActionStatusComponent implements OnInit {
  @Input() progressValue;
  @Input() executionStatus;

  public statusCodes = {
    1: 'Finished',
    2: 'Active',
    3: 'Failed',
    4: 'Timeout',
    5: 'Canceled',
    6: 'Terminated',
    7: 'Scheduled',
  };

  constructor() {}

  ngOnInit() {}
}
