<article class="article">
  <div class="card">
    <div class="card-content">
      <div class="row">
        <div class="col">
          <h3>{{ !!loanKey ? 'Edit Amazon Loan' : 'Add New Amazon Loan' }}</h3>
        </div>
      </div>
      <div class="row" *ngIf="loanForm; else loading">
        <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-lg-5'">
          <div class="card-title">Amazon Details</div>
          <form class="row" [formGroup]="loanForm" novalidate>
            <mat-form-field class="col-md-12">
              <input matInput placeholder="Loan ID" formControlName="loanExternalId" type="text" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <mat-select placeholder="Marketplace" formControlName="mpSupKey">
                <mat-option *ngFor="let option of availableMpSupKeys$ | async" [value]="option.id">{{ option.label }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <mat-select placeholder="Payment Status" formControlName="paymentStatus">
                <mat-option *ngFor="let status of availablePaymentStatuses" [value]="status">{{ status }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Next Payment Date" formControlName="nextPaymentDate" [matDatepicker]="nextPaymentDatePicker" />
              <mat-datepicker-toggle matSuffix [for]="nextPaymentDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #nextPaymentDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Next Payment Amount" formControlName="nextPaymentAmount" type="number" />
            </mat-form-field>

            <div class="divider-lg"></div>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Origination Date" formControlName="originationDate" [matDatepicker]="originationDatePicker" />
              <mat-datepicker-toggle matSuffix [for]="originationDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #originationDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Maturity Date" formControlName="maturityDate" [matDatepicker]="maturityDatePicker" />
              <mat-datepicker-toggle matSuffix [for]="maturityDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #maturityDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Original Loan Amount" formControlName="loanAmount" type="number" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Annual Interest" formControlName="annualInterest" type="number" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Last Payment Date" formControlName="lastPaymentDate" [matDatepicker]="lastPaymentDatePicker" />
              <mat-datepicker-toggle matSuffix [for]="lastPaymentDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #lastPaymentDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Last Payment Amount" formControlName="lastPaymentAmount" type="number" />
            </mat-form-field>
          </form>
        </div>
        <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-lg-5 offset-lg-1'">
          <div class="card-title">Payability Details</div>

          <form class="row" [formGroup]="loanForm" novalidate>
            <mat-form-field class="col-md-12">
              <input matInput placeholder="Payment Period Dates" formControlName="paymentPeriodDays" type="number" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Days left to Loan Payments" formControlName="daysLeftToPayment" type="number" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Loan Provision Reserved" formControlName="loanExtraReserveAmount" type="number" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <mat-select placeholder="Loan Type" formControlName="loanType">
                <mat-option *ngFor="let loanType of marketplaceLoanTypes" [value]="loanType">
                  {{ loanType }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="!!loanKey" class="col-md-12">
              <input matInput placeholder="Marketplace Loan Key" formControlName="marketplaceLoanKey" type="text" />
            </mat-form-field>
          </form>
          <div class="card-title">Past Due Amount LPR</div>
          <form class="row" [formGroup]="loanForm" novalidate>
            <mat-form-field class="col-md-12">
              <input matInput placeholder="Past Due Payment Period Dates" formControlName="pastDuePaymentPeriodDates" type="number" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Days Left to Past Due Payment" formControlName="daysLeftToPastDuePayment" type="number" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input matInput placeholder="Past Due Loan Provision Reserved" formControlName="pastDueLoanProvisionReserved" type="number" />
            </mat-form-field>
            <mat-form-field class="col-md-12">
              <input matInput placeholder="Past Due Amount" formControlName="pastDueAmount" type="number" />
            </mat-form-field>

            <mat-form-field class="col-md-12">
              <input
                matInput
                placeholder="Past Due Amount Payment Date"
                formControlName="nextSettlementDate"
                [matDatepicker]="nextSettlementDatePicker"
              />
              <mat-datepicker-toggle matSuffix [for]="nextSettlementDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #nextSettlementDatePicker></mat-datepicker>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="row" *ngIf="loanForm; else loading">
        <div class="col-md-12">
          <h5>Last updated at : {{ updatedTs }}</h5>
        </div>
      </div>
    </div>
    <div class="card-action">
      <button mat-raised-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="accent" (click)="onSave()" [disabled]="!(canEditLoan$ | async)">Save</button>
      <button *ngIf="!!loanKey" mat-raised-button color="warn" (click)="onDelete()" [disabled]="!(canDeleteLoan$ | async)">Delete</button>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h5>Loan Provision Reserved Formula - description of how we calculate Loan Provision Reserved</h5>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <h6>
              <span class="badge badge-primary">next payment date</span> <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">last payment date</span>
              <span class="badge badge-info">+</span>
              <span class="badge badge-secondary">payment period days</span>
              <span class="badge badge-info">+</span>
              <span class="badge badge-secondary">number of days to close the statement</span>
            </h6>
            <h6>
              <span class="badge badge-primary">days left to payment</span> <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">next payment date</span>
              <span class="badge badge-info">-</span>
              <span class="badge badge-secondary">current date</span>
            </h6>
            <h6>
              <span class="badge badge-primary">base loan provision reserved</span> <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">loan next payment amount</span>
              <span class="badge badge-info">/</span>
              <span class="badge badge-secondary">payment period days</span>
              <span class="badge badge-info">*</span>
              <span class="badge badge-warning">(</span>
              <span class="badge badge-secondary">payment period days</span>
              <span class="badge badge-info">-</span>
              <span class="badge badge-secondary">days left to payment</span>
              <span class="badge badge-warning">)</span>
            </h6>
            <h6>
              <span class="badge badge-primary">statement length</span> <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">14 days</span>
            </h6>
            <h6>
              <span class="badge badge-primary">past due loan provision reserved</span> <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">past due amount</span>
              <span class="badge badge-info">/</span>
              <span class="badge badge-secondary">statement length -> 14</span>
              <span class="badge badge-info">*</span>
              <span class="badge badge-warning">(</span>
              <span class="badge badge-secondary">statement length -> 14</span>
              <span class="badge badge-info">-</span>
              <span class="badge badge-warning">(</span>
              <span class="badge badge-secondary">next settlement date</span>
              <span class="badge badge-info">-</span>
              <span class="badge badge-secondary">current date</span>
              <span class="badge badge-warning">)</span>
              <span class="badge badge-warning">)</span>
            </h6>
            <h6>
              <span class="badge badge-primary">loan provision reserved</span> <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">base loan provision reserved</span>
              <span class="badge badge-info">+</span>
              <span class="badge badge-secondary">past due loan provision reserved</span>
            </h6>
          </mat-expansion-panel>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h5>Current calculation - description of how we calculate on current values</h5>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <h6>
              <span class="badge badge-success">base loan provision reserved</span> <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">{{ loanForm.get('nextPaymentAmount').value }}</span>
              <span class="badge badge-info">/</span>
              <span class="badge badge-secondary">{{ loanForm.get('paymentPeriodDays').value }}</span>
              <span class="badge badge-info">*</span>
              <span class="badge badge-warning">(</span>
              <span class="badge badge-secondary">{{ loanForm.get('paymentPeriodDays').value }}</span>
              <span class="badge badge-info">-</span>
              <span class="badge badge-secondary">{{ loanForm.get('daysLeftToPayment').value }}</span>
              <span class="badge badge-warning">)</span>
              <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">{{ loanForm.get('baseLoanProvisionReserved').value }}</span>
            </h6>
            <h6>
              <span class="badge badge-success">past due loan provision reserved</span> <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">{{ loanForm.get('pastDueAmount').value }}</span>
              <span class="badge badge-info">/</span>
              <span class="badge badge-secondary">statement length -> 14</span>
              <span class="badge badge-info">*</span>
              <span class="badge badge-warning">(</span>
              <span class="badge badge-secondary">statement length -> 14</span>
              <span class="badge badge-info">-</span>
              <span class="badge badge-secondary">{{ loanForm.get('daysLeftToPastDuePayment').value }}</span>
              <span class="badge badge-warning">)</span>
              <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">{{ loanForm.get('pastDueLoanProvisionReserved').value }}</span>
            </h6>
            <h6>
              <span class="badge badge-success">Loan Provision Reserved</span> <span class="badge badge-info">=</span>
              <span class="badge badge-warning">(</span>
              <span class="badge badge-secondary">{{ loanForm.get('baseLoanProvisionReserved').value }}</span>
              <span class="badge badge-info">-></span>
              <span class="badge badge-secondary">Base Loan Provision Reserve</span>
              <span class="badge badge-warning">)</span>
              <span class="badge badge-info">+</span>
              <span class="badge badge-warning">(</span>
              <span class="badge badge-secondary">{{ loanForm.get('pastDueLoanProvisionReserved').value }}</span>
              <span class="badge badge-info">-></span>
              <span class="badge badge-secondary">Past Due Loan Provision Reserved</span>
              <span class="badge badge-warning">)</span>
              <span class="badge badge-info">=</span>
              <span class="badge badge-secondary">{{ loanForm.get('loanExtraReserveAmount').value }}</span>
            </h6>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</article>

<ng-template #loading>
  <app-loading-spinner [size]="3"></app-loading-spinner>
</ng-template>
