import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { ProductMatrixInitializeTablesService } from '../service/product-matrix-initialize-tables.service';
import { IProducts } from '../service/product-matrix-interfaces';

@Component({
  selector: 'app-product-payment-gateways',
  templateUrl: './product-payment-gateways.component.html',
  styleUrls: ['./product-payment-gateways.component.scss'],
})
export class ProductPaymentGatewaysComponent implements OnInit {
  @Input() response: IProducts;

  public serviceColumns = ['name', 'marketplace', 'status'];
  public productStatus: string;
  public dataSource: MatTableDataSource<Element>;
  public data: any[];
  public productStatusColor: string;

  constructor() {}

  ngOnInit() {
    this.data = [];
    this.setUpDataFromResponse(this.response.payment_gateway);
    this.dataSource = new MatTableDataSource(this.data);
  }

  private setUpDataFromResponse(product: any): void {
    this.productStatus = ProductMatrixInitializeTablesService.setProductStatus(product.onboarding_outcome.onboarding_result);
    this.productStatusColor = ProductMatrixInitializeTablesService.setProductStatusColor(this.productStatus);
    for (const key in product.mp_data) {
      if (product.mp_data.hasOwnProperty(key)) {
        const mp = product.mp_data[key];
        if (mp.status !== 'notSupported') {
          this.data.push({
            name: mp.marketplace_name ? mp.marketplace_name : '-',
            marketplace: mp.marketplace_supplier ? mp.marketplace_supplier.marketplace.marketplace_name : '-',
            status: mp.status === 'active' ? 'Connected' : 'Not Connected',
          });
        }
      }
    }
  }
}
