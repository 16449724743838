import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';

import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { PermissionModel } from './../../../shared/users/permission.model';
import { UsersService } from './../../../shared/users/service/users.service';
import { UserModel } from './../../../shared/users/user.model';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // Fields
  public readonly basePageSize = 50;
  public readonly basePermCode = 'INT_LOGIN';

  public users: UserModel[];
  public permissions: PermissionModel[];

  public saUsername: string;
  public saPermissionCode: string;

  public displayedColumns = ['loginId', 'firstname', 'lastname', 'permission', 'action'];
  public dataSource = new MatTableDataSource();

  public numberOfRecords = 0;
  public recordsOffset = 0;

  private httpParams: any;

  constructor(private service: UsersService, private toastrService: ToastrService) {
    super();

    this.dataSource = new MatTableDataSource();

    this.httpParams = {
      limit: this.basePageSize,
      offset: this.recordsOffset,
      permCode: this.basePermCode,
    };
  }

  // Methods
  public ngOnInit(): void {
    this.getPermissions();
    this.getData();

    this.setupComponent();
  }

  public ngAfterViewInit(): void {
    this.subscriptions.push(
      this.paginator.page.subscribe((data) => {
        // update query params and get data
        this.setQueryParams(data);
        this.getData();
      }),
    );
  }

  public search(): void {
    this.initDataGridState();

    // update query params and get data
    this.setQueryParams();
    this.getData();
  }

  public clearSearch(): void {
    this.initSearchArgs();
    this.initDataGridState();

    // update query params and get data
    this.setQueryParams();
    this.getData();
  }

  private setupComponent(): void {
    this.initSearchArgs();
    this.initDataGridState();
  }

  private initSearchArgs(): void {
    this.saPermissionCode = this.basePermCode;
    this.saUsername = '';
  }

  private initDataGridState(): void {
    this.recordsOffset = 0;
    this.paginator.pageIndex = 0;
  }

  private setQueryParams(paginatorParams?: any): void {
    if (paginatorParams) {
      this.httpParams.offset = this.basePageSize * paginatorParams.pageIndex;
    }

    if (this.httpParams.permCode !== this.saPermissionCode) {
      this.httpParams.permCode = this.saPermissionCode;
    }

    if (this.httpParams.search !== this.saUsername) {
      this.httpParams.search = this.saUsername;
    }
  }

  private getPermissions(): void {
    this.subscriptions.push(
      this.service.getPermissions().subscribe(
        (data) => {
          if (data) {
            let perm = data.filter((item) => item.assignable === true);
            perm = perm.sort((a: PermissionModel, b: PermissionModel) => {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }

              return 0;
            });

            this.permissions = perm;
          }
        },
        () => {
          this.toastrService.error('Getting permissions list failed.');
        },
      ),
    );
  }

  private getData(): void {
    this.subscriptions.push(
      this.service.getUsersQuery(this.httpParams).subscribe(
        (data) => {
          if (data && data.results) {
            // get user permissions
            data.results.forEach((element) => {
              element.permission = element.perms.length ? element.perms[0].permDesc : undefined;
            });

            // bind data
            this.users = data.results;
            this.dataSource.data = data.results;
            this.numberOfRecords = data.total;
          }
        },
        () => {
          this.toastrService.error('Getting users data failed.');
        },
      ),
    );
  }
}
