<ul class="dataBox">
  <li *ngFor="let marketplace of marketplacesNames">
    <span class="badge badge-secondary" [matTooltipDisabled]="!showTooltips" [matTooltip]="marketplace.fullName">
      <ng-container *ngIf="showFullName; else shortName">
        {{ marketplace.fullName }}
      </ng-container>
      <ng-template #shortName>{{ marketplace.shortName }}</ng-template>
    </span>
  </li>
</ul>
