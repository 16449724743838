import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Deserialize, DeserializeKeysFrom } from 'cerialize';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { SupplierMarketplaceOptInPeriodModel } from '../model/supplier-marketplace-optin-period.model';

@Injectable()
export class SupplierMarketplaceOptInPeriodsService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers';
  }

  fetchItems(supplierKey, mpSupKey, refresh: boolean = false) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${mpSupKey}/opt_in_periods`;
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient
      .get<SupplierMarketplaceOptInPeriodModel>(endpointUrl, {
        params: queryParams,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(result, SupplierMarketplaceOptInPeriodModel);
        }),
      );
  }

  addOptInPeriod(supplierKey, mpSupKey, data): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${mpSupKey}/opt_in_periods`;

    return this.httpClient.post(endpointUrl, data).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  deleteOptInPeriod(supplierKey, mpSupKey, mpSupOptInPeriodKey, reason): Observable<any> {
    let params = new HttpParams();
    params = params.set('reasonCode', reason.reasonCode);
    params = params.set('reasonNote', reason.reasonNote);

    const endpointUrl = `${this.apiUrl}/${supplierKey}/funding_particulars/${mpSupKey}/opt_in_periods/${mpSupOptInPeriodKey}`;

    return this.httpClient.delete(endpointUrl, { params }).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }
}
