import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DataTableQueryParams } from '../../shared/data-table/data-table.common';
import { SupplierPaymentBatchListModel } from '../model/supplier-payment-batch-list-model';
import { PaymentBatchDownloaderAbstractService } from '../service/payment-batch-downloader-abstract.service';
import { PaymentBatchesAbstractService } from '../service/payment-batches-abstract-service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { IColumnPredefinition } from './../../shared/data-table-grid/models/default-column-definition';
import { CurrencyCellRenderer } from './../../shared/functions/currency-cell-renderer';
import { PrepareRenderer } from './../../shared/functions/date-cell-renderer';
import { DownloadCellRenderer } from './../../shared/functions/download-cell-renderer';
import { IPaginationData } from './../../shared/pyb-pagination/pagination-data';

@Component({
  selector: 'app-process-batches',
  templateUrl: './process-batches.component.html',
  styleUrls: ['./process-batches.component.scss'],
})
export class ProcessBatchesComponent extends SubscriberComponent implements OnInit {
  public batches: Observable<SupplierPaymentBatchListModel[]>;

  public tableSettings: any;
  public baseHttpParams: any;
  public batchesRows: any;
  public batchesColumns: IColumnPredefinition[];
  public filterConfig: Object[];
  public isReady: boolean;
  public paginationData?: IPaginationData;
  public total: number;
  public offset: number;
  params: DataTableQueryParams;

  constructor(
    private service: PaymentBatchesAbstractService,
    private paymentBatchDownloaderService: PaymentBatchDownloaderAbstractService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.baseHttpParams = {
      limit: 20,
      offset: 0,
      order_by: 'submitted',
      submitted: false,
    };

    this.batchesColumns = [
      {
        field: 'name',
        headerName: 'Name',
        cellRenderer: (cellData: any) => {
          return `<a href="${location.hash.replace('batches', cellData.data.paymentBatchKey)}">${cellData.value}</a>`;
        },
        width: 300,
      },
      {
        field: 'instantAccess',
        headerName: 'Instant Access',
      },
      {
        field: 'fundTransferType',
        headerName: 'Fund Transfer Type',
      },
      {
        field: 'paymentTypes',
        headerName: 'Payment Type Counts',
      },
      {
        field: 'paymentTotal',
        headerName: 'Payment Total',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'numHold',
        headerName: 'Num Hold',
      },
      {
        field: 'reviewers',
        headerName: 'Reviewers',
      },
      {
        field: 'numPayments',
        headerName: 'Num Payments',
      },
      {
        field: 'numApproved',
        headerName: 'Num Approved',
      },
      {
        field: 'numChangeMe',
        headerName: 'Num Change Me',
      },
      {
        field: 'plannedPaymentTime',
        headerName: 'Planned Payment Time',
        cellRenderer: PrepareRenderer(true),
      },
      {
        field: 'scheduledPayments',
        headerName: 'Scheduled Payments',
      },
      {
        field: 'canShowFile',
        headerName: 'File',
        cellRenderer: DownloadCellRenderer,
        onCellClicked: (record: any) => {
          this.paymentBatchDownloaderService.download(record.data.paymentBatchKey);
        },
      },
      {
        field: 'submitted',
        headerName: 'Submitted',
      },
      {
        field: 'locked',
        headerName: 'Locked',
      },
      {
        field: 'numCancelled',
        headerName: 'Num Cancelled',
      },
      {
        field: 'paymentBatchKey',
        headerName: 'Payment Batch Key',
      },
      {
        field: 'status',
        headerName: 'Status',
      },
      {
        field: 'lastUpdated',
        headerName: 'Last Updated',
      },
    ];

    this.filterConfig = [
      {
        fieldName: 'submitted',
        label: 'Submitted',
        fieldType: 'select',
        options: [
          { label: 'All', id: '' },
          { label: 'Submitted', id: 'true' },
          { label: 'Not Submitted', id: 'false' },
        ],
        defaultValue: 'false',
      },
    ];

    this.getBatches();
  }

  searchAction(params: any) {
    if (Object.keys(params).length === 0) {
      this.baseHttpParams = {
        limit: 20,
        offset: 0,
      };
    }
    const mergedParams = {
      ...this.baseHttpParams,
      ...params,
    };
    this.getBatches(mergedParams, true);
  }

  getBatches(params?: DataTableQueryParams, isSearchFilterAction?: boolean): void {
    this.params = params;
    this.isReady = false;
    if (!params) {
      params = this.baseHttpParams;
    }

    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      httpParams = httpParams.append(key, params[key]);
    });
    this.subscriptions.push(
      this.service.getItems(httpParams).subscribe((rows) => {
        this.batchesRows = rows['results'];
        this.total = rows['total'];
        this.offset = params.offset;
        this.setPaginationData(isSearchFilterAction);
      }),
    );
  }

  tableOnParamQueryChanged(args: DataTableQueryParams): void {
    this.getBatches(args);
  }

  setPaginationData(isSearchPaginationData) {
    this.paginationData = {
      ...this.baseHttpParams,
      total: this.total,
      resetPageCounter: isSearchPaginationData,
    };
  }

  onPageChange(dataTableQueryParams: DataTableQueryParams): void {
    const changedParams = {
      ...this.params,
      ...dataTableQueryParams,
    };
    this.getBatches(changedParams);
  }
}
