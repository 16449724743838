<div class="example-container mat-elevation-z8">
  <mat-card>
    <mat-card-title>
      <div class="row">
        <div class="col-md-6">Events</div>
        <div class="col-md-6 header-buttons">
          <button mat-raised-button color="primary" (click)="refreshEvents()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-title>

    <div class="grid-filters-wrapper">
      <app-event-filters (filtersChange)="filtersChange($event)"></app-event-filters>
    </div>

    <mat-card-content>
      <div class="events-table">
        <mat-table
          #table
          [dataSource]="dataSource"
          matSort
          [matSortDirection]="'desc'"
          [matSortActive]="'created_at'"
          [matSortDisableClear]="true"
        >
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <app-event-badge [type]="element.type"></app-event-badge>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="message_content">
            <mat-header-cell *matHeaderCellDef>Message / Content</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.data.message || element.data.subject || element.data.targetType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Created at</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.createdAt | fromUnix | date: 'M/d/y h:mm a' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef>User</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.data.user">{{ element.data.user.email }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="target">
            <mat-header-cell *matHeaderCellDef>Target</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.targetUuid">{{ 't: ' + element.targetUuid }}</span>
              <span *ngIf="element.targetUuid">
                <a mat-button class="open-supplier-link" target="_blank" [href]="supplierPageUrl(element.targetUuid)">
                  <mat-icon>open_in_new</mat-icon>
                </a>
              </span>
              <br />
              <span *ngIf="element.ownerUuid">{{ 'o: ' + element.ownerUuid }}</span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row class="event-item" (click)="openPreview(row)" *matRowDef="let row; columns: displayedColumns"> </mat-row>
        </mat-table>

        <mat-paginator [length]="resultsLength" [pageSize]="limit"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
