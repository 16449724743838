import { autoserialize } from 'cerialize';

import { USER_PERMISSION } from './user-permissions.enum';

export class UserPermModel {
  @autoserialize
  permDesc: string;

  @autoserialize
  supplierKey: string;

  @autoserialize
  loginPermKey: string;

  @autoserialize
  permCode: USER_PERMISSION | string;

  constructor(permDesc: string, supplierKey: string, loginPermKey: string, permCode: USER_PERMISSION | string) {
    this.permDesc = permDesc;
    this.supplierKey = supplierKey;
    this.loginPermKey = loginPermKey;
    this.permCode = permCode;
  }
}
