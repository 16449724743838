<div *ngIf="executionStatus <= 2; else statusInfo">
  <mat-progress-spinner mode="determinate" [value]="progressValue"></mat-progress-spinner>
  <div class="inside-text">{{ progressValue }}%</div>
</div>

<ng-template #statusInfo>
  <div class="badgeInfo">
    <span class="badge badge-danger">{{ statusCodes[executionStatus] }}</span>
  </div>
</ng-template>
