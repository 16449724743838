import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { FeatureFlagsService } from '../shared/feature-flags/feature-flags.service';
import { environment } from './../../environments/environment';
import { AppState } from './../store/app.reducers';

@Injectable()
export class FeatureFlagsGeneralGuard implements CanActivate {
  private displayed = false;

  constructor(private featureFlagsService: FeatureFlagsService, private store: Store<AppState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select('auth').pipe(
      filter((data) => data && data.user),
      first(),
      switchMap(() => from(this.featureFlagsService.initFF())),
      tap(() => {
        if (!environment.production) {
          this.displayFeatureFlagsStatus();
        }
      }),
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(true);
      }),
    );
  }

  private displayFeatureFlagsStatus(): void {
    if (this.displayed) {
      return;
    }

    this.displayed = true;

    const flags = this.featureFlagsService.getAvailableFlagsExecStatus();
    const entries = flags
      .map((f) => {
        const [key] = Object.keys(f);
        const [value] = Object.values(f);

        const status = value ? 'Enabled' : 'Disabled';
        return `${key}: ${status}`;
      })
      .join('\r\n');
    console.log(`%c[Feature Flags] Current Functionality Status:\n\r\n\r${entries}`, 'font-weight:bolder');
  }
}
