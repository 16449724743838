import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { RepositoryInterface } from '../../shared/utils/repository-interface';
import { MarketplaceListModel } from '../model/marketplace-list-model';
import { MarketplaceModel } from '../model/marketplace-model';

@Injectable()
export class MarketplaceRepository implements RepositoryInterface {
  private apiUrl = environment.internalApiUrl + 'marketplaces';

  constructor(private http: HttpClient) {}

  getItems(): Observable<MarketplaceListModel[]> {
    return this.http.get(this.apiUrl).pipe(
      map((response) => {
        const mappedResult = [];
        for (const item of <any>response) {
          mappedResult.push(new MarketplaceListModel(item));
        }
        return mappedResult;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  getItem(uuid: string): Observable<MarketplaceModel> {
    return this.http.get(`${this.apiUrl}/${uuid}`).pipe(
      map((response) => new MarketplaceModel(response)),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  saveItem(uuid: string, item: MarketplaceModel): Observable<MarketplaceModel> {
    return this.http.put(`${this.apiUrl}/${uuid}`, item).pipe(
      map((response) => new MarketplaceModel(response)),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  addItem(item: MarketplaceModel): Observable<any> {
    return this.http.post(`${this.apiUrl}`, item).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  getPeriodGroupCodes(): Observable<any[]> {
    return this.http.get(`${environment.internalApiUrl}marketplace_period_groups`).pipe(
      map((response: any[]) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  getDefaultFreeTrialTypeCodes(): Observable<any[]> {
    return this.http.get(`${environment.internalApiUrl}free_trials`).pipe(
      map((response: any[]) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }
}
