import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';

export const CANCELLATION_STATUSES = ['Pending', 'Canceled', 'Collections', 'Inactive', 'None'];

@Component({
  selector: 'app-supplier-box-header',
  templateUrl: './supplier-box-header.component.html',
  styleUrls: ['./supplier-box-header.component.scss'],
})
export class SupplierBoxHeaderComponent extends SubscriberComponent implements OnInit {
  readonly userPermission = USER_PERMISSION;
  selectSupplierDetails$: Observable<SupplierDetailsModel>;

  constructor(private readonly store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.selectSupplierDetails$ = this.store.select(selectSupplierDetails);
  }
}
