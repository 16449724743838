import 'ag-grid-enterprise';

import { CommonModule, CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { AgGridModule } from 'ag-grid-angular';
import { MomentModule } from 'angular2-moment';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';

import { B2bPreview, B2bPreviewDialogComponent } from '../crm-tools/shared/b2b-preview/b2b-preview.component';
import { PybMaterialModule } from '../pyb-material/pyb-material.module';
import { ActionsAddFabsComponent } from './actions-add-fabs/actions-add-fabs.component';
import { ActionsMenuComponent } from './actions-menu/actions-menu.component';
import { AppsDocumentDownloadDirective } from './b2b-document/b2b-document-download.directive';
import { CardFormgroupComponent } from './card-formgroup/card-formgroup.component';
import { ActiveContractsStatusCellComponent } from './cell-components/active-contracts-status-cell/active-contracts-status-cell.component';
import { CheckboxFiltersComponent } from './checkbox-filters/checkbox-filters.component';
import { ChipComponent } from './chip/chip.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CsvExportService } from './csv-export-service/csv-export.service';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DataGridFormatPipe } from './data-grid/data-grid-format.pipe';
import { DataGridOrderByPipe } from './data-grid/data-grid-order-by.pipe';
import { DataTableAgGridComponent } from './data-table-ag-grid/data-table-ag-grid.component';
import { HeaderTemplateRendererService } from './data-table-ag-grid/data-table-ag-grid-header-template-renderer.service';
import { DataTableGridComponent } from './data-table-grid/data-table-grid.component';
import { EvaConfirmDialogComponent } from './dialog/dialogs/confirm/eva-confirm-dialog.component';
/* eslint-disable */
import { DisabledReasonDirective } from './disabled-reason.directive';
import { DragAndDropListComponent } from './drag-and-drop-list/drag-and-drop-list.component';
import { EditorModule } from './editor/editor.module';
import { BoardComponent } from './error-handling/tetris/board/board.component';
import { PybtrisButtonsComponent } from './error-handling/tetris/board/pybtris-buttons/pybtris-buttons.component';
import { GameService } from './error-handling/tetris/game.service';
import { TetrisComponent } from './error-handling/tetris/tetris.component';
import { EvaComponent } from './eva/eva.component';
import { EvaService } from './eva/eva.service';
import { ExternalReportComponent } from './external-report/external-report.component';
import { FeatureFlagsModule } from './feature-flags/feature-flag.module';
import { FilterControlDatepickerComponent } from './filtering-panel/controls/filter-control-datepicker/filter-control-datepicker.component';
import { FilterControlInputComponent } from './filtering-panel/controls/filter-control-input/filter-control-input.component';
import { FilterControlSelectComponent } from './filtering-panel/controls/filter-control-select/filter-control-select.component';
import { FilteringPanelComponent } from './filtering-panel/filtering-panel.component';
import { CapitalizePipe } from './filters/capitalize.pipe';
import { EllipsisPipe } from './filters/ellipsis.pipe';
import { KeysPipe } from './filters/keys.pipe';
import { AppFooterComponent } from './footer/footer.component';
import { DynamicFormComponent } from './forms/dynamic-form/dynamic-form.component';
import { DynamicFormFieldComponent } from './forms/dynamic-form-field/dynamic-form-field.component';
import { NgHttpLoaderModule } from './http-loader/ng-http-loader.module';
import { IframeModule } from './iframe/iframe.module';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { InlineEditDateComponent } from './inline-edit/date/inline-edit-date.component';
import { InlineEditDateAndTimeComponent } from './inline-edit/date-and-time/inline-edit-date-and-time.component';
import { FormatPreviewPipe } from './inline-edit/format-preview';
import { InlineNumberComponent } from './inline-edit/inline-number/inline-number.component';
import { InlineEditNumberSliderComponent } from './inline-edit/number-slider/inline-edit-number-slider.component';
import { InlineEditSelectComponent } from './inline-edit/select/inline-edit-select.component';
import { InlineEditSwitchComponent } from './inline-edit/switch/inline-edit-switch.component';
import { InlineAddTextComponent } from './inline-edit/text/inline-add-text/inline-add-text.component';
import { InlineEditTextComponent } from './inline-edit/text/inline-edit-text.component';
import { InlineEditTextareaComponent } from './inline-edit/textarea/inline-edit-textarea.component';
import { InlineEditYesNoComponent } from './inline-edit/yes-no/inline-edit-yes-no.component';
import { InputFileComponent } from './input-file/input-file.component';
import { AppStageInfoComponent } from './layout/app-stage-info/app-stage-info.component';
import { FabHolderNavComponent } from './layout/fab-holder-nav/fab-holder-nav.component';
import { LoadingSpinnerModule } from './loading-spinner/loading-spinner.module';
import { MessageService } from './message.service';
import { NoDataComponent } from './no-data/no-data.component';
import { NotyfToastComponent } from './notyf.toast';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PybAgGridModule } from './pyb-ag-grid/pyb-ag-grid.module';
import { PybPaginationComponent } from './pyb-pagination/pyb-pagination.component';
import { RadiobuttonsComponent } from './radiobuttons/radiobuttons.component';
import { ReasonDialogComponent } from './reason-dialog/reason-dialog.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { OpenSearchOverlayDirective } from './search-overlay/open-search-overlay.directive';
import { SearchEngineService } from './search-overlay/search-engine-service-3-0/search-engine.service';
import { SearchOverlayComponent } from './search-overlay/search-overlay.component';
import { SearchOverlayDirective } from './search-overlay/search-overlay.directive';
import { SearchOverlaySingleResultComponent } from './search-overlay/search-overlay-single-result/search-overlay-single-result.component';
import { SharedRoutingModule } from './shared-routing.module';
import { AutoCloseMobileNavDirective } from './sidenav/auto-close-mobile-nav.directive';
import { AppSidenavComponent } from './sidenav/sidenav.component';
import { AccordionNavDirective } from './sidenav/sidenav-menu/accordion-nav.directive';
import { AppendSubmenuIconDirective } from './sidenav/sidenav-menu/append-submenu-icon.directive';
import { HighlightActiveItemsDirective } from './sidenav/sidenav-menu/highlight-active-items.directive';
import { AppSidenavMenuComponent } from './sidenav/sidenav-menu/sidenav-menu.component';
import { ToggleOffcanvasNavDirective } from './sidenav/toggle-offcanvas-nav.directive';
import { SimpleTableComponent } from './simple-table/simple-table.component';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { StickyDirective } from './sticky.directive';
import { SummaryDialogComponent } from './summary-dialog/summary-dialog.component';
import { TaskCountBadgeComponent } from './task-count-badge/task-count-badge.component';
import { TextValueComponent } from './text-value/text-value.component';
import { TimeSelectComponent } from './time-select-picker/time-select-picker.component';
import { TitleWithIconComponent } from './title-with-icon/title-with-icon.component';
import { FromUnixPipe } from './utils/fromUnix.pipe';
import { SafeValuePipe } from './utils/pipes/safe-value.pipe';
import { SafeHtmlPipe } from './utils/safe-html.pipe';
import { VersionService } from './version.service';
/* eslint-enable */

const declarations = [
  AccordionNavDirective,
  AppendSubmenuIconDirective,
  DisabledReasonDirective,
  AppFooterComponent,
  AppSidenavComponent,
  AppSidenavMenuComponent,
  AutoCloseMobileNavDirective,
  AppsDocumentDownloadDirective,
  BoardComponent,
  CapitalizePipe,
  CardFormgroupComponent,
  ConfirmDeleteComponent,
  ConfirmDialogComponent,
  EvaConfirmDialogComponent,
  SummaryDialogComponent,
  DataGridComponent,
  DataGridFormatPipe,
  DataGridOrderByPipe,
  DynamicFormComponent,
  DynamicFormFieldComponent,
  EllipsisPipe,
  HighlightActiveItemsDirective,
  InlineNumberComponent,
  InlineEditNumberSliderComponent,
  InlineEditSelectComponent,
  InlineEditSwitchComponent,
  InlineEditTextareaComponent,
  InlineEditTextComponent,
  InlineEditYesNoComponent,
  InlineEditDateComponent,
  InlineEditDateAndTimeComponent,
  KeysPipe,
  FormatPreviewPipe,
  OpenSearchOverlayDirective,
  PageNotFoundComponent,
  ResetPasswordComponent,
  SearchOverlayComponent,
  SearchOverlayDirective,
  StickyDirective,
  TetrisComponent,
  ToggleOffcanvasNavDirective,
  NotyfToastComponent,
  FabHolderNavComponent,
  B2bPreview,
  SafeHtmlPipe,
  FromUnixPipe,
  B2bPreviewDialogComponent,
  AppStageInfoComponent,
  InputFileComponent,
  TaskCountBadgeComponent,
  ActionsAddFabsComponent,
  ActionsMenuComponent,
  ReasonDialogComponent,
  InformationDialogComponent,
  InlineAddTextComponent,
  InlineAddTextComponent,
  SearchOverlaySingleResultComponent,
  DataTableAgGridComponent,
  DataTableGridComponent,
  PybtrisButtonsComponent,
  FilteringPanelComponent,
  FilterControlSelectComponent,
  FilterControlDatepickerComponent,
  FilterControlInputComponent,
  CheckboxFiltersComponent,
  SimpleTableComponent,
  ActiveContractsStatusCellComponent,
  TitleWithIconComponent,
  TextValueComponent,
  DragAndDropListComponent,
  PybtrisButtonsComponent,
  PybPaginationComponent,
  ExternalReportComponent,
  RadiobuttonsComponent,
  SpinnerButtonComponent,
  NoDataComponent,
  ChipComponent,
  SafeValuePipe,
  EvaComponent,
  TimeSelectComponent,
];

const modules = [
  CommonModule,
  SharedRoutingModule,
  RouterModule,
  ClipboardModule,
  FormsModule,
  ReactiveFormsModule,
  PybMaterialModule,
  NgHttpLoaderModule,
  NgxErrorsModule,
  InfiniteScrollModule,
  OrderModule,

  MomentModule,
  NgxPermissionsModule,
  PybAgGridModule,
  IframeModule,
  FeatureFlagsModule,
  LoadingSpinnerModule,
  EditorModule,
];

const providers = [
  CsvExportService,
  CurrencyPipe,
  PercentPipe,
  GameService,
  DatePipe,
  VersionService,
  MessageService,
  SearchEngineService,
  SearchEngineService,
  HeaderTemplateRendererService,
  CookieService,
  EvaService,
];

@NgModule({
  imports: [
    ...modules,
    AgGridModule.withComponents(),
    ToastrModule.forRoot({
      toastComponent: NotyfToastComponent,
      toastClass: 'notyf',
      positionClass: 'notyf-container',
      tapToDismiss: true,
      closeButton: true,
      timeOut: 5000,
    }),
  ],
  declarations: [...declarations],
  exports: [...declarations, ...modules],
  providers: [...providers],
  entryComponents: [
    ConfirmDialogComponent,
    SummaryDialogComponent,
    NotyfToastComponent,
    B2bPreviewDialogComponent,
    ReasonDialogComponent,
    InformationDialogComponent,
    FilterControlSelectComponent,
    FilterControlDatepickerComponent,
    FilterControlInputComponent,
    ActiveContractsStatusCellComponent,
    EvaConfirmDialogComponent,
  ],
})
export class SharedModule {}
