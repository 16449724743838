<mat-card class="smsNumbers__card">
  <span><h5>SMS mobile numbers</h5></span>
  <mat-divider></mat-divider>
  <mat-card-content>
    <mat-table [dataSource]="dataSourceSmsNumbers" *ngIf="dataSourceSmsNumbers">
      <ng-container matColumnDef="phoneNumber">
        <mat-header-cell *matHeaderCellDef>Phone Number</mat-header-cell>
        <mat-cell *matCellDef="let smsData">{{ smsData['phone_number'] }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="sid">
        <mat-header-cell *matHeaderCellDef>SID</mat-header-cell>
        <mat-cell *matCellDef="let smsData">{{ smsData['sid'] }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="updatedAt">
        <mat-header-cell *matHeaderCellDef>Updated at</mat-header-cell>
        <mat-cell *matCellDef="let smsData">{{ smsData['updated_at'] | date: 'short' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="source">
        <mat-header-cell *matHeaderCellDef>Source</mat-header-cell>
        <mat-cell *matCellDef="let smsData">{{ smsData['source'] ? smsData['source'] : '-' }}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedInstantPayoutsColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedInstantPayoutsColumns"></mat-row>
    </mat-table>
  </mat-card-content>
  <mat-card-actions>
    <form [formGroup]="numberFromForm">
      <app-spinner-button
        class="mr-2"
        (onClick)="requestNewNumber()"
        [color]="'accent'"
        [loading]="requestNewNumberLoading"
        [customWidth]="'15rem'"
        [spinnerColor]="'white'"
        [text]="'Request new number'"
      >
      </app-spinner-button>
      from:
      <mat-form-field>
        <mat-select placeholder="mobile number source" [formControl]="fromNumberSource" (selectionChange)="numberFromSelected($event)">
          <mat-option *ngFor="let mobileNumberSource of mobileNumberSources" [value]="mobileNumberSource">
            {{ mobileNumberSource }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-actions>
</mat-card>
