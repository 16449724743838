<h1 mat-dialog-title>{{ actionTitle }}</h1>

<div [formGroup]="supplierActionForm" *ngIf="showForm && supplierActionForm">
  <mat-form-field class="full-width">
    <input matInput readonly placeholder="Supplier" type="text" formControlName="sup_name" />
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select placeholder="Marketplace Supplier Key" formControlName="mp_sup_key">
      <mat-option *ngFor="let marketplace of marketplaces" [value]="marketplace.mp_key">
        {{ marketplace.label }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input
      matInput
      [matDatepicker]="startPicker"
      placeholder="Statement Start Date"
      formControlName="start_date"
      [max]="supplierActionForm.controls.end_date.value"
    />
    <mat-datepicker-toggle matSuffix [for]="startPicker"> </mat-datepicker-toggle>
    <mat-datepicker #startPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input
      matInput
      [matDatepicker]="endPicker"
      placeholder="Statement End Date"
      formControlName="end_date"
      [min]="supplierActionForm.controls.start_date.value"
    />
    <mat-datepicker-toggle matSuffix [for]="endPicker"> </mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Statement Ending Balance" type="number" step="0.01" formControlName="end_bal" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Statement ID" formControlName="statement_id" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select placeholder="Statement Code" formControlName="statement_code">
      <mat-option *ngFor="let statement of availableStatements" [value]="statement.value">
        {{ statement.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Beginning Balance" type="number" step="0.01" formControlName="begin_bal" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="MP Payment Amount" type="number" step="0.01" formControlName="mp_pmt_amount" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <div mat-dialog-actions class="justify-content-end" *ngIf="showForm">
    <button mat-raised-button (click)="submit()" [disabled]="!supplierActionForm.valid" tabindex="2" color="accent">Save</button>
    <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
  </div>

  <app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
</div>
