import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Injector, Input, NgZone, OnInit, Optional, ViewContainerRef } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_SCROLL_STRATEGY, MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appDisabledReason]',
  exportAs: 'appDisabledReason',
})
export class DisabledReasonDirective extends MatTooltip implements OnInit {
  @Input() get appDisabledReason() {
    return this.message;
  }

  set appDisabledReason(value: string) {
    this.localMessage = value;
    this.checkAndSetTooltip();
  }

  @Input() set disableWithReason(disable: boolean) {
    this.localDisable = disable;
    this.checkAndSetTooltip();
  }

  private localMessage: string;
  private localDisable = false;

  constructor(
    _overlay: Overlay,
    _elementRef: ElementRef,
    _scrollDispatcher: ScrollDispatcher,
    _viewContainerRef: ViewContainerRef,
    _ngZone: NgZone,
    _platform: Platform,
    _ariaDescriber: AriaDescriber,
    _focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) _scrollStrategy: any,
    @Optional() _dir: Directionality,
    _injector: Injector,
  ) {
    super(
      _overlay,
      _elementRef,
      _scrollDispatcher,
      _viewContainerRef,
      _ngZone,
      _platform,
      _ariaDescriber,
      _focusMonitor,
      _scrollStrategy,
      _dir,
      _injector.get(MAT_TOOLTIP_DEFAULT_OPTIONS, null),
      _injector.get(DOCUMENT),
    );
  }

  ngOnInit(): void {}

  private checkAndSetTooltip(): void {
    if (this.localDisable === true) {
      this.message = this.localMessage;
      return;
    }

    this.message = undefined;
  }
}
