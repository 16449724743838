<section class="card">
  <div class="card-content">
    <div class="">
      <div class="row checkboxes" [formGroup]="formCheckboxesLastBox">
        <div class="col-md-6">
          <mat-checkbox color="primary" formControlName="tinVerified">TIN Verified</mat-checkbox>
        </div>
        <div class="col-md-6">
          <mat-checkbox color="primary" formControlName="legalEntityVerified">Legal Entity Verified</mat-checkbox>
        </div>
      </div>
      <p class="textSize">*Supplier has attested that ownership & all beneficial owners are valid.</p>
    </div>
  </div>
</section>
