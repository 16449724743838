export enum TaskPriorities {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export const taskPriorities: any[] = [
  {
    name: 'Low',
    key: TaskPriorities.LOW,
  },
  {
    name: 'Medium',
    key: TaskPriorities.MEDIUM,
  },
  {
    name: 'High',
    key: TaskPriorities.HIGH,
  },
  {
    name: 'Critical',
    key: TaskPriorities.CRITICAL,
  },
];
