import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { InlineEditBaseComponent } from '../base/inline-edit-base.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditSwitchComponent),
  multi: true,
};

@Component({
  selector: 'app-inline-edit-switch',
  styleUrls: ['../base/inline-edit-base.component.scss', './inline-edit-switch.component.scss'],
  templateUrl: './inline-edit-switch.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
})
export class InlineEditSwitchComponent extends InlineEditBaseComponent {
  @Input() label = ''; // Label value for input element
}
