import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { AppState } from '../../../store/app.reducers';
import { FileModel } from '../../models/file.model';
import { DeleteCrmFile } from '../../store/crm.actions';

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['../../shared/crm-tools.scss', '../../shared/timeline-item.scss', './file-item.component.scss'],
})
export class FileItemComponent implements OnInit {
  @Input() item: FileModel;

  thumbnail = null;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    if (this.item.data && this.item.data.doc && this.item.data.doc.thumbnails && this.item.data.doc.thumbnails[0]) {
      this.thumbnail = this.item.data.doc.thumbnails[0];
    }
  }

  correctTime(oldTime) {
    const newTime = moment(oldTime).format();
    const offSetTime = moment.parseZone(newTime, 'YYYY-MM-DD HH:mm').utcOffset();
    return offSetTime;
  }

  deleteFile() {
    this.store.dispatch(new DeleteCrmFile({ uuid: this.item.uuid, targetUuid: this.item.targetUuid, b2bUuid: this.item.data.doc.uuid }));
  }
}
