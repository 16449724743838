<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="title">Reports</div>
        <div *ngIf="showTableActions" class="actions">
          <mat-form-field class="action">
            <mat-select placeholder="Filter reports" [(ngModel)]="selectedVisibilityFilter">
              <mat-option *ngFor="let record of visibilityFilterDictionary" [value]="record.id">
                {{ record.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button class="action" [routerLink]="['../new']" mat-raised-button [color]="'accent'">
            <mat-icon>add</mat-icon>
            Add Report
          </button>
        </div>
      </div>

      <div class="box table-responsive">
        <table class="mdl-data-table table-fixed">
          <thead>
            <tr>
              <th class="mdl-data-table__cell--non-numeric col-md-3">Report Name</th>
              <th class="mdl-data-table__cell--non-numeric col-md-3">Google Export Last Update</th>
              <th class="mdl-data-table__cell--non-numeric col-md-2">Google Sheet Link</th>
              <th class="mdl-data-table__cell--non-numeric col-md-2">Google Sheet Status</th>
              <th class="mdl-data-table__cell--non-numeric col-md-1">Update Sheet</th>
              <th class="mdl-data-table__cell--non-numeric col-md-1">Edit</th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-reportListModel [ngForOf]="reports | reportFilter: selectedVisibilityFilter">
              <tr>
                <!-- Report Name -->
                <td [ngClass]="{ hidden: !reportListModel.enabledForReports }" class="mdl-data-table__cell--non-numeric col-md-3">
                  <a [routerLink]="['/reports', reportListModel.name]">
                    {{ reportListModel.displayName }}
                  </a>
                </td>
                <!-- Google Export Last Update -->
                <td [ngClass]="{ hidden: !reportListModel.enabledForReports }" class="mdl-data-table__cell--non-numeric col-md-3">
                  {{ reportListModel.lastUpdated | date: "MM/dd/yyyy 'at' h:mm a" }}
                </td>
                <!-- Google Sheet Link -->
                <td class="mdl-data-table__cell--non-numeric col-md-2">
                  <a *ngIf="reportListModel.googleSheet" href="{{ reportListModel.googleSheet }}" target="_blank">Google Sheet</a>
                </td>
                <!-- Google Sheet Status -->
                <td [ngClass]="{ hidden: !reportListModel.enabledForReports }" class="mdl-data-table__cell--non-numeric col-md-2">
                  <span *ngIf="reportListModel.task">{{ reportListModel.taskStatus }}</span>
                </td>
                <!-- Update Sheet -->
                <td class="mdl-data-table__cell--non-numeric mdl-button-cell col-md-1">
                  <button
                    mat-raised-button
                    *ngIf="showUpdate(reportListModel)"
                    class="mat-default"
                    (click)="onSheetUpdate(reportListModel)"
                  >
                    Update
                  </button>
                </td>
                <!-- Edit -->
                <td class="mdl-data-table__cell--non-numeric mdl-button-cell col-md-1">
                  <button mat-raised-button class="mat-primary" [routerLink]="['/reports', reportListModel.name, 'edit']">Edit</button>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
