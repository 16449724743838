/* eslint-disable */
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UWRules } from '../common/interfaces/uw-rules';
import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { AmazonHealthValue } from '../common/consts/underwritings-initial-constants';
/* eslint-enable */

@Component({
  selector: 'app-amazon-api',
  templateUrl: './amazon-api.component.html',
})
export class AmazonApiComponent implements OnInit, OnChanges {
  @Input() amazonLoanDecision: boolean;
  @Input() UWRules: UWRules;
  @Input() amazonHealth: any;

  headerType: UnderwritingInitialCardHeaderStatusType;
  headerStatus: string;
  amazonHealthValue = AmazonHealthValue;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes) {
    this.setHeaderDetails();
  }

  setHeaderDetails() {
    if (this.isSuccessHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.SUCCESS;
      this.headerStatus = 'Valid';
    }
    if (this.isDangerHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.DANGER;
      this.headerStatus = 'Needs review';
    }
  }

  isSuccessHeaderType() {
    return this.amazonLoanDecision;
  }

  isDangerHeaderType() {
    return !this.amazonLoanDecision;
  }
}
