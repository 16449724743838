import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { routes as adminRoutes } from './admin/admin.routes';
import { routes as welcomeRoutes } from './app-welcome/app-welcome-routing/app-welcome-routing.routes';
import { routes as tasksRoutes } from './crm-tools/tasks-routing.routes';
import { routes as emailRoutes } from './emails/emails-routing.routes';
import { routes as manageRoutes } from './manage/manage-routing.routes';
import { routes as marketplacesRoutes } from './marketplaces/marketplaces-routing.routes';
import { routes as processesRoutes } from './processes/processes-routing.routes';
import { routes as reportsRoutes } from './reports/reports-routing.routes';
import { MainLayoutComponent } from './shared/layout/main-layout.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { routes as supplierRoutes } from './suppliers/suppliers-routing.routes';
import { routes as UwRoutes } from './underwriting/underwriting-routing.routes';

const redirectToWelcomeRoute = {
  path: '',
  redirectTo: '/welcome',
  pathMatch: 'full',
};

export const generalAppRoutes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      ...welcomeRoutes,
      ...adminRoutes,
      ...tasksRoutes,
      ...emailRoutes,
      ...manageRoutes,
      ...marketplacesRoutes,
      ...processesRoutes,
      ...reportsRoutes,
      ...supplierRoutes,
      ...UwRoutes,
      redirectToWelcomeRoute,
    ],
  },
  redirectToWelcomeRoute,
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(generalAppRoutes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutesModule {}
