<mat-form-field *ngIf="shouldRenderSelect; else notSelect">
  <mat-select [placeholder]="actionField.name" (selectionChange)="selectChange($event)" [formControl]="selectFormControl">
    <mat-option *ngFor="let choice of actionField.choices" [value]="choice">
      {{ choice }}
    </mat-option>
  </mat-select>
  <mat-error> This field is required </mat-error>
</mat-form-field>

<ng-template #notSelect>
  <mat-form-field>
    <input matInput [placeholder]="actionField.name" (change)="inputChange($event)" [formControl]="inputFormControl" />
    <mat-error> This field is required </mat-error>
  </mat-form-field>
</ng-template>
