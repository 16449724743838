<div class="offer-inputs-container">
  <div class="offer-inputs-container__card-content">
    <div>
      <div *ngIf="isNewOffer">
        <span>Source of Advance: Onboarding</span>
      </div>

      <div class="underwriting-risk-rating line-row">
        <span>Risk Rating:</span>
        <span *ngIf="isNewOffer; else notNewOfferUnderwritingRiskRating">
          {{ underwritingRiskRatingDescription }}
        </span>

        <ng-template #notNewOfferUnderwritingRiskRating>
          <mat-form-field class="line-row__value">
            <mat-select
              (selectionChange)="changeUnderwritingRiskRating($event)"
              [(ngModel)]="underwritingRiskRating"
              [disabled]="disableInputsWithoutRequestedAmount"
            >
              <mat-option [value]="-1">Default</mat-option>
              <mat-option *ngFor="let r of [].constructor(10); let i = index" [value]="i"> {{ i + 1 }}r </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
      </div>

      <div *ngIf="isNewOffer">
        <span>Recommended Limit: {{ recommended.descriptionGrossAmount }}</span>
      </div>

      <div *ngIf="isNewOffer" class="requested-amount line-row">
        <span>Requested Limit:</span>
        <mat-form-field class="line-row__value">
          <input
            matInput
            (keyup)="changeRequestedAmount($event)"
            (mouseup)="changeRequestedAmount($event)"
            [(ngModel)]="requestedAmount"
            [disabled]="disableRequestedAmount"
            type="number"
          />
        </mat-form-field>
      </div>

      <div *ngIf="!isNewOffer" class="net-delivery-amount line-row">
        <span>Approved Limit:</span>
        <mat-form-field class="line-row__value">
          <input
            matInput
            (keyup)="changeNetDeliveryAmount($event)"
            (mouseup)="changeNetDeliveryAmount($event)"
            [(ngModel)]="netDeliveryAmount"
            [disabled]="disableInputsWithoutRequestedAmount"
            type="number"
          />
        </mat-form-field>
      </div>
      <div class="approved-amount line-row">
        <span *ngIf="isNewOffer; else notNewOfferApprovedAmount">
          Gross Advance Amount (Requested):
          {{ requested.grossAmount | currency: 'USD' }}
        </span>

        <ng-template #notNewOfferApprovedAmount>
          <span>Approved Amount (Gross Advance):</span>
          <mat-form-field class="line-row__value">
            <input
              matInput
              (keyup)="changeApprovedAmount($event)"
              (mouseup)="changeApprovedAmount($event)"
              [(ngModel)]="approvedAmount"
              [disabled]="disableInputsWithoutRequestedAmount"
              type="number"
            />
          </mat-form-field>
        </ng-template>
      </div>

      <div *ngIf="isNewOffer">
        <span>Reserve Amount: {{ requested.grossAmount - requested.netDeliveryAmount | currency: 'USD' }}</span>
      </div>

      <div class="return-strategy line-row">
        <span>Return Strategy:</span>
        <mat-form-field class="line-row__value">
          <mat-select [disabled]="disableReturnStrategy" [(ngModel)]="returnStrategy" (selectionChange)="onReturnStrategyChange()">
            <mat-option *ngFor="let strategyOption of returnStrategyOptions" [value]="strategyOption.value">
              {{ strategyOption.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="mp-reserve line-row">
        <span [ngClass]="{ 'text-warn': isMpReserveForPaymentEmpty }">Maximum Repayment Reserve:</span>
        <mat-form-field class="line-row__value">
          <input
            matInput
            (change)="changeMpReserveForPayment($event)"
            [(ngModel)]="reserveForRepayments"
            [disabled]="disableInputsWithoutRequestedAmount"
            type="number"
          />
        </mat-form-field>
      </div>
      <div class="grace-period line-row">
        <span>Grace Period (Days):</span>
        <mat-form-field class="line-row__value">
          <input
            matInput
            type="number"
            (change)="changeGracePeriodEndDate($event)"
            [(ngModel)]="gracePeriodEndDate"
            [disabled]="isReadonly"
          />
        </mat-form-field>
      </div>
      <div class="no-rebate line-row">
        <span>No Rebate:</span>
        <mat-slide-toggle
          class="line-row__value"
          [(ngModel)]="noRebate"
          (change)="changeNoRebate($event)"
          [color]="'primary'"
          [checked]="noRebate"
          [disabled]="disableInputsWithoutRequestedAmount"
        >
        </mat-slide-toggle>
      </div>
      <div class="no-rebate">
        <span>One Advance Per Limit:</span>
        <mat-slide-toggle [(ngModel)]="forceFull" [disabled]="true"> </mat-slide-toggle>
      </div>
    </div>
    <div *ngIf="forceFullSaved">
      <app-fee-summary *ngIf="isNewOffer; else notNewOfferCalculator" [summaryData]="elementData"> </app-fee-summary>

      <ng-template #notNewOfferCalculator>
        <app-fee-calculator></app-fee-calculator>
      </ng-template>

      <div class="right-card-information">
        <app-additinal-manual-advance-offer-inputs
          [showUwStatusSelector]="showUwStatusSelector"
          [averageFee]="averageFee"
          [isNewOffer]="isNewOffer"
          [requested]="requested"
          [expectedDuration]="expectedDuration"
          [totalPeriodLength]="totalPeriodLength"
          [expectedMonthlyEarnings]="expectedMonthlyEarnings"
          [disableInputsWithoutRequestedAmount]="disableInputsWithoutRequestedAmount"
          [underwritingStatus]="underwritingStatus"
          (onFieldChange)="onAdditionalFieldChange($event)"
        >
        </app-additinal-manual-advance-offer-inputs>
      </div>
    </div>
  </div>

  <div class="offer-inputs-container__footer">
    <div class="offer__inputs__buttons">
      <button
        *ngIf="isNewOffer; else notNewOffer"
        mat-raised-button
        color="primary"
        [disabled]="!isSaveEnabled"
        (click)="saveDataFromStore()"
      >
        Request Offer
      </button>

      <ng-template #notNewOffer>
        <button mat-raised-button color="primary" (click)="resetOffer()" [disabled]="disableInputsWithoutRequestedAmount">
          Clear Manual Inputs
        </button>
      </ng-template>
    </div>

    <mat-form-field>
      <textarea
        *ngIf="isNewOffer; else notNewOfferNotes"
        matInput
        placeholder="Request Notes"
        [(ngModel)]="requestNotes"
        (change)="changeRequestNotes($event)"
      ></textarea>

      <ng-template #notNewOfferNotes>
        <textarea
          matInput
          placeholder="Underwriting Notes"
          [disabled]="disableInputsWithoutRequestedAmount"
          [(ngModel)]="underwriterNotes"
          (change)="changeUnderwritingNotes($event)"
        ></textarea>
      </ng-template>
    </mat-form-field>
  </div>
</div>
