<div class="calculator-row">
  <div class="calculator-row__periods">Period {{ periodNumber + 1 }}</div>
  <div class="calculator-row__weeks">
    <input
      (keyup)="changePeriodLength($event)"
      (mouseup)="changePeriodLength($event)"
      [(ngModel)]="periodLength"
      [disabled]="isReadonly"
      type="number"
    />
    <span class="calculator-row__weeks__label">Weeks</span>
  </div>
  <div class="calculator-row__fee">
    <input (keyup)="changeFee($event)" (mouseup)="changeFee($event)" [(ngModel)]="fee" [disabled]="isReadonly" type="number" />%
  </div>
  <div>
    <button mat-icon-button color="warn" (click)="removeMe()" [disabled]="isRemoveDisabled || isReadonly">
      <mat-icon aria-label="Example icon-button with a heart icon">remove_circle</mat-icon>
    </button>
  </div>
</div>
