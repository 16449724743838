<div [formGroup]="nestedListTemplateForm">
  <mat-list formArrayName="listElements" *ngFor="let element of listElements['controls']; let i = index">
    <div [formGroupName]="i">
      <mat-list-item>
        <button mat-mini-fab *ngIf="hasChildren(i)" (click)="toggleCollapse(i)" color="accent" class="btn btn-sm">
          <mat-icon *ngIf="isCollapsed(i)">keyboard_arrow_right</mat-icon>
          <mat-icon *ngIf="!isCollapsed(i)">keyboard_arrow_down</mat-icon>
        </button>
        <mat-form-field class="full-width">
          <input matInput placeholder="Name" formControlName="name" />
        </mat-form-field>
        <button mat-mini-fab (click)="addNestedListElement(i)" color="primary" class="btn btn-sm"><mat-icon>add</mat-icon></button>
        <button mat-mini-fab (click)="removeRootListElement(i)" color="warn" class="btn btn-sm"><mat-icon>clear</mat-icon></button>
      </mat-list-item>
      <div *ngIf="!isCollapsed(i)">
        <mat-list
          class="nested-list"
          formArrayName="children"
          *ngFor="let child of element['controls'].children['controls']; let j = index"
        >
          <div [formGroupName]="j">
            <mat-list-item>
              <mat-form-field class="full-width">
                <input matInput placeholder="Name" formControlName="name" />
              </mat-form-field>
              <button mat-mini-fab (click)="removeNestedListElement(i, j)" color="warn" class="btn btn-sm">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-list-item>
          </div>
        </mat-list>
      </div>
    </div>
  </mat-list>
  <button mat-raised-button (click)="addRootListElement()" color="primary">Add root item</button>
</div>
