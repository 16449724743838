import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { ElasticHighlightsService, INameAndValuePair } from '../search-engine-service-3-0/elastic-highlights.service';
import { SearchSingleResultModel } from '../search-engine-service-3-0/search-single-result-model';

@Component({
  selector: 'app-search-overlay-single-result',
  templateUrl: './search-overlay-single-result.component.html',
  styleUrls: ['./search-overlay-single-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SearchOverlaySingleResultComponent implements OnInit {
  @Input() searchSingleResult: SearchSingleResultModel;
  @Input() hasRestrictedAccessPermission: boolean;

  @Output() closeOverlay: EventEmitter<string> = new EventEmitter<string>();

  public tempFields: INameAndValuePair[];

  constructor() {}

  ngOnInit() {
    const tempFieldsObj = {
      ...this.searchSingleResult,
    };
    delete tempFieldsObj.legalName_displayed;
    delete tempFieldsObj.supplierKey_displayed;

    this.tempFields = ElasticHighlightsService.convertHighlightObjectToArray(tempFieldsObj);
  }

  closeOverlayRestrict(key: string) {
    this.closeOverlay.emit(key);
  }

  simplyCloseOverlay() {
    this.closeOverlay.emit('');
  }
}
