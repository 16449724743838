/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { share } from 'rxjs/operators';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { PermissionErrorPopUpComponent } from '../../../manage/permission-error-pop-up/permission-error-pop-up.component';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../suppliers/model/supplier.model';
import { selectSupplierDetails } from '../../../suppliers/store/supplier/supplier.actions';
import { AddChargebackComponent } from '../../../suppliers/supplier/add-chargeback/add-chargeback.component';
import { AddContractComponent } from '../../../suppliers/supplier/add-contract/add-contract.component';
import { AddMarketplacePaymentComponent } from '../../../suppliers/supplier/add-marketplace-payment/add-marketplace-payment.component';
import { AddReceivableComponent } from '../../../suppliers/supplier/add-receivable/add-receivable.component';
import { AddRecoveryComponent } from '../../../suppliers/supplier/add-recovery/add-recovery.component';
import { AddWriteoffComponent } from '../../../suppliers/supplier/add-writeoff/add-writeoff.component';
import { AddZeroMarketplacePaymentComponent } from '../../../suppliers/supplier/add-zero-marketplace-payment/add-zero-marketplace-payment.component';
import { AdjustPayabilityFeeComponent } from '../../../suppliers/supplier/adjust-payability-fee/adjust-payability-fee.component';
import { EditMarketplaceStatementComponent } from '../../../suppliers/supplier/edit-marketplace-statement/edit-marketplace-statement.component';
import { DIALOG_SIZE } from '../../dialog.config';
import { FEATURE_FLAGS } from '../../feature-flags/feature-flags.const';
import { UsersService } from '../../users/service/users.service';
import { LoadEarningsComponent } from './../../../suppliers/supplier/load-earnings/load-earnings.component';
import { LoadRemittanceComponent } from './../../../suppliers/supplier/load-remittance/load-remittance.component';
// eslint-disable-next-line max-len
import { SupplierPaymentDebitRequestComponent } from './../../../suppliers/supplier/supplier-payment/supplier-payment-list/supplier-payment-debit-request/supplier-payment-debit-request.component';
import { SubscriberComponent } from './../../component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../users/user-permissions.enum';
/* eslint-enable */

@Component({
  selector: 'app-layout-sidenav-menu',
  styles: [],
  templateUrl: './sidenav-menu.component.html',
})
export class AppSidenavMenuComponent extends SubscriberComponent implements OnInit {
  readonly featureFlags = FEATURE_FLAGS;
  readonly userPermission = USER_PERMISSION;

  opts = {
    barOpacity: 0.5,
    barBackground: '#fff',
    barWidth: 6,
    barBorderRadius: 3,
    gridOpacity: 0.2,
    barMargin: 4,
    gridBackground: '#fff',
    gridWidth: 6,
    alwaysVisible: false,
    visibleTimeout: 2000,
  };
  loggedUser$: Observable<any>;
  shouldShowRemitance = false;

  showActionsMenu = false;
  showSearchButton = true;
  hasCRMPermission;
  isSupplierActive: boolean;

  private user;

  constructor(public dialog: MatDialog, private store: Store<AppState>, private userService: UsersService, private router: Router) {
    super();
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        this.user = user;

        this.subscriptions.push(
          this.userService.checkPermission(USER_PERMISSION.CRM_ADMIN, this.user).subscribe((permitted) => {
            this.hasCRMPermission = permitted;
          }),
        );
      }),
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select('layout').subscribe((data) => {
        this.showActionsMenu = data.actionsVisible;
        this.showSearchButton = data.searchButtonVisible;
      }),
    );

    this.loggedUser$ = this.store.select(selectLoggedUserModel).pipe(share());

    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetailsModel: SupplierDetailsModel) => {
        if (!(supplierDetailsModel === undefined)) {
          const isAmazonVendor = <boolean>supplierDetailsModel.isAmazonVendor;
          const isTarget = <boolean>supplierDetailsModel.isTarget;
          this.shouldShowRemitance = isAmazonVendor || isTarget;
          this.isSupplierActive = supplierDetailsModel.status?.supplierStatus === 'Active';
        }
      }),
    );
  }

  openPermissionErrorComponent() {
    const dialogRef = this.dialog.open(PermissionErrorPopUpComponent, {
      width: DIALOG_SIZE.RWD_50_AUTO_HEIGHT.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  navigateToEvents(): void {
    this.hasCRMPermission ? this.router.navigate(['/manage/events']) : this.openPermissionErrorComponent();
  }

  loadEarnings() {
    const dialogRef = this.dialog.open(LoadEarningsComponent, {
      width: '50vw',
    });
  }

  loadRemittance() {
    const dialogRef = this.dialog.open(LoadRemittanceComponent, {
      width: '50vw',
    });
  }

  openAddChargebackDialog() {
    const dialogRef = this.dialog.open(AddChargebackComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  openAddReceivableDialog() {
    const dialogRef = this.dialog.open(AddReceivableComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  openAddWriteoffDialog() {
    const dialogRef = this.dialog.open(AddWriteoffComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  openAddNewDefaultContractDialog() {
    const dialogRef = this.dialog.open(AddContractComponent, {
      width: DIALOG_SIZE.MEDIUM.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  openAddRecoveryDialog() {
    const dialogRef = this.dialog.open(AddRecoveryComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  openEditMpStatementDialog() {
    this.dialog.open(EditMarketplaceStatementComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });
  }

  openAddMpPaymentDialog() {
    const dialogRef = this.dialog.open(AddMarketplacePaymentComponent, {
      width: DIALOG_SIZE.RWD_40.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  openAddZeroPaymentDialog() {
    const dialogRef = this.dialog.open(AddZeroMarketplacePaymentComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  openAdjustFeeDialog() {
    const dialogRef = this.dialog.open(AdjustPayabilityFeeComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  openAddFundTransferRequest() {
    const dialogRef = this.dialog.open(SupplierPaymentDebitRequestComponent, {
      width: DIALOG_SIZE.DEFAULT.width,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }
}
