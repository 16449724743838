<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-title">New Report</div>

      <form class="custom-form" [formGroup]="addReportForm" (ngSubmit)="onSubmit()">
        <div class="form-content">
          <fieldset class="form-section">
            <mat-form-field>
              <mat-select placeholder="Choose Database View/Table" formControlName="reportName">
                <mat-option *ngFor="let record of viewsAndTables" [value]="record">
                  {{ record }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Report Name" formControlName="reportLabel" />
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Enable at Supplier Level" formControlName="enabledForSupplier">
                <mat-option *ngFor="let record of enabledForSupplierDictionary" [value]="record.id">
                  {{ record.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-checkbox formControlName="enabledForReports">Report is visible on report list</mat-checkbox>
          </fieldset>
        </div>
        <div class="form-submit">
          <button mat-raised-button class="btn btn-cancel pull-right" color="warn" (click)="onCancel()">Cancel</button>
          <button mat-raised-button class="btn btn-success pull-right" type="submit" color="accent">Save</button>
        </div>
      </form>
    </div>
  </div>
</section>
