import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectLoggedUserModel } from '../../auth/store/auth.actions';
import { AuthState } from '../../auth/store/auth.reducers';
import { AppConfigService } from '../../config';
import * as fromApp from '../../store/app.reducers';
import { SIDENAV_CLOSED_EVENT, TableEventsService } from '../table-events.service';
import { VersionService } from '../version.service';
import { SubscriberComponent } from './../component-subscriber/subscriber.component';

@Component({
  selector: 'app-layout-sidenav',
  styleUrls: ['./sidenav.component.scss'],
  templateUrl: './sidenav.component.html',
})
export class AppSidenavComponent extends SubscriberComponent implements OnInit {
  public authState: Observable<AuthState>;
  public loggedUser$: Observable<any>;
  public themeConfig;
  public feVersion: string;
  public beVersion: string;
  public flags: string;
  @ViewChild('sidebar', { static: true }) sidenav;

  constructor(
    private appConfig: AppConfigService,
    private store: Store<fromApp.AppState>,
    private el: ElementRef,
    private tableEventsService: TableEventsService,
    private versionService: VersionService,
  ) {
    super();
    this.feVersion = this.versionService.getFrontendVersion();
    this.themeConfig = appConfig.theme;
  }

  ngOnInit(): void {
    this.getBackendVersion();

    this.authState = this.store.select('auth');
    this.loggedUser$ = this.store.select(selectLoggedUserModel);

    $(this.el.nativeElement).on('mouseleave', (e) => {
      this.tableEventsService.broadcast(SIDENAV_CLOSED_EVENT);
    });
  }

  getBackendVersion(): void {
    this.subscriptions.push(
      this.versionService.getBackendVersion().subscribe((version: string) => {
        this.beVersion = version;
      }),
    );
  }

  toggleCollapsedNav() {
    this.themeConfig.navCollapsed = !this.themeConfig.navCollapsed;
  }
}
