<mat-card class="onboardingMarketplaces__card">
  <mat-card-content>
    <h5>Marketplaces to be shown in onboarding</h5>

    <form class="onboardingMarketplaces__card__form" [formGroup]="marketplacesForm">
      <mat-radio-group class="onboardingMarketplaces__card__form__radioGroup" formControlName="marketplacesOption">
        <mat-radio-button class="onboardingMarketplaces__card__form__radio" value="show_all">All marketplaces</mat-radio-button>
        <mat-radio-button class="onboardingMarketplaces__card__form__radio" value="auto_create"
          >Select Default Marketplace</mat-radio-button
        >
        <mat-radio-button class="onboardingMarketplaces__card__form__radio" value="show_chosen">Pre-select Marketplaces</mat-radio-button>
      </mat-radio-group>

      <mat-divider></mat-divider>

      <div *ngIf="isVisible()">
        <p class="onboardingMarketplaces__card__form__paraph">Select Marketplace to Add</p>

        <mat-form-field>
          <mat-select *ngIf="filteredMarketplaces" placeholder="Select Marketplace" formControlName="selectedMarketplace">
            <mat-option *ngFor="let marketplace of filteredMarketplaces" [value]="marketplace">
              {{ marketplace.marketplaceName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button
          class="onboardingMarketplaces__card__form__button"
          mat-raised-button
          color="accent"
          (click)="addMarketplace()"
          [disabled]="filteredMarketplaces.length < 1"
        >
          <i class="material-icons">add</i>
          Add Marketplace
        </button>

        <p class="onboardingMarketplaces__card__form__paraph">Added Marketplaces</p>

        <app-drag-and-drop-list [list]="selectedMarketplaces" formControlName="marketplaces"></app-drag-and-drop-list>
      </div>
    </form>
  </mat-card-content>
</mat-card>
