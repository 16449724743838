import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';

import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { PermissionModel } from './../../../shared/users/permission.model';
import { UsersService } from './../../../shared/users/service/users.service';

@Component({
  selector: 'app-perm-groups-list',
  templateUrl: './perm-groups-list.component.html',
  styleUrls: ['./perm-groups-list.component.scss'],
})
export class PermGroupsListComponent extends SubscriberComponent implements OnInit {
  // Fields
  public permissions: PermissionModel[];

  public displayedColumns = ['permDesc', 'action'];
  public dataSource = new MatTableDataSource();

  public numberOfRecords = 0;

  constructor(private service: UsersService, private toastrService: ToastrService) {
    super();
    this.dataSource = new MatTableDataSource();
  }

  public ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    this.subscriptions.push(
      this.service.getPermissions().subscribe(
        (data) => {
          if (data) {
            this.permissions = data.filter((item) => item.assignable === true);

            // bind data
            this.dataSource.data = this.permissions;
          }
        },
        () => {
          this.toastrService.error('Getting permissions list failed.');
        },
      ),
    );
  }
}
