<h6>Opt In Periods</h6>
<ng-template #noOptInPeriods>
  <div>There is no no opt on periods defined.</div>
</ng-template>

<table class="mdl-data-table" *ngIf="optInPeriods && optInPeriods.length; else noOptInPeriods">
  <thead>
    <tr>
      <th class="mdl-data-table__cell--non-numeric">Start</th>
      <th class="mdl-data-table__cell--non-numeric">End</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let period of optInPeriods">
      <td class="mdl-data-table__cell--non-numeric">{{ period.startDate | date: 'mediumDate' }}</td>
      <td class="mdl-data-table__cell--non-numeric">{{ period.endDate | date: 'mediumDate' }}</td>
      <td app-confirm-delete class="delete-optin text-center" (onConfirm)="deleteOptInPeriod(period)">
        <button mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="showAddRow; then addRowForm; else addRowButton"></div>
<ng-template #addRowForm>
  <div class="opt-in-add-wrapper">
    <form [formGroup]="addOptInPeriodForm" #formAddNote>
      <div class="row">
        <mat-form-field class="col-md-4">
          <input matInput [matDatepicker]="pickerFrom" placeholder="Choose a start date" formControlName="startDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="col-md-4">
          <input matInput [matDatepicker]="pickerTo" placeholder="Choose an end date" formControlName="endDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <div class="col-md-4 text-center">
          <button
            (click)="addOptInRow()"
            [disabled]="addOptInPeriodForm.invalid || addInProgress"
            [ngClass]="{ 'btn-busy': addInProgress }"
            class="pull-right clearfix"
            mat-raised-button
            color="primary"
          >
            <mat-icon>check</mat-icon>
          </button>

          <a href="#" *ngIf="!addInProgress" class="pull-right" (click)="showAddRow = false">Cancel adding</a>
        </div>

        <div class="col-md-12">
          <span class="help-text-wrapper warning">Dates are inclusive and cannot overlap.</span>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #addRowButton>
  <div class="opt-in-add-wrapper">
    <div class="align-items-end d-flex justify-content-between flex-md-row-reverse">
      <div>
        <button mat-raised-button color="default" (click)="showAddRow = true" [disabled]="isNotApproved">Add period</button>
        <button
          *ngIf="checkIfAmazon()"
          class="pull-right btn-gap-h"
          mat-raised-button
          (click)="toggleMarketplaceSuspension()"
          [disabled]="!isSuspended"
        >
          Add Suspension Reason
        </button>
      </div>
      <mat-checkbox
        color="primary"
        *ngIf="optInPeriods?.length === 0"
        [(ngModel)]="isNotApproved"
        (change)="onIsNotApprovedChange(isNotApproved)"
      >
        Not Yet Approved
      </mat-checkbox>
    </div>
  </div>
</ng-template>
