import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CommunicationService } from './communication/communication.service';
import { IFRAME_COMMUNICATION_PARENT_FRONTEND_DOMAIN } from './communication/iframe-communication.const';
import { IframeComponent } from './iframe.component';

@NgModule({
  declarations: [IframeComponent],
  imports: [CommonModule],
  providers: [{ provide: IFRAME_COMMUNICATION_PARENT_FRONTEND_DOMAIN, useValue: '*' }, CommunicationService],
  exports: [IframeComponent],
})
export class IframeModule {}
