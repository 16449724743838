export enum TASK_COLUMN {
  ID = 'id',
  ITEM_NUMBER = 'item_number',
  TITLE = 'title',
  AUTHOR = 'author',
  CREATED_AT = 'created_at',
  STATUS = 'status',
  PRIORITY = 'priority',
  DUE_DATE = 'due_date',
  ASSIGNEE = 'assignee',
  SUPPLIER = 'supplier',
  ACTIONS = 'actions',
}
