<ng-template #loadingCard>
  <div class="card-content">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>

<div class="card">
  <div class="card-content">
    <div class="d-flex tobBlock">
      <app-title-with-icon [iconClass]="'fa-gears'" [title]="'Manual UW Review'"> </app-title-with-icon>
      <div class="tobBlock__right" *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
        <mat-checkbox color="primary" (change)="selectAll($event)" [disabled]="isDisabled">Select All</mat-checkbox>
        <button mat-raised-button color="primary" (click)="saveForm()" [disabled]="isDisabled">Save</button>
      </div>
    </div>
    <div class="divider custom-margin"></div>
    <div class="row">
      <form class="checkboxes" *ngIf="uwReviewForm; else loadingCard" [formGroup]="uwReviewForm">
        <mat-checkbox class="col-md-4" color="primary" formControlName="MARQETA_KYC_FULL_NAME">Full Name Verified</mat-checkbox>
        <mat-checkbox class="col-md-4" color="primary" formControlName="MARQETA_KYC_SSN">SSN Verified</mat-checkbox>
        <mat-checkbox class="col-md-4" color="primary" formControlName="MARQETA_KYC_DOB">DOB Verified</mat-checkbox>
        <mat-checkbox class="col-md-4" color="primary" formControlName="MARQETA_KYC_RESIDENTAL_ADDRESS"
          >Residential Address Verified</mat-checkbox
        >
        <mat-checkbox class="col-md-4" color="primary" formControlName="MARQETA_KYC_OFAC_CHECK">OFAC Check Completed</mat-checkbox>
        <mat-checkbox *ngIf="show" class="col-md-4" color="primary" formControlName="MARQETA_KYC_BUSINESS_OFAC_CHECK"
          >Business OFAC Check Completed</mat-checkbox
        >
        <mat-checkbox *ngIf="show" class="col-md-4" color="primary" formControlName="MARQETA_KYC_BUSINESS_ADDRESS"
          >Business Address Verified</mat-checkbox
        >
        <mat-checkbox *ngIf="show" class="col-md-4" color="primary" formControlName="MARQETA_KYC_LEGAL_ENTITY"
          >Legal Entity Verified</mat-checkbox
        >
        <mat-checkbox *ngIf="show && !isSoleProp" class="col-md-4" color="primary" formControlName="MARQETA_KYC_TIN"
          >TIN Verified</mat-checkbox
        >
      </form>
    </div>
  </div>
</div>
