import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isArray, isNullOrUndefined, isUndefined } from 'util';

import { CardFormgroupMessageService } from '../../../shared/card-formgroup/card-formgroup-message.service';
/* eslint-enable */
import { IIframeDefinition } from '../../../shared/interfaces/iframe-definition.interface';
import { CrmStates } from '../../../shared/layout/layout.enums';
import { LayoutState } from '../../../shared/layout/store/layout.reducers';
import { Logger } from '../../../shared/logger';
import { AppState } from '../../../store/app.reducers';
import { OnboardingTaskProgressModel } from '../../model/onboarding-task-progress.model';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { SupplierMarketplaceDetailsModel } from '../../model/supplier-marketplace.model';
import { RxEditBaseComponent } from '../../rx-edit-base.component';
import { COMPLIANCE_API_DEFAULT_VERSION, SupplierComplianceActionService } from '../../services/supplier-compliance-action.service';
import { B2bWorkflowService } from '../../services/workflow/b2b-workflow.service';
import { FetchSupplierMarketplaces, selectSupplierDetails, selectSupplierMarketplaces } from '../../store/supplier/supplier.actions';
import { SupplierUnderwritingService } from '../supplier-underwriting/supplier-underwriting.service';
import { IExternalReport } from './../../../reports/model/i-external-report';
import { ExternalReportsService } from './../../../reports/service/external-reports.service';
import { ErrorLogger } from './../../../shared/services/error-logger/error-logger.service';
import { OnboardingProcessModalComponent } from './onboarding-process-modal/onboarding-process-modal.component';
/* eslint-disable */
import { ProductMatrixInitializeTablesService } from './services-product-matrix/service-table/service/product-matrix-initialize-tables.service';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY, ONBOARDING_PROGRESS_KEY, SupplierOnboardingService } from './supplier-onboarding.service';

@Component({
  selector: 'app-supplier-onboarding',
  templateUrl: './supplier-onboarding.component.html',
  styleUrls: ['./supplier-onboarding.component.scss'],
})
export class SupplierOnboardingComponent extends RxEditBaseComponent implements OnInit, OnDestroy {
  private getHistoryList$: Subscription;
  onboardingCheckAction: any;

  amazonMarketplaceDetailsLoading: boolean;
  public serviceColumns = ['product', 'amount', 'start_date', 'onboarding_outcome', 'uw_status', 'status'];
  public paymentConfigurationColumns = ['payment_option', 'fee', 'status'];
  public marketplacesColumns = ['marketplace_name', 'fee', 'advance_rate', 'opt_in', 'opt_out', 'status'];
  public dataSource = new MatTableDataSource();
  public serviceDataSource = null;
  public paymentConfigurationDataSource = null;
  public marketplacesDataSource = null;
  public onboardingDetails = null;
  private SERVICE_CLASS_NAME = 'SupplierMarketplacesService';
  private readonly STATUSES = {
    INTERESTED: 'interested',
    NOT_INTERESTED: 'not interested',
    NOT_OFFERED: 'not offered',
    PENDING: 'pending',
    APPROVED: 'approved',
    ENABLED: 'enabled',
    DISABLED: 'disabled',
    ADDED: 'added',
    AVAILABLE: 'available',
  };
  private readonly LABEL_CLASSES = {
    GREEN: 'green-label',
    RED: 'red-label',
    GRAY: 'gray-label',
    ORANGE: 'orange-label',
    YELLOW: 'yellow-label',
  };
  readonly ML_REPORTS = ['SPF-UNDR'];

  onboardingTaskProgress: OnboardingTaskProgressModel = new OnboardingTaskProgressModel();
  onboardingTaskProgres: Array<OnboardingTaskProgressModel> = [];

  supplierDetailsModel: SupplierDetailsModel;

  amazonMarketplaceDetails: SupplierMarketplaceDetailsModel;

  subscriptions: Subscription[] = [];
  supplierKey: string;
  marketplaceKey: string;

  layoutState$: Store<LayoutState>;
  crmPanelStates = CrmStates;

  isRunOnboardingWorkflowDisabled = true;
  disableOnBoarding = false;

  supplierMarketplacesState$;
  supplierMarketplacesState;
  suppMPKeys;
  public marketplaceSaleInformation: MatTableDataSource<any>;
  iframesDefinitions: IIframeDefinition[] = [];

  constructor(
    store: Store<AppState>,
    messageService: CardFormgroupMessageService,
    errorLogger: ErrorLogger,
    private readonly route: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly onboardingService: SupplierOnboardingService,
    private readonly supplierUnderwritingService: SupplierUnderwritingService,
    private readonly complianceActionService: SupplierComplianceActionService,
    private readonly taskMonitorService: B2bWorkflowService,
    public readonly dialogService: MatDialog,
    private readonly externalReportsService: ExternalReportsService,
  ) {
    super(store, messageService, errorLogger);
  }

  ngOnInit() {
    this.layoutState$ = this.store.select('layout');

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((supplierDetailsModel: SupplierDetailsModel) => {
          this.supplierKey = supplierDetailsModel.supplierKey;

          this.supplierDetailsModel = supplierDetailsModel;
          this.store.dispatch(
            new FetchSupplierMarketplaces({
              supplierDetailsModel,
              refresh: true,
            }),
          );

          this.ML_REPORTS.forEach((report) => {
            this.subscriptions.push(
              this.externalReportsService.getDetail(report).subscribe((response: IExternalReport[]) => {
                response.map((externalReport: IExternalReport) => {
                  const customParamUrl = externalReport.customParamUrl;
                  this.iframesDefinitions.push({
                    name: externalReport.customName ? externalReport.customName : externalReport.name,
                    url: customParamUrl.replace('<supplier_key>', this.supplierKey),
                  });
                });
              }),
            );
          });

          this.checkUnderwritingListHistory();
        }),
    );

    this.amazonMarketplaceDetailsLoading = true;

    this.subscriptions.push(
      this.store.select(selectSupplierMarketplaces).subscribe(
        (marketplaces) => {
          if (this.supplierDetailsModel) {
            this.setMarketplaces(marketplaces);
          }
          this.prepareSaleInformationDataSource();
          this.amazonMarketplaceDetails = undefined;

          if (!isUndefined(marketplaces)) {
            this.amazonMarketplaceDetails = marketplaces.find((marketplaceModel: SupplierMarketplaceDetailsModel) => {
              return marketplaceModel.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY;
            });
          }

          this.amazonMarketplaceDetailsLoading = false;
        },
        (error) => {
          Logger.log(error);
          this.amazonMarketplaceDetailsLoading = false;
        },
      ),
    );

    const supplierOboardingTask = 'onboarding-960ce3ef-437c-49f2-b25f-e6db8abc7343';
    const onboardingTaskProgres: OnboardingTaskProgressModel = new OnboardingTaskProgressModel();

    this.subscriptions.push(
      this.taskMonitorService.subscribeTask(supplierOboardingTask, (onboardingTask) => {
        onboardingTaskProgres.taskProgressData = onboardingTask;
        onboardingTaskProgres.isRunning = true;
        onboardingTaskProgres.iterationStep = onboardingTask.progressStep;
        onboardingTaskProgres.maxIterationStep = onboardingTask.progressCount;

        if (onboardingTask.finishedAt && onboardingTask.step > 0) {
          onboardingTaskProgres.iterationStep = 0;
          onboardingTaskProgres.maxIterationStep = 0;
          onboardingTaskProgres.isRunning = false;
        }

        this.onboardingTaskProgres.push(onboardingTaskProgres);
      }),
    );
  }

  public getStatusLabelClass(element) {
    let onboardingOutcome = element.onboarding_outcome;

    if (typeof onboardingOutcome === 'boolean') {
      onboardingOutcome = onboardingOutcome ? 'enabled' : 'disabled';
    }

    if (!isNullOrUndefined(onboardingOutcome)) {
      const lowercasedOnboardingOutcome = onboardingOutcome.toLowerCase();

      if (
        lowercasedOnboardingOutcome === this.STATUSES.INTERESTED ||
        lowercasedOnboardingOutcome === this.STATUSES.ENABLED ||
        lowercasedOnboardingOutcome === this.STATUSES.APPROVED ||
        lowercasedOnboardingOutcome === this.STATUSES.AVAILABLE
      ) {
        return this.LABEL_CLASSES.GREEN;
      } else if (lowercasedOnboardingOutcome === this.STATUSES.NOT_INTERESTED || lowercasedOnboardingOutcome === this.STATUSES.DISABLED) {
        return this.LABEL_CLASSES.RED;
      } else if (lowercasedOnboardingOutcome === this.STATUSES.NOT_OFFERED) {
        return this.LABEL_CLASSES.GRAY;
      } else if (lowercasedOnboardingOutcome === this.STATUSES.PENDING) {
        return this.LABEL_CLASSES.ORANGE;
      } else if (lowercasedOnboardingOutcome === this.STATUSES.ADDED) {
        return this.LABEL_CLASSES.YELLOW;
      }

      return '';
    }

    return '';
  }

  private setMarketplaces(marketplaces: any) {
    this.supplierMarketplacesState = marketplaces;
    if (marketplaces && marketplaces[0]) {
      this.suppMPKeys = marketplaces;
      for (let i = 0; i < this.suppMPKeys.length; i++) {
        /* eslint-disable */
        const supplierOboardingTask = `Onboarding ${this.supplierDetailsModel.legalName} (${this.supplierKey}) - ${this.suppMPKeys[i].marketplaceName} (${this.suppMPKeys[i].mpSupKey})`;
        /* eslint-enable */

        const onboardingTaskProgres: OnboardingTaskProgressModel = new OnboardingTaskProgressModel();

        this.subscriptions.push(
          this.taskMonitorService.subscribeTask(supplierOboardingTask, (onboardingTask) => {
            onboardingTaskProgres.taskProgressData = onboardingTask;
            onboardingTaskProgres.isRunning = true;
            onboardingTaskProgres.iterationStep = onboardingTask.progressStep;
            onboardingTaskProgres.maxIterationStep = onboardingTask.progressCount;
            onboardingTaskProgres.taskName = supplierOboardingTask;
            onboardingTaskProgres.itemName = this.suppMPKeys[i].marketplaceName;

            if (onboardingTask.finishedAt && onboardingTask.progressStep > 0) {
              onboardingTaskProgres.iterationStep = 0;
              onboardingTaskProgres.maxIterationStep = 0;
              onboardingTaskProgres.isRunning = false;
            }
            if (this.onboardingTaskProgres.length !== 0) {
              if (this.onboardingTaskProgres.filter((el) => el._taskProgressData.taskName === onboardingTask.taskName).length > 0) {
                for (let index = 0; index < this.onboardingTaskProgres.length; index++) {
                  if (this.onboardingTaskProgres[index]._taskProgressData.taskName === onboardingTask.taskName) {
                    this.onboardingTaskProgres[index] = onboardingTaskProgres;
                  }
                }
              }

              return;
            } else {
              this.onboardingTaskProgres.push(onboardingTaskProgres);
            }
          }),
        );
      }
    }
  }

  private checkUnderwritingListHistory() {
    if (this.getHistoryList$ && !this.getHistoryList$.closed) {
      this.getHistoryList$.unsubscribe();
    }

    this.getHistoryList$ = this.supplierUnderwritingService.getHistoryList(this.supplierKey).subscribe((data) => {
      if (data && data.length && data[0].status === 'verified') {
        this.isRunOnboardingWorkflowDisabled = false;
      }
    });

    this.subscriptions.push(this.getHistoryList$);
  }

  isOnboardingWorkflowDisabled() {
    return (
      this.onboardingTaskProgress.isRunning ||
      (this.supplierDetailsModel.status.supplierStatus !== 'Active' && this.isRunOnboardingWorkflowDisabled)
    );
  }

  runOnboardingWorkflow() {
    const dialogRef = this.dialogService.open(OnboardingProcessModalComponent, {
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (!confirm) {
        return;
      }

      if (confirm.hasOwnProperty('skipScraping') && confirm.skipScraping && confirm.hasOwnProperty('sendEmail')) {
        this.onboardingService.startOnboardingWorkflow(this.supplierKey, confirm.skipScraping, confirm.mpKeys, confirm.sendEmail).subscribe(
          () => {
            this.disableOnBoarding = true;
            this.toastr.success('Onboarding Workflow Process Started');
          },
          (err) => {
            this.toastr.error(err && err.error && err.error.message ? err.error.message : 'Could not start Onboarding Workflow Process.');
          },
        );

        return;
      }

      this.onboardingService.startOnboardingWorkflow(this.supplierKey, confirm.skipScraping, confirm.mpKeys, confirm.sendEmail).subscribe(
        (resp) => {
          this.disableOnBoarding = true;
          this.showWorkflowWarnings(resp.warnings);
          if (resp.workflows.length) {
            this.toastr.success('Onboarding Workflow Process Started');
          }
        },
        (err) => {
          this.toastr.error(err && err.error && err.error.message ? err.error.message : 'Could not start Onboarding Workflow Process.');
        },
      );
    });
  }

  showWorkflowWarnings(warnings) {
    warnings.forEach((mp) => {
      let toastText = `${mp.marketplace}: `;
      Object.keys(mp.messages).forEach((key) => {
        mp.messages[key].forEach((text) => {
          toastText += `${key}: ${text.replace('<br />', '')} `;
        });
      });
      this.toastr.warning(toastText);
    });
  }

  startOnboardingWorkflow() {
    const updateStatus = (compilanceActionStatusKey, newStatus: string) => {
      this.subscriptions.push(
        this.complianceActionService
          .updateAction({ status: newStatus }, compilanceActionStatusKey, COMPLIANCE_API_DEFAULT_VERSION)
          .subscribe(
            (updateResult) => {
              this.toastr.success('Onboarding Workflow Process Started');
            },
            (error) => {
              Logger.log(error);
              this.toastr.error('Could not start Onboarding Workflow Process.');
            },
          ),
      );
    };

    this.subscriptions.push(
      this.complianceActionService
        .getAction(this.supplierKey, ONBOARDING_PROGRESS_KEY)
        .pipe(
          map((result) => {
            if (isArray(result) && result.length > 0) {
              return result[0];
            } else {
              return result;
            }
          }),
        )
        .subscribe((onboardingAction) => {
          this.onboardingCheckAction = onboardingAction;

          if (this.onboardingCheckAction.status === 'incomplete') {
            // TODO nkler: use const / readonly instead hard coded string
            this.complianceActionService
              .createAction({
                compliance_key: ONBOARDING_PROGRESS_KEY,
                compliance_action_key: onboardingAction.compliance_action_key,
                compliance_action_type: onboardingAction.compliance_action_type,
                supplier_key: this.supplierKey,
                label: '', // TODO nkler: is it required?
              })
              .subscribe((actionResult) => {
                // TODO nkler: use const / readonly instead hard coded string
                updateStatus(actionResult.compliance_action_status_key, 'approved');
              });
          } else {
            // TODO nkler: handle edge cases
            const onboardingWorkflowStatusKey = this.onboardingCheckAction.satisfied_by[0].compliance_action_status_key;

            updateStatus(onboardingWorkflowStatusKey, 'approved');
          }
        }),
    );
  }

  protected resolveServiceClassBy(formGroupKey: string): string {
    return this.SERVICE_CLASS_NAME;
  }

  protected resolveParamsBy(formGroupKey: string): any[] {
    return [this.amazonMarketplaceDetails.marketplaceKey];
  }

  protected getSupplierKey(): string {
    return this.supplierKey;
  }

  statusChanged(element) {
    this.updateOnboardingServicesStatuses(element);
  }

  private updateOnboardingServicesStatuses(element) {
    this.subscriptions.push(
      this.onboardingService.updateOnboardingStatuses(this.supplierKey, element).subscribe(
        (response) => this.updateOnboardingServicesStatusesSuccess(response),
        (error) => this.updateOnboardingServicesStatusesError(error),
      ),
    );
  }

  private updateOnboardingServicesStatusesSuccess(response) {
    this.getSupplierOnboardingProducts();
    this.toastr.success('Status has been successfully updated');
  }

  private updateOnboardingServicesStatusesError(error) {
    this.getSupplierOnboardingProducts();
    this.toastr.error(error.error.message ? error.error.message : 'Cannot update data');
  }

  private getSupplierOnboardingProducts() {
    this.subscriptions.push(
      this.onboardingService
        .getSupplierOnboardingProducts(this.supplierKey)
        .subscribe((response) => this.getSupplierOnboardingProductsSuccess(response)),
    );
  }

  private getSupplierOnboardingProductsSuccess(result: any) {
    this.serviceDataSource = new MatTableDataSource(result.services);
    this.paymentConfigurationDataSource = new MatTableDataSource(result.payment_configuration);
    this.paymentConfigurationDataSource = new MatTableDataSource(result.payment_configuration);
    this.marketplacesDataSource = new MatTableDataSource(result.marketplaces);
  }

  private prepareSaleInformationDataSource(): void {
    const saleInfo = [];
    if (!isNullOrUndefined(this.suppMPKeys)) {
      this.suppMPKeys.map((mp: SupplierMarketplaceDetailsModel) => {
        if (mp.marketplaceType === 'marketplace') {
          saleInfo.push({
            marketplace: mp.marketplaceName,
            monthlyRevenue: mp.estimatedMonthlyRevenue
              ? ProductMatrixInitializeTablesService.transformIntoCurrency(mp.estimatedMonthlyRevenue)
              : '-',
            monthlyGrossRevenue: mp.estimatedMonthlyGrossRevenue
              ? ProductMatrixInitializeTablesService.transformIntoCurrency(mp.estimatedMonthlyGrossRevenue)
              : '-',
            timeSelling: mp.timeSellingLength ? mp.timeSellingLength : mp.lengthTimeSelling ? mp.lengthTimeSelling : '-',
            currentPayout: mp.currentPayout ? mp.currentPayout : '-',
            netSales0to30: !isNullOrUndefined(mp.netSales0to30) ? mp.netSales0to30 : '-',
            netSales30to60: !isNullOrUndefined(mp.netSales30to60) ? mp.netSales30to60 : '-',
            netSales60to90: !isNullOrUndefined(mp.netSales60to90) ? mp.netSales60to90 : '-',
            grossSales0to30: !isNullOrUndefined(mp.grossSales0to30) ? mp.grossSales0to30 : '-',
            grossSales30to60: !isNullOrUndefined(mp.grossSales30to60) ? mp.grossSales30to60 : '-',
            grossSales60to90: !isNullOrUndefined(mp.grossSales60to90) ? mp.grossSales60to90 : '-',
          });
        }
      });
      this.marketplaceSaleInformation = new MatTableDataSource(saleInfo);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => sub && sub.unsubscribe());
  }
}
