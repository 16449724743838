export class DataTableQueryParams {
  limit: number;
  offset: number;
  order_by?: string;
}

// data table configuration types
export class DataTableFilterConfigurationRecord {
  label: string;
  value: string;
  order: number;

  custom?: any;
}

export class DataTableSortingConfigurationRecord {
  type: DataTableSortingType;
  column: number;
  asc: boolean;
}

export class DataTableColumnConfigurationRecord {
  data: string;
  displayName: string;
  width: number;

  filter?: DataTableFilterConfigurationRecord;
  type?: string;
  format?: string;
  language?: string;
  editor?: boolean | string;
  dataType?: DataTableDataType;
  selectOptions?: string[];
  renderer?: string | rendererFunctionType;

  onClick?: (record) => void;
  onValueChange?: (record) => void;
}

export class DataTableSettingsRecord {
  pageSize: number;
  orderBy: string;
  columns: DataTableColumnConfigurationRecord[];

  sorting: DataTableSortingConfigurationRecord;
  fixedColumnsLeft: number;
  fixedRowsTop: number;

  customFilters: any[];
  customAction: any[];
  hiddenColumns: string[];
  hiddenFilters: string[];
  linkColumns: any[];

  showSearch?: boolean;
  showFilters?: boolean;

  rowHeaderAction: (row: any) => void;
  columnHeaderAction: (column: any) => void;
}

export class DataTableConfigurationRecord {
  title: string;
  columns: DataTableColumnConfigurationRecord[];
  dictionaries: any;
}

// data table internal types
export class DataTableFilter {
  name: string;
  label: string;
  type: string; // text | data | currency
  order?: number;
  dictionary?: any;
  custom?: any;
  value?: any;
  value_min?: any;
  value_max?: any;
}

export enum DataTableDataType {
  default,
  db,
  calc,
  edit,
  blue,
}

export enum DataTableSortingType {
  disabled,
  frontSide,
  serverSide,
}

export class DataTableDictionaryRecord {
  id: string;
  label: string;
}

type rendererFunctionType = (instance, td, row, col, prop, value, cellProperties) => any;
