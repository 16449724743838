import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-email-frontend-template',
  templateUrl: './email-frontend-template.component.html',
})
export class EmailFrontendTemplateComponent implements OnInit {
  public emailFrontendTemplateForm: FormGroup;

  @Input() configValue;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.configValue) {
      this.createEditForm();
    } else {
      this.createNewForm();
    }
  }

  createNewForm() {
    this.emailFrontendTemplateForm = this.formBuilder.group({
      template_name: [''],
      description: [''],
      subject: [''],
      body: [''],
    });
  }

  createEditForm() {
    this.emailFrontendTemplateForm = this.formBuilder.group({
      template_name: this.configValue.template_name,
      description: this.configValue.description,
      subject: this.configValue.subject,
      body: this.configValue.body,
    });
  }
}
