<mat-tab-group [selectedIndex]="activeIndex">
  <mat-tab label *ngFor="let tab of NAVLINKS" #currentTab>
    <ng-template mat-tab-label>
      <a [routerLink]="tab.route" routerLinkActive #rla="routerLinkActive">
        {{ tab.label }}
      </a>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<div class="card">
  <router-outlet></router-outlet>
</div>
