<section class="container-fluid">
  <div class="card">
    <div class="card-content">
      <div>
        <div>Card Transactions</div>

        <div class="row balance">
          <span class="data-item col-sm-2 col-xs-6">
            <span class="value text-primary">
              {{
                creditInfo?.availableBalance && creditInfo?.availableBalance !== 'None'
                  ? (creditInfo?.availableBalance | currency)
                  : 'Loading...'
              }}
            </span>
            <span class="label text-muted">Available Balance</span>
          </span>
          <span class="data-item col-sm-2 col-xs-6">
            <span class="value text-primary">
              {{
                creditInfo?.arBilledBalance && creditInfo?.arBilledBalance !== 'None'
                  ? (creditInfo?.arBilledBalance | currency)
                  : 'Loading...'
              }}
            </span>
            <span class="label text-muted">Total Card Spend</span>
          </span>
          <span class="data-item col-sm-2 col-xs-6">
            <span class="value text-primary">
              {{ creditInfo?.creditLimit && creditInfo?.creditLimit !== 'None' ? (creditInfo?.creditLimit | currency) : 'Loading...' }}
            </span>
            <span class="label text-muted">Credit Limit</span>
          </span>
          <span class="data-item col-sm-2 col-xs-6">
            <span class="value text-primary">
              {{
                creditInfo?.unbilledAmount && creditInfo?.unbilledAmount !== 'None' ? (creditInfo?.unbilledAmount | currency) : 'Loading...'
              }}
            </span>
            <span class="label text-muted">Pending Card Txn</span>
          </span>
          <span class="data-item col-sm-2 col-xs-6">
            <span class="value text-primary">{{ creditInfo?.lastUsedDate || 'Loading...' }}</span>
            <span class="label text-muted">Last Used Date</span>
          </span>
        </div>

        <div class="config-form row">
          <div class="card-title">Card Transactions Configuration</div>

          <form *ngIf="cardCongigForm" [formGroup]="cardCongigForm" class="col-md-2 col-xs-12">
            <app-inline-edit-text
              type="number"
              [formControlName]="'efsRebatePercent'"
              [formGroup]="cardCongigForm"
              [label]="'Rebate  Percentage'"
              [isRequired]="true"
              (onSave)="updateEFSRebate(updateEFSRebate)"
            >
            </app-inline-edit-text>
          </form>

          <form *ngIf="isVelocityControlsFormVisible" [formGroup]="cardTransactionsConfigForm" class="col-md-10 col-xs-12">
            <div class="row">
              <span class="col-md-2 col-xs-12">
                <app-inline-edit-text
                  type="number"
                  [formControlName]="'max_xact_count_per_hour'"
                  [formGroup]="cardTransactionsConfigForm"
                  [label]="'Card Transactions (Hourly Limit)'"
                  [isRequired]="true"
                  (onSave)="saveCardTransactionsConfig(cardTransactionsConfigForm)"
                >
                </app-inline-edit-text>
              </span>
              <span class="col-md-2 col-xs-12">
                <app-inline-edit-text
                  type="number"
                  [formControlName]="'max_xact_amount_at_once'"
                  [formGroup]="cardTransactionsConfigForm"
                  [label]="'Transaction Spend (Max. Amount)'"
                  [isRequired]="true"
                  (onSave)="saveCardTransactionsConfig(cardTransactionsConfigForm)"
                >
                </app-inline-edit-text>
              </span>
              <span class="col-md-2 col-xs-12">
                <app-inline-edit-text
                  type="number"
                  [formControlName]="'max_xact_amount_daily'"
                  [formGroup]="cardTransactionsConfigForm"
                  [label]="'Daily Spend (Max. Amount)'"
                  [isRequired]="true"
                  (onSave)="saveCardTransactionsConfig(cardTransactionsConfigForm)"
                >
                </app-inline-edit-text>
              </span>
              <span class="col-md-2 col-xs-12">
                <app-inline-edit-yes-no
                  [formControlName]="'override_foreign_fraud_check'"
                  [formGroup]="cardTransactionsConfigForm"
                  [label]="'Override Foreign Fraud Check'"
                  [isRequired]="false"
                  (onSave)="saveCardTransactionsConfig(cardTransactionsConfigForm)"
                >
                </app-inline-edit-yes-no>
              </span>
              <span class="col-md-3 col-xs-12">
                <button mat-raised-button color="primary" matSuffix (click)="exportCurrentData()">Export Current</button>
                <button mat-raised-button color="primary" matSuffix (click)="exportAllData()">Export All</button>
              </span>
            </div>
          </form>
        </div>
      </div>

      <div class="">
        <div class="buttons">
          <button
            *ngFor="let button of dateButtons"
            [disabled]="isDataLoading"
            [ngClass]="{ 'picked-button-color': button.isPicked, '': !button.isPicked }"
            (click)="loadData(button)"
            class="margin-right"
            mat-raised-button
            color="primary"
            matSuffix
          >
            {{ button.time }} days
          </button>
        </div>
      </div>
      <app-filtering-panel-component
        (searchValueEmitter)="getFilteredMarqeta($event)"
        [configuration]="filterConfig"
      ></app-filtering-panel-component>
      <div *ngIf="cardTransactionHistoryData && !isDataLoading; else loading">
        <app-data-table-grid [rows]="cardTransactionHistoryData" [columnDefs]="cardTransactionHistoryColumnDefs" [domLayout]="'autoHeight'">
        </app-data-table-grid>
      </div>
      <ng-template #loading>
        <app-loading-spinner [size]="2"></app-loading-spinner>
      </ng-template>
    </div>
  </div>
</section>
