<div class="card">
  <div class="card-content">
    <app-underwriting-initial-card-header
      [chipText]="'Automated'"
      [chipColor]="'accent'"
      [title]="'Amazon Advertising'"
      [status]="headerStatus"
      [type]="headerType"
    >
    </app-underwriting-initial-card-header>
    <div class="table-wrapper">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="10%" class="text-right">Target</th>
            <th width="10%" class="text-right">Value</th>
            <th width="20%" class="text-left">Decision</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Does supplier advertise with Amazon?</td>
            <td class="verticalCenter">0%</td>
            <td class="verticalCenter">
              <span *ngIf="amazonHealth">
                {{ amazonHealth?.advertising_cost_of_sales ? amazonHealth.advertising_cost_of_sales.value + '%' : noDataText }}
              </span>
            </td>
            <td>
              <mat-form-field class="selectSize">
                <mat-select [(value)]="amazonAdvertising">
                  <mat-option *ngFor="let option of amazonAdvertisings" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-hint>Automated pre-selected option</mat-hint>
              </mat-form-field>
            </td>
          </tr>
          <br />
          <tr>
            <td colspan="2">Override Amazon Advertising results</td>
            <td colspan="2" class="text-right" width="20%">
              <ng-container *ngIf="!computingDataIsInProgress">
                <app-radiobuttons
                  [value]="amazonAdvertisingOverride[0].value"
                  [labelPosition]="'before'"
                  [options]="radiobuttons"
                  (onChange)="radiobuttonsChange($event)"
                >
                </app-radiobuttons>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
