<section *ngIf="owners">
  <div *ngFor="let owner of owners; index as i">
    <div>
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-12 minor-title"><ng-container *ngIf="!owner.mainOwner">Additional </ng-container>Beneficial Owner</div>
          </div>

          <app-title-with-icon [iconClass]="'fa-user'" [title]="owner.firstName + ' ' + owner.lastName"> </app-title-with-icon>

          <div class="divider divider-sm"></div>

          <div class="row">
            <div class="col-md-12 minor-title">Address</div>

            <div class="col-md-12">
              <app-text-value [label]="'Address Line 1'" [value]="owner?.address"> </app-text-value>
            </div>

            <div class="col-md-12">
              <app-text-value [label]="'Address Line 2'" [value]="owner?.address2"> </app-text-value>
            </div>

            <div class="col-md-6">
              <app-text-value [label]="'Country'" [value]="owner?.country"> </app-text-value>
            </div>

            <div class="col-md-6">
              <app-text-value [label]="'State'" [value]="owner?.stateCode"> </app-text-value>
            </div>

            <div class="col-md-6">
              <app-text-value [label]="'City'" [value]="owner?.city"> </app-text-value>
            </div>

            <div class="col-md-6">
              <app-text-value [label]="'Zip'" [value]="owner?.zipPostalCode"> </app-text-value>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12 minor-title">Details</div>

            <div class="col-md-6">
              <app-text-value [label]="'SSN'" [value]="owner?.SSN"> </app-text-value>
            </div>

            <div class="col-md-6">
              <app-text-value [label]="'Date of Birth'" [value]="owner?.dateOfBirth"> </app-text-value>
            </div>

            <div class="col-md-12">
              <app-text-value [label]="'Ownership %'" [value]="owner?.shares" [emptyValue]="'-'"> </app-text-value>
            </div>

            <app-main-verification-checkboxes *ngIf="isAdditionalOwnerCheckmarked" [isCheckmarked]="isAdditionalOwnerCheckmarked[i]">
            </app-main-verification-checkboxes>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="additionalOwner">
        <app-uw-review
          [supplierKey]="supplierDetailsModel.supplierKey"
          [owner]="additionalOwner[i]"
          [key]="additionalOwnerKey[i]"
          [show]="isShown"
          [isDisabled]="isDisabled"
        ></app-uw-review>
      </div>
    </div>
  </div>
</section>
