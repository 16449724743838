<div class="card-header advances">
  <mat-tab-group>
    <mat-tab label="Advances">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-6">
            <app-pending-offer (onLoaded)="onLoaded($event)"></app-pending-offer>
          </div>
          <div class="col-12 col-sm-6 col-lg-6">
            <app-active-advance-line (isPending)="isPending($event)"></app-active-advance-line>
          </div>
        </div>
        <div>
          <div class="card-header">
            <div class="card-title">Requested Offers</div>
          </div>
          <app-requested-advances></app-requested-advances>
        </div>
        <div>
          <app-approved-advance-lines></app-approved-advance-lines>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngFor="let iframeDefinition of iframesDefinitions" [label]="iframeDefinition.name"
      ><app-iframe *ngIf="iframeDefinition.url" [url]="iframeDefinition.url"></app-iframe>
    </mat-tab>
  </mat-tab-group>
</div>
