<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content [hidden]="showSpinner">
  <app-action-upload
    *ngFor="let uploadField of uploadFields"
    [uploadField]="uploadField"
    (fileUuid)="fileUuid($event)"
    (isSpinner)="isSpinner($event)"
  ></app-action-upload>
  <app-action-field
    *ngFor="let actionField of actionFields"
    [actionField]="actionField"
    (updateValue)="actionFieldChange($event)"
  ></app-action-field>
</div>
<ng-container *ngIf="showSpinner">
  <div>
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-container>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="submit()" [disabled]="!readyToSend">Submit</button>
  <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
</div>
