import { IMatchScore } from '../real-time-validation/real-time-validator.interfaces';

export const GetTextFromScore = (score: IMatchScore): string => {
  if (!score) {
    return '--';
  }

  if (!score.hasOwnProperty('#text')) {
    return '--';
  }

  return score['#text'];
};
