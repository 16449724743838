import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-min-fee-overrides-table',
  templateUrl: './min-fee-overrides-table.component.html',
  styleUrls: ['./min-fee-overrides-table.component.scss'],
})
export class MinFeeOverridesTableComponent implements OnInit {
  @Input() minFeesOverrides = [];

  showAddRow = false;

  constructor() {}

  ngOnInit() {}

  deleteMinFeeOverride() {}
}
