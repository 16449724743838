import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import { CsvExportService } from '../../../shared/csv-export-service/csv-export.service';
import { DataTableQueryParams } from '../../../shared/data-table/data-table.common';
import { CurrencyCellRenderer } from '../../../shared/functions/currency-cell-renderer';
import { DateCellRenderer } from '../../../shared/functions/date-cell-renderer';
import { NumberCellRenderer } from '../../../shared/functions/number-cell-renderer';
import { ADD_TRANSACTION_EVENT, TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { KeyValue } from './../../../shared/models/key-value';
import { IPaginationData } from './../../../shared/pyb-pagination/pagination-data';
import { ReceivableService } from './../add-receivable/service/receivable.service';

@Component({
  selector: 'app-supplier-receivables',
  templateUrl: './supplier-receivables.component.html',
  styleUrls: ['./supplier-receivables.component.scss'],
})
export class SupplierReceivablesComponent extends SubscriberComponent implements OnInit {
  @HostBinding('class.is-open')
  isBlocked = false;

  public readonly reportName: string = 'xacts/details';
  public readonly exportPath: string = 'xacts/details/export_csv';

  public reportData: Observable<any>;
  public reportConfig: Observable<any>;

  public baseHttpParams: any;
  public reportConfigName: string;
  public exportMenuOptions: any;

  public supplierKey: string;
  public tableSettings: any;
  public currentQueryParams: any;
  public columns;
  public rows: any;
  public filterConfig: any;
  public paginationData?: IPaginationData;
  public total: number;
  public offset: number;
  public refreshLoading = false;

  private filterParams: KeyValue<string> = {};

  constructor(
    private readonly route: ActivatedRoute,
    private readonly service: SupplierService,
    private readonly csvExportService: CsvExportService,
    private readonly eventService: TableEventsService,
    private readonly toastrService: ToastrService,
    private readonly store: Store<AppState>,
    private readonly receivableService: ReceivableService,
  ) {
    super();
  }

  ngOnInit() {
    this.baseHttpParams = {
      limit: 20,
      offset: 0,
    };

    this.exportMenuOptions = {
      'Export to CSV': () => {
        this.exportCsv();
      },
    };

    this.filterConfig = [
      {
        fieldName: 'amznDateGte',
        label: 'Marketplace Date',
        fieldType: 'datePicker',
        isConnecting: true,
      },
      {
        fieldName: 'amznDateLte',
        label: '',
        fieldType: 'datePicker',
      },
      {
        fieldName: 'payabilityDateGte',
        label: 'Payability Purchased Date',
        fieldType: 'datePicker',
        isConnecting: true,
      },
      {
        fieldName: 'payabilityDateLte',
        label: '',
        fieldType: 'datePicker',
      },
      {
        fieldName: 'chargebackDateGte',
        label: 'Payability Chargeback Date',
        isConnecting: true,
        fieldType: 'datePicker',
      },
      {
        fieldName: 'chargebackDateLte',
        label: '',
        fieldType: 'datePicker',
      },
      {
        fieldName: 'transactionStatus',
        label: 'Payment Status',
        fieldType: 'input',
      },
      {
        fieldName: 'marketplaceName',
        label: 'Marketplace',
        fieldType: 'input',
      },
      {
        fieldName: 'q',
        label: 'Description',
        fieldType: 'input',
      },
      {
        fieldName: 'transactionType',
        label: 'Transaction Type',
        fieldType: 'input',
      },
    ];

    this.columns = [
      {
        field: 'amznDate',
        headerName: 'Marketplace Date',
        cellRenderer: DateCellRenderer,
        width: 100,
      },
      {
        field: 'payabilityDate',
        headerName: 'Payability Purchased Date',
        cellRenderer: DateCellRenderer,
        width: 100,
      },
      {
        field: 'chargebackDate',
        headerName: 'Payability Chargeback Date',
        cellRenderer: DateCellRenderer,
        width: 100,
      },
      {
        field: 'transactionStatus',
        headerName: 'Marketplace Payment Status',
      },
      {
        field: 'marketplaceName',
        headerName: 'Marketplace',
      },
      {
        field: 'description',
        headerName: 'Description',
      },
      {
        field: 'transactionType',
        headerName: 'Transaction Type',
      },
      {
        field: 'receivable',
        headerName: 'Receivable Amount',
        cellRenderer: CurrencyCellRenderer,
        width: 115,
      },
      {
        field: 'chargeback',
        headerName: 'Maketplace Charges / Refunds & Holds',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'netAmount',
        headerName: 'Net',
        cellRenderer: CurrencyCellRenderer,
        width: 60,
      },
      {
        field: 'flexParticipationPercent',
        headerName: 'Participation Rate',
        cellRenderer: NumberCellRenderer,
        width: 110,
      },
      {
        field: 'fundingRate',
        headerName: 'Funding Rate',
        type: 'text',
        width: 85,
      },
    ];

    this.eventService.on(ADD_TRANSACTION_EVENT, () => {
      this.getData(this.currentQueryParams);
    });

    this.subscriptions.push(
      this.receivableService.change.subscribe(() => {
        this.isBlocked = true;
        setTimeout(() => {
          this.isBlocked = false;
        }, 8000);
      }),
    );

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;

          this.getData();
        }),
    );
  }

  getData(params?: DataTableQueryParams): void {
    if (!params) {
      params = this.baseHttpParams;
    }

    params = { ...params, ...this.filterParams };
    this.subscriptions.push(
      this.service.getSupplierReport(this.supplierKey, this.reportName, params).subscribe((data) => {
        this.rows = data.results;
        this.total = data.total;
        this.offset = params.offset;
        this.setPaginationData();
      }),
    );
  }

  onFilterChanged(params: KeyValue<string>): void {
    this.filterParams = params;
    this.getData();
  }

  exportCsv() {
    const args = { ...this.currentQueryParams, ...this.filterParams, make_dump: true };
    this.subscriptions.push(
      this.service.getSupplierReport(this.supplierKey, this.exportPath, args).subscribe((data) => {
        this.csvExportService.exportTransactions(data, 'receivables', '');
      }),
    );
  }

  refreshButton() {
    this.refreshLoading = true;
    this.subscriptions.push(
      this.service
        .refreshCacheSupplier(this.supplierKey)
        .pipe(finalize(() => (this.refreshLoading = false)))
        .subscribe(
          () => {
            this.toastrService.success('The supplier has been updated');
          },
          () => {
            this.toastrService.error('The supplier has not been updated');
          },
        ),
    );
    this.getData();
  }

  onPageChange(dataTableQueryParams: DataTableQueryParams): void {
    this.baseHttpParams = {
      ...dataTableQueryParams,
    };
    this.getData();
  }

  setPaginationData() {
    this.paginationData = {
      ...this.baseHttpParams,
      total: this.total,
    };
  }
}
