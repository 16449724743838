import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, first } from 'rxjs/operators';

export class UwTaskProgressModel {
  DEFAULT_LABEL = '';

  isRunning: boolean;
  currentLabel: string;
  iterationStep: number;
  maxIterationStep: number;

  private _step: number;
  private _count: number;
  private _finishedAt: string;

  private _taskProgressData: any;
  private _refreshSubject = new Subject<boolean>();
  private _refresh: Observable<boolean>;

  constructor() {
    this._refresh = this._refreshSubject.pipe();
    this.taskProgressData = {
      step: 0,
      count: 0,
      finishedAt: null,
    };
  }

  fetchProgressValue(arg1, arg2) {
    if (arg1 && arg2) {
      const value = ((arg1 / arg2) * 100).toFixed(1);
      if (value === '100.0') {
        this._refreshSubject.next(true);
      }
      return value + '%';
    }
  }

  set taskProgressData(value: any) {
    this._taskProgressData = value;
  }

  get step(): number {
    return this._taskProgressData.step;
  }

  get count(): number {
    return this._taskProgressData.count;
  }

  get finishedAt(): string {
    return this._taskProgressData.finishedAt;
  }

  getRefresh(): Observable<boolean> {
    return this._refresh.pipe(
      filter((value) => {
        return this._finishedAt !== null;
      }),
      debounceTime(4500),
      first(),
    );
  }

  getCurrentLabel() {
    if (this._step === 0) {
      return 'Underwriting Workflow started';
    }

    if (this._finishedAt) {
      return 'Underwriting Workflow completed';
    }

    return this.DEFAULT_LABEL;
  }
}
