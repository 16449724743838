import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Deserialize, DeserializeKeysFrom } from 'cerialize';
import { Observable, of } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { ReportColumn, ReportTableConfiguration } from '../../reports/report-table/report-table.interfaces';
import { ServiceUpdatePartialInterface } from '../../shared/interfaces/service-update-partial-interface/service-update-partial-interface';
import { toLowercaseUnderscore } from '../../shared/utils/to-lowercase-underscore.util';
import { PaymentConfigModel } from '../model/payment-config.model';
import { SupplierDetailsModel } from '../model/supplier.model';
import { SupplierBalanceModel } from '../model/supplier-balance.model';
import { SupplierListModel } from '../model/supplier-list.model';
import { SupplierManualBankAccountModel } from '../model/supplier-manual-bank-account.model';
import { TransferPaymentDetails } from '../model/transfer-payment-details.model';
import { Field, InternalReport } from './internal-report';

export const SEARCH_DEBOUNCE_TIME = 800;

export interface GetTokenResponse {
  token: string;
}

@Injectable()
export class SupplierService implements ServiceUpdatePartialInterface {
  private internalApiUrl: string;
  private apiUrl: string;
  // TODO: Upon checking, we found that this functionality is not used.
  //  To be safe, it will be commented out for a period of time before final removal.
  //  Date: 19/05/2021 / Task: APPS-438
  // private supplierApiUrl: string;
  private cliApiUrl: string;
  readonly supplierKeyColumnName = 'supplier_key';

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.internalApiUrl = appConfig.env.internalApiUrl;
    this.apiUrl = this.internalApiUrl + 'suppliers';
    // TODO: Upon checking, we found that this functionality is not used.
    //  To be safe, it will be commented out for a period of time before final removal.
    //  Date: 19/05/2021 / Task: APPS-438
    // this.supplierApiUrl = appConfig.env.supplierApiUrl;
    this.cliApiUrl = appConfig.env.cliApiUrl;
  }

  getItems(queryParams: HttpParams) {
    return this.httpClient.get<SupplierListModel[]>(this.apiUrl, {
      observe: 'body',
      responseType: 'json',
      params: queryParams,
    });
  }

  getToken(supplierKey: string): Observable<GetTokenResponse> {
    return this.httpClient.get(`${this.apiUrl}/${supplierKey}/get_token`, {
      observe: 'body',
      responseType: 'json',
    }) as Observable<GetTokenResponse>;
  }

  fetchSupplierDetails(supplierKey, refresh: boolean = false) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}`;
    let queryParams = new HttpParams().set('detailed', '1').set('taxId', '1');
    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient
      .get<SupplierDetailsModel>(endpointUrl, {
        params: queryParams,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(result, SupplierDetailsModel);
        }),
      );
  }

  saveItem(uuid: string, item: SupplierDetailsModel): Observable<any> {
    return this.httpClient.put(`${this.apiUrl}/${uuid}`, item).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getReport(reportName: string, options: any): Observable<any> {
    const endpointUrl = `${this.internalApiUrl}reports/${reportName}`;

    let queryParams = new HttpParams();

    const isDump = options.hasOwnProperty('make_dump') && options['make_dump'];

    Object.keys(options).forEach((key) => {
      queryParams = queryParams.append(key, options[key]);
    });

    const request = isDump
      ? this.httpClient.get(endpointUrl, { params: queryParams, responseType: 'text' as const })
      : this.httpClient.get(endpointUrl, { params: queryParams });

    return request.pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getStatementsForAddSupplierFundRecovery(supplierKey: string, marketplaceSupplierKey: string): Observable<any> {
    const endpointUrl = `${this.internalApiUrl}suppliers/${supplierKey}/get_statements_for_add_supplier_fund_recovery/${marketplaceSupplierKey}`;
    const request = this.httpClient.get(endpointUrl);

    return request.pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getHealth(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.internalApiUrl}suppliers/${supplierKey}/amazon_health`;

    return this.httpClient.get<Object>(endpointUrl).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getAdvance(supplierKey: string, advanceKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/supplier_advances/${advanceKey}`;

    return this.httpClient.get<Object>(endpointUrl).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );

    // return this.mock$;
  }

  patchAdvance(supplierKey: string, advanceKey: string, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/supplier_advances/${advanceKey}`;

    return this.httpClient.put<Object>(endpointUrl, data).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  addAdvance(supplierKey: string, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/supplier_advances`;

    return this.httpClient.post<Object>(endpointUrl, data).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  deleteAdvance(supplierKey: string, advanceKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/supplier_advances/${advanceKey}`;

    return this.httpClient.delete<Object>(endpointUrl).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  payAdvance(supplierKey: string, advanceKey: string, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/supplier_advances/${advanceKey}/action`;

    return this.httpClient.post<Object>(endpointUrl, data).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  postDelinquencyStatus(supplierKey: string, advanceKey: string, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/supplier_advances/${advanceKey}/change_delinquency`;

    return this.httpClient.post<Object>(endpointUrl, data).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getSupplierReport(supplierKey, reportName: string, options: any): Observable<any> {
    if (!reportName) {
      reportName = 'mp_statements';
    }
    const endpointUrl = `${this.apiUrl}/${supplierKey}/${reportName}`;
    let queryParams = new HttpParams();

    const isDump = options.hasOwnProperty('make_dump') && options['make_dump'];

    Object.keys(options).forEach((key) => {
      queryParams = queryParams.append(key, options[key]);
    });

    const request = isDump
      ? this.httpClient.get(endpointUrl, { params: queryParams, responseType: 'text' as const })
      : this.httpClient.get(endpointUrl, { params: queryParams });

    return request.pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getSupplierNestedReport(supplierKey, reportName: string, nestedKey: string, options: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/${reportName}/${nestedKey}`;

    let queryParams = new HttpParams();
    Object.keys(options).forEach((key) => {
      queryParams = queryParams.append(key, options[key]);
    });

    return this.httpClient.get<Object>(endpointUrl, { params: queryParams }).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  // TODO: Upon checking, we found that this functionality is not used.
  //  To be safe, it will be commented out for a period of time before final removal.
  //  Date: 19/05/2021 / Task: APPS-438
  // getShortLivedToken(): Observable<any> {
  //   const tokenEndpointUrl = `${this.supplierApiUrl}/auth/short_lived_token`;
  //
  //   return this.httpClient.get(`${tokenEndpointUrl}`);
  // }

  // getCsvFile(supplierKey: string, reportName: string): Observable<any> {
  //   const csvFileEndpointUrl = `${this.supplierApiUrl}/suppliers/${supplierKey}/${reportName}/export_csv?short_lived_token=`;
  //
  //   return this.getShortLivedToken().pipe(
  //     mergeMap((data) => {
  //       return this.httpClient.get(`${csvFileEndpointUrl}${data.short_lived_token}`);
  //     }),
  //   );
  // }

  getReportConfig(reportName: string): Observable<ReportTableConfiguration | null> {
    const api = this.appConfig.env.internalApiUrl;
    const endpointUrl = `${api}reports/config`;

    return this.httpClient.get<Array<any>>(endpointUrl).pipe(
      map((result: InternalReport[]) => {
        const filtered = result.filter(this.isInReports(reportName));
        if (!filtered.length) {
          return null;
        }
        return {
          title: filtered[0].label ? filtered[0].label : filtered[0].name,
          columns: filtered[0].fields.map(this.mapFieldsToColums.bind(this)),
        } as ReportTableConfiguration;
      }),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  private mapFieldsToColums(column: Field): ReportColumn {
    const name = Object.keys(column)[0];
    const obj = column[name];

    return {
      data: column[name].label ? column[name].label : Object.keys(column)[0],
      editor: false,
      filter: obj.filterable ? this.getFilter(name) : undefined,
      type: obj.type,
      cellRenderer: !(toLowercaseUnderscore(name) === this.supplierKeyColumnName) ? null : this.prepareSupplierKeyCellRenderer(),
    } as ReportColumn;
  }

  private prepareSupplierKeyCellRenderer(): (cellData: any) => string {
    return (cellData: any) => {
      const supplierKey = cellData.value;
      return supplierKey ? `<a target="_blank" href="#/suppliers/${supplierKey}">${supplierKey}</a>` : '';
    };
  }

  isInReports(reportName: string): (report: InternalReport) => unknown {
    return (report: InternalReport) => {
      return [reportName, `v_${reportName}`, `vm_${reportName}`, `f_${reportName}`].includes(report.name);
    };
  }

  getFilter(name: string): { label: string } {
    const label = toLowercaseUnderscore(name);
    return { label };
  }

  setNewPassword(uuid: string, newPassword: string): Observable<any> {
    const endpoint = `${this.apiUrl}/${uuid}/set_password`;
    const newPasswordData = {
      new_password: newPassword,
    };

    return this.httpClient.post(endpoint, newPasswordData).pipe(
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  saveItemPart(supplierKey: string, itemPart: any): Observable<SupplierDetailsModel> {
    // TODO nkler: backend response does not include 'detailed' model
    // TODO nkler: PUT should be changed to PATCH, when our back-ends start to support http PATCH method
    return this.httpClient.put<SupplierDetailsModel>(`${this.apiUrl}/${supplierKey}`, itemPart).pipe(
      map((result) => {
        DeserializeKeysFrom(CamelCase);
        return Deserialize(result, SupplierDetailsModel);
      }),
    );
  }

  updateHideFlag(isHidden = true, supplier: SupplierDetailsModel) {
    const supplierPartialData = {
      hide: isHidden,
    };
    return this.saveItemPart(supplier.supplierKey, supplierPartialData);
  }

  getSupplierBalance(supplierKey, refresh: boolean = false): Observable<SupplierBalanceModel> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/balance`;
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient.get<SupplierBalanceModel>(endpointUrl, { params: queryParams }).pipe(
      map((result) => {
        DeserializeKeysFrom(CamelCase);
        return Deserialize(result, SupplierBalanceModel);
      }),
    );
  }

  getRiskInfo(supplierKey, refresh: boolean = false): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/risk_score`;
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient.get(endpointUrl, { params: queryParams });
  }

  saveRiskInfo(supplierKey, dataToSave: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/risk_score`;
    return this.httpClient.put(endpointUrl, dataToSave);
  }

  // @TODO: nikodem proper type response for errors
  checkRoutingNum(countryCode: string, viewValue: string): Observable<any> {
    const queryParams = new HttpParams().set('country', countryCode).set('id', viewValue);
    return this.httpClient
      .get(this.appConfig.env.internalApiUrl + 'swift/verify_routing', {
        params: queryParams,
      })
      .pipe(
        catchError((error) => {
          return of({
            error: true,
          });
        }),
      );
  }

  updateSuspendFlag(supplierKey: string, suspend: boolean, reason: any): Observable<any> {
    const suspendUrl = suspend ? 'suspend' : 'unsuspend';
    return this.httpClient.post(`${this.appConfig.env.internalApiUrl}suppliers/${supplierKey}/${suspendUrl}`, reason);
  }

  updateLockFlag(supplierKey: string, lock: boolean): Observable<any> {
    return this.httpClient.patch(`${this.appConfig.env.internalApiUrl}suppliers/${supplierKey}/lock`, {
      lock,
    });
  }

  requestEfsCard(supplierKey: string, url: string): Observable<any> {
    return this.httpClient.post(`${this.appConfig.env.internalApiUrl}suppliers/${supplierKey}/efs/newcard`, { supplier_admin_url: url });
  }

  uploadNewEgg(supplierKey, data) {
    const formData = new FormData();

    Object.keys(data).map((key) => {
      if (key === 'file') {
        formData.append(key, data[key].files[0], data[key].files[0].name);
      } else {
        formData.append(key, data[key]);
      }
    });

    return this.httpClient.post(`${this.appConfig.env.internalApiUrl}suppliers/${supplierKey}/upload_newegg`, formData);
  }

  public requestMarqetaCard(supplierKey: string, cardRequestObject: any) {
    return this.httpClient.post(`${this.internalApiUrl}suppliers/${supplierKey}/marqeta/newcard`, cardRequestObject);
  }

  public activateMarqetaCard(supplierKey: string, marqetaId: string) {
    return this.httpClient.post(`${this.internalApiUrl}suppliers/${supplierKey}/marqeta/activate/${marqetaId}/`, null);
  }

  public blockMarqetaCard(supplierKey: string, marqetaId: string, requestObject) {
    return this.httpClient.post(`${this.internalApiUrl}suppliers/${supplierKey}/marqeta/block/${marqetaId}/`, requestObject);
  }

  public removeMarqetaCard(supplierKey: string, marqetaId: string, requestObject) {
    return this.httpClient.post(`${this.internalApiUrl}suppliers/${supplierKey}/marqeta/remove/${marqetaId}/`, requestObject);
  }

  public replaceMarqetaCard(supplierKey: string, marqetaId: string, requestObject: any) {
    return this.httpClient.post(`${this.internalApiUrl}suppliers/${supplierKey}/marqeta/reissue/${marqetaId}/`, requestObject);
  }

  public getCardTransitionReasons() {
    return this.httpClient.get(`${this.internalApiUrl}suppliers/marqeta/card_reasons`);
  }

  public getSupplierLogins(supplierKey: string) {
    return this.httpClient.get(`${this.appConfig.env.internalApiUrl}suppliers/owners/${supplierKey}`);
  }

  public updateSupplierMainOwner(loginKey: string, mainOwnerObject) {
    return this.httpClient.put(`${this.appConfig.env.internalApiUrl}suppliers/owner/${loginKey}`, mainOwnerObject);
  }

  public refreshCacheSupplier(supplierKey: string) {
    return this.httpClient.post(`${this.cliApiUrl}suppliers/${supplierKey}/cache`, {});
  }

  public getMarketplaceStatements(supplierKey: string, hasMpPayment = '&has_mp_payment=false') {
    return this.httpClient.get(`${this.internalApiUrl}suppliers/${supplierKey}/mp_statements?limit=999999${hasMpPayment}`);
  }

  public updateDataFromMarqeta(supplierKey: string) {
    return this.httpClient.put(`${this.internalApiUrl}suppliers/${supplierKey}/marqeta/company_info`, {});
  }

  public updateMarketplaceStatement(supplierKey: string, mpStatementKey: string, dataToSave: object) {
    return this.httpClient.patch(`${this.internalApiUrl}suppliers/${supplierKey}/marketplace_statement/${mpStatementKey}`, dataToSave);
  }

  public deleteMarketplaceStatement(mpStatementKey: string) {
    return this.httpClient.delete(`${this.internalApiUrl}marketplace_statements/${mpStatementKey}`);
  }

  public createMarketplaceStatement(newStatement: any) {
    return this.httpClient.post(`${this.internalApiUrl}marketplace_statements`, newStatement);
  }

  public updateSupplierHomeAddress(supplierKey: string, dataToSave: object) {
    return this.httpClient.post(`${this.apiUrl}/${supplierKey}/home_address`, dataToSave);
  }

  public getPlaidBalanceInformation(supplierKey: string): Observable<any> {
    return this.httpClient.get(`${this.internalApiUrl}plaid/${supplierKey}/data`);
  }

  public runPlaidApi(supplierKey: string, itemId: string): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}plaid/${supplierKey}/request_report`, { itemId });
  }

  public getPlaidReportUuid(supplierKey: string, itemId: string): Observable<any> {
    return this.httpClient.get(`${this.internalApiUrl}plaid/${supplierKey}/pdf?itemId=${itemId}`);
  }

  public getSupplierNotifications(supplierKey: string): Observable<any> {
    return this.httpClient.get(`${this.internalApiUrl}${supplierKey}/notifications`);
  }

  public activateSupplierNotification(supplierKey: string, notificationType: string): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}${supplierKey}/notifications/${notificationType}/create`, {});
  }

  public deactivateSupplierNotification(supplierKey: string, notificationType: string): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}${supplierKey}/notifications/${notificationType}/deactivate`, {});
  }

  public addSupplierFundRecovery(supplierKey: string, fundRecoveryData: any): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}suppliers/${supplierKey}/add_supplier_fund_recovery`, fundRecoveryData);
  }

  public getSupplierPaymentConfigs(supplierKey: string): Observable<PaymentConfigModel[]> {
    return this.httpClient.get<PaymentConfigModel[]>(`${this.internalApiUrl}suppliers/${supplierKey}/payment/configs`);
  }

  public requestPayment(supplierKey: string, paymentDetails: TransferPaymentDetails): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}suppliers/${supplierKey}/payment/requests`, paymentDetails);
  }

  public addManualBankStatement(
    supplierKey: string,
    bankAccountModel: SupplierManualBankAccountModel,
  ): Observable<SupplierManualBankAccountModel> {
    return this.httpClient.post<SupplierManualBankAccountModel>(
      `${this.internalApiUrl}suppliers/${supplierKey}/manual_bank_data`,
      bankAccountModel,
    );
  }

  public getManualBankStatements(supplierKey: string): Observable<SupplierManualBankAccountModel[]> {
    return this.httpClient.get<SupplierManualBankAccountModel[]>(`${this.internalApiUrl}suppliers/${supplierKey}/manual_bank_data`);
  }

  public configureManualBankStatement(
    supplierKey: string,
    bankAccountKey: string,
    flags: { achContract?: boolean; receivingPayments?: boolean },
  ): Observable<SupplierManualBankAccountModel> {
    if (!flags || [flags.achContract, flags.receivingPayments].every((v) => v === undefined)) {
      throw new Error('SupplierService.configureManualBankStatement: you must specify at least one flag');
    }

    return this.httpClient.put<SupplierManualBankAccountModel>(
      `${this.internalApiUrl}suppliers/${supplierKey}/manual_bank_data/${bankAccountKey}/update`,
      flags,
    );
  }
}
