import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from '../../config';

@Injectable()
export class EfsService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers';
  }

  getEFS(supplierKey: string, resource: string, data: any): Observable<any> {
    const endpoint = `${this.apiUrl}/${supplierKey}/efs/${resource}`;

    return this.httpClient.post(endpoint, data).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  getMarqeta(supplierKey: string, resource: string, data: any): Observable<any> {
    const endpoint = `${this.apiUrl}/${supplierKey}/pcard/${resource}`;

    return this.httpClient.post(endpoint, data).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  downloadEFS(supplierKey: string, data: any): Observable<any> {
    const endpoint = `${this.apiUrl}/${supplierKey}/efs/get_company_report`;

    return this.httpClient.post(endpoint, data, { responseType: 'text' as const }).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  downloadMarqeta(supplierKey: string, data: any): Observable<any> {
    const endpoint = `${this.apiUrl}/${supplierKey}/pcard/get_general_report_v2`;

    return this.httpClient.post(endpoint, data, { responseType: 'text' as const }).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }
}
