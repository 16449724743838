import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { SubscriberComponent } from '../../../../../../shared/component-subscriber/subscriber.component';
import { ErrorVisualizationService } from '../../../../../../shared/services/error-visualization/error-visualization.service';
import { FlexParticipationService } from '../service/flex-participation.service';
import { IFlexParticipation } from '../service/flex-participation-interfaces';

@Component({
  selector: 'app-sub-product-freemium-flex-participation',
  templateUrl: './sub-product-freemium-flex-participation.component.html',
  styleUrls: ['./sub-product-freemium-flex-participation.component.scss'],
})
export class SubProductFreemiumFlexParticipationComponent extends SubscriberComponent implements OnInit {
  @Input() flexDto: IFlexParticipation;
  @Input() supplierKey: string;

  constructor(
    private flexParticipationService: FlexParticipationService,
    private errorVisualizationService: ErrorVisualizationService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {}

  changeFlexEnabled(event) {
    this.subscriptions.push(
      this.flexParticipationService.putEnableFlex(this.supplierKey, event.checked).subscribe(
        (response) => {
          this.toastrService.success(response.message);
        },
        (err: any) => {
          this.errorVisualizationService.showError({ name: 'Switching SD Enabled Error', errorObject: err });
        },
      ),
    );
  }
}
