import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ExternalReportListComponent } from './external-report-list/external-report-list.component';
import { ReportsAbstractService } from './model/reports-abstract.service';
import { ReportFilterPipe } from './pipes/report.pipe';
import { ReportAddComponent } from './report-add/report-add.component';
import { ReportEditComponent } from './report-edit/report-edit.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportTableComponent } from './report-table/report-table.component';
import { ExternalReportsService } from './service/external-reports.service';
import { ReportsRepositoryService } from './service/reports-repository.service';
import { WaitingSuppliersReportComponent } from './waiting-suppliers/waiting-suppliers.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    ReportFilterPipe,
    ReportListComponent,
    ReportEditComponent,
    ReportTableComponent,
    ExternalReportListComponent,
    WaitingSuppliersReportComponent,
    ReportAddComponent,
  ],
  providers: [
    { provide: ReportsAbstractService, useClass: ReportsRepositoryService },
    { provide: ExternalReportsService, useClass: ExternalReportsService },
  ],
})
export class ReportsModule {}
