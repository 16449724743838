import { Action } from '@ngrx/store';

export const RESET_ALL_FORMS = 'RESET_ALL_FORMS';

export class ResetAllForms implements Action {
  readonly type = RESET_ALL_FORMS;
  constructor() {}
}

export type OnboardingActions = ResetAllForms;
