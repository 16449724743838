import { autoserialize, autoserializeIndexable, inheritSerialization } from 'cerialize';

import { BaseCrmModel } from './base-crm.model';

@inheritSerialization(BaseCrmModel)
export class CommentModel extends BaseCrmModel {
  @autoserialize public taskUrl: string;
  @autoserialize public body: string;
  @autoserialize public childrenCount: number;
  @autoserialize public createdByEmail: string;
  @autoserialize public createdByKey: string;
  @autoserialize public instanceId: number;
  @autoserializeIndexable(CommentModel) public comments: CommentModel[] = [];

  constructor(
    createdAt: string,
    ownerUuid: string,
    targetType: string,
    targetUuid: string,
    type: string,
    uuid: string,
    taskUrl: string,
    body: string,
    childrenCount: number,
    createdByEmail: string,
    createdByKey: string,
    instanceId: number,
    comments: CommentModel[],
  ) {
    super(createdAt, ownerUuid, targetType, targetUuid, type, uuid);
    this.taskUrl = taskUrl;
    this.body = body;
    this.childrenCount = childrenCount;
    this.createdByEmail = createdByEmail;
    this.createdByKey = createdByKey;
    this.instanceId = instanceId;
    this.comments = comments || [];
  }
}
