import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';

import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { PermissionModel } from './../../../shared/users/permission.model';
import { UsersService } from './../../../shared/users/service/users.service';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-perm-groups-edit',
  templateUrl: './perm-groups-edit.component.html',
  styleUrls: ['./perm-groups-edit.component.scss'],
})
export class PermGroupsEditComponent extends SubscriberComponent implements OnInit {
  // Fields
  public editPermissionGroupForm: FormGroup;

  public isLocked = false;

  private currentPermission: PermissionModel;
  private currentSubpermissions: PermissionModel[];
  private permCode: USER_PERMISSION | string;

  // Flags
  private isLoaded = false;

  // Accessors
  public get title(): string {
    return !this.isLoaded ? 'Loading...' : `${this.currentPermission.permDesc}`;
  }

  public get permissions(): FormArray {
    return this.editPermissionGroupForm ? (this.editPermissionGroupForm.get('permissions') as FormArray) : this.formBuilder.array([]);
  }

  public get rootPermissions(): FormArray {
    return this.editPermissionGroupForm ? (this.editPermissionGroupForm.get('rootPermissions') as FormArray) : this.formBuilder.array([]);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: UsersService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  // Methods
  public ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.permCode = params['permGroupKey'];

        this.getData();
      }),
    );

    this.setupComponent();
  }

  public toggleLock(): void {
    this.isLocked = !this.isLocked;
    this.disableForm();
  }

  public getPlaceholder(group: FormGroup): string {
    let result = '';

    if (group) {
      const control = group.get('permDesc');
      if (control) {
        result = control.value;
      }
    }

    return result;
  }

  public onDelete(): void {
    this.subscriptions.push(
      this.service.removePermission(this.permCode).subscribe(
        () => {
          this.toastrService.success('Permission group has been successfully deleted.');
          this.router.navigate(['../../../perm-groups/list'], { relativeTo: this.route });
        },
        () => {
          this.toastrService.error('Deleting permission group failed.');
        },
      ),
    );
  }

  public onSubmit(): void {
    if (this.editPermissionGroupForm.valid) {
      this.subscriptions.push(
        this.updatePermission(this.editPermissionGroupForm.value).subscribe(
          () => {
            const permissions = this.editPermissionGroupForm.value.permissions;
            const rootPermissions = this.editPermissionGroupForm.value.rootPermissions;

            const allPermissions = [...permissions, ...rootPermissions];

            this.subscriptions.push(
              this.updateSubpermissions(this.permCode, allPermissions).subscribe(
                () => {
                  this.toastrService.success('New permission has been successfully added.');
                  this.router.navigate(['../../../perm-groups/list'], { relativeTo: this.route });
                },
                (secondError) => {
                  const detailedMessage =
                    secondError && secondError.error && secondError.error.message ? ' ' + secondError.error.message : '';
                  this.toastrService.error('Failed to add new permission. ' + detailedMessage);
                },
              ),
            );
          },
          (firstError) => {
            const detailedMessage = firstError && firstError.error && firstError.error.message ? ' ' + firstError.error.message : '';
            this.toastrService.error('Failed to add new permission. ' + detailedMessage);
          },
        ),
      );
    }
  }

  public onCancel(): void {
    this.router.navigate(['../../../perm-groups/list'], { relativeTo: this.route });
  }

  private disableForm(): void {
    if (this.editPermissionGroupForm && this.editPermissionGroupForm.get('permCode')) {
      this.isLocked ? this.editPermissionGroupForm.disable() : this.editPermissionGroupForm.enable();
      this.editPermissionGroupForm.controls['permCode'].disable();
    }
  }

  private setupComponent(): void {
    this.editPermissionGroupForm = this.formBuilder.group({
      permCode: [{ value: '', disabled: false }, Validators.required],
      permDesc: [{ value: '', disabled: false }, Validators.required],
      generalPerm: [false],
      specSupPerm: [false],
      permissions: this.formBuilder.array([]),
      rootPermissions: this.formBuilder.array([]),
    });

    this.disableForm();
  }

  private getData(): void {
    this.subscriptions.push(
      this.service.getPermissions().subscribe(
        (data) => {
          if (data) {
            const permissions = data.filter((item) => item.assignable === true);
            const rootPermissions = data.filter((item) => item.assignable !== true);

            if (this.editPermissionGroupForm) {
              // ged edited permission group data
              this.currentPermission = permissions.find((item) => item.permCode === this.permCode);
              if (this.currentPermission) {
                this.editPermissionGroupForm.patchValue({
                  permCode: this.currentPermission.permCode,
                  permDesc: this.currentPermission.permDesc,
                  generalPerm: this.currentPermission.generalPerm,
                  specSupPerm: this.currentPermission.specSupPerm,
                });
              }

              // get assignable permission groups data
              const groups = permissions.map((permission) =>
                this.formBuilder.group({
                  selected: [false],
                  permCode: [permission.permCode],
                  permDesc: [permission.permDesc],
                }),
              );

              const permissionsArray = this.formBuilder.array(groups);
              this.editPermissionGroupForm.setControl('permissions', permissionsArray);

              // get root permission groups data
              const rootGroups = rootPermissions.map((permission) =>
                this.formBuilder.group({
                  selected: [false],
                  permCode: [permission.permCode],
                  permDesc: [permission.permDesc],
                }),
              );

              const rootPermissionsArray = this.formBuilder.array(rootGroups);
              this.editPermissionGroupForm.setControl('rootPermissions', rootPermissionsArray);

              this.getSubpermissions();
              this.disableForm();
            }
          }
        },
        () => {
          this.toastrService.error('Getting permissions list failed.');
        },
      ),
    );
  }

  private getSubpermissions(): void {
    this.subscriptions.push(
      this.service.getSubpermissions(this.permCode).subscribe(
        (data) => {
          if (data) {
            if (this.editPermissionGroupForm) {
              this.currentSubpermissions = data;

              // set subpermissions from assignable groups
              const permissionsFA = this.editPermissionGroupForm.get('permissions') as FormArray;
              if (permissionsFA) {
                data.forEach((singleRecord) => {
                  const control = permissionsFA.controls.find((singleControl) => singleControl.value.permCode === singleRecord.permCode);
                  if (control) {
                    control.patchValue({
                      selected: true,
                    });
                  }
                });
              }

              // set subpersmissions form root groups
              const rootPermissionsFA = this.editPermissionGroupForm.get('rootPermissions') as FormArray;
              if (rootPermissionsFA) {
                data.forEach((singleRecord) => {
                  const control = rootPermissionsFA.controls.find(
                    (singleControl) => singleControl.value.permCode === singleRecord.permCode,
                  );
                  if (control) {
                    control.patchValue({
                      selected: true,
                    });
                  }
                });
              }
            }

            this.isLoaded = true;
            this.disableForm();
          }
        },
        () => {
          this.toastrService.error('Getting group subpermissions list failed.');
        },
      ),
    );
  }

  private updatePermission(data: any): Observable<any> {
    const init = {
      generalPerm: data.generalPerm,
      specSupPerm: data.specSupPerm,
      permDesc: data.permDesc,
    };

    return this.service.updatePermission(this.permCode, init);
  }

  private updateSubpermissions(
    permCode: USER_PERMISSION | string,
    permissions: { selected: boolean; permCode: USER_PERMISSION | string; permDesc: string }[],
  ): Observable<any> {
    const newPermissions = permissions.filter((item) => item.selected === true);
    const permissionsToAdd = this.getPermissionsToAdd(this.currentSubpermissions, newPermissions);
    const permissionsToRemove = this.getPermissionsToRemove(this.currentSubpermissions, newPermissions);

    const requests = [];

    permissionsToAdd.map((permission) => {
      const data = {
        permCode: permission.permCode,
      };
      requests.push(this.service.addSubpermission(permCode, data));
    });

    permissionsToRemove.map((permission) => {
      requests.push(this.service.removeSubpermission(permCode, permission.permCode));
    });

    if (requests && requests.length > 0) {
      return forkJoin(requests);
    } else {
      return of({});
    }
  }

  private getPermissionsToAdd(
    oldPermissions: { permCode: USER_PERMISSION | string }[],
    newPermissions: { permCode: USER_PERMISSION | string }[],
  ): { permCode: USER_PERMISSION | string }[] {
    const result = [];

    newPermissions.forEach((newPerm) => {
      const perm = oldPermissions.find((oldPerm) => oldPerm.permCode === newPerm.permCode);
      if (!perm) {
        result.push(newPerm);
      }
    });

    return result;
  }

  private getPermissionsToRemove(
    oldPermissions: { permCode: USER_PERMISSION | string }[],
    newPermissions: { permCode: USER_PERMISSION | string }[],
  ): { permCode: USER_PERMISSION | string }[] {
    const result = [];

    oldPermissions.forEach((oldPerm) => {
      const perm = newPermissions.find((newPerm) => newPerm.permCode === oldPerm.permCode);
      if (!perm) {
        result.push(oldPerm);
      }
    });

    return result;
  }
}
