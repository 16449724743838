import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject, combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { ActionFormComponent } from './action-form.component';

@Component({
  selector: 'app-actions-form',
  templateUrl: './actions-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ActionsFormComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  @Input() topForm: FormArray;
  @Input() loaded$: Observable<any>;
  @ViewChildren('actions') actionComponents: QueryList<ActionFormComponent>;
  public value$ = new Subject();

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.loaded$.subscribe((eventRule) => {
        while (this.topForm.length !== 0) {
          this.topForm.removeAt(0);
        }
        for (const action of eventRule.actions) {
          this.addAction();
        }
      }),
    );
  }

  ngAfterViewInit() {
    const actionComponents$ = new BehaviorSubject(this.actionComponents.toArray());
    this.subscriptions.push(this.actionComponents.changes.pipe(map((list) => list.toArray())).subscribe(actionComponents$));

    this.subscriptions.push(
      actionComponents$
        .asObservable()
        .pipe(
          map((list) => list.map((actionFormComponent) => actionFormComponent.actionValue$)),
          switchMap((valueObservables) => observableCombineLatest(...valueObservables)),
        )
        .subscribe(this.value$),
    );
  }

  addAction() {
    this.topForm.push(this.fb.group({}));
  }
}
