import { UWDailyDataSource } from '../underwriting-daily/uw-daily-data-source';

export const GetUpdatedRules = (
  keyArray,
  dailyForm,
  collectedData: UWDailyDataSource | null,
): { [key: string]: { error_value: string } } => {
  return {
    [keyArray[0]]: {
      error_value: Number.isFinite(dailyForm.value.order_defect_rate)
        ? '' + dailyForm.value.order_defect_rate
        : collectedData.order_defect_rate_rule.error_value,
    },
    [keyArray[1]]: {
      error_value: Number.isFinite(dailyForm.value.late_shipment_rate)
        ? '' + dailyForm.value.late_shipment_rate
        : collectedData.late_shipment_rate_rule.error_value,
    },
    [keyArray[2]]: {
      error_value: Number.isFinite(dailyForm.value.late_responses)
        ? '' + dailyForm.value.late_responses
        : collectedData.late_responses_rule.error_value,
    },
    [keyArray[3]]: {
      error_value: Number.isFinite(dailyForm.value.pre_fullfillment_cancel_rate)
        ? '' + dailyForm.value.pre_fullfillment_cancel_rate
        : collectedData.pre_fullfillment_cancel_rate_rule.error_value,
    },
    [keyArray[4]]: {
      error_value: Number.isFinite(dailyForm.value.delivered_on_time_rate)
        ? '' + dailyForm.value.delivered_on_time_rate
        : collectedData.delivered_on_time_rate_rule.error_value,
    },
    [keyArray[5]]: {
      error_value: Number.isFinite(dailyForm.value.valid_tracking_rate)
        ? '' + dailyForm.value.valid_tracking_rate
        : collectedData.valid_tracking_rate_rule.error_value,
    },
    [keyArray[6]]: {
      error_value: Number.isFinite(dailyForm.value.return_dissatisfaction_rate)
        ? '' + dailyForm.value.return_dissatisfaction_rate
        : collectedData.return_dissatisfaction_rate_rule.error_value,
    },
  };
};
