<ng-container *ngIf="record && record.advanceLineData">
  <mat-table #table [dataSource]="record.advanceLineData">
    <ng-container matColumnDef="show_inner_table">
      <mat-header-cell [ngClass]="'expansion-header'" *matHeaderCellDef></mat-header-cell>
      <mat-cell [ngClass]="'expansion-header'" *matCellDef="let setting">
        <div (click)="record.panelExpanded = !record.panelExpanded" class="expansion-panel">
          <div class="pointer rotation rotation_initial" [ngClass]="{ rotation_90: record.panelExpanded }">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="advance_line_key">
      <mat-header-cell class="advance-line-key" *matHeaderCellDef>Advance Line ID</mat-header-cell>
      <mat-cell
        (click)="advanceLineKeyClick(setting.advance_line_key)"
        class="advance-line-key advance-line-key-cell"
        *matCellDef="let setting"
      >
        {{ setting.advance_line_key }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="limit_amount">
      <mat-header-cell *matHeaderCellDef>Total Limit Amount</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.limit_amount | currency }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="outstanding_limit_amount">
      <mat-header-cell *matHeaderCellDef>Remaining Limit Amount</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.outstanding_limit_amount | currency }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="max_extra_reserve_percent">
      <mat-header-cell *matHeaderCellDef>Max Extra Reserve Percent</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.max_extra_reserve_percent }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="create_ts">
      <mat-header-cell *matHeaderCellDef>Create Date</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.create_ts | date: 'MM/dd/yyyy' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.status }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="total_extra_reserve">
      <mat-header-cell *matHeaderCellDef>Total Extra Reserve</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.total_extra_reserve | currency }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="total_fee">
      <mat-header-cell *matHeaderCellDef>Total Fee</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.total_fee | currency }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="advances_number">
      <mat-header-cell *matHeaderCellDef># of Advances</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.advances.length }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="return_strategy">
      <mat-header-cell *matHeaderCellDef>Return Strategy</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.return_strategy }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="underwriting_date">
      <mat-header-cell class="underwriting_date" *matHeaderCellDef>Underwriting Date</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.underwriting_date ? setting.underwriting_date : '-' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="expected_monthly_earnings">
      <mat-header-cell class="expected_monthly_earnings" *matHeaderCellDef>Expected Monthly Earnings</mat-header-cell>
      <mat-cell *matCellDef="let setting">
        {{ setting.expected_monthly_earnings ? (setting.expected_monthly_earnings | currency) : '-' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expected_duration">
      <mat-header-cell class="expected_duration" *matHeaderCellDef>Expected Duration</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.expectedDuration ? setting.expectedDuration : '-' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="max_duration">
      <mat-header-cell class="max_duration" *matHeaderCellDef>Max Duration</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.totalPeriodLength ? setting.totalPeriodLength : '-' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="average_fee">
      <mat-header-cell class="average_fee" *matHeaderCellDef>Line Fee %</mat-header-cell>
      <mat-cell *matCellDef="let setting">{{ setting.averageFee ? setting.averageFee : 'setting.averageFee' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="re_underwrite">
      <mat-header-cell class="re_underwrite" *matHeaderCellDef>Re Underwrite</mat-header-cell>
      <mat-cell *matCellDef="let setting">
        <span class="material-icons action-icon" matTooltip="Click to re underwrite line" (click)="reUnderwriteClick(setting)">
          settings_backup_restore
        </span>
      </mat-cell>
    </ng-container>
    <div>
      <mat-header-row [class.display-none]="rowIndex !== 0" *matHeaderRowDef="columns"></mat-header-row>
    </div>
    <mat-row *matRowDef="let myRowData; columns: columns"></mat-row>
  </mat-table>
</ng-container>
