import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { ReportListModel } from '../model/report-list-model';
import { EditableReportFieldModel, EditableReportModel, ReportModel } from '../model/report-model';
import { ReportsAbstractService } from '../model/reports-abstract.service';

@Injectable()
export class ReportsRepositoryService extends ReportsAbstractService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private http: HttpClient) {
    super();

    this.apiUrl = appConfig.env.internalApiUrl + 'reports';
  }

  getItems(): Observable<ReportListModel[]> {
    return this.http.get(`${this.apiUrl}/config`).pipe(
      map((response) => {
        const mappedResult = [];
        for (const item of <any>response) {
          mappedResult.push(new ReportListModel(item));
        }
        return mappedResult;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  getItem(uuid: string): Observable<ReportModel> {
    return this.http.get(`${this.apiUrl}/${uuid}`).pipe(
      map((response) => new ReportModel(response)),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  saveItem(uuid: string, item: ReportModel): Observable<ReportModel> {
    return this.http.put(`${this.apiUrl}/${uuid}`, item).pipe(
      map((response) => new ReportModel(response)),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  getViewsAndTables(): Observable<any> {
    const endpointUrl = `${this.apiUrl}/db_objects`;

    return this.http.get(endpointUrl).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  getReportConfiguration(reportName: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}/config`;

    return this.http.get<Array<any>>(endpointUrl).pipe(
      map((result) => {
        const filtered = result.filter((report) => report.name === reportName);
        return filtered.length
          ? {
              title: filtered[0].label ? filtered[0].label : filtered[0].name,
              reportKey: filtered[0].report_key,
              fields: filtered[0].fields.map((column) => {
                const name = Object.keys(column)[0];
                const obj = column[name];

                return {
                  name: Object.keys(column)[0],
                  show: true,
                  filterable: obj.filterable,
                  type: obj.type,
                };
              }),
            }
          : null;
      }),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getReport(uuid: string): Observable<EditableReportModel> {
    const endpointUrl = `${this.apiUrl}/config/${uuid}`;

    DeserializeKeysFrom(UnderscoreCase);

    return this.http.get<Array<any>>(endpointUrl).pipe(
      map(
        (response) => Deserialize(response, EditableReportModel),
        catchError((error) => {
          return observableThrowError(error);
        }),
      ),
    );
  }

  addReport(data: EditableReportModel): Observable<EditableReportModel> {
    const endpointUrl = `${this.apiUrl}/config`;

    SerializeKeysTo(UnderscoreCase);

    return this.http.post<EditableReportModel>(endpointUrl, Serialize(data, EditableReportModel)).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  updateReport(uuid: string, data: EditableReportModel): Observable<any> {
    const endpointUrl = `${this.apiUrl}/config/${uuid}`;

    SerializeKeysTo(UnderscoreCase);

    return this.http.put<EditableReportModel>(endpointUrl, Serialize(data, EditableReportModel)).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  removeReport(uuid: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}/config/${uuid}`;

    return this.http.delete(endpointUrl).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  addReportField(uuid: string, data: EditableReportFieldModel): Observable<any> {
    const endpointUrl = `${this.apiUrl}/config/${uuid}/overrides`;

    SerializeKeysTo(UnderscoreCase);

    return this.http.post<EditableReportModel>(endpointUrl, Serialize(data, EditableReportFieldModel)).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  updateReportField(uuid: string, subKey: string, data: EditableReportFieldModel): Observable<any> {
    const endpointUrl = `${this.apiUrl}/config/${uuid}/overrides/${subKey}`;

    SerializeKeysTo(UnderscoreCase);

    return this.http.put<EditableReportModel>(endpointUrl, Serialize(data, EditableReportFieldModel)).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }
}
