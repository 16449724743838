<ng-template #loadingCard>
  <div class="card-content">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>

<article class="article">
  <div *ngIf="persmLoaded" class="row supplierInformationContainer">
    <div class="col-xl-12 col-lg-12">
      <div *ngIf="supplierGeneralModels$ | async; else loadingCard; let supplierInfo">
        <ng-container *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
          <div class="card">
            <div class="card-content">
              <div class="top-box">
                <app-title-with-icon
                  [iconClass]="'fa-user'"
                  [title]="supplierDetailsModel.contactFirstName + ' ' + supplierDetailsModel.contactLastName"
                  [subtitle]="contactPersonsTitle"
                >
                </app-title-with-icon>
              </div>
              <app-uw-personal-checks-card></app-uw-personal-checks-card>
            </div>
          </div>
        </ng-container>

        <app-uw-review
          *ngIf="mainOwner"
          [supplierKey]="supplierDetailsModel.supplierKey"
          [owner]="mainOwner"
          [show]="!isShown"
          [isDisabled]="isDisabled"
          [isSoleProp]="isSoleProp"
        >
        </app-uw-review>

        <ng-container *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
          <app-supplier-home-address
            [homeAddressForm]="homeAddressForm"
            [isDisabled]="isDisabled"
            [addressCountries]="addressCountries"
            [addressStates]="addressStates"
            (onSaveHomeAddressForm)="saveHomeAddressForm($event)"
          >
          </app-supplier-home-address>
        </ng-container>

        <ng-container *ngxPermissionsOnly="userPermission.REST_UW_ACCESS">
          <app-supplier-home-address
            [homeAddressForm]="homeAddressForm"
            [isDisabled]="isDisabled"
            [addressCountries]="addressCountries"
            [addressStates]="addressStates"
            (onSaveHomeAddressForm)="saveHomeAddressForm($event)"
            [customTitle]="'Owner’s information'"
          >
            <div class="row px-1">
              <div class="col">
                <form
                  *ngIf="formGroups[CONTACT_DATA_FORM]; let contactDataForm"
                  app-card-formgroup
                  novalidate
                  #formGroupContact
                  class="supplier-contact-data"
                  [formGroup]="contactDataForm"
                  [title]="''"
                  [isGroupEditable]="false"
                >
                  <div class="row">
                    <app-inline-edit-text
                      class="col-md-4"
                      [formControlName]="'legalName'"
                      [formGroup]="contactDataForm"
                      [label]="'Full Name'"
                      [inGroupEdit]="false"
                      [disableInline]="true"
                    >
                    </app-inline-edit-text>
                    <app-inline-edit-date
                      class="col-md-4"
                      [formControlName]="'dateOfBirth'"
                      [formGroup]="contactDataForm"
                      type="text"
                      [label]="'Date of Birth'"
                      [disableInline]="true"
                    >
                    </app-inline-edit-date>
                    <app-inline-edit-text
                      class="col-md-4"
                      [formControlName]="'ownerSSN'"
                      [formGroup]="contactDataForm"
                      [label]="'SSN'"
                      [inGroupEdit]="false"
                      [disableInline]="true"
                    >
                    </app-inline-edit-text>
                  </div>
                </form>
              </div>
            </div>
          </app-supplier-home-address>
        </ng-container>

        <app-supplier-business-address
          *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]"
          [businessCodeLabel]="businessCodeLabel"
          [supplierDetailsModel]="supplierDetailsModel"
        >
        </app-supplier-business-address>

        <app-supplier-business-address
          *ngxPermissionsOnly="userPermission.REST_UW_ACCESS"
          [businessCodeLabel]="businessCodeLabel"
          [supplierDetailsModel]="supplierDetailsModel"
          [customTitle]="supplierDetailsModel.businessTypeCode !== 'SP' ? 'Business informations' : 'Address'"
        >
          <div class="row px-1" *ngIf="supplierDetailsModel.businessTypeCode === 'SP'">
            <div class="col">
              <form
                *ngIf="formGroups[CONTACT_DATA_FORM]; let contactDataForm"
                app-card-formgroup
                novalidate
                #formGroupContact
                class="supplier-contact-data"
                [formGroup]="contactDataForm"
                [title]="''"
                [isGroupEditable]="false"
              >
                <div class="row">
                  <app-inline-edit-text
                    class="col-md-4"
                    [formControlName]="'legalName'"
                    [formGroup]="contactDataForm"
                    [label]="'Full Name'"
                    [inGroupEdit]="false"
                    [disableInline]="true"
                  >
                  </app-inline-edit-text>
                  <app-inline-edit-date
                    class="col-md-4"
                    [formControlName]="'dateOfBirth'"
                    [formGroup]="contactDataForm"
                    type="text"
                    [label]="'Date of Birth'"
                    [disableInline]="true"
                  >
                  </app-inline-edit-date>
                  <app-inline-edit-text
                    class="col-md-4"
                    [formControlName]="'ownerSSN'"
                    [formGroup]="contactDataForm"
                    [label]="'SSN'"
                    [inGroupEdit]="false"
                    [disableInline]="true"
                  >
                  </app-inline-edit-text>
                </div>
              </form>
            </div>
          </div>
        </app-supplier-business-address>

        <app-supplier-owners
          *ngIf="owners"
          [owners]="owners"
          [isAdditionalOwnerCheckmarked]="isAdditionalOwnerCheckmarked"
          [additionalOwner]="additionalOwner"
          [supplierDetailsModel]="supplierDetailsModel"
          [isShown]="isShown"
          [isDisabled]="isDisabled"
          [additionalOwnerKey]="additionalOwnerKey"
        >
        </app-supplier-owners>

        <app-supplier-business-details
          *ngIf="supplierDetailsModel.businessTypeCode !== 'SP'"
          [formCheckboxesLastBox]="formCheckboxesLastBox"
        >
        </app-supplier-business-details>
      </div>
    </div>
  </div>
  <div *ngIf="!persmLoaded" class="row supplierInformationContainer">Loading...</div>
</article>
