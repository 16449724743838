import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-main-verification-checkboxes',
  templateUrl: './main-verification-checkboxes.component.html',
  styleUrls: ['./main-verification-checkboxes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainVerificationCheckboxesComponent implements OnInit, OnChanges {
  @Input() isCheckmarked: any;

  checkboxesForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.checkboxesForm = this.formBuilder.group({
      fullNameVerified: [{ value: '', disabled: true }],
      ssnVerified: [{ value: '', disabled: true }],
    });
  }

  ngOnChanges(): void {
    if (this.isCheckmarked && this.checkboxesForm) {
      Object.values(this.checkboxesForm.controls).forEach((control) => {
        control.patchValue(this.isCheckmarked);
      });
    }
  }
}
