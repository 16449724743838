<article class="article" *ngIf="!isLoading; else loading">
  <div class="flex-container">
    <div class="header box box-default">
      <div class="navigation">
        <a [routerLink]="['../../']" title="Go to advances list" class="close-button">
          <i class="material-icons">arrow_back</i>
        </a>
      </div>
      <div class="info">
        <h6>View Details</h6>
      </div>
      <div class="actions"></div>
    </div>
    <div [formGroup]="offerDetailsForm">
      <div class="container">
        <div class="card">
          <div class="card-content">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Offer Details</div>
                <mat-form-field class="full-width">
                  <input matInput placeholder="Author" [value]="offerDetailsData?.last_login_id" readonly />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="Last Updated" [value]="offerDetailsData?.update_ts | date: 'MM/dd/yyyy'" readonly />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="Creation Date" [value]="offerDetailsData?.create_ts | date: 'MM/dd/yyyy'" readonly />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput placeholder="Recommended Amount" [value]="offerDetailsData?.recommended_amount | currency" readonly />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput class="text-primary" placeholder="Requested Amount" formControlName="requested_amount" type="number" />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Net Delivery Amount"
                    [value]="offerDetailsForm.get('net_delivery_amount').value | currency"
                    readonly
                  />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Reserve Total Amount"
                    [value]="offerDetailsForm.get('requested_reserve_total_amount').value | currency"
                    readonly
                  />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <div>
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      class="text-primary"
                      placeholder="Advance Fee Reserve (%)"
                      formControlName="requested_advance_reserve_percent"
                      type="number"
                    />
                    <mat-error>This field is required.</mat-error>
                    <mat-hint class="hint">{{ advanceFeeHint }}</mat-hint>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      class="text-primary"
                      placeholder="Reserve For Repayments (%)"
                      formControlName="extra_reserve_percent"
                      type="number"
                    />
                    <mat-error>This field is required.</mat-error>
                    <mat-hint class="hint">{{ extraReserveHint }}</mat-hint>
                  </mat-form-field>
                </div>
                <mat-form-field class="full-width">
                  <input
                    matInput
                    class="text-primary"
                    placeholder="Weekly Fee Percent (%)"
                    formControlName="weekly_fee_percent"
                    type="number"
                  />
                  <mat-error>This field is required.</mat-error>
                  <mat-hint class="hint">{{ weeklyFeeHint }}</mat-hint>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <textarea matInput placeholder="Request Notes" class="notes" formControlName="request_notes"></textarea>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <div class="card-title">Operational Review</div>
                <mat-form-field class="full-width">
                  <input matInput placeholder="Offer Status" [value]="offerDetailsData?.offer_status" readonly />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Underwriter Determined Average Monthly Remittance"
                    formControlName="underwritter_det_avg_mon_remittance"
                    type="number"
                  />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <mat-select placeholder="Underwriter Risk Rating" formControlName="underwriting_risk_profile">
                    <mat-option *ngFor="let riskRating of riskRatings" [value]="riskRating">
                      {{ riskRating }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input matInput class="text-primary" placeholder="Approved Amount" formControlName="approved_amount" type="number" />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Net Delivery Amount"
                    [value]="offerDetailsForm.get('approved_net_delivery_amount').value | currency"
                    readonly
                  />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Reserve Total Amount"
                    [value]="offerDetailsForm.get('reserve_total_amount').value | currency"
                    readonly
                  />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Underwriting Status"
                    [ngClass]="{ 'text-success': offerDetailsData?.uw_status == 'verified' }"
                    [value]="offerDetailsData?.uw_status"
                    readonly
                  />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Underwriting Date"
                    [value]="offerDetailsData?.underwriting_date | date: 'MM/dd/yyyy'"
                    readonly
                  />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                  <textarea matInput placeholder="Underwriting Notes" class="notes" formControlName="underwriter_notes"></textarea>
                </mat-form-field>
              </div>

              <div class="card-actions">
                <button mat-raised-button class="accept-button" [color]="'accent'" (click)="sendPendingOffer()" tabindex="2">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</article>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</ng-template>
