import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { CurrencyCellRenderer } from '../../../../shared/functions/currency-cell-renderer';
import { DateCellRenderer } from '../../../../shared/functions/date-cell-renderer';
import { NumberCellRenderer } from '../../../../shared/functions/number-cell-renderer';
import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SupplierAdvancesService } from './../service/supplier-advances.service';

@Component({
  selector: 'app-requested-advances',
  templateUrl: './requested-advances.component.html',
  styleUrls: ['./requested-advances.component.scss'],
})
export class RequestedAdvancesComponent extends SubscriberComponent implements OnInit {
  readonly reportName: string = 'supplier_advances';
  requestHistory: any;
  tableSettings: any;
  columns: any;

  private baseHttpParams: any;
  private supplierKey: string;
  private reportConfigName = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
    private readonly supplierAdvancesService: SupplierAdvancesService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.baseHttpParams = {
      limit: 20,
      offset: 0,
    };

    this.columns = [
      {
        field: 'requested_amount',
        headerName: 'Requested Amount',
        cellRenderer: (cellData: any) => {
          if (cellData.value == null) {
            cellData.value = 0;
          }
          const formattedValue = new CurrencyPipe('en-US').transform(cellData.value, 'USD', 'symbol');
          // eslint-disable-next-line max-len
          return `<a class="pull-right" href="${location}/progressive-details/${cellData.data.proposed_advance_line_key}">${formattedValue}</a>`;
        },
        width: 120,
      },
      {
        field: 'reserve_total_amount',
        headerName: 'Reserve Total Amount',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'offer_status',
        headerName: 'Offer Status',
      },
      {
        field: 'create_ts',
        headerName: 'Creation Date',
        cellRenderer: DateCellRenderer,
      },
      {
        field: 'recommended_amount',
        headerName: 'Recommended Amount',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'uw_status',
        headerName: 'Underwriting Status',
      },
      {
        field: 'underwriting_date',
        headerName: 'Underwriting Date',
        cellRenderer: DateCellRenderer,
      },
      {
        field: 'underwriting_risk_profile',
        headerName: 'Underwriting Risk Profile',
      },
      {
        field: 'underwritter_det_avg_mon_remittance',
        headerName: 'Underwritter Det Avg Mon Remittance',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'extra_reserve_percent',
        headerName: 'Extra Reserve Percent',
        cellRenderer: NumberCellRenderer,
      },
      {
        field: 'extra_reserve_amount',
        headerName: 'Extra Reserve Amount',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: 'weekly_fee_percent',
        headerName: 'Weekly Fee Percent',
        cellRenderer: NumberCellRenderer,
      },
      {
        field: 'grace_period_length',
        headerName: 'Grace Period (Days)',
        cellRenderer: NumberCellRenderer,
      },
      {
        field: 'no_rebate',
        headerName: 'No Rebate',
      },
      {
        field: 'return_strategy',
        headerName: 'Return Strategy',
      },
      {
        field: 'force_full',
        headerName: 'Force Full',
      },
    ];

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;
          this.updateData();
        }),
    );
  }

  updateData() {
    this.supplierAdvancesService.getRequestsHistory(this.supplierKey).subscribe((data) => {
      this.requestHistory = data.results;
    });
  }
}
