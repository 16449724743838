export const DIALOG_SIZE = {
  DEFAULT: {
    width: '70vw',
    height: '70vh',
  },
  SMALL: {
    width: '450px',
  },
  SMALL2: {
    width: '600px',
  },
  SMALL3: {
    width: '315px',
  },
  MEDIUM: {
    width: '678px',
  },
  MEDIUM2: {
    width: '50em',
    height: '70vh',
  },
  MEDIUM3: {
    width: '60%',
    height: '60%',
  },
  MEDIUM_AUTO_HEIGHT: {
    width: '40%',
    height: 'auto',
  },
  LARGE: {
    width: '80%',
    height: '80%',
  },
  LARGE_AUTO_HEIGHT: {
    width: '80%',
    height: 'auto',
  },
  XL: {
    width: '90%',
    height: '90%',
  },

  RWD_20: {
    width: '20vw',
  },
  RWD_40: {
    width: '40vw',
  },
  RWD_50_AUTO_HEIGHT: {
    width: '50vw',
    height: 'auto',
  },
  RWD_60: {
    width: '60vw',
  },
  RWD_80: {
    width: '80vw',
    height: '80vh',
  },
};
