<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>

<div *ngIf="supplierDetailsModel$ | async; else loading; let supplierInfo" class="supplier-suspend-switch">
  <span *ngIf="supplierSuspended; else suspendSupplier">
    <button
      mat-raised-button
      class="btn-w-md btn-w-md"
      (click)="toggleSuspend(supplierInfo)"
      [disabled]="toggleInProgress"
      [ngClass]="{ 'btn-busy': toggleInProgress }"
      [color]="'primary'"
      data-test="unsuspendButton"
    >
      Unsuspend
    </button>
  </span>

  <ng-template #suspendSupplier>
    <button
      mat-raised-button
      color="warn"
      class="btn-w-md"
      [disabled]="toggleInProgress || !requiredDataHasLoaded"
      [ngClass]="{ 'btn-busy': toggleInProgress }"
      (click)="toggleSuspend(supplierInfo)"
      data-test="suspendButton"
    >
      Suspend
    </button>
  </ng-template>
</div>
