import { Injectable } from '@angular/core';

import { EmailStatuses, EmailTypes, emailTypes } from '../emails/email-types';

@Injectable()
export class EmailsStatusService {
  constructor() {}

  getTypeName(typeKey: string): string {
    const foundItem = emailTypes.find((item) => item.key === typeKey);
    if (foundItem) {
      return foundItem.name;
    }
  }

  getFiltersType(): any[] {
    return emailTypes.filter((type) => type.filter);
  }

  getDefaultFiltersType(): any[] {
    return [EmailTypes.AUTO_EMAIL, EmailTypes.NOTE_EMAIL, EmailTypes.EMAIL_SUPPLIER, EmailTypes.BATCH_GROUP];
  }

  isBatchGroup(type: string): boolean {
    return type === EmailTypes.BATCH_GROUP;
  }

  isPending(status: string): boolean {
    return status === EmailStatuses.PENDING;
  }

  isSend(status: string): boolean {
    return status === EmailStatuses.SENT;
  }
}
