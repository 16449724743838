export const AgreementReview = [
  {
    text: 'Legal name matches and is valid on agreement',
    value: false,
  },
  {
    text: 'Secretary of state info matches agreement (only applicable when not sole proprietor)',
    value: false,
  },
  {
    text: 'Agreement signed by registered agent or managing member?',
    value: false,
  },
  {
    text: 'For the guarantor, the address on the photo ID and the proof of address document matches.',
    value: false,
  },
];

export const AmazonInformation = [
  {
    text:
      'Does legal entity name in Amazon agree to the supplier and validity agreement?' +
      'If not need to update contract to reflect amazon name.',
    value: false,
  },
  {
    text: 'Does the payee banking information in Amazon agree to legal entity  name?',
    value: false,
  },
];

export const PersonalBgCheck = [
  {
    text: 'Does the Guarantor have any bankruptcy?',
    target: '0',
    value: 'no data',
    automated: false,
  },
  {
    text: 'Does the Guarantor have any judgement?',
    target: '0',
    value: 'no data',
    automated: false,
  },
  {
    text: 'Does the Guarantor have any lien against them?',
    target: '0',
    value: 'no data',
    automated: false,
  },
  {
    text: 'Does the Guarantor have a criminal record for any event, excluding driving infractions?',
    target: 'Criminal: 0 Traffic: 0',
    value: 'no data',
    automated: false,
  },
];

export const CredictCheck = [
  {
    text: 'Does the supplier have any open UCC against them?',
    target: '0',
    value: 'no data',
    automated: false,
  },
];

export const InternationalSupplier = [
  {
    text: 'Does the supplier live outside of the US but sell in the US?',
    target: false,
    warning: true,
    actual: true,
    value: false,
  },
  {
    text: 'Sells between $15K-$50K per month?',
    target: true,
    warning: true,
    actual: true,
    value: false,
  },
  {
    text: 'Is bank located in the US? If yes, 3% fee, but if located outside of US, 5%',
    target: true,
    warning: true,
    actual: false,
    value: false,
  },
  {
    text: 'Is the supplier a sole proprietorship?',
    target: true,
    warning: true,
    actual: true,
    value: false,
  },
  {
    text: 'Has the supplier been selling on Amazon for at least one year?',
    target: true,
    warning: true,
    actual: false,
    value: false,
  },
];

export const AmazonAdvertisings = ['Yes', 'No'];

export const AmazonLending = [{ text: 'Does supplier have a loan in place with Amazon? If yes, there may be an adjustment on reserve.' }];

export const AmazonLendingOverride = [
  {
    value: false,
  },
];

export const TransUnionOverride = [
  {
    value: false,
  },
  {
    value: false,
  },
];

export const InternationalSupplierOverride = [
  {
    value: false,
  },
];

export const AmazonAdvertisingOverride = [
  {
    value: false,
  },
];

export const AmazonRevenueOverride = [
  {
    value: false,
  },
];

export const NotificationOverride = [
  {
    value: false,
  },
];

export const BlockDecision = {
  isOn: false,
};

export const LegalNameMatches = ['legal_name_matches', 'ba6f6211-9c72-47c6-a4c1-04f262032353'];
export const SosInfoMatches = ['sos_info_matches', '59edec79-d6f4-4087-b869-86d80c02f837'];
export const AgreementSigned = ['agreement_signed', '3641652a-70f9-4ee3-a4ad-ae158c1aebc2'];
export const AddressPhotoMatches = ['address_photo_matches', '6f3901a9-9587-4ec6-bf6a-8bc8d61382c7'];
export const AgreementEntityName = ['agreement_entity_name', 'debc9972-5e45-4143-a034-e0e7be7eaa3a'];
export const BankingEntityName = ['banking_entity_name', '33e13671-0a27-4961-9bdd-688f683e2957'];
export const StatusTransUnion = ['status_trans_union', 'caf88905-bd26-4959-90ee-dfe12b645fc5'];
export const StatusUccFiling = ['status_ucc_filing', '13df3a31-ab25-45f5-b358-fa56207e23f7'];
export const AmazonRevenue = ['amazon_revenue', '7d7b7ce2-4a81-4cec-be45-5f8be4dcd843'];
export const StatusInternationalSupplier = ['international_supplier', '00b920fb-7d0c-4a88-a63d-ec6aeb6de51c'];
export const AmazonAdvertisingLabel = ['amazon_advertising', '1237ec37-7e58-4bc4-9dde-6f58b55e44ae'];
export const AmazonLendingLabel = ['amazon_lending', '3341d3b6-8638-424f-b921-349028f80066'];
export const StatusNotification = ['notifications_and_suspensions', '5ba22f28-1a18-4e31-9b49-4db54e141f62'];
export const TuTaxId = ['correct_tax_id', '5d03490e-6ab7-49bf-9fe2-073433d89aca'];
export const ComplianceActionKeys = [
  '869f7aaf-e9bd-4f85-bfa6-6a0b0d5616a2',
  'f9be5d82-bdbc-4502-8214-4a8c0f095e85',
  'c5f8e904-75f3-4090-9783-fadafe2162fb',
  '331d90cc-cf7a-4d9f-92bd-8855c1af08c5',
  '9a3f6e03-4a70-4e7f-861c-86c267789600',
];

export const ActionStatuses = ['incomplete', 'pending', 'verified', 'rejected'];

export const DefaultRadiobuttons = [
  { color: 'warn', text: 'No', value: false },
  { color: 'accent', text: 'Yes', value: true },
];

export const AmazonHealthValue = 35000;

export const MaxValueForUWForm = 100;

export const ManualLabels = [
  'legal_name_matches',
  'sos_info_matches',
  'agreement_signed',
  'address_photo_matches',
  'agreement_entity_name',
  'banking_entity_name',
  'status_trans_union',
  'status_ucc_filing',
  'amazon_revenue',
  'international_supplier',
  'amazon_advertising',
  'amazon_lending',
  'notifications_and_suspensions',
];

export const NoDataText = '-';
