import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-underwriting-report-tab',
  templateUrl: './underwriting-report-tab.component.html',
  styleUrls: ['./underwriting-report-tab.component.scss'],
})
export class UnderwritingReportTabComponent extends SubscriberComponent implements OnInit {
  supplierKey: string;
  externalReportId: string;

  constructor(private readonly route: ActivatedRoute, private readonly store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;
        }),
    );

    this.externalReportId = this.route.snapshot.data['reportId'];
  }
}
