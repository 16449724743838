<h1 mat-dialog-title>Edit config</h1>

<mat-dialog-content>
  <form [formGroup]="form" class="row">
    <mat-form-field class="col-md-6" *ngIf="showField('key')">
      <input matInput placeholder="Key" aria-label="Key" formControlName="key" required />
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="showField('config_type')">
      <input matInput placeholder="Config Type" aria-label="Config Type" formControlName="configType" />
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="showField('template_name')">
      <input matInput placeholder="Template Name" aria-label="Template Name" formControlName="templateName" required />
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="showField('description')">
      <input matInput placeholder="Description" aria-label="Description" formControlName="description" />
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="showField('subject')">
      <input matInput placeholder="Subject" aria-label="Subject" formControlName="subject" required />
    </mat-form-field>

    <mat-form-field class="col-md-6" *ngIf="showField('from_address')">
      <input matInput placeholder="From Address" aria-label="From Address" formControlName="fromAddress" />
    </mat-form-field>

    <mat-form-field class="col-md-12" *ngIf="showField('to_address')">
      <input matInput placeholder="To Address" aria-label="To Address" formControlName="toAddress" />
    </mat-form-field>

    <div class="col-md-12 htmlEditor" *ngIf="showField('html_template') && useTemplate">
      <app-editor formControlName="htmlTemplate" [toolsTheme]="TOOLS_THEME.EMAIL" #appEditor></app-editor>
    </div>

    <mat-form-field class="col-md-12" *ngIf="showField('text_template') && useTemplate">
      <textarea matInput placeholder="Text template" aria-label="Text template" formControlName="textTemplate"></textarea>
    </mat-form-field>

    <div class="col-md-12 template-variables" *ngIf="showField('template_variables') && useTemplate">
      <div>
        <h4>Template variables</h4>
        <small> Click on title of variable to put it in your template content or use copy action and paste it yourself </small>
        <div class="row" *ngIf="templateVariables">
          <app-email-edit-variables
            class="col-md-4"
            *ngIf="templateVariables.variables"
            [title]="'Variables'"
            [variables]="templateVariables.variables"
            (change)="pasteInEditor($event)"
          >
          </app-email-edit-variables>
          <app-email-edit-variables
            class="col-md-4"
            *ngIf="templateVariables.supplier"
            [title]="'Supplier'"
            [variables]="templateVariables.supplier"
            (change)="pasteInEditor($event)"
          >
          </app-email-edit-variables>
        </div>
        <div class="row" *ngIf="isTemplate()">
          <app-email-edit-variables
            class="col-md-4"
            [title]="'Supplier related'"
            [variables]="getAllowTemplatesVariablesSupplier()"
            (change)="pasteInEditor($event)"
          >
          </app-email-edit-variables>
          <app-email-edit-variables
            class="col-md-4"
            [title]="'Supplier related'"
            [variables]="getAllowTemplatesVariablesUser()"
            (change)="pasteInEditor($event)"
          >
          </app-email-edit-variables>
          <app-email-edit-variables
            class="col-md-4"
            [title]="'Supplier related'"
            [variables]="getAllowTemplatesVariablesMiscalenous()"
            (change)="pasteInEditor($event)"
          >
          </app-email-edit-variables>
        </div>
      </div>
    </div>

    <div class="col-md-12 toggle-template-btn" *ngIf="showField('template_buttons')">
      <button mat-raised-button class="mat-primary" *ngIf="useTemplate" (click)="toggleUseTemplate()">REMOVE TEMPLATE</button>
      <button mat-raised-button class="mat-primary" *ngIf="!useTemplate" (click)="toggleUseTemplate()">ADD TEMPLATE</button>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="save()" [color]="'accent'">Save</button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'">Cancel</button>
</mat-dialog-actions>
