import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { isUndefined } from 'util';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { SupplierMarketplaceListModel } from '../../model/supplier-marketplace.model';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { ConfirmDialogComponent } from './../../../shared/confirm-dialog/confirm-dialog.component';
import { ToggleLockSupplier } from './../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-unlock',
  templateUrl: './supplier-unlock.component.html',
  styleUrls: ['./supplier-unlock.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnlockSupplierComponent extends SubscriberComponent implements OnInit {
  public supplierDetailsModel$: Store<SupplierDetailsModel>;
  public supplierMarketplaces: SupplierMarketplaceListModel[] = [];
  public toggleInProgress = false;
  public requiredDataHasLoaded = false;

  public supplierLocked: boolean;

  public suspendCodeDomain = 'susphist.Suspend';
  public suspendReasons: Observable<any[]>;

  public unsuspendCodeDomain = 'susphist.Unsuspend';
  public unsuspendReasons: Observable<any[]>;

  public supplierKey: string;

  constructor(public dialog: MatDialog, private store: Store<AppState>, private toastrService: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.supplierDetailsModel$ = this.store.select(selectSupplierDetails);
    this.subscriptions.push(
      this.store.select('supplier').subscribe((supplierState) => {
        if (
          !isUndefined(supplierState) &&
          !isUndefined(supplierState.supplierModel) &&
          !isUndefined(supplierState.supplierModel.supplierDetailsModel)
        ) {
          this.supplierLocked = !!supplierState.supplierModel.supplierDetailsModel.supplierLocked;
          this.requiredDataHasLoaded = true;
        }

        if (this.toggleInProgress && supplierState.toggleSuspendInEdit === false) {
          if (!isUndefined(supplierState.toggleSuspendError)) {
            this.toastrService.error('Error during update operation');
          } else {
            this.toastrService.success('Updated successfully');
          }

          this.toggleInProgress = supplierState.toggleSuspendInEdit;
        }
      }),
    );
  }

  toggleLock(): void {
    const msg = `Do you want to ${this.supplierLocked ? 'UNLOCK' : 'LOCK'} supplier's account?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL.width,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (!confirm) {
          return;
        }
        this.store.dispatch(new ToggleLockSupplier({ lock: !this.supplierLocked }));
      }),
    );
  }
}
