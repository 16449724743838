import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cli-action-templates',
  templateUrl: './cli-action-templates.component.html',
  styleUrls: ['./cli-action-templates.component.scss'],
})
export class CliActionTemplatesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  downloadTemplate(): any {
    return window.open('/assets/examples/loyalty_adjustments_upload_csv_file.csv', '_blank');
  }
}
