<span>Instant Payouts</span>
<mat-divider></mat-divider>

<mat-table [dataSource]="dataSourceIPayouts">
  <ng-container matColumnDef="marketplace">
    <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.marketplace }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="uwStatus">
    <mat-header-cell *matHeaderCellDef>UW Status</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.uwStatus | capitalize }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedInstantPayoutsColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedInstantPayoutsColumns"></mat-row>
</mat-table>
