<div class="field-wrapper" [class]="fieldClass" [ngClass]="{ 'has-icon': materialIcon, 'in-group-edit': inGroupEdit }">
  <!-- EDIT MODE -->
  <div *ngIf="inEditMode(); else presentationMode" class="edit-mode">
    <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>

    <mat-form-field>
      <input
        #inlineEditControl
        matInput
        [formControl]="formControl"
        [name]="value"
        [type]="type"
        [placeholder]="label"
        (blur)="markAsTouched()"
      />
    </mat-form-field>

    <mat-error *ngIf="hasRequiredError()"> This field is <strong>required</strong> </mat-error>

    <mat-error *ngIf="hasMaxError()"> Value needs to be lower than initial value </mat-error>

    <div class="help-text-wrapper warning" *ngIf="helpText && !hasRequiredError()">
      {{ helpText }}
    </div>

    <div class="inline-buttons" *ngIf="isInlineEditOnly()">
      <button class="btn btn-xs btn-cancel" (click)="close()">Cancel <i class="material-icons">close</i></button>
      <button
        class="btn btn-xs btn-success btn-save"
        (click)="callSave(formControl.value)"
        [disabled]="hasRequiredError() || hasMaxError()"
        data-test="saveButton"
      >
        Save <i class="material-icons">done</i>
      </button>
    </div>
  </div>

  <ng-template #presentationMode>
    <div class="presentation-mode" [ngClass]="{ 'disable-inline': disableInline }">
      <mat-icon *ngIf="materialIcon">{{ materialIcon }}</mat-icon>
      <div class="fake-field">
        <div class="label">
          {{ label }}
          <mat-icon *ngIf="showCopy" ngxClipboard [cbContent]="value" class="btn-copy" matTooltip="Click to copy {{ label }}">
            content_copy
          </mat-icon>
        </div>
        <div
          class="value"
          [ngClass]="{ masked: showMask }"
          *ngIf="!displayType; else formatedValue"
          (click)="edit(formControl.value)"
          (focus)="edit(formControl.value)"
        >
          <ng-container *ngIf="showMask; else standardValue">
            {{ mask }}
          </ng-container>

          <ng-template #standardValue>
            {{ formControl.value | formatPreview: type:defaultEmptyValue }}
          </ng-template>
        </div>

        <ng-template #formatedValue>
          <span *ngIf="showMask; else standardValue">
            {{ mask }}
          </span>

          <ng-template #standardValue>
            <span *ngIf="isPercent">{{ formControl.value * 100 | formatPreview: type:defaultEmptyValue }}</span>
            <span *ngIf="isCurrency">{{ formControl.value | currency: 'USD':'symbol' | formatPreview: type:defaultEmptyValue }}</span>
          </ng-template>
        </ng-template>
      </div>
      <mat-icon class="ml-1 pointer small-icon" *ngIf="showToggleMaskBtn" (click)="toggleMask()">
        {{ mask ? 'remove_red_eye' : 'visibility_off' }}
      </mat-icon>
    </div>
  </ng-template>
</div>
