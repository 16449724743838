import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-summary-dialog',
  templateUrl: './summary-dialog.component.html',
})
export class SummaryDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SummaryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public ngOnInit(): void {}
}
