import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'util';

import { AppState } from '../../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { UccService } from '../ucc.service';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-ucc-trans-union',
  templateUrl: './ucc-trans-union.component.html',
  styleUrls: ['./ucc-trans-union.component.scss'],
})
export class UccTransUnionComponent extends SubscriberComponent implements OnInit {
  public uccKey: string;
  public uccData: any;
  public collateralTypes: string[];
  private supplierKey: string;

  constructor(private uccService: UccService, private store: Store<AppState>, private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.setSubscriptions();
  }

  redirectToList(): void {
    this.uccKey
      ? this.router.navigate(['../../list'], { relativeTo: this.route })
      : this.router.navigate(['./../list'], { relativeTo: this.route });
  }

  private setSubscriptions(): void {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierData) => {
        if (!isUndefined(supplierData)) {
          this.supplierKey = supplierData.supplierKey;

          this.subscriptions.push(
            this.route.params.subscribe((params: Params) => {
              this.uccKey = params['id'];

              if (this.uccKey) {
                this.setUccData();
              }
            }),
          );
        }
      }),
    );
  }

  private setUccData(): void {
    this.subscriptions.push(
      this.uccService.getTransUnionUcc(this.supplierKey, this.uccKey).subscribe((data) => {
        this.collateralTypes = data.collateralDescription ? data.collateralDescription.split(',') : [''];
        this.uccData = data;
      }),
    );
  }
}
