export enum USER_PERMISSION {
  AUTH_TOKEN = 'AUTH_TOKEN',
  SUP_LOGIN = 'SUP_LOGIN',
  INT_LOGIN = 'INT_LOGIN',
  INT_UPDATE = 'INT_UPDATE',
  INT_PERMS_ADM = 'INT_PERMS_ADM',
  INT_SUP_CRED = 'INT_SUP_CRED',
  INT_MP_PMTS = 'INT_MP_PMTS',
  INT_RCV = 'INT_RCV',
  INT_XACT_CNCL = 'INT_XACT_CNCL',
  INT_FEE_UPD = 'INT_FEE_UPD',
  INT_SUPER_USER = 'INT_SUPER_USER',
  MARKETING = 'MARKETING',
  CUST_SERVICE = 'CUST_SERVICE',
  VIEW_ONLY = 'VIEW_ONLY',
  FINANCE = 'FINANCE',
  Sales = 'Sales',
  US = 'us',
  DEV = 'DEV',
  DELETED = 'DELETED',
  INT_PMT_ADMIN = 'INT_PMT_ADMIN',
  INT_PMT_REVIEW = 'INT_PMT_REVIEW',
  INT_REP_ADMIN = 'INT_REP_ADMIN',
  INT_RULES_UPDATE = 'INT_RULES_UPDATE',
  INT_GLOBAL_SETTINGS = 'INT_GLOBAL_SETTINGS',
  INT_EMAIL_SETTINGS = 'INT_EMAIL_SETTINGS',
  MANAGE_PMT = 'MANAGE_PMT',
  MANAGE_BAD_DEBT = 'MANAGE_BAD_DEBT',
  dev_support = 'dev_support',
  CRM_ADMIN = 'CRM_ADMIN',
  REST_UW_ACCESS = 'REST_UW_ACCESS',
  PROD_MGR = 'PROD_MGR',
  UNDERWRITING = 'UNDERWRITING',
  MANAGE_LINKING_PAYMENT_GATEWAYS = 'MANAGE_LINKING_PAYMENT_GATEWAYS',
  MP_LOANS_MODIFY = 'MP_LOANS_MODIFY',
  MP_LOANS_DELETE = 'MP_LOANS_DELETE',
  MP_STATEMENTS_MODIFY = 'MP_STATEMENTS_MODIFY',
  MP_STATEMENTS_DELETE = 'MP_STATEMENTS_DELETE',
  ADVANCE_AUDITORS = 'ADVANCE_AUDITORS',
}
