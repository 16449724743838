/// <reference path="users/user-edit/user-edit.component.ts"/>
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';

import { OnboardingService } from '../shared/onboarding/service/onboarding.service';
import { SharedModule } from '../shared/shared.module';
import { UsersService } from '../shared/users/service/users.service';
/* eslint-disable */
import { BatchImportsComponent } from './batch-imports/batch-imports.component';
import { BulkNotesComponent } from './batch-imports/bulk-notes/bulk-notes.component';
import { CliActionComponent } from './batch-imports/cli-action/cli-action.component';
import { CliActionButtonComponent } from './batch-imports/cli-action/cli-action-button/cli-action-button.component';
import { ActionFieldComponent } from './batch-imports/cli-action/cli-action-dialog/action-field/action-field.component';
import { ActionUploadComponent } from './batch-imports/cli-action/cli-action-dialog/action-upload/action-upload.component';
import { CliActionDialogComponent } from './batch-imports/cli-action/cli-action-dialog/cli-action-dialog.component';
import { CliActionLastRunComponent } from './batch-imports/cli-action/cli-action-last-run/cli-action-last-run.component';
import { CliActionNameComponent } from './batch-imports/cli-action/cli-action-name/cli-action-name.component';
import { CliActionResultsComponent } from './batch-imports/cli-action/cli-action-results/cli-action-results.component';
import { CliActionService } from './batch-imports/cli-action/cli-action-service/cli-action.service';
import { CliActionMockService } from './batch-imports/cli-action/cli-action-service/cli-action-mock.service';
import { CliActionStatusComponent } from './batch-imports/cli-action/cli-action-status/cli-action-status.component';
import { CliActionTemplatesComponent } from './batch-imports/cli-action/cli-action-templates/cli-action-templates.component';
import { DepositAccountsComponent } from './batch-imports/deposit-accounts/deposit-accounts.component';
import { PreFundedCardsComponent } from './batch-imports/pre-funded-cards/pre-funded-cards.component';
import { BatchImportService } from './batch-imports/service/batch-imports.service';
import { SupplierPromosComponent } from './batch-imports/supplier-promos/supplier-promos.component';
import { SupplierRebatesComponent } from './batch-imports/supplier-rebates/supplier-rebates.component';
import { UccUploadComponent } from './batch-imports/ucc-upload/ucc-upload';
import { WeeklyPurchasesComponent } from './batch-imports/weekly-purchases/weekly-purcheses.component';
import { ConfigsComponent } from './configs/configs.component';
import { ContractsComponent } from './contracts/contracts.component';
import { DeleteEmailSettingComponent } from './email-settings/delete-email-setting/delete-email-setting.component';
import { EmailAddNewConfigDialogComponent } from './email-settings/email-add-new-config-dialog/email-add-new-config-dialog.component';
import { EmailEditConfigDialogComponent } from './email-settings/email-edit-config-dialog/email-edit-config-dialog.component';
import { EmailEditVariablesComponent } from './email-settings/email-edit-variables/email-edit-variables.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { EmailSettingsListComponent } from './email-settings/email-settings-list/email-settings-list.component';
import { EmailTypeBadgeComponent } from './email-settings/email-type-badge/email-type-badge.component';
import { EventsService } from './event-rules/events.service';
import { ActionFormComponent } from './event-rules/rules-add-edit/action-form/action-form.component';
import { ActionsFormComponent } from './event-rules/rules-add-edit/action-form/actions-form.component';
import { CreateTaskComponent } from './event-rules/rules-add-edit/action-form/create-task/create-task.component';
import { EditSupplierComponent } from './event-rules/rules-add-edit/action-form/edit-supplier/edit-supplier.component';
import { SendEmailComponent } from './event-rules/rules-add-edit/action-form/send-email/send-email.component';
import { SendToEventBridgeComponent } from './event-rules/rules-add-edit/action-form/send-to-event-bridge/send-to-event-bridge.component';
import { RulesAddEditComponent } from './event-rules/rules-add-edit/rules-add-edit.component';
import { SelectorFormComponent } from './event-rules/rules-add-edit/selector-form/selector-form.component';
import { RulesHistoryListComponent } from './event-rules/rules-history-list/rules-history-list.component';
import { RulesListComponent } from './event-rules/rules-list/rules-list.component';
import { RulesUtilsService } from './event-rules/rules-utils.service';
import { EventRules2Component } from './event-rules-2/event-rules-2.component';
import { EventBadgeComponent } from './events/event-badge/event-badge.component';
import { EventFiltersComponent } from './events/event-filters/event-filters.component';
import { EventPreviewComponent } from './events/event-preview/event-preview.component';
import { EventsComponent } from './events/events.component';
import { FeeSettingsComponent } from './fee-settings/fee-settings.component';
import { FeeSettingsAddComponent } from './fee-settings/fee-settings-add/fee-settings-add.component';
import { FeeSettingsDeleteComponent } from './fee-settings/fee-settings-delete/fee-settings-delete.component';
import { FeeSettingsEditComponent } from './fee-settings/fee-settings-edit/fee-settings-edit.component';
import { FeeSettingsListComponent } from './fee-settings/fee-settings-list/fee-settings-list.component';
import { FeeSettingsService } from './fee-settings/service/fee-settings.service';
import { DeleteSettingComponent } from './global-settings/delete-setting/delete-setting.component';
import { EditSettingComponent } from './global-settings/edit-setting/edit-setting.component';
import { GlobalSettingsComponent } from './global-settings/global-settings.component';
import { NewSettingComponent } from './global-settings/new-setting/new-setting.component';
import { GlobalSettingsService } from './global-settings/service/global-settings.service';
import { BatchSupplierEmailComponent } from './global-settings/templates/batch-supplier-email/batch-supplier-email.component';
import { EmailBaseTemplateComponent } from './global-settings/templates/email-base-template/email-base-template.component';
import { EmailConfigComponent } from './global-settings/templates/email-config/email-config.component';
import { EmailFrontendTemplateComponent } from './global-settings/templates/email-frontend-template/email-frontend-template.component';
import { FrontendEmailConfigComponent } from './global-settings/templates/frontend-email-config/frontend-email-config.component';
import { KeyValueDictionaryComponent } from './global-settings/templates/key-value-dictionary/key-value-dictionary.component';
import { ListTemplateComponent } from './global-settings/templates/list-template/list-template.component';
import { NestedListTemplateComponent } from './global-settings/templates/nested-list-template/nested-list-template.component';
import { TaskTemplateComponent } from './global-settings/templates/task-template/task-template.component';
import { MonthEndCloseComponent } from './month-end-close/month-end-close.component';
import { DeleteOnboardingComponent } from './onboarding/delete-onboarding-setting/delete-onboarding.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingAddComponent } from './onboarding/onboarding-add/onboarding-add.component';
import { OnboardingGeneralInformationComponent } from './onboarding/onboarding-add/onboarding-general-information/onboarding-general-information.component';
import { OnboardingMarketplacesComponent } from './onboarding/onboarding-add/onboarding-marketplaces/onboarding-marketplaces.component';
import { OnboardingPaymentOptionsComponent } from './onboarding/onboarding-add/onboarding-payment-options/onboarding-payment-options.component';
import { OnboardingProductsComponent } from './onboarding/onboarding-add/onboarding-products/onboarding-products.component';
import { OnboardingListComponent } from './onboarding/onboarding-list/onboarding-list.component';
import { OnboardingMpAddEditDialogComponent } from './onboarding/onboarding-mp-add-edit-dialog/onboarding-mp-add-edit-dialog.component';
import { OnboardingMpListComponent } from './onboarding/onboarding-mp-list/onboarding-mp-list.component';
import { PermGroupsComponent } from './perm-groups/perm-groups.component';
import { PermGroupsAddComponent } from './perm-groups/perm-groups-add/perm-groups-add.component';
import { PermGroupsEditComponent } from './perm-groups/perm-groups-edit/perm-groups-edit.component';
import { PermGroupsListComponent } from './perm-groups/perm-groups-list/perm-groups-list.component';
import { PermissionErrorPopUpComponent } from './permission-error-pop-up/permission-error-pop-up.component';
import { UserAddComponent } from './users/user-add/user-add.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UsersComponent } from './users/users.component';
/* eslint-enable */

@NgModule({
  imports: [SharedModule, MatStepperModule, MatCardModule, MatExpansionModule],
  declarations: [
    UsersComponent,
    UserEditComponent,
    UserAddComponent,
    UserListComponent,
    PermGroupsComponent,
    PermGroupsAddComponent,
    PermGroupsEditComponent,
    PermGroupsListComponent,
    ConfigsComponent,
    BatchImportsComponent,
    DepositAccountsComponent,
    UccUploadComponent,
    PreFundedCardsComponent,
    BulkNotesComponent,
    SupplierRebatesComponent,
    SupplierPromosComponent,
    WeeklyPurchasesComponent,
    GlobalSettingsComponent,
    NewSettingComponent,
    EditSettingComponent,
    DeleteSettingComponent,
    TaskTemplateComponent,
    EmailBaseTemplateComponent,
    EmailFrontendTemplateComponent,
    FrontendEmailConfigComponent,
    KeyValueDictionaryComponent,
    EmailConfigComponent,
    BatchSupplierEmailComponent,
    ListTemplateComponent,
    NestedListTemplateComponent,
    RulesListComponent,
    RulesAddEditComponent,
    RulesHistoryListComponent,
    SelectorFormComponent,
    ActionFormComponent,
    ActionsFormComponent,
    EditSupplierComponent,
    CreateTaskComponent,
    SendEmailComponent,
    EventsComponent,
    EmailSettingsComponent,
    EmailSettingsListComponent,
    EmailEditConfigDialogComponent,
    EmailTypeBadgeComponent,
    EmailAddNewConfigDialogComponent,
    EmailEditVariablesComponent,
    DeleteEmailSettingComponent,
    EventBadgeComponent,
    EventPreviewComponent,
    EventFiltersComponent,
    PermissionErrorPopUpComponent,
    OnboardingComponent,
    OnboardingListComponent,
    OnboardingMpListComponent,
    DeleteOnboardingComponent,
    OnboardingMpAddEditDialogComponent,
    FeeSettingsComponent,
    FeeSettingsListComponent,
    FeeSettingsEditComponent,
    FeeSettingsAddComponent,
    FeeSettingsDeleteComponent,
    CliActionComponent,
    CliActionNameComponent,
    CliActionButtonComponent,
    CliActionStatusComponent,
    CliActionLastRunComponent,
    CliActionTemplatesComponent,
    CliActionResultsComponent,
    CliActionDialogComponent,
    ActionFieldComponent,
    ActionUploadComponent,
    OnboardingAddComponent,
    OnboardingGeneralInformationComponent,
    OnboardingMarketplacesComponent,
    OnboardingProductsComponent,
    OnboardingPaymentOptionsComponent,
    ContractsComponent,
    SendToEventBridgeComponent,
    EventRules2Component,
    MonthEndCloseComponent,
  ],
  providers: [
    EventsService,
    RulesUtilsService,
    BatchImportService,
    GlobalSettingsService,
    UsersService,
    OnboardingService,
    FeeSettingsService,
    CliActionService,
    CliActionMockService,
  ],
  entryComponents: [
    EventPreviewComponent,
    DepositAccountsComponent,
    PreFundedCardsComponent,
    BulkNotesComponent,
    EmailEditConfigDialogComponent,
    EmailAddNewConfigDialogComponent,
    SupplierRebatesComponent,
    SupplierPromosComponent,
    WeeklyPurchasesComponent,
    NewSettingComponent,
    EditSettingComponent,
    DeleteSettingComponent,
    DeleteEmailSettingComponent,
    PermissionErrorPopUpComponent,
    UccUploadComponent,
    DeleteOnboardingComponent,
    OnboardingMpAddEditDialogComponent,
    FeeSettingsDeleteComponent,
    CliActionDialogComponent,
  ],
})
export class ManageModule {}
