import { autoserialize } from 'cerialize';

export class ConfigModel {
  @autoserialize
  configKey: string;

  @autoserialize
  configType: string;

  @autoserialize
  configValue: any;

  @autoserialize
  createdAt?: string;

  @autoserialize
  instanceId?: number;

  @autoserialize
  useTemplates?: boolean;

  constructor(configKey: string, configType: string, configValue: any, createdAt?: string, instanceId?: number, useTemplates?: boolean) {
    this.configKey = configKey;
    this.configType = configType;
    this.configValue = configValue;
    this.createdAt = createdAt;
    this.instanceId = instanceId;
    this.useTemplates = useTemplates;
  }
}
