import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-drag-and-drop-list',
  templateUrl: './drag-and-drop-list.component.html',
  styleUrls: ['./drag-and-drop-list.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DragAndDropListComponent), multi: true }],
})
export class DragAndDropListComponent implements OnInit, ControlValueAccessor {
  @Input()
  list: any[];

  @Output()
  change: EventEmitter<any[]> = new EventEmitter<any[]>();

  public value: any[] = [];
  private onModelChange: Function;
  private onModelTouched: Function;

  constructor() {}

  ngOnInit() {}

  public dragStart(event): void {
    event.dataTransfer.setData('mat-card', event.target.id);
    event.dataTransfer.dropEffect = 'move';
  }

  public dragOver(event): void {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  public dropItem(event: any, index: number): void {
    const data = event.dataTransfer.getData('mat-card');
    const sourceElement = this.list[data];
    this.list.splice(data, 1);
    this.list.splice(index, 0, sourceElement);
    this.emitChanges();
    this.value = this.list;
    this.onModelChange(this.value);
  }

  public removeItem(index: number): void {
    this.list.splice(index, 1);
    this.emitChanges();
    this.value = this.list;
    this.onModelChange(this.value);
    this.onModelTouched(this.value);
  }

  private emitChanges(): void {
    this.change.emit(this.list);
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  public writeValue(value: string[]): void {
    this.value = value;
  }
}
