<form name="supplierDetailsHealth.generalForm" ng-submit="supplierDetailsHealth.save()" class="form form-wrapper ng-pristine ng-valid">
  <fieldset>
    <legend>Delinquency</legend>
    <div class="table-wrapper">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>Date</th>
            <th>Delinquency State</th>
            <th>Supplier Incremental Risk Grade</th>
            <th>Current Risk Grade</th>
            <th>Risk Overwritten</th>
            <th>Current ADA %</th>
            <th>Current ADA $</th>
          </tr>
        </thead>
        <!-- ngIf: !supplierDetails.serviceBusy -->
        <tbody ng-if="!supplierDetails.serviceBusy" class="ng-scope">
          <!-- ngIf: !supplierDetailsHealth.riskDelinquency.length -->
          <!-- ngRepeat: item in supplierDetailsHealth.riskDelinquency -->
          <tr ng-repeat="item in supplierDetailsHealth.riskDelinquency" class="ng-scope">
            <td class="ng-binding">07/21/17</td>
            <td class="ng-binding">1A</td>
            <td class="ng-binding">0</td>
            <td class="ng-binding">1</td>
            <td class="ng-binding">No</td>
            <td class="ng-binding">0</td>
            <td class="ng-binding"></td>
          </tr>
          <!-- end ngRepeat: item in supplierDetailsHealth.riskDelinquency -->
        </tbody>
        <!-- end ngIf: !supplierDetails.serviceBusy --><!-- ngIf: supplierDetails.serviceBusy -->
      </table>
    </div>

    <p class="question">&nbsp;</p>
    <!-- ngIf: !supplierDetailsHealth.isRiskInfo() -->
    <!-- ngIf: supplierDetailsHealth.isRiskInfo() -->
    <!--<fieldset ng-if="supplierDetailsHealth.isRiskInfo()" class="ng-scope">-->
    <!--<div class="form-group"><label for="q3qav" class="floating-label">Delinquency Type</label><select-->
    <!--class="form-control ng-pristine ng-valid ng-not-empty ng-touched"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.delinquencyType"-->
    <!--ng-options="type.delinquency_type as type.delinquency_type_label for type in supplierDetailsHealth.delinquencyTypes"-->
    <!--pyb-floating-label="'Delinquency Type'" id="q3qav">-->
    <!--<option value="" class=""></option>-->
    <!--<option label="1-GL1" value="string:1-GL1" selected="selected">1-GL1</option>-->
    <!--<option label="2-GLW1" value="string:2-GLW1">2-GLW1</option>-->
    <!--<option label="3-YL1" value="string:3-YL1">3-YL1</option>-->
    <!--<option label="4-YL2" value="string:4-YL2">4-YL2</option>-->
    <!--<option label="5-RL1" value="string:5-RL1">5-RL1</option>-->
    <!--<option label="6-RLW1" value="string:6-RLW1">6-RLW1</option>-->
    <!--<option label="7-RL2" value="string:7-RL2">7-RL2</option>-->
    <!--<option label="8-RLW2" value="string:8-RLW2">8-RLW2</option>-->
    <!--<option label="SC1" value="string:SC1">SC1</option>-->
    <!--<option label="SC2" value="string:SC2">SC2</option>-->
    <!--<option label="SC3" value="string:SC3">SC3</option>-->
    <!--<option label="SC4" value="string:SC4">SC4</option>-->
    <!--<option label="SC5" value="string:SC5">SC5</option>-->
    <!--<option label="SC6" value="string:SC6">SC6</option>-->
    <!--<option label="W1" value="string:W1">W1</option>-->
    <!--</select></div>-->
    <!--<div class="form-group"><label for="0c8ydr" class="floating-label">Delinquency Age</label><select-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.delinquencyAge"-->
    <!--ng-options="type.delinquency_age as type.delinquency_age_label for type in supplierDetailsHealth.delinquencyAges"-->
    <!--pyb-floating-label="'Delinquency Age'" id="0c8ydr">-->
    <!--<option value="" class=""></option>-->
    <!--<option label="A (current-15 days)" value="string:A" selected="selected">A (current-15 days)</option>-->
    <!--<option label="B (16-30 days)" value="string:B">B (16-30 days)</option>-->
    <!--<option label="C (31-60 days)" value="string:C">C (31-60 days)</option>-->
    <!--<option label="D (61-90 days)" value="string:D">D (61-90 days)</option>-->
    <!--<option label="E Over 90 days" value="string:E">E Over 90 days</option>-->
    <!--<option label="F (Write-off)" value="string:F">F (Write-off)</option>-->
    <!--<option label="MD" value="string:Z">MD</option>-->
    <!--</select></div>-->
    <!--<div class="form-group"><label for="9asj18" class="floating-label">Scenario</label><select-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.delinquencyScenario"-->
    <!--ng-options="type as type for type in supplierDetailsHealth.delinquencyScenarios" pyb-floating-label="'Scenario'"-->
    <!--id="9asj18">-->
    <!--<option value="" class=""></option>-->
    <!--<option label="1" value="number:1" selected="selected">1</option>-->
    <!--</select></div>-->
    <!--</fieldset>&lt;!&ndash; end ngIf: supplierDetailsHealth.isRiskInfo() &ndash;&gt;</fieldset>-->
    <!--<fieldset class="col-xs-12 col-md-6 col-lg-4">-->
    <!--<legend>Risk Related Information</legend>-->
    <!--<fieldset>-->
    <!--<div class="form-group"><label for="4svm1m" class="floating-label">Risk Score Override</label><select-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.riskScoreOverride"-->
    <!--ng-options="type as type for type in [1, 2, 3, 4, 5]" pyb-floating-label="'Risk Score Override'" id="4svm1m">-->
    <!--<option value="" class="" selected="selected"></option>-->
    <!--<option label="1" value="number:1">1</option>-->
    <!--<option label="2" value="number:2">2</option>-->
    <!--<option label="3" value="number:3">3</option>-->
    <!--<option label="4" value="number:4">4</option>-->
    <!--<option label="5" value="number:5">5</option>-->
    <!--</select></div>-->
    <!--<div class="form-group"><label for="8ol2w" class="floating-label">Credit Score</label><input type="number"-->
    <!--step="1"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.thirdPartyCreditScore"-->
    <!--pyb-floating-label="'Credit Score'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--id="8ol2w"></div>-->
    <!--<div class="form-group"><label for="4b172" class="floating-label">Credit2Be Credit Score</label><input-->
    <!--type="number" step="1" ng-model="supplierDetailsHealth.riskInfo.credit2beCreditScore"-->
    <!--pyb-floating-label="'Credit2Be Credit Score'" class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--id="4b172"></div>-->
    <!--<div class="form-group"><label for="ho13y9" class="floating-label">DnB Credit Score</label><input type="number"-->
    <!--step="1"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.dnbCreditScore"-->
    <!--pyb-floating-label="'DnB Credit Score'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--id="ho13y9">-->
    <!--</div>-->
    <!--<div class="form-group"><label for="47zmca" class="floating-label">UCC Score</label><input type="number" step="1"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.uccScore"-->
    <!--pyb-floating-label="'UCC Score'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--id="47zmca"></div>-->
    <!--<div class="form-group"><input type="checkbox" id="seniorUCC"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.pbSeniorUcc"-->
    <!--class="ng-pristine ng-untouched ng-valid ng-empty"><label for="seniorUCC">Payability-->
    <!--is the senior UCC</label></div>-->
    <!--<div class="form-group"><label for="m141y9l" class="floating-label">Validity Score</label><input type="number"-->
    <!--step="1"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.validityScore"-->
    <!--pyb-floating-label="'Validity Score'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--id="m141y9l">-->
    <!--</div>-->
    <!--<div class="form-group"><input type="checkbox" id="PersonalValidityAgreement"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.personalValidityAgreement"-->
    <!--class="ng-pristine ng-untouched ng-valid ng-empty"><label-->
    <!--for="PersonalValidityAgreement">Personal Validity Agreement</label></div>-->
    <!--<div class="form-group"><label for="zcnxv9" class="floating-label">Inc Score</label><input type="number" step="1"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.incScore"-->
    <!--pyb-floating-label="'Inc Score'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--id="zcnxv9"></div>-->
    <!--<div class="form-group"><label for="xtdchh" class="floating-label">Business Type</label><select-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.businessType"-->
    <!--ng-options="type.id as type.label for type in supplierDetailsHealth.businessTypes"-->
    <!--pyb-floating-label="'Business Type'" id="xtdchh">-->
    <!--<option value="" class="" selected="selected"></option>-->
    <!--<option label="LCC" value="string:llc">LCC</option>-->
    <!--<option label="Incorporated" value="string:incorporated">Incorporated</option>-->
    <!--<option label=" Sole Proprietorship" value="string:sole-property"> Sole Proprietorship</option>-->
    <!--</select></div>-->
    <!--<div class="form-group"><label for="ofday2" class="floating-label">Geo Score</label><input type="number" step="1"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.geoScore"-->
    <!--pyb-floating-label="'Geo Score'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--id="ofday2"></div>-->
    <!--<div class="form-group"><label for="l19tk" class="floating-label">Country</label><select-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.country"-->
    <!--ng-options="type.id as type.label for type in supplierDetailsHealth.countries" pyb-floating-label="'Country'"-->
    <!--id="l19tk">-->
    <!--<option value="" class="" selected="selected"></option>-->
    <!--<option label="Domestic" value="string:domestic">Domestic</option>-->
    <!--<option label="International" value="string:international">International</option>-->
    <!--</select></div>-->
    <!--<div class="form-group"><label for="14r35k" class="floating-label">Fulfillment</label><select-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-empty"-->
    <!--ng-model="supplierDetailsHealth.riskInfo.fulfillment"-->
    <!--ng-options="type.id as type.label for type in supplierDetailsHealth.fulfillments"-->
    <!--pyb-floating-label="'Fulfillment'" id="14r35k">-->
    <!--<option value="" class="" selected="selected"></option>-->
    <!--<option label="Direct" value="string:direct">Direct</option>-->
    <!--<option label="Marketplace" value="string:marketplace">Marketplace</option>-->
    <!--<option label="N/A" value="string:na">N/A</option>-->
    <!--</select></div>-->
    <!--</fieldset>-->
    <!--</fieldset>-->
    <!--<fieldset class="col-xs-12 col-md-6 col-lg-4">-->
    <!--<legend>Amazon Health</legend>-->
    <!--<fieldset disabled="disabled">-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.health_standing &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="98wqq" class="floating-label">Health-->
    <!--Standing</label><input type="text" input-clipboard-btn-title="Copy Health Standing"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.health_standing"-->
    <!--pyb-floating-label="'Health Standing'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-not-empty"-->
    <!--id="98wqq"> <span class="input-group-btn" title="Copy Health Standing">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.health_standing_details &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="965xtg" class="floating-label">Health Standing-->
    <!--Details</label><textarea type="text" input-clipboard-btn-title="Copy Health Standing Details"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.health_standing_details"-->
    <!--pyb-floating-label="'Health Standing Details'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-empty"-->
    <!--id="965xtg"></textarea> <span class="input-group-btn"-->
    <!--title="Copy Health Standing Details">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link text-transparent"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.account_performance_status &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="qusger" class="floating-label">Account-->
    <!--Performance Status</label><input type="text" input-clipboard-btn-title="Copy Account Performance Status"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.account_performance_status"-->
    <!--pyb-floating-label="'Account Performance Status'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-not-empty"-->
    <!--id="qusger"> <span class="input-group-btn"-->
    <!--title="Copy Account Performance Status">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.account_performance_info &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="ocu2rp" class="floating-label">Account-->
    <!--Performance Info</label><input type="text" input-clipboard-btn-title="Copy Account Performance Info"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.account_performance_info"-->
    <!--pyb-floating-label="'Account Performance Info'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-not-empty"-->
    <!--id="ocu2rp"> <span class="input-group-btn"-->
    <!--title="Copy Account Performance Info">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.account_warning &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="ktdj4q" class="floating-label">Account-->
    <!--Warning</label><input type="text" input-clipboard-btn-title="Copy Account Warning"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.account_warning"-->
    <!--pyb-floating-label="'Account Warning'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-not-empty"-->
    <!--id="ktdj4q"> <span class="input-group-btn" title="Copy Account Warning">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.last_check_ts &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="zuw3p" class="floating-label">Last Check-->
    <!--Time</label><input type="text" input-clipboard-btn-title="Copy Last Check Time"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.last_check_ts"-->
    <!--pyb-floating-label="'Last Check Time'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-not-empty" id="zuw3p">-->
    <!--<span class="input-group-btn" title="Copy Last Check Time">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.amazon_recent_payment &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="bwh4vf" class="floating-label">Amazon Recent-->
    <!--Payment</label><input type="text" input-clipboard-btn-title="Copy Amazon Recent Payment"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.amazon_recent_payment"-->
    <!--pyb-floating-label="'Amazon Recent Payment'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-empty" id="bwh4vf">-->
    <!--<span class="input-group-btn" title="Copy Amazon Recent Payment">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link text-transparent"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.recent_payment_date &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="dlkk6" class="floating-label">Recent Payment-->
    <!--Date</label><input type="text" input-clipboard-btn-title="Copy Recent Payment Date"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.recent_payment_date"-->
    <!--pyb-floating-label="'Recent Payment Date'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-empty" id="dlkk6"> <span-->
    <!--class="input-group-btn" title="Copy Recent Payment Date">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link text-transparent"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-group">&lt;!&ndash; inputClipboardBtn: supplierDetailsHealth.amazonHealth.amazon_account_number &ndash;&gt;-->
    <!--<div class="input-group input-clipboard-btn ng-scope"><label for="2xfqoo" class="floating-label">Amazon Account-->
    <!--Number</label><input type="text" input-clipboard-btn-title="Copy Amazon Account Number"-->
    <!--ng-model="supplierDetailsHealth.amazonHealth.amazon_account_number"-->
    <!--pyb-floating-label="'Amazon Account Number'"-->
    <!--class="form-control ng-pristine ng-untouched ng-valid ng-scope ng-not-empty" id="2xfqoo">-->
    <!--<span class="input-group-btn" title="Copy Amazon Account Number">    <a -->
    <!--ng-class="{ 'text-transparent': !inputClipboardBtn.dataToCopy }"-->
    <!--class="btn btn-link"-->
    <!--ng-click="inputClipboardBtn.copy()">      🔗    </a>  </span>-->
    <!--</div>-->
    <!--</div>-->
    <!--</fieldset>-->
  </fieldset>
</form>
