import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Deserialize, DeserializeKeysFrom, SerializeKeysTo } from 'cerialize';
import { forkJoin, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { ServiceUpdatePartialInterface } from '../../shared/interfaces/service-update-partial-interface/service-update-partial-interface';
import { SupplierDetailsModel } from '../model/supplier.model';
import { SupplierMarketplaceDetailsModel } from '../model/supplier-marketplace.model';

@Injectable()
export class SupplierMarketplacesService implements ServiceUpdatePartialInterface {
  readonly apiUrl: string;
  private marketplaceUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers';
    this.marketplaceUrl = appConfig.env.marketplaceApiUrl;
  }

  prepareMarketplaceListObservable(supplierDetailsModel: SupplierDetailsModel, refresh: boolean) {
    const supplierKey = supplierDetailsModel.supplierKey;

    // prepare request for every static complianceActionKeys value
    const observableBatch = supplierDetailsModel.marketplaces.map((marketplaceInfo) => {
      return this.fetchMarketplaceDetails(supplierKey, marketplaceInfo.mpSupKey, refresh);
    });

    // put them as one observable join
    return forkJoin(observableBatch);
  }

  fetchMarketplaceDetails(supplierKey, mpSupKey, refresh: boolean = false) {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/mp/${mpSupKey}`;
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient
      .get<SupplierMarketplaceDetailsModel>(endpointUrl, {
        params: queryParams,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(result, SupplierMarketplaceDetailsModel);
        }),
      );
  }

  saveItemPart(itemKey: string, itemPart: any, ...params): Observable<any> {
    const mpSupKey = params[0]; // TODO nkler: how to resolve it dynamicly but without indexed array?
    delete itemPart.isNotApproved;
    return this.saveMarketplaceDetails(itemKey, mpSupKey, itemPart);
  }

  saveMarketplaceDetails(supplierKey, mpSupKey, data): Observable<any> {
    // FIXME nkler: should be fixed on reactive forms side - @Luke HELP NEEDED ;)
    if (data.estimatedMonthlyRevenue) {
      data.estimatedMonthlyRevenue = +data.estimatedMonthlyRevenue;
    }
    if (data.paymentTermsDays) {
      data.paymentTermsDays = +data.paymentTermsDays;
    }
    if (data.paymentFrequency) {
      data.paymentFrequency = +data.paymentFrequency;
    }

    // This line in not needed but it was here for the very long time and IMO it's safer to leave it as is.
    SerializeKeysTo(CamelCase);

    DeserializeKeysFrom(CamelCase);
    data = Deserialize(data, SupplierMarketplaceDetailsModel);

    const endpointUrl = `${this.apiUrl}/${supplierKey}/mp/${mpSupKey}`;
    return this.httpClient.put(endpointUrl, data).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  saveMarketplaceSuspendReason(supplierKey: string, body: object): Observable<any> {
    const endpointUrl = `${this.apiUrl}/${supplierKey}/marketplace_suspend_reason`;
    return this.httpClient.put(endpointUrl, body).pipe(
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  updatePaymentProcessorSettings(mpKey: string, body: object): Observable<any> {
    const endpointUrl = `${this.appConfig.env.internalApiUrl}supplier/${mpKey}/update_payment_gateway_bank_account_number`;
    return this.httpClient.post(endpointUrl, body).pipe(catchError((error) => Observable.throw(error)));
  }

  updateSalesData(marketplaceKey: string, supplierKey: string): Observable<any> {
    const endpointUrl = `${this.appConfig.env.marketplaceApiUrl}/platforms/${marketplaceKey}/update_sales_data`;
    const body = {
      supplier_key: supplierKey,
    };
    return this.httpClient.post(endpointUrl, body).pipe(catchError((error) => Observable.throw(error)));
  }
}
