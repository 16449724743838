<h1 mat-dialog-title>Add onboarding marketplace template</h1>

<mat-dialog-content>
  <div>
    <form [formGroup]="form" class="row">
      <div class="col-md-12">
        <mat-form-field class="full-width">
          <input
            matInput
            type="text"
            placeholder="Marketplace"
            aria-label="Marketplace"
            [formControl]="marketplaceForm"
            [matAutocomplete]="autoMarket"
            required
          />
        </mat-form-field>

        <mat-autocomplete #autoMarket="matAutocomplete" [displayWith]="displayMarketplaceName" (optionSelected)="setMarketplace($event)">
          <mat-option *ngFor="let mp of filteredMarketplaces | async" [value]="mp">
            {{ mp.marketplaceName }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <div class="col-md-6">
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Funding Period Group Code"
            formControlName="fundingPeriodGroupCode"
            [matAutocomplete]="autoFunds"
            required
          />
        </mat-form-field>

        <mat-autocomplete #autoFunds="matAutocomplete">
          <mat-option *ngFor="let fp of fundingPeriods | async" [value]="fp.fundingPeriodGroupCode">
            {{ fp.fundingPeriodGroupCode + ' — ' + fp.userFriendlyName }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <div class="col-md-6">
        <mat-form-field class="full-width">
          <input matInput placeholder="Fee Schedule Key" [formControl]="feeScheduleForm" [matAutocomplete]="autoSchedule" required />
        </mat-form-field>

        <mat-autocomplete #autoSchedule="matAutocomplete" [displayWith]="displayFeeScheduleName" (optionSelected)="setFeeSchedule($event)">
          <mat-option *ngFor="let fs of filteredFeeSchedules | async" [value]="fs">
            {{ fs.feeScheduleName }}
          </mat-option>
        </mat-autocomplete>
      </div>

      <mat-form-field class="col-md-6">
        <input
          matInput
          type="number"
          placeholder="Reserve Percent"
          aria-label="Reserve Percent"
          formControlName="reservePercent"
          min="0"
          max="100"
          pattern="^[0-9]"
          required
        />
      </mat-form-field>

      <mat-form-field class="col-md-6">
        <input
          matInput
          type="number"
          placeholder="Minimum Reserve Percent"
          aria-label="Minimum Reserve Percent"
          formControlName="minReservePercent"
          min="0"
          max="100"
          pattern="^[0-9]"
          required
        />
      </mat-form-field>

      <mat-form-field class="col-md-6">
        <input
          matInput
          type="number"
          placeholder="Rcv Purchase Threshold"
          aria-label="Rcv Purchase Threshold"
          formControlName="rcvPurchThreshold"
          pattern="^[0-9]"
          required
        />
      </mat-form-field>

      <mat-form-field class="col-md-6">
        <input
          matInput
          type="number"
          placeholder="Monthly Minimum Fee"
          aria-label="Monthly Minimum Fee"
          formControlName="monthlyMinFee"
          pattern="^[0-9]"
          required
        />
      </mat-form-field>

      <mat-checkbox class="col-md-3" formControlName="hideSplashScreen">Hide Splash Screen</mat-checkbox>
      <mat-checkbox class="col-md-3" formControlName="feeFromReserve">Fee From Reserve</mat-checkbox>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="save()" [color]="'accent'">Save</button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'">Cancel</button>
</mat-dialog-actions>
