import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { KeyValue } from '../models/key-value';
import { GlobalSettingsService } from './../../manage/global-settings/service/global-settings.service';
import { FEATURE_FLAGS } from './feature-flags.const';
import { FEATURE_FLAGS_ENV, FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY } from './feature-flags.enum';
import { FeatureFlagEntity } from './feature-flags.model';

@Injectable()
export class FeatureFlagsService {
  private readonly EXEC_FLAGS_SESSION_KEY = 'FeatureExecFlags';
  private readonly EXEC_FLAGS_SESSION_LAST_CHANGE_KEY = 'FeatureExecFlagsLastChange';

  private featureFlags: FEATURE_FLAGS[] = [];
  private globalSettingsFetchedFlags = new BehaviorSubject({});
  private lastUpdateFFfromGlobalSettings: Record<string, number> = {};

  constructor(private globalSettingsService: GlobalSettingsService) {}

  isEnabled(feature: FEATURE_FLAGS): boolean {
    const flags = [...this.featureFlags];

    const availableFlagExecStatus = this.getAvailableFlagsExecStatus();
    this.applyExecFlags(availableFlagExecStatus, flags);

    if (flags.length === 0 || typeof feature !== 'string') {
      return false;
    }

    const index = flags.findIndex((f) => f === feature);

    if (index < 0) {
      return false;
    }

    return true;
  }

  getFlags(): string[] {
    return this.featureFlags;
  }

  getAvailableFlags(): string[] {
    return Object.entries(FEATURE_FLAGS).map((v) => v[1]);
  }

  getAvailableFlagsBuildStatus(): KeyValue<boolean>[] {
    const flags = this.getFlags();
    return this.getAvailableFlags().map((flag) => {
      const present = flags.find((f) => f === flag);

      return { [flag]: !!present };
    });
  }

  getAvailableFlagsExecStatus(): KeyValue<boolean>[] {
    return this.fetchAvailableFlagsExecStatus();
  }

  clearGlobalSettingsFetchedFlags(): void {
    this.globalSettingsFetchedFlags.next({});
  }

  applyExecFlags(availableFlagExecStatus: KeyValue<boolean>[], flags: FEATURE_FLAGS[] | string[]): void {
    availableFlagExecStatus.forEach((f) => {
      const [key, value] = Object.entries(f)[0];

      const flagIndex = flags.findIndex((flagKey) => flagKey === key);

      if (value === false) {
        if (flagIndex >= 0) {
          flags.splice(flagIndex, 1);
        }
      } else {
        if (flagIndex < 0) {
          flags.push(key as FEATURE_FLAGS);
        }
      }
    });
  }

  async getRecalculatedFlagsStatus(
    env: FEATURE_FLAGS_ENV = FEATURE_FLAGS_ENV.IA,
    availableFlags: string[] = [],
    buildProvidedFlags: FEATURE_FLAGS[] = [],
    globalSettingsProvidedFlags: FEATURE_FLAGS[] = [],
    availableFlagExecStatus: KeyValue<boolean>[] = [],
  ): Promise<FeatureFlagEntity[]> {
    if (env === FEATURE_FLAGS_ENV.IA) {
      availableFlags = this.getAvailableFlags();
      buildProvidedFlags = this.featureFlags;
      globalSettingsProvidedFlags = await this.fetchAvailableFlagsGlobalSettingsStatus().toPromise();
    }

    const execFlags = [...globalSettingsProvidedFlags];

    if (env === FEATURE_FLAGS_ENV.IA) {
      const lastChangedInStorage = this.getLastChangedByKey();
      const lastChangedInSession = this.getLastChangeExecFlagsFromSession();
      if (lastChangedInSession > lastChangedInStorage || lastChangedInStorage === 0) {
        availableFlagExecStatus = this.getAvailableFlagsExecStatus();
      } else {
        availableFlagExecStatus = [];
      }
    }

    this.applyExecFlags(availableFlagExecStatus, execFlags);

    return availableFlags.map((flag) => {
      const buildIsset = buildProvidedFlags.findIndex((f) => f === flag);
      const execIsset = execFlags.findIndex((f) => f === flag);
      const globalSettingsIsset = globalSettingsProvidedFlags.findIndex((f) => f === flag);

      return {
        key: flag,
        build: buildIsset >= 0,
        exec: execIsset >= 0,
        globalSettings: globalSettingsIsset >= 0,
      };
    });
  }

  getLastChangeExecFlagsFromSession(): number {
    const timestamp = sessionStorage.getItem(this.EXEC_FLAGS_SESSION_LAST_CHANGE_KEY);
    return timestamp === null ? 0 : +timestamp;
  }

  storeAvailableFlagsExecStatus(flags: KeyValue<boolean>[]): void {
    const oldFlags = this.fetchAvailableFlagsExecStatus();

    if (!isEqual(oldFlags, flags)) {
      sessionStorage.setItem(this.EXEC_FLAGS_SESSION_LAST_CHANGE_KEY, (+new Date()).toString());
    }
    sessionStorage.setItem(this.EXEC_FLAGS_SESSION_KEY, JSON.stringify(flags));
  }

  clearAvailableFlagsExecStatus(): void {
    sessionStorage.removeItem(this.EXEC_FLAGS_SESSION_KEY);
    sessionStorage.removeItem(this.EXEC_FLAGS_SESSION_LAST_CHANGE_KEY);
  }

  getEnabledFlags(flags): FeatureFlagEntity[] & string[] {
    return flags.filter((f) => f.exec).map((f) => f.key);
  }

  getLastChangedByKey(settingKey = FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY.IA): number {
    return this.lastUpdateFFfromGlobalSettings[settingKey] ? this.lastUpdateFFfromGlobalSettings[settingKey] : 0;
  }

  async changeFlag(flag: string, value: boolean): Promise<boolean> {
    const avFlags = await this.getRecalculatedFlagsStatus();

    const index = avFlags.findIndex((f) => f.key === flag);

    if (index < 0) {
      return false;
    }

    avFlags[index].exec = value;

    this.storeAvailableFlagsExecStatus(avFlags.map(({ key, exec }) => ({ [key]: exec })));

    return true;
  }

  fetchAvailableFlagsGlobalSettingsStatus(settingKey = FEATURE_FLAGS_ENV_GLOBAL_SETTINGS_KEY.IA): Observable<FEATURE_FLAGS[]> {
    const flagsValue = this.globalSettingsFetchedFlags.value;

    if (!flagsValue[settingKey]) {
      return this.globalSettingsService.get(settingKey, true).pipe(
        first(),
        map((config) => {
          if (!config) {
            this.lastUpdateFFfromGlobalSettings[settingKey] = 0;
            return [];
          } else {
            const flags = this.parseRawFlags(config.config_value.flags);
            this.lastUpdateFFfromGlobalSettings[settingKey] = config.config_value.lastChange ? config.config_value.lastChange : 0;
            // ToDo - custom flags per user: config.config_value without flags (there will be emails)

            this.globalSettingsFetchedFlags.next({ ...flagsValue, [settingKey]: flags });
            return flags;
          }
        }),
      );
    } else {
      return of(flagsValue[settingKey]);
    }
  }

  storeState(settingKey: string, flags: string[]): Observable<any> {
    return this.globalSettingsService.get(settingKey).pipe(
      first(),
      switchMap((config) => {
        config.config_value.flags = flags.join(',');
        this.lastUpdateFFfromGlobalSettings[settingKey] = +new Date();
        config.config_value.lastChange = this.lastUpdateFFfromGlobalSettings[settingKey];
        return this.globalSettingsService.edit(settingKey, config);
      }),
    );
  }

  async initFF(): Promise<void> {
    const env = this.getFeatureFlagsFromEnv();
    this.featureFlags = this.parseRawFlags(env);
    const flags = await this.getRecalculatedFlagsStatus();

    this.storeAvailableFlagsExecStatus(flags.map(({ key, exec }) => ({ [key]: exec })));
  }

  private fetchAvailableFlagsExecStatus(): KeyValue<boolean>[] {
    const flags = sessionStorage.getItem(this.EXEC_FLAGS_SESSION_KEY);

    return flags === null ? [] : JSON.parse(flags);
  }

  private getFeatureFlagsFromEnv(): string {
    return environment.featureFlags;
  }

  private parseRawFlags(flagString: string): FEATURE_FLAGS[] {
    if (!flagString) {
      return [];
    }

    let flags = flagString
      .split(',')
      .map((f) => f.trim())
      .sort();
    if (flags[0] === '') {
      flags = [];
    }

    return flags as FEATURE_FLAGS[];
  }
}
