import { animate, animation, query, stagger, style } from '@angular/animations';

export const listAnimation = animation(
  [
    query(':enter', [style({ opacity: 0, height: 0, transform: 'translateX(100px)' })], { optional: true }),

    query(
      ':leave',
      [
        style({ opacity: 1, height: '*', transform: 'translateX(0)' }),
        animate('{{ time }}', style({ opacity: 0, height: 0, transform: 'translateX(100px)' })),
      ],
      { optional: true },
    ),

    query(':enter', stagger('300ms', [animate('{{ time }}', style({ opacity: 1, height: '*', transform: 'translateX(0)' }))]), {
      optional: true,
    }),
  ],
  { params: { time: '.6s ease' } },
);

export const commentsListAnimation = animation(
  [
    query(':enter', [style({ opacity: 0, height: 0, transform: 'translateY(-50px)' })], { optional: true }),

    query(
      ':leave',
      [
        style({ opacity: 1, height: '*', transform: 'translateY(0)' }),
        animate('{{ time }}', style({ opacity: 0, height: 0, transform: 'translateY(-50px)' })),
      ],
      { optional: true },
    ),

    query(':enter', stagger('300ms', [animate('{{ time }}', style({ opacity: 1, height: '*', transform: 'translateY(0)' }))]), {
      optional: true,
    }),
  ],
  { params: { time: '.6s ease' } },
);

export const itemAnimation = animation(
  [
    query('.item', style({ opacity: 0, height: 0 }), { optional: true }),
    query('.item', [stagger(300, [animate('{{ time }}', style({ opacity: 1, height: '*' }))])], { optional: true }),
  ],
  { params: { time: '.6s ease' } },
);

export const expandAnimation = animation(
  [style({ overflow: 'hidden', opacity: 0, height: 0 }), animate('{{ time }}', style({ opacity: 1, height: '*' }))],
  { params: { time: '1.5s ease' } },
);
