/* eslint-disable */
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { PybExtensionService } from '../../../../services/pyb-extension/pyb-extension.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.reducers';
import { GatewayService } from '../../../../../marketplaces/service/gateway.service';
import { SupplierMarketplaceDetailsModel } from '../../../../model/supplier-marketplace.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { SupplierNewPaymentGatewayDialogComponent } from '../supplier-new-payment-gateway-dialog/supplier-new-payment-gateway-dialog.component';
import { IMarketplacesState } from '../../../../../marketplaces/store/marketplace.reducer';
import { SupplierMarketplaceGatewayModel } from '../../../../model/supplier-marketplace-gateway.model';
import { isNullOrUndefined } from 'util';
import { AddPaymentGateway } from '../../../../store/supplier/supplier.actions';
import { ToastrService } from 'ngx-toastr';
import { DIALOG_SIZE } from '../../../../../shared/dialog.config';

/* eslint-enable */

interface PaymentGateway {
  marketplace_key: string;
  marketplace_code: string;
  marketplace_name: string;
  marketplace_desc: string;
  marketplace_url: string;
  icon: string;
  allow_enroll: boolean;
  sup_wdgt_order: number;
  type: string;
}

@Component({
  selector: 'app-supplier-single-marketplace',
  templateUrl: './supplier-single-marketplace.component.html',
  styleUrls: ['./supplier-single-marketplace.component.scss'],
})
export class SupplierSingleMarketplaceComponent extends SubscriberComponent implements OnInit, AfterContentInit {
  @Input() marketplace: SupplierMarketplaceDetailsModel;
  @Input() supplierKey: string;
  @Output() actions = new EventEmitter<string>();

  source: SafeUrl;

  public gatewayList: PaymentGateway[];
  public filteredGatewayList: PaymentGateway[];

  constructor(
    private pybExtService: PybExtensionService,
    private store: Store<AppState>,
    private gatewayService: GatewayService,
    public _DomSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.fetchPossiblePaymentGateways();
    this.sortGateways(this.marketplace.paymentGateways);
  }

  ngAfterContentInit() {
    this.source = this._DomSanitizer.bypassSecurityTrustUrl(this._DomSanitizer.sanitize(SecurityContext.HTML, this.marketplace.icon));
  }

  loginToMarketplace(marketplaceDetailsModel) {
    this.pybExtService.loginToMarketplace(marketplaceDetailsModel, this.subscriptions, this.store, this.supplierKey);
  }

  public openNewPaymentGatewayModal() {
    const dialogRef = this.dialog.open(SupplierNewPaymentGatewayDialogComponent, {
      data: this.filteredGatewayList,
      width: DIALOG_SIZE.RWD_20.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result: any) => {
        if (!isNullOrUndefined(result)) {
          this.connectToGatewayBy(result);
        }
      }),
    );
  }

  public disconnectGatewayBy(gatewayKey: string) {
    this.subscriptions.push(
      this.gatewayService.disconnectMarketplace(this.marketplace.mpSupKey, gatewayKey).subscribe(
        () => {
          this.marketplace.paymentGateways = this.marketplace.paymentGateways.filter((gateway: SupplierMarketplaceGatewayModel) => {
            return gateway.mp_sup_key !== gatewayKey;
          });
          this.filterPaymentGateways();
          this.sortGateways(this.marketplace.paymentGateways);
        },
        (err) => {
          this.toastr.error(err.error.message);
        },
      ),
    );
  }

  private fetchPossiblePaymentGateways(): void {
    this.subscriptions.push(
      this.store.select('marketplace').subscribe((marketplaceState: IMarketplacesState) => {
        this.gatewayList = marketplaceState.possiblePaymentGateways;
        this.filterPaymentGateways();
      }),
    );
  }

  private connectToGatewayBy(result: any): void {
    this.subscriptions.push(
      this.gatewayService.connectMarketplace(this.marketplace.mpSupKey, result).subscribe(
        (resp: { payment_gateways: SupplierMarketplaceGatewayModel[] }) => {
          this.marketplace.paymentGateways = resp.payment_gateways;
          this.filterPaymentGateways();
          const lastAddedGateway = this.marketplace.paymentGateways[this.marketplace.paymentGateways.length - 1];
          this.store.dispatch(
            new AddPaymentGateway({
              ...lastAddedGateway,
              mpSupKey: lastAddedGateway.mp_sup_key,
            }),
          );
          this.sortGateways(this.marketplace.paymentGateways);
        },
        (err) => {
          this.toastr.error(err.error.message);
        },
      ),
    );
  }

  private filterPaymentGateways(): void {
    this.filteredGatewayList = this.gatewayList.filter((elem) => {
      for (const linkedGateway of this.marketplace.paymentGateways) {
        if (elem.marketplace_key === linkedGateway.marketplace.marketplace_key) {
          return false;
        }
      }
      return true;
    });
  }

  public sortGateways(gateways: SupplierMarketplaceGatewayModel[]): void {
    gateways.sort(this.compareGatewaysOrder);
  }

  private compareGatewaysOrder(firstGateway: SupplierMarketplaceGatewayModel, secondGateway: SupplierMarketplaceGatewayModel): number {
    if (firstGateway.marketplace.marketplace_name < secondGateway.marketplace.marketplace_name) {
      return -1;
    }
    if (firstGateway.marketplace.marketplace_name > secondGateway.marketplace.marketplace_name) {
      return 1;
    }
    return 0;
  }
}
