<div class="card">
  <div class="card-content align-items-center">
    <div class="text-center"><span class="card-title">Pending Offer Request</span></div>
    <form [formGroup]="pendingOfferForm" class="pending-offer-form">
      <div>
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Advance Total Amount"
            readonly
            [value]="pendingOfferForm.get('approved_amount').value | currency: 'USD'"
            formControlName="approved_amount"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input matInput placeholder="Underwriting Status" readonly formControlName="uw_status" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input matInput placeholder="Offer Status" readonly formControlName="offer_status" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input matInput placeholder="A/B Selection" readonly formControlName="product_selection_feedback_data" />
        </mat-form-field>
      </div>
    </form>
    <mat-dialog-actions class="actions-buttons">
      <button
        mat-raised-button
        class="accept-button mr-2"
        [color]="'accent'"
        (click)="redirectToNewOffer()"
        [disabled]="requestOfferDisable"
        tabindex="2"
      >
        {{ requestOfferCaption }}
      </button>
      <button
        mat-raised-button
        class="accept-button"
        [color]="'primary'"
        (click)="redirectToProgressiveDetails()"
        [disabled]="pendingOfferData?.approved_amount == null"
        tabindex="-1"
      >
        View Details
      </button>
    </mat-dialog-actions>
  </div>
</div>
