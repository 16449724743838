import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubscriberComponent } from '../../../../../../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../../../../../../store/app.reducers';
import { AddPeriod } from '../../../store/calculator.actions';

@Component({
  selector: 'app-fee-calculator',
  templateUrl: './fee-calculator.component.html',
  styleUrls: ['./fee-calculator.component.scss'],
})
export class FeeCalculatorComponent extends SubscriberComponent implements OnInit {
  public periods = [];
  public totalFee: number;
  public totalPeriodLength: number;
  public averageFee: number;
  public isTotalFeeIncorrect: boolean;
  public isTotalPeriodToLong: boolean;
  public isAddDisabled: boolean;
  public isReadonly: boolean;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select('calc').subscribe((calcValue) => {
        this.periods = calcValue.periods;
        this.totalFee = calcValue.totalFee;
        this.totalPeriodLength = calcValue.totalPeriodLength;
        this.averageFee = calcValue.averageFee;
        this.isTotalFeeIncorrect = calcValue.isTotalFeeIncorrect;
        this.isTotalPeriodToLong = calcValue.isTotalPeriodToLong;
        this.isAddDisabled = calcValue.isAddDisabled;
        this.isReadonly = calcValue.isReadonly;
      }),
    );
  }

  addPeriod() {
    this.store.dispatch(new AddPeriod());
  }

  identify(index) {
    return index;
  }
}
