import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SupplierDetailsModel } from '../../../../model/supplier.model';
import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { AgreementReview, DefaultRadiobuttons } from '../common/consts/underwritings-initial-constants';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-agreement-review',
  templateUrl: './agreement-review.component.html',
})
export class AgreementReviewComponent {
  @Input() agreementReview = AgreementReview;
  @Input() supplierDetailsModel: SupplierDetailsModel;
  @Input() agreementReviewDecision: number;
  @Input() computingDataIsInProgress: number;

  @Output() onAgreementReviewChange = new EventEmitter();

  readonly userPermission = USER_PERMISSION;

  underwritingInitialCardHeaderStatusType = UnderwritingInitialCardHeaderStatusType;
  radiobuttons = DefaultRadiobuttons;

  agreementReviewChange(value, index) {
    if (!this.agreementReview || !this.agreementReview[index]) {
      return;
    }
    this.agreementReview[index].value = value;
    this.onAgreementReviewChange.emit(this.agreementReview);
  }
}
