import { Injectable } from '@angular/core';
/* eslint-disable */
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { empty } from 'rxjs/observable/empty';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { of } from 'rxjs/observable/of';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isUndefined } from 'util';

import { CrmClearCurrentState, FetchCrmAll, FetchCrmNotes, SET_TARGET_UUID } from '../../../crm-tools/store/crm.actions';
import { Logger } from '../../../shared/logger';
import { LongTermContractsService } from '../../../shared/services/long-term-contracts/long-term-contracts.service';
import { AppState } from '../../../store/app.reducers';
import { FundingParticularsModel } from '../../model/funding-particulars.model';
import { SupplierDetailsModel, SupplierModel } from '../../model/supplier.model';
import { SupplierBalanceModel } from '../../model/supplier-balance.model';
import { SupplierMarketplaceDetailsModel } from '../../model/supplier-marketplace.model';
import { FundingParticularsService } from '../../services/funding-particulars.service';
import { PaymentConfigService } from '../../services/payment-config/payment-config.service';
import { SupplierService } from '../../services/supplier.service';
import { SupplierMarketplaceFundingService } from '../../services/supplier-marketplace-funding.service';
import { SupplierMarketplaceMinFeeOverridesService } from '../../services/supplier-marketplace-min-fee-overrides.service';
import { SupplierMarketplaceOptInPeriodsService } from '../../services/supplier-marketplace-opt-in-periods.service';
import { SupplierMarketplacesService } from '../../services/supplier-marketplaces.service';
import { SupplierOnboardingService } from '../../supplier/supplier-onboarding/supplier-onboarding.service';
import { FetchCrmPinnedNotes } from './../../../crm-tools/store/crm.actions';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';
import {
  ADD_OPT_IN_ROW,
  AddOptInRow,
  AddOptInRowError,
  AddOptInRowSuccess,
  CREATE_SUPPLIER_PAYMENT_CONFIGS,
  CreateSupplierPaymentConfigs,
  DELETE_OPT_IN_ROW,
  DELETE_SUPPLIER_PAYMENT_CONFIGS,
  DeleteOptInRow,
  DeleteOptInRowError,
  DeleteOptInRowSuccess,
  DeleteSupplierPaymentConfigs,
  FETCH_COMPLIANCE_ACTIONS_WITH_FILES,
  FETCH_COMPLIANCE_ACTIONS_WITH_FILES_ERROR,
  FETCH_SUPPLIER_DETAILS,
  FETCH_SUPPLIER_DETAILS_ERROR,
  FETCH_SUPPLIER_MARKETPLACE,
  FETCH_SUPPLIER_MARKETPLACE_DETAILS_PART,
  FETCH_SUPPLIER_MARKETPLACE_ERROR,
  FETCH_SUPPLIER_MARKETPLACES,
  FETCH_SUPPLIER_MARKETPLACES_ERROR,
  FETCH_SUPPLIER_PAYMENT_CONFIGS,
  FETCH_SUPPLIER_PAYMENT_CONFIGS_ERROR,
  FetchComplianceActionsWithFiles,
  FetchSupplierDetails,
  FetchSupplierMarketplace,
  FetchSupplierMarketplaceDetailsPart,
  FetchSupplierMarketplaces,
  FetchSupplierMarketplaceSuccess,
  FetchSupplierPaymentConfigs,
  PaymentConfigError,
  PaymentConfigSuccess,
  REQUEST_EFS_CARD,
  RequestEfsCard,
  selectSupplierDetails,
  SET_SUPPLIER_KEY,
  TOGGLE_HIDE_SUPPLIER,
  TOGGLE_LOCK_SUPPLIER,
  TOGGLE_SUSPEND_SUPPLIER,
  ToggleHideSupplier,
  ToggleHideSupplierError,
  ToggleHideSupplierSuccess,
  ToggleLockSupplier,
  ToggleLockSupplierError,
  ToggleLockSupplierSuccess,
  ToggleSuspendSupplier,
  ToggleSuspendSupplierError,
  ToggleSuspendSupplierSuccess,
  UDPATE_SUPPLIER_PAYMENT_CONFIGS,
  UPDATE_MODEL_PART,
  UPDATE_SUPPLIER_DETAILS_MODEL_BY_EVA,
  UPDATE_SUPPLIER_MODEL,
  UpdateModelPart,
  UpdateModelPartError,
  UpdateModelPartSuccess,
  UpdateSupplierModel,
  UpdateSupplierPaymentConfigs,
} from './supplier.actions';
import { AutoPaymentService } from '../../services/auto-payment.service';
/* eslint-enable */

const NEEDED_KEY = 'supplierKey';

@Injectable()
export class SupplierEffects {
  @Effect({ dispatch: false })
  updateSupplierDetailsModelByEva = this.actions$.ofType(UPDATE_SUPPLIER_DETAILS_MODEL_BY_EVA).pipe(
    withLatestFrom(this.store$.select(selectSupplierDetails)),
    map((results) => {
      const fromEva = (results[0] as any).payload;
      let supplierDetailsModel = { ...results[1] } as SupplierDetailsModel;

      supplierDetailsModel = { ...supplierDetailsModel, ...fromEva.supplier };
      this.store$.dispatch(
        new UpdateSupplierModel(
          {
            supplierDetailsModel,
          },
          fromEva.supplierKey,
        ),
      );
    }),
  );

  @Effect()
  navigateToSupplier = this.actions$.ofType(ROUTER_NAVIGATION).pipe(
    map((action: RouterAction<RouterStateSnapshot>) => action.payload.routerState.root),
    mergeMap((activatedRoot: ActivatedRouteSnapshot) => {
      const supplierRoute = this.filter(activatedRoot, NEEDED_KEY);

      if (
        supplierRoute !== undefined &&
        supplierRoute.params !== undefined &&
        supplierRoute.params[NEEDED_KEY] !== undefined &&
        supplierRoute.params[NEEDED_KEY]
      ) {
        return [
          {
            type: SET_SUPPLIER_KEY,
            payload: supplierRoute.params[NEEDED_KEY],
          },
          {
            type: SET_TARGET_UUID,
            payload: {
              targetUuid: supplierRoute.params[NEEDED_KEY],
            },
          },
          {
            type: FETCH_SUPPLIER_DETAILS,
            payload: {
              supplierKey: supplierRoute.params[NEEDED_KEY],
            },
          },
        ];
      } else {
        return empty();
      }
    }),
  );

  @Effect()
  getSupplierDetails = this.actions$.ofType(FETCH_SUPPLIER_DETAILS).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const isAdvanceAuditorUser = this.permissionsService.getPermission(USER_PERMISSION.ADVANCE_AUDITORS);
      const supplierAction: FetchSupplierDetails = <FetchSupplierDetails>result[0];
      const appState: AppState = <AppState>result[1];

      const supplierKey: string = supplierAction.payload.supplierKey;
      const refresh: boolean = supplierAction.payload.refresh;

      if (!isUndefined(supplierKey)) {
        const supplier = appState.supplier.suppliers[supplierKey];

        if (refresh || !supplier) {
          return forkJoin(
            this.supplierService.fetchSupplierDetails(supplierKey, refresh),
            isAdvanceAuditorUser ? of(null) : this.fundingService.fetchFundingParticulars(supplierKey, refresh),
            isAdvanceAuditorUser ? of(null) : this.supplierService.getSupplierBalance(supplierKey, refresh),
            forkJoin(
              isAdvanceAuditorUser ? of(null) : this.longTermContractsService.getContractsForSupplier(supplierKey),
              isAdvanceAuditorUser ? of(null) : this.longTermContractsService.getHistoricalContractsForSupplier(supplierKey),
            ),
          ).pipe(
            switchMap((results) => {
              const supplierDetailsModel: SupplierDetailsModel = results[0];
              const fundingParticularsModel: FundingParticularsModel = results[1];
              const supplierBalanceModel: SupplierBalanceModel = results[2];

              const supplierModel = new SupplierModel();
              supplierModel.supplierKey = supplierKey;
              supplierModel.supplierDetailsModel = supplierDetailsModel;
              supplierModel.currentFundingParticularsModel = fundingParticularsModel;
              supplierModel.balance = supplierBalanceModel;

              return of({
                type: UPDATE_SUPPLIER_MODEL,
                supplierKey,
                payload: supplierModel,
              });
            }),
            catchError((error) => {
              if (404 === error.status) {
                Logger.log('Supplier fetch - 404 error', error);
              } else {
                Logger.log(`Supplier fetch - ${error.statusText}`, error);
              }

              return of({
                type: FETCH_SUPPLIER_DETAILS_ERROR,
                payload: {
                  errorResponse: error,
                },
              });
            }),
          );
        } else {
          return of({
            type: UPDATE_SUPPLIER_MODEL,
            supplierKey,
            payload: supplier,
          });
        }
      } else {
        return of();
      }
    }),
  );

  @Effect()
  fetchSupplierMarketplaces = this.actions$.ofType(FETCH_SUPPLIER_MARKETPLACES).pipe(
    map((action: FetchSupplierMarketplaces) => action.payload),
    withLatestFrom(this.store$),
    switchMap((results) => {
      const actionPayload = results[0];
      const appState = results[1];

      const supplierDetailsModel: SupplierDetailsModel = actionPayload.supplierDetailsModel;
      const refresh = actionPayload.refresh;

      if (!isUndefined(supplierDetailsModel.supplierKey)) {
        const supplier = appState.supplier.suppliers[supplierDetailsModel.supplierKey];

        if (refresh || isUndefined(supplier) || isUndefined(supplier.marketplaces)) {
          if (!isUndefined(supplierDetailsModel.marketplaces) && supplierDetailsModel.marketplaces.length > 0) {
            return this.marketplaceService.prepareMarketplaceListObservable(supplierDetailsModel, refresh).pipe(
              switchMap((marketplaces) => {
                const supplierModel = new SupplierModel();
                supplierModel.marketplaces = marketplaces;

                return of({
                  type: UPDATE_SUPPLIER_MODEL,
                  supplierKey: supplierDetailsModel.supplierKey,
                  payload: supplierModel,
                });
              }),
              catchError((error) => {
                Logger.log(error);
                return of({
                  type: FETCH_SUPPLIER_MARKETPLACES_ERROR,
                });
              }),
            );
          } else {
            const supplierModel = new SupplierModel();
            supplierModel.marketplaces = [];

            return of({
              type: UPDATE_SUPPLIER_MODEL,
              supplierKey: supplierDetailsModel.supplierKey,
              payload: supplierModel,
            });
          }
        } else {
          return of(); // do nothing - previously fetched model is already in state
        }
      }
    }),
  );

  @Effect()
  fetchComplSupplierMarketplaces = this.actions$.ofType(FETCH_COMPLIANCE_ACTIONS_WITH_FILES).pipe(
    map((action: FetchComplianceActionsWithFiles) => action.payload),
    withLatestFrom(this.store$),
    switchMap((results) => {
      const actionPayload = results[0];
      const appState = results[1]; // TODO nkler: check if they are already fetched (in state)

      const supplierKey: string = actionPayload.supplierKey;
      const refresh = actionPayload.refresh;

      const supplier = appState.supplier.suppliers[supplierKey];

      if (refresh || isUndefined(supplier) || isUndefined(supplier.kycDocumentsStatuses)) {
        // return this.onboardingService.prepareComplianceObservable(COMPLIANCE_ACTION_KEYS, supplierKey)
        return this.onboardingService.prepareKycDocumentsObservable(supplierKey, refresh).pipe(
          switchMap((kycDocumentsStatuses: any[]) => {
            const filteredResults = kycDocumentsStatuses.map((actionsArr) => actionsArr[0]);
            const supplierModel = new SupplierModel();
            supplierModel.kycDocumentsStatuses = filteredResults;

            return of({
              type: UPDATE_SUPPLIER_MODEL,
              supplierKey,
              payload: supplierModel,
            });
          }),
          catchError((error) => {
            Logger.log(error);
            return of({
              type: FETCH_COMPLIANCE_ACTIONS_WITH_FILES_ERROR,
            });
          }),
        );
      } else {
        return of(); // do nothing - previously fetched model is already in state
      }
    }),
  );

  @Effect()
  fetchSupplierMarketplace = this.actions$.ofType(FETCH_SUPPLIER_MARKETPLACE).pipe(
    map((action: FetchSupplierMarketplace) => action.payload),
    withLatestFrom(this.store$),
    switchMap(([actionPayload]) => {
      const supplierDetailsModel: SupplierDetailsModel = actionPayload.supplierDetailsModel;
      const refresh = actionPayload.refresh;

      if (!supplierDetailsModel.supplierKey) {
        return;
      }

      if (supplierDetailsModel.marketplaces !== undefined && supplierDetailsModel.marketplaces.length > 0) {
        return this.marketplaceService
          .fetchMarketplaceDetails(supplierDetailsModel.supplierKey, actionPayload.marketplaceUuid, refresh)
          .pipe(
            switchMap((marketplaceModel) => {
              return [new FetchSupplierMarketplaceSuccess({ marketplaceModel })];
            }),
            catchError((error) => {
              Logger.log(error);
              return of({
                type: FETCH_SUPPLIER_MARKETPLACE_ERROR,
              });
            }),
          );
      } else {
        const supplierModel = new SupplierModel();
        supplierModel.marketplaces = [];

        return of({
          type: UPDATE_SUPPLIER_MODEL,
          supplierKey: supplierDetailsModel.supplierKey,
          payload: supplierModel,
        });
      }
    }),
  );

  @Effect()
  fetchMarketplaceDetailsPart = this.actions$.ofType(FETCH_SUPPLIER_MARKETPLACE_DETAILS_PART).pipe(
    map((action: FetchSupplierMarketplaceDetailsPart) => action.payload),
    withLatestFrom(this.store$),
    switchMap((results) => {
      const actionPayload = results[0];
      const appState: AppState = results[1];

      const supplierDetailsModel: SupplierDetailsModel = actionPayload.supplierDetailsModel;
      const marketplaceModel: SupplierMarketplaceDetailsModel = actionPayload.marketplaceModel;
      const refresh = actionPayload.refresh;

      if (!isUndefined(supplierDetailsModel.supplierKey)) {
        const supplierModel: SupplierModel = appState.supplier.suppliers[supplierDetailsModel.supplierKey];

        const mpSupKey = marketplaceModel.mpSupKey;
        const supplierKey = supplierDetailsModel.supplierKey;

        if (
          refresh ||
          isUndefined(supplierModel) ||
          isUndefined(supplierModel[actionPayload.storeKey]) ||
          isUndefined(supplierModel[actionPayload.storeKey][mpSupKey])
        ) {
          const fetchService = this.availableServices[actionPayload.serviceClassName];

          return fetchService.fetchItems(supplierKey, mpSupKey, refresh).pipe(
            switchMap((marketplaceDetailsPartModel) => {
              let updatedModels = {};
              if (!isUndefined(supplierModel[actionPayload.storeKey])) {
                updatedModels = {
                  ...supplierModel[actionPayload.storeKey],
                };
              }
              updatedModels[mpSupKey] = marketplaceDetailsPartModel;
              supplierModel[actionPayload.storeKey] = updatedModels;

              return of({
                type: UPDATE_SUPPLIER_MODEL,
                supplierKey,
                payload: supplierModel,
              });
            }),
            catchError((error) => {
              Logger.log(error);
              return of({
                type: FETCH_SUPPLIER_MARKETPLACES_ERROR,
              });
            }),
          );
        } else {
          return of(); // do nothing - previously fetched model is already in state
        }
      }
    }),
  );

  @Effect()
  updateHideFlag = this.actions$.ofType(TOGGLE_HIDE_SUPPLIER).pipe(
    map((action: ToggleHideSupplier) => action.payload),
    withLatestFrom(this.store$),
    mergeMap((result) => {
      const currentHideFlag: boolean = result[0];
      const appState: AppState = <AppState>result[1];

      return this.supplierService.updateHideFlag(currentHideFlag, appState.supplier.supplierModel.supplierDetailsModel).pipe(
        switchMap((supplierDetailsModel) => {
          return [
            new ToggleHideSupplierSuccess(),
            new FetchSupplierDetails({
              supplierKey: supplierDetailsModel.supplierKey,
              refresh: true,
            }),
          ];
        }),
        catchError((error) => {
          return [new ToggleHideSupplierError(error)];
        }),
      );
    }),
  );

  @Effect()
  updateSuspendFlag = this.actions$.ofType(TOGGLE_SUSPEND_SUPPLIER).pipe(
    map((action: ToggleSuspendSupplier) => action.payload),
    withLatestFrom(this.store$),
    mergeMap((result) => {
      const appState: AppState = <AppState>result[1];
      const newSuspendFlag: boolean = result[0].suspend;
      const reason: boolean = result[0].reason;
      const supplierKey: string = appState.supplier.supplierModel.supplierDetailsModel.supplierKey;

      return this.supplierService.updateSuspendFlag(supplierKey, newSuspendFlag, reason).pipe(
        switchMap((supplierDetailsModel) => {
          return [
            new ToggleSuspendSupplierSuccess(),
            new FetchSupplierDetails({
              supplierKey,
              refresh: true,
            }),
            new CrmClearCurrentState(),
            new FetchCrmAll({
              refresh: `${moment().unix()}`,
              limit: 5,
            }),
            new FetchCrmNotes({
              refresh: `${moment().unix()}`,
              limit: 10,
            }),
            new FetchCrmPinnedNotes(),
          ];
        }),
        catchError((error) => {
          return [new ToggleSuspendSupplierError(error)];
        }),
      );
    }),
  );

  @Effect()
  updateLockFlag = this.actions$.ofType(TOGGLE_LOCK_SUPPLIER).pipe(
    map((action: ToggleLockSupplier) => action.payload),
    withLatestFrom(this.store$),
    mergeMap((result) => {
      const appState: AppState = <AppState>result[1];
      const newFlag: boolean = result[0].lock;
      const supplierKey: string = appState.supplier.supplierModel.supplierDetailsModel.supplierKey;

      return this.supplierService.updateLockFlag(supplierKey, newFlag).pipe(
        switchMap((supplierDetailsModel) => {
          return [
            new ToggleLockSupplierSuccess(),
            new FetchSupplierDetails({
              supplierKey,
              refresh: true,
            }),
            new CrmClearCurrentState(),
            new FetchCrmAll({
              refresh: `${moment().unix()}`,
              limit: 5,
            }),
            new FetchCrmNotes({
              refresh: `${moment().unix()}`,
              limit: 10,
            }),
            new FetchCrmPinnedNotes(),
          ];
        }),
        catchError((error) => {
          return [new ToggleLockSupplierError(error)];
        }),
      );
    }),
  );

  @Effect()
  updatePartial = this.actions$.ofType(UPDATE_MODEL_PART).pipe(
    map((action: UpdateModelPart) => action.payload),
    mergeMap((result) => {
      const { supplierKey, serviceClassName, newValues, formGroupKey, modelParams } = result;

      try {
        const updateService = this.availableServices[serviceClassName];

        return updateService.saveItemPart(supplierKey, newValues, ...modelParams).pipe(
          switchMap((supplierDetailsModel) => {
            return [
              new UpdateModelPartSuccess({
                supplierKey,
                formGroupKey,
                newValues,
              }),
              new FetchSupplierDetails({
                supplierKey,
                refresh: true,
              }),
            ];
          }),
          catchError((error) => {
            return [
              new UpdateModelPartError({
                supplierKey,
                newValues,
                formGroupKey,
                error,
              }),
            ];
          }),
        );
      } catch (error) {
        return of([
          new UpdateModelPartError({
            supplierKey,
            newValues,
            formGroupKey,
            error,
          }),
        ]);
      }
    }),
  );

  @Effect()
  getSupplierPaymentConfigs = this.actions$.ofType(FETCH_SUPPLIER_PAYMENT_CONFIGS).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: FetchSupplierPaymentConfigs = <FetchSupplierPaymentConfigs>result[0];
      const appState: AppState = <AppState>result[1];
      const supplierKey: string = appState.supplier.supplierKey;
      const forceNew = action && action.payload && action.payload.fresh;

      if (!isUndefined(supplierKey)) {
        const supplier = appState.supplier.supplierModel;

        if (!supplier.paymentConfigsModels || forceNew) {
          return this.paymentConfigsService.query(supplierKey).pipe(
            switchMap((results) => {
              const supplierPaymentConfigs = {};
              results.map((item) => {
                supplierPaymentConfigs[item.paymentConfigKey] = item;
              });

              return of({
                type: UPDATE_SUPPLIER_MODEL,
                payload: {
                  paymentConfigsModels: supplierPaymentConfigs,
                },
                supplierKey,
              });
            }),
            catchError((error) => {
              return of({
                type: FETCH_SUPPLIER_PAYMENT_CONFIGS_ERROR,
              });
            }),
          );
        }
      }
      return of();
    }),
  );

  @Effect()
  createSupplierPaymentConfigs = this.actions$.ofType(CREATE_SUPPLIER_PAYMENT_CONFIGS).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: CreateSupplierPaymentConfigs = <CreateSupplierPaymentConfigs>result[0];
      const appState: AppState = <AppState>result[1];
      const supplierKey: string = appState.supplier.supplierKey;
      const dataToSave = action && action.payload && action.payload.paymentConfig;
      const actionId = action && action.payload && action.payload.actionId;

      if (!isUndefined(supplierKey)) {
        return this.paymentConfigsService.create(supplierKey, dataToSave).pipe(
          switchMap((paymentConfigsModel) => {
            return [
              new PaymentConfigSuccess({
                actionId,
              }),
            ];
          }),
          catchError((error) => {
            return of(new PaymentConfigError({ actionId, error }));
          }),
        );
      }
      return of();
    }),
  );

  @Effect()
  deleteSupplierPaymentConfigs = this.actions$.ofType(DELETE_SUPPLIER_PAYMENT_CONFIGS).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: DeleteSupplierPaymentConfigs = <DeleteSupplierPaymentConfigs>result[0];
      const appState: AppState = <AppState>result[1];
      const supplierKey: string = appState.supplier.supplierKey;
      const paymentConfigKey = action && action.payload && action.payload.paymentConfigKey;
      const actionId = action && action.payload && action.payload.actionId;

      if (!isUndefined(supplierKey)) {
        return this.paymentConfigsService.remove(supplierKey, paymentConfigKey).pipe(
          switchMap((results) => {
            return [
              new PaymentConfigSuccess({
                actionId,
              }),
            ];
          }),
          catchError((error) => {
            return of(new PaymentConfigError({ actionId, error }));
          }),
        );
      }
      return of();
    }),
  );

  @Effect()
  updateSupplierPaymentConfigs = this.actions$.ofType(UDPATE_SUPPLIER_PAYMENT_CONFIGS).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: UpdateSupplierPaymentConfigs = <UpdateSupplierPaymentConfigs>result[0];
      const appState: AppState = <AppState>result[1];
      const supplierKey: string = appState.supplier.supplierKey;
      const dataToSave = action && action.payload && action.payload.paymentConfig;
      const paymentConfigKey = action && action.payload && action.payload.paymentConfigKey;
      const actionId = action && action.payload && action.payload.actionId;

      if (!isUndefined(supplierKey)) {
        return [
          new DeleteSupplierPaymentConfigs({ paymentConfigKey, actionId }),
          new CreateSupplierPaymentConfigs({ paymentConfig: dataToSave, actionId }),
        ];
      }
      return of();
    }),
  );

  @Effect()
  requestEfsCard = this.actions$.ofType(REQUEST_EFS_CARD).pipe(
    withLatestFrom(this.store$),
    switchMap((result) => {
      const action: RequestEfsCard = <RequestEfsCard>result[0];
      const appState: AppState = <AppState>result[1];
      const supplierKey: string = appState.supplier.supplierKey;
      const url = action && action.payload && action.payload.url;

      if (!isUndefined(url)) {
        return this.supplierService.requestEfsCard(supplierKey, url).pipe(
          switchMap((results) => {
            return of({
              type: UPDATE_SUPPLIER_MODEL,
              payload: {
                efsCardRequested: results.success,
              },
              supplierKey,
            });
          }),
          catchError((error) => {
            return of();
          }),
        );
      }
      return of();
    }),
  );

  @Effect()
  addOptInRow = this.actions$.ofType(ADD_OPT_IN_ROW).pipe(
    map((action: AddOptInRow) => action.payload),
    mergeMap((payload) => {
      return this.marketplaceOptInPeriodsService
        .addOptInPeriod(payload.supplierDetailsModel.supplierKey, payload.marketplaceDetailsModel.mpSupKey, payload.data)
        .pipe(
          switchMap((result) => {
            return [
              new AddOptInRowSuccess(payload),
              new FetchSupplierMarketplaceDetailsPart({
                marketplaceModel: payload.marketplaceDetailsModel,
                supplierDetailsModel: payload.supplierDetailsModel,
                serviceClassName: 'SupplierMarketplaceOptInPeriodsService', // TODO nkler: const does not work?
                storeKey: 'marketplaceOptInPeriodModels',
                refresh: true,
              }),
              new CrmClearCurrentState(),
              new FetchCrmAll({
                refresh: `${moment().unix()}`,
                limit: 5,
              }),
              new FetchCrmNotes({
                refresh: `${moment().unix()}`,
                limit: 10,
              }),
              new FetchCrmPinnedNotes(),
            ];
          }),
          catchError((error) => {
            Logger.log(error);
            return [new AddOptInRowError(error)];
          }),
        );
    }),
  );

  @Effect()
  deleteOptInRow = this.actions$.ofType(DELETE_OPT_IN_ROW).pipe(
    map((action: DeleteOptInRow) => action.payload),
    mergeMap((payload) => {
      return this.marketplaceOptInPeriodsService
        .deleteOptInPeriod(
          payload.supplierDetailsModel.supplierKey,
          payload.marketplaceDetailsModel.mpSupKey,
          payload.optInPeriodKey,
          payload.reason,
        )
        .pipe(
          switchMap((result) => {
            return [
              new DeleteOptInRowSuccess(payload),
              new FetchSupplierMarketplaceDetailsPart({
                marketplaceModel: payload.marketplaceDetailsModel,
                supplierDetailsModel: payload.supplierDetailsModel,
                serviceClassName: 'SupplierMarketplaceOptInPeriodsService', // TODO nkler: const does not work?
                storeKey: 'marketplaceOptInPeriodModels',
                refresh: true,
              }),
              new CrmClearCurrentState(),
              new FetchCrmAll({
                refresh: `${moment().unix()}`,
                limit: 5,
              }),
              new FetchCrmNotes({
                refresh: `${moment().unix()}`,
                limit: 10,
              }),
              new FetchCrmPinnedNotes(),
            ];
          }),
          catchError((error) => {
            Logger.log(error);
            return [new DeleteOptInRowError(error)];
          }),
        );
    }),
  );

  private availableServices: {};

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private supplierService: SupplierService,
    private router: Router,
    private autoPaymentService: AutoPaymentService,
    private fundingService: FundingParticularsService,
    private onboardingService: SupplierOnboardingService,
    private marketplaceService: SupplierMarketplacesService,
    private marketplaceFundingService: SupplierMarketplaceFundingService,
    private marketplaceOptInPeriodsService: SupplierMarketplaceOptInPeriodsService,
    private marketplaceMinFeeOverridesService: SupplierMarketplaceMinFeeOverridesService,
    private paymentConfigsService: PaymentConfigService,
    private longTermContractsService: LongTermContractsService,
    private readonly permissionsService: NgxPermissionsService,
  ) {
    this.availableServices = {
      SupplierService: this.supplierService,
      AutoPaymentService: this.autoPaymentService,
      FundingParticularsService: this.fundingService,
      PaymentConfigsService: this.paymentConfigsService,
      SupplierOboardingService: this.onboardingService,
      SupplierMarketplacesService: this.marketplaceService,
      SupplierMarketplaceFundingService: this.marketplaceFundingService,
      SupplierMarketplaceOptInPeriodsService: this.marketplaceOptInPeriodsService,
      SupplierMarketplaceMinFeeOverridesService: this.marketplaceMinFeeOverridesService,
    };
  }

  filter(activatedRoot, neededKey) {
    if (activatedRoot.params.hasOwnProperty(neededKey)) {
      return activatedRoot;
    }

    if (activatedRoot.firstChild) {
      return this.filter(activatedRoot.firstChild, neededKey);
    }
  }
}
