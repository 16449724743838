<h1 mat-dialog-title>Cancel {{ title }}</h1>
<div mat-dialog-content>
  <span *ngIf="showConfirmationScreen" class="info-box">Are you sure you want to proceed?</span>
  <mat-form-field class="field-text full-width">
    <input matInput type="text" placeholder="Transaction Key" [disabled]="showConfirmationScreen" [(ngModel)]="transactionKey" />
  </mat-form-field>
  <mat-form-field class="field-text full-width">
    <input matInput type="text" placeholder="Reason" [disabled]="showConfirmationScreen" [(ngModel)]="reason" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div *ngIf="showEditionScreen">
    <button mat-raised-button (click)="submitOnClick()" [disabled]="invalid" [color]="'accent'" tabindex="2">Submit</button>
    <button mat-raised-button (click)="cancelOnClick()" [color]="'warn'" tabindex="-1">Cancel</button>
  </div>
  <div *ngIf="showConfirmationScreen">
    <button mat-raised-button (click)="confirmOnClick()" [disabled]="invalid" [color]="'accent'" tabindex="2">Yes</button>
    <button mat-raised-button (click)="denyOnClick()" [color]="'warn'" tabindex="-1">No</button>
  </div>
</div>
