import { Injectable } from '@angular/core';

import { IDataTableAgGridHeaderDef } from '../data-table-ag-grid/models/data-table-ag-grid-header-def.interface';

@Injectable({
  providedIn: 'root',
})
export class AgGridDataReshaperService {
  constructor() {}

  prepareColumnDefs(columnsConfig: IDataTableAgGridHeaderDef[]): any[] {
    if (!columnsConfig || !columnsConfig.length) {
      return [];
    }

    return columnsConfig.map((column: IDataTableAgGridHeaderDef) => {
      return {
        field: column.data,
        colId: column.data,
      };
    });
  }

  flattenRowData(rowData: any) {}
}
