<div *ngIf="form" [formGroup]="form">
  <mat-form-field class="full-width">
    <mat-select placeholder="Template name" formControlName="templateName">
      <mat-option *ngFor="let template of templatesOptions" [value]="template.configKey">
        {{ template.configValue.template_name || template.configValue.label || template.configKey }}
        <small>{{ template.configValue.description }}</small>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="time-delay-wrapper">
    <i class="material-icons">notifications</i>

    <mat-form-field>
      <input matInput type="number" placeholder="Number" min="1" formControlName="delayTime" />
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Time" [(value)]="defaultTimePeriod" formControlName="delayPeriod">
        <mat-option *ngFor="let period of timePeriods" [value]="period.value">
          {{ period.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox formControlName="delayEnabled"> Delay sending </mat-checkbox>
  </div>

  <mat-checkbox class="send-checkbox" formControlName="sendToSupplier">Send to supplier</mat-checkbox>

  <div formArrayName="to">
    <h5>Receivers</h5>
    <ng-container *ngIf="to && to.controls">
      <div class="row" *ngFor="let control of to.controls; let last = last; let i = index">
        <mat-form-field class="col-md-10">
          <mat-select placeholder="CC" (keyup)="util.tryAddNext(to, i, util.toModel())" [formControlName]="i">
            <mat-option *ngFor="let user of usersList" [value]="user.email">
              {{ user.email }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button (click)="to.removeAt(i)" *ngIf="to.length > 1" class="col-md-1">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button (click)="util.tryAddNext(to, i, util.toModel())" *ngIf="last" class="col-md-1">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>

  <div class="timer-note">Note: Timer will trigger action type on a look-forward basis</div>
</div>
