import { CurrencyCellRenderer } from '../../../shared/functions/currency-cell-renderer';

interface ICustomColumnPredefinition {
  headerIcon?: 'icon-help';
}

export interface IColumnPredefinition extends ICustomColumnPredefinition {
  headerName: string;
  field: string;
  isSummed?: boolean;
  filter?: boolean | string;
  cellRenderer?: (params: { [key: string]: number }) => string;
  headerComponentParams?: {
    template: string;
  };
  onCellValueChanged?: (params: any) => any;
  headerClass?: string;
  pinned?: string;
  editable?: boolean;
  cellClass?: string | ((params: any, dictionary?: any) => string);
  cellEditor?: string;
  cellEditorParams?: (params: any) => any;
  valueFormatter?: (params: any) => any;
  valueParser?: (params: any) => any;
  onCellClicked?: (params: any) => any;
  width?: number;
  headerTooltip?: string;
}

export class ColumnPredef implements IColumnPredefinition {
  public headerComponentParams?: {
    template: string;
  };
  public width = 100;
  public headerHeight = 200;
  public filter?: boolean | string;
  public onCellValueChanged?: (params: any) => any;
  public headerClass?: string;
  public editable?: boolean;
  public cellStyle?: any;
  public cellClass?: string | ((params: any, dictionary?: any) => string);
  public cellEditor?: string;
  public cellEditorParams?: (params: any) => any;
  public valueFormatter?: (params: any) => any;
  public valueParser?: (params: any) => any;
  public onCellClicked?: (params: any) => any;
  /**
   * @param field
   * @param headerName
   * @param isSummed
   * @param cellRenderer
   * @param pinned
   */
  constructor(
    public field: string,
    public headerName: string,
    public isSummed: boolean,
    public cellRenderer?: (params) => string,
    public pinned?: string,
  ) {
    if (!cellRenderer) {
      this.cellRenderer = CurrencyCellRenderer;
    }
    if (pinned) {
      pinned === 'left' ? (this.pinned = 'left') : (this.pinned = 'right');
    }
  }
  fontColorAssigner(params: any): string {
    if (!params.value) {
      return '';
    }
    if (params.value < 0) {
      return `<span style="color: red"> ${params.value} </span>`;
    }
    return params.value;
  }
}
