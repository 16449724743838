<div class="dialog">
  <h1 mat-dialog-title>{{ data && data.title ? data.title : 'Confirmation required' }}</h1>
  <div mat-dialog-content class="dialog__content">
    <h6 class="dialog__message">{{ data.message }}</h6>
    <mat-form-field *ngIf="isMarqetaActionMode">
      <mat-select [placeholder]="'Choose a transition reason'" [formControl]="actionReason" required>
        <mat-option *ngFor="let reason of transitionReasons" [value]="reason.id">{{ reason.description }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions *ngIf="isProceedButtonVisible">
    <button mat-raised-button [mat-dialog-close]="true" tabindex="2" color="accent">Proceed</button>
    <button mat-raised-button [mat-dialog-close]="false" tabindex="-1" color="warn">Close</button>
  </div>
  <div mat-dialog-actions *ngIf="!isMarqetaActionMode && !isProceedButtonVisible">
    <button mat-raised-button [mat-dialog-close]="true" tabindex="2" color="accent">Yes</button>
    <button mat-raised-button [mat-dialog-close]="false" tabindex="-1" color="warn">No</button>
  </div>
  <div mat-dialog-actions *ngIf="isMarqetaActionMode && !isProceedButtonVisible">
    <button mat-raised-button (click)="closeDialog(actionReason)" [disabled]="actionReason.invalid" color="accent">Yes</button>
    <button mat-raised-button (click)="dialogRef.close(null)" color="warn">No</button>
  </div>
</div>
