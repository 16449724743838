<span class="confirmDelete__deleteIcon"
  ><i class="material-icons" (click)="openOption()">{{ icon }}</i></span
>
<span class="confirm-delete" [ngClass]="{ open: open }" data-test="crmQADeleteSpan"
  >Do you want to delete?
  <a data-test="crmConfirmDelete">
    <i (click)="confirm()" class="material-icons">done</i>
  </a>
  <a (click)="cancel()" data-test="crmCancelDelete">
    <i class="material-icons">cancel</i>
  </a>
</span>
