<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-title">Create Payment Batches</div>

      <form class="custom-form" [formGroup]="addPaymentBatchForm" (ngSubmit)="onSubmit()">
        <div class="form-content">
          <div class="form-actions">
            <a class="lock-button" title="{{ 'Click to ' + ((isLocked && 'unlock') || 'lock') }}" (click)="toggleLock()">
              <i class="material-icons">{{ isLocked ? 'lock' : 'lock_open' }}</i>
            </a>
          </div>

          <fieldset class="form-section">
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Batch Name" formControlName="name" />
            </mat-form-field>
            <mat-form-field (click)="picker.open()">
              <input
                matInput
                [matDatepicker]="picker"
                placeholder="Choose a date"
                readonly
                formControlName="date"
                (dateChange)="pickerOnDateChange($event)"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="time" placeholder="Choose a time" formControlName="time" />
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Fund Transfer Type" formControlName="type">
                <mat-option *ngFor="let type of batchTypes" [value]="type.value">
                  {{ type.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </fieldset>

          <fieldset class="form-section">
            <mat-radio-group class="full-width" formControlName="paymentType" (click)="checkBatchOption()">
              <mat-radio-button [disabled]="isDebit" value="instantAccess"
                >Is this batch for Instant Access payment requests?</mat-radio-button
              >
              <mat-radio-button [disabled]="isDebit" value="scheduledPayments">Is this batch for scheduled payments?</mat-radio-button>
              <mat-radio-button [disabled]="isDebit" value="proposedAdvanceLine"
                >Is this batch for Instant Advance payment requests?</mat-radio-button
              >
            </mat-radio-group>
          </fieldset>

          <h6>Payment types to include</h6>
          <fieldset class="form-section">
            <mat-checkbox [disabled]="isDebit" formControlName="addAchPmts">ACH</mat-checkbox>
            <mat-checkbox *ngIf="!isDebit" formControlName="addWirPmts">Wire</mat-checkbox>
          </fieldset>

          <fieldset [hidden]="true" class="form-section">
            <mat-form-field class="full-width">
              <mat-select [disabled]="isDebit" placeholder="Fee Delivery" formControlName="paymentBatchFeeCode">
                <mat-option *ngFor="let option of premiumFeeOption | async" [value]="option.codeValue">
                  {{ option.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </fieldset>
        </div>
        <div class="form-submit">
          <button class="btn btn-success pull-right" type="submit" color="accent" [disabled]="isLocked">Save</button>
        </div>
      </form>
    </div>
  </div>
</section>
