import { Observable, of } from 'rxjs';

export abstract class LookupAbstractService {
  public getFundStatusList(): Observable<any> {
    return of([
      { label: 'Not Sent', id: 'Not Sent', disabled: false },
      { label: 'Sent', id: 'Sent', disabled: false },
      { label: 'Rejected', id: 'Rejected', disabled: false },
      { label: 'Approved', id: 'Approved', disabled: true },
    ]);
  }

  public getSellingTimeRanges(): Observable<any> {
    return of([
      { label: 'Less than 90 days', id: '<90d' },
      { label: 'Between 90 days and 1 year', id: '>=90d,<=1y' },
      { label: 'Over 1 year', id: '>1y' },
    ]);
  }

  public getIMTypes(): Observable<any[]> {
    return of([
      { id: 'Skype', label: 'Skype' },
      { id: 'Google', label: 'Google' },
      { id: 'Aim', label: 'Aim' },
      { id: 'Other', label: 'Other' },
    ]);
  }

  abstract getTimezones(): Observable<any[]>;

  abstract getCountries(): Observable<any[]>;

  abstract getXactTypes(): Observable<any[]>;

  abstract getFundingPeriods(): Observable<any[]>;

  abstract getBusinessTypes(): Observable<any[]>;

  abstract getUSStates(): Observable<any[]>;

  abstract getStates(countryCode: string): Observable<any[]>;

  abstract getDelinquencyScenarios(): Observable<any>;

  abstract getDelinquencyAges(): Observable<any>;

  abstract getDelinquencyTypes(): Observable<any>;

  abstract getComplianceDoctypes(): Observable<any[]>;

  abstract getFeeSchedules(params?): Observable<any[]>;

  abstract getPaymentMethods(countryCode: string): Observable<any[]>;

  abstract getPayeeStatuses(): Observable<any>;

  abstract getOutcomeReasons(): Observable<any>;

  abstract getPaymentOutcome(): Observable<any>;

  abstract getReviewers(): Observable<any>;

  abstract getCodes(domain: string): Observable<any[]>;
}
