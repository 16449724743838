export interface EventRule extends Entity {
  actions: Array<Action>;
  eventType: string;
  eventTypeCode: string;
  targetType: string;
  message: string;
  user: string;
  filters: Array<Filter>;
}

export class Filter {
  constructor(public name: string, public op: string, public val: string | number) {}

  static regex = /([a-zA-Z.]+ ) *(!=|=|<|>|>=|<=|=<|=>) *([^ ]+)/;

  static fromExpression(text) {
    const [all, name, operator, val] = text.match(Filter.regex);
    let op;
    switch (operator) {
      case '=':
        op = 'eq';
        break;
      case '!=':
        op = 'ne';
        break;
      case '<':
        op = 'lt';
        break;
      case '>':
        op = 'gt';
        break;
      case '>=':
      case '=>':
        op = 'gte';
        break;
      case '<=':
      case '=<':
        op = 'lte';
        break;
      default:
        throw new Error("Can't parse " + text);
    }
    return new Filter(name, op, val);
  }

  static fromJson({ name, op, val }) {
    return new Filter(name, op, val);
  }

  toExpression() {
    let operator;
    switch (this.op) {
      case 'eq':
        operator = '=';
        break;
      case 'ne':
        operator = '!=';
        break;
      case 'lt':
        operator = '<';
        break;
      case 'gt':
        operator = '>';
        break;
      case 'gte':
        operator = '>=';
        break;
      case 'lte':
        operator = '=<';
        break;
      default:
        throw new Error("Can't convert " + this);
    }
    return `${this.name} ${operator} ${this.val}`;
  }
}

export class Action {
  static CREATE_TASK = 'create-task';
  static SEND_EMAIL = 'send-email';
  static SLACK_NOTIF = 'notify-on-slack';
  static EDIT_SUPPLIER = 'edit-supplier';
  static SEND_TO_EVENT_BRIDGE = 'push-to-event-bridge';

  type: string;
  template: string;
  to?: string;
  contextMapping: object;
  dataMapping: object;
  data: object;
  delay: number;
  delayTime: number;
  delayPeriod: number;
  delayEnabled: boolean;
}

interface Entity {
  uuid: string;
  created_at: number;
}

export const actionTypes = [
  Action.CREATE_TASK,
  Action.SEND_EMAIL,
  // Action.SLACK_NOTIF,
  Action.EDIT_SUPPLIER,
  Action.SEND_TO_EVENT_BRIDGE,
];

export const eventTypes = [
  'analitics',
  'api-event',
  'cli-event',
  'event',
  'messaging-log',
  'messaging-result',
  'task-change-event',
  'underwriting-event',
];

export const eventTargets = ['compliance', 'crm-task', 'email', 'marketplace', 'supplier'];
