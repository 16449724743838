<div id="container">
  <mat-card
    *ngFor="let item of list; let i = index"
    class="container__card"
    id="{{ i }}"
    draggable="true"
    (dragstart)="dragStart($event)"
    (dragover)="dragOver($event)"
    (drop)="dropItem($event, i)"
  >
    <div>
      {{ item.marketplaceName }}
    </div>
    <div class="container__card__buttons">
      <button mat-icon-button color="warn" (click)="removeItem(i)">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="container__card__buttons__menu">
        <mat-icon>menu</mat-icon>
      </div>
    </div>
  </mat-card>
</div>
