import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of, Subscription } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { isObject } from 'util';

import { SubscriberComponent } from '../../../../../../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from '../../../../../../../../shared/confirm-dialog/confirm-dialog.component';
import { DIALOG_SIZE } from '../../../../../../../../shared/dialog.config';
import { ErrorVisualizationService } from '../../../../../../../../shared/services/error-visualization/error-visualization.service';
import { UsersService } from '../../../../../../../../shared/users/service/users.service';
import { USER_PERMISSION } from '../../../../../../../../shared/users/user-permissions.enum';
import { AppState } from '../../../../../../../../store/app.reducers';
import { ALL_UW_STATUSES } from '../../../../../../../utils/all-uw-statuses';
import { isExpectedDurationValid } from '../../../../../../../utils/is-expected-duration-valid';
import { isExpectedMonthlyEarningsValid } from '../../../../../../../utils/is-expected-montly-earnings-valid';
import { SupplierAdvancesService } from '../../../../../service/supplier-advances.service';
import { SummaryOfferModel } from '../../../models/summary-offer.model';
import { PendingOfferRiskRatingService } from '../../../service/pending-offer-risk-rating.service';
import { PeriodsCalculationsService } from '../../../service/periods-calculations.service';
import { OfferStatuses } from '../../../store/calculator.enums';
import { CalculatorState } from '../../../store/calculator-interfaces';
import { UserModel } from '../../../../../../../../shared/users/user.model';
import { selectLoggedUserModel } from '../../../../../../../../auth/store/auth.actions';

@Component({
  selector: 'app-advance-offer-summary',
  templateUrl: './advance-offer-summary.component.html',
  styleUrls: ['./advance-offer-summary.component.scss'],
})
export class AdvanceOfferSummaryComponent extends SubscriberComponent implements OnInit {
  @Input() advanceOfferRequest: any;

  elementData = [];
  isTotalFeeIncorrect: boolean;
  isTotalPeriodToLong: boolean;
  isMpReserveForPaymentEmpty: boolean;
  isReadonly: boolean;

  approvedAmount: number;
  netDeliveryAmount: number;
  reserveForRepayments: number;
  totalFee: number;
  forceFullSaved: boolean;

  summaryOffer: SummaryOfferModel;

  isSaveEnabled: boolean;
  AdvanceUwStatuses = ALL_UW_STATUSES;

  expectedMonthlyEarnings: number;
  expectedDuration: number;
  totalPeriodLength: number;
  averageFee: number;

  pendingAdvanceLineKey: string;
  isRetractDisabled: boolean;
  isRetractVisible: boolean;

  constructor(
    private readonly store: Store<AppState>,
    private readonly pendingOfferRiskRatingService: PendingOfferRiskRatingService,
    private readonly supplierAdvancesService: SupplierAdvancesService,
    private readonly toastr: ToastrService,
    private readonly errorVisualizationService: ErrorVisualizationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialogService: MatDialog,
    private readonly usersService: UsersService,
    private readonly cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.summaryOffer = new SummaryOfferModel(
      this.advanceOfferRequest.uw_status,
      this.advanceOfferRequest.underwriting_date,
      this.advanceOfferRequest.offer_status,
      this.advanceOfferRequest.create_ts,
      this.advanceOfferRequest.update_ts,
      this.advanceOfferRequest.last_login_id.split(':', 2)[1],
    );
    this.isSaveEnabled = false;

    this.initRetract();

    this.subscriptions.push(
      this.store.select('calc').subscribe((calcValue) => {
        const periods = calcValue.periods;
        this.isTotalFeeIncorrect = calcValue.isTotalFeeIncorrect;
        this.isTotalPeriodToLong = calcValue.isTotalPeriodToLong;
        this.isMpReserveForPaymentEmpty = calcValue.isMpReserveForPaymentEmpty;
        this.isReadonly = calcValue.isReadonly;

        this.isSaveEnabled = this.checkIfSaveIsEnabled(calcValue);

        this.approvedAmount = calcValue.approvedAmount;
        this.netDeliveryAmount = calcValue.netDeliveryAmount;
        this.reserveForRepayments = calcValue.reserveForRepayments;
        this.totalFee = calcValue.totalFee;
        this.forceFullSaved = calcValue.forceFullSaved;
        this.expectedMonthlyEarnings = calcValue.expectedMonthlyEarnings;
        this.expectedDuration = calcValue.expectedDuration;
        this.totalPeriodLength = calcValue.totalPeriodLength;
        this.averageFee = calcValue.averageFee;

        this.elementData = PeriodsCalculationsService.createPeriodSummary(periods);
      }),
    );
  }

  checkIfSaveIsEnabled(state: CalculatorState): boolean {
    if (state.isTotalPeriodToLong) {
      return false;
    }
    if (state.isTotalFeeIncorrect) {
      return false;
    }
    if (!state.approvedAmount) {
      return false;
    }
    if (state.isEmptyFee) {
      return false;
    }
    if (state.isEmptyPeriodLength) {
      return false;
    }
    if (!isExpectedMonthlyEarningsValid(state.expectedMonthlyEarnings)) {
      return false;
    }
    if (!isExpectedDurationValid(state.expectedDuration, state.totalPeriodLength)) {
      return false;
    }
    return !state.isReadonly;
  }

  redirectToBase(): void {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  updatePendingOffer(): void {
    this.subscriptions.push(
      this.store.select('calc').subscribe((calcValues) => {
        this.subscriptions.push(
          this.pendingOfferRiskRatingService
            .updateProgressivePricingOffer(calcValues, this.advanceOfferRequest.advance_offer_request_key)
            .subscribe(
              (data) => {
                this.toastr.success('Offer was updated successfully');
                this.redirectToBase();
                return data;
              },
              (err: any) => {
                this.errorVisualizationService.showError({
                  name: 'Offer Error',
                  errorObject: err,
                });
              },
            ),
        );
      }),
    );
  }

  uwStatusChangeRequest(e): void {
    this.subscriptions.push(
      this.supplierAdvancesService
        .postUnderwritingStatus(this.advanceOfferRequest.supplier_key, this.advanceOfferRequest.advance_offer_request_key, {
          uw_status: e.value,
        })
        .subscribe(
          () => {},
          (err: any) => {
            this.errorVisualizationService.showError({
              name: 'Offer Error',
              errorObject: err,
            });
          },
        ),
    );
  }

  retractOffer(): void {
    const confirmData = {
      data: {
        title: 'Retract Offer',
        message: 'By confirming this action, the system will remove the offer from the supplier dashboard.',
      },
      width: DIALOG_SIZE.SMALL.width,
    };

    const subscription: Subscription = this.dialogService
      .open(ConfirmDialogComponent, confirmData)
      .afterClosed()
      .pipe(
        take(1),
        mergeMap((confirmed) =>
          confirmed ? this.supplierAdvancesService.retractOffer(this.pendingAdvanceLineKey) : of({ preventAction: true }),
        ),
      )
      .subscribe(
        (param: { preventAction: boolean }) => {
          if (!param?.preventAction) {
            this.toastr.success('Offer retracted successfully!');
            this.redirectToBase();
          }
        },
        (error) => {
          if (error?.error?.message) {
            if (isObject(error.error.message)) {
              this.toastr.error(Object.entries(error.error.message).reduce((prev, curr) => prev + `${curr[0]}: ${curr[1]}\n`, ''));
            } else {
              this.toastr.error(error.error.message);
            }
          }
        },
      );

    this.subscriptions.push(subscription);
  }

  private initRetract(): void {
    this.isRetractDisabled = OfferStatuses.PROCESSED !== this.advanceOfferRequest.status;
    this.pendingAdvanceLineKey = this.advanceOfferRequest.proposed_advance_line_key || this.advanceOfferRequest.advance_offer_request_key;

    let hasGeneralPermission: boolean;
    let userModel: UserModel;

    const subscription: Subscription = this.store
      .select(selectLoggedUserModel)
      .pipe(
        filter(Boolean),
        tap((user: UserModel) => (userModel = user)),
        mergeMap(() => this.usersService.checkGeneralPermissions([USER_PERMISSION.FINANCE, USER_PERMISSION.DEV], userModel)),
        tap((generalPermission: boolean) => (hasGeneralPermission = generalPermission)),
        mergeMap(() => this.usersService.checkPermissions([USER_PERMISSION.FINANCE, USER_PERMISSION.DEV], userModel)),
      )
      .subscribe((hasPermission: boolean) => {
        this.isRetractVisible = hasGeneralPermission || hasPermission;
        this.cd.detectChanges();
      });
    this.subscriptions.push(subscription);
  }
}
