<ng-template #loadingForm>
  <div class="form-content">
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #authTokenAddForm>
  <div class="form-content">
    <form
      *ngIf="authTokenForm"
      app-card-formgroup
      #formAuthToken
      [formGroup]="authTokenForm"
      (onSave)="saveAuthToken(authTokenForm, true)"
      (onEdit)="onEdit($event)"
    >
      <app-inline-edit-text
        *ngIf="formAuthToken.inEdit"
        [formControlName]="'token'"
        [formGroup]="authTokenForm"
        [inGroupEdit]="formAuthToken.inEdit"
        [label]="'Auth token'"
        [disableInline]="true"
      >
      </app-inline-edit-text>

      <div ngxErrors="token" class="auth-code-error">
        <mat-error ngxError="pattern" when="dirty">
          Auth token must be in base32 format. See examples on
          <a target="_blank" href="https://tools.ietf.org/html/rfc3548.html">base32 Encoding</a>
        </mat-error>
      </div>

      <button *ngIf="!formAuthToken.inEdit" (click)="formAuthToken.inEdit = true; clearForm()" mat-raised-button color="primary">
        Setup Token
      </button>
    </form>
  </div>
</ng-template>

<ng-template #authTokenEditForm>
  <div class="form-content">
    <form app-card-formgroup *ngIf="authTokenForm" #formAuthToken [formGroup]="authTokenForm" (onSave)="saveAuthToken(authTokenForm)">
      <span class="token-preview badge badge-secondary" [hidden]="formAuthToken.inEdit"> Encrypted value: *************** </span>

      <app-inline-edit-text
        *ngIf="formAuthToken.inEdit"
        [formControlName]="'token'"
        [formGroup]="authTokenForm"
        [label]="'Auth token'"
        [disableInline]="true"
        [inGroupEdit]="formAuthToken.inEdit"
      >
      </app-inline-edit-text>

      <div ngxErrors="token" class="auth-code-error">
        <mat-error ngxError="pattern" when="dirty">
          Auth token must be in base32 format. See examples on
          <a target="_blank" href="https://tools.ietf.org/html/rfc3548.html">base32 Encoding</a>
        </mat-error>
      </div>

      <div class="clearfix" *ngIf="!formAuthToken.inEdit">
        <button (click)="deleteAuthToken()" mat-raised-button color="warn">Delete Token</button>
      </div>
    </form>
  </div>
</ng-template>

<div *ngIf="authTokenChecked; else loadingForm">
  <h5>Auth token</h5>
  <div *ngIf="currentAuthToken; then authTokenEditForm; else authTokenAddForm"></div>
</div>

<div *ngIf="authCode">
  <button class="pull-right" mat-raised-button color="primary" (click)="getAuthCode()">Get new code</button>
  <h5>
    Auth code:
    <span class="auth-code badge badge-secondary" mdTooltip="Click to copy" ngxClipboard [cbContent]="authCode">{{ authCode }}</span>
    <mat-icon ngxClipboard [cbContent]="authCode" class="btn-copy" mdTooltip="Click to copy">content_copy</mat-icon>
  </h5>

  <div class="clearfix">
    <mat-progress-bar [color]="'accent'" [mode]="'determinate'" [value]="authCodeCounter / 3"> </mat-progress-bar>
  </div>
</div>
