<div class="paginationComponent" *ngIf="_paginationData">
  <div class="info">
    <span *ngIf="configuration && configuration.perPageOptions">
      <small> Items per page:&nbsp; </small>
      <mat-form-field>
        <mat-select placeholder="" aria-label="type" [(ngModel)]="configuration.selectedPerPage" (selectionChange)="changeItemsPerPage()">
          <mat-option *ngFor="let itemCount of configuration.perPageOptions" [value]="itemCount">
            {{ itemCount }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      {{ _paginationData.itemMin }} - {{ _paginationData.itemMax }} of {{ _paginationData.total }} items
    </span>
  </div>

  <ul class="pagination">
    <li class="page-item"><a class="page-link" (click)="navClick(0)">First</a></li>
    <li class="page-item"><a class="page-link" (click)="navClick(currentPage - 1)">Previous</a></li>
    <li class="page-item" *ngIf="pageRangeFrom > 0"><a class="page-link">...</a></li>
    <li class="page-item" *ngFor="let page of pages" [ngClass]="{ active: page === currentPage }">
      <a class="page-link" (click)="navClick(page)">{{ page + 1 }}</a>
    </li>
    <li class="page-item" *ngIf="pageRangeTo < lastPage"><a class="page-link">...</a></li>
    <li class="page-item"><a class="page-link" (click)="navClick(currentPage + 1)">Next</a></li>
    <li class="page-item" *ngIf="!!_paginationData.total"><a class="page-link" (click)="navClick(lastPage)">Last</a></li>
  </ul>
</div>
