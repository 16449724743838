import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { PAYMENT_TYPE } from '../../../shared/enums/payment-type';
import { trackByIndex } from '../../../shared/utils/functions/track-by-index.util';
import { AppState } from '../../../store/app.reducers';
import { PaymentConfigModel } from '../../model/payment-config.model';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { TransferPaymentDetails } from '../../model/transfer-payment-details.model';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierBalance, selectSupplierDetails } from '../../store/supplier/supplier.actions';

@Component({
  selector: 'app-create-transfer-request',
  templateUrl: './create-transfer-request.component.html',
  styleUrls: ['./create-transfer-request.component.scss'],
})
export class CreateTransferRequestComponent extends SubscriberComponent implements OnInit {
  readonly MIN_TRANSFER_AMOUNT = 25;
  readonly trackBy = trackByIndex;

  supplierKey: string;
  loading = true;
  radiobuttons = [];
  form: FormGroup;
  availableBalance = 0;
  supplierBalanceIsLowerThanMin = false;
  supplierDetails: SupplierDetailsModel;

  constructor(
    public dialogRef: MatDialogRef<CreateTransferRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { supplierKey: string },
    private supplierService: SupplierService,
    private fb: FormBuilder,
    private store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setSupplierKey();
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      paymentConfigKey: ['', Validators.required],
      requestedAmount: [undefined, [this.requestedAmountValidator.bind(this)]],
      transferEntireBalance: [false],
    });
    this.subscribeOnFormChanges();
  }

  requestedAmountValidator(control: FormControl): null | { invalid: true } {
    const value = control.value;
    const isValidAmount = value && value >= this.MIN_TRANSFER_AMOUNT && value <= this.availableBalance;
    return isValidAmount ? null : { invalid: true };
  }

  subscribeOnFormChanges(): void {
    this.subscriptions.push(
      this.form.controls.transferEntireBalance.valueChanges.subscribe((transferEntireBalance) => {
        this.form.controls.requestedAmount.patchValue(transferEntireBalance ? this.availableBalance : undefined);
      }),
    );
  }

  setSupplierKey(): void {
    if (this.data && this.data.supplierKey) {
      this.supplierKey = this.data.supplierKey;
      this.getPaymentConfigs();
      this.selectSupplierBalance();
      this.selectSupplierDetails();
    } else {
      throw new Error('Missing required supplierKey');
    }
  }

  selectSupplierBalance(): void {
    this.subscriptions.push(
      this.store.select(selectSupplierBalance).subscribe((supplierBalance) => {
        if (!supplierBalance) {
          return;
        }
        this.availableBalance = supplierBalance.availableBalance;
        this.supplierBalanceIsLowerThanMin = this.availableBalance < this.MIN_TRANSFER_AMOUNT;
      }),
    );
  }

  selectSupplierDetails(): void {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetails) => {
        this.supplierDetails = supplierDetails;
      }),
    );
  }

  getPaymentConfigs(): void {
    if (!this.supplierKey) {
      return;
    }
    this.subscriptions.push(
      this.supplierService.getSupplierPaymentConfigs(this.supplierKey).subscribe((configs: PaymentConfigModel[]) => {
        this.loading = false;
        this.radiobuttons = configs.map((config) => {
          return {
            text: this.getConfigText(config.paymentType, config.paymentConfig),
            value: config.paymentConfigKey,
            isDefault: config.isDefault,
            disabled: !this.isApproved(config),
          };
        });
      }),
    );
  }

  getConfigText(paymentType: string, paymentConfig): PAYMENT_TYPE | '' {
    if (!paymentConfig || !paymentType) {
      return;
    }
    switch (paymentType) {
      case PAYMENT_TYPE.EFS: {
        return paymentConfig.efs && paymentConfig.efs.cardNumber;
      }
      case PAYMENT_TYPE.PPM: {
        return (
          paymentConfig.ppm &&
          (paymentConfig.ppm.cardNumber
            ? paymentConfig.ppm.cardNumber
            : paymentConfig.ppm.cardholderFirstName + ' ' + paymentConfig.ppm.cardholderLastName)
        );
      }
      case PAYMENT_TYPE.WIR: {
        return paymentConfig.bank && paymentConfig.bank.name;
      }
      case PAYMENT_TYPE.PCARD: {
        return paymentConfig.pcard && paymentConfig.pcard.cardNumber;
      }
      default: {
        return '';
      }
    }
  }

  onSaveClick(): void {
    if (this.form && this.form.valid) {
      this.requestPayment();
    }
  }

  requestPayment(): void {
    const paymentDetails: TransferPaymentDetails = {
      requestedAmount: this.form.value.requestedAmount,
      paymentConfigKey: this.form.value.paymentConfigKey,
    };

    this.subscriptions.push(
      this.supplierService.requestPayment(this.supplierKey, paymentDetails).subscribe(
        () => {
          this.closeDialog({
            type: 'success',
            text: `${this.supplierDetails.contactFirstName} ${this.supplierDetails.contactLastName}
              requested transfer of ${paymentDetails.requestedAmount} via ${paymentDetails.paymentConfigKey}`,
          });
        },
        (err) => {
          this.closeDialog({
            type: 'error',
            text: err && err.error && err.error.message ? err.error.message : 'Sorry, something went wrong',
          });
        },
      ),
    );
  }

  isApproved(result: PaymentConfigModel): boolean {
    return result.configStatus === 'approved';
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }
}
