import { GlobalSetting } from '../models/global-setting.model';
import { GLOBAL_SETTINGS_ACTION, GlobalSettingsActions } from './global-settings.actions';
import { GLOBAL_SETTINGS_STATE, GlobalSettingsState } from './global-settings.state';

function updateConfig(config: GlobalSetting, configs: GlobalSetting[]): GlobalSetting[] {
  if (!config || !configs) {
    return;
  }

  const _configs = [...configs.map((c) => ({ ...c }))];

  const shoudDelete = !config.config_value;
  const index = _configs.findIndex((e) => e.config_key === config.config_key);

  if (index < 0) {
    if (!shoudDelete) {
      _configs.push(config);
    }
  } else {
    if (!shoudDelete) {
      _configs[index] = config;
    } else {
      delete _configs[index];
    }
  }

  return _configs;
}

export function globalSettingsReducers(state = GLOBAL_SETTINGS_STATE, action: GlobalSettingsActions): GlobalSettingsState {
  switch (action.type) {
    case GLOBAL_SETTINGS_ACTION.FETCH_ALL:
      return {
        ...state,
        loading: true,
      };
    case GLOBAL_SETTINGS_ACTION.FETCH_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        configs: action.payload.configs,
      };
    case GLOBAL_SETTINGS_ACTION.UPDATE_CONFIG:
      return {
        ...state,
        configs: updateConfig(action.payload.config, state.configs),
      };
    default:
      return state;
  }
}
