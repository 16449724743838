import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ReportsAbstractService } from '../model/reports-abstract.service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { EditableReportModel } from './../model/report-model';

@Component({
  selector: 'app-report-add',
  templateUrl: './report-add.component.html',
  styleUrls: ['./report-add.component.scss'],
})
export class ReportAddComponent extends SubscriberComponent implements OnInit {
  // Fields
  public addReportForm: FormGroup;

  public viewsAndTables: string[];
  public enabledForSupplierDictionary: { label: string; id: string }[];

  constructor(
    private service: ReportsAbstractService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  // Methods
  public ngOnInit() {
    this.getData();
    this.setupComponent();
  }

  public onSubmit(): void {
    if (this.addReportForm.valid) {
      const init = {
        reportLabel: this.addReportForm.value.reportLabel,
        reportName: this.addReportForm.value.reportName,
        enabledForReports: this.addReportForm.value.enabledForReports,
        enabledForSupplier: this.addReportForm.value.enabledForSupplier !== 'no' ? this.addReportForm.value.enabledForSupplier : null,
      };

      const newReport = new EditableReportModel();
      Object.assign(newReport, init);

      this.subscriptions.push(
        this.service.addReport(newReport).subscribe(
          (success) => {
            this.toastrService.success('New report config has been successfully added.');
            this.router.navigate(['../list'], { relativeTo: this.route });
          },
          (error) => {
            this.toastrService.error('Adding new report config failed.');
          },
        ),
      );
    }
  }

  public onCancel(): void {
    this.router.navigate(['../list'], { relativeTo: this.route });
  }

  private getData(): void {
    this.subscriptions.push(
      this.service.getViewsAndTables().subscribe((data) => {
        this.viewsAndTables = data;
      }),
    );
  }

  private setupComponent(): void {
    this.enabledForSupplierDictionary = [
      { label: 'No', id: 'no' },
      { label: 'Yes', id: 'all' },
      { label: 'Only for Instant Access suppliers', id: 'instant-access' },
      { label: 'Only for Non-Instant Access suppliers', id: 'non-instant-access' },
    ];

    this.addReportForm = this.formBuilder.group({
      reportName: [{ value: '', disabled: false }, Validators.required],
      reportLabel: [{ value: '', disabled: false }, Validators.required],
      enabledForSupplier: [{ value: '', disabled: false }, Validators.required],
      enabledForReports: [false],
    });
  }
}
