<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-content">
        <span class="card-title">Underwriting History</span>
        <div class="table-wrapper">
          <table class="table table-condensed table-hover" *ngIf="underwritingHistory.length >= 1; else noHistory">
            <thead>
              <tr>
                <th>Date created</th>
                <th>Date updated</th>
                <th>Author</th>
                <th>Status</th>
                <th width="10%">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of underwritingHistory; let $index = index">
                <td>{{ item?.created | date: "MM/dd/yyyy 'at' h:mma" }}</td>
                <td>{{ item?.updated | date: "MM/dd/yyyy 'at' h:mma" }}</td>
                <td>{{ item?.completed_by }}</td>
                <td>{{ item?.status === 'verified' ? 'approved' : item?.status }}</td>
                <td class="text-left">
                  <button class="btn btn-primary btn-outlined btn-sm" (click)="getUnderwritingHistoryRecordClick(item, $index)">
                    Load results
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <ng-template #noHistory> No history records for supplier </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
