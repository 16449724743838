import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { isUndefined } from 'util';

import { FeatureFlagsService } from '../../shared/feature-flags/feature-flags.service';
import { FeatureFlagsEvaService } from '../../shared/feature-flags/feature-flags-eva.service';
import { VersionService } from '../../shared/version.service';
import { AppState } from '../../store/app.reducers';
import { GoogleSignInSuccess } from '../google-auth-login/google-auth-login.component';
import { ClearAuthState, LoginFailed, TryLogin, TryLoginGoogle } from '../store/auth.actions';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends SubscriberComponent implements OnInit {
  loading = false;
  loginForm: FormGroup;
  model: any = {};
  feVersion: string;
  beVersion: string;

  private routerParamsSub: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private versionService: VersionService,
    private featureFlagsService: FeatureFlagsService,
    private featureFlagsEvaService: FeatureFlagsEvaService,
  ) {
    super();
    this.feVersion = this.versionService.getFrontendVersion();
    this.createForm();
  }

  ngOnInit(): void {
    this.featureFlagsService.clearAvailableFlagsExecStatus();
    this.featureFlagsEvaService.reset();

    this.getBackendVersion();

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        if (!isUndefined(params['message'])) {
          setTimeout(() => {
            this.toastrService.info(params['message'], null, { timeOut: 10000 });
          });
        }
      }),
    );

    this.subscriptions.push(
      this.store.select('auth').subscribe((authState) => {
        if (authState.authError && this.loading) {
          let message = 'Cannot connect with server';
          if (!isUndefined(authState.authError) && authState.authError.status === 401) {
            message = 'Invalid login or password';
          }
          if (!isUndefined(authState.authError) && authState.authError.status === 403) {
            message = 'Access denied';
          }

          this.toastrService.error(message, null, { timeOut: 3000 });
          this.loading = false;

          this.store.dispatch(new ClearAuthState());
        }
      }),
    );
  }

  getBackendVersion(): void {
    this.subscriptions.push(
      this.versionService.getBackendVersion().subscribe((version: string) => {
        this.beVersion = version;
      }),
    );
  }

  createForm(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login(): void {
    this.loading = true;
    this.model = this.loginForm.value;

    this.store.dispatch(new TryLogin({ username: this.model.username, password: this.model.password }));
  }

  onGoogleSignInSuccess(event: GoogleSignInSuccess): void {
    this.loading = true;
    this.store.dispatch(new TryLoginGoogle(event.googleUser));
  }

  onGoogleSignInFailure(): void {
    this.loading = false;
    this.store.dispatch(new LoginFailed({ status: 403 }));
  }
}
