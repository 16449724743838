<h1 mat-dialog-title>Supplier's bank accounts connected to Plaid</h1>

<div mat-dialog-content class="pt-2 pb-2">
  <ng-container *ngIf="!dataLoading; else spinner">
    <ng-container *ngIf="authData; else noData">
      <ul class="authDataList">
        <li *ngFor="let item of authData; trackBy: trackBy" class="mb-3">
          <mat-card>
            <p>
              <span class="authDataList__itemTitle">Owners:</span>
              <ng-container *ngIf="item.ownersNames && item.ownersNames.length; else emptyCell">
                <ng-container *ngFor="let name of item.ownersNames; let i = index; trackBy: trackBy">
                  {{ name }}
                  <span *ngIf="i !== item.ownersNames.length - 1">, </span>
                </ng-container>
              </ng-container>
            </p>
            <p>
              <span class="authDataList__itemTitle">Account Number:</span>
              {{ item | safeValue: ['accountNumber'] }}
            </p>
            <p>
              <span class="authDataList__itemTitle">ACH Routing Number: </span>
              {{ item | safeValue: ['achRoutingNumber'] }}
            </p>
            <p>
              <span class="authDataList__itemTitle">Wire Routing Number:</span>
              {{ item | safeValue: ['achWireRoutingNumber'] }}
            </p>
          </mat-card>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="closeDialog()">Close</button>
</div>

<ng-template #spinner>
  <div class="spinnerWrapper d-flex align-items-center justify-content-center">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #noData>
  <p>
    <span>Sorry, some error occurred</span>
    <ng-container *ngIf="errorMessage">
      <span>: {{ errorMessage }}</span>
    </ng-container>
  </p>
</ng-template>

<ng-template #emptyCell>-</ng-template>
