import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-permission-error-pop-up',
  templateUrl: './permission-error-pop-up.component.html',
  styleUrls: ['./permission-error-pop-up.component.scss'],
})
export class PermissionErrorPopUpComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PermissionErrorPopUpComponent>) {}

  ngOnInit() {}

  close(payload?): void {
    this.dialogRef.close(payload);
  }
}
