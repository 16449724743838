import { AfterViewInit, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseField } from '../fields/base-field';

@Component({
  selector: 'app-df-field',
  templateUrl: './dynamic-form-field.component.html',
})
export class DynamicFormFieldComponent implements AfterViewInit {
  @Input() field: BaseField<any>;
  @Input() form: FormGroup;
  get isValid() {
    return this.form.controls[this.field.key].valid;
  }

  ngAfterViewInit() {
    if (this.form && this.field && this.field.key && this.form.controls[this.field.key]) {
      if (this.field && this.field.disabled) {
        setTimeout(() => {
          this.form.controls[this.field.key].disable();
        });
      }
    }
  }
}
