<form *ngIf="form" [formGroup]="form" novalidate class="col-md-5">
  <mat-form-field class="full-width pb-3">
    <mat-select placeholder="Action Type" formControlName="type">
      <mat-option *ngFor="let type of actionTypes" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
    <mat-hint align="start">{{ typesDefinition[type].description }}</mat-hint>
  </mat-form-field>

  <div [ngSwitch]="type">
    <app-edit-supplier *ngSwitchCase="TYPES.EDIT_SUPPLIER" [form]="form"></app-edit-supplier>
    <app-create-task *ngSwitchCase="TYPES.CREATE_TASK" [form]="form"></app-create-task>
    <app-send-email *ngSwitchCase="TYPES.SEND_EMAIL" [form]="form" [loaded$]="action$"></app-send-email>
    <app-send-to-event-bridge *ngSwitchCase="TYPES.SEND_TO_EVENT_BRIDGE" [form]="form"></app-send-to-event-bridge>
  </div>
</form>

<div class="col-md-6">
  <!--<pre>-->
  <!--{{form.value|json }}-->
  <!--Valid: {{form.valid }}-->
  <!--Errors: {{form.errors|json }}-->
  <!--</pre>-->
</div>
