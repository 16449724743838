import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, pipe, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { FileInputModel } from '../input-file/input-file.model';
import { FileCompliance, PybDocument } from './pyb-documents.interface';

@Injectable()
export class PybDocumentsService {
  private apiUrl: string;
  private internalApiUrl: string;
  private errorBlobPipe = catchError(this.showErrorBlob.bind(this));

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient, private toastrService: ToastrService) {
    this.apiUrl = appConfig.env.pybDocumentsApiUrl;
    this.internalApiUrl = appConfig.env.internalApiUrl;
  }

  uploadFile(fileInput: FileInputModel[]): Observable<PybDocument[]> {
    const endpointUrl = `${this.apiUrl}documents`;
    const formData = new FormData();

    formData.append('files', fileInput[0].files[0]);

    return this.httpClient.post<PybDocument[]>(endpointUrl, formData);
  }

  uploadFileProxy(fileInput: FileInputModel[], supplierKey: string, compliance: FileCompliance): Observable<PybDocument[]> {
    const endpointUrl = `${this.internalApiUrl}suppliers/${supplierKey}/documents`;
    const formData = new FormData();

    formData.append('files', fileInput[0].files[0]);

    Object.keys(compliance).map((key) => {
      formData.append(key, compliance[key]);
    });

    return this.httpClient.post<PybDocument[]>(endpointUrl, formData);
  }

  downloadFile(fileId: string, withBlobError = false): Observable<Blob> {
    const endpointUrl = `${this.apiUrl}documents/${fileId}`;

    return this.httpClient.get(endpointUrl, { responseType: 'blob' }).pipe(pipe.apply(this, withBlobError ? [this.errorBlobPipe] : []));
  }

  downloadThumbnail(fileId: string, withBlobError = false): Observable<Blob> {
    const endpointUrl = `${this.apiUrl}thumbnails/${fileId}`;

    return this.httpClient.get(endpointUrl, { responseType: 'blob' }).pipe(pipe.apply(this, withBlobError ? [this.errorBlobPipe] : []));
  }

  deleteFile(fileId: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}documents/${fileId}`;

    return this.httpClient.delete(endpointUrl).pipe(
      catchError((error) => {
        this.toastrService.error(error.error.detail);
        return observableThrowError(error);
      }),
    );
  }

  showErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable(() => {
      reader.onloadend = (): void => {
        this.toastrService.error(JSON.parse(reader.result.toString()).detail);
      };
    });
    reader.readAsText(err.error);
    return obs;
  }
}
