import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest as observableCombineLatest, ReplaySubject, Subject } from 'rxjs';
import { filter, map, share, switchMap, tap } from 'rxjs/operators';

import { Filter } from '../event-rule.models';
import { EventsService } from '../events.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { DAYS, MINUTES, WEEKS } from './action-form/send-email/send-email.component';

@Component({
  selector: 'app-rules-add-edit',
  templateUrl: './rules-add-edit.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RulesAddEditComponent extends SubscriberComponent implements AfterViewInit, OnInit {
  @ViewChild('selector', { static: true }) selector;
  @ViewChild('actions', { static: true }) actions;
  sendButton$ = new Subject();

  selectorForm = new FormGroup({});
  actionsForm = new FormArray([]);
  isEditing: boolean;
  private uuid;
  public loadEventRule$: ReplaySubject<any> = new ReplaySubject();

  constructor(private eventsService: EventsService, private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.isEditing = false;
    this.loadEventRule$ = new ReplaySubject();
    const load$ = this.route.paramMap.pipe(
      filter((params) => params.has('eventRuleId')),
      tap((params) => {
        this.isEditing = true;
        this.uuid = params.get('eventRuleId');
      }),
      switchMap((params) => this.eventsService.get(this.uuid)),
      map((resp) => resp.data),
      map((rule) => {
        rule.filters = rule.filters || [];
        rule.filters = rule.filters.map((f) => Filter.fromJson(f).toExpression()) as any;
        for (const action of rule.actions) {
          if (action.delay > 0) {
            action.delayEnabled = true;
          }
          if (action.delay % WEEKS === 0) {
            action.delayPeriod = WEEKS;
            action.delayTime = action.delay / WEEKS;
          } else if (action.delay % DAYS === 0) {
            action.delayPeriod = DAYS;
            action.delayTime = action.delay / DAYS;
          } else {
            action.delayPeriod = MINUTES;
            action.delayTime = action.delay / MINUTES;
          }
        }
        return rule;
      }),
      share(),
    );
    this.subscriptions.push(load$.subscribe(this.loadEventRule$));
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      observableCombineLatest(this.selector.selectorValue$, this.actions.value$, this.sendButton$)
        .pipe(
          switchMap(([selector, actions]) => {
            const eventRule = Object.assign({ actions: [] }, selector);
            eventRule.actions = actions as any[];

            if (this.isEditing) {
              return this.eventsService.save(this.uuid, eventRule);
            }
            return this.eventsService.create(eventRule);
          }),
        )
        .subscribe(() => {
          this.router.navigate(['manage/event-rules']);
        }),
    );
  }
}
