<div class="actions-list-pinned" *ngIf="currentNote">
  <app-note-pinned-item (unpinNote)="onUnpinNote()" [item]="currentNote"></app-note-pinned-item>
  <mat-paginator
    *ngIf="noteCount > 1"
    class="actions-list-pinned__paginator"
    [length]="noteCount"
    [pageSize]="1"
    (page)="onPageChange($event)"
  ></mat-paginator>
  <div class="actions-list-pinned__loading" *ngIf="loading">
    <app-loading-spinner [size]="2"></app-loading-spinner>
  </div>
</div>
