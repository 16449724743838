import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { MarketplaceLoan } from '../model/marketplace-loan.model';

@Injectable()
export class MarketplaceLoansService {
  private readonly apiUrlForFetchAndEdit: string;
  private readonly apiUrlForCreateAndDelete: string;

  private readonly creationEndpointFieldNames: Record<keyof MarketplaceLoan, string> = {
    annualInterest: 'annual_interest',
    daysLeftToPayment: 'days_left_to_payment',
    lastPaymentAmount: 'last_payment_amount',
    lastPaymentDate: 'last_payment_date',
    loanAmount: 'loan_amount',
    loanExternalId: 'loan_external_id',
    loanExtraReserveAmount: 'loan_extra_reserve_amount',
    loanType: 'loan_type',
    marketplaceLoanKey: 'marketplace_loan_key',
    maturityDate: 'maturity_date',
    nextPaymentAmount: 'next_payment_amount',
    nextPaymentDate: 'next_payment_date',
    originationDate: 'origination_date',
    paymentPeriodDays: 'payment_period_days',
    paymentStatus: 'payment_status',
    mpSupKey: 'mp_sup_key',
    baseLoanProvisionReserved: 'base_loan_provision_reserved',
    pastDueLoanProvisionReserved: 'past_due_loan_provision_reserved',
    pastDueAmount: 'past_due_amount',
    pastDuePaymentPeriodDates: 'past_due_payment_period_dates',
    daysLeftToPastDuePayment: 'days_left_to_past_due_payment',
    nextSettlementDate: 'next_settlement_date',
    updatedTs: 'updated_ts',
  };

  constructor(private readonly appConfig: AppConfigService, private readonly httpClient: HttpClient) {
    this.apiUrlForFetchAndEdit = this.appConfig.env.internalApiUrl + 'marketplace_loan';
    this.apiUrlForCreateAndDelete = this.appConfig.env.internalApiUrl + 'marketplace_loans';
  }

  fetchMarketplaceLoan(loanKey: string, refresh = false): Observable<MarketplaceLoan> {
    const endpoint = `${this.apiUrlForFetchAndEdit}/${loanKey}`;
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient.get<MarketplaceLoan>(endpoint, { params: queryParams }).pipe(
      map((data) => {
        data.lastPaymentDate = moment(data.lastPaymentDate);
        data.nextPaymentDate = moment(data.nextPaymentDate);
        data.originationDate = moment(data.originationDate);
        data.maturityDate = moment(data.maturityDate);
        data.nextSettlementDate = moment(data.nextSettlementDate);
        data.updatedTs = moment(data.updatedTs);
        return data;
      }),
    );
  }

  editMarketplaceLoan(loanKey: string, data: MarketplaceLoan): Observable<any> {
    return this.httpClient.put(`${this.apiUrlForFetchAndEdit}/${loanKey}`, data);
  }

  createMarketplaceLoan(data: MarketplaceLoan): Observable<any> {
    return this.httpClient.post(`${this.apiUrlForCreateAndDelete}`, this.mapPayloadForCreateRequest(data));
  }

  deleteMarketplaceLoan(loanKey: string): Observable<any> {
    return this.httpClient.delete(`${this.apiUrlForCreateAndDelete}/${loanKey}`);
  }

  private mapPayloadForCreateRequest(loan: MarketplaceLoan): Record<string, string> {
    const entries = Object.entries(loan);
    const entriesWithDatesConverted = entries.map(this.convertDatesForCreateRequest.bind(this));
    const entriesWithFieldNamesConverted: [string, any][] = entriesWithDatesConverted.map(([key, value]) => [
      this.creationEndpointFieldNames[key],
      value,
    ]);
    return entriesWithFieldNamesConverted.reduce((sofar, [key, value]) => ({ ...sofar, [key]: value }), {});
  }

  private convertDatesForCreateRequest([key, value]: [string, string]): [string, string] {
    if (!key.endsWith('Date')) {
      return [key, value];
    }

    const convertedDate = moment(value).format('MM-DD-YYYY');
    return [key, convertedDate];
  }
}
