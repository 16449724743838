import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { GENERAL_ERROR_MESSAGE } from '../../../../../shared/consts/general-error-message';
import { trackByIndex } from '../../../../../shared/utils/functions/track-by-index.util';
import { RefreshAuthDataModel } from '../../../../model/refresh-auth-data.model';
import { SupplierPlaidService } from '../supplier-payment-plaid-information/supplier-plaid.service';

@Component({
  selector: 'app-bank-accounts-dialog',
  templateUrl: './bank-accounts-dialog.component.html',
  styleUrls: ['./bank-accounts-dialog.component.scss'],
})
export class BankAccountsDialogComponent extends SubscriberComponent {
  dataLoading = true;
  supplierKey: string;
  itemId: string;
  errorMessage: string;
  authData: RefreshAuthDataModel[];
  readonly trackBy = trackByIndex;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BankAccountsDialogComponent>,
    private supplierPlaidService: SupplierPlaidService,
    private toastrService: ToastrService,
  ) {
    super();
    this.setInitialData();
    this.getData();
  }

  setInitialData(): void {
    this.errorMessage = undefined;
    this.supplierKey = this.data.supplierKey;
    this.itemId = this.data.itemId;
  }

  getData(): void {
    if (!this.supplierKey || !this.itemId) {
      this.showError(GENERAL_ERROR_MESSAGE);
      return;
    }
    this.subscriptions.push(
      this.supplierPlaidService
        .refreshAuth(this.supplierKey, this.itemId)
        .pipe(finalize(() => this.stopLoading()))
        .subscribe(
          (data: RefreshAuthDataModel[]) => {
            this.authData = data;
          },
          (err) => this.refreshAuthError(err.error.message),
        ),
    );
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  showError(message: string): void {
    this.stopLoading();
    this.toastrService.error(message, null, { timeOut: 3000 });
    setTimeout(() => {
      this.closeDialog();
    });
  }

  refreshAuthError(message: string): void {
    this.errorMessage = message;
  }

  stopLoading(): void {
    this.dataLoading = false;
  }
}
