export function contextMenuItemsGetter(params) {
  const contextMenuItems = [
    {
      name: 'Export',
      subMenu: [
        {
          name: 'CSV Export (.csv)',
          action: () => params.api.exportDataAsCsv(params),
        },
        {
          name: 'Excel Export (.xlsx)',
          action: () => params.api.exportDataAsExcel(params),
        },
        {
          name: 'Excel Export (.xml)',
          action: () => params.api.exportDataAsExcel(params),
        },
      ],
    },
    {
      name: 'Copy',
      subMenu: [
        {
          name: 'Cell',
          action: () => navigator.clipboard.writeText(JSON.stringify(params.value)),
        },
        {
          name: 'Row',
          action: () => navigator.clipboard.writeText(JSON.stringify(params.node.data)),
        },
      ],
    },
  ];
  return contextMenuItems;
}
