import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../../shared/component-subscriber/subscriber.component';
import { AppState } from '../../../../../store/app.reducers';
import { SupplierService } from '../../../../services/supplier.service';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-payment-prompts',
  templateUrl: './supplier-payment-prompts.component.html',
  styleUrls: ['./supplier-payment-prompts.component.scss'],
})
export class SupplierPaymentPromptsComponent extends SubscriberComponent implements OnInit {
  private supplierKey: string;
  public advanceUnderwriting: boolean;
  public collectionAmount: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly supplierService: SupplierService,
    private readonly toastr: ToastrService,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;

          this.supplierService.getSupplierNotifications(this.supplierKey).subscribe((notifications) => {
            if (!notifications) {
              return;
            }
            this.advanceUnderwriting = notifications.hasOwnProperty('advance_underwriting');
            this.collectionAmount = notifications.hasOwnProperty('collection_account');
          });
        }),
    );
  }

  public changeCollectionPrompt(): void {
    this.setNotification(this.collectionAmount, 'collection_account');
  }

  public changeAdvanceUnderwritingPrompt(): void {
    this.setNotification(this.advanceUnderwriting, 'advance_underwriting');
  }

  private setNotification(value: boolean, notificationType: string): any {
    if (value) {
      return this.supplierService.activateSupplierNotification(this.supplierKey, notificationType).subscribe(
        () => {},
        (err) => {
          this.toastr.error('Something went wrong');
        },
      );
    }
    this.supplierService.deactivateSupplierNotification(this.supplierKey, notificationType).subscribe(
      () => {},
      (err) => {
        this.toastr.error('Something went wrong');
      },
    );
  }
}
