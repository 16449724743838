import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { AppState } from '../../../store/app.reducers';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SupplierMarqetaService } from '../supplier-marqeta-account/supplier-marqeta.service';
import { TransactionModalComponent } from '../transaction-modal/transaction-modal.component';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { TransactionDetails } from './transaction-details.models';

@Component({
  selector: 'app-card-transactions-details',
  templateUrl: './card-transactions-details.component.html',
  styleUrls: ['./card-transactions-details.component.scss'],
})
export class CardTransactionsDetailsComponent extends SubscriberComponent implements OnInit {
  public transactionDetails: TransactionDetails;

  private supplierKey: string;
  private marqetaKey: string;

  constructor(
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
    private readonly supplierMarqetaService: SupplierMarqetaService,
    private readonly toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
          mergeMap(() => this.route.params),
          filter((params) => params && params.hasOwnProperty('marqetaKey')),
          tap((params) => (this.marqetaKey = params['marqetaKey'])),
        )
        .subscribe(() => this.setTransaction()),
    );
  }

  setTransaction() {
    if (this.supplierKey && this.marqetaKey) {
      this.subscriptions.push(
        this.supplierMarqetaService.getMarqetaTransaction(this.supplierKey, this.marqetaKey).subscribe(
          (transaction: TransactionDetails) => {
            this.transactionDetails = transaction;
          },
          (err) => {
            this.toastrService.error(err && err.error && err.error.message ? err.error.message : 'Something went wrong');
          },
        ),
      );
    }
  }

  openModal(index) {
    const data = { data: this.transactionDetails.logs[index], payload: this.transactionDetails.logs[index].payload };
    const previewDialog = this.dialog.open(TransactionModalComponent, {
      ...DIALOG_SIZE.MEDIUM3,
      data,
    });

    this.subscriptions.push(previewDialog.afterClosed().subscribe(() => {}));
  }
}
