import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { ProductMatrixInitializeTablesService } from '../service/product-matrix-initialize-tables.service';
import { IProducts } from '../service/product-matrix-interfaces';

@Component({
  selector: 'app-product-instant-advance',
  templateUrl: './product-instant-advance.component.html',
  styleUrls: ['./product-instant-advance.component.scss'],
})
export class ProductInstantAdvanceComponent implements OnInit {
  @Input() supplierKey: string;
  @Input() response: IProducts;

  public dataSource: MatTableDataSource<any>;
  public serviceColumns = [
    'advanceStatus',
    'originationDate',
    'weeksSinceStart',
    'maxPaybackTerm',
    'paybackAmount',
    'amountCollected',
    'outstandingBalance',
    'feeReserve',
    'marketplace',
  ];
  public productStatus: string;
  public data: any[];
  public mpHasPayAdvance: string[];
  public productStatusColor: string;

  constructor() {}

  ngOnInit() {
    this.initTable(this.response);
  }

  private initTable(response: IProducts): void {
    this.data = [];
    this.setUpDataFromResponse(response.instant_advance);
    this.dataSource = new MatTableDataSource(this.data);
  }

  private setUpDataFromResponse(product: any): void {
    const spData = product.supplier_data;
    this.productStatus = ProductMatrixInitializeTablesService.setProductStatus(product.onboarding_outcome.onboarding_result);
    this.productStatusColor = ProductMatrixInitializeTablesService.setProductStatusColor(this.productStatus);
    this.marketplaceHasPayAdvance(product);
    this.setUpDataSource(spData);
  }

  private setUpDataSource(spData: any) {
    if (spData.status === '-') {
      return this.data;
    }
    if (!spData.advance) {
      return this.data;
    }
    this.data.push({
      advanceStatus: spData.status,
      originationDate: spData.advance.origination_date ? spData.advance.origination_date : '-',
      weeksSinceStart: spData.advance.weeks_since_start ? `${spData.advance.weeks_since_start} weeks` : '-',
      maxPaybackTerm: spData.advance.maximum_payback_term ? spData.advance.maximum_payback_term : '-',
      paybackAmount: spData.advance.advance_total_amount
        ? ProductMatrixInitializeTablesService.transformIntoCurrency(spData.advance.advance_total_amount)
        : '-',
      amountCollected: spData.advance.amount_collected
        ? ProductMatrixInitializeTablesService.transformIntoCurrency(spData.advance.amount_collected)
        : '-',
      outstandingBalance: spData.advance.outstanding_balance
        ? ProductMatrixInitializeTablesService.transformIntoCurrency(spData.advance.outstanding_balance)
        : '-',
      feeReserve: spData.advance.fee_reserve ? ProductMatrixInitializeTablesService.transformIntoCurrency(spData.advance.fee_reserve) : '-',
      marketplace: this.mpHasPayAdvance.length > 0 ? this.mpHasPayAdvance.join(', ') : '-',
    });
  }

  private marketplaceHasPayAdvance(product: any): void {
    this.mpHasPayAdvance = [];
    for (const key in product.mp_data) {
      if (product.mp_data.hasOwnProperty(key)) {
        const mp = product.mp_data[key];
        if (mp.pay_advance) {
          this.mpHasPayAdvance.push(mp.marketplace_name);
        }
      }
    }
  }
}
