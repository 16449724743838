import { autoserialize, autoserializeAs } from 'cerialize';

export class PaymentConfigDataModel {
  @autoserialize bank: any;
  @autoserialize beneficiary?: any;
  @autoserialize intermediary?: any;
  @autoserialize ppm?: any;
  @autoserialize pcard?: any;
  @autoserialize efs?: any;

  constructor(bank: any, beneficiary: any, intermediary: any, ppm: any, efs: any) {
    this.bank = bank;
    this.beneficiary = beneficiary;
    this.intermediary = intermediary;
    this.ppm = ppm;
    this.efs = efs;
  }
}

export class PaymentConfigModel {
  @autoserialize configStatus: string;
  @autoserializeAs(PaymentConfigDataModel) paymentConfig: PaymentConfigDataModel;
  @autoserialize paymentType: string;
  @autoserialize paymentConfigKey: string;
  @autoserialize isDefault: boolean;
  @autoserialize enableForDebit: boolean;
  @autoserialize isPlaid: boolean;

  constructor(
    configStatus: string,
    paymentConfig: PaymentConfigDataModel,
    paymentType: string,
    paymentConfigKey: string,
    isDefault: boolean,
    enableForDebit: boolean,
    isPlaid: boolean,
  ) {
    this.configStatus = configStatus;
    this.paymentConfig = paymentConfig;
    this.paymentType = paymentType;
    this.paymentConfigKey = paymentConfigKey;
    this.isDefault = isDefault;
    this.enableForDebit = enableForDebit;
    this.isPlaid = isPlaid;
  }
}
