<div [formGroup]="frontendEmailConfigForm">
  <mat-form-field class="full-width">
    <input matInput placeholder="Template name" formControlName="template_name" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Description" formControlName="description" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Subject" formControlName="subject" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="From address" formControlName="source" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="To addresses" formControlName="to" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>
</div>
