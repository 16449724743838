import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { isUndefined } from 'util';

import { CardFormgroupMessageService } from './card-formgroup-message.service';

@Component({
  // eslint-disable-next-line
  selector: '[app-card-formgroup]',
  templateUrl: './card-formgroup.component.html',
  styleUrls: ['./card-formgroup.component.scss'],
})
export class CardFormgroupComponent implements OnInit, OnDestroy {
  saveResultSubscription: Subscription;

  @Input() title;
  @Input() formGroup;
  @Input() singleField = false;
  @Input() inEdit = false;
  @Input() showClear = true;

  processing = false;

  @Input() isGroupEditable = true;
  @Input() contextMenuItems = [];

  @Output() onSave = new EventEmitter();
  @Output() onReset = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onUpdateInitial = new EventEmitter();
  @Output() onEdit = new EventEmitter();

  model: any;

  constructor(private messageService: CardFormgroupMessageService, private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.saveResultSubscription = this.messageService.subscribe(this.formGroup, (msgEvent) => {
      if (msgEvent.actionType === 'saveError') {
        this.processing = false;

        if (msgEvent.payload) {
          if (!isUndefined(msgEvent.payload.message)) {
            this.toastrService.error(msgEvent.payload.message);
          } else if (msgEvent.payload instanceof Array) {
            msgEvent.payload.map((messageItem: any) => {
              if (!isUndefined(messageItem.message)) {
                this.toastrService.error(messageItem.message);
              }
            });
          }
        } else {
          this.toastrService.error('There was an error while trying to update');
        }
      }

      if (msgEvent.actionType === 'saveSuccess') {
        this.updateInitialValues();
        this.inEdit = false;

        this.processing = false;
        this.toastrService.success('Updated successfully');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.saveResultSubscription && !this.saveResultSubscription.closed) {
      this.saveResultSubscription.unsubscribe();
    }
  }

  disableEditMode(): void {
    this.processing = false;
    if (!this.singleField) {
      this.inEdit = false;
    }
  }

  toggleEdit(inEditValue: boolean = null) {
    if (!this.singleField) {
      if (inEditValue === null) {
        this.inEdit = !this.inEdit;
      } else {
        this.inEdit = inEditValue;
      }

      if (this.inEdit) {
        this.messageService.broadcast(this.formGroup, 'groupInEdit');
      } else {
        this.onCancel.emit();
        this.messageService.broadcast(this.formGroup, 'cancel');
      }
    }
    this.onEdit.emit(this.inEdit);
  }

  save(): void {
    this.messageService.broadcast(this.formGroup, 'update');
    this.onSave.emit();

    this.messageService.broadcast(this.formGroup, 'saveGroup');
    this.processing = true;
  }

  cancelEditing() {
    this.onCancel.emit();

    this.messageService.broadcast(this.formGroup, 'cancel');
    if (!this.singleField) {
      this.inEdit = false;
    }
    this.onEdit.emit(this.inEdit);
  }

  clearValues() {
    this.messageService.broadcast(this.formGroup, 'clear');
    this.onReset.emit(this.formGroup);
  }

  updateInitialValues() {
    this.onUpdateInitial.emit();
    this.messageService.broadcast(this.formGroup, 'updateInitial');
  }
}
