import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MarketplaceListComponent } from './marketplace-list/marketplace-list.component';
import { GatewayService } from './service/gateway.service';
import { MarketplaceRepository } from './service/marketplace-repository';
import { MarketplacesAbstractService } from './service/marketplaces-abstract.service';

@NgModule({
  imports: [SharedModule],
  declarations: [MarketplaceComponent, MarketplaceListComponent],
  providers: [{ provide: MarketplacesAbstractService, useClass: MarketplaceRepository }, GatewayService],
})
export class MarketplacesModule {}
