<div class="row">
  <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-xl-6 col-lg-5 col-12'">
    <div class="card">
      <div class="card-content">
        <div class="top-box">
          <div class="icon-box">
            <div class="ibox-icon">
              <a><i class="material-icons">call_missed_outgoing</i></a>
            </div>
            <div class="title">
              <span>Outgoing payment accounts</span>
              <button [routerLink]="['../new']" class="pull-right" mat-raised-button [color]="'accent'">
                <mat-icon>add</mat-icon>
                Add new account
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="paymentConfigList$ | async; else loading">
          <mat-list *ngIf="(paymentConfigList$ | async)?.length; else noOutgoingAccounts">
            <mat-list-item *ngFor="let paymentConfig of paymentConfigList$ | async">
              <h2 matLine [routerLink]="['../edit/', paymentConfig.paymentConfigKey]">
                <span>{{ paymentConfig.paymentType }}</span>
              </h2>
              <button mat-icon-button [routerLink]="['../edit/', paymentConfig.paymentConfigKey]">
                <mat-icon>edit</mat-icon>
              </button>
              <pre matLine>{{ paymentConfig.paymentConfigKey }}</pre>
              <div matLine class="d-inline">
                <span class="badge badge-pill badge-secondary" *ngIf="paymentConfig?.isDefault && !paymentConfig.paymentConfig.pcard"
                  >Default</span
                >
                <span
                  class="badge badge-pill"
                  *ngIf="!paymentConfig.paymentConfig.pcard"
                  [ngClass]="paymentConfigStatuses[paymentConfig.configStatus]"
                >
                  {{ paymentConfig.configStatus }}
                </span>
                <span
                  *ngIf="paymentConfig.paymentConfig.pcard && paymentConfig.paymentConfig?.pcard.pcard_status"
                  class="badge badge-pill badge-secondary"
                >
                  {{ paymentConfig.paymentConfig?.pcard.pcard_status.toLowerCase() }}
                </span>
                <span *ngIf="paymentConfig.paymentConfig.pcard" class="badge badge-pill badge-secondary">
                  {{ paymentConfig.paymentConfig?.pcard.pcard_type_indicator.toLowerCase() }}
                </span>
                <span *ngIf="paymentConfig.enableForDebit" class="badge badge-pill badge-primary"> Enable for Debit </span>
                <span *ngIf="paymentConfig.isPlaid" class="badge badge-pill badge-secondary"> Plaid </span>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div class="card-action">
        <button mat-raised-button color="primary" (click)="chooseCardType()">Request Seller Card</button>
        <button mat-raised-button color="primary" (click)="openModalUpdateMarqetaData()">Reset Marqeta Account</button>
      </div>
    </div>
    <div class="card">
      <div class="card-content ppm-card">
        <app-loading-spinner [size]="2" *ngIf="isSpinnerVisible"></app-loading-spinner>

        <mat-slide-toggle
          color="accent"
          [(ngModel)]="ppmAvailable"
          (change)="saveSingleField('ppmAvailable', $event.checked, formGroups['DEBIT_CARD_PUSH_FORM'])"
        >
          Allow Debit Card Push Payments for this supplier
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-xl-12 col-lg-12' : 'col-xl-6 col-lg-7 col-12'">
    <div class="card">
      <div class="card-content">
        <div class="top-box">
          <div class="icon-box">
            <div class="ibox-icon ibox-success">
              <a><i class="material-icons">trending_down</i></a>
            </div>
            <div class="title">
              <span>Incoming payment accounts</span>
              <button
                class="pull-right"
                mat-raised-button
                [color]="'accent'"
                (click)="assignDepositAccount()"
                *ngIf="!supplierDetails?.depositAccountKey"
              >
                <mat-icon>add</mat-icon>
                Add new account
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="supplierDetails; else loading">
          <mat-list *ngIf="supplierDetails?.depositAccountKey; else noIncomingAccounts">
            <mat-list-item>
              <h2 matLine [routerLink]="['../deposit-account-edit', supplierDetails?.depositAccountKey]">
                <span>Deposit Account</span>
              </h2>
              <button mat-icon-button [routerLink]="['../deposit-account-edit', supplierDetails?.depositAccountKey]">
                <mat-icon>edit</mat-icon>
              </button>
              <pre matLine>{{ supplierDetails?.depositAccountKey }}</pre>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div class="card-action" *ngIf="supplierDetails?.depositAccountKey">
          <button (click)="deleteDepositAccount()" mat-raised-button color="warn">Remove account</button>
          <button class="ml-4" (click)="generateBankStatement()" mat-raised-button color="primary">Generate Bank Statement</button>
          <button 
          class="ml-4" 
          mat-raised-button 
          [color]="depositAccount?.autoPostingBlocked ? 'accent' : 'warn'"
          (click)="changeAutomaticPaymentsStatus()" 
          > 
            <mat-icon>block</mat-icon> {{ depositAccount?.autoPostingBlocked ? 'Unblock' : 'Block' }} Automatic Deposits 
        </button>
      </div>
    </div>
    <app-supplier-payment-prompts></app-supplier-payment-prompts>
    <app-supplier-payment-plaid-information></app-supplier-payment-plaid-information>
    <app-supplier-payment-manual-link></app-supplier-payment-manual-link>
  </div>
</div>

<ng-template #loading>
  <app-loading-spinner [size]="4"></app-loading-spinner>
</ng-template>

<ng-template #noOutgoingAccounts>
  <h6>No outgoing accounts!</h6>
</ng-template>

<ng-template #noIncomingAccounts>
  <h6>No incoming accounts!</h6>
</ng-template>
