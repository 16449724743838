import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import Logger from '../../../shared/logger';
import { LookupAbstractService } from '../../../shared/lookup-service/lookup-abstract.service';
import { FetchUtilAttribute, selectCountries } from '../../../shared/utils/store/utils.actions';
import { AppState } from '../../../store/app.reducers';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-choose-card-type',
  templateUrl: './choose-card-type.component.html',
  styleUrls: ['./choose-card-type.component.scss'],
})
export class ChooseCardTypeComponent extends SubscriberComponent implements OnInit {
  private readonly VIRTUAL_CARD_TYPE = 'VIRTUAL';
  private readonly PHYSICAL_CARD_TYPE = 'PHYSICAL';
  private isReplacementMode = false;
  public cardTypeForm: FormGroup;
  public shippingAddressForm: FormGroup;
  public countries: Observable<any[]>;
  public states: any[] = [];
  public isPhysical = false;
  public isMarqetaActionMode = false;
  public actionReason = new FormControl(null, Validators.required);
  public expediteCard = false;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private lookupRepo: LookupAbstractService,
    public dialogRef: MatDialogRef<ChooseCardTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supplierService: SupplierService,
  ) {
    super();
    this.store.dispatch(new FetchUtilAttribute({ attr: 'countries' }));
  }

  ngOnInit() {
    this.countries = this.store.select(selectCountries);
    this.countries.pipe(share());
    this.prepareCardTypeFormGroup();
    this.prepareShippingAddressFormGroup();
    this.subscribeToCountryChange();
    this.subscribeToSupplierDetails();
    this.checkIfIsReplacementMode();
    this.checkDialogData();
  }

  public chooseCardType({ value }) {
    if (value.type === this.PHYSICAL_CARD_TYPE) {
      this.isPhysical = true;
    } else if (value.type === this.VIRTUAL_CARD_TYPE) {
      this.dialogRef.close({
        type: value.type,
      });
    }
  }

  public requestNewCard({ value }) {
    const cardConfigObject = this.prepareCardConfigObject(value);
    this.dialogRef.close(cardConfigObject);
  }

  private prepareCardTypeFormGroup() {
    this.cardTypeForm = this.formBuilder.group({
      type: [this.VIRTUAL_CARD_TYPE, Validators.required],
    });
  }

  private prepareShippingAddressFormGroup() {
    this.shippingAddressForm = this.formBuilder.group({
      address1: ['', Validators.required],
      address2: '',
      city: ['', Validators.required],
      zip: ['', Validators.required],
      country: ['', Validators.required],
      state: '',
    });
  }

  private subscribeToCountryChange() {
    this.subscriptions.push(
      this.shippingAddressForm.get('country').valueChanges.subscribe((value) => {
        if (value && value.length) {
          this.getCountryStates(value);
        }
      }),
    );
  }

  private getCountryStates(value: any) {
    this.subscriptions.push(
      this.lookupRepo.getStates(value).subscribe((data) => {
        const stateControl = this.shippingAddressForm.get('state');
        this.states = data;
        stateControl.setValidators(data && data.length ? [Validators.required] : []);
        stateControl.updateValueAndValidity();
      }),
    );
  }

  private subscribeToSupplierDetails() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierModel) => {
        const supplierKey = supplierModel && supplierModel.supplierKey ? supplierModel.supplierKey : null;
        if (!isNullOrUndefined(supplierKey)) {
          this.getSupplierMainOwner(supplierKey);
        }
      }),
    );
  }

  private patchShippingForm(supplierAddress: any) {
    if (!isNullOrUndefined(supplierAddress)) {
      const shippingAddress = {
        address1: supplierAddress.address,
        address2: supplierAddress.address2,
        city: supplierAddress.city,
        zip: supplierAddress.zipPostalCode,
        country: supplierAddress.country,
        state: supplierAddress.stateCode,
      };
      this.shippingAddressForm.patchValue(shippingAddress);
    }
  }

  private checkIfIsReplacementMode() {
    if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.replacementMode)) {
      this.isPhysical = this.isReplacementMode = this.data.replacementMode;
    }
  }

  private prepareCardConfigObject(shippingAddress) {
    const cardConfigObject = {
      address: shippingAddress,
      expediteCard: this.expediteCard,
    };

    if (!this.isReplacementMode) {
      Object.assign(cardConfigObject, { type: this.PHYSICAL_CARD_TYPE });
    }

    if (this.isMarqetaActionMode) {
      Object.assign(cardConfigObject, { reason: this.actionReason.value });
    }

    return cardConfigObject;
  }

  private checkDialogData() {
    if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.transitionReasons)) {
      this.isMarqetaActionMode = true;
    }
  }

  private getSupplierMainOwner(supplierKey: string) {
    this.subscriptions.push(
      this.supplierService.getSupplierLogins(supplierKey).subscribe(
        (response: any) => this.getSupplierMainOwnerSuccess(response),
        (error) => Logger.log(error),
      ),
    );
  }

  private getSupplierMainOwnerSuccess(response: any[]) {
    if (!isNullOrUndefined(response)) {
      const mainOwnerObject = response.filter((value) => value.mainOwner === true)[0];
      this.patchShippingForm(mainOwnerObject);
    }
  }
}
