<div class="header box box-default batches-header">
  <div class="navigation">
    <a [routerLink]="['/processes/supplier-payments/batches']" title="Go to supplier list" class="close-button">
      <i class="material-icons">arrow_back</i>
    </a>
  </div>
  <div class="info">
    <h6>{{ title }}</h6>
    <span>{{ description }}</span>
  </div>
  <div class="status">
    <div class="single-status r-br" *ngIf="showTaskStatus">
      <h6>{{ task.status }}</h6>
      <span>Status</span>
    </div>
    <div class="single-status" *ngIf="showLastUpdateStatus">
      <h6>{{ lastUpdatedPayments | date: "MM/dd/yyyy 'at' h:mm a" }}</h6>
      <span>Last Updated</span>
    </div>
    <div class="single-status l-br" *ngIf="showLastAddedPaymentsStatus">
      <h6>{{ lastAddedPayments | date: "MM/dd/yyyy 'at' h:mm a" }}</h6>
      <span>Last Added Payment</span>
    </div>
  </div>
  <div class="actions">
    <div *ngIf="isDebit" class="debit-buttons">
      <button *ngIf="!isSubmitted" mat-raised-button [color]="'primary'" (click)="addPaymentsOnClick()">Add Payments</button>
      <button *ngIf="isAutoDebit && isSubmitted" mat-raised-button [color]="'accent'" (click)="onUnlock()">Unlock</button>
      <button mat-raised-button [color]="'primary'" (click)="approveOrSubmitPaymentOnClick()">{{ submitBtnText }}</button>
    </div>
    <div *ngIf="!isDebit" class="credit-buttons">
      <button mat-raised-button *ngIf="showRefresh" [color]="'accent'" (click)="refreshViewOnClick()">Refresh View</button>
      <button mat-raised-button *ngIf="showAction" [color]="'primary'" (click)="addPaymentsOnClick()">Add Payments</button>
      <button mat-raised-button *ngIf="showAction" [color]="'primary'" (click)="updatePaymentOnClick()">Update</button>
      <button mat-raised-button *ngIf="showAction" [color]="'primary'" (click)="approveOrSubmitPaymentOnClick()">
        {{ showApproveOrSubbmitLabel }}
      </button>
      <button mat-raised-button *ngIf="showAction" [color]="'accent'" (click)="editBatchOnClick()">Edit</button>
    </div>
  </div>
</div>
