<div class="row">
  <div class="border-bottom col-lg-12">
    <h2 mat-dialog-title>Summary</h2>
  </div>
  <div class="col-lg-2">ID</div>
  <div class="col-lg-10">
    {{ data?.data?.id }}
  </div>
  <div class="col-lg-2">Time</div>
  <div class="col-lg-10">
    {{ data?.data?.create_ts }}
  </div>
  <div class="col-lg-2">Method</div>
  <div class="col-lg-10">
    {{ data?.data?.method }}
  </div>
  <div class="col-lg-2">URL</div>
  <div class="col-lg-10">
    {{ data?.data?.url }}
  </div>
  <div class="col-lg-2">Status</div>
  <div class="col-lg-10">
    {{ data?.data?.status }}
  </div>
  <div class="col-lg-2">IP address</div>
  <div class="col-lg-10">
    {{ data?.data?.ip_address }}
  </div>
  <div class="col-lg-12 border-bottom"></div>
  <div class="col-lg-12">
    <h2 mat-dialog-title>Request</h2>
  </div>
  <div class="col-lg-12">
    <pre>{{ data?.payload | json }}</pre>
  </div>
</div>
<div>
  <button class="pull-right" mat-raised-button (click)="close()" color="warn">Close</button>
</div>
