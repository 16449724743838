<div *ngIf="form" [formGroup]="form">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Advanced </mat-panel-title>
    </mat-expansion-panel-header>

    <div formArrayName="dataMapping">
      <h5 class="mb-0">Item data mapping</h5>
      <small> Supplier property will be updated with data from event pointed by <b>selector</b>. </small>
      <div *ngFor="let control of dataMapping.controls; let i = index" [formGroupName]="i" class="row">
        <mat-form-field class="col-md-6">
          <input
            matInput
            placeholder="Supplier property"
            formControlName="propName"
            (keyup)="util.tryAddNext(dataMapping, i, util.contextModel())"
          />
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Selector" formControlName="selector" />
          <button matSuffix mat-icon-button (click)="dataMapping.removeAt(i)" *ngIf="dataMapping.length > 1">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div formArrayName="data">
      <h5 class="mb-0">Item data</h5>
      <small> Supplier property will be updated with excat <b>value</b>. </small>
      <div *ngFor="let control of data.controls; let i = index" [formGroupName]="i" class="row">
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Supplier property" formControlName="propName" (keyup)="util.tryAddNext(data, i, util.dataModel())" />
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="Value" formControlName="value" />
          <button matSuffix class="col-md-1" mat-icon-button (click)="data.removeAt(i)" *ngIf="data.length > 1">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</div>
