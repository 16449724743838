import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { FeatureFlagsGeneralGuard } from './../guards/feature-flags-general.guard';
import { USER_PERMISSION } from './../shared/users/user-permissions.enum';
import { RouteAccessResolver } from './../shared/utils/route-access-resolver';
import { AdminBaseComponent } from './base/base.component';
import { AdminFeatureFlagsComponent } from './feature-flags/feature-flags.component';
import { AdminQaComponent } from './qa/qa.component';

export const routes: Routes = [
  {
    path: 'admin',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: USER_PERMISSION.DEV,
        redirectTo: '/',
      },
    },
    children: [
      {
        path: '',
        component: AdminBaseComponent,
        children: [
          {
            path: 'feature-flags',
            component: AdminFeatureFlagsComponent,
          },
          {
            path: 'qa',
            component: AdminQaComponent,
          },
          {
            path: '**',
            redirectTo: '/admin/feature-flags',
          },
        ],
      },
    ],
  },
];
