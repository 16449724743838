import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';

import { AppConfigService } from '../config';
import { ConfigsService } from '../shared/configs/service/configs.service';
import { SharedModule } from '../shared/shared.module';
import { ActionsAddComponent } from './actions-add/actions-add.component';
import { ActionTabAllComponent } from './actions-list/action-tabs/action-tab-all/action-tab-all.component';
import { ActionTabBatchEmailsComponent } from './actions-list/action-tabs/action-tab-emails/action-tab-batch-emails.component';
import { ActionTabEmailsComponent } from './actions-list/action-tabs/action-tab-emails/action-tab-emails.component';
import { ActionTabEventsComponent } from './actions-list/action-tabs/action-tab-events/action-tab-events.component';
import { ActionTabNotesComponent } from './actions-list/action-tabs/action-tab-notes/action-tab-notes.component';
import { ActionTabTasksComponent } from './actions-list/action-tabs/action-tab-tasks/action-tab-tasks.component';
import { ActionsListComponent } from './actions-list/actions-list.component';
import { ActionsListPinnedComponent } from './actions-list-pinned/actions-list-pinned.component';
import { CommentAddComponent } from './comments/comment-add/comment-add.component';
import { CommentItemComponent } from './comments/comment-item/comment-item.component';
import { CommentListComponent } from './comments/comment-list/comment-list.component';
import { CrmSidebarComponent } from './crm-sidebar/crm-sidebar.component';
import { EmailConfirmDialogComponent } from './emails/email-confirm-dialog/email-confirm-dialog.component';
import { EmailItemComponent } from './emails/email-item/email-item.component';
import { EmailExpandRowComponent } from './emails/email-list/email-expand-row/email-expand-row.component';
import { EmailGridFiltersComponent } from './emails/email-list/email-grid-filters/email-grid-filters.component';
import { EmailListComponent } from './emails/email-list/email-list.component';
import { EmailStatusBadgeComponent } from './emails/email-list/email-status-badge/email-status-badge.component';
import { EmailNewBatchComponent } from './emails/email-new-batch/email-new-batch.component';
import { EmailPreviewComponent } from './emails/email-preview/email-preview.component';
import { EmailSendComponent } from './emails/email-send/email-send.component';
import { TimelineItemComponent } from './emails/timeline-item/timeline-item.component';
import { EventItemComponent } from './events/event-item/event-item.component';
import { EventTaskItemComponent } from './events/event-task-item/event-task-item.component';
import { FileItemComponent } from './files/file-item/file-item.component';
import { FileUploadComponent } from './files/file-upload/file-upload.component';
import { NoteAddEditComponent } from './notes/note-add-edit/note-add-edit.component';
import { NoteItemComponent } from './notes/note-item/note-item.component';
import { NotePaymentItemComponent } from './notes/note-payment-item/note-payment-item.component';
import { NotePinnedItemComponent } from './notes/note-pinned-item/note-pinned-item.component';
import { CommentsService } from './services/comments.service';
import { CrmService } from './services/crm.service';
import { EmailsService } from './services/emails.service';
import { EmailsStatusService } from './services/emails-status.service';
import { FileTypesService } from './services/file-types.service';
import { TasksService } from './services/tasks.service';
import { TasksFiltersService } from './services/tasks-filters.service';
import { B2bPreviewDialogComponent } from './shared/b2b-preview/b2b-preview.component';
import { UserFilterPipe } from './shared/user-filter.pipe';
import { TaskAddEditComponent } from './tasks/task-add-edit/task-add-edit.component';
import { TaskItemComponent } from './tasks/task-item/task-item.component';
import { AssigneePickerComponent } from './tasks/task-list/assignee-picker/assignee-picker.component';
import { TaskGridFiltersComponent } from './tasks/task-list/task-grid-filters/task-grid-filters.component';
import { TaskGridFiltersSetsComponent } from './tasks/task-list/task-grid-filters-sets/task-grid-filters-sets.component';
import { TaskListComponent } from './tasks/task-list/task-list.component';
import { TaskListTransitionComponent } from './tasks/task-list/task-list/transition/task-list-transition.component';
import { TaskListActionsComponent } from './tasks/task-list/task-list-actions/task-list-actions.component';
import { TaskStatusBadgeComponent } from './tasks/task-list/task-status-badge/task-status-badge.component';
import { TaskNextStatusDirective } from './tasks/task-next-status.directive';
import { TasksComponent } from './tasks/tasks.component';
import { ActionTabCalculatorComponent } from './actions-list/action-tabs/action-tab-calculator/action-tab-calculator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AccessCalculatorService } from './services/action-tab-calculator.service';

const declared = [
  ActionsAddComponent,
  ActionsListComponent,
  ActionsListPinnedComponent,
  CommentAddComponent,
  CommentItemComponent,
  CrmSidebarComponent,
  EmailItemComponent,
  EventItemComponent,
  EventTaskItemComponent,
  FileItemComponent,
  NoteAddEditComponent,
  NoteItemComponent,
  NotePaymentItemComponent,
  TaskItemComponent,
  ActionTabNotesComponent,
  ActionTabAllComponent,
  ActionTabTasksComponent,
  ActionTabEventsComponent,
  ActionTabEmailsComponent,
  ActionTabBatchEmailsComponent,
  ActionTabCalculatorComponent,
  FileUploadComponent,
  TasksComponent,
  TaskListComponent,
  TaskListTransitionComponent,
  TaskListActionsComponent,
  TaskGridFiltersSetsComponent,
  TaskStatusBadgeComponent,
  TaskAddEditComponent,
  EmailSendComponent,
  EmailListComponent,
  EmailPreviewComponent,
  EmailGridFiltersComponent,
  EmailStatusBadgeComponent,
  EmailExpandRowComponent,
  EmailConfirmDialogComponent,
  EmailNewBatchComponent,
  TimelineItemComponent,
  UserFilterPipe,
  TaskNextStatusDirective,
  CommentListComponent,
  TaskGridFiltersComponent,
  AssigneePickerComponent,
  NotePinnedItemComponent,
];

@NgModule({
  imports: [CommonModule, SharedModule, MatMenuModule, ReactiveFormsModule],
  declarations: [...declared],
  exports: [...declared],
  providers: [
    { provide: CrmService, useClass: CrmService },
    { provide: TasksService, useClass: TasksService, deps: [AppConfigService, HttpClient, Store] },
    { provide: TasksFiltersService, useClass: TasksFiltersService, deps: [AppConfigService, HttpClient, Store] },
    { provide: EmailsService, useClass: EmailsService, deps: [AppConfigService, HttpClient, Store] },
    { provide: EmailsStatusService, useClass: EmailsStatusService },
    { provide: CommentsService, useClass: CommentsService, deps: [AppConfigService, HttpClient, Store] },
    { provide: ConfigsService, useClass: ConfigsService },
    { provide: FileTypesService, useClass: FileTypesService, deps: [ConfigsService] },
    AccessCalculatorService
  ],
  entryComponents: [
    NoteAddEditComponent,
    TaskAddEditComponent,
    EmailExpandRowComponent,
    EmailConfirmDialogComponent,
    ActionTabCalculatorComponent,
    EmailPreviewComponent,
    EmailSendComponent,
    FileUploadComponent,
    EmailNewBatchComponent,
    B2bPreviewDialogComponent,
  ],
})
export class CrmToolsModule { }
