<ng-template #loadingCard>
  <div class="card-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
<ng-template #loadingFrom>
  <div class="form-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
<ng-template #noInvitationEmail>
  <div class="card-title marketplace-access clearfix">No Invitation Email</div>
</ng-template>
<ng-template #noMarketplace>
  <div class="card-title marketplace-access clearfix">No Amazon Marketplace</div>
</ng-template>

<div class="col-xl-12 col-lg-12">
  <div class="card">
    <div class="card-content" *ngIf="amazonMarketplacePermissions && amazonMarketplaceAccess; else loadingCard">
      <div *ngIf="complianceStatuses?.amazonAccess[0]?.status && amazonMarketplacePermissions?.status; else noMarketplace">
        <div class="top-box">
          <form
            *ngIf="formGroups[MARKETPLACE_ACCESS_FORM]; else loadingFrom; let marketplaceAccessForm"
            app-card-formgroup
            [isGroupEditable]="false"
            novalidate
            #formComplianceMarketplaceAccess
            [formGroup]="marketplaceAccessForm"
            (onSave)="saveActionStatuses(MARKETPLACE_ACCESS_FORM)"
          >
            <div class="title" [hidden]="formComplianceMarketplaceAccess.inEdit">
              Marketplace Access
              <span
                [hidden]="formComplianceMarketplaceAccess.inEdit"
                class="badge"
                [ngClass]="{
                  'badge-warning': complianceStatuses?.amazonAccess[0]?.status === UwStatus.INCOMPLETE,
                  'badge-info': complianceStatuses?.amazonAccess[0]?.status === UwStatus.PENDING,
                  'badge-success': complianceStatuses?.amazonAccess[0]?.status === UwStatus.VERIFIED,
                  'badge-danger': complianceStatuses?.amazonAccess[0]?.status === UwStatus.REJECTED
                }"
                >{{ complianceStatuses?.amazonAccess[0]?.status | capitalize }}</span
              >
            </div>

            <div [hidden]="!formComplianceMarketplaceAccess.inEdit">
              <app-inline-edit-select
                *ngIf="formComplianceMarketplaceAccess.inEdit"
                class="col-md-12"
                [formControlName]="'status'"
                [formGroup]="marketplaceAccessForm"
                [label]="'Marketplace Access Status'"
                [inGroupEdit]="formComplianceMarketplaceAccess.inEdit"
                [items]="availableStatusesForSelect"
                [disableInline]="true"
              >
              </app-inline-edit-select>
            </div>
          </form>
        </div>

        <div
          class="supplier-login clearfix"
          *ngIf="
            amazonMarketplaceDetails && (amazonMarketplaceAccess.invite_email || amazonMarketplaceDetails.externalLogin);
            else noInvitationEmail
          "
        >
          <div>
            <a class="value"
              >{{ amazonMarketplaceAccess.invite_email || amazonMarketplaceDetails.externalLogin | ellipsis: 40 }}
              <mat-icon
                ngxClipboard
                [cbContent]="amazonMarketplaceAccess.invite_email || amazonMarketplaceDetails.externalLogin"
                class="btn-copy"
                matTooltip="Click to copy"
                >content_copy</mat-icon
              >
            </a>
          </div>
          <div class="clearfix">
            <button
              [disabled]="resendInvitationBusy"
              mat-raised-button
              color="primary"
              [class.btn-busy]="resendInvitationBusy"
              (click)="onResendInvitation($event)"
            >
              Resend invitation
            </button>
          </div>
        </div>
        <div class="marketplace-permissions" *ngIf="amazonMarketplacePermissions; else loadingCard">
          <div class="top-box">
            <form
              *ngIf="formGroups[MARKETPLACE_PERMISSION_FORM]; else loadingFrom; let marketplacePermissionForm"
              app-card-formgroup
              [isGroupEditable]="false"
              novalidate
              #formComplianceMarketplacePermission
              [formGroup]="marketplacePermissionForm"
              (onSave)="saveActionStatuses(MARKETPLACE_PERMISSION_FORM)"
            >
              <div class="title" [hidden]="formComplianceMarketplacePermission.inEdit">
                Marketplace permissions
                <span
                  [hidden]="formComplianceMarketplacePermission.inEdit"
                  class="badge"
                  [ngClass]="{
                    'badge-warning': complianceStatuses?.amazonPermissions[0]?.status === UwStatus.INCOMPLETE,
                    'badge-info': complianceStatuses?.amazonPermissions[0]?.status === UwStatus.PENDING,
                    'badge-success': complianceStatuses?.amazonPermissions[0]?.status === UwStatus.VERIFIED,
                    'badge-danger': complianceStatuses?.amazonPermissions[0]?.status === UwStatus.REJECTED
                  }"
                  >{{ complianceStatuses?.amazonPermissions[0]?.status | capitalize }}</span
                >
              </div>
              <div [hidden]="!formComplianceMarketplacePermission.inEdit">
                <app-inline-edit-select
                  *ngIf="formComplianceMarketplacePermission.inEdit"
                  class="col-md-12"
                  [formControlName]="'status'"
                  [formGroup]="marketplacePermissionForm"
                  [label]="'Marketplace Permission Status'"
                  [inGroupEdit]="formComplianceMarketplacePermission.inEdit"
                  [items]="availableStatusesForSelect"
                  [disableInline]="true"
                >
                </app-inline-edit-select>
              </div>
            </form>
          </div>

          <button [disabled]="reloadPermissionsBusy" (click)="reloadPermissions()" mat-raised-button color="primary">
            Recheck <mat-icon [ngClass]="{ 'fa-spin': reloadPermissionsBusy }" class="material-icons">autorenew</mat-icon>
          </button>

          <div class="divider divider-md"></div>

          <div class="table-wrapper ng-scope">
            <table class="table table-condensed table-hover">
              <tbody>
                <tr *ngFor="let permissionType of permissionsTypes | async">
                  <td>{{ permissionType }} <span *ngIf="permissionType === 'Amazon Lending'">(if applicable)</span></td>
                  <td>
                    <span *ngIf="amazonMarketplacePermissions?.status === UwStatus.PENDING">
                      PENDING <span class="badge badge-pill badge-warning">!</span>
                    </span>
                    <span
                      *ngIf="
                        amazonMarketplacePermissions?.status === UwStatus.REJECTED || amazonMarketplacePermissions?.status === 'approved'
                      "
                    >
                      <span *ngIf="amazonMarketplacePermissions?.satisfied_by?.permissions?.results?.length">
                        <span *ngIf="amazonMarketplacePermissions?.satisfied_by?.permissions?.results[permissionType] === 'OK'">
                          <i class="material-icons">done</i>
                        </span>
                        <span *ngIf="amazonMarketplacePermissions?.satisfied_by?.permissions?.results[permissionType] !== 'OK'">
                          NOT VERIFIED <span class="badge badge-pill badge-danger">!</span>
                        </span>
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="amazonMarketplacePermissions && amazonMarketplaceAccess; else loadingCard">
            <button mat-raised-button class="btn-w-md btn-w-md remove-access-btn" (click)="confirmRemoveMarketplaceAccess()" color="warn">
              Remove marketplace access
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
