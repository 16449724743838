import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class ReceivableService {
  isBlocked = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  blockButton() {
    this.isBlocked = true;
    this.change.emit(this.isBlocked);
  }
}
