import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PybMaterialModule } from './../../pyb-material/pyb-material.module';
import { LoadingSpinnerComponent } from './loading-spinner.component';

@NgModule({
  imports: [CommonModule, PybMaterialModule],
  declarations: [LoadingSpinnerComponent],
  exports: [LoadingSpinnerComponent],
})
export class LoadingSpinnerModule {}
