import { Injectable } from '@angular/core';

export enum messageCodes {
  installExtension = 'INSTALL_EXTENSION',
  cantReceiveToken = 'CANT_RECEIVE_SUPPLIER_TOKEN',
  inTestEnvironmentsCanOnlyTo = 'YOU_CAN_ONLY_LOG_IN_TO_SUPPLIER_SUPPLIER4',
}

@Injectable()
export class MessageService {
  private messages = {
    [messageCodes.installExtension]: 'Payability Extension not Installed. Please install Extension and refresh page!',
    [messageCodes.cantReceiveToken]: "Can't receive supplier token!",
    [messageCodes.inTestEnvironmentsCanOnlyTo]: 'You can only log in to oldSD (supplier) or newSD (supplier4).',
  };

  constructor() {}

  public getMessageByCode(code: string): string {
    return this.messages[code] ? this.messages[code] : '';
  }
}
