import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';

import { EditableReportFieldModel } from '../model/report-model';
import { ReportsAbstractService } from '../model/reports-abstract.service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { EditableReportModel } from './../model/report-model';

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.scss'],
})
export class ReportEditComponent extends SubscriberComponent implements OnInit {
  // Fields
  public editReportForm: FormGroup;

  public dbObjectsTable: string[];
  public fieldsNamesTable: string[];
  public fieldsTable: any[];
  public enabledForSupplierDictionary: { label: string; id: string }[];
  public sortingOrderDictionary: { label: string; id: string }[];

  private reportName: string;
  private reportTitle: string;
  private configuration: any;

  // Flags
  private isConfigurationLoaded = false;

  // Accessors
  public get title(): string {
    return !this.isConfigurationLoaded ? 'Loading...' : `${this.reportTitle}`;
  }

  public get fields(): FormArray {
    return this.editReportForm ? (this.editReportForm.get('fields') as FormArray) : this.formBuilder.array([]);
  }

  constructor(
    private service: ReportsAbstractService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  // Methods
  public ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.reportName = params['reportKey'];
      }),
    );

    this.getData();
    this.setupComponent();
  }

  public getPlaceholder(index: number): string {
    let result = '';

    if (this.fields) {
      const group = this.fields.at(index) as FormGroup;
      if (group) {
        const control = group.get('fieldName');
        if (control) {
          result = control.value;
        }
      }
    }

    return result;
  }

  public onDelete(): void {
    this.subscriptions.push(
      this.service.removeReport(this.configuration.reportKey).subscribe(
        () => {
          this.toastrService.success('Report config has been successfully deleted.');
          this.router.navigate(['../../list'], { relativeTo: this.route });
        },
        () => {
          this.toastrService.error('Deleting report config failed.');
        },
      ),
    );
  }

  public onSubmit(): void {
    if (this.editReportForm.valid) {
      this.subscriptions.push(
        forkJoin([
          this.updateReportConfiguration(this.editReportForm.value),
          this.updateFields(this.editReportForm.value.fields),
        ]).subscribe(
          () => {
            this.toastrService.success('Report config has been successfully updated.');
            this.router.navigate(['../../list'], { relativeTo: this.route });
          },
          () => {
            this.toastrService.error('Saving report config failed.');
          },
        ),
      );
    }
  }

  public onCancel(): void {
    this.router.navigate(['../../list'], { relativeTo: this.route });
  }

  private getData(): void {
    this.subscriptions.push(
      forkJoin([this.service.getViewsAndTables(), this.service.getReportConfiguration(this.reportName)]).subscribe((data) => {
        this.dbObjectsTable = data[0];
        this.configuration = data[1];

        this.reportTitle = this.configuration.title;
        this.fieldsTable = this.configuration.fields;

        if (this.configuration.fields) {
          this.fieldsNamesTable = this.configuration.fields.map((field) => field.name);
        }

        this.isConfigurationLoaded = true;

        if (this.configuration && this.configuration.reportKey) {
          this.subscriptions.push(
            this.service.getReport(this.configuration.reportKey).subscribe((report) => {
              // update list with databse objects
              this.dbObjectsTable.push(report.reportName);

              // update form values
              this.editReportForm.patchValue({
                reportName: report.reportName,
                reportLabel: report.reportLabel,
                enabledForSupplier: report.enabledForSupplier ? report.enabledForSupplier : 'no',
                googleSheetsUrl: report.googleSheetsUrl,
                workflowId: report.workflowId,
                enabledForReports: report.enabledForReports,
              });

              if (report.sortBy) {
                this.editReportForm.patchValue({
                  sortingByField: report.sortBy.charAt(0) === '-' ? report.sortBy.substring(1) : report.sortBy,
                  sortingOrder: report.sortBy.charAt(0) === '-' ? '-' : '+',
                });
              }

              if (report.overrides) {
                report.overrides.forEach((record) => {
                  let shouldAddNewDefinition = false;
                  let fieldDefinition;

                  // search for existing field definition
                  const items = this.fieldsTable.filter((item) => {
                    return item.name === record.fieldName;
                  });

                  if (!items || items.length === 0) {
                    fieldDefinition = {};
                    shouldAddNewDefinition = true;
                  } else {
                    fieldDefinition = items[0];
                  }

                  // ovveride field definition data
                  fieldDefinition.filterable = record.fieldFilterable;
                  fieldDefinition.show = record.fieldShow;
                  fieldDefinition.name = record.fieldName;
                  fieldDefinition.overrideKey = record.overrideKey;

                  if (record.fieldLabel) {
                    fieldDefinition.label = record.fieldLabel;
                  }
                  if (record.fieldType) {
                    fieldDefinition.type = record.fieldType;
                  }

                  // add new definition
                  if (shouldAddNewDefinition) {
                    this.fieldsTable.push(fieldDefinition);
                  }
                });
              }

              const fieldsGroups = this.fieldsTable.map((field) =>
                this.formBuilder.group({
                  fieldName: [field.name],
                  fieldShow: [field.show],
                  fieldLabel: [field.label],
                  fieldType: [field.type],
                  fieldFilterable: [field.filterable],
                  overrideKey: [field.overrideKey],
                }),
              );
              const fieldsArray = this.formBuilder.array(fieldsGroups);
              this.editReportForm.setControl('fields', fieldsArray);
            }),
          );
        }
      }),
    );
  }

  private updateFields(fields: any[]): Observable<any> {
    return forkJoin(
      fields.map((field) => {
        const init = {
          fieldName: field.fieldName,
          fieldLabel: field.fieldLabel || null,
          fieldType: field.fieldType || null,
          fieldFilterable: field.fieldFilterable,
          fieldShow: field.fieldShow,
        };

        const data = new EditableReportFieldModel();
        Object.assign(data, init);

        return field.overrideKey
          ? this.service.updateReportField(this.configuration.reportKey, field.overrideKey, data)
          : this.service.addReportField(this.configuration.reportKey, data);
      }),
    );
  }

  private updateReportConfiguration(data: any): Observable<any> {
    const init = {
      reportLabel: data.reportLabel,
      reportName: data.reportName,
      enabledForReports: data.enabledForReports,
      enabledForSupplier: data.enabledForSupplier !== 'no' ? data.enabledForSupplier : null,
      workflowId: data.workflowId,
      googleSheetsUrl: data.googleSheetsUrl,
      sortBy: data.sortingByField ? (data.sortingOrder === '-' ? data.sortingOrder : '') + data.sortingByField : null,
    };

    const report = new EditableReportModel();
    Object.assign(report, init);

    return this.service.updateReport(this.configuration.reportKey, report);
  }

  private setupComponent(): void {
    this.enabledForSupplierDictionary = [
      { label: 'No', id: 'no' },
      { label: 'Yes', id: 'all' },
      { label: 'Only for Instant Access suppliers', id: 'instant-access' },
      { label: 'Only for Non-Instant Access suppliers', id: 'non-instant-access' },
    ];

    this.sortingOrderDictionary = [
      { label: 'Ascending Order', id: '+' },
      { label: 'Descending Order', id: '-' },
    ];

    this.editReportForm = this.formBuilder.group({
      reportName: [{ value: '', disabled: false }, Validators.required],
      reportLabel: [{ value: '', disabled: false }, Validators.required],
      enabledForSupplier: [{ value: '', disabled: false }, Validators.required],
      googleSheetsUrl: [{ value: '', disabled: false }],
      workflowId: [{ value: '', disabled: false }],
      enabledForReports: [false],
      sortingByField: [''],
      sortingOrder: [''],
      fields: this.formBuilder.array([]),
    });
  }
}
