import { Injectable } from '@angular/core';

import { ErrorInterface } from './error-interface';
import { StandardErrorMessages } from './standard-error-messages';

@Injectable()
export class ErrorParserService {
  constructor() {}

  parse(errorData: ErrorInterface): string {
    if ('' === errorData.name && this.isEmpty(errorData.errorObject)) {
      return StandardErrorMessages.UNKNOWN_ERROR;
    }

    let errorMessage = StandardErrorMessages.ERROR_PREFIX + errorData.name + StandardErrorMessages.SECTION_SEPARATOR;

    if ('string' === typeof errorData.errorObject) {
      errorMessage += errorData.errorObject as string;
      return errorMessage;
    }

    if (this.hasStructureWithMessagesArray(errorData.errorObject)) {
      return (errorMessage += this.extractMessages(errorData.errorObject));
    }

    if (errorData.errorObject.hasOwnProperty('error')) {
      if (errorData.errorObject.error.hasOwnProperty('message')) {
        return errorData.errorObject.error.message;
      }
    }

    if (errorData.errorObject.hasOwnProperty('message')) {
      const errorObject: { message?: string } = errorData.errorObject;
      errorMessage += errorObject.message;
      return errorMessage;
    }

    errorMessage += StandardErrorMessages.ERROR_NOT_RECOGNIZED;
    return errorMessage;
  }

  private isEmpty(errorObject: any): boolean {
    if (!errorObject) {
      return true;
    }

    if ('{}' === JSON.stringify(errorObject)) {
      return true;
    }

    if ('[]' === JSON.stringify(errorObject)) {
      return true;
    }

    return false;
  }

  private extractMessages(errorObject: any): string {
    return errorObject.error.meta.errors.reduce((messages, meta) => messages + meta.message + StandardErrorMessages.SEPARATOR, '');
  }

  private hasStructureWithMessagesArray(errorObject: any): boolean {
    if (this.hasErrorKey(errorObject)) {
      if (this.hasMetaKey(errorObject.error)) {
        if (this.hasErrorsArray(errorObject.error.meta)) {
          return true;
        }
      }
    }
    return false;
  }

  private hasErrorKey(errorObject: any): boolean {
    if (!errorObject.hasOwnProperty('error')) {
      return false;
    }
    return true;
  }

  private hasErrorsArray(metaObject: any): boolean {
    if (!metaObject.hasOwnProperty('errors')) {
      return false;
    }

    if (!Array.isArray(metaObject.errors)) {
      return false;
    }
    return true;
  }

  private hasMetaKey(errorErrorObject: any): boolean {
    if (!errorErrorObject.hasOwnProperty('meta')) {
      return false;
    }

    return true;
  }
}
