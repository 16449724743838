import { Component, EventEmitter, Output } from '@angular/core';

import { NoteItemComponent } from '../note-item/note-item.component';

@Component({
  selector: 'app-note-pinned-item',
  templateUrl: './note-pinned-item.component.html',
  styleUrls: ['../../shared/timeline-item.scss', './note-pinned-item.component.scss'],
})
export class NotePinnedItemComponent extends NoteItemComponent {
  @Output() unpinNote = new EventEmitter();

  onUnpinNote() {
    this.unpinNote.emit();
  }
}
