import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SupplierAdvancesService } from '../service/supplier-advances.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { IAdvanceLineData } from './models/advance-line-data.interface';
import { getNoRebate } from './models/get-no-rebate.function';

@Component({
  selector: 'app-active-advance-line',
  templateUrl: './active-advance-line.component.html',
  styleUrls: ['./active-advance-line.component.scss'],
})
export class ActiveAdvanceLineComponent extends SubscriberComponent implements OnInit {
  activeAdvanceLineForm: FormGroup;
  activeAdvanceLineData: IAdvanceLineData;
  isActive = false;
  @Output() isPending = new EventEmitter<boolean>();
  isRepaid = false;
  private supplierKey: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly supplierAdvancesService: SupplierAdvancesService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
          mergeMap((data) => this.supplierAdvancesService.getActiveAdvanceLine(data.supplierKey)),
        )
        .subscribe((activeAdvanceLineData: IAdvanceLineData) => {
          if (!activeAdvanceLineData) {
            return;
          }
          this.activeAdvanceLineData = activeAdvanceLineData;
          this.patchValue(activeAdvanceLineData);
          this.setAdvanceStatuses();
        }),
    );
  }

  redirectToDetails() {
    this.router.navigate([`./advance/${this.activeAdvanceLineData.advance_line_key}`], { relativeTo: this.route });
  }

  private createForm() {
    this.activeAdvanceLineForm = this.formBuilder.group({
      limitAmount: '',
      originationDate: '',
      noRebate: '',
      returnStrategy: '',
      forceFull: '',
    });
  }

  private patchValue(activeAdvanceLineData: IAdvanceLineData) {
    this.activeAdvanceLineForm.patchValue({
      limitAmount: activeAdvanceLineData.limit_amount,
      originationDate: activeAdvanceLineData.create_ts,
      noRebate: getNoRebate(activeAdvanceLineData.advance_templates),
      returnStrategy: activeAdvanceLineData.return_strategy,
      forceFull: activeAdvanceLineData.force_full,
    });
  }

  private setAdvanceStatuses() {
    this.isActive = this.activeAdvanceLineData.status === 'Active';
    this.isRepaid = this.activeAdvanceLineData.status === 'Repaid';
    this.isPending.emit(this.activeAdvanceLineData.status === 'Offer Pending');
  }
}
