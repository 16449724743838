import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-currency-cell',
  template: '{{ params.value | currency }}',
})
export class PybAgCellCurrencyComponent implements ICellRendererAngularComp {
  params: any;
  refresh(params: any): boolean {
    if (params.value !== this.params.value) {
      this.params = params;
    }
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  agInit(params: any): void {
    this.params = params;
  }
}
