import { Pipe, PipeTransform } from '@angular/core';

import { PermissionModel } from '../../shared/users/permission.model';
import { UserModel } from '../../shared/users/user.model';

@Pipe({
  name: 'filter',
})
export class UserFilterPipe implements PipeTransform {
  transform(items: (UserModel | PermissionModel)[], searchText: string): (UserModel | PermissionModel)[] {
    if (!items) {
      return [];
    }
    if (!searchText || typeof searchText !== 'string') {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return it.name.toLowerCase().includes(searchText);
    });
  }
}
