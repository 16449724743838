import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { FeatureFlagsGeneralGuard } from '../guards/feature-flags-general.guard';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';
import { RouteAccessResolver } from '../shared/utils/route-access-resolver';
import { TaskListComponent } from './tasks/task-list/task-list.component';
import { TasksComponent } from './tasks/tasks.component';

export const routes: Routes = [
  {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
      },
    },
    children: [
      { path: '', redirectTo: 'list/0/filter', pathMatch: 'full' },
      { path: 'list', redirectTo: 'list/0/filter', pathMatch: 'full' },
      { path: 'list/:filterId/filter', component: TaskListComponent },
    ],
  },
];
