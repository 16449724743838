/* eslint-disable */
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { SupplierAdvancesService } from '../service/supplier-advances.service';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { CurrencyCellRenderer } from '../../../../shared/functions/currency-cell-renderer';
import { NumberCellRenderer } from '../../../../shared/functions/number-cell-renderer';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-underwritings',
  templateUrl: './underwritings.component.html',
  styleUrls: ['./underwritings.component.scss'],
})
export class UnderwritingsComponent extends SubscriberComponent implements OnInit {
  @Input() supplierKey: string;
  @Input() advanceLineKey: string;
  @Input() set needToLoadData(status: boolean) {
    this.dataNeedToBeLoaded = status;
    if (status) {
      this.getData();
    }
  }
  dataNeedToBeLoaded = false;

  public rows: any;
  public columns: ColDef[];
  dataReceived = false;
  noDataFromTheApi = false;
  loading = false;

  constructor(private supplierAdvancesService: SupplierAdvancesService) {
    super();
  }

  ngOnInit() {
    this.setInitialData();
    this.getData();
  }

  setInitialData() {
    this.columns = [
      {
        field: 'advanceLineUwHistoryKey',
        headerName: 'Underwriting Line History Key',
      },
      {
        field: 'lineFee',
        headerName: 'Fee',
        cellRenderer: CurrencyCellRenderer,
        width: 70,
      },
      {
        field: 'lineLimit',
        headerName: 'Limit',
        cellRenderer: CurrencyCellRenderer,
        width: 70,
      },
      {
        field: 'uwStatus',
        headerName: 'Underwriting Status',
      },
      {
        field: 'riskRating',
        headerName: 'Underwriting Risk',
      },
      {
        field: 'lineMaxReserveRep',
        headerName: 'Max Reserve Amount',
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: 'Grace Period Length (Days)',
        cellRenderer: (cellData) => {
          return cellData!.data!.template!.grace_period_length;
        },
      },
      {
        headerName: 'No Rebate',
        cellRenderer: (cellData) => {
          return cellData!.data!.template!.no_rebate;
        },
      },
      {
        field: 'expectedDuration',
        headerName: 'Expected Duration',
        cellRenderer: NumberCellRenderer,
      },
      {
        field: 'expectedMonthlyEarnings',
        headerName: 'Expected Monthly Earnings',
        cellRenderer: CurrencyCellRenderer,
      },
    ];
  }

  getData() {
    if (this.dataReceived) {
      return;
    }
    this.getUnderwritings();
  }

  getUnderwritings() {
    if (!this.dataNeedToBeLoaded || !this.advanceLineKey || !this.supplierKey) {
      return;
    }
    this.noDataFromTheApi = false;
    this.loading = true;
    this.subscriptions.push(
      this.supplierAdvancesService
        .getAdvanceLineUwHistory(this.supplierKey, this.advanceLineKey)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          (data) => {
            this.onDataLoading(data);
          },
          () => {
            this.noDataFromTheApi = true;
          },
        ),
    );
  }

  onDataLoading(data) {
    this.noDataFromTheApi = false;
    this.dataReceived = true;
    this.rows = data;
  }
}
