<ng-template #noMarketplace>
  <div class="card-title marketplace-access clearfix">No Amazon Marketplace</div>
</ng-template>

<mat-card class="onboardingCompliances__card">
  <mat-card-content>
    <div *ngIf="dataList && dataList.length > 0; else noMarketplace">
      <div *ngFor="let data of dataList; let i = index">
        <h5>{{ data.mpLabel }}</h5>

        <mat-table class="onboardingCompliances__card__table" [dataSource]="data.compliances">
          <ng-container matColumnDef="complianceLabel">
            <mat-header-cell *matHeaderCellDef>Compliance Label</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.complianceLabel }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-select
                *ngIf="element.changeable; else standardLabel"
                [(ngModel)]="element.status"
                (selectionChange)="updateComplianceStatus(data, element, $event)"
                class="status status-{{ element.status }}"
              >
                <mat-option *ngFor="let status of accessStatuses" [value]="status">{{ status | capitalize }}</mat-option>
              </mat-select>
              <ng-template #standardLabel>
                <span class="status status-{{ element.status }}">{{ element.status | capitalize }}</span>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="requiredForFunding">
            <mat-header-cell *matHeaderCellDef>Required for Funding</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.requiredForFunding.toString() | capitalize }}</mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="marketplaceDetailsColumns"
            class="onboardingCompliances__card__table__headerRow"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: marketplaceDetailsColumns" class="onboardingCompliances__card__table__row"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
