import { Observable } from 'rxjs';

import { RepositoryInterface } from '../../shared/utils/repository-interface';
import { MarketplaceListModel } from '../model/marketplace-list-model';
import { MarketplaceModel } from '../model/marketplace-model';

export abstract class MarketplacesAbstractService implements RepositoryInterface {
  abstract getItems(): Observable<MarketplaceListModel[]>;
  abstract getItem(uuid: string): Observable<MarketplaceModel>;
  abstract saveItem(uuid: string, item: MarketplaceModel): Observable<MarketplaceModel>;
  abstract addItem(item: MarketplaceModel): Observable<MarketplaceModel>;
  abstract getPeriodGroupCodes(): Observable<any[]>;
  abstract getDefaultFreeTrialTypeCodes(): Observable<any[]>;
}
