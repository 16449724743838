import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { FeatureFlagsGeneralGuard } from '../guards/feature-flags-general.guard';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';
import { RouteAccessResolver } from '../shared/utils/route-access-resolver';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MarketplaceListComponent } from './marketplace-list/marketplace-list.component';

export const routes: Routes = [
  {
    path: 'marketplaces',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
      },
    },
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: MarketplaceListComponent },
      { path: 'add', component: MarketplaceComponent },
      { path: ':marketplaceKey/edit', component: MarketplaceComponent },
    ],
  },
];
