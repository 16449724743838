import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { LoadEarningsComponent } from '../load-earnings/load-earnings.component';
import { LoadRemittanceComponent } from '../load-remittance/load-remittance.component';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-actions-import-menu',
  templateUrl: './actions-import-menu.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ActionsImportMenuComponent extends SubscriberComponent implements OnInit {
  shouldShowRemitance = false;

  constructor(public dialog: MatDialog, private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetailsModel: SupplierDetailsModel) => {
        if (!(supplierDetailsModel === undefined)) {
          const isAmazonVendor = <boolean>supplierDetailsModel.isAmazonVendor;
          const isTarget = <boolean>supplierDetailsModel.isTarget;
          this.shouldShowRemitance = isAmazonVendor || isTarget;
        }
      }),
    );
  }

  loadEarnings() {
    const dialogRef = this.dialog.open(LoadEarningsComponent, {
      width: DIALOG_SIZE.RWD_50_AUTO_HEIGHT.width,
    });
  }

  loadRemittance() {
    const dialogRef = this.dialog.open(LoadRemittanceComponent, {
      width: DIALOG_SIZE.RWD_50_AUTO_HEIGHT.width,
    });
  }
}
