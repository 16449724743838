<h1 mat-dialog-title>Create failed payment transaction</h1>
<div mat-dialog-content>
  <div class="content" *ngIf="loaded; else loading">
    <span *ngIf="showErrorScreen" class="info-box">No payments to reverse.</span>
    <div *ngIf="!showErrorScreen">
      <span *ngIf="showConfirmationScreen" class="info-box">Are you sure you want to proceed?</span>

      <mat-form-field class="field-text full-width">
        <input matInput type="text" placeholder="Transaction Key" [disabled]="disabled" [(ngModel)]="transactionKey" />
      </mat-form-field>
      <mat-form-field class="field-text full-width">
        <mat-select name="options" placeholder="Payment Method and Amount" [disabled]="disabled" [(ngModel)]="subPaymentKey">
          <mat-option *ngFor="let keyValue of dictionary" [value]="keyValue.id">
            {{ keyValue.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="field-text full-width">
        <input matInput type="text" placeholder="Reason" [disabled]="disabled" [(ngModel)]="reason" />
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="actions" *ngIf="loaded">
    <div *ngIf="showErrorScreen">
      <button mat-raised-button (click)="okOnClick()" [color]="'accent'" tabindex="2">OK</button>
    </div>
    <div *ngIf="showEditionScreen">
      <button mat-raised-button (click)="submitOnClick()" [disabled]="invalid" [color]="'accent'" tabindex="2">Submit</button>
      <button mat-raised-button (click)="cancelOnClick()" [color]="'warn'" tabindex="-1">Cancel</button>
    </div>
    <div *ngIf="showConfirmationScreen">
      <button mat-raised-button (click)="confirmOnClick()" [disabled]="invalid" [color]="'accent'" tabindex="2">Yes</button>
      <button mat-raised-button (click)="denyOnClick()" [color]="'warn'" tabindex="-1">No</button>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading">
    <mat-progress-spinner class="progress-spinner" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
