import { Observable } from 'rxjs';

import { PHONE_NUMBER_SOURCE } from '../../../enums/phone-number-source';

export interface SmsNumber {
  created_at: string;
  data: any;
  phone_number: string;
  sid?: string;
  supplier_key: string;
  updated_at: string;
  uuid: string;
}

export interface SmsNumbers {
  data: SmsNumber[];
  meta: string;
  status_code: number;
}

export interface Capabilities {
  mms: boolean;
  sms: boolean;
  voice: boolean;
}

export interface SmsRequestData {
  account_sid: string;
  address_requirements: string;
  api_version: string;
  beta: boolean;
  capabilities: Capabilities;
  date_created: string;
  date_updated: string;
  emergency_address_sid?: string;
  emergency_status: string;
  friendly_name: string;
  origin: string;
  phone_number: string;
  sid: string;
  sms_application_sid: string;
  sms_fallback_method: string;
  sms_fallback_url: string;
  sms_method: string;
  sms_url: string;
  status_callback: string;
  status_callback_method: string;
  status_code: number;
  trunk_sid?: string;
  uri: string;
  voice_application_sid?: string;
  voice_caller_id_lookup: boolean;
  voice_fallback_method: string;
  voice_fallback_url?: string;
  voice_method: string;
  voice_url?: string;
}

export interface SmsRequestMeta {
  message: string;
}

export interface SmsRequestResponse {
  data: SmsRequestData;
  meta: SmsRequestMeta;
  status_code: number;
}

export interface SmsNumbersProvider {
  getNumbers(supplierKey: string): Observable<SmsNumbers>;
  requestNumber(supplierKey: string, source: string): Observable<SmsRequestResponse>;
}

export class SmsNumbersBase implements SmsNumbers {
  data: SmsNumber[] = [];
  meta: string;
  status_code: number;
}

export class SmsNumberBase implements SmsNumber {
  created_at: string;
  data: any;
  phone_number: string;
  supplier_key: string;
  updated_at: string;
  uuid: string;
  sid?: string;
  source?: PHONE_NUMBER_SOURCE;

  updateFromSmsRequestResponse(smsRequestResponse: SmsRequestResponse) {
    this.phone_number = smsRequestResponse.data.phone_number;
    this.sid = smsRequestResponse.data.sid;
    this.created_at = smsRequestResponse.data.date_created;
    this.updated_at = smsRequestResponse.data.date_updated;
  }
}
