export class SupplierGeneralFormTypes {
  static readonly CONTACT_NAME_FORM = 'contactNameForm';
  static readonly CONTACT_DATA_FORM = 'contactDataForm';
  static readonly INSTANT_MESSENGER_FORM = 'instantMessengerForm';
  static readonly LEGAL_FORM = 'legalForm';

  static readonly ADDRESS_FORM = 'addressForm';
  static readonly TAX_FORM = 'taxForm';
  static readonly TIMEZONE_FORM = 'timezoneForm';
  static readonly INCORPORATION_FORM = 'incorporationForm';
  static readonly GENERAL_INFO_FORM = 'generalInfoForm';
  static readonly AUTO_PAYMENT_FORM = 'autoPaymentForm';
  static readonly FUNDING_PARTICULARS_FORM = 'fundingParticularsForm';
  static readonly SHIPPING_ADDRESS_FORM = 'shippingAddressForm';
}
