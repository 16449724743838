
import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: false,
  displayErrorsInConsole: true,
  displayErrorsPopup: false,
  VERSION: require('../../package.json').version,
  envName: 'test',
  cliApiUrl: 'https://vpn.payability.com/database_v2/cli/',
  internalApiUrl: 'https://vpn.payability.com/database_v2/internal/',
  supWidgetsApiUrl: 'https://vpn.payability.com/database_v2/sup_widget/',
  authToolsApiUrl: 'https://0vd7bg72e4.execute-api.us-east-1.amazonaws.com/stage/',
  workflowApiUrl: 'https://vpn.payability.com/b2btaskmanager/',
  workflowGciId: 317355,
  googleAuthClientId: '592848749680-3fs9ru3khk5u53i92hgf0v0giuhds1ee.apps.googleusercontent.com',
  sentryUrl: 'https://9a818f1e25d446de9f6fce09d61f451d@sentry.io/247722',
  supplierReferralUrl: 'https://go-test.payability.com/',
  b2bDocumentsApiUrl: 'https://vpn.payability.com/b2bdocument/',
  pybDocumentsApiUrl: 'https://vpn.payability.com/pyb-documents/',
  documentsApiUrl: 'https://ajwk785vec.execute-api.us-east-1.amazonaws.com/dev/',
  actionsToolsUrl: 'https://vn1s47jzkd.execute-api.us-east-1.amazonaws.com/stage/',
  eventLoggerUrl: 'https://epu44ua492.execute-api.us-east-1.amazonaws.com/test_rules2/',
  eventRulesOldUrl: 'https://drkrpkdxe4.execute-api.us-east-1.amazonaws.com/stage/',
  messagingUrl: 'https://argumexh08.execute-api.us-east-1.amazonaws.com/stage/',
  supplierUrl: 'https://vpn-supplier.payability.com',
  marketplaceApiUrl: 'https://marketplace-api-dev2.payability.dev/',
  automaticMarketplaceApiUrl: 'https://vpn.payability.com/automatic-uw-tool-web-api/',
  evaApiUrl: 'https://vpn-eva-fe.payability.com/',
  s3FilesProviderApi: 'https://vpn.payability.com/s3-files-provider/',
  instanceId: 8001,
  frontendSegmentIOCode: 'qQtIVnv1eERcK7bsQs8ITA48ak8kzxA3/',
  supplierSiteVersions: [
    'c2fo',
    'morecommerce',
    'neweggcapital',
    'neweggcapital2',
    'onboarding',
    'onboarding1',
    'supplier',
    'supplier2',
    'supplier3',
    'supplier4',
    'volusion'
  ],
  featureFlags: '',
  gitHash: '90d9138f4feccdf2ff8bb31a0dc233b4789092c2',
  lastBuildDate: '2024-11-06T14:12',
  domain: 'payability.com'
};
