import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

type MessageCallback = (messageEvent: MessageEventInterface) => void;

interface Message {
  formGroupInstance: FormGroup;
  actionType: CardFormgroupMessageType | string;
  payload?: any;
}

export enum CardFormgroupMessageType {
  GROUP_IN_EDIT = 'groupInEdit',
  CANCEL = 'cancel',
  SAVE_GROUP = 'saveGroup',
  UPDATE = 'update',
  CLEAR = 'clear',
  UPDATE_INITIAL = 'updateInitial',
  SAVE_SUCCESS = 'saveSuccess',
  SAVE_ERROR = 'saveError',
}

export interface MessageEventInterface {
  actionType: CardFormgroupMessageType;
  payload: any;
}

@Injectable()
export class CardFormgroupMessageService {
  private readonly handler = new Subject<Message>();

  broadcast(formGroupInstance: FormGroup, actionType: CardFormgroupMessageType | string, payload?: any): void {
    this.handler.next({ formGroupInstance, payload, actionType });
  }

  subscribe(formGroupInstance: FormGroup, callback: MessageCallback): Subscription {
    return this.handler
      .pipe(
        filter((event) => event.formGroupInstance === formGroupInstance),
        map(
          (event) =>
            ({
              actionType: event.actionType,
              payload: event.payload,
            } as MessageEventInterface),
        ),
      )
      .subscribe(callback);
  }
}
