<div class="row">
  <div [ngClass]="(layoutState$ | async).crmPanelState === crmPanelStates.FIXED ? 'col-12' : 'col-lg-6 col-md-12'">
    <div class="card">
      <div class="card-content">
        <div class="card-title">Deposit Account</div>
        <div class="divider"></div>
        <div class="row" *ngIf="depositAccount; else loading">
          <mat-form-field class="col-md-12">
            <input matInput placeholder="Account Name" [(ngModel)]="depositAccount.accountName" type="text" />
          </mat-form-field>
          <mat-form-field class="col-md-12">
            <input matInput placeholder="Account Number" [(ngModel)]="depositAccount.accountNumber" [disabled]="true" type="text" />
          </mat-form-field>
          <mat-form-field class="col-md-12">
            <input matInput placeholder="Routing Number" [(ngModel)]="depositAccount.routingNumber" [disabled]="true" type="text" />
          </mat-form-field>
        </div>
      </div>
      <div class="card-action text-right">
        <button mat-button class="pull-left" color="warn" (click)="deleteDepositAccount()">Delete</button>
        <button mat-button color="warn" [routerLink]="['../../list']">Cancel</button>
        <button mat-button color="primary" (click)="saveDepositAccount()">Save</button>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-loading-spinner></app-loading-spinner>
</ng-template>
