import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { FeatureFlagsEvaService } from '../feature-flags/feature-flags-eva.service';
import { MESSAGE_SOURCE, MESSAGE_TYPE } from '../iframe/communication/message-type.enum';
import { SubscriberComponent } from './../component-subscriber/subscriber.component';
import { CommunicationService } from './../iframe/communication/communication.service';
import { UsersService } from './../users/service/users.service';
import { EvaService } from './eva.service';
import { EvaCommunicationCommands, EvaCommunicationService } from './eva-communication.service';
import { EvaPermissions } from './models/eva-permissions.model';

@Component({
  selector: 'app-eva',
  templateUrl: './eva.component.html',
  styleUrls: ['./eva.component.scss'],
  providers: [EvaCommunicationService],
})
export class EvaComponent extends SubscriberComponent implements OnInit, OnDestroy {
  @Input() url: string = null;
  @Input() set queryParams(params: { [key: string]: string }) {
    this.params = params;
    this.setEvaUrl();
  }

  evaUrl: string = null;
  params: { [key: string]: string } = {};
  iframeId: string;
  enabled = true;
  private user = null;
  private permissions: EvaPermissions;

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    this.evaService.clearAuth();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.evaService.clearAuth();
  }

  constructor(
    private evaService: EvaService,
    private cd: ChangeDetectorRef,
    private evaCommunicationService: EvaCommunicationService,
    private featureFlagsEvaService: FeatureFlagsEvaService,
    private usersService: UsersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));

    this.subscriptions.push(
      this.usersService
        .getPermissionSubPerms(this.getUserPermCode())
        .pipe(map((subperms) => subperms.map((subperm) => subperm.permCode)))
        .subscribe((subPermissions) => this.setConfig(subPermissions)),
    );
  }

  setEvaUrl(): void {
    if (this.user) {
      const token = this.user.jwtToken;
      this.evaService.setAuth(token);
      this.evaUrl = this.evaService.getUrl(this.url, this.params);
      this.cd.detectChanges();
    }
  }

  communication(): CommunicationService {
    return this.evaCommunicationService.communication();
  }

  iframeIdInit(iframeId: string) {
    this.iframeId = iframeId;
    this.evaCommunicationService.setIframeId(this.iframeId);
  }

  sendMessage(): EvaCommunicationCommands {
    return this.evaCommunicationService;
  }

  sendConfig(): void {
    this.subscriptions.push(
      this.communication()
        .incomingMessage$.pipe(filter((message) => this.isConfigRequest(message)))
        .subscribe(async (message) => {
          const data = message.payload.data ? message.payload.data.featureFlags : null;

          if (data) {
            await this.sendConfigResponse(data);
          }
        }),
    );
  }

  reload(): void {
    this.enabled = false;
    this.cd.detectChanges();

    setTimeout(() => {
      this.enabled = true;
      this.cd.detectChanges();
    }, 100);
  }

  private getUserPermCode(): string {
    if (!this.user) {
      return null;
    }

    if (this.user.perms && this.user.perms.length === 0) {
      return null;
    }

    return this.user.perms[0].permCode;
  }

  private isConfigRequest(message): boolean {
    if (!message || !message.source) {
      return false;
    }

    return message.source === MESSAGE_SOURCE.CHILD && message.payload.type === MESSAGE_TYPE.GENERAL__CONFIG_REQUEST;
  }

  private setConfig(subPermissions: string[]): void {
    this.permissions = {
      permission: this.getUserPermCode(),
      subPermissions,
    };
    this.setEvaUrl();
    this.sendConfig();
  }

  private async sendConfigResponse(data): Promise<void> {
    await this.featureFlagsEvaService.initFF(data.available, data.build);

    this.evaCommunicationService.communication().featureFlagsFromEva$.next(data);

    const recalculatedFlags = this.featureFlagsEvaService.fetchAvailableFlagsExecStatus();

    const evaFlags = [];
    recalculatedFlags.forEach((flag) => {
      const [key, value] = Object.entries(flag)[0];

      if (value) {
        evaFlags.push(key);
      }
    });
    this.sendMessage().sendUpdatedConfig(evaFlags, this.permissions);
  }

  ngOnDestroy(): void {
    this.evaService.clearAuth();
  }
}
