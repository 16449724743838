<ng-template #loadingCard>
  <div class="card-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
<ng-template #loadingFrom>
  <div class="form-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
<div class="card">
  <div class="card-content row">
    <div *ngIf="transactionDetails; else loadingCard" class="col-lg-12">
      <div class="navigation">
        <a [routerLink]="['../']" title="Go to supplier list" class="close-button">
          <i class="material-icons">arrow_back</i>
        </a>
      </div>
      <div class="col-lg-12 border-bottom">
        <h5>Transaction</h5>
      </div>
      <div class="col-lg-12">
        <h4>
          ${{ transactionDetails?.general?.amount }} <small>USD</small>
          <span class="status"> {{ transactionDetails?.general?.status }}</span>
        </h4>
      </div>

      <!-- <div *ngFor="let historyItem of transactionDetails?.history; let index = index" class="col-lg-12">
        <mat-icon *ngIf="index === 0"> folder </mat-icon>
        <mat-icon *ngIf="index === 1"> check_circle </mat-icon>
        <mat-icon *ngIf="index === 2"> sync </mat-icon>
        <div class="title">
          {{ historyItem?.text }}
        </div>
        <div class="value">
          {{ historyItem?.date }}
        </div>
      </div> -->

      <table>
        <thead>
          <tr>
            <th width="10%" class="text-left">Amount</th>
            <th width="10%" class="text-left">Create_ts</th>
            <th width="10%" class="text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of transactionDetails?.general?.history; let $index = index">
            <td class="text-left">${{ item?.amount }}</td>
            <td class="text-left">
              {{ item?.create_ts }}
            </td>
            <td class="text-left">
              {{ item?.status }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="card">
  <div *ngIf="transactionDetails; else loadingCard" class="card-content row">
    <div class="col-lg-12 border-bottom">
      <h5>Transaction Details</h5>
    </div>
    <div class="col-lg-2">Transaction ID</div>
    <div class="col-lg-10">
      {{ transactionDetails?.details?.token }}
    </div>
    <div class="col-lg-2">Amount</div>
    <div class="col-lg-10">${{ transactionDetails?.details?.amount }}</div>
    <div class="col-lg-2">Rebate</div>
    <div class="col-lg-10">${{ transactionDetails?.details?.rebate }}</div>
    <div class="col-lg-2">Date</div>
    <div class="col-lg-10">
      {{ transactionDetails?.details?.date }}
    </div>
  </div>
</div>

<div class="card">
  <div class="card-content">
    <div *ngIf="transactionDetails; else loadingCard">
      <div class="col-lg-12 border-bottom">
        <h5>Logs</h5>
      </div>
      <div class="transaction-details" *ngFor="let log of transactionDetails?.logs; let index = index">
        <div class="row" (click)="openModal(index, transactionDetails.logs)">
          <div class="col-lg-1">
            <span class="status"> {{ log?.status }} </span>
          </div>
          <div class="col-lg-9">POST {{ log?.url }}</div>
          <div class="col-lg-2">{{ log?.create_ts }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
