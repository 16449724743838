import { Component, OnInit } from '@angular/core';

import { themeConfigData } from '../../config';

@Component({
  selector: 'app-layout-footer',
  styles: [],
  templateUrl: './footer.component.html',
})
export class AppFooterComponent implements OnInit {
  public ThemeConfig: any;

  ngOnInit() {
    this.ThemeConfig = themeConfigData;
  }
}
