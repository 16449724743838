<section class="container-fluid no-breadcrumbs page-dashboard">
  <div class="box box-default">
    <div class="box-header row">
      <div class="col-md-8">
        <span *ngIf="id">
          <h4>{{ item?.marketplaceName }}</h4>
          <p>
            <a
              matTooltip="Copy MarketplaceKey"
              *ngIf="item?.marketplaceKey"
              ngxClipboard
              [cbContent]="item?.marketplaceKey"
              [innerText]="item?.marketplaceKey"
            ></a>
            <span *ngIf="item?.marketplaceCode">—</span>
            <a
              matTooltip="Copy MarketplaceCode"
              *ngIf="item?.marketplaceCode"
              ngxClipboard
              [cbContent]="item?.marketplaceCode"
              [innerText]="item?.marketplaceCode"
            ></a>
            <span>—</span>
            <a matTooltip="Copy current URL" *ngIf="item?.marketplaceKey" ngxClipboard [cbContent]="currentUrl()">🔗</a>
          </p>
        </span>
      </div>
      <div class="col-md-4 text-right">
        <div>
          <a [routerLink]="'/marketplaces'" title="Go to marketplace list" class="close-button">
            <i class="material-icons">close</i>
          </a>
        </div>
      </div>
    </div>
    <div class="box-body">
      <form class="row" name="marketplaceDetailsForm" [formGroup]="marketplaceForm" novalidate>
        <fieldset class="col-md-6 row">
          <div class="col-md-10">
            <p><strong>Marketplace Information</strong></p>
            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput placeholder="Marketplace name" formControlName="marketplaceName" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="full-width">
                <textarea
                  matInput
                  placeholder="Marketplace description"
                  formControlName="marketplaceDescription"
                  matTextareaAutosize
                ></textarea>
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>

            <fieldset formGroupName="address">
              <div class="=form-group">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Address Line 1" formControlName="addr1" type="text" />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
              </div>
              <div class="=form-group">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Address Line 2" formControlName="addr2" type="text" />
                </mat-form-field>
              </div>
              <div class="=form-group">
                <mat-form-field class="full-width">
                  <input matInput placeholder="City" formControlName="city" type="text" />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
              </div>
              <div class="=form-group">
                <mat-form-field class="full-width">
                  <input matInput placeholder="State" formControlName="state" type="text" />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
              </div>
              <div class="=form-group">
                <mat-form-field>
                  <mat-select class="full-width" placeholder="Country" formControlName="country" type="text" matInput>
                    <mat-option *ngFor="let country of countries | async" [value]="country.countryCode">
                      {{ country.label }}
                    </mat-option>
                  </mat-select>
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
              </div>
              <div class="=form-group">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Zip Code" formControlName="postalCode" type="text" />
                  <mat-error>This field is required.</mat-error>
                </mat-form-field>
              </div>
            </fieldset>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput placeholder="Marketplace URL" formControlName="marketplaceUrl" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label class="full-width">Marketplace Image</label>
              <div class="image-container" *ngIf="url">
                <img [src]="url" class="image" />
              </div>
              <label [ngClass]="'enable'" for="file-upload" class="custom-file-upload"> <i class="fa fa-cloud-upload"></i> Upload </label>
              <input (change)="readUrl($event)" [formControl]="eventToReset" accept="image/x-png" id="file-upload" type="file" />
              <button mat-button mat-raised-button color="warn" [disabled]="lockedDeleteBtn" (click)="deleteUrl()">Delete</button>
            </div>
            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput placeholder="Instructions PDF URL" formControlName="instructionPdfUrl" />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field>
                <mat-select class="full-width" placeholder="Period Group Code" formControlName="marketplacePeriodGroupCode" matInput>
                  <mat-option *ngFor="let periodCode of marketplacePeriodGroupCode" [value]="periodCode.id">
                    {{ periodCode.id }}
                  </mat-option>
                </mat-select>
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Receiving Due Days" formControlName="receivingDueDays" />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field>
                <mat-select class="full-width" placeholder="Access Type" formControlName="accessType" matInput>
                  <mat-option *ngFor="let item of ['manual', 'login', 'login with store name']" [value]="spacesToUnderscore(item)">
                    {{ item }}
                  </mat-option>
                </mat-select>
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput placeholder="Supplier Widget Priority" formControlName="supplierWidgetPriority" />
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput placeholder="Time Zone" formControlName="timezone" [matAutocomplete]="auto" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>

              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let zone of timezones | async" [value]="zone.id">
                  {{ zone.label }}
                </mat-option>
                <mat-error>This field is required.</mat-error>
              </mat-autocomplete>
            </div>

            <div class="form-group">
              <mat-checkbox formControlName="instantAccess">Instant Access</mat-checkbox>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="instantAdvance">Instant Advance</mat-checkbox>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="allowEnroll">Allow Enroll</mat-checkbox>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="trusted">Trusted</mat-checkbox>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="hideSplashScreen">Hide Splash Screen</mat-checkbox>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="addSupplierLogins">Add Supplier Logins</mat-checkbox>
            </div>
            <ng-container *appEnabledByFeatureFlag="featureFlags.UNDERWRITING_TRACKER">
              <div class="form-group">
                <mat-checkbox formControlName="allowUnderwritingTracker">Allow Underwriting Tracker</mat-checkbox>
              </div>
            </ng-container>
          </div>
        </fieldset>

        <fieldset class="col-md-6 row" formGroupName="defaults">
          <div class="col-md-10">
            <p><strong>Default Settings for Suppliers</strong></p>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="Default Funding Period Group Code"
                  formControlName="defaultFundingPeriodGroupCode"
                  [matAutocomplete]="autoFunds"
                />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>

              <mat-autocomplete #autoFunds="matAutocomplete">
                <mat-option *ngFor="let fp of fundingPeriods | async" [value]="fp.fundingPeriodGroupCode">
                  {{ fp.fundingPeriodGroupCode + ' — ' + fp.userFriendlyName }}
                </mat-option>
              </mat-autocomplete>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <mat-select placeholder="Fee Schedule Name" formControlName="defaultFeeSchedule">
                  <mat-option *ngFor="let feeSchedule of feeSchedules" [value]="feeSchedule.feeScheduleKey">
                    {{ feeSchedule.feeScheduleName }}
                  </mat-option>
                </mat-select>
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Default Reserve Percent" formControlName="defaultReservePercent" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Default Min Reserve Percent" formControlName="defaultMinReservePercent" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="field-wider">
                <mat-select
                  class="full-width"
                  placeholder="Default Free Trial Type Code"
                  formControlName="defaultFreeTrialTypeCode"
                  matInput
                >
                  <mat-option *ngFor="let freeTrialCode of defaultFreeTrialTypeCode" [value]="freeTrialCode.id">
                    {{ freeTrialCode.id }}
                  </mat-option>
                </mat-select>
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input
                  matInput
                  type="number"
                  placeholder="Default Free Trial Num Mp Periods"
                  formControlName="defaultFreeTrialNumMpPeriods"
                />
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Default Rcv Purchase Threshold" formControlName="defaultRcvPurchaseThreshold" />
                <mat-error>This field is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-checkbox formControlName="defaultMpCanOptIn">Default Mp Can Opt In</mat-checkbox>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="defaultPbCanOptIn">Default Pb Can Opt In</mat-checkbox>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="defaultMpPaysPb">Default Mp Pays Pb</mat-checkbox>
            </div>
            <div class="form-group">
              <mat-checkbox formControlName="defaultFeeFromReserve">Default Fee From Reserve</mat-checkbox>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput placeholder="Default Payment Terms" formControlName="defaultPaymentTerms" />
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Default Payment Terms Days" formControlName="defaultPaymentTermsDays" />
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Default Payment Frequency" formControlName="defaultPaymentFrequency" />
              </mat-form-field>
            </div>
          </div>
        </fieldset>
        <fieldset class="col-md-6 row">
          <div class="col-md-10">
            <p><strong>Risk</strong></p>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Risk Score" formControlName="riskScore" />
                <mat-error>Required number from 0 to 5</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Risk Score Override" formControlName="riskScoreOverride" />
                <mat-error>Required number from 0 to 5</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Credit Score" formControlName="creditScore" />
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Credit2Be Credit Score" formControlName="credit2beCreditScore" />
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="full-width">
                <input matInput type="number" placeholder="DnB Credit Score" formControlName="dnbCreditScore" />
              </mat-form-field>
            </div>
          </div>
        </fieldset>
      </form>
      <div class="col-md-12">
        <button type="submit" (click)="onSubmit()" class="btn btn-success pull-right">Save</button>
      </div>
    </div>
  </div>
</section>
