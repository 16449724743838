import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { isUndefined } from 'util';

@Pipe({ name: 'fromUnix' })
export class FromUnixPipe implements PipeTransform {
  transform(value: any, ...args: string[]): any {
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }
    return !isUndefined(value) ? moment.unix(value).toDate() : new Date();
  }
}
