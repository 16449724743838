import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { SubscriberComponent } from './../component-subscriber/subscriber.component';
import { LayoutService } from './layout.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.html',
})
export class MainLayoutComponent extends SubscriberComponent implements OnInit {
  customClasses = '';

  constructor(private readonly layoutService: LayoutService, private readonly cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.layoutService.customClasses$.subscribe((customClasses) => {
        this.customClasses = customClasses;
        this.cd.detectChanges();
      }),
    );
  }
}
