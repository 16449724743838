import { Component, ViewChild } from '@angular/core';

import { EvaComponent } from '../../shared/eva/eva.component';

@Component({
  selector: 'app-admin-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss'],
})
export class AdminQaComponent {
  @ViewChild('appEva') appEva: EvaComponent;
}
