import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isUndefined } from 'util';

import { CsvExportService } from '../../../shared/csv-export-service/csv-export.service';
import { DataTableQueryParams, DataTableSettingsRecord } from '../../../shared/data-table/data-table.common';
import { ADD_TRANSACTION_EVENT, TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-supplier-transaction-summary',
  templateUrl: './supplier-transaction-summary.component.html',
  styleUrls: ['./supplier-transaction-summary.component.scss'],
})
export class SupplierTransactionSummaryComponent extends SubscriberComponent implements OnInit {
  public readonly reportName: string = 'transaction_summary';

  public reportData: Observable<any>;
  public reportConfig: Observable<any>;
  public currentQueryParams: any;

  public baseHttpParams: any;
  public reportConfigName: string;
  public exportMenuOptions: any;

  public supplierKey: string;
  public tableSettings: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly service: SupplierService,
    private readonly datePipe: DatePipe,
    private readonly store: Store<AppState>,
    private readonly csvExportService: CsvExportService,
    private readonly eventService: TableEventsService,
  ) {
    super();
  }

  ngOnInit() {
    this.baseHttpParams = {
      limit: 50,
      offset: 0,
      order_by: '-xact_post_date',
    };

    this.exportMenuOptions = {
      'Export to CSV': () => {
        this.exportCsv();
      },
    };

    const init = {
      pageSize: this.baseHttpParams.limit,
      hiddenColumns: ['supplier_key', 'supplier_name'],
      hiddenFilters: ['supplier_key', 'supplier_name'],
      orderBy: this.baseHttpParams.order_by,
      linkColumns: [
        {
          name: 'xact_post_date',
          value: 'xact_post_date',
          link: (display, value) => {
            const formatedValue = this.datePipe.transform(value, 'yyyy-MM-dd');
            return `<a href="${location.hash.replace('transaction-summary', 'transaction-details?date=' + formatedValue)}">${display}</a>`;
          },
        },
      ],
    };

    this.tableSettings = new DataTableSettingsRecord();
    Object.assign(this.tableSettings, init);

    this.reportConfigName = !isUndefined(this.reportName)
      ? this.reportName.includes('v_')
        ? this.reportName
        : `v_${this.reportName}`
      : '';

    this.eventService.on(ADD_TRANSACTION_EVENT, () => {
      this.getData(this.currentQueryParams);
    });

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;
          this.getConfig();
          this.getData();
        }),
    );
  }

  getData(params?: DataTableQueryParams): void {
    if (!params) {
      params = this.baseHttpParams;
    }

    this.reportData = this.service.getSupplierReport(this.supplierKey, this.reportName, params);
  }

  getConfig(): void {
    this.reportConfig = this.service.getReportConfig(this.reportConfigName);
  }

  tableOnParamQueryChanged(args: DataTableQueryParams): void {
    this.currentQueryParams = args;

    this.getData(args);
  }

  exportCsv() {
    const args = { ...this.currentQueryParams, make_dump: true };
    this.subscriptions.push(
      this.service.getSupplierReport(this.supplierKey, this.reportName, args).subscribe((data) => {
        this.csvExportService.exportTransactions(data, 'transaction-summary', '');
      }),
    );
  }
}
