/* eslint-disable */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { DefaultRadiobuttons, NoDataText } from '../common/consts/underwritings-initial-constants';
import { UWRules } from '../common/interfaces/uw-rules';
/* eslint-enable */

@Component({
  selector: 'app-amazon-lending',
  templateUrl: './amazon-lending.component.html',
})
export class AmazonLendingComponent implements OnChanges {
  @Input() amazonLendingOverride: any;
  @Input() amazonLoanDecision: boolean;
  @Input() UWRules: UWRules;
  @Input() amazonHealth: any;
  @Input() computingDataIsInProgress: boolean;

  @Output() onAmazonLendingOverride = new EventEmitter();

  headerType: UnderwritingInitialCardHeaderStatusType;
  headerStatus: string;
  radiobuttons = DefaultRadiobuttons;
  noDataText = NoDataText;

  ngOnChanges(changes) {
    this.setHeaderDetails();
  }

  setHeaderDetails() {
    if (this.isSuccessHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.SUCCESS;
      this.headerStatus = 'Valid';
    }
    if (this.isDangerHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.DANGER;
      this.headerStatus = 'Needs review';
    }
  }

  isSuccessHeaderType() {
    return this.amazonLendingOverride[0].value || (!this.amazonLendingOverride[0].value && this.amazonLoanDecision);
  }

  isDangerHeaderType() {
    return !this.amazonLendingOverride[0].value && !this.amazonLoanDecision;
  }

  radiobuttonsChange(value) {
    if (!this.amazonLendingOverride[0]) {
      return;
    }
    this.amazonLendingOverride[0].value = value;
    this.onAmazonLendingOverride.emit(this.amazonLendingOverride);
  }
}
