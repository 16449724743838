import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isUndefined } from 'util';

import { BaseSupplierAction } from '../../../suppliers/supplier/base-supplier-action/base-supplier-action';
import { BatchImportService } from '../service/batch-imports.service';
import { selectLoggedUserModel } from './../../../auth/store/auth.actions';
import { TableEventsService } from './../../../shared/table-events.service';
import { AppState } from './../../../store/app.reducers';
import { CliService } from './../../../suppliers/services/cli/cli.service';
import { SupplierService } from './../../../suppliers/services/supplier.service';

@Component({
  selector: 'app-bulk-notes',
  templateUrl: './bulk-notes.component.html',
  styleUrls: ['../batch-imports.component.scss'],
})
export class BulkNotesComponent extends BaseSupplierAction implements OnInit {
  actionTitle = 'Uppload Bulk Notes';
  label = 'Add bulk notes';

  subscriptions = [];
  user: any;

  constructor(
    formBuilder: FormBuilder,
    store: Store<AppState>,
    dialogRef: MatDialogRef<BaseSupplierAction>,
    cliService: CliService,
    supplierService: SupplierService,
    eventsService: TableEventsService,
    private batchService: BatchImportService,
  ) {
    super(formBuilder, store, dialogRef, cliService, supplierService, eventsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        if (!isUndefined(user)) {
          this.user = user;
        }
      }),
    );
  }

  createForm() {
    this.supplierActionForm = this.formBuilder.group({
      file: [''],
    });
  }

  submit() {
    this.isConfirmed = true;

    this.supplierActionForm.patchValue({
      supplier_key: this.supplierKey,
    });

    const dataToSave = this.supplierActionForm.value;
    const request = this.batchService.addBulkNotes(dataToSave, this.user);

    this.subscriptions.push(
      request.subscribe(
        () => {
          this.onSuccess('File uploaded succesfully');
        },
        (response) => {
          this.onError(response.error.message);
        },
      ),
    );
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }
}
