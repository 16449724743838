<h1 mat-dialog-title>{{ actionTitle }}</h1>

<div [formGroup]="supplierActionForm" *ngIf="showForm && supplierActionForm">
  <mat-form-field class="full-width">
    <mat-select placeholder="Marketplace" formControlName="mp_sup_key">
      <mat-option *ngFor="let marketplace of marketplaces" [value]="marketplace.mp_key">
        {{ marketplace.label }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select placeholder="Marketplace Statement" formControlName="mp_statement">
      <mat-option *ngFor="let statement of marketplaceStatements" [value]="statement">
        {{ statement.start_date | date: 'MM/dd/yyyy' }} - {{ statement.end_date ? (statement.end_date | date: 'MM/dd/yyyy') : 'now' }} :
        {{ (statement.end_bal ? statement.end_bal : 0) | currency }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="startPicker" placeholder="Start date" formControlName="start_date" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
    <mat-datepicker #startPicker></mat-datepicker>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="endPicker" placeholder="End date" formControlName="end_date" />
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="End Balance" type="number" step="0.01" formControlName="end_bal" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <div mat-dialog-actions class="justify-content-end" *ngIf="showForm">
    <button mat-raised-button (click)="submit()" [disabled]="!supplierActionForm.valid" tabindex="2" color="accent">Save</button>
    <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
  </div>

  <app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
</div>
