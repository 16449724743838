import { Component } from '@angular/core';

@Component({
  selector: 'app-supplier-fees',
  templateUrl: './supplier-fees.component.html',
})
export class SupplierFeesComponent {
  navLinks = [
    { route: 'purchase', label: 'Purchase Fees' },
    { route: 'payment', label: 'Payment Fees' },
    { route: 'reserve', label: 'Reserve' },
  ];
}
