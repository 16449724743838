<h1 mat-dialog-title>Update Cancellation</h1>
<div [formGroup]="cancelForm" *ngIf="!isLoading; else spinner">
  <div class="row">
    <div mat-dialog-content class="col-md-7">
      <ng-container *ngTemplateOutlet="marketplacesTpl"></ng-container>

      <mat-form-field class="input-width">
        <mat-select placeholder="Reason" formControlName="initReason">
          <mat-option *ngFor="let item of reasons; trackBy: trackByKey" [value]="item.key">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="warning" class="warning-message">
        <div class="alert alert-danger">{{ warning }}</div>
      </div>

      <div class="editor">
        <textarea class="text-area" rows="4" formControlName="text"> </textarea>
      </div>
    </div>
    <div class="col-md-5 margin-top">
      <div class="calendar-conta">
        <div class="row">
          <div [class.col-md-12]="!predictedFinishedDate" class="col-md-6">
            <div>Opt-Out Date</div>
            <div class="date">{{ dueDate | date: 'M/d/y' }}</div>
          </div>
          <div *ngIf="predictedFinishedDate" class="col-md-6 warning">
            Active contract ! <br />Ends {{ predictedFinishedDate | date: 'M/d/y' }}
          </div>
          <div class="col-md-12">
            <mat-calendar *ngIf="!isUpdating" class="calendar" [selected]="dueDate" (selectedChange)="changeDate($event)"></mat-calendar>
            <ng-container *ngIf="isUpdating">
              <div class="winback" formGroupName="winback">
                <div>
                  <mat-checkbox formControlName="enabled">Log Details</mat-checkbox>
                </div>
                <div [hidden]="!winbackFormControls.enabled.value">
                  <div>
                    <h6>Saved Marketplaces</h6>
                    <div formArrayName="marketplaces" class="row">
                      <span
                        *ngFor="let mp of winbackFormControls.marketplaces['controls']; trackBy: trackByMp; let i = index"
                        [formGroupName]="i"
                        class="col-md-6"
                      >
                        <mat-checkbox formControlName="isChecked">{{ mp.value.data.name }}</mat-checkbox>
                      </span>
                    </div>
                  </div>
                  <div>
                    <h6>Saved Products</h6>
                    <div class="row" formGroupName="products">
                      <div class="col">
                        <mat-checkbox formControlName="instant_access">{{ pybProducts.instant_access }}</mat-checkbox>
                        <mat-checkbox formControlName="instant_advance">{{ pybProducts.instant_advance }}</mat-checkbox>
                      </div>
                      <div class="col">
                        <mat-checkbox formControlName="instant_payouts">{{ pybProducts.instant_payouts }}</mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <div>
                    <mat-form-field class="input-width">
                      <mat-select placeholder="Sales Rep" formControlName="sales_rep">
                        <mat-option *ngFor="let person of winbackSalesRep; trackBy: trackByKey" [value]="person.key">
                          {{ person.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="input-width">
                      <mat-select placeholder="Reason" formControlName="reason">
                        <mat-option *ngFor="let reason of winbackReasons; trackBy: trackByKey" [value]="reason.key">
                          {{ reason.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div [hidden]="!winbackCustomReason">
                    <label>Custom Reason</label>
                    <textarea class="text-area" rows="4" formControlName="reason_description"> </textarea>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <button mat-raised-button [disabled]="disableSubmitButton" (click)="onSubmit()" class="pull-right" tabindex="2" color="accent">
      Save
    </button>
    <button mat-raised-button (click)="onClose()" class="pull-right cancel-btn" tabindex="-1" color="warn">Cancel</button>
  </div>
</div>

<ng-template #spinner>
  <app-loading-spinner class="spinner" [size]="2" *ngIf="isLoading"></app-loading-spinner>
</ng-template>

<ng-template #marketplacesTpl>
  <div [formGroup]="cancelForm">
    <div formArrayName="marketPlaces" class="row">
      <span
        *ngFor="let mp of cancelForm.controls.marketPlaces['controls']; trackBy: trackByMp; let i = index"
        [formGroupName]="i"
        class="col-md-6"
      >
        <mat-checkbox formControlName="isChecked">{{ mp.value.data.marketplace_name }}</mat-checkbox>
      </span>
    </div>
  </div>
</ng-template>
