import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-trans-union-person',
  templateUrl: './trans-union-person.component.html',
})
export class TransUnionPersonComponent implements OnInit {
  public personData: any;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.personData = this.data;
  }
}
