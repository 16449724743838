import { autoserialize, autoserializeAs, inheritSerialization } from 'cerialize';

import { MarketplaceStatusModel } from '../../marketplaces/model/marketplace-status.model';
import { SupplierMarketplaceFundingModel } from './supplier-marketplace-funding.model';
import { SupplierMarketplaceGatewayModel } from './supplier-marketplace-gateway.model';
import { SupplierMarketplaceMinFeeOverridesModel } from './supplier-marketplace-min-fee-overrides.model';
import { SupplierMarketplaceOptInPeriodModel } from './supplier-marketplace-optin-period.model';

// TODO nkler: handle rxjs using general model
export class SupplierMarketplaceModel {
  public supplierKey;
  public mpSupKey;

  public marketplaceDetailsModel?: SupplierMarketplaceDetailsModel;
  public marketplaceFundindModel?: SupplierMarketplaceFundingModel;
  public minFeesOverridesModel?: SupplierMarketplaceMinFeeOverridesModel;
  public optinPeriodsModel?: SupplierMarketplaceOptInPeriodModel[];
}

export class SupplierMarketplaceListModel {
  @autoserialize
  mpSupKey?: string;

  @autoserialize
  marketplaceCode?: string;

  @autoserialize
  marketplaceKey?: string;

  @autoserialize
  marketplaceName?: string;

  @autoserialize
  marketplaceType?: string;

  @autoserialize
  externalSupplierId?: string;

  @autoserialize
  estimatedMonthlyRevenue?: number;

  @autoserialize
  fundingParticulars?: any;

  @autoserialize
  draft?: boolean;

  @autoserializeAs(MarketplaceStatusModel)
  marketplaceStatus?: MarketplaceStatusModel;

  @autoserialize
  paymentGateways?: SupplierMarketplaceGatewayModel[];

  @autoserialize
  fundingPeriodGroupCode?: string;

  @autoserialize
  mpPeriodGroupCode?: string;

  @autoserialize
  mpPayDelayDays?: number;

  @autoserialize
  instantAccess?: boolean;

  @autoserialize
  accessType?: string;

  @autoserialize
  accessStatus?: string;

  @autoserialize
  instructionPdfUrl?: string;

  @autoserialize
  selAutomaticDebitsForStatements?: boolean;

  constructor(
    mpSupKey: string,
    marketplaceCode: string,
    marketplaceKey: string,
    marketplaceName: string,
    externalSupplierId: string,
    estimatedMonthlyRevenue: number,
    fundingParticulars: any,
    draft: boolean,
    marketplaceStatus: MarketplaceStatusModel,
    fundingPeriodGroupCode: string,
    mpPeriodGroupCode: string,
    mpPayDelayDays: number,
    instantAccess: boolean,
    accessType: string,
    accessStatus: string,
    instructionPdfUrl: string,
    loadTransactionWithDelayPayment: boolean,
    marketplaceType: string,
    paymentGateways: SupplierMarketplaceGatewayModel[],
    selAutomaticDebitsForStatements: boolean,
  ) {
    this.mpSupKey = mpSupKey;
    this.marketplaceCode = marketplaceCode;
    this.marketplaceKey = marketplaceKey;
    this.marketplaceName = marketplaceName;
    this.externalSupplierId = externalSupplierId;
    this.estimatedMonthlyRevenue = estimatedMonthlyRevenue;
    this.fundingParticulars = fundingParticulars;
    this.draft = draft;
    this.marketplaceStatus = marketplaceStatus;
    this.fundingPeriodGroupCode = fundingPeriodGroupCode;
    this.mpPeriodGroupCode = mpPeriodGroupCode;
    this.mpPayDelayDays = mpPayDelayDays;
    this.instantAccess = instantAccess;
    this.accessType = accessType;
    this.accessStatus = accessStatus;
    this.instructionPdfUrl = instructionPdfUrl;
    this.marketplaceType = marketplaceType;
    this.paymentGateways = paymentGateways;
    this.selAutomaticDebitsForStatements = selAutomaticDebitsForStatements;
  }
}

@inheritSerialization(SupplierMarketplaceListModel)
export class SupplierMarketplaceDetailsModel extends SupplierMarketplaceListModel {
  @autoserialize
  ascSellerId?: string;

  @autoserialize
  ascAwsSecretKey?: string;

  @autoserialize
  ascMarketplaceId?: string;

  @autoserialize
  ascAwsAccessKeyId?: string;

  @autoserialize
  apiStatus: string;

  @autoserialize
  externalPassword?: any;

  @autoserialize
  ascDevAccountNumber?: any;

  @autoserialize
  paymentTermsDays?: any;

  @autoserialize
  paymentFrequency?: any;

  @autoserialize
  paymentTerms?: any;

  @autoserialize
  lengthTimeSelling: string;

  @autoserialize
  timeSellingLength?: string;

  @autoserialize
  externalMarketplaceUrl?: any;

  @autoserialize
  externalLogin?: any;

  @autoserialize
  funding?: any;

  @autoserialize
  icon?: string;

  @autoserialize
  optinPeriods?: any;

  @autoserialize
  payAdvance?: any;

  @autoserialize
  loadTransactionWithDelayPayment: boolean;

  @autoserialize
  apiLastCheckedTs: any;

  @autoserialize
  lastCheckedTs: any;

  @autoserialize
  bankAccountNumber: string;

  @autoserialize
  estimatedMonthlyGrossRevenue?: number;

  @autoserialize
  currentPayout?: number;

  @autoserialize
  netSales0to30?: number;

  @autoserialize
  netSales30to60?: number;

  @autoserialize
  netSales60to90?: number;

  @autoserialize
  grossSales0to30?: number;

  @autoserialize
  grossSales30to60?: number;

  @autoserialize
  grossSales60to90?: number;

  @autoserialize
  overrideDepositAccount?: boolean;

  @autoserialize
  enableForDebit?: boolean;

  @autoserialize
  selAutomaticDebitsForStatements?: boolean;

  @autoserialize
  overrideDeltaCheck?: boolean;

  @autoserialize
  overrideDailyPlaidCheck?: boolean;

  @autoserialize
  isNotApproved?: boolean;

  constructor(
    mpSupKey: string,
    marketplaceCode: string,
    marketplaceKey: string,
    marketplaceName: string,
    externalSupplierId: string,
    estimatedMonthlyRevenue: number,
    fundingParticulars: any,
    draft: boolean,
    marketplaceStatus: MarketplaceStatusModel,
    fundingPeriodGroupCode: string,
    mpPeriodGroupCode: string,
    mpPayDelayDays: number,
    instantAccess: boolean,
    accessType: string,
    accessStatus: string,
    instructionPdfUrl: string,
    ascSellerId: string,
    ascAwsSecretKey: string,
    ascMarketplaceId: string,
    ascAwsAccessKeyId: string,
    apiStatus: string,
    externalPassword: any,
    ascDevAccountNumber: any,
    paymentTermsDays: any,
    paymentFrequency: any,
    paymentTerms: any,
    payAdvance: any,
    lengthTimeSelling: string,
    externalMarketplaceUrl: any,
    externalLogin: any,
    funding: any,
    icon: string,
    loadTransactionWithDelayPayment: boolean,
    apiLastCheckedTs: any,
    lastCheckedTs: any,
    marketplaceType: string,
    paymentGateways: SupplierMarketplaceGatewayModel[],
    bankAccountNumber: string,
    estimatedMonthlyGrossRevenue: number,
    currentPayout: number,
    netSales0to30: number,
    netSales30to60: number,
    netSales60to90: number,
    grossSales0to30: number,
    grossSales30to60: number,
    grossSales60to90: number,
    timeSellingLength: string,
    overrideDepositAccount: boolean,
    enableForDebit: boolean,
    selAutomaticDebitsForStatements: boolean,
    overrideDeltaCheck: boolean,
    overrideDailyPlaidCheck: boolean,
    isNotApproved: boolean,
  ) {
    super(
      mpSupKey,
      marketplaceCode,
      marketplaceKey,
      marketplaceName,
      externalSupplierId,
      estimatedMonthlyRevenue,
      fundingParticulars,
      draft,
      marketplaceStatus,
      fundingPeriodGroupCode,
      mpPeriodGroupCode,
      mpPayDelayDays,
      instantAccess,
      accessType,
      accessStatus,
      instructionPdfUrl,
      loadTransactionWithDelayPayment,
      marketplaceType,
      paymentGateways,
      selAutomaticDebitsForStatements,
    );

    this.ascSellerId = ascSellerId;
    this.ascAwsSecretKey = ascAwsSecretKey;
    this.ascMarketplaceId = ascMarketplaceId;
    this.ascAwsAccessKeyId = ascAwsAccessKeyId;
    this.apiStatus = apiStatus;
    this.externalPassword = externalPassword;
    this.ascDevAccountNumber = ascDevAccountNumber;
    this.paymentTermsDays = paymentTermsDays;
    this.paymentFrequency = paymentFrequency;
    this.paymentTerms = paymentTerms;
    this.payAdvance = payAdvance;
    this.lengthTimeSelling = lengthTimeSelling;
    this.externalMarketplaceUrl = externalMarketplaceUrl;
    this.externalLogin = externalLogin;
    this.funding = funding;
    this.icon = icon;
    this.loadTransactionWithDelayPayment = loadTransactionWithDelayPayment;
    this.apiLastCheckedTs = apiLastCheckedTs;
    this.lastCheckedTs = lastCheckedTs;
    this.paymentGateways = paymentGateways;
    this.bankAccountNumber = bankAccountNumber;
    this.estimatedMonthlyGrossRevenue = estimatedMonthlyGrossRevenue;
    this.currentPayout = currentPayout;
    this.netSales0to30 = netSales0to30;
    this.netSales30to60 = netSales30to60;
    this.netSales60to90 = netSales60to90;
    this.grossSales0to30 = grossSales0to30;
    this.grossSales30to60 = grossSales30to60;
    this.grossSales60to90 = grossSales60to90;
    this.timeSellingLength = timeSellingLength;
    this.overrideDepositAccount = overrideDepositAccount;
    this.enableForDebit = enableForDebit;
    this.selAutomaticDebitsForStatements = selAutomaticDebitsForStatements;
    this.overrideDeltaCheck = overrideDeltaCheck;
    this.overrideDailyPlaidCheck = overrideDailyPlaidCheck;
    this.isNotApproved = isNotApproved;
  }
}
