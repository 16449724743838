<article class="article">
  <div class="card">
    <div class="card-content">
      <div class="d-flex flex-row justify-content-between">
        <div class="card-title">Amazon Lending</div>
        <button mat-raised-button [routerLink]="'../add-loan'" [color]="'accent'" class="mb-2" [disabled]="!(canAddLoan$ | async)">
          <mat-icon>add</mat-icon> Add New Amazon Loan
        </button>
      </div>

      <div class="row amazon-lending-stats" *ngIf="activeLoan">
        <div class="data-item col-sm-2 col-xs-6">
          <span class="value text-primary">$0.00</span>
          <span class="label text-muted">Current Amount Due</span>
        </div>
        <div class="data-item col-sm-2 col-xs-6">
          <span class="value text-primary">{{
            activeLoan?.nextPaymentDate ? (activeLoan?.nextPaymentDate | date: 'MM/dd/yyyy') : '---'
          }}</span>
          <span class="label text-muted">Next Loan Payment Date</span>
        </div>
        <div class="data-item col-sm-2 col-xs-6">
          <span class="value text-primary">
            {{ activeLoan?.daysLeftToPayment ? activeLoan?.daysLeftToPayment : '---' }}
            {{ activeLoan?.daysLeftToPayment ? (activeLoan?.daysLeftToPayment?.length === 1 ? 'day' : 'days') : '' }}
          </span>
          <span class="label text-muted">Days until Next Payment Date</span>
        </div>
        <div class="data-item col-sm-2 col-xs-6">
          <span class="value text-primary">{{
            activeLoan?.loanExtraReserveAmount ? (activeLoan?.loanExtraReserveAmount | currency) : '$0.00'
          }}</span>
          <span class="label text-muted">Current Payability Loan Provision Accrued</span>
        </div>
      </div>

      <div *ngIf="rows">
        <app-data-table-grid [nodesCounted]="true" [rows]="rows" [columnDefs]="columns$ | async" [domLayout]="'autoHeight'">
        </app-data-table-grid>
      </div>
    </div>
  </div>
</article>
