import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import { selectLoggedUserModel } from '../../auth/store/auth.actions';
import { FilterSet } from '../../crm-tools/models/task-filter.model';
import { TasksFiltersService } from '../../crm-tools/services/tasks-filters.service';
import { TaskStatuses } from '../../crm-tools/tasks/task-statuses';
import { AppState } from '../../store/app.reducers';
import { SubscriberComponent } from '../component-subscriber/subscriber.component';
import { FeatureFlagsService } from '../feature-flags/feature-flags.service';
import { TableEventsService, TASK_COUNTERS_UPDATE } from '../table-events.service';
import { UserModel } from '../users/user.model';

@Component({
  selector: 'app-task-count-badge',
  templateUrl: './task-count-badge.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TaskCountBadgeComponent extends SubscriberComponent implements OnInit, OnDestroy {
  private readonly VISIBILITY_CHANGE = 'visibilitychange';
  private readonly VISIBLE = 'visible';
  @Input() filterType: string;

  readonly RELOAD_COUNT_INTERVAL = 60000;

  badgeSettings: any;
  availableTypes: any;
  tasksCountItems: FilterSet = null;
  reloadCount$ = new Subject();

  private reloadHandler = null;

  constructor(
    private store: Store<AppState>,
    private countersService: TasksFiltersService,
    private tableEvents: TableEventsService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    super();
  }

  ngOnInit(): void {
    document.addEventListener(this.VISIBILITY_CHANGE, this.handlePageVisibility);

    this.subscriptions.push(
      this.reloadCount$.pipe(switchMap(() => this.loadCountData())).subscribe((count) => (this.tasksCountItems = count)),
    );

    this.subscriptions.push(
      this.store
        .select(selectLoggedUserModel)
        .pipe(
          filter((loggedUser) => !!loggedUser),
          first(),
        )
        .subscribe((loggedUser: UserModel) => {
          this.initSettings(loggedUser);

          this.badgeSettings = this.availableTypes[this.filterType];
          this.reloadCount();
        }),
    );

    this.tableEvents.on(TASK_COUNTERS_UPDATE, () => this.reloadCount());
  }

  initSettings(currentUser: UserModel): void {
    const groups = currentUser.perms.map((permItem) => permItem.permCode);

    this.availableTypes = {
      currentUser: {
        label: 'Your tasks',
        customClass: 'bagde-user',
        filterId: 1,
        filters: [
          {
            name: 'assignee_uuid',
            op: 'eq',
            val: currentUser.loginKey,
          },
          {
            name: 'status',
            op: 'in',
            val: [TaskStatuses.NEW, TaskStatuses.IN_PROGR],
          },
        ],
      },
      currentUserGroup: {
        label: 'Group tasks',
        customClass: 'bagde-group',
        filterId: 2,
        filters: [
          {
            name: 'assignee_uuid',
            op: 'in',
            val: groups,
          },
          {
            name: 'assignee_type',
            op: 'eq',
            val: 'group',
          },
          {
            name: 'status',
            op: 'in',
            val: [TaskStatuses.NEW, TaskStatuses.IN_PROGR],
          },
        ],
      },
    };
  }

  reloadCount(): void {
    this.reloadCount$.next();
    clearTimeout(this.reloadHandler);
    this.reloadHandler = setTimeout(() => this.reloadCount(), this.RELOAD_COUNT_INTERVAL);
  }

  loadCountData(): Observable<FilterSet> {
    const countersReqeust = [
      {
        filtersQuery: this.badgeSettings.filters,
      },
    ];

    return this.countersService.getFilterCounts(countersReqeust).pipe(
      map((data) => {
        return data[0];
      }),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    document.removeEventListener(this.VISIBILITY_CHANGE, this.handlePageVisibility);
    clearTimeout(this.reloadHandler);
  }

  private readonly handlePageVisibility = (): void => {
    document.visibilityState === this.VISIBLE ? this.reloadCount() : clearTimeout(this.reloadHandler);
  };
}
