import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EnabledByFeatureFlagDirective } from './feature-flag.directive';
import { FeatureFlagGuard } from './feature-flag.guard';
import { FeatureFlagsService } from './feature-flags.service';
import { FeatureFlagsAdminService } from './feature-flags-admin.service';
import { FeatureFlagsEvaService } from './feature-flags-eva.service';

@NgModule({
  declarations: [EnabledByFeatureFlagDirective],
  imports: [CommonModule],
  providers: [FeatureFlagsService, FeatureFlagsAdminService, FeatureFlagsEvaService, FeatureFlagGuard],
  exports: [EnabledByFeatureFlagDirective],
})
export class FeatureFlagsModule {}
