import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { SupplierUnderwritingService } from '../../supplier-underwriting.service';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';
import { UwReviewForm } from './_models/uw-review-form.model';
import { UwReviewModel } from './_models/uw-review-model';

@Component({
  selector: 'app-uw-review',
  templateUrl: './uw-review.component.html',
  styleUrls: ['./uw-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UwReviewComponent extends SubscriberComponent implements OnInit {
  @Input() supplierKey: string;
  @Input() owner: object;
  @Input() key: string;
  @Input() show: boolean;
  @Input() isDisabled: boolean;
  @Input() isSoleProp: boolean;

  readonly userPermission = USER_PERMISSION;

  uwReviewForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private underwritingService: SupplierUnderwritingService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.uwReviewForm = this.formBuilder.group(new UwReviewForm(new UwReviewModel()));

    if (this.isDisabled) {
      this.uwReviewForm.disable();
    }

    if (this.owner) {
      this.uwReviewForm.patchValue(this.owner);
    }

    this.cd.detectChanges();
  }

  private setControlsValue(form, arg: boolean) {
    Object.values(form.controls).forEach((control: any) => {
      control.patchValue(arg);
    });
  }

  public selectAll(event) {
    if (event.checked === true) {
      return this.setControlsValue(this.uwReviewForm, true);
    } else {
      return this.setControlsValue(this.uwReviewForm, false);
    }
  }

  public saveForm() {
    if (!isNullOrUndefined(this.key)) {
      const dataToSave = {};
      dataToSave[this.key] = this.preparePayloadForAdditionalOwner();
      this.subscriptions.push(
        this.underwritingService.patchOwnersMarqetaKycCompliances(this.supplierKey, dataToSave).subscribe(
          (value) => {
            this.toastrService.success('Review has been successfully updated!');
          },
          (error) => {
            this.toastrService.error(error.message);
          },
        ),
      );
    } else {
      const dataToSave = this.uwReviewForm.value;
      this.subscriptions.push(
        this.underwritingService.patchMarqetaKycCompliances(this.supplierKey, dataToSave).subscribe(
          (value) => {
            this.uwReviewForm.updateValueAndValidity();
            this.toastrService.success('Review has been successfully updated.');
          },
          (error) => {
            this.toastrService.error(error.message);
          },
        ),
      );
    }
  }

  private preparePayloadForAdditionalOwner() {
    const customForm = {};
    customForm['MARQETA_KYC_FULL_NAME'] = this.uwReviewForm.get('MARQETA_KYC_FULL_NAME').value;
    customForm['MARQETA_KYC_SSN'] = this.uwReviewForm.get('MARQETA_KYC_SSN').value;
    customForm['MARQETA_KYC_DOB'] = this.uwReviewForm.get('MARQETA_KYC_DOB').value;
    customForm['MARQETA_KYC_RESIDENTAL_ADDRESS'] = this.uwReviewForm.get('MARQETA_KYC_RESIDENTAL_ADDRESS').value;
    customForm['MARQETA_KYC_OFAC_CHECK'] = this.uwReviewForm.get('MARQETA_KYC_OFAC_CHECK').value;
    return customForm;
  }
}
