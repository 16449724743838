import { startCase } from 'lodash';

import { ActionButton, BUTTON_TYPE } from './underwriting-initial-footer.const';

export const BUTTONS_CONFIG: ActionButton[] = [
  {
    type: BUTTON_TYPE.REJECTED,
    disabled: true,
    loading: false,
    color: 'warn',
    name: startCase(BUTTON_TYPE.REJECTED),
    disabledReason: null,
  },
  {
    type: BUTTON_TYPE.INCOMPLETE,
    disabled: true,
    loading: false,
    color: 'black',
    name: startCase(BUTTON_TYPE.INCOMPLETE),
    disabledReason: null,
  },
  {
    type: BUTTON_TYPE.SALES_RESOLUTION,
    disabled: true,
    loading: false,
    color: 'primary',
    name: startCase(BUTTON_TYPE.SALES_RESOLUTION),
    disabledReason: null,
  },
  {
    type: BUTTON_TYPE.VERIFIED,
    disabled: true,
    loading: false,
    color: 'accent',
    name: startCase(BUTTON_TYPE.VERIFIED),
    disabledReason: null,
  },
];
