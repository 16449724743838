import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Observable, Subscription } from 'rxjs';

import { CancelEmitService } from '../../../suppliers/services/cancel-emit.service';
import { CANCELLATION_STATUSES } from '../../../suppliers/supplier/supplier-box-header/supplier-box-header.component';
import { CardFormgroupMessageService } from '../../card-formgroup/card-formgroup-message.service';
import { InlineEditBaseComponent } from '../base/inline-edit-base.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditSelectComponent),
  multi: true,
};

@Component({
  selector: 'app-inline-edit-select',
  styleUrls: ['../base/inline-edit-base.component.scss'],
  templateUrl: './inline-edit-select.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
})
export class InlineEditSelectComponent extends InlineEditBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSelect, { static: false }) inlineEditControl;
  @Input() label = '';
  @Input() type = 'text';
  @Input() items: Observable<any[]>;
  @Output() translatedValue = new EventEmitter<string>();

  itemsValue: any[] = [];
  friendlyValue: string;

  private status = ['pending', 'canceled', 'collections', 'inactive', 'none'];
  private readonly subscriptions: Subscription[] = [];

  constructor(protected messageService: CardFormgroupMessageService, protected cancelEmitService: CancelEmitService) {
    super(messageService, cancelEmitService);
    this.subscriptions.push(
      this.cancelEmitService.onCancellationChange.subscribe(() => {
        if (CANCELLATION_STATUSES.some((cancellationStatus) => cancellationStatus.toLowerCase() === this.formControl.value.toLowerCase())) {
          this.callSave('pending');
        }
      }),
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setupSelect();

    this.registerOnChange(this.translateValue);

    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((data) => {
        this.value = data;
      }),
    );
  }

  resetValues(): void {
    super.resetValues();
    this.translateValue();
  }

  ngAfterViewInit(): void {
    if (!this.items) {
      return;
    }
    this.subscriptions.push(
      this.items.subscribe((items) => {
        setTimeout(() => {
          this.itemsValue = items;
          this.translateValue();
        });
      }),
    );
  }

  onSelectChange(): void {
    this.inlineEditControl.close();
    this.translateValue();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  protected onFormGroupChange(): void {
    super.onFormGroupChange();
    this.setupSelect();
  }

  private setupSelect(): void {
    this.registerOnChange(this.translateValue);

    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((data) => {
        this.value = data;
        this.translateValue();
      }),
    );
  }

  private translateValue(): {} {
    if (this.itemsValue) {
      const foundItem = this.itemsValue.find((item) => item.id === this.formControl.value);
      if (foundItem) {
        this.friendlyValue = foundItem.label;
        this.translatedValue.emit(this.friendlyValue);
      }
    }

    return {};
  }
}
