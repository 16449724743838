<mat-table *ngIf="dataSource" [dataSource]="dataSource">
  <ng-container matColumnDef="marketplace">
    <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
    <mat-cell *matCellDef="let reserve">{{ reserve?.marketplaceName }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="advanceRate">
    <mat-header-cell *matHeaderCellDef class="table-fields">Advance Rate</mat-header-cell>
    <mat-cell *matCellDef="let reserve" class="table-fields">
      <mat-form-field>
        <input matInput [value]="reserve?.advanceRate" type="number" [(ngModel)]="reserve.advanceRate" [disabled]="isEditable" />
      </mat-form-field>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="minimumReserveRate">
    <mat-header-cell *matHeaderCellDef class="table-fields">Minimum Reserve Rate</mat-header-cell>
    <mat-cell *matCellDef="let reserve" class="table-fields">
      <mat-form-field>
        <input
          matInput
          [value]="reserve?.minimumReserveRate"
          type="number"
          [(ngModel)]="reserve.minimumReserveRate"
          [disabled]="isEditable"
        />
      </mat-form-field>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="optInStart">
    <mat-header-cell *matHeaderCellDef class="table-fields">Opt-In Start</mat-header-cell>
    <mat-cell *matCellDef="let reserve" class="table-fields">{{ reserve?.optInStart | date: 'mediumDate' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="optInEnd">
    <mat-header-cell *matHeaderCellDef class="table-fields">Opt-In End</mat-header-cell>
    <mat-cell *matCellDef="let reserve" class="table-fields">{{ reserve?.optInEnd | date: 'mediumDate' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef class="table-fields">Action</mat-header-cell>
    <mat-cell *matCellDef="let reserve" class="table-fields">
      <button mat-icon-button (click)="editAction()">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-raised-button [disabled]="isLoadingResult" *ngIf="!isEditable" color="accent" (click)="submit(reserve)">Save</button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>
</mat-table>
