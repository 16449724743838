<div class="events-filters-header">
  <form [formGroup]="filters" #formFilters>
    <!-- <mat-form-field>
      <mat-select placeholder="Type" formControlName="type" multiple>
        <mat-option *ngFor="let statusItem of eventTypesSelect | async" [value]="statusItem">
          {{ statusItem }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <!-- <mat-form-field>
      <input matInput placeholder="Created after" aria-label="Created after" [matDatepicker]="afterPicker"
        formControlName="created_after" (click)="afterPicker.open()" />
      <mat-icon matSuffix [hidden]="!getFieldValue('created_after')"
        (click)="clearFieldValue('created_after'); $event.preventDefault(); $event.stopPropagation()">
        close
      </mat-icon>
      <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
      <mat-datepicker #afterPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Created before" aria-label="Created before" [matDatepicker]="beforePicker"
        formControlName="created_before" (click)="beforePicker.open()" />
      <mat-icon matSuffix [hidden]="!getFieldValue('created_before')"
        (click)="clearFieldValue('created_before'); $event.preventDefault(); $event.stopPropagation()">
        close
      </mat-icon>
      <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
      <mat-datepicker #beforePicker></mat-datepicker>
    </mat-form-field> -->

    <mat-form-field>
      <input matInput placeholder="eventTypeCode" aria-label="search" [formControl]="search" />
      <mat-icon matSuffix [hidden]="!getFieldValue('search')" (click)="clearSearch(); $event.preventDefault(); $event.stopPropagation()">
        close
      </mat-icon>
    </mat-form-field>
  </form>
</div>
