import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PasswordMatchValidator } from '../../../shared/forms/validators/password-match-validator';
import { SupplierService } from '../../services/supplier.service';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-supplier-change-password-dialog',
  templateUrl: './supplier-change-password-dialog.component.html',
})
export class SupplierChangePasswordDialogComponent extends SubscriberComponent implements OnInit {
  passwordForm: FormGroup;
  private supplierKey: string;

  constructor(
    public dialogRef: MatDialogRef<SupplierChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private supplierService: SupplierService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data.supplierKey) {
      throw new Error('Missing required supplierKey');
    }

    this.supplierKey = this.data.supplierKey;
    this.createForm();
  }

  createForm(): void {
    this.passwordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        retypePassword: ['', Validators.required],
      },
      { validator: PasswordMatchValidator.MatchPassword },
    );
  }

  savePasswordFormGroup(): void {
    const valid = this.passwordForm.valid;
    const value = this.passwordForm.getRawValue();

    if (valid) {
      this.subscriptions.push(
        this.supplierService.setNewPassword(this.supplierKey, value.password).subscribe(
          (result) => {
            this.dialogRef.close(result);
          },
          (error) => {
            // TODO nkler: silent sentry log here?
            this.dialogRef.close();
          },
        ),
      );
    } else {
      throw new Error('INVALID FORM GROUP');
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  get showErrorPassMsg(): boolean {
    const retypePasswordField = this.passwordForm && this.passwordForm.get('retypePassword');

    return retypePasswordField && retypePasswordField.touched && retypePasswordField.hasError('MatchPassword');
  }
}
