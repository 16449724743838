<h1 mat-dialog-title>{{ actionTitle }}</h1>

<div class="confirmation" class="confirmation" *ngIf="isSaved">
  {{ confirmationMessage }}
</div>

<div [formGroup]="supplierActionForm" *ngIf="showForm">
  <mat-form-field class="full-width">
    <mat-select placeholder="File type" formControlName="file_type">
      <mat-option [value]="'credits_csv'"> Credits CSV </mat-option>
      <mat-option [value]="'due_csv'"> Due CSV </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <app-input-file formControlName="file" valuePlaceholder="No file selected" required></app-input-file>
    <mat-icon matSuffix>folder</mat-icon>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="picker" placeholder="Post date" formControlName="post_date" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="startPicker" placeholder="Start date" formControlName="start_date" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
    <mat-datepicker #startPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="endPicker" placeholder="End date" formControlName="end_date" />
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
  </mat-form-field>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="showForm && !isSaved">
  <button mat-raised-button (click)="save()" tabindex="2" color="accent">Upload</button>
  <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="isSaved">
  <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Yes</button>
  <button mat-raised-button (click)="editForm()" tabindex="-1" color="warn">No</button>
</div>

<div *ngIf="isSuccess">
  <div class="alert alert-success">
    {{ successMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Close</button>
  </div>
</div>

<div *ngIf="isError">
  <div class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Close</button>
  </div>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
