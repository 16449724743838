import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IAdvanceLineData } from '../../active-advance/models/advance-line-data.interface';

@Component({
  selector: 'app-approved-advance-lines-table',
  templateUrl: './approved-advance-lines-table.component.html',
})
export class ApprovedAdvanceLinesTableComponent implements OnInit {
  @Input() record: IAdvanceLineData;
  @Input() rowIndex: number;
  @Input() columns: string[];
  @Output() onAdvanceLineKeyClick = new EventEmitter();
  @Output() onReUnderwriteClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  advanceLineKeyClick(key: string) {
    this.onAdvanceLineKeyClick.emit(key);
  }

  reUnderwriteClick(setting: IAdvanceLineData) {
    this.onReUnderwriteClick.emit(setting);
  }
}
