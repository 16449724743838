import { autoserialize } from 'cerialize';

export class CrmUserModel {
  @autoserialize public loginKey?: string;
  @autoserialize public email: string;

  constructor(email: string, loginKey?: string) {
    this.loginKey = loginKey;
    this.email = email;
  }
}
