/* eslint-disable */
export const AMAZON_SELLER_CENTRAL = {
  loginFormSelector: '#ap_signin_form',
  passInputSelector: '#ap_password',
  userInputSelector: '#ap_email',
  otpInputSelector: '#auth-mfa-otpcode',
  url: 'https://sellercentral.amazon.com/ap/signin?_encoding=UTF8&language=en_US&openid.assoc_handle=sc_na_amazon&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.mode=checkid_setup&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.ns.pape=http%3A%2F%2Fspecs.openid.net%2Fextensions%2Fpape%2F1.0&openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fsellercentral.amazon.com%2Fgp%2Fhomepage.html%3Fie%3DUTF8%26*Version*%3D1%26*entries*%3D0&pageId=sc_na_amazon',
};

export const TOP_HATTER_MARKETPLACE = {
  requiredVersion: '>=1.9.3',
  passInputSelector: '',
  userInputSelector: '',
  loginFormButtonSelector: '',
  randomDelayFrom: 800,
  randomDelayTo: 1600,
  url: 'https://tophatter.com/sign_in',
  multistep: [
    {
      recognizeSiteBy: 'button[data-e2e=email-sign-in-button]',
      passInputSelector: '',
      userInputSelector: '',
      nextButtonSelector: `button[data-e2e=email-sign-in-button]`,
    },
    {
      recognizeSiteBy: 'input[type=password]',
      passInputSelector: `input[type=password]`,
      userInputSelector: `input[type=email]`,
      nextButtonSelector: 'button.btn-primary',
    },
  ],
};

export const WALMART_MARKETPLACE = {
  delay: 1800,
  clickDelay: 2200,
  randomDelayFrom: 500,
  randomDelayTo: 1300,
  passInputSelector: 'input[data-automation-id=pwd]',
  userInputSelector: 'input[data-automation-id=uname]',
  loginFormButtonSelector: 'button[data-automation-id=loginBtn]',
  isWalmart: true,
  url: 'https://seller.walmart.com/',
};

export const PAYONEER_MARKETPLACE = {
  requiredVersion: '>=1.4.1',
  loginFormSelector: '.loginForm form',
  passInputSelector: 'input[type=password]',
  userInputSelector: '#username',
  loginFormButtonSelector: '#login_button',
  url: 'https://login.payoneer.com/',
  delay: 1200,
};

export const SHOPIFY_MARKETPLACE = {
  requiredVersion: '>=1.4.0',
  loginFormSelector: '',
  passInputSelector: '',
  userInputSelector: '',
  delay: 1800,
  clickDelay: 2200,
  randomDelayFrom: 500,
  randomDelayTo: 1300,
  shouldClickTwice: true,
  multistep: [
    {
      recognizeSiteBy: '#shop_domain',
      shopDomain: '#shop_domain',
      passInputSelector: '',
      userInputSelector: '',
      nextButtonSelector: `button[name='commit']`,
    },
    {
      recognizeSiteBy: '.changeable-label__text',
      passInputSelector: '#account_password',
      userInputSelector: '',
      nextButtonSelector: 'button',
    },
    {
      recognizeSiteBy: '#account_email',
      passInputSelector: '',
      userInputSelector: '#account_email',
      nextButtonSelector: 'button',
    },
  ],
  url: 'https://accounts.shopify.com/store-login',
};

export const MORE_COMMERCE_MARKETPLACE = {
  requiredVersion: '>=1.6.0',
  loginFormSelector: '',
  passInputSelector: '#password',
  userInputSelector: '#email',
  loginFormButtonSelector: '#auth-sign-in-button',
  url: 'https://www.morecommerce.com/login',
  delay: 1500,
  clickDelay: 1000,
};

export const EBAY_MARKETPLACE = {
  requiredVersion: '>=1.6.0',
  passInputSelector: '',
  userInputSelector: '',
  loginFormButtonSelector: '',
  delay: 500,
  clickDelay: 3000,
  url: 'https://signin.ebay.com/ws/eBayISAPI.dll?SignIn&ru=https%3A%2F%2Fwww.ebay.com%2F',
  multistep: [
    {
      recognizeSiteBy: '.hide #pass',
      passInputSelector: ``,
      userInputSelector: `#userid`,
      nextButtonSelector: '#signin-continue-btn',
    },
    {
      recognizeSiteBy: '.hide #userid',
      passInputSelector: `#pass`,
      userInputSelector: ``,
      nextButtonSelector: '#sgnBt',
    },
  ],
};

export const VOLUSION_MARKETPLACE = {
  requiredVersion: '>=1.7.0',
  passInputSelector: `input[name='password']`,
  userInputSelector: `input[name='email']`,
  loginFormButtonSelector: '.btn_login',
  url: '*',
};

export const PAYPAL_MARKETPLACE = {
  requiredVersion: '>=1.8.0',
  randomDelayFrom: 100,
  randomDelayTo: 200,
  passInputSelector: ``,
  userInputSelector: ``,
  loginFormButtonSelector: ``,
  url: 'https://www.paypal.com/us/signin',
  shouldClickTwice: true,
  multistep: [
    {
      recognizeSiteBy: '#splitPassword',
      passInputSelector: `input[name='login_password']`,
      userInputSelector: `input[name='login_email']`,
      nextButtonSelector: '#btnLogin',
    },
    {
      recognizeSiteBy: '#btnLogin',
      passInputSelector: `input[name='login_password']`,
      userInputSelector: `input[name='login_email']`,
      nextButtonSelector: '#btnLogin',
    },
  ],
};

export const STRIPE_MARKETPLACE = {
  requiredVersion: '>=1.9.0',
  randomDelayFrom: 100,
  randomDelayTo: 200,
  clickDelay: 1000,
  passInputSelector: `input[name='password']`,
  userInputSelector: `input[name='email']`,
  loginFormButtonSelector: `button[type='submit']`,
  url: 'https://dashboard.stripe.com/login',
  shouldClickTwice: true,
  multistep: [
    {
      recognizeSiteBy: `input[name='email']`,
      passInputSelector: `input[name='password']`,
      userInputSelector: `input[name='email']`,
      nextButtonSelector: 'p.submit',
    },
  ],
};

export const AUTHORIZE_MARKETPLACE = {
  requiredVersion: '>=1.9.0',
  randomDelayFrom: 1100,
  randomDelayTo: 1200,
  passInputSelector: "input[name='input-password']",
  userInputSelector: "input[name='input-username']",
  loginFormButtonSelector: `button.button-submit`,
  url: 'https://account.authorize.net/UI/themes/anet/Logon.aspx',
};

export const NEWEGG_MARKETPLACE = {
  requiredVersion: '>=1.9.0',
  randomDelayFrom: 1100,
  randomDelayTo: 1200,
  passInputSelector: '',
  userInputSelector: '',
  loginFormButtonSelector: ``,
  url: 'https://sellerportal.newegg.com/',
  iframeRedirection: {
    iframeLinkPart: 'https://secure.newegg.com/identity/signin',
  },
  multistep: [
    {
      recognizeSiteBy: '#labeled-input-signEmail',
      passInputSelector: ``,
      userInputSelector: `input[name='signEmail']`,
      nextButtonSelector: '#signInSubmit',
    },
    {
      recognizeSiteBy: '#labeled-input-password',
      passInputSelector: `input[name='password']`,
      userInputSelector: ``,
      nextButtonSelector: '#signInSubmit',
    },
  ],
};

export const PINGPONG_MARKETPLACE = {
  requiredVersion: '>=1.9.0',
  passInputSelector: "input[aria-label='Password']",
  userInputSelector: "input[aria-label='Email']",
  loginFormButtonSelector: `div.form-submit > button`,
  shouldClickTwice: true,
  url: 'https://business.pingpongx.com/entrance/signin',
  delay: 1500,
  clickDelay: 500,
};
