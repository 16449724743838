import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { DIALOG_SIZE } from '../../../../../shared/dialog.config';
import { CrmStates } from '../../../../../shared/layout/layout.enums';
import { AppState } from '../../../../../store/app.reducers';
import { SupplierService } from '../../../../services/supplier.service';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { PendingOfferRiskRatingService } from '../../pending-offer/pending-offer-calculator/service/pending-offer-risk-rating.service';
import {
  CalculatorHardReset,
  SetDefaultProgressiveAdvanceSettings,
  SetUnderwritingRiskRating,
} from '../../pending-offer/pending-offer-calculator/store/calculator.actions';
import { IReofferReserveAndNetAmount } from '../../pending-offer/pending-offer-calculator/store/calculator-interfaces';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from './../../../../../shared/confirm-dialog/confirm-dialog.component';
import { SupplierAdvancesService } from './../../service/supplier-advances.service';
import { PayAdvanceComponent } from './pay-advance/pay-advance.component';

const UpdateOfferMessage = 'Offer has been successfully updated.';

@Component({
  selector: 'app-supplier-advance-details',
  templateUrl: './active-advance-details.component.html',
  styleUrls: ['./active-advance-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveAdvanceDetailsComponent extends SubscriberComponent implements OnInit {
  editAdvanceForm: FormGroup;
  crmPanelStates = CrmStates;
  isActive = false;
  isExpired = false;
  isRepaid = false;
  isPending = false;
  isLoading = true;
  isEligible = false;
  tempShowButton = false;
  noActiveAdvance = false;

  private advanceKey: string;
  private supplierKey: string;

  private totalFee: number;

  public advanceReoffer: any;
  public riskRating: number;
  public reserveForRepayment: number;

  public get createNewAdvance(): boolean {
    return this.advanceKey === 'new' ? true : false;
  }

  public delinquentAdvanceIsEnabled: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly supplierService: SupplierService,
    private readonly toastrService: ToastrService,
    private readonly dialog: MatDialog,
    private readonly supplierAdvancesService: SupplierAdvancesService,
    private readonly pendingOfferRiskRatingService: PendingOfferRiskRatingService,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
          mergeMap(() => this.route.params),
        )
        .subscribe((params) => {
          this.advanceKey = params['advanceKey'];

          this.setupComponent();
          this.initSummaryTable();
        }),
    );
  }

  private initSummaryTable() {
    this.store.dispatch(new CalculatorHardReset());
    this.subscriptions.push(
      this.pendingOfferRiskRatingService.getDefaultProgressiveAdvanceSettings().subscribe((data) => {
        this.store.dispatch(new SetDefaultProgressiveAdvanceSettings(data));
      }),
    );

    this.subscriptions.push(this.getCalculatorData());
    this.subscriptions.push(this.getMlOffer());
  }

  private getCalculatorData() {
    return this.store.select('calc').subscribe((calcValue) => {
      this.totalFee = calcValue.totalFee;
      this.reserveForRepayment = calcValue.reserveForRepayments;
      this.editAdvanceForm.patchValue(this.calcReserveAndNetAmount());
      this.advanceReoffer = {
        periods: calcValue.periods,
      };
    });
  }

  private getMlOffer() {
    return this.supplierAdvancesService.getMlOffer(this.supplierKey).subscribe((eligibilityData) => {
      if (eligibilityData) {
        this.riskRating = eligibilityData.ranking;
        this.store.dispatch(new SetUnderwritingRiskRating(eligibilityData.riskRating));
        this.getData();
      }
    });
  }

  private calcReserveAndNetAmount(): IReofferReserveAndNetAmount {
    if (this.totalFee > 0) {
      return {
        netDeliveryAmount: +Number(this.editAdvanceForm.get('reofferAmount').value * (1 - 0.01 * this.totalFee)).toFixed(2),
        reofferReserve: +Number(this.editAdvanceForm.get('reofferAmount').value * this.totalFee * 0.01).toFixed(2),
      };
    }
    return {
      netDeliveryAmount: +Number(this.editAdvanceForm.get('reofferAmount').value).toFixed(2),
      reofferReserve: 0,
    };
  }

  private calcNetDeliveryRequested(): any {
    return {
      netDeliveryRequested: +this.editAdvanceForm.get('reofferAmount').value - this.editAdvanceForm.get('advanceLiability').value,
    };
  }

  public colorNetDeliveryRequested(): string {
    const value = this.editAdvanceForm.get('netDeliveryRequested').value;
    if (value < 0) {
      return 'red';
    }
    return value > 0 ? 'green' : 'black';
  }

  deleteAdvance() {
    this.isLoading = true;

    this.subscriptions.push(
      this.supplierService.deleteAdvance(this.supplierKey, this.advanceKey).subscribe(
        () => {
          this.toastrService.success('Advance info has been successfully deleted.');
          this.router.navigateByUrl(`/suppliers/${this.supplierKey}/advances/list`);
        },
        (err) => {
          this.isLoading = false;
          this.toastrService.error(err.message, null, { timeOut: 3000 });
        },
      ),
    );
  }

  hasMinError = () => this.editAdvanceForm && this.editAdvanceForm.get('reofferAmount').hasError('min');

  hasRequiredError = () => this.editAdvanceForm && this.editAdvanceForm.get('reofferAmount').hasError('required');

  isValid = () => this.editAdvanceForm.valid;

  payAdvanceOnClick(): void {
    const dialogRef = this.dialog.open(PayAdvanceComponent, {
      data: {
        supplierKey: this.supplierKey,
        advanceKey: this.advanceKey,
      },
      width: DIALOG_SIZE.SMALL.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigateByUrl(`/suppliers/${this.supplierKey}/advances`);
        }
      }),
    );
  }

  private openConfirmDialog() {
    const msg = 'Are you sure you want to proceed?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL3.width,
    });
    return dialogRef;
  }

  requestOfferOnClick(): void {
    this.subscriptions.push(
      this.openConfirmDialog()
        .afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.requestOffer();
          }
        }),
    );
  }

  delinquentAdvanceOnClick(): void {
    this.subscriptions.push(
      this.openConfirmDialog()
        .afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            return this.requestUpdateDelinquency();
          }
          this.editAdvanceForm.get('delinquentAdvance').setValue(!this.editAdvanceForm.get('delinquentAdvance').value);
        }),
    );
  }

  private requestUpdateDelinquency() {
    this.supplierService
      .postDelinquencyStatus(this.supplierKey, this.advanceKey, {
        delinquency_status: this.editAdvanceForm.get('delinquentAdvance').value,
      })
      .subscribe(
        () => {
          this.toastrService.success('Delinquency status successfully updated');
        },
        (err) => {
          this.toastrService.error(err.message);
        },
      );
  }

  saveAdvanceReserve() {
    const msg = `Changing the reserve for payment will allocate additional funds for the next Advance repayment
                 and may result in a negative available balance. Are you sure you want to proceed?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL2.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.submitAdvanceReserve();
        }
      }),
    );
  }

  saveStartReserve() {
    const data = {
      startReservePct: +this.editAdvanceForm.value.startReservePercent,
    };

    this.subscriptions.push(
      this.supplierService.patchAdvance(this.supplierKey, this.advanceKey, data).subscribe(
        () => {
          this.toastrService.success(UpdateOfferMessage);
        },
        (err) => {
          this.toastrService.error(err.message);
        },
      ),
    );
  }

  saveAdvanceTotal() {
    const msg = 'Advance total amount will be changed. Are you sure you want to proceed?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL2.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.submitAdvanceTotal();
        }
      }),
    );
  }

  saveExpirationDate() {
    const msg = `Changing the expiration date will cause the supplier to see this advance on the supplier dashboard.
                 Are you sure you want to proceed?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL2.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.submitExpirationDate();
        }
      }),
    );
  }

  saveExtraReservePercent() {
    const msg = 'Reserve for repayments will be changed. Are you sure you want to proceed?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL2.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.submitExtraReservePercent();
        }
      }),
    );
  }

  private submitExpirationDate() {
    const data = {
      expirationDate: moment(this.editAdvanceForm.value.expirationDate).format('YYYY-MM-DD'),
    };

    this.subscriptions.push(
      this.supplierService.patchAdvance(this.supplierKey, this.advanceKey, data).subscribe(
        () => {
          this.toastrService.success(UpdateOfferMessage);
        },
        (err) => {
          this.toastrService.error(err.message);
        },
      ),
    );
  }

  private submitAdvanceReserve() {
    const data = {
      advanceReservePercent: +this.editAdvanceForm.value.advanceReservePercent,
      reserveTotalAmount: +this.editAdvanceForm.value.reserveTotalAmount,
    };

    this.subscriptions.push(
      this.supplierService.patchAdvance(this.supplierKey, this.advanceKey, data).subscribe(
        () => {
          this.toastrService.success(UpdateOfferMessage);
        },
        (err) => {
          this.toastrService.error(err.message);
        },
      ),
    );
  }

  private submitExtraReservePercent() {
    const data = {
      extraReservePercent: +this.editAdvanceForm.value.extraReservePercent,
    };

    this.subscriptions.push(
      this.supplierService.patchAdvance(this.supplierKey, this.advanceKey, data).subscribe(
        () => {
          this.toastrService.success(UpdateOfferMessage);
        },
        (err) => {
          this.toastrService.error(err.message);
        },
      ),
    );
  }

  private submitAdvanceTotal() {
    const data = {
      advanceTotalAmount: +this.editAdvanceForm.value.advanceTotalAmount,
      reserveTotalAmount: +this.editAdvanceForm.value.reserveTotalAmount,
    };

    this.subscriptions.push(
      this.supplierService.patchAdvance(this.supplierKey, this.advanceKey, data).subscribe(
        () => {
          this.toastrService.success(UpdateOfferMessage);
        },
        (err) => {
          this.toastrService.error(err.message);
        },
      ),
    );
  }

  private setupComponent(): void {
    this.editAdvanceForm = this.fb.group({
      // Advance Details
      advanceStatus: [{ value: null }],
      weeksSinceOrigination: [{ value: null }],
      originationDate: [{ value: null }],
      advanceTotalAmount: [{ value: null }, [Validators.required]],
      advanceReservePercent: [{ value: null }],
      startReservePercent: [{ value: null }],
      reserveTotalAmount: [{ value: null }],
      netDistribution: [{ value: null }],

      feePercent: [{ value: null }],
      totalFeesCharged: [{ value: null }],
      earlyPaymentRebate: [{ value: null }],

      advanceLiability: [{ value: null }],
      advanceOutstanding: [{ value: null }],
      maturityDate: [{ value: null }],

      // Payment Details
      extraReservePercent: [null, [Validators.required]],
      repaidAmount: [{ value: null }],
      extraReserveAmount: [{ value: null }],
      lastPaymentDate: [{ value: null }],
      lastPaymentAmount: [{ value: null }],

      approvedByTreasury: [null],
      viewedBySupplier: [null],
      supplierSavedForLater: [null],
      supplierRejected: [null],

      expirationDate: [null],

      delinquentAdvance: [null],

      // Re-Offer Eligiblity
      eligibleForReoffer: [{ value: null }],
      pacingStatus: [{ value: null }],
      reofferAmount: [{ value: null }],
      reofferReserve: [{ value: null }],
      netDeliveryAmount: [{ value: null }],
      outstandingLiability: [{ value: null }],
      riskRating: [{ value: null }],
      reserveForRepayment: [{ value: null }],
      gracePeriodEndDate: [{ value: null }],
      gracePeriodLength: [{ value: null }],
      noRebate: [{ value: null }],
      netDeliveryRequested: [{ value: null }],
      returnStrategy: [{ value: null }],
      forceFull: [{ value: null }],
    });

    this.editAdvanceForm.get('advanceTotalAmount').valueChanges.subscribe(() => {
      this.editAdvanceForm.patchValue({
        reserveTotalAmount: +Number(
          (this.editAdvanceForm.get('advanceReservePercent').value / 100) * this.editAdvanceForm.get('advanceTotalAmount').value,
        ).toFixed(2),
      });
    });
    this.editAdvanceForm.get('advanceReservePercent').valueChanges.subscribe(() => {
      this.editAdvanceForm.patchValue({
        reserveTotalAmount: +Number(
          (this.editAdvanceForm.get('advanceReservePercent').value / 100) * this.editAdvanceForm.get('advanceTotalAmount').value,
        ).toFixed(2),
      });
    });
  }

  private getData(): void {
    this.subscriptions.push(
      this.supplierService.getAdvance(this.supplierKey, this.advanceKey).subscribe((data) => {
        if (data) {
          this.setAdvanceStatuses(data);

          this.editAdvanceForm.patchValue({
            advanceStatus: data.advanceStatus,
            weeksSinceOrigination: data.weeksSinceOrigination,
            originationDate: data.originationDate,
            advanceTotalAmount: data.advanceTotalAmount,
            advanceReservePercent: data.advanceReservePercent,
            startReservePercent: data.startReservePct,
            reserveTotalAmount: data.reserveTotalAmount,
            netDistribution: data.netDistribution,
            feePercent: data.feePercent,
            totalFeesCharged: data.totalFeesCharged,
            earlyPaymentRebate: data.earlyPaymentRebate,
            advanceLiability: data.advanceLiability,
            advanceOutstanding: data.advanceOutstanding,
            maturityDate: data.maturityDate,
            extraReservePercent: data.extraReservePercent,
            repaidAmount: data.repaidAmount,
            extraReserveAmount: data.extraReserveAmount,
            lastPaymentDate: data.lastPaymentDate,
            lastPaymentAmount: data.lastPaymentAmount,
            approvedByTreasury: data.approvedByTreasury,
            viewedBySupplier: data.viewedBySupplier,
            supplierSavedForLater: data.supplierSavedForLater,
            supplierRejected: data.supplierRejected,
            expirationDate: data.expirationDate,
            riskRating: this.riskRating,
            reserveForRepayment: this.reserveForRepayment,
            delinquentAdvance: data.delinquent,
            gracePeriodEndDate: data.gracePeriodEndDate,
            gracePeriodLength: data.gracePeriodLength,
            noRebate: data.noRebate,
          });

          this.editAdvanceForm.get('advanceTotalAmount').setValidators([Validators.required, Validators.max(data.advanceTotalAmount)]);

          this.getReofferData();

          this.delinquentAdvanceIsEnabled = data.active;
        }
      }),
    );
  }

  private setAdvanceStatuses(data) {
    this.isActive = data.advanceStatus === 'Active';
    this.isRepaid = data.advanceStatus === 'Repaid';
    this.isExpired = data.advanceStatus === 'Offer Expired';
    this.isPending = data.advanceStatus === 'Offer Pending';
  }

  private getReofferData() {
    this.resetData();
    this.subscriptions.push(
      this.supplierAdvancesService.getReofferData(this.supplierKey).subscribe(
        (reofferData) => {
          this.isLoading = false;

          if (reofferData) {
            this.editAdvanceForm.patchValue({
              eligibleForReoffer: reofferData.eligibleForReoffer ? 'Yes' : 'No',
              pacingStatus: +Number(reofferData.pacingStatus).toFixed(2),
              reofferAmount: reofferData.reofferAmount,
              outstandingLiability: reofferData.outstandingLiability,
            });
            this.patchAdvanceAfterReofferAmmountChange();
            this.editAdvanceForm
              .get('reofferAmount')
              .setValidators([Validators.required, Validators.min(reofferData.outstandingLiability)]);
            this.isEligible = reofferData.eligibleForReoffer;
          }

          this.editAdvanceForm.get('reofferAmount').valueChanges.subscribe(() => {
            this.patchAdvanceAfterReofferAmmountChange();
          });
        },
        () => {
          this.isLoading = false;
          this.noActiveAdvance = true;
        },
      ),
    );
  }

  resetData() {
    this.noActiveAdvance = false;
  }

  public isRequestDisabled(): boolean {
    return this.editAdvanceForm.get('reofferAmount').invalid;
  }

  private patchAdvanceAfterReofferAmmountChange() {
    this.editAdvanceForm.patchValue(this.calcReserveAndNetAmount());
    this.editAdvanceForm.patchValue(this.calcNetDeliveryRequested());
  }

  private requestOffer(): void {
    let data = {};

    this.store.select('calc').subscribe((calcValue) => {
      data = {
        ...this.pendingOfferRiskRatingService.prepareDataToSave(calcValue),
        requested_amount: +this.editAdvanceForm.get('reofferAmount').value,
        requested_reserve_total_amount: +this.editAdvanceForm.get('reofferReserve').value,
        riskRating: this.riskRating,
        refinOfSupAdvKey: this.advanceKey,
      };
    });

    this.subscriptions.push(
      this.supplierAdvancesService.postReoffer(this.supplierKey, data).subscribe(
        () => {
          this.toastrService.success('Reoffer has been activated successfully.');
        },
        (response) => {
          this.toastrService.error(response.error.message);
        },
      ),
    );
  }
}
