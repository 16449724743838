import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { GridOptions } from 'ag-grid-community';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { marketplaceTypes } from '../../../../marketplaces/service/gateway.service';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { CsvExportService } from '../../../../shared/csv-export-service/csv-export.service';
import { DataTableQueryParams } from '../../../../shared/data-table/data-table.common';
import { ColumnPredef, IColumnPredefinition } from '../../../../shared/data-table-grid/models/default-column-definition';
import { IPaginationConfiguration } from '../../../../shared/pyb-pagination/pagination-configuration';
import { IPaginationData } from '../../../../shared/pyb-pagination/pagination-data';
import { AppState } from '../../../../store/app.reducers';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { SupplierMarketplaceListModel } from '../../../model/supplier-marketplace.model';
import { SupplierService } from '../../../services/supplier.service';
import { FetchSupplierMarketplaces, selectSupplierDetails, selectSupplierMarketplaces } from '../../../store/supplier/supplier.actions';
import { HeaderTemplateRendererService } from './../../../../shared/data-table-ag-grid/data-table-ag-grid-header-template-renderer.service';

@Component({
  selector: 'app-daily-summary-report',
  templateUrl: './daily-summary-report.component.html',
  styleUrls: ['./daily-summary-report.component.scss'],
})
export class DailySummaryReportComponent extends SubscriberComponent implements OnInit {
  public marketplaceList: SupplierMarketplaceListModel[];
  public filterForm: FormGroup;
  public supplierKey: string;
  public baseHttpParams: any;
  public paginationData?: IPaginationData;
  public report: any;
  public total: number;
  public filterConfig: any;
  private defaultPageSize = 1000000;
  private pageNumber = 0;
  public paginationConfiguration: IPaginationConfiguration = {
    maxPagesShown: 10,
    perPageOptions: [5, 10, 20, 50],
    defaultPerPage: this.defaultPageSize,
    selectedPerPage: this.defaultPageSize,
  };

  columnsPredefinitions: IColumnPredefinition[] = [
    new ColumnPredef('xact_post_date', 'Post Date (xact_post_date)', false, (params) => {
      return params.value;
    }),
    new ColumnPredef('receivable', 'Receivables', true),
    new ColumnPredef('chargeback', 'Chargebacks', true),
    new ColumnPredef('net_earning', 'Net Earnings', true),
    new ColumnPredef('purchase', 'Receivables Purchased', true),
    new ColumnPredef('repurchase', 'Re-purchase', true),
    new ColumnPredef('marketplace_payment', 'Marketplace Payment', true),
    new ColumnPredef('fees', 'Payability Fee', true),
    new ColumnPredef('freemium', 'Fee Rebate', true),
    new ColumnPredef('supplier_payment', 'Supplier Transfer', true),
    new ColumnPredef('card_hold', 'Card Hold', true),
    new ColumnPredef('card_charge', 'Card Charge', true),
    new ColumnPredef('available_balance', 'Available Balance', false),
    new ColumnPredef('reserve_balance', 'Reserve Balance', false),
    new ColumnPredef('due_from_marketplace', 'Due From Marketplace', false),
    new ColumnPredef('due_to_supplier', 'Due To Supplier', false),
    new ColumnPredef('outstanding_balance', 'Outstanding Balance', false),
    new ColumnPredef('sup_adv_liability', 'Advance Liability', true),
    new ColumnPredef('potential_liability', 'Potential Liability', false),
  ];
  testReport: any;
  dataRows: any;
  summed: any;
  customGridOptions: GridOptions;
  supplierDetails: SupplierDetailsModel;

  public get pageMin(): number {
    return this.pageNumber * this.defaultPageSize;
  }

  public get pageMax(): number {
    const value = (this.pageNumber + 1) * this.defaultPageSize;
    return value > this.total ? this.total : value;
  }

  public get canNavigateBack(): boolean {
    return this.pageNumber > 0;
  }

  public get canNavigateNext(): boolean {
    return (this.pageNumber + 1) * this.defaultPageSize < this.total;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly route: ActivatedRoute,
    private readonly supplierService: SupplierService,
    private readonly csvExportService: CsvExportService,
    private readonly store: Store<AppState>,
    private readonly headerTemplateRendererService: HeaderTemplateRendererService,
  ) {
    super();
  }

  ngOnInit() {
    this.customGridOptions = {
      headerHeight: 75,
    };

    this.baseHttpParams = {
      limit: this.defaultPageSize,
      offset: this.pageNumber * this.defaultPageSize,
      order_by: '-xact_post_date',
    };

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierDetails = data;
          this.supplierKey = data.supplierKey;
          this.getData();
        }),
    );

    this.initForm();
    this.getMarketplacesForSupplier();
  }

  private getMarketplacesForSupplier(): void {
    this.store.select(selectSupplierMarketplaces).subscribe((marketplacesAndGateways) => {
      if (marketplacesAndGateways) {
        this.marketplaceList = marketplacesAndGateways.filter((marketplaceOrGateway) => {
          return marketplaceTypes.STANDARD_MARKETPLACE === marketplaceOrGateway.marketplaceType;
        });
        this.prepareFilterConfig();
      }
    });
  }

  private initForm(): void {
    this.filterForm = this.fb.group({
      startDate: [''],
      endDate: [''],
      selectedMarketplace: [''],
    });
  }

  private transformValueToLink(elem: any): any {
    const formattedValueForDisplay = this.datePipe.transform(elem, 'MM/dd/yyyy');
    const formattedValueForLink = this.datePipe.transform(elem, 'yyyy-MM-dd');
    const url = location.hash.replace('transaction-summary', 'transaction-details?date=' + formattedValueForLink);
    return `<a href="${url}">${formattedValueForDisplay}</a>`;
  }

  private setCommonKeys(elem: any): any {
    return {
      receivable: elem.receivable,
      purchase: elem.purchase,
      chargeback: elem.chargeback,
      net_earning: elem.net_earning,
      marketplace_payment: elem.marketplace_payment,
      fees: elem.fees,
      supplier_payment: elem.supplier_payment,
      card_hold: elem.card_hold,
      card_charge: elem.card_charge,
      available_balance: elem.available_balance,
      reserve_balance: elem.reserve_balance,
      due_from_marketplace: elem.due_from_marketplace,
      due_to_supplier: elem.due_from_supplier,
      outstanding_balance: elem.outstanding_balance,
      sup_adv_liability: elem.sup_adv_liability,
      potential_liability: elem.potential_liability,
    };
  }

  private prepareDataRows(results: any): any {
    if (!results) {
      return false;
    }

    return results.map((elem) => {
      return {
        dataPath: [elem.xact_post_date],
        xact_post_date: this.transformValueToLink(elem.xact_post_date),
        ...this.setCommonKeys(elem),
        __children: elem.marketplaces || elem.non_mp ? this.prepareNestedDataSource(elem.marketplaces, elem.non_mp) : [],
      };
    });
  }

  private prepareNestedDataSource(marketplaces: any, nonMarketplace: any): any {
    const arr = [];
    for (const marketplace of marketplaces) {
      if (marketplace) {
        arr.push({
          xact_post_date: marketplace.marketplace_name,
          ...this.setCommonKeys(marketplace),
        });
      }
    }
    if (nonMarketplace) {
      arr.push({
        xact_post_date: 'No Marketplace Matched',
        ...this.setCommonKeys(nonMarketplace),
      });
    }
    return arr;
  }

  public clearFilters(): void {
    this.filterForm.reset();
    this.pageNumber = 0;
    this.deleteFilterParams();
    this.setBaseParams();
    this.getData();
  }

  public exportToCsv(): void {
    delete this.baseHttpParams['limit'];
    delete this.baseHttpParams['offset'];
    delete this.baseHttpParams['order_by'];
    this.subscriptions.push(
      this.supplierService
        .getSupplierReport(this.supplierKey, 'transaction_summary', {
          make_dump: true,
          fetchAll: true,
          ...this.baseHttpParams,
        })
        .subscribe((data) => {
          this.csvExportService.exportTransactions(data, 'transaction-summary', '');
          this.setBaseParams();
        }),
    );
  }

  public prepareMarketPlaceSelectOptions() {
    return this.marketplaceList.map((marketplace) => {
      return { label: marketplace.marketplaceName, id: marketplace.mpSupKey };
    });
  }

  public prepareFilterConfig() {
    this.filterConfig = [
      {
        fieldName: 'xact_post_date__gt',
        label: 'Start Date',
        fieldType: 'datePicker',
        isConnecting: true,
      },
      {
        fieldName: 'xact_post_date__lt',
        label: 'End Date',
        fieldType: 'datePicker',
      },
      {
        fieldName: 'marketplace',
        label: 'Marketplace',
        fieldType: 'select',
        options: this.prepareMarketPlaceSelectOptions(),
      },
    ];
  }

  public filterResults(): void {
    const filterParams = {
      xact_post_date__gt: this.filterForm.get('startDate').value,
      xact_post_date__lt: this.filterForm.get('endDate').value,
      marketplace_key: this.filterForm.get('selectedMarketplace').value,
    };
    for (const key in filterParams) {
      if (filterParams[key] === '' || isNullOrUndefined(filterParams[key])) {
        delete filterParams[key];
      }
    }
    this.deleteFilterParams();
    this.pageNumber = 0;
    this.setBaseParams();
    this.baseHttpParams = { ...this.baseHttpParams, ...filterParams };
    this.getData();
  }

  public getData(params = this.baseHttpParams): void {
    this.subscriptions.push(
      this.supplierService
        .getSupplierReport(this.supplierKey, 'transaction_summary', { ...this.baseHttpParams, ...params })
        .subscribe((result) => {
          if (!result) {
            return;
          }
          this.report = result;
          this.total = result.total;
          this.setPaginationData();
          this.summed = result.summed;
          const preDataSource = this.prepareDataRows(this.report.results);
          if (!preDataSource) {
            this.dataRows = [];
            return;
          }
          const reducer = (accumulator, currentValue) => {
            accumulator.push(currentValue);
            if (currentValue.__children && currentValue.__children.length) {
              const children = currentValue.__children.map((child) => {
                return {
                  dataPath: [...currentValue.dataPath, child.xact_post_date],
                  ...child,
                };
              });
              accumulator.push(...children);
            }
            return accumulator;
          };
          this.dataRows = preDataSource.reduce(reducer, []);
          this.columnsPredefinitions.forEach((e) => {
            if (this.report.summed[e.field]) {
              e.headerComponentParams = {
                template: this.headerTemplateRendererService.createTemplate({
                  value: this.report.summed[e.field],
                }),
              };
            }
          });

          if (!this.filterConfig) {
            this.store.dispatch(new FetchSupplierMarketplaces({ supplierDetailsModel: this.supplierDetails, refresh: true }));
          }
        }),
    );
  }

  setPaginationData() {
    this.paginationData = {
      ...this.baseHttpParams,
      total: this.total,
    };
  }

  public navigateNext(): void {
    this.pageNumber += 1;
    this.setBaseParams();
    this.getData();
  }

  public navigateBack(): void {
    this.pageNumber -= 1;
    this.setBaseParams();
    this.getData();
  }

  public navigateFirst(): void {
    this.pageNumber = 0;
    this.setBaseParams();
    this.getData();
  }

  public navigateLast(): void {
    this.pageNumber = Math.ceil(this.total / this.defaultPageSize) - 1;
    this.setBaseParams();
    this.getData();
  }

  private setBaseParams(): void {
    this.baseHttpParams.limit = this.defaultPageSize;
    this.baseHttpParams.offset = this.pageNumber * this.defaultPageSize;
    this.baseHttpParams.order_by = '-xact_post_date';
  }

  private deleteFilterParams(): void {
    delete this.baseHttpParams['xact_post_date__gt'];
    delete this.baseHttpParams['xact_post_date__lt'];
    delete this.baseHttpParams['marketplace_key'];
  }

  onPageChange(dataTableQueryParams: DataTableQueryParams): void {
    this.baseHttpParams = {
      ...dataTableQueryParams,
      order_by: dataTableQueryParams.order_by ? dataTableQueryParams.order_by : '-xact_post_date',
    };
    this.getData();
  }
}
