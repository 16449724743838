import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Observable, of } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';

import { EmailsStatusService } from '../../../services/emails-status.service';
import { taskStatuses } from '../../../tasks/task-statuses';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-email-grid-filters',
  templateUrl: './email-grid-filters.component.html',
  styleUrls: ['./email-grid-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmailGridFiltersComponent extends SubscriberComponent implements OnInit {
  @Output()
  filtersChange = new EventEmitter<any>();

  availableFilters: Observable<any[]>;

  filters: FormGroup;

  supplier_name = new FormControl();
  supplier_key = new FormControl();
  subject = new FormControl();
  createdAfter = new FormControl();
  createdBefore = new FormControl();

  constructor(private formBuilder: FormBuilder, private emailsStatusService: EmailsStatusService) {
    super();
  }

  ngOnInit() {
    this.availableFilters = of(this.emailsStatusService.getFiltersType());

    this.createForm();

    this.subscriptions.push(
      this.supplier_name.valueChanges.pipe(debounceTime(700), startWith('')).subscribe((value: string) => {
        this.filters.patchValue({
          supplier_name: value,
        });
      }),
    );

    this.subscriptions.push(
      this.supplier_key.valueChanges.pipe(debounceTime(700), startWith('')).subscribe((value: string) => {
        this.filters.patchValue({
          supplier_key: value,
        });
      }),
    );

    this.subscriptions.push(
      this.subject.valueChanges.pipe(debounceTime(700), startWith('')).subscribe((value: string) => {
        this.filters.patchValue({
          subject: value,
        });
      }),
    );

    this.filters.controls['type'].setValue(this.emailsStatusService.getDefaultFiltersType(), { onlySelf: true });

    this.subscriptions.push(
      this.filters.valueChanges.subscribe((filters) => {
        this.filtersChange.next(filters);
      }),
    );
  }

  private createForm() {
    this.filters = this.formBuilder.group({
      type: new FormControl(null),
      supplier_name: [''],
      supplier_key: [''],
      subject: [''],
      createdAfter: [''],
      createdBefore: [''],
    });
  }

  getStatusName(statusKey) {
    const foundItem = taskStatuses.find((item) => item.key === statusKey);
    if (foundItem) {
      return foundItem.name;
    }
  }

  clearValue(fieldKey, fieldFormToReset) {
    const fieldToReset = this.filters.get(fieldKey);
    if (fieldToReset) {
      fieldToReset.reset();
    }
    if (fieldFormToReset) {
      fieldFormToReset.reset();
    }
  }

  setDate(type: string, event: MatDatepickerInputEvent<Date>) {
    const newValues = {};
    newValues[type] = event.value;
    this.filters.patchValue(newValues);
  }

  clearDate(type: string, dateField) {
    const newValues = {};
    newValues[type] = undefined;
    this.filters.patchValue(newValues);
    dateField.reset();
  }
}
