import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';

import { AppConfigService } from '../../../config';
import { messageCodes, MessageService } from '../../../shared/message.service';
import { PybExtensionService } from '../pyb-extension/pyb-extension.service';
import { GetTokenResponse, SupplierService } from '../supplier.service';

@Injectable({
  providedIn: 'root',
})
export class LoginAsSupplierService {
  supplierKey: string;
  supplierUrl: string;
  private isInstalled;
  readonly link: string;

  constructor(
    private appConfig: AppConfigService,
    private messageService: MessageService,
    private supplierService: SupplierService,
    private pybExtService: PybExtensionService,
    private toastrService: ToastrService,
  ) {
    this.setSupplierUrl();
    this.checkWebExtension();
  }

  loginAsSupplier(supplierKey: string): void {
    if (!supplierKey) {
      this.toastrService.error('Supplier key is missing!');
      return;
    }
    this.setSupplierKey(supplierKey);
    this.checkWebExtension();

    if (this.isInstalled) {
      this.getToken(this.supplierKey);
    } else {
      this.toastrService.info(this.messageService.getMessageByCode(messageCodes.installExtension));
    }
  }

  setSupplierKey(supplierKey: string): void {
    this.supplierKey = supplierKey;
  }

  setSupplierUrl(): void {
    this.supplierUrl = this.appConfig.env.supplierUrl;
  }

  checkWebExtension(): void {
    this.pybExtService.isInstalled().then((response) => {
      this.isInstalled = response;
    });
  }

  isProduction(): boolean {
    return !!this.appConfig.env.production;
  }

  getSupplierSiteFromJwt(jwtToken: string): string {
    const encodedToken: any = jwt_decode(jwtToken);
    return encodedToken.supplier_site;
  }

  isSupplierSiteAvailable(supplierSite: string): boolean {
    if (this.isProduction()) {
      return true;
    }

    return ['supplier', 'supplier4', 'onboarding'].indexOf(supplierSite.split('-').pop()) > -1;
  }

  createSupplierSiteUrl(supplierSite, jwtToken): string {
    const firstUrlPart = this.supplierUrl.split('.').shift();
    const supplierDomain = this.supplierUrl.replace(firstUrlPart, supplierSite);
    return `https://${supplierDomain}/#/login/auth?token=${jwtToken}`;
  }

  getToken(supplierKey: string): void {
    this.supplierService.getToken(supplierKey).subscribe(
      (data: GetTokenResponse) => this.loginToSD(data),
      () => this.showLoginError(),
    );
  }

  loginToSD(data: GetTokenResponse) {
    const jwtToken = data.token;
    const supplierSite = this.getSupplierSiteFromJwt(jwtToken);

    if (!this.isSupplierSiteAvailable(supplierSite)) {
      this.showUnavailabilityWarning();
      return;
    }

    this.pybExtService.openPrivateTab(this.createSupplierSiteUrl(supplierSite, jwtToken));
  }

  showLoginError() {
    this.toastrService.error(this.messageService.getMessageByCode(messageCodes.cantReceiveToken));
  }

  showUnavailabilityWarning() {
    this.toastrService.warning(this.messageService.getMessageByCode(messageCodes.inTestEnvironmentsCanOnlyTo));
  }
}
