import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { CrmStates } from '../../../../shared/layout/layout.enums';
import { LayoutState } from '../../../../shared/layout/store/layout.reducers';
import { LookupAbstractService } from '../../../../shared/lookup-service/lookup-abstract.service';
import { FetchUtilAttribute, selectCountries } from '../../../../shared/utils/store/utils.actions';
import { AppState } from '../../../../store/app.reducers';
import { OutgoingPaymentMethod } from '../../../enums/outgoing-payment-method.enum';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { PaymentConfigService } from '../../../services/payment-config/payment-config.service';
import {
  CreateSupplierPaymentConfigs,
  DeleteSupplierPaymentConfigs,
  FetchSupplierPaymentConfigs,
  PaymentConfigClearStatus,
  selectPaymentMethods,
  selectPendingPCStates,
  selectSupplierDetails,
} from '../../../store/supplier/supplier.actions';
import { PaymentConfigStatus } from '../../../store/supplier/supplier.reducers';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { PaymentHistoryService } from './payment-history.service';

class ConfigDataSource extends MatTableDataSource<any> {
  constructor() {
    super();
    const prev = this.sortingDataAccessor;
    this.sortingDataAccessor = function (data, sortHeaderId) {
      if (sortHeaderId === 'notes') {
        return data.notes.toLowerCase();
      }
      return prev(data, sortHeaderId);
    };
  }
}

@Component({
  selector: 'app-outgoing-payment-add',
  templateUrl: './outgoing-payment-add.component.html',
  styleUrls: ['./outgoing-payment-add.component.scss'],
})
export class OutgoingPaymentAddComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) cardTransitionsPaginator: MatPaginator;

  actionId = new Date().toString();
  step = 0;
  editMode = false;
  countries = [];
  countryCtrl = new FormControl();
  filteredCountries: Observable<any[]>;
  payConfig: any;
  payConfigStatus = null;
  paymentConfigKey = null;
  supplierDetails: SupplierDetailsModel;
  paymentConfigForm: FormGroup;
  paymentMethods: any[];
  paymentConfigStatuses = ['pending', 'approved', 'rejected'];
  layoutState$: Store<LayoutState>;
  crmPanelStates = CrmStates;
  cardTransitionHistoryColumns = ['date', 'status', 'reason', 'notes', 'channel'];
  cardTransitionsData = new ConfigDataSource();
  pageSizeOptions = [10, 25, 50];

  readonly OutgoingPaymentMethod = OutgoingPaymentMethod;
  readonly paymentMethodLabels: Record<OutgoingPaymentMethod, string> = {
    [OutgoingPaymentMethod.ACH]: 'ACH',
    [OutgoingPaymentMethod.WIR]: 'Wire',
    [OutgoingPaymentMethod.EFS]: 'EFS',
    [OutgoingPaymentMethod.PPM]: 'Debit Card Push Payment',
    [OutgoingPaymentMethod.LLP]: 'Lian Lian Pay',
    [OutgoingPaymentMethod.PCARD]: 'PCARD',
  };

  constructor(
    private readonly paymentConfigService: PaymentConfigService,
    private readonly lookupRepo: LookupAbstractService,
    private readonly toastrService: ToastrService,
    private readonly datePipe: DatePipe,
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cardService: PaymentHistoryService,
  ) {
    super();
    this.store.dispatch(new FetchUtilAttribute({ attr: 'countries' }));
    this.payConfig = {};
    this.layoutState$ = this.store.select('layout');

    this.subscriptions.push(
      this.store.select(selectCountries).subscribe((data) => {
        if (!isUndefined(data)) {
          this.countries = data;
        } else {
          this.countries = [];
        }
      }),
    );

    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((data: SupplierDetailsModel) => {
        this.supplierDetails = data;
      }),
    );

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.paymentConfigKey = params['paymentConfigKey'];

        if (!!this.paymentConfigKey) {
          this.subscriptions.push(
            this.store.select(selectPaymentMethods).subscribe((results) => {
              if (results) {
                const payConf = results[this.paymentConfigKey];
                if (payConf) {
                  this.editMode = true;
                  this.payConfig = { ...payConf };
                  this.payConfigStatus = payConf.configStatus;

                  setTimeout(() => {
                    this.initCardTransitionsTable();
                  }, 1500);
                }
              } else {
                this.store.dispatch(new FetchSupplierPaymentConfigs());
              }
            }),
          );
        }
      }),
    );

    this.filteredCountries = this.countryCtrl.valueChanges.pipe(
      startWith(null),
      map((state) => (state && this.countries ? this.filterCountry(state) : this.countries.slice())),
    );
  }

  filterCountry(name: string) {
    return this.countries.filter((country) => country.label.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  configFormDataUpdate(event) {
    this.paymentConfigForm = event;
  }

  onCountrySelect(event: MatAutocompleteSelectedEvent) {
    this.paymentMethods = [];
    this.subscriptions.push(
      this.lookupRepo.getPaymentMethods(event.option.value).subscribe((data) => {
        this.paymentMethods = this.removeLLPFromPaymentMethod(data);

        if (this.paymentMethods && this.paymentMethods.length > 0) {
          this.setStep(1);
        } else {
          this.toastrService.error('Sorry! No payment methods found for selected country');
        }
      }),
    );
  }

  removeLLPFromPaymentMethod(paymentMethods) {
    if (!paymentMethods) {
      return [];
    }

    return paymentMethods.filter((paymentMethod) => paymentMethod.paymentMethod !== OutgoingPaymentMethod.LLP);
  }

  onPaymentSelect() {
    this.setStep(2);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectPendingPCStates).subscribe((items) => {
        Object.keys(items).map((id) => {
          switch (items[id].status) {
            case PaymentConfigStatus.ERROR:
              let message = 'Could not save changes. Try again!';
              if (!isNullOrUndefined(items[id].error) && items[id].error.error) {
                message = items[id].error.error.message;
              }
              this.store.dispatch(new PaymentConfigClearStatus({ actionId: id }));
              this.toastrService.error(message);
              break;
            case PaymentConfigStatus.SUCCESS:
              this.store.dispatch(new PaymentConfigClearStatus({ actionId: id }));
              this.store.dispatch(new FetchSupplierPaymentConfigs({ fresh: true }));

              this.toastrService.success('Item saved successfully!');
              this.router.navigate(['suppliers', this.supplierDetails.supplierKey, 'payment', 'list']);
              break;
          }
        });
      }),
    );
  }

  ngAfterViewInit() {
    this.cardTransitionsData.sort = this.sort;
    this.cardTransitionsData.paginator = this.cardTransitionsPaginator;
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  noop() {
    return false;
  }

  getCountryName(val) {
    if (this.countries && this.countries.length) {
      const foundItem = this.countries.find((item) => item.id === val);
      if (foundItem) {
        return foundItem.label;
      }
    }
  }

  getCountryFlag(val) {
    const foundItem = this.countries.find((item) => item.id === val);
    if (foundItem) {
      return foundItem.flag;
    }
  }

  changeStatus(status) {
    const config = {
      ...this.payConfig,
      configStatus: status,
    };

    return this.paymentConfigService.save(this.supplierDetails.supplierKey, config).subscribe(
      () => {
        this.toastrService.success('Payment method status saved!');
        this.store.dispatch(new FetchSupplierPaymentConfigs({ fresh: true }));
        this.router.navigate(['../../list'], { relativeTo: this.route });
      },
      (error) => {
        if (!isUndefined(error.error) && !isUndefined(error.error.message)) {
          this.toastrService.error(error.error.message);
        }
      },
    );
  }

  savePaymentConfig() {
    if (this.paymentConfigForm && this.paymentConfigForm.valid) {
      const dataToSave = {
        ...this.payConfig,
        paymentConfig: {
          ...this.prepareValuesToSend(this.paymentConfigForm.value),
        },
      };

      if (dataToSave.paymentType === OutgoingPaymentMethod.EFS) {
        // If sent, API breaks
        delete dataToSave.paymentConfig.beneficiary;

        if (dataToSave.paymentConfig.startDate) {
          dataToSave.paymentConfig.startDate = this.datePipe.transform(dataToSave.paymentConfig.startDate, 'y-MM-dd');
        }
      }

      if (this.editMode) {
        const paymentConfigKey = dataToSave.paymentConfigKey;
        delete dataToSave['paymentConfigKey'];
        this.subscriptions.push(
          this.paymentConfigService.remove(this.supplierDetails.supplierKey, paymentConfigKey).subscribe(() => {
            this.store.dispatch(
              new CreateSupplierPaymentConfigs({
                paymentConfig: dataToSave,
                actionId: this.actionId,
              }),
            );
          }),
        );
      } else {
        this.store.dispatch(
          new CreateSupplierPaymentConfigs({
            paymentConfig: dataToSave,
            actionId: this.actionId,
          }),
        );
      }
      return;
    } else {
      this.paymentConfigForm.markAllAsTouched();
      this.toastrService.error('Validation failed. Check fields and try again.');
    }
  }

  removePaymentConfig() {
    if (this.editMode && this.paymentConfigKey && this.supplierDetails.supplierKey) {
      this.store.dispatch(
        new DeleteSupplierPaymentConfigs({
          paymentConfigKey: this.paymentConfigKey,
          actionId: this.actionId,
        }),
      );
    }
  }

  isPhysicalCard() {
    return (
      this.payConfig.paymentType === OutgoingPaymentMethod.PCARD && this.payConfig.paymentConfig.pcard.pcard_type_indicator === 'PHYSICAL'
    );
  }

  public getShippingStatus() {
    return !isNullOrUndefined(this.payConfig.paymentConfig.pcard.pcard_shipping_status)
      ? this.payConfig.paymentConfig.pcard.pcard_shipping_status
      : 'INITIATED';
  }

  private prepareValuesToSend(values) {
    const v = { ...values };

    if (v?.bank.hasOwnProperty('Id')) {
      v.bank.Id = v.bank.Id.trim();
    }

    return v;
  }

  private initCardTransitionsTable() {
    if (!this.payConfig.paymentConfig.pcard || !this.payConfig.paymentConfig.pcard.card_token) {
      return;
    }

    this.subscriptions.push(
      this.cardService
        .getCardTransactionHistory(this.supplierDetails.supplierKey, this.payConfig.paymentConfig.pcard.card_token)
        .subscribe((cardTransactions) => {
          this.cardTransitionsData.data = cardTransactions;
        }),
    );
  }
}
