import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../../config';
import { SupplierUnderwritingInterface } from './underwriting-initial/common/interfaces/supplier-underwriting-interface';

export const KYC_IDENTITY_KEY = '869f7aaf-e9bd-4f85-bfa6-6a0b0d5616a2';
export const KYC_ADDRESS_KEY = 'f9be5d82-bdbc-4502-8214-4a8c0f095e85';
export const ACCOUNT_ACCESS_INVITATION_KEY = 'c5f8e904-75f3-4090-9783-fadafe2162fb';
export const AMAZON_SELLER_CENTRAM_PERM_KEY = '331d90cc-cf7a-4d9f-92bd-8855c1af08c5';

export const COMPLIANCE_ACTION_KEYS = [KYC_IDENTITY_KEY, KYC_ADDRESS_KEY, ACCOUNT_ACCESS_INVITATION_KEY, AMAZON_SELLER_CENTRAM_PERM_KEY];

@Injectable()
export class SupplierUnderwritingService implements SupplierUnderwritingInterface {
  static readonly COMPLIANCE_STATUS_KEY = 'compliance_status_key';

  private apiUrl: string;
  private uwToolsApi: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'suppliers/';
    this.uwToolsApi = appConfig.env.automaticMarketplaceApiUrl;
  }

  getAmazonHealthRules(supplierKey: string) {
    const endpointUrl = this.apiUrl + `${supplierKey}/amazon_health_rules`;
    return this.httpClient.get<any[]>(endpointUrl);
  }

  updateAmazonHealthRules(supplierKey: string, updateRules: object) {
    const endpointUrl = this.apiUrl + `${supplierKey}/amazon_health_rules`;
    return this.httpClient.put<any[]>(endpointUrl, updateRules);
  }

  getDailyUnderwritingData(supplierKey: string, marketplaceKey: string, date_from: string, refresh = false) {
    let queryParams = new HttpParams().append('date_from', date_from);
    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }
    const endpointUrl = this.apiUrl + `${supplierKey}/marketplace/${marketplaceKey}/data`;
    return this.httpClient.get<any[]>(endpointUrl, {
      params: queryParams,
    });
  }

  getActionsList(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}${supplierKey}/compliance_status`;
    return this.httpClient.get(endpointUrl);
  }

  getHistoryList(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}${supplierKey}/compliance_snapshot/list`;
    return this.httpClient.get(endpointUrl);
  }

  getHistoryRecord(supplierKey: string, itemKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}${supplierKey}/compliance_snapshot/${itemKey}`;
    return this.httpClient.get(endpointUrl);
  }

  getAmazonDataService(supplierKey: string, marketplaceKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}${supplierKey}/marketplace/${marketplaceKey}/data`;
    return this.httpClient.get(endpointUrl);
  }

  getActionsService(supplierKey: string, complianceKey: string, action: string, version: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}${supplierKey}/compliance_status/${complianceKey}/${action}/${version}`;
    return this.httpClient.get(endpointUrl);
  }
  postActionStatusService(version: string, body: object): Observable<any> {
    const endpointUrl = `${this.appConfig.env.internalApiUrl}compliance_action_status/${version}`;
    return this.httpClient.post(endpointUrl, body);
  }
  putActionStatusService(complianceActionStatusKey: string, version: string, body: object): Observable<any> {
    const endpointUrl = `${this.appConfig.env.internalApiUrl}compliance_action_status/${complianceActionStatusKey}/${version}`;
    return this.httpClient.put(endpointUrl, body);
  }
  putComplianceStatusService(complianceActionStatusKey: string, body: object): Observable<any> {
    const endpointUrl = `${this.appConfig.env.internalApiUrl}compliance_status/${complianceActionStatusKey}`;
    return this.httpClient.put(endpointUrl, body);
  }
  getAmazonApiInfo(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}${supplierKey}/check_amazon_api`;
    return this.httpClient.get(endpointUrl);
  }
  checkIdentitySole(supplierKey: string, data: any): Observable<any> {
    const partialUrl = `${data.SSN}?first_name=${data.firstName}&last_name=${data.lastName}`;
    const endpointUrl = `${this.apiUrl}${supplierKey}/identity_check/sole/${partialUrl}`;
    return this.httpClient.get(endpointUrl);
  }
  checkIdentityCompany(supplierKey: string, data: any): Observable<any> {
    const partialUrl = `${data.taxId}?company_name=${data.legalName}`;
    const endpointUrl = `${this.apiUrl}${supplierKey}/identity_check/company/${partialUrl}`;
    return this.httpClient.get(endpointUrl);
  }
  getMarqetaKycCompliances(supplierKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}${supplierKey}/marqeta_kyc_compliances`;
    return this.httpClient.get(endpoint);
  }
  patchMarqetaKycCompliances(supplierKey: string, data: any): Observable<any> {
    const endpoint = `${this.apiUrl}${supplierKey}/marqeta_kyc_compliances`;
    return this.httpClient.patch(endpoint, data);
  }
  getOwnersMarqetaKycCompliances(supplierKey: string): Observable<any> {
    const endpoint = `${this.apiUrl}${supplierKey}/marqeta_kyc_compliances/owners`;
    return this.httpClient.get(endpoint);
  }
  patchOwnersMarqetaKycCompliances(supplierKey: string, data: any): Observable<any> {
    const endpoint = `${this.apiUrl}${supplierKey}/marqeta_kyc_compliances/owners`;
    return this.httpClient.patch(endpoint, data);
  }
  getFinalOffer(supplierKey: string) {
    const endpoint = `${this.appConfig.env.internalApiUrl}supplier/${supplierKey}/uw_modal`;
    return this.httpClient.get(endpoint);
  }

  fetchAvailableTools(supplierKey: string, personId: string) {
    const params = new HttpParams({ fromObject: { supplier_key: supplierKey, person_id: personId } });

    return this.httpClient.get(`${this.uwToolsApi}/tools`, { params });
  }
}
