import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../config';
import { KycGeneralResponse, KycUpdateCompliance } from './../model/compliance.model';

export const COMPLIANCE_API_DEFAULT_VERSION = 'v2';

@Injectable()
export class SupplierComplianceActionService {
  readonly UPLOAD_FILE_COMPLIANCE_ACTION_TYPE = 'e9ebe0e3-75df-4de9-8be0-5fd2c9d69313';
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
  }

  saveStatus(complianceKey, data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}compliance_status/${complianceKey}`;
    return this.httpClient.put(endpointUrl, data);
  }

  createStatus(data: any): Observable<any> {
    const endpointUrl = `${this.apiUrl}compliance_status`;
    return this.httpClient.post(endpointUrl, data);
  }

  deleteAction(complianceKey): Observable<any> {
    const endpointUrl = `${this.apiUrl}compliance_status/${complianceKey}`;
    return this.httpClient.delete(endpointUrl);
  }

  getAction(supplierKey, complianceKey, version = COMPLIANCE_API_DEFAULT_VERSION): Observable<any> {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}/compliance_status/${complianceKey}/actions/${version}`;
    return this.httpClient.get(endpointUrl);
  }

  createAction(data, version = COMPLIANCE_API_DEFAULT_VERSION): Observable<any> {
    let endpointUrl = `${this.apiUrl}compliance_action_status`;

    if (version) {
      endpointUrl += `/${version}`;
    }

    return this.httpClient.post(endpointUrl, data);
  }

  updateAction(data, complianceActionStatusKey, version = COMPLIANCE_API_DEFAULT_VERSION): Observable<any> {
    let endpointUrl = `${this.apiUrl}compliance_action_status/${complianceActionStatusKey}`;
    if (version) {
      endpointUrl += `/${version}`;
    }

    return this.httpClient.put(endpointUrl, data);
  }

  updateCompliance(supplierKey: string, payload: KycUpdateCompliance): Observable<KycGeneralResponse> {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}/compliance_action_status/${this.UPLOAD_FILE_COMPLIANCE_ACTION_TYPE}`;
    return this.httpClient.put(endpointUrl, payload);
  }
}
