<h2 mat-dialog-title>Re Underwrite Offer</h2>
<div mat-dialog-content>
  <app-manual-advance-offer-inputs [supplierKey]="supplierKey" [showUwStatusSelector]="true"> </app-manual-advance-offer-inputs>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="onSaveClick()" [disabled]="!isSaveEnabled" tabindex="2" [color]="'accent'">
    {{ 'Save' }}
  </button>
  <button mat-raised-button (click)="close()" [color]="'warn'" tabindex="-1">Cancel</button>
</div>
