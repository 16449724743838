<div>
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let tab of tabs$ | async"
      [routerLink]="tab.route"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ tab.label }}
    </a>
  </nav>

  <router-outlet></router-outlet>
</div>
