import { autoserialize } from 'cerialize';

export class SupplierAuthTokenModel {
  @autoserialize
  token: string;

  @autoserialize
  supplier: string;

  constructor(token: string, supplier: string = null) {
    this.token = token;
    this.supplier = supplier;
  }
}
