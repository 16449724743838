import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
})
export class TimelineItemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
