<div class="">
  <div class="margin__left_50">Weekly Underwritings</div>
</div>
<ng-container *ngIf="!loading; else spinner">
  <ng-container *ngIf="!noDataFromTheApi && rows && rows.length; else noData">
    <app-data-table-grid [nodesCounted]="true" [rows]="rows" [columnDefs]="columns" [domLayout]="'autoHeight'"> </app-data-table-grid>
  </ng-container>
</ng-container>

<ng-template #noData>
  <app-no-data></app-no-data>
</ng-template>

<ng-template #spinner>
  <div class="loader">
    <app-loading-spinner [size]="1"></app-loading-spinner>
  </div>
</ng-template>
