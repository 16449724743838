import { Action, createSelector } from '@ngrx/store';
import { Deserialize } from 'cerialize';
import { isString, isUndefined } from 'util';

import { UserModel } from '../../shared/users/user.model';
import { AppState } from '../../store/app.reducers';
import { AuthState } from './auth.reducers';
import * as fromAuth from './auth.reducers';

export const TRY_LOGIN = 'TRY_LOGIN';
export const TRY_LOGIN_GOOGLE = 'TRY_LOGIN_GOOGLE';
export const LOGIN = 'LOGIN';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';
export const HANDLE_AUTH_REDIRECT = 'HANDLE_AUTH_REDIRECT';

export interface LoggedUserInterface {
  jwtToken: string;
  username: string;
}

export class TryLogin implements Action {
  readonly type = TRY_LOGIN;

  constructor(public payload: { username: string; password: string }) {}
}

export class TryLoginGoogle implements Action {
  readonly type = TRY_LOGIN_GOOGLE;

  constructor(public payload: { googleAuthUser: any }) {}
}

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: { user: LoggedUserInterface }) {}
}

export class LoginFailed implements Action {
  readonly type = LOGIN_FAILED;

  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;

  constructor(public payload: { message: string } = { message: null }) {}
}

export class ClearAuthState implements Action {
  readonly type = CLEAR_AUTH_STATE;
}

export class HandleAuthRedirect implements Action {
  readonly type = HANDLE_AUTH_REDIRECT;
}

const selectAuth = (state: AppState): fromAuth.AuthState => state.auth;

export function hasCorrectToken(authState: AuthState): boolean {
  return (
    !isUndefined(authState.user) && !isUndefined(authState.user.jwtToken) && isString(authState.user.jwtToken) && authState.user.jwtToken
  );
}

export function hasCorrectData(authState: AuthState): boolean {
  return !isUndefined(authState.user) && !isUndefined(authState.user.loginId) && authState.user.loginId;
}

export const selectAuthToken = createSelector(selectAuth, (state: AuthState) => {
  if (hasCorrectToken(state)) {
    return state.user.jwtToken;
  }
});

export const selectLoggedUserModel = createSelector(selectAuth, (state: AuthState) => {
  if (hasCorrectToken(state) && hasCorrectData(state)) {
    return Deserialize(state.user, UserModel);
  }
});

export const isLoggedIn = createSelector(selectAuth, (auth) => auth.isAuthenticated);

export const selectLoggedUserLoginKey = createSelector(selectAuth, (state: AuthState) => {
  if (hasCorrectToken(state) && hasCorrectData(state)) {
    return state.user.loginKey;
  }
});

export type AuthActions = Login | LoginFailed | Logout | TryLogin | ClearAuthState | HandleAuthRedirect;
