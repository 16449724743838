import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { first, skipWhile } from 'rxjs/operators';

import { DIALOG_SIZE } from '../../../../shared/dialog.config';
import { FetchSupplierDetails, selectSupplierDetails, selectSupplierMarketplaces } from '../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from './../../../../shared/confirm-dialog/confirm-dialog.component';
import { FeatureFlagsService } from './../../../../shared/feature-flags/feature-flags.service';
import { LookupAbstractService } from './../../../../shared/lookup-service/lookup-abstract.service';
import { AppState } from './../../../../store/app.reducers';
import { FetchSupplierMarketplaces } from './../../../store/supplier/supplier.actions';
import { PurchaseFeeModel } from './../model/purchase-fee.model';
import { SupplierFeesService } from './../service/supplier-fees.service';

@Component({
  selector: 'app-purchase-fees',
  templateUrl: './purchase-fees.component.html',
  styleUrls: ['./purchase-fees.component.scss'],
})
export class PurchaseFeesComponent extends SubscriberComponent implements OnInit {
  columnsToDisplay = ['marketplace', 'feeScheduleType', 'feeScheduleName', 'feePercentage', 'fixedFee', 'action'];
  dataSource: MatTableDataSource<PurchaseFeeModel>;
  feeSchedule: any;
  feeSchedules: Array<any> = [];
  feeScheduleTypes: Set<string>;
  isLoadNetEarnings: boolean;
  marketplaceKey: string;
  purchaseFeeForm: FormGroup;
  purchaseFees: Array<PurchaseFeeModel> = new Array<PurchaseFeeModel>();
  supplierForm: FormGroup;
  isLoadingResult = false;
  private supplierKey: string;

  constructor(
    private store: Store<AppState>,
    private lookupRepo: LookupAbstractService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private matDialog: MatDialog,
    private supplierFeesService: SupplierFeesService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();

    this.subscriptions.push(
      this.lookupRepo.getFeeSchedules().subscribe((feeSchedules) => {
        this.feeSchedules = feeSchedules.filter((feeSchedule) => feeSchedule.feeScheduleCategory === 'PURCHASE');
        if (this.feeSchedules.length > 0) {
          this.feeScheduleTypes = new Set(
            this.feeSchedules.map((feeSchedule) => {
              if (feeSchedule != null) {
                return feeSchedule.feeScheduleType;
              }
            }),
          );
          this.subscriptions.push(
            this.store
              .select(selectSupplierMarketplaces)
              .pipe(
                skipWhile((marketplaces) => marketplaces == null),
                first(),
              )
              .subscribe((marketplaces) => {
                if (marketplaces != null) {
                  marketplaces.forEach((marketplace) => {
                    const purchaseFee = new PurchaseFeeModel();
                    let feeScheduleLocal;

                    purchaseFee.marketplaceName = marketplace.marketplaceName;
                    purchaseFee.marketplaceKey = marketplace.mpSupKey;
                    if (marketplace.fundingParticulars != null) {
                      purchaseFee.feeScheduleKey = marketplace.fundingParticulars.currentFeeScheduleKey;
                    }

                    feeScheduleLocal = this.feeSchedules.filter(
                      (feeSchedule) => feeSchedule.feeScheduleKey === purchaseFee.feeScheduleKey,
                    )[0];

                    if (feeScheduleLocal != null) {
                      purchaseFee.feeScheduleType = feeScheduleLocal.feeScheduleType;
                      this.setFeeScheduleNames(purchaseFee);
                      purchaseFee.feeScheduleName = feeScheduleLocal.feeScheduleName;
                      feeScheduleLocal.feeScheduleTiers.sort((a, b) => a.tierStart > b.tierStart);
                      purchaseFee.tierStart = feeScheduleLocal.feeScheduleTiers[0].tierStart;
                      purchaseFee.tierEnd = feeScheduleLocal.feeScheduleTiers[0].tierEnd;
                      purchaseFee.feePercentage = feeScheduleLocal.feeScheduleTiers[0].feePercent;
                      purchaseFee.fixedFee = feeScheduleLocal.feeScheduleTiers[0].feeFixed;

                      // this.purchaseFees.push(purchaseFee);
                    }
                    this.purchaseFees.push(purchaseFee);
                  });
                  this.dataSource = new MatTableDataSource(this.purchaseFees);
                }
              }),
          );
        }
      }),
    );

    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetails) => {
        if (supplierDetails != null) {
          this.supplierKey = supplierDetails.supplierKey;
          this.isLoadNetEarnings = supplierDetails.loadNetEarnings;

          this.store.dispatch(
            new FetchSupplierMarketplaces({
              supplierDetailsModel: supplierDetails,
              refresh: true,
            }),
          );
        }
      }),
    );
  }

  createForm(): void {
    this.supplierForm = this.formBuilder.group({
      loadNetEarnings: [false],
    });
  }

  hasTiers(purchaseFee: PurchaseFeeModel): boolean {
    const feeSchedule = this.feeSchedules.filter((feeScheduleData) => feeScheduleData.feeScheduleName === purchaseFee.feeScheduleName)[0];
    if (feeSchedule == null) {
      return false;
    } else {
      return feeSchedule.feeScheduleTiers.length > 1;
    }
  }

  openConfirmationDialog(marketplaceKey: string, feeScheduleKey: string): void {
    const message = `Modification to the fee schedule may interrupt payments until the supplier accepts.
                     Do you want to proceed?`;

    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: { message },
      width: DIALOG_SIZE.SMALL.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.savePurchaseFee(marketplaceKey, feeScheduleKey);
        }
      }),
    );
  }

  saveEarningsType(): void {
    this.isLoadingResult = true;
    this.subscriptions.push(
      this.supplierFeesService.setSupplierEaringsType(this.supplierKey, this.isLoadNetEarnings).subscribe(
        () => {
          this.toastrService.success('Earnings type saved successfully');
          this.store.dispatch(new FetchSupplierDetails({ supplierKey: this.supplierKey, refresh: true }));
        },
        () => {
          this.toastrService.error('');
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }

  setFeeScheduleForSelectedName(selectedFeeScheduleName: string, purchaseFee: PurchaseFeeModel): void {
    const feeSchedule = this.feeSchedules.filter((feeScheduleData) => feeScheduleData.feeScheduleName === selectedFeeScheduleName)[0];

    purchaseFee.feeScheduleKey = feeSchedule.feeScheduleKey;
    feeSchedule.feeScheduleTiers.sort((a, b) => a.tierStart > b.tierStart);
    purchaseFee.tierStart = feeSchedule.feeScheduleTiers[0].tierStart;
    purchaseFee.tierEnd = feeSchedule.feeScheduleTiers[0].tierEnd;
    purchaseFee.feePercentage = feeSchedule.feeScheduleTiers[0].feePercent;
    purchaseFee.fixedFee = feeSchedule.feeScheduleTiers[0].feeFixed;

    const indexOfPurchaseFee = this.dataSource.data.indexOf(purchaseFee);

    this.removeTiers(indexOfPurchaseFee, purchaseFee);

    this.dataSource = new MatTableDataSource(this.purchaseFees);
  }

  setFeeScheduleNames(fee: PurchaseFeeModel): void {
    fee.feeScheduleNames = this.feeSchedules
      .filter((feeSchedule) => feeSchedule.feeScheduleType === fee.feeScheduleType)
      .map((feeSchedule) => feeSchedule.feeScheduleName);
  }

  toggleCollapse(index: number, purchaseFee: PurchaseFeeModel): void {
    if (purchaseFee.isCollapsed) {
      this.addTiers(index, purchaseFee);
    } else {
      this.removeTiers(index, purchaseFee);
    }
  }

  toggleEditMode(purchaseFee: PurchaseFeeModel): void {
    purchaseFee.isEditable = !purchaseFee.isEditable;
  }

  private addTiers(index: number, purchaseFee: PurchaseFeeModel): void {
    const feeSchedule = this.feeSchedules.filter((feeScheduleData) => feeScheduleData.feeScheduleName === purchaseFee.feeScheduleName)[0];
    const tiers = new Array<PurchaseFeeModel>();

    if (feeSchedule.feeScheduleTiers.length > 1) {
      feeSchedule.feeScheduleTiers.forEach((feeScheduleTier) => {
        const tier = new PurchaseFeeModel();

        tier.isTier = true;
        tier.tierStart = feeScheduleTier.tierStart;
        tier.tierEnd = feeScheduleTier.tierEnd;
        tier.feePercentage = feeScheduleTier.feePercent;
        tier.fixedFee = feeScheduleTier.feeFixed;

        tiers.push(tier);
      });
      tiers.shift();
    }
    this.purchaseFees.splice(++index, 0, ...tiers);
    purchaseFee.isCollapsed = false;
    this.dataSource = new MatTableDataSource(this.purchaseFees);
  }

  private removeTiers(index: number, purchaseFee: PurchaseFeeModel): void {
    let tiersCounter = 0;

    for (let i = 0; i < this.purchaseFees.length; i++) {
      if (i > index) {
        if (this.purchaseFees[i].isTier) {
          tiersCounter++;
        } else {
          break;
        }
      }
    }

    this.purchaseFees.splice(++index, tiersCounter);
    purchaseFee.isCollapsed = true;

    this.dataSource = new MatTableDataSource(this.purchaseFees);
  }

  private savePurchaseFee(marketplaceKey: string, feeScheduleKey: string): void {
    this.isLoadingResult = true;
    this.subscriptions.push(
      this.supplierFeesService.setSupplierFeeData(this.supplierKey, marketplaceKey, feeScheduleKey).subscribe(
        () => {
          this.toastrService.success('Fee schedule saved successfully.');
        },
        (error) => {
          this.toastrService.error(error.error.message);
        },
        () => {
          this.isLoadingResult = false;
        },
      ),
    );
  }
}
