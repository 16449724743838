export enum TaskStatuses {
  NEW = 'new',
  IN_PROGR = 'in-progr',
  DONE = 'done',
  CLOSED = 'closed',
}

export const taskStatuses: any[] = [
  {
    name: 'New',
    key: TaskStatuses.NEW,
  },
  {
    name: 'Started',
    key: TaskStatuses.IN_PROGR,
  },
  {
    name: 'Done',
    key: TaskStatuses.DONE,
  },
  {
    name: 'Closed',
    key: TaskStatuses.CLOSED,
  },
];
