import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cli-action-last-run',
  templateUrl: './cli-action-last-run.component.html',
  styleUrls: ['./cli-action-last-run.component.scss'],
})
export class CliActionLastRunComponent implements OnInit {
  @Input() userInfo;

  constructor() {}

  ngOnInit() {}
}
