<h1 mat-dialog-title>{{ actionTitle }}</h1>

<div class="confirmation" *ngIf="isSaved">
  {{ confirmationMessage }}
</div>

<div [formGroup]="supplierActionForm" *ngIf="showForm">
  <mat-form-field class="full-width">
    <mat-select placeholder="Marketplace" formControlName="marketplace_code">
      <mat-option *ngFor="let marketplace of marketplaces" [value]="marketplace.id">
        {{ marketplace.label }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Chargeback external ID" formControlName="chargeback_external_id" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput [matDatepicker]="picker" placeholder="Post date" formControlName="post_date" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width" *ngIf="supplierActionForm.get('marketplace_code').value === '156'">
    <mat-select placeholder="Statement" formControlName="statement_id">
      <mat-option *ngFor="let statement of statements" [value]="statement.id">
        {{ statement.label }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Amount" type="number" step="0.01" formControlName="chargeback_amount" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Description" formControlName="reason" />
    <mat-error>This field is required.</mat-error>
  </mat-form-field>

  <div class="mt-2 mb-2">
    <mat-slide-toggle color="accent" formControlName="is_loan_refinance"> Loan Refinance </mat-slide-toggle>
  </div>

  <div class="mt-2 mb-2">
    <mat-slide-toggle color="accent" formControlName="passed_to_supplier"> Passed to supplier </mat-slide-toggle>
  </div>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="showForm && !isSaved">
  <button mat-raised-button (click)="save()" [disabled]="!supplierActionForm.valid" tabindex="2" color="accent">Save</button>
  <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="isSaved">
  <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Yes</button>
  <button mat-raised-button (click)="editForm()" tabindex="-1" color="warn">No</button>
</div>

<div class="warning" *ngIf="isLoan">
  {{ loanMessage }}
</div>

<div *ngIf="isSuccess">
  <div class="alert alert-success">
    {{ successMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="resetForm()" tabindex="2" color="accent">Add Another</button>
    <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="warn">Done</button>
  </div>
</div>

<div *ngIf="isError">
  <div class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="back()" tabindex="-1" color="accent">Back</button>
  </div>
</div>

<app-spinner *ngIf="isLoadingResult" [forceShow]="true"></app-spinner>
