export class OnboardingDetailsCompliance {
  public changeable: boolean;
  public complianceKey: string;
  public complianceLabel: string;
  public complianceLevel: string;
  public complianceType: string;
  public description: string;
  public requiredForFunding: boolean;
  public status: string;

  constructor(changeable, complianceKey, complianceLabel, complianceLevel, complianceType, description, requiredForFunding, status) {
    this.changeable = changeable;
    this.complianceKey = complianceKey;
    this.complianceLabel = complianceLabel;
    this.complianceLevel = complianceLevel;
    this.complianceType = complianceType;
    this.description = description;
    this.requiredForFunding = requiredForFunding;
    this.status = status;
  }
}

export class OnboardingDetails {
  public marketplaceKey: string;
  public marketplaceName: string;
  public compliances: Array<OnboardingDetailsCompliance>;
  public isIntegrated: boolean;

  constructor(compliances: Array<OnboardingDetailsCompliance>, marketplaceKey: string, marketplaceName: string, isIntegrated: boolean) {
    this.marketplaceKey = marketplaceKey;
    this.marketplaceName = marketplaceName;
    this.compliances = compliances;
    this.isIntegrated = isIntegrated;
  }
}

export class OnboardingDetailsComplianceMapper {
  public static map(data): OnboardingDetailsCompliance {
    if (!data) {
      return;
    }

    return new OnboardingDetailsCompliance(
      data.changeable,
      data.complianceKey,
      data.complianceLabel,
      data.complianceLevel,
      data.complianceType,
      data.description,
      data.requiredForFunding,
      data.status,
    );
  }
}

export class OnboardingDetailsMapper {
  public static map(data): OnboardingDetails {
    if (!data) {
      return;
    }

    return new OnboardingDetails(
      data.compliances.map(OnboardingDetailsComplianceMapper.map),
      data.marketplaceKey,
      data.marketplaceName,
      data.isIntegrated,
    );
  }
}
