import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { AppConfigService } from '../../../../config';

export const STATUS_REJECTED = 'rejected';
export const STATUS_APPROVED = 'approved';

@Injectable()
export class SupplierVerifyDocumentService {
  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl + 'compliance_action_status';
  }

  setFileStatus(satisfiedBy: any, newStatus: string) {
    satisfiedBy.status = newStatus;

    const actionStatusKey = satisfiedBy.compliance_action_status_key;
    const endpointUrl = `${this.apiUrl}/${actionStatusKey}`;

    return this.httpClient
      .put(endpointUrl, satisfiedBy, {
        observe: 'body',
        responseType: 'json',
      })
      .pipe(
        tap((result) => {
          // TODO nkler: we can use here env-configurable logger service
        }),
      );
  }
}
