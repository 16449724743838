import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-load-earnings',
  templateUrl: './import-load-earnings.component.html',
  styleUrls: ['./import-load-earnings.component.less'],
})
export class ImportLoadEarningsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
