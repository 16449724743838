import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from 'util';

@Pipe({
  name: 'findSchedule',
  pure: false,
})
@Injectable()
export class FindSchedule implements PipeTransform {
  transform(items: any, term: any): any {
    if (isUndefined(items) || !items) {
      return items;
    }

    return items.find(function (schedule) {
      return schedule.feeScheduleKey === term;
    });
  }
}
