export const GetCreditCheck = (numberOfRecordsFound: number) => {
  return [
    {
      text: 'Does the supplier have any open UCC against them?',
      target: 'UCC Filings: 0',
      value: 'UCC Filings: ' + numberOfRecordsFound,
      automated: Number(numberOfRecordsFound) === 0,
    },
  ];
};
