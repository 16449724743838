import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { hasCorrectToken } from '../../auth/store/auth.actions';
import { AppState } from '../../store/app.reducers';
import { CommunicationService } from './communication/communication.service';
import { ResizeIframeService } from './resize/resize-iframe.service';

const IFRAME_ID_PARAM_NAME = 'pluginIframeId';
const AUTH_TOKEN_PARAM_NAME = 'auth';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit, OnDestroy {
  @Input() set url(newUrl: string) {
    this.url$.next(newUrl);
  }
  @Input() passAuthToken = false;

  @ViewChild('iframe') iframe: ElementRef;

  @Output() onIframeIdInit = new EventEmitter<string>();

  public safeUrl$: Observable<SafeResourceUrl | undefined> = of(undefined);
  public hash?: string;
  private authToken$: Observable<string | undefined> = of(undefined);
  private url$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  loaded = false;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store<AppState>,
    private readonly communicationService: CommunicationService,
    private readonly iframeService: ResizeIframeService,
  ) {}

  ngOnInit() {
    this.communicationService.listenMessagesFromChilds();

    this.authToken$ = this.passAuthToken
      ? this.store.select('auth').pipe(map((authState) => (hasCorrectToken(authState) ? (authState.user.jwtToken as string) : undefined)))
      : of(undefined);

    this.safeUrl$ = combineLatest([this.authToken$, this.url$]).pipe(
      map(([token, url]) => {
        if (!url) {
          return undefined;
        }

        if (token) {
          url = this.addParamToUrl(url, AUTH_TOKEN_PARAM_NAME, token);
        }

        this.hash = 'frameId' + Math.floor(1e10 * Math.random()).toString();
        this.onIframeIdInit.emit(this.hash);
        url = this.addParamToUrl(url, IFRAME_ID_PARAM_NAME, this.hash);

        /*
        This value will be used in iframe's src attribute which implies SecurityContext.RESOURCE_URL.
        According to https://angular.io/guide/security#bypass-security-apis "sanitizing resource URLs isn't possible because they contain
        arbitrary code" so calling `bypassSecurityTrustResourceUrl` is necessary here. For our purposes (the urls are pretty standard and
        simple) sanitizing it as `SecurityContext.URL` should be enough to meet the security standards.
        */
        url = this.sanitizer.sanitize(SecurityContext.URL, url);
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.hash) {
      this.communicationService.removeChild(this.hash);
    }
  }

  onLoaded(): void {
    const win = this.iframe ? this.iframe.nativeElement.contentWindow : null;

    if (win) {
      const url = this.url$.getValue();
      this.communicationService.addChild({
        iframeId: this.hash,
        domain: environment.domain,
        url,
        window: win,
      });
      this.loaded = true;
    }
  }

  private addParamToUrl(url: string, param: string, value: string): string {
    const joinWith = url.indexOf('?') > 0 ? '&' : '?';

    return `${url}${joinWith}${param}=${value}`;
  }
}
