<h1 mat-dialog-title>Pay advance</h1>
<div mat-dialog-content>
  <div class="content">
    <span *ngIf="showConfirmationScreen" class="info-box">
      Are you sure you want to proceed?
      <p>{{ connfirmationInfo }}</p>
    </span>

    <mat-radio-group class="full-width" [(ngModel)]="payoffType" [disabled]="disabledAll">
      <mat-radio-button [value]="payoffTypeEnum.outstandingBalance">Outstanding balance</mat-radio-button>
      <div class="single-radio">
        <mat-radio-button [value]="payoffTypeEnum.ammount">Other amount</mat-radio-button>
        <mat-form-field class="field-text full-width">
          <input matInput type="number" [disabled]="disabledAll || disabledInput" [(ngModel)]="payoffAmount" />
        </mat-form-field>
      </div>
    </mat-radio-group>
  </div>
</div>
<div>
  <mat-slide-toggle color="accent" [disabled]="disableWriteOff()" [(ngModel)]="writeOff"> Write-off Advance </mat-slide-toggle>
</div>
<div mat-dialog-actions>
  <div class="actions">
    <div *ngIf="showEditionScreen">
      <button mat-raised-button (click)="submitOnClick()" [disabled]="invalid" [color]="'accent'" tabindex="2">Submit</button>
      <button mat-raised-button (click)="cancelOnClick()" [color]="'warn'" tabindex="-1">Cancel</button>
    </div>
    <div *ngIf="showConfirmationScreen">
      <button mat-raised-button (click)="confirmOnClick()" [disabled]="invalid" [color]="'accent'" tabindex="2">Yes</button>
      <button mat-raised-button (click)="denyOnClick()" [color]="'warn'" tabindex="-1">No</button>
    </div>
  </div>
</div>
