import { Component } from '@angular/core';

import { FilteringPanelService } from '../../services/filtering-panel-service/filtering-panel.service';
import { BaseFilterControl } from '../base-filter-control';

@Component({
  selector: 'app-filter-control-select',
  templateUrl: './filter-control-select.component.html',
  styleUrls: ['./filter-control-select.component.scss'],
})
export class FilterControlSelectComponent extends BaseFilterControl {
  options = [];

  constructor(protected filteringPanelService: FilteringPanelService) {
    super(filteringPanelService);
  }
}
