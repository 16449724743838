import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-email-status-badge',
  templateUrl: './email-status-badge.component.html',
  styleUrls: ['./email-status-badge.component.scss'],
})
export class EmailStatusBadgeComponent {
  @Input()
  status: string;

  constructor() {}
}
