<h6 class="text-center">New Bank Account</h6>
<form [formGroup]="bankAccountForm" (ngSubmit)="onSubmit($event)" *ngIf="availableCountries$ | async as availableCountries">
  <div class="row">
    <mat-form-field class="col-12 col-md-6 col-lg-4">
      <input matInput placeholder="Beneficiary Name" formControlName="beneficiaryName" type="text" />
    </mat-form-field>
    <mat-form-field class="col-12 col-md-6 col-lg-4">
      <input matInput placeholder="Bank Name" formControlName="bankName" type="text" />
    </mat-form-field>
    <mat-form-field class="col-12 col-md-6 col-lg-4">
      <mat-select matInput placeholder="Bank Country" formControlName="bankCountry">
        <mat-option *ngFor="let country of availableCountries; trackBy: trackCountries" [value]="country.countryCode">{{
          country.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-12 col-lg-6 col-xl-4">
      <input matInput placeholder="Account number" formControlName="accountNumber" type="text" />
    </mat-form-field>
    <div class="col-12 col-lg-6 col-xl-4">
      <mat-form-field style="width: 100%">
        <input matInput placeholder="Routing Number" formControlName="routingNumber" type="text" />
      </mat-form-field>
      <mat-error> {{ routingNumberErrorMsg$ | async }} </mat-error>
    </div>
    <mat-form-field class="col-12 col-lg-6 col-xl-4">
      <input matInput placeholder="Address 1" formControlName="addr1" type="text" />
    </mat-form-field>
    <mat-form-field class="col-12 col-lg-6 col-xl-4">
      <input matInput placeholder="Address 2" formControlName="addr2" type="text" />
    </mat-form-field>
    <mat-form-field class="col-12 col-lg-6 col-xl-4">
      <input matInput placeholder="City" formControlName="city" type="text" />
    </mat-form-field>
    <mat-form-field class="col-12 col-lg-6 col-xl-4">
      <input matInput placeholder="ZIP" formControlName="postalCode" type="text" />
    </mat-form-field>

    <mat-form-field class="col-12 col-lg-6 col-xl-4">
      <mat-select matInput placeholder="Country of Residence" formControlName="country">
        <mat-option *ngFor="let country of availableCountries; trackBy: trackCountries" [value]="country.countryCode">{{
          country.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="availableStates$ | async as availableStates" class="col-12 col-lg-6 col-xl-4">
      <mat-select *ngIf="availableStates?.length" matInput placeholder="State" formControlName="state">
        <mat-option *ngFor="let state of availableStates; trackBy: trackStates" [value]="state.id">{{ state.label }}</mat-option>
      </mat-select>
      <input *ngIf="!availableStates?.length" matInput placeholder="State" formControlName="state" type="text" />
    </mat-form-field>
    <div class="col-12 d-flex justify-content-between">
      <button mat-raised-button color="primary" type="reset" (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="!bankAccountForm.valid">Add Bank Account</button>
    </div>
  </div>
</form>
