import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { SubscriberComponent } from '../../shared/component-subscriber/subscriber.component';
import { UsersService } from '../../shared/users/service/users.service';
import { USER_PERMISSION } from '../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-month-end-close',
  templateUrl: './month-end-close.component.html',
  styleUrls: ['./month-end-close.component.scss'],
})
export class MonthEndCloseComponent extends SubscriberComponent implements OnInit {
  hasCRMPermission: boolean;

  constructor(private userService: UsersService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userService.checkPermission(USER_PERMISSION.CRM_ADMIN).subscribe((permitted) => {
        this.hasCRMPermission = permitted;
        this.cd.detectChanges();
      }),
    );
  }
}
