import { autoserialize, autoserializeAs } from 'cerialize';
import { isArray, isUndefined } from 'util';

import { UserPermModel } from './user-perm.model';
import { USER_PERMISSION } from './user-permissions.enum';

export class UserModel {
  readonly type = 'user';
  username: string;

  @autoserialize
  loginKey: string;

  @autoserialize
  loginId: string;

  @autoserialize
  lastname: string;

  @autoserialize
  authyId: string;

  @autoserialize
  phoneNumber: string;

  @autoserialize
  firstname: string;

  @autoserialize
  authyRegVerified: boolean;

  @autoserialize
  countryCode: string;

  @autoserialize
  email: string;

  @autoserializeAs(UserPermModel)
  perms: UserPermModel[];

  constructor(
    loginKey: string,
    loginId: string,
    lastname: string,
    authyId: string,
    phoneNumber: string,
    firstname: string,
    username: string,
    authyRegVerified: boolean,
    countryCode: string,
    email: string,
    perms: UserPermModel[],
  ) {
    this.loginKey = loginKey;
    this.loginId = loginId;
    this.lastname = lastname;
    this.authyId = authyId;
    this.phoneNumber = phoneNumber;
    this.username = username;
    this.firstname = firstname;
    this.authyRegVerified = authyRegVerified;
    this.countryCode = countryCode;
    this.email = email;
    this.perms = perms;
  }

  get id(): string {
    return this.loginId;
  }

  get key(): string {
    return this.loginKey;
  }

  get name(): string {
    let name = '';
    if (this.firstname) {
      name += this.firstname;
    }
    if (this.lastname) {
      name += ' ' + this.lastname;
    }
    if (name.length === 0) {
      name = this.loginId;
    }
    return name;
  }

  get fullname(): string {
    let fullname = '';
    if (this.firstname) {
      fullname += this.firstname;
    }
    if (this.lastname) {
      fullname += ' ' + this.lastname;
    }
    return fullname;
  }

  isOwner(reqModel): boolean {
    return !isUndefined(reqModel.ownerUuid) ? reqModel.ownerUuid === this.loginKey : false;
  }

  isSuperUser(reqModel, actionName): boolean {
    if (!isUndefined(this.perms) && actionName) {
      if (isArray(this.perms) && this.perms.length) {
        let hasSuperAccess = false;
        for (let i = 0, len = this.perms.length; i < len; i++) {
          if (!isUndefined(this.perms[i].permCode) && this.perms[i].permCode === USER_PERMISSION.CRM_ADMIN) {
            hasSuperAccess = true;
            break;
          }
        }
        return hasSuperAccess;
      }
    }
    return false;
  }

  hasAccessTo(item, action): boolean {
    if (!isUndefined(item) && !isUndefined(action)) {
      return this.isOwner(item) || this.isSuperUser(item, action);
    } else {
      return false;
    }
  }
}
