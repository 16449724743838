import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { IExternalReport } from '../../../../reports/model/i-external-report';
import { ExternalReportsService } from '../../../../reports/service/external-reports.service';
import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { IIframeDefinition } from '../../../../shared/interfaces/iframe-definition.interface';
import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { PendingOfferRiskRatingService } from '../pending-offer/pending-offer-calculator/service/pending-offer-risk-rating.service';
import { SetDefaultProgressiveAdvanceSettings } from '../pending-offer/pending-offer-calculator/store/calculator.actions';

@Component({
  selector: 'app-base-advances',
  templateUrl: './base-advances.component.html',
  styleUrls: ['./base-advances.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseAdvancesComponent extends SubscriberComponent implements OnInit {
  approvedAmount: Number;
  isPendingAdvance: boolean;
  iframeUrl: string;
  iframesDefinitions: IIframeDefinition[] = [];
  supplierKey: string;

  constructor(
    private readonly riskRatingService: PendingOfferRiskRatingService,
    private readonly store: Store<AppState>,
    private readonly externalReportsService: ExternalReportsService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
          mergeMap(() => this.externalReportsService.getDetail('SFC-AMZ,LTSR-AMZ')),
        )
        .subscribe((response: IExternalReport[]) => {
          response.map((externalReport: IExternalReport) => {
            const customParamUrl = externalReport.customParamUrl;
            this.iframesDefinitions.push({
              name: externalReport.customName ? externalReport.customName : externalReport.name,
              url: customParamUrl.replace('<supplier_key>', this.supplierKey),
            });
          });
        }),
    );

    this.subscriptions.push(
      this.riskRatingService.getDefaultProgressiveAdvanceSettings().subscribe((data) => {
        this.store.dispatch(new SetDefaultProgressiveAdvanceSettings(data));
      }),
    );
  }

  isPending(isPendingAdvance: boolean) {
    this.isPendingAdvance = isPendingAdvance;
  }

  onLoaded(approvedAmount: Number) {
    this.approvedAmount = approvedAmount;
  }
}
