import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

import { ReportsVisibility } from '../model/report-common';

// Pipes
@Pipe({
  name: 'reportFilter',
})
export class ReportFilterPipe implements PipeTransform {
  transform(data: any[], filter: ReportsVisibility): any {
    if (isNullOrUndefined(filter) || isNullOrUndefined(data)) {
      return data;
    }

    const reports = data.filter((report) => {
      return (
        (report.enabledForReports && filter === <number>ReportsVisibility.showVisible) ||
        (filter === <number>ReportsVisibility.showHidden && !report.enabledForReports) ||
        filter === <number>ReportsVisibility.showAll
      );
    });

    return reports;
  }
}
