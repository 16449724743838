import { FilteringPanelService } from '../services/filtering-panel-service/filtering-panel.service';

export abstract class BaseFilterControl {
  value: string;
  label: string;
  fieldName: string;
  defaultValue: string;
  fieldType: string;

  constructor(protected filteringPanelService: FilteringPanelService) {}

  onValueChange(value: string) {
    this.filteringPanelService.filterValueSource.next({ fieldName: this.fieldName, value, fieldType: this.fieldType });
  }
}
