<li>
  <a mat-button>
    <i class="nav-icon material-icons">apps</i>
    <span class="nav-text">Actions</span>
  </a>
  <ul>
    <li>
      <a mat-button class="mat-button prepend-icon"><span>Form Layouts</span></a>
    </li>
  </ul>
</li>

<div class="actions-add-fabs vertical" [ngClass]="{ active: open }">
  <button class="mat-fab mat-primary pull-right" (click)="open = !open">
    <i class="material-icons">menu</i>
  </button>
  <ul>
    <li>
      <a (click)="openAddChargebackDialog()">
        <button class="mat-fab mat-primary">
          <i class="material-icons">add</i>
        </button>
        <span class="label">Add chargeback</span>
      </a>
    </li>

    <li (click)="openAddReceivableDialog()">
      <button class="mat-fab mat-primary">
        <i class="material-icons">add</i>
      </button>
      <span class="label" appOpenAddReceivable>Add receivable</span>
    </li>

    <li (click)="openAddMpPaymentDialog()">
      <button class="mat-fab mat-primary">
        <i class="material-icons">add</i>
      </button>
      <span class="label" appOpenAddMpPayment>Add MP Payment</span>
    </li>

    <li (click)="openAddZeroPaymentDialog()">
      <button class="mat-fab mat-primary">
        <i class="material-icons">add</i>
      </button>
      <span class="label" appOpenAddZeroMpPayment>Add Zero MP Payment</span>
    </li>

    <li (click)="openAddNoteDialog()">
      <button class="mat-fab btn-notes">
        <i class="material-icons">message</i>
      </button>
      <span class="label">Add note</span>
    </li>

    <li (click)="openUploadFileDialog()">
      <button class="mat-fab btn-files">
        <i class="material-icons">attachment</i>
      </button>
      <span class="label">Upload file</span>
    </li>

    <li (click)="openAddTaskDialog()">
      <button class="mat-fab btn-tasks">
        <i class="material-icons">assignment</i>
      </button>
      <span class="label">Add task</span>
    </li>

    <li (click)="openSendEmailDialog()">
      <button class="mat-fab btn-emails">
        <i class="material-icons">email</i>
      </button>
      <span class="label">Send email</span>
    </li>
  </ul>
</div>
