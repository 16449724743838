<form [formGroup]="form" novalidate>
  <div formGroupName="efs" class="row">
    <p class="col-md-12"><strong>EFS account information</strong></p>

    <mat-form-field class="col-md-12">
      <input matInput [matDatepicker]="picker" placeholder="EFS Creation Date" formControlName="startDate" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="col-md-12">
      <input matInput placeholder="Company ID" formControlName="companyId" type="text" />
    </mat-form-field>

    <mat-form-field class="col-md-12">
      <input matInput placeholder="Card Number" formControlName="cardNumber" type="text" />
    </mat-form-field>

    <div ngxErrors="efs.cardNumber" class="col-md-12">
      <mat-error ngxError="error" when="touched"> This must be a valid card number </mat-error>
    </div>

    <mat-form-field class="col-md-6">
      <input matInput placeholder="Expiration Month (MM/YY)" formControlName="cardExp" type="text" />
    </mat-form-field>

    <div ngxErrors="efs.cardExp" class="col-md-12">
      <mat-error ngxError="error"> This must be a valid Expiration Date (MM/YY) </mat-error>
    </div>

    <mat-form-field class="col-md-6">
      <input matInput placeholder="CVC" formControlName="cardCvc" type="text" />
    </mat-form-field>

    <div ngxErrors="efs.cardCvc" class="col-md-12">
      <mat-error ngxError="error"> This must be a valid CVC </mat-error>
    </div>
  </div>
</form>
