import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';

export function EditCellRenderer(): string {
  return `<div class="d-table m-auto">
            <mat-icon class="mat-icon material-icons pointer" role="img" aria-hidden="true">edit</mat-icon>
         </div>`;
}

export function EditColumn(edit: (params) => Observable<void>, optionalConfig: ColDef = null): ColDef {
  let config: ColDef = {
    field: 'delete_col',
    headerName: '',
    colId: 'delete_col',
    width: 50,
    sortable: false,
    resizable: false,
    suppressMenu: true,
    onCellClicked: (params) => edit(params),
    cellRenderer: EditCellRenderer,
  };

  if (optionalConfig) {
    config = { ...config, ...optionalConfig };
  }

  return config;
}
