import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { skip } from 'rxjs/operators';

import { CrmStates } from '../../shared/layout/layout.enums';
import * as fromLayoutActions from '../../shared/layout/store/layout.actions';
import { LayoutState } from '../../shared/layout/store/layout.reducers';
import { AppState } from '../../store/app.reducers';
import { CrmItemStatus } from '../shared/crm.enums';
import { CrmItemClearStatus, selectPendingCrmStates } from '../store/crm.actions';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-crm-sidebar',
  styleUrls: ['./crm-sidebar.component.scss'],
  templateUrl: './crm-sidebar.component.html',
})
export class CrmSidebarComponent extends SubscriberComponent implements OnInit {
  public isOpen: boolean;
  public isFixed: boolean;
  private layoutState$: Observable<LayoutState>;

  constructor(private store: Store<AppState>, private toastrService: ToastrService) {
    super();
  }

  ngOnInit() {
    this.layoutState$ = this.store.select('layout');
    this.subscriptions.push(
      this.layoutState$.subscribe((data) => {
        this.isOpen = data.crmPanelState === CrmStates.OPEN;
        this.isFixed = data.crmPanelState === CrmStates.FIXED;
      }),
    );

    this.subscriptions.push(
      this.store
        .select(selectPendingCrmStates)
        .pipe(skip(1))
        .subscribe((items) => {
          Object.keys(items).map((id) => {
            switch (items[id]) {
              case CrmItemStatus.ERROR:
                this.store.dispatch(new CrmItemClearStatus({ actionId: id }));
                this.toastrService.error('Could not save changes. Try again!');
                break;
              case CrmItemStatus.SUCCESS:
                this.store.dispatch(new CrmItemClearStatus({ actionId: id }));
                this.toastrService.success('Item saved successfully!');
                break;
            }
          });
        }),
    );
  }

  open() {
    this.store.dispatch(new fromLayoutActions.CrmPanelOpen());
  }

  close() {
    this.store.dispatch(new fromLayoutActions.CrmPanelClose());
  }

  toggleOpen() {
    const nextState = this.isOpen ? new fromLayoutActions.CrmPanelClose() : new fromLayoutActions.CrmPanelOpen();
    this.store.dispatch(nextState);
  }

  toggleFixed() {
    const nextState = this.isFixed ? new fromLayoutActions.CrmPanelOpen() : new fromLayoutActions.CrmPanelFix();
    this.store.dispatch(nextState);
  }
}
