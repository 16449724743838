import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store/src/store';
import { ToastrService } from 'ngx-toastr';

import { AppConfigService } from '../../../config';
import { B2bDocumentService } from '../../../shared/b2b-document/b2b-document.service';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { PybDocumentsService } from '../../../shared/pyb-documents/pyb-documents.service';
import { AppState } from '../../../store/app.reducers';
import { NoteItemComponent } from '../note-item/note-item.component';

@Component({
  selector: 'app-note-payment-item',
  templateUrl: './note-payment-item.component.html',
  styleUrls: ['../../shared/crm-tools.scss', '../../shared/timeline-item.scss'],
})
export class NotePaymentItemComponent extends NoteItemComponent {
  constructor(
    dialog: MatDialog,
    toastrService: ToastrService,
    store: Store<AppState>,
    appConfig: AppConfigService,
    b2bDownloadService: B2bDocumentService,
    httpClient: HttpClient,
    renderer: Renderer2,
    cd: ChangeDetectorRef,
    featureFlagsService: FeatureFlagsService,
    pybDocuments: PybDocumentsService,
    sanitizer: DomSanitizer,
  ) {
    super(
      dialog,
      store,
      appConfig,
      b2bDownloadService,
      httpClient,
      renderer,
      featureFlagsService,
      pybDocuments,
      toastrService,
      sanitizer,
      cd,
    );
  }
  addComment = false;
}
