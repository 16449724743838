<span>Instant Access</span>
<mat-divider></mat-divider>

<mat-table [dataSource]="dataSourceIAccess">
  <ng-container matColumnDef="marketplace">
    <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.marketplace }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="reserveRate">
    <mat-header-cell *matHeaderCellDef>Reserve Rate</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.reserveRate }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="fee">
    <mat-header-cell *matHeaderCellDef>Fee</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.fee }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="uwStatus">
    <mat-header-cell *matHeaderCellDef>UW Status</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.uwStatus | capitalize }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedInstantAccessColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedInstantAccessColumns"></mat-row>
</mat-table>
