<section class="container-fluid flex-container">
  <article class="article flex-container">
    <div class="card flex-container">
      <div class="card-content flex-container">
        <div class="report flex-container">
          <app-data-table-ag-grid style="height: 80%; width: 100%" [customGridOptions]="customGridOptions"></app-data-table-ag-grid>
        </div>
      </div>
    </div>
  </article>
</section>
