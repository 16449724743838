import { SearchDataModel } from './search-data-model';

const DISPLAYED_SUFFIX = '_displayed';

interface ISourceSupplier {
  EIN: string;
  SSN: string;
  email: string;
  firstName: string;
  lastName: string;
  legalName: string;
  persons: any[];
  phone: string;
  supplierKey: string;
}

interface ISalesRep {
  email: string;
  name: string;
}

interface IHitSource {
  cancellationStatus: any;
  dealInfo: any;
  marketplaces: any[];
  payabilityStatus: any;
  registrationDate: any;
  salesRep: ISalesRep;
  supplier: ISourceSupplier;
}

interface IHit {
  highlight: any;
  _source: IHitSource;
}

interface IResponseHits {
  hits: IHit[];
  max_score: number;
  total: number;
}

export interface ISearchResponse {
  hits: IResponseHits;
  timed_out: boolean;
  took: number;
}

export interface INameAndValuePair {
  fieldName: string;
  fieldValue: string;
}

export class ElasticHighlightsService {
  constructor() {}

  static getHitsFromResponse(response: ISearchResponse): IHit[] {
    return response.hits.hits;
  }

  static getSourceFromHit(hit: IHit): IHitSource {
    return hit._source;
  }

  static getHighlightFromHit(hit: IHit): any {
    return hit.highlight;
  }

  static highlightTextByPhrase(text: string, phrase: string) {
    const searchPhrase = ElasticHighlightsService.cleanupPhrase(phrase).split(' ').join('|');
    return text.replace(new RegExp('(' + searchPhrase + ')', 'gi'), '<span class="search-highlight">$1</span>');
  }

  static cleanupPhrase(phrase: string): string {
    const escapedPhrase = ElasticHighlightsService.escapeRegExp(phrase);
    return escapedPhrase.trim().replace(/\s+/g, ' ');
  }

  static escapeRegExp(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
  }

  static shouldBeHighlighted(highlight: any, highlightedKey: string): boolean {
    return highlight.hasOwnProperty(highlightedKey);
  }

  static prepareDisplayedField(source: IHitSource, sourceKey: string, highlight: any, searchPhrase: string) {
    const highlightedKey = sourceKey;
    const text = typeof source[sourceKey] === 'string' ? source[sourceKey].trim().replace(/<(.|\n)*?>/g, '') : source[sourceKey];
    return ElasticHighlightsService.shouldBeHighlighted(highlight, highlightedKey)
      ? ElasticHighlightsService.highlightTextByPhrase(text, searchPhrase)
      : text;
  }

  static prepareHighlightHits(hit: IHit, searchPhrase: string) {
    const highlight = ElasticHighlightsService.getHighlightFromHit(hit);
    const source = ElasticHighlightsService.getSourceFromHit(hit);
    const objectToReturn = {
      ...source,
    };

    for (const sourceKey in source) {
      if (source.hasOwnProperty(sourceKey)) {
        const displayedKey = sourceKey + DISPLAYED_SUFFIX;
        objectToReturn[displayedKey] = ElasticHighlightsService.prepareDisplayedField(source, sourceKey, highlight, searchPhrase);
      }
    }
    return objectToReturn;
  }

  static convertHighlightObjectToArray(objToConvert: any): INameAndValuePair[] {
    const highlightedFields: INameAndValuePair[] = [];
    for (const key in objToConvert) {
      if (objToConvert.hasOwnProperty(key)) {
        if (key.includes(DISPLAYED_SUFFIX)) {
          const keyWithoutSuffix = key.split(DISPLAYED_SUFFIX)[0];
          if (objToConvert[key] !== objToConvert[keyWithoutSuffix]) {
            highlightedFields.push({
              fieldName: keyWithoutSuffix.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
              fieldValue: objToConvert[key],
            });
          }
        }
      }
    }

    return highlightedFields;
  }

  static highlightObj(response: ISearchResponse, searchPhrase: string): SearchDataModel {
    const hits = ElasticHighlightsService.getHitsFromResponse(response);
    const highlightedHits = hits.map((hit) => ElasticHighlightsService.prepareHighlightHits(hit, searchPhrase));

    const dto = {
      totalCount: response.hits.total,
      results: highlightedHits,
    };
    return new SearchDataModel(dto);
  }
}
