<mat-table *ngIf="dataSource" #table [dataSource]="dataSource">
  <ng-container matColumnDef="feePeriods">
    <mat-header-cell *matHeaderCellDef> Fee Periods </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.feePeriods }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="periodLength">
    <mat-header-cell *matHeaderCellDef> Period Length </mat-header-cell>
    <mat-cell [ngClass]="{ 'text-warn': isTotalPeriodToLong }" *matCellDef="let element"> {{ element.periodLength }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="fee">
    <mat-header-cell *matHeaderCellDef> Fee % </mat-header-cell>
    <mat-cell [ngClass]="{ 'text-warn': isTotalFeeIncorrect }" *matCellDef="let element"> {{ element.fee }} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
