import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-event-preview',
  templateUrl: './event-preview.component.html',
  styleUrls: ['./event-preview.component.scss'],
})
export class EventPreviewComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<EventPreviewComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {}

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }
}
