<h1 mat-dialog-title>{{ editMode ? 'Edit note' : 'Add note' }}</h1>
<div mat-dialog-content>
  <form [formGroup]="noteAddEditForm" #formEditAddNote>
    <app-editor formControlName="body" data-test="crmNotesEditor"></app-editor>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    (click)="saveNote()"
    [disabled]="noteAddEditForm.invalid"
    tabindex="2"
    [color]="'accent'"
    data-test="crmNoteEditorSave"
  >
    {{ pending ? '' : 'Save' }}
    <app-loading-spinner [size]="1" *ngIf="pending"></app-loading-spinner>
  </button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'" tabindex="-1" data-test="crmNoteEditorCancel">Cancel</button>
</div>
