import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isUndefined } from 'util';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { AppConfigService } from '../../../config';
import { B2bDocumentService } from '../../../shared/b2b-document/b2b-document.service';
import { FEATURE_FLAGS } from '../../../shared/feature-flags/feature-flags.const';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { PybDocument, UploadFile } from '../../../shared/pyb-documents/pyb-documents.interface';
import { PybDocumentsService } from '../../../shared/pyb-documents/pyb-documents.service';
import { AppState } from '../../../store/app.reducers';
import { FileTypeModel } from '../../models/file-type.model';
import { FileTypesService } from '../../services/file-types.service';
import { CrmItemStatus } from '../../shared/crm.enums';
import { AddCrmFile, selectCrmTargetUuid, selectPendingCrmStates } from '../../store/crm.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent extends SubscriberComponent implements OnInit {
  fileUploadForm: FormGroup;
  loading = false;
  actionId = new Date().toString();

  fileType = new FormControl();
  fileTypeOptions: FileTypeModel[];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FileUploadComponent>,
    public b2bDocumentService: B2bDocumentService,
    private store: Store<AppState>,
    private fileTypesService: FileTypesService,
    private appConfig: AppConfigService,
    private pybDocumentsService: PybDocumentsService,
    private featureFlagsService: FeatureFlagsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectPendingCrmStates).subscribe((crmStates) => {
        if (!isUndefined(crmStates[this.actionId])) {
          if (crmStates[this.actionId] === CrmItemStatus.SUCCESS) {
            this.closeDialog();
          }
        }
      }),
    );

    this.subscriptions.push(
      this.store.select(selectCrmTargetUuid).subscribe((targetUuid) => {
        this.fileUploadForm.patchValue({ wniosekId: targetUuid });
      }),
    );

    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        if (!isUndefined(user)) {
          this.fileUploadForm.patchValue({
            user_login: user.name,
            user_email: user.email,
          });
        }
      }),
    );

    this.subscriptions.push(
      this.fileTypesService.getFileTypes().subscribe((types) => {
        this.fileTypeOptions = types;
      }),
    );

    this.subscriptions.push(
      this.fileType.valueChanges.subscribe((val) => {
        this.fileUploadForm.patchValue({
          docType: val.name,
          docTypeUuid: val.uuid,
        });
      }),
    );
  }

  createForm() {
    this.fileUploadForm = this.fb.group({
      file: [''],
      gci: [''],
      wniosekId: ['', Validators.required],
      docType: ['', Validators.required],
      docTypeUuid: ['', Validators.required],
      user_login: ['', Validators.required],
      user_email: ['', Validators.required],
    });
  }

  uploadFile() {
    if (this.fileUploadForm.valid) {
      const dataToSave = this.fileUploadForm.value;
      if (this.featureFlagsService.isEnabled(FEATURE_FLAGS.PYB_DOCUMENTS)) {
        this.pybDocumentsUpload(dataToSave);
        return;
      }
      this.b2bDocumentUpload(dataToSave);
    }
  }

  pybDocumentsUpload(dataToSave: any): void {
    this.subscriptions.push(
      this.pybDocumentsService.uploadFile([dataToSave.file]).subscribe((response: PybDocument[]) => {
        const pybDocument: UploadFile = {
          doc: {
            docType: dataToSave.docType,
            docTypeUuid: dataToSave.docTypeUuid,
            name: response[0].name,
            thumbnailUUID: response[0].thumbnail.id,
            uuid: response[0].id,
          },
          user: {
            email: dataToSave.user_email,
            login: dataToSave.user_login,
          },
        };
        this.store.dispatch(new AddCrmFile({ data: pybDocument, actionId: this.actionId }));
      }),
    );
  }

  b2bDocumentUpload(dataToSave: any): void {
    dataToSave.gci = this.appConfig.env.workflowGciId;
    this.subscriptions.push(
      this.b2bDocumentService.uploadFile(this.fileUploadForm.value).subscribe((response) => {
        const b2bUploaded = <any>{ ...response };
        b2bUploaded.doc.docType = dataToSave.docType;
        b2bUploaded.doc.docTypeUuid = dataToSave.docTypeUuid;
        b2bUploaded['user'] = {
          login: dataToSave.user_login,
          email: dataToSave.user_email,
        };
        this.store.dispatch(new AddCrmFile({ data: b2bUploaded, actionId: this.actionId }));
      }),
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
