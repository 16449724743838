import { autoserialize, autoserializeIndexable, inheritSerialization } from 'cerialize';
import * as moment from 'moment';
import { isUndefined } from 'util';

import { TaskFollower } from './../tasks/models/task-follower.model';
import { BaseCrmModel } from './base-crm.model';
import { CommentModel } from './comment.model';

@inheritSerialization(BaseCrmModel)
export class TaskModel extends BaseCrmModel {
  @autoserialize public assigneeEmail: string;
  @autoserialize public assigneeName: string;
  @autoserialize public assigneeType: string;
  @autoserialize public assigneeUuid: string;
  @autoserialize public body: string;
  @autoserialize public commentsCount: number;
  @autoserialize public createdByName: string;
  @autoserialize public dueDate: number;
  @autoserialize public itemNumber: number;
  @autoserialize public priority: string;
  @autoserialize public status: string;
  @autoserialize public supplierName: string;
  @autoserialize public taskUrlTemplate?: string;
  @autoserialize public title: string;
  @autoserialize public followers: TaskFollower[];
  @autoserialize public category?: string;
  @autoserializeIndexable(CommentModel) public latestComments: CommentModel[];

  private _isOverdue?: boolean;

  constructor(
    createdAt: string,
    ownerUuid: string,
    targetType: string,
    targetUuid: string,
    type: string,
    uuid: string,
    assigneeEmail: string,
    assigneeName: string,
    assigneeType: string,
    assigneeUuid: string,
    body: string,
    commentsCount: number,
    createdByName: string,
    dueDate: number,
    itemNumber: number,
    latestComments: CommentModel[],
    priority: string,
    status: string,
    supplierName: string,
    taskUrlTemplate: string,
    title: string,
    updatedAt: string,
    followers: TaskFollower[],
    category: string,
  ) {
    super(createdAt, ownerUuid, targetType, targetUuid, type, uuid, updatedAt);
    this.assigneeEmail = assigneeEmail;
    this.assigneeName = assigneeName;
    this.assigneeType = assigneeType;
    this.assigneeUuid = assigneeUuid;
    this.body = body;
    this.commentsCount = commentsCount;
    this.createdByName = createdByName;
    this.dueDate = dueDate;
    this.itemNumber = itemNumber;
    this.latestComments = latestComments;
    this.priority = priority;
    this.status = status;
    this.supplierName = supplierName;
    this.taskUrlTemplate = taskUrlTemplate;
    this.title = title;
    this.type = type;
    this.followers = followers ? followers : [];
    this.category = category;
  }

  get isOverdue() {
    if (isUndefined(this._isOverdue)) {
      if (moment.unix(this.dueDate).isSameOrAfter(moment(Date.now()), 'day')) {
        this._isOverdue = false;
      } else {
        this._isOverdue = true;
      }
    }

    return this._isOverdue;
  }
}
