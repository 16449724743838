import { Action } from '@ngrx/store';

import { UwStatus } from '../../../../../enums/uw-status';
import { IProgressivePricingSettings, UpdatePeriodRowI } from './calculator-interfaces';

export const SET_UNDERWRITING_RISK_RATING = 'SET_UNDERWRITING_RISK_RATING';
export const SET_DEFAULT_PROGRESSIVE_ADVANCE_SETTINGS = 'SET_DEFAULT_PROGRESSIVE_ADVANCE_SETTINGS';

export const SET_APPROVED_AMOUNT = 'SET_APPROVED_AMOUNT';
export const SET_NET_DELIVERY_AMOUNT = 'SET_NET_DELIVERY_AMOUNT';
export const SET_RESERVE_FOR_REPAYMENTS = 'SET_RESERVE_FOR_REPAYMENTS';

export const SET_FEE = 'SET_FEE';
export const SET_PERIOD_LENGTH = 'SET_PERIOD_LENGTH';

export const ADD_PERIOD = 'ADD_PERIOD';
export const REMOVE_PERIOD = 'REMOVE_PERIOD';

export const CALCULATOR_RESET = 'CALCULATOR_RESET';

export const SET_TO_READONLY = 'SET_TO_READONLY';
export const SET_TO_EDITABLE = 'SET_TO_EDITABLE';

export const SET_REQUESTED_AMOUNT = 'SET_REQUESTED_AMOUNT';
export const SET_NEW_OFFER = 'SET_NEW_OFFER';

export const SET_ELIGIBILITY_DATA = 'SET_ELIGIBILITY_DATA';

export const SET_REQUEST_NOTES = 'SET_REQUEST_NOTES';
export const SET_UNDERWRITING_NOTES = 'SET_UNDERWRITING_NOTES';
export const SET_GRACE_PERIOD_END_DATE = 'SET_GRACE_PERIOD_END_DATE';
export const SET_GRACE_PERIOD_SAVED_END_DATE = 'SAVE_GRACE_PERIOD_SAVED_END_DATE';
export const SET_NO_REBATE = 'SET_NO_REBATE';
export const SET_NO_REBATE_SAVED = 'SET_NO_REBATE_SAVED';
export const SET_RETURN_STRATEGY = 'SET_RETURN_STRATEGY';
export const SET_RETURN_STRATEGY_SAVED = 'SET_RETURN_STRATEGY_SAVED';
export const SET_EXPECTED_MONTHLY_EARNINGS = 'SET_EXPECTED_MONTHLY_EARNINGS';
export const SET_EXPECTED_DURATION = 'SET_EXPECTED_DURATION';
export const SET_FORCE_FULL = 'SET_FORCE_FULL';
export const SET_FORCE_FULL_SAVED = 'SET_FORCE_FULL_SAVED';
export const SET_UW_STATUS = 'SET_UW_STATUS';

export const SET_STATE_FROM_PERSISTED_DATA = 'SET_STATE_FROM_PERSISTED_DATA';

export const CALCULATOR_HARD_RESET = 'CALCULATOR_HARD_RESET';

export class UpdatePeriodRowValues implements UpdatePeriodRowI {
  constructor(public periodNumber: number, public newValue: number) {}
}

export class SetFee implements Action {
  readonly type = SET_FEE;

  constructor(public payload: UpdatePeriodRowI) {}
}

export class SetPeriodLength implements Action {
  readonly type = SET_PERIOD_LENGTH;

  constructor(public payload: UpdatePeriodRowI) {}
}

export class AddPeriod implements Action {
  readonly type = ADD_PERIOD;

  constructor() {}
}

export class RemovePeriod implements Action {
  readonly type = REMOVE_PERIOD;

  constructor(public payload: number) {}
}

export class SetApprovedAmount implements Action {
  readonly type = SET_APPROVED_AMOUNT;

  constructor(public payload: number) {}
}

export class SetNetDeliveryAmount implements Action {
  readonly type = SET_NET_DELIVERY_AMOUNT;

  constructor(public payload: number) {}
}

export class SetRequestedAmount implements Action {
  readonly type = SET_REQUESTED_AMOUNT;

  constructor(public payload: number) {}
}

export class SetReserveForRepayments implements Action {
  readonly type = SET_RESERVE_FOR_REPAYMENTS;

  constructor(public payload: number) {}
}

export class SetUnderwritingRiskRating implements Action {
  readonly type = SET_UNDERWRITING_RISK_RATING;

  constructor(public payload: number) {}
}

export class CalculatorReset implements Action {
  readonly type = CALCULATOR_RESET;

  constructor() {}
}

export class CalculatorHardReset implements Action {
  readonly type = CALCULATOR_HARD_RESET;

  constructor() {}
}

export class SetDefaultProgressiveAdvanceSettings implements Action {
  readonly type = SET_DEFAULT_PROGRESSIVE_ADVANCE_SETTINGS;

  constructor(public payload: IProgressivePricingSettings) {}
}

export class SetToReadOnly implements Action {
  readonly type = SET_TO_READONLY;

  constructor() {}
}

export class SetToEditable implements Action {
  readonly type = SET_TO_EDITABLE;

  constructor() {}
}

export class SetNewOffer implements Action {
  readonly type = SET_NEW_OFFER;

  constructor(public payload: boolean) {}
}

export class SetEligibilityData implements Action {
  readonly type = SET_ELIGIBILITY_DATA;

  constructor(public payload: any) {}
}

export class SetRequestNotes implements Action {
  readonly type = SET_REQUEST_NOTES;

  constructor(public payload: string) {}
}

export class SetUnderwritingNotes implements Action {
  readonly type = SET_UNDERWRITING_NOTES;

  constructor(public payload: string) {}
}

export class SetGracePeriodEndDate implements Action {
  readonly type = SET_GRACE_PERIOD_END_DATE;
  constructor(public payload: any) {}
}

export class SetSavedGracedPeriodEndDate implements Action {
  readonly type = SET_GRACE_PERIOD_SAVED_END_DATE;
  constructor(public payload: any) {}
}

export class SetNoRebate implements Action {
  readonly type = SET_NO_REBATE;
  constructor(public payload: any) {}
}

export class SetSavedNoRebate implements Action {
  readonly type = SET_NO_REBATE_SAVED;
  constructor(public payload: any) {}
}

export class SetReturnStrategy implements Action {
  readonly type = SET_RETURN_STRATEGY;
  constructor(public payload: any) {}
}

export class SetSavedReturnStrategy implements Action {
  readonly type = SET_RETURN_STRATEGY_SAVED;
  constructor(public payload: any) {}
}

export class SetForceFull implements Action {
  readonly type = SET_FORCE_FULL;
  constructor(public payload: any) {}
}

export class SetForceFullSaved implements Action {
  readonly type = SET_FORCE_FULL_SAVED;
  constructor(public payload: any) {}
}

export class SetStateFromPersistedData implements Action {
  readonly type = SET_STATE_FROM_PERSISTED_DATA;

  constructor(public payload: any) {}
}

export class SetExpectedMonthlyEarnings implements Action {
  readonly type = SET_EXPECTED_MONTHLY_EARNINGS;
  constructor(public payload: number) {}
}

export class SetExpectedDuration implements Action {
  readonly type = SET_EXPECTED_DURATION;
  constructor(public payload: number) {}
}

export class SetUwStatus implements Action {
  readonly type = SET_UW_STATUS;
  constructor(public payload: UwStatus) {}
}

export type CalcActions =
  | AddPeriod
  | RemovePeriod
  | SetApprovedAmount
  | SetNetDeliveryAmount
  | SetRequestedAmount
  | SetReserveForRepayments
  | SetPeriodLength
  | SetFee
  | SetUnderwritingRiskRating
  | CalculatorReset
  | CalculatorHardReset
  | SetDefaultProgressiveAdvanceSettings
  | SetToEditable
  | SetToReadOnly
  | SetNewOffer
  | SetEligibilityData
  | SetRequestNotes
  | SetUnderwritingNotes
  | SetStateFromPersistedData
  | SetExpectedMonthlyEarnings
  | SetExpectedDuration;

export interface CalcActionsWithPayload extends Action {
  payload: any;
}
