import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { DIALOG_SIZE } from '../../../../shared/dialog.config';
import { snakeCaseToTitleCase } from '../../../../shared/functions/snake-case-to-title-case';
import { AppState } from '../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';
import { SupplierAdvancesService } from '../../../supplier/supplier-advances/service/supplier-advances.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { PendingOfferDetailsComponent } from './pending-offer-details/pending-offer-details.component';

enum ACTIVE_ADVANCE_STATUS {
  ACTIVE = 'Active',
}

@Component({
  selector: 'app-pending-offer',
  templateUrl: './pending-offer.component.html',
})
export class PendingOfferComponent extends SubscriberComponent implements OnInit {
  pendingOfferForm: FormGroup;
  pendingOfferData: any;
  requestOfferCaption: string;
  requestOfferDisable: boolean;

  private supplierKey: string;

  private readonly REQUEST_RELINE = 'Request Re-line';
  private readonly REQUEST_LINE = 'Request Line';

  @Output() onLoaded = new EventEmitter<Number>();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly supplierAdvancesService: SupplierAdvancesService,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<AppState>,
    public readonly dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
          tap(() => this.updateForm()),
          mergeMap((data) => this.supplierAdvancesService.getActiveAdvance(data.supplierKey)),
        )
        .subscribe(
          (activeAdvanceData) => {
            if (!activeAdvanceData) {
              return;
            }
            this.requestOfferCaption = activeAdvanceData.status === ACTIVE_ADVANCE_STATUS.ACTIVE ? this.REQUEST_RELINE : this.REQUEST_LINE;
            this.requestOfferDisable = activeAdvanceData.status === ACTIVE_ADVANCE_STATUS.ACTIVE;
          },
          () => (this.requestOfferCaption = this.REQUEST_LINE),
        ),
    );
  }

  openDetailsDialog() {
    const requestKey = this.pendingOfferData.advance_offer_request_key;

    const dialogRef = this.dialog.open(PendingOfferDetailsComponent, {
      width: DIALOG_SIZE.MEDIUM3.width,
      data: requestKey,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {}));
  }

  redirectToDetails() {
    this.router.navigate([`./pending-request/${this.pendingOfferData.advance_offer_request_key}`], { relativeTo: this.route });
  }

  redirectToNewOffer() {
    this.router.navigate(['./progressive-details'], { relativeTo: this.route });
  }

  redirectToProgressiveDetails() {
    let progressiveUrl = './progressive-details/';
    progressiveUrl += this.pendingOfferData.advance_offer_request_key ? this.pendingOfferData.advance_offer_request_key : '';
    this.router.navigate([progressiveUrl], { relativeTo: this.route });
  }

  private createForm() {
    this.pendingOfferForm = this.formBuilder.group({
      approved_amount: '',
      uw_status: '',
      offer_status: '',
      product_selection_feedback_data: '',
    });
  }

  private updateForm() {
    this.subscriptions.push(
      this.supplierAdvancesService.getAdvanceRequest(this.supplierKey).subscribe((pendingOfferData) => {
        this.pendingOfferData = pendingOfferData;
        if (this.pendingOfferData) {
          this.onLoaded.emit(pendingOfferData.approved_amount);
          this.patchValue();
        }
      }),
    );
  }

  private patchValue() {
    this.pendingOfferForm.patchValue({
      approved_amount: this.pendingOfferData.approved_amount,
      uw_status: this.pendingOfferData.uw_status,
      offer_status: this.pendingOfferData.offer_status,
      product_selection_feedback_data: this.prepareProductSelectionFeedback(),
    });
  }

  private prepareProductSelectionFeedback(): string {
    const FEEDBACK_DATA = this.pendingOfferData.product_selection_feedback_data;
    if (!FEEDBACK_DATA) {
      return '';
    }

    if (!FEEDBACK_DATA.product) {
      return '';
    }

    return snakeCaseToTitleCase(FEEDBACK_DATA.product);
  }
}
