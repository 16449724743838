import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';
import { SupplierAdvancesService } from './../../service/supplier-advances.service';

@Component({
  selector: 'app-pending-offer-details',
  templateUrl: './pending-offer-details.component.html',
  styleUrls: ['./pending-offer-details.component.scss'],
})
export class PendingOfferDetailsComponent extends SubscriberComponent implements OnInit {
  advanceFeeHint = '';
  extraReserveHint = '';
  isLoading = true;
  isRequestSending = false;
  offerDetailsForm: FormGroup;
  offerDetailsData: any;
  riskRatings = ['Very Good', 'Good', 'Average', 'Risky', 'Very Risky'];
  weeklyFeeHint = '';

  constructor(
    private formBuilder: FormBuilder,
    private supplierAdvancesService: SupplierAdvancesService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.getData();
  }

  sendPendingOffer() {
    const formData = { ...this.offerDetailsForm.value };

    formData.request_key = this.offerDetailsData.advance_offer_request_key;

    this.subscriptions.push(
      this.supplierAdvancesService.postRequest(formData).subscribe(
        () => {
          this.toastrService.success('Offer has been successfully updated.');
          this.redirectToBase();
        },
        (error) => {
          this.toastrService.error(error.message);
        },
      ),
    );
  }

  redirectToBase() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  private createForm() {
    this.offerDetailsForm = this.formBuilder.group({
      approved_amount: '',
      approved_net_delivery_amount: '',
      extra_reserve_percent: '',
      net_delivery_amount: '',
      reserve_total_amount: '',
      request_key: '',
      request_notes: '',
      requested_advance_reserve_percent: '',
      requested_amount: '',
      requested_reserve_total_amount: '',
      underwriter_notes: '',
      underwritter_det_avg_mon_remittance: '',
      underwriting_risk_profile: '',
      weekly_fee_percent: '',
    });
  }

  private getData() {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        const requestKey = params['requestKey'];
        this.subscriptions.push(
          (this.offerDetailsData = this.supplierAdvancesService.getOfferDetails(requestKey).subscribe((offerDetailsData) => {
            this.isLoading = false;
            this.offerDetailsData = offerDetailsData;
            this.offerDetailsData.last_login_id = this.offerDetailsData.last_login_id.split(':', 2)[1];
            this.patchValue();
          })),
        );
      }),
    );
  }

  private patchValue() {
    const netDeliveryAmount = this.offerDetailsData.requested_amount - this.offerDetailsData.requested_reserve_total_amount;
    const approvedNetDeliveryAmount = this.offerDetailsData.approved_amount - this.offerDetailsData.reserve_total_amount;

    this.offerDetailsForm.patchValue({
      approved_amount: this.offerDetailsData.approved_amount,
      approved_net_delivery_amount: approvedNetDeliveryAmount,
      approved_reserve_total_amount: this.offerDetailsData.reserve_total_amount,
      extra_reserve_percent: this.offerDetailsData.extra_reserve_percent,
      net_delivery_amount: netDeliveryAmount,
      request_notes: this.offerDetailsData.request_notes,
      requested_amount: this.offerDetailsData.requested_amount,
      requested_advance_reserve_percent: this.offerDetailsData.requested_advance_reserve_percent,
      requested_reserve_total_amount: this.offerDetailsData.requested_reserve_total_amount,
      reserve_total_amount: this.offerDetailsData.reserve_total_amount,
      underwriter_notes: this.offerDetailsData.underwriter_notes,
      underwriting_risk_profile: this.offerDetailsData.underwriting_risk_profile,
      underwritter_det_avg_mon_remittance: this.offerDetailsData.underwritter_det_avg_mon_remittance,
      weekly_fee_percent: this.offerDetailsData.weekly_fee_percent,
    });

    this.subscribeToAdvanceReservePercentValueChanges();
    this.subscribeToApprovedAmountValueChanges();
    this.subscribeToExtraReservePercentValueChanges();
    this.subscribeToRequestedAmountValueChanges();
    this.subscribeToWeeklyFeePercentValueChanges();
  }

  private subscribeToAdvanceReservePercentValueChanges() {
    this.subscriptions.push(
      this.offerDetailsForm.get('requested_advance_reserve_percent').valueChanges.subscribe((reservePercent) => {
        const requestedAmount = this.offerDetailsForm.get('requested_amount').value;
        const approvedAmount = this.offerDetailsForm.get('approved_amount').value;
        const reserveTotalAmount = (requestedAmount * reservePercent) / 100;
        const approvedTotalAmount = (approvedAmount * reservePercent) / 100;
        const originalValue = this.offerDetailsData.requested_advance_reserve_percent;

        this.offerDetailsForm.get('requested_reserve_total_amount').setValue((requestedAmount * reservePercent) / 100);
        this.offerDetailsForm.get('reserve_total_amount').setValue((approvedAmount * reservePercent) / 100);
        this.offerDetailsForm.get('net_delivery_amount').setValue(requestedAmount - reserveTotalAmount);
        this.offerDetailsForm.get('approved_net_delivery_amount').setValue(approvedAmount - approvedTotalAmount);

        if (reservePercent > originalValue) {
          this.advanceFeeHint = 'Changing the Advance Fee Reserve will give a lower advance net distribution amount to the supplier.';
        } else if (reservePercent < originalValue) {
          this.advanceFeeHint = 'Changing the Advance Fee Reserve will give a higher advance net distribution amount to the supplier.';
        } else {
          this.advanceFeeHint = '';
        }
      }),
    );
  }

  private subscribeToApprovedAmountValueChanges() {
    this.subscriptions.push(
      this.offerDetailsForm.get('approved_amount').valueChanges.subscribe((approvedAmount) => {
        const reservePercent = this.offerDetailsForm.get('requested_advance_reserve_percent').value / 100;
        const reserveTotalAmount = approvedAmount * reservePercent;

        this.offerDetailsForm.get('reserve_total_amount').setValue(reserveTotalAmount);
        this.offerDetailsForm.get('approved_net_delivery_amount').setValue(approvedAmount - reserveTotalAmount);
      }),
    );
  }

  private subscribeToExtraReservePercentValueChanges() {
    this.subscriptions.push(
      this.offerDetailsForm.get('extra_reserve_percent').valueChanges.subscribe((reserveAmount) => {
        const originalValue = this.offerDetailsData.extra_reserve_percent;

        if (reserveAmount > originalValue) {
          this.extraReserveHint = 'Changing the reserve for repayment will allocate additional funds for future advance repayments.';
        } else if (reserveAmount < originalValue) {
          this.extraReserveHint = 'Changing the reserve for repayment will allocate lesser funds for future advance repayments';
        } else {
          this.extraReserveHint = '';
        }
      }),
    );
  }

  private subscribeToRequestedAmountValueChanges() {
    this.subscriptions.push(
      this.offerDetailsForm.get('requested_amount').valueChanges.subscribe((requestedAmount) => {
        const reservePercent = this.offerDetailsForm.get('requested_advance_reserve_percent').value / 100;
        const reserveTotalAmount = requestedAmount * reservePercent;

        this.offerDetailsForm.get('requested_reserve_total_amount').setValue(reserveTotalAmount);
        this.offerDetailsForm.get('net_delivery_amount').setValue(requestedAmount - reserveTotalAmount);
      }),
    );
  }

  private subscribeToWeeklyFeePercentValueChanges() {
    this.subscriptions.push(
      this.offerDetailsForm.get('weekly_fee_percent').valueChanges.subscribe((weeklyFeePercent) => {
        const originalValue = this.offerDetailsData.weekly_fee_percent;

        if (weeklyFeePercent > originalValue) {
          this.weeklyFeeHint = 'Changing the weekly fee percent will charge the supplier more on a weekly basis.';
        } else if (weeklyFeePercent < originalValue) {
          this.weeklyFeeHint = 'Changing the weekly fee percent will charge the supplier less on a weekly basis.';
        } else {
          this.weeklyFeeHint = '';
        }
      }),
    );
  }
}
