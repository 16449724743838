<article class="article">
  <div class="box box-default table-box table-striped table-responsive mdl-shadow--2dp">
    <table class="mdl-data-table">
      <thead>
        <tr>
          <th class="mdl-data-table__cell--non-numeric">Report Name</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-reportListModel [ngForOf]="reportList | async">
          <tr>
            <td class="mdl-data-table__cell--non-numeric">
              <a class="supplier-details-link" [routerLink]="['/suppliers', supplierKey, 'reports', reportListModel.name]">
                {{ reportListModel.displayName }}
              </a>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</article>
