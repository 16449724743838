import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { FeatureFlagsGeneralGuard } from '../guards/feature-flags-general.guard';
import { USER_PERMISSION } from '../shared/users/user-permissions.enum';
import { RouteAccessResolver } from '../shared/utils/route-access-resolver';
import { BalanceApprovalComponent } from './balance-approval/balance-approval.component';
import { PendingEditComponent } from './pending-edit/pending-edit.component';
import { ProcessAddComponent } from './process-add/process-add.component';
import { ProcessBatchesComponent } from './process-batches/process-batches.component';
import { ProcessEditComponent } from './process-edit/process-edit.component';
import { ProcessListComponent } from './process-list/process-list.component';
// eslint-disable-next-line max-len
import { SupplierPaymentsDetailsComponent } from './supplier-payments-details/supplier-payment-details/supplier-payment-details/supplier-payment-details.component';

export const routes: Routes = [
  {
    path: 'processes',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        except: [USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS],
      },
    },
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: ProcessListComponent },
      { path: ':processKey/batches', component: ProcessBatchesComponent },
      { path: ':processKey/new', component: ProcessAddComponent },
      { path: ':processKey/edit', component: ProcessEditComponent },
      { path: ':processKey/:paymentKey', component: SupplierPaymentsDetailsComponent },
      { path: ':supKey', component: PendingEditComponent },
      { path: 'approval', component: BalanceApprovalComponent },
    ],
  },
  {
    path: 'proceses',
    canActivate: [RouteAccessResolver, FeatureFlagsGeneralGuard],
    children: [
      { path: '', redirectTo: 'approval', pathMatch: 'full' },
      { path: 'approval', component: BalanceApprovalComponent },
    ],
  },
];
