<mat-card class="onboardingGeneralInformation__card">
  <mat-card-content>
    <h5>General Onboarding Information</h5>

    <form class="onboardingGeneralInformation__card__form" [formGroup]="generalInformationForm">
      <mat-form-field class="onboardingGeneralInformation__card__form__field">
        <input matInput placeholder="Template Name" formControlName="templateName" />
        <mat-error *ngIf="generalInformationForm.controls['templateName'].invalid">Template Name is required</mat-error>
      </mat-form-field>

      <mat-form-field class="onboardingGeneralInformation__card__form__field">
        <mat-select placeholder="Supplier Site" formControlName="supplierSite">
          <mat-option *ngFor="let supplierSite of supplierSites" [value]="supplierSite">
            {{ supplierSite }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="onboardingGeneralInformation__card__form__field">
        <input matInput placeholder="Site URL Parameter" formControlName="siteUrlParameter" />
        <mat-error *ngIf="generalInformationForm.controls['siteUrlParameter'].invalid">Site URL Parameter is required</mat-error>
      </mat-form-field>

      <mat-form-field class="onboardingGeneralInformation__card__form__field">
        <input matInput placeholder="CSS Stylesheet Name" formControlName="cssStylesheetName" />
      </mat-form-field>

      <mat-checkbox class="onboardingGeneralInformation__card__form__checkbox" formControlName="loadNetEarnings"
        >Load Net Earnings</mat-checkbox
      >

      <mat-checkbox class="onboardingGeneralInformation__card__form__checkbox" formControlName="plaidStep">Enable Plaid</mat-checkbox>
    </form>
  </mat-card-content>
</mat-card>
