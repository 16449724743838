<div class="card" *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
  <div class="card-content">
    <app-underwriting-initial-card-header
      [chipText]="'Automated'"
      [chipColor]="'accent'"
      [title]="'Credit Check'"
      [status]="headerStatus"
      [type]="headerType"
    >
    </app-underwriting-initial-card-header>

    <div class="row">
      <div class="col-6">
        <ng-template #noBusinessReport> Recourds found: - </ng-template>
        <button
          mat-raised-button
          color="primary"
          (click)="openBusinessReport()"
          *ngIf="transUnion?.results?.business?.company && transUnion?.results?.business?.company?.NumberOfRecordsFound > 0"
        >
          Open Business Check
        </button>
        <p
          *ngIf="
            transUnion?.results?.business?.company && transUnion?.results?.business?.company?.NumberOfRecordsFound;
            else noBusinessReport
          "
        >
          Records found: {{ transUnion?.results?.business?.company?.NumberOfRecordsFound || '-' }}
        </p>
      </div>
      <div class="col-6">
        <button
          mat-raised-button
          color="primary"
          (click)="openUccReport()"
          *ngIf="transUnion?.results?.business?.ucc_filings && transUnion?.results?.business?.ucc_filings?.NumberOfRecordsFound > 0"
        >
          Open Ucc Filing Check
        </button>
        <ng-template #noUccReport> Recourds found: - </ng-template>
        <p
          *ngIf="
            transUnion?.results?.business?.ucc_filings && transUnion?.results?.business?.ucc_filings?.NumberOfRecordsFound;
            else noUccReport
          "
        >
          Records found: {{ transUnion?.results?.business?.ucc_filings?.NumberOfRecordsFound || '-' }}
        </p>
      </div>
    </div>
    <div class="table-wrapper">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="20%" class="text-right">Target</th>
            <th width="20%" class="text-right">Value</th>
            <th width="10%" class="text-right">Automated</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of credictCheck; let $index = index">
            <td>{{ item?.text }}</td>
            <td class="text-right">
              {{ item?.target }}
            </td>
            <td class="text-right">
              {{ item?.value }}
            </td>
            <td class="text-right" width="20%">
              <span *ngIf="item?.automated" class="text-success"> Valid </span>
              <span *ngIf="!item?.automated" class="text-danger"> Needs review </span>
            </td>
          </tr>
          <tr *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
            <td colspan="3">Override Credit Check results</td>
            <td colspan="2" class="text-right" width="20%">
              <ng-container *ngIf="computingDataIsInProgress">
                <app-radiobuttons
                  [value]="transUnionOverride[1].value"
                  [labelPosition]="'before'"
                  [options]="radiobuttons"
                  (onChange)="radiobuttonsChange($event)"
                >
                </app-radiobuttons>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
