<section class="container-fluid flex-container">
  <div *ngIf="headerInformationData" class="header box box-default">
    <div class="info">
      <h6>Treasury Approved:</h6>
      <span>
        Approved: &nbsp;
        <span
          *ngIf="
            headerInformationData.treasuryApprovedTrue !== undefined || headerInformationData.treasuryApprovedTrue !== null;
            else noData
          "
        >
          {{ headerInformationData.treasuryApprovedTrue }} &nbsp;/&nbsp;
        </span>
        Rejected: &nbsp;
        <span
          *ngIf="
            headerInformationData.treasuryApprovedReject !== undefined || headerInformationData.treasuryApprovedReject !== null;
            else noData
          "
        >
          {{ headerInformationData.treasuryApprovedReject }}&nbsp;/&nbsp;
        </span>
        None: &nbsp;
        <span
          *ngIf="
            headerInformationData.treasuryApprovedNone !== undefined || headerInformationData.treasuryApprovedNone !== null;
            else lastNoData
          "
        >
          {{ headerInformationData.treasuryApprovedNone }}
        </span>
      </span>

      <h6>Account health check pass:</h6>
      <span>
        Pass: &nbsp;
        <span *ngIf="headerInformationData.amazonHealthPass !== undefined || headerInformationData.amazonHealthPass !== null; else noData">
          {{ headerInformationData.amazonHealthPass }} &nbsp;/&nbsp;
        </span>
        Fail: &nbsp;
        <span
          *ngIf="headerInformationData.amazonHealthFail !== undefined || headerInformationData.amazonHealthFail !== null; else lastNoData"
        >
          {{ headerInformationData.amazonHealthFail }}
        </span>
      </span>

      <h6>ML financial data check pass:</h6>
      <span>
        Pass: &nbsp;
        <span *ngIf="headerInformationData.financialMlPass !== undefined || headerInformationData.financialMlPass !== null; else noData">
          {{ headerInformationData.financialMlPass }}&nbsp;/&nbsp;
        </span>
        Fail: &nbsp;
        <span
          *ngIf="headerInformationData.financialMLFail !== undefined || headerInformationData.financialMLFail !== null; else lastNoData"
        >
          {{ headerInformationData.financialMLFail }}
        </span>
      </span>

      <ng-template #noData>
        <span> -- &nbsp;/&nbsp; </span>
      </ng-template>

      <ng-template #lastNoData>
        <span> -- </span>
      </ng-template>
    </div>
  </div>

  <article class="article flex-container">
    <div class="card flex-container">
      <div class="card-content flex-container">
        <app-filtering-panel-component
          (searchValueEmitter)="getPayments($event)"
          [configuration]="filterConfig"
        ></app-filtering-panel-component>
        <div *ngIf="rows">
          <app-data-table-grid [nodesCounted]="true" [rows]="rows" [columnDefs]="columns" [domLayout]="'autoHeight'" [offset]="offset">
          </app-data-table-grid>
          <app-pyb-pagination
            *ngIf="paginationData && paginationConfiguration"
            [configuration]="paginationConfiguration"
            (onPageChange)="onPageChange($event)"
          >
          </app-pyb-pagination>
        </div>
      </div>
    </div>
  </article>
</section>
