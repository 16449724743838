import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { AppConfigService } from '../../../config';
import { OnboardingModel, OnboardingMpModel, ThemeOnboardingModel } from '../onboarding.model';

export enum OnboardingTypes {
  ONBOARDING_TEMPLATE = 'onboarding_template',
  ONBOARDING_TEMPLATE_MP = 'onboarding_template_mp',
}

@Injectable()
export class OnboardingService {
  private apiUrl: string;
  private instanceId: number;
  private headers: HttpHeaders;
  private internalApiUrl: string;

  // Stored data
  private configsList: Subject<OnboardingModel[]>;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.internalApiUrl = appConfig.env.internalApiUrl;
    this.apiUrl = appConfig.env.internalApiUrl + 'onboarding_templates/';
    this.instanceId = appConfig.env.instanceId;
    this.headers = new HttpHeaders().set('x-instanceid', '' + this.instanceId);

    this.getTemplateList().subscribe((data) => {
      this.configsList = data;
    });
  }

  public list(force = false): Observable<OnboardingModel[]> {
    return isNullOrUndefined(this.configsList) || force ? this.getTemplateList() : of(this.configsList);
  }

  public getSchemasList(supplierKey: string): Observable<any> {
    const url = `${this.internalApiUrl}supplier/${supplierKey}/schema`;
    return this.httpClient.get(url);
  }

  public add(data: OnboardingModel): Observable<OnboardingModel> {
    SerializeKeysTo(UnderscoreCase);
    return this.httpClient
      .post(`${this.apiUrl}template`, Serialize(data, OnboardingModel), {
        observe: 'body',
        responseType: 'json',
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(result, OnboardingModel);
        }),
      );
  }

  public addMp(data: OnboardingMpModel): Observable<OnboardingMpModel> {
    SerializeKeysTo(UnderscoreCase);
    return this.httpClient
      .post(`${this.apiUrl}mp/template`, Serialize(data, OnboardingMpModel), {
        observe: 'body',
        responseType: 'json',
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(result, OnboardingModel);
        }),
      );
  }

  public set(key: string, data: any): Observable<OnboardingModel> {
    SerializeKeysTo(UnderscoreCase);
    return this.httpClient
      .post(`${this.apiUrl}${key}/update`, Serialize(data, OnboardingModel), {
        observe: 'body',
        responseType: 'json',
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(result['data'], OnboardingModel);
        }),
      );
  }

  public setMp(key: string, data: any): Observable<OnboardingMpModel> {
    SerializeKeysTo(UnderscoreCase);
    return this.httpClient
      .post(`${this.apiUrl}mp/${key}/update`, Serialize(data, OnboardingMpModel), {
        observe: 'body',
        responseType: 'json',
        headers: this.headers,
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(result['data'], OnboardingMpModel);
        }),
      );
  }

  getTemplateList() {
    return this.httpClient
      .get(`${this.apiUrl}all_templates`, {
        observe: 'body',
        responseType: 'json',
      })
      .pipe(
        map((result) => {
          return (<any>result).map((item) => {
            DeserializeKeysFrom(UnderscoreCase);
            return Deserialize(item, OnboardingModel);
          });
        }),
      );
  }

  getTemplate(key: any): Observable<any> {
    return this.httpClient
      .get(`${this.apiUrl}${key}/template_with_mp`, {
        observe: 'body',
        responseType: 'json',
      })
      .pipe(
        map((result) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(result, OnboardingModel);
        }),
      )
      .pipe(
        map((elem) => {
          elem.mpTemplates = elem.mpTemplates.map((temp) => Deserialize(temp, OnboardingMpModel));
          return elem;
        }),
      );
  }

  remove(key: any): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}${key}/template`, {
      observe: 'body',
      responseType: 'json',
      headers: this.headers,
    });
  }

  removeMp(key: any): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}mp/${key}/template`, {
      observe: 'body',
      responseType: 'json',
      headers: this.headers,
    });
  }

  createTemplate(data: ThemeOnboardingModel): Observable<any> {
    SerializeKeysTo(UnderscoreCase);
    return this.httpClient.post(`${this.apiUrl}template`, Serialize(data, ThemeOnboardingModel));
  }

  getTemplateDetails(templateKey: string): Observable<any> {
    return this.httpClient
      .get(`${this.apiUrl}${templateKey}/template_with_mp`)
      .pipe(
        map((result) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(result, ThemeOnboardingModel);
        }),
      )
      .pipe(
        map((elem) => {
          elem.mpTemplates = elem.mpTemplates.map((temp) => Deserialize(temp, OnboardingMpModel));
          return elem;
        }),
      );
  }

  updateTemplate(templateKey: string, data: ThemeOnboardingModel): Observable<any> {
    SerializeKeysTo(UnderscoreCase);
    return this.httpClient.post(`${this.apiUrl}${templateKey}/update`, Serialize(data, ThemeOnboardingModel));
  }

  addMarketplaceToTemplate(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}mp/template`, data);
  }

  removeMarketplaceFromTemplate(templateKey: string, data: any): Observable<any> {
    const httpParams = new HttpParams().set('marketplaces_to_remove', JSON.stringify(data));
    return this.httpClient.delete(`${this.apiUrl}mp/${templateKey}/template`, { params: httpParams });
  }

  updateMarketplacesOrder(templateKey: string, data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}${templateKey}/reorder_mp_templates`, data);
  }
}
