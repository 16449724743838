export enum OfferStatuses {
  PROCESSED = 'processed',
}

export enum CalculatorInputFields {
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED',
  NET_DELIVERY = 'NET_DELIVERY',
  RESERVE_FOR_REPAYMENTS = 'RESERVE_FOR_REPAYMENTS',
  UNDER_WRITING_RISK_RATING = 'UNDER_WRITING_RISK_RATING',
  REQUEST_NOTES = 'REQUEST_NOTES',
  UNDERWRITING_NOTES = 'UNDERWRITING_NOTES',
  GRACE_PERIOD_END_DATE = 'GRACE_PERIOD_END_DATE',
  NO_REBATE = 'NO_REBATE',
  RETURN_STRATEGY = 'RETURN_STRATEGY',
  FORCE_FULL = 'FORCE_FULL',
  EXPEXTED_MONTHLY_EARINGS = 'EXPEXTED_MONTHLY_EARINGS',
  EXPECTED_DURATION = 'EXPECTED_DURATION',
  MAX_DURATION = 'MAX_DURATION',
  LINE_FEE = 'LINE_FEE',
  LINE_MAX_REPAYMENT_RESERVE = 'LINE_MAX_REPAYMENT_RESERVE',
  CURRENT_REPAYMENT_RESERVE = 'CURRENT_REPAYMENT_RESERVE',
  UW_STATUS = 'UW_STATUS',
}
