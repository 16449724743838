<div class="welcome__container">
  <h3 class="welcome__preTitle">Welcome to</h3>
  <div
    class="welcome__logo"
    [ngClass]="
      {
        HALLOWEEN: 'halloween',
        APRIL: 'april',
        NEW_YEAR: 'newYear'
      }[activeOccasionLogo]
    "
    data-test="welcomeLogo"
  ></div>
  <div class="welcome__version">
    <h3 class="welcome__version__subtitle">
      <span class="welcome__version__fe" matTooltip="Frontend version">{{ feVersion }}</span> /
      <span class="welcome__version__be" matTooltip="Backend version">{{ $beVersion | async }}</span>
    </h3>
    <h4 *ngIf="message">{{ message }}</h4>
  </div>
</div>
