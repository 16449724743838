import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { NEVER, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';

import { SubscriberComponent } from '../../../../shared/component-subscriber/subscriber.component';
import { DataTableQueryParams } from '../../../../shared/data-table/data-table.common';
import { CurrencyCellRenderer } from '../../../../shared/functions/currency-cell-renderer';
import { UsersService } from '../../../../shared/users/service/users.service';
import { USER_PERMISSION } from '../../../../shared/users/user-permissions.enum';
import { AppState } from '../../../../store/app.reducers';
import { SupplierService } from '../../../services/supplier.service';
import { selectSupplierDetails } from '../../../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier-amazon-lending-list',
  templateUrl: './supplier-amazon-lending-list.component.html',
  styleUrls: ['./supplier-amazon-lending-list.component.scss'],
})
export class SupplierAmazonLendingListComponent extends SubscriberComponent implements OnInit {
  columns$: Observable<ColDef[]> = of([]);
  canAddLoan$: Observable<boolean> = of(false);
  canEditLoan$: Observable<boolean> = of(false);
  rows: any;
  activeLoan: any;

  private supplierKey$: Observable<string> = NEVER;
  private hasMpLoansModifyPerm$: Observable<boolean>;
  private readonly reportName: string = 'marketplace_loans';

  constructor(
    private readonly store: Store<AppState>,
    private readonly service: SupplierService,
    private readonly userService: UsersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkPermissions();
    this.setColumns();
    this.setSupplierKey();
    this.getData();
  }

  private setSupplierKey(): void {
    this.supplierKey$ = this.store.select(selectSupplierDetails).pipe(
      filter((data) => !!(data && data.supplierKey)),
      map((data) => data.supplierKey),
    );
  }

  private getData(): void {
    const getReportParams: DataTableQueryParams = { limit: 20, offset: 0 };

    this.subscriptions.push(
      this.supplierKey$
        .pipe(
          distinctUntilChanged(),
          switchMap((supplierKey) => this.service.getSupplierReport(supplierKey, this.reportName, getReportParams)),
          map((value) => {
            this.activeLoan = value.filter((loan) => loan.active).pop();
            value.forEach((loan) => (loan.active = loan.active ? 'Yes' : 'No'));
            return { results: value };
          }),
        )
        .subscribe((data) => (this.rows = data.results)),
    );
  }

  private setColumns(): void {
    this.columns$ = this.canEditLoan$.pipe(
      map((canEditLoan) => [
        {
          field: 'loanExternalId',
          headerName: 'Loan ID',
          cellRenderer: (cellValue: any) => {
            if (!canEditLoan) {
              return `<span class="pull-right">${cellValue.value}<span>`;
            }

            return `<a class="pull-right" href="${location.hash.replace('list', 'loan/' + cellValue.data.marketplaceLoanKey)}">${
              cellValue.value
            }</a>`;
          },
          width: 90,
        },
        {
          field: 'paymentStatus',
          headerName: 'Status',
          width: 80,
        },
        {
          field: 'loanAmount',
          headerName: 'Loan Amount',
          cellRenderer: CurrencyCellRenderer,
          width: 105,
        },
        {
          field: 'active',
          headerName: 'Active',
          width: 70,
        },
        {
          field: 'daysLeftToPayment',
          headerName: 'Days left to Loan Payment',
          width: 75,
        },
        {
          field: 'paymentPeriodDays',
          headerName: 'Payment Period Dates',
          width: 80,
        },
        {
          field: 'loanExtraReserveAmount',
          headerName: 'Loan Provision Reserved',
          cellRenderer: CurrencyCellRenderer,
          width: 90,
        },
        {
          field: 'nextPaymentDate',
          headerName: 'Next Payment Date',
          width: 95,
        },
        {
          field: 'nextPaymentAmount',
          headerName: 'Next Payment Amount',
          cellRenderer: CurrencyCellRenderer,
          width: 90,
        },
      ]),
    );
  }

  private checkPermissions(): void {
    this.hasMpLoansModifyPerm$ = this.userService.checkPermission(USER_PERMISSION.MP_LOANS_MODIFY).pipe(shareReplay(1));
    this.canAddLoan$ = this.hasMpLoansModifyPerm$;
    this.canEditLoan$ = this.hasMpLoansModifyPerm$;
  }
}
