export enum flexField {
  participationRate = 'participationRate',
  fundingRate = 'fundingRate',
  freemiumFee = 'freemiumFee',
  freemiumFeeRate = 'freemiumFeeRate',
}

export interface IFlexColumnDefinition {
  name: flexField;
  connectedField: flexField;
  label: string;
  methodName: string;
}
