import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

import { FEATURE_FLAGS } from '../../../../shared/feature-flags/feature-flags.const';
import { MESSAGE_SOURCE, MESSAGE_TYPE } from '../../../../shared/iframe/communication/message-type.enum';
import { AppState } from '../../../../store/app.reducers';
import { OnboardingTaskProgressModel } from '../../../model/onboarding-task-progress.model';
import { SupplierDetailsModel } from '../../../model/supplier.model';
import { selectSupplierDetails, UpdateSupplierModel } from '../../../store/supplier/supplier.actions';
import { SupplierOnboardingService } from '../supplier-onboarding.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { DialogService } from './../../../../shared/dialog/dialog.service';
import { EvaComponent } from './../../../../shared/eva/eva.component';
import { FeatureFlagsService } from './../../../../shared/feature-flags/feature-flags.service';
import { MessageFromIframe } from './../../../../shared/iframe/communication/communication-message.model';
import { OnboardingService } from './../../../../shared/onboarding/service/onboarding.service';

export interface IOnboardingDetailsModel {
  marketing_campaign: string;
  marketing_content: string;
  marketing_medium: string;
  marketing_source: string;
  onboard_templates: string;
  registration_date: string;
  currentSchema: string;
  url: string;
}

@Component({
  selector: 'app-supplier-onboarding-details',
  templateUrl: './supplier-onboarding-details.component.html',
  styleUrls: ['./supplier-onboarding-details.component.scss'],
})
export class SupplierOnboardingDetailsComponent extends SubscriberComponent implements OnInit {
  public form: FormGroup;
  public onboardingDetails = null;
  public supplierDetailsModel: SupplierDetailsModel;
  public onboardingTaskProgress: OnboardingTaskProgressModel = new OnboardingTaskProgressModel();
  public onboardingTemplatesList: string[] = [];
  public onboardingSchemasList: string[] = [];
  public onbTemplateValue: string;
  public onbSchemaValue: string;
  public useEva = false;
  public evaQueryParams: { [key: string]: string };
  public supplierStatus: string = null;

  private supplierKey: string;

  readonly onboardingSchemaCopy = 'Onboarding Schema';
  readonly ACTIVE_STATUS = 'Active';

  @Input() onboardingTaskProgres: Array<OnboardingTaskProgressModel> = [];
  @Output() runOnboardingWorkflow = new EventEmitter();
  @ViewChild('appEva') appEva: EvaComponent;

  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly supplierOnboardingService: SupplierOnboardingService,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly store: Store<AppState>,
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly dialog: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.useEva = this.featureFlagsService.isEnabled(FEATURE_FLAGS.EVA_ONBOARDING_SUMMARY);

    this.createForm();

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(filter((data) => !!(data && data.supplierKey)))
        .subscribe((data) => {
          this.supplierKey = data.supplierKey;

          this.evaQueryParams = {
            supplierKey: this.supplierKey,
          };
          if (this.useEva) {
            setTimeout(() => {
              this.initEvaCommunication();
            });
          }

          this.supplierStatus = data.status.supplierStatus;
          this.form.get('isComplete').setValue(data.onboardingStatus, {
            emitEvent: false,
          });
          this.supplierDetailsModel = data;

          this.getOnboardingDetails();
          this.getOnboardingTemplatesList();
          this.getOnboardingSchemasList();
        }),
    );
  }

  private getOnboardingDetails() {
    this.subscriptions.push(
      this.supplierOnboardingService.getOnboardingDetails(this.supplierKey).subscribe((response: IOnboardingDetailsModel) => {
        this.onboardingDetails = response;
        this.onbTemplateValue = response.onboard_templates;
        this.onbSchemaValue = response.currentSchema;
      }),
    );
  }

  private getOnboardingTemplatesList() {
    this.subscriptions.push(
      this.onboardingService.list(true).subscribe((result) => {
        result.map((e) => {
          this.onboardingTemplatesList.push(e.onboardingTemplateCode);
        });
      }),
    );
  }

  private getOnboardingSchemasList() {
    this.subscriptions.push(
      this.onboardingService.getSchemasList(this.supplierKey).subscribe((result) => {
        this.onboardingSchemasList = result.availableSchemas;
      }),
    );
  }

  createForm() {
    this.form = this.formBuilder.group({
      isComplete: new FormControl(false),
    });
  }

  setOnboardingTemplate() {
    this.subscriptions.push(
      this.supplierOnboardingService.setOnboardingTemplate(this.supplierKey, this.onbTemplateValue).subscribe(
        () => {
          this.toastr.success('Onboarding template succesfully changed');
        },
        (err) => {
          this.toastr.error(err.error && err.error.message ? err.error.message : 'Onboarding template setup failed');
        },
      ),
    );
  }

  setOnboardingSchema() {
    this.subscriptions.push(
      this.supplierOnboardingService.setOnboardingSchema(this.supplierKey, this.onbSchemaValue).subscribe(
        () => {
          this.toastr.success('Onboarding schema succesfully changed');
        },
        (err) => {
          this.toastr.error(err.error && err.error.message ? err.error.message : 'Onboarding schema setup failed');
        },
      ),
    );
  }

  runOnboardingWorkflowEmit() {
    this.runOnboardingWorkflow.emit();
  }

  private initEvaCommunication(): void {
    if (!this.appEva) {
      return;
    }

    this.subscriptions.push(
      this.appEva
        .communication()
        .incomingMessage$.pipe(
          filter((message: MessageFromIframe) => {
            if (!message || !message.source) {
              return;
            }
            return message.source === MESSAGE_SOURCE.CHILD;
          }),
        )
        .subscribe((message) => {
          if (message.payload.type === MESSAGE_TYPE.ONBOARDING__INIT_LOAD_EARNINGS_PROCESS) {
            this.runOnboardingWorkflowEmit();
          }
        }),
    );
  }

  onBackToOnboarding(): void {
    this.dialog
      .openConfirm({
        title: 'Move Back To Onboarding',
        body: 'Do you want to restart the onboarding process on using onboarding 3.0?',
      })
      .then((value) => {
        if (!value) {
          return;
        }

        this.doBackToOnboarding();
      });
  }

  doBackToOnboarding(): void {
    this.subscriptions.push(
      this.supplierOnboardingService.backSupplierToOnboarding(this.supplierKey).subscribe(
        () => {
          this.updateStoreForOnboardingStatus(false);
          this.toastr.success('Supplier succesfully back to onboarding');
        },
        (err) => {
          this.toastr.error(err.error && err.error.message ? err.error.message : 'Back to onboarding failed');
        },
      ),
    );
  }

  updateStoreForOnboardingStatus(onboardingStatus: boolean): void {
    this.supplierDetailsModel.onboardingStatus = onboardingStatus;
    this.store.dispatch(
      new UpdateSupplierModel(
        {
          supplierDetailsModel: this.supplierDetailsModel,
        },
        this.supplierKey,
      ),
    );
  }
}
