import { Component, OnChanges, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { FEATURE_FLAGS } from '../../shared/feature-flags/feature-flags.const';
import { LookupAbstractService } from '../../shared/lookup-service/lookup-abstract.service';
import { FetchUtilAttribute, selectCountries, selectFundingPeriods, selectTimezones } from '../../shared/utils/store/utils.actions';
import { AppState } from '../../store/app.reducers';
import { MarketplaceModel } from '../model/marketplace-model';
import { MarketplacesAbstractService } from '../service/marketplaces-abstract.service';
import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
})
export class MarketplaceComponent extends SubscriberComponent implements OnInit, OnChanges {
  readonly featureFlags = FEATURE_FLAGS;
  feeSchedules: Array<any>;
  fundingPeriods: any;
  item: MarketplaceModel;
  marketplaceForm: FormGroup;
  timezones: any;
  id: any;
  marketplacePeriodGroupCode: any;
  defaultFreeTrialTypeCode: any;
  eventToReset = new FormControl();
  lockedDeleteBtn = true;
  countries: Observable<any[]>;
  url;
  private imgTemporaryUrl;

  constructor(
    public itemsRepo: MarketplacesAbstractService,
    public toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private lookupRepo: LookupAbstractService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer,
  ) {
    super();
    this.createForm();

    this.store.dispatch(new FetchUtilAttribute({ attr: 'timezones' }));
    this.store.dispatch(new FetchUtilAttribute({ attr: 'fundingPeriods' }));
    this.store.dispatch(new FetchUtilAttribute({ attr: 'countries' }));
  }

  createForm() {
    this.marketplaceForm = this.fb.group({
      marketplaceCode: null,
      marketplaceName: ['', Validators.required],
      marketplaceDescription: [null, Validators.required],
      address: this.fb.group({
        addr1: [null, Validators.required],
        addr2: null,
        city: [null, Validators.required],
        state: [null, Validators.required],
        country: [null, Validators.required],
        postalCode: [null, Validators.required],
      }),
      marketplaceUrl: [null, Validators.required],
      instructionPdfUrl: null,
      marketplacePeriodGroupCode: [{ value: '', disabled: true }, Validators.required],
      receivingDueDays: null,
      accessType: [null, Validators.required],
      supplierWidgetPriority: [{ value: '', disabled: true }],
      timezone: [{ value: '', disabled: true }, Validators.required],
      hideSplashScreen: false,
      addSupplierLogins: false,
      defaultReservePercent: null,
      allowEnroll: false,
      trusted: false,
      instantAccess: false,
      instantAdvance: false,
      riskScore: [null, Validators.pattern('[0-5]')],
      riskScoreOverride: [null, Validators.pattern('[0-5]')],
      creditScore: null,
      credit2beCreditScore: null,
      dnbCreditScore: null,
      allowUnderwritingTracker: false,
      defaults: this.fb.group({
        defaultFeeFromReserve: false,
        defaultFeeSchedule: [null, Validators.required],
        defaultFreeTrialNumMpPeriods: null,
        defaultFreeTrialTypeCode: [{ value: '', disabled: true }, Validators.required],
        defaultFundingPeriodGroupCode: [null, Validators.required],
        defaultMinReservePercent: [null, Validators.required],
        defaultMpCanOptIn: false,
        defaultMpPaysPb: false,
        defaultPaymentFrequency: null,
        defaultPaymentTerms: null,
        defaultPaymentTermsDays: null,
        defaultPbCanOptIn: false,
        defaultRcvPurchaseThreshold: [null, Validators.required],
        defaultReservePercent: [null, Validators.required],
      }),
    });
  }

  ngOnInit() {
    this.createForm();
    this.store.dispatch(new FetchUtilAttribute({ attr: 'timezones' }));
    this.store.dispatch(new FetchUtilAttribute({ attr: 'fundingPeriods' }));
    this.store.dispatch(new FetchUtilAttribute({ attr: 'countries' }));
    this.id = this.route.snapshot.paramMap.get('marketplaceKey');
    this.enableFormFunctions();

    if (this.isInEditMode()) {
      this.subscribeToExistingItem();
    }
    this.fillDropdowns();
  }

  subscribeToExistingItem(): void {
    this.subscriptions.push(
      this.itemsRepo.getItem(this.id).subscribe((result) => {
        this.item = result;
        this.url = this._sanitizer.bypassSecurityTrustResourceUrl(this._sanitizer.sanitize(SecurityContext.HTML, this.item.iconPng));
        this.imgTemporaryUrl = this.url;
        this.marketplaceForm.patchValue(this.item);
      }),
    );
  }

  fillDropdowns(): void {
    this.timezones = this.store.select(selectTimezones);
    this.subscriptions.push(
      this.lookupRepo.getFeeSchedules().subscribe((feeSchedules) => {
        this.feeSchedules = feeSchedules.filter((feeSchedule) => feeSchedule.feeScheduleCategory === 'PURCHASE');
      }),
    );
    this.fundingPeriods = this.store.select(selectFundingPeriods);
    this.countries = this.store.select(selectCountries);
    this.getPeriodGroupCode();
    this.getDefaultFreeTrialTypeCode();
    this.url = null;
    this.imgTemporaryUrl = this.url;
  }

  getPeriodGroupCode(): void {
    this.subscriptions.push(
      this.itemsRepo.getPeriodGroupCodes().subscribe((result) => {
        this.marketplacePeriodGroupCode = result;
      }),
    );
  }

  getDefaultFreeTrialTypeCode(): void {
    this.subscriptions.push(
      this.itemsRepo.getDefaultFreeTrialTypeCodes().subscribe((result) => {
        this.defaultFreeTrialTypeCode = result;
      }),
    );
  }

  ngOnChanges() {
    this.marketplaceForm.patchValue(this.item);
  }

  deleteUrl() {
    this.url = this.imgTemporaryUrl;
    this.eventToReset.setValue(null);
    this.eventToReset.updateValueAndValidity();
    if (this.url === null || this.url['changingThisBreaksApplicationSecurity'] === null || this.url === this.imgTemporaryUrl) {
      this.lockedDeleteBtn = true;
    } else {
      this.lockedDeleteBtn = false;
    }
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (readerEvent: ProgressEvent) => {
        this.url = (<FileReader>readerEvent.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    this.lockedDeleteBtn = false;
  }

  onSubmit(): void {
    if (this.marketplaceForm.invalid) {
      this.marketplaceForm.get('marketplaceName').markAsTouched();
      this.marketplaceForm.get('marketplaceDescription').markAsTouched();
      this.marketplaceForm.get('address.addr1').markAsTouched();
      this.marketplaceForm.get('address.addr2').markAsTouched();
      this.marketplaceForm.get('address.city').markAsTouched();
      this.marketplaceForm.get('address.state').markAsTouched();
      this.marketplaceForm.get('address.country').markAsTouched();
      this.marketplaceForm.get('address.postalCode').markAsTouched();
      this.marketplaceForm.get('marketplaceUrl').markAsTouched();
      this.marketplaceForm.get('marketplacePeriodGroupCode').markAsTouched();
      this.marketplaceForm.get('accessType').markAsTouched();
      this.marketplaceForm.get('timezone').markAsTouched();
      this.marketplaceForm.get('defaults.defaultFeeSchedule').markAsTouched();
      this.marketplaceForm.get('defaults.defaultFreeTrialTypeCode').markAsTouched();
      this.marketplaceForm.get('defaults.defaultFundingPeriodGroupCode').markAsTouched();
      this.marketplaceForm.get('defaults.defaultMinReservePercent').markAsTouched();
      this.marketplaceForm.get('defaults.defaultRcvPurchaseThreshold').markAsTouched();
      this.marketplaceForm.get('defaults.defaultReservePercent').markAsTouched();
    } else {
      if (this.isInEditMode()) {
        this.item = this.prepareSaveItem();
        this.saveItem();
      } else {
        this.item = this.prepareAddItem();
        this.addItem();
      }
      this.ngOnChanges();
    }
  }

  saveItem() {
    this.subscriptions.push(
      this.itemsRepo.saveItem(this.item.marketplaceKey, this.item).subscribe(
        () => {
          this.router.navigate(['../../list'], { relativeTo: this.route });
          this.toastrService.success('Marketplace successfully saved!');
        },
        () => {
          this.toastrService.error('Error while saving marketplace!');
        },
      ),
    );
  }

  addItem() {
    this.subscriptions.push(
      this.itemsRepo.addItem(this.item).subscribe(
        (success) => {
          this.router.navigate(['/marketplaces/list'], { relativeTo: this.route });
          this.toastrService.success('Marketplace successfully added!');
        },
        (error) => {
          this.toastrService.error('Error while adding marketplace!');
        },
      ),
    );
  }

  prepareSaveItem(): MarketplaceModel {
    const dataToSave = { ...this.marketplaceForm.value };
    if (this.url['changingThisBreaksApplicationSecurity']) {
      dataToSave['iconPng'] = this.url['changingThisBreaksApplicationSecurity'];
    } else if (this.url['changingThisBreaksApplicationSecurity'] === null) {
      dataToSave['iconPng'] = this.url['changingThisBreaksApplicationSecurity'];
    } else {
      dataToSave['iconPng'] = this.url.toString();
    }
    return new MarketplaceModel(Object.assign(this.item, dataToSave));
  }

  prepareAddItem(): MarketplaceModel {
    const dataToSave = { ...this.marketplaceForm.value };
    dataToSave['iconPng'] = this.url;
    return new MarketplaceModel(dataToSave);
  }

  currentUrl(): string {
    return document.location.href;
  }

  enableFormFunctions(): void {
    this.enableForm();
    if (isNullOrUndefined(this.url)) {
      this.lockedDeleteBtn = true;
    } else if (this.url['changingThisBreaksApplicationSecurity'] !== null) {
      this.lockedDeleteBtn = true;
    } else if (this.url['changingThisBreaksApplicationSecurity'] === null) {
      this.lockedDeleteBtn = true;
    } else {
      this.lockedDeleteBtn = false;
    }
  }

  enableForm(): void {
    this.marketplaceForm.enable();
    this.marketplaceForm.controls['supplierWidgetPriority'].disable();
    if (this.isInEditMode()) {
      this.disableControlsUnchangeableInEditingMode();
    }
  }

  disableControlsUnchangeableInEditingMode(): void {
    this.marketplaceForm.controls['marketplacePeriodGroupCode'].disable();
    this.marketplaceForm.controls['supplierWidgetPriority'].disable();
    this.marketplaceForm.controls['timezone'].disable();
    this.marketplaceForm.get('defaults').get('defaultFreeTrialTypeCode').disable();
  }

  isInEditMode() {
    return !!this.id;
  }

  spacesToUnderscore(item: string) {
    return item.split(' ').join('_');
  }
}
