import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fee-settings',
  templateUrl: './fee-settings.component.html',
  styleUrls: ['./fee-settings.component.scss'],
})
export class FeeSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
