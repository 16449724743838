import { DataSource } from '@angular/cdk/collections';
/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operators';
import { isNull, isNullOrUndefined, isString } from 'util';

import { CardFormgroupMessageService } from '../../../shared/card-formgroup/card-formgroup-message.service';
import { CrmStates } from '../../../shared/layout/layout.enums';
import { LayoutState } from '../../../shared/layout/store/layout.reducers';
import { LookupAbstractService } from '../../../shared/lookup-service/lookup-abstract.service';
import { AppState } from '../../../store/app.reducers';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

/* eslint-enable */

@Component({
  selector: 'app-supplier-health',
  templateUrl: './supplier-health.component.html',
  styleUrls: ['./supplier-health.component.scss'],
})
export class SupplierHealthComponent extends SubscriberComponent implements OnInit {
  showDelinquencyRecord = false;
  countries = of([
    { id: 'domestic', label: 'Domestic' },
    { id: 'international', label: 'International' },
  ]);
  fulfillments = of([
    { id: 'direct', label: 'Direct' },
    { id: 'marketplace', label: 'Marketplace' },
    { id: 'na', label: 'N/A' },
  ]);

  businessTypes: Observable<any[]>;
  amazonHealth: any;
  riskDelinquency: RiskDataSource | null;

  delinquencyAges: Observable<any[]>;
  delinquencyScenarios: Observable<any[]>;
  delinquencyTypes: Observable<any[]>;

  delinquencyColumns = ['date', 'state', 'incrementalRiskGrade', 'currentRiskGrade', 'riskOverwritten', 'currentADA', 'currentADACurr'];

  supplierKey = null;

  riskInfo = {
    riskScoreOverride: null,
    thirdPartyCreditScore: null,
    credit2beCreditScore: null,
    dnbCreditScore: null,
    uccScore: null,
    pbSeniorUcc: null,
    validityScore: null,
    personalValidityAgreement: null,
    incScore: null,
    businessType: null,
    geoScore: null,
    country: null,
    fulfillment: null,
    delinquencyAge: null,
    delinquencyScenario: null,
    delinquencyType: null,
  };

  riskForm: FormGroup;

  layoutState$: Store<LayoutState>;
  crmPanelStates = CrmStates;

  constructor(
    private lookupService: LookupAbstractService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private supplierService: SupplierService,
    private toastrService: ToastrService,
    private messageService: CardFormgroupMessageService,
    public dialog: MatDialog,
  ) {
    super();
    this.businessTypes = this.lookupService.getBusinessTypes();
  }

  ngOnInit() {
    this.layoutState$ = this.store.select('layout');

    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierDetails) => {
        if (!isNullOrUndefined(supplierDetails)) {
          this.supplierKey = supplierDetails.supplierKey;

          this.riskDelinquency = new RiskDataSource(this.supplierService, this.supplierKey);

          this.getRiskInfo();
          this.getAmazonHealth();
        }
      }),
    );

    this.delinquencyScenarios = this.lookupService.getDelinquencyScenarios();
    this.delinquencyAges = this.lookupService.getDelinquencyAges();
    this.delinquencyTypes = this.lookupService.getDelinquencyTypes();
  }

  isRiskInfo() {
    return this.riskInfo.delinquencyType || this.riskInfo.delinquencyAge || this.riskInfo.delinquencyScenario || this.showDelinquencyRecord;
  }

  addDelinquencyRecord() {
    this.showDelinquencyRecord = true;
    this.riskInfo.delinquencyScenario = 1;
  }

  getAmazonHealth() {
    this.subscriptions.push(
      this.supplierService.getHealth(this.supplierKey).subscribe((data) => {
        this.amazonHealth = data && data.results && data.results.length ? data.results[7] : false;

        try {
          if (this.amazonHealth.account_performance_info && isString(this.amazonHealth.account_performance_info)) {
            this.amazonHealth.account_performance_info = JSON.parse(this.amazonHealth.account_performance_info);
          }
        } catch (error) {
          console.error(error);
        }
      }),
    );
  }

  getRiskInfo() {
    this.subscriptions.push(
      this.supplierService.getRiskInfo(this.supplierKey).subscribe((data) => {
        this.riskInfo = data;
        this.createForm(data);
      }),
    );
  }

  createForm(data) {
    this.riskForm = this.formBuilder.group({
      delinquencyScenario: data.delinquencyScenario,
      delinquencyAge: data.delinquencyAge,
      delinquencyType: data.delinquencyType,
      riskScoreOverride: data.riskScoreOverride,
      thirdPartyCreditScore: data.thirdPartyCreditScore,
      credit2beCreditScore: data.credit2beCreditScore,
      dnbCreditScore: data.dnbCreditScore,
      uccScore: data.uccScore,
      pbSeniorUcc: data.pbSeniorUcc,
      validityScore: data.validityScore,
      personalValidityAgreement: data.personalValidityAgreement,
      incScore: data.incScore,
      businessType: data.businessType,
      geoScore: data.geoScore,
      country: data.country,
      fulfillment: data.fulfillment,
    });
  }

  saveRiskInfo() {
    const dataToSave = this.riskForm.value;
    this.subscriptions.push(
      this.supplierService.saveRiskInfo(this.supplierKey, dataToSave).subscribe(
        (data) => {
          this.messageService.broadcast(this.riskForm, 'saveSuccess');
        },
        (error) => {
          this.toastrService.error('Risk info has not been saved');
          this.messageService.broadcast(this.riskForm, 'saveError');
        },
      ),
    );
  }
}

export class RiskDataSource extends DataSource<any> {
  constructor(private supplierService: SupplierService, private supplierKey) {
    super();
  }

  connect(): Observable<any[]> {
    if (!isNull(this.supplierKey)) {
      return this.supplierService.getReport('v_delinquency', { supplier_key: this.supplierKey }).pipe(map((data) => data.results));
    }
  }

  disconnect() {}
}
