import { transition, trigger, useAnimation } from '@angular/animations';
import { Component } from '@angular/core';

import { listAnimation } from '../../../shared/cms.animations';
import { FetchCrmBatchEmails, selectCrmBatchEmails } from '../../../store/crm.actions';
import { ActionTabEmailsComponent } from './action-tab-emails.component';

@Component({
  selector: 'app-action-tab-batch-emails',
  templateUrl: './action-tab-emails.component.html',
  animations: [trigger('listAnimation', [transition('* => *', [useAnimation(listAnimation)])])],
})
export class ActionTabBatchEmailsComponent extends ActionTabEmailsComponent {
  selector = selectCrmBatchEmails;
  fetchAction = FetchCrmBatchEmails;
}
