<h1 mat-dialog-title>
  {{ data?.title }}
</h1>
<div mat-dialog-content>
  <div class="content" *ngIf="data?.body">
    <ng-container *ngIf="isStaticContentBody; else dynamicContent">
      {{ data?.body }}
    </ng-container>
    <ng-template #dynamicContent>
      <ng-template [ngTemplateOutlet]="data.body"></ng-template>
    </ng-template>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onCancel()" color="warn" tabindex="-1">Cancel</button>

  <button mat-raised-button (click)="onConfirm()" tabindex="2" color="primary">Confirm</button>
</div>
