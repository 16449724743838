<div class="row">
  <app-loading-spinner class="spinner" *ngIf="isLoading" [size]="4"></app-loading-spinner>

  <form [formGroup]="pCardForm" class="form card-content" novalidate>
    <div class="row">
      <p class="col-md-12">
        <strong>Marqeta Account Information</strong>
      </p>

      <mat-form-field class="col-md-12">
        <input matInput [matDatepicker]="creationDatePicker" type="text" placeholder="Creation Date" formControlName="startDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="creationDatePicker" disabled></mat-datepicker-toggle>
        <mat-datepicker #creationDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="col-md-12">
        <input matInput type="text" formControlName="cardNumber" placeholder="Card Number" readonly />
      </mat-form-field>

      <mat-form-field class="col-md-12">
        <input matInput type="text" formControlName="cardExp" placeholder="Expiration Month (MM/YY)" readonly />
      </mat-form-field>

      <mat-form-field class="col-md-12">
        <input matInput type="text" formControlName="cardCvc" placeholder="CVC" readonly />
      </mat-form-field>
    </div>
    <div class="row buttons-position">
      <button [disabled]="!isUnactivated()" mat-raised-button color="accent" (click)="activateMarqetaCard()" class="mat-action-row__button">
        Activate
      </button>
      <button
        *ngIf="!isBlocked()"
        [disabled]="isUnactivated() || isTerminated()"
        mat-raised-button
        color="primary"
        (click)="blockMarqetaCard()"
        class="mat-action-row__button"
      >
        Block
      </button>
      <button
        *ngIf="isBlocked() && !isTerminated()"
        mat-raised-button
        color="primary"
        (click)="activateMarqetaCard()"
        class="mat-action-row__button"
      >
        Unblock
      </button>
      <button mat-raised-button color="primary" [disabled]="isUnactivated()" (click)="replaceMarqetaCard()" class="mat-action-row__button">
        Replace
      </button>
      <button [disabled]="isTerminated()" mat-raised-button color="warn" (click)="removeMarqetaCard()" class="mat-action-row__button">
        Delete
      </button>
    </div>
  </form>
</div>
