import { autoserialize } from 'cerialize';

export class SupplierOwnerModel {
  @autoserialize
  SSN?: string;

  @autoserialize
  address: string;

  @autoserialize
  address2: string;

  @autoserialize
  city: string;

  @autoserialize
  country: string;

  @autoserialize
  dateOfBirth: string;

  @autoserialize
  email: string;

  @autoserialize
  firstName: string;

  @autoserialize
  lastName: string;

  @autoserialize
  loginKey: string;

  @autoserialize
  mainOwner: boolean;

  @autoserialize
  phoneNumber?: string;

  @autoserialize
  shares?: any;

  @autoserialize
  stateCode: string;

  @autoserialize
  zipPostalCode: string;

  constructor(
    SSN: string,
    address: string,
    address2: string,
    city: string,
    country: string,
    dateOfBirth: string,
    email: string,
    firstName: string,
    lastName: string,
    loginKey: string,
    mainOwner: boolean,
    phoneNumber: string,
    shares: any,
    stateCode: string,
    zipPostalCode: string,
  ) {
    this.SSN = SSN;
    this.address = address;
    this.address2 = address2;
    this.city = city;
    this.country = country;
    this.dateOfBirth = dateOfBirth;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.loginKey = loginKey;
    this.mainOwner = mainOwner;
    this.phoneNumber = phoneNumber;
    this.shares = shares;
    this.stateCode = stateCode;
    this.zipPostalCode = zipPostalCode;
  }
}
