import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GetUpdatedRules } from '../../utils/get-updated-rules';
import { UWDailyDataSource } from '../uw-daily-data-source';

@Component({
  selector: 'app-underwriting-daily-edit',
  templateUrl: './underwriting-daily-edit.component.html',
})
export class UnderwritingDailyEditComponent implements OnInit, OnChanges {
  @Input() collectedData: UWDailyDataSource | null;

  dailyForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes) {
    if (changes.collectedData && this.collectedData) {
      this.createForm();
      this.setValueToForm();
    }
  }

  createForm() {
    if (this.dailyForm) {
      return;
    }
    this.dailyForm = this.fb.group({
      order_defect_rate: [null, [Validators.required, Validators.max(100)]],
      late_shipment_rate: [null, [Validators.required, Validators.max(100)]],
      late_responses: [null, [Validators.required, Validators.max(100)]],
      pre_fullfillment_cancel_rate: [null, [Validators.required, Validators.max(100)]],
      delivered_on_time_rate: [null, [Validators.required, Validators.min(0)]],
      valid_tracking_rate: [null, [Validators.required, Validators.min(0)]],
      return_dissatisfaction_rate: [null, [Validators.required, Validators.max(100)]],
    });
  }

  setValueToForm() {
    this.dailyForm.setValue({
      order_defect_rate: Number(this.collectedData.order_defect_rate_rule.error_value),
      late_shipment_rate: Number(this.collectedData.late_shipment_rate_rule.error_value),
      late_responses: Number(this.collectedData.late_responses_rule.error_value),
      pre_fullfillment_cancel_rate: Number(this.collectedData.pre_fullfillment_cancel_rate_rule.error_value),
      delivered_on_time_rate: Number(this.collectedData.delivered_on_time_rate_rule.error_value),
      valid_tracking_rate: Number(this.collectedData.valid_tracking_rate_rule.error_value),
      return_dissatisfaction_rate: Number(this.collectedData.return_dissatisfaction_rate_rule.error_value),
    });
  }

  getUpdatedRules() {
    const keyArray = [
      this.collectedData.order_defect_rate_rule.key,
      this.collectedData.late_shipment_rate_rule.key,
      this.collectedData.late_responses_rule.key,
      this.collectedData.pre_fullfillment_cancel_rate_rule.key,
      this.collectedData.delivered_on_time_rate_rule.key,
      this.collectedData.valid_tracking_rate_rule.key,
      this.collectedData.return_dissatisfaction_rate_rule.key,
    ];
    return GetUpdatedRules(keyArray, this.dailyForm, this.collectedData);
  }
}
