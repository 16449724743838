import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { AppConfigService } from '../../../../../config';

@Injectable()
export class SupplierPlaidService {
  readonly internalApiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.internalApiUrl = appConfig.env.internalApiUrl;
  }

  addToAch(supplierKey: string, accountId: string, decision: boolean): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}plaid/${supplierKey}/add_to_ach/${accountId}`, {
      decision,
    });
  }

  setItemLoginRequired(supplierKey: string, itemId: string, decision: boolean): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}plaid/${supplierKey}/item_login_required/${itemId}`, {
      decision,
    });
  }

  receivePayments(supplierKey: string, accountId: string, decision: boolean): Observable<any> {
    return this.httpClient.post(`${this.internalApiUrl}plaid/${supplierKey}/reciving_payments/${accountId}`, {
      decision,
    });
  }

  refreshAuth(supplierKey: string, itemId: string) {
    return this.httpClient.get(`${this.internalApiUrl}plaid/${supplierKey}/auth_data?itemId=${itemId}`, {});
  }

  refreshBalance(supplierKey: string, itemId: string) {
    return this.httpClient.post(`${this.internalApiUrl}plaid/${supplierKey}/refresh_balance/`, {
      itemId,
    });
  }
}
