import { Injectable } from '@angular/core';
import { Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo, UnderscoreCase } from 'cerialize';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AppConfigService } from '../../../config';
import { ConfigModel } from '../config.model';
import { GlobalSetting } from './../../../manage/global-settings/models/global-setting.model';
import { GlobalSettingsService } from './../../../manage/global-settings/service/global-settings.service';
import { ConfigsAbstractService } from './configs-abstract.service';

export enum ConfigTypes {
  TASK_PRIORITIES = 'task_priorities',
  TASK_STATUSES = 'task_statuses',
  TASK_TEMPLATE = 'task_template',
  EMAIL_BACK_CONFIG = 'email_config',
  EMAIL_FRONT_BATCH_CONFIG = 'email_frontend_batch_config',
  EMAIL_FRONT_CONFIG = 'email_frontend_config',
  EMAIL_TEMPLATE = 'email_frontend_template',
  EMAIL_BASE_TEMPL = 'email_base_template',
  EMAIL_FRONTEND_AUTOMATIC_CONFIG = 'email_frontend_automatic_config',
  TASK_GROUPS = 'task_assignee_allowed_groups',
}

export enum ConfigEmailTypes {
  EMAIL_BACK_CONFIG = 'email_config',
  EMAIL_FRONT_BATCH_CONFIG = 'email_frontend_batch_config',
  EMAIL_FRONT_CONFIG = 'email_frontend_config',
  EMAIL_TEMPLATE = 'email_frontend_template',
  EMAIL_BASE_TEMPL = 'email_base_template',
  EMAIL_FRONTEND_AUTOMATIC_CONFIG = 'email_frontend_automatic_config',
}

export const ConfigEmailTypesNames: any[] = [
  {
    key: ConfigEmailTypes.EMAIL_BACK_CONFIG,
    value: 'Automatic Emails',
    can_add_new: true,
  },
  {
    key: ConfigEmailTypes.EMAIL_FRONT_BATCH_CONFIG,
    value: 'Batch supplier Emails',
    can_add_new: true,
  },
  {
    key: ConfigEmailTypes.EMAIL_FRONT_CONFIG,
    value: 'Manual Emails - Configuration',
    can_add_new: true,
  },
  {
    key: ConfigEmailTypes.EMAIL_TEMPLATE,
    value: 'Manual Emails - Templates',
    can_add_new: true,
  },
  {
    key: ConfigEmailTypes.EMAIL_BASE_TEMPL,
    value: 'Email Styling Default Settings',
    can_add_new: true,
  },
  {
    key: ConfigEmailTypes.EMAIL_FRONTEND_AUTOMATIC_CONFIG,
    value: 'Event Rules v2',
    can_add_new: true,
  },
];

@Injectable()
export class ConfigsService extends ConfigsAbstractService {
  constructor(private appConfig: AppConfigService, private globalSettingsService: GlobalSettingsService) {
    super();
  }

  public list(force = false): Observable<ConfigModel[]> {
    return this.globalSettingsService.getAll(force).pipe(
      first(),
      map((data) =>
        data.map((config) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize({ ...config }, ConfigModel);
        }),
      ),
    );
  }

  public listByType(configType: ConfigTypes): Observable<ConfigModel[]> {
    return this.globalSettingsService.getAllByType(configType).pipe(
      first(),
      map((data) =>
        data.map((config) => {
          DeserializeKeysFrom(UnderscoreCase);
          return Deserialize(config, ConfigModel);
        }),
      ),
    );
  }

  public add(data: ConfigModel): Observable<ConfigModel> {
    SerializeKeysTo(UnderscoreCase);
    return this.globalSettingsService.add(Serialize(data, ConfigModel) as GlobalSetting).pipe(
      first(),
      map((result) => {
        DeserializeKeysFrom(UnderscoreCase);
        return Deserialize(result, ConfigModel);
      }),
    );
  }

  public set(key: string, value: any): Observable<ConfigModel> {
    const data = {
      config_value: value,
    };
    SerializeKeysTo(UnderscoreCase);
    return this.globalSettingsService.edit(key, data).pipe(
      first(),
      map((result) => {
        DeserializeKeysFrom(UnderscoreCase);
        return Deserialize(result, ConfigModel);
      }),
    );
  }

  deleteEmailSetting(key: any): Observable<any> {
    return this.globalSettingsService.delete(key);
  }

  sortTaskTemplates(sortData: Observable<ConfigModel[]>): Observable<ConfigModel[]> {
    const labelFunction = (template) => (template.configValue.label || template.configKey).trim().toLowerCase();
    return sortData.pipe(map((response) => response.sort((prev, second) => labelFunction(prev).localeCompare(labelFunction(second)))));
  }
}
