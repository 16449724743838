<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="title">Fund Transfer Batches</div>

        <div class="actions">
          <button [routerLink]="['../new']" mat-raised-button color="accent">
            <mat-icon>add</mat-icon>
            Add Batch
          </button>
        </div>
      </div>

      <app-filtering-panel-component
        (searchValueEmitter)="searchAction($event)"
        [configuration]="filterConfig"
      ></app-filtering-panel-component>

      <div *ngIf="batchesRows && !isReady; else loading">
        <app-data-table-grid
          [nodesCounted]="true"
          [rows]="batchesRows"
          [columnDefs]="batchesColumns"
          domLayout="autoHeight"
          [offset]="offset"
        >
        </app-data-table-grid>

        <app-pyb-pagination
          *ngIf="batchesRows && batchesRows.length > 0"
          [paginationData]="paginationData"
          (onPageChange)="onPageChange($event)"
        >
        </app-pyb-pagination>
      </div>

      <ng-template #loading>
        <app-loading-spinner [size]="2"></app-loading-spinner>
      </ng-template>
    </div>
  </div>
</section>
