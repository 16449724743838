import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UwTaskProgressModel } from '../../../../model/uw-task-progress.model';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-underwriting-initial-header',
  templateUrl: './underwriting-initial-header.component.html',
})
export class UnderwritingInitialHeaderComponent {
  @Input() UWDecision: string;
  @Input() actualHistoryUsed: any;
  @Input() uwTaskProgress: UwTaskProgressModel = new UwTaskProgressModel();
  @Input() underwritingActionStatusKey: string;
  @Input() underwritingScriptLoading = false;

  @Output() onRunUnderwritingClick = new EventEmitter();

  readonly userPermission = USER_PERMISSION;

  runUnderwriting() {
    this.onRunUnderwritingClick.emit();
  }
}
