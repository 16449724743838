import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, of } from 'rxjs';
import { concat, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { SupplierService } from '../../suppliers/services/supplier.service';
import { SubscriberComponent } from '../component-subscriber/subscriber.component';
import { LayoutService } from '../layout/layout.service';
import { MonitorService } from '../monitor.service';
import { USER_PERMISSION } from '../users/user-permissions.enum';
import { SearchEngineService } from './search-engine-service-3-0/search-engine.service';
import { SearchSingleResultModel } from './search-engine-service-3-0/search-single-result-model';

@Component({
  selector: 'app-search-overlay',
  styleUrls: ['./search-overlay.component.scss'],
  templateUrl: './search-overlay.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [SupplierService],
})
export class SearchOverlayComponent extends SubscriberComponent implements OnInit {
  readonly paginationLimit = 10;
  readonly placeholderPrompt = 'Find a supplier...';

  searchInProgress = false;
  queryString: string;
  suppliersItems: SearchSingleResultModel[];
  totalCount: number;
  hasRestrictedAccessPermission = false;
  noResults: boolean;

  constructor(
    private readonly element: ElementRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly toastrService: ToastrService,
    private readonly layoutService: LayoutService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly monitorService: MonitorService,
    private readonly searchEngineService: SearchEngineService,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.initAutoClose();
    this.monitorService.setUntracked();
    this.listenAndSuggest();
    this.hasRestrictedUWAccessPermission();
  }

  doSearch(queryString: string, offset?: number): void {
    this.searchInProgress = true;
    this.noResults = false;

    if (!offset) {
      offset = 0;
    }

    this.subscriptions.push(
      this.searchEngineService.search3(queryString, offset, this.paginationLimit).subscribe((response) => {
        this.searchInProgress = false;
        this.noResults = !(response.results.length > 0);
        this.suppliersItems = response.results;
        this.totalCount = response.totalCount;
      }),
    );
  }

  listenAndSuggest(): void {
    this.prepareSuggest();
    this.prepareSearch();
  }

  prepareSuggest(): void {
    if (!this.queryString) {
      return;
    }
    this.subscriptions.push(
      this.searchEngineService.search(this.queryString, 7, 10).subscribe(
        (response) => {
          this.cdr.markForCheck();

          const suppliers = response.results;
          this.suppliersItems = suppliers;

          this.searchInProgress = false;

          return suppliers;
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            this.toastrService.error(
              error.status === 400 ? 'Invalid search string - check formatting if you are using special keywords' : error.message,
            );
          }

          this.searchInProgress = false;
          this.listenAndSuggest();
        },
      ),
    );
  }

  prepareSearch(): void {
    this.subscriptions.push(
      fromEvent(this.element.nativeElement, 'keyup')
        .pipe(
          map((e: any) => e.target.value),
          debounceTime(350),
          concat(),
          distinctUntilChanged(),
          filter((query: string) => query.length > 1),
          switchMap((query: string) => {
            this.doSearch(query);
            return of();
          }),
        )
        .subscribe(() => {
          this.cdr.markForCheck();

          this.suppliersItems = [];
          this.searchInProgress = true;
        }),
    );
  }

  closeOverlay(supKey?: string): void {
    if (supKey) {
      this.monitorService.send(supKey);
    }
    this.layoutService.updateSearchOverlayState('close');
  }

  setPage(e): void {
    this.doSearch(this.queryString, e.pageIndex * this.paginationLimit);
  }

  private hasRestrictedUWAccessPermission(): void {
    const permission = this.permissionsService.getPermission(USER_PERMISSION.REST_UW_ACCESS);
    this.hasRestrictedAccessPermission = !isNullOrUndefined(permission);
  }

  private initAutoClose(): void {
    this.subscriptions.push(this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe(() => this.closeOverlay()));
  }
}
