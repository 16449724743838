import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { isUndefined } from 'util';

import { CancelEmitService } from '../../../suppliers/services/cancel-emit.service';
import { CANCELLATION_STATUSES } from '../../../suppliers/supplier/supplier-box-header/supplier-box-header.component';
import { CardFormgroupMessageService } from '../../card-formgroup/card-formgroup-message.service';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditDateAndTimeComponent),
  multi: true,
};

export const TIMES = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
];

@Component({
  selector: 'app-inline-edit-date-and-time',
  styleUrls: ['../base/inline-edit-base.component.scss', './inline-edit-date-and-time.component.scss'],
  templateUrl: './inline-edit-date-and-time.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
})
export class InlineEditDateAndTimeComponent implements ControlValueAccessor, AfterViewChecked, OnInit, OnDestroy, AfterViewInit {
  @Input() label = ''; // Label value for input element
  @Input() type;
  @ViewChild('inlineEditControl') inlineEditControl: ElementRef; // input DOM element

  @Input() formControlNamee: string;
  @Input() formGroup: FormGroup;
  @Input() showCopy = false;

  @Input() isRequired; // Is field required?
  @Input() disabled = false; // Is field disabled?
  @Input() inGroupEdit: boolean; // Is parent formgroup in edit mode

  @Input() compact: boolean;
  @Input() disable: boolean;
  @Input() disableInline: boolean;
  @Input() materialIcon: string;
  @Input() fieldClass = '';
  @Input() helpText = null;
  @Input() isReadOnly = false;

  @Output() onSave = new EventEmitter();

  public timeForm: FormGroup;
  public times: any = TIMES;
  public showDateString: string;

  private _cancelSubscription: Subscription;
  protected _value: any; // Private variable for input value
  protected _preValue: any; // The value before clicking to edit
  public editing = false; // Is Component in edit mode?
  public defaultEmptyValue = '- empty value -';
  public onChange: any = Function.prototype; // Trascend the onChange event
  public onTouched: any = Function.prototype; // Trascend the onTouch event

  get value(): any {
    return this._value;
  }
  set value(v: any) {
    v = this.castValueByType(v);
    if (v !== this._value) {
      this._value = v;
      this.onChange(this._value);
    }
  }

  constructor(
    private messageService: CardFormgroupMessageService,
    private cancelEmitService: CancelEmitService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this._value = new Date();

    if (!this.formControlNamee) {
      throw new Error('Please init required "formControlNamee" attribute');
    }

    if (!this.formGroup) {
      throw new Error('Please init required "formGroup" attribute');
    }

    this._cancelSubscription = this.messageService.subscribe(this.formGroup, (messageEvent) => {
      if (messageEvent.actionType === 'groupInEdit') {
        this.close();
      }
      if (messageEvent.actionType === 'saveGroup') {
        this.formGroup.get(this.formControlNamee).setValue(this.value);
        this.setTime();
      }
      if (messageEvent.actionType === 'cancel') {
        this.resetValues();
      }
      if (messageEvent.actionType === 'clear') {
        this.value = '';
        this.timeForm.reset();
      }
      if (messageEvent.actionType === 'updateInitial') {
        this._preValue = this._value;
      }
      if (messageEvent.actionType === 'update') {
        this.formGroup.get(this.formControlNamee).setValue(this.prepareDate(this.formGroup.get(this.formControlNamee).value));
      }
    });

    this.createForm();
    this.setTime();
  }

  public castValueByType(v: any): any {
    return v;
  }

  public createForm(): void {
    this.timeForm = this.formBuilder.group({
      hours: [0, Validators.required],
      minutes: [0, Validators.required],
      seconds: [0, Validators.required],
      time: ['AM', Validators.required],
    });
  }

  public setTime(): void {
    if (!this.formGroup.get(this.formControlNamee) || !this.formGroup.get(this.formControlNamee).value) {
      return;
    }

    let hours = this.formGroup.get(this.formControlNamee).value.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;

    this.timeForm.get('hours').setValue(hours);
    this.timeForm.get('minutes').setValue(this.formGroup.get(this.formControlNamee).value.getMinutes());
    this.timeForm.get('seconds').setValue(this.formGroup.get(this.formControlNamee).value.getSeconds());
    this.timeForm.get('time').setValue(ampm);

    this.showDateString = moment(this.formGroup.get(this.formControlNamee).value).format('MM/DD/YYYY hh:mm:ss A');
  }

  public writeValue(value: any) {
    this.value = this.castValueByType(value);
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public markAsTouched() {
    this.onTouched();
  }

  public edit(value) {
    this.onTouched();

    if (this.disabled) {
      return;
    }

    this.editing = true;
  }

  public close() {
    this.resetValues();
  }

  public resetValues() {
    this.editing = false;
    if (!isUndefined(this._preValue)) {
      this.value = this._preValue;
      this._preValue = undefined;
      this.setTime();
    }
  }

  public callSave(value) {
    for (const status of CANCELLATION_STATUSES) {
      if (status.toUpperCase() === String(value).toUpperCase()) {
        this.cancelEmitService.setEditCancellation(value);
      }
    }

    this.formGroup.get(this.formControlNamee).setValue(this.prepareDate(new Date(this.formGroup.get(this.formControlNamee).value)));
    this.onSave.emit(this.formGroup.get(this.formControlNamee).value);
    this.setTime();
    this.editing = false;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public prepareDate(date): Date {
    const newDate = new Date(date);

    if (date) {
      const hours = this.timeForm.get('time').value === 'PM' ? this.timeForm.get('hours').value + 12 : this.timeForm.get('hours').value;
      return new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        hours,
        this.timeForm.get('minutes').value,
        this.timeForm.get('seconds').value,
      );
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.resetValues();
    this._cancelSubscription.unsubscribe();
  }

  ngAfterViewChecked() {
    if (isUndefined(this._preValue)) {
      this._preValue = this._value;
    }
  }

  ngAfterViewInit() {
    if (this.formGroup && this.formControlNamee && this.formGroup.controls[this.formControlNamee]) {
      if (this.disabled) {
        setTimeout(() => {
          this.formGroup.controls[this.formControlNamee].disable();
        });
      }
    }
  }

  inEditMode = () => (this.editing && !this.disableInline) || this.inGroupEdit;
  isInlineEditOnly = () => this.editing && !this.inGroupEdit;
  hasRequiredError = () => this.formGroup && this.formGroup.get(this.formControlNamee).hasError('required');
  hasMaxError = () => this.formGroup && this.formGroup.get(this.formControlNamee).hasError('max');
}
