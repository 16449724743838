import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isUndefined } from 'util';

import { AppState } from '../../../store/app.reducers';
import { ConfigsService } from '../../configs/service/configs.service';
import { LookupRepository } from '../../lookup-service/lookup-repository';
import { FETCH_UTIL_ATTR, FetchUtilAttribute, SetUtilAttribute } from './utils.actions';

const attributeFetchFunctions = {
  timezones: { fn: 'getTimezones' },
  fundingPeriods: { fn: 'getFundingPeriods' },
  usStates: { fn: 'getUSStates' },
  businessTypes: { fn: 'getUSStates' },
  countries: { fn: 'getCountries' },
};

@Injectable()
export class UtilsEffects {
  @Effect()
  fetchUtilAttribute = this.actions$.ofType(FETCH_UTIL_ATTR).pipe(
    withLatestFrom(this.store$),
    mergeMap((result) => {
      const fetchAction: FetchUtilAttribute = <FetchUtilAttribute>result[0];
      const appState: AppState = <AppState>result[1];

      const attrName = fetchAction.payload.attr;
      const isAlreadyLoaded = appState.utils.hasOwnProperty(attrName) && !isUndefined(appState.utils[attrName]);
      const isRefreshRequested = fetchAction.hasOwnProperty('payload') && !isUndefined((<any>fetchAction).payload.refresh);

      const serviceClass = attrName === 'settings' ? 'configService' : 'lookupService';
      const serviceFn = attributeFetchFunctions[attrName].fn;

      if (!isAlreadyLoaded || isRefreshRequested) {
        return this[serviceClass][serviceFn]().pipe(
          switchMap((results: any) => {
            return [new SetUtilAttribute({ attr: attrName, data: results })];
          }),
        );
      } else {
        return of();
      }
    }),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private lookupService: LookupRepository,
    private configService: ConfigsService,
  ) {}
}
