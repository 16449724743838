import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { RulesUtilsService } from '../../../rules-utils.service';

@Component({
  selector: 'app-edit-supplier',
  templateUrl: './edit-supplier.component.html',
})
export class EditSupplierComponent implements OnInit {
  @Input() form: FormGroup;
  dataMapping: FormArray;
  data: FormArray;

  constructor(public util: RulesUtilsService) {}

  ngOnInit() {
    if (this.form) {
      this.dataMapping = this.form.get('dataMapping') as FormArray;
      this.data = this.form.get('data') as FormArray;
    }
  }
}
