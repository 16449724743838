<div class="card" *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]">
  <div class="card-content">
    <app-underwriting-initial-card-header
      [chipText]="'Manual'"
      [title]="'Amazon Information'"
      [status]="amazonInformationDecision ? 'Needs review' : 'Valid'"
      [type]="amazonInformationDecision ? underwritingInitialCardHeaderStatusType.DANGER : underwritingInitialCardHeaderStatusType.SUCCESS"
    >
    </app-underwriting-initial-card-header>

    <div class="table-wrapper">
      <table class="table table-condensed table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th width="20%" class="text-right">
              <span>Decision</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of amazonInformation; let $index = index">
            <td>{{ item?.text }}</td>
            <td class="text-right" width="20%">
              <ng-container *ngIf="!computingDataIsInProgress">
                <app-radiobuttons
                  [value]="item.value"
                  [labelPosition]="'before'"
                  [options]="radiobuttons"
                  (onChange)="amazonInformationChange($event, $index)"
                >
                </app-radiobuttons>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
