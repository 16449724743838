<mat-card class="subProductFreemiumFlexParticipation">
  <mat-card-header>
    <mat-card-title class="subProductFreemiumFlexParticipation__title"><h5>Freemium & Flex Participation</h5></mat-card-title>
    <mat-card-subtitle class="subProductFreemiumFlexParticipation__subtitle">(Instant Access only)</mat-card-subtitle>
    <div class="subProductFreemiumFlexParticipation__SDSwitch">
      <span class="subProductFreemiumFlexParticipation__SDSwitch__enable">Enable for SD</span>
      <mat-slide-toggle [(ngModel)]="flexDto.flexEnabled" (change)="changeFlexEnabled($event)"> </mat-slide-toggle>
    </div>
  </mat-card-header>
  <mat-card-content *ngIf="flexDto">
    <div *ngIf="flexDto.marketplaces.length" class="subProductFreemiumFlexParticipation__marketplaces">
      <app-freemium-marketplace-headers></app-freemium-marketplace-headers>
      <app-freemium-marketplace-row
        class="subProductFreemiumFlexParticipation__marketplaceRow"
        *ngFor="let marketplace of flexDto.marketplaces; index as i"
        [marketplace]="marketplace"
      ></app-freemium-marketplace-row>
    </div>
  </mat-card-content>
</mat-card>
