<div>
  <h5>{{ title }}</h5>
  <div class="wrapper-template-buttons">
    <div class="template-buttons" *ngFor="let variable of variables">
      <button mat-raised-button class="mat-primary template-variable" (click)="pasteInEditor(variable.value)">
        {{ variable.label }}
      </button>

      <button ngxClipboard [cbContent]="variable.value" mat-raised-button class="template-copy">
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
  </div>
</div>
