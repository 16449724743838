import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { AppConfigService } from '../../config';
import { SubscriberComponent } from '../../shared/component-subscriber/subscriber.component';
import { GoogleApiService } from './ng-gapi';

export class GoogleSignInSuccess {
  public googleUser: any;

  constructor(googleUser: gapi.auth2.GoogleUser) {
    this.googleUser = googleUser;
  }
}

export class GoogleSignInFailure {}

@Component({
  selector: 'app-google-auth-login',
  template: `<div class="google-login-container d-flex justify-content-center">
    <div id="loginBtn"></div>
  </div>`,
  styleUrls: ['./google-auth-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleAuthLoginComponent extends SubscriberComponent implements AfterViewInit {
  @Output()
  readonly googleSignInSuccess: EventEmitter<GoogleSignInSuccess> = new EventEmitter<GoogleSignInSuccess>();
  @Output()
  readonly googleSignInFailure: EventEmitter<GoogleSignInFailure> = new EventEmitter<GoogleSignInFailure>();

  private clientId: string;

  constructor(appConfig: AppConfigService, private gapiService: GoogleApiService, private cd: ChangeDetectorRef) {
    super();
    this.clientId = appConfig.env.googleAuthClientId;
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.gapiService.onLoad().subscribe(() => {
        this.auth2Init();
      }),
    );
  }

  private auth2Init(): void {
    if (this.clientId === null) {
      throw new Error('clientId property is necessary.');
    }

    (<any>window).onGoogleLibraryLoad = () => {
      (<any>window).google.accounts.id.initialize({
        client_id: this.clientId,
        callback: this.googleResponse.bind(this),
        auto_select: true,
        cancel_on_tap_outside: true,
        itp_support: true,
        context: 'signin',
        ux_mod: 'popup',
      });
      (<any>window).google.accounts!.id.renderButton(document!.getElementById('loginBtn')!, {
        theme: 'filled_blue',
        size: 'large',
        shape: 'rectangular',
        text: 'signin_with',
      });
      (<any>window).google.accounts.id.prompt();

      this.cd.detectChanges();
    };
  }

  async googleResponse(response) {
    if (response.credential) {
      return this.handleSuccess(response);
    }
    this.handleFailure();
  }

  private handleFailure(): void {
    this.googleSignInFailure.next(new GoogleSignInFailure());
  }

  private handleSuccess(googleUser): void {
    this.googleSignInSuccess.next(new GoogleSignInSuccess(googleUser));
  }
}
