<div class="col-xl-12 col-lg-12">
  <div class="card">
    <div>
      <div *ngIf="true; else noMarketplace">
        <mat-card>
          <mat-card-title>
            <div class="row">
              <div class="col-md-6">
                <!-- comany name -->
              </div>
            </div>
          </mat-card-title>

          <mat-card-content>
            <div class="email-settings-table">
              <mat-table #table (matSortChange)="sortData($event)" [dataSource]="dataList" matSort [matSortDirection]="'desc'">
                <ng-container matColumnDef="firstName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>First name</mat-header-cell>
                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable">
                      {{ element.firstName }}
                    </span>

                    <mat-form-field *ngIf="element.isEditable" floatLabel="never">
                      <input matInput [(ngModel)]="element.firstName" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Last name</mat-header-cell>
                  <!-- <mat-cell *matCellDef="let element"> {{ element.lastName }} </mat-cell> -->

                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable">
                      {{ element.lastName }}
                    </span>

                    <mat-form-field *ngIf="element.isEditable" floatLabel="never">
                      <input matInput [(ngModel)]="element.lastName" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="shares">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Ownership %</mat-header-cell>
                  <!-- <mat-cell *matCellDef="let element"> {{ element.shares }} </mat-cell> -->

                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable">
                      {{ element.shares }}
                    </span>

                    <mat-form-field *ngIf="element.isEditable" floatLabel="never">
                      <input matInput [(ngModel)]="element.shares" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Login Email</mat-header-cell>
                  <!-- <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell> -->

                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable">
                      {{ element.email }}
                    </span>

                    <mat-form-field *ngIf="element.isEditable" floatLabel="never">
                      <input matInput [(ngModel)]="element.email" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="address">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
                  <!-- <mat-cell *matCellDef="let element"> {{ element.address }} </mat-cell> -->

                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable">
                      {{ element.address }}
                    </span>

                    <mat-form-field *ngIf="element.isEditable" floatLabel="never">
                      <input matInput [(ngModel)]="element.address" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="SSN">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>SSN</mat-header-cell>
                  <!-- <mat-cell *matCellDef="let element"> {{ element.SSN }} </mat-cell> -->
                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable" class="d-flex align-items-center">
                      {{ !showSSN && element.SSN ? mask : element.SSN }}
                      <mat-icon class="ml-1 pointer small-icon" *ngIf="element.SSN" (click)="toggleMask()">
                        {{ showSSN ? 'visibility_off' : 'remove_red_eye' }}
                      </mat-icon>
                    </span>

                    <mat-form-field *ngIf="element.isEditable && isAuthorizedToChangeSsn" floatLabel="never">
                      <input matInput [(ngModel)]="element.SSN" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="city">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
                  <!-- <mat-cell *matCellDef="let element"> {{ element.SSN }} </mat-cell> -->
                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable">
                      {{ element.city }}
                    </span>

                    <mat-form-field *ngIf="element.isEditable" floatLabel="never">
                      <input matInput [(ngModel)]="element.city" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="stateCode">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
                  <!-- <mat-cell *matCellDef="let element"> {{ element.SSN }} </mat-cell> -->
                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable">
                      {{ element.stateCode }}
                    </span>

                    <mat-form-field *ngIf="element.isEditable" floatLabel="never">
                      <input matInput [(ngModel)]="element.stateCode" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dateOfBirth">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Date of Birth</mat-header-cell>
                  <!-- <mat-cell *matCellDef="let element"> {{ element.dateOfBirth }} </mat-cell> -->
                  <mat-cell class="margin-right" *matCellDef="let element">
                    <span *ngIf="!element.isEditable">
                      {{ element.dateOfBirth }}
                    </span>

                    <mat-form-field *ngIf="element.isEditable" floatLabel="never" class="full-width">
                      <input matInput type="text" placeholder="MM/dd/yyyy" [(ngModel)]="element.dateOfBirth" />
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="!element.isTier" (click)="toggleEditMode(element)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-raised-button *ngIf="element.isEditable" class="button-size" (click)="save(element)" color="accent">
                      Save
                    </button>

                    <button
                      mat-raised-button
                      [disabled]="element.mainOwner"
                      *ngIf="element.isEditable"
                      class="button-size"
                      (click)="delete(element)"
                      color="warn"
                    >
                      Delete
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="additionalBusinessOwnersColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: additionalBusinessOwnersColumns"></mat-row>
              </mat-table>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <ng-template #noMarketplace>
        <div class="card-content">
          <app-loading-spinner [size]="4"></app-loading-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>
