import { PlatformLocation } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { AppConfigService } from '../../../config';
import { B2bDocumentService } from '../../../shared/b2b-document/b2b-document.service';
import { ConfigModel } from '../../../shared/configs/config.model';
import { ConfigsService, ConfigTypes } from '../../../shared/configs/service/configs.service';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { FeatureFlagsService } from '../../../shared/feature-flags/feature-flags.service';
import { PybDocumentsService } from '../../../shared/pyb-documents/pyb-documents.service';
import { PermissionModel } from '../../../shared/users/permission.model';
import { UsersService } from '../../../shared/users/service/users.service';
import { UserModel } from '../../../shared/users/user.model';
import { ObjectsDiffUtil } from '../../../shared/utils/functions/objects-diff.util';
import { trackByProp } from '../../../shared/utils/track-by-prop.util';
import { AppState } from '../../../store/app.reducers';
import { SupplierListModel } from '../../../suppliers/model/supplier-list.model';
import { SupplierService } from '../../../suppliers/services/supplier.service';
import { selectSupplierDetails } from '../../../suppliers/store/supplier/supplier.actions';
import { TasksService } from '../../services/tasks.service';
import { CrmItemStatus } from '../../shared/crm.enums';
import { ItemWithAttachmentComponent } from '../../shared/item-with-attachment.component';
import { AddCrmTask, CrmBeforeTaskSave, EditCrmTask, selectCrmItems, selectPendingCrmStates } from '../../store/crm.actions';
import { DIALOG_SIZE } from './../../../shared/dialog.config';
import { CommentListComponent } from './../../comments/comment-list/comment-list.component';
import { TaskFollower } from './../models/task-follower.model';
import { TaskStatuses } from './../task-statuses';

@Component({
  selector: 'app-task-add-edit',
  templateUrl: './task-add-edit.component.html',
  styleUrls: ['./task-add-edit.component.scss'],
})
export class TaskAddEditComponent extends ItemWithAttachmentComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<TaskAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private usersService: UsersService,
    private supplierService: SupplierService,
    private configsService: ConfigsService,
    private tasksService: TasksService,
    private platformLocation: PlatformLocation,
    appConfig: AppConfigService,
    dialog: MatDialog,
    b2bDownloadService: B2bDocumentService,
    httpClient: HttpClient,
    renderer: Renderer2,
    featureFlagsService: FeatureFlagsService,
    pybDocuments: PybDocumentsService,
    toastrService: ToastrService,
    sanitizer: DomSanitizer,
  ) {
    super(dialog, b2bDownloadService, toastrService, httpClient, appConfig, renderer, featureFlagsService, pybDocuments, sanitizer);

    if (!!data && data.task !== undefined) {
      this.taskData = data.task;
      this.editMode = true;
    }
  }

  get followers(): FormArray {
    return this.taskAddEditForm.get('followers') as FormArray;
  }
  hideButton = true;
  actionId = new Date().toString();
  subscriptions: Subscription[] = [];
  taskAddEditForm: FormGroup;
  taskData: any;
  @ViewChild('taskBodyElement') taskBodyElement: ElementRef;
  @ViewChild('commentListComponent') commentListComponent: CommentListComponent;

  editMode = false;
  loggedUser: any; // TODO nkler: set user model interface
  pending = false;

  // TODO: Load from service
  config = {
    templates: [
      {
        label: 'Template 1',
        value: 'temp1',
      },
    ],
    task_priorities: [
      { name: 'Low', uuid: 'low' },
      { name: 'Medium', uuid: 'medium' },
      { name: 'High', uuid: 'high' },
      { name: 'Critical', uuid: 'critical' },
    ],
    task_statuses: [
      { name: 'New', uuid: 'new' },
      { name: 'Started', uuid: 'in-progr' },
      { name: 'Done', uuid: 'done' },
      { name: 'Closed', uuid: 'closed' },
    ],
  };

  template = new FormControl();
  templateOptions: Observable<ConfigModel[]>;

  assignee = new FormControl();
  assigneeOptions: (UserModel | PermissionModel)[] = [];
  filteredAssignees: Observable<(UserModel | PermissionModel)[]>;
  followersOptions: (UserModel | PermissionModel)[] = [];
  filteredFollowersAssignees: Observable<(UserModel | PermissionModel)[]>[] = [];
  assigneesPending = true;
  test: Observable<any>;
  categories: Observable<{ name: string; uuid: string }[]>;

  supplier = new FormControl();
  filteredSuppliers: Observable<SupplierListModel[]>;
  defaultSupplierSearch = 'created_since:' + new Date().toISOString().slice(0, 10).replace(/-/g, '/');
  suppliersPending = false;
  taskBodyInEdit = false;
  hasAccessTo = true;

  templateName: string;
  showSaveTemplate = false;
  readonly trackByFollowerEmail = trackByProp<TaskFollower>('follower_email');
  readonly ASSIGNEE_TYPE_GROUP = 'group';

  private ownerUuid: string;
  private createdByName: string;
  private universalEmail = 'pyb-admin@payability.com';
  private commentData: any;
  private oldFormValues: any = null;
  private teamEmails: { name: string; uuid: string }[] = null;
  getObjectsDiffTopLevelKeys = (newObject, originalObject) => Object.keys(ObjectsDiffUtil(newObject, originalObject));

  displayAssigneeFn(user: UserModel): string {
    return !isNullOrUndefined(user) ? (typeof user === 'object' ? user.name : user) : '';
  }

  displayFollowersFn(follower): string {
    return !isNullOrUndefined(follower) ? (typeof follower === 'object' ? follower.follower_name : follower) : '';
  }

  displaySupplierFn(supplier: SupplierListModel): string {
    return !isNullOrUndefined(supplier) ? (typeof supplier === 'object' ? supplier.legalName : supplier) : '';
  }

  ngOnInit() {
    this.createForm();
    this.handleBackdropClick();

    this.categories = this.configsService.get('task_categories').pipe(map((results) => (results ? results : [])));

    this.subscriptions.push(
      this.configsService
        .get('task_group_emails')
        .pipe(map((results) => (results ? results : [])))
        .subscribe((results) => {
          this.teamEmails = results;
        }),
    );

    // Close dialog after save
    this.subscriptions.push(
      this.store.select(selectPendingCrmStates).subscribe((crmStates) => {
        if (!isUndefined(crmStates[this.actionId])) {
          if (crmStates[this.actionId] === CrmItemStatus.SUCCESS) {
            this.subscriptions.push(
              this.store.select(selectCrmItems).subscribe((taskItems) => {
                if (taskItems) {
                  this.closeDialog({ actionId: this.actionId, task: taskItems[this.actionId] });
                } else {
                  this.closeDialog({});
                }
              }),
            );
          }
        }
      }),
    );

    // Set default value for supplier search
    this.supplier.setValue(this.defaultSupplierSearch);

    if (!isNullOrUndefined(this.data)) {
      if (this.data.currentSupplier) {
        // Set current supplier as target
        this.subscriptions.push(
          this.store.select(selectSupplierDetails).subscribe((data) => {
            if (!isUndefined(data)) {
              this.taskAddEditForm.patchValue({
                supplierName: data.legalName,
                targetUuid: data.supplierKey,
              });
              this.supplier.setValue(data.legalName);
            }
          }),
        );
      }

      if (this.data.task) {
        // Edit mode

        if (this.taskData.followers.length > 0) {
          this.taskData.follow = true;
        }

        this.taskAddEditForm.patchValue(this.taskData);
        this.supplier.setValue(this.taskData.supplierName);
        this.assignee.setValue(this.taskData.assigneeName);
        this.taskAddEditForm.get('dueDate').setValue(moment.unix(parseInt(this.taskData.dueDate, 10)).toDate());
        this.ownerUuid = this.taskData['ownerUuid'];
        this.createdByName = this.taskData['createdByName'];

        this.oldFormValues = this.taskAddEditForm.value;
      }
    }

    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((authUserModel) => {
        if (!isUndefined(authUserModel)) {
          this.loggedUser = authUserModel;
          if (this.editMode) {
            this.hasAccessTo = this.loggedUser.hasAccessTo(this.taskData, 'edit');
          }
        }
      }),
    );

    this.loadAndSortTaskTemplatesOptions();

    // Subscribe to assignee options
    this.subscriptions.push(
      this.usersService.getPreparedCombinedList().subscribe((data) => {
        this.assigneesPending = false;
        this.assigneeOptions = data;
        this.initAssigneeAutocomplete();
      }),
    );

    // Update form with selected supplier
    this.supplier.valueChanges.pipe(debounceTime(500)).subscribe((search) => {
      this.suppliersPending = true;
      const query = search && typeof search === 'object' ? search.legalName : search === '' ? this.defaultSupplierSearch : search;
      this.filteredSuppliers = this.supplierService.getItems(new HttpParams().set('search', query)).pipe(
        tap((data) => {
          this.suppliersPending = false;
        }),
      );
    });
  }

  findFiles() {
    if (!isNullOrUndefined(this.taskData) && !isNullOrUndefined(this.taskData.body) && !isUndefined(this.taskBodyElement)) {
      const elems = this.taskBodyElement.nativeElement.querySelectorAll('span[pyb-b2b-preview]');
      for (const item of elems) {
        this.transformAttachment(item);
      }
    }
  }

  ngAfterViewInit() {
    this.findFiles();
  }

  initAssigneeAutocomplete() {
    this.filteredAssignees = this.assignee.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map((user: any) => (user && typeof user === 'object' ? user.name : user)),
      map((name) => {
        return name ? this.filterUsers(name) : this.assigneeOptions.slice();
      }),
    );
  }

  filterUsers(val: string): (UserModel | PermissionModel)[] {
    return !isUndefined(this.assigneeOptions)
      ? this.assigneeOptions.filter((user) => {
          return user.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
        })
      : [];
  }

  addFilteredFollowersAssignees(index: number) {
    this.filteredFollowersAssignees[index] = this.followers.at(index).valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map((user: any) => (user && typeof user === 'object' ? user.follower_name : user)),
      map((name) => (name ? this.filterUsers(name) : this.followersOptions.slice())),
    );
  }

  createForm() {
    this.taskAddEditForm = this.formBuilder.group({
      taskUrlTemplate: [''],
      status: ['new', Validators.required],
      priority: ['medium', Validators.required],
      dueDate: [new Date(), Validators.required],
      createdByName: [''],
      assigneeUuid: ['', Validators.required],
      assigneeName: ['', Validators.required],
      assigneeType: ['', Validators.required],
      assigneeEmail: [''],
      supplierName: [''],
      targetUuid: [''],
      title: ['', Validators.required],
      body: ['', Validators.required],
      follow: [false],
      followers: this.formBuilder.array([]),
      category: [''],
    });

    this.createFollowersInputs();
  }

  openSupplierPage() {
    const baseUrl = (<any>this.platformLocation).location.origin;
    return `${baseUrl}/#/suppliers/${this.taskAddEditForm.get('targetUuid').value}/general`;
  }

  templateSelected($event) {
    const templateData = $event.value;

    const msg = 'Changing the template overwrites current data. Do you want to continue?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL.width,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.setTemplate(templateData);
      }
    });
  }

  setTemplate(templateData) {
    if (!isUndefined(templateData.assignee)) {
      templateData['assigneeName'] = templateData.assignee.name;
      templateData['assigneeEmail'] = templateData.assignee.email;
      templateData['assigneeType'] = templateData.assignee.type;
      templateData['assigneeUuid'] = templateData.assignee.uuid;
      templateData['createdByName'] = templateData.created_by_name || templateData.createdByName;
      templateData['supplierName'] = templateData.supplier_name || templateData.supplierName;
      delete templateData['assignee'];
      delete templateData['created_by_name'];
      delete templateData['createdByName'];
      delete templateData['supplier_name'];
      delete templateData['supplierName'];
    }

    this.taskAddEditForm.patchValue(templateData);

    if (!isUndefined(templateData.supplierName)) {
      this.supplier.setValue(templateData.supplierName);
    }

    if (!isUndefined(templateData.assigneeName)) {
      this.assignee.setValue(templateData.assigneeName);
    }

    if (!isUndefined(templateData.dueDate)) {
      this.taskAddEditForm.get('dueDate').setValue(moment.unix(parseInt(templateData.dueDate, 10)).toDate());
    }
  }

  setAssignee($event) {
    const assignee = $event.option.value;
    this.taskAddEditForm.patchValue({
      assigneeUuid: assignee.key,
      assigneeName: assignee.name,
      assigneeEmail: assignee.email,
      assigneeType: assignee.type,
    });
  }

  assignToMe() {
    if (!isUndefined(this.loggedUser)) {
      this.setAssignee({ option: { value: this.loggedUser } });
      this.assignee.setValue(this.loggedUser);
    }
  }

  setSupplier($event) {
    this.taskAddEditForm.controls['supplierName'].setValue($event.option.value.legalName);
    this.taskAddEditForm.controls['targetUuid'].setValue($event.option.value.supplierKey);
  }

  setDueDate(type: string) {
    const date = new Date();
    switch (type) {
      case 'tomorrow':
        date.setDate(date.getDate() + 1);
        break;
      case 'inWeek':
        date.setDate(date.getDate() + 7);
        break;
    }
    this.taskAddEditForm.controls['dueDate'].setValue(date);
  }

  getTaskUrl() {
    const baseUrl = (<any>this.platformLocation).location.origin;
    const uuid = this.taskData ? this.taskData.uuid : '{}';
    return `${baseUrl}/#/tasks?taskUuid=${uuid}`;
  }

  onSaveTask() {
    if (!this.taskAddEditForm.valid) {
      return;
    }
    if (this.editMode) {
      this.editTask(true);
    } else {
      this.addTask();
    }
  }

  getAssigneeEmailFromGlobalSettings(uuid: string): string {
    if (!this.teamEmails) {
      return undefined;
    }

    const teamObj = this.teamEmails.find((te) => te.uuid === uuid);
    if (!teamObj) {
      return undefined;
    }

    return teamObj.name;
  }

  getFormData(): any {
    const formData = { ...this.taskAddEditForm.value };

    if (!formData.follow) {
      formData.followers = [];
    }

    delete formData.follow;

    formData.followers = formData.followers.filter((f) => !!f.follower_email);

    formData.taskUrlTemplate = this.getTaskUrl();
    formData.dueDate = moment(formData.dueDate).unix();

    if (formData.assigneeType === this.ASSIGNEE_TYPE_GROUP) {
      const assigneeEmail = this.getAssigneeEmailFromGlobalSettings(formData.assigneeUuid);
      if (assigneeEmail) {
        formData.assigneeEmail = assigneeEmail;
      }
    }

    return formData;
  }

  addTask() {
    this.taskAddEditForm.controls['createdByName'].setValue(this.loggedUser.name);
    const formData = this.getFormData();

    this.store.dispatch(new AddCrmTask({ data: formData, actionId: this.actionId }));
  }

  editTask(manualAction = false, formData = null) {
    if (manualAction && formData === null) {
      const changed = this.checkToReopenWhenSomethingChanged({ ...this.taskAddEditForm.value });
      if (changed) {
        return;
      }
    }

    if (formData === null) {
      formData = this.getFormData();
    }

    formData.ownerUuid = this.ownerUuid;
    formData.createdByName = this.createdByName;
    if (isNullOrUndefined(formData['assigneeEmail'])) {
      formData['assigneeEmail'] = this.universalEmail;
    }
    this.actionId = this.taskData.uuid;
    this.store.dispatch(new CrmBeforeTaskSave({ data: formData, uuid: this.actionId }));
    this.store.dispatch(new EditCrmTask({ data: formData, uuid: this.actionId }));
  }

  checkToReopenWhenSomethingChanged(formData): boolean {
    if (this.oldFormValues === null) {
      return false;
    }

    if (formData.status !== TaskStatuses.CLOSED) {
      return false;
    }

    const diff = this.getObjectsDiffTopLevelKeys(formData, this.oldFormValues);
    let comment = null;

    if (this.commentListComponent) {
      comment = this.commentListComponent.getCurrentCommentData();
    }

    if (diff.length > 0 || comment !== null) {
      this.openConfirmationDialog(this.getFormData());
      return true;
    }

    return false;
  }

  openConfirmationDialog(formData) {
    const msg = 'Changes have been made to this task. Do you want to REOPEN it?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL.width,
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          formData.status = TaskStatuses.NEW;
          this.editTask(true, formData);

          return;
        }

        this.editTask();
      }),
    );
  }

  handleOnComment(data: any) {
    this.commentData = data;
  }

  closeDialog(payload?): void {
    this.dialogRef.close(payload);
  }

  saveAsTemplate() {
    const templateData = this.getFormData();
    templateData.label = this.templateName;
    templateData.assignee = {
      name: templateData['assigneeName'],
      email: templateData['assigneeEmail'],
      type: templateData['assigneeType'],
      uuid: templateData['assigneeUuid'],
    };

    delete templateData['assigneeName'];
    delete templateData['assigneeEmail'];
    delete templateData['assigneeType'];
    delete templateData['assigneeUuid'];
    delete templateData['created_by_name'];
    delete templateData['createdByName'];
    delete templateData['supplier_name'];
    delete templateData['supplierName'];

    delete templateData['dueDate'];
    delete templateData['targetUuid'];
    delete templateData['taskUrlTemplate'];

    const taskTemplate = {
      configValue: templateData,
      configType: 'task_template',
      configKey: 'task_template_' + Date.now(),
    };
    this.configsService.add(taskTemplate).subscribe(
      (data) => {
        this.toastrService.success('Task template successfully added');
      },
      (response) => {
        this.toastrService.error(response.error.meta.errors[0].message);
      },
    );
  }

  setTaskState(stateName) {
    if (!isUndefined(this.tasksService.tasksTransitions[stateName])) {
      const requestedState = this.tasksService.tasksTransitions[stateName];
      if (!isUndefined(requestedState[this.taskData.status])) {
        this.taskAddEditForm.patchValue({ status: requestedState[this.taskData.status] });
        this.editTask();
      }
    }
  }

  isStateChangePossible(transition) {
    if (!isUndefined(this.tasksService.tasksTransitions) && !isUndefined(this.tasksService.tasksTransitions[transition])) {
      const allowedTransitions = this.tasksService.tasksTransitions[transition];
      return !isUndefined(allowedTransitions[this.taskData.status]);
    } else {
      return false;
    }
  }

  getTaskLink() {
    return this.tasksService.getAbsoluteUrl((<any>this.platformLocation).location.origin, this.taskData.uuid);
  }

  handleCopyLink($event) {
    this.toastrService.success(`Task link ${this.getTaskLink()} copied to clipboard.`);

    $event.preventDefault();
    $event.stopPropagation();
  }

  addFollower(obj: Partial<UserModel> = null): void {
    this.followers.push(this.formBuilder.control(this.followerObject(obj)));
    this.addFilteredFollowersAssignees(this.followers.length - 1);
  }

  followerObject(obj: Partial<UserModel> = null): TaskFollower {
    return {
      follower_email: obj && obj.email ? obj.email : null,
      follower_name: obj && obj.name ? obj.name : null,
    };
  }

  onAddFollower(control: FormControl, follower: MatAutocompleteSelectedEvent): void {
    const followers = this.followers.value as TaskFollower[];
    const email = follower.option.value.email;

    if (followers.findIndex((f) => f.follower_email === email) >= 0) {
      control.setValue(this.followerObject());
      return;
    }

    control.setValue(
      this.followerObject({
        email,
        name: `${follower.option.value.firstname} ${follower.option.value.lastname}`,
      }),
    );

    const lastFollower = followers[followers.length - 1];
    if (lastFollower.follower_email !== null) {
      this.addFollower();
    }
  }

  onRemoveFollower(index: number): void {
    const followers = this.followers;
    followers.removeAt(index);
    this.filteredFollowersAssignees.splice(index, 1);

    if (followers.length === 0) {
      this.addFollower();
    }
  }

  createFollowersInputs(): void {
    let followersInputs = 1;
    if (this.taskData && this.taskData.followers) {
      followersInputs += this.taskData.followers.length;
    }
    for (let i = 0; i < followersInputs; i++) {
      this.addFollower();
    }
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private loadAndSortTaskTemplatesOptions() {
    const sortData = this.configsService.listByType(ConfigTypes.TASK_TEMPLATE);
    this.templateOptions = this.configsService.sortTaskTemplates(sortData);
  }

  getName(authorUuidKey) {
    if (this.assigneeOptions && this.assigneeOptions.length) {
      const foundItem = this.assigneeOptions
        .filter((item) => item.type === 'user')
        .find((item) => {
          return item.key === authorUuidKey;
        });
      if (foundItem) {
        if (foundItem instanceof UserModel) {
          return foundItem.fullname;
        } else {
          return authorUuidKey;
        }
      } else {
        return authorUuidKey;
      }
    }
  }

  private handleBackdropClick(): void {
    const subscription: Subscription = this.dialogRef
      .backdropClick()
      .pipe(mergeMap(() => this.openConfirmationIfDirty()))
      .subscribe((shouldClose: boolean) => (shouldClose ? this.dialogRef.close() : null));

    this.subscriptions.push(subscription);
  }

  private openConfirmationIfDirty(): Observable<boolean> {
    const param = {
      data: { message: 'If you leave modal, all your unsaved data will be lost.' },
      width: DIALOG_SIZE.SMALL.width,
    };

    return this.isFormDirty ? this.dialog.open(ConfirmDialogComponent, param).afterClosed() : of(true);
  }

  private get isFormDirty(): boolean {
    if (this.editMode) {
      const mainModelChanged = !isEqual(this.taskAddEditForm.value, this.oldFormValues);
      const commentChanged = this.commentListComponent?.addCommentComponent?.commentAddForm?.dirty;
      return mainModelChanged || commentChanged;
    }

    return this.taskAddEditForm.dirty;
  }
}
