export enum BUTTON_TYPE {
  REJECTED = 'rejected',
  INCOMPLETE = 'incomplete',
  SALES_RESOLUTION = 'sales resolution',
  VERIFIED = 'verified',
}

export interface ActionButton {
  type: BUTTON_TYPE;
  disabled: boolean;
  loading: boolean;
  color: string;
  name: string;
  disabledReason: string;
}
