import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-supplier-new-payment-gateway-dialog',
  templateUrl: './supplier-new-payment-gateway-dialog.component.html',
  styleUrls: ['./supplier-new-payment-gateway-dialog.component.scss'],
})
export class SupplierNewPaymentGatewayDialogComponent implements OnInit {
  gatewayKey: string;
  accountNumber: string;

  constructor(public dialogRef: MatDialogRef<SupplierNewPaymentGatewayDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}
}
