import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { AppState } from '../../store/app.reducers';
import { SetSupplierKey } from '../../suppliers/store/supplier/supplier.actions';

@Injectable()
export class SupplierKeyResolver implements Resolve<string> {
  constructor(private readonly store: Store<AppState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const SUPPLIER_KEY_PARAMETER = 'supplierKey';
    const supplierKey = route.parent.params[SUPPLIER_KEY_PARAMETER]
      ? route.parent.params[SUPPLIER_KEY_PARAMETER]
      : route.parent.parent.params[SUPPLIER_KEY_PARAMETER];

    if (supplierKey) {
      this.store.dispatch(new SetSupplierKey(supplierKey));
    }

    return supplierKey;
  }
}
