export function CurrencyCellRenderer(params: { [key: string]: number }): string {
  if (params.value === undefined || params.value === null) {
    return '';
  }

  let className = '';
  if (params.value < 0) {
    className = 'subzero';
  }

  const usdFormate = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return `<div class="align-right ${className}">${usdFormate.format(params.value)}</div>`;
}
