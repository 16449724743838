<h1 mat-dialog-title *ngIf="!editMode" data-test="addTaskHeader">Add task</h1>
<h1 mat-dialog-title *ngIf="editMode" data-test="editTaskHeader">
  Edit task #{{ taskData?.itemNumber }}
  <small>Created by: {{ getName(taskData?.ownerUuid) || taskData?.createdByName }}</small>
</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-md-3" [formGroup]="taskAddEditForm">
      <mat-form-field class="full-width" *ngIf="!editMode">
        <mat-select
          placeholder="Template"
          [formControl]="template"
          (selectionChange)="templateSelected($event)"
          data-test="addTaskTemplateInput"
        >
          <mat-option *ngFor="let template of templateOptions | async" [value]="template.configValue">
            {{ template?.configValue?.label ? template?.configValue?.label : template?.configKey }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-select placeholder="Status" formControlName="status" data-test="addTaskStatusInput">
          <mat-option *ngFor="let status of config.task_statuses" [value]="status.uuid">
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-select placeholder="Priority" formControlName="priority" data-test="addTaskPriorityInput">
          <mat-option *ngFor="let priority of config.task_priorities" [value]="priority.uuid">
            {{ priority.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" ngxErrors="dueDate">
        <input matInput [matDatepicker]="picker" placeholder="Due date" formControlName="dueDate" data-test="addTaskDueDateInput" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        <mat-error ngxError="required" when="touched" data-test="addTaskDueDateError"> Due date is required </mat-error>
      </mat-form-field>

      <mat-chip-list class="full-width datepicker-pills mat-chip-list">
        <mat-chip (click)="setDueDate('today')" data-test="addTaskDueDateTodayBtn">Today</mat-chip>
        <mat-chip (click)="setDueDate('tomorrow')" data-test="addTaskDueDateTomorrowBtn">Tomorrow</mat-chip>
        <mat-chip (click)="setDueDate('inWeek')" data-test="addTaskDueDateInWeekBtn">In a week</mat-chip>
      </mat-chip-list>

      <mat-form-field class="full-width">
        <mat-select placeholder="Category" formControlName="category" data-test="addTaskCategoryInput">
          <mat-option *ngFor="let category of categories | async" [value]="category.uuid">
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input
          type="text"
          placeholder="Assignee"
          aria-label="Assignee"
          matInput
          [formControl]="assignee"
          required
          [matAutocomplete]="autoAssignee"
          data-test="addTaskAssigneeInput"
        />

        <app-loading-spinner [size]="1" matSuffix *ngIf="assigneesPending"></app-loading-spinner>
        <mat-autocomplete #autoAssignee="matAutocomplete" [displayWith]="displayAssigneeFn" (optionSelected)="setAssignee($event)">
          <mat-option *ngFor="let option of filteredAssignees | async" [value]="option">
            <i class="material-icons option-icon">{{ option.type === ASSIGNEE_TYPE_GROUP ? 'people' : 'person' }}</i> {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button mat-raised-button class="btn-sm pull-right" (click)="assignToMe()" color="primary" data-test="addTaskAssignToMeBtn">
        Assign to me
      </button>

      <mat-form-field class="full-width">
        <input
          type="text"
          placeholder="Supplier"
          aria-label="Supplier"
          matInput
          [formControl]="supplier"
          [matAutocomplete]="autoSupplier"
          data-test="addTaskSupplierInput"
        />
        <app-loading-spinner [size]="1" matSuffix *ngIf="suppliersPending"></app-loading-spinner>
        <mat-autocomplete #autoSupplier="matAutocomplete" [displayWith]="displaySupplierFn" (optionSelected)="setSupplier($event)">
          <mat-option *ngFor="let option of filteredSuppliers | async" [value]="option">
            {{ option.legalName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div *ngIf="taskAddEditForm.get('targetUuid').value">
        <button
          mat-raised-button
          color="accent"
          ngxClipboard
          [cbContent]="taskAddEditForm.get('targetUuid').value"
          matTooltip="Click to copy supplier key"
          data-test="addTaskCopySupKeyBtn"
        >
          Copy supplier key
        </button>
        <a mat-raised-button color="primary" target="_blank" [href]="openSupplierPage()" data-test="addTaskOpenSupBtn">Open supplier</a>
      </div>
      <div class="mt-2">
        <mat-checkbox formControlName="follow" data-test="addTaskFollowTaskCheckbox"> Follow the task </mat-checkbox>
        <div class="mt-2" [hidden]="!taskAddEditForm.get('follow').value" formArrayName="followers" data-test="addEditTaskFollowersList">
          <div data-test="followers-list-entity" *ngFor="let control of followers.controls; trackBy: trackByFollowerEmail; let i = index">
            <mat-form-field class="full-width">
              <div *ngIf="!assigneesPending && !!control.value.follower_email" matSuffix>
                <button mat-icon-button (click)="onRemoveFollower(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <input
                type="text"
                placeholder="Follower"
                aria-label="Follower"
                matInput
                [formControl]="control"
                [matAutocomplete]="followersAssignee"
                data-test="addTaskFollowerInput"
              />
              <app-loading-spinner [size]="1" matSuffix *ngIf="assigneesPending"></app-loading-spinner>
              <mat-autocomplete
                #followersAssignee="matAutocomplete"
                [displayWith]="displayFollowersFn"
                (optionSelected)="onAddFollower(control, $event)"
              >
                <mat-option *ngFor="let option of filteredFollowersAssignees[i] | async" [value]="option">
                  <i class="material-icons option-icon">{{ option.type === ASSIGNEE_TYPE_GROUP ? 'people' : 'person' }}</i>
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-9">
      <div [formGroup]="taskAddEditForm">
        <mat-form-field class="full-width" *ngIf="hasAccessTo; else titleDisabled" ngxErrors="title" data-test="editTaskTitleHeader">
          <input matInput placeholder="Title" formControlName="title" data-test="editTaskTitleInput" />
          <mat-error ngxError="required" when="touched"> Title is required </mat-error>
        </mat-form-field>
        <ng-template #titleDisabled>
          <h6 class="mt-0" data-test="editTaskTitleDisabled">{{ taskData.title }}</h6>
        </ng-template>
        <div class="button-toggles clearfix" *ngIf="editMode">
          <small *ngIf="taskData.status">
            <div>Change task status:</div>
            <div class="btn-group mat-elevation-z2">
              <button
                [disabled]="!isStateChangePossible('start')"
                (click)="setTaskState('start')"
                type="button"
                class="btn btn-primary"
                data-test="editTaskStatusStart"
              >
                Start
              </button>
              <button
                [disabled]="!isStateChangePossible('reopen')"
                (click)="setTaskState('reopen')"
                type="button"
                class="btn btn-primary"
                data-test="editTaskStatusReopen"
              >
                Reopen
              </button>
              <button
                [disabled]="!isStateChangePossible('resolve')"
                (click)="setTaskState('resolve')"
                type="button"
                class="btn btn-primary"
                data-test="editTaskStatusResolve"
              >
                Resolve
              </button>
              <button
                [disabled]="!isStateChangePossible('close')"
                (click)="setTaskState('close')"
                type="button"
                class="btn btn-primary"
                data-test="editTaskStatusClose"
              >
                Close
              </button>
            </div>
          </small>
          <small class="pull-right">
            <button
              mat-raised-button
              color="accent"
              ngxClipboard
              [cbContent]="getTaskLink()"
              matTooltip="Copy task link"
              (click)="handleCopyLink($event)"
              data-test="editTaskCopyTaskLink"
            >
              Copy link <mat-icon>insert_link</mat-icon>
            </button>
          </small>
        </div>
        <app-comment-list
          *ngIf="editMode"
          #commentListComponent
          [hideButton]="true"
          tasksContext="false"
          [targetUuid]="taskData.uuid"
          targetType="task"
          [addComment]="true"
          [author]="getName(taskData?.ownerUuid) || taskData?.createdByName"
          [createdAt]="taskData?.createdAt"
          [updatedAt]="taskData?.updatedAt"
          data-test="editTaskCommentSection"
        >
        </app-comment-list>
        <div class="task-body" *ngIf="editMode && !taskBodyInEdit; else bodyEditor">
          <div
            class="task-preview-body"
            [ngClass]="{ disabled: !hasAccessTo }"
            #taskBodyElement
            [innerHtml]="taskData.body | safe"
            data-test="editTaskPreview"
          ></div>
          <a class="task-body-edit" *ngIf="hasAccessTo" (click)="taskBodyInEdit = !taskBodyInEdit" data-test="editTaskEditBtn">
            <i class="material-icons">edit</i>
          </a>
        </div>
        <small *ngIf="!hasAccessTo" data-test="editTaskOnlyAuthorCanEditMsg">Only author can fully edit task</small>
        <ng-template #bodyEditor>
          <app-editor formControlName="body"></app-editor>
        </ng-template>
      </div>

      <div *ngIf="!editMode">
        <hr />
        <button
          mat-raised-button
          *ngIf="!showSaveTemplate; else templateForm"
          (click)="showSaveTemplate = !showSaveTemplate"
          color="primary"
          data-test="editTaskSaveAsTemplate"
        >
          Save as template
        </button>

        <ng-template #templateForm>
          <mat-form-field>
            <input matInput placeholder="Template name" [(ngModel)]="templateName" [required]="showSaveTemplate" />
            <span matSuffix>
              <button mat-button mat-icon-button (click)="saveAsTemplate()" matTooltip="Save template" data-test="editTaskSaveTemplateBtn">
                <mat-icon>save</mat-icon>
              </button>
              <button
                mat-button
                mat-icon-button
                (click)="showSaveTemplate = false"
                matTooltip="Cancel"
                data-test="editTaskCancelSaveTemplateBtn"
              >
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </mat-form-field>
          <div class="text-right"></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button
    mat-raised-button
    (click)="onSaveTask()"
    [disabled]="taskAddEditForm.invalid"
    tabindex="2"
    [color]="'accent'"
    data-test="addEditTaskSaveTaskBtn"
  >
    {{ pending ? '' : 'Save' }}
    <app-loading-spinner [size]="1" *ngIf="pending"></app-loading-spinner>
  </button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'" tabindex="-1" data-test="addEditTaskCancelBtn">Cancel</button>
</div>
