import { autoserialize } from 'cerialize';

export class SupplierBalanceModel {
  @autoserialize
  public efsRebate = 0;

  @autoserialize
  public totalBalance = 0.0;

  @autoserialize
  public statementBalance = null;

  @autoserialize
  public availableBalance = 0.0;

  @autoserialize
  public potentialLiability = 0.0;

  @autoserialize
  public dueFromMpBalance = 0;

  @autoserialize
  public pendingBalance = 0;

  @autoserialize
  public reserveBalance = 0.0;

  @autoserialize
  public availablePendingBalance = 0.0;

  @autoserialize
  public availableCardHold = 0.0;

  constructor(
    efsRebate = 0,
    totalBalance = 0,
    statementBalance = null,
    availableBalance = 0,
    potentialLiability = 0,
    dueFromMpBalance = 0,
    pendingBalance = 0,
    reserveBalance = 0,
    availablePendingBalance = 0,
    availableCardHold = 0,
  ) {
    this.efsRebate = efsRebate;
    this.totalBalance = totalBalance;
    this.statementBalance = statementBalance;
    this.availableBalance = availableBalance;
    this.potentialLiability = potentialLiability;
    this.dueFromMpBalance = dueFromMpBalance;
    this.pendingBalance = pendingBalance;
    this.reserveBalance = reserveBalance;
    this.availablePendingBalance = availablePendingBalance;
    this.availableCardHold = availableCardHold;
  }
}
