import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-radiobuttons',
  templateUrl: './radiobuttons.component.html',
  styleUrls: ['./radiobuttons.component.scss'],
})
export class RadiobuttonsComponent implements OnInit {
  @Input() value: boolean;
  @Input() labelPosition: 'before' | 'after';
  @Input() options: { color: ThemePalette; text: string; value?: boolean }[] = [];
  @Output() onChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  change() {
    this.onChange.emit(this.value);
  }
}
