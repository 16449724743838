import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../../../config';
import { ElasticHighlightsService, ISearchResponse } from './elastic-highlights.service';
import { SearchDataModel } from './search-data-model';
import { SearchResponseFixture } from './search-response-fixture';

export const NEW_SEARCH_ENGINE = 'NEW_SEARCH_ENGINE';

@Injectable()
export class SearchEngineService {
  internalApiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.internalApiUrl = appConfig.env.internalApiUrl + 'suppliers';
  }

  search(searchPhrase: string, offset: number, limit: number): Observable<SearchDataModel> {
    // if (this.featureFlags.isFlagOn(NEW_SEARCH_ENGINE)) {
    if (1 === 1) {
      let params = new HttpParams().set('limit', '' + limit);
      params = params.set('offset', '' + offset);
      params = params.set('search2', '' + searchPhrase);
      return this.httpClient
        .get<SearchDataModel>(this.internalApiUrl, {
          params,
        })
        .pipe(
          map((response: SearchDataModel) => new SearchDataModel(response)),
          catchError(this.handleError),
        );
    }
    return observableOf(new SearchDataModel(SearchResponseFixture));
  }

  pybEncode(textToEncode: string): string {
    return textToEncode.replace(/[\?=&\+]/g, function (m) {
      return { '?': '~F', '=': '~3D', '&': '~26', '+': '~2B' }[m];
    });
  }

  search3(searchPhrase: string, offset: number, limit: number): Observable<SearchDataModel> {
    let params = new HttpParams().set('limit', '' + limit);
    params = params.set('offset', '' + offset);
    // params = params.set('search2', '' + searchPhrase);
    params = params.set('search3', '' + this.pybEncode(searchPhrase));

    return this.httpClient
      .get<ISearchResponse>(this.internalApiUrl, {
        params,
      })
      .pipe(
        map((data) => {
          if (!data.hits) {
            const dto = {
              totalCount: 0,
              results: [],
            };
            return new SearchDataModel(dto);
          }
          return ElasticHighlightsService.highlightObj(data, searchPhrase);
        }),
      );
  }

  handleError(error: any, caught: Observable<any>) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    alert(errorMessage);
    return ErrorObservable.create(errorMessage);
  }
}
