import { RuleEngine } from '../../../models/rule-engine.interface';
import { RuleEngineItem } from '../../../models/rule-rngine-item.interface';

export class UWAmazonHealth {
  isLoadingResults = true;
  order_defect_rate_rule: RuleEngineItem;
  late_shipment_rate_rule: RuleEngineItem;
  late_responses_rule: RuleEngineItem;
  pre_fullfillment_cancel_rate_rule: RuleEngineItem;
  delivered_on_time_rate_rule: RuleEngineItem;
  valid_tracking_rate_rule: RuleEngineItem;
  return_dissatisfaction_rate_rule: RuleEngineItem;

  constructor(private numericRules: RuleEngine) {
    this.order_defect_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'order_defect_rate';
    })[0];
    this.late_shipment_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'late_shipment_rate';
    })[0];
    this.late_responses_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'late_responses';
    })[0];
    this.pre_fullfillment_cancel_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'pre_fullfillment_cancel_rate';
    })[0];
    this.delivered_on_time_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'delivered_on_time';
    })[0];
    this.valid_tracking_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'valid_tracking_rate';
    })[0];
    this.return_dissatisfaction_rate_rule = this.numericRules.filter(function (item) {
      return item.field_name === 'return_dissatisfaction_rate';
    })[0];
  }
}
