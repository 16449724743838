<div class="supplier-info-box" [ngClass]="{ collapsed: !this.isOpen, 'supplier-info-box--full-size': fullSize }">
  <app-eva
    *ngIf="supplierKey"
    #topBarComponent
    [url]="url"
    [queryParams]="{ supplierKey: supplierKey, currentPage: currentPage }"
    [ngClass]="{ 'supplier-main-bar': isNewMenu, 'supplier-main-bar-old': !isNewMenu }"
    data-test="supplierMainBar"
  >
  </app-eva>
  <ng-content></ng-content>

  <!--TODO: commented for now as we dont have functionality to collapse header on Eva side-->
  <!--  <button class="btn toggle-header" (click)="togglePanel()"><i class="material-icons">more_horiz</i></button>-->
</div>
