import { Directive, HostListener, Input, Renderer2 } from '@angular/core';

import { IEnvironment } from '../../../environments/environment.interface';
import { AppConfigService } from '../../config';
import { AppsDocumentService } from './../apps-document/apps-document.service';

@Directive({
  // eslint-disable-next-line
  selector: '[appAppsDocumentDownload]',
})
export class AppsDocumentDownloadDirective {
  @Input() fileUuid: string;
  @Input() fileName: string;

  readonly gciId: IEnvironment['workflowGciId'];

  constructor(private renderer: Renderer2, private appConfig: AppConfigService, private appsDocumentsService: AppsDocumentService) {
    this.gciId = appConfig.env.workflowGciId;
  }

  downloadFile() {
    this.appsDocumentsService.getFileDownloadUrl(this.gciId, this.fileUuid, false).subscribe((downloadUrl: string | Blob) => {
      return downloadUrl instanceof Blob
        ? this.downloadUrl(window.URL.createObjectURL(downloadUrl), this.fileName)
        : this.downloadUrl(downloadUrl, this.fileName);
    });
  }

  @HostListener('click') onClick() {
    this.downloadFile();
    return false;
  }

  private downloadUrl(downloadUrl, fileName) {
    const link = this.renderer.createElement('a');

    link.href = downloadUrl;
    link.download = fileName;
    link.target = '_blank';

    this.renderer.listen(link, 'click', (event) => {});

    link.click();
    return false;
  }
}
