import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fileSaver from 'file-saver';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, finalize, map, share, tap } from 'rxjs/operators';
import { isUndefined } from 'util';

import { AppConfigService } from '../../../config';
import { SseService } from '../../../shared/sse.service';

type MonitoringCallback = (payload: any) => void;

@Injectable()
export class B2bWorkflowService {
  private apiUrl: string;
  private gciId: string;

  private handler = new Subject<any>();

  private stream$: Observable<any>;
  private streamShared$: Observable<any>;
  private pendingSubs: number;

  constructor(private appConfig: AppConfigService, private http: HttpClient, private sseService: SseService) {
    this.apiUrl = appConfig.env.workflowApiUrl;
    this.gciId = appConfig.env.workflowGciId.toString();
  }

  taskMonitoring(): Observable<any> {
    const endpointURL = `${this.apiUrl}tasksMonitoring`;
    return !isUndefined(this.streamShared$) ? this.streamShared$ : this.initStream(endpointURL);
  }

  private initStream(endpointURL) {
    this.pendingSubs++;
    this.stream$ = this.sseService.getStream(endpointURL).pipe(
      tap((result) => {
        this.handler.next({ payload: result });
        return result;
      }),
    );
    // this.streamShared$ = this.stream$;
    this.streamShared$ = this.stream$.pipe(share());

    this.stream$.pipe(finalize(() => {}));
    this.streamShared$.pipe(finalize(() => {}));

    return this.streamShared$;
  }

  subscribeTask(taskName: string, callback: MonitoringCallback, searchForInclusion = false): Subscription {
    return this.taskMonitoring().subscribe((taskObject) => {
      return this.handler
        .pipe(
          filter((event) => {
            if (searchForInclusion) {
              if (event.payload.taskName.search(new RegExp(taskName)) > -1) {
                return true;
              }
            } else {
              return event.payload.taskName === taskName;
            }
          }),
          map((event) => {
            return event.payload;
          }),
        )
        .subscribe(callback);
    });
  }

  getLastExecution(name?: string) {
    const endpointURL = `${this.apiUrl}getLastExecution`;
    let params = new HttpParams().set('gci', this.gciId);
    if (!isUndefined(name)) {
      params = params.set('name', name);
    }

    return this.http.get(endpointURL, {
      params,
    });
  }

  executeWorkflow(data) {
    const endpointURL = `${this.apiUrl}executeWorkflow`;
    const params = new HttpParams().set('gci', this.gciId);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const str = [];
    for (const property of Object.keys(data)) {
      str.push(encodeURIComponent(property) + '=' + encodeURIComponent(data[property]));
    }

    const urlEncodedData = str.join('&');

    return this.http.post(endpointURL, urlEncodedData, {
      params,
      headers,
    });
  }

  getListOfActivities(id: string): Observable<any> {
    const encodedId = encodeURIComponent(id);
    const endpointURL = `${this.apiUrl}getListOfActivities?id=${encodedId}`;
    const params = new HttpParams().set('gci', this.gciId);

    return this.http.get(endpointURL, {
      params,
    });
  }

  downloadFile(name: string, url: any) {
    if (url instanceof Blob) {
      this.blobSaveAs(url, name, 'text/txt', '.txt');
      return;
    }

    this.http.get(url, { responseType: 'blob' }).subscribe((file) => {
      this.blobSaveAs(file, name, 'text/txt', '.txt');
    });
  }

  downloadPdfFile(name: string, url: any) {
    if (url instanceof Blob) {
      this.blobSaveAs(url, name, 'application/pdf');
      return;
    }

    this.http.get(url, { responseType: 'blob' }).subscribe((file) => {
      this.blobSaveAs(file, name, 'application/pdf');
    });
  }

  private blobSaveAs(file: any, name: string, type: string, extension: string = ''): void {
    const blob = new Blob([file], { type });
    fileSaver.saveAs(blob, `${name}${extension}`);
  }
}
