import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Deserialize } from 'cerialize';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { merge, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { isArray, isNullOrUndefined, isUndefined } from 'util';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { Logger } from '../../../shared/logger';
import { BatchModel } from '../../models/batch.model';
import { EmailsService } from '../../services/emails.service';
import { EmailsStatusService } from '../../services/emails-status.service';
import { EmailConfirmDialogComponent } from '../email-confirm-dialog/email-confirm-dialog.component';
import { EmailNewBatchComponent } from '../email-new-batch/email-new-batch.component';
import { EmailPreviewComponent } from '../email-preview/email-preview.component';
import { EmailTypes } from '../email-types';
import { EmailExpandRowComponent } from './email-expand-row/email-expand-row.component';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['email-list.component.scss', '../../shared/crm-tools-buttons.scss'],
})
export class EmailListComponent extends SubscriberComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true })
  readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  readonly sort: MatSort;
  @ViewChildren('myExpandedRow', { read: ViewContainerRef })
  readonly containers;

  readonly listFilterSetSubject: Subject<{ [filterKey: string]: any }> = new Subject<{ [filterKey: string]: any }>();
  readonly refresh$: Subject<string> = new Subject<string>();
  readonly dataSource = new MatTableDataSource<BatchModel>();

  readonly columns = ['type', 'created_at', 'subject', 'from', 'status', 'receiver', 'author', 'supplier', 'actions'];
  readonly pageSizeOptions = [10, 25, 50, 100];

  expandedRow: number;
  itemsPerPage = 50;
  resultsLength: number;
  lastItem: any;
  isLoadingResults = false;

  listFilters: { [filterKey: string]: any };

  constructor(
    public readonly dialog: MatDialog,
    private readonly emailsService: EmailsService,
    private readonly toastrService: ToastrService,
    private readonly emailsStatusService: EmailsStatusService,
    private readonly resolver: ComponentFactoryResolver,
  ) {
    super();
  }

  ngOnInit(): void {
    /* Init filters */
    this.listFilters = { type: this.emailsStatusService.getDefaultFiltersType() };
    this.resultsLength = 0;
    this.sort.direction = 'desc';
    this.sort.active = 'created_at';

    this.subscriptions.push(
      this.sort.sortChange.subscribe(() => {
        this.lastItem = undefined;
      }),
    );

    this.subscriptions.push(
      this.listFilterSetSubject.subscribe((filters: { [filterKey: string]: any }) => {
        this.listFilters = filters;
        this.resetDatagridState();
      }),
    );

    this.subscriptions.push(this.paginator.page.subscribe());

    this.subscriptions.push(
      merge(this.sort.sortChange, this.paginator.page, this.listFilterSetSubject, this.refresh$)
        .pipe(
          startWith(null),
          switchMap(() => {
            this.isLoadingResults = true;
            this.itemsPerPage = !isNullOrUndefined(this.paginator.pageSize) ? this.paginator.pageSize : this.itemsPerPage;

            return this.emailsService.listEmails(
              this.itemsPerPage,
              { field: this.sort.active, direction: this.sort.direction },
              { filters: this.filterToQuery(this.listFilters) },
              this.lastItem,
            );
          }),
          map((data) => {
            this.isLoadingResults = false;
            this.resultsLength = data.meta.count;
            this.lastItem = data.meta.last;
            return data.data;
          }),
          catchError((error) => {
            Logger.log(error);
            this.isLoadingResults = false;
            this.lastItem = undefined;
            return of([]);
          }),
        )
        .subscribe((data) => {
          return (this.dataSource.data = data);
        }),
    );
  }

  private approveBatch(batchUuid: string): void {
    this.subscriptions.push(
      this.emailsService.approveBatch(batchUuid).subscribe(
        (response) => {
          this.toastrService.success(response['meta'].message);
          this.refreshStatusRow(Deserialize(response['data'], BatchModel));
          if (this.expandedRow !== null) {
            this.containers.toArray()[this.expandedRow].clear();
          }
          this.expandedRow = null;
        },
        (error) => this.toastError(error),
      ),
    );
  }

  private cancelBatch(batchUuid: string): void {
    this.subscriptions.push(
      this.emailsService.cancelBatch(batchUuid).subscribe(
        (response) => {
          this.toastrService.success(response['meta'].message);
          this.refreshStatusRow(Deserialize(response['data'], BatchModel));
          if (this.expandedRow !== null) {
            this.containers.toArray()[this.expandedRow].clear();
          }
          this.expandedRow = null;
        },
        (error) => this.toastError(error),
      ),
    );
  }

  private toastError(error): void {
    if (error && error.error && error.error.meta && error.error.meta.errors && error.error.meta.errors.length) {
      this.toastrService.error(error.error.meta.errors[0].message);
    }
  }

  private filterToQuery(filterUiModel): { name; op; val }[] {
    const q = (name, op, val) => ({ name, op, val });
    const toTimestamp = (date) => moment(date).unix();
    const filterQuery = [];

    for (const key in filterUiModel) {
      if (filterUiModel.hasOwnProperty(key)) {
        const val = filterUiModel[key];
        if (val === null || isUndefined(val) || val === '') {
          delete filterUiModel[key];
          continue;
        }

        if (key === 'supplier_name') {
          filterQuery.push(q('supplier_name', 'contains', val.toString()));
        } else if (key === 'supplier_key') {
          filterQuery.push(q('target_uuid', 'contains', val.toString()));
        } else if (key === 'subject') {
          filterQuery.push(q('subject', 'contains', val.toString()));
        } else if (key === 'createdBefore') {
          filterQuery.push(q('created_at', 'lt', toTimestamp(val)));
        } else if (key === 'createdAfter') {
          filterQuery.push(q('created_at', 'gt', toTimestamp(val)));
        } else if (key === 'item_number') {
          filterQuery.push(q('item_number', 'eq', parseInt(val, 10)));
        } else if (isArray(val)) {
          filterQuery.push(q(key, 'in', val));
        } else if (val.charAt(0) === '!') {
          filterQuery.push(q(key, 'ne', val.slice(1)));
        } else {
          filterQuery.push(q(key, 'eq', val));
        }
      }
    }
    return filterQuery;
  }

  /* GRID OPTIONS */
  private resetDatagridState(): void {
    this.paginator.pageIndex = 0;
    this.lastItem = undefined;
    this.refresh$.next(Date.now().toString());
  }

  private refreshStatusRow(updatedEmail: BatchModel): void {
    this.dataSource.data = this.dataSource.data.map((emailItem: BatchModel) => {
      if (emailItem.uuid === updatedEmail.uuid) {
        emailItem.status = updatedEmail.status;
        return emailItem;
      } else {
        return emailItem;
      }
    });
  }

  filtersChange(filters: { [filterKey: string]: any }): void {
    this.listFilterSetSubject.next(filters);
  }

  expandRow(index: number, row): void {
    if (row.type !== EmailTypes.BATCH_GROUP) {
      return;
    }
    if (this.expandedRow != null) {
      this.containers.toArray()[this.expandedRow].clear();
    }
    if (this.expandedRow === index) {
      this.expandedRow = null;
    } else {
      const container = this.containers.toArray()[index];
      const factory: ComponentFactory<EmailExpandRowComponent> = this.resolver.resolveComponentFactory(EmailExpandRowComponent);
      const messageComponent = container.createComponent(factory);

      messageComponent.instance.batchGroupUuid = row.uuid;

      this.expandedRow = index;
    }
  }

  /* DIALOGS */
  previewEmailDialog(element: BatchModel): void {
    const previewDialog = this.dialog.open(EmailPreviewComponent, {
      ...DIALOG_SIZE.LARGE,
      data: element,
    });

    this.subscriptions.push(previewDialog.afterClosed().subscribe());
  }

  newBatchEmailDialog(): void {
    const previewDialog = this.dialog.open(EmailNewBatchComponent, {
      ...DIALOG_SIZE.LARGE,
    });

    this.subscriptions.push(
      previewDialog.afterClosed().subscribe((response) => {
        if (response) {
          this.toastrService.success(response.meta.message);
          this.resetDatagridState();
        }
      }),
    );
  }

  approveBatchDialog(element: BatchModel): void {
    const previewDialog = this.dialog.open(EmailConfirmDialogComponent, {
      ...DIALOG_SIZE.MEDIUM_AUTO_HEIGHT,
      data: { msg: 'Are you sure you want to approve this batch? This will send all emails to the suppliers in the batch.' },
    });

    this.subscriptions.push(
      previewDialog.afterClosed().subscribe((response) => {
        if (response) {
          this.approveBatch(element.uuid);
        }
      }),
    );
  }

  cancelBatchDialog(element: BatchModel): void {
    const previewDialog = this.dialog.open(EmailConfirmDialogComponent, {
      ...DIALOG_SIZE.MEDIUM_AUTO_HEIGHT,
      data: { msg: 'Are you sure you want to cancel this batch? This will cancel all emails and you cannot approve them after.' },
    });

    this.subscriptions.push(
      previewDialog.afterClosed().subscribe((response) => {
        if (response) {
          this.cancelBatch(element.uuid);
        }
      }),
    );
  }

  /* RULES FOR BUTTONS AND LINKS */
  showSendAndCancelSingleButton(element: BatchModel): boolean {
    return this.emailsStatusService.isPending(element.status) && !this.emailsStatusService.isBatchGroup(element.type);
  }

  showApproveAndCancelAllButton(element: BatchModel): boolean {
    return this.emailsStatusService.isBatchGroup(element.type) && this.emailsStatusService.isPending(element.status);
  }

  showPreviewEmailLink(element: BatchModel): boolean {
    return !this.emailsStatusService.isBatchGroup(element.type);
  }

  isBatchGroup(type: string): boolean {
    return this.emailsStatusService.isBatchGroup(type);
  }

  /* LABELS TO GRID */
  getEmailTypeName(typeKey: string): string {
    return this.emailsStatusService.getTypeName(typeKey);
  }

  private isActive(index: number): boolean {
    return this.expandedRow === index;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.refresh$.complete();
    this.listFilterSetSubject.complete();
  }
}
