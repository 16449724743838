/* eslint-disable */

import {
  SetApprovedAmount,
  SetExpectedDuration,
  SetExpectedMonthlyEarnings,
  SetForceFull,
  SetForceFullSaved,
  SetGracePeriodEndDate,
  SetNetDeliveryAmount,
  SetNewOffer,
  SetNoRebate,
  SetRequestedAmount,
  SetRequestNotes,
  SetReserveForRepayments,
  SetReturnStrategy,
  SetSavedGracedPeriodEndDate,
  SetSavedNoRebate,
  SetSavedReturnStrategy,
  SetStateFromPersistedData,
  SetToEditable,
  SetToReadOnly,
  SetUnderwritingNotes,
  SetUwStatus,
} from '../supplier/supplier-advances/pending-offer/pending-offer-calculator/store/calculator.actions';
import { OfferStatuses } from '../supplier/supplier-advances/pending-offer/pending-offer-calculator/store/calculator.enums';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';

export const SetAdvanceLineToStore = (store: Store<AppState>, data) => {
  const advanceOfferRequest = data;

  const periods = Array.isArray(advanceOfferRequest!.advance_templates[0]!.periods)
    ? advanceOfferRequest!.advance_templates[0]!.periods
    : [];
  const riskRating = advanceOfferRequest.risk_rating ? +advanceOfferRequest.risk_rating : -1;
  store.dispatch(new SetStateFromPersistedData({ periods, riskRating }));
  store.dispatch(new SetReserveForRepayments(data.extra_reserve_percent));

  store.dispatch(new SetNetDeliveryAmount(data.limit_amount));

  store.dispatch(new SetRequestedAmount(data.requested_amount));
  store.dispatch(new SetRequestNotes(data.request_notes));
  store.dispatch(new SetUnderwritingNotes(data.underwriter_notes));
  store.dispatch(new SetExpectedMonthlyEarnings(data.expected_monthly_earnings));
  const expectedDuration = data.advance_templates && data.advance_templates.length ? data.advance_templates[0].expected_duration : null;
  store.dispatch(new SetExpectedDuration(expectedDuration));
  const gracePeriodLength = data.advance_templates && data.advance_templates.length ? data.advance_templates[0].grace_period_length : null;
  store.dispatch(new SetGracePeriodEndDate(gracePeriodLength));
  store.dispatch(new SetSavedGracedPeriodEndDate(gracePeriodLength));
  store.dispatch(new SetNoRebate(data.no_rebate));
  store.dispatch(new SetSavedNoRebate(data.no_rebate));
  store.dispatch(new SetReturnStrategy(data.return_strategy));
  store.dispatch(new SetSavedReturnStrategy(data.return_strategy));
  store.dispatch(new SetForceFull(data.force_full));
  store.dispatch(new SetForceFullSaved(data.force_full));
  if (OfferStatuses.PROCESSED === data.offer_status) {
    store.dispatch(new SetToReadOnly());
  } else {
    store.dispatch(new SetToEditable());
  }
  store.dispatch(new SetUwStatus(data.underwriting_status));
  store.dispatch(new SetNewOffer(false));
};
