<article class="article">
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="!noActiveAdvance; else noActiveAdvanceTemplate">
      <div class="flex-container">
        <div class="header box box-default">
          <div class="navigation">
            <a [routerLink]="['../../']" title="Go to advances list" class="close-button">
              <i class="material-icons">arrow_back</i>
            </a>
          </div>
          <div class="info">
            <h6>Advance Information</h6>
          </div>
          <div class="actions"></div>
        </div>

        <form [formGroup]="editAdvanceForm">
          <div class="form-content card-container">
            <div [ngClass]="isActive || isRepaid ? 'col-xl-4 col-lg-4' : 'container'">
              <div class="card">
                <div class="card-content">
                  <div class="card-title">Advance Details</div>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Advance Status"
                      formControlName="advanceStatus"
                      type="text"
                      [ngClass]="{ 'text-success': isActive }"
                      readonly
                    />
                  </mat-form-field>
                  <fieldset [disabled]="true">
                    <div class="checkboxes">
                      <mat-checkbox formControlName="approvedByTreasury">Approved By Treasury</mat-checkbox>
                      <mat-checkbox formControlName="viewedBySupplier">Viewed By Supplier</mat-checkbox>
                      <mat-checkbox formControlName="supplierSavedForLater">Supplier Saved For Later</mat-checkbox>
                      <mat-checkbox formControlName="supplierRejected">Supplier Rejected</mat-checkbox>
                    </div>
                  </fieldset>
                  <mat-form-field>
                    <input matInput placeholder="Weeks Since Origination" formControlName="weeksSinceOrigination" type="text" readonly />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Origination Date"
                      [value]="editAdvanceForm.get('originationDate').value | date: 'MM/dd/yyyy'"
                      readonly
                    />
                  </mat-form-field>
                  <div *ngIf="!(isActive || isRepaid)">
                    <app-inline-edit-date
                      *ngIf="isExpired"
                      [formControlName]="'expirationDate'"
                      [formGroup]="editAdvanceForm"
                      [label]="'Expiration Date'"
                      (onSave)="saveExpirationDate()"
                    >
                    </app-inline-edit-date>
                    <mat-form-field *ngIf="!isExpired" class="full-width">
                      <input
                        matInput
                        placeholder="Expiration Date"
                        [value]="editAdvanceForm.get('expirationDate').value | date: 'MM/dd/yyyy'"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                  <app-inline-edit-text
                    *ngIf="isPending"
                    [formControlName]="'advanceTotalAmount'"
                    [formGroup]="editAdvanceForm"
                    [label]="'Advance Total'"
                    (onSave)="saveAdvanceTotal()"
                  >
                  </app-inline-edit-text>
                  <mat-form-field *ngIf="!isPending">
                    <input
                      matInput
                      placeholder="Advance Total"
                      [value]="editAdvanceForm.get('advanceTotalAmount').value | currency: 'USD'"
                      readonly
                    />
                  </mat-form-field>
                  <app-inline-edit-text
                    [formControlName]="'advanceReservePercent'"
                    [formGroup]="editAdvanceForm"
                    [label]="'Advance Fee Reserve (%)'"
                    (onSave)="saveAdvanceReserve()"
                  >
                  </app-inline-edit-text>
                  <app-inline-edit-text
                    [formControlName]="'startReservePercent'"
                    [formGroup]="editAdvanceForm"
                    [label]="'Start Reserve (%)'"
                    (onSave)="saveStartReserve()"
                  >
                  </app-inline-edit-text>
                  <mat-form-field>
                    <input matInput placeholder="Advance Fee Reserve ($)" formControlName="reserveTotalAmount" readonly />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Net Distribution"
                      [value]="editAdvanceForm.get('netDistribution').value | currency: 'USD'"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field [hidden]="true">
                    <input matInput placeholder="Weekly Fee Percent" formControlName="feePercent" type="number" readonly />
                  </mat-form-field>
                  <app-short-offer-summary [advance]="advance"></app-short-offer-summary>
                  <div *ngIf="!isPending">
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Total Fees Charged"
                        [value]="editAdvanceForm.get('totalFeesCharged').value | currency: 'USD'"
                        readonly
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Early Payment Rebate"
                        [value]="editAdvanceForm.get('earlyPaymentRebate').value | currency: 'USD'"
                        readonly
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Advance Liability"
                        [value]="editAdvanceForm.get('advanceLiability').value | currency: 'USD'"
                        readonly
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Outstanding Balance"
                        [value]="editAdvanceForm.get('advanceOutstanding').value | currency: 'USD'"
                        readonly
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Estimated Maturity Date"
                        [value]="editAdvanceForm.get('maturityDate').value | date: 'MM/dd/yyyy'"
                        readonly
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Grace Period End Date"
                        formControlName="gracePeriodEndDate"
                        [value]="editAdvanceForm.get('gracePeriodEndDate').value | date: 'MM/dd/yyyy'"
                        readonly
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Grace Period length"
                        formControlName="gracePeriodLength"
                        [value]="editAdvanceForm.get('gracePeriodLength').value"
                        readonly
                      />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Grace Period length"
                        formControlName="gracePeriodLength"
                        [value]="editAdvanceForm.get('noRebate').value"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isActive || isRepaid" class="col-xl-4 col-lg-4">
              <div class="card">
                <div class="card-content">
                  <div class="card-title">Payment Details</div>
                  <app-inline-edit-text
                    *ngIf="!isRepaid"
                    [formControlName]="'extraReservePercent'"
                    [formGroup]="editAdvanceForm"
                    [label]="'Reserve For Repayment Rate (%)'"
                    (onSave)="saveExtraReservePercent()"
                  >
                  </app-inline-edit-text>
                  <mat-form-field *ngIf="isRepaid">
                    <input
                      readonly
                      matInput
                      placeholder="Reserve For Repayment Rate (%)"
                      [value]="editAdvanceForm.get('extraReservePercent').value"
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      readonly
                      matInput
                      placeholder="Total Amount Repaid"
                      [value]="editAdvanceForm.get('repaidAmount').value | currency: 'USD'"
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Current Advance Reserve"
                      [value]="editAdvanceForm.get('extraReserveAmount').value | currency: 'USD'"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="Last Payment Date" formControlName="lastPaymentDate" type="date" readonly />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Last Payment Amount"
                      [value]="editAdvanceForm.get('lastPaymentAmount').value | currency: 'USD'"
                      readonly
                    />
                  </mat-form-field>

                  <div class="card-actions">
                    <button [disabled]="!isActive" mat-raised-button color="primary" (click)="payAdvanceOnClick()">Pay Advance</button>
                    <mat-checkbox
                      formControlName="delinquentAdvance"
                      (change)="delinquentAdvanceOnClick()"
                      [disabled]="!delinquentAdvanceIsEnabled"
                      >Delinquent Advance</mat-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isActive || isRepaid" class="col-xl-4 col-lg-4">
              <div class="card">
                <div class="card-content">
                  <div class="card-title">Re-Finance Eligibility</div>
                  <mat-form-field>
                    <input matInput placeholder="Eligible For Re-Finance" formControlName="eligibleForReoffer" readonly />
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="Pacing Status (weeks)" formControlName="pacingStatus" readonly />
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="Machine Learning Ranking" formControlName="riskRating" readonly />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      class="text-primary"
                      placeholder="Requested Re-offer Amount (Net Delivery Amount)"
                      formControlName="reofferAmount"
                    />
                    <mat-error *ngIf="hasRequiredError()">This field is required</mat-error>
                    <mat-error *ngIf="hasMinError()">Amount needs to be greater than advance liability</mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Requested Refinance Gross Advance Amount"
                      [value]="editAdvanceForm.get('netDeliveryAmount').value | currency: 'USD'"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Refinance Reserve Amount"
                      [value]="editAdvanceForm.get('reofferReserve').value | currency: 'USD'"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      [ngStyle]="{ color: colorNetDeliveryRequested() }"
                      matInput
                      placeholder="Net Deliver Ammount (Requested)"
                      [value]="editAdvanceForm.get('netDeliveryRequested').value | currency: 'USD'"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="Reserve For Repayment (%)" formControlName="reserveForRepayment" readonly />
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="Advance Liability" [value]="editAdvanceForm.get('outstandingLiability').value" readonly />
                  </mat-form-field>
                  <app-short-offer-summary [advance]="advanceReoffer"></app-short-offer-summary>
                  <div class="card-actions">
                    <button mat-raised-button color="primary" (click)="requestOfferOnClick()" [disabled]="isRequestDisabled()">
                      Request Offer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
  </ng-container>
</article>

<ng-template #loading>
  <div class="justify-content-center flex d-flex full-width">
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #noActiveAdvanceTemplate>
  <app-no-data [text]="'There is no active advance'"></app-no-data>
</ng-template>
