<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <div class="full-width">
    <div *ngIf="xactData && !isDataLoading; else loading">
      <app-data-table-grid [rows]="xactData" [columnDefs]="xactColumnDefs" [domLayout]="'autoHeight'"> </app-data-table-grid>
    </div>
    <ng-template #loading>
      <app-loading-spinner [size]="2"></app-loading-spinner>
    </ng-template>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true" tabindex="2" color="accent">Close</button>
</div>
