/*
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS
 * FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR
 * COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER
 * IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN
 * CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, throwError as _throw } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class PendingInterceptorService implements HttpInterceptor {
  private _pendingRequests = 0;
  private _pendingRequestsStatus: Subject<boolean> = new Subject<boolean>();

  constructor(private toastrService: ToastrService) {}

  get pendingRequestsStatus(): Observable<boolean> {
    return this._pendingRequestsStatus.asObservable();
  }

  get pendingRequests(): number {
    return this._pendingRequests;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._pendingRequests++;

    if (1 === this._pendingRequests) {
      this._pendingRequestsStatus.next(true);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status >= 500) {
            this.toastrService.error(`${error.status}: ${error.message}`);
          }
        }

        return _throw(error);
      }),
      finalize(() => {
        this._pendingRequests--;

        if (0 === this._pendingRequests) {
          this._pendingRequestsStatus.next(false);
        }
      }),
    );
  }
}

export function PendingInterceptorServiceFactory(toastrService) {
  return new PendingInterceptorService(toastrService);
}

export const PendingInterceptorServiceFactoryProvider = {
  provide: PendingInterceptorService,
  useFactory: PendingInterceptorServiceFactory,
  deps: [ToastrService],
};
