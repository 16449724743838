import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { AppConfigService } from '../../config';
// import { selectFundingParticulars, selectSupplierDetails, selectSupplierDetailsAndFunding } from '../../store/supplier/supplier.actions';

interface AccessPriceCalculatorPayload {
  seller_size: string;
  senior_lien: string;
  supplier_category: string;
  time_selling: string;
  repayment_method: string;
  fba: string;
}

@Injectable()
export class AccessCalculatorService {

  private apiUrl: string;

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.internalApiUrl;
  }

  public calculate(payload: AccessPriceCalculatorPayload, supplierKey: string): Observable<any> {
    const url = this.getCalculateUrl(supplierKey);
    return from(this.httpClient.post(url, payload));
  }

  public fetchCalculated(supplierKey: string): Observable<any> {
    const url = this.getCalculateUrl(supplierKey);
    return from(this.httpClient.get(url));
  }

  public save(payload: Record<string, any>, supplierKey: string): Observable<any> {
    const url = this.getCalculateUrl(supplierKey) + '/save';
    return from(this.httpClient.post(url, payload));
  }

  private getCalculateUrl(supplierKey: string): string {
    return `${this.apiUrl}suppliers/${supplierKey}/access_risk_calculator`;
  }


}

