import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { isUndefined } from 'util';

import { CsvExportService } from '../../../shared/csv-export-service/csv-export.service';
import { DataTableQueryParams, DataTableSettingsRecord } from '../../../shared/data-table/data-table.common';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { LookupAbstractService } from '../../../shared/lookup-service/lookup-abstract.service';
import { ADD_TRANSACTION_EVENT, TableEventsService } from '../../../shared/table-events.service';
import { AppState } from '../../../store/app.reducers';
import { SupplierService } from '../../services/supplier.service';
import { selectSupplierDetails } from '../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';
import { ROW_COUNT_COLUMN_DEF } from './../../../shared/data-table-grid/models/row-count-column-def';
import { PaginationConfigDTO } from './../../../shared/pyb-pagination/pagination-config-dto';
import { IPaginationData } from './../../../shared/pyb-pagination/pagination-data';
import { ReceivableService } from './../add-receivable/service/receivable.service';
import { CancelTransactionComponent } from './cancel-transaction/cancel-transaction.component';
import { FailedPaymentComponent } from './failed-payment/failed-payment.component';
import { NestedTransactionComponent } from './nested-transaction/nested-transaction.component';

@Component({
  selector: 'app-supplier-transaction-details',
  templateUrl: './supplier-transaction-details.component.html',
  styleUrls: ['./supplier-transaction-details.component.scss'],
})
export class SupplierTransactionDetailsComponent extends SubscriberComponent implements OnInit {
  @HostBinding('class.is-open')
  isBlocked = false;

  public readonly reportName: string = 'xacts';

  private readonly reversable = ['SUP_PMT'];
  private readonly cancellable = [
    'ADJUST_COMM',
    'BAD_DEBT_RECOVER',
    'BAD_DEBT_WRITEOFF',
    'BAL_BASED_FCT_COM',
    'CARD_ACTION',
    'CREATE_RCV',
    'DEL_COL_FEE',
    'DEL_COL_REFUND',
    'EARN_ADJ_NO_RCV',
    'EARN_ADJ_PURCH',
    'EARN_ADJ_WITH_RCV',
    'EFS_RBT_EFS_DUE',
    'EFS_RBT_SUP_CRED',
    'MISC_SUP_CRED',
    'MP_CBK',
    'MP_PMT_RCVD',
    'OPS_FIX',
    'PENDING_RELEASE',
    'PMT_FAILED',
    'PURCH_RCV',
    'REPURCHASE',
    'RSV_RELEASE',
    'SUP_ADV',
    'SUP_ADV_FEE',
    'SUP_ADV_PMT',
    'SUP_FUND_RECOV',
  ];

  public reportData: Observable<any>;
  public dataFetch = new Subject();
  public reportConfig: Observable<any>;
  public currentQueryParams: any;

  public baseHttpParams: any;
  public reportConfigName: string;
  public supplierKey: string;
  public tableSettings: DataTableSettingsRecord;
  public paginationData?: IPaginationData;
  public total: number;

  xactData = [];
  isDataLoading = false;
  paginationConfiguration: PaginationConfigDTO = new PaginationConfigDTO();
  private paginationOptions = [5, 10, 25, 50, 100];

  xactFilterConfig = [
    {
      fieldName: 'startPostDate',
      label: 'xact post date (from)',
      fieldType: 'datePicker',
      defaultValue: null,
    },
    {
      fieldName: 'endPostDate',
      label: 'xact post date (to)',
      fieldType: 'datePicker',
      defaultValue: null,
    },
    {
      fieldName: 'xact_type_code',
      label: 'xact type',
      fieldType: 'input',
      defaultValue: null,
    },
    {
      fieldName: 'contextXactKey',
      label: 'context',
      fieldType: 'input',
      defaultValue: null,
    },
  ];
  readonly xactColumnDefs = [
    ROW_COUNT_COLUMN_DEF,
    {
      field: 'xact_ts',
      headerName: 'xact_ts',
      cellRenderer: ({ value }) => {
        const date = moment(value).format('MM/DD/yyyy h:mm a');
        return `<span class="cell-link">${date}</span>`;
      },
      onCellClicked: (data: any) => this.tableRowHeaderAction(data.data),
    },
    {
      field: 'transactionDate',
      headerName: 'Date',
    },
    {
      field: 'transactionType',
      headerName: 'Description',
      width: 250,
      cellRenderer: (data) => {
        const status = data.data.contextStatus;

        if (status === 'Available') {
          return '<span class="cell-link">' + data.value + '</span>';
        } else {
          return data.value;
        }
      },
    },
    {
      field: 'summaryAmount',
      headerName: 'Amount',
      width: 100,
    },
    {
      field: 'availForSupplierChange',
      headerName: 'Change',
      width: 100,
    },
    {
      field: 'availForSupplierBalance',
      headerName: 'Balance',
      width: 100,
    },
    {
      field: 'reserveChange',
      headerName: 'Change',
      width: 100,
    },
    {
      field: 'reserveBalance',
      headerName: 'Balance',
      width: 100,
    },
    {
      field: 'dueFromMarketChange',
      headerName: 'Non-specific Change',
      width: 100,
    },
    {
      field: 'dueFromMarketBalance',
      headerName: 'Non-specific Balance',
      width: 100,
    },
    {
      field: 'dueFromMarketUnpurchChange',
      headerName: 'Un-purch Change',
      width: 100,
    },
    {
      field: 'dueFromMarketUnpurchBalance',
      headerName: 'Un-purch Balance',
      width: 100,
    },
    {
      field: 'dueFromMarketPurchChange',
      headerName: 'Purch Change',
      width: 100,
    },
    {
      field: 'dueFromMarketPurchBalance',
      headerName: 'Purch Balance',
      width: 100,
    },
    {
      field: 'reserveBaseChange',
      headerName: 'Change',
      width: 100,
    },
    {
      field: 'reserveBaseBalance',
      headerName: 'Balance',
      width: 100,
    },
    {
      field: 'balBasedFeeBaseChange',
      headerName: 'Change',
      width: 100,
    },
    {
      field: 'balBasedFeeBaseBalance',
      headerName: 'Balance',
      width: 100,
    },
    {
      field: 'xact_key',
      headerName: 'xact_key',
      minWidth: 280,
    },
    {
      field: 'xact_type_code',
      headerName: 'xact_type_code',
      filter: {
        label: 'xact_type',
      },
    },
    {
      field: 'contextStatus',
      headerName: 'Context status',
      width: 100,
    },
    {
      field: 'xact_type_code',
      headerName: 'Action',
      width: 100,
      cellRenderer: ({ value }) => {
        if (this.cancellable.indexOf(value) > -1) {
          return '<span class="cell-link">Cancel</span>';
        } else if (this.reversable.indexOf(value) > -1) {
          return '<span class="cell-link">Failed payment</span>';
        } else {
          return '';
        }
      },
      onCellClicked: (data: any) => this.reverseOrCancelPayment(data.data),
    },
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly service: SupplierService,
    private readonly lookupService: LookupAbstractService,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly csvExportService: CsvExportService,
    private readonly eventService: TableEventsService,
    private readonly store: Store<AppState>,
    private readonly receivableService: ReceivableService,
  ) {
    super();

    this.subscriptions.push(
      this.dataFetch
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap((params?: any) =>
            this.service.getSupplierReport(this.supplierKey, this.reportName, params).pipe(
              tap(() => {
                this.isDataLoading = false;
              }),
              map((data) => ({ data, params })),
              catchError(() => {
                this.isDataLoading = false;
                return of({ data: { results: [], total: 0 }, params });
              }),
            ),
          ),
        )
        .subscribe((result) => {
          const { data, params } = result;
          this.xactData = data.results;
          this.total = data.total;
          this.baseHttpParams.offset = params.offset;

          if (params.startPostDate) {
            this.baseHttpParams.startPostDate = params.startPostDate;
          }

          if (params.endPostDate) {
            this.baseHttpParams.endPostDate = params.endPostDate;
          }

          this.setPaginationData();
        }),
    );
  }

  ngOnInit() {
    this.isDataLoading = true;
    this.baseHttpParams = {
      limit: 50,
      offset: 0,
    };

    this.paginationConfiguration.maxPagesShown = 5;
    this.paginationConfiguration.perPageOptions = this.paginationOptions;
    this.paginationConfiguration.selectedPerPage = this.baseHttpParams.limit;

    this.reportConfigName = !isUndefined(this.reportName)
      ? this.reportName.includes('v_')
        ? this.reportName
        : `v_${this.reportName}`
      : '';

    this.eventService.on(ADD_TRANSACTION_EVENT, () => {
      this.getData(this.currentQueryParams);
    });

    this.subscriptions.push(
      this.receivableService.change.subscribe(() => {
        this.isBlocked = true;
        setTimeout(() => {
          this.isBlocked = false;
        }, 8000);
      }),
    );

    this.subscriptions.push(
      this.store
        .select(selectSupplierDetails)
        .pipe(
          filter((data) => !!(data && data.supplierKey)),
          tap((data) => (this.supplierKey = data.supplierKey)),
          mergeMap(() => this.route.queryParams),
          tap((params) => {
            if (params.date) {
              this.baseHttpParams.startPostDate = params.date;
              this.baseHttpParams.endPostDate = params.date;
            }
          }),
        )
        .subscribe(() => {
          this.prepareDefaultFilters();
        }),
    );
  }

  private prepareDefaultFilters(): void {
    let today = moment().format('YYYY-MM-DD');
    let from = moment().subtract(1, 'month').format('YYYY-MM-DD');

    const endPostDate = this.xactFilterConfig.find((r) => r.fieldName === 'endPostDate');
    const startPostDate = this.xactFilterConfig.find((r) => r.fieldName === 'startPostDate');

    if (this.baseHttpParams.startPostDate && this.baseHttpParams.endPostDate) {
      today = this.baseHttpParams.endPostDate;
      from = this.baseHttpParams.startPostDate;
    }

    endPostDate.defaultValue = today;
    startPostDate.defaultValue = from;
  }

  private getData(params?: DataTableQueryParams): void {
    if (!params) {
      params = this.baseHttpParams;
    }

    this.currentQueryParams = params;

    this.isDataLoading = true;
    this.dataFetch.next(params);
  }

  private reverseOrCancelPayment(record: any): void {
    let dialogRef;

    if (record) {
      const xactCode = record.xact_type_code;
      if (this.cancellable.indexOf(xactCode) > -1) {
        dialogRef = this.dialog.open(CancelTransactionComponent, {
          data: {
            title: record.transactionType,
            supplierKey: this.supplierKey,
            transactionKey: record.xact_key,
          },
          width: DIALOG_SIZE.SMALL.width,
        });
      } else if (this.reversable.indexOf(xactCode) > -1) {
        dialogRef = this.dialog.open(FailedPaymentComponent, {
          data: {
            title: record.transactionType,
            supplierKey: this.supplierKey,
            transactionKey: record.xact_key,
          },
          width: DIALOG_SIZE.SMALL.width,
        });
      }

      this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {}));
    }
  }

  private tableRowHeaderAction(row: any): void {
    const dialogRef = this.dialog.open(NestedTransactionComponent, {
      data: {
        title: 'Transaction details',
        supplierKey: this.supplierKey,
        transactionKey: row.xact_key,
      },
      ...DIALOG_SIZE.RWD_80,
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {}));
  }

  getFilteredXact(filters) {
    const args = {
      limit: 50,
      offset: 0,
      ...filters,
    };

    this.getData(args);
  }

  exportCsv() {
    const args = { ...this.currentQueryParams, make_dump: true };
    this.subscriptions.push(
      this.service.getSupplierReport(this.supplierKey, this.reportName, args).subscribe((data) => {
        this.csvExportService.exportTransactions(data, 'transaction-details', '');
      }),
    );
  }

  refreshButton() {
    this.subscriptions.push(
      this.service.refreshCacheSupplier(this.supplierKey).subscribe(
        (success) => {
          this.toastrService.success('The supplier has been updated');
        },
        (error) => {
          this.toastrService.error('The supplier has not been updated');
        },
      ),
    );
    this.getData();
  }

  onPageChange(dataTableQueryParams: DataTableQueryParams): void {
    this.baseHttpParams = {
      ...this.baseHttpParams,
      ...dataTableQueryParams,
    };

    this.currentQueryParams = { ...this.currentQueryParams, ...dataTableQueryParams };

    this.getData();
  }

  setPaginationData() {
    this.paginationData = {
      ...this.baseHttpParams,
      total: this.total,
    };
  }
}
