import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export const ADD_TRANSACTION_EVENT = 'ADD_TRANSACTION_EVENT';
export const CRM_OPEN_ALL_TAB = 'CRM_OPEN_ALL_TAB';
export const SIDENAV_CLOSED_EVENT = 'SIDENAV_CLOSED_EVENT';
export const TASK_COUNTERS_UPDATE = 'TASK_COUNTERS_UPDATE';

@Injectable()
export class TableEventsService {
  private listeners = {};
  private events = new Subject();

  constructor() {
    this.events.subscribe(({ name, args }) => {
      if (this.listeners[name]) {
        for (const listener of this.listeners[name]) {
          listener(...args);
        }
      }
    });
  }

  on(name, listener) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  broadcast(name, ...args) {
    this.events.next({
      name,
      args,
    });
  }
}
