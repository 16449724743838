import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubscriberComponent } from '../../shared/component-subscriber/subscriber.component';
import { CsvExportService } from '../../shared/csv-export-service/csv-export.service';
import { CommunicationService } from '../../shared/iframe/communication/communication.service';
import { MessageFromIframe } from '../../shared/iframe/communication/communication-message.model';
import { MESSAGE_TYPE } from '../../shared/iframe/communication/message-type.enum';
import { IFetchedRequestsState } from '../../shared/interceptors/fetched-requests.reducers';
import { ActionsHide, ActionsShow } from '../../shared/layout/store/layout.actions';
import { USER_PERMISSION } from '../../shared/users/user-permissions.enum';
import { AppState } from '../../store/app.reducers';
import { selectSupplier, UnloadSupplier } from '../store/supplier/supplier.actions';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
})
export class SupplierComponent extends SubscriberComponent implements OnInit, OnDestroy {
  readonly userPermission = USER_PERMISSION;
  fetchedRequests$: Store<IFetchedRequestsState>;
  supplierError: HttpErrorResponse;
  fullSize = true;

  constructor(
    private readonly store: Store<AppState>,
    private readonly csvExportService: CsvExportService,
    private readonly communicationService: CommunicationService,
  ) {
    super();
  }

  exportErrorStatusToCsv(data, filename): void {
    this.csvExportService.exportCsv(data, 'error' + filename);
  }

  ngOnInit(): void {
    this.store.dispatch(new ActionsShow());
    this.subscriptions.push(this.store.select(selectSupplier).subscribe((supplier) => (this.supplierError = supplier.supplierError)));
    this.subscriptions.push(this.store.select('layout').subscribe(() => window.dispatchEvent(new Event('resize'))));
    this.fetchedRequests$ = this.store.select('errors');
    this.listenIframeMessages();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(new UnloadSupplier());
    this.store.dispatch(new ActionsHide());
  }

  private listenIframeMessages(): void {
    this.subscriptions.push(
      this.communicationService.incomingMessage$
        .pipe(filter((message) => [MESSAGE_TYPE.FULL_SIZE_TOP_BAR, MESSAGE_TYPE.REVERT_TOP_BAR_SIZE].includes(message?.payload?.type)))
        .subscribe((message) => this.onReceiveMessageFromIframe(message)),
    );
  }

  private onReceiveMessageFromIframe(messageData: MessageFromIframe): void {
    switch (messageData.payload.type) {
      case MESSAGE_TYPE.FULL_SIZE_TOP_BAR: {
        this.fullSize = false;
        break;
      }
      case MESSAGE_TYPE.REVERT_TOP_BAR_SIZE: {
        this.fullSize = true;
        break;
      }
    }
  }
}
