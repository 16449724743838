import { Action } from '@ngrx/store';

import { IPaymentGateway } from '../model/gateway.model';

export const SET_POSSIBLE_GATEWAYS = 'SET_POSSIBLE_GATEWAYS';

export class SetPossibleGateways implements Action {
  readonly type = SET_POSSIBLE_GATEWAYS;
  constructor(public payload: IPaymentGateway[]) {}
}

export type MartketplaceActions = SetPossibleGateways;
