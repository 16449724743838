<div class="d-flex align-items-center justify-content-between">
  <app-title-with-icon [iconClass]="'fa-user'" [title]="reportTitle" [subtitle]="reportSubtitle"> </app-title-with-icon>
  <div class="d-flex">
    <div class="mr-5">
      <app-spinner-button
        *ngxPermissionsExcept="[userPermission.REST_UW_ACCESS, userPermission.ADVANCE_AUDITORS]"
        [loading]="loading"
        [customWidth]="'15.5rem'"
        [text]="buttonLabel"
        (onClick)="onRunBackgroundCheckClick()"
      >
      </app-spinner-button>
      <p class="position-absolute">
        <span>Last checked: {{ lastReportTimestamp$ | async }}</span>
      </p>
    </div>
  </div>
</div>
