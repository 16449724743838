import { autoserializeAs, inheritSerialization } from 'cerialize';

import { BaseCrmModel } from './base-crm.model';
import { EventDataModel } from './event-data.model';

@inheritSerialization(BaseCrmModel)
export class EventModel extends BaseCrmModel {
  @autoserializeAs(EventDataModel) public data: EventDataModel;

  constructor(
    createdAt: string,
    ownerUuid: string,
    targetType: string,
    targetUuid: string,
    type: string,
    uuid: string,
    updatedAt: string,
    instanceId: number,
    data: EventDataModel,
  ) {
    super(createdAt, ownerUuid, targetType, targetUuid, type, uuid, updatedAt, instanceId);
    this.data = data;
  }
}
