import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { camelCase, startCase } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ComplianceDoctype } from '../../suppliers/model/compliance.model';
import { KycDocument } from './../../suppliers/model/compliance.model';
import { LookupAbstractService } from './lookup-abstract.service';

@Injectable()
export class LookupRepository extends LookupAbstractService {
  private apiUrl = environment.internalApiUrl;

  constructor(private httpClient: HttpClient, private toastrService: ToastrService) {
    super();
  }

  getTimezones(): Observable<any[]> {
    return this.httpClient.get(this.apiUrl + 'timezones').pipe(
      map((response: any[]) => {
        return response.map((item) => {
          return { id: item, label: item };
        });
      }),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getFundingPeriods(): Observable<any> {
    return this.httpClient.get(this.apiUrl + 'funding_periods').pipe(
      map((response) => {
        return response;
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getBusinessTypes(): Observable<any[]> {
    return this.httpClient.get(this.apiUrl + 'countries/US/business_types').pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.code;
          item['label'] = item.name;
          return item;
        });
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getUSStates(): Observable<any[]> {
    return this.getStates('US');
  }

  getStates(countryCode = 'US'): Observable<any[]> {
    return this.httpClient.get(this.apiUrl + `countries/${countryCode}/states`).pipe(
      map((response) => {
        const states = (<any[]>response).map((item) => {
          item['id'] = item.stateCode;
          item['label'] = item.stateName;
          return item;
        });
        states.push({
          id: '',
          label: '- empty value -',
        });
        return states;
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getCountries(): Observable<any[]> {
    return this.httpClient.get(this.apiUrl + 'countries').pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.countryCode;
          item['label'] = item.countryName;
          return item;
        });
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getXactTypes(): Observable<any[]> {
    return this.httpClient.get(this.apiUrl + 'xactTypes').pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.xactTypeCode;
          item['label'] = item.xactTypeName;
          return item;
        });
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getComplianceDoctypes(): Observable<ComplianceDoctype[]> {
    return this.handleLookupResponse('compliance_doctypes').pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.compliance_doctype_key;
          item['label'] = item.compliance_doctype_name;
          return item;
        });
      }),
    );
  }

  getKycDocuments(supplierKey: string): Observable<KycDocument[]> {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}/compliance/section/kyc_documents`;

    return this.httpClient.get(endpointUrl) as Observable<KycDocument[]>;
  }

  getDocuSignLink(supplierKey: string): Observable<any> {
    const endpointUrl = `${this.apiUrl}suppliers/${supplierKey}/get_second_sign_url`;
    const returnUrl = window.location.href;
    const params = new HttpParams().set('returnUrl', returnUrl);

    return this.httpClient.get(endpointUrl, { params });
  }

  getDelinquencyScenarios(): Observable<any> {
    return this.handleLookupResponse('delinquency_scenarios').pipe(
      map((response) => {
        return (<any>response).delinquency_scenario_key;
      }),
    );
  }

  getDelinquencyAges(): Observable<any> {
    return this.handleLookupResponse('delinquency_scenarios').pipe(
      map((response) => {
        return (<any>response).delinquency_age;
      }),
    );
  }

  getDelinquencyTypes(): Observable<any> {
    return this.handleLookupResponse('delinquency_scenarios').pipe(
      map((response) => {
        return (<any>response).delinquency_type;
      }),
    );
  }

  getFeeSchedules(params = {}): Observable<any> {
    return this.handleLookupResponse('fee_schedules', params).pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.feeScheduleKey;
          item['label'] = item.feeScheduleName;
          return item;
        });
      }),
    );
  }

  addTier(data: any, key: string): Observable<any> {
    return this.httpClient.post(this.apiUrl + 'fee_schedule_tier/' + key, data);
  }

  editTier(data: any, key: string): Observable<any> {
    return this.httpClient.put(this.apiUrl + 'fee_schedule_tier/' + key, data);
  }

  addFeeSchedule(data: any): Observable<any> {
    return this.httpClient.post(this.apiUrl + 'fee_schedules', data);
  }

  getPaymentMethods(countryCode: string): Observable<any> {
    return this.httpClient.get(this.apiUrl + `countries/${countryCode}/payment_methods`).pipe(
      map((response) => {
        return response;
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getCodes(domain: string): Observable<any> {
    return this.httpClient.get(this.apiUrl + `lookup/codes/${domain}`).pipe(
      map((response) => {
        return response;
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getAuthGoogleLogin(googleAuthToken: string): Observable<any> {
    return this.httpClient.post(this.apiUrl + 'auth/google/login', { token: googleAuthToken }).pipe(
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getPayeeStatuses(): Observable<any> {
    return this.httpClient.get(this.apiUrl + 'lookup/codes/payment.confPayeeDiverted').pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.codeValue;
          item['label'] = item.displayName;
          return item;
        });
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getOutcomeReasons(): Observable<any> {
    return this.httpClient.get(this.apiUrl + 'lookup/codes/payment.outcomeReason').pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.codeValue;
          item['label'] = item.displayName;
          return item;
        });
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getPaymentOutcome(): Observable<any> {
    return this.httpClient.get(this.apiUrl + 'lookup/codes/payment.paymentOutcome').pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.codeValue;
          item['label'] = item.displayName;
          return item;
        });
      }),
      tap(
        (data) => {},
        (error) => {},
      ),
      catchError((error) => {
        return ErrorObservable.create(error);
      }),
    );
  }

  getReviewers(): Observable<any> {
    return this.httpClient.get(this.apiUrl + 'lookup/paymentReviewers').pipe(
      map((response) => {
        return (<any[]>response).map((item) => {
          item['id'] = item.codeValue;
          item['label'] = item.displayName;
          return item;
        });
      }),
      catchError((error) => {
        this.toastrService.error('Cannot fetch Payment Reviewers');
        return ErrorObservable.create(error);
      }),
    );
  }

  private handleLookupResponse(dictKey, params = {}) {
    const errorMsgDict = startCase(camelCase(dictKey));

    return this.httpClient.get(this.apiUrl + dictKey, { params }).pipe(
      catchError((error) => {
        this.toastrService.error('Cannot fetch ' + errorMsgDict);
        return ErrorObservable.create(error);
      }),
    );
  }
}
