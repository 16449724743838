import { createSelector } from '@ngrx/store';

import { AppState } from '../../../../store/app.reducers';
import { UnderwritingState } from './underwriting.state';

const selectUnderwriting = (state: AppState) => state.underwriting;

export const selectUwTools = createSelector(selectUnderwriting, (state: UnderwritingState) =>
  state.toolsLoaded && state.tools.length ? state.tools : null,
);
