<div class="pull-right">
  <app-chip [text]="chipText" [chipColor]="chipColor"></app-chip>
</div>
<span class="card-title">{{ title }}</span>
<div class="mb-2" *ngIf="status">
  Status:
  <span
    [ngClass]="{
      'text-danger': type == underwritingInitialCardHeaderStatusType.DANGER,
      'text-success': type == underwritingInitialCardHeaderStatusType.SUCCESS
    }"
  >
    {{ status }}
  </span>
</div>
