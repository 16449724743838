import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, share } from 'rxjs/operators';

import { LookupAbstractService } from '../../../../../../shared/lookup-service/lookup-abstract.service';
import { FetchUtilAttribute, selectCountries } from '../../../../../../shared/utils/store/utils.actions';
import { AppState } from '../../../../../../store/app.reducers';
import { CreditCardValidator } from '../../../card-number.validator';
import { postalCodeUpdateValidators } from '../payment-type-util';
import { SubscriberComponent } from './../../../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-payment-type-ppm',
  templateUrl: './payment-type-ppm.component.html',
  styleUrls: ['./payment-type-ppm.component.scss'],
})
export class PaymentTypePpmComponent extends SubscriberComponent implements OnInit {
  @Input() paymentConfig: any;
  @Output() configForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  countries: Observable<any[]>;
  states: any[] = [];
  form: FormGroup;
  editMode = false;

  payeeTypes = [
    { code: 'individual', name: 'Individual' },
    { code: 'business', name: 'Business' },
  ];

  constructor(
    private fb: FormBuilder,
    private lookupRepo: LookupAbstractService,
    private store: Store<AppState>,
    private datePipe: DatePipe,
  ) {
    super();
    this.store.dispatch(new FetchUtilAttribute({ attr: 'countries' }));
    this.createForm();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.form
        .get(['ppm', 'cardholderMailAddr', 'countryCode'])
        .valueChanges.subscribe(postalCodeUpdateValidators(this.form.get(['ppm', 'cardholderMailAddr', 'postalCode']))),
    );
    if (this.paymentConfig && this.paymentConfig.hasOwnProperty('paymentConfig')) {
      this.editMode = true;
      this.form.patchValue(this.paymentConfig.paymentConfig);
    }
    this.countries = this.store.select(selectCountries);
    this.countries.pipe(share());
  }

  createForm() {
    this.form = this.fb.group({
      /* eslint-disable */
      ppm: this.fb.group({
        payeeType: ['', Validators.required],
        cardholderFirstName: ['', Validators.required],
        cardholderLastName: ['', Validators.required],
        cardholderMailAddr: this.fb.group({
          addr1: ['', Validators.required],
          state: '',
          city: ['', Validators.required],
          countryCode: ['', Validators.required],
          postalCode: ['', [Validators.required]],
        }),
        cardholderPhoneWork: ['', [Validators.required, Validators.pattern(/^[-+() 0-9]+$/)]],
        cardholderEmail: ['', [Validators.required, Validators.email]],
        cardholderSsn: [
          '',
          Validators.pattern(/^(?!219-09-9999|078-05-1120)(?!666|000|9[0-9]{2})[0-9]{3}-(?!00)[0-9]{2}-(?!0{4})[0-9]{4}$/),
        ],
        cardholderDateOfBirth: ['', Validators.pattern(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/)],
        bizDateOfIncorporation: ['', Validators.pattern(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/)],
        bizFedEmployerIdNum: ['', Validators.pattern(/^[0-9]{2}-[0-9]{7}$/)],
        cardExpDate: ['', [CreditCardValidator.validateCardExpiry]],
        cardNumber: ['', [CreditCardValidator.validateCardNumber]],
      }),
      /* eslint-enable */
    });

    this.subscriptions.push(
      this.form
        .get('ppm')
        .get('cardholderMailAddr')
        .get('countryCode')
        .valueChanges.subscribe((value) => {
          if (value && value.length) {
            this.subscriptions.push(
              this.lookupRepo.getStates(value).subscribe((data) => {
                const stateControl = this.form.get('ppm').get('cardholderMailAddr').get('state');
                this.states = data;
                stateControl.setValidators(data && data.length ? [Validators.required] : []);
                stateControl.updateValueAndValidity();
              }),
            );
          }
        }),
    );

    this.subscriptions.push(
      this.form.valueChanges.pipe(debounceTime(500)).subscribe((values) => {
        this.configForm.emit(this.form);
      }),
    );
  }

  getFormattedExpDate(expDate) {
    if (!expDate) {
      return;
    }

    const date = '20' + expDate.split('/').reverse().join('-') + '-01';
    return this.datePipe.transform(expDate, 'MM y');
  }
}
