<div class="wrapperContainer">
  <h1 mat-dialog-title class="wrapperContainer__title">{{ actionTitle }}</h1>
  <div class="wrapperContainer__content">
    <div class="confirmation" *ngIf="isSaved">
      {{ confirmationMessage }}
    </div>

    <form [formGroup]="supplierActionForm" *ngIf="showForm && supplierActionForm">
      <div class="wrapperContainer__mainOptions">
        <mat-form-field class="full-width">
          <mat-select placeholder="Marketplace" formControlName="mp_sup_key">
            <mat-option *ngFor="let marketplace of marketplaces" [value]="marketplace.id">
              {{ marketplace.label }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-select placeholder="Marketplace Statement" formControlName="mp_statement_key">
            <mat-option *ngFor="let statement of marketplaceStatements" [value]="statement.mp_statement_key">
              {{ statement.start_date | date: 'MM/dd/yyyy' }} -
              {{ statement.end_date ? (statement.end_date | date: 'MM/dd/yyyy') : 'now' }} :
              {{ (statement.end_bal ? statement.end_bal : 0) | currency }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <input matInput [matDatepicker]="fpStartPicker" placeholder="Start date" formControlName="fp_start_date" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="fpStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #fpStartPicker></mat-datepicker>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <input matInput [matDatepicker]="fpEndPicker" placeholder="End date" formControlName="fp_end_date" />
          <mat-datepicker-toggle matSuffix [for]="fpEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #fpEndPicker></mat-datepicker>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <input matInput placeholder="Deposit Reference Number" formControlName="mp_pmt_external_id" />
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <input matInput [matDatepicker]="postDatePicker" placeholder="Post date" formControlName="post_date" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="postDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #postDatePicker></mat-datepicker>
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Payment amount"
            type="number"
            step="0.01"
            (change)="paymentAmountChange()"
            formControlName="payment_amount"
          />
          <mat-error>This field is required.</mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-between">
          <div class="toggleWrapper">
            <mat-slide-toggle color="accent" formControlName="paid_in_full">
              <label>Paid in full</label>
            </mat-slide-toggle>
          </div>

          <div class="toggleWrapper">
            <mat-slide-toggle color="accent" formControlName="disable_excess_check"> Disable Payment Validation </mat-slide-toggle>
          </div>
        </div>
      </div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Additional options</b>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field class="full-width">
            <input matInput [matDatepicker]="startPicker" placeholder="Start date" formControlName="start_date" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error>This field is required.</mat-error>
            <span
              class="material-icons infoIcon"
              matTooltip="Calculate marketplace payments based on receivables or chargebacks posted on or after the start date"
            >
              info
            </span>
          </mat-form-field>

          <mat-form-field class="full-width">
            <input matInput [matDatepicker]="endPicker" placeholder="End date" formControlName="end_date" />
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error>This field is required.</mat-error>
            <span
              class="material-icons infoIcon"
              matTooltip="Calculate marketplace payments based on receivables or chargebacks on posted on or before the end date"
            >
              info
            </span>
          </mat-form-field>

          <div class="position-relative toggleWrapper">
            <mat-slide-toggle color="accent" formControlName="pay_loan_rcv_first"> Pay Amazon Loan First </mat-slide-toggle>
            <span class="material-icons infoIcon" matTooltip="Apply payment to Marketplace Loan Receivables first"> info </span>
          </div>

          <div class="position-relative toggleWrapper">
            <mat-slide-toggle color="accent" formControlName="no_pay_later_statements"> Do Not Pay Later Statements </mat-slide-toggle>
            <span
              class="material-icons infoIcon"
              matTooltip="If marketplace statement is specified then don’t apply payment to later statements"
            >
              info
            </span>
          </div>

          <mat-form-field class="full-width">
            <input type="number" matInput placeholder="Compensed by Supplier Amount" formControlName="compensed_by_supplier_amount" />
            <mat-error>Value should be more than 0</mat-error>
            <span
              class="material-icons infoIcon"
              matTooltip="Provide an amount that will be taken from supplier available balance and used to increase the payment amount"
            >
              info
            </span>
          </mat-form-field>

          <mat-form-field class="full-width">
            <input matInput placeholder="Advance Repayment Amount" type="number" formControlName="advance_repayment_amount" />
            <mat-error>This field should be positive and less or equal to the Payment amount</mat-error>
            <span
              class="material-icons infoIcon"
              matTooltip="Override advance repayment amount. If the amount is not specified it will be calculated automatically"
            >
              info
            </span>
          </mat-form-field>

          <div class="position-relative toggleWrapper">
            <mat-slide-toggle color="accent" formControlName="cross_post_stmt_type"> Cross Payment Statement </mat-slide-toggle>
            <span
              class="material-icons infoIcon"
              matTooltip="Allow marketplace payment to be allocated to statements of adifferent type than the current. Please note that this option will limit the end date parameter to the statement end date"
            >
              info
            </span>
          </div>

          <div class="position-relative toggleWrapper">
            <mat-slide-toggle color="accent" formControlName="first_in_first_out"> FIFO </mat-slide-toggle>
            <span class="material-icons infoIcon" matTooltip="Apply payment to older receivables first"> info </span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
  </div>
  <div class="wrapperContainer__footer">
    <div *ngIf="isSuccess">
      <div class="alert alert-success">
        <p>{{ successMessage }}</p>
        <ng-container *ngIf="successPaymentMessage">
          <p>{{ successPaymentMessage.advanceRepaymentAmount }}</p>
          <p>{{ successPaymentMessage.passThroughAmount }}</p>
        </ng-container>
      </div>
      <div mat-dialog-actions class="justify-content-end">
        <button mat-raised-button (click)="resetForm()" tabindex="2" color="primary">Add Another</button>
        <button mat-raised-button (click)="closeDialog()" tabindex="-1" color="accent">Done</button>
      </div>
    </div>

    <div *ngIf="isError">
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <div mat-dialog-actions class="justify-content-end">
        <button mat-raised-button (click)="backClick()" tabindex="-1" color="accent">Back</button>
      </div>
    </div>

    <div mat-dialog-actions class="justify-content-end" *ngIf="showForm && !isSaved && supplierActionForm">
      <button mat-raised-button (click)="save()" [disabled]="!supplierActionForm.valid" tabindex="2" color="accent">Save</button>
      <button mat-raised-button (click)="closeDialog()" color="warn" tabindex="-1">Cancel</button>
    </div>

    <div mat-dialog-actions class="justify-content-end" *ngIf="isSaved">
      <button mat-raised-button (click)="submit()" tabindex="2" color="accent">Yes</button>
      <button mat-raised-button (click)="editForm()" tabindex="-1" color="warn">No</button>
    </div>
  </div>

  <div class="spinnerWrapper" *ngIf="isLoadingResult">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</div>
