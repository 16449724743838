<mat-card class="productFrequentEarningLoading__matCard">
  <div class="productFrequentEarningLoading__matCard__title">
    <h5>Frequent Earnings Loading</h5>
    <div *ngIf="notOffered">
      <p [ngStyle]="{ color: '#F5AB2E' }">{{ 'Not Available' | uppercase }}</p>
    </div>
  </div>
  <mat-card-content>
    <ng-container *ngIf="!notOffered">
      <mat-table class="productFrequentEarningLoading__matCard__table" [dataSource]="dataSource">
        <ng-container matColumnDef="marketplace">
          <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
          <mat-cell *matCellDef="let element" class="productFrequentEarningLoading__matCard__table__cell">
            {{ element.marketplace }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="run_time">
          <mat-header-cell *matHeaderCellDef>Run Time (UTC)</mat-header-cell>
          <mat-cell *matCellDef="let element" class="productFrequentEarningLoading__matCard__table__cell">
            <div class="timepickers">
              <app-time-select-picker
                *ngFor="let el of element.run_time; index as i; let last"
                [value]="el"
                [quantum]="60"
                [last]="last"
                (changedTime)="onChangeTime($event, element, i)"
                (removedTime)="onRemoveTime(element, i)"
              >
              </app-time-select-picker>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="enabled">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" class="productFrequentEarningLoading__matCard__table__cell">
            <mat-slide-toggle [(ngModel)]="element.enabled" (change)="activationProductRequest($event, element)"> </mat-slide-toggle>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="serviceColumns" class="productFrequentEarningLoading__matCard__table__headerRow">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: serviceColumns" class="productFrequentEarningLoading__matCard__table__row"> </mat-row>
      </mat-table>
    </ng-container>
  </mat-card-content>
</mat-card>
