<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="feeScheduleType">
    <mat-header-cell *matHeaderCellDef>Fee Schedule Type</mat-header-cell>
    <mat-cell *matCellDef="let fee">
      <mat-select *ngIf="!fee.isTier" placeholder="Type" aria-label="type" [(ngModel)]="fee.feeScheduleType" [disabled]="!fee.isEditable">
        <mat-option *ngFor="let feeScheduleType of feeScheduleTypes" [value]="feeScheduleType" (click)="setFeeScheduleNames(fee)">
          {{ feeScheduleType }}
        </mat-option>
      </mat-select>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="feeScheduleName" disabled>
    <mat-header-cell *matHeaderCellDef>Fee Schedule Name</mat-header-cell>
    <mat-cell *matCellDef="let fee">
      <mat-select *ngIf="!fee.isTier" placeholder="Name" aria-label="name" [(ngModel)]="fee.feeScheduleName" [disabled]="!fee.isEditable">
        <mat-option
          *ngFor="let feeScheduleName of fee.feeScheduleNames"
          [value]="feeScheduleName"
          (click)="setFeeScheduleForSelectedName(feeScheduleName, fee)"
        >
          {{ feeScheduleName }}
        </mat-option>
      </mat-select>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="feeScheduleTypeCode">
    <mat-header-cell *matHeaderCellDef>Fee Type Code</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.paymentTypeCode }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="feeDelivery">
    <mat-header-cell *matHeaderCellDef>Fee Delivery</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.feeScheduleDelivery }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="tierStart">
    <mat-header-cell *matHeaderCellDef>Tier Start</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.tierStart | currency: 'USD' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="tierEnd">
    <mat-header-cell *matHeaderCellDef>Tier End</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.tierEnd | currency: 'USD' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="feePercentage">
    <mat-header-cell *matHeaderCellDef>Fee Percentage</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.feePercentage / 100 | percent: '1.0-4' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="minPmtFee">
    <mat-header-cell *matHeaderCellDef>Minimum Payment Fee</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.minPmtFee | currency: 'USD' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="fixedFee">
    <mat-header-cell *matHeaderCellDef>Fixed Fee</mat-header-cell>
    <mat-cell *matCellDef="let fee">{{ fee?.fixedFee | currency: 'USD' }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let fee">
      <button *ngIf="!fee.isTier" mat-icon-button (click)="toggleEditMode(fee)"><mat-icon>edit</mat-icon></button>
      <button
        mat-raised-button
        [disabled]="isLoadingResult"
        *ngIf="fee.isEditable"
        (click)="openConfirmationDialog(fee.feeScheduleKey, fee.feeScheduleName)"
        color="accent"
      >
        Save
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>
</mat-table>
