<article class="article" *ngIf="!isLoading; else loading">
  <div class="flex-container">
    <div class="header box box-default">
      <div class="navigation">
        <a [routerLink]="['../../']" title="Go to advances list" class="close-button">
          <i class="material-icons">arrow_back</i>
        </a>
      </div>
      <div class="info">
        <h6>Machine Learning Output</h6>
      </div>
    </div>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-md-4">
              <span class="bold">Machine Proposed Advance:</span>
            </div>
            <div class="col-md-4">
              <span class="bold">{{ mlOfferDetails?.advanced_pp_l_amount | currency }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span>RiskRating:</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.ranking }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span>Sales Trend:</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.sales_trend }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span>Holidays:</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.holidays }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span>Last Daily Payment Held:</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.last_daily_payment_held }}</span>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-4">
              <span class="underline md-3">Health Metric</span>
            </div>
            <div class="col-md-4">
              <span class="underline md-3">Machine Rating</span>
            </div>
            <div class="col-md-4">
              <span class="underline md-3">Underlying Number (Average)</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span>Average Cancellation Rate:</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.average_cancellation_rate }}</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.cancellation_rate / 100 | percent: '1.0-3' }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span>Average Delivered on Time:</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.average_delivered_on_time }}</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.delivery_rate / 100 | percent: '1.0-3' }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span>Average Order Defect Rate:</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.average_order_defect_rate }}</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.order_defect_rate / 100 | percent: '1.0-3' }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <span>Suspension History (Ok Count):</span>
            </div>
            <div class="col-md-4">
              <span>{{ mlOfferDetails?.suspension_history }}</span>
            </div>
            <div class="col-md-4">
              <div><span>-</span></div>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-4">
              <div><span class="underline md-3">Other Metrics</span></div>
            </div>
            <div class="col-md-4">
              <div><span class="underline md-3">Value</span></div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div><span>Amazon Loan Amount:</span></div>
            </div>
            <div class="col-md-4">
              <div>
                <span>{{ mlOfferDetails?.amazon_loan | currency }}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div><span>Amazon Loan Maturity Date:</span></div>
            </div>
            <div class="col-md-4">
              <div>
                <span>{{ mlOfferDetails?.amazon_loan_maturity_date }} </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div><span>Past Due Days:</span></div>
            </div>
            <div class="col-md-4">
              <div>
                <span>{{ mlOfferDetails?.past_due_days }}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div><span>Past Due Amount:</span></div>
            </div>
            <div class="col-md-4">
              <div>
                <span>{{ mlOfferDetails?.past_due_amount | currency }}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div><span>Eligibility Type:</span></div>
            </div>
            <div class="col-md-4">
              <div>
                <span>{{ mlOfferDetails?.eligibility_type }}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div><span>Percent FBA Sales:</span></div>
            </div>
            <div class="col-md-4">
              <div>
                <span>{{ mlOfferDetails?.percent_fba_sales }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</article>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</ng-template>
