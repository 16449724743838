<section class="supplier-details-underwriting supplier-details-underwriting-daily pageSection">
  <div class="row">
    <div class="col-6 mt-3 mb-3">
      <h4 class="m-0">Daily Underwriting data</h4>
    </div>
    <div class="col-6 mt-3 mb-3">
      <button [hidden]="!editRules" class="pull-right ml-3" mat-raised-button (click)="saveRulesClick()" color="accent">Save Rules</button>
      <button
        [hidden]="editRules"
        [disabled]="isRestrictedUWView"
        class="pull-right"
        mat-raised-button
        color="primary"
        (click)="startEditRules($event)"
      >
        Edit Rules
      </button>
    </div>
  </div>

  <app-underwriting-daily-edit *ngIf="editRules" [collectedData]="collectedData"> </app-underwriting-daily-edit>

  <div *ngIf="!dailyViewOn && !editRules">
    <ng-container *ngIf="!dataLoading; else loadingForm">
      <ng-container *ngIf="collectedData; else noData">
        <app-underwrating-daily-table [collectedData]="collectedData" [displayedColumns]="displayedColumns"> </app-underwrating-daily-table>
      </ng-container>
    </ng-container>
  </div>
</section>

<ng-template #loadingForm>
  <div class="spinnerWrapper">
    <app-loading-spinner [size]="4"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #noData>
  <p>No data</p>
</ng-template>
