import { PlatformLocation } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { filter, take } from 'rxjs/operators';

import { selectLoggedUserModel } from '../../../auth/store/auth.actions';
import { AppState } from '../../../store/app.reducers';
import { CommentModel } from '../../models/comment.model';
import { CommentsService } from '../../services/comments.service';
import { CRM_AFTER_TASK_SAVE, CrmAfterTaskSave } from '../../store/crm.actions';
import { SubscriberComponent } from './../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-comment-add',
  templateUrl: './comment-add.component.html',
  styleUrls: ['./comment-add.component.scss'],
})
export class CommentAddComponent extends SubscriberComponent implements OnInit, OnDestroy {
  @Input() hideButton: boolean;
  @Input() targetUuid: string;
  @Input() targetType: string;
  @Output() onClose = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  @Output() onComment = new EventEmitter();

  commentAddForm: FormGroup;

  isPending: boolean;
  interval;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private commentsService: CommentsService,
    private $actions: ActionsSubject,
    private toastrService: ToastrService,
    private platformLocation: PlatformLocation,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.setUpSubscriptions();
  }

  setUpSubscriptions(): void {
    this.subscriptions.push(
      this.store
        .select(selectLoggedUserModel)
        .pipe(filter((user) => user))
        .subscribe((user) => {
          this.commentAddForm.patchValue({ createdByKey: user.name, createdByEmail: user.email });
        }),
    );

    this.subscriptions.push(
      this.$actions
        .pipe(
          filter((action) => action.type === CRM_AFTER_TASK_SAVE),
          filter((action: CrmAfterTaskSave) => action.payload.uuid === this.targetUuid),
        )
        .subscribe(() => {
          this.isPending = true;
          this.saveCommentWhenNeeded();
        }),
    );
  }

  getCurrentCommentData() {
    if (this.isCommentNotEmpty()) {
      const dataToSave = this.commentAddForm.value;
      dataToSave.taskUrl = this.getTaskUrl();

      return dataToSave;
    }

    return null;
  }

  saveCommentWhenNeeded() {
    if (this.isCommentNotEmpty()) {
      return this.saveComment();
    }
    this.isPending = false;
  }

  isCommentNotEmpty(): boolean {
    return this.commentAddForm.value.body.trim().length > 0;
  }

  createForm() {
    this.commentAddForm = this.formBuilder.group({
      body: ['', Validators.required],
      targetUuid: [this.targetUuid, Validators.required],
      createdByKey: [''],
      createdByEmail: [''],
      targetType: [this.targetType, Validators.required],
      taskUrl: [''],
    });
  }

  getTaskUrl() {
    const baseUrl = (<any>this.platformLocation).location.origin;
    const uuid = this.targetUuid ? this.targetUuid : '{}';
    return `${baseUrl}/#/tasks?taskUuid=${uuid}`;
  }

  saveComment() {
    if (this.commentAddForm.valid) {
      const commentData: CommentModel = { ...this.commentAddForm.value, taskUrl: this.getTaskUrl() };
      this.emitOnCommentOutput(commentData);
      return this.sendAddCommentRequestAndDontUnsubscribeOnDestroy(commentData);
    }
    this.isPending = false;
  }

  private emitOnCommentOutput(commentData: CommentModel): void {
    this.onComment.emit({
      targetUuid: this.targetUuid,
      commentData,
    });
  }

  private sendAddCommentRequestAndDontUnsubscribeOnDestroy(commentData: CommentModel): void {
    // 2021-05-27:
    // Unsubscribing it on destroy caused https://payability.atlassian.net/browse/APPS-513 due to the race condition.
    // The request may not complete before closing the dialog.
    this.commentsService
      .addComment(this.targetUuid, commentData)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success('Comment successfully added');
        this.onSuccess.emit();
        this.isPending = false;
      });
  }

  ngOnDestroy(): void {
    const maxIntervals = 10;
    let currentIntervals = 0;
    this.interval = setInterval(() => {
      currentIntervals += 1;

      if (!this.isPending || currentIntervals >= maxIntervals) {
        super.ngOnDestroy();
        this.isPending = false;
        clearInterval(this.interval);
      }
    }, 500);
  }
}
