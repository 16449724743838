import { HttpRequest } from '@angular/common/http';
import { Action } from '@ngrx/store';

export const FETCH_REQUEST = 'FETCH_REQUEST';

export class FetchRequest implements Action {
  readonly type = FETCH_REQUEST;
  constructor(public payload: HttpRequest<any>) {}
}

export type FetchedRequestsActions = FetchRequest;
