<div class="form-group" [ngClass]="{ 'form-in-edit': inEdit }">
  <app-spinner *ngIf="processing" [forceShow]="true"></app-spinner>
  <div class="form-group-content">
    <h6 *ngIf="title" class="form-group-title">{{ title }}</h6>

    <span class="form-group-buttons">
      <button *ngIf="contextMenuItems && contextMenuItems.length" mat-icon-button [matMenuTriggerFor]="contextMenu" class="pull-right">
        <mat-icon class="material-icons">menu</mat-icon>
      </button>
      <button
        class="form-group-edit"
        *ngIf="isGroupEditable"
        mat-icon-button
        (click)="toggleEdit()"
        class="pull-right"
        data-test="formEditButton"
      >
        <mat-icon class="material-icon">edit</mat-icon>
      </button>

      <mat-menu #contextMenu="matMenu" x-position="after" y-position="below" overlapTrigger="false">
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
      </mat-menu>
    </span>

    <div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="form-group-action" *ngIf="inEdit">
    <button class="btn btn-default mr-3" (click)="cancelEditing()">Cancel</button>
    <button *ngIf="showClear" class="btn btn-default" (click)="clearValues()" data-test="clearFormButton">Clear form</button>
    <button class="btn btn-primary pull-right" [disabled]="formGroup?.invalid" (click)="save()" data-test="formSaveAllButton">
      Save all
    </button>
  </div>
</div>
