import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { isUndefined } from 'util';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { DIALOG_SIZE } from '../../../shared/dialog.config';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { selectSupplierDetails, ToggleHideSupplier } from '../../store/supplier/supplier.actions';

@Component({
  selector: 'app-toggle-hide-supplier',
  templateUrl: './toggle-hide-supplier.component.html',
  styleUrls: ['./toggle-hide-supplier.component.scss'],
})
export class ToggleHideSupplierComponent extends SubscriberComponent implements OnInit {
  public supplierDetailsModel$: Store<SupplierDetailsModel>;
  public toggleInProgress = false;

  constructor(public dialog: MatDialog, private store: Store<AppState>, private toastrService: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.supplierDetailsModel$ = this.store.select(selectSupplierDetails);
    this.subscriptions.push(
      this.store.select('supplier').subscribe((supplierState) => {
        if (this.toggleInProgress && supplierState.toggleHideInEdit === false) {
          if (!isUndefined(supplierState.toggleHideError)) {
            this.toastrService.error('Error during update operation');
          } else {
            this.toastrService.success('Updated successfully');
          }

          this.toggleInProgress = supplierState.toggleHideInEdit;
        }
      }),
    );
  }

  toggleSupplierHidden(supplierDetails: SupplierDetailsModel): void {
    const msg = `Do you want to ${supplierDetails.hide ? 'unhide' : 'hide'} supplier?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: msg },
      width: DIALOG_SIZE.SMALL.width,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.toggleInProgress = true;
          this.store.dispatch(new ToggleHideSupplier(!supplierDetails.hide));
        }
      }),
    );
  }
}
