import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Deserialize, DeserializeKeysFrom } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '../../config';
import { SupplierAuthTokenModel } from '../model/supplier-auth-token.model';

@Injectable()
export class SupplierAuthTokenService {
  readonly RESOURCE_CODE = 'code';
  readonly RESOURCE_TOKEN = 'token';

  private apiUrl;

  constructor(public appConfig: AppConfigService, private httpClient: HttpClient) {
    this.apiUrl = appConfig.env.authToolsApiUrl;
  }

  private getEndpointUrl(resource: string, supplierKey: string = null): string {
    let endpointUrl = `${this.apiUrl}${resource}/`;

    if (supplierKey) {
      endpointUrl += `${supplierKey}/`;
    }
    return endpointUrl;
  }

  getAuthCode(supplierKey: string, refresh: boolean = false): Observable<any> {
    const endpointUrl = this.getEndpointUrl(this.RESOURCE_CODE, supplierKey);
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient.get<CrmApiResult>(endpointUrl, { params: queryParams });
  }

  updateAuthToken(supplierKey: string, data: SupplierAuthTokenModel): Observable<any> {
    const endpointUrl = this.getEndpointUrl(this.RESOURCE_TOKEN, supplierKey);
    return this.httpClient.put(endpointUrl, data);
  }

  createAuthToken(data: SupplierAuthTokenModel): Observable<any> {
    const endpointUrl = this.getEndpointUrl(this.RESOURCE_TOKEN);
    return this.httpClient.post(endpointUrl, data);
  }

  deleteAuthToken(supplierKey: string): Observable<any> {
    const endpointUrl = this.getEndpointUrl(this.RESOURCE_TOKEN, supplierKey);
    return this.httpClient.delete(endpointUrl);
  }

  getAuthToken(supplierKey: string, refresh: boolean = false): Observable<SupplierAuthTokenModel> {
    const endpointUrl = this.getEndpointUrl(this.RESOURCE_TOKEN, supplierKey);
    let queryParams = new HttpParams();

    if (refresh) {
      queryParams = queryParams.append('refreshTimestamp', Date.now().toString());
    }

    return this.httpClient.get<CrmApiResult>(endpointUrl, { params: queryParams }).pipe(
      map((result) => {
        DeserializeKeysFrom(CamelCase);
        return Deserialize(result.data, SupplierAuthTokenModel);
      }),
    );
  }
}

interface CrmApiResult {
  data: any;
  meta: any;
  status_code: number;
}
