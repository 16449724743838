import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Action, actionTypes } from '../../event-rule.models';
import { RulesUtilsService } from '../../rules-utils.service';
import { SubscriberComponent } from './../../../../shared/component-subscriber/subscriber.component';
import { eventBridgeActionNameValidator } from './send-to-event-bridge/validators/event-bridge-action-name.validator';

@Component({
  selector: 'app-action-form',
  templateUrl: './action-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ActionFormComponent extends SubscriberComponent implements OnInit {
  @Input() topForm: FormGroup;
  @Input() action$: Observable<any>;
  @Input() index: number;
  form: FormGroup;
  actionValue$ = new BehaviorSubject({});
  TYPES = Action;

  readonly actionTypes = actionTypes;

  typesDefinition = {
    [Action.CREATE_TASK]: {
      description: 'Creates new task from specified template',
      group: this.fb.group({
        templateName: ['', Validators.required],
        contextMapping: this.fb.array([this.utils.contextModel()]),
        dataMapping: this.fb.array([this.utils.contextModel()]),
        data: this.fb.array([this.utils.dataModel()]),
      }),
      arrays: {
        contextMapping: this.utils.contextModel,
        dataMapping: this.utils.contextModel,
        data: this.utils.dataModel,
      },
    },
    [Action.SEND_EMAIL]: {
      description: 'Sends emails to secified recipents',
      group: this.fb.group({
        templateName: ['', Validators.required],
        delayTime: [1],
        delayPeriod: [1],
        delayEnabled: [false],
        sendToSupplier: [false],
        to: this.fb.array([this.utils.toModel()]),
        // contextMapping: this.fb.array([this.utils.contextModel()]),
      }),
      arrays: {
        to: this.utils.toModel,
      },
    },
    // [Action.SLACK_NOTIF]: {
    //   description: 'Sends slack notifications to specified users',
    //   group: this.fb.group({
    //     to: this.fb.array([this.utils.toModel()]),
    //     template: [''],
    //   })
    // },
    [Action.EDIT_SUPPLIER]: {
      description: 'Makes PUT request and updates supplier',
      group: this.fb.group({
        dataMapping: this.fb.array([this.utils.contextModel()]),
        data: this.fb.array([this.utils.dataModel()]),
      }),
      arrays: {
        dataMapping: this.utils.contextModel,
        data: this.utils.dataModel,
      },
    },
    [Action.SEND_TO_EVENT_BRIDGE]: {
      description: 'Send event to event bridge',
      group: this.fb.group({
        busName: ['', [Validators.required, eventBridgeActionNameValidator]],
      }),
    },
  };

  constructor(private fb: FormBuilder, private utils: RulesUtilsService) {
    super();
    this.form = this.fb.group({
      type: [Action.EDIT_SUPPLIER, Validators.required],
    });

    this.subscriptions.push(
      this.form.valueChanges
        .pipe(
          filter(() => this.form.valid),
          debounceTime(500),
          distinctUntilChanged(),
          map((value) => Object.assign({}, value)),
          map(this.skipEmpty),
          map((form) => {
            if (form.delayTime) {
              form.delay = form.delayTime * form.delayPeriod;
              delete form.delayTime;
              delete form.delayPeriod;
            } else {
              form.delay = null;
            }
            return form;
          }),
        )
        .subscribe(this.actionValue$),
    );

    // Dynamic adding controls
    const defaultControls = this.typesDefinition[Action.EDIT_SUPPLIER].group.controls;
    for (const control in defaultControls) {
      if (defaultControls.hasOwnProperty(control)) {
        this.form.addControl(control, defaultControls[control]);
      }
    }

    this.subscriptions.push(
      this.form.get('type').valueChanges.subscribe((type) => {
        for (const key in this.form.controls) {
          if (key !== 'type') {
            this.form.removeControl(key);
          }
        }
        const currentControls = this.typesDefinition[type].group.controls;
        for (const control in currentControls) {
          if (currentControls.hasOwnProperty(control)) {
            this.form.addControl(control, currentControls[control]);
          }
        }
      }),
    );
  }

  get type() {
    return this.form.get('type').value;
  }

  ngOnInit() {
    if (this.topForm) {
      this.topForm.addControl('action', this.form);
    }

    this.saveData(Action.EDIT_SUPPLIER);
    this.saveData(Action.SEND_EMAIL);
  }

  private skipEmpty(value) {
    for (const array of ['dataMapping', 'contextMapping', 'data']) {
      if (value[array]) {
        value[array] = value[array].filter((f) => f.propName || f.selector || f.value);
      }
    }

    if (value.to) {
      value.to = value.to.filter((f) => f !== '');
    }

    for (const key in value) {
      if (value[key] === '') {
        delete value[key];
      }
    }
    return value;
  }

  saveData(actionType) {
    if (!this.action$) {
      return;
    }

    this.subscriptions.push(
      this.action$.pipe(map((eventRule) => eventRule.actions[this.index] || { type: actionType })).subscribe((action) => {
        if (this.typesDefinition[action.type]) {
          const currentControls = this.typesDefinition[action.type].group.controls;
          for (const control in currentControls) {
            if (currentControls.hasOwnProperty(control)) {
              this.form.addControl(control, currentControls[control]);
            }
          }
        }

        if (this.typesDefinition[action.type]) {
          const formArrays = this.typesDefinition[action.type].arrays;
          for (const key in formArrays) {
            if (formArrays.hasOwnProperty(key)) {
              const build = formArrays[key];
              for (const {} of action[key] || []) {
                const arr = this.form.get(key) as FormArray;
                arr.push(build());
              }
            }
          }
        }

        this.form.patchValue(action);
      }),
    );
  }
}
