import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { CommunicationMessage, CommunicationMessageForIframe, IframeChild, MessageFromIframe } from './communication-message.model';
import { IFRAME_COMMUNICATION_PARENT_FRONTEND_DOMAIN } from './iframe-communication.const';
import { MESSAGE_SOURCE } from './message-type.enum';

@Injectable()
export class CommunicationService {
  incomingMessage$: Observable<MessageFromIframe>;
  featureFlagsFromEva$ = new Subject<any>();

  private messageFromParent$ = new Subject<CommunicationMessageForIframe>();
  private messageFromChild$ = new Subject<CommunicationMessageForIframe>();

  private receiverWindow: Window;
  private pluginIframeId: string;

  private domain: string;
  private childs: IframeChild[] = [];

  private listeningParent = false;
  private listeningChilds = false;
  private window: Window = null;
  private top: Window = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(IFRAME_COMMUNICATION_PARENT_FRONTEND_DOMAIN) private parentFrontendDomain: string,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.window = window;
      this.top = top;
    }
    this.domain = environment.domain;

    this.incomingMessage$ = merge(
      this.messageFromChild$.pipe(map((data) => ({ ...data, source: MESSAGE_SOURCE.CHILD }))),
      this.messageFromParent$.pipe(map((data) => ({ ...data, source: MESSAGE_SOURCE.PARENT }))),
    ).pipe(share());
  }

  addChild(child: IframeChild): number {
    const index = this.getChildIndex(child.iframeId);

    if (index >= 0) {
      return index;
    }

    this.childs.push(child);
    return this.childs.length - 1;
  }

  removeChild(iframeId: string): boolean {
    const index = this.getChildIndex(iframeId);

    if (index < 0) {
      return false;
    }

    this.childs.splice(index, 1);

    return true;
  }

  listenMessagesFromParent(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.listeningParent) {
      return;
    }

    this.listeningParent = true;

    this.pluginIframeId = new URLSearchParams(this.window.location.search).get('pluginIframeId');

    if (this.pluginIframeId === null) {
      return;
    }

    if (!this.isInSameIframe()) {
      return;
    }

    this.setDomain();

    window.addEventListener(
      'message',
      (e) => {
        this.parseMessageFromParent(e);
      },
      false,
    );
  }

  listenMessagesFromChilds(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.listeningChilds) {
      return;
    }

    this.listeningChilds = true;

    this.childs = [];
    this.setDomain();

    window.addEventListener(
      'message',
      (e) => {
        this.parseMessageFromChilds(e);
      },
      false,
    );
  }

  sendMessageToParent(message: CommunicationMessage): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this.receiverWindow) {
      this.setReceiverWindow();
    }
    const messageToSend: CommunicationMessageForIframe = {
      payload: message,
      pluginIframeId: this.pluginIframeId,
    };

    let target = '*';
    if (!this.isLocalhost()) {
      target = this.parentFrontendDomain;
    }

    this.setDomain();
    if (!environment.production) {
      console.log('[IA] Sending', messageToSend.payload.type, messageToSend.payload.data, target);
    }
    this.receiverWindow.postMessage(messageToSend, target);
  }

  sendMessageToChild(iframeId: string, message: CommunicationMessage): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const index = this.getChildIndex(iframeId);
    if (index < 0) {
      return;
    }

    const child = this.childs[index];

    const messageToSend: CommunicationMessageForIframe = {
      payload: message,
      pluginIframeId: iframeId,
    };

    let target = '*';
    if (!this.isLocalhost()) {
      const url = new URL(child.url);
      target = url.origin;
    }

    this.setDomain();
    if (child && child.window) {
      if (!environment.production) {
        console.log('[IA] Sending', messageToSend.payload.type, messageToSend.payload.data, target);
      }
      child.window.postMessage(messageToSend, target);
    }
  }

  private parseMessageFromParent(e: any): void {
    if (!this.checkOrigin(e.origin)) {
      return;
    }
    if (!this.checkIframeIdExists(e.data)) {
      return;
    }
    const data = e.data as CommunicationMessageForIframe;

    if (this.pluginIframeId !== data.pluginIframeId) {
      return;
    }
    if (!environment.production) {
      console.log('[IA] Received', data.payload.type, data.payload.data);
    }
    this.messageFromParent$.next(data);
  }

  private parseMessageFromChilds(e: any): void {
    if (!this.checkIframeIdExists(e.data)) {
      return;
    }

    const data = e.data as CommunicationMessageForIframe;

    const childIndex = this.getChildIndex(data.pluginIframeId);
    if (childIndex < 0) {
      return;
    }

    if (!this.checkChildOrigin(this.childs[childIndex], e.origin)) {
      return;
    }
    if (!environment.production) {
      console.log('[IA] Received', data.payload.type, data.payload.data);
    }
    this.messageFromChild$.next(data);
  }

  private setReceiverWindow(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.receiverWindow = window.parent;
    }
  }

  private checkIframeIdExists(message: CommunicationMessageForIframe): boolean {
    if (!message || !message.pluginIframeId) {
      return false;
    }

    return true;
  }

  private checkOrigin(origin: string): boolean {
    if (this.isLocalhost(origin)) {
      return true;
    }
    return origin === this.parentFrontendDomain;
  }

  private checkChildOrigin(child: IframeChild, origin: string): boolean {
    if (this.isLocalhost(origin)) {
      return true;
    }
    const url = new URL(child.url);
    return origin === url.origin;
  }

  private isInSameIframe(): boolean {
    try {
      return this.window !== this.top;
    } catch (e) {
      return false;
    }
  }

  private isLocalhost(origin: string = null): boolean {
    if (environment.production) {
      return false;
    }

    const localhost = 'localhost';
    return (origin && origin === localhost) || location.hostname === localhost;
  }

  private getChildIndex(iframeId: string): number {
    return this.childs.findIndex((c) => c.iframeId === iframeId);
  }

  private setDomain(): void {
    try {
      document.domain = this.domain;
    } catch (e) {}
  }
}
