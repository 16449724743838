import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EmailNewBatchComponent } from '../email-new-batch/email-new-batch.component';

@Component({
  selector: 'app-email-confirm-dialog',
  templateUrl: './email-confirm-dialog.component.html',
  styleUrls: ['./email-confirm-dialog.component.scss'],
})
export class EmailConfirmDialogComponent implements OnInit {
  choise: boolean;

  constructor(public dialogRef: MatDialogRef<EmailNewBatchComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  accept() {
    this.choise = true;
    this.closeDialog();
  }

  cancel() {
    this.choise = false;
    this.closeDialog();
  }

  private closeDialog(): void {
    this.dialogRef.close(this.choise);
  }
}
