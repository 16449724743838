import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { isUndefined } from 'util';

import { CardFormgroupMessageService } from '../../../shared/card-formgroup/card-formgroup-message.service';
import Logger from '../../../shared/logger';
import { AppState } from '../../../store/app.reducers';
import { SupplierDetailsModel } from '../../model/supplier.model';
import { SupplierMarketplaceDetailsModel } from '../../model/supplier-marketplace.model';
import { RxEditBaseComponent } from '../../rx-edit-base.component';
import { PybExtensionService } from '../../services/pyb-extension/pyb-extension.service';
import { selectSupplierDetails, selectSupplierMarketplaces } from '../../store/supplier/supplier.actions';
import { SupplierMarketplaceDict } from '../supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-dict';
import { SupplierMarketplaceForm } from '../supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-form';
import { SupplierMarketplaceFormTypes } from '../supplier-marketplaces/supplier-marketplace-edit/supplier-marketplace-form-types';
import { AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY } from '../supplier-onboarding/supplier-onboarding.service';
import { selectLoggedUserModel } from './../../../auth/store/auth.actions';
import { ErrorLogger } from './../../../shared/services/error-logger/error-logger.service';
import { UsersService } from './../../../shared/users/service/users.service';
import { USER_PERMISSION } from './../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-marketplace-access-details',
  templateUrl: './marketplace-access-details.component.html',
  styleUrls: ['./marketplace-access-details.component.scss'],
})
export class MarketplaceAccessDetailsComponent extends RxEditBaseComponent implements OnInit {
  readonly MARKETPLACE_ACCESS_FORM = SupplierMarketplaceFormTypes.MARKETPLACE_ACCESS_FORM;
  readonly SUPPLIER_MARKETPLACE_SERVICE_CLASS_NAME = 'SupplierMarketplacesService';

  readonly accessStatuses = SupplierMarketplaceDict.accessStatuses;

  @Input()
  title: string;
  hasFinancePermission = false;

  amazonMarketplaceDetails: SupplierMarketplaceDetailsModel;
  supplierDetailsModel: SupplierDetailsModel;

  public amazonMarketplaceDetailsLoading: boolean;

  private mpKey: string;

  constructor(
    store: Store<AppState>,
    messageService: CardFormgroupMessageService,
    errorLogger: ErrorLogger,
    private toastrService: ToastrService,
    private pybExtService: PybExtensionService,
    private marketplaceFormBuilder: SupplierMarketplaceForm,
    private route: ActivatedRoute,
    private usersService: UsersService,
  ) {
    super(store, messageService, errorLogger);
  }

  ngOnInit() {
    if (this.route && this.route.hasOwnProperty('params')) {
      this.subscriptions.push(
        this.route.params.subscribe((params) => {
          if (params.hasOwnProperty('mpSupplierKey')) {
            this.mpKey = params['mpSupplierKey'];
          }
        }),
      );
    }

    this.checkFinancePermissions();

    this.amazonMarketplaceDetailsLoading = true;

    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((selectSupplierDetailsModel: SupplierDetailsModel) => {
        this.supplierDetailsModel = selectSupplierDetailsModel;

        this.subscriptions.push(
          this.store.select(selectSupplierMarketplaces).subscribe(
            (marketplaces) => {
              this.amazonMarketplaceDetails = undefined;
              if (!isUndefined(marketplaces)) {
                this.amazonMarketplaceDetails = marketplaces.find((marketplaceModel: SupplierMarketplaceDetailsModel) => {
                  if (this.mpKey) {
                    return marketplaceModel.mpSupKey === this.mpKey;
                  }

                  return marketplaceModel.marketplaceKey === AMAZON_SELLER_CENTRAL_MARKETPLACE_KEY;
                });

                if (this.amazonMarketplaceDetails) {
                  this.initGeneralForms();
                }

                this.amazonMarketplaceDetailsLoading = false;
              }
            },
            (error) => {
              Logger.log(error);
              this.amazonMarketplaceDetailsLoading = false;
            },
          ),
        );
      }),
    );
  }

  customRequestMapper(modelData) {
    const data = { ...modelData };

    if (!this.hasFinancePermission) {
      delete data.overrideDailyPlaidCheck;
    }

    return data;
  }

  initGeneralForms() {
    if (isUndefined(this.formGroups[SupplierMarketplaceFormTypes.MARKETPLACE_ACCESS_FORM])) {
      this.formGroups = {
        ...this.formGroups,
        ...this.marketplaceFormBuilder.initMarketplaceAccessFormGroup(this.amazonMarketplaceDetails),
      };
    }
  }

  getSupplierKey() {
    return this.supplierDetailsModel.supplierKey;
  }

  resolveServiceClassBy(formGroupName: string) {
    return this.SUPPLIER_MARKETPLACE_SERVICE_CLASS_NAME;
  }

  protected prepareRequestPartData(formGroupName, partData) {
    const wholeModelState = {
      ...this.amazonMarketplaceDetails,
      ...partData,
    };
    return wholeModelState;
  }

  protected resolveParamsBy(formGroupName: string): any[] {
    return [this.amazonMarketplaceDetails.mpSupKey];
  }

  loginToMarketplace(marketplaceDetailsModel) {
    this.pybExtService.loginToMarketplace(marketplaceDetailsModel, this.subscriptions, this.store, this.supplierDetailsModel.supplierKey);
  }

  private checkFinancePermissions(): void {
    this.subscriptions.push(
      this.store.select(selectLoggedUserModel).subscribe((user) => {
        this.subscriptions.push(
          this.usersService.checkPermission(USER_PERMISSION.FINANCE, user).subscribe((permitted) => {
            this.hasFinancePermission = permitted;
          }),
        );
      }),
    );
  }
}
