<h1 mat-dialog-title>
  Send Email to {{ supplierName }} ({{ supplierEmail }})
  <button mat-raised-button class="btn-sm pull-right" color="accent" (click)="showBcc = !showBcc">CC / BCC</button>
</h1>
<div class="row" [hidden]="!showBcc">
  <div class="col-md-6">
    <mat-form-field class="full-width">
      <mat-chip-list #chipListCC>
        <mat-chip *ngFor="let value of ccSelected" (removed)="removeCC(value)">
          {{ value }} <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <input
        #inputCC
        type="text"
        class="full-width"
        [matChipInputFor]="chipListCC"
        [matChipInputAddOnBlur]="false"
        placeholder="CC"
        [(ngModel)]="ccSearch"
        matInput
        [matAutocomplete]="autoCC"
      />

      <app-loading-spinner [size]="1" matSuffix *ngIf="usersPending"></app-loading-spinner>

      <mat-autocomplete #autoCC="matAutocomplete" (optionSelected)="setCC($event)" [displayWith]="displayUsersFn">
        <mat-option *ngFor="let option of usersOptions | filter: ccSearch" [value]="option">
          <i class="material-icons option-icon">{{ option.type === 'group' ? 'people' : 'person' }}</i> {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="col-md-6">
    <mat-form-field class="full-width">
      <mat-chip-list #chipListBCC>
        <mat-chip *ngFor="let value of bccSelected" (removed)="removeBCC(value)">
          {{ value }} <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>

      <input
        #inputBCC
        type="text"
        [matChipInputFor]="chipListBCC"
        placeholder="BCC"
        matInput
        [(ngModel)]="bccSearch"
        [matAutocomplete]="autoBCC"
      />

      <app-loading-spinner [size]="1" matSuffix *ngIf="usersPending"></app-loading-spinner>

      <mat-autocomplete #autoBCC="matAutocomplete" (optionSelected)="setBCC($event)" [displayWith]="displayUsersFn">
        <mat-option *ngFor="let option of usersOptions | filter: bccSearch" [value]="option">
          <i class="material-icons option-icon">{{ option.type === 'group' ? 'people' : 'person' }}</i>
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>

<form [formGroup]="emailSendForm" #formSendEmail class="row">
  <div class="col-12">
    <mat-form-field class="full-width">
      <mat-select placeholder="Template" [formControl]="template" (selectionChange)="templateSelected($event)">
        <mat-option *ngFor="let template of templateOptions | async" [value]="template.configValue">
          {{ template.configKey }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="Subject" formControlName="subject" />
    </mat-form-field>
    <div ngxErrors="subject" class="col-md-12">
      <mat-error ngxError="required" when="touched"> Email subject is required </mat-error>
    </div>

    <app-editor formControlName="body" [toolsTheme]="TOOLS_THEME.EMAIL"></app-editor>
    <div ngxErrors="body" class="col-md-12">
      <mat-error ngxError="required" when="touched"> Email message is required </mat-error>
    </div>
  </div>
</form>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button [disabled]="emailSendForm.invalid" class="preview-btn" (click)="openPreview()" tabindex="2" color="accent">
    Show preview
  </button>
  <button mat-raised-button (click)="closeDialog()" [color]="'warn'" tabindex="-1">Cancel</button>
</div>
