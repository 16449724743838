import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { SubscriberComponent } from './../../shared/component-subscriber/subscriber.component';
import { PendingReviewService } from './../service/pending-review.service';

@Component({
  selector: 'app-pending-edit',
  templateUrl: './pending-edit.component.html',
  styleUrls: ['./pending-edit.component.scss'],
})
export class PendingEditComponent extends SubscriberComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  supKey: string;
  colections: Observable<any>;
  data: any;
  public baseHttpParams: any;

  constructor(private route: ActivatedRoute, private service: PendingReviewService) {
    super();
    this.subscription = this.route.params.subscribe((params) => {
      this.supKey = params['supKey'];
    });
  }

  ngOnInit() {
    this.baseHttpParams = {
      supplier_key: this.supKey,
    };

    this.colections = this.service.getPendingReview(this.baseHttpParams);
    this.subscriptions.push(
      this.colections.subscribe((result) => {
        this.data = result;
      }),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }
}
