<h1 mat-dialog-title>Confirmation required</h1>

<mat-dialog-actions>
  <p>{{ data.msg }}</p>
</mat-dialog-actions>

<mat-dialog-actions class="actions-buttons">
  <button mat-raised-button (click)="cancel()" class="cancel-button" [color]="'warn'" tabindex="-1">No</button>
  <button mat-raised-button class="accept-button" (click)="accept()" [color]="'accept'" tabindex="-1">Yes</button>
</mat-dialog-actions>
