import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../config';
import { ServiceUpdatePartialInterface } from '../../shared/interfaces/service-update-partial-interface/service-update-partial-interface';
import { SupplierDetailsModel } from '../model/supplier.model';
import { SupplierStatusModel } from '../model/supplier-status.model';


@Injectable()
export class AutoPaymentService implements ServiceUpdatePartialInterface {
  private internalApiUrl: string;
  private apiUrl: string;
  readonly supplierKeyColumnName = 'supplier_key';

  constructor(private appConfig: AppConfigService, private httpClient: HttpClient) {
    this.internalApiUrl = appConfig.env.internalApiUrl;
    this.apiUrl = this.internalApiUrl + 'suppliers';

  }

  saveItem(__uuid: string, item: any): Observable<any> {
    console.log(`------------- full item: ${item}`)
    return this.saveItemPart(item.supplierKey, {
      hasAutoTransferEnabled: item.status.hasAutoTransferEnabled,
      hasForwardPaymentEnabled: item.status.hasForwardPaymentEnabled,
    });
  }

  saveItemPart(supplierKey: string, __itemPart: Partial<SupplierStatusModel>): Observable<SupplierDetailsModel> {
    return this.httpClient.post<any>(`${this.apiUrl}/${supplierKey}/payment/payment_features`, __itemPart);
  }

}
