<article class="article">
  <div class="flex-container">
    <div class="header box box-default">
      <div class="navigation">
        <a [routerLink]="['../../']" title="Go to reserves list" class="close-button">
          <i class="material-icons">arrow_back</i>
        </a>
      </div>
      <div class="info">
        <h6>Edit Marketplace</h6>
      </div>
      <div class="actions"></div>
    </div>
    <div class="container">
      <ng-template #loadingCard>
        <div class="card-content">
          <app-loading-spinner [size]="1"></app-loading-spinner>
        </div>
      </ng-template>

      <ng-template #loadingForm>
        <div class="form-content">
          <app-loading-spinner [size]="2"></app-loading-spinner>
        </div>
      </ng-template>

      <div class="card" *ngIf="formGroups[FUNDING_FORM]; else loadingForm; let fundingForm">
        <div class="card-content">
          <mat-card-header>
            <div mat-card-avatar>
              <img class="marketplace-img" *ngIf="marketplaceDetailsModel?.icon; else marketplaceIcon" [src]="marketplaceIconSanitized" />
              <ng-template #marketplaceIcon>
                <i class="material-icons marketplace-icon">store_mall_directory</i>
              </ng-template>
            </div>
            <mat-card-title>
              <h3>
                {{ marketplaceDetailsModel.marketplaceName }}
                <span
                  *ngIf="fundingForm.get('fundPartStatus').value; else loadingForm; let fundPartStatus"
                  class="badge"
                  [ngClass]="{
                    'badge-warning': fundPartStatus === 'Not Sent',
                    'badge-info': fundPartStatus === 'Sent',
                    'badge-danger': fundPartStatus === 'Rejected',
                    'badge-success': fundPartStatus === 'Approved'
                  }"
                >
                  {{ fundPartStatus }}
                </span>
              </h3>
            </mat-card-title>
            <mat-card-subtitle>
              <pre ngxClipboard [cbContent]="marketplaceDetailsModel.mpSupKey" matTooltip="Copy MP-Sup Key to clipboard">{{
                marketplaceDetailsModel.mpSupKey
              }}</pre>
            </mat-card-subtitle>
            <mat-chip-list class="pull-right">
              <mat-chip *ngIf="marketplaceDetailsModel.draft" [color]="'default'">Draft</mat-chip>
              <mat-chip *ngIf="marketplaceDetailsModel.marketplaceStatus.hidden" [color]="'accent'">Hidden</mat-chip>
            </mat-chip-list>
          </mat-card-header>

          <div class="divider divider-sm"></div>

          <app-opt-in-table [mpSupKey]="routeMpSupKey"></app-opt-in-table>
          <div class="divider divider-sm"></div>

          <form
            app-card-formgroup
            *ngIf="fundingForm"
            #formGeneralSettings
            [formGroup]="fundingForm"
            (onSave)="saveFormGroup(fundingForm, FUNDING_FORM)"
          >
            <app-inline-edit-select
              *ngIf="formGeneralSettings.inEdit"
              [formControlName]="'fundPartStatus'"
              [formGroup]="fundingForm"
              [label]="'Status'"
              [disableInline]="true"
              [inGroupEdit]="formGeneralSettings.inEdit"
              [items]="fundStatusList"
              (onSave)="saveSingleField('fundPartStatus', $event)"
              [helpText]="'Modification may interrupt payments until supplier accepts.'"
            >
            </app-inline-edit-select>

            <app-inline-edit-text
              [formControlName]="'advanceRate'"
              [formGroup]="fundingForm"
              [label]="'Advance Rate'"
              [disableInline]="true"
              [type]="'number'"
              [displayType]="'percent'"
              [inGroupEdit]="formGeneralSettings.inEdit"
              [helpText]="'Modification may interrupt payments until supplier accepts.'"
              (onSave)="saveSingleField('advanceRate', $event)"
            >
            </app-inline-edit-text>

            <div class="row">
              <app-inline-edit-text
                type="number"
                [displayType]="'percent'"
                class="col-md-12"
                [formControlName]="'minimumReservePct'"
                [formGroup]="fundingForm"
                [disableInline]="true"
                [label]="'Minimum Reserve'"
                [inGroupEdit]="formGeneralSettings.inEdit"
                (onSave)="saveSingleField('minimumReservePct', $event)"
              >
              </app-inline-edit-text>

              <app-inline-edit-text
                type="text"
                [displayType]="'currency'"
                class="col-md-6"
                [formControlName]="'creditLimit'"
                [formGroup]="fundingForm"
                [label]="'Credit Limit'"
                [disableInline]="true"
                [inGroupEdit]="formGeneralSettings.inEdit"
                (onSave)="saveSingleField('creditLimit', $event)"
              >
              </app-inline-edit-text>

              <app-inline-edit-text
                type="number"
                class="col-md-6"
                [formControlName]="'concentrationLimit'"
                [formGroup]="fundingForm"
                [label]="'Concentration Limit'"
                [disableInline]="true"
                [inGroupEdit]="formGeneralSettings.inEdit"
                (onSave)="saveSingleField('concentrationLimit', $event)"
              >
              </app-inline-edit-text>

              <div class="col-md-12">
                <div
                  class="funding-period-group"
                  ngxClipboard
                  [cbContent]="fundingForm.get('fundingPeriodGroup').value"
                  matTooltip="Click to copy value"
                >
                  Funding period: <span class="badge badge-pill badge-light">{{ fundingForm.get('fundingPeriodGroup').value }}</span>
                  <a><i class="nav-icon material-icons">content_copy</i></a>
                </div>
              </div>
            </div>
          </form>

          <form
            app-card-formgroup
            *ngIf="formGroups[REVENUE_FORM]; else loadingForm; let revenueForm"
            #formGeneralSettings
            [formGroup]="revenueForm"
            (onSave)="saveFormGroup(revenueForm, REVENUE_FORM)"
          >
            <div class="row">
              <app-inline-edit-text
                type="text"
                class="col-md-6"
                [formControlName]="'estimatedMonthlyRevenue'"
                [formGroup]="revenueForm"
                [label]="'Estimated Monthly Revenue'"
                [disableInline]="true"
                [inGroupEdit]="formGeneralSettings.inEdit"
                (onSave)="saveSingleField('estimatedMonthlyRevenue', $event)"
              >
              </app-inline-edit-text>

              <app-inline-edit-select
                class="col-md-6"
                [formControlName]="'lengthTimeSelling'"
                [formGroup]="revenueForm"
                [label]="'Length of Time Selling'"
                [disableInline]="true"
                [inGroupEdit]="formGeneralSettings.inEdit"
                [items]="sellingTimeRanges$"
              >
              </app-inline-edit-select>
            </div>
          </form>

          <form
            app-card-formgroup
            *ngIf="formGroups[PAYMENT_FORM]; else loadingForm; let paymentForm"
            #formPayment
            [title]="'Payment'"
            [formGroup]="paymentForm"
            (onSave)="saveFormGroup(paymentForm, PAYMENT_FORM)"
          >
            <div class="row">
              <app-inline-edit-text
                class="col-md-12"
                type="text"
                [formControlName]="'paymentTerms'"
                [formGroup]="paymentForm"
                [label]="'Payment Terms'"
                [inGroupEdit]="formPayment.inEdit"
                (onSave)="saveSingleField('paymentTerms', $event, PAYMENT_FORM)"
              >
              </app-inline-edit-text>

              <app-inline-edit-text
                type="number"
                class="col-md-6"
                [formControlName]="'paymentTermsDays'"
                [formGroup]="paymentForm"
                [label]="'Payment Terms Days'"
                [inGroupEdit]="formPayment.inEdit"
                (onSave)="saveSingleField('paymentTermsDays', $event, PAYMENT_FORM)"
              >
              </app-inline-edit-text>

              <app-inline-edit-text
                type="number"
                class="col-md-6"
                [formControlName]="'paymentFrequency'"
                [formGroup]="paymentForm"
                [label]="'Payment Frequency'"
                [inGroupEdit]="formPayment.inEdit"
                (onSave)="saveSingleField('paymentFrequency', $event, PAYMENT_FORM)"
              >
              </app-inline-edit-text>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</article>
