/* eslint-disable */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UWRules } from '../common/interfaces/uw-rules';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UWAmazonHealth } from '../common/interfaces/uw-amazon-health';
import { ToastrService } from 'ngx-toastr';
import { SupplierUnderwritingService } from '../../supplier-underwriting.service';
import { UnderwritingInitialCardHeaderStatusType } from '../common/components/underwriting-initial-card-header/underwriting-initial-card-header-status-type';
import { MaxValueForUWForm, NoDataText } from '../common/consts/underwritings-initial-constants';
/* eslint-enable */

@Component({
  selector: 'app-account-health',
  templateUrl: './account-health.component.html',
  styleUrls: ['./account-health.component.scss'],
})
export class AccountHealthComponent implements OnInit, OnChanges {
  @Input() supplierKey: string;
  @Input() UWRules: UWRules;
  @Input() editRules: boolean;
  @Input() isRestrictedUWView: boolean;
  @Input() benchmarkStatus: any;
  @Input() amazonHealth: any;

  @Output() onEditRulesClick = new EventEmitter();
  @Output() rulesSaved = new EventEmitter();

  headerType: UnderwritingInitialCardHeaderStatusType;
  headerStatus: string;
  collectedData: UWAmazonHealth | null;
  maxValueForUWForm = MaxValueForUWForm;
  uwForm: FormGroup;
  noDataText = NoDataText;

  constructor(private toastr: ToastrService, private supplierUnderwritingService: SupplierUnderwritingService, private fb: FormBuilder) {}

  ngOnInit() {
    this.setData();
  }

  ngOnChanges(changes) {
    this.setHeaderDetails();
    if (changes.supplierKey && this.supplierKey) {
      this.createForm();
    }
  }

  createForm() {
    this.uwForm = this.fb.group({
      order_defect_rate: [null, [Validators.required, Validators.max(this.maxValueForUWForm)]],
      late_shipment_rate: [null, [Validators.required, Validators.max(this.maxValueForUWForm)]],
      late_responses: [null, [Validators.required, Validators.max(this.maxValueForUWForm)]],
      pre_fullfillment_cancel_rate: [null, [Validators.required, Validators.max(this.maxValueForUWForm)]],
      delivered_on_time_rate: [null, [Validators.required, Validators.min(0)]],
      valid_tracking_rate: [null, [Validators.required, Validators.min(0)]],
      return_dissatisfaction_rate: [null, [Validators.required, Validators.max(this.maxValueForUWForm)]],
    });
  }

  setHeaderDetails() {
    if (this.isSuccessHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.SUCCESS;
      this.headerStatus = 'Valid';
    }
    if (this.isDangerHeaderType()) {
      this.headerType = UnderwritingInitialCardHeaderStatusType.DANGER;
      this.headerStatus = 'Needs review';
    }
  }

  isSuccessHeaderType() {
    return !this.isDangerHeaderType();
  }

  isDangerHeaderType() {
    if (!this.UWRules) {
      return true;
    }
    return (
      (this.UWRules.order_defect_rate.error && this.UWRules.order_defect_rate.value !== 'N/A') ||
      (this.UWRules.late_shipment_rate.error && this.UWRules.late_shipment_rate.value !== 'N/A') ||
      (this.UWRules.late_responses.error && this.UWRules.late_responses.value !== 'N/A') ||
      (this.UWRules.pre_fullfillment_cancel_rate.error && this.UWRules.pre_fullfillment_cancel_rate.value !== 'N/A') ||
      (this.UWRules.delivered_on_time.error && this.UWRules.delivered_on_time.value !== 'N/A') ||
      (this.UWRules.valid_tracking_rate.error && this.UWRules.valid_tracking_rate.value !== 'N/A') ||
      (this.UWRules.return_dissatisfaction_rate.error && this.UWRules.return_dissatisfaction_rate.value !== 'N/A') ||
      this.benchmarkStatus !== 'OK'
    );
  }

  setData() {
    this.supplierUnderwritingService.getAmazonHealthRules(this.supplierKey).subscribe((amazonHealthRules) => {
      this.updateForm(amazonHealthRules);
    });
  }

  updateForm(amazonHealthRules) {
    const numericRules = amazonHealthRules.filter((item) => item.field_type === 'numeric');
    this.collectedData = new UWAmazonHealth(numericRules);
    this.uwForm.setValue({
      order_defect_rate: Number(this.collectedData.order_defect_rate_rule.error_value),
      late_shipment_rate: Number(this.collectedData.late_shipment_rate_rule.error_value),
      late_responses: Number(this.collectedData.late_responses_rule.error_value),
      pre_fullfillment_cancel_rate: Number(this.collectedData.pre_fullfillment_cancel_rate_rule.error_value),
      delivered_on_time_rate: Number(this.collectedData.delivered_on_time_rate_rule.error_value),
      valid_tracking_rate: Number(this.collectedData.valid_tracking_rate_rule.error_value),
      return_dissatisfaction_rate: Number(this.collectedData.return_dissatisfaction_rate_rule.error_value),
    });
  }

  editRulesClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.onEditRulesClick.emit();
  }

  saveRules(uwForm) {
    const keyArray = [
      this.collectedData.order_defect_rate_rule.key,
      this.collectedData.late_shipment_rate_rule.key,
      this.collectedData.late_responses_rule.key,
      this.collectedData.pre_fullfillment_cancel_rate_rule.key,
      this.collectedData.delivered_on_time_rate_rule.key,
      this.collectedData.valid_tracking_rate_rule.key,
      this.collectedData.return_dissatisfaction_rate_rule.key,
    ];
    const rulesToUpdate = this.getRulesToUpdate(uwForm, keyArray);
    this.supplierUnderwritingService.updateAmazonHealthRules(this.supplierKey, rulesToUpdate).subscribe(
      () => this.onRulesUpdateSuccess(),
      (err) => this.onRulesUpdateError(err),
    );
  }

  onRulesUpdateSuccess() {
    this.toastr.success('Account Health rule change saved properly!');
    this.editRules = false;
    this.supplierUnderwritingService.getAmazonHealthRules(this.supplierKey).subscribe((ruleData) => {
      const numericRules = ruleData.filter((item) => item.field_type === 'numeric');
      this.collectedData = new UWAmazonHealth(numericRules);
      this.uwForm.setValue({
        order_defect_rate: Number(this.collectedData.order_defect_rate_rule.error_value),
        late_shipment_rate: Number(this.collectedData.late_shipment_rate_rule.error_value),
        late_responses: Number(this.collectedData.late_responses_rule.error_value),
        pre_fullfillment_cancel_rate: Number(this.collectedData.pre_fullfillment_cancel_rate_rule.error_value),
        delivered_on_time_rate: Number(this.collectedData.delivered_on_time_rate_rule.error_value),
        valid_tracking_rate: Number(this.collectedData.valid_tracking_rate_rule.error_value),
        return_dissatisfaction_rate: Number(this.collectedData.return_dissatisfaction_rate_rule.error_value),
      });
    });
    this.rulesSaved.emit();
  }

  onRulesUpdateError(err) {
    this.toastr.error(err.error.message, 'Problems with saving Account Health!', {
      enableHtml: true,
    });
  }

  getRulesToUpdate(uwForm, keyArray) {
    return {
      [keyArray[0]]: {
        error_value: Number.isFinite(uwForm.value.order_defect_rate)
          ? '' + uwForm.value.order_defect_rate
          : this.collectedData.order_defect_rate_rule.error_value,
      },
      [keyArray[1]]: {
        error_value: Number.isFinite(uwForm.value.late_shipment_rate)
          ? '' + uwForm.value.late_shipment_rate
          : this.collectedData.late_shipment_rate_rule.error_value,
      },
      [keyArray[2]]: {
        error_value: Number.isFinite(uwForm.value.late_responses)
          ? '' + uwForm.value.late_responses
          : this.collectedData.late_responses_rule.error_value,
      },
      [keyArray[3]]: {
        error_value: Number.isFinite(uwForm.value.pre_fullfillment_cancel_rate)
          ? '' + uwForm.value.pre_fullfillment_cancel_rate
          : this.collectedData.pre_fullfillment_cancel_rate_rule.error_value,
      },
      [keyArray[4]]: {
        error_value: Number.isFinite(uwForm.value.delivered_on_time_rate)
          ? '' + uwForm.value.delivered_on_time_rate
          : this.collectedData.delivered_on_time_rate_rule.error_value,
      },
      [keyArray[5]]: {
        error_value: Number.isFinite(uwForm.value.valid_tracking_rate)
          ? '' + uwForm.value.valid_tracking_rate
          : this.collectedData.valid_tracking_rate_rule.error_value,
      },
      [keyArray[6]]: {
        error_value: Number.isFinite(uwForm.value.return_dissatisfaction_rate)
          ? '' + uwForm.value.return_dissatisfaction_rate
          : this.collectedData.return_dissatisfaction_rate_rule.error_value,
      },
    };
  }
}
