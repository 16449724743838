<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="title">Permission Groups</div>
        <div class="actions">
          <button class="action" [routerLink]="['../new']" mat-raised-button [color]="'accent'">
            <mat-icon>add</mat-icon>
            Add Permission Group
          </button>
        </div>
      </div>

      <div class="permissions-table">
        <mat-table #table [dataSource]="dataSource">
          <!-- Description Column -->
          <ng-container matColumnDef="permDesc">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.permDesc }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-raised-button class="mat-primary" [routerLink]="['/manage/perm-groups', element.permCode, 'edit']">Edit</button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</section>
