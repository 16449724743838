<section class="card">
  <div class="card-content">
    <app-title-with-icon [iconClass]="'fa-user'" [title]="customTitle || 'Home Address'"> </app-title-with-icon>

    <div class="divider"></div>

    <form
      *ngIf="homeAddressForm; else loadingForm"
      app-card-formgroup
      [title]="customTitle || 'Home Address'"
      #formGroupHomeAddress
      [formGroup]="homeAddressForm"
      [isGroupEditable]="!isDisabled"
      (onSave)="saveHomeAddressForm()"
    >
      <ng-content></ng-content>

      <div class="row">
        <app-inline-edit-text
          class="col-md-12"
          [formControlName]="'addr1'"
          type="text"
          [formGroup]="homeAddressForm"
          [label]="'Address Line 1'"
          [inGroupEdit]="formGroupHomeAddress.inEdit"
          [disableInline]="true"
        >
        </app-inline-edit-text>

        <app-inline-edit-text
          class="col-md-12"
          [formControlName]="'addr2'"
          type="text"
          [formGroup]="homeAddressForm"
          [label]="'Address Line 2'"
          [inGroupEdit]="formGroupHomeAddress.inEdit"
          [disableInline]="true"
        >
        </app-inline-edit-text>

        <app-inline-edit-select
          class="col-md-6"
          [formControlName]="'country'"
          [label]="'Country'"
          [formGroup]="homeAddressForm"
          [inGroupEdit]="formGroupHomeAddress.inEdit"
          [disableInline]="true"
          [items]="addressCountries"
        >
        </app-inline-edit-select>

        <app-inline-edit-select
          class="col-md-6"
          [formControlName]="'state'"
          [label]="'State'"
          [formGroup]="homeAddressForm"
          [inGroupEdit]="formGroupHomeAddress.inEdit"
          [disableInline]="true"
          [items]="addressStates"
        >
        </app-inline-edit-select>

        <app-inline-edit-text
          class="col-md-6"
          [formControlName]="'city'"
          type="text"
          [label]="'City'"
          [formGroup]="homeAddressForm"
          [inGroupEdit]="formGroupHomeAddress.inEdit"
          [disableInline]="true"
        >
        </app-inline-edit-text>

        <app-inline-edit-text
          class="col-md-6"
          [formControlName]="'postalCode'"
          type="text"
          [formGroup]="homeAddressForm"
          [label]="'Zip'"
          [inGroupEdit]="formGroupHomeAddress.inEdit"
          [disableInline]="true"
        >
        </app-inline-edit-text>
      </div>
    </form>
  </div>
</section>

<ng-template #loadingForm>
  <div class="form-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
