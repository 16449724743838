<mat-card class="productWeeklyPayments__matCard">
  <div class="productWeeklyPayments__matCard__title">
    <h5>Weekly Payments</h5>
    <div>
      <p [ngStyle]="{ color: productStatusColor }">{{ productStatus | uppercase }}</p>
    </div>
  </div>
  <mat-card-content>
    <mat-table *ngIf="dataSource.data.length" class="productWeeklyPayments__matCard__table" [dataSource]="dataSource">
      <ng-container matColumnDef="marketplace">
        <mat-header-cell *matHeaderCellDef>Marketplace</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productWeeklyPayments__matCard__table__cell">
          {{ element.marketplace }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastPurchaseDate">
        <mat-header-cell *matHeaderCellDef>Last Purchase Date</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productWeeklyPayments__matCard__table__cell">
          {{ element.lastPurchaseDate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pastDue">
        <mat-header-cell *matHeaderCellDef>Past Due</mat-header-cell>
        <mat-cell *matCellDef="let element" class="productWeeklyPayments__matCard__table__cell">
          {{ element.pastDue }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="optInDate">
        <mat-header-cell *matHeaderCellDef>Opt In Date</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productWeeklyPayments__matCard__table__cell-date">
          {{ element.optInDate }}
          <button
            mat-icon-button
            class="productWeeklyPayments__matCard__table__cell__btn"
            (click)="openReasonDialog(element.optInDate, element.optOutDate, i)"
          >
            <mat-icon class="productWeeklyPayments__matCard__table__cell__icon">date_range</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="optOutDate">
        <mat-header-cell *matHeaderCellDef>Opt Out Date</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productWeeklyPayments__matCard__table__cell-date">
          {{ element.optOutDate }}
          <button
            mat-icon-button
            class="productWeeklyPayments__matCard__table__cell__btn"
            (click)="openReasonDialog(element.optInDate, element.optOutDate, i)"
          >
            <mat-icon class="productWeeklyPayments__matCard__table__cell__icon">date_range</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <mat-header-cell *matHeaderCellDef>Enabled</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="productWeeklyPayments__matCard__table__cell">
          <!--          <mat-checkbox [(ngModel)]="element.enabled" (change)="activationProductRequest($event, i)"></mat-checkbox>-->
          <mat-slide-toggle [(ngModel)]="element.enabled" (change)="activationProductRequest($event, i)"></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="serviceColumns" class="productWeeklyPayments__matCard__table__headerRow"></mat-header-row>
      <mat-row *matRowDef="let row; columns: serviceColumns" class="productWeeklyPayments__matCard__table__row"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
