export interface USD {
  pending_credits: string;
  credit_balance: string;
  ledger_balance: string;
  available_balance: string;
  currency_code: string;
}

export interface Balances {
  USD: USD;
}

export interface Gpa {
  available_balance: string;
  credit_balance: string;
  pending_credits: string;
  ledger_balance: string;
  currency_code: string;
  balances: Balances;
}

export interface Response {
  code: string;
  memo: string;
}

export interface CardSecurityCodeVerification {
  response: Response;
  type: string;
}

export interface Acquirer {
  retrieval_reference_number: string;
  institution_country: string;
  institution_id_code: string;
  system_trace_audit_number: string;
}

export interface Request {
  zip: string;
  street_address: string;
}

export interface Response2 {
  code: string;
  memo: string;
}

export interface OnFile {
  zip: string;
  street_address: string;
}

export interface AddressVerification {
  request: Request;
  response: Response2;
  on_file: OnFile;
}

export interface Response3 {
  code: string;
  memo: string;
}

export interface Source {
  active: boolean;
  created_time: Date;
  token: string;
  last_modified_time: Date;
  name: string;
  type: string;
  is_default_account: boolean;
}

export interface Request2 {
  zip: string;
  street_address: string;
}

export interface Response5 {
  code: string;
  memo: string;
}

export interface OnFile2 {
  zip: string;
  street_address: string;
}

export interface Issuer {
  response: Response5;
  on_file: OnFile2;
}

export interface AddressVerification2 {
  request: Request2;
  issuer: Issuer;
}

export interface JitFunding {
  business_token: string;
  acting_user_token: string;
  token: string;
  address_verification: AddressVerification2;
  method: string;
  amount: string;
  memo: string;
}

export interface Data {
  jit_funding: JitFunding;
}

export interface Response4 {
  code: string;
  data: Data;
}

export interface GatewayLog {
  order_number: string;
  response: Response4;
  duration: number;
  message: string;
  transaction_id: string;
  timed_out: boolean;
}

export interface Funding {
  source: Source;
  gateway_log: GatewayLog;
  amount: string;
}

export interface Request3 {
  zip: string;
  street_address: string;
}

export interface Response6 {
  code: string;
  memo: string;
}

export interface OnFile3 {
  zip: string;
  street_address: string;
}

export interface Issuer2 {
  response: Response6;
  on_file: OnFile3;
}

export interface AddressVerification3 {
  request: Request3;
  issuer: Issuer2;
}

export interface JitFunding2 {
  business_token: string;
  acting_user_token: string;
  token: string;
  method: string;
  amount: string;
  address_verification: AddressVerification3;
}

export interface GpaOrder {
  created_time: Date;
  response: Response3;
  funding: Funding;
  last_modified_time: Date;
  amount: string;
  business_token: string;
  memo: string;
  token: string;
  funding_source_token: string;
  jit_funding: JitFunding2;
  state: string;
  currency_code: string;
  transaction_token: string;
}

export interface Card {
  metadata: any;
}

export interface Response7 {
  code: string;
  memo: string;
}

export interface CardholderAuthenticationData {
  verification_value_created_by: string;
  electronic_commerce_indicator: string;
  verification_result: string;
}

export interface Poi {
  card_presence: string;
  channel: string;
  pin_present: string;
  partial_approval_capable: string;
  processing_type: string;
  cardholder_presence: string;
  tid: string;
}

export interface CardAcceptor {
  zip: string;
  mcc_groups: string[];
  poi: Poi;
  country: string;
  network_mid: string;
  mcc: string;
  name: string;
  state: string;
  city: string;
  mid: string;
}

export interface Business {
  metadata: any;
}

export interface Network {
  transaction_risk_score: number;
}

export interface Fraud {
  network: Network;
}

export interface Payload {
  gpa: Gpa;
  card_security_code_verification: CardSecurityCodeVerification;
  acting_user_token: string;
  created_time: Date;
  duration: number;
  issuer_interchange_amount: number;
  acquirer: Acquirer;
  amount: string;
  address_verification: AddressVerification;
  business_token: string;
  gpa_order: GpaOrder;
  token: string;
  acquirer_fee_amount: number;
  is_recurring: boolean;
  state: string;
  subnetwork: string;
  card: any;
  user_transaction_time: Date;
  response: Response7;
  cardholder_authentication_data: CardholderAuthenticationData;
  network: string;
  card_acceptor: CardAcceptor;
  type: string;
  business: Business;
  card_token: string;
  currency_code: string;
  request_amount: string;
  fraud: Fraud;
  settlement_date: Date;
  preceding_related_transaction_token: string;
}

export interface History {
  amount: number;
  status: string;
  create_ts: string;
  text: string;
}

// CLASSES

export class TransactionLog {
  ip_address: string;
  create_ts: string;
  url: string;
  method: string;
  id: string;
  status: string;
  payload: Payload;

  constructor(
    ip_address: string = null,
    create_ts: string = null,
    url: string = null,
    method: string = null,
    id: string = null,
    status: string = null,
    payload: Payload = null,
  ) {
    this.ip_address = ip_address;
    this.create_ts = create_ts;
    this.url = url;
    this.method = method;
    this.id = id;
    this.status = status;
    this.payload = payload;
  }
}

export class TransactionDetail {
  date: string;
  amount: number;
  rebate: number;
  token: string;

  constructor(date: string = null, amount: number = null, rebate: number = null, token: string = null) {
    this.date = date;
    this.amount = amount;
    this.rebate = rebate;
    this.token = token;
  }
}

export class General {
  amount: number;
  status: string;
  history: History[];

  constructor(amount: number = null, status: string = null, history: History[] = null) {
    this.amount = amount;
    this.status = status;
    this.history = history;
  }
}

export class TransactionHistory {
  text: string;
  date: string;

  constructor(text: string = null, date: string = null) {
    this.text = text;
    this.date = date;
  }
}

export class TransactionDetails {
  details: TransactionDetail;
  logs: TransactionLog[];
  general: General;
  history: TransactionHistory[];

  constructor(
    details: TransactionDetail = null,
    logs: TransactionLog[] = null,
    general: General = null,
    history: TransactionHistory[] = null,
  ) {
    this.details = details;
    this.logs = logs;
    this.general = general;
    this.history = history;
  }
}

// MAPPERS

export class TransactionHistoryMapper {
  public static map(data): TransactionHistory {
    if (!data) {
      return;
    }

    return new TransactionHistory(data.text, data.date);
  }
}

export class TransactionDetailsMapper {
  public static map(data): TransactionDetails {
    if (!data) {
      return;
    }

    return new TransactionDetails(
      TransactionDetailMapper.map(data.details),
      data.logs.map(TransactionLogMapper.map),
      GeneralMapper.map(data.general),
    );
  }
}

export class TransactionDetailMapper {
  public static map(data): TransactionDetail {
    if (!data) {
      return;
    }

    return new TransactionDetail(data.date, data.amount, data.rebate, data.token);
  }
}

export class TransactionLogMapper {
  public static map(data): TransactionLog {
    if (!data) {
      return;
    }

    return new TransactionLog(data.ip_address, data.create_ts, data.url, data.method, data.id, data.status, data.payload);
  }
}

export class GeneralMapper {
  public static map(data): General {
    if (!data) {
      return;
    }

    return new General(data.amount, data.status, data.history);
  }
}
