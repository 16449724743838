import { Observable } from 'rxjs';

import { EventModel } from '../models/event.model';
import { CrmMetaModel } from '../models/meta.model';
import { TaskModel } from '../models/task.model';
import { TaskStatuses as T } from '../tasks/task-statuses';

export abstract class TasksAbstractService {
  public tasksTransitions = {
    start: {
      [T.NEW]: T.IN_PROGR,
    },
    reopen: {
      [T.IN_PROGR]: T.NEW,
      [T.CLOSED]: T.NEW,
      [T.DONE]: T.NEW,
    },
    resolve: {
      [T.NEW]: T.DONE,
      [T.IN_PROGR]: T.DONE,
    },
    close: {
      [T.NEW]: T.CLOSED,
      [T.IN_PROGR]: T.CLOSED,
      [T.DONE]: T.CLOSED,
    },
  };

  abstract addTask(data: TaskModel): Observable<TaskModel>;
  abstract editTask(uuid: string, data: TaskModel): Observable<TaskModel>;
  abstract deleteTask(uuid: string, targetUuid: string): Observable<Partial<TaskModel>>;
  abstract getEvents(uuid: string): Observable<EventModel[]>;
  abstract listTasks(limit: number, orderBy: any, filters: any, last: any): Observable<{ data: TaskModel[]; meta: CrmMetaModel }>;

  public getAbsoluteUrl(baseUrl: string, uuid: string): string {
    return `${baseUrl}/#/tasks/list?taskUuid=${uuid}`;
  }
}
