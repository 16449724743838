import { Component, Input } from '@angular/core';

import { IExternalReport } from '../../reports/model/i-external-report';
import { ExternalReportsService } from '../../reports/service/external-reports.service';
import { SubscriberComponent } from '../component-subscriber/subscriber.component';
import { IIframeDefinition } from '../interfaces/iframe-definition.interface';

@Component({
  selector: 'app-external-report',
  templateUrl: './external-report.component.html',
  styleUrls: ['./external-report.component.scss'],
})
export class ExternalReportComponent extends SubscriberComponent {
  key: string;
  externalId: string;
  iframeDefinition: IIframeDefinition;

  constructor(private externalReportsService: ExternalReportsService) {
    super();
  }

  @Input()
  set supplierKey(key: string) {
    if (!key) {
      return;
    }
    this.key = key;
    this.getDetail();
  }

  @Input()
  set externalReportId(externalReportId: string) {
    if (!externalReportId) {
      return;
    }
    this.externalId = externalReportId;
    this.getDetail();
  }

  private getDetail(): void {
    if (!this.externalId || !this.key) {
      return;
    }

    this.subscriptions.push(
      this.externalReportsService.getDetail(this.externalId).subscribe((response: IExternalReport[]) => {
        response.forEach((externalReport: IExternalReport) => {
          const customParamUrl = externalReport.customParamUrl;
          this.iframeDefinition = {
            name: externalReport.customName ? externalReport.customName : externalReport.name,
            url: customParamUrl.replace('<supplier_key>', this.key),
          };
        });
      }),
    );
  }
}
