import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';

import { SupplierService } from '../../suppliers/services/supplier.service';
import { SubscriberComponent } from './../component-subscriber/subscriber.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent extends SubscriberComponent implements OnInit {
  public isMarqetaActionMode = false;
  public actionReason = new FormControl(null, Validators.required);
  public isProceedButtonVisible = false;
  public transitionReasons = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supplierService: SupplierService,
  ) {
    super();
  }

  ngOnInit() {
    this.checkDialogData();
  }

  public closeDialog({ value }) {
    this.dialogRef.close(value);
  }

  private checkDialogData() {
    if (!isNullOrUndefined(this.data.withReasons) && this.data.withReasons === true) {
      this.isMarqetaActionMode = true;
      this.getCardTransitionReasons();
    }

    if (!isNullOrUndefined(this.data.isProceedButtonVisible) && this.data.isProceedButtonVisible === true) {
      this.isProceedButtonVisible = true;
    }
  }

  private getCardTransitionReasons() {
    this.subscriptions.push(
      this.supplierService.getCardTransitionReasons().subscribe((transitionReasons: any) => (this.transitionReasons = transitionReasons)),
    );
  }
}
