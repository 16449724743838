import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SubscriberComponent } from '../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-winback-confirmation-modal',
  templateUrl: './winback-confirmation-modal.component.html',
  styleUrls: ['./winback-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WinbackConfirmationModalComponent extends SubscriberComponent implements OnInit {
  public marketplaces = [];
  public products = [];
  public reason = null;
  public reason_description = null;
  public salesRep = null;

  constructor(public dialogRef: MatDialogRef<WinbackConfirmationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  }

  get productsTxt(): string {
    return this.products.join(', ');
  }

  get marketplacesTxt(): string {
    return this.marketplaces.join(', ');
  }

  ngOnInit(): void {
    this.marketplaces = this.data.summary.marketplaces;
    this.products = this.data.summary.products;
    this.reason = this.data.summary.reason;
    this.reason_description = this.data.summary.reason_description;
    this.salesRep = this.data.summary.sales_rep;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
