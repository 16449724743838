import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeValue',
})
export class SafeValuePipe implements PipeTransform {
  readonly DEFAULT_EMPTY_SYMBOL = '-';

  public transform(value: any, properties: string[] = [], emptySymbol = this.DEFAULT_EMPTY_SYMBOL): string {
    let symbolToReturn = emptySymbol;
    if (!this.valueExists(value)) {
      return symbolToReturn;
    }
    properties.forEach((property) => {
      if (!this.valueExists(value[property])) {
        symbolToReturn = emptySymbol;
      } else {
        value = value[property];
        symbolToReturn = value;
      }
    });
    return symbolToReturn;
  }

  valueExists(value: string | number): boolean {
    return !!value || value === 0;
  }
}
