import { ColumnPredef } from '../../../../shared/data-table-grid/models/default-column-definition';

const COLUMN_WIDTH = 160;

export const PrepareColumn = (field: string, name: string): ColumnPredef => {
  const column: ColumnPredef = new ColumnPredef(field, name, false);
  column.editable = false;
  delete column.cellRenderer;
  delete column.width;
  delete column.pinned;
  delete column.isSummed;
  delete column.headerHeight;
  column.width = COLUMN_WIDTH;
  return column;
};
