import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { selectLoggedUserModel } from '../auth/store/auth.actions';
import { AppConfigService } from '../config';
import { AppState } from '../store/app.reducers';
import { UserModel } from './users/user.model';
import { USER_PERMISSION } from './users/user-permissions.enum';

export interface UserProviderInformation {
  ip: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  postal: string;
  org: string;
}

export interface UserInformation {
  firstname: string;
  email: string;
  lastname: string;
}

@Injectable()
export class MonitorService {
  private eventloggerApiUrl: string;
  private messageQueue: any[] = [];
  private userProviderInformation: UserProviderInformation;
  private userInformation: UserInformation;
  private serviceBusy = false;
  private userPerrmision: string;
  private tracked = false;
  private segmentCode: string;

  constructor(private store: Store<AppState>, private httpClient: HttpClient, private router: Router, appConfig: AppConfigService) {
    this.segmentCode = appConfig.env.frontendSegmentIOCode;
    this.getUserData();
    this.eventloggerApiUrl = appConfig.env.eventLoggerUrl + 'track';
    this.runMonitor();
  }

  public send(message?): void {
    if (
      ![USER_PERMISSION.REST_UW_ACCESS, USER_PERMISSION.ADVANCE_AUDITORS].includes(this.userPerrmision as USER_PERMISSION) ||
      this.tracked
    ) {
      return;
    }

    this.logEvent(message);
    this.tracked = !this.tracked;
  }

  public setUntracked(): void {
    this.tracked = false;
  }

  private processQueue(): boolean | void {
    this.serviceBusy = true;

    if (!this.messageQueue.length) {
      this.serviceBusy = false;
      return this.serviceBusy;
    }

    this.processMessage(this.messageQueue.shift());
  }

  private runMonitor(): void {
    if (!(<any>window).analytics) {
      // Create a queue, but don't obliterate an existing one!
      const analytics = ((<any>window).analytics = (<any>window).analytics || []);

      // If the real analytics.js is already on the page return.
      if (analytics.initialize) {
        return;
      }

      // If the snippet was invoked already show an error.
      if (analytics.invoked) {
        if (window.console && console.error) {
          console.error('Segment snippet included twice.');
        }
        return;
      }

      // Invoked flag, to make sure the snippet
      // is never invoked twice.
      analytics.invoked = true;

      // A list of the methods in Analytics.js to stub.
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];

      // Define a factory to create stubs. These are placeholders
      // for methods in Analytics.js so that you never have to wait
      // for it to load to actually record data. The `method` is
      // stored as the first argument, so we can replay the data.
      analytics.factory = function (method) {
        return function () {
          // eslint-disable-next-line
          const args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          analytics.push(args);
          return analytics;
        };
      };

      // For each of our methods, generate a queueing stub.
      for (let i = 0; i < analytics.methods.length; i++) {
        const key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
      }

      // Define a method to load Analytics.js from our CDN,
      // and that will be sure to only ever load it once.
      analytics.load = function (key, options) {
        // Create an async script element based on your key.
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + 'analytics.min.js';

        // Insert our script next to the first script element.
        const first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
        analytics._loadOptions = options;
      };

      // Add a version to keep track of what's in the wild.
      analytics.SNIPPET_VERSION = '4.1.0';

      // Load Analytics.js with your key, which will automatically
      // load the tools you've enabled for your account. Boosh!
      analytics.load(this.segmentCode);

      // Make the first page call to load the integrations. If
      // you'd like to manually name or tag the page, edit or
      // move this call however you'd like.
      analytics.page();
    }

    if (!(<any>window).fga) {
      /*eslint-disable*/
      // prettier-ignore
      (function(i, s, o, g, r, a, m) {i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function() {
          (i[r].q = i[r].q || []).push(arguments); }, i[r].l = 1 * <any>(new Date()); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'fga');
      /* eslint-enable*/
    }

    this.initFullStory();
  }

  initFullStory(): void {
    this.store
      .select(selectLoggedUserModel)
      .pipe(filter((loggedUser) => !!loggedUser))
      .subscribe((loggedUser: UserModel) => {
        (<any>window).analytics.identify(loggedUser.name, {
          email: loggedUser.email,
          displayName: loggedUser.name,
        });
      });
  }

  processMessage(message) {
    // Log info

    if (typeof message !== 'object') {
      return this.processQueue();
    }

    // Łukasz
    // if (EVENTS[message.subject]) {
    //   // Do the actions in series
    //   Async.eachLimit(
    //     EVENTS[message.subject].actions,
    //     1,
    //     function (processFunction, doneCallback) {
    //       processFunction(message, doneCallback)
    //     },
    //     processQueue
    //   )
    // } else if (message.subject) {
    //   sendSegmentEvent(message, processQueue)
    // }

    this.sendSegmentEvent(message, this.processQueue);
  }

  private sendSegmentEvent(message, done) {
    // TODO : Clean up, probblly segment.io will be no longer needed in favour of eventLogger
    if ((<any>window).analytics) {
      const onboardingData = {};
      // $log.info('$auth3: ', $auth)

      if (message.data) {
        Object.assign(onboardingData, ...message.data);
      }

      (<any>window).analytics.track(message.subject, this.flatten(onboardingData), {
        context: {
          screen: {
            width: (<any>window).screen.width,
            height: (<any>window).screen.height,
          },
        },
      });

      // this.logEvent('test subject');
    }
    done();
  }

  private flatten(source, dest = {}, separator = '-', path = []) {
    for (const key in source) {
      if (!source.hasOwnProperty(key)) {
        continue;
      }

      if (typeof source[key] === 'object') {
        this.flatten(source[key], dest, separator, path.concat(key));
      } else {
        dest[path.concat(key).join(separator)] = source[key];
      }
    }

    return dest;
  }

  private prepareData(suppKey: string): any {
    const data: any = {};
    data.user = this.userInformation;
    data.url = 'test`';
    data.target_type = 'supplier';
    data.subject = 'Restricted UW Access';
    data.extra = {};
    data.extra['route'] = this.router.url;
    data.extra['eventTypeCode'] = 'kyc_secure_site_guardrails';
    if (suppKey) {
      data.extra['suppKey'] = suppKey;
    }

    return data;
  }

  public getUserData(): void {
    this.store.select('auth').subscribe((data) => {
      if (data.user) {
        if (data.user.perms && data.user.perms[0] && data.user.perms[0].permCode) {
          this.userPerrmision = data.user.perms[0].permCode;
        }

        this.userInformation = {
          firstname: data.user.firstname,
          email: data.user.email,
          lastname: data.user.lastname,
        };
      }
    });
  }

  private getHeaders(suppKey: string): any {
    const headers: { [name: string]: string } = {};
    headers['Authorization'] = 'Token 79e4933035c840438f999f8ad9e38571';
    headers['X-EventLogger-InstanceId'] = '8003';
    headers['X-EventLogger-Type'] = 'analitics';
    if (suppKey) {
      headers['X-EventLogger-TargetUUID'] = suppKey;
    }

    return headers;
  }

  private logEvent(msg: string): void {
    if (this.tracked) {
      return;
    }

    (<any>window).analytics.identify('KYC Secure Site Guardrails', { ...this.prepareData(msg) });

    this.httpClient
      .post(
        this.eventloggerApiUrl,
        { ...this.prepareData(msg) },
        {
          headers: new HttpHeaders(this.getHeaders(msg)),
        },
      )
      .subscribe(
        () => {},
        () => {},
      );
  }
}
