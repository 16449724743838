<section class="container-fluid flex-container">
  <div class="card flex-container">
    <div class="card-content flex-container">
      <div class="card-header">
        <div class="navigation">
          <a [routerLink]="['/manage/users/list']" title="Go to users list" class="close-button">
            <i class="material-icons">arrow_back</i>
          </a>
        </div>
        <div class="title">
          {{ title }}
        </div>
      </div>

      <form class="custom-form" [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
        <div class="form-content">
          <div class="form-actions">
            <a class="lock-button" title="{{ 'Click to ' + ((isLocked && 'unlock') || 'lock') }}" (click)="toggleLock()">
              <i class="material-icons">{{ isLocked ? 'lock' : 'lock_open' }}</i>
            </a>
          </div>

          <h6>User Details</h6>
          <fieldset class="form-section">
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="First Name" formControlName="firstname" />
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Last Name" formControlName="lastname" />
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Login ID - used for login" formControlName="loginId" />
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Email" formControlName="email" />
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Authy ID" formControlName="authyId" />
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Country Code" formControlName="countryCode" />
            </mat-form-field>
            <mat-form-field class="field-text">
              <input matInput type="text" placeholder="Phone Number" formControlName="phoneNumber" />
            </mat-form-field>
          </fieldset>

          <h6>Permissions</h6>
          <fieldset class="form-section">
            <mat-form-field>
              <mat-select name="options" placeholder="profile" formControlName="permission">
                <mat-option *ngFor="let permission of permissions" [value]="permission.permCode">
                  {{ permission.permDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </fieldset>
        </div>
        <div class="form-submit">
          <button mat-raised-button class="btn btn-cancel pull-right" color="primary" (click)="onCancel()">Cancel</button>
          <button mat-raised-button class="btn btn-success pull-right" type="submit" color="accent" [disabled]="isLocked">Save</button>
          <button
            mat-raised-button
            class="btn btn-success pull-right"
            type="button"
            color="warn"
            (click)="onDelete()"
            [disabled]="isLocked"
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
