import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { isUndefined } from 'util';

import { CardFormgroupMessageService } from '../../../../../shared/card-formgroup/card-formgroup-message.service';
import { Logger } from '../../../../../shared/logger';
import { AppState } from '../../../../../store/app.reducers';
import { SupplierModel } from '../../../../model/supplier.model';
import { SupplierAuthTokenModel } from '../../../../model/supplier-auth-token.model';
import { SupplierAuthTokenService } from '../../../../services/supplier-auth-token.service';
import { selectSupplierModel } from '../../../../store/supplier/supplier.actions';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-supplier-auth-token',
  templateUrl: './supplier-auth-token.component.html',
  styleUrls: ['./supplier-auth-token.component.scss'],
})
export class SupplierAuthTokenComponent extends SubscriberComponent implements OnInit {
  supplierModel: SupplierModel;

  authTokenForm: FormGroup;

  authCode = null;
  authCodeCounter = 0;
  authTokenChecked = false;

  currentAuthToken: string;
  currentTimer: any;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private authService: SupplierAuthTokenService,
    private messageService: CardFormgroupMessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(selectSupplierModel).subscribe((supplierModel: SupplierModel) => {
        this.supplierModel = supplierModel;
        if (!isUndefined(this.supplierModel) && this.supplierModel.supplierKey) {
          this.subscriptions.push(
            this.authService.getAuthToken(this.supplierModel.supplierKey, true).subscribe(
              (authModelToCheck: SupplierAuthTokenModel) => {
                this.currentAuthToken = authModelToCheck.token;
                this.authTokenChecked = true;

                this.getAuthCode();
              },
              (errorResponse: HttpErrorResponse) => {
                if (errorResponse.status !== 404) {
                  Logger.error(errorResponse);
                }
                this.authTokenChecked = true;
              },
            ),
          );
        }
      }),
    );
    this.createForm();
  }

  createForm() {
    this.authTokenForm = this.formBuilder.group({
      // eslint-disable-next-line
      token: ['', [Validators.required, Validators.pattern(/^[A-Z2-7](.+)$/)]],
    });
  }

  getAuthCode() {
    if (!this.supplierModel) {
      return;
    }

    this.subscriptions.push(
      this.authService.getAuthCode(this.supplierModel.supplierKey, true).subscribe(
        (result) => {
          this.authCode = result.data.code;
          this.authCodeCounter = 300;
          this.setTimer();
        },
        (error) => {
          Logger.log(error);
        },
      ),
    );
  }

  saveAuthToken({ value, valid }: { value: any; valid: boolean }, createNew = false) {
    if (valid) {
      let saveAction;
      const authTokenModel: SupplierAuthTokenModel = value;

      if (!this.supplierModel) {
        throw new Error('INVALID FORM GROUP');
      }

      authTokenModel.supplier = this.supplierModel.supplierKey;

      if (createNew) {
        saveAction = this.authService.createAuthToken(authTokenModel);
      } else {
        saveAction = this.authService.updateAuthToken(this.supplierModel.supplierKey, authTokenModel);
      }
      this.subscriptions.push(
        saveAction.subscribe(
          (result) => {
            this.currentAuthToken = authTokenModel.token;
            this.authCodeCounter = 0;
            this.setTimer();

            this.messageService.broadcast(this.authTokenForm, 'saveSuccess');
          },
          (errorResponse: HttpErrorResponse) => {
            if (errorResponse.status === 400) {
              const errorMessage = errorResponse.error.meta.errors;
              this.messageService.broadcast(this.authTokenForm, 'saveError', errorMessage);
            } else {
              Logger.error(errorResponse);
            }
            this.authTokenChecked = true;
          },
        ),
      );
    } else {
      throw new Error('INVALID FORM GROUP');
    }
  }

  deleteAuthToken() {
    if (!this.supplierModel) {
      return;
    }
    this.subscriptions.push(
      this.authService.deleteAuthToken(this.supplierModel.supplierKey).subscribe(
        () => {
          this.authTokenChecked = true;
          this.authCode = null;
          clearInterval(this.currentTimer);
          this.authCodeCounter = 300;
          this.currentAuthToken = null;

          this.messageService.broadcast(this.authTokenForm, 'saveSuccess');
        },
        (error) => {
          Logger.log(error);
          this.messageService.broadcast(this.authTokenForm, 'saveError');
        },
      ),
    );
  }

  clearForm() {
    this.authTokenForm.get('token').setValue('');
  }

  onEdit(value) {
    if (value) {
      this.clearForm();
    }
  }

  setTimer() {
    if (this.currentTimer) {
      clearInterval(this.currentTimer);
    }
    this.currentTimer = setInterval(() => {
      if (this.authCodeCounter === 0) {
        this.getAuthCode();
      }
      this.authCodeCounter--;
    }, 100);
  }
}
